<template>
    <v-list-item ref="infoWindow" three-line class="pl-0">
        <v-list-item-avatar v-if="offer.thumb" class="my-0" :size="isCompactMode ? '50' : '125'" tile>
            <v-img
                tile
                class="thumb"
                :src="offer.thumb | imageUrl(250)"
                :alt="offer.name"
                lazy-src="/placeholder.png"
                @error="onImgError"
                @load="load"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
        </v-list-item-avatar>
        <div class="align-self-start" :class="isCompactMode ? 'small-content' : ''">
            <v-rating class="d-flex mr-3" x-small dense readonly :value="offer.category" />
            <v-list-item-title :title="offer.name">
                <n-link v-if="hotelPageLink" no-prefetch :to="hotelPageLink">
                    <span class="text-wrap">{{ offer.name }}</span>
                </n-link>
                <span v-else class="text-wrap">
                    {{ offer.name }}
                </span>
            </v-list-item-title>
            <v-list-item-subtitle class="mb-2">
                <div v-if="offer.address" class="text-wrap">
                    <v-icon small>mdi-map-marker</v-icon>
                    {{ offer.address }}
                </div>
                <div v-if="offer.distance">
                    <strong>{{ $t('distance') }}:</strong>
                    {{ offer.distance }} km from center
                </div>
                <div v-if="offer.district || offer.district === 'other'">
                    <strong>{{ $t('district') }}:</strong>
                    {{ offer.district }}
                </div>
            </v-list-item-subtitle>
            <div class="text-right">
                <v-btn color="primary" x-small @click="changeFilter">{{ $t('tours.show_tours') }}</v-btn>
            </div>
        </div>
    </v-list-item>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {toursStore, toursRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TourMapInfoWindow extends Vue {
        @Prop() offer
        @Prop({default: true}) withLink

        imgError = false
        clientWidth = 201

        load() {
            this.setSize()
        }

        onImgError() {
            this.imgError = true
            return false
        }

        setSize() {
            this.clientWidth = this.$refs.infoWindow.$el.clientWidth
        }

        changeFilter() {
            toursStore.SET_FILTER({
                key: 'hotelName',
                value: [this.offer.name],
            })
            toursRuntimeStore.filter()
        }

        get hotelPageLink() {
            // if (this.withLink) {
            //     return hotelsRuntimeStore.hotelPageLink(this.offer, hotelsStore.searchRequest)
            // }
            return null
        }

        get isCompactMode() {
            return this.clientWidth < 200
        }
    }
</script>

<style scoped>
    .small-content {
        font-size: 0.8rem !important;
    }
</style>
