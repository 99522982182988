<template>
    <div>
        <breadcrumbs class="mb-3" />
        <div class="d-flex mb-4">
            <v-btn depressed :to="{name: 'company-client-profile', params: {id: 0}}" class="primary me-2">
                {{ $t('new_corporate_client_profile') }}
            </v-btn>
            <v-btn v-if="isTO1" outlined color="primary" :disabled="!emails.length" @click="emailModal = true">
                {{ $t('send_email') }}
            </v-btn>
        </div>
        <companies-table ref="table" :company-type="['CORPORATOR']" @change-selected-emails="emails = $event" />
        <send-clients-email-modal v-model="emailModal" :emails="emails" @sent="$refs.table.selected = []" />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import CompaniesTable from '@/components/account/CompaniesTable'
    import {authStore} from '@/utils/store-accessor'
    import SendClientsEmailModal from '@/components/account/modals/SendClientsEmailModal'

    @Component({
        components: {
            SendClientsEmailModal,
            Breadcrumbs,
            CompaniesTable,
        },
        middleware: 'auth',
    })
    export default class CompanyCorporateClientsPage extends Vue {
        emails = []
        emailModal = false

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
