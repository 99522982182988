<template>
    <div>
        <v-autocomplete
            ref="input"
            v-model="city"
            :items="items"
            :loading="baseLoading || loading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            item-value="id"
            :dense="dense"
            :label="baseLabel || $t(label)"
            :placeholder="showPlaceholder ? $t(label) : ''"
            :no-filter="noFilter"
            :clearable="true"
            return-object
            :single-line="singleLine"
            :outlined="outlined"
            :filled="filled"
            :disabled="disabled"
            :hide-details="!showDetails && !tryResearch.status"
            :error="tryResearch.status"
            :error-messages="tryResearch.status ? [tryResearch.message] : []"
            :background-color="backgroundColor"
            :prepend-inner-icon="showIcon ? prependInnerIcon : null"
            :rules="rules"
            :class="textFieldClass"
            :rounded="rounded"
            :persistent-placeholder="persistentPlaceholder"
            data-cy="destination-input"
            :filter="filter"
            @change="
                $emit('change', $event)
                $refs.input.blur()
            "
            @click:clear="clear"
            @click="showMobileAutocomplete($event)"
            @input="onInput"
        >
            <template v-slot:item="{item}">
                <slot name="item" :item="item">
                    <v-icon small left>
                        {{ icon(item.type) }}
                    </v-icon>
                    <span v-html="item.label" />
                </slot>
            </template>
            <template v-slot:selection="{item}">
                <slot name="selection" :item="item">
                    {{ item.selectedLabel || item.label }}
                </slot>
            </template>
        </v-autocomplete>
        <v-dialog v-model="showDialog" fullscreen transition="dialog-bottom-transition">
            <v-toolbar dark color="primary" class="d-flex justify-end card-toolbar">
                <v-toolbar-title>{{ $t(label) }}</v-toolbar-title>
                <v-btn icon dark @click="showDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="d-flex flex-column" height="100%">
                <v-card-text>
                    <v-text-field
                        ref="mobileInput"
                        v-model="locationNameSearch"
                        :prepend-inner-icon="showIcon ? prependInnerIcon : null"
                        :loading="loading"
                        :error="tryResearch.status"
                        :error-messages="tryResearch.status ? [tryResearch.message] : []"
                        @input="onSearch(locationNameSearch)"
                    >
                        <template v-slot:append>
                            <v-icon v-if="locationNameSearch.length > 1" @click="locationNameSearch = ''">
                                mdi-close
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-card-text>
                <v-list class="overflow-y-auto">
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        class="d-flex justify-start align-center"
                        @click="selectCity(item)"
                    >
                        <v-list-item-icon class="v-list-item__icon">
                            <v-icon>
                                {{ icon(item.type) }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-weight-regular d-block" v-html="item.label" />
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {Component, Model, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {clone} from '@/utils/helpers'

    //TODO Improve mobile UX
    @Component
    export default class CityAutocomplete extends Vue {
        @Model('change') value
        @Prop() rules
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: false, type: Boolean}) filled
        @Prop({default: () => []}) defaultCities
        //TODO Need refactoring
        @Prop({default: 'destination'}) label
        @Prop() baseLabel
        @Prop({default: true}) showPlaceholder
        @Prop({default: true}) showIcon
        @Prop({default: false, type: Boolean}) disabled
        @Prop() countryId
        @Prop({default: false, type: Boolean}) dense
        @Prop() productType
        @Prop({default: false, type: Boolean}) showDetails
        @Prop({default: 'mdi-map-marker'}) prependInnerIcon
        @Prop({default: 'white'}) backgroundColor
        @Prop({default: false, type: Boolean}) rounded
        @Prop({default: false, type: Boolean}) persistentPlaceholder
        @Prop({default: false, type: Boolean}) baseLoading
        @Prop({default: () => {}, type: Function}) filter
        @Prop({default: true}) noFilter
        @Prop() locationType

        entries = []
        loading = false
        search = null
        city = null
        tryResearch = {
            status: false,
            message: '',
        }
        timer = null
        showDialog = false
        locationNameSearch = ''

        mounted() {
            this.load()
        }

        load() {
            if (!this.search && !this.city && this.entries.length === 0) {
                this.entries = this.defaultCities
            }
        }

        onInput() {
            if (this.tryResearch.status) {
                this.tryResearch.status = false
            }
        }

        clear() {
            this.tryResearch.status = false
            this.entries = []
        }

        async showMobileAutocomplete(event) {
            if (this.$breakpoint.smAndDown) {
                this.showDialog = true
                this.$refs.input.blur()
                await this.$nextTick()
                setTimeout(() => {
                    this.$refs.mobileInput.focus()
                })
                event.stopPropagation()
            }
        }

        async selectCity(cityItem) {
            //TODO Vuetify VAutocomplete workaround for select without validation error - need check after Vuetify update
            let location = clone(cityItem)
            let closeEl = this.$refs.input.$el.querySelector('.v-input__icon--clear button')
            if (closeEl) closeEl.click()
            this.$emit('change', location)
            if (!closeEl) {
                location = clone(location)
                await this.$nextTick()
                closeEl = this.$refs.input.$el.querySelector('.v-input__icon--clear button')
                closeEl.click()
                this.$emit('change', location)
            }
            this.showDialog = false
        }

        get labels() {
            return this.entries.map(entry => {
                return (
                    `${entry.name}, ${entry.parentName}` +
                    (entry.parentName !== entry.countryName ? `, ${entry.countryName}` : '')
                )
            })
        }

        get items() {
            return this.entries.map(entry => {
                const label =
                    `${entry.name}, ${entry.parentName}` +
                    (entry.parentName !== entry.countryName ? `, ${entry.countryName}` : '')
                return Object.assign({}, entry, {label})
            })
        }

        @Watch('search')
        onSearch(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.initSearch(val)
            }, 500)
        }

        async searchCities(val) {
            this.tryResearch.status = false
            const value = val.trim().toLowerCase()

            const rq = {
                pattern: `%${value}%`,
                limitCities: 10,
                orderBy: 'POPULARITY',
                ...(this.countryId && {countryId: this.countryId}),
                ...(this.productType && {productType: this.productType}),
                ...(this.productType === 'ACCOMMODATION' && {hotelsQuantity: 1}),
                ...(this.locationType && {locationType: this.locationType}),
            }
            const rs = await this.$api.locations.get(rq)
            if (rs.cities.length === 0) {
                this.tryResearch.status = true
                this.tryResearch.message = this.isMobile
                    ? this.$t('try_rewrite_destination_mobile')
                    : this.$t('try_rewrite_destination')
            }

            return rs.cities
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                this.entries = await this.searchCities(val)
            } finally {
                this.loading = false
            }
        }

        @Watch('value')
        onChangeValue(val) {
            this.city = val
            if (val && Object.keys(val).length && !this.entries.find(({id}) => id === val.id)) {
                this.entries = [val]
            } else if (!val || !Object.keys(val).length) {
                this.entries = this.defaultCities
            }
        }

        get locationIcon() {
            return 'mdi-earth'
        }

        icon() {
            return this.locationIcon
        }

        get textFieldClass() {
            return this.$breakpoint && this.$breakpoint.smAndDown ? 'caption' : ''
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
<style scoped lang="scss">
    div::v-deep {
        .v-dialog {
            overflow-y: hidden;
        }
        .v-label {
            font-size: inherit;
        }
    }
</style>
