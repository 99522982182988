<template>
    <v-list>
        <template v-for="(entry, index) in formattedEntries">
            <tour-offer-entry
                :key="`entry_${index}`"
                :entry="entry"
                :offer="offer"
                :citizenship-id="citizenshipId"
                :selectable="selectable"
                :search-request="searchRequest"
            />
            <v-divider v-if="index < formattedEntries.length - 1" :key="`divider_${index}`" />
        </template>
        <template v-if="optionalEntries.length">
            <div class="mt-4 font-weight-medium text-truncate">
                {{ `${$t('tours.optional_services')}:` }}
            </div>
            <tour-offer-entry
                v-for="(entry, index) in optionalEntries"
                :key="index"
                :entry="entry"
                :offer="offer"
                :selectable="selectable"
                :search-request="searchRequest"
            />
        </template>
    </v-list>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import TourOfferEntry from '~src/components/tours/search/offers/tourOfferEntry.src'
    import {addDays, format} from 'date-fns'

    @Component({components: {TourOfferEntry}})
    export default class TourOfferEntriesList extends Vue {
        @Prop({required: true}) offer
        @Prop({required: false, type: Boolean}) selectable
        @Prop() searchRequest
        @Prop() citizenshipId

        getTime(entry, packageDate) {
            if (entry.entryDate) {
                return entry.entryDate
            } else if (entry.dayNumber > 1) {
                return format(addDays(new Date(packageDate), entry.dayNumber - 1), 'yyyy-MM-dd')
            } else return packageDate
        }

        get formattedEntries() {
            let firstFlight
            let lastFlight

            return this.offer.entries.reduce((formattedEntries, entry, index) => {
                if (!entry.obligatory && !entry.base) return formattedEntries
                if (entry.type === 'FLIGHT') {
                    firstFlight = Object.assign({}, entry)
                    const firstFlightSegments = firstFlight.offers[0].itinerary[0].segments
                    firstFlight.flightName =
                        firstFlightSegments[0].departure.airportDescription.airportName +
                        ' - ' +
                        firstFlightSegments[firstFlightSegments.length - 1].arrival.airportDescription.airportName
                    firstFlight.entryDate = firstFlightSegments[0].departure.date
                    formattedEntries.push(firstFlight)

                    if (entry.offers[0].itinerary.length > 1) {
                        lastFlight = Object.assign({}, entry)
                        const lastFlightSegments = lastFlight.offers[0].itinerary[1].segments
                        lastFlight.flightName =
                            lastFlightSegments[0].departure.airportDescription.airportName +
                            ' - ' +
                            lastFlightSegments[lastFlightSegments.length - 1].arrival.airportDescription.airportName
                        lastFlight.entryDate = lastFlightSegments[0].departure.date
                    }
                } else {
                    const entryCopy = Object.assign({}, entry)
                    entryCopy.entryDate = this.getTime(entryCopy, this.offer.info.packageDate)
                    formattedEntries.push(entryCopy)
                }
                if (lastFlight && index === this.offer.entries.length - 1) formattedEntries.push(lastFlight)
                return formattedEntries
            }, [])
        }

        get optionalEntries() {
            return this.offer.entries.reduce((entries, entry) => {
                if (!entry.obligatory && !entry.base) {
                    const entryCopy = Object.assign({}, entry)
                    entryCopy.entryDate = this.getTime(entryCopy, this.offer.info.packageDate)
                    entries.push(entryCopy)
                }
                return entries
            }, [])
        }
    }
</script>
