<template>
    <v-app>
        <header-bar v-model="drawer" />
        <v-main>
            <admin-navigation />
            <v-container fluid class="pl-15">
                <nuxt />
            </v-container>
        </v-main>
        <navigation-drawer-mobile v-if="$breakpoint.smAndDown" v-model="drawer" />
        <footer-bar />
    </v-app>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import {EventBus, LOGOUT_REDIRECT_EVENT} from '@/utils/event-bus'
    import HeaderBar from '~src/components/headerBar.src'
    import FooterBar from '~src/components/footerBar.src'
    import AdminNavigation from '@/components/admin/AdminNavigation'
    import BaseLayout from '@/components/BaseLayout'
    import NavigationDrawerMobile from '~src/components/snippets/navigationDrawerMobile.src'

    @Component({
        components: {
            NavigationDrawerMobile,
            AdminNavigation,
            HeaderBar,
            FooterBar,
        },
    })
    export default class AdminLayout extends BaseLayout {
        created() {
            EventBus.$on(LOGOUT_REDIRECT_EVENT, () => {
                if (this.$toast) {
                    this.$toast.error(this.$t('authorization_timeout'))
                }
            })
        }
    }
</script>
