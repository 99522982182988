<template>
    <v-form v-model="valid">
        <v-row>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.bank"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.bank')"
                />
            </v-col>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.iban"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.iban')"
                />
            </v-col>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.branch"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.branch')"
                />
            </v-col>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.swiftCode"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.swift_code')"
                />
            </v-col>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.account"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.account')"
                />
            </v-col>
            <v-col md="6" cols="12">
                <date-picker
                    v-model="bankTransferDetails.valueDate"
                    outlined
                    dense
                    :label="$t('bank_transfer.value_date')"
                    hide-details
                />
            </v-col>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.reference"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.reference')"
                />
            </v-col>
            <v-col md="6" cols="12">
                <v-text-field
                    v-model="bankTransferDetails.receivingBank"
                    outlined
                    dense
                    hide-details
                    :label="$t('bank_transfer.receiving_bank')"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, PropSync, VModel} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {PaymentRedirectForm, DatePicker},
    })
    export default class BankTransferForm extends mixins(FormValidationRulesMixin) {
        @VModel() valid
        @PropSync('_bankTransferDetails') bankTransferDetails

        mounted() {
            this.bankTransferDetails.valueDate = this.$dateFns.format(new Date())
        }
    }
</script>
