<template>
    <v-card class="my-2">
        <v-card-title :class="!$breakpoint.smAndDown ? 'title' : 'subtitle-2'" class="text-nobreak">
            {{ itineraryName }}
        </v-card-title>
        <v-card-text>
            <template v-for="(segment, segmentIndex) in itinerary.segments">
                <flight-segment-detail :key="`itinerary-segment-${segmentIndex}`" :segment="segment" />
                <div
                    v-if="itinerary.segments.length > 1 && segmentIndex !== itinerary.segments.length - 1"
                    :key="`itinerary-segment-${segmentIndex}-connection`"
                    class="waiting-block px-5 d-flex flex-wrap align-center flex-column flex-md-row"
                >
                    <div class="text-no-wrap">
                        {{ connectionDuration(segment, itinerary.segments[segmentIndex + 1]) | durationFormat }}
                    </div>
                    <div class="ml-0 ml-md-10">
                        {{ $t('flights.conneсt_in_airport') }}
                    </div>
                    <div class="ml-0 ml-md-10">
                        <strong v-if="connectionDuration(segment, itinerary.segments[segmentIndex + 1]) > 180">
                            {{ $t('flights.long_wait') }}
                        </strong>
                    </div>
                </div>
            </template>
        </v-card-text>
        <v-card-actions>
            <v-subheader v-if="itinerary.duration">
                {{ $t('flights.total_trip_time') }}: {{ itinerary.duration | durationFormat }}
            </v-subheader>
        </v-card-actions>
        <slot />
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FlightSegmentDetail from '~/components/flights/search/offers/FlightSegmentDetail'

    @Component({
        components: {
            FlightSegmentDetail,
        },
    })
    export default class FlightItineraryDetail extends Vue {
        @Prop() itinerary

        connectionDuration({arrivalDate}, {departureDate}) {
            return this.$dateFns.differenceInMinutes(
                this.$dateFns.parseISO(departureDate),
                this.$dateFns.parseISO(arrivalDate)
            )
        }

        get itineraryName() {
            const firstSegment = this.itinerary.segments[0]
            const lastSegment = this.itinerary.segments[this.itinerary.segments.length - 1]
            return `${firstSegment.departureAirportDescription.airportName}, ${firstSegment.departureAirportDescription.cityName}, ${firstSegment.departureAirportDescription.countryName} - ${lastSegment.arrivalAirportDescription.airportName}, ${lastSegment.arrivalAirportDescription.cityName}, ${lastSegment.arrivalAirportDescription.countryName}`
        }
    }
</script>

<style lang="scss">
    .waiting-block {
        display: flex;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-radius: 0.375rem;
        background: #f1f2f8;
        color: #d1435b;
    }
</style>
