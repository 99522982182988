<template>
    <v-dialog v-model="modal" width="800px" max-width="100%">
        <v-card v-if="offer" width="800px" max-width="100%" class="pa-md-3">
            <v-card-title>
                <span class="text-h6">
                    {{ `${offer.serviceName} / ${offer.categoryName}` }}
                </span>
                <v-icon class="v-card-close ma-4" @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="black--text">
                <v-tabs>
                    <v-tab>
                        {{ $t('description') }}
                    </v-tab>
                    <v-tab-item>
                        <v-row no-gutters>
                            <template v-if="offer.serviceDescription">
                                <v-col cols="12">
                                    <div v-html="offer.serviceDescription" />
                                </v-col>
                            </template>
                            <v-col v-else cols="12" class="d-flex align-center flex-column justify-center">
                                <div class="text-center text-caption text--secondary">
                                    {{ $t('no_data') }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_CRUISE_OFFER_INFO_MODAL} from '@/utils/event-bus'

    //TODO Need refactoring with other products
    @Component
    export default class CruiseOfferInfoModal extends Vue {
        offer = null
        modal = false

        created() {
            EventBus.$on(SHOW_CRUISE_OFFER_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_CRUISE_OFFER_INFO_MODAL, this.showModal)
        }

        showModal({offer}) {
            this.offer = offer
            this.modal = true
        }
    }
</script>
