<template>
    <div>
        <v-btn
            :disabled="!orderIds.length"
            :color="orderIds.length ? 'primary' : 'grey'"
            depressed
            text
            x-small
            @click="modal = true"
        >
            {{ $t('edit_reservations') }}
        </v-btn>
        <v-dialog v-model="modal" max-width="500">
            <v-card>
                <v-card-title class="title font-weight-light">{{ $t('change_selected_requests') }}</v-card-title>
                <v-card-text>
                    <v-select
                        v-model="requestManager"
                        :items="requestManagerList"
                        :label="$t('request_manager')"
                        item-value="personId"
                        item-text="fullName"
                        clearable
                    />
                    <v-select
                        v-model="serviceManager"
                        :items="serviceManagerList"
                        :label="$t('service_manager')"
                        item-value="personId"
                        item-text="fullName"
                        clearable
                    />
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn
                        :loading="isLoading"
                        :disabled="isLoading"
                        color="primary"
                        depressed
                        small
                        @click="saveManagers"
                    >
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn color="grey" small depressed class="white--text" @click="modal = false">
                        {{ $t('cancel') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class EditeReservationsModal extends Vue {
        @Prop({default: [], type: Array}) orderIds

        modal = false
        isLoading = false
        requestManager = null
        requestManagerList = []
        serviceManager = null
        serviceManagerList = []

        async mounted() {
            try {
                const {users} = await this.$api.persons.get({companyId: this.user.person.companyId})
                const roles = ['director', 'sales_manager', 'supervisor', 'sales_staff']

                this.requestManagerList = users
                    // .filter(u => u.role.includes('manager'))
                    .filter(u => roles.includes(u.role.split('.')[1]))
                    .map(u => {
                        u.fullName = [u.firstName, u.lastName].join(' ')
                        return u
                    })
                this.serviceManagerList = this.requestManagerList
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async saveManagers() {
            this.isLoading = true
            try {
                await this.$api.orderManagers.post({
                    orderIds: this.orderIds,
                    ...(this.requestManager && {orderManagerId: this.requestManager}),
                    ...(this.serviceManager && {serviceManagerId: this.serviceManager}),
                })
                this.modal = false
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        get user() {
            return authStore.user
        }
    }
</script>
