<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card v-if="isLoading" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-title class="title font-weight-light">
                {{ $t('cancellation') }}
            </v-card-title>
            <v-card-text class="text-center">
                <div v-if="error" class="error--text">
                    {{ $t('cancelInfoMessage.error') }}
                </div>
                <template v-else>
                    <div v-for="service in servicesWithCancelPrices" :key="service.processId">
                        <cancel-service-status-label
                            :customer-payment-status="service.customerPaymentStatus"
                            :cancel-price="service.cancelPrice"
                            :service-price="servicePrice(service)"
                            :is-not-paid="isNotPaid(service)"
                            :service="service"
                            :company="company"
                        >
                            <template v-slot:title>
                                <div class="d-flex align-center">
                                    <v-icon class="me-1" dense>{{ getServiceTypeIcon(service.serviceType) }}</v-icon>
                                    <span class="font-weight-medium" v-text="service.serviceName" />
                                </div>
                            </template>
                        </cancel-service-status-label>
                        <v-divider class="py-1" />
                    </div>
                    <div v-if="totalCancelPrice.amount > 0" class="d-flex pt-3 error--text">
                        <span class="font-weight-medium"> {{ $t('total') }} </span>
                        &nbsp;
                        <span>
                            {{ totalCancelPrice | price }}
                        </span>
                    </div>
                </template>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn v-if="isShowConfirmButton" class="warning" @click="cancelAll">
                    {{ $t('confirm_cancellation') }}
                </v-btn>
                <v-btn @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/store'
    import CancelServiceStatusLabel from '@/components/account/modals/CancelServiceStatusLabel'
    import {convertPrice} from '@/utils/filters'
    import {getServiceTypeIcon} from '@/utils/helpers'

    @Component({
        components: {CancelServiceStatusLabel},
    })
    export default class CancelServicesAllModal extends Vue {
        modal = false
        isLoading = true
        error = false
        servicesWithCancelPrices = null
        orderProcessId = null

        async mounted() {
            await this.$store.restored
            if (!Object.keys(authStore.company).length) {
                await authStore.loadCompany()
            }
        }

        async show(services, {processId}) {
            this.servicesWithCancelPrices = null
            this.error = false
            this.isLoading = true
            this.modal = true
            this.orderProcessId = processId

            try {
                const promises = services.map(service => {
                    return service.cancellationAllowed
                        ? async () => {
                              const cancelPrice = await this.$api.currentCancelInfo.get({processId: service.processId})
                              return {...cancelPrice, ...service}
                          }
                        : () => ({...service})
                })

                await Promise.all(promises.map(e => e())).then(servicesWithCancelPrices => {
                    this.servicesWithCancelPrices = servicesWithCancelPrices
                })
            } catch (e) {
                this.error = true
            } finally {
                this.isLoading = false
            }
        }

        async cancelAll() {
            try {
                this.isLoading = true

                const statuses = await this.$api.cancelService.post(this.cancelRequest)

                if (statuses.every(e => e === 'CANCELED')) {
                    this.$toast.success(`${this.$t('cancelInfoMessage.cancel_success')}`)
                } else {
                    this.$toast.warning(
                        `${this.$t('cancelInfoMessage.contact_to_agency', {
                            email: this.company.email,
                            phone: this.company.phones.join(','),
                        })}`
                    )
                }
            } catch (e) {
                this.$toast.error(
                    `${this.$t('cancelInfoMessage.error')}<br />${this.$t('cancelInfoMessage.contact_to_agency', {
                        email: this.company.email,
                        phone: this.company.phones.join(','),
                    })}`
                )
            } finally {
                this.modal = false
                this.$emit('cancelService')
            }
        }

        servicePrice(service) {
            return service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT')?.price
        }

        isNotPaid(service) {
            return ![
                'PAID',
                'PARTIAL_PAID',
                'request.service.payment_status.in_transit',
                'request.service.payment_status.invoice_waiting',
                'request.service.payment_status.overdue',
            ].includes(service.customerPaymentStatus)
        }

        cancellationAllowed(service) {
            return (
                service.cancelPrice &&
                service.customerPaymentStatus !== 'PARTIAL_PAID' &&
                (service.cancelPrice.amount === 0 ||
                    (service.cancelPrice.amount < this.servicePrice(service)?.amount && !this.isNotPaid(service)))
            )
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        get cancelRequest() {
            return {processId: this.orderProcessId}
        }

        get totalCancelPrice() {
            return this.servicesWithCancelPrices.reduce(
                (priceObj, service) => {
                    if (!service.cancelPrice) {
                        return priceObj
                    }

                    const convertedCancelPrice = convertPrice(service.cancelPrice)

                    if (!priceObj.currency) {
                        priceObj.currency = convertedCancelPrice.currency
                    }

                    priceObj.amount += convertedCancelPrice.amount

                    return priceObj
                },
                {amount: 0, currency: null}
            )
        }

        get servicesCanBeCancelled() {
            return this.servicesWithCancelPrices?.filter(service => {
                return this.cancellationAllowed(service)
            })
        }

        get isShowConfirmButton() {
            return this.servicesCanBeCancelled?.length
        }

        get company() {
            return authStore.company
        }
    }
</script>
