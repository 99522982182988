<template>
    <div class="text-caption text--secondary">
        {{ $t('for') }}
        {{ touristsTotal }} {{ $tc(touristLabel, touristsTotal) }}
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class PriceNote extends Vue {
        @Prop({required: true}) searchRequest
        @Prop({default: 'guest'}) touristLabel

        get touristsTotal() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.length
        }
    }
</script>
