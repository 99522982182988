<template>
    <v-col cols="12" class="pa-0" :class="{'sticky-bar': !$breakpoint.smAndDown}">
        <v-row no-gutters :class="{'d-flex flex-column': direction === 'column'}">
            <v-col v-if="flight && room" cols="12" :md="direction === 'row' ? 5 : 12">
                <v-card
                    v-ripple="false"
                    height="100%"
                    :elevation="tab === 0 && $route.name === 'packages' ? 24 : 2"
                    @click="changeTab(0)"
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-img :src="hotel.thumb | imageUrl(350)" :eager="true" />
                            </v-col>
                            <v-col cols="12" md="8" class="d-flex flex-column align-start">
                                <v-rating
                                    class="d-inline-block mr-3"
                                    x-small
                                    dense
                                    readonly
                                    :value="parseInt(hotel.category)"
                                />
                                <div>
                                    {{ hotel.name }}
                                </div>
                                <div>
                                    {{ room.name }}
                                </div>
                                <div>
                                    {{ $t(`filters_mealTypes.${room.mealTypeStandardCode}`) }}
                                </div>
                                <div />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions v-if="changable" class="d-flex justify-end ontop">
                        <v-btn v-if="$route.name !== 'packages' || tab !== 0 || $breakpoint.smAndDown" text>
                            {{ $t('change') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col v-if="hotel" cols="12" :md="direction === 'row' ? 5 : 12">
                <v-card
                    v-ripple="false"
                    height="100%"
                    class="pb-10 pb-md-0"
                    :elevation="tab === 1 && $route.name === 'packages' ? 12 : 2"
                    @click="changeTab(1)"
                >
                    <v-card-text class="px-1 py-2">
                        <flight-itinerary
                            v-for="(itinerary, index) in flight.itinerary"
                            :key="index"
                            :itinerary="itinerary"
                            :minify="true"
                        />
                        <div v-if="$route.name !== 'packages'">
                            <detail-modal :offer="flight" />
                            <fare-rules :offer-key="flight.offerKey" />
                        </div>
                    </v-card-text>
                    <v-card-actions v-if="changable" class="d-flex justify-end ontop">
                        <v-btn v-if="$route.name !== 'packages' || tab !== 1 || $breakpoint.smAndDown" text>
                            {{ $t('change') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col v-if="flight && room" cols="12" :md="direction === 'row' ? 2 : 12">
                <v-card height="100%">
                    <v-card-text class="d-flex align-center align-md-end flex-column">
                        <span class="headline black--text">
                            <strong>
                                {{ totalPrice | price }}
                            </strong>
                        </span>
                        <span>{{ $t('for_tour_for') }} {{ touristsTotal }} {{ $tc('person', touristsTotal) }}</span>
                    </v-card-text>
                    <v-spacer />
                    <div class="d-flex flex-column pa-2">
                        <v-btn
                            v-if="bookable"
                            :disabled="needUpdateRooms"
                            color="primary"
                            block
                            class="mb-2"
                            @click="selectForBooking"
                        >
                            {{ $t('book') }}
                        </v-btn>
                        <v-btn v-if="$route.name === 'packages'" :disabled="needUpdateRooms" block @click="goToDetail">
                            {{ $t('detail_info') }}
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {packagesRuntimeStore, packagesStore} from '~/store'
    import FlightItinerary from '@/components/flights/snippets/FlightItinerary'
    import DetailModal from '~/components/flights/DetailModal'
    import FareRules from '~/components/flights/FareRules'

    @Component({
        components: {
            FlightItinerary,
            DetailModal,
            FareRules,
        },
    })
    export default class SelectedOffers extends Vue {
        @Prop({default: 0}) tab
        @Prop({default: 'row'}) direction
        @Prop({default: true}) changable
        @Prop({default: true}) bookable
        @Prop({required: true}) hotel
        @Prop({required: true}) room
        @Prop({required: true}) flight
        @Prop({required: true}) searchRequest
        @Prop({required: true}) price
        @Prop() totalOrderPrice
        @Prop() currentPrice

        changeTab(tab) {
            if (this.$route.name !== 'packages') {
                this.$router.push({...packagesRuntimeStore.searchPageLink(this.searchRequest), params: {tab}})
            } else {
                this.$emit('change-tab', tab)
            }
        }

        goToDetail() {
            this.$router.push({name: 'packageDetail'})
        }

        async selectForBooking() {
            const offerKey = packagesStore.offerKey
            packagesStore.ADD_TO_BASKET({
                offerKey,
                hotel: this.hotel,
                room: this.room,
                flight: this.flight,
                searchRequest: this.searchRequest,
                price: this.price,
            })
            await this.$router.push({
                name: 'packageBooking',
                query: {offerKey},
            })
        }

        get needUpdateRooms() {
            return packagesStore.needUpdateRooms(this.hotel)
        }

        get totalPrice() {
            if (!this.changable && this.currentPrice) return this.currentPrice
            return this.totalOrderPrice || packagesRuntimeStore.totalPrice(this.room, this.flight, this.price)
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get touristsTotal() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.length
        }
    }
</script>

<style lang="scss" scoped>
    .ontop {
        position: absolute;
        bottom: 0;
        right: 0;
    }
</style>
