<template>
    <div class="d-flex flex-row" :class="!$breakpoint.smAndDown ? alignment : ''">
        <div v-if="isTourOperator || isAgency" class="width-50">
            <order-price
                v-if="isAgency || commissionAndNettoCheckbox"
                :title="isAgency ? 'price' : 'supplier_price'"
                :price="supplierPrice"
                :payment-status="supplierPaymentStatus"
                :cancel-penalties="supplierCancelPenalties"
                :show-commission="true"
                :service-card-guarantee="serviceCardGuarantee"
                :service="service"
                :discount="supplierDiscount"
                :hide-cancel-penalties="isBankFee"
                show-pricing-source-type
            />
        </div>
        <order-price
            v-if="!isAgency"
            :title="isTourOperator ? 'client_price' : null"
            :price="clientPrice"
            :payment-status="customerPaymentStatus"
            :cancel-penalties="clientCancelPenalties"
            :show-commission="isTourOperator"
            :loyalty-points="loyaltyPoints"
            :service-card-guarantee="serviceCardGuarantee"
            :service="service"
            :hide-cancel-penalties="isBankFee"
            :discount="clientDiscount"
        >
            <slot name="violation" />
        </order-price>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import OrderPrice from '~src/components/account/snippets/orderPrice.src'

    @Component({
        components: {
            OrderPrice,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
        },
    })
    export default class OrderPrices extends Vue {
        @Prop() supplierPrice
        @Prop() supplierPaymentStatus
        @Prop() supplierCancelPenalties
        @Prop() clientPrice
        @Prop() customerPaymentStatus
        @Prop() clientCancelPenalties
        @Prop() serviceCardGuarantee
        @Prop() loyaltyPoints
        @Prop({default: 'justify-space-around'}) alignment
        @Prop() service

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get clientDiscount() {
            return this.service?.salesTerms.find(({type}) => type === 'CLIENT').discount
        }

        get supplierDiscount() {
            return this.service?.salesTerms.find(({type}) => type === 'SUPPLIER').discount
        }

        get isBankFee() {
            return this.service?.serviceDetails?.[0]?.extraServiceProductType === 'BANK_FEE'
        }
    }
</script>

<style scoped>
    .width-50 {
        width: 50%;
    }
</style>
