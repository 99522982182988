<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ClientAutocomplete from '@/components/account/modals/newOrderRequest/ClientAutocomplete'
    import {PHONE_REGEXP} from '@/utils/helpers'

    @Component
    export default class CompanyAutocomplete extends ClientAutocomplete {
        @Prop({default: () => [null]}) types
        @Prop({default: 'name'}) mainSearchParam
        @Prop({default: false, type: Boolean}) phoneSearch

        companyPhoneString = null
        cancelTokenSources = []

        async loadClients() {
            const addClients = clients => {
                const result = [...this.clients, ...clients]
                result.sort((a, b) => a.name.localeCompare(b.name))
                if (this.itemValue) {
                    this.clients = result.filter(el => el[this.itemValue])
                } else {
                    this.clients = result
                }
            }
            try {
                this.clients = []
                this.cancelTokenSources.forEach(cancelTokenSource => cancelTokenSource.cancel())
                this.cancelTokenSources = []
                this.isLoadingClients = true
                this.companyPhoneString = PHONE_REGEXP.test(this.searchInput) ? this.searchInput : null
                const promises = this.types.map(type => {
                    return async () => {
                        await Promise.allSettled([
                            (async () => {
                                const cancelTokenSource = this.$axios.CancelToken.source()
                                this.cancelTokenSources.push(cancelTokenSource)
                                const clients =
                                    this.companyPhoneString && this.phoneSearch
                                        ? (
                                              await this.$api.companies.get(
                                                  {
                                                      ...(type && {type}),
                                                      shortResponse: true,
                                                      phone: this.searchInput,
                                                      limit: 10,
                                                  },
                                                  cancelTokenSource.token
                                              )
                                          ).object
                                        : []
                                addClients(clients)
                            })(),
                            (async () => {
                                const cancelTokenSource = this.$axios.CancelToken.source()
                                this.cancelTokenSources.push(cancelTokenSource)
                                const clients = (
                                    await this.$api.companies.get(
                                        {
                                            ...(type && {type}),
                                            shortResponse: true,
                                            [this.mainSearchParam]: this.searchInput,
                                            limit: 10,
                                        },
                                        cancelTokenSource.token
                                    )
                                ).object
                                addClients(clients)
                            })(),
                        ])
                    }
                })
                await Promise.allSettled(promises.map(e => e()))
            } catch (e) {
                /* empty */
            } finally {
                this.isLoadingClients = false
            }
        }

        inputText(val) {
            const phone = val?.phones?.find(p => p.includes(this.companyPhoneString)) || null
            return val?.name + (phone ? ` (${phone})` : '') || null
        }
    }
</script>
