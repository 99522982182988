<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <package-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:sidebar>
            <selected-offers
                v-if="loaded"
                :direction="'column'"
                :changable="false"
                :bookable="false"
                :hotel="basketItem.hotel"
                :room="basketItem.room"
                :flight="basketItem.flight"
                :price="basketItem.price"
                :search-request="searchRequest"
                :total-order-price="basketItem.totalPrice"
                :current-price="basketItem.currentPrice"
            />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import SelectedOffers from '~/components/packages/search/SelectedOffers'
    import PackageBreadcrumbs from '~/components/packages/PackageBreadcrumbs'
    import {packagesStore} from '@/utils/store-accessor'
    import PackagePageMixin from '@/components/packages/mixins/PackagePageMixin'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'

    @Component({
        components: {
            ConfirmationPageLayout,
            ConfirmationStatus,
            PackageBreadcrumbs,
            SelectedOffers,
        },
        layout: 'blank',
    })
    export default class PackageConfirmationPage extends mixins(PackagePageMixin, ConfirmationPageMixin) {
        get basketItem() {
            return packagesStore.basketItem(packagesStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }
    }
</script>
