<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {carsRentRuntimeStore, carsRentStore} from '@/store'

    @Component
    export default class CarsStoreMixin extends Vue {
        get productStore() {
            return carsRentStore
        }

        get productRuntimeStore() {
            return carsRentRuntimeStore
        }

        get productType() {
            return 'carsRent'
        }
    }
</script>
