<template>
    <div>
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ $t(`filterTitles.${filter}`) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field
                        v-model="name"
                        outlined
                        hide-details
                        single-line
                        dense
                        :clearable="true"
                        :placeholder="placeholder"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Component, Vue, Prop, Model, Watch} from 'vue-property-decorator'

    @Component
    export default class NameFilter extends Vue {
        @Model() value
        @Prop({required: true}) filters
        @Prop() filter
        @Prop() placeholder

        name = null
        timer = null
        expanded = 0

        mounted() {
            this.name = this.value[this.filter]
        }

        @Watch('name')
        onNameChange() {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.change()
            }, 400)
        }

        change() {
            this.$emit('change', {key: this.filter, value: this.name})
        }

        get values() {
            return this.filters[this.filter]
        }

        get notMobile() {
            return !this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped>
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
    }
</style>
