<template>
    <v-form v-model="valid">
        <vc-date-picker
            v-if="startDate && endDate"
            v-model="range"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('daterange')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="formattedDate"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="firstUpdateDateFrom && firstUpdateDateTo"
            v-model="firstUpdateDateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('first_update_dates_range')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(firstUpdateDateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="lastUpdateDateFrom && lastUpdateDateTo"
            v-model="lastUpdateDateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('last_update_dates_range')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(lastUpdateDateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="bookingDateFrom && bookingDateTo"
            v-model="bookingDateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('reportParameterNames.bookingDate')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(bookingDateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="departureDateFrom && departureDateTo"
            v-model="departureDateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('reportParameterNames.departureDate')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(departureDateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="paymentPeriodDateFrom && paymentPeriodDateTo"
            v-model="paymentPeriodDateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="
                            reportId === 'Prestige_Customer_Statement_Report'
                                ? $t('reportParameterNames.balanceDuePeriod')
                                : $t('reportParameterNames.paymentPeriod')
                        "
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(paymentPeriodDateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="loginDateFrom && loginDateTo"
            v-model="loginDateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('daterange')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(loginDateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <vc-date-picker
            v-if="dateFrom && dateTo"
            v-model="dateRange"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('daterange')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="getFormattedRangeString(dateRange)"
                    />
                </div>
            </template>
        </vc-date-picker>
        <v-text-field
            v-if="email !== null"
            v-model="email"
            :label="$t('recipient_email')"
            :rules="[emailRule('recipient_email')]"
            outlined
        />
        <v-radio-group v-if="startDateType" v-model="startDateType" :column="false">
            <template v-slot:label>
                {{ $t('reportParameterNames.START_DATE_TYPE') }}
            </template>
            <v-radio class="mx-2" :label="$t('created')" value="Created" />
            <v-radio :label="$t('check_in')" value="CheckIn" />
        </v-radio-group>
        <v-select
            v-if="paymentMethod !== null && reportId !== 'Prestige_Customer_Statement_Report'"
            v-model="paymentMethod"
            :items="paymentMethods"
            item-text="name"
            item-value="name"
            outlined
            :loading="loading"
            :disabled="loading"
            clearable
            hide-details
            :label="$t('payment_method')"
            :placeholder="$t('payment_method')"
            class="mb-4"
        />
        <v-select
            v-if="paymentMethod !== null && reportId === 'Prestige_Customer_Statement_Report'"
            v-model="paymentMethod"
            :items="paymentSchemes"
            item-text="name"
            item-value="value"
            outlined
            multiple
            clearable
            hide-details
            :label="$t('reportParameterNames.paymentScheme')"
            :placeholder="$t('reportParameterNames.paymentScheme')"
            class="mb-4"
        />
        <company-autocomplete
            v-if="abtaNumber !== null"
            v-model="abtaNumber"
            :item-value="'externalCode'"
            :item-text="'externalCode'"
            :main-search-param="'externalCode'"
            disable-persistent-placeholder
            :types="['TOUR_AGENCY']"
            class="mb-4"
            :rules="[]"
            :label="$t('reportParameterNames.abtaNumber')"
        />
        <company-autocomplete
            v-if="travelAgencyName !== null"
            v-model="travelAgencyName"
            :item-value="'name'"
            :item-text="'name'"
            disable-persistent-placeholder
            :types="['TOUR_AGENCY']"
            class="mb-4"
            :rules="[]"
            :label="$t('reportParameterNames.travelAgencyName')"
        />
        <company-autocomplete
            v-if="agencyConsortium !== null"
            v-model="agencyConsortium"
            :main-search-param="'consortium'"
            :item-value="'consortium'"
            :item-text="'consortium'"
            disable-persistent-placeholder
            :types="['TOUR_AGENCY']"
            class="mb-4"
            :rules="[]"
            :label="$t('reportParameterNames.agencyConsortium')"
        />
        <company-autocomplete
            v-if="agencySubConsortium !== null"
            v-model="agencySubConsortium"
            :main-search-param="'subconsortium'"
            :item-value="'subconsortium'"
            :item-text="'subconsortium'"
            disable-persistent-placeholder
            :types="['TOUR_AGENCY']"
            class="mb-4"
            :rules="[]"
            :label="$t('reportParameterNames.agencySubConsortium')"
        />
        <company-autocomplete
            v-if="supplierName !== null"
            v-model="supplierName"
            :item-value="'name'"
            :item-text="'name'"
            disable-persistent-placeholder
            :types="['INTERNAL_SUPPLIER']"
            class="mb-4"
            :rules="[]"
            :label="$t('supplier_name')"
        />
        <company-autocomplete
            v-if="organizationId !== null"
            v-model="organizationId"
            :item-value="'id'"
            :item-text="'name'"
            disable-persistent-placeholder
            class="mb-4"
            :rules="[]"
            :label="$t('reportParameterNames.organisationName')"
        />
        <company-autocomplete
            v-if="companyId !== null"
            v-model="companyId"
            :item-value="'id'"
            :item-text="'name'"
            disable-persistent-placeholder
            class="mb-4"
            :rules="[]"
            :label="$t('recipient_organization')"
        />
        <persons-search-autocomplete
            v-if="userId !== null"
            :key="`user_${organizationId || authCompanyId}`"
            v-model="user"
            :company-id="organizationId || authCompanyId"
            outlined
            disable-persistent-placeholder
            class="mb-4"
            :show-icon="false"
            :rules="[]"
            label="user_name"
        />
        <private-client-autocomplete
            v-if="privateClientId !== null"
            v-model="privateClient"
            outlined
            :show-icon="false"
            disable-persistent-placeholder
            class="mb-4"
            :rules="[]"
            label="statement_of_account.client_types.pc"
        />
        <hotel-autocomplete
            v-if="hotelId !== null"
            v-model="hotel"
            label="reportParameterNames.hotel_name"
            outlined
            :single-line="false"
            :country-id="country"
            :city-id="cityId"
            :content-provider-id="1"
            class="mb-4"
            @change="onOwnHotelItemChange"
        />
        <v-select
            v-if="country !== null"
            v-model="country"
            :items="countries"
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
            :label="`${$t('country')}`"
            :placeholder="`${$t('country')}`"
            class="mb-4"
        />
        <v-select
            v-if="channelManagerCode !== null"
            v-model="channelManagerCode"
            :items="channelManagerItems"
            outlined
            clearable
            hide-details
            :label="`${$t('channel_manager_name')}`"
            :placeholder="`${$t('channel_manager_name')}`"
            class="mb-4"
            :loading="channelManagersLoading"
        />
        <city-autocomplete
            v-if="cityId !== null"
            v-model="city"
            label="city"
            outlined
            :single-line="false"
            :disabled="!country"
            :country-id="country"
            class="mb-4"
        />
        <v-select
            v-if="roomType"
            v-model="roomType"
            :items="roomTypes"
            outlined
            multiple
            :item-value="'name'"
            :item-text="'name'"
            clearable
            :disabled="!roomTypes || !roomTypes.length"
            hide-details
            :loading="roomTypesLoading"
            :label="`${$t('room_type')}`"
            class="mb-4"
        />
        <v-select
            v-if="carCategory"
            v-model="carCategory"
            :items="carCategories"
            outlined
            multiple
            :item-value="'name.en'"
            :item-text="'name.en'"
            clearable
            :disabled="!carCategories || !carCategories.length"
            hide-details
            :loading="carCategoriesLoading"
            :label="`${$t('reportParameterNames.carCategory')}`"
            class="mb-4"
        />
        <v-select
            v-if="b2cWebsiteId !== null"
            v-model="b2cWebsiteId"
            :items="b2cs"
            outlined
            item-value="b2cId"
            item-text="name"
            clearable
            :disabled="!b2cs || !b2cs.length"
            hide-details
            :loading="b2csLoading"
            :label="`${$t('reportParameterNames.b2c_website')}`"
            class="mb-4"
        />
        <v-select
            v-if="b2cId !== null"
            v-model="b2cId"
            :items="b2cs"
            outlined
            item-value="b2cId"
            item-text="name"
            clearable
            :disabled="!b2cs || !b2cs.length"
            hide-details
            :loading="b2csLoading"
            :label="`${$t('reportParameterNames.b2c_website')}`"
            class="mb-4"
        />
        <v-checkbox
            v-if="directClientsOnly !== null"
            v-model="directClientsOnly"
            :label="`${$t('reportParameterNames.directOnly')}`"
        />
        <v-radio-group
            v-if="dateType !== null && (dateType === 'Arrival' || dateType === 'Resident')"
            v-model="dateType"
            :column="false"
            hide-details
        >
            <template v-slot:label>
                {{ $t('reportParameterNames.DateType') }}
            </template>
            <v-radio class="mx-2" :label="$t('reportParameterNames.arrival')" value="Arrival" />
            <v-radio class="mr-2" :label="$t('reportParameterNames.resident')" value="Resident" />
        </v-radio-group>
        <hotel-autocomplete
            v-if="hotelIdChanelManager !== null"
            v-model="hotelChanelManager"
            label="sort.hotel_name"
            outlined
            :single-line="false"
            :country-id="country"
            :city-id="cityId"
            :content-provider-id="1"
            class="mb-4"
        />
        <hotel-autocomplete
            v-if="hotelName !== null"
            v-model="hotelNameItem"
            label="sort.hotel_name"
            outlined
            :single-line="false"
            class="mb-4"
        />
        <v-select
            v-if="currency"
            v-model="currency"
            :items="currencies"
            outlined
            clearable
            hide-details
            :label="`${$t('currency')}`"
            class="mb-4"
        />
        <date-picker
            v-if="exchangeRateDate"
            v-model="exchangeRateDate"
            :label="$t('reportParameterNames.DateForFixedDateRate')"
            :max="$dateFns.format(new Date())"
            outlined
            hide-details
        />
        <div class="radio-groups-alignment">
            <v-radio-group
                v-if="dateType && (dateType === 'Created' || dateType === 'CheckOut')"
                v-model="dateType"
                :column="false"
                hide-details
            >
                <template v-slot:label>
                    {{ $t('reportParameterNames.DateType') }}
                </template>
                <v-radio class="mx-2" :label="$t('reportParameterNames.reservationDate')" value="Created" />
                <v-radio class="mr-2" :label="$t('reportParameterNames.chekOutDate')" value="CheckOut" />
            </v-radio-group>
            <v-radio-group v-if="datePeriod" v-model="datePeriod" :column="false" hide-details>
                <template v-slot:label>
                    {{ $t('reportParameterNames.DatePeriod') }}
                </template>
                <v-radio class="mx-2" :label="$t('month')" value="Month" />
                <v-radio class="mr-2" :label="$t('quarter')" value="Quarter" />
                <v-radio class="mr-2" :label="$t('halfYear')" value="HalfYear" />
                <v-radio :label="$t('year')" value="Year" />
            </v-radio-group>
        </div>

        <v-select
            v-if="priceListType && !isAgency"
            v-model="priceListType"
            :items="priceListTypeItems"
            outlined
            hide-details
            :label="`${$t('price_list_type')}`"
            class="mb-4"
        />
        <v-select
            v-if="priceListType === 'CONTRACT_GROUP'"
            v-model="priceListTypeSecond"
            :items="contractGroups"
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
            :label="`${$t('priceListTypes.CONTRACT_GROUP')}`"
            class="mb-4"
        />
        <v-select
            v-if="priceListType === 'CLIENT' && !isAgency"
            v-model="priceListTypeSecond"
            :items="companies"
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
            :label="`${$t('priceListTypes.CLIENT')}`"
            class="mb-4"
        />
    </v-form>
</template>

<script>
    import {Component, Prop, PropSync, VModel} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'
    import HotelAutocomplete from '@/components/search/forms/HotelAutocomplete'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import CompanyAutocomplete from '@/components/account/modals/newOrderRequest/CompanyAutocomplete.vue'
    import {appInstance} from '@/utils/app-accessor'
    import PrivateClientAutocomplete from '@/components/search/forms/PrivateClientAutocomplete.vue'
    import PersonsSearchAutocomplete from '@/components/search/forms/PersonsSearchAutocomplete.vue'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin.vue'

    @Component({
        components: {
            PersonsSearchAutocomplete,
            PrivateClientAutocomplete,
            CompanyAutocomplete,
            HotelAutocomplete,
            CityAutocomplete,
            DatePicker,
        },
    })
    export default class ReportParametersForm extends FormValidationRulesMixin {
        @VModel() valid
        @PropSync('_params', {default: () => ({})}) params
        @Prop() reportId

        showExchangeRateDatePicker = false
        cityItem = null
        hotelItem = null
        contractGroups = []
        companies = []
        paymentMethods = []
        paymentSchemes = [
            {
                name: 'ACAS',
                value: ['ACAS', 'Bank Transfer Payment (ACAS)'],
            },
            {
                name: 'Bank Transfer Agent',
                value: ['Bank Transfer Payment (Agent)'],
            },
            {
                name: 'Card Agent',
                value: ['Pay By card (Agent)', 'Bank Transfer Payment (Card)'],
            },
            {
                name: 'Credit Agent',
                value: ['Bank Transfer Payment (Credit)'],
            },
            {
                name: 'Direct Sales',
                value: ['Pay By Card (Direct)', 'Bank Transfer Payment'],
            },
            {
                name: 'SPS',
                value: ['SPS', 'Bank Transfer Payment (SPS)'],
            },
            {
                name: 'TAPS',
                value: ['TAPS', 'Bank Transfer Payment (TAPS)'],
            },
        ]
        loading = false
        roomTypes = []
        roomTypesLoading = false
        carCategories = []
        b2cs = []
        carCategoriesLoading = false
        b2csLoading = false
        privateClientItem = null
        userItem = null
        channelManagerItems = []
        channelManagersLoading = false

        async mounted() {
            try {
                //TODO backend mistake, 2 dateType fields in Summary Report for accommodation and Stop Sales Report for Prestige
                if (this.dateType !== null && this.dateType !== 'Created') {
                    this.dateType = 'Arrival'
                }

                this.loading = true
                const loadContractGroups = async () => {
                    this.contractGroups = (await this.$api.contractGroups.get()).contractGroups
                }
                const loadCompanies = async () => {
                    this.companies = (
                        await this.$api.companies.get({relationship: 'CLIENT', shortResponse: true})
                    ).object
                }
                const loadPaymentMethods = async () => {
                    this.paymentMethods = await this.$api.paymentMethods.get({'is active': true})
                }
                const loadCarCategories = async () => {
                    this.carCategoriesLoading = true
                    this.carCategories = (await this.$api.ownCarRentContracts.get()).ownProductContracts
                    this.carCategoriesLoading = false
                }

                const loadb2cs = async () => {
                    try {
                        this.b2csLoading = true
                        this.b2cs = (await this.$api.b2c.get()).b2cs
                    } catch (e) {
                        this.b2cs = []
                    } finally {
                        this.b2csLoading = false
                    }
                }
                const promises = [loadContractGroups(), loadCompanies()]
                if (this.paymentMethod !== null) {
                    promises.push(loadPaymentMethods())
                }
                if (this.carCategory) {
                    promises.push(loadCarCategories())
                }
                if (this.b2cWebsiteId !== null || this.b2cId !== null) {
                    promises.push(loadb2cs())
                }
                if (this.channelManagerCode !== null) {
                    const channelManagers = async () => {
                        try {
                            this.channelManagersLoading = true
                            this.channelManagerItems = (await this.$api.channelManagers.get()).channelManagers.map(
                                ({code, name}) => ({value: code, text: name})
                            )
                        } catch (e) {
                            this.channelManagerItems = []
                        } finally {
                            this.channelManagersLoading = false
                        }
                    }
                    promises.push(channelManagers())
                }

                await Promise.all(promises)

                if (this.priceListType && this.isAgency) {
                    this.priceListType = 'CLIENT'
                    this.priceListTypeSecond = this.companies[0].id
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        get priceListTypeItems() {
            const priceListTypeItems = [
                {
                    value: 'NETTO',
                    text: this.$t('priceListTypes.NETTO'),
                },
                {
                    value: 'DIRECT_SALES',
                    text: this.$t('priceListTypes.DIRECT_SALES'),
                },
                {
                    value: 'CONTRACT_GROUP',
                    text: this.$t('priceListTypes.CONTRACT_GROUP'),
                },
                {
                    value: 'CLIENT',
                    text: this.$t('priceListTypes.CLIENT'),
                },
            ]
            if (authStore.isTO2) {
                priceListTypeItems.splice(0, 1)
            }
            return priceListTypeItems
        }

        get countries() {
            return runtimeStore.countries
        }

        get currencies() {
            return Object.values(this.$config.currency.available).map(currency => ({
                text: currency,
                value: currency,
            }))
        }

        get range() {
            return {
                start: this.startDate,
                end: this.endDate,
            }
        }

        set range(val) {
            if (!val) return
            this.setParam('DateFrom', `${val.start} 00:00`)
            this.setParam('DateTill', `${val.end} 23:59`)
        }

        get startDate() {
            return this.getParam('DateFrom')
        }

        get endDate() {
            return this.getParam('DateTill')
        }

        get firstUpdateDateFrom() {
            return this.getParam('FirstUpdateDateFrom')
        }

        get firstUpdateDateTo() {
            return this.getParam('FirstUpdateDateTo')
        }

        get firstUpdateDateRange() {
            return {
                start: this.firstUpdateDateFrom,
                end: this.firstUpdateDateTo,
            }
        }

        set firstUpdateDateRange(val) {
            if (!val) return
            this.setParam('FirstUpdateDateFrom', `${val.start} 00:00`)
            this.setParam('FirstUpdateDateTo', `${val.end} 23:59`)
        }

        get lastUpdateDateFrom() {
            return this.getParam('LastUpdateDateFrom')
        }

        get lastUpdateDateTo() {
            return this.getParam('LastUpdateDateTo')
        }

        get lastUpdateDateRange() {
            return {
                start: this.lastUpdateDateFrom,
                end: this.lastUpdateDateTo,
            }
        }

        set lastUpdateDateRange(val) {
            if (!val) return
            this.setParam('LastUpdateDateFrom', `${val.start} 00:00`)
            this.setParam('LastUpdateDateTo', `${val.end} 23:59`)
        }

        get channelManagerCode() {
            return this.getParam('ChannelManagerCode')
        }

        set channelManagerCode(val) {
            this.setParam('ChannelManagerCode', val)
        }

        get bookingDateRange() {
            return {
                start: `${this.bookingDateFrom}`,
                end: `${this.bookingDateTo}`,
            }
        }

        set bookingDateRange(val) {
            if (!val) return
            this.setParam('BookingDateFrom', `${val.start} 00:00`)
            this.setParam('BookingDateTo', `${val.end} 23:59`)
        }

        get bookingDateFrom() {
            return this.getParam('BookingDateFrom')
        }

        get bookingDateTo() {
            return this.getParam('BookingDateTo')
        }

        get departureDateRange() {
            return {
                start: `${this.departureDateFrom}`,
                end: `${this.departureDateTo}`,
            }
        }

        set departureDateRange(val) {
            if (!val) return
            this.setParam('DepartureDateFrom', `${val.start} 00:00`)
            this.setParam('DepartureDateTo', `${val.end} 23:59`)
        }

        get departureDateFrom() {
            return this.getParam('DepartureDateFrom')
        }

        get departureDateTo() {
            return this.getParam('DepartureDateTo')
        }

        get paymentPeriodDateRange() {
            return {
                start: `${this.paymentPeriodDateFrom}`,
                end: `${this.paymentPeriodDateTo}`,
            }
        }

        set paymentPeriodDateRange(val) {
            if (!val) return
            this.setParam('PaymentPeriodDateFrom', `${val.start} 00:00`)
            this.setParam('PaymentPeriodDateTo', `${val.end} 23:59`)
        }

        get paymentPeriodDateFrom() {
            return this.getParam('PaymentPeriodDateFrom')
        }

        get paymentPeriodDateTo() {
            return this.getParam('PaymentPeriodDateTo')
        }

        get loginDateFrom() {
            return this.getParam('LoginDateFrom')
        }

        get loginDateTo() {
            return this.getParam('LoginDateTo')
        }

        get loginDateRange() {
            return {
                start: this.loginDateFrom,
                end: this.loginDateTo,
            }
        }

        set loginDateRange(val) {
            if (!val) return
            this.setParam('LoginDateFrom', `${val.start} 00:00`)
            this.setParam('LoginDateTo', `${val.end} 23:59`)
        }

        get dateFrom() {
            return this.getParam('DateFrom')
        }

        get dateTo() {
            return this.getParam('DateTo')
        }

        get dateRange() {
            return {
                start: this.dateFrom,
                end: this.dateTo,
            }
        }

        set dateRange(val) {
            if (!val) return
            this.setParam('DateFrom', `${val.start} 00:00`)
            this.setParam('DateTo', `${val.end} 23:59`)
        }

        set organizationId(val) {
            this.setParam('OrganizationId', val)
        }

        get organizationId() {
            return this.getParam('OrganizationId')
        }

        get companyId() {
            return this.getParam('CompanyId')
        }

        set companyId(val) {
            this.setParam('CompanyId', val ? +val : val)
        }

        get privateClientId() {
            return this.getParam('PrivateClientId')
        }

        get privateClient() {
            return this.privateClientItem
        }

        set privateClient(val) {
            this.privateClientItem = val
            this.setParam('PrivateClientId', !val ? '' : val.personId)
        }

        get userId() {
            return this.getParam('UserId')
        }

        get user() {
            return this.userItem
        }

        set user(val) {
            this.userItem = val
            this.setParam('UserId', !val ? '' : val.personId)
        }

        get country() {
            return this.getParam('Country')
        }

        set country(val) {
            this.setParam('Country', !val ? '' : val)
            this.city = null
            this.hotel = null
        }

        get cityId() {
            return this.getParam('City')
        }

        get city() {
            return this.cityItem
        }

        set city(val) {
            this.cityItem = val
            this.setParam('City', !val ? '' : val.id)
            this.hotel = null
        }

        get hotelId() {
            return this.getParam('OwnHotelSuggestion')
        }

        get hotel() {
            return this.hotelItem
        }

        set hotel(val) {
            this.hotelItem = val
            this.setParam('OwnHotelSuggestion', !val ? '' : val.id)
        }

        get hotelIdChanelManager() {
            return this.getParam('HotelId')
        }

        get hotelChanelManager() {
            return this.hotelItem
        }

        set hotelChanelManager(val) {
            this.hotelItem = val
            this.setParam('HotelId', !val ? '' : val.id)
        }

        get hotelName() {
            return this.getParam('HotelName')
        }

        get hotelNameItem() {
            return this.hotelItem
        }

        set hotelNameItem(val) {
            this.hotelItem = val
            this.setParam('HotelName', val.name)
        }

        get startDateType() {
            return this.getParam('START_DATE_TYPE')
        }

        set startDateType(val) {
            this.setParam('START_DATE_TYPE', val)
        }

        get currency() {
            return this.getParam('Currency')
        }

        set paymentMethod(val) {
            this.setParam('PaymentMethod', val)
        }

        get paymentMethod() {
            return this.getParam('PaymentMethod')
        }

        set abtaNumber(val) {
            this.setParam('ABTANumber', val)
        }

        get abtaNumber() {
            return this.getParam('ABTANumber')
        }

        set travelAgencyName(val) {
            this.setParam('TravelAgencyName', val)
        }

        get travelAgencyName() {
            return this.getParam('TravelAgencyName')
        }

        set agencyConsortium(val) {
            this.setParam('AgencyConsortium', val)
        }

        get agencyConsortium() {
            return this.getParam('AgencyConsortium')
        }

        set agencySubConsortium(val) {
            this.setParam('AgencySubConsortium', val)
        }

        get agencySubConsortium() {
            return this.getParam('AgencySubConsortium')
        }

        set directClientsOnly(val) {
            this.setParam('DirectClientsOnly', val)
        }

        get directClientsOnly() {
            return this.getParam('DirectClientsOnly')
        }

        set supplierName(val) {
            this.setParam('SupplierName', val)
        }

        get supplierName() {
            return this.getParam('SupplierName')
        }

        async onOwnHotelItemChange(val) {
            if (this.roomType) {
                if (val && val.id) {
                    await this.loadRoomTypes(val.id)
                } else {
                    this.roomTypes = []
                    this.roomType = []
                }
            }
        }

        async loadRoomTypes(hotelId) {
            try {
                this.roomTypesLoading = true

                const contracts = (await appInstance.$api.contracts.get({hotelId})).contracts
                this.roomTypes = contracts[0].contractDetails?.ownHotelRooms
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.roomTypesLoading = false
            }
        }

        set roomType(val) {
            this.setParam('RoomType', val)
        }

        get roomType() {
            return this.getParam('RoomType')
        }

        set carCategory(val) {
            this.setParam('CarCategory', val)
        }

        get carCategory() {
            return this.getParam('CarCategory')
        }

        set b2cWebsiteId(val) {
            this.setParam('B2CWebsiteId', val ? +val : val)
        }

        get b2cWebsiteId() {
            return this.getParam('B2CWebsiteId')
        }

        get b2cId() {
            return this.getParam('B2CId')
        }

        set b2cId(val) {
            this.setParam('B2CId', val ? +val : val)
        }

        set currency(val) {
            this.setParam('Currency', val)
        }

        get exchangeRateDate() {
            return this.getParam('DateForFixedDateRate')
        }

        set exchangeRateDate(val) {
            this.setParam('DateForFixedDateRate', `${val} 23:59`)
        }

        get datePeriod() {
            return this.getParam('DatePeriod')
        }

        get dateType() {
            return this.getParam('DateType')
        }

        set dateType(val) {
            this.setParam('DateType', val)
        }

        set datePeriod(val) {
            this.setParam('DatePeriod', val)
        }

        get priceListType() {
            const priceListType = this.getParam('PriceListType')
            return priceListType ? priceListType.first : null
        }

        set priceListType(first) {
            this.setParam('PriceListType', {first})
        }

        get priceListTypeSecond() {
            return this.getParam('PriceListType').second
        }

        set priceListTypeSecond(second) {
            this.setParam('PriceListType', {first: this.priceListType, second})
        }

        get formattedDate() {
            return this.range.start !== null && this.range.end !== null
                ? `${this.$options.filters.dateFormat(this.range.start)} - ${this.$options.filters.dateFormat(
                      this.range.end
                  )}`
                : ''
        }

        get email() {
            return this.getParam('Email')
        }

        set email(val) {
            this.setParam('Email', val)
        }

        getFormattedRangeString(range) {
            return range.start !== null && range.end !== null
                ? `${this.$options.filters.dateFormat(range.start)} - ${this.$options.filters.dateFormat(range.end)}`
                : ''
        }

        getParam(paramName) {
            const param = this.params.find(param => param.name === paramName)
            return param ? param.value : null
        }

        setParam(paramName, value) {
            const param = this.params.find(param => param.name === paramName)

            if (!param) return
            if (value !== null && value !== undefined) {
                this.$set(param, 'value', value)
            } else {
                this.$set(param, 'value', param.multiselect ? [] : '')
            }
        }

        get isAgency() {
            return authStore.isAgency
        }

        get authCompanyId() {
            return authStore.company?.id
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        .radio-groups-alignment * {
            legend {
                flex: 0 0 80px;
            }
        }
    }
</style>
