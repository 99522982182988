<template>
    <v-card>
        <v-card-text>
            <v-form>
                <v-row>
                    <v-col cols="12" md="4">
                        <daterange
                            :start-date.sync="model.dateFrom"
                            :end-date.sync="model.dateTo"
                            :popover="{placement: 'bottom'}"
                            :show-icon="false"
                            :single-line="false"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <days-picker :_day-of-weeks.sync="model.dayOfWeek" />
                    </v-col>
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="12" md="12">
                        <h2 class="mb-4">
                            {{ $t('supplier.availability_title') }}
                        </h2>
                        <div class="d-flex grey lighten-2 py-4 px-6 justify-space-between">
                            <v-row class="align-center">
                                <v-col cols="12" md="9">
                                    <h3>
                                        {{ $t('supplier.apply_to_all_rooms') }}
                                    </h3>
                                </v-col>
                                <v-col cols="12" md="3" class="d-flex">
                                    <v-text-field
                                        v-model="quantity"
                                        type="number"
                                        hide-details
                                        :label="$t('supplier.quantity')"
                                        class="mr-2"
                                    />
                                    <v-btn color="primary" @click="updateQuantity">
                                        {{ $t('supplier.apply_to_all') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <v-expansion-panels v-if="model.services.length">
                            <v-expansion-panel v-for="(service, index) in services" :key="service.id">
                                <v-expansion-panel-header class="grey lighten-4">
                                    <h3 class="grey--text text--darken-1">
                                        {{ service.name }}
                                    </h3>
                                    <template v-slot:actions>
                                        <div class="flex-column">
                                            <v-text-field
                                                v-model="model.services[index].amount"
                                                type="number"
                                                hide-details
                                                placeholder=""
                                                :label="$t('supplier.quantity')"
                                            />
                                            <div class="primary--text text-right mt-4">
                                                {{ $t('supplier.inventory_overview') }}
                                                <v-icon color="primary">$expand</v-icon>
                                            </div>
                                        </div>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-sheet height="64">
                                        <v-toolbar flat>
                                            <v-btn fab text small color="grey darken-2" @click="prev">
                                                <v-icon small>mdi-chevron-left</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>
                                                {{ startDate | dateShortFormat }} - {{ endDate | dateShortFormat }}
                                            </v-toolbar-title>
                                            <v-btn fab text small color="grey darken-2" @click="next">
                                                <v-icon small>mdi-chevron-right</v-icon>
                                            </v-btn>
                                            <v-spacer />
                                            <div>
                                                {{ $t('supplier.rooms_to_sell') }}
                                                <v-icon class="green--text">mdi-checkbox-blank-circle</v-icon>
                                            </div>
                                        </v-toolbar>
                                    </v-sheet>
                                    <v-sheet height="600">
                                        <v-calendar
                                            ref="calendar"
                                            :start="startDate"
                                            :end="endDate"
                                            type="month"
                                            :events="events(service)"
                                            event-overlap-mode="stack"
                                            :event-color="getEventColor"
                                        />
                                    </v-sheet>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-btn color="primary" class="mt-6" @click="$emit('update', model)">
                            {{ $t('supplier.update_rooms_for_sale') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import Daterange from '@/components/supplier/DateRange'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import DaysPicker from '@/components/supplier/DaysPicker'

    @Component({
        components: {DaysPicker, Daterange},
    })
    export default class MassEditPrices extends Mixins(FormValidationRulesMixin) {
        @Prop() startDate
        @Prop() endDate
        @Prop() services

        model = {
            dateFrom: null,
            dateTo: null,
            dayOfWeek: [true, true, true, true, true, true, true],
            services: [],
            operation: 'SET',
        }
        quantity = null

        mounted() {
            this.$set(this.model, 'dateFrom', this.startDate)
            this.$set(this.model, 'dateTo', this.endDate)

            let services = []
            this.services.forEach(service => {
                services.push({
                    serviceId: service.id,
                    categoryId: service.categoryId,
                    amount: null,
                })
            })
            this.$set(this.model, 'services', services)
        }

        updateQuantity() {
            this.model.services.forEach(service => {
                service.amount = this.quantity
            })
        }

        events(service) {
            const available = service.rates.map(rate => {
                return {
                    name: rate.service.availableQuantity.toString(),
                    start: rate.date,
                    end: rate.date,
                    timed: false,
                    color: 'green',
                }
            })

            return available
        }

        getEventColor(event) {
            return event.color
        }

        prev() {
            this.$refs.calendar.prev()
        }

        next() {
            this.$refs.calendar.next()
        }
    }
</script>

<style scoped lang="scss"></style>
