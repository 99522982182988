<template>
    <v-card elevation="0">
        <div v-if="fullCard" class="mb-5">
            <v-card-title class="title font-weight-light align-baseline">
                {{ $t('available_offers') }}
                <span class="ml-4 text-body-2 primary--text cursor-pointer" @click="expanded = !expanded">
                    {{ $t('filters') }}
                    <v-icon color="primary" small>
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </span>
            </v-card-title>
            <v-expand-transition v-model="expanded">
                <v-row v-if="expanded" no-gutters>
                    <v-col cols="12" md="4" class="mr-0 mr-md-3">
                        <sort-select v-model="sortKey" :items="sortItems" />
                    </v-col>
                    <v-col cols="12" md="3" class="mr-0 mr-md-3">
                        <group-offers-select
                            class="pt-3"
                            :items="['none', 'serviceName']"
                            :product-store="productStore"
                        />
                    </v-col>
                    <v-col cols="12" md="3" class="pt-md-3">
                        <activity-date-filter v-model="dateFilter" :offers="offers" />
                    </v-col>
                </v-row>
            </v-expand-transition>
        </div>
        <v-expansion-panels v-if="groupOffersBy !== 'none'" v-model="panels" multiple flat focusable accordion>
            <v-expansion-panel v-for="(offers, groupIndex) in groupedOffers" :key="`group-${groupIndex}`">
                <v-expansion-panel-header
                    class="text-nobreak font-weight-bold"
                    :class="$breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                    color="blue-grey lighten-5"
                >
                    <div>
                        {{ offers[0][groupOffersBy] }}
                    </div>
                    <div class="d-flex align-center justify-space-between font-weight-bold text-right pr-4">
                        <v-chip
                            label
                            x-small
                            :color="minPriceOffer(offers).available ? 'green darken-1' : 'orange accent-3'"
                            class="flex-grow-0 ml-6 white--text"
                        >
                            {{ minPriceOffer(offers).available ? $t('available') : $t('on_request') }}
                        </v-chip>
                        <template v-if="minPriceOffer(offers).price && !hidePrice">
                            {{ $t('from') }} {{ minPriceOffer(offers).price | price }}
                        </template>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <activity-offer-card
                        v-for="(offer, offerIndex) in offers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="fullCard"
                        :grouped-by="groupOffersBy"
                        :data-cy="`activity-offer-card-${offerIndex}`"
                        @images="$emit('images', $event)"
                        @info="$emit('info', $event)"
                    >
                        <template v-slot:price>
                            <slot name="price" :offer="offer" :offer-key="offer.offerKey" />
                        </template>
                    </activity-offer-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import ActivityOfferCard from '~src/components/activities/info/activityOfferCard.src'
    import {activitiesStore} from '@/utils/store-accessor'
    import SortSelect from '@/components/search/SortSelect'
    import GroupOffersSelect from '@/components/search/GroupOffersSelect'
    import ActivityDateFilter from '@/components/activities/snippets/ActivityDateFilter'

    @Component({
        components: {
            ActivityOfferCard,
            SortSelect,
            GroupOffersSelect,
            ActivityDateFilter,
        },
    })
    export default class ActivityGroupedOffers extends Vue {
        @Prop({default: () => []}) offers
        @Prop({default: false, type: Boolean}) fullCard
        @Prop() dateFilter
        @Prop({default: false, type: Boolean}) hidePrice

        panels = [0]
        expanded = false

        @Watch('groupOffersBy')
        onGroupOffersByChange() {
            this.panels = [0]
        }

        minPriceOffer(offers) {
            return offers.reduce((a, b) => (a.price.amount < b.price.amount ? a : b), offers[0])
        }

        get groupedOffers() {
            const sortFns = {
                priceAsc: (a, b) => a.price.amount - b.price.amount,
                priceDesc: (a, b) => b.price.amount - a.price.amount,
            }
            let offers = [...this.offers]
            if (this.dateFilter) {
                offers = offers.filter(offer => offer.date === this.dateFilter)
            }
            offers.sort(sortFns[this.sortKey])
            return offers.reduce((groupedOffers, offer) => {
                let group = groupedOffers.find(group => group[0][this.groupOffersBy] === offer[this.groupOffersBy])
                if (!group) {
                    group = []
                    groupedOffers.push(group)
                }
                group.push(offer)
                return groupedOffers
            }, [])
        }

        get sortKey() {
            return activitiesStore.sortFnName
        }

        set sortKey(sortKey) {
            activitiesStore.SET_SORT(sortKey)
        }

        get productStore() {
            return activitiesStore
        }

        get groupOffersBy() {
            return this.productStore.groupOffersBy
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
            ]
        }
    }
</script>

<style scoped>
    >>> .v-expansion-panel-content__wrap {
        padding-left: 0;
        padding-right: 0;
    }
</style>
