<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card class="d-flex flex-column">
            <v-toolbar dark color="primary" class="flex-grow-0">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('email_form.text') }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn dark text @click="save">{{ $t('save') }}</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="d-flex flex-grow-1 wrapper">
                <email-editor ref="editor" class="flex-grow-1" @load="editorLoaded" />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {EmailEditor} from 'vue-email-editor'

    @Component({
        components: {
            EmailEditor,
        },
    })
    export default class EmailEditModal extends Vue {
        @VModel() dialog
        @Prop({required: true}) json

        design = null

        @Emit()
        change(html) {
            return html
        }

        save() {
            this.$refs.editor.exportHtml(({html, design}) => {
                this.design = design
                this.dialog = false
                this.change(html)
            })
        }

        beforeDestroy() {
            this.$refs.editor = null
        }

        editorLoaded() {
            this.$refs.editor.loadDesign(this.design || JSON.parse(this.json))
        }
    }
</script>

<style scoped lang="scss">
    .wrapper {
        line-height: 0;
    }
</style>
