<template>
    <search-summary-layout
        :search-disabled="searchDisabled"
        :search-request="searchRequest"
        form-name="insurance-search-form"
        tourists-label="guest"
        show-dates
        show-days-count
        show-tourists
    >
        <template v-slot:title>
            {{ locationLabel }}
        </template>
    </search-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {insuranceRuntimeStore} from '@/store'
    import SearchSummaryLayout from '~src/components/parts/searchSummaryLayout.src'

    @Component({
        components: {
            SearchSummaryLayout,
        },
    })
    export default class InsuranceSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest

        get locationLabel() {
            return `${insuranceRuntimeStore.location.name}`
        }
    }
</script>
