<template>
    <div v-if="product.info.operatedBy && product.info.supplierCode === 'jonview'" class="text-caption text--secondary">
        {{ $t('operated_by') }}: {{ product.info.operatedBy }}
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class TransferOperatedByLabel extends Vue {
        @Prop({required: true, type: Object}) product
    }
</script>
