<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import InsuranceStoreMixin from '@/mixins/InsuranceStoreMixin'

    @Component
    export default class InsuranceBookingPageLayout extends mixins(BookingPageBase, InsuranceStoreMixin) {
        initTourists(sr, tourists) {
            const touristsList = BookingPageBase.options.methods.initTourists.call(this, sr, tourists)
            touristsList.forEach(tourist => {
                tourist.address = null
                tourist.passportNumber = null
            })

            return touristsList
        }

        get productSearchPageName() {
            return 'insuranceSearch'
        }

        get productConfirmationPageName() {
            return 'insuranceConfirmation'
        }

        get productName() {
            return 'insurance'
        }

        get productBookingPageName() {
            return 'insuranceBooking'
        }

        get corporatePolicyType() {
            return null
        }

        get touristToken() {
            return 'tourist'
        }
    }
</script>
