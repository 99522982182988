<template>
    <div>
        <v-card v-if="!isLoading" :elevation="elevation" class="pa-4 text-body-2">
            <v-row>
                <v-col cols="12" md="5" align-self="center">{{ `${$t('quantity_of_travelers')}:` }}</v-col>
                <v-col cols="6" md="2">
                    <v-text-field
                        v-model="calculationsAndSettings.travelersQuantity"
                        type="number"
                        min="0"
                        max="99"
                        dense
                        hide-details
                        single-line
                        class="ma-0 mr-4 pa-0 input--no-arrows"
                    />
                </v-col>
                <v-col cols="6" md="5">
                    <v-checkbox v-model="calculationsAndSettings.travelersQuantityVisible" dense hide-details>
                        <template v-slot:label>
                            <span class="text-body-2">
                                {{ $t('visible_for_client') }}
                            </span>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="5" align-self="center">{{ `${$t('quantity_free_of_charge')}:` }}</v-col>
                <v-col cols="6" md="2">
                    <v-text-field
                        v-model="calculationsAndSettings.focQuantity"
                        type="number"
                        min="0"
                        max="99"
                        dense
                        hide-details
                        single-line
                        class="ma-0 mr-4 pa-0 input--no-arrows"
                    />
                </v-col>
                <v-col cols="6" md="5">
                    <v-checkbox v-model="calculationsAndSettings.focQuantityVisible" dense hide-details>
                        <template v-slot:label>
                            <span class="text-body-2">
                                {{ $t('visible_for_client') }}
                            </span>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="5" align-self="center">{{ `${$t('price_per_person')}:` }}</v-col>
                <v-col cols="6" md="7">
                    {{ calculationsAndSettings.pricePerPerson | priceFormat(calculationsAndSettings.currency) }}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pb-0">{{ $t('show_prices_for_client') }}</v-col>
                <v-col cols="12" class="py-0">
                    <v-radio-group v-model="calculationsAndSettings.clientPriceType" class="mt-0">
                        <v-radio value="FOR_ALL_SERVICES">
                            <template v-slot:label>
                                <span class="text-body-2">
                                    {{ $t('for_all_services_within_trip') }}
                                </span>
                            </template>
                        </v-radio>
                        <v-radio value="ONLY_TOTAL">
                            <template v-slot:label>
                                <span class="text-body-2">
                                    {{ $t('only_total') }}
                                </span>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pb-0">{{ $t('show_cancellation_policy_for_client') }}</v-col>
                <v-col cols="12" class="py-0">
                    <v-radio-group v-model="calculationsAndSettings.cancellationChargeType" class="mt-0">
                        <v-radio value="FOR_ALL_SERVICES">
                            <template v-slot:label>
                                <span class="text-body-2">
                                    {{ $t('for_all_services_within_trip') }}
                                </span>
                            </template>
                        </v-radio>
                        <v-radio value="ONLY_TOTAL">
                            <template v-slot:label>
                                <span class="text-body-2">
                                    {{ $t('only_total') }}
                                </span>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" :md="calculationsAndSettings.cancelPenalties.length ? 12 : 9">
                    <v-btn color="primary" depressed text @click="addCancelPenalties">
                        {{ $t('add_cancel_penalties') }}
                    </v-btn>
                    <div v-for="(item, index) in calculationsAndSettings.cancelPenalties" :key="index">
                        <v-row>
                            <v-col cols="12" md="8">
                                <order-service-daterange
                                    :_start-date.sync="item.dateFrom"
                                    :_end-date.sync="item.dateTo"
                                    :start-date-label="$t('date_from')"
                                    :end-date-label="$t('date_to')"
                                    :outlined="false"
                                    :single-line="false"
                                    :no-title="true"
                                    dense
                                />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field
                                    v-model="item.amount"
                                    :label="$t('calculation_amount')"
                                    type="number"
                                    min="0"
                                    dense
                                    hide-details
                                    class="input--no-arrows"
                                />
                            </v-col>
                            <v-col cols="1" align-self="center">
                                <v-btn color="error" depressed icon small @click="deleteCancelPenalties(index)">
                                    <v-icon color="error" small>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    :md="calculationsAndSettings.cancelPenalties.length ? 12 : 3"
                    :class="{'text-right': !$breakpoint.smAndDown}"
                    align-self="end"
                >
                    <v-btn
                        :loading="isSaving"
                        :disabled="isSaving"
                        :block="$breakpoint.smAndDown"
                        color="primary"
                        depressed
                        @click="save"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-else :elevation="elevation">
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="primary" class="mb-0" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import OrderServiceDaterange from '~/components/account/filters/OrderServiceDaterange'

    @Component({
        components: {OrderServiceDaterange},
    })
    export default class OrderCalculationsAndSettings extends Vue {
        @Prop() orderId
        @Prop() elevation

        isLoading = false
        isSaving = false
        calculationsAndSettings = {
            travelersQuantity: 0,
            travelersQuantityVisible: false,
            focQuantity: 0,
            focQuantityVisible: false,
            clientPriceType: '',
            cancellationChargeType: '',
            cancelPenalties: [],
        }

        async mounted() {
            try {
                this.isLoading = true
                this.calculationsAndSettings = await this.$api.orderCalculator.get({orderId: this.orderId})
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        async save() {
            try {
                this.isSaving = true
                const request = {
                    travelersQuantity: this.calculationsAndSettings.travelersQuantity,
                    travelersQuantityVisible: this.calculationsAndSettings.travelersQuantityVisible,
                    focQuantity: this.calculationsAndSettings.focQuantity,
                    focQuantityVisible: this.calculationsAndSettings.focQuantityVisible,
                    clientPriceType: this.calculationsAndSettings.clientPriceType,
                    cancellationChargeType: this.calculationsAndSettings.cancellationChargeType,
                    cancelPenalties: this.calculationsAndSettings.cancelPenalties,
                }

                this.calculationsAndSettings = await this.$api.orderCalculator.put(this.orderId, request)
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isSaving = false
            }
        }

        addCancelPenalties() {
            this.calculationsAndSettings.cancelPenalties.push({
                dateFrom: null,
                dateTo: null,
                amount: 0,
            })
        }

        deleteCancelPenalties(i) {
            this.calculationsAndSettings.cancelPenalties.splice(i, 1)
        }
    }
</script>
