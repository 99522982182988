<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class CruisePortsSearchMixin extends Vue {
        async searchCruisePorts(rq) {
            const defaults = {
                pattern: null,
                limit: 10,
            }

            //TODO Fix limit in API
            const rs = await this.$api.cruisePorts.get({...defaults, ...rq})

            return rs.map(port => {
                return Object.assign(port, {type: 'cruisePort', parentName: port.countryName})
            })
        }
    }
</script>
