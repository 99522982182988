<template>
    <v-form ref="form" v-model="valid" :disabled="disabled">
        <div>
            <span class="text-h6 text-capitalize">
                {{ `${$t(`transfers.${pointType === 'departure' ? 'from' : 'to'}`)}: ` }}
            </span>
            <v-icon>mdi-airplane-takeoff</v-icon>
            <span class="text-subtitle-2">
                {{ formTitle }}
            </span>
        </div>
        <v-row>
            <v-col v-if="!isProductFieldDisabled('POINT_FLIGHT_NUMBER')" cols="12" sm="4" md="3" lg="2">
                <v-text-field
                    v-model="flightNumber"
                    :rules="flightNumberRules"
                    :label="productFieldLabel('transfers.flight_number', 'POINT_FLIGHT_NUMBER')"
                />
            </v-col>
            <v-col v-if="!isProductFieldDisabled('POINT_AIRPORT')" cols="12" sm="8" md="6">
                <airports-search-input
                    v-model="otherAirportCode"
                    :label="
                        pointType === 'arrival'
                            ? productFieldLabel('transfers.departure_to_airport', 'POINT_AIRPORT')
                            : productFieldLabel('transfers.arriving_from_airport', 'POINT_AIRPORT')
                    "
                    :rules="airportRules"
                />
            </v-col>
            <template v-if="!isProductFieldDisabled('POINT_FLIGHT_TIME')">
                <v-col cols="6" sm="4" md="3" lg="2">
                    <date-picker
                        v-model="date"
                        :label="productFieldLabel('date', 'POINT_FLIGHT_TIME')"
                        :rules="flightDateRules"
                        :min="minDate"
                        :max="maxDate"
                    />
                </v-col>
                <v-col v-if="!isProductFieldDisabled('POINT_FLIGHT_TIME')" cols="6" sm="4" md="3" lg="2" xl="1">
                    <time-field
                        v-model="time"
                        :label="productFieldLabel('time', 'POINT_FLIGHT_TIME')"
                        :rules="flightTimeRules"
                    />
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync} from 'nuxt-property-decorator'
    import {transfersRuntimeStore} from '@/utils/store-accessor'
    import AirportsSearchInput from '~/components/snippets/AirportsSearchInput'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferFormMixin from '@/components/transfers/mixins/TransferFormMixin'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {TimeField, AirportsSearchInput, DatePicker},
    })
    export default class TransferAirportForm extends mixins(
        TransferFormMixin,
        ProductBookingFieldsMixin,
        FormValidationRulesMixin
    ) {
        @Prop() pointType
        @Prop() disabled
        @Prop() product
        @Prop() searchRequest
        @Prop() flightService
        @PropSync('_valid', {default: false}) valid
        @Prop() returnTransferOffer

        mounted() {
            this.initData()
        }

        initData() {
            const date = this.$dateFns.parseISO(this.date)
            if (this.returnTransferOffer) {
                this.setProp('dateTime', this.returnTransferOffer.date + 'T00:00')
            } else if (
                !this.date ||
                this.$dateFns.isAfter(date, this.$dateFns.parseISO(this.maxDate)) ||
                this.$dateFns.isBefore(date, this.$dateFns.parseISO(this.minDate))
            ) {
                this.setProp('dateTime', this.searchRequest.dateTime + 'T00:00')
            }

            if (
                this.product.offers?.length &&
                this.product.offers[0].departureInfo.type === 'AIRPORT' &&
                this.pointType === 'departure' &&
                this.flightService
            ) {
                const {flightNumber, departureAirportCode} =
                    this.flightService.serviceDetails[0].itineraries[0].flightSegments[0]
                this.setProp('flightNumber', flightNumber)
                this.setProp('otherAirportCode', departureAirportCode)
            }
            if (
                this.product.offers?.length &&
                this.product.offers[0].arrivalInfo.type === 'AIRPORT' &&
                this.pointType === 'arrival' &&
                this.flightService &&
                this.flightService.serviceDetails[0].itineraries[1]
            ) {
                const {flightNumber, arrivalAirportCode} =
                    this.flightService.serviceDetails[0].itineraries[1].flightSegments[0]
                this.setProp('flightNumber', flightNumber)
                this.setProp('otherAirportCode', arrivalAirportCode)
            }
        }

        splitDateTime() {
            let dateTime = this.getProp('dateTime')
            if (dateTime) {
                dateTime = dateTime.split('T')
            } else {
                dateTime = []
            }
            return dateTime
        }

        get flightDateRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_FLIGHT_TIME')) {
                rules.push(this.requiredRule('date', 'POINT_FLIGHT_TIME'))
            }
            return rules
        }

        get flightTimeRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_FLIGHT_TIME')) {
                rules.push(this.requiredRule('time', 'POINT_FLIGHT_TIME'))
            }
            return rules
        }

        get airportRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_AIRPORT')) {
                rules.push(
                    this.pointType === 'arrival'
                        ? this.requiredRule('transfers.departure_to_airport', 'POINT_AIRPORT')
                        : this.requiredRule('transfers.arriving_from_airport', 'POINT_AIRPORT')
                )
            }
            return rules
        }

        get flightNumberRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_FLIGHT_NUMBER')) {
                rules.push(this.requiredRule('transfers.flight_number', 'POINT_FLIGHT_NUMBER'))
                rules.push(
                    v => /^([A-Z]{3}|[A-Z\d]{2})\s?(\d{1,4})$/.test(v) || `${this.$t('transfers.flight_number_error')}`
                )
            }
            return rules
        }

        get flightNumber() {
            return this.getProp('flightNumber')
        }

        set flightNumber(val) {
            this.setProp('flightNumber', val.toUpperCase())
        }

        get date() {
            const dateTime = this.getProp('dateTime')
            if (!dateTime) return dateTime
            return dateTime.split('T')[0]
        }

        set date(val) {
            const dateTime = this.splitDateTime()
            dateTime[0] = val
            this.setProp('dateTime', dateTime.join('T'))
        }

        get time() {
            const dateTime = this.getProp('dateTime')
            if (!dateTime) return null
            return dateTime.split('T')[1]
        }

        set time(val) {
            const dateTime = this.splitDateTime()
            dateTime[1] = val
            this.setProp('dateTime', dateTime.join('T'))
        }

        get otherAirportCode() {
            return this.getProp('otherAirportCode')
        }

        set otherAirportCode(val) {
            this.setProp('otherAirportCode', val)
        }

        get ownProduct() {
            return transfersRuntimeStore.ownProduct(this.product)
        }

        get minDate() {
            if (this.pointType === 'arrival') {
                return this.searchRequest.dateTime
            } else {
                return this.$dateFns.format(
                    this.$dateFns.subDays(this.$dateFns.parseISO(this.searchRequest.dateTime), 1)
                )
            }
        }

        get maxDate() {
            if (this.returnTransferOffer) {
                return this.$dateFns.format(
                    this.$dateFns.addDays(this.$dateFns.parseISO(this.returnTransferOffer.date), 1)
                )
            }
            if (this.pointType === 'arrival') {
                return this.$dateFns.format(
                    this.$dateFns.addDays(this.$dateFns.parseISO(this.searchRequest.dateTime), 1)
                )
            } else {
                return this.searchRequest.dateTime
            }
        }
    }
</script>
