<template>
    <div>
        <compare-snackbar v-model="snackbar.show" @close-comparison="toggleComparison" />
        <v-btn v-if="offers.length > 1" depressed block @click="toggleComparison">
            {{ compareMode ? $t('close_comparison') : $t('compare_hotels') }}
        </v-btn>
        <component
            :is="!$breakpoint.smAndDown ? 'hotel-offer-card' : 'hotel-offer-card-mobile'"
            v-for="(offer, index) in offers"
            :key="`${index}_${offer.hotelCode}_${offer.supplierCode}`"
            :offer="offer"
            :compare-mode="compareMode"
            :search-request="searchRequest"
            :class="compareMode && offersToCompareKeys.indexOf(offer.hotelCode) !== -1 ? 'compare-selected' : ''"
            :show-supplier-rating="showSupplierRating"
            :data-cy="`hotel-offer-${index}`"
            class="my-2"
        />
        <div v-if="isEmptyResults" class="text-center title grey--text text--lighten-1 py-10">
            {{ $t('no_available_offers') }}
        </div>
        <conditions-modal>
            <template v-slot:info>
                <hotel-conditions-modal-info />
            </template>
            <template v-slot:hotelPriceNote>
                <hotel-price-note :search-request="searchRequest" />
            </template>
        </conditions-modal>
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
        <additional-fees-modal />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'
    import HotelOfferCard from '~src/components/search/offers/hotelOfferCard.src'
    import HotelOfferCardMobile from '~/components/search/offers/HotelOfferCardMobile'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import HotelRoomInfoModal from '~/components/modals/HotelRoomInfoModal'
    import CompareSnackbar from '@/components/snippets/CompareSnackbar'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo'
    import AdditionalFeesModal from '~src/components/modals/AdditionalFeesModal'
    import HotelPriceNote from '~/components/hotels/snippets/HotelPriceNote'

    @Component({
        components: {
            AdditionalFeesModal,
            HotelConditionsModalInfo,
            HotelMealTypeInfoModal,
            HotelOfferCard,
            HotelOfferCardMobile,
            ConditionsModal,
            HotelRoomInfoModal,
            CompareSnackbar,
            HotelPriceNote,
        },
    })
    export default class HotelOffers extends Vue {
        @Prop() page

        compareMode = false
        snackbar = {
            show: false,
        }

        get isEmptyResults() {
            return !hotelsStore.searchResponse.offersCount && !hotelsRuntimeStore.searchActive
        }

        get offers() {
            const index = this.$config.search.resultsOnPage * (this.page - 1)
            return hotelsRuntimeStore.filteredOffers.slice(index, index + this.$config.search.resultsOnPage)
        }

        toggleComparison() {
            this.snackbar.show = !this.snackbar.show
            this.compareMode = !this.compareMode
        }

        get offersToCompareKeys() {
            return hotelsStore.offersToCompare.map(el => {
                return el.hotelCode
            })
        }

        get searchRequest() {
            return hotelsStore.searchRequest
        }

        get showSupplierRating() {
            return false
        }
    }
</script>

<style scoped lang="scss">
    .compare-selected {
        border: 2px solid #1976d2;
        box-shadow: 0 3px 1px -2px #1976d2, 0px 2px 2px 0px #1976d2, 0px 1px 5px 0px #1976d2;
    }
</style>
