<template>
    <v-dialog v-model="dialog" width="400" hide-overlay>
        <v-card>
            <v-card-title> {{ `${$t('delete')}  ${serviceName}?` }}</v-card-title>
            <v-card-text> Are you sure? This action cannot be undone </v-card-text>
            <v-card-actions class="d-flex justify-end full-width">
                <v-btn small color="primary" :loading="loading" @click="confirm">
                    {{ $t('confirm') }}
                </v-btn>
                <v-btn small :disabled="loading" @click="dialog = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class DeleteServiceDialog extends Vue {
        @VModel() dialog
        @Prop({required: true}) processId
        @Prop({required: true}) serviceName

        loading = false

        async confirm() {
            this.loading = true

            try {
                await this.$api.orderServices.remove({processId: this.processId.replace(/^.{6}/, '')})
                this.$toast.success(this.$t('success_message.deleted_successfully'))
                this.dialog = false
                this.updateOrder()
            } catch (e) {
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            } finally {
                this.loading = false
            }
        }

        @Emit()
        updateOrder() {}
    }
</script>
