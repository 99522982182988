<template>
    <search-summary-layout
        :search-disabled="searchDisabled"
        :search-request="searchRequest"
        :show-date="showDate"
        :show-dates="showDates"
        form-name="tour-search-form"
        tourists-label="guest"
        show-tourists
        show-duration
    >
        <template v-slot:title>
            {{ locationLabel }}
        </template>
        <template v-if="info.duration" v-slot:content>
            <div>{{ `${touristsTotal} ${$tc('guest', touristsTotal)}` }}</div>
            <div v-if="info.duration">
                {{
                    `${info.duration} ${$tc('days', info.duration)} / ${info.duration - 1} ${$tc(
                        'night',
                        info.duration - 1
                    )}`
                }}
            </div>
        </template>
    </search-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {toursRuntimeStore} from '@/store'
    import SearchSummaryLayout from '~src/components/parts/searchSummaryLayout.src'
    import TourSearchForm from '~/components/tours/search/forms/TourSearchForm'

    @Component({
        components: {
            SearchSummaryLayout,
            TourSearchForm,
        },
    })
    export default class TourSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest
        @Prop({default: false, type: Boolean}) showDate
        @Prop({default: false, type: Boolean}) showDates
        @Prop({default: () => ({}), type: Object}) info

        get locationLabel() {
            return `${toursRuntimeStore.arrivalCity.name}, ${toursRuntimeStore.arrivalCity.countryName}`
        }

        get startDateFrom() {
            return this.searchRequest.startDateFrom
        }

        get startDateTo() {
            return this.searchRequest.startDateTo
        }

        get touristsTotal() {
            return toursRuntimeStore.touristsTotal(this.searchRequest)
        }

        get nightsFrom() {
            return this.searchRequest.durationFrom - 1
        }

        get nightsTo() {
            return this.searchRequest.durationTo - 1
        }

        get duration() {
            return `${this.$t('tours.from')} ${this.nightsFrom} ${this.$tc('night', this.nightsFrom)} -
                ${this.$t('tours.to')} ${this.nightsTo} ${this.$tc('night', this.nightsTo)}`
        }
    }
</script>
