<script>
    import {Component} from 'nuxt-property-decorator'
    import FlightsPage from '@/pages/flights/FlightsPage'

    @Component
    export default class FlightsAvailabilityPage extends FlightsPage {
        async fetch() {
            this.productRuntimeStore.SET_SEARCH_TYPE(this.$route.name === 'flights' ? 'price' : 'schedule')
        }

        async load() {
            await this.$store.restored
            await this.loadRuntimeData()
            this.loaded = true
            await this.search()
            this.productRuntimeStore.filter({offers: this.searchResponse.offers, filters: this.filterValues})
        }

        async search() {
            await this.productRuntimeStore.checkAvailability(this.productStore.availabilityRequest)
        }

        isOffersExpired() {
            return false
        }

        changeFilter(data) {
            this.productStore.SET_AVAILABLE_OFFER_FILTER(data)
            this.productRuntimeStore.filter({offers: this.searchResponse.offers, filters: this.filterValues})
        }

        resetFilters() {
            this.productStore.RESET_AVAILABLE_OFFER_FILTERS()
            this.productRuntimeStore.filter({offers: this.searchResponse.offers, filters: this.filterValues})
        }

        get sortKey() {
            return this.productStore.sortFnName
        }

        set sortKey(sortKey) {
            this.productStore.SET_SORT(sortKey)
            this.productRuntimeStore.filter({offers: this.searchResponse.offers, filters: this.filterValues})
        }

        get searchRequest() {
            return this.productStore.scheduleSearchRequest
        }

        get searchResponse() {
            return this.productStore.availabilityResponse
        }

        get filterValues() {
            return this.productStore.availableOfferFilters
        }

        get searchActive() {
            return this.productRuntimeStore.checkAvailabilityActive
        }

        get currency() {
            return this.productStore.scheduleSearchRequest.convertToCurrency !== 'ORIGINAL'
                ? this.productStore.searchRequest.convertToCurrency
                : this.filteredOffers[0]?.price.currency
        }
    }
</script>
