<template>
    <div>
        <component
            :is="`activity-card${$breakpoint.smAndDown ? '-mobile' : ''}`"
            v-for="(product, index) in offers"
            :key="product.info.name"
            :product="product"
            :search-request="searchRequest"
            :data-cy="`activity-offer-${index}`"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import ActivityCard from '@/components/activities/search/offers/ActivityCard'
    import ActivityCardMobile from '@/components/activities/search/offers/ActivityCardMobile'
    import {activitiesStore} from '@/utils/store-accessor'

    @Component({
        components: {
            ActivityCard,
            ActivityCardMobile,
        },
    })
    export default class ActivityOffers extends Vue {
        @Prop({default: () => []}) offers

        get searchRequest() {
            return activitiesStore.searchRequest
        }
    }
</script>
