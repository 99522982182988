<script>
    import {Component, Vue, Watch} from 'nuxt-property-decorator'
    import {postMessageFilter, postMessageLoad} from '@/utils/worker-helpers'
    import {AUTH_EVENT, CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    //TODO Merge with SearchPageBase after all products refactoring
    @Component
    export default class Tmp_SearchPageBase extends Vue {
        loaded = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        created() {
            EventBus.$on(AUTH_EVENT, this.search)
            EventBus.$on(CHANGE_LOCALE_EVENT, this.search)
        }

        async mounted() {
            await this.load()
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.search)
            EventBus.$off(CHANGE_LOCALE_EVENT, this.search)
            this.productRuntimeStore.SET_OFFERS([])
        }

        async load() {
            await this.$store.restored
            this.loaded = true
            if (this.needNewSearch()) {
                await this.search()
            } else if (this.productRuntimeStore.offers.length === 0) {
                const offers = await this.$localForage.getItem(this.productName)
                this.resetPageLock = true
                this.productRuntimeStore.START_FILTER()
                postMessageLoad(this.productName, this.productStore, offers)
            }
        }

        async search() {
            await this.productRuntimeStore.search(this.searchRequest)
        }

        stopSearch() {
            this.productRuntimeStore.stopSearch()
        }

        changeFilter(data) {
            this.productStore.SET_FILTER(data)
            this.productRuntimeStore.START_FILTER()
            postMessageFilter(this.productName, this.productStore)
        }

        resetFilters() {
            this.productStore.RESET_FILTERS()
            this.productRuntimeStore.START_FILTER()
            postMessageFilter(this.productName, this.productStore)
        }

        get searchResponse() {
            return this.productStore.searchResponse
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get filterActive() {
            return this.productRuntimeStore.filterActive
        }

        get filteredOffers() {
            return this.productRuntimeStore.offers
        }

        get filterValues() {
            return this.productStore.filters
        }

        get sortKey() {
            return this.productStore.sortFnName
        }

        set sortKey(sortKey) {
            this.productStore.SET_SORT(sortKey)
            this.productRuntimeStore.START_FILTER()
            postMessageFilter(this.productName, this.productStore)
        }

        get currency() {
            return this.productStore.searchRequest.convertToCurrency !== 'ORIGINAL'
                ? this.productStore.searchRequest.convertToCurrency
                : this.filteredOffers[0]?.price?.currency
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
            ]
        }
    }
</script>
