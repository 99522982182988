var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pa-2"},[_c('v-card',{staticClass:"pa-4",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"absolute","top":"12px","right":"24px","z-index":"1"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-account-check-outline ")]),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                        "+_vm._s(`${_vm.orderTravelers.length} ${_vm.$tc('guest', _vm.orderTravelers.length)}`)+"\n                    ")])],1),_vm._v(" "),(_vm.orderTravelersWithNames.length)?[_c('v-slide-y-transition',{staticClass:"full-width",attrs:{"group":"","tag":"v-row"}},_vm._l((_vm.orderTravelersWithNamesSliced),function(traveler,index){return _c('v-col',{key:traveler.travelerId,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"full-height",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"full-height py-1"},[_c('v-row',[_c('v-col',{staticClass:"text-capitalize",attrs:{"cols":"4","md":"3","xl":"2"}},[_vm._v("\n                                            "+_vm._s(traveler.isTourLead
                                                    ? _vm.$t('lead_guest')
                                                    : _vm.$tc('guest', 1) + ' ' + (index + 1))+"\n                                        ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"8","md":"9","xl":"10"}},[_c('div',[_vm._v("\n                                                "+_vm._s(traveler.prefix || '')+"\n                                            ")]),_vm._v(" "),_c('div',{staticClass:"text-h6 font-weight-bold black--text"},[_vm._v("\n                                                "+_vm._s(`${traveler.name[0].firstName || ''} ${
                                                        traveler.name[0].lastName || ''
                                                    }`)+"\n                                            ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-md-center flex-column flex-md-row"},[(traveler.email)?_c('div',[_vm._v("\n                                                    "+_vm._s(`${traveler.email}${traveler.phone ? ',' : ''}`)+"\n                                                     \n                                                ")]):_vm._e(),_vm._v(" "),(traveler.phone)?_c('div',[_vm._v("\n                                                    "+_vm._s(` ${traveler.phone}`)+"\n                                                ")]):_vm._e()]),_vm._v(" "),(traveler.passports && traveler.passports.length)?_c('div',[_vm._v("\n                                                "+_vm._s(_vm.$t('documents'))+":\n                                                "+_vm._s(`${traveler.passports[0].number || ''} (${
                                                        traveler.passports[0].expiryDate
                                                    })`)+"\n                                            ")]):_vm._e(),_vm._v(" "),(traveler.dateOfBirth)?_c('div',[_vm._v("\n                                                "+_vm._s(_vm.$t('birthdate'))+":\n                                                "+_vm._s(`${_vm.birthDateFormatted(traveler)}`)+"\n                                                "),(_vm.orderEndDate && _vm.showAge)?_c('span',[_vm._v("\n                                                    "+_vm._s(`(${_vm.getAge(traveler.dateOfBirth)} ${_vm.showYrs ? 'yrs' : ''})`)+"\n                                                ")]):_vm._e()]):_vm._e()])],1)],1),_vm._v(" "),(_vm.orderTravelersWithNamesSliced.length > 2)?_c('v-divider'):_vm._e()],1)],1)}),1),_vm._v(" "),(_vm.orderTravelersWithNames.length > _vm.expandLimit)?_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('span',{staticClass:"caption"},[_vm._v("\n                                    "+_vm._s(_vm.expanded ? _vm.$t('collapse') : _vm.$t('view_all'))+"\n                                ")]),_vm._v(" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("\n                                    "+_vm._s(_vm.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n                                ")])],1)],1)],1):_vm._e()]:_c('div',{staticClass:"text-center text-caption text--secondary full-width pb-4"},[_vm._v("\n                    "+_vm._s(_vm.$t('no_data'))+"\n                ")])],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }