<script>
    import {Vue, Component} from 'nuxt-property-decorator'

    @Component
    export default class UserAutoLoginPage extends Vue {
        async asyncData({$api, redirect, $cookies, $axios, query: {token}}) {
            if (process.server) {
                try {
                    $axios.setHeader('Token', token)
                    const rs = await $api.getUserInfoByToken.get({token})
                    $cookies.set('token', token)
                    //TODO Not need when API getUserInfoByToken by auth token implemented
                    if (rs.settings) {
                        $cookies.set('role', 'client')
                        $cookies.set('pid', rs.person.id)
                        $cookies.set('userTimeZone', null)
                        $cookies.set('companyType', null)
                        $cookies.set('availableProducts', rs.settings.availableProducts || null)
                    } else {
                        const {
                            users: [person],
                        } = await $api.persons.get({id: rs.userId})
                        $cookies.set('role', person.role)
                        $cookies.set('pid', person.personId)
                        $cookies.set('userTimeZone', rs.userTimeZone)
                        $cookies.set('companyType', rs.companyType)
                        $cookies.set('availableProducts', rs.availableProducts)
                    }
                } catch (e) {
                    $cookies.set('role', null)
                    $cookies.set('pid', null)
                    $cookies.set('userTimeZone', null)
                    $cookies.set('companyType', null)
                    $cookies.set('availableProducts', null)
                }
            }
            redirect('/')
        }

        render() {
            return null
        }
    }
</script>
