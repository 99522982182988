<template>
    <div>
        <div class="d-flex">
            <v-img
                :aspect-ratio="4 / 3"
                :src="mainImageReturnTransfer.url | imageUrl(250)"
                :lazy-src="'/placeholder.png' | srcHost"
                max-width="150"
                max-height="112.5"
                tile
                class="me-3"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && mainImageReturnTransfer.url"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="text-truncate">
                <div
                    :title="infoReturnTransfer.name"
                    class="primary--text font-weight-medium text-subtitle-1 text-truncate"
                >
                    {{ infoReturnTransfer.name }}
                </div>
                <v-chip
                    v-if="returnTransferOffer.transferType && returnTransferOffer.transferType !== 'UNKNOWN'"
                    label
                    x-small
                >
                    {{ $t(`filters_transferType.${returnTransferOffer.transferType}`) }}
                </v-chip>
                <div v-if="infoReturnTransfer.shortDescription" class="mb-4 text-body-2 text-wrap">
                    {{ infoReturnTransfer.shortDescription }}
                </div>
            </div>
        </div>
        <v-card flat tile class="my-2 pa-0">
            <v-card-text class="pa-0">
                <search-summary-content :search-request="searchRequestReturnTransfer" show-date show-tourists />
            </v-card-text>
        </v-card>
        <v-divider class="mt-4 mb-2" />
        <div>
            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo()">
                <v-icon small left>mdi-information-outline</v-icon>
                <span>
                    {{ returnTransferOffer.info.description }}
                </span>
                <span v-if="offerTime" class="ms-2 grey--text font-weight-bold text-body-2">
                    {{ `(${offerTime})` }}
                </span>
            </div>
            <div v-if="returnTransferOffer.available" class="caption d-flex align-center">
                <v-icon :title="$t('available')" left small class="success--text"> mdi-checkbox-marked-circle </v-icon>
                <span class="success--text" v-text="$t('available')" />
            </div>
            <div v-else class="caption d-flex align-center">
                <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                <span class="warning--text" v-text="$t('on_request')" />
            </div>
            <conditions-label
                :offer-key="returnTransferOffer.offerKey"
                :price="returnTransferOffer.price"
                class="text-caption"
                @conditions="showConditionsInfoReturnTransfer"
            />
            <div v-if="returnTransferOffer.info.tariffName" class="caption d-flex align-center">
                <v-icon left small class="info--text">mdi-tag-text</v-icon>
                {{ returnTransferOffer.info.tariffName }}
            </div>
            <transfer-driver-languages :lang-codes="returnTransferOffer.langCodes" />
            <div class="mt-1 font-weight-bold">
                <span>
                    {{ returnTransferOffer.price | price }}
                </span>
            </div>
        </div>
        <extra-services-summary
            v-if="extraServices[1] && additionalOptions[1]"
            :extra-services="extraServices[1]"
            :additional-options="additionalOptions[1]"
        />
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ConditionsLabel from '@/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import OrderSummaryBase from '@/components/booking/OrderSummaryBase.vue'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'

    //TODO Need delete component and move template in TransferOrderSummary as part of array list
    @Component({
        components: {ExtraServicesSummary, TransferDriverLanguages, ConditionsLabel, SearchSummaryContent},
    })
    export default class TransferReturnOrderSummaryCard extends OrderSummaryBase {
        @Prop() infoReturnTransfer
        @Prop() returnTransferOffer
        @Prop() searchRequestReturnTransfer
        @Prop({required: true, type: Array}) additionalOptions

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfoReturnTransfer() {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequestReturnTransfer, this.returnTransferOffer)
        }

        offerInfo() {
            EventBus.$emit(SHOW_TRANSFER_INFO_MODAL, {
                offer: this.returnTransferOffer,
                info: this.returnTransferOffer.info,
            })
        }

        get mainImageReturnTransfer() {
            return this.infoReturnTransfer.images?.find(image => image.mainImage) || {}
        }

        get offerTime() {
            if (this.returnTransferOffer.time?.length) {
                return this.returnTransferOffer.time[0]
            }
            if (this.returnTransferOffer.departureTimeFrom && this.returnTransferOffer.departureTimeTo) {
                return [this.returnTransferOffer.departureTimeFrom, this.returnTransferOffer.departureTimeTo].join(
                    ' - '
                )
            }

            return null
        }
    }
</script>
