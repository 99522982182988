<template>
    <div class="d-flex flex-column">
        <v-select v-model="status" outlined dense :items="statuses" :label="$t('status')" />
    </div>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ModifyManualStatus extends Vue {
        @PropSync('_status') status
        @Prop({default: () => [], type: Array}) statusList

        get statuses() {
            const statuses = this.statusList || [
                'CONFIRMED',
                'CANCELLED',
                'REJECTED',
                'CONFIRMATION PENDING',
                'QUOTE',
                'PRE-BOOKING',
            ]
            return statuses.map(value => ({value, text: this.$t(`bookingStatus.${value.split(' ').join('_')}`)}))
        }
    }
</script>
