<template>
    <v-dialog v-model="modal" width="800px" max-width="100%">
        <v-card v-if="info" width="800px" max-width="100%" class="pa-md-3">
            <v-card-title>
                <span class="text-h6">
                    {{ info.name }}
                </span>
                <v-icon class="v-card-close ma-4" @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="black--text">
                <v-tabs v-if="(info.images && info.images.length) || info.shortDescription">
                    <v-tab>
                        {{ $t('description') }}
                    </v-tab>
                    <v-tab-item>
                        <v-row no-gutters>
                            <v-col v-if="info.images && info.images.length" cols="12" md="4">
                                <v-carousel
                                    height="170px"
                                    hide-delimiters
                                    show-arrows-on-hover
                                    :show-arrows="info.images.length > 1"
                                >
                                    <v-carousel-item v-for="(image, imageIndex) in info.images" :key="imageIndex">
                                        <v-img :src="image.url | imageUrl(300)" height="170px" contain />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col v-if="info.duration" cols="12" md="8" class="ps-4">
                                <div class="font-weight-bold mt-2">
                                    {{ $t('duration') }}
                                </div>
                                <span>
                                    {{ `${info.duration} ${$tc('day', info.duration)}` }}
                                </span>
                            </v-col>
                            <template v-if="info.shortDescription">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold">{{ $t('description_type.short') }}</div>
                                    <div v-html="info.shortDescription" />
                                </v-col>
                            </template>
                            <template v-if="fullDescription">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`description_type.general`) }}
                                    </div>
                                    <div v-html="fullDescription" />
                                </v-col>
                            </template>
                            <template v-if="itineraryPoints.length">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold my-2">
                                        {{ $t('itinerary') }}
                                    </div>
                                    <ul class="d-flex flex-column">
                                        <li v-for="(point, i) in itineraryPoints" :key="i">
                                            <span v-if="point">
                                                {{ point.cityName }}
                                            </span>
                                        </li>
                                    </ul>
                                </v-col>
                            </template>
                            <v-col cols="12">
                                <v-divider class="my-4" />
                            </v-col>
                            <template v-if="shipName">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.ship_name`) }}
                                    </div>
                                    <span>
                                        {{ shipName }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="shipType">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.ship_type`) }}
                                    </div>
                                    <span>
                                        {{ $t(`cruises.ship_types.${shipType}`) }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="cruiseLine">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.cruise_line`) }}
                                    </div>
                                    <span>
                                        {{ cruiseLine }}
                                    </span>
                                </v-col>
                            </template>

                            <template v-if="yearBuilt">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.year`) }}
                                    </div>
                                    <span>
                                        {{ yearBuilt }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="length">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.length`) }}
                                    </div>
                                    <span>
                                        {{ length }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="width">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.width`) }}
                                    </div>
                                    <span>
                                        {{ width }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="decksCount">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.decks_count`) }}
                                    </div>
                                    <span>
                                        {{ decksCount }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="cabinsCount">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.cabins_count`) }}
                                    </div>
                                    <span>
                                        {{ cabinsCount }}
                                    </span>
                                </v-col>
                            </template>
                            <template v-if="maxPassengers">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.max_passengers`) }}
                                    </div>
                                    <span>
                                        {{ maxPassengers }}
                                    </span>
                                </v-col>
                            </template>

                            <template v-if="shipDescription">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.ship_description`) }}
                                    </div>
                                    <div v-html="shipDescription" />
                                </v-col>
                            </template>
                            <template v-if="deckDescription">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`cruises.decks_description`) }}
                                    </div>
                                    <div v-html="deckDescription" />
                                </v-col>
                            </template>
                            <template v-if="descriptions && descriptions.length">
                                <v-col cols="12" md="8">
                                    <strong>{{ $t('description') }} </strong><br />
                                    <div v-for="(desc, descIndex) in descriptions" :key="descIndex" v-html="desc" />
                                </v-col>
                            </template>
                            <template v-if="servicesDescription">
                                <v-col cols="12" md="8">
                                    <div class="font-weight-bold mt-2">
                                        {{ $t(`services`) }}
                                    </div>
                                    <div v-html="servicesDescription" />
                                </v-col>
                            </template>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_CRUISE_INFO_MODAL} from '@/utils/event-bus'
    import {handleNewLine} from '@/utils/filters'

    //TODO Need refactoring with other products
    @Component
    export default class CruiseInfoModal extends Vue {
        info = null
        modal = false
        conditionsInfo = {}
        itineraryPoints = []

        created() {
            EventBus.$on(SHOW_CRUISE_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_CRUISE_INFO_MODAL, this.showModal)
        }

        showModal({info}) {
            this.info = info
            this.loadItinerary()
            this.modal = true
        }

        async loadItinerary() {
            if (this.info) {
                this.itineraryPoints = [this.departurePoint, ...this.info.itineraryPoints]
            }

            if (this.itineraryPoints?.length) {
                const destinationCities = await Promise.all(
                    this.itineraryPoints.map(p => {
                        return (async () => {
                            try {
                                const locations = await this.$api.locations.get({
                                    id: p.cityId,
                                    countryId: p.countryId,
                                    limitCities: 1,
                                })

                                return locations?.cities[0]
                            } catch (e) {
                                return null
                            }
                        })()
                    })
                )

                this.mapPoints = [...destinationCities]
            }
        }

        get departurePoint() {
            return this.info.departurePoint
        }

        get shipName() {
            return this.info.shipName
        }

        get cruiseLine() {
            return this.info.cruiseLine
        }

        get yearBuilt() {
            return this.info.yearBuilt
        }

        get maxPassengers() {
            return this.info.maxPassengers
        }

        get shipType() {
            return this.info.shipType
        }

        get length() {
            return this.info.length
        }

        get width() {
            return this.info.width
        }

        get cabinsCount() {
            return this.info.cabinsCount
        }

        get decksCount() {
            return this.info.decksCount
        }

        get deckDescription() {
            return this.info.deckDescription
        }

        get cruiseServices() {
            return this.info.cruiseServices
        }

        get fullDescription() {
            return handleNewLine(this.info?.fullDescription)
        }

        get shipDescription() {
            return handleNewLine(this.info?.shipDescriptions?.join(' '))
        }

        get shortDescription() {
            return handleNewLine(this.info?.shortDescription)
        }

        get servicesDescription() {
            return handleNewLine(this.info?.servicesDescription)
        }

        get showShortDescription() {
            return (
                this.shortDescription &&
                (!this.fullDescription || this.fullDescription.indexOf(this.shortDescription) === -1)
            )
        }

        get descriptions() {
            return this.info?.descriptions
        }
    }
</script>
