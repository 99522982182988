<template>
    <v-dialog v-model="isShowDialog" max-width="600">
        <template v-slot:activator="{on}">
            <div class="text-lowercase cursor-pointer" v-on="on">
                {{ $t('income') }}
                <v-icon small class="ms-1">mdi-pencil</v-icon>
            </div>
        </template>
        <v-card>
            <v-card-title class="text-h5 primary--text py-5">
                {{ $t('price_adjustment') }}
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row class="py-4">
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-center justify-center text-subtitle-1">
                                <span class="font-weight-bold py-1" v-text="$t('change_total_price.supplier_price')" />
                                <span class="text-h6 font-weight-light mt-2 font-weight-bold">
                                    {{ getPrice(totalNettoPrice) }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-center justify-center text-subtitle-1">
                                <span class="font-weight-bold py-1" v-text="$t('change_total_price.client_price')" />
                                <span class="text-h6 font-weight-light mt-2 font-weight-bold">
                                    {{ getPrice(newTotalBruttoPrice) }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-start text-subtitle-1">
                                <span class="font-weight-bold py-1" v-text="$t('income')" />
                                <div class="d-flex align-center">
                                    <v-text-field
                                        v-model="amountNewBanknote"
                                        type="number"
                                        outlined
                                        dense
                                        :label="$t('invoices.amount')"
                                        hide-details="auto"
                                    />
                                </div>
                            </div>
                        </v-col>
                        <v-col md="4" />
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-if="totalBruttoCommission.amount > 0"
                                v-model="amountCommissionNewBanknote"
                                type="number"
                                outlined
                                :label="$t('commission')"
                                dense
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-start text-subtitle-1">
                                <div class="d-flex align-center">
                                    <v-text-field
                                        v-model="incomePercentNewRounded"
                                        type="number"
                                        outlined
                                        :label="$t('profit')"
                                        dense
                                        hide-details="auto"
                                    />
                                    <span class="ms-2" v-text="'%'" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-divider />
            <v-card-actions class="py-4">
                <span class="text-caption px-2" v-text="$t('change_total_price.save_prompt')" />
                <v-spacer />
                <v-btn color="primary" :loading="isLoading" @click="sendRequest">
                    {{ $t('save') }}
                </v-btn>
                <v-btn text @click="isShowDialog = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {convertPrice, price} from '@/utils/filters'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class ChangeIncomeDialog extends Vue {
        @Prop() totalNettoPrice
        @Prop() totalBruttoPrice
        @Prop() totalNettoCommission
        @Prop() totalBruttoCommission
        @Prop() totalIncome

        @Prop() services

        isShowDialog = false
        isLoading = false
        valid = false

        incomePercentNewValue = 0

        amountNewValueCent = 0
        amountCommissionNewCent = 0

        newTotalBruttoPrice = {}

        @Watch('isShowDialog')
        onDialogShow() {
            if (this.isShowDialog) {
                this.amountCommissionNewCent = this.totalBruttoCommission.amountCent
                this.amountNewCent = this.totalIncome.amountCent
            }
        }

        getPrice(p) {
            const systemCurrency = authStore.systemCurrency
            return systemCurrency ? price(p, systemCurrency) : price(p)
        }

        async sendRequest() {
            try {
                this.isLoading = true

                for (let service of this.services) {
                    const clientPrice = service.salesTerms.find(e => e.type === 'CLIENT')
                    const supplierPrice = service.salesTerms.find(e => e.type === 'SUPPLIER')
                    const supplierConverted = convertPrice(supplierPrice.price, clientPrice.price.currency)
                    const supplierCommissionConverted = convertPrice(
                        {...supplierPrice.price, amount: supplierPrice.price.commission},
                        clientPrice.price.currency
                    )

                    const newCommissionAmountCent =
                        (+this.amountCommissionNewCent / this.totalBruttoCommission.amountCent) *
                            clientPrice.price.commission *
                            100 || 0

                    const newPriceAmountCent =
                        (100 *
                            (newCommissionAmountCent +
                                supplierConverted.amount * 100 -
                                supplierCommissionConverted.amount * 100)) /
                        (100 - this.incomePercentNewValue)

                    const newTerm = {
                        type: clientPrice.type,
                        price: {
                            amount: newPriceAmountCent / 100,
                            commission: {
                                amount: newCommissionAmountCent / 100,
                            },
                        },
                    }

                    await this.$api.manualModification.post({
                        processId: service.processId,
                        status: service.status.toUpperCase(),
                        salesTerm: [newTerm],
                        applyClientAdjustedPrice: true,
                    })
                }

                this.$emit('modifyService')
            } catch (e) {
                this.$emit('modifyService')

                if (e.msg) {
                    this.errors = [{message: e.msg}]
                } else {
                    this.errors = e.errors
                }
                this.errors?.forEach(error => {
                    this.$toast.error(error.message)
                })
            } finally {
                this.isLoading = false
            }
        }

        get incomePercentNewRounded() {
            return +this.incomePercentNewValue.toFixed(2)
        }

        set incomePercentNewRounded(val) {
            if (+val < 0) {
                this.incomePercentNewRounded = 0
                return
            } else if (+val >= 100) {
                this.incomePercentNewRounded = 99.99
                return
            }
            this.incomePercentNewValue = +val
            this.amountNewCent =
                (this.incomePercentNewValue *
                    (this.totalNettoPrice.amountCent +
                        +this.amountCommissionNewCent +
                        this.totalNettoCommission.amountCent)) /
                (100 - this.incomePercentNewValue)
        }

        get amountNewCent() {
            return +this.amountNewValueCent
        }

        set amountNewCent(val) {
            this.amountNewValueCent = +val
            this.newTotalBruttoPrice = {
                amount:
                    (this.totalNettoPrice.amountCent +
                        this.amountNewValueCent +
                        this.amountCommissionNewCent +
                        this.totalNettoCommission.amountCent) /
                    100,
                amountCent:
                    this.totalNettoPrice.amountCent +
                    this.amountNewValueCent +
                    this.amountCommissionNewCent +
                    this.totalNettoCommission.amountCent,
                currency: this.totalNettoPrice.currency,
            }
            this.incomePercentNewValue = +(
                (this.amountNewValueCent * 100) /
                (this.totalNettoPrice.amountCent +
                    this.amountNewValueCent +
                    +this.amountCommissionNewCent +
                    this.totalNettoCommission.amountCent)
            )
        }

        get amountNewBanknote() {
            return Math.ceil(this.amountNewCent) / 100
        }

        set amountNewBanknote(val) {
            this.amountNewCent = +val * 100
        }

        get amountCommissionNewBanknote() {
            return Math.ceil(this.amountCommissionNewCent) / 100
        }

        set amountCommissionNewBanknote(val) {
            this.amountCommissionNewCent = +val * 100
            const tempBruttoCent = this.totalBruttoPrice.amountCent
            this.amountNewCent =
                tempBruttoCent -
                this.amountCommissionNewCent -
                this.totalNettoPrice.amountCent -
                this.totalNettoCommission.amountCent
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-text-field__details {
            .v-messages__message {
                color: var(--v-warning-base);
            }
        }
    }
</style>
