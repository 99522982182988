<template>
    <div>
        <div class="d-flex">
            <v-img
                max-width="150"
                class="me-3"
                tile
                :src="image | imageUrl(600)"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div>
                <div class="d-flex align-center">
                    <span :title="info.name" class="me-2 primary--text font-weight-medium text-subtitle-1">
                        {{ info.name }}
                    </span>
                    <v-chip v-if="product.type && product.type !== 'UNKNOWN'" label x-small>
                        {{ $t(`filters_activityType.${product.type}`) }}
                    </v-chip>
                </div>
                <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" class="mb-2" />
                <div v-if="info.shortDescription" class="mb-4 text-body-2">
                    {{ info.shortDescription }}
                </div>
            </div>
        </div>
        <slot name="summary" :grouped-offers="groupedOffers" />
        <v-divider class="mb-2" />
        <div v-for="{offers, date} in groupedOffers" :key="date" :class="{'mb-5': groupedOffers.length > 1}">
            <div v-if="groupedOffers.length > 1" class="text-body-2 text--secondary">{{ date | dateFormat }}</div>
            <div v-for="offer in offers" :key="offer.offerKey" class="relative-position">
                <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo(offer)">
                    <v-icon small left>mdi-information-outline</v-icon>
                    <span>
                        {{ offer.serviceName }}
                    </span>
                </div>
                <div v-if="offer.available" class="d-flex align-center caption">
                    <v-icon :title="$t('available')" left small class="success--text">
                        mdi-checkbox-marked-circle
                    </v-icon>
                    <span class="success--text" v-text="$t('available')" />
                </div>
                <div v-else class="caption d-flex align-center">
                    <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                    <span class="warning--text" v-text="$t('on_request')" />
                </div>
                <template v-if="showPrice">
                    <conditions-label
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        class="text-caption"
                        @conditions="showConditionsInfo(offer)"
                    />
                    <div class="mt-1 font-weight-bold">
                        <span>
                            {{ offerPrice(offer) | price }}
                        </span>
                        <commission-amount :commission="offer.price.commission" />
                        <net-amount :price="offer.price" />
                    </div>
                </template>
                <div v-else-if="free" class="mt-1 font-weight-bold">{{ $t('free') }}</div>
                <slot name="removeBtn" :offer-key="offer.offerKey" />
            </div>
        </div>
        <extra-services-summary
            v-if="extraServices && additionalOptions"
            :extra-services="extraServices"
            :additional-options="additionalOptions"
        />
        <add-ons-summary-list :selected-add-ons="selectedAddOns" />
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {clone} from '@/utils/helpers'
    import {activitiesStore, authStore} from '@/store'
    import ConditionsLabel from '@/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_ACTIVITIES_INFO_MODAL, SHOW_ACTIVITY_CONDITIONS_INFO} from '@/utils/event-bus'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import AddOnsSummaryList from '@/components/booking/addOns/AddOnsSummaryList.vue'
    import OrderSummaryBase from '@/components/booking/OrderSummaryBase.vue'

    @Component({
        components: {
            AddOnsSummaryList,
            ExtraServicesSummary,
            OrderSummaryLayout,
            ConditionsLabel,
            CommissionAmount,
            NetAmount,
            SupplierNameLabel,
        },
    })
    export default class ActivityOrderSummary extends OrderSummaryBase {
        @Prop({required: true}) product
        @Prop({required: true, type: Array}) offers
        @Prop({required: true}) info
        @Prop({default: true}) prebooked
        @Prop({required: true, type: Array}) selectedAddOns

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo(offer) {
            EventBus.$emit(SHOW_ACTIVITY_CONDITIONS_INFO, offer)
        }

        offerInfo(offer) {
            EventBus.$emit(SHOW_ACTIVITIES_INFO_MODAL, {offer, info: this.info})
        }

        offerPrice(offer) {
            const price = this.prebooked && offer?.currentPrice ? clone(offer.currentPrice) : clone(offer.price)
            const mandatory =
                activitiesStore.bookingAdditionalOptions?.extraServices?.filter(
                    extraService => extraService.mandatory
                ) || []

            if (mandatory.length) {
                mandatory.forEach(item => {
                    price.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount
                    price.originalAmount -= item.salesTerms.find(el => el.type === 'CLIENT').originalAmount
                })
            }

            return price
        }

        get image() {
            if (!this.info.images) return null
            let main = this.info.images.find(image => image.mainImage)
            if (!main && this.info.images.length > 0) {
                main = this.info.images[0]
            }
            return main && main.url ? main.url : null
        }

        get isTO1() {
            return authStore.isTO1
        }

        get groupedOffers() {
            return this.offers
                .reduce((groups, offer) => {
                    const group = groups.find(group => group.date === offer.date)
                    if (group) {
                        group.offers.push(offer)
                    } else {
                        groups.push({
                            date: offer.date,
                            offers: [offer],
                        })
                    }
                    return groups
                }, [])
                .sort((gr1, gr2) => this.$dateFns.parseISO(gr1.date) - this.$dateFns.parseISO(gr2.date))
        }

        get showPrice() {
            return !this.free
        }
    }
</script>
