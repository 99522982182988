<template>
    <v-list-item class="px-0 d-flex entries">
        <v-list-item-action class="mr-2">
            <v-checkbox
                v-if="!entry.obligatory && !entry.base"
                :input-value="selected"
                color="primary"
                dense
                @click="selectEntry(entry)"
            />
        </v-list-item-action>
        <div class="d-lg-flex align-center">
            <div v-if="!hideDate" class="entries__time">
                {{ date | dateFormat }}
            </div>
            <div v-if="!hideEntryType" class="entries__type">
                <v-icon small>
                    {{ getIcon(entry.type) }}
                </v-icon>
                {{ getEntryType(entry) }}
            </div>
            <div v-if="selectable" class="entries__duration">
                <hotel-service-duration-select
                    v-if="entry.type === 'ACCOMMODATION' && entry.duration"
                    :entry="entry"
                    :offer="offer"
                    :citizenship-id="citizenshipId"
                    :search-request="searchRequest"
                />
            </div>
            <div v-if="entry.type === 'ACCOMMODATION'">
                <div class="mb-n2 d-lg-flex align-center">
                    {{ selectedHotel(entry).name }}
                    <hotel-stars :hotel="selectedHotel(entry)" />
                    <v-btn
                        color="primary"
                        text
                        :icon="entry.offers.length <= 1"
                        small
                        right
                        class="d-none d-lg-block"
                        @click="showServiceInfo(entry)"
                    >
                        <v-icon color="primary" small>mdi-information-outline</v-icon>
                        <span v-if="entry.offers.length > 1">{{ $t('tours.alternative_hotels') }}</span>
                    </v-btn>
                    <v-chip v-if="isHotelSoldOut" x-small>
                        {{ $t('unavailable') }}
                    </v-chip>
                </div>
                <div class="grey--text subtitle-2 mb-n1">
                    {{ `${selectedHotel(entry).cityName}, ${selectedHotel(entry).countryName}` }}
                </div>
                <div class="grey--text subtitle-2">
                    {{ `${selectedRoom(entry).name}, ${selectedRoom(entry).mealTypeName}` }}
                </div>
                <v-btn color="primary" text small class="d-lg-none" @click="showServiceInfo(entry)">
                    <v-icon color="primary" small>mdi-information-outline</v-icon>
                    {{ $t('tours.alternative_hotels') }}
                </v-btn>
            </div>
            <div v-else-if="entry.type === 'CAR'">
                <div>
                    {{ getProductName(entry) }}
                    <v-btn color="primary" text icon small right>
                        <v-icon small @click="showServiceInfo(entry)">mdi-information-outline</v-icon>
                    </v-btn>
                </div>
                <car-feature-labels :info="entry.info" x-small />
                <car-characteristic :info="entry.info" small />
            </div>
            <div v-else>
                <div>
                    {{ getProductName(entry) }}
                    <v-btn color="primary" text icon small right>
                        <v-icon small @click="showServiceInfo(entry)">mdi-information-outline</v-icon>
                    </v-btn>
                </div>
                <div class="grey--text subtitle-2 mt-n2">
                    {{ `${getProductServiceName(entry)}` }}
                </div>
            </div>
        </div>
    </v-list-item>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {addDays, format} from 'date-fns'
    import {
        EventBus,
        SHOW_TOUR_ACTIVITY_INFO,
        SHOW_TOUR_CAR_INFO,
        SHOW_TOUR_CRUISE_INFO,
        SHOW_TOUR_EXTRA_SERVICE_INFO,
        SHOW_TOUR_FLIGHT_INFO,
        SHOW_TOUR_HOTEL_INFO,
        SHOW_TOUR_INSURANCE_INFO,
        SHOW_TOUR_TRANSFER_INFO,
    } from '@/utils/event-bus'
    import {hotelsRuntimeStore, toursRuntimeStore} from '@/utils/store-accessor'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import HotelServiceDurationSelect from '@/components/account/modals/newOrderRequest/forms/HotelServiceDurationSelect'
    import CarFeatureLabels from '@/components/carsRent/snippets/CarFeatureLabels'
    import CarCharacteristic from '@/components/carsRent/snippets/CarCharacteristic'

    @Component({
        components: {HotelServiceDurationSelect, HotelStars, CarFeatureLabels, CarCharacteristic},
    })
    export default class TourOfferEntry extends Vue {
        @Prop({required: true}) entry
        @Prop({required: true}) offer
        @Prop({required: false, type: Boolean}) hideDate
        @Prop({required: false, type: Boolean}) hideEntryType
        @Prop({required: false, type: Boolean}) selectable
        @Prop() searchRequest
        @Prop() citizenshipId

        selectEntry(entry) {
            //TODO Need refactoring
            const selectedOfferKey =
                entry.type === 'ACCOMMODATION'
                    ? this.selectedRoom(this.entry).groupedOffers[0].offerKey
                    : entry.type === 'CAR'
                    ? entry.offer.offerKey
                    : entry.offers[0].offers
                    ? entry.offers[0].offers[0].offerKey
                    : entry.offers[0].offerKey
            if (!this.selected) {
                toursRuntimeStore.SELECT_OFFER({offerKey: this.offerKey, selectedOfferKey})
            } else {
                toursRuntimeStore.UNSELECT_OFFER({offerKey: this.offerKey, selectedOfferKey})
            }
        }

        showServiceInfo(entry) {
            switch (entry.type) {
                case 'ACCOMMODATION':
                    EventBus.$emit(SHOW_TOUR_HOTEL_INFO, entry, this.offerKey)
                    return
                case 'FLIGHT':
                    EventBus.$emit(SHOW_TOUR_FLIGHT_INFO, entry, this.offerKey)
                    return
                case 'TRANSFER':
                    EventBus.$emit(SHOW_TOUR_TRANSFER_INFO, entry, this.offerKey)
                    return
                case 'EVENT':
                case 'EXCURSION':
                    EventBus.$emit(SHOW_TOUR_ACTIVITY_INFO, entry, this.offerKey)
                    return
                case 'EXTRA_SERVICE':
                    EventBus.$emit(SHOW_TOUR_EXTRA_SERVICE_INFO, entry, this.offerKey)
                    return
                case 'INSURANCE':
                    EventBus.$emit(SHOW_TOUR_INSURANCE_INFO, entry, this.offerKey)
                    return
                case 'CRUISE':
                    EventBus.$emit(SHOW_TOUR_CRUISE_INFO, entry, this.offerKey)
                    return
                case 'CAR':
                    EventBus.$emit(SHOW_TOUR_CAR_INFO, entry, this.offerKey)
                    return
            }
        }

        getIcon(type) {
            switch (type) {
                case 'ACCOMMODATION':
                    return 'mdi-office-building'
                case 'TRANSFER':
                    return 'mdi-bus'
                case 'EXCURSION':
                case 'EVENT':
                    return 'mdi-lightning-bolt-outline'
                case 'FLIGHT':
                    return 'mdi-airplane'
                case 'INSURANCE':
                    return 'mdi-umbrella'
                case 'EXTRA_SERVICE':
                    return 'mdi-star-box'
                case 'CRUISE':
                    return 'mdi-ferry'
                case 'CAR':
                    return 'mdi-car'
                default:
                    return 'mdi-help'
            }
        }

        getEntryType(item) {
            switch (item.type) {
                case 'ACCOMMODATION':
                    return 'HOTEL'
                case 'EXTRA_SERVICE':
                    return 'EXTRA SERVICE'
                default:
                    return item.type
            }
        }

        getProductName(item) {
            switch (item.type) {
                case 'ACCOMMODATION':
                case 'TRANSFER':
                case 'EXCURSION':
                case 'EVENT':
                case 'INSURANCE':
                case 'EXTRA_SERVICE':
                case 'CRUISE':
                case 'CAR':
                    return item.name
                case 'FLIGHT':
                    return item.flightName || item.name
                default:
                    return item.productName
            }
        }

        getProductServiceName(entry) {
            switch (entry.type) {
                case 'ACCOMMODATION':
                    return ''
                case 'FLIGHT':
                    return entry.offers[0].itinerary[0].segments[0].class.classType
                case 'EVENT':
                case 'EXCURSION':
                    for (const product of entry.offers) {
                        const selectedOffer = product.offers.find(({offerKey}) => offerKey === entry.selectedOfferKey)
                        if (selectedOffer) return selectedOffer.serviceName
                    }
                    return ''
                case 'EXTRA_SERVICE':
                    return entry.selectedOfferKey
                        ? entry.offers.find(offer => offer.offerKey === entry.selectedOfferKey).info.service
                        : entry.offers[0].info.service
                case 'INSURANCE':
                    return entry.selectedOfferKey
                        ? entry.offers.find(offer => offer.offerKey === entry.selectedOfferKey).planName
                        : entry.offers[0].planName
                case 'TRANSFER':
                    return entry.selectedOfferKey
                        ? entry.offers.find(offer => offer.offerKey === entry.selectedOfferKey).info.description
                        : entry.offers[0].info.description
                case 'CRUISE':
                    for (const product of entry.offers) {
                        const selectedOffer = product.offers.find(({offerKey}) => offerKey === entry.selectedOfferKey)
                        if (selectedOffer)
                            return `${selectedOffer.serviceName} / ${selectedOffer.categoryName} / ${
                                selectedOffer.mealType?.name || ''
                            }`
                    }
                    return ''
                case 'CAR':
                    return entry.name
                default:
                    return ''
            }
        }

        selectedHotel(entry) {
            if (!entry.obligatory && !entry.selectedOfferKey) return entry.offers[0]
            return hotelsRuntimeStore.findHotelByOfferKey(entry.offers, entry.selectedOfferKey)
        }

        selectedRoom(entry) {
            if (!entry.obligatory && !entry.selectedOfferKey) return entry.offers[0].rooms[0]

            return this.selectedHotel(entry).rooms.find(
                ({groupedOffers}) => groupedOffers[0].offerKey === entry.selectedOfferKey
            )
        }

        get date() {
            if (this.entry.entryDate) {
                return this.entry.entryDate
            } else if (this.entry.dayNumber > 1) {
                return format(addDays(new Date(this.offer.info.packageDate), this.entry.dayNumber - 1), 'yyyy-MM-dd')
            } else {
                return this.offer.info.packageDate
            }
        }

        get offerKey() {
            return this.offer.offerKey
        }

        get selected() {
            return this.entry.selectedOfferKey
        }

        get isHotelSoldOut() {
            const isHotel = this.entry.type === 'ACCOMMODATION'

            if (!isHotel) {
                return false
            }

            let selectedRoom

            this.entry?.offers.forEach(offer => {
                const room = offer.rooms.find(room => {
                    return !!room.groupedOffers.find(g => g.offerKey === this.entry?.selectedOfferKey)
                })

                if (room) {
                    selectedRoom = room
                    return undefined
                }
            })

            return !!selectedRoom?.soldOut
        }
    }
</script>

<style lang="scss" scoped>
    .entries {
        &__time {
            min-width: 150px;
        }

        &__duration {
            width: 150px;
        }

        &__type {
            min-width: 150px;
        }
    }
</style>
