<template>
    <div>
        <div ref="map" class="map" />
        <v-sheet color="amber lighten-5 d-flex align-center pa-1">
            <svg viewBox="4 1 16 22" height="40px" width="30px">
                <path
                    :d="markerIconOptions.path"
                    :fill="markerColor({own: true})"
                    :fill-opacity="markerIconOptions.fillOpacity"
                    :stroke="markerIconOptions.strokeColor"
                />
            </svg>
            <span class="pl-1 pr-2">{{ $t('mapLegend.own') }}</span>
            <svg viewBox="4 1 16 22" height="40px" width="30px">
                <path
                    :d="markerIconOptions.path"
                    :fill="markerColor({})"
                    :fill-opacity="markerIconOptions.fillOpacity"
                    :stroke="markerIconOptions.strokeColor"
                />
            </svg>
            <span class="pl-1 pr-2">{{ $t('mapLegend.other') }}</span>
        </v-sheet>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import gmapsInit from '@/utils/gmaps'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'
    import {Vue} from 'vue-property-decorator'
    import {MarkerClusterer} from '@googlemaps/markerclusterer'
    import HotelHomeMapInfoWindow from '~/components/hotels/HotelHomeMapInfoWindow'
    import {uniqBy} from 'lodash'

    @Component
    export default class HotelHomeMap extends mixins(HotelMapMixin) {
        google
        markerWithInfoWindow

        async mounted() {
            //TODO Workaround for test
            const hotels = this.$config.testApi
                ? uniqBy(
                      (
                          await Promise.all([
                              this.$api.hotels.get({limit: -1}),
                              this.$api.hotels.get({limit: -1, contentProviderId: 1}),
                          ])
                      ).reduce((result, hotels) => [...result, ...hotels], []),
                      'id'
                  )
                : await this.$api.hotels.get({limit: 0})
            this.google = await gmapsInit()
            const map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    mapTypeControl: true,
                    zoomControl: true,
                    fullscreenControl: true,
                    zoom: 8,
                    restriction: {
                        latLngBounds: {north: 80, south: -70, west: -180, east: 180},
                        strictBounds: true,
                    },
                }),
                bounds = new this.google.maps.LatLngBounds(),
                markers = hotels.map(hotel => {
                    const {lat, lng} = hotel
                    const marker = new this.google.maps.Marker({
                        position: {lat, lng},
                        icon: this.createMarkerIcon(hotel),
                        data: hotel,
                    })
                    marker.addListener('click', () => {
                        if (!marker.infoWindow) {
                            if (this.markerWithInfoWindow) {
                                this.markerWithInfoWindow.infoWindow.close()
                                delete this.markerWithInfoWindow.infoWindow
                            }
                            this.markerWithInfoWindow = marker
                            const InfoWindowComponent = Vue.extend(HotelHomeMapInfoWindow),
                                instance = new InfoWindowComponent({
                                    propsData: {
                                        offer: hotel,
                                    },
                                    parent: this,
                                })
                            instance.$mount()
                            marker.infoWindow = new this.google.maps.InfoWindow({
                                content: instance.$el,
                            })
                            this.google.maps.event.addListener(marker.infoWindow, 'closeclick', () => {
                                marker.infoWindow.close()
                                delete marker.infoWindow
                                this.markerWithInfoWindow = null
                            })
                            marker.infoWindow.open(this.map, marker)
                        }
                    })
                    bounds.extend(marker.getPosition())
                    return marker
                })
            map.fitBounds(bounds)
            new MarkerClusterer({
                map,
                markers,
                renderer: {
                    render: ({count, position}) => {
                        const color = this.$vuetify.theme.themes.light.primary
                        const svg = btoa(`
                            <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                                <ellipse style="stroke: ${color}; fill: rgb(255, 255, 255); stroke-width: 4px;" cx="30" cy="30" rx="28" ry="28"></ellipse>
                                <text style="fill: ${color}; font-family: sans-serif; font-size: 14px; white-space: pre; text-anchor: middle;" x="30" y="35">${count}</text>
                            </svg>
                        `)
                        return new this.google.maps.Marker({
                            position,
                            icon: {
                                url: `data:image/svg+xml;base64,${svg}`,
                                scaledSize: new this.google.maps.Size(45, 45),
                            },
                            label: {
                                text: ' ',
                            },
                            // adjust zIndex to be above other markers
                            zIndex: Number(this.google.maps.Marker.MAX_ZINDEX) + count,
                        })
                    },
                },
            })
        }
    }
</script>

<style scoped lang="scss">
    .map {
        height: 600px;
    }
</style>
