<template>
    <v-overlay :value="active">
        <v-progress-circular indeterminate size="64" />
    </v-overlay>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'

    @Component
    export default class OverlayProgress extends Vue {
        @Prop({required: true}) active
    }
</script>
