<template>
    <div>
        <template v-if="extraBaggageOptions.length || additionalFlightOptionsLoading">
            <div v-for="(tourist, index) in tourists" :key="index">
                <div class="text-subtitle-1 text-capitalize font-weight-bold">
                    {{ `${$tc('passenger', 1)} ${index + 1} ${tourist.type === 'child' ? `(${$tc('child')})` : ''}` }}
                </div>
                <v-skeleton-loader v-if="additionalFlightOptionsLoading" type="list-item@3" :elevation="0" />
                <extra-baggage-form
                    v-else
                    v-model="prepareBookRequest"
                    :index="index"
                    :disabled="disabled"
                    :offer="selectedFlight"
                    :extra-baggage-options="extraBaggageOptions"
                />
            </div>
        </template>
        <flight-seats-map
            v-model="prepareBookRequest"
            :offer="selectedFlight"
            :disabled="disabled"
            :search-request="reducedSearchRequest"
            :tourists="tourists"
            @map-loaded="flightSeatsMap = $event"
        />
    </div>
</template>

<script>
    import {Component, Emit, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import FlightSeatsMap from '@/components/flights/booking/FlightSeatsMap.vue'
    import ExtraBaggageForm from '@/components/flights/booking/ExtraBaggageForm.vue'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'
    import FlightBookingExtraMixin from '@/components/flights/booking/mixins/FlightBookingExtraMixin.vue'
    import {sumPrice} from '@/utils/api-helpers'

    @Component({
        components: {ExtraBaggageForm, FlightSeatsMap},
    })
    export default class FlightExtraData extends FlightBookingExtraMixin {
        @VModel() prepareBookRequest
        @Prop({required: true, type: Object}) entry
        @Prop({required: true, type: Object}) searchRequest
        @Prop({required: true, type: Boolean}) disabled

        flightSeatsMap = {}
        extraBaggageOptions = []
        additionalFlightOptionsLoading = true

        @Emit()
        changePrice() {
            //console.log(sumPrice([this.extraBaggagePrice, this.seatsPrice]))
            return sumPrice([this.extraBaggagePrice, this.seatsPrice])
            //return 'test'
        }

        @Watch('prepareBookRequest', {deep: true})
        onPrepareBookRequestChange() {
            this.changePrice()
            //this.$emit('change-price', sumPrice([this.extraBaggagePrice, this.seatsPrice]))
        }

        async mounted() {
            await this.loadAdditionalFlightOptions(this.entry.selectedOfferKey)
        }

        async loadAdditionalFlightOptions(offerKey) {
            try {
                const {extraBaggageOptions} = await this.$api.additionalFlightOptions.get({offerKey})
                if (extraBaggageOptions) {
                    this.extraBaggageOptions = extraBaggageOptions
                } else {
                    this.extraBaggageOptions = []
                }
            } catch (e) {
                this.extraBaggageOptions = []
            } finally {
                this.additionalFlightOptionsLoading = false
            }
        }

        get tourists() {
            const {adults, childrenAges} = this.reducedSearchRequest
            return [...new Array(adults).fill({type: 'adult'}), ...childrenAges.map(age => ({type: 'child', age}))]
        }

        get currency() {
            return this.searchRequest.convertToCurrency
        }

        get reducedSearchRequest() {
            return hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest).reduce(
                (sr, {adults, childrenAges}) => ({
                    adults: sr.adults + adults,
                    childrenAges: [...sr.childrenAges, ...childrenAges],
                }),
                {adults: 0, childrenAges: []}
            )
        }

        get selectedFlight() {
            return this.entry.offers.find(({offerKey}) => offerKey === this.entry.selectedOfferKey)
        }
    }
</script>
