<template>
    <div
        v-if="contacts && contacts.length"
        class="me-4 d-flex align-md-center flex-column flex-md-row align-start mx-md-6"
        :class="{'full-width': $breakpoint.smAndDown}"
    >
        <div
            v-for="contact in contacts"
            :key="contact.description"
            class="d-flex justify-space-between font-weight-bold text-caption mx-md-5 mx-1 mb-2 mb-md-0"
            :class="{'full-width': $breakpoint.smAndDown}"
        >
            <span
                v-if="contact.description"
                class="me-2 d-flex align-center"
                :class="{'white--text': $breakpoint.smAndDown}"
                v-text="contact.description"
            />
            <v-spacer v-if="$breakpoint.smAndDown" />
            <div class="d-flex flex-column justify-start primary--text">
                <div v-if="contact.phone" class="d-flex align-center">
                    <v-icon v-if="isShowIcons" small class="me-1">mdi-cellphone</v-icon>
                    <a class="text-decoration-none" :href="`tel:${contact.phone}`" v-text="contact.phone" />
                </div>
                <div v-if="contact.email" class="d-flex align-center">
                    <v-icon v-if="isShowIcons" small class="me-1">mdi-at</v-icon>
                    <a class="text-decoration-none" :href="`mailto:${contact.email}`" v-text="contact.email" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'

    @Component
    export default class ContactsBlock extends Vue {
        isShowIcons = false

        get contacts() {
            return cmsStore.layoutSettings.contacts
        }
    }
</script>
