<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import {toursRuntimeStore, toursStore} from '@/utils/store-accessor'

    @Component
    export default class TourBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest

        get items() {
            const items = [this.homeItem]
            let basketItem
            switch (this.$route.name) {
                case 'tourSearch':
                    items.push({
                        text: `${toursRuntimeStore.arrivalCity.name}, ${toursRuntimeStore.arrivalCity.countryName}`,
                        to: toursRuntimeStore.searchPageLink(this.searchRequest),
                        disabled: true,
                        nuxt: true,
                        exact: true,
                    })
                    break
                case 'tour':
                    items.push({
                        text: `${toursRuntimeStore.arrivalCity.name}, ${toursRuntimeStore.arrivalCity.countryName}`,
                        to: toursRuntimeStore.searchPageLink(this.searchRequest),
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: decodeURIComponent(toursRuntimeStore.packageTourInfo.name),
                        disabled: true,
                    })
                    break
                case 'tourBooking':
                    items.push({
                        text: `${toursRuntimeStore.arrivalCity.name}, ${toursRuntimeStore.arrivalCity.countryName}`,
                        to: toursRuntimeStore.searchPageLink(this.searchRequest),
                        nuxt: true,
                        exact: true,
                    })
                    basketItem = toursStore.basketItem(this.$route.query.offerKey)
                    items.push({
                        text: decodeURIComponent(toursRuntimeStore.packageTourInfo.name),
                        to: {
                            name: 'tour',
                            query: {
                                ...basketItem.searchRequest,
                                packageTourId: basketItem.searchRequest.packageTourId,
                                duration: toursRuntimeStore.packageTourInfo.duration,
                            },
                        },
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'tourConfirmation':
                    items.push({
                        text: decodeURIComponent(toursRuntimeStore.packageTourInfo.name),
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
