<template>
    <client-only>
        <div>
            <v-data-table
                :headers="headers"
                :items="isLoading ? [] : services"
                :items-per-page="ordersOnPage"
                :server-items-length="ordersCount"
                :loading="isLoading"
                :options.sync="options"
                :footer-props="{'items-per-page-options': [ordersOnPage, 100]}"
                :hide-default-header="isMobile"
                :no-data-text="noDataText"
                :mobile-breakpoint="0"
                group-by="orderId"
                disable-sort
                data-cy="orders-table"
                class="elevation-1"
            >
                <template v-if="selectable" v-slot:header.id="{header}">
                    <v-checkbox
                        v-model="selectAll"
                        :ripple="false"
                        :value="false"
                        dense
                        hide-details
                        @change="changeSelectAll"
                    >
                        <template v-slot:label>
                            <span>{{ header.text }}</span>
                        </template>
                    </v-checkbox>
                </template>
                <template v-slot:header.date="{header}">
                    <span class="cursor-pointer" @click="toggleSortOrder">
                        {{ header.text }}
                        <v-icon v-if="sortOrder !== 'none'" class="cursor-pointer hidden-sm-and-down me-1">
                            {{ sortOrder === 'ASCENDING' ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                    </span>
                </template>
                <template v-slot:group.header="{group, toggle, isOpen}">
                    <td
                        :class="{open: isOpen}"
                        class="text-start cursor-pointer toggle-expanded"
                        @click="toggleExpanded(toggle)"
                    >
                        <div class="d-flex align-center">
                            <v-icon v-if="isB2B" class="cursor-pointer hidden-sm-and-down me-1">
                                {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                            <v-checkbox
                                v-if="selectable && !isHideCheckbox && !update"
                                :key="group"
                                v-model="selected"
                                :ripple="false"
                                :value="getOrder(group)"
                                :value-comparator="(orderA, orderB) => orderA.orderId === orderB.orderId"
                                dense
                                hide-details
                                @click.stop
                            />
                            <template v-if="selectable">
                                <nuxt-link
                                    no-prefetch
                                    :to="{name: 'order-details', params: {id: group}}"
                                    @click.native.stop
                                >
                                    <strong>{{ group }}</strong>
                                </nuxt-link>
                                <span v-if="!orderTitleEdit[group]" class="ml-2">
                                    {{ getOrder(group).title }}
                                    <v-icon
                                        small
                                        color="primary"
                                        @click.stop="
                                            $set(orderTitleEdit, group, true)
                                            $set(orderTitle, group, getOrder(group).title)
                                        "
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </span>
                                <div v-else class="d-flex align-center ml-4">
                                    <v-text-field
                                        v-model="orderTitle[group]"
                                        dense
                                        style="min-width: 80px"
                                        hide-details
                                        class="order-title-edit"
                                    />
                                    <v-btn
                                        small
                                        class="ml-1"
                                        color="primary"
                                        :loading="orderTitleEditLoading[group]"
                                        @click.stop="saveOrderTitle(group)"
                                    >
                                        {{ $t('save') }}
                                    </v-btn>
                                </div>
                            </template>
                            <nuxt-link v-else no-prefetch :to="{name: 'order-details', params: {id: group}}">
                                <strong>{{ group }}</strong>
                            </nuxt-link>
                        </div>
                    </td>
                    <template v-if="isB2B">
                        <template v-if="!hideHeaders.created">
                            <td v-if="!isOpen" class="cursor-pointer" @click="toggleExpanded(toggle)">
                                <div v-text="groupedOrder(group).created" />
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="!hideHeaders.modified">
                            <td v-if="!isOpen" class="cursor-pointer">
                                <div v-text="groupedOrder(group).modified" />
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="isTO1 && !hideHeaders.clientCompanyName">
                            <td v-if="!isOpen" class="cursor-pointer" @click="toggleExpanded(toggle)">
                                <strong v-text="groupedOrder(group).clientCompanyName" />
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="!isShowOrderStatusByServicesTA && !hideHeaders.status">
                            <td v-if="!isOpen" class="cursor-pointer" @click="toggleExpanded(toggle)">
                                <strong :class="groupedOrder(group).statusColor">
                                    {{ groupedOrder(group).statusText }}
                                </strong>
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="isShowOrderStatusByServicesTA && !hideHeaders.status">
                            <td v-if="!isOpen" class="cursor-pointer" @click="toggleExpanded(toggle)">
                                <strong :class="groupedOrder(group).statusByServicesColor">
                                    {{ groupedOrder(group).statusByServicesText }}
                                </strong>
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="!hideHeaders.title">
                            <td v-if="!isOpen" class="cursor-pointer" @click="toggleExpanded(toggle)">
                                <div class="d-flex flex-column">
                                    <div class="d-flex">
                                        <div class="me-1" v-html="groupedOrder(group).pureTitle" />
                                        <v-icon v-for="(icon, i) in groupedOrder(group).icons" :key="i" small>
                                            {{ icon }}
                                        </v-icon>
                                    </div>
                                    <div v-html="groupedOrder(group).location" />
                                </div>
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="!hideHeaders.date">
                            <td v-if="!isOpen" class="text-center cursor-pointer" @click="toggleExpanded(toggle)">
                                <div class="text-no-wrap" v-html="groupedOrder(group).date" />
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="!hideHeaders.travellers">
                            <td v-if="!isOpen" class="cursor-pointer" @click="toggleExpanded(toggle)">
                                <span v-html="groupedOrder(group).travelers" />
                            </td>
                            <td v-else />
                        </template>
                        <td
                            v-if="!hideHeaders.supplierPrice && tableColumns.some(t => t.value === 'supplierPrice')"
                            class="cursor-pointer py-1 text-right"
                            @click="toggleExpanded(toggle)"
                        >
                            <template v-if="!groupedOrder(group).isEmptyOrder">
                                <div
                                    v-if="isShowOrderNettoPrice(groupedOrder(group))"
                                    v-html="groupedOrder(group).netto.title"
                                />
                                <div v-if="commissionAndNettoCheckbox && groupedOrder(group).netto.commissionAmount">
                                    <div class="commission text-no-wrap">
                                        {{ $t('commission') }}:
                                        {{
                                            groupedOrder(group).netto.commissionAmount
                                                | priceFormat(groupedOrder(group).netto.currency)
                                        }}
                                    </div>
                                    <div class="commission text-no-wrap">
                                        {{ $t('net_amount') }}:
                                        {{
                                            (groupedOrder(group).netto.amount -
                                                groupedOrder(group).netto.commissionAmount)
                                                | priceFormat(groupedOrder(group).netto.currency)
                                        }}
                                    </div>
                                </div>
                                <payment-status-label
                                    v-if="
                                        showPaymentAndInvoiceStatus &&
                                        showNettoPaymentStatus &&
                                        !isShowRequestedOrderNettoPaymentStatus(groupedOrder(group))
                                    "
                                    :status="groupedOrder(group).netto.status"
                                />
                                <span
                                    v-if="isShowRequestedOrderNettoPaymentStatus(groupedOrder(group))"
                                    class="warning--text"
                                >
                                    {{ $t('requested') }}
                                </span>
                            </template>
                        </td>
                        <td
                            v-if="!hideHeaders.price && !isAgency && tableColumns.some(t => t.value === 'price')"
                            class="cursor-pointer py-1 text-right"
                            @click="toggleExpanded(toggle)"
                        >
                            <template v-if="!groupedOrder(group).isEmptyOrder">
                                <div
                                    v-if="isShowOrderBruttoPrice(groupedOrder(group))"
                                    v-html="groupedOrder(group).brutto.title"
                                />
                                <div v-if="commissionAndNettoCheckbox && groupedOrder(group).brutto.commissionAmount">
                                    <div class="commission text-no-wrap">
                                        {{ $t('commission') }}:
                                        {{
                                            groupedOrder(group).brutto.commissionAmount
                                                | priceFormat(groupedOrder(group).brutto.currency)
                                        }}
                                    </div>
                                    <div class="commission text-no-wrap">
                                        {{ $t('net_amount') }}:
                                        {{
                                            (groupedOrder(group).brutto.amount -
                                                groupedOrder(group).brutto.commissionAmount)
                                                | priceFormat(groupedOrder(group).brutto.currency)
                                        }}
                                    </div>
                                </div>
                                <payment-status-label
                                    v-if="
                                        showPaymentAndInvoiceStatus &&
                                        !isShowRequestedOrderBruttoPaymentStatus(groupedOrder(group))
                                    "
                                    :status="groupedOrder(group).brutto.status"
                                />
                                <span
                                    v-if="isShowRequestedOrderBruttoPaymentStatus(groupedOrder(group))"
                                    class="warning--text"
                                >
                                    {{ $t('requested') }}
                                </span>
                            </template>
                        </td>
                        <td
                            v-if="!hideHeaders.dueToPay && tableColumns.some(t => t.value === 'dueToPay')"
                            class="cursor-pointer text-right"
                            @click="toggleExpanded(toggle)"
                        >
                            <template v-if="!groupedOrder(group).isEmptyOrder">
                                <div
                                    v-for="due in isAgency
                                        ? groupedOrder(group).netto.dueToPayArray
                                        : groupedOrder(group).brutto.dueToPayArray"
                                    :key="due"
                                    v-text="due"
                                />
                            </template>
                        </td>
                        <td v-if="!hideHeaders.income && commissionAndNettoCheckbox" class="text-right">
                            <div v-if="groupedOrder(group).income">
                                <div>
                                    {{
                                        groupedOrder(group).income.amount
                                            | priceFormat(groupedOrder(group).income.currency)
                                    }}
                                </div>
                                <div v-if="groupedOrder(group).income.percent" class="grey--text">
                                    {{ `${groupedOrder(group).income.percent}%` }}
                                </div>
                            </div>
                        </td>
                        <template
                            v-if="
                                showPaymentAndInvoiceStatus &&
                                !hideHeaders.invoice &&
                                $config.account.showOrderInvoiceBtn
                            "
                        >
                            <td v-if="!isOpen" class="text-center cursor-pointer" @click="toggleExpanded(toggle)">
                                <v-btn
                                    v-if="groupedOrder(group).invoiceOrderAvailable"
                                    class="cursor-pointer"
                                    text
                                    style="text-transform: unset !important"
                                    @click.stop="
                                        isAgency
                                            ? showInvoices('SUPPLIER', groupedOrder(group))
                                            : showInvoices('CUSTOMER', groupedOrder(group))
                                    "
                                >
                                    <div class="d-flex flex-column">
                                        <v-icon class="mt-1" dense>mdi-file-download</v-icon>
                                        <span class="text-caption mt-n1" style="font-size: 10px !important">
                                            {{ $t('invoices.invoice') }}
                                        </span>
                                    </div>
                                </v-btn>
                            </td>
                            <td v-else />
                        </template>
                        <template v-if="!hideHeaders.voucher">
                            <td v-if="!isOpen" class="text-center cursor-pointer" @click="toggleExpanded(toggle)">
                                <v-btn
                                    v-if="groupedOrder(group).voucherOrderAvailable"
                                    class="cursor-pointer"
                                    text
                                    :loading="voucherLoading === groupedOrder(group).orderId"
                                    style="text-transform: unset !important"
                                    @click.stop="downloadVoucher(undefined, groupedOrder(group).orderId)"
                                >
                                    <div class="d-flex flex-column">
                                        <v-icon class="mt-1" dense>mdi-file-download</v-icon>
                                        <span class="text-caption mt-n1" style="font-size: 10px !important">
                                            {{ $t('voucher') }}
                                        </span>
                                    </div>
                                </v-btn>
                            </td>
                            <td v-else />
                        </template>
                    </template>
                    <template v-if="isB2C">
                        <td v-if="!hideHeaders.created" />
                        <td v-if="!hideHeaders.modified" />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td class="cursor-pointer py-1 text-right" @click="toggleExpanded(toggle)">
                            <template v-if="!groupedOrder(group).isEmptyOrder">
                                <div
                                    v-if="isShowOrderBruttoPrice(groupedOrder(group))"
                                    v-html="groupedOrder(group).brutto.title"
                                />
                                <div v-if="commissionAndNettoCheckbox && groupedOrder(group).brutto.commissionAmount">
                                    <div class="commission text-no-wrap">
                                        {{ $t('commission') }}:
                                        {{
                                            groupedOrder(group).brutto.commissionAmount
                                                | priceFormat(groupedOrder(group).brutto.currency)
                                        }}
                                    </div>
                                    <div class="commission text-no-wrap">
                                        {{ $t('net_amount') }}:
                                        {{
                                            (groupedOrder(group).brutto.amount -
                                                groupedOrder(group).brutto.commissionAmount)
                                                | priceFormat(groupedOrder(group).brutto.currency)
                                        }}
                                    </div>
                                </div>
                                <payment-status-label
                                    v-if="
                                        showPaymentAndInvoiceStatus &&
                                        !isShowRequestedOrderBruttoPaymentStatus(groupedOrder(group))
                                    "
                                    :status="groupedOrder(group).brutto.status"
                                />
                                <span
                                    v-if="isShowRequestedOrderBruttoPaymentStatus(groupedOrder(group))"
                                    class="warning--text"
                                >
                                    {{ $t('requested') }}
                                </span>
                            </template>
                        </td>
                        <td v-if="!hideHeaders.invoice" />
                        <td />
                    </template>
                </template>
                <template v-slot:item.mobile="{item}">
                    <orders-service-mobile
                        :service="item"
                        @open-voucher-dialog="openVoucherDialog"
                        @show-add-service-modal="showAddServiceModal"
                    />
                </template>
                <template v-slot:item.created="{item}">
                    <div class="text-no-wrap" v-html="item.created" />
                </template>
                <template v-slot:item.modified="{item}">
                    <div class="text-no-wrap" v-html="item.modified" />
                </template>
                <template v-slot:item.title="{item}">
                    <div v-if="!item.isEmptyOrder" class="d-flex flex-column">
                        <span class="d-flex justify-end justify-md-start">
                            <v-icon small class="mr-1">{{ getServiceTypeIcon(item.serviceType) }}</v-icon>
                            <div v-html="item.pureTitle" />
                        </span>
                        <div v-html="item.title + item.location" />
                    </div>
                    <v-btn
                        v-if="!$config.account.hideAddServiceBtn && item.isEmptyOrder"
                        :color="'primary'"
                        small
                        class="mb-2"
                        @click="showAddServiceModal(item.orderId)"
                        v-text="$t('add_service')"
                    />
                </template>
                <template v-slot:item.date="{item}">
                    <div class="text-no-wrap" v-html="item.date" />
                </template>
                <template v-slot:item.travellers="{item}">
                    <span v-html="item.travellers" />
                </template>
                <template v-slot:item.status="{item}">
                    <div class="d-flex align-center">
                        <span :class="item.statusColor">{{ item.status }}</span>
                        <v-btn
                            v-if="item.statusOriginal && item.statusOriginal.toUpperCase() === 'QUOTE' && isTO1"
                            icon
                            small
                            @click.stop="deleteService(item)"
                        >
                            <v-icon small> mdi-trash-can-outline </v-icon>
                        </v-btn>
                    </div>

                    <div v-if="isTO1">
                        <span class="commission">
                            {{ item.supplierCompanyName }}
                        </span>
                    </div>
                </template>
                <template v-slot:item.statusByServices="{item}">
                    <span :class="item.statusColor">{{ item.status }}</span>
                    <div v-if="isTO1">
                        <span class="commission">
                            {{ item.supplierCompanyName }}
                        </span>
                    </div>
                </template>
                <template
                    v-if="!hideHeaders.supplierPrice && tableColumns.some(t => t.value === 'supplierPrice')"
                    v-slot:item.supplierPrice="{item}"
                >
                    <orders-supplier-price-label
                        v-if="!item.isEmptyOrder && item.showAllServicesPrice"
                        :item="item"
                        :show-payment-status="showNettoPaymentStatus && showPaymentAndInvoiceStatus"
                    />
                </template>
                <template v-if="!hideHeaders.price" v-slot:item.price="{item}">
                    <orders-price-label
                        v-if="!item.isEmptyOrder && item.showAllServicesPrice"
                        :show-payment-status="showPaymentAndInvoiceStatus"
                        :item="item"
                    />
                </template>
                <template v-slot:item.income="{item}">
                    <div v-if="!item.isEmptyOrder && item.income.amount && item.showAllServicesPrice">
                        {{ item.income.amount | priceFormat(item.income.currency) }}
                        <div v-if="item.income.percent" class="grey--text">
                            {{ `${item.income.percent}%` }}
                        </div>
                    </div>
                </template>
                <template v-slot:item.clientCompanyName="{item}">
                    <strong>{{ item.clientCompanyName }}</strong>
                    <small>{{ item.agent }}</small>
                </template>
                <template v-slot:item.voucher="{item}">
                    <v-btn
                        v-if="!item.isEmptyOrder && item.voucherAvailable"
                        class="cursor-pointer"
                        text
                        style="text-transform: unset !important"
                        @click="openVoucherDialog(item)"
                    >
                        <div class="d-flex flex-column">
                            <v-icon dense class="mt-1">mdi-file-download</v-icon>
                            <span class="text-caption mt-n1" style="font-size: 10px !important">
                                {{ $t('voucher') }}
                            </span>
                        </div>
                    </v-btn>
                </template>
            </v-data-table>
            <voucher-dialog
                v-model="isShowVoucherDialog"
                :is-loading="voucherLoading"
                :title="$t('voucher')"
                @download-voucher="downloadVoucher(voucherService)"
                @send-on-email="sendVoucherOnEmail(voucherService, $event)"
            />
            <order-add-service-modal
                v-if="addServiceOrder"
                v-model="isShowOrderAddServiceModal"
                :order="addServiceOrder"
            />
            <invoices-modal @issuedInvoice="onIssuedInvoice" />
            <delete-service-dialog
                v-if="serviceToDelete"
                v-model="isShowDeleteServiceDialog"
                :process-id="serviceToDelete.processId"
                :service-name="serviceToDelete.serviceName"
                @update-order="load"
            />
        </div>
    </client-only>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import goTo from 'vuetify/es5/services/goto'
    import {clean, getOrderTravelers, getServiceTypeIcon, matches} from '@/utils/helpers'
    import LoadDataStatusMixin from '@/mixins/LoadDataStatusMixin'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import OrdersPriceLabel from '~src/components/account/snippets/ordersPriceLabel.src'
    import OrdersSupplierPriceLabel from '~src/components/account/snippets/ordersSupplierPriceLabel.src'
    import VoucherMixin from '@/components/account/mixins/VoucherMixin'
    import {convertPrice, priceFormat} from '@/utils/filters'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import OrdersServiceMobile from '@/components/account/OrdersServiceMobile'
    import OrderAddServiceModal from '~src/components/account/modals/orderAddServiceModal.src'
    import {format} from 'date-fns'
    import {EventBus, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import InvoicesModal from '~src/components/modals/invoicesModal.src'
    import OrderDetailsSummary from '~src/components/account/orderDetailsSummary.src'
    import DeleteServiceDialog from '@/components/account/orderServices/DeleteServiceDialog'

    @Component({
        components: {
            DeleteServiceDialog,
            InvoicesModal,
            OrdersServiceMobile,
            OrderAddServiceModal,
            PaymentStatusLabel,
            CommissionAmount,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
            OrdersPriceLabel,
            OrdersSupplierPriceLabel,
        },
    })
    export default class Orders extends mixins(LoadDataStatusMixin, VoucherMixin) {
        @VModel() selected
        @Prop({default: false}) selectable
        @Prop({default: false}) isExpandedAll
        @Prop() tableColumns

        loaded = false
        orderTitleEdit = {}
        orderTitleEditLoading = {}
        orderTitle = {}
        autoOrdersUpdate = null
        isHideCheckbox = false
        addServiceOrder = null
        isShowVoucherDialog = false
        isShowOrderAddServiceModal = false
        sortOrder = 'none'

        selectAll = false
        update = false

        invoiceType = null
        invoiceOrder = null

        isShowDeleteServiceDialog = false
        serviceToDelete = null

        @Watch('selected.length')
        onSelectedChange() {
            const selectedOrderIds = this.selected.map(({orderId}) => orderId)
            this.selectAll = runtimeStore.ordersResponse.orders.every(({orderId}) => selectedOrderIds.includes(orderId))
        }

        @Watch('isExpandedAll')
        toggleAll() {
            if (this.isExpandedAll) {
                this.expandAll()
            } else {
                this.collapseAll()
            }
        }

        changeSelectAll(selectAll) {
            if (selectAll) {
                runtimeStore.ordersResponse.orders.forEach(order => {
                    const index = this.selected.findIndex(({orderId}) => orderId === order.orderId)
                    if (index === -1) {
                        this.selected.push(order)
                    }
                })
            } else {
                runtimeStore.ordersResponse.orders.forEach(order => {
                    const index = this.selected.findIndex(({orderId}) => orderId === order.orderId)
                    if (index !== -1) {
                        this.selected.splice(index, 1)
                    }
                })
            }
        }

        async mounted() {
            if (this.isB2B) {
                this.isHideCheckbox = true
            }
            persistentStore.SET_ORDERS_TABLE_OPTIONS({...this.options, itemsPerPage: this.ordersOnPage})
            await this.$store.restored
            this.toggleAll()
            await this.load()
            this.autoOrdersUpdate = setInterval(() => {
                this.loadOrders(true)
            }, 150000)
        }

        async load() {
            this.loaded = true
            await this.loadOrders()
        }

        beforeDestroy() {
            clearInterval(this.autoOrdersUpdate)
            if (this.$route.name !== 'order-details') {
                persistentStore.SET_ORDERS_TABLE_OPTIONS({
                    itemsPerPage: persistentStore.ordersTableOptions.itemsPerPage,
                    page: 1,
                })
            }
        }

        async onIssuedInvoice() {
            await this.loadOrders(true)
            this.$nextTick(() => {
                this.toggleExpanded(() => {})
                this.toggleExpanded(() => {})
            })
            this.showInvoices(this.invoiceType, this.invoiceOrder)
        }

        deleteService(service) {
            this.serviceToDelete = service
            this.isShowDeleteServiceDialog = true
        }

        showInvoices(invoiceType, order) {
            this.invoiceType = invoiceType
            this.invoiceOrder = order

            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: order.orderId,
                objectId: order.objectId,
                invoiceType,
                order,
            })
        }

        showAddServiceModal(orderId) {
            this.addServiceOrder = {orderId, services: []}
            this.isShowOrderAddServiceModal = true
        }

        toggleExpanded(toggle) {
            if (this.isB2B && !this.isMobile) {
                this.isHideCheckbox = true
                toggle()
                this.$nextTick(() => {
                    this.isHideCheckbox = false
                })
            }
        }

        collapseAll() {
            if (this.isB2B && !this.isMobile && this.$el.querySelectorAll) {
                const toggleButtons = [...this.$el.querySelectorAll('.toggle-expanded')]

                toggleButtons.forEach(e => {
                    if (e.classList.contains('open')) {
                        e.click()
                    }
                })
            }
        }

        expandAll() {
            if (this.isB2B && !this.isMobile && this.$el.querySelectorAll) {
                const toggleButtons = [...this.$el.querySelectorAll('.toggle-expanded')]

                toggleButtons.forEach(e => {
                    if (!e.classList.contains('open')) {
                        e.click()
                    }
                })
            }
        }

        groupedOrder(orderId) {
            const order = {
                ...runtimeStore.ordersResponse.orders.find(e => e.orderId === orderId),
            }

            order.statusText = order.status
                ? this.$t(`order_statuses.${order.status.toUpperCase().replace(/[\s.]/g, '_')}`)
                : ''
            order.statusColor = order.status ? runtimeStore.orderStatusColor(order.status) : ''

            order.created = this.$options.filters.dateShortFormat(order.created)

            order.modified = this.$options.filters.dateShortFormat(order.modified)

            order.voucherOrderAvailable = order.services.some(service => this.voucherAvailable(service))

            order.invoiceOrderAvailable =
                !OrderDetailsSummary.options.methods.gotPayAtHotelService.call(null, order) && order.status !== 'NEW'

            if (!order.services?.length) {
                return {...order, isEmptyOrder: true}
            }

            order.services = this.getSortedServices(order.services)

            const statusByServices = order.services?.every(e => e.status === 'CONFIRMED')
                ? 'CONFIRMED'
                : order.services?.every(e => e.status === 'CANCELED')
                ? 'CANCELED'
                : order.services?.every(e => e.status === 'REJECTED')
                ? 'REJECTED'
                : order.services?.some(e => e.status === 'ERROR')
                ? 'ERROR'
                : order.services?.some(e => e.status === 'CONFIRMATION PENDING' || e.status === 'PENDING CONFIRMATION')
                ? 'CONFIRMATION_PENDING'
                : order.services?.some(e => e.status === 'PRECONFIRMED')
                ? 'PRECONFIRMED'
                : undefined

            order.statusByServicesText = statusByServices ? this.$t(`bookingStatus.${statusByServices}`) : ''
            order.statusByServicesColor = statusByServices ? runtimeStore.orderServiceStatusColor(statusByServices) : ''

            order.icons = []
            order.netto = {amount: 0, commissionAmount: 0, dueToPayArray: []}
            order.brutto = {amount: 0, commissionAmount: 0, dueToPayArray: []}
            order.income = {amount: 0, percent: 0}

            order.services = this.getSortedServices(order.services)

            const tourLead = order.services[0]?.travelers?.find(e => e.isTourLead) || order.services[0]?.travelers[0]

            const datesStart = order?.services
                .filter(s => s.serviceDetails?.[0]?.extraServiceProductType !== 'BANK_FEE')
                .map(service => {
                    return new Date(service.startDateTime?.slice(0, 10))?.getTime()
                })
                .sort()
            const datesEnd = order?.services
                .filter(s => s.serviceDetails?.[0]?.extraServiceProductType !== 'BANK_FEE')
                .map(service => {
                    return new Date(service.endDateTime?.slice(0, 10))?.getTime()
                })
                .sort()
            const minDate = datesStart[0]
            const maxDate = datesEnd[datesEnd.length - 1]

            const netto = order.orderPrices.find(e => e.type === 'SUPPLIER').orderPrices
            const brutto = order.orderPrices.find(e => e.type === 'CLIENT').orderPrices

            const getFullName = t =>
                t ? `${t.prefix || ''} ${t.name?.[0]?.firstName || ''} ${t.name?.[0]?.lastName || ''}`.trim() : null

            const orderTravelers = this.getOrderTravelers(order)

            order.travelers = `
                ${tourLead ? getFullName(tourLead) || this.$t('lead_guest') : ''}
                ${orderTravelers.length > 1 ? '(+' + (orderTravelers.length - 1) + ')' : ''}
            `

            if (order.orderPackage) {
                order.pureTitle = `<strong>${order.orderPackage.packageName}</strong>`
            } else {
                order.pureTitle = `<strong>${order.services[0].serviceName}</strong>`
            }

            order.date = `${this.$options.filters.dateShortFormat(minDate)}<br>${this.$options.filters.dateShortFormat(
                maxDate
            )}`

            order.services.forEach(e => {
                let icon = this.getServiceTypeIcon(e.serviceType)

                if (e.orderPackage) {
                    icon = this.getServiceTypeIcon(order.orderPackage.packageType)
                }

                if (!order.icons.includes(icon)) {
                    order.icons.push(icon)
                }
            })

            netto.forEach(e => {
                const convertedPrice = convertPrice(e)
                const convertedCommission = convertPrice({amount: e.commission, currency: e.currency})

                if (!order.netto.currency) {
                    order.netto.currency = convertedPrice.currency
                }
                order.netto.amount += convertedPrice.amount
                order.netto.commissionAmount += convertedCommission.amount
                order.netto.dueToPayArray.push(priceFormat(e.dueToPay, e.currency))
            })

            brutto.forEach(e => {
                const convertedPrice = convertPrice(e)
                const convertedCommission = convertPrice({amount: e.commission, currency: e.currency})

                if (!order.brutto.currency) {
                    order.brutto.currency = convertedPrice.currency
                }
                order.brutto.amount += convertedPrice.amount
                order.brutto.commissionAmount += convertedCommission.amount
                order.brutto.dueToPayArray.push(priceFormat(e.dueToPay, e.currency))
            })

            order.netto.title = `${priceFormat(order.netto.amount, order.netto.currency)}`
            order.netto.status = order.supplierPaymentStatus

            order.brutto.title = `${priceFormat(order.brutto.amount, order.brutto.currency)}`
            order.brutto.status = order.customerPaymentStatus

            const systemCurrency = authStore.systemCurrency || persistentStore.currency
            order.income.currency = systemCurrency
            order.services.forEach(service => {
                const servicePrepared = this.services.find(s => s.processId === service.processId)

                if (servicePrepared) {
                    const serviceIncomePrice = servicePrepared.income

                    const convertedserviceIncomePrice = convertPrice(serviceIncomePrice, systemCurrency)
                    order.income.amount += convertedserviceIncomePrice.amount
                }
            })

            let amountFx
            let currencyFx

            if (this.$config.account.paymentScheduler) {
                amountFx = order.fxGainLoss || 0
                currencyFx = order.orderPrices.find(item => item.type === 'CLIENT').orderPrices[0].currency
            } else {
                amountFx = order.services.reduce((total, service) => {
                    total += service.serviceExchangeRates?.fxGainLoss ? service.serviceExchangeRates.fxGainLoss : 0

                    return total
                }, 0)
                currencyFx = order.orderPrices.find(el => el.type === 'CLIENT').orderPrices[0].currency
            }

            const fxGainLossConverted = convertPrice(
                {
                    amount: amountFx,
                    currency: currencyFx,
                },
                systemCurrency
            )
            const convertedBruttoPrice = convertPrice(order.brutto, systemCurrency)

            order.income.amount += fxGainLossConverted.amount
            const percent = (order.income.amount * 100) / convertedBruttoPrice.amount
            order.income.percent = Math.round(percent * 100) / 100

            return order
        }

        getOrderTravelers(order) {
            return getOrderTravelers(order)
        }

        toggleSortOrder() {
            if (this.sortOrder === 'none') this.sortOrder = 'ASCENDING'
            else if (this.sortOrder === 'ASCENDING') this.sortOrder = 'DESCENDING'
            else this.sortOrder = 'none'

            this.loadOrders()
            this.$emit('change-sort-order', this.sortOrder)
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO2() {
            return authStore.isTO2
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get showCancelledServices() {
            return persistentStore.showCancelledServices
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get options() {
            return persistentStore.ordersTableOptions
        }

        set options(options) {
            if (!this.loaded) return
            const prev = persistentStore.ordersTableOptions
            if (!prev || matches(options, prev)) return
            persistentStore.SET_ORDERS_TABLE_OPTIONS(options)
            this.loadOrders()
        }

        filter() {
            if (this.options.page !== 1) {
                persistentStore.SET_ORDERS_TABLE_OPTIONS(Object.assign({}, this.options, {page: 1}))
            }
            this.loadOrders()
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        openVoucherDialog(service) {
            this.isShowVoucherDialog = true
            this.voucherService = service
        }

        voucherAvailable(service) {
            return runtimeStore.isServiceVoucherAvailable(service)
        }

        get filters() {
            return persistentStore.orderFilters
        }

        get isLoading() {
            return runtimeStore.loading
        }

        get services() {
            const orderServices = []
            const orders = runtimeStore.ordersResponse.orders.concat()
            if (this.sortOrder === 'none') {
                orders.sort((o1, o2) =>
                    this.$dateFns.isAfter(this.$dateFns.parseISO(o1.modified), this.$dateFns.parseISO(o2.modified))
                        ? -1
                        : 1
                )
            }
            const travellersLabel = service => {
                if (
                    !service ||
                    !service.travelers ||
                    !service.travelers.length ||
                    (!service.travelers[0].name[0].firstName && !service.travelers[0].name[0].lastName)
                )
                    return '-'
                let travellers = `${service.travelers[0].prefix || ''} ${
                    service.travelers[0].name[0].firstName || ''
                } ${service.travelers[0].name[0].lastName || ''}`
                if (service.travelers.length > 1) {
                    travellers += ` (+${service.travelers.length - 1})`
                }
                return travellers
            }
            orders.forEach(order => {
                let sortedServices

                if (!this.showCancelledServices) {
                    sortedServices = this.getSortedServices(
                        order.services.filter(
                            service =>
                                service.status.toLowerCase() !== 'canceled' ||
                                (service.status.toLowerCase() === 'canceled' &&
                                    (service.salesTerms.find(item => item.type === 'CLIENT').price.amount !== 0 ||
                                        service.salesTerms.find(item => item.type === 'SUPPLIER').price.amount !== 0))
                        )
                    )
                } else sortedServices = this.getSortedServices(order.services)

                if (!order.services?.length) {
                    orderServices.push({
                        orderId: order.orderId,
                        created: this.$options.filters.dateShortFormat(order.created),
                        modified: this.$options.filters.dateShortFormat(order.modified),
                        statusColor: order.status
                            ? runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(order.status))
                            : '',
                        isEmptyOrder: true,
                    })
                }

                if (order.orderPackage) {
                    orderServices.push({
                        orderId: order.orderId,
                        created: this.$options.filters.dateShortFormat(order.created),
                        modified: this.$options.filters.dateShortFormat(order.modified),
                        clientCompanyName: order.clientCompanyName,
                        pureTitle: `<strong>${order.orderPackage.packageName}</strong><br>`,
                        title: '',
                        serviceType: order.orderPackage.packageType,
                        location: '',
                        date: this.isMobile
                            ? `${this.$options.filters.dateShortFormat(
                                  order.orderPackage.startDateTime
                              )} - ${this.$options.filters.dateShortFormat(order.orderPackage.endDateTime)}`
                            : `${this.$options.filters.dateShortFormat(
                                  order.orderPackage.startDateTime
                              )}<br>${this.$options.filters.dateShortFormat(order.orderPackage.endDateTime)}`,
                        travellers: travellersLabel(order.services[0]),
                        status: order.orderPackage.status
                            ? this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(order.orderPackage.status)}`)
                            : '',
                        statusColor: order.orderPackage.status
                            ? runtimeStore.orderServiceStatusColor(
                                  runtimeStore.orderServiceStatus(order.orderPackage.status)
                              )
                            : '',
                        supplierCompanyName: order.orderPackage.supplierCompanyName,
                        supplier: {
                            paymentStatus: order.orderPackage.supplierPaymentStatus,
                            price: order.orderPackage.supplierPrice,
                        },
                        customer: {
                            paymentStatus: order.orderPackage.customerPaymentStatus,
                            price: order.orderPackage.clientPrice,
                        },
                        violation: null,
                        corporatePolicies: [],
                        income: {
                            amount: 0,
                        },
                        showAllServicesPrice:
                            this.isTO1 || order.clientPriceType === 'FOR_ALL_SERVICES' || !order.clientPriceType,
                        voucherAvailable: this.voucherAvailable(order.orderPackage),
                    })

                    sortedServices = sortedServices.filter(s => !s.serviceContainerId)
                }

                sortedServices.forEach(service => {
                    let location = '',
                        pureTitle = `<strong>${service.serviceName}</strong>`,
                        title = '',
                        date = this.$options.filters.dateShortFormat(service.startDateTime),
                        created = this.$options.filters.dateShortFormat(service.created),
                        modified = this.$options.filters.dateShortFormat(order.modified),
                        countryName,
                        cityName,
                        travelersMealType
                    //const transferPlace = place => place.address || place.airportCode || ''
                    switch (service.serviceType) {
                        case 'ACCOMMODATION':
                            travelersMealType = [
                                ...new Set(
                                    service.travelers.reduce((mealTypes, traveler) => {
                                        if (traveler.mealType) {
                                            mealTypes.push(traveler.mealType)
                                        }
                                        return mealTypes
                                    }, [])
                                ),
                            ].join(', ')
                            service.serviceDetailsContainer.accommodation.rooms.forEach(room => {
                                title += `${room.roomTypeName}, ${travelersMealType || room.mealTypeName}<br>`
                            })
                            countryName = service.serviceDetailsContainer.accommodation.countryName
                                ? service.serviceDetailsContainer.accommodation.countryName
                                : ''
                            cityName = service.serviceDetailsContainer.accommodation.cityName
                                ? service.serviceDetailsContainer.accommodation.cityName
                                : ''
                            location = []
                            if (countryName) {
                                location.push(countryName)
                            }
                            if (cityName) {
                                location.push(cityName)
                            }
                            location = location.join(', ')
                            date +=
                                `${this.isMobile ? ' - ' : '<br>'}` +
                                this.$options.filters.dateShortFormat(service.endDateTime)
                            break
                        case 'FLIGHT':
                            location = service.serviceDetailsContainer.flight.flightName
                            break
                        case 'ACTIVITY':
                            location = service.serviceDetailsContainer.activity.location
                            break
                        case 'TRANSFER':
                            break
                        case 'CARRENT':
                            date +=
                                `${this.isMobile ? ' - ' : '<br>'}` +
                                this.$options.filters.dateShortFormat(service.endDateTime)
                            break
                    }
                    const orderService = {
                        orderId: order.orderId,
                        created: created,
                        modified,
                        clientCompanyName: order.clientCompanyName,
                        pureTitle,
                        title,
                        serviceType: service.serviceType,
                        location,
                        date,
                        travellers: travellersLabel(service),
                        //TODO [API] Fix statuses
                        status: service.status
                            ? this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(service.status)}`)
                            : '',
                        statusColor: service.status
                            ? runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(service.status))
                            : '',
                        statusOriginal: service.status,
                        supplierCompanyName: service.supplierCompanyName,
                        supplier: {
                            paymentStatus: service.supplierPaymentStatus,
                            cardGuarantee: service.serviceDetailsContainer.accommodation
                                ? service.serviceDetailsContainer.accommodation.cardGuarantee
                                : false,
                            price: service.salesTerms.find(salesTerm => salesTerm.type === 'SUPPLIER').price,
                        },
                        customer: {
                            paymentStatus: service.customerPaymentStatus,
                            price: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price,
                        },
                        violation: service.violation,
                        corporatePolicies: service.corporatePolicies || [],
                        processId: service.processId,
                        serviceName: service.serviceName,
                        income: {},
                        showAllServicesPrice:
                            this.isTO1 || order.clientPriceType === 'FOR_ALL_SERVICES' || !order.clientPriceType,
                        ...(service.customerPenaltiesTooltip && {
                            customerPenaltiesTooltip: service.customerPenaltiesTooltip,
                        }),
                        ...(service.customerPenaltyStatus && {
                            customerPenaltyStatus: service.customerPenaltyStatus.toLowerCase(),
                        }),
                        voucherAvailable: this.voucherAvailable(service),
                    }

                    // TODO: temporary solution, need fix on the backend
                    if (this.isAgency && !orderService.customer.price.commission)
                        orderService.customer.price.commission = orderService.supplier.price.commission

                    orderService.income.currency = orderService.customer.price.currency

                    let convertNettoAmountByXr, convertNettoCommisionByXr

                    const convertBruttoPrice = orderService.customer.price
                    const convertBruttoCommision = {
                        amount: orderService.customer.price.commission,
                        currency: orderService.customer.price.currency,
                    }

                    if (this.showExchangeRates && service.serviceExchangeRates?.bookingXR) {
                        convertNettoAmountByXr = {
                            amount: orderService.supplier.price.amount * service.serviceExchangeRates?.bookingXR,
                            currency: orderService.customer.price.currency,
                        }

                        convertNettoCommisionByXr = {
                            amount: orderService.supplier.price.commission * service.serviceExchangeRates?.bookingXR,
                            currency: orderService.customer.price.currency,
                        }
                    } else {
                        convertNettoAmountByXr = convertPrice(
                            orderService.supplier.price,
                            orderService.customer.price.currency
                        )
                        convertNettoCommisionByXr = convertPrice(
                            {
                                amount: orderService.supplier.price.commission,
                                currency: orderService.supplier.price.currency,
                            },
                            orderService.customer.price.currency
                        )
                    }

                    if (this.isAgency) {
                        orderService.income.amount = convertBruttoCommision.amount
                    } else {
                        orderService.income.amount =
                            convertBruttoPrice.amount -
                            convertBruttoCommision.amount -
                            (convertNettoAmountByXr.amount - convertNettoCommisionByXr.amount)
                    }

                    const percent = (orderService.income.amount * 100) / convertBruttoPrice.amount
                    orderService.income.percent = Math.round(percent * 100) / 100

                    const systemCurrency = authStore.systemCurrency || persistentStore.currency
                    orderService.income = convertPrice(orderService.income, systemCurrency)

                    orderServices.push(orderService)
                })
            })
            return orderServices.map(service => Object.freeze(service))
        }

        get showExchangeRates() {
            return this.$config.showExchangeRates && this.isTO1
        }

        get isShowOrderStatusByServicesTA() {
            return this.$config.account.showOrderStatusByServicesTA && (this.isAgency || this.isTO2)
        }

        get headers() {
            if (this.isMobile) return [{value: 'mobile', show: 'mobile'}]

            return this.tableColumns.filter(item => item.show)
        }

        // TODO: need to refactor
        get hideHeaders() {
            return this.tableColumns.reduce((headers, item) => {
                if (!item.show) headers[item.value] = true

                return headers
            }, {})
        }

        get ordersOnPage() {
            return this.isB2B ? 50 : this.$config.account.ordersOnPage
        }

        get isB2B() {
            return authStore.isB2B
        }

        get isB2C() {
            return authStore.isB2C
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get ordersCount() {
            return runtimeStore.ordersResponse.ordersCount
        }

        get isTO1() {
            return authStore.isTO1
        }

        get personRole() {
            return authStore.personRole
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }

        get showNettoPaymentStatus() {
            return this.personRole !== 'dispatcher'
        }

        async loadOrders(update = false) {
            const options = persistentStore.ordersTableOptions
            const rq = Object.assign(
                {
                    orderType: authStore.orderType,
                    responseType: 'SHORT',
                    limit: this.options.itemsPerPage,
                    offset: this.options.itemsPerPage * (options.page - 1),
                },
                this.prepareFilters(this.filters)
            )

            if (this.sortOrder !== 'none') {
                rq.sortCriteria = 'earliestServiceDate'
                rq.sortOrder = this.sortOrder
                if (!rq.serviceStartDateFrom)
                    rq.serviceStartDateFrom = format(this.$dateFns.subYears(new Date(), 5), 'yyyy-MM-dd')
            }
            if (rq.orderId) {
                rq.orderId = parseInt(rq.orderId, 10)
            }
            if (rq.city) {
                rq.cityId = rq.city.id
                delete rq.city
            }
            if (rq.paymentMethod && rq.paymentMethod.name) {
                rq.paymentMethod = rq.paymentMethod.name
            }
            if (rq.supplier) {
                rq.supplierId = rq.supplier.id
                delete rq.supplier
            }
            if (rq.agent) {
                rq.agentId = rq.agent.personId
                delete rq.agent
            }
            if (rq.reservationManager) {
                rq.managerId = rq.reservationManager.personId
                delete rq.reservationManager
            }
            if (rq.serviceManager) {
                rq.serviceManagerId = rq.serviceManager.personId
                delete rq.serviceManager
            }
            if (rq.customerCompany) {
                rq.customerCompany = rq.customerCompany.name
            }
            if (rq.client) {
                rq.clientId = rq.client.personId
                delete rq.client
            }
            if (rq.tripOrganizer) {
                rq.tripOrganizerId = rq.tripOrganizer.personId
                delete rq.tripOrganizer
            }
            if (rq.waitingApprovalFromMyself) {
                rq.authorizeUserId = this.person.personId
                delete rq.waitingApprovalFromMyself
            }
            if (rq.waitAuthorize === false) {
                delete rq.waitAuthorize
            }
            if (rq.noSupplierRef === false) {
                delete rq.noSupplierRef
            }
            if (rq.supplierInvoiceStatuses.length) {
                rq.supplierPaymentStatuses = [...rq.supplierPaymentStatuses, ...rq.supplierInvoiceStatuses]
                delete rq.supplierInvoiceStatuses
            }
            if (rq.customerInvoiceStatuses.length) {
                rq.customerPaymentStatuses = [...rq.customerPaymentStatuses, ...rq.customerInvoiceStatuses]
                delete rq.customerInvoiceStatuses
            }
            const addTime = (rq, date, time) => {
                if (rq[date]) {
                    rq[date] += time
                }
            }
            addTime(rq, 'createdDateFrom', 'T00:00')
            addTime(rq, 'createdDateTo', 'T23:59')
            addTime(rq, 'serviceStartDateFrom', 'T00:00')
            addTime(rq, 'serviceStartDateTo', 'T23:59')
            addTime(rq, 'serviceEndDateFrom', 'T00:00')
            addTime(rq, 'serviceEndDateTo', 'T23:59')
            addTime(rq, 'modifiedDateFrom', 'T00:00')
            addTime(rq, 'modifiedDateTo', 'T23:59')
            addTime(rq, 'lastTicketingDateFrom', 'T00:00')
            addTime(rq, 'lastTicketingDateTo', 'T23:59')
            addTime(rq, 'customerPaymentDueDateFrom', 'T00:00')
            addTime(rq, 'customerPaymentDueDateTo', 'T00:00')
            addTime(rq, 'supplierPaymentDueDateFrom', 'T00:00')
            addTime(rq, 'supplierPaymentDueDateTo', 'T00:00')
            clean(rq)
            if (update) {
                await runtimeStore.loadOrders({rq, update})
                //TODO Workaround for checkboxes correct refresh
                this.update = true
                await this.$nextTick()
                this.update = false
            } else {
                goTo(0)
                this.loadDataError = !(await runtimeStore.loadOrders({rq}))
                this.showLoadDataStatusMessage()
            }
            this.toggleAll()
        }

        prepareFilters(filters) {
            return filters
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        getOrder(orderId) {
            return runtimeStore.ordersResponse.orders.find(order => order.orderId === orderId)
        }

        async saveOrderTitle(orderId) {
            this.orderTitleEditLoading[orderId] = true
            const data = {title: this.orderTitle[orderId]}
            try {
                await this.$api.orders.put(orderId, data)
                runtimeStore.UPDATE_ORDER({orderId, data})
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.orderTitleEditLoading[orderId] = false
                this.orderTitleEdit[orderId] = false
            }
        }

        get person() {
            return authStore.person
        }

        isShowOrderNettoPrice() {
            return true
        }

        isShowRequestedOrderNettoPaymentStatus() {
            return false
        }

        isShowOrderBruttoPrice() {
            return true
        }

        isShowRequestedOrderBruttoPaymentStatus() {
            return false
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    div::v-deep {
        tr {
            .v-data-table__mobile-row:first-child {
                display: none !important ;
            }
        }
        @media #{map-get($display-breakpoints, 'xs-only')} {
            .v-row-group__header strong {
                &:before {
                    content: '#';
                }
            }
        }

        table {
            border-collapse: separate;
            border-spacing: 0 10px !important;
        }

        .v-input--selection-controls {
            margin-top: 0;
        }

        th {
            .v-input--selection-controls {
                padding-top: 0;
            }
        }

        .v-input .v-label {
            font-size: 0.75rem;
        }
    }

    .order-title-edit {
        font-size: 12px;
        margin: 0;
    }

    @media (max-width: 960px) {
        div::v-deep {
            tr {
                .v-data-table__mobile-row {
                    height: auto !important;
                }
            }
        }
    }

    .commission {
        color: grey;
        font-size: 10px;
    }
</style>
