<template>
    <div>
        <v-select
            v-if="autocompleteDisabled"
            ref="option"
            v-model="value"
            :name="name"
            :items="items"
            :label="$attrs.label"
            :counter="$attrs.counter"
            item-text="name"
            :small-chips="multiple"
            :search-input.sync="search"
            deletable-chips
            item-value="value"
            :single-line="singleLine"
            :outlined="outlined"
            hide-details
            :multiple="multiple"
            :clearable="clearable"
            :dark="dark"
            :dense="dense"
            light
            :disabled="disabled"
            @change="change"
        />
        <v-autocomplete
            v-else
            ref="option"
            v-model="value"
            :name="name"
            :items="items"
            :label="$attrs.label"
            :counter="$attrs.counter"
            item-text="name"
            :small-chips="multiple"
            :search-input.sync="search"
            deletable-chips
            item-value="value"
            :single-line="singleLine"
            :outlined="outlined"
            hide-details
            :multiple="multiple"
            :clearable="clearable"
            light
            :dark="dark"
            :dense="dense"
            :disabled="disabled"
            @change="change"
        />
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {AUTH_EVENT, EventBus} from '@/utils/event-bus'

    @Component
    export default class SelectOption extends Vue {
        @Prop({default: false, type: Boolean}) autocompleteDisabled
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: true}) clearable
        @Prop({required: true}) name
        @Prop({default: () => []}) items
        @Prop() defaultValue
        @Prop({required: false, type: Boolean}) dark
        @Prop({required: false, type: Boolean}) multiple
        @Prop({required: false, type: Boolean}) dense

        value = null
        search = ''

        @Watch('value')
        onValueChange() {
            this.search = ''

            this.change(this.value)
        }

        @Emit()
        change(value) {
            this.$refs.option.blur()

            return {value, name: this.name}
        }

        created() {
            EventBus.$on(AUTH_EVENT, this.setDefaultCitizenship)
            this.value = this.$route.query[this.name] || this.defaultValue
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.setDefaultCitizenship)
        }

        setDefaultCitizenship() {
            if (this.name === 'citizenshipId' && this.$route.name === 'home') {
                this.$nextTick(() => {
                    this.value = this.defaultValue
                })
            }
        }
    }
</script>

<style scoped>
    ::v-deep .v-list-item__title {
        font-size: 14px;
    }

    ::v-deep input {
        pointer-events: none;
    }
</style>
