<template>
    <form ref="form" :action="data.url" method="POST" class="d-none">
        <input
            v-for="param in data.parameters"
            :key="param.name"
            type="hidden"
            :name="param.name"
            :value="param.value"
        />
        <input type="hidden" name="paymentNumber" :value="data.paymentNumber" />
        <input type="hidden" name="paymentTransactionId" :value="data.paymentTransactionId" />
        <input v-if="data.originUrl" type="hidden" name="originUrl" :value="data.originUrl" />
    </form>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class PaymentRedirectForm extends Vue {
        data = {
            url: null,
            parameters: [],
            paymentNumber: null,
            paymentTransactionId: null,
        }

        async submit(data) {
            this.data = data
            await this.$nextTick()
            this.$refs.form.submit()
        }
    }
</script>
