<template>
    <div>
        <div v-if="title">
            <span class="font-weight-regular"> {{ $t(title) }} </span>
        </div>
        <div>
            <div class="info-text title text-no-wrap black--text font-weight-bold">
                {{ price.amount | priceFormat(price.currency) }}
            </div>
            <pricing-source-type-label
                v-if="showPricingSourceType && service && service.serviceDetailsContainer.flight"
                :service="service.serviceDetailsContainer.flight"
            />
            <span v-if="discount && discount.name" class="caption accent--text">
                {{ $t('discount') }}:
                <span v-if="isTO1">
                    {{ discount.amount | priceFormat(price.currency) }}
                    <br />
                </span>
                <span>
                    {{ discount.name }}
                </span>
            </span>
            <div v-if="showCommission && price.commission && commissionAndNettoCheckbox" class="d-flex flex-column">
                <span class="commission-block">
                    <span class="font-weight-regular"> {{ $t('commission') }}: </span>
                    {{ price.commission | priceFormat(price.currency) }}
                </span>
                <span class="commission-block">
                    <span class="font-weight-regular"> {{ $t('net_amount') }}: </span>
                    {{ (price.amount - price.commission) | priceFormat(price.currency) }}
                </span>
            </div>
            <div v-if="loyaltyPoints && !isSupplier" class="commission-block">
                <span class="font-weight-regular">
                    {{
                        loyaltyPoints > 0
                            ? `${$t('loyalty_accum')}: ${loyaltyPoints}`
                            : `${$t('loyalty_spend')}: ${Math.abs(loyaltyPoints)}`
                    }}
                </span>
            </div>
            <slot name="violation" />
        </div>
        <payment-status-label
            v-if="isShowPaymentStatus && !hidePaymentStatusLabel && !$config.account.paymentScheduler"
            :status="paymentStatus"
            :card-guarantee="serviceCardGuarantee"
        />
        <cancel-penalty-label
            v-if="cancelPenalties && !hideCancelPenalties"
            :price="price"
            :cancel-penalty="cancelPenalties"
            class="d-block mt-4"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import CancelPenaltyLabel from '@/components/snippets/CancelPenaltyLabel'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import PricingSourceTypeLabel from '@/components/snippets/PricingSourceTypeLabel.vue'

    @Component({
        components: {
            PricingSourceTypeLabel,
            PaymentStatusLabel,
            CancelPenaltyLabel,
        },
    })
    export default class OrderPrice extends Vue {
        @Prop() title
        @Prop() price
        @Prop() paymentStatus
        @Prop() cancelPenalties
        @Prop({default: false}) showCommission
        @Prop({default: false}) violation
        @Prop({default: false, type: Boolean}) hidePaymentStatusLabel
        @Prop({default: false, type: Boolean}) hideCancelPenalties
        @Prop({default: () => []}) corporatePolicies
        @Prop() serviceCardGuarantee
        @Prop({default: ''}) loyaltyPoints
        @Prop() service
        @Prop() discount
        @Prop({default: false, type: Boolean}) showPricingSourceType

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get isShowPaymentStatus() {
            return !this.service?.serviceGroupId
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isSupplier() {
            return authStore.isSupplier
        }
    }
</script>
