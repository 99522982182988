<template>
    <v-dialog v-model="isShowOrderAddServiceModal" content-class="overflow--visible">
        <v-card-title>
            <v-spacer />
            {{ `${$t('adding_service_to_order')} #${order.orderId}` }}
            <v-spacer />
            <v-btn icon dark @click="isShowOrderAddServiceModal = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <search-form-tabs />
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import {
        activitiesRuntimeStore,
        activitiesStore,
        hotelsStore,
        persistentStore,
        transfersRuntimeStore,
        transfersStore,
        flightsStore,
        flightsRuntimeStore,
        packagesStore,
        packagesRuntimeStore,
        toursStore,
        toursRuntimeStore,
        insuranceStore,
        cruisesStore,
        carsRentStore,
        extraServicesStore,
    } from '@/store'
    import SearchFormTabs from '~src/components/searchFormTabs.src'
    import {clone, getOrderTravelers} from '@/utils/helpers'

    @Component({
        components: {
            SearchFormTabs,
        },
    })
    export default class OrderAddServiceModal extends Vue {
        @VModel() isShowOrderAddServiceModal
        @Prop({required: true}) order

        persistentTourists = []
        hotelsTourists = []

        @Watch('isShowOrderAddServiceModal')
        onDialogShow(val) {
            if (val) {
                if (this.order.agentCompanyId && this.order.agent?.id && this.order.clientType !== 'DIRECT_SALES') {
                    persistentStore.SET_SELECTED_AGENT({
                        ...this.order.agent,
                        personId: this.order.agent.id,
                        companyId: this.order.agentCompanyId,
                        companyName: this.order.clientCompanyName,
                    })
                }

                persistentStore.SET_SELECTED_ORDER_ID(this.order.orderId)
                this.persistentTourists = clone(persistentStore.tourists)
                this.hotelsTourists = clone(hotelsStore.tourists)

                const tourists = getOrderTravelers(this.order).map(
                    ({
                        prefix,
                        name: [{firstName, lastName}],
                        citizenshipId,
                        isChild,
                        phone,
                        email,
                        dateOfBirth: birthdate,
                        passports: [passport],
                    }) => {
                        return {
                            prefix,
                            firstName,
                            lastName,
                            citizenshipId,
                            type: isChild ? 'child' : 'adult',
                            ...(!isChild && {phone}),
                            ...(!isChild && {email}),
                            birthdate,
                            ...(isChild && {
                                age: this.$dateFns.differenceInYears(new Date(), this.$dateFns.parseISO(birthdate)),
                            }),
                            passport: {
                                type: passport?.type || null,
                                number: passport?.number || null,
                                issueDate: passport?.issueDate || null,
                                expiryDate: passport?.expiryDate || null,
                            },
                        }
                    }
                )

                persistentStore.SET_TOURISTS(tourists)
                hotelsStore.SET_TOURISTS([tourists])

                const accommodationService = this.order.services.find(
                    service => service.serviceType === 'ACCOMMODATION'
                )
                const transferService = this.order.services.find(service => service.serviceType === 'TRANSFER')
                const flightService = this.order.services.find(
                    service => service.serviceType === 'OWNCHARTER' || service.serviceType === 'FLIGHT'
                )
                let adults = 0,
                    childrenAges = []

                tourists.forEach(tourist => {
                    if (tourist.type === 'adult') adults += 1
                    else {
                        childrenAges.push(tourist.age)
                    }
                })

                if (accommodationService && transferService) {
                    const {hotelCode, hotelName, cityId, countryId} = accommodationService.serviceDetails[0]
                    transfersRuntimeStore.loadDeparturePoint({
                        departureHotelCode: hotelCode,
                        departureHotelName: hotelName,
                        departureCityId: cityId,
                    })
                    transfersStore.SET_SEARCH_REQUEST({
                        ...transfersStore.searchRequest,
                        departureCityId: cityId,
                        ...(adults && {adults}),
                        ...(childrenAges.length && {childrenAges}),
                        dateTime: accommodationService.endDateTime,
                    })
                    this.fillActivitiesForm({adults, childrenAges, cityId})
                    this.fillFlightForm({adults, childrenAges, cityId})
                    this.fillPackagesForm({adults, childrenAges, cityId})
                    this.fillToursForm({adults, childrenAges, cityId})
                    this.fillInsuranceForm({adults, childrenAges, countryId})
                    this.fillCruisesForm({adults, childrenAges, cityId})
                    this.fillCarsForm({adults, childrenAges, cityId})
                    this.fillHotelForm({
                        adults,
                        childrenAges,
                        cityId,
                        countryId,
                        hotelCode,
                        hotelName,
                    })
                } else if (accommodationService) {
                    const {hotelCode, hotelName, cityId, countryId} = accommodationService.serviceDetails[0]
                    transfersRuntimeStore.loadArrivalPoint({
                        arrivalHotelCode: hotelCode,
                        arrivalHotelName: hotelName,
                        arrivalCityId: cityId,
                    })
                    const searchRequest = {
                        ...transfersStore.searchRequest,
                        arrivalCityId: cityId,
                        ...(adults && {adults}),
                        ...(childrenAges.length && {childrenAges}),
                        dateTime: accommodationService.startDateTime,
                    }
                    if (flightService) {
                        const {arrivalAirportCode, flightNumber} =
                            flightService.serviceDetails[0].itineraries[0].flightSegments[0]
                        searchRequest.departurePlace = arrivalAirportCode
                        searchRequest.flightNumber = flightNumber
                        searchRequest.departureCityId = cityId
                        searchRequest.departureCountryId = countryId

                        transfersRuntimeStore.loadDeparturePoint({
                            departurePlace: arrivalAirportCode,
                            departureCityId: cityId,
                            departureCountryId: countryId,
                            flightNumber,
                        })
                    }
                    transfersStore.SET_SEARCH_REQUEST(searchRequest)
                    this.fillActivitiesForm({adults, childrenAges, cityId})
                    this.fillFlightForm({adults, childrenAges, cityId})
                    this.fillPackagesForm({adults, childrenAges, cityId})
                    this.fillToursForm({adults, childrenAges, cityId})
                    this.fillInsuranceForm({adults, childrenAges, countryId})
                    this.fillCruisesForm({adults, childrenAges, cityId})
                    this.fillCarsForm({adults, childrenAges, cityId})
                    this.fillExtraServicesForm({adults, childrenAges, cityId, countryId})
                    this.fillHotelForm({
                        adults,
                        childrenAges,
                        cityId,
                        countryId,
                        hotelCode,
                        hotelName,
                    })
                }
            } else {
                persistentStore.SET_SELECTED_ORDER_ID(null)
                persistentStore.SET_TOURISTS(this.persistentTourists)
                hotelsStore.SET_TOURISTS(this.hotelsTourists)
                transfersRuntimeStore.SET_DEPARTURE_POINT({})
                transfersRuntimeStore.SET_ARRIVAL_POINT({})
                transfersStore.SET_SEARCH_REQUEST({...transfersStore.searchRequest, departureCityId: null})
                activitiesRuntimeStore.SET_CITY({})
                activitiesStore.SET_SEARCH_REQUEST({...activitiesStore.searchRequest, cityId: null})
                persistentStore.SET_SELECTED_AGENT(null)
            }
        }

        fillActivitiesForm({adults, childrenAges, cityId}) {
            activitiesRuntimeStore.loadCity(cityId)
            activitiesStore.SET_SEARCH_REQUEST({
                ...activitiesStore.searchRequest,
                cityId: cityId,
                ...(adults && {adults}),
                ...(childrenAges.length && {childrenAges}),
            })
        }

        async fillFlightForm({adults, childrenAges, cityId}) {
            flightsRuntimeStore.SET_ROUTES([])
            await flightsRuntimeStore.SET_ARRIVAL_LOCATION(cityId)
            flightsStore.SET_SEARCH_REQUEST({
                ...flightsStore.searchRequest,
                ...(adults && {adults}),
                ...(childrenAges.length && {childrenAges}),
            })
        }

        fillPackagesForm({adults, childrenAges, cityId}) {
            packagesRuntimeStore.loadArrivalPoint(cityId)
            packagesStore.SET_SEARCH_REQUEST({
                ...packagesStore.searchRequest,
                ...(adults && {adults}),
                ...(childrenAges.length && {childrenAges}),
                arrivalCityId: cityId,
            })
        }

        fillToursForm({adults, childrenAges, cityId}) {
            toursRuntimeStore.loadArrivalCity(cityId)
            toursStore.SET_SEARCH_REQUEST({
                ...toursStore.searchRequest,
                ...(adults && {adults}),
                ...(childrenAges.length && {childrenAges}),
                arrivalCityId: cityId,
            })
        }

        fillInsuranceForm({adults, childrenAges, countryId}) {
            insuranceStore.SET_SEARCH_REQUEST({
                ...insuranceStore.searchRequest,
                locationId: countryId,
                ...(adults && {adults}),
                ...(childrenAges.length && {childrenAges}),
            })
        }

        fillCruisesForm({adults, childrenAges, cityId}) {
            cruisesStore.SET_SEARCH_REQUEST({
                ...cruisesStore.searchRequest,
                ...(adults && {adults}),
                ...(childrenAges.length && {childrenAges}),
                departureCityId: cityId,
                destinationCityId: cityId,
            })
        }

        fillCarsForm({cityId}) {
            carsRentStore.SET_SEARCH_REQUEST({
                ...carsRentStore.searchRequest,
                pickUpCityId: cityId,
                dropOffCityId: cityId,
            })
        }

        fillExtraServicesForm({cityId, countryId}) {
            extraServicesStore.SET_SEARCH_REQUEST({
                ...extraServicesStore.searchRequest,
                countryId,
                cityId,
            })
        }

        fillHotelForm({cityId, countryId}) {
            hotelsStore.SET_SEARCH_REQUEST({
                ...hotelsStore.searchRequest,
                countryId,
                cityId,
            })
        }
    }
</script>

<style lang="scss" scoped>
    .v-card__title {
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
    }

    ::v-deep {
        .v-tabs > .v-tabs-bar {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
</style>
