import { render, staticRenderFns } from "./PriceFilter.vue?vue&type=template&id=022c6392&scoped=true&"
import script from "./PriceFilter.vue?vue&type=script&lang=js&"
export * from "./PriceFilter.vue?vue&type=script&lang=js&"
import style0 from "./PriceFilter.vue?vue&type=style&index=0&id=022c6392&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022c6392",
  null
  
)

export default component.exports