<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ServiceBase extends Vue {
        @Prop({required: true}) order
        @Prop() serviceTips
        @Prop() orderEndDate

        get orderId() {
            return this.order.orderId
        }

        get objectId() {
            return this.order.objectId
        }
    }
</script>
