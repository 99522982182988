<template>
    <div v-scroll="onScroll" v-intersect.once="onIntersect" class="map">
        <div ref="map" />
        <div ref="controls" class="mt-3 ml-3 align-center controls">
            <hotel-map-location-filter v-if="locationFilter" ref="filter" :google="google" :map="map" :city="city" />
            <v-btn v-if="!isMobile && !hideExpandButton" color="white" class="mt-3 me-3" @click="toggle">
                <v-icon>{{ `mdi-chevron-${expanded ? 'right' : 'left'}` }}</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {Vue, mixins, Component, Watch, PropSync, Prop} from 'nuxt-property-decorator'
    import gmapsInit from '~/utils/gmaps'
    import {MarkerClusterer, SuperClusterAlgorithm} from '@googlemaps/markerclusterer'
    import {price} from '~/utils/filters'
    import HotelMapInfoWindow from '~src/components/search/offers/hotelMapInfoWindow.src'
    import {
        EventBus,
        FOOTER_INTERSECT_EVENT,
        OFFER_ITEM_HOVER_EVENT,
        OFFER_ITEM_LEAVE_EVENT,
        SHOW_ON_MAP_EVENT,
    } from '~/utils/event-bus'
    import {hotelsStore} from '~/store'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'
    import HotelMapLocationFilter from '@/components/hotels/snippets/HotelMapLocationFilter'
    import base64js from 'base64-js'
    import {TextEncoderLite} from 'text-encoder-lite'

    function Base64Encode(str, encoding = 'utf-8') {
        const bytes = new (TextEncoder || TextEncoderLite)(encoding).encode(str)
        return base64js.fromByteArray(bytes)
    }

    @Component({components: {HotelMapLocationFilter}})
    export default class HotelMap extends mixins(HotelMapMixin) {
        @Prop({default: () => []}) offers
        @Prop({default: null}) parent
        @Prop({default: true}) linkToHotels
        @Prop({default: null}) center
        @Prop({default: null}) city
        @Prop({default: false, type: Boolean}) locationFilter
        @PropSync('_expanded', {default: false}) expanded

        hideExpandButton = false
        map = null
        geocoder = null
        markers = []
        google = null
        markerCluster = null
        isFooterIntersecting = false
        bounds = null
        mapPositionInit = false
        isMapPositionSets = false

        toggle() {
            this.expanded = !this.expanded
            this.$nextTick(() => {
                //TODO When thrown exception???
                try {
                    this.setMapXPosition()
                    this.setMapPosition()
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            })
        }

        yPos() {
            const y = !this.isMobile
                ? this.$parent.$parent.$el.parentElement.getBoundingClientRect().y
                : this.getMobileYPosition()
            return y > 0 ? y : 0
        }

        async initMap() {
            try {
                let filterWidth
                if (this.$refs.controls && this.locationFilter) {
                    filterWidth = this.$refs.filter.$el.offsetWidth
                }
                this.setMapXPosition()
                this.setMapYPosition()
                this.google = await gmapsInit()
                this.geocoder = new this.google.maps.Geocoder()
                const map = new this.google.maps.Map(this.$refs.map, this.getMapOptions())
                if (this.$refs.controls) {
                    map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(this.$refs.controls)
                    if (this.locationFilter && filterWidth + 60 > this.$el.offsetWidth) {
                        map.setOptions({fullscreenControl: false})
                    }
                }
                /*this.geocoder.geocode({address: hotelsStore.city.name}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }
                    map.setCenter(results[0].geometry.location);
                    map.fitBounds(results[0].geometry.viewport);
                });*/
                this.map = map
                EventBus.$on(FOOTER_INTERSECT_EVENT, isIntersecting => {
                    this.isFooterIntersecting = isIntersecting
                })
                if (this.city && this.city.latitude && this.city.longitude) {
                    this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
                }

                map.addListener('drag', () => {
                    if (this.offers.some(offer => offer.coordinates && offer.coordinates.length === 2))
                        this.isMapPositionSets = true
                })
                map.addListener('dblclick', () => {
                    if (this.offers.some(offer => offer.coordinates && offer.coordinates.length === 2))
                        this.isMapPositionSets = true
                })
                map.addListener('click', () => {
                    if (this.offers.some(offer => offer.coordinates && offer.coordinates.length === 2))
                        this.isMapPositionSets = true
                })
            } catch (error) {
                console.error(error)
            }
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        beforeDestroy() {
            this.saveMapPosition()
        }

        async load() {
            await this.initMap()
            this.setMarkers(this.offers)
            EventBus.$on(OFFER_ITEM_HOVER_EVENT, async offer => {
                const marker = this.markers.find(({data}) => data.hotelCode === offer.hotelCode)
                if (!marker || marker.infoWindow) return
                if (marker.prevMap === undefined) marker.prevMap = marker.getMap()
                if (!marker.prevMap) {
                    await marker.setMap(this.map)
                }
                marker.setVisible(true)
                //this.map.setZoom(16);
                //this.map.setCenter(marker.position);
                this.showInfoWindow(marker)
                //this.google.maps.event.trigger(marker, 'mouseover');
            })
            EventBus.$on(OFFER_ITEM_LEAVE_EVENT, async offer => {
                const marker = this.markers.find(({data}) => data.hotelCode === offer.hotelCode)
                if (!marker) return
                //this.map.setZoom(this.zoom);
                if (marker.infoWindow) {
                    this.google.maps.event.clearInstanceListeners(marker.infoWindow)
                    //await marker.infoWindow.close();
                    //await marker.infoWindow.close(null, marker);
                    await marker.infoWindow.close(this.map, marker)
                    marker.infoWindow = null
                }
                if (!marker.prevMap) {
                    marker.setVisible(false)
                    //marker.setMap(null);
                }
                //this.google.maps.event.trigger(marker, 'mouseout');
            })
            EventBus.$on(SHOW_ON_MAP_EVENT, offer => {
                let marker = null
                this.markers.forEach(el => {
                    if (el.data.hotelCode === offer.hotelCode) {
                        el.setIcon({
                            ...this.markerIconOptions,
                            fillColor: this.$vuetify.theme.themes.light.error,
                        })
                        marker = el
                    } else {
                        el.setIcon(this.createMarkerIcon(el.data))
                    }
                })

                if (!marker) {
                    this.geocoder.geocode({address: offer.address}, (results, status) => {
                        if (status !== 'OK' || !results[0]) {
                            return
                        }
                        hotelsRuntimeStore.UPDATE_COORDINATES({
                            ...offer,
                            coordinates: [results[0].geometry.location.lat(), results[0].geometry.location.lng()],
                        })
                        marker = this.addMarker(offer)
                        this.setMarkers(this.offers)
                        this.map.setCenter(marker.position)
                        this.map.setZoom(18)
                        //TODO Check why not show at first time
                        this.showInfoWindow(marker)
                    })
                } else {
                    this.map.setCenter(marker.position)
                    this.map.setZoom(18)
                    this.showInfoWindow(marker)
                }
            })
        }

        setMapXPosition() {
            this.$el.style.width = !this.isMobile
                ? this.$el.parentElement.offsetWidth -
                  parseInt(window.getComputedStyle(this.$el.parentElement, null).getPropertyValue('padding-left'), 10) +
                  'px'
                : '100%'
        }

        setMapYPosition() {
            this.$el.style.top = this.yPos() + 'px'
            let footerMargin = 0
            if (this.isFooterIntersecting) {
                footerMargin = window.innerHeight - document.querySelector('footer').getBoundingClientRect().y
            }
            this.$el.style.height = window.innerHeight - this.yPos() - footerMargin + 'px'
        }

        onScroll() {
            this.setMapYPosition()
            //console.log(this.$el.parentElement.getBoundingClientRect().y);
            //console.log(e.target.documentElement.scrollTop);
        }

        @Watch('city')
        changeCity() {
            if (!this.map || !this.city.latitude || !this.city.longitude) return
            this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
        }

        @Watch('offers.length')
        changeOffers() {
            if (!this.map) return
            this.setMarkers(this.offers)
        }

        setMarkers(offers) {
            this.markers.forEach(marker => marker.setMap(null))
            if (this.markerCluster) {
                this.markerCluster.clearMarkers()
            }
            this.markers = []
            if (!offers.length) return
            offers.forEach(offer => {
                this.addMarker(offer)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            this.setMapPosition()
            this.markerCluster = new MarkerClusterer({
                map: this.map,
                markers: this.markers,
                renderer: {
                    render: ({count, position, markers}) => {
                        const minPrice = this.minClusterPrice(markers),
                            text = this.clusterMarkerLabel(minPrice),
                            color = this.$vuetify.theme.themes.light.primary
                        const svg = Base64Encode(`
                            <svg viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg">
                                <rect width="120" height="30" style="fill: ${color};" rx="15" ry="15"></rect>
                                <ellipse style="fill: rgb(255, 255, 255);" cx="16" cy="15" rx="13" ry="13"></ellipse>
                                <text style="fill: ${color}; font-family: sans-serif; font-size: 12px; font-weight: 700; text-anchor: middle; white-space: pre;" x="16" y="19">${count}</text>
                                <text style="fill: rgb(255, 255, 255); font-family: sans-serif; font-size: 12px; white-space: pre;" x="36" y="19">${text}</text>
                            </svg>
                        `)
                        return new this.google.maps.Marker({
                            position,
                            icon: {
                                url: `data:image/svg+xml;base64,${svg}`,
                                scaledSize: new this.google.maps.Size(120, 30),
                            },
                            label: {
                                text: ' ',
                            },
                            // adjust zIndex to be above other markers
                            zIndex: Number(this.google.maps.Marker.MAX_ZINDEX) + count,
                        })
                    },
                    algorithm: new SuperClusterAlgorithm({}),
                },
            })
        }

        clusterMarkerLabel(minPrice) {
            return minPrice ? `${this.$t('from')} ${price(minPrice)}` : ''
        }

        setMapPosition() {
            if (
                !this.isMapPositionSets &&
                this.offers.some(offer => offer.coordinates && offer.coordinates.length === 2)
            ) {
                if (!this.mapPositionInit && (hotelsStore.mapPosition.position || hotelsStore.mapPosition.zoom)) {
                    this.map.setCenter({
                        lat: hotelsStore.mapPosition.position.latitude,
                        lng: hotelsStore.mapPosition.position.longitude,
                    })
                    this.map.setZoom(hotelsStore.mapPosition.zoom)
                } else {
                    this.map.fitBounds(this.bounds)
                }
                this.mapPositionInit = true
                // this.isMapPositionSets = true
            }
        }

        minClusterPrice(markers) {
            let minPrice = markers[0].minPrice
            if (minPrice) {
                minPrice = markers.reduce((price, marker) => {
                    const minPriceRoomOfferPrice = marker.minPrice
                    return price.amount < minPriceRoomOfferPrice.amount ? price : minPriceRoomOfferPrice
                }, minPrice)
            }
            return minPrice
        }

        addMarker(offer) {
            let position
            if (offer.coordinates) {
                position = {
                    lat: offer.coordinates[0],
                    lng: offer.coordinates[1],
                }
            } else {
                return
            }

            if (this.markers.findIndex(marker => marker.position === position) !== -1) return

            //if (this.markers.find(marker => position === marker.position)) return;
            const minPriceRoomOffer = hotelsRuntimeStore.minPriceRoomOffer(offer)
            const minPrice =
                minPriceRoomOffer.price ||
                (this.$config.packagesNotDeltaPrice ? minPriceRoomOffer.notDeltaPrice : minPriceRoomOffer.deltaPrice)
            const marker = new this.google.maps.Marker({
                position,
                ...(price(minPrice) && {
                    label: {
                        text: `${this.$t('from')} ${price(minPrice)}`,
                        className: 'custom-label',
                    },
                }),
                icon: this.createMarkerIcon(offer),
                data: offer,
                minPrice,
            })

            marker.addListener('click', () => {
                if (!marker.infoWindow) {
                    this.isMapPositionSets = true
                    this.showInfoWindow(marker)
                }
            })

            /*marker.addListener('click', () => {
                const {supplierCode, cityCode, hotelCode} = offer,
                    cityId = hotelsStore.city.id
                const routeData = this.$router.resolve({
                    name: 'hotel',
                    query: {supplierCode, cityCode, hotelCode, cityId},
                })
                window.open(routeData.href, '_blank')
            })*/

            /*marker.addListener('mouseover', () => {
                infoWindow.open(this.map, marker);
            });
            marker.addListener('mouseout', () => {
                infoWindow.close();
            });*/

            this.markers.push(marker)
            return marker
        }

        showInfoWindow(marker) {
            const markerWithInfoWindow = this.markers.find(marker => marker.infoWindow)
            if (markerWithInfoWindow) {
                markerWithInfoWindow.infoWindow.close()
                markerWithInfoWindow.infoWindow = null
            }
            const InfoWindowComponent = Vue.extend(HotelMapInfoWindow)
            const instance = new InfoWindowComponent({
                propsData: {
                    offer: marker.data,
                    withLink: this.linkToHotels,
                },
                parent: this,
            })
            instance.$mount()
            marker.infoWindow = new this.google.maps.InfoWindow({
                content: instance.$el,
            })
            this.google.maps.event.addListener(marker.infoWindow, 'closeclick', () => {
                marker.infoWindow.close()
                marker.infoWindow = null
            })
            marker.infoWindow.open(this.map, marker)
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: this.google.maps.MapTypeControlStyle.DEFAULT,
                    position: this.google.maps.ControlPosition.LEFT_BOTTOM,
                },
                zoomControl: true,
                fullscreenControl: !this.isMobile,
                zoom: 8,
                restriction: {
                    latLngBounds: {north: 80, south: -70, west: -180, east: 180},
                    strictBounds: true,
                },
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        getMobileYPosition() {
            return !this.parent ? this.$parent.$refs.mobileTabs.$el.offsetHeight : 0
        }

        onIntersect() {}

        saveMapPosition() {
            hotelsStore.SET_MAP_POSITION({
                position: {
                    latitude: this.map.getCenter().lat(),
                    longitude: this.map.getCenter().lng(),
                },
                zoom: this.map.getZoom(),
            })
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    .map {
        position: fixed;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            margin-left: -$container-padding-x;
        }

        > div {
            height: 100%;
        }
    }

    ::v-deep {
        .custom-label {
            background-color: var(--v-primary-base) !important;
            border: 3px solid white !important;
            border-radius: 20px;
            padding: 4px !important;
            color: white !important;
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-25%);
        }
    }
</style>
