<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="address"
                    :placeholder="$t('address')"
                    :rules="addressRules"
                    :label="productFieldLabel('type_address_manually', 'POINT_ADDRESS')"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="postalCode"
                    :label="productFieldLabel('transfers.postal_code', 'POINT_POSTAL_CODE')"
                    :rules="postalCodeRules"
                    width="200"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import AirportsSearchInput from '~/components/snippets/AirportsSearchInput'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferAddressForm from '@/components/transfers/booking/forms/TransferAddressForm'

    @Component({
        components: {TimeField, AirportsSearchInput, DatePicker},
    })
    export default class AddressForm extends TransferAddressForm {
        @Prop() serviceType

        getProp(prop) {
            if (!this.request[this.pointType]) return null

            return this.request[this.pointType][this.serviceType][prop]
        }

        setProp(prop, val) {
            this.request[this.pointType][this.serviceType][prop] = val
        }

        initData() {}
    }
</script>
