<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {toursRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TourDatepickerMixin extends Vue {
        get availableDates() {
            const availableDates = toursRuntimeStore.packageTourInfo.availabilities
                .map(availability => {
                    let start = new Date(availability.startDateFrom),
                        end = new Date(availability.startDateTo),
                        min = this.$dateFns.addDays(new Date(), 1)
                    if (this.$dateFns.isAfter(min, start)) {
                        start = min
                        if (this.$dateFns.isAfter(min, end)) {
                            return null
                        }
                    }
                    return {
                        start,
                        end,
                        weekdays: availability.weekdays.map(weekday => {
                            weekday++
                            if (weekday > 7) {
                                weekday = 9 - weekday
                            }
                            return weekday
                        }),
                    }
                })
                .filter(Boolean)
            return availableDates.length
                ? availableDates
                : {start: new Date(this.searchRequest.startDateFrom), end: new Date(this.searchRequest.startDateTo)}
        }
    }
</script>
