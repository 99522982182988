<template>
    <div>
        <breadcrumbs class="mb-5" />
        <v-expansion-panels v-if="categoriesToShow.length">
            <v-expansion-panel v-for="category in categoriesToShow" :key="category[0].categoryName">
                <v-expansion-panel-header>
                    {{ category[0].needTranslation ? $t(category[0].categoryName) : category[0].categoryName }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list>
                        <v-list-item v-for="report in category" :key="report.reportId">
                            <v-btn text @click="loadReport(report)">
                                {{ report.needTranslation ? $tc(report.reportName, 2) : report.reportName }}
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="text-h5 text-center grey--text text--lighten-1">
            {{ noDataText }}
        </div>
        <report-modal ref="report" />
        <statement-of-account-report-modal ref="statement_of_account_report" />
    </div>
</template>

<script>
    import {Component, Mixins} from 'vue-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ReportModal from '~src/components/account/reports/reportModal.src'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import LoadDataStatusMixin from '@/mixins/LoadDataStatusMixin'
    import StatementOfAccountReportModal from '@/components/account/reports/StatementOfAccountReportModal'

    const loadData = async $api => {
        try {
            const reports = (await $api.reportsList.get()).reports
            const categories = reports.reduce((categories, report) => {
                let category = categories.find(category => category[0].categoryName === report.categoryName)
                if (!category) {
                    category = []
                    categories.push(category)
                }
                category.push(report)
                return categories
            }, [])
            categories.sort((c1, c2) => (c1[0].categoryName > c2[0].categoryName ? 1 : -1))
            categories.forEach(category => {
                category.sort((r1, r2) => (r1.reportName > r2.reportName ? 1 : -1))
            })
            categories.push([
                {
                    categoryName: 'statement_of_account.title',
                    reportId: 'statement_of_account_clients',
                    reportName: 'clients',
                    needTranslation: true,
                },
                {
                    categoryName: 'statement_of_account.title',
                    reportId: 'statement_of_account_suppliers',
                    reportName: 'suppliers',
                    needTranslation: true,
                },
            ])
            return {categories, loadDataError: false}
        } catch (e) {
            return {categories: [], loadDataError: true}
        }
    }

    @Component({
        components: {
            StatementOfAccountReportModal,
            ReportModal,
            Breadcrumbs,
        },
    })
    export default class ReportsPage extends Mixins(LoadDataStatusMixin) {
        categories = []
        statement_of_account_reports = ['statement_of_account_clients', 'statement_of_account_suppliers']

        async asyncData({$api}) {
            return loadData($api)
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.loadData)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.loadData)
        }

        mounted() {
            this.showLoadDataStatusMessage()
        }

        async loadData() {
            const {categories, loadDataError} = await loadData(this.$api)
            this.categories = categories
            this.loadDataError = loadDataError
            this.showLoadDataStatusMessage()
        }

        loadReport(report) {
            if (this.statement_of_account_reports.includes(report.reportId)) {
                this.$refs.statement_of_account_report.loadReportParameters(report)
            } else {
                this.$refs.report.loadReportParameters(report)
            }
        }

        get categoriesToShow() {
            return this.categories
        }
    }
</script>
