<template>
    <v-select
        v-model="groupOffersBy"
        :label="$t('group_by')"
        :items="items"
        dense
        :hide-details="hideDetails"
        @change="$emit('groupSelected', $event)"
    >
        <template v-slot:item="{item}">
            <div>
                {{ $t(`${tokenBase}.${item}`) }}
            </div>
        </template>
        <template v-slot:selection="{item}">
            <div>
                {{ $t(`${tokenBase}.${item}`) }}
            </div>
        </template>
    </v-select>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class GroupOffersSelect extends Vue {
        @Prop({required: true}) items
        @Prop({required: true}) productStore
        @Prop({default: false, type: Boolean}) hideDetails
        @Prop({default: 'groupBy'}) tokenBase

        get groupOffersBy() {
            return this.productStore.groupOffersBy
        }

        set groupOffersBy(val) {
            this.productStore.SET_GROUP_OFFERS_BY(val)
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-select__selections,
        .v-list-item--link {
            font-size: 13px;
        }
    }
</style>
