<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {activeProducts} from '@/utils/authorization-info-utils'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class AuthInfoMixin extends Vue {
        get activeProducts() {
            return activeProducts(this.$config, authStore.availableProducts)
        }
    }
</script>
