<template>
    <div>
        <breadcrumbs :text="personLabel" />
        <client-form :person="client" :is-member="true" />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import CompanyClientPage from '~/pages/account/CompanyClientPage'

    @Component({middleware: ['auth', 'members']})
    export default class MemberPage extends CompanyClientPage {
        get personLabel() {
            if (this.client.personId) {
                const {prefix, firstName, lastName} = this.client
                return `${prefix || ''} ${firstName || ''} ${lastName || ''}`
            }
            return this.$t('new_member_profile')
        }
    }
</script>
