<template>
    <div v-if="services && services.length" class="d-block my-4 px-0">
        <div class="font-weight-bold mb-2">
            {{ $t('extra_services') }}
        </div>
        <div v-for="({name, quantity, price}, index) in services" :key="index" class="py-0 d-flex caption">
            <span>{{ name }}</span>
            <span v-if="quantity > 1">&nbsp;* {{ quantity }} </span>
            <v-spacer />
            <span class="font-weight-bold">
                {{ price | price }}
            </span>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {salesTermsRsTransformer} from '@/utils/api-helpers'

    @Component
    export default class ExtraServicesSummary extends Vue {
        @Prop({required: true, type: Array}) extraServices
        @Prop({required: true, type: Object}) additionalOptions

        get services() {
            return this.additionalOptions?.extraServices?.reduce(
                (extraServices, {name, salesTerms, serviceRPH, mandatory}) => {
                    const quantity = mandatory
                        ? 1
                        : this.extraServices.find(extraService => extraService.serviceRPH === serviceRPH)?.quantity
                    if (quantity) {
                        const {price} = salesTermsRsTransformer(salesTerms)
                        price.amount *= quantity
                        if (price.originalAmount) price.originalAmount *= quantity
                        extraServices.push({
                            name,
                            quantity,
                            price,
                        })
                    }
                    return extraServices
                },
                []
            )
        }
    }
</script>
