<template>
    <v-card class="my-2" :elevation="!fullCard ? 0 : undefined" :outlined="fullCard">
        <v-list-item three-line>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <div
                    class="wrap-words d-flex align-center text-subtitle-1 font-weight-bold cursor-pointer mb-1"
                    @click="offerInfo()"
                >
                    <v-icon small left>mdi-information-outline</v-icon>
                    {{ offer.serviceName }}
                </div>
                <div class="wrap-words d-flex align-center text-subtitle-1 font-weight-medium cursor-pointer mb-1">
                    <v-icon small left>mdi-calendar</v-icon>
                    <span
                        :class="{
                            'font-weight-bold': groupedBy !== 'serviceName',
                            caption: groupedBy === 'serviceName',
                        }"
                    >
                        {{ groupedBy === 'serviceName' ? $options.filters.dateFormat(offer.date) : offer.serviceName }}
                    </span>
                </div>
                <template v-if="!groupedBy">
                    <div v-if="offer.available" class="d-flex align-center caption wrap-words">
                        <v-icon :title="$t('available')" left small class="success--text">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <span class="success--text" v-text="$t('available')" />
                    </div>
                    <div v-else class="d-flex align-center caption wrap-words">
                        <v-icon left :title="$t('on_request')" small class="warning--text">mdi-alert-circle</v-icon>
                        <span class="warning--text" v-text="$t('on_request')" />
                    </div>
                </template>
                <div v-if="groupedBy !== 'serviceName'" class="d-flex align-center caption">
                    <v-icon small left>mdi-calendar</v-icon>
                    {{ $options.filters.dateFormat(offer.date) }}
                </div>
                <span class="caption">
                    <conditions-label
                        v-if="offer"
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </span>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center d-flex flex-column align-center">
                <slot name="price" :offer="offer" :offer-key="offer.offerKey">
                    <v-btn
                        :loading="infoLoading"
                        :disabled="infoLoading"
                        color="primary"
                        height="36px"
                        max-height="36px"
                        block
                        depressed
                        nuxt
                        small
                        no-prefetch
                        data-cy="client-price"
                        @click="selectForBooking"
                    >
                        {{ offer.price | price }}
                    </v-btn>
                    <price-note :search-request="searchRequest" />
                    <commission-amount :commission="offer.price.commission" />
                    <net-amount :price="offer.price" />
                    <discount-label :price="offer.price" class="d-inline-block" />
                </slot>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_ACTIVITIES_INFO_MODAL, SHOW_ACTIVITY_CONDITIONS_INFO} from '@/utils/event-bus'
    import {activitiesStore, authStore} from '~/store'
    import PriceNote from '@/components/snippets/PriceNote'
    import NetAmount from '@/components/snippets/NetAmount'

    @Component({
        components: {
            PriceNote,
            CommissionAmount,
            DiscountLabel,
            ActivityInfoModal,
            ConditionsLabel,
            NetAmount,
        },
    })
    export default class ActivityOfferCard extends Vue {
        @Prop() offer
        @Prop({default: true}) fullCard
        @Prop() groupedBy

        imgError = false
        info = {
            images: [],
        }
        infoLoading = false

        async mounted() {
            try {
                this.infoLoading = true
                this.info = await this.$api.activityInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard) {
                    this.$emit('info', this.info)
                }
                if (this.fullCard && this.info.images) {
                    this.$emit('images', this.info.images)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.infoLoading = false
            }
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            activitiesStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
            this.$router.push({
                name: 'activityBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        offerInfo() {
            EventBus.$emit(SHOW_ACTIVITIES_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_ACTIVITY_CONDITIONS_INFO, this.offer)
        }

        get mainImage() {
            let mainImage = this.info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images.length > 0) {
                mainImage = this.info.images[0]
            }
            return mainImage
        }

        get searchRequest() {
            return activitiesStore.searchRequest
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
