<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {calcExtraServicesTotalPrice, calcTotalPrice} from '@/utils/price-utils'

    @Component
    export default class OrderSummaryMixin extends Vue {
        //TODO Need total price calc global refactoring, now it is workaround for current order summary component solution
        get totalPrice() {
            const {price, supplierPrice} = this.extraServicesTotalPrice
            if (!price || this.prebooked) return this.orderTotalPrice
            const {amount, currency, originalAmount, originalCurrency} = this.orderTotalPrice
            if (!price.originalCurrency) {
                price.originalCurrency = price.currency
                price.originalAmount = price.amount
            }
            const priceWithExtensions = calcTotalPrice([{amount, currency, originalAmount, originalCurrency}, price])
            if (!priceWithExtensions.originalCurrency) {
                priceWithExtensions.originalCurrency = priceWithExtensions.currency
                priceWithExtensions.originalAmount = priceWithExtensions.amount
            }
            const totalPrice = {
                ...this.orderTotalPrice,
                ...priceWithExtensions,
            }
            if (!supplierPrice) return totalPrice
            if (!supplierPrice.originalCurrency) {
                supplierPrice.originalCurrency = supplierPrice.currency
                supplierPrice.originalAmount = supplierPrice.amount
            }
            const {amount: supplierPriceAmount, originalAmount: supplierPriceOriginalCurrencyAmount} = calcTotalPrice([
                {
                    amount: this.orderTotalPrice.supplierPriceAmount,
                    currency,
                    originalAmount: this.orderTotalPrice.supplierPriceOriginalCurrencyAmount,
                    originalCurrency,
                },
                supplierPrice,
            ])
            return {
                ...totalPrice,
                ...{
                    supplierPriceAmount,
                    supplierPriceOriginalCurrencyAmount: supplierPriceOriginalCurrencyAmount || supplierPriceAmount,
                },
            }
        }

        get extraPrices() {
            if (!this.extraServices) return [{}]
            return [calcExtraServicesTotalPrice(this.additionalOptions, this.extraServices)]
        }

        get extraServicesTotalPrice() {
            return {
                price: calcTotalPrice([...this.extraPrices.map(({price}) => price).filter(Boolean)]),
                supplierPrice: calcTotalPrice([
                    ...this.extraPrices.map(({supplierPrice}) => supplierPrice).filter(Boolean),
                ]),
            }
        }
    }
</script>
