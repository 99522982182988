<template>
    <v-dialog v-model="dialog" width="1200">
        <template v-slot:activator="{on}">
            <div class="ms-6 d-flex align-center">
                <span class="primary--text caption">
                    {{ `${$t('edit')} ${$t('itinerary')}`.toLowerCase() }}
                </span>
                <v-btn fab elevation="1" x-small class="ms-2 mb-1" v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-icon class="v-card-close mt-3 me-3" @click="dialog = false">mdi-close</v-icon>
            <v-card-title>
                {{ `${$t('edit')} ${$t('itinerary')}` }}
            </v-card-title>
            <v-card-text>
                <v-tabs v-model="tab" show-arrows>
                    <v-tabs-slider color="primary" />
                    <v-tab v-for="item in tabItems" :key="item" :disabled="!duration">
                        {{ $t(item) }}
                    </v-tab>
                </v-tabs>
                <v-card outlined>
                    <v-form ref="form" v-model="valid">
                        <v-card-text>
                            <v-expand-transition>
                                <v-tabs
                                    v-if="tabItems[tab] !== 'photo'"
                                    v-model="locale"
                                    centered
                                    fixed-tabs
                                    show-arrows
                                >
                                    <v-tabs-slider color="primary" />
                                    <v-tab v-for="item in locales" :key="item">
                                        <language-label :language="item" text-button translate />
                                    </v-tab>
                                </v-tabs>
                            </v-expand-transition>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-row class="mt-4">
                                        <v-col :cols="2">
                                            <v-text-field
                                                v-model.number="duration"
                                                dense
                                                outlined
                                                :label="$t('duration')"
                                                type="number"
                                                :rules="[requiredRule('duration')]"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col :cols="12">
                                            <v-textarea
                                                v-model="productDescriptions[locales[locale]]"
                                                rows="3"
                                                dense
                                                outlined
                                                :label="$t('description')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col :cols="12">
                                            <v-textarea
                                                v-model="includedInfoDescriptions[locales[locale]]"
                                                rows="3"
                                                dense
                                                outlined
                                                :label="$t('included')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col :cols="12">
                                            <v-textarea
                                                v-model="notIncludedInfoDescriptions[locales[locale]]"
                                                rows="3"
                                                dense
                                                outlined
                                                :label="$t('not_included')"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row v-for="day in duration" :key="day" no-gutters>
                                        <v-col cols="12">
                                            <v-card>
                                                <v-card-title color="secondary" class="text-capitalize">
                                                    {{ `${$tc('day', 1)}  ${day}` }}
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-col :cols="12">
                                                        <v-textarea
                                                            v-model="
                                                                itineraryDaysDescriptions[day - 1].descriptions[
                                                                    locales[locale]
                                                                ]
                                                            "
                                                            rows="2"
                                                            dense
                                                            outlined
                                                            :label="$t('description')"
                                                        />
                                                    </v-col>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row class="pt-6">
                                        <v-col cols="12">
                                            <v-file-input
                                                :key="fileInputKey"
                                                prepend-icon="mdi-camera"
                                                small-chips
                                                outlined
                                                dense
                                                accept="image/jpg, image/jpeg, image/png"
                                                class="cursor-pointer"
                                                multiple
                                                :label="$t('select_photo')"
                                                @change="onPhotoLoad"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-for="(image, i) in images" :key="image.url" cols="12" md="3">
                                            <v-card>
                                                <div style="position: relative">
                                                    <div :style="{opacity: image.toDelete ? 0.3 : 1}">
                                                        <v-img
                                                            :src="
                                                                image.fileStream ||
                                                                $options.filters.imageUrl(image.url, 300)
                                                            "
                                                            :lazy-src="'/placeholder.png' | srcHost"
                                                            aspect-ratio="1"
                                                            type="number"
                                                            gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.1)"
                                                            class="grey lighten-2 mb-8"
                                                        />
                                                    </div>
                                                    <v-btn
                                                        style="position: absolute; right: 4px; top: 4px"
                                                        @click="toggleDeleteImage(image)"
                                                    >
                                                        <v-icon>{{
                                                            image.toDelete ? 'mdi-undo' : 'mdi-delete'
                                                        }}</v-icon>
                                                    </v-btn>
                                                    <v-icon
                                                        v-if="image.toDelete"
                                                        :color="'error'"
                                                        style="
                                                            position: absolute;
                                                            left: 0;
                                                            right: 0;
                                                            top: 0;
                                                            bottom: 0;
                                                            width: 24px;
                                                            height: 24px;
                                                            margin: auto;
                                                        "
                                                        >mdi-close</v-icon
                                                    >
                                                </div>
                                            </v-card>
                                            <v-text-field
                                                v-model="image.dayNumber"
                                                persistent-placeholder
                                                dense
                                                outlined
                                                :label="$tc('day', 1)"
                                            />
                                            <v-text-field
                                                v-model="image.order"
                                                dense
                                                persistent-placeholder
                                                outlined
                                                type="number"
                                                :label="$t('display_number')"
                                                @change="onImageDisplayNumberChange($event, image, i)"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn color="primary" :loading="loading" :disabled="!valid" @click="save">
                                {{ 'save' }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import LanguageLabel from '@/components/snippets/LanguageLabel'

    @Component({
        components: {LanguageLabel},
    })
    export default class EditItineraryModal extends mixins(FormValidationRulesMixin) {
        @Prop() orderId
        @Prop() orderItinerary

        dialog = false
        loading = false
        tabItems = ['general_info', 'description', 'photo']
        tab = 0
        locale = 0
        fileInputKey = 0
        valid = false

        duration = 0
        productDescriptions = {}
        includedInfoDescriptions = {}
        notIncludedInfoDescriptions = {}
        itineraryDaysDescriptions = null
        images = []

        @Watch('dialog')
        onDialogChange() {
            this.reset()
        }

        @Watch('duration')
        onDurationChange() {
            this.itineraryDaysDescriptions = [...Array(this.duration)].map((e, i) => ({
                number: this.itineraryDaysDescriptions[i]?.number || i + 1,
                descriptions: this.itineraryDaysDescriptions[i]?.descriptions || {},
            }))
        }

        async onPhotoLoad(files) {
            for (let i = 0; i < files.length; i++) {
                const base64Img = await this.toBase64(files[i])

                this.images.push({
                    order: this.images.length + 1,
                    fileStream: base64Img,
                    toDelete: false,
                })
            }
        }

        @Emit()
        async save() {
            try {
                this.loading = true
                await this.$api.orderItinerary.put(this.request)
                // eslint-disable-next-line no-empty
            } catch (e) {
                if (e.msg) {
                    this.$toast.error(e.msg)
                }
            } finally {
                this.loading = false
            }
        }

        toggleDeleteImage(image) {
            image.toDelete = !image.toDelete
        }

        onImageDisplayNumberChange(event, image, index) {
            const newImgDisplayNumber = +event
            const prevImgDisplayNumber = index + 1

            this.images.forEach(img => {
                if (newImgDisplayNumber < prevImgDisplayNumber) {
                    if (+img.order >= newImgDisplayNumber && img !== image && !(+img.order > prevImgDisplayNumber)) {
                        img.order = +img.order + 1
                    }
                } else {
                    if (+img.order <= newImgDisplayNumber && img !== image && !(+img.order < prevImgDisplayNumber)) {
                        img.order = +img.order - 1
                    }
                }
            })

            this.images.sort((img1, img2) => {
                return img1.order - img2.order
            })
        }

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }

        reset() {
            this.duration = this.orderItinerary.duration
            this.productDescriptions = this.orderItinerary.productDescription || {}
            this.includedInfoDescriptions = this.orderItinerary.includedInfo || {}
            this.notIncludedInfoDescriptions = this.orderItinerary.notIncludedInfo || {}
            this.itineraryDaysDescriptions =
                this.orderItinerary.itineraryDays?.map(e => ({
                    number: e.number,
                    descriptions: e.description || {},
                })) || {}
            this.fileInputKey++
            this.images =
                this.orderItinerary.images?.map((img, i) => {
                    return {...img, toDelete: false, order: i + 1}
                }) || []
        }

        get locales() {
            return Object.values(this.$config.locales)
        }

        get request() {
            return {
                duration: this.duration,
                includedInfoDescriptions: this.includedInfoDescriptions,
                notIncludedInfoDescriptions: this.notIncludedInfoDescriptions,
                productDescriptions: this.productDescriptions,
                itineraryDays: this.itineraryDaysDescriptions,
                itineraryImages: this.images.map(img => ({
                    ...(img.fileStream && {fileStream: img.fileStream.split(',')[1]}),
                    ...(img.dayNumber && {requestItineraryDayNumber: +img.dayNumber}),
                    ...(img.imageId && {imageId: img.imageId}),
                    order: +img.order - 1,
                    toDelete: img.toDelete,
                    fileNames: {
                        en: 'image.jpg',
                    },
                })),
                orderId: this.orderId,
            }
        }
    }
</script>
