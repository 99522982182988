<template>
    <div v-if="tripadvisorRating.rating || tripadvisorRating.reviewsURL">
        <component
            :is="tripadvisorRating.reviewsURL ? 'a' : 'span'"
            :href="tripadvisorRating.reviewsURL"
            target="_blank"
            class="d-flex align-center text-decoration-none"
        >
            <img
                v-if="!tripadvisorRating.rating"
                :src="'/tripadvisor/ta.svg' | srcHost"
                alt="Tripadvisor"
                height="20"
            />
            <img
                v-else
                :src="`/tripadvisor/ta-${tripadvisorRating.rating * 10}.svg` | srcHost"
                :alt="tripadvisorRating.rating"
            />
            <span
                v-if="tripadvisorRating.reviewsCount"
                class="text-caption secondary--text ms-1 text-truncate"
                :class="{'text-decoration-underline': tripadvisorRating.reviewsURL}"
            >
                {{ $t('based_on') }} {{ $tc('review', tripadvisorRating.reviewsCount) }}
            </span>
        </component>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TripadvisorRating extends Vue {
        @Prop() tripadvisorRating
    }
</script>
