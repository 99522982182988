<template>
    <v-dialog v-model="isShowDialog" max-width="600">
        <template v-slot:activator="{on}">
            <v-btn fab small icon class="mt-n2" :loading="isLoading" v-on="on">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5 primary--text py-5">
                {{ $t('change_total_price.for_client') }}
            </v-card-title>
            <v-card-text>
                <span class="text-caption error--text font-weight-bold" v-text="`${$t('change_total_price.note')}: `" />
                <span class="text-caption" v-text="$t('change_total_price.attention_message')" />
                <v-form v-model="valid">
                    <v-row class="py-4">
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-center justify-center text-subtitle-1">
                                <span class="font-weight-bold py-1" v-text="$t('change_total_price.supplier_price')" />
                                <span
                                    class="text-h6 font-weight-light mt-2"
                                    v-text="`${supplierPrice} ${totalPrice.originalCurrency || totalPrice.currency}`"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-start text-subtitle-1">
                                <span class="font-weight-bold py-1" v-text="$t('change_total_price.markup')" />
                                <div class="d-flex align-center">
                                    <v-text-field v-model="markup" type="number" outlined dense hide-details="auto" />
                                    <span class="ms-2" v-text="'%'" />
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="d-flex flex-column align-start text-subtitle-1">
                                <span class="font-weight-bold py-1" v-text="$t('change_total_price.client_price')" />
                                <div class="d-flex align-center">
                                    <v-text-field
                                        v-model="clientPrice"
                                        type="number"
                                        outlined
                                        dense
                                        :rules="[v => v >= 0 || `${$t('change_total_price.client_price_zero_rule')}`]"
                                        :hint="clientPriceHint"
                                        hide-details="auto"
                                    />
                                    <span class="ms-2" v-text="totalPrice.originalCurrency" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-divider />
            <v-card-actions class="py-4">
                <span class="text-caption px-2" v-text="$t('change_total_price.save_prompt')" />
                <v-spacer />
                <v-btn color="primary" :disabled="!valid" @click="changePriceSubmit">
                    {{ $t('save') }}
                </v-btn>
                <v-btn text @click="isShowDialog = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {EventBus, PREPARE_BOOK} from '@/utils/event-bus'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class ChangeTotalPriceDialog extends Vue {
        @Prop({required: true}) totalPrice
        @Prop({required: true}) productStore
        @Prop() roomOffers
        @Prop({required: true, type: Array}) extraPrices

        valid = false
        clientPriceValue = 0
        markupValue = 0
        isShowDialog = false

        @Watch('isShowDialog')
        onDialogShow() {
            if (this.isShowDialog) {
                this.clientPrice = +this.totalPrice.originalAmount
            }
        }

        changePriceSubmit() {
            if (this.roomOffers && this.roomOffers.length > 1) {
                this.roomOffers.forEach((e, i) => {
                    let originalAmount = e.supplierPrice.amount
                    const extraServicesAmount =
                        this.extraPrices[i]?.price?.originalAmount || this.extraPrices[i]?.price?.amount || 0

                    originalAmount += extraServicesAmount

                    if (this.productStore.checkInOutExtraService[i]?.lateCheckOut) {
                        originalAmount += this.productStore.checkInOutExtraService[i].lateCheckOut.price.amount
                    }
                    if (this.productStore.checkInOutExtraService[i]?.earlyCheckIn) {
                        originalAmount += this.productStore.checkInOutExtraService[i].earlyCheckIn.price.amount
                    }

                    let adjustedPriceAmount =
                        Math.round((originalAmount + (originalAmount * +this.markupValue) / 100) * 100) / 100

                    adjustedPriceAmount -= extraServicesAmount

                    if (this.productStore.checkInOutExtraService[i]?.lateCheckOut) {
                        adjustedPriceAmount -= this.productStore.checkInOutExtraService[i].lateCheckOut.price.amount
                    }
                    if (this.productStore.checkInOutExtraService[i]?.earlyCheckIn) {
                        adjustedPriceAmount -= this.productStore.checkInOutExtraService[i].earlyCheckIn.price.amount
                    }

                    this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({
                        index: i,
                        prop: 'adjustedPriceAmount',
                        val: adjustedPriceAmount,
                    })
                })
            } else {
                let adjustedPriceAmount = this.clientPriceValue
                const extraServicesAmount =
                    this.extraPrices[0]?.price?.originalAmount || this.extraPrices[0]?.price?.amount || 0

                adjustedPriceAmount -= extraServicesAmount

                if (this.productStore.checkInOutExtraService?.[0]?.lateCheckOut) {
                    adjustedPriceAmount -= this.productStore.checkInOutExtraService[0].lateCheckOut.price.amount
                }
                if (this.productStore.checkInOutExtraService?.[0]?.earlyCheckIn) {
                    adjustedPriceAmount -= this.productStore.checkInOutExtraService[0].earlyCheckIn.price.amount
                }

                this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({
                    prop: 'adjustedPriceAmount',
                    val: adjustedPriceAmount,
                    index: 0,
                })
            }

            EventBus.$emit(PREPARE_BOOK)
            this.isShowDialog = false
        }

        get clientPriceHint() {
            return this.supplierPrice > this.clientPrice
                ? this.$t('change_total_price.client_price_to_supplier_rule')
                : null
        }

        get isLoading() {
            return runtimeStore.bookingActive
        }

        get supplierPrice() {
            return +this.totalPrice.supplierPriceOriginalCurrencyAmount
        }

        get markup() {
            return this.markupValue
        }

        get clientPrice() {
            return this.clientPriceValue
        }

        set markup(val) {
            this.markupValue = val
            this.clientPriceValue = ((this.markupValue / 100) * this.supplierPrice + this.supplierPrice).toFixed(2)
        }

        set clientPrice(val) {
            this.clientPriceValue = val
            this.markupValue = (((this.clientPriceValue - this.supplierPrice) / this.supplierPrice) * 100).toFixed(2)
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-text-field__details {
            .v-messages__message {
                color: var(--v-warning-base);
            }
        }
    }
</style>
