<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <car-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <cars-search-summary :search-request="searchRequest" search-disabled />
            <car-order-summary
                :search-request="searchRequest"
                :info="product.info"
                :offer="product.offer"
                :prepare-book-response="prepareBookResponse"
            />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import CarBreadcrumbs from '@/components/carsRent/CarBreadcrumbs'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import CarsSearchPageMixin from '@/components/carsRent/mixins/CarsSearchPageMixin'
    import {carsRentStore} from '@/utils/store-accessor'
    import CarsSearchSummary from '@/components/carsRent/search/CarsSearchSummary'
    import CarOrderSummary from '@/components/carsRent/booking/CarOrderSummary'

    @Component({
        components: {
            ConfirmationPageLayout,
            CarBreadcrumbs,
            CarsSearchPageMixin,
            CarsSearchSummary,
            CarOrderSummary,
        },
        layout: 'blank',
    })
    export default class CarConfirmationPage extends mixins(ConfirmationPageMixin, CarsSearchPageMixin) {
        get basketItem() {
            return carsRentStore.basketItem(carsRentStore.prepareBookRequest.offerKey)
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offer
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }
    }
</script>
