<template>
    <v-snackbar v-model="isShowSnackbar" top color="error">
        {{ $t('offer_not_available') }}
        <template v-slot:action="{attrs}">
            <v-btn text v-bind="attrs" @click="refresh">
                {{ $t('search') }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    import {EventBus} from '@/utils/event-bus'
    import {hotelsStore} from '@/utils/store-accessor'
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class RefreshOffersCitizenshipSnackbar extends Vue {
        @Prop() basketItem

        isShowSnackbar = false
        citizenshipId = null

        show(citizenshipId) {
            if (citizenshipId) {
                this.citizenshipId = citizenshipId
                this.isShowSnackbar = true
            }
        }

        async refresh() {
            let hotelId

            try {
                const rs = await this.$api.suggestHotels.get({
                    pattern: this.basketItem.offer.name,
                    limit: 1,
                    locationId: this.basketItem.searchRequest.cityId,
                })
                hotelId = rs.hotels[0]?.id
                // eslint-disable-next-line no-empty
            } catch (e) {}

            hotelsStore.SET_SEARCH_REQUEST({
                ...hotelsStore.searchRequest,
                citizenshipId: this.citizenshipId,
            })

            this.$emit('refresh', hotelId)
            this.isShowSnackbar = false
            this.citizenshipId = null
        }

        created() {
            EventBus.$on('SHOW_OFFER_NOT_AVAILABLE_CITIZENSHIP', this.show)
        }

        beforeDestroy() {
            EventBus.$off('SHOW_OFFER_NOT_AVAILABLE_CITIZENSHIP', this.show)
        }
    }
</script>
