<template>
    <div v-if="showPaymentAndInvoiceStatus" :class="statusColor">
        {{ text }}
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class PaymentStatusLabel extends Vue {
        @Prop({required: true}) status
        @Prop({default: false, type: Boolean}) cardGuarantee
        @Prop({default: false, type: Boolean}) noColor

        get text() {
            if (this.$config.account.plainPaymentStatus || this.$config.account.paymentScheduler) {
                return this.$t(
                    `plainPaymentStatus.${!['PAID', 'PARTIAL_PAID'].includes(this.status) ? 'NOT_PAID' : this.status}`
                )
            } else {
                return this.$t(`paymentStatus.${this.cardGuarantee ? 'PAY_AT_HOTEL' : this.status}`)
            }
        }

        get statusColor() {
            if (this.noColor) return null
            if (this.$config.account.plainPaymentStatus || this.$config.account.paymentScheduler) {
                if (this.status === 'PAID') {
                    return 'success--text'
                } else if (this.status === 'PARTIAL_PAID') {
                    return 'warning--text'
                }
            } else {
                if (this.cardGuarantee) {
                    return 'info--text'
                } else if (['PAID', 'BILL', 'PARTIAL_PAID'].includes(this.status)) {
                    return 'success--text'
                } else if (['NO_BILL', 'IN_TRANSIT', 'NO_FULLY_BILL', 'INVOICE_WAITING'].includes(this.status)) {
                    return 'warning--text'
                }
            }
            return 'error--text'
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }
    }
</script>
