<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class CustomAdditionalOptions extends Vue {
        @Prop() productName

        render() {
            return null
        }
    }
</script>
