<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import {ACTIVITY} from '@/utils/activities/activities-const'
    import {isOwnProduct} from '@/utils/api-helpers'
    import ActivityOrderSummaryMixin from '@/components/activities/mixins/ActivityOrderSummaryMixin.vue'

    @Component
    export default class ActivityBookingPageLayout extends mixins(
        BookingPageBase,
        ActivityStoreMixin,
        ActivityOrderSummaryMixin
    ) {
        @Prop({required: true}) product

        get productSearchPageName() {
            return 'activities'
        }

        get productConfirmationPageName() {
            return 'activityConfirmation'
        }

        get productName() {
            return ACTIVITY
        }

        get productBookingPageName() {
            return 'activityBooking'
        }

        get corporatePolicyType() {
            return 'activity'
        }

        get touristToken() {
            return 'guest'
        }

        get startDate() {
            return this.offer.date
        }

        get showAddOns() {
            return isOwnProduct(this.product.supplierId)
        }
    }
</script>
