<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="8" md="6">
                <airports-search-input
                    v-model="otherAirportCode"
                    :label="
                        pointType === 'arrival'
                            ? productFieldLabel('transfers.departure_to_airport', 'POINT_AIRPORT')
                            : productFieldLabel('transfers.arriving_from_airport', 'POINT_AIRPORT')
                    "
                    :rules="airportRules"
                />
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="2">
                <v-text-field
                    v-model="flightNumber"
                    :label="productFieldLabel('transfers.flight_number', 'POINT_FLIGHT_NUMBER')"
                    :rules="flightNumberRules"
                />
            </v-col>
            <v-col cols="6" sm="4" md="3" lg="2">
                <date-picker
                    v-model="date"
                    :label="productFieldLabel('date', 'POINT_FLIGHT_TIME')"
                    :rules="flightDateRules"
                />
            </v-col>
            <v-col v-if="!isProductFieldDisabled('POINT_FLIGHT_TIME')" cols="6" sm="4" md="3" lg="2" xl="1">
                <time-field
                    v-model="time"
                    :label="productFieldLabel('time', 'POINT_FLIGHT_TIME')"
                    :rules="flightTimeRules"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import AirportsSearchInput from '~/components/snippets/AirportsSearchInput'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferAirportForm from '@/components/transfers/booking/forms/TransferAirportForm'

    @Component({
        components: {TimeField, AirportsSearchInput, DatePicker},
    })
    export default class AirportForm extends TransferAirportForm {
        @Prop() serviceType

        getProp(prop) {
            if (!this.request[this.pointType]) return null

            return this.request[this.pointType][this.serviceType][prop]
        }

        setProp(prop, val) {
            this.request[this.pointType][this.serviceType][prop] = val
        }

        initData() {}
    }
</script>
