<template>
    <div class="mb-5">
        <strong class="d-block" v-html="offer.name" />
        <strong class="d-block">{{ $t('date') }}: {{ startDate | dateFormat }} - {{ endDate | dateFormat }}</strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_INSURANCE_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class InsuranceConditionsModalInfo extends Vue {
        searchRequest = {}
        offer = {}

        mounted() {
            EventBus.$on(SHOW_INSURANCE_CONDITIONS_INFO, this.showInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_INSURANCE_CONDITIONS_INFO, this.showInfo)
        }

        showInfo(searchRequest, offer) {
            this.searchRequest = searchRequest
            this.offer = offer
        }

        get startDate() {
            return this.searchRequest.startDate
        }

        get endDate() {
            return this.searchRequest.endDate
        }
    }
</script>
