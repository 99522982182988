<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <flight-offers :offers="entry.offers">
            <template v-slot:price="{offer}">
                <tour-offer-delta-button :offer="offer" :offer-key="offerKey" :entry-name="entry.entryName" />
                <pricing-source-type-label :service="offer" class="text-right full-width" />
            </template>
        </flight-offers>
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_FLIGHT_INFO} from '@/utils/event-bus'
    import FlightOffers from '@/components/flights/search/offers/FlightOffers'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton'
    import PricingSourceTypeLabel from '@/components/snippets/PricingSourceTypeLabel.vue'

    @Component({
        components: {
            PricingSourceTypeLabel,
            TourOfferDeltaButton,
            FlightOffers,
        },
    })
    export default class TourFlightInfoModal extends Vue {
        modal = false
        entry = {}
        offerKey = null

        mounted() {
            EventBus.$on(SHOW_TOUR_FLIGHT_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_FLIGHT_INFO, this.showModal)
        }

        showModal(entry, offerKey) {
            this.entry = entry
            this.offerKey = offerKey
            this.modal = true
        }
    }
</script>
