<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {extraServicesRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class ExtraServicePageMixin extends Vue {
        async loadRuntimeData() {
            if (extraServicesRuntimeStore.city.id !== this.searchRequest.cityId) {
                await extraServicesRuntimeStore.loadCity(this.searchRequest.cityId)
            }
        }
    }
</script>
