<template>
    <v-alert
        v-if="orderId"
        dense
        dark
        color="grey darken-2"
        class="add-service-snackbar cursor-pointer rounded-0 sticky-bar"
        @click="goToOrder"
    >
        <template v-slot:prepend>
            <v-badge v-if="servicesNumber" :offset-x="6" :offset-y="22" color="green" :content="servicesNumber">
                <v-icon>mdi-briefcase-variant-outline</v-icon>
            </v-badge>
            <v-icon v-else class="me-3">mdi-briefcase-variant-outline</v-icon>
            <span :class="{'ms-7': !!servicesNumber}" v-text="`${$t('order')}  #${orderId}`" />
            <v-tooltip v-if="order" bottom color="dark">
                <template v-slot:activator="{on}">
                    <v-icon right small v-on="on">mdi-information-outline</v-icon>
                </template>
                <div class="py-4">
                    <div
                        v-for="service in order.services"
                        :key="service.serviceId"
                        class="d-flex flex-row align-center mb-1"
                    >
                        <v-icon color="white" class="mr-2 mb-1">
                            {{ serviceTypeIcon(service) }}
                        </v-icon>
                        <span>
                            {{ service.startDateTime | dateShortFormat }}
                            {{ service.serviceName }}
                        </span>
                    </div>
                </div>
            </v-tooltip>
        </template>
        <template v-slot:close>
            <v-btn color="white" icon class="close-btn" @click.stop="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-alert>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '~/store'
    import {getServiceTypeIcon} from '@/utils/helpers'

    @Component
    export default class AddServiceSnackbar extends Vue {
        async mounted() {
            await this.$store.restored
            if (this.orderId && this.servicesNumber === undefined) {
                await runtimeStore.loadOrder({
                    orderType: authStore.orderType,
                    orderId: this.orderId,
                })
            }
        }

        goToOrder() {
            if (this.isDriver || this.isGuide) {
                this.$router.push({name: 'scheduler'})
            } else {
                this.$router.push({name: 'order-details', params: {id: this.orderId}})
            }
        }

        close() {
            this.goToOrder()
            persistentStore.SET_SELECTED_ORDER_ID(null)
        }

        serviceTypeIcon(service) {
            return getServiceTypeIcon(service.serviceType)
        }

        get orderId() {
            return persistentStore.selectedOrderId
        }

        get order() {
            return runtimeStore.orderDetails
        }

        get servicesNumber() {
            return this.order?.services?.length
        }

        get isGuide() {
            return authStore.isGuide
        }

        get isDriver() {
            return authStore.isDriver
        }
    }
</script>
