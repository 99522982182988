<template>
    <div>
        <v-carousel :show-arrows="nextCarouselItems.length > 0" hide-delimiters height="auto">
            <v-carousel-item key="0">
                <v-row no-gutters>
                    <v-col class="d-flex child-flex pe-2 ps-0" cols="4">
                        <v-card flat tile class="d-flex">
                            <v-img
                                v-if="mainImage && mainImage.url"
                                :src="mainImage.url | imageUrl(600)"
                                :title="imgTitle(mainImage)"
                                :lazy-src="'/placeholder.png' | srcHost"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                @click="showPhotoSwipe(0)"
                                @load="onImageLoad"
                                @error="hidePlaceholder(mainImage.url)"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        v-show="imagePlaceholdersVision[0]"
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5" />
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="8">
                        <v-row dense>
                            <v-col
                                v-for="(image, imageIndex) in firstCarouselItem"
                                :key="imageIndex"
                                class="d-flex child-flex"
                                cols="3"
                            >
                                <v-card flat tile class="d-flex">
                                    <v-img
                                        v-if="image && image.url"
                                        :src="image.url | imageUrl(350)"
                                        :title="imgTitle(image)"
                                        :lazy-src="'/placeholder.png' | srcHost"
                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                        @click="showPhotoSwipe(imageIndex + 1)"
                                        @error="hidePlaceholder(image.url)"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                v-show="imagePlaceholdersVision[getImageIndex(image.url)]"
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular indeterminate color="grey lighten-5" />
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-carousel-item>
            <v-carousel-item v-for="(images, index) in nextCarouselItems" :key="index + 1">
                <v-row dense>
                    <v-col v-for="(image, imageIndex) in images" :key="imageIndex" class="d-flex child-flex" cols="2">
                        <v-card flat tile class="d-flex">
                            <v-img
                                v-if="image && image.url"
                                :src="image.url | imageUrl(600)"
                                :title="imgTitle(image)"
                                :lazy-src="'/placeholder.png' | srcHost"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                @error="hidePlaceholder(image.url)"
                                @click="
                                    showPhotoSwipe(
                                        imagesCountInFirstCarouselItem + index * imagesCountInCarouselItem + imageIndex
                                    )
                                "
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        v-show="imagePlaceholdersVision[getImageIndex(image.url)]"
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5" />
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-carousel-item>
        </v-carousel>
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import VPhotoSwipe from '@/components/snippets/VPhotoSwipe'

    @Component({components: {VPhotoSwipe}})
    export default class ProductGallery extends Vue {
        @Prop({default: () => []}) imagesData

        photoSwipeIsOpen = false
        photoSwipeOptions = {
            index: 0,
        }
        imagePlaceholdersVision = []

        mounted() {
            this.mapImagePlaceholders()
        }

        showPhotoSwipe(index) {
            this.photoSwipeOptions.index = index
            this.photoSwipeIsOpen = true
        }

        mapImagePlaceholders() {
            this.$set(this.imagePlaceholdersVision, 0, true)
            this.images.forEach((image, index) => {
                this.$set(this.imagePlaceholdersVision, index + 1, true)
            })
        }

        getImageIndex(url) {
            return this.images.findIndex(image => image.url === url) + 1
        }

        hidePlaceholder(url) {
            this.$set(this.imagePlaceholdersVision, this.getImageIndex(url), false)
        }

        onImageLoad(src) {
            const img = new Image()
            /*img.addEventListener('load', function() {
                item.w = this.naturalWidth;
                item.h = this.naturalHeight;
            });*/
            img.src = src
            //console.log(img.naturalWidth, img.naturalHeight);
        }

        imgTitle(img) {
            return img.title === 'null' ? null : img.title
        }

        get imagesCountInFirstCarouselItem() {
            return 9
        }

        get firstCarouselItem() {
            return this.images.slice(1, this.imagesCountInFirstCarouselItem).filter(Boolean)
        }

        get imagesCountInCarouselItem() {
            return 12
        }

        get nextCarouselItems() {
            const nextCarouselItems = []
            for (
                let i = this.imagesCountInFirstCarouselItem;
                i < this.images.length;
                i = i + this.imagesCountInCarouselItem
            ) {
                nextCarouselItems.push(this.images.slice(i, i + this.imagesCountInCarouselItem).filter(Boolean))
            }
            return nextCarouselItems
        }

        get mainImage() {
            return this.images[0]
        }

        get images() {
            return [...this.imagesData].sort(a => (a.mainImage ? -1 : 0))
        }

        get photoSwipeItems() {
            return this.images.map(({url, title}) => ({
                src: this.$options.filters.imageUrl(url, 800),
                w: 0,
                h: 0,
                title: title === 'null' ? null : title,
            }))
        }
    }
</script>
