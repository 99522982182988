<template>
    <v-card class="mb-5">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="5">
                    <tour-general-info :info="info" />
                </v-col>
                <v-col cols="12" md="2">
                    <div v-for="traveler in travelers" :key="traveler.personId">
                        <v-icon left small>mdi-account</v-icon>
                        <div>
                            <span class="text-uppercase" v-html="getTravelerName(traveler)" />
                            <small>{{ traveler.citizenshipName }}</small>
                            <div v-if="traveler.passports && traveler.passports.length" class="text-caption">
                                {{ $t('document') }} {{ traveler.passports[0].number }}<br />
                                {{ $t('expire_date') }} {{ traveler.passports[0].expiryDate }}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="3" class="justify-center justify-md-end">
                    <order-prices
                        :supplier-cancel-penalties="order.orderPackage.supplierCancelPenalties"
                        :supplier-payment-status="order.supplierPaymentStatus"
                        :supplier-price="order.orderPackage.supplierPrice"
                        :client-cancel-penalties="order.orderPackage.clientCancelPenalties"
                        :customer-payment-status="order.customerPaymentStatus"
                        :client-price="order.orderPackage.clientPrice"
                    />
                </v-col>
                <v-col
                    v-if="isShowBookButton || isShowCancelButton"
                    cols="12"
                    md="2"
                    class="d-flex flex-column align-md-end justify-center"
                >
                    <v-btn v-if="isShowBookButton" depressed color="primary" @click="bookAllQuote">
                        {{ $t('book') }}
                    </v-btn>
                    <v-btn v-if="isShowCancelButton" class="mt-2" depressed @click="cancelTour">
                        {{ $t('cancel') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import OrderPrices from '~src/components/account/snippets/orderPrices.src'
    import TourGeneralInfo from '@/components/tours/snippets/TourGeneralInfo'
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'

    @Component({
        components: {
            TourGeneralInfo,
            OrderPrices,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
        },
    })
    export default class PackageTourInfo extends Vue {
        @Prop() order

        @Emit()
        cancelTour() {}

        @Emit()
        bookAllQuote() {}

        get isShowCancelButton() {
            return this.packageServices.every(
                s =>
                    !!s.cancellationAllowed ||
                    (s.status === 'Confirmation pending' && this.$config.account.cancelConfirmationPendingService)
            )
        }

        get isShowBookButton() {
            return this.packageServices.every(s => s.status === 'Quote')
        }

        get packageServices() {
            return this.order.services.filter(s => !!s.serviceContainerId)
        }

        get info() {
            let startDate = this.packageServices[0].startDateTime
            let endDate = this.packageServices[0].endDateTime
            for (let i = 0; i < this.packageServices.length; i++) {
                if (
                    this.$dateFns.isBefore(
                        this.$dateFns.parseISO(this.packageServices[i].startDateTime),
                        this.$dateFns.parseISO(startDate)
                    )
                )
                    startDate = this.packageServices[i].startDateTime
                if (
                    this.$dateFns.isAfter(
                        this.$dateFns.parseISO(this.packageServices[i].startDateTime),
                        this.$dateFns.parseISO(endDate)
                    )
                )
                    endDate = this.packageServices[i].startDateTime
            }
            // TODO: you need to understand why it is calculated one less
            const packageDuration =
                this.$dateFns.differenceInCalendarDays(
                    this.$dateFns.parseISO(endDate),
                    this.$dateFns.parseISO(startDate)
                ) + 1

            return {
                packageDuration,
                packageDate: startDate,
            }
        }

        get travelers() {
            return this.packageServices.travelers
        }

        getTravelerName(traveler) {
            if (traveler.prefix) return `${traveler.prefix} ${traveler.name[0].firstName} ${traveler.name[0].lastName}`
            return `${traveler.name[0].firstName} ${traveler.name[0].lastName}`
        }
    }
</script>
