<template>
    <v-card class="my-2 product-card" outlined>
        <div class="d-flex wrapper">
            <div style="position: relative">
                <v-img
                    tile
                    :src="thumb | imageUrl(350)"
                    :alt="name"
                    eager
                    width="200"
                    max-width="200"
                    height="200"
                    :lazy-src="'/placeholder.png' | srcHost"
                    class="me-2"
                    @error="imgError = true"
                >
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && thumb" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <slot name="wishlist" />
            </div>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                <slot />
            </div>
            <div class="ma-4 price-col flex-shrink-0 text-right">
                <slot name="priceCol" />
            </div>
            <recommended-icon v-if="isRecommended" class="recommended-thumb" />
        </div>
        <v-expand-transition>
            <slot name="offers" />
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import RecommendedIcon from '@/components/search/RecommendedIcon'
    import WishlistLike from '@/components/search/offers/WishlistLike'

    @Component({
        components: {WishlistLike, RecommendedIcon},
    })
    export default class ProductCardLayout extends Vue {
        @Prop({required: true}) thumb
        @Prop({required: true}) name
        @Prop({required: true}) productLink
        @Prop({required: true}) offersCount
        @Prop({default: false, type: Boolean}) isRecommended
        @Prop({default: false, type: Boolean}) comparisonMode
        @PropSync('expanded', {required: true}) expandedCount
        @PropSync('expandedLimit', {required: true}) expandedLimitVal

        imgError = false

        toggleOffers() {
            this.expandedCount = this.expandedCount ? 0 : this.expandedLimitVal
        }
    }
</script>

<style scoped lang="scss">
    .product-card {
        overflow: hidden;
        cursor: unset;
    }

    .wrapper {
        height: 200px;
    }

    .price-col {
        width: 180px;
    }

    .recommended-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;
        }
    }

    ::v-deep .v-expansion-panel {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>
