<script>
    import {Component, Prop} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'

    @Component
    export default class HotelAutocomplete extends CityAutocomplete {
        @Prop() cityId
        @Prop() contentProviderId

        async searchHotels(val) {
            const rq = {
                pattern: val,
                contentProviderId: this.contentProviderId,
                limit: 10,
            }
            if (this.cityId) {
                rq.locationId = this.cityId
            } else if (this.countryId) {
                rq.locationId = this.countryId
            }
            const rs = await this.$api.suggestHotels.get(rq)
            return rs.hotels.map(hotel => {
                const location = hotel.location
                delete hotel.location
                return Object.assign(hotel, location, {parentName: location.countryName, type: 'hotel'})
            })
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                this.entries = await this.searchHotels(val)
            } finally {
                this.loading = false
            }
        }

        icon(type) {
            switch (type) {
                case 'hotel':
                    return this.hotelIcon
                default:
                    return this.locationIcon
            }
        }

        get items() {
            return this.entries.map(entry => {
                const label = `${entry.name}, ${entry.cityName}, ${entry.countryName}`
                return Object.assign({}, entry, {label})
            })
        }

        get hotelIcon() {
            return 'mdi-office-building'
        }
    }
</script>
