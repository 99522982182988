<template>
    <template-editor-page-layout @save="save" @save-new="saveNew">
        <keep-alive>
            <email-editor ref="editor" class="flex-grow-1" @load="editorLoaded" />
        </keep-alive>
    </template-editor-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {EmailEditor} from 'vue-email-editor'
    import {adminRuntimeStore} from '@/utils/store-accessor'
    import TemplateEditorPageLayout from '@/components/admin/TemplateEditorPageLayout'
    import TemplateEditorPageMixin from '@/components/account/mixins/TemplateEditorPageMixin'

    @Component({
        components: {
            TemplateEditorPageLayout,
            EmailEditor,
        },
        middleware: ['auth', 'authorization'],
        layout: 'pure',
    })
    export default class EmailEditorPage extends mixins(TemplateEditorPageMixin) {
        beforeDestroy() {
            this.$refs.editor = null
        }

        //TODO Need fix editor init after second page mount or reload this page
        async editorLoaded() {
            if (!this.$route.params.id) return
            let template = adminRuntimeStore.notificationTemplates.find(
                template => template.id === this.$route.params.id && !template.notificationType.includes('DOC')
            )
            if (!template) {
                try {
                    const data = await this.$api.notificationTemplates.get(this.$route.params.id)
                    template = data.templates[0]
                } catch (e) {
                    this.$nuxt.error({statusCode: 404})
                }
            }
            this.template = template
            this.$refs.editor.loadDesign(JSON.parse(this.template.jsonTemplate))
        }

        save() {
            this.$refs.editor.exportHtml(async data => {
                await this.saveTemplate({
                    jsonTemplate: JSON.stringify(data.design),
                    htmlTemplate: data.html,
                    info: this.template.info,
                })
                await this.$router.push({name: 'emailTemplates'})
            })
        }

        saveNew(template) {
            this.$refs.editor.exportHtml(async data => {
                await this.saveNewTemplate({
                    ...template,
                    jsonTemplate: JSON.stringify(data.design),
                    htmlTemplate: data.html,
                })
                await this.$router.push({name: 'emailTemplates'})
            })
        }
    }
</script>
