<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'

    @Component
    export default class SupplierAutocomplete extends CityAutocomplete {
        @Prop({required: false, default: 'SUPPLIER', type: String}) relationship

        async searchSuppliers(val) {
            const rq = {
                relationship: this.relationship,
                shortResponse: true,
                limit: 10,
                name: val,
            }
            const rs = await this.$api.companies.get(rq)

            return rs.object
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                this.entries = await this.searchSuppliers(val)
            } finally {
                this.loading = false
            }
        }

        get items() {
            return this.entries.map(entry => {
                const label = entry.name
                return Object.assign({}, entry, {label})
            })
        }

        get locationIcon() {
            return ''
        }
    }
</script>
