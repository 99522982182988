<template>
    <div>
        <v-row class="d-flex align-center">
            <v-col :md="7" :cols="12">
                <client-autocomplete
                    v-if="!isNewClientMode"
                    v-model="selectedClient"
                    :label="`${$t('choose.client_long')}*`"
                />
            </v-col>
            <v-col v-if="!isGuest" :md="5" :cols="12">
                <div class="d-flex">
                    <v-spacer />
                    <v-btn
                        elevation="0"
                        class="ms-3"
                        @click="isNewClientMode = !isNewClientMode"
                        v-text="
                            isNewClientMode ? `${$t('choose.choose')} ${$t('choose.client')}` : $t('new_user_profile')
                        "
                    />
                </div>
            </v-col>
        </v-row>
        <v-expand-transition>
            <create-new-client v-if="isNewClientMode" class="mt-8" :value="newClient" />
        </v-expand-transition>
    </div>
</template>

<script>
    import {Component, PropSync, VModel, Vue} from 'nuxt-property-decorator'
    import ClientAutocomplete from '@/components/account/modals/newOrderRequest/ClientAutocomplete'
    import CreateNewClient from '@/components/account/modals/newOrderRequest/CreateNewClient'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {CreateNewClient, ClientAutocomplete},
    })
    export default class PrivateClient extends Vue {
        @VModel() selectedClient
        @PropSync('_newClient') newClient
        @PropSync('_isNewClientMode') isNewClientMode

        get isGuest() {
            return !authStore.person
        }
    }
</script>
