<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class ProductBookingFieldsMixin extends Vue {
        isProductFieldMandatory(fieldKey) {
            return this.productBookingFields?.[fieldKey] === 'MANDATORY'
        }

        isProductFieldDisabled(fieldKey) {
            return this.productBookingFields?.[fieldKey] === 'DISABLED'
        }

        productFieldLabel(token, fieldKey) {
            return this.isProductFieldMandatory(fieldKey) ? `${this.$t(token)}*` : `${this.$t(token)}`
        }

        get productBookingFields() {
            return runtimeStore.productBookingFields
        }
    }
</script>
