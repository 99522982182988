<template>
    <v-card :outlined="outlined" :elevation="elevation" tile>
        <v-card-title class="title">
            {{ $t('carsRent.pick-up_and_drop-off_points') }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col v-if="pickUpItems.length" cols="12" md="3">
                        <v-select
                            :items="pickUpItems"
                            :label="$t('carsRent.pick-up_point')"
                            :rules="[v => !!v || `${$t('carsRent.pick-up_point')} ${$t('validation.required')}`]"
                            :disabled="disabled"
                            dense
                            @input="setPickUpLocation"
                        />
                        <div v-if="pickUpLocation.address">
                            <v-icon color="primary" left small>mdi-map-marker</v-icon>
                            {{ pickUpLocation.address }}
                            <div
                                v-if="pickUpLocation.latitude && pickUpLocation.longitude"
                                class="ms-2 d-inline-block text-caption primary--text text-decoration-underline text-lowercase cursor-pointer"
                                @click="
                                    $emit('show-on-map', {
                                        latitude: pickUpLocation.latitude,
                                        longitude: pickUpLocation.longitude,
                                    })
                                "
                            >
                                {{ $t('show_on_map') }}
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="dropOffItems.length" cols="12" md="3">
                        <v-select
                            :items="dropOffItems"
                            :label="$t('carsRent.drop-off_point')"
                            :rules="[
                                v =>
                                    !!v ||
                                    supplierCode === 'jonview' ||
                                    `${$t('carsRent.drop-off_point')} ${$t('validation.required')}`,
                            ]"
                            :disabled="disabled"
                            dense
                            @input="setDropOffLocation"
                        />
                        <div v-if="dropOffLocation.address">
                            <v-icon color="primary" left small>mdi-map-marker</v-icon>
                            {{ dropOffLocation.address }}
                            <div
                                v-if="dropOffLocation.latitude && dropOffLocation.longitude"
                                class="ms-2 d-inline-block text-caption primary--text text-decoration-underline text-lowercase cursor-pointer"
                                @click="
                                    $emit('show-on-map', {
                                        latitude: dropOffLocation.latitude,
                                        longitude: dropOffLocation.longitude,
                                    })
                                "
                            >
                                {{ $t('show_on_map') }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import {carsRentStore} from '@/utils/store-accessor'

    @Component
    export default class CarDetailsForm extends Vue {
        @Prop({required: true}) offer
        @Prop() product
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: 0}) elevation
        @PropSync('_valid', {default: false}) valid

        beforeDestroy() {
            this.clearLocations()
        }

        setPickUpLocation(val) {
            carsRentStore.SET_PICK_UP_LOCATION(val)
        }

        setDropOffLocation(val) {
            carsRentStore.SET_DROP_OFF_LOCATION(val)
        }

        clearLocations() {
            carsRentStore.SET_PICK_UP_LOCATION({})
            carsRentStore.SET_DROP_OFF_LOCATION({})
        }

        get pickUpLocation() {
            return carsRentStore.pickUpLocation
        }

        get dropOffLocation() {
            return carsRentStore.dropOffLocation
        }

        get pickUpItems() {
            return (
                this.offer?.pickUpPoints?.map(value => ({
                    text: value.name || value.address || value.locationCode,
                    value,
                })) || []
            )
        }

        get dropOffItems() {
            return (
                this.offer?.returnPoints?.map(value => ({
                    text: value.name || value.address || value.locationCode,
                    value,
                })) || []
            )
        }

        get supplierCode() {
            return this.product?.info.supplierCode
        }
    }
</script>
