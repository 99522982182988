<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import {insuranceRuntimeStore} from '@/store'

    @Component
    export default class InsuranceBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest

        get items() {
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'insuranceSearch':
                    items.push({
                        text: `${insuranceRuntimeStore.location.name}`,
                        to: insuranceRuntimeStore.searchPageLink(this.searchRequest),
                        disabled: true,
                        nuxt: true,
                        exact: true,
                    })
                    break
                case 'insurance':
                    break
                case 'insuranceBooking':
                    items.push({
                        text: `${insuranceRuntimeStore.location.name}`,
                        to: insuranceRuntimeStore.searchPageLink(this.searchRequest),
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'insuranceConfirmation':
                    break
            }
            return items
        }
    }
</script>
