<script>
    import {Component} from 'nuxt-property-decorator'
    import SupplierAutocomplete from '@/components/search/forms/SupplierAutocomplete'

    @Component
    export default class PrivateClientAutocomplete extends SupplierAutocomplete {
        async searchPrivateClients(val) {
            const rq = {
                pattern: val,
                limit: 10,
                active: true,
            }
            const rs = await this.$api.privateClients.get(rq)

            return rs.persons
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                this.entries = await this.searchPrivateClients(val)
            } finally {
                this.loading = false
            }
        }

        get items() {
            return this.entries.map(entry => {
                const prefix = entry.prefix ? entry.prefix : ''
                const firstName = entry.firstName ? entry.firstName : ''
                const lastName = entry.lastName ? entry.lastName : ''
                const label =
                    (prefix ? `${prefix} ` : '') + (firstName ? `${firstName} ` : '') + (lastName ? `${lastName}` : '')
                return Object.assign({}, entry, {label})
            })
        }
    }
</script>
