<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {activitiesRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class ActivityPageMixin extends Vue {
        async loadRuntimeData() {
            if (activitiesRuntimeStore.city.id !== this.searchRequest.cityId)
                await activitiesRuntimeStore.loadCity(this.searchRequest.cityId)
        }
    }
</script>
