<template>
    <v-dialog v-model="modal" overlay-opacity="0" width="500">
        <v-card v-if="isLoading" color="primary" dark>
            <v-card-title class="justify-space-between px-4 fs-14">
                {{ $t('cancellation_penalties') }}
            </v-card-title>
            <v-card-text class="fs-14">
                <slot name="info" />
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-title class="justify-space-between px-4 fs-14">
                {{ $t('cancellation_penalties') }}
                <v-icon class="justify-end" @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <div class="px-4 py-0 text--secondary font-weight-thin fs-12">
                <slot name="info" />
            </div>
            <v-list v-if="!hasErrors">
                <template v-if="freeDate">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <strong class="green--text font-weight-regular fs-12">
                                    {{ $t('before') }}
                                    <template v-if="userTimeZone">
                                        {{ $dateFns.addMinutes(freeDate, -1) | dateTimeFormat }} *
                                    </template>
                                    <template v-else>
                                        {{ freeDate | dateFormat }}
                                    </template>
                                </strong>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="mr-1">
                            <strong class="success--text font-weight-regular fs-12">{{
                                $t('cancellation_free')
                            }}</strong>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                </template>
                <template v-for="(cancellationItem, index) in conditionsInfo.cancellationItems">
                    <v-list-item :key="index + '_0'">
                        <v-list-item-content>
                            <v-list-item-title v-if="!cancellationItem.noShow">
                                <strong class="red--text font-weight-regular fs-12">
                                    {{ $t('after') }}
                                    <template v-if="userTimeZone">
                                        {{ $dateFns.parseISO(cancellationItem.dateFrom) | dateTimeFormat }} *
                                    </template>
                                    <template v-else>
                                        {{ $dateFns.parseISO(cancellationItem.dateFrom) | dateFormat }}
                                    </template>
                                </strong>
                            </v-list-item-title>
                            <div v-else class="text--secondary font-weight-thin fs-12">
                                <strong>No show</strong>
                                <span>({{ $t('no_show_at_hotel') }})</span>
                            </div>
                            <div
                                v-if="cancellationItem.description"
                                class="text--secondary font-weight-regular fs-12"
                                v-html="cancellationItem.description"
                            />
                        </v-list-item-content>
                        <v-list-item-action v-if="cancellationItem.price && cancellationItem.price.amount" class="mr-1">
                            <strong v-if="disableCurrencyConversion" class="fs-12">
                                {{ cancellationItem.price.amount | priceFormat(cancellationItem.price.currency) }}
                            </strong>
                            <strong v-else class="fs-12">
                                {{ cancellationItem.price | price }}
                            </strong>
                            <slot name="hotelPriceNote" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        v-if="index < conditionsInfo.cancellationItems.length - 1 || conditionsInfo.errors"
                        :key="index + '_1'"
                    />
                </template>
                <v-list-item v-if="userTimeZone" dense class="caption">
                    * {{ $t('your_local_time') }} ({{ userTimeZone }})
                </v-list-item>
                <v-list-item v-for="(error, index) in conditionsInfo.errors" :key="index" class="caption pb-2">
                    <span class="warning--text">{{ error }}</span>
                </v-list-item>
            </v-list>
            <v-card-text v-else class="text-center pt-5 pb-10">
                {{ $t('empty_conditions_message') }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, mixins, Watch} from 'nuxt-property-decorator'
    import {EventBus, HIDE_CONDITIONAL_MODAL_INFO, SHOW_CONDITIONS_MODAL_EVENT} from '@/utils/event-bus'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import ConditionsMixin from '@/components/mixins/ConditionsMixin'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'

    @Component({
        components: {HotelPriceNote},
    })
    export default class ConditionsModal extends mixins(ConditionsMixin) {
        @Prop({type: Boolean}) disableCurrencyConversion

        //TODO Layout for error.error objects
        conditionsInfoData = {}
        offerKey = null
        modal = false

        @Watch('modal')
        onModalChange(modal) {
            if (!modal) {
                EventBus.$emit(HIDE_CONDITIONAL_MODAL_INFO)
            }
        }

        mounted() {
            EventBus.$on(SHOW_CONDITIONS_MODAL_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_CONDITIONS_MODAL_EVENT, this.showModal)
        }

        showModal(offerKey, conditionsInfo, offer) {
            this.offerKey = offerKey
            this.conditionsInfoData = conditionsInfo
            this.offer = offer
            this.modal = true
        }

        get conditionsInfo() {
            return this.offerKey ? persistentStore.conditions[this.offerKey] : this.conditionsInfoData
        }

        get isLoading() {
            return this.offerKey ? runtimeStore.isConditionsLoading(this.offerKey) : false
        }

        get userTimeZone() {
            return this.$cookies.get('userTimeZone')
        }
    }
</script>

<style>
    .fs-14 {
        font-size: 14px !important;
    }
    .fs-12 {
        font-size: 12px;
    }
</style>
