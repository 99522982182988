<script>
    import {Component} from 'vue-property-decorator'
    import ForgotPasswordForm from '~/components/account/forms/ForgotPasswordForm.vue'

    @Component
    export default class ForgotB2BPasswordForm extends ForgotPasswordForm {
        async restorePassword(rq) {
            return await this.$api.restoreB2BUserPasswordWithApiKey.post(rq)
        }
    }
</script>
