<template>
    <v-dialog v-model="dialog" hide-overlay>
        <template v-slot:activator="{on}">
            <v-btn absolute top right fab small v-on="on">
                <v-icon class="" small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card :loading="isLoading" class="overflow-hidden pa-5">
            <v-card-title class="d-flex flex-row">
                <div class="d-flex flex-grow-1 text-h6" v-text="$t('modify.current_traveler_details')" />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="overflow-y-auto">
                <v-form>
                    <v-row v-for="tourist in persons" :key="tourist.travelerId" align="end">
                        <v-col cols="12" md="1">
                            <v-select
                                v-model="tourist.prefix"
                                :items="['Mr', 'Ms', 'Mrs']"
                                :label="`${$t('salutation')}`"
                                :rules="[requiredRule('salutation')]"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                v-model="tourist.firstName"
                                :label="`${$t('first_name')}`"
                                :rules="[requiredRule('first_name')]"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                v-model="tourist.lastName"
                                :label="`${$t('last_name')}`"
                                :rules="[requiredRule('last_name')]"
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <date-picker
                                v-model="tourist.birthdate"
                                :label="$t('birthdate')"
                                :max="$dateFns.format(new Date())"
                                year-active-picker
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field v-model="tourist.number" :label="`${$t('document_number')}`" />
                        </v-col>
                        <v-col cols="12" md="2">
                            <date-picker
                                v-model="tourist.validTill"
                                :label="$t('expire_date')"
                                :rules="[requiredRule('expire_date')]"
                                :min="today"
                                year-active-picker
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row justify="center">
                    <v-col cols="12" md="2">
                        <v-btn :disabled="isLoading" class="mt-5" block color="primary" @click="save">
                            {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component({components: {DatePicker}})
    export default class EditTravelersDialog extends mixins(FormValidationRulesMixin) {
        @Prop({required: true}) travelers
        @Prop({required: true}) service

        isLoading = false
        persons = []
        dialog = false

        created() {
            this.persons = this.travelers.map(t => {
                return {
                    firstName: t.name[0].firstName || '',
                    middleName: t.name[0].middleName || '',
                    lastName: t.name[0].lastName || '',
                    prefix: t.prefix || '',
                    birthdate: t.dateOfBirth || '',
                    number: t.passports[0]?.number || '',
                    validTill: t.passports[0]?.expiryDate || '',
                }
            })
        }

        async save() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            this.isLoading = true

            try {
                await this.$api.travellerData.put(processId, {
                    processId,
                    persons: this.persons,
                })
                await runtimeStore.refreshOrder()

                this.dialog = false
                // eslint-disable-next-line no-empty
            } catch (e) {
                if (e.errors?.[0]?.errorType === 'validation' && e.errors?.[0]?.message) {
                    this.$toast.error(e.errors[0].message, {
                        timeout: 8000,
                    })
                } else {
                    this.$toast.error(this.$t('error_message.save_error'))
                }
            } finally {
                this.isLoading = false
            }
        }

        get today() {
            return this.$dateFns.format(new Date())
        }
    }
</script>
