<template>
    <div class="pa-4">
        <breadcrumbs />
        <v-stepper v-model="stepper">
            <v-stepper-items>
                <v-stepper-content :step="1">
                    <v-tabs v-model="selectedTabIndex" show-arrows center-active class="mb-8" @change="load">
                        <v-tab v-for="tab in tabs" :key="tab.name">
                            <v-icon class="me-1">
                                {{ tab.ico }}
                            </v-icon>
                            {{ $t(tab.title) }}
                        </v-tab>
                    </v-tabs>
                    <div class="mb-4 text-body-2 primary--text cursor-pointer" @click="expanded = !expanded">
                        {{ $t('filters') }}
                        <v-icon color="primary" small>
                            {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                    </div>
                    <v-expand-transition>
                        <v-row v-show="expanded" class="mb-4">
                            <v-col cols="12" md="4">
                                <order-service-daterange
                                    :_start-date.sync="startDate"
                                    :_end-date.sync="endDate"
                                    :start-date-label="$t('create_date_from')"
                                    :end-date-label="$t('create_date_to')"
                                    :outlined="false"
                                    :single-line="false"
                                    :no-title="true"
                                    dense
                                    showp-placeholder
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <country-autocomplete
                                    v-model="country"
                                    :label="$t('country')"
                                    dense
                                    @change="city = {}"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <city-hotel-autocomplete
                                    v-model="city"
                                    :label="$t('city')"
                                    dense
                                    @change="country = {}"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="primary" @click="load">{{ $t('filter') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-tabs-items v-model="selectedTabIndex">
                        <v-tab-item v-if="tabs.some(tab => tab.type === 'EXCURSION')">
                            <sales-monitoring-report-table
                                :statistics-data="statistics.excursionStatistics"
                                :is-loading="isLoading"
                                type="EXCURSION"
                                @show-details="nextStep"
                            />
                        </v-tab-item>
                        <v-tab-item v-if="tabs.some(tab => tab.type === 'TRANSFER')">
                            <sales-monitoring-report-table
                                :statistics-data="statistics.transferStatistics"
                                :is-loading="isLoading"
                                type="TRANSFER"
                                @show-details="nextStep"
                            />
                        </v-tab-item>
                        <v-tab-item v-if="tabs.some(tab => tab.type === 'FLIGHT')">
                            <sales-monitoring-report-table
                                :statistics-data="statistics.flightStatistics"
                                :is-loading="isLoading"
                                type="FLIGHT"
                                @show-details="nextStep"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-stepper-content>
                <v-stepper-content :step="2">
                    <v-btn color="primary" text class="mb-4" @click="nextStep">back</v-btn>
                    <detailed-sales-monitoring-report
                        v-if="selectedProduct"
                        :selected-product="selectedProduct"
                        :selected-date="selectedDate"
                    />
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import AuthInfoMixin from '~src/mixins/authInfoMixin.src'
    import OrderServiceDaterange from '~/components/account/filters/OrderServiceDaterange'
    import CityHotelAutocomplete from '~src/components/search/forms/cityHotelAutocomplete.src'
    import SalesMonitoringReportTable from '@/components/account/SalesMonitoringReportTable'
    import DetailedSalesMonitoringReport from '@/components/account/DetailedSalesMonitoringReport'
    import CountryAutocomplete from '~/components/search/forms/CountryAutocomplete'

    @Component({
        components: {
            Breadcrumbs,
            OrderServiceDaterange,
            CityHotelAutocomplete,
            SalesMonitoringReportTable,
            DetailedSalesMonitoringReport,
            CountryAutocomplete,
        },
        middleware: 'auth',
    })
    export default class BusinessIntelligencePage extends mixins(AuthInfoMixin) {
        expanded = false
        isLoading = false
        selectedTabIndex = 0
        stepper = 1
        startDate = null
        endDate = null
        city = {}
        country = {}
        statistics = {}
        selectedProduct = null
        selectedDate = null

        async mounted() {
            this.startDate = this.$dateFns.format(new Date())
            this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 14))

            await this.load()
        }

        async load() {
            try {
                this.isLoading = true
                const request = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    productType: this.selectedTab.type,
                    ...(this.city?.id && {cityId: this.city.id}),
                    ...(this.country?.id && {countryId: this.country.id}),
                }

                const {productStatistics} = await this.$api.salesMonitoringReport.get(request)
                if (this.selectedTab.type === 'EXCURSION') this.statistics.excursionStatistics = productStatistics
                else if (this.selectedTab.type === 'TRANSFER') this.statistics.transferStatistics = productStatistics
                else if (this.selectedTab.type === 'FLIGHT') this.statistics.flightStatistics = productStatistics

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        nextStep({product, dayIndex}) {
            this.selectedProduct = product ? product : null
            this.selectedDate =
                product?.itemsByDate?.length && product.itemsByDate[dayIndex]
                    ? this.$dateFns.format(product.itemsByDate[dayIndex].date)
                    : null

            this.stepper = this.stepper === 1 ? 2 : 1
        }

        get tabs() {
            const tabs = []

            if (this.activeProducts.includes('activities')) {
                tabs.push({
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                    type: 'EXCURSION',
                })
            }
            if (this.activeProducts.includes('transfers')) {
                tabs.push({
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                    type: 'TRANSFER',
                })
            }
            if (this.activeProducts.includes('flights')) {
                tabs.push({
                    ico: 'mdi-airplane',
                    title: 'flights.flights',
                    type: 'FLIGHT',
                })
            }

            return tabs
        }

        get selectedTab() {
            return this.tabs[this.selectedTabIndex]
        }
    }
</script>
