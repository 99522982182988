<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class FakeTemplatesMixin extends Vue {
        fakeTemplates = []

        async loadFakeTemplates() {
            this.fakeTemplates = []
        }
    }
</script>
