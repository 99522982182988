<template>
    <v-container class="pa-0 my-2">
        <ul>
            <li v-for="(value, index) in values" :key="index" :class="index < values.length - 1 ? 'mr-4' : ''">
                <v-icon :color="value.color">
                    {{ value.icon }}
                </v-icon>
                <small>
                    {{ $t(`supplier.${value.status}`) }}
                </small>
            </li>
        </ul>
    </v-container>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component({})
    export default class CalendarLegend extends Vue {
        values = [
            {status: 'bookable', icon: 'mdi-checkbox-blank-circle', color: 'green'},
            {status: 'has_restrictions', icon: 'mdi-bell-circle', color: 'green'},
            {status: 'closed', icon: 'mdi-checkbox-blank-circle', color: 'red'},
            {status: 'sold_out', icon: 'mdi-checkbox-blank-circle', color: 'yellow'},
            {status: 'no_room', icon: 'mdi-checkbox-blank-circle', color: 'grey'},
        ]
    }
</script>

<style scoped lang="scss">
    ul {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: flex-end;
    }
</style>
