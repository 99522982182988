<template>
    <form ref="form" :action="data.url" method="POST">
        <input
            v-for="postParam in data.postParams"
            :key="postParam.name"
            type="hidden"
            :name="postParam.name"
            :value="postParam.value"
        />
        <input type="hidden" name="paymentNumber" :value="data.paymentNumber" />
        <input type="hidden" name="paymentTransactionId" :value="data.paymentTransactionId" />
    </form>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component({
        layout: 'pure',
    })
    export default class PaymentRedirectPage extends Vue {
        //TODO Need refactoring with PaymentRedirectForm

        data = {
            postParams: [],
        }

        asyncData({req}) {
            if (process.server) {
                const qs = require('querystring')
                let body = '',
                    tmp = ''
                while ((tmp = req.read())) {
                    body += tmp
                }
                const post = qs.parse(body)

                post.url = post.originUrl
                delete post.originUrl

                const data = {
                    postParams: [],
                }
                Object.keys(post).forEach(key => {
                    const val = post[key]
                    if (!['paymentNumber', 'paymentTransactionId', 'url'].includes(key)) {
                        if (Array.isArray(val)) {
                            data.postParams.push({
                                name: `${key}[0]`,
                                value: val[0],
                            })
                        } else {
                            data.postParams.push({
                                name: key,
                                value: val,
                            })
                        }
                    } else {
                        data[key] = key === 'paymentTransactionId' ? parseInt(val, 10) : val
                    }
                })
                return {data}
            }
        }

        mounted() {
            this.$refs.form.submit()
        }
    }
</script>
