<template>
    <communications-filters-wrapper :has-active-filters="hasActiveFilters">
        <v-form class="mb-5 table-filters">
            <v-row dense>
                <v-col cols="12" md="6">
                    <daterange
                        :start-date-label="$t('created_date_from')"
                        :end-date-label="$t('created_date_to')"
                        :_start-date.sync="filters.createdDateFrom"
                        :_end-date.sync="filters.createdDateTo"
                        dense
                        :outlined="false"
                        :single-line="false"
                        :hide-details="false"
                        :min-start-date="null"
                        same-date
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <daterange
                        :start-date-label="$t('modified_date_from')"
                        :end-date-label="$t('modified_date_to')"
                        :_start-date.sync="filters.modifiedDateFrom"
                        :_end-date.sync="filters.modifiedDateTo"
                        dense
                        :outlined="false"
                        :single-line="false"
                        :hide-details="false"
                        :min-start-date="null"
                        same-date
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="filters.subject" :label="$t('title')" dense />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="filters.description" :label="$tc('internal_note', 1)" dense />
                </v-col>
                <v-col v-if="!preset" cols="12" md="3">
                    <v-text-field v-model="filters.orderId" :label="$t('order_number')" dense />
                </v-col>
            </v-row>
            <v-btn small color="primary" @click="submit">
                {{ $t('filter') }}
            </v-btn>
            <v-btn small color="white" @click="reset">
                {{ $t('reset') }}
            </v-btn>
        </v-form>
    </communications-filters-wrapper>
</template>

<script>
    import {Vue, Component, Emit, Prop, VModel} from 'nuxt-property-decorator'
    import Daterange from '@/components/search/forms/Daterange.vue'
    import CommunicationsFiltersWrapper from '@/components/account/communications/CommunicationsFiltersWrapper.vue'

    @Component({
        components: {CommunicationsFiltersWrapper, Daterange},
    })
    export default class CommunicationsFilters extends Vue {
        @VModel() filters
        @Prop({required: true, type: Boolean}) hasActiveFilters
        @Prop({default: false, type: Boolean}) preset

        @Emit()
        submit() {}

        @Emit()
        reset() {}
    }
</script>
