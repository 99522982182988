<template>
    <div v-if="supplierName" class="supplier-name grey--text">{{ supplierName }}</div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class SupplierNameLabel extends Vue {
        @Prop({required: false, type: String}) supplierName
    }
</script>

<style scoped>
    .supplier-name {
        font-size: 10px;
    }
</style>
