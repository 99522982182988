<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="searchResponse.offers && searchResponse.offers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <tour-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.offers.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <tour-offers :page="page" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <tour-hotel-info-modal :search-request="searchRequest" />
            <tour-flight-info-modal />
            <tour-transfer-info-modal :search-request="searchRequest" />
            <tour-activities-info-modal :search-request="searchRequest" />
            <tour-extra-service-info-modal :search-request="searchRequest" />
            <tour-insurance-info-modal :search-request="searchRequest" />
            <tour-cruise-info-modal :search-request="searchRequest" />
            <tour-car-info-modal :search-request="searchRequest" />
            <hotel-room-info-modal />
            <hotel-meal-type-info-modal />
            <additional-fees-modal />
            <client-only>
                <v-dialog v-if="mapModal" v-model="mapModal" :max-width="500">
                    <map-location
                        :latitude="mapOffer.coordinates[0]"
                        :longitude="mapOffer.coordinates[1]"
                        height="500px"
                    />
                </v-dialog>
            </client-only>
        </template>
        <template v-slot:left-sidebar>
            <tour-search-summary :search-request="searchRequest" show-dates />
            <sort-select v-model="sortKey" :items="sortItems" class="mb-4" />
            <group-offers-select
                :items="['none', 'tourName']"
                :product-store="productStore"
                token-base="tours.groupBy"
            />
            <tours-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <tour-map :city="arrivalCity" :_expanded.sync="mapExpanded" :offers="hotels" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import ToursStoreMixin from '@/mixins/ToursStoreMixin'
    import TourPageMixin from '@/components/tours/mixins/TourPageMixin'
    import TourBreadcrumbs from '@/components/tours/TourBreadcrumbs'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import {toursRuntimeStore, toursStore} from '@/utils/store-accessor'
    import {isEqual} from '@/utils/helpers'
    import {searchRequest} from '@/utils/tours/tours-blank-states'
    import {persistentStore} from '~/store'
    import TourSearchSummary from '@/components/tours/search/TourSearchSummary'
    import SortSelect from '@/components/search/SortSelect'
    import GroupOffersSelect from '~src/components/search/GroupOffersSelect'
    import ToursFilters from '@/components/tours/search/ToursFilters'
    import TourMap from '@/components/tours/search/TourMap.vue'
    import TourHotelInfoModal from '@/components/tours/modals/TourHotelInfoModal'
    import TourFlightInfoModal from '@/components/tours/modals/TourFlightInfoModal'
    import TourTransferInfoModal from '@/components/tours/modals/TourTransferInfoModal'
    import TourActivitiesInfoModal from '@/components/tours/modals/TourActivitiesInfoModal'
    import TourExtraServiceInfoModal from '@/components/tours/modals/TourExtraServiceInfoModal'
    import TourInsuranceInfoModal from '@/components/tours/modals/TourInsuranceInfoModal'
    import TourCruiseInfoModal from '@/components/tours/modals/TourCruiseInfoModal'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import {CHANGE_LOCALE_EVENT, EventBus, FILTER_EVENT, SHOW_ON_MAP_EVENT} from '~/utils/event-bus'
    import Pagination from '~/components/search/Pagination'
    import TourOffers from '@/components/tours/search/offers/TourOffers.vue'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import TourCarInfoModal from '@/components/tours/modals/TourCarInfoModal'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal.vue'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal.vue'
    import AdditionalFeesModal from '@/components/modals/AdditionalFeesModal.vue'
    import MapLocation from '~src/components/info/mapLocation.src'

    @Component({
        components: {
            AdditionalFeesModal,
            HotelRoomInfoModal,
            HotelMealTypeInfoModal,
            TourHotelInfoModal,
            TourFlightInfoModal,
            TourTransferInfoModal,
            TourActivitiesInfoModal,
            TourExtraServiceInfoModal,
            TourInsuranceInfoModal,
            TourCruiseInfoModal,
            TourBreadcrumbs,
            ExpiredOffersSnackbar,
            SearchProgress,
            TourSearchSummary,
            SortSelect,
            GroupOffersSelect,
            ToursFilters,
            TourMap,
            SearchLayout,
            Pagination,
            TourOffers,
            TourCarInfoModal,
            MapLocation,
        },
        layout: 'blank',
    })
    export default class ToursSearchPage extends mixins(ToursStoreMixin, TourPageMixin, SearchPageAuthMixin) {
        mapExpanded = false
        loaded = false
        resetPageLock = false

        mapModal = false
        mapOffer = null

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async created() {
            EventBus.$on(FILTER_EVENT, this.resetPage)
            EventBus.$on(CHANGE_LOCALE_EVENT, this.search)
            EventBus.$on(SHOW_ON_MAP_EVENT, this.showMap)
        }

        beforeDestroy() {
            EventBus.$off(FILTER_EVENT, this.resetPage)
            EventBus.$off(CHANGE_LOCALE_EVENT, this.search)
            toursRuntimeStore.SET_OFFERS([])
            EventBus.$off(SHOW_ON_MAP_EVENT, this.showMap)
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true

            if (
                !isEqual(toursStore.singleTourSearchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !toursStore.hasOffers
            ) {
                await this.search()
            } else if (toursRuntimeStore.offers.length === 0) {
                const offers = await this.$localForage.getItem('tours')
                this.resetPageLock = true
                toursRuntimeStore.load(offers)
            }
        }

        async search() {
            await toursRuntimeStore.search(this.searchRequest)
        }

        stopSearch() {
            toursRuntimeStore.stopSearch()
        }

        isOffersExpired() {
            return toursStore.isOffersExpired()
        }

        changeFilter(data) {
            toursStore.SET_FILTER(data)
            toursRuntimeStore.filter()
        }

        resetFilters() {
            toursStore.RESET_FILTERS()
            toursRuntimeStore.filter()
        }

        resetPage() {
            if (this.resetPageLock) {
                this.resetPageLock = false
            } else {
                this.page = 1
            }
        }

        showMap(mapOffer) {
            this.mapOffer = mapOffer
            this.mapModal = true
        }

        get groupOffersBy() {
            return toursStore.groupOffersBy
        }

        get groupedOffersByName() {
            return TourOffers.options.computed.groupedOffers.get.call(this)
        }

        get searchRequest() {
            const rq = Object.assign(searchRequest(), this.$route.query)
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.arrivalCityId = parseInt(rq.arrivalCityId, 10)
            if (rq.departureCityId) rq.departureCityId = parseInt(rq.departureCityId, 10)
            rq.durationFrom = parseInt(rq.durationFrom, 10)
            rq.durationTo = parseInt(rq.durationTo, 10)
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            if (rq.packageTourId) {
                rq.packageTourId = parseInt(rq.packageTourId, 10)
            }

            return rq
        }

        get searchResponse() {
            return toursStore.searchResponse
        }

        get filteredOffers() {
            return toursRuntimeStore.offers
        }

        get hotels() {
            const arr = []
            const obj = {}
            this.filteredOffers.map(tour => {
                let key = ''
                tour.entries.map(entry => {
                    if (entry.type === 'ACCOMMODATION') {
                        entry.offers.forEach(offer => {
                            if (offer.coordinates && offer.coordinates.length) {
                                key = offer.name.replaceAll(' ', '_')
                                if (!obj[key]) {
                                    obj[key] = offer
                                    arr.push(offer)
                                }
                            }
                        })
                    }
                })
            })
            return arr
        }

        get filterValues() {
            return toursStore.filters
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get filterActive() {
            return this.productRuntimeStore.filterActive
        }

        get arrivalCity() {
            return toursRuntimeStore.arrivalCity
        }

        get sortKey() {
            return toursStore.sortFnName
        }

        set sortKey(sortKey) {
            toursStore.SET_SORT_FN_NAME(sortKey)
            toursRuntimeStore.sort(this.filteredOffers)
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.duration_desc'),
                    value: 'durationDesc',
                },
                {
                    text: this.$t('sort.duration_asc'),
                    value: 'durationAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
                {
                    text: this.$t('sort.date_asc'),
                    value: 'dateAsc',
                },
                {
                    text: this.$t('sort.date_desc'),
                    value: 'dateDesc',
                },
            ]
        }

        get currency() {
            return toursStore.searchRequest.convertToCurrency
        }

        get pages() {
            if (this.groupOffersBy === 'tourName') {
                return Math.ceil(this.groupedOffersByName.length / this.$config.search.resultsOnPage)
            }
            return Math.ceil(toursRuntimeStore.offers.length / this.$config.search.resultsOnPage)
        }

        get page() {
            return toursStore.searchPage
        }

        set page(page) {
            toursStore.SET_SEARCH_PAGE(page)
        }
    }
</script>
