<template>
    <v-card class="elevation-0" tile>
        <v-card-title>
            <v-icon left color="primary">mdi-comment-text</v-icon>
            {{ $t('special_remark_rm') }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" :disabled="disabled">
                <v-textarea v-model="specialRemarkRM" :placeholder="$t('type_here')" rows="1" />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class BookingCommentsForm extends Vue {
        @Prop() disabled
        @VModel() specialRemarkRM
    }
</script>
