<template>
    <v-autocomplete
        v-model="selectedClient"
        :items="clients"
        :item-text="itemText || inputText"
        :item-value="itemValue"
        :search-input.sync="searchInput"
        :loading="isLoadingClients"
        hide-no-data
        :label="label"
        :return-object="!itemValue"
        outlined
        hide-details
        :persistent-placeholder="!disablePersistentPlaceholder"
        background-color="white"
        :clearable="true"
        :disabled="disabled"
        :rules="rules || [requiredRule('select_user')]"
        @keydown="onInput"
        @change="change"
    >
        <template v-slot:prepend-item>
            <slot />
        </template>
    </v-autocomplete>
</template>

<script>
    import {Component, Emit, Prop, VModel, Watch, mixins} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class ClientAutocomplete extends mixins(FormValidationRulesMixin) {
        @Prop() companyId
        @Prop() label
        @Prop() isTa
        @Prop() itemValue
        @Prop() itemText
        @Prop() rules
        @Prop({default: false, type: Boolean}) disablePersistentPlaceholder
        @Prop({type: Boolean, default: false}) disabled
        @VModel() selectedClient

        clients = []
        isLoadingClients = false
        searchInput = null
        timer = null

        @Emit()
        change(val) {
            return val
        }

        @Watch('companyId')
        async onCompanyIdChange() {
            await this.onInput('')
        }

        async loadClients() {
            try {
                this.isLoadingClients = true

                const data = await this.$api[this.isTa ? 'persons' : 'privateClients'].get({
                    pattern: this.searchInput,
                    active: true,
                    companyId: this.companyId,
                })

                const personArray = data[this.isTa ? 'users' : 'persons']

                personArray.sort((a, b) =>
                    `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(`${b.firstName || ''} ${b.lastName || ''}`)
                )

                this.clients = personArray

                if (this.itemValue) {
                    this.clients = this.clients.filter(el => el[this.itemValue])
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoadingClients = false
            }
        }

        onInput() {
            this.clearResults()
            this.isLoadingClients = true

            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.loadClients()
            }, 800)
        }

        clearResults() {
            this.selectedClient = null
            this.clients = []
        }

        inputText(val) {
            const email = val?.email

            return val
                ? `${val.prefix || ''} ${val.firstName || ''} ${val.lastName || ''}` + (email ? ` (${email})` : '')
                : null
        }
    }
</script>
