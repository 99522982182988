<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <cruise-offers :offers="entry.offers" content-class="my-auto" is-package>
            <template v-slot:price="{offer}">
                <tour-offer-delta-button :offer="offer" :offer-key="offerKey" :entry-name="entry.entryName" />
            </template>
        </cruise-offers>
        <cruise-info-modal />
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_CRUISE_INFO} from '@/utils/event-bus'
    import CruiseOffers from '@/components/cruises/search/offers/CruiseOffers.vue'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton.vue'
    import CruiseInfoModal from '@/components/cruises/info/CruiseInfoModal.vue'

    @Component({
        components: {CruiseInfoModal, TourOfferDeltaButton, CruiseOffers},
    })
    export default class TourCruiseInfoModal extends Vue {
        @Prop({required: true}) searchRequest

        modal = false
        entry = {}
        offerKey = null

        mounted() {
            EventBus.$on(SHOW_TOUR_CRUISE_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_CRUISE_INFO, this.showModal)
        }

        showModal(entry, offerKey) {
            this.entry = entry
            this.offerKey = offerKey
            this.modal = true
        }
    }
</script>
