<template>
    <product-card-layout-mobile
        :product-link="productLink"
        :name="product.name"
        :thumb="product.mainImage"
        :offers-count="product.offers && product.offers.length"
        :expanded.sync="expanded"
        :expanded-limit.sync="expandedLimit"
        :is-recommended="product.recommended"
        :is-package="isPackage"
    >
        <template v-slot>
            <div
                v-if="product.shortDescription"
                class="short-description mt-3"
                v-html="$options.filters.stripHtml(product.shortDescription)"
            />
            <v-divider class="my-3" />
        </template>
        <template v-slot:price>
            <slot name="price" :offer="minPriceOffer">
                <div class="font-weight-medium text-h6">
                    <span>{{ $t('from') }} {{ minPriceOffer.price | price }}</span>
                </div>
                <discount-label :price="minPriceOffer.price" />
                <commission-amount :commission="minPriceOffer.price.commission" />
            </slot>
        </template>
        <template v-slot:offers>
            <template v-if="groupOffersBy !== 'none'">
                <cruise-grouped-offers :product="product">
                    <template v-slot:price="{offer, offerKey}">
                        <slot name="price" :offer="offer" :offer-key="offerKey" />
                    </template>
                </cruise-grouped-offers>
            </template>
            <template v-else>
                <cruise-offer-card
                    v-for="(offer, offerIndex) in limitedOffers"
                    :key="offerIndex"
                    :offer="offer"
                    :full-card="false"
                >
                    <template v-slot:price="{offer}">
                        <slot name="price" :offer="offer" />
                    </template>
                </cruise-offer-card>
                <v-list-item
                    v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                    dense
                    class="d-flex justify-center"
                    @click.stop="
                        expanded = product.offers.length
                        expandedLimit = product.offers.length
                    "
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-list-item>
            </template>
        </template>
    </product-card-layout-mobile>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import CruiseCard from '@/components/cruises/search/offers/CruiseCard'
    import CruiseOfferCard from '@/components/cruises/info/CruiseOfferCard'
    import ProductCardLayoutMobile from '~/components/search/ProductCardLayoutMobile'

    @Component({
        components: {
            DiscountLabel,
            CommissionAmount,
            CruiseOfferCard,
            ProductCardLayoutMobile,
        },
    })
    export default class CruiseCardMobile extends CruiseCard {}
</script>
