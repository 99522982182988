<template>
    <div class="text-center d-flex flex-column title grey--text text--lighten-1 py-10">
        <span v-text="$t('no_found_label.didnt_find')" />
        <span class="mt-2">
            {{ $t('no_found_label.send') }}
            <v-btn dense class="mx-1" :color="'primary'" @click="newOrderRequestModal = true">
                {{ $t('no_found_label.reservation_request') }}
            </v-btn>
            {{ $t('no_found_label.assist') }}
        </span>
        <new-order-request-modal v-model="newOrderRequestModal" />
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import NewOrderRequestModal from '~src/components/account/modals/newOrderRequest/newOrderRequestModal.src'

    @Component({
        components: {NewOrderRequestModal},
    })
    export default class NoOffersLabel extends Vue {
        newOrderRequestModal = false
    }
</script>
