<template>
    <div class="d-flex flex-wrap">
        <div v-if="info.vehicleCategory" class="text-center">
            <v-chip :small="small" :x-small="xSmall">
                {{ info.vehicleCategory }}
            </v-chip>
        </div>
        <div v-if="info.vehicleClass" class="text-center ml-2">
            <v-chip :small="small" :x-small="xSmall">
                {{ info.vehicleClass }}
            </v-chip>
        </div>
        <div v-if="info.transmissionType" class="text-center ml-2">
            <v-chip :small="small" :x-small="xSmall">
                {{ info.transmissionType }}
            </v-chip>
        </div>
        <div v-if="info.fuelType && info.fuelType !== 'UNSPECIFIED'" class="text-center ml-2">
            <v-chip :small="small" :x-small="xSmall">
                {{ info.fuelType }}
            </v-chip>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class CarFeatureLabels extends Vue {
        @Prop({default: () => ({}), type: Object, required: true}) info
        @Prop({default: () => false, type: Boolean}) small
        @Prop({default: () => false, type: Boolean}) xSmall
    }
</script>
