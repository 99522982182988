<template>
    <div>
        <breadcrumbs class="mb-3" />
        <v-btn depressed nuxt :to="{name: 'member', params: {id: 0}}" class="primary mb-4">
            {{ $t('new_member_profile') }}
        </v-btn>
        <clients-table :is-member="true" />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import CompanyClientsPage from '@/pages/account/CompanyClientsPage'

    @Component({middleware: ['auth', 'members']})
    export default class MembersPage extends CompanyClientsPage {}
</script>
