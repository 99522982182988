<script>
    import {Component} from 'nuxt-property-decorator'
    import FakeTemplatesMixin from '~src/components/account/mixins/fakeTemplatesMixin.src'

    @Component
    export default class DocumentTemplatesMixin extends FakeTemplatesMixin {
        documentTemplates = []
        isLoading = false

        async loadDocumentTemplates(data = {}) {
            const {locale, id} = data

            try {
                this.documentTemplates = (
                    await this.$api.documentTemplates.get({
                        ...((locale || this.locale) && {localeCode: locale || this.locale}),
                        ...(id && {id}),
                    })
                ).templates

                // this.documentTemplates = []
                // await this.loadFakeTemplates()
                // this.documentTemplates = [...this.documentTemplates, ...this.fakeTemplates]

                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get locale() {
            return this.$i18n.locale
        }
    }
</script>
