<template>
    <a
        v-ripple
        target="_blank"
        :href="href"
        class="att-tab-link d-flex align-center justify-center white--text text-decoration-none px-3 text-uppercase h6 font-weight-medium"
    >
        <v-icon class="me-1">
            {{ ico }}
        </v-icon>
        <span class="hidden-sm-and-down">{{ $t(`${name}`) }}</span>
    </a>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {Prop} from 'vue-property-decorator'

    @Component
    export default class SearchFormTabLink extends Vue {
        @Prop({required: true}) name
        @Prop({required: true}) href
        @Prop({required: true}) ico
    }
</script>

<style scoped lang="scss">
    .att-tab-link {
        font-size: 0.875rem;
        opacity: 0.6;
        min-width: 90px;
    }
</style>
