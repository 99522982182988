<template>
    <div>
        <component
            :is="`cruise-card${$breakpoint.smAndDown ? '-mobile' : ''}`"
            v-for="(product, index) in offers"
            :key="product.name"
            :product="product"
            :data-cy="`cruise-offer-${index}`"
            :class="contentClass"
            :is-package="isPackage"
        >
            <template v-slot:price="{offer}">
                <slot name="price" :offer="offer" />
            </template>
        </component>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import CruiseCard from '@/components/cruises/search/offers/CruiseCard'
    import CruiseCardMobile from '@/components/cruises/search/offers/CruiseCardMobile'

    @Component({
        components: {
            CruiseCard,
            CruiseCardMobile,
        },
    })
    export default class CruiseOffers extends Vue {
        @Prop({default: () => []}) offers
        @Prop() contentClass
        @Prop({default: false, type: Boolean}) isPackage
    }
</script>
