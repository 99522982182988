<template>
    <component
        :is="card ? 'v-card' : 'div'"
        v-if="info.checkInFrom || info.checkInTo || info.checkOutFrom || info.checkOutTo"
        outlined
        class="d-flex flex-column black--text text-caption"
        :class="{'pt-3 px-4': card}"
    >
        <v-divider v-if="!card" class="mb-3" />
        <v-row v-if="info.checkInFrom || info.checkInTo" class="d-flex align-start" no-gutters>
            <v-col cols="4" class="d-flex font-weight-bold">
                <v-icon class="me-2" small color="black">mdi-clock-outline</v-icon>
                <div>{{ $t('earlyCheckInTitle') }}:</div>
            </v-col>
            <v-col cols="8" class="d-flex flex-column flex-grow-1 font-weight-medium">
                <div class="ps-2 width-max-content">{{ checkInLabel }}</div>
                <v-range-slider v-model="checkInRange" hide-details dense :min="0" :max="24" readonly />
            </v-col>
        </v-row>
        <v-row v-if="info.checkOutFrom || info.checkOutTo" class="d-flex align-start" no-gutters>
            <v-col cols="4" class="d-flex font-weight-bold">
                <v-icon class="me-2" small color="black">mdi-clock-outline</v-icon>
                <div>{{ $t('lateCheckOutTitle') }}:</div>
            </v-col>
            <v-col cols="8" class="d-flex flex-column font-weight-medium flex-grow-1">
                <div class="ps-2">{{ checkOutLabel }}</div>
                <v-range-slider v-model="checkOutRange" dense hide-details :min="0" :max="24" readonly />
            </v-col>
        </v-row>
        <v-divider v-if="!card" />
    </component>
</template>

<script>
    import {hotelsRuntimeStore} from '~/store'
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class HotelInfoTime extends Vue {
        @Prop({default: false, type: Boolean}) card

        get info() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }

        getRangeByKey(key) {
            const fromNumber = +this.info[`${key}From`]?.slice(0, 2) || 0
            const toNumber = +this.info[`${key}To`]?.slice(0, 2) || 24

            return [fromNumber, toNumber]
        }

        getLabelByKey(key) {
            let label

            if (!this.info[`${key}From`]) {
                label = `${this.$t('before')} ${this.info[`${key}To`]}`
            } else if (!this.info[`${key}To`]) {
                label = `${this.$t('after')} ${this.info[`${key}From`]}`
            } else {
                label = `${this.$t('tours.from')} ${this.info[`${key}From`]} ${this.$t('tours.to')} ${
                    this.info[`${key}To`]
                }`
            }

            return label[0].toUpperCase() + label.slice(1)
        }

        get checkInLabel() {
            return this.getLabelByKey('checkIn')
        }

        get checkOutLabel() {
            return this.getLabelByKey('checkOut')
        }

        get checkInRange() {
            return this.getRangeByKey('checkIn')
        }

        get checkOutRange() {
            return this.getRangeByKey('checkOut')
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-slider__thumb-container {
        visibility: hidden;
    }

    ::v-deep .v-slider--horizontal .v-slider__track-container {
        height: 3px;
    }
</style>
