<template>
    <v-card class="main-form" outlined tile :width="width">
        <v-card-title v-if="$breakpoint.smAndDown && popup" class="d-flex justify-end">
            <v-icon color="white" @click="$emit('close-popup')">mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-else-if="$breakpoint.smAndDown && !popup" class="white--text">
            <span v-if="activeProducts.length > 1">{{ $t(title) }}</span>
        </v-card-title>
        <v-card-text class="white--text">
            <slot name="form" />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, mixins} from 'nuxt-property-decorator'
    import AuthInfoMixin from '~src/mixins/authInfoMixin.src'

    @Component
    export default class SearchFormLayout extends mixins(AuthInfoMixin) {
        @Prop({default: false, type: Boolean}) popup
        @Prop() title
        @Prop() width
    }
</script>
