<template>
    <v-layout :fill-height="$fetchState.pending" column>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
        <mobile-info-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" mobile-tab1-title="search" />
        <v-container v-if="!$fetchState.pending" fluid>
            <tour-breadcrumbs v-if="!$breakpoint.smAndDown" :search-request="searchRequest" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <v-btn
                        v-if="(totalPrice || searchActive) && !$breakpoint.smAndDown"
                        class="float-right"
                        color="primary"
                        small
                        :loading="searchActive"
                        :disabled="searchActive"
                        @click="selectForBooking"
                    >
                        {{ totalPrice | price }}
                    </v-btn>
                    <itinerary-info
                        :info="info"
                        :supplier-name="offer.info && offer.info.supplierName ? offer.info.supplierName : ''"
                    >
                        <template v-slot:offerInfo>
                            <template v-if="offer.info">
                                <h6 class="text-h6">
                                    {{ $t('general_info') }}
                                </h6>
                                <tour-general-info class="mb-5" :info="offer.info" />
                            </template>
                            <v-skeleton-loader v-else-if="searchActive" type="paragraph" />
                            <div v-else-if="!formattedDays.length" class="text-center text-subtitle-1 red lighten-3">
                                <span class="red--text text--darken-3">{{ $t('tours.no_tours_message') }}</span>
                            </div>
                        </template>
                    </itinerary-info>
                    <h6 class="text-h6">
                        {{ $t('details') }}
                    </h6>
                    <v-divider />
                    <v-list v-if="!searchActive">
                        <template v-for="(item, index) in formattedDays">
                            <template v-if="item.dayOffers.length || item.dayOptions.length">
                                <v-list-item :key="'day-' + index">
                                    <v-list-item-icon v-if="!$breakpoint.smAndDown" class="text-capitalize">
                                        <v-icon>mdi-weather-sunny</v-icon>
                                        {{ $tc('day', 1) }} {{ item.day }}
                                    </v-list-item-icon>
                                    <v-list-item-avatar
                                        v-if="!$breakpoint.smAndDown"
                                        tile
                                        size="125"
                                        class="align-self-start mt-3 d-none d-md-inline-flex"
                                    >
                                        <v-img
                                            :src="dayImage(item.day).url | imageUrl(125)"
                                            :lazy-src="'/placeholder.png' | srcHost"
                                        />
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ml-md-5">
                                        <v-img
                                            v-if="info.images && $breakpoint.smAndDown"
                                            :src="dayImage(item.day).url | imageUrl(500)"
                                            :lazy-src="'/placeholder.png' | srcHost"
                                            max-height="125"
                                            class="mb-2"
                                        >
                                            <v-row
                                                align="end"
                                                class="lightbox white--text px-2 fill-height text-capitalize"
                                            >
                                                <v-col>
                                                    <div class="subheading">
                                                        <v-icon color="white">mdi-weather-sunny</v-icon>
                                                        {{ $tc('day', 1) }} {{ item.day }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-img>
                                        <div class="text-body-2" v-html="item.mainDescription" />
                                        <v-list v-if="item.dayOffers.length && offer.info" dense>
                                            <template v-for="dayOffer in item.dayOffers">
                                                <tour-offer-entry
                                                    :key="dayOffer.offerKey"
                                                    class="py-2"
                                                    :entry="dayOffer"
                                                    :offer="offer"
                                                    hide-date
                                                    hide-entry-type
                                                />
                                            </template>
                                        </v-list>
                                        <v-skeleton-loader
                                            v-else-if="!offer.info"
                                            transition="fade-transition"
                                            type="article"
                                        />
                                        <v-list v-if="item.dayOptions.length && offer.info">
                                            <div class="ms-8 text-h6">
                                                {{ $t('tours.optional_services') }}
                                            </div>
                                            <template v-for="dayOption in item.dayOptions">
                                                <tour-offer-entry
                                                    :key="dayOption.offerKey"
                                                    class="py-2"
                                                    :entry="dayOption"
                                                    :offer="offer"
                                                    hide-date
                                                    hide-entry-type
                                                />
                                            </template>
                                        </v-list>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </v-list>
                    <v-skeleton-loader v-else transition="fade-transition" type="article" />
                    <v-btn
                        v-if="$breakpoint.smAndDown && (totalPrice || searchActive)"
                        block
                        color="primary"
                        class="sticky"
                        :loading="searchActive"
                        :disabled="searchActive"
                        @click="selectForBooking"
                    >
                        {{ totalPrice | price }}
                    </v-btn>
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <tour-search-summary :search-request="searchRequest" :info="info" />
                    <tour-datepicker :search-request="searchRequest" class="mb-5" />
                    <tour-map ref="map" />
                </v-col>
            </v-row>
        </v-container>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
        <conditions-modal />
        <tour-hotel-info-modal :search-request="searchRequest" />
        <tour-flight-info-modal />
        <tour-transfer-info-modal :search-request="searchRequest" />
        <tour-activities-info-modal :search-request="searchRequest" />
        <tour-extra-service-info-modal :search-request="searchRequest" />
        <tour-insurance-info-modal :search-request="searchRequest" />
        <tour-cruise-info-modal :search-request="searchRequest" />
        <tour-car-info-modal :search-request="searchRequest" />
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
        <additional-fees-modal />
        <client-only>
            <v-dialog v-if="mapModal" v-model="mapModal" :max-width="500">
                <map-location :latitude="mapOffer.coordinates[0]" :longitude="mapOffer.coordinates[1]" height="500px" />
            </v-dialog>
        </client-only>
    </v-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import TourStoreMixin from '@/mixins/TourStoreMixin'
    import TourPageMixin from '@/components/tours/mixins/TourPageMixin'
    import TourBreadcrumbs from '@/components/tours/TourBreadcrumbs'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import MobileInfoPageTabs from '@/components/snippets/MobileInfoPageTabs'
    import {persistentStore, toursRuntimeStore, toursStore} from '@/utils/store-accessor'
    import {searchRequest} from '@/utils/tours/tours-blank-states'
    import TourSearchSummary from '@/components/tours/search/TourSearchSummary'
    import {isEqual} from '@/utils/helpers'
    import TourGeneralInfo from '@/components/tours/snippets/TourGeneralInfo'
    import TourMap from '@/components/tours/info/TourMap'
    import TourDatepicker from '@/components/tours/search/forms/TourDatepicker'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import NuxtPageMixin from '@/mixins/NuxtPageMixin'
    import ItineraryInfo from '@/components/info/ItineraryInfo'
    import TourOfferEntry from '~src/components/tours/search/offers/tourOfferEntry.src'
    import TourHotelInfoModal from '@/components/tours/modals/TourHotelInfoModal'
    import TourFlightInfoModal from '@/components/tours/modals/TourFlightInfoModal'
    import TourTransferInfoModal from '@/components/tours/modals/TourTransferInfoModal'
    import TourActivitiesInfoModal from '@/components/tours/modals/TourActivitiesInfoModal'
    import TourExtraServiceInfoModal from '@/components/tours/modals/TourExtraServiceInfoModal'
    import TourInsuranceInfoModal from '@/components/tours/modals/TourInsuranceInfoModal'
    import {AUTH_EVENT, CHANGE_LOCALE_EVENT, EventBus, SHOW_ON_MAP_EVENT} from '@/utils/event-bus'
    import TourCruiseInfoModal from '@/components/tours/modals/TourCruiseInfoModal.vue'
    import TourCarInfoModal from '@/components/tours/modals/TourCarInfoModal'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal.vue'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal.vue'
    import AdditionalFeesModal from '@/components/modals/AdditionalFeesModal.vue'
    import MapLocation from '~src/components/info/mapLocation.src'

    @Component({
        components: {
            AdditionalFeesModal,
            HotelRoomInfoModal,
            HotelMealTypeInfoModal,
            TourCruiseInfoModal,
            ItineraryInfo,
            ExpiredOffersSnackbar,
            TourDatepicker,
            TourMap,
            TourGeneralInfo,
            TourSearchSummary,
            MobileInfoPageTabs,
            ConditionsModal,
            TourBreadcrumbs,
            TourOfferEntry,
            TourHotelInfoModal,
            TourFlightInfoModal,
            TourTransferInfoModal,
            TourActivitiesInfoModal,
            TourExtraServiceInfoModal,
            TourInsuranceInfoModal,
            TourCarInfoModal,
            MapLocation,
        },
        layout: 'blank',
    })
    export default class TourPage extends mixins(TourStoreMixin, TourPageMixin, NuxtPageMixin) {
        mobileTabs = 0
        options = {}

        mapModal = false
        mapOffer = null

        @Watch('$route.query')
        onQueryChange() {
            this.loadOffers()
            if (this.$breakpoint.smAndDown) {
                this.mobileTabs = 0
            }
        }

        validate({query}) {
            return query.packageTourId
        }

        created() {
            EventBus.$on(AUTH_EVENT, this.refreshExpiredOffers)
            EventBus.$on(CHANGE_LOCALE_EVENT, this.refreshExpiredOffers)
            EventBus.$on(SHOW_ON_MAP_EVENT, this.showMap)
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.fetchStatePending
            this.$refs.map.initMarkers()
            await this.loadOffers()
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.refreshExpiredOffers)
            EventBus.$off(CHANGE_LOCALE_EVENT, this.refreshExpiredOffers)
            EventBus.$off(SHOW_ON_MAP_EVENT, this.showMap)
        }

        async loadOffers() {
            await this.$store.restored
            const singleTourSearchRequest = {
                ...toursStore.singleTourSearchRequest,
                packageTourId: this.packageTourId,
                startDateTo: toursStore.singleTourSearchRequest?.startDateFrom,
            }

            if (!isEqual(singleTourSearchRequest, this.searchRequest) || this.isOffersExpired()) {
                await toursRuntimeStore.singleTourSearch(this.searchRequest)
            } else {
                const offers = await this.$localForage.getItem('tours')
                toursRuntimeStore.load(offers)
            }
        }

        async refreshExpiredOffers() {
            await toursRuntimeStore.singleTourSearch(this.searchRequest)
        }

        dayImage(dayNumber) {
            return this.info.images?.find(image => image.dayNumber === dayNumber) || {}
        }

        isOffersExpired() {
            return toursStore.isSingleHotelOffersExpired()
        }

        selectForBooking() {
            const offerKey = this.offer.offerKey
            toursStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'tourBooking',
                query: {offerKey},
            })
        }

        showMap(mapOffer) {
            this.mapOffer = mapOffer
            this.mapModal = true
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.arrivalCityId = parseInt(rq.arrivalCityId, 10)
            if (rq.departureCityId) rq.departureCityId = parseInt(rq.departureCityId, 10)
            rq.durationFrom = parseInt(rq.durationFrom, 10)
            rq.durationTo = parseInt(rq.durationTo, 10)
            if (this.$route.query.startDateFrom) {
                rq.startDateFrom = this.$route.query.startDateFrom
                rq.startDateTo = this.$route.query.startDateFrom
            } else if (this.info.availabilities.length) {
                rq.startDateFrom = this.info.availabilities[0].startDateFrom
                rq.startDateTo = this.info.availabilities[0].startDateFrom
            }
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            rq.packageTourId = parseInt(rq.packageTourId, 10)

            return rq
        }

        get packageTourId() {
            return parseInt(this.$route.query.packageTourId, 10)
        }

        get info() {
            return toursRuntimeStore.packageTourInfo
        }

        get offer() {
            return (
                toursRuntimeStore.offers.find(offer => offer.info.id === this.packageTourId) ||
                toursStore.singleTourSearchResponse.offers[0] ||
                {}
            )
        }

        get totalPrice() {
            return toursRuntimeStore.totalPrice(this.offer)
        }

        get searchActive() {
            return toursRuntimeStore.singleTourSearchActive
        }

        get formattedDays() {
            const days = {}
            let counter = 0
            let lastFlight = {}

            const productOffersAdapter = product =>
                product.offers?.length
                    ? product.offers
                    : product.packageOffers?.length
                    ? product.packageOffers
                    : [product]

            if (this.info.itineraryDays) {
                this.info.itineraryDays.forEach(item => {
                    days[item.number] = {
                        day: item.number,
                        mainDescription: item.description,
                        dayOffers: [],
                        dayOptions: [],
                    }
                })
                this.info.entries.forEach(entry => {
                    if (entry.dayNumber !== counter) counter = entry.dayNumber
                    let offerName, serviceName, selectedHotel, selectedOffer
                    const offerEntry = Object.assign(
                        {},
                        this.offer.entries?.find(el => el.entryName === entry.entryName)
                    )

                    if (offerEntry) {
                        switch (offerEntry.type) {
                            case 'ACCOMMODATION':
                                offerEntry.offers.forEach(hotel => {
                                    let room
                                    if (offerEntry.selectedOfferKey) {
                                        room = hotel.rooms.find(
                                            room =>
                                                room.groupedOffers.findIndex(
                                                    groupedOffer =>
                                                        groupedOffer.offerKey === offerEntry.selectedOfferKey
                                                ) !== -1
                                        )
                                    } else {
                                        room = hotel.rooms[0]
                                    }

                                    if (room) {
                                        serviceName = room.name
                                        selectedHotel = hotel

                                        return
                                    }
                                })
                                offerName = selectedHotel.name
                                break
                            case 'EXCURSION':
                            case 'EVENT':
                                offerName = offerEntry.name
                                for (const product of offerEntry.offers) {
                                    selectedOffer = productOffersAdapter(product).find(
                                        ({offerKey}) => offerKey === offerEntry.selectedOfferKey
                                    )
                                    if (selectedOffer) break
                                }
                                serviceName = selectedOffer ? selectedOffer.serviceName : ''
                                break
                            case 'EXTRA_SERVICE':
                                offerName = offerEntry.name
                                serviceName = offerEntry.selectedOfferKey
                                    ? offerEntry.offers.find(offer => offer.offerKey === offerEntry.selectedOfferKey)
                                          .info.service
                                    : offerEntry.offers[0].info.service
                                break
                            case 'FLIGHT':
                                if (offerEntry.offers[0].itinerary.length > 1 && offerEntry.obligatory) {
                                    offerName =
                                        offerEntry.offers[0].itinerary[0].segments[0].departure.airportDescription
                                            .airportName +
                                        ' - ' +
                                        offerEntry.offers[0].itinerary[0].segments[0].arrival.airportDescription
                                            .airportName

                                    lastFlight = Object.assign({}, offerEntry)
                                    lastFlight.offerName =
                                        lastFlight.offers[0].itinerary[1].segments[0].departure.airportDescription
                                            .airportName +
                                        ' - ' +
                                        lastFlight.offers[0].itinerary[1].segments[0].arrival.airportDescription
                                            .airportName
                                } else {
                                    offerName = offerEntry.name
                                }
                                break
                            case 'TRANSFER':
                                offerName = offerEntry.name
                                serviceName = offerEntry.selectedOfferKey
                                    ? offerEntry.offers.find(offer => offer.offerKey === offerEntry.selectedOfferKey)
                                          .info.description
                                    : offerEntry.offers[0].info.description
                                break
                            case 'INSURANCE':
                                offerName = offerEntry.name
                                serviceName = offerEntry.selectedOfferKey
                                    ? offerEntry.offers.find(offer => offer.offerKey === offerEntry.selectedOfferKey)
                                          .planName
                                    : offerEntry.offers[0].planName
                                break
                            case 'CRUISE':
                                offerName = offerEntry.name
                                for (const product of offerEntry.offers) {
                                    selectedOffer = productOffersAdapter(product).find(
                                        ({offerKey}) => offerKey === offerEntry.selectedOfferKey
                                    )
                                    if (selectedOffer) break
                                }
                                serviceName = selectedOffer
                                    ? `${selectedOffer.serviceName} / ${selectedOffer.className}`
                                    : ''
                                break
                        }
                        offerEntry.offerName = offerName
                        offerEntry.serviceName = serviceName

                        if (offerEntry.obligatory) days[counter].dayOffers.push(offerEntry)
                        else days[counter].dayOptions.push(offerEntry)
                    }
                })
            }
            if (Object.keys(lastFlight).length) days[counter]?.dayOffers.push(lastFlight)

            return Object.values(days)
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .lightbox .col {
        background: rgba(0, 0, 0, 0.4);
    }
    .sticky {
        position: sticky;
        bottom: 8px;
        left: 0;
    }
</style>
