<template>
    <v-dialog
        v-model="modal"
        :hide-overlay="!pageLock"
        width="100%"
        :max-width="step !== 1 && type === 'travelers' && ownProduct ? '80%' : 600"
        :persistent="pageLock"
        content-class="overflow--visible"
    >
        <v-card outlined>
            <div class="mb-4">
                <v-icon class="v-card-close" :disabled="pageLock" @click="modal = false">mdi-close</v-icon>
                <v-card-title>
                    {{ title }}
                </v-card-title>
                <v-card-subtitle class="mt-2">
                    <div
                        v-if="(!error && ![3, 4].includes(step)) || (step === 3 && prepareModifyResult && !error)"
                        v-html="subtitle"
                    />
                    <div v-else-if="error">
                        <p class="red--text mb-10">
                            {{ error }}
                        </p>
                    </div>
                </v-card-subtitle>
                <v-card-text v-if="!error">
                    <template v-if="step === 1">
                        <v-radio-group v-model="type" column>
                            <v-radio :label="$t('modify.type.dates')" value="dates" />
                            <v-radio :label="$t('modify.type.travelers')" value="travelers" />
                            <v-radio
                                value="extraServices"
                                :disabled="
                                    additionalOptionsLoading ||
                                    !additionalOptions.extraServices ||
                                    additionalOptions.extraServices.length === 0
                                "
                            >
                                <template v-slot:label>
                                    {{ $t('modify.type.extraServices') }}
                                    <v-icon v-if="additionalOptionsLoading" small right>mdi-loading mdi-spin</v-icon>
                                    <span
                                        v-else-if="
                                            !additionalOptions.extraServices ||
                                            additionalOptions.extraServices.length === 0
                                        "
                                    >
                                        &nbsp;({{ $t('not_available') }})
                                    </span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </template>
                    <template v-if="step === 2">
                        <div v-if="type === 'dates'">
                            <modify-daterange
                                :start-date.sync="startDate"
                                :end-date.sync="endDate"
                                :start-date-label="null"
                                :end-date-label="null"
                                clearable
                                :outlined="false"
                                :single-line="true"
                                :no-title="true"
                            />
                            <p class="mt-6" style="line-height: 1">
                                <small> * {{ $t('modify.dates_warning_message') }} </small>
                            </p>
                        </div>
                        <div v-else-if="type === 'travelers'">
                            <modify-travelers
                                :_tourists.sync="tourists"
                                :_valid.sync="valid"
                                :own-product="ownProduct"
                            />
                            <p class="mt-6" style="line-height: 1">
                                <small> * {{ $t('modify.dates_warning_message') }} </small>
                            </p>
                        </div>
                        <div v-else-if="type === 'extraServices'">
                            <extra-services-form v-model="extraServices" :additional-options="additionalOptions" />
                            <!--template
                                v-if="
                                    additionalOptions.extraBaggageOptions &&
                                    additionalOptions.extraBaggageOptions.length &&
                                    flight
                                "
                            >
                                <div v-for="(tourist, index) of tourists" :key="index" class="mt-4">
                                    <div class="text-subtitle-2 text-capitalize">
                                        <template v-if="tourist.firstName || tourist.lastName">
                                            {{ tourist.prefix || '' }}
                                            {{ tourist.firstName || '' }}
                                            {{ tourist.lastName || '' }}
                                        </template>
                                        <template v-else>{{ $tc('passenger', 1) }} {{ index + 1 }}</template>
                                    </div>
                                    <extra-baggage-form
                                        v-model="prepareBookRequest"
                                        :index="index"
                                        :offer="flight"
                                        :extra-baggage-options="additionalOptions.extraBaggageOptions"
                                    >
                                        <template v-slot:segmentTitle="{segment}">
                                            {{ segment.departure.airportCode }} - {{ segment.arrival.airportCode }}
                                        </template>
                                    </extra-baggage-form>
                                </div>
                            </template-->
                        </div>
                    </template>
                    <template v-if="step === 3">
                        <div v-if="prepareModifyResult">
                            <div v-if="type === 'dates'">
                                <h4>
                                    {{ $t('modify.current_dates') }}
                                </h4>
                                <v-row class="text-center">
                                    <v-col cols="12" md="3">
                                        <small class="d-block">{{ $t('check_in') }}</small>
                                        <span class="black--text">
                                            {{ service.startDateTime | dateFormat }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <small class="d-block">{{ $t('check_out') }}</small>
                                        <span class="black--text">
                                            {{ service.endDateTime | dateFormat }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <small class="d-block text-capitalize">{{ $tc('night', 2) }}</small>
                                        <span class="black--text">
                                            {{ nights(service.startDateTime, service.endDateTime) }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <small class="d-block">{{ $t('price') }}</small>
                                        <span class="black--text">
                                            {{
                                                clientPrice(service).amount | priceFormat(clientPrice(service).currency)
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-2 mb-6" />
                                <h4 class="primary--text">
                                    {{ $t('modify.new_dates') }}
                                </h4>
                                <v-row class="text-center">
                                    <v-col cols="12" md="3">
                                        <small class="d-block">{{ $t('check_in') }}</small>
                                        <span class="black--text">
                                            {{ prepareModifyResult.startDate | dateFormat }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <small class="d-block">{{ $t('check_out') }}</small>
                                        <span class="black--text">
                                            {{ prepareModifyResult.endDate | dateFormat }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <small class="d-block text-capitalize">{{ $tc('night', 2) }}</small>
                                        <span class="black--text">
                                            {{ nights(prepareModifyResult.startDate, prepareModifyResult.endDate) }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <small class="d-block">{{ $t('price') }}</small>
                                        <span class="primary--text">
                                            {{
                                                clientPrice(prepareModifyResult).amount
                                                    | priceFormat(clientPrice(prepareModifyResult).currency)
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-2" />
                            </div>
                            <div v-else-if="type === 'travelers'">
                                <h4>
                                    {{ $t('modify.current_traveler_details') }}
                                </h4>
                                <v-row
                                    v-for="traveler in service.travelers"
                                    :key="traveler.personId"
                                    class="text-center"
                                >
                                    <v-col :md="ownProduct ? 6 : 12">
                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('salutation') }}</small>
                                                <span class="black--text">
                                                    {{ traveler.prefix }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('first_name') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ traveler.name[0].firstName }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('last_name') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ traveler.name[0].lastName }}
                                                </span>
                                            </v-col>
                                            <v-col v-if="ownProduct" cols="12" md="3">
                                                <small class="d-block">{{ $t('citizenship') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{
                                                        traveler.citizenshipId
                                                            ? countryName(traveler.citizenshipId)
                                                            : '-'
                                                    }}
                                                </span>
                                            </v-col>
                                            <v-col v-else cols="12" md="3">
                                                <small class="d-block">{{ $t('price') }}</small>
                                                <span class="black--text">
                                                    {{
                                                        clientPrice(service).amount
                                                            | priceFormat(clientPrice(service).currency)
                                                    }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="ownProduct" md="6">
                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('birthdate') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ traveler.dateOfBirth || '-' }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('document_number') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ traveler.passports[0] ? traveler.passports[0].number : '-' }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('expire_date') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ traveler.passports[0] ? traveler.passports[0].expiryDate : '-' }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('price') }}</small>
                                                <span class="black--text">
                                                    {{
                                                        clientPrice(service).amount
                                                            | priceFormat(clientPrice(service).currency)
                                                    }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-2 mb-6" />
                                <h4 class="primary--text">
                                    {{ $t('modify.new_traveler_details') }}
                                </h4>
                                <v-row v-for="tourist in tourists" :key="tourist.personId" class="text-center">
                                    <v-col :md="ownProduct ? 6 : 12">
                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('salutation') }}</small>
                                                <span class="black--text">
                                                    {{ tourist.prefix }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('first_name') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ tourist.firstName }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('last_name') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ tourist.lastName }}
                                                </span>
                                            </v-col>
                                            <v-col v-if="ownProduct" cols="12" md="3">
                                                <small class="d-block">{{ $t('citizenship') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{
                                                        tourist.citizenshipId ? countryName(tourist.citizenshipId) : '-'
                                                    }}
                                                </span>
                                            </v-col>
                                            <v-col v-else cols="12" md="3">
                                                <small class="d-block">{{ $t('price') }}</small>
                                                <span class="primary--text">
                                                    {{
                                                        clientPrice(prepareModifyResult).amount
                                                            | priceFormat(clientPrice(prepareModifyResult).currency)
                                                    }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="ownProduct" md="6">
                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('birthdate') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ tourist.birthdate || '-' }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('document_number') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ tourist.passport.number || '-' }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('expire_date') }}</small>
                                                <span class="black--text d-block" style="min-height: 21px">
                                                    {{ tourist.passport.expiryDate || '-' }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <small class="d-block">{{ $t('price') }}</small>
                                                <span class="primary--text">
                                                    {{
                                                        clientPrice(prepareModifyResult).amount
                                                            | priceFormat(clientPrice(prepareModifyResult).currency)
                                                    }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-2" />
                            </div>
                            <div v-else-if="type === 'extraServices'" class="text-center">
                                <template v-if="extraServiceItems.length">
                                    <div v-for="({name, quantity}, index) in extraServiceItems" :key="index">
                                        {{ name }} * {{ quantity }}
                                    </div>
                                </template>
                                <template v-if="extraBaggageItems.length">
                                    <div v-for="({name, quantity}, index) in extraBaggageItems" :key="index">
                                        {{ name }} * {{ quantity }}
                                    </div>
                                </template>
                                <div v-else>{{ $t('ModifyModal.empty_extra_services_note') }}</div>
                                <small class="d-block">{{ $t('ModifyModal.total_client_price') }}</small>
                                <span class="primary--text">
                                    {{
                                        clientPrice(prepareModifyResult).amount
                                            | priceFormat(clientPrice(prepareModifyResult).currency)
                                    }}
                                </span>
                            </div>
                        </div>
                        <div v-else class="d-flex justify-center align-center pa-10">
                            <v-progress-circular :size="50" color="primary" indeterminate />
                        </div>
                    </template>
                    <template v-if="step === 4">
                        <payment-options v-model="paymentMethodId" class="elevation-0" disabled />
                        <payment-redirect-form ref="paymentRedirectForm" />
                    </template>
                </v-card-text>
                <v-card-actions v-if="!error" class="justify-center">
                    <template v-if="step === 1">
                        <v-btn color="primary" small class="mr-5" @click="goStep(2)">
                            {{ $t('continue') }}
                        </v-btn>
                        <v-btn color="white" small @click="modal = false">
                            {{ $t('cancel') }}
                        </v-btn>
                    </template>
                    <template v-if="step === 2">
                        <div>
                            <v-btn
                                color="primary"
                                small
                                :disabled="
                                    (type === 'dates' && (!startDate || !endDate)) ||
                                    (type === 'travelers' && (!valid || !checkEditMode))
                                "
                                class="mr-5 mb-3 mb-md-0"
                                :block="$breakpoint.smAndDown"
                                @click="goStep(3)"
                            >
                                {{ $t('modify.check_availability') }}
                            </v-btn>
                            <v-btn color="white" small :block="$breakpoint.smAndDown" @click="goStep(1)">
                                {{ $t('cancel') }}
                            </v-btn>
                        </div>
                    </template>
                    <template v-if="[3, 4].includes(step) && prepareModifyResult">
                        <v-btn
                            v-if="step === 3"
                            color="primary"
                            small
                            class="mr-5"
                            :loading="pageLock"
                            :disabled="pageLock"
                            @click="goStep(4)"
                        >
                            {{ service.status !== 'Quote' ? $t('next') : $t('confirm') }}
                        </v-btn>
                        <v-btn
                            v-else-if="step === 4"
                            color="primary"
                            small
                            class="mr-5"
                            :loading="pageLock"
                            :disabled="pageLock"
                            @click="goStep(5)"
                        >
                            {{ $t('confirm') }}
                        </v-btn>
                        <v-btn color="white" small :disabled="pageLock" @click="goStep(2)">
                            {{ type === 'dates' ? $t('modify.check_other_dates') : $t('cancel') }}
                        </v-btn>
                    </template>
                    <template v-if="step === 5">
                        <v-btn color="primary" small @click="modal = false">Ok</v-btn>
                    </template>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {EventBus, SHOW_MODIFY_MODAL_EVENT} from '@/utils/event-bus'
    import ModifyDaterange from '@/components/account/modify/ModifyDaterange'
    import ModifyTravelers from '@/components/account/modify/ModifyTravelers'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm.vue'
    import ExtraBaggageForm from '@/components/flights/booking/ExtraBaggageForm.vue'
    import FlightOrderServiceAdapter from '@/api/flights/adapter/FlightOrderServiceAdapter'
    import ServiceAdditionalOptionsMixin from '@/components/account/mixins/ServiceAdditionalOptionsMixin.vue'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm.vue'
    import PaymentOptions from '@/components/booking/PaymentOptions.vue'
    import BookingMixin from '@/components/mixins/BookingMixin.vue'

    @Component({
        components: {
            PaymentOptions,
            PaymentRedirectForm,
            ExtraBaggageForm,
            ExtraServicesForm,
            ModifyTravelers,
            ModifyDaterange,
        },
    })
    export default class ModifyModal extends mixins(ServiceAdditionalOptionsMixin, BookingMixin) {
        modal = false
        service = {}
        type = 'dates'
        step = 1
        startDate = null
        endDate = null
        tourists = []
        prepareModifyResult = null
        pageLock = false
        valid = true
        error = null
        orderId = null
        prepareBookRequest = {extraBaggage: []}
        paymentMethodId = null

        created() {
            EventBus.$on(SHOW_MODIFY_MODAL_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_MODIFY_MODAL_EVENT, this.showModal)
        }

        async showModal(service, orderId) {
            this.step = 1
            this.error = null
            this.service = service
            this.orderId = orderId
            this.tourists = this.service.travelers.map(traveler => ({
                prefix: traveler.prefix,
                firstName: traveler.name[0].firstName,
                lastName: traveler.name[0].lastName,
                ...(this.ownProduct && {
                    citizenshipId: traveler.citizenshipId,
                    birthdate: traveler.dateOfBirth,
                    passport: {
                        id: traveler.passports[0]?.passportId || 0,
                        number: traveler.passports[0]?.number || '',
                        expiryDate: traveler.passports[0]?.expiryDate || '',
                    },
                }),
                editMode: false,
            }))
            this.additionalOptionsLoading = true
            this.modal = true
            await this.loadAdditionalOptions(service)
        }

        formatDatepickerInput(props) {
            let result = ''
            if (this.dateTime !== null) {
                result = this.$options.filters.dateFormat(this.dateTime)
            }
            props.value = result
            return props
        }

        async goStep(step) {
            switch (step) {
                case 1:
                    this.error = null
                    break
                case 2:
                    this.error = null
                    this.prepareModifyResult = null
                    break
                case 3:
                    await this.prepareModify()
                    break
                case 4:
                    await this.loadPayments()
                    break
                case 5:
                    this.pageLock = true
                    await this.confirm()
                    this.pageLock = false
                    break
            }
            this.step = step
        }

        async prepareModify() {
            try {
                const rq = {
                    processId: this.service.processId.replace(/^.{6}/, ''),
                }
                if (this.type === 'dates') {
                    Object.assign(rq, {startDate: this.startDate, endDate: this.endDate})
                } else if (this.type === 'travelers') {
                    // eslint-disable-next-line no-unused-vars
                    const tourists = this.tourists.map(({editMode, ...tourist}) => {
                        if (tourist.passport) {
                            if (!tourist.passport.number && !tourist.passport.expireDate) {
                                delete tourist.passport
                            } else if (tourist.passport.id === 0) {
                                delete tourist.passport.id
                            }
                        }
                        return tourist
                    })
                    Object.assign(rq, {tourists: tourists})
                } else if (this.type === 'extraServices') {
                    Object.assign(rq, {extraServices: this.extraServices})
                    if (this.additionalOptions?.extraBaggageOptions?.length) {
                        //TODO Need to add on back
                        //Object.assign(rq, this.prepareBookRequest)
                    }
                }
                this.prepareModifyResult = await this.$api.prepareModifyService.post(rq)

                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }

                if (!this.prepareModifyResult.available) {
                    this.error = this.$t('modify.errors.supplier')
                    this.$toast.error(this.error)
                }
            } catch (error) {
                const errorMessage = error.errors[0].message
                switch (error.errors[0].token) {
                    case 'api.modify.order.tourist.type.is.not.allowed.to.be.modified':
                        this.error = this.$t('modify.errors.touristType')
                        break
                    default:
                        this.error = errorMessage
                }
                if (errorMessage === 'Incorrect start date.' || errorMessage === 'Incorrect end date.') {
                    this.error = this.$t('modify.errors.dates')
                }
                if (errorMessage.startsWith('Action is not available for the supplier')) {
                    this.error = this.$t('modify.errors.supplier')
                }
                this.$toast.error(this.error)
            }
        }

        async loadPayments() {
            try {
                await persistentStore.getPaymentOptions({
                    bookingKey: this.prepareModifyResult.bookingKey,
                    convertToCurrency: persistentStore.getCurrency(this.service.salesTerms[0].price.currency),
                })
                const {id, book} = persistentStore.paymentMethods[0]
                this.paymentMethodId = id
                if (this.service.status === 'Quote' && !book) {
                    await this.goStep(5)
                }
            } catch (e) {
                this.error = this.$t('error_message.get_payment_options_unknown_error')
            }
        }

        async confirm() {
            const paymentMethod = persistentStore.paymentMethods.find(
                paymentMethod => paymentMethod.id === this.paymentMethodId
            )
            if (paymentMethod.onlinePSP) {
                const success = await this.makeOnlinePayment()
                if (!success) {
                    this.error = this.$t('modify.errors.confirmation')
                }
                return
            }
            try {
                await this.$api.modifyService.post({
                    bookingKey: this.prepareModifyResult.bookingKey,
                    paymentMethodId: this.paymentMethodId,
                })
                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }
                this.$emit('modifyService')
            } catch (error) {
                this.error = this.$t('modify.errors.confirmation')
                this.$toast.error(error.errors[0].message)
            }
        }

        async markAsNotSentToSupplier() {
            await this.$api.orders.put(this.orderId, {
                servicesInfo: [
                    {
                        processId: this.service.processId,
                        serviceSentToSupplier: false,
                    },
                ],
            })
        }

        nights(start, end) {
            const startDate = new Date(start.slice(0, 10))
            const endDate = new Date(end.slice(0, 10))
            return (new Date(endDate) - new Date(startDate)) / 8.64e7
        }

        clientPrice(object) {
            return object.salesTerms.find(price => {
                return price.type === 'CLIENT'
            }).price
        }

        countryName(id) {
            return runtimeStore.countries.find(country => country.id === id)?.name
        }

        getPaymentCancelUrl() {
            return window.location.origin + this.$route.fullPath
        }

        get dateTimeData() {
            return this.$dateFns.parseISO(this.dateTime)
        }

        set dateTimeData(val) {
            this.dateTime = val ? this.$dateFns.format(val) : null
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get title() {
            if (this.step === 1) {
                return this.$t('modify.title')
            }

            return this.type === 'dates'
                ? this.$t('modify.title_dates')
                : this.type === 'travelers'
                ? this.$t('modify.title_travelers')
                : this.$t('modify.title_extra_services')
        }

        get subtitle() {
            if (this.step === 1) {
                return this.$t('modify.subtitle')
            }

            if (this.step === 3) {
                return this.type === 'dates'
                    ? this.$t('modify.subtitle_dates_ok')
                    : this.type === 'travelers'
                    ? this.$t('modify.subtitle_travelers_ok')
                    : this.$t('ModifyModal.confirm_extra_services_note')
            }

            if (this.step === 5) {
                return this.type === 'dates'
                    ? this.$t('modify.subtitle_dates_confirm')
                    : this.type === 'travelers'
                    ? this.$t('modify.subtitle_travelers_confirm')
                    : this.$t('modify.subtitle_extra_services_confirm')
            }

            return this.type === 'dates'
                ? this.$t('modify.subtitle_dates')
                : this.type === 'travelers'
                ? this.$t('modify.subtitle_travelers')
                : this.$t('ModifyModal.edit_extra_services_note')
        }

        get checkEditMode() {
            return this.tourists.every(tourist => tourist.editMode === false)
        }

        get ownProduct() {
            return runtimeStore.ownProduct(this.service.supplierCode)
        }

        get extraServiceItems() {
            return this.extraServices.map(({serviceRPH, quantity}) => ({
                name: this.additionalOptions.extraServices.find(extraService => extraService.serviceRPH === serviceRPH)
                    .name,
                quantity,
            }))
        }

        get extraBaggageItems() {
            return this.prepareBookRequest.extraBaggage.map(({serviceRPH, quantity}) => ({
                name: this.additionalOptions.extraBaggageOptions.find(
                    extraService => extraService.serviceRPH === serviceRPH
                ).name,
                quantity,
            }))
        }

        get flight() {
            if (!['FLIGHT', 'OWNCHARTER'].includes(this.service.serviceType)) return undefined
            return FlightOrderServiceAdapter(this.service)
        }

        get bookingKey() {
            return this.prepareModifyResult?.bookingKey
        }

        get productName() {
            return null
        }

        get corporatePolicyViolationCodeId() {
            return null
        }

        get extendedPaymentSuccessUrlParams() {
            return {
                modifyService: true,
                orderId: this.orderId,
                processId: this.service.processId,
            }
        }
    }
</script>
