<template>
    <div>
        <selected-filters :selected="filterValues" :translatable="['wishlist']" @delete-filter="clearFilter" />
        <price-filter
            v-model="filterValues"
            :filters="filters"
            :label="$t('price')"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.dates && filters.dates.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="dates"
            :pluralization="false"
            :date-filter="true"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.wishlist && filters.wishlist.length"
            v-model="filterValues"
            :filters="filters"
            filter="wishlist"
            :translate-labels="true"
            :panel-expanded="false"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            label="activitiesDataStore name"
            :filters="filters"
            filter="name"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            label="Activities description"
            :filters="filters"
            filter="shortDescription"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.types && filters.types.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="types"
            :pluralization="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.startTimes && filters.startTimes.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="startTimes"
            :pluralization="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.themes && filters.themes.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="themes"
            :pluralization="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.categories && filters.categories.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="categories"
            :pluralization="false"
            @change="change($event)"
        />
        <filtered-mobile-button
            v-if="$breakpoint.smAndDown"
            class="sticky-button"
            :count="filteredOffersCount"
            :visible="Object.keys(filterValues).length > 0"
            @change-mode="goToOffers"
            @clear-filters="reset"
        />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import CheckboxFilter from '~/components/search/filters/CheckboxFilter'
    import FilteredMobileButton from '~/components/snippets/FilteredMobileButton'
    import NameFilter from '~/components/filters/NameFilter'
    import SelectedFilters from '~/components/snippets/SeletedFilters'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import FiltersBase from '@/components/search/FiltersBase'

    @Component({
        components: {
            PriceFilter,
            CheckboxFilter,
            FilteredMobileButton,
            NameFilter,
            SelectedFilters,
        },
    })
    export default class ActivityFilters extends FiltersBase {}
</script>
