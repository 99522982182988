<template>
    <v-form
        v-model="subscribeValid"
        class="d-flex mx-md-0 mx-auto align-center"
        :action="mailChimpLink"
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
        novalidate
    >
        <v-text-field
            name="EMAIL"
            :rules="validationRules('email', [emailRule, requiredRule])"
            hide-details
            dense
            type="email"
            :placeholder="$t('email')"
        />
        <v-btn class="ms-2" color="primary" type="submit" :disabled="!subscribeValid">
            {{ $t('subscribe') }}
        </v-btn>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class MailChimpForm extends mixins(FormValidationRulesMixin) {
        @Prop() mailChimpLink

        subscribeValid = false
    }
</script>
