<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {isEqual} from '@/utils/helpers'

    @Component
    export default class SearchPageMixin extends Vue {
        needNewSearch() {
            return (
                !isEqual(this.productStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !this.productStore.hasOffers
            )
        }

        isOffersExpired() {
            return this.productStore.isOffersExpired()
        }

        get searchRequest() {
            return this.productRuntimeStore.searchRequestFromQuery(this.$route.query)
        }
    }
</script>
