<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {insuranceRuntimeStore, insuranceStore} from '@/store'

    @Component
    export default class InsuranceStoreMixin extends Vue {
        get productStore() {
            return insuranceStore
        }

        get productRuntimeStore() {
            return insuranceRuntimeStore
        }

        get productType() {
            return 'insurance'
        }
    }
</script>
