<template>
    <v-icon class="info-icon me-2" right small color="black">
        {{ icon }}
    </v-icon>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ServiceTipsIcon extends Vue {
        get icon() {
            return 'mdi-note-multiple-outline'
        }
    }
</script>
