<template>
    <div>
        <v-text-field
            v-if="!loaded"
            :dense="dense"
            :class="textFieldClass"
            :label="label || $t('check_in')"
            readonly
            :single-line="singleLine"
            :outlined="outlined"
            filled
            hide-details
            :background-color="backgroundColor"
            :rounded="rounded"
            :prepend-inner-icon="showIcon ? prependInnerIcon : null"
            :append-icon="appendIcon"
            data-cy="date-rage-input"
            @click:append="clickAppend"
        />
        <client-only>
            <vc-date-picker
                v-model="range"
                is-range
                :mode="mode"
                :is24hr="mode === 'dateTime'"
                :model-config="modelConfig"
                :locale="$i18n.locale"
                :min-date="minStartDate"
                :max-date="maxEndDate"
                :columns="$breakpoint.smAndDown ? 1 : 2"
                :rows="1"
                :first-day-of-week="$config.firstDayOfWeek || undefined"
                :popover="popover"
                data-cy="date-picker-input"
                @dayclick="dayclick"
                @popoverWillShow="popoverWillShow"
            >
                <template v-slot="{inputEvents}">
                    <div v-on="inputEvents.start">
                        <v-text-field
                            :dense="dense"
                            :class="textFieldClass"
                            :label="label || $t('check_in')"
                            readonly
                            :single-line="singleLine"
                            :outlined="outlined"
                            filled
                            hide-details
                            :background-color="backgroundColor"
                            :rounded="rounded"
                            :prepend-inner-icon="showIcon ? prependInnerIcon : null"
                            :append-icon="appendIcon"
                            :value="formattedDate"
                            @click:append="clickAppend"
                        />
                    </div>
                </template>
            </vc-date-picker>
        </client-only>
    </div>
</template>

<script>
    import {Component, Emit, Prop, PropSync, Vue} from 'nuxt-property-decorator'

    @Component
    export default class DaterangeM extends Vue {
        @PropSync('startDate') start
        @PropSync('endDate') end
        @Prop({default: false, type: Boolean}) outlined
        @Prop() label
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: true}) singleLine
        @Prop({default: true}) showIcon
        @Prop({default: () => ({placement: 'bottom'})}) popover
        @Prop({default: 'hotels'}) product
        @Prop({default: 'date'}) mode
        @Prop() appendIcon
        @Prop({default: 180}) maxNights
        @Prop({default: 'mdi-calendar'}) prependInnerIcon
        @Prop({default: 'white'}) backgroundColor
        @Prop({default: false, type: Boolean}) rounded

        selectedStartDate = null
        loaded = false

        @Emit('click:append')
        clickAppend(event) {
            return event
        }

        mounted() {
            this.loaded = true
        }

        addDays(date, days) {
            return this.$dateFns.format(this.$dateFns.addDays(date, days))
        }

        dayclick(day) {
            if (!this.selectedStartDate) {
                this.selectedStartDate = this.$dateFns.parseISO(day.id)
            }
        }

        popoverWillShow() {
            this.selectedStartDate = null
        }

        get range() {
            return {
                start: this.start,
                end: this.end,
            }
        }

        set range(val) {
            if (!val) return
            this.start = val.start
            this.end = val.end
        }

        get minStartDate() {
            if (this.selectedStartDate) {
                const minStartDate = this.$dateFns.subDays(this.selectedStartDate, this.maxNights)
                if (this.$dateFns.isAfter(minStartDate, new Date())) {
                    return this.$dateFns.format(minStartDate)
                }
            }
            return this.$dateFns.format(new Date())
        }

        get maxEndDate() {
            if (!this.selectedStartDate) return null
            return this.addDays(this.selectedStartDate, this.maxNights)
        }

        get formattedDate() {
            return this.range.start !== null && this.range.end !== null
                ? `${this.$options.filters.dateFormat(this.range.start)} -  ${this.$options.filters.dateFormat(
                      this.range.end
                  )}`
                : ''
        }

        get textFieldClass() {
            let className = ''
            if (this.$breakpoint.smAndDown) {
                className += 'caption'
            }
            className += ' date-range-input ' + this.product
            return className
        }

        get modelConfig() {
            if (this.mode === 'dateTime') {
                return {type: 'string', mask: 'YYYY-MM-DDTHH:mm'}
            } else {
                return {type: 'string', mask: 'YYYY-MM-DD'}
            }
        }
    }
</script>

<style scoped lang="scss">
    .date-range-input::v-deep {
        @media (min-width: 1400px) and (max-width: 1635px) {
            input {
                font-size: 13px;
            }
        }
        @media (min-width: 1325px) and (max-width: 1399px) {
            input {
                font-size: 12px;
            }
        }
        @media (min-width: 1245px) and (max-width: 1324px) {
            input {
                font-size: 11px;
            }
        }
        @media (min-width: 1165px) and (max-width: 1244px) {
            input {
                font-size: 10px;
            }
        }
        @media (min-width: 1090px) and (max-width: 1164px) {
            input {
                font-size: 9px;
            }
        }
        @media (min-width: 961px) and (max-width: 1089px) {
            input {
                font-size: 8px;
            }
        }
    }

    .date-range-input.flights::v-deep {
        @media (min-width: 1636px) and (max-width: 1840px) {
            input {
                font-size: 14px;
            }
        }
        @media (min-width: 1400px) and (max-width: 1635px) {
            input {
                font-size: 11px;
            }
        }
        @media (min-width: 1325px) and (max-width: 1399px) {
            input {
                font-size: 10px;
            }
        }
        @media (min-width: 1245px) and (max-width: 1324px) {
            input {
                font-size: 9px;
            }
        }
        @media (min-width: 1165px) and (max-width: 1244px) {
            input {
                font-size: 9px;
            }
        }
        @media (min-width: 1090px) and (max-width: 1164px) {
            input {
                font-size: 8px;
            }
        }
        @media (min-width: 961px) and (max-width: 1089px) {
            input {
                font-size: 7px;
            }
        }
    }
</style>
