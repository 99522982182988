<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelMap from '@/components/search/offers/HotelMap'

    @Component()
    export default class InsuranceMap extends HotelMap {
        //TODO InsuranceMap
        async load() {
            await this.initMap()
        }

        setMapPosition() {
            this.map.fitBounds(this.bounds)
            this.mapPositionInit = true
        }

        saveMapPosition() {}
    }
</script>
