<template>
    <v-tabs v-model="mobileTabs" fixed-tabs class="sticky-bar">
        <v-tab>
            <!-- <v-icon>mdi-office-building</v-icon> -->
            {{ $t('info') }}
        </v-tab>
        <v-tab>
            <!-- <v-icon>mdi-bed</v-icon> -->
            {{ $t(mobileTab1Title) }}
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Vue, Component, Model, Watch, Prop} from 'vue-property-decorator'

    @Component
    export default class MobileInfoPageTabs extends Vue {
        @Model('change') value
        @Prop({default: 'offers'}) mobileTab1Title

        mobileTabs = 0

        @Watch('value')
        onChangeValue(val) {
            this.mobileTabs = val
        }

        @Watch('mobileTabs')
        onChangeMobileTabs(val) {
            this.$emit('change', val)
        }
    }
</script>
