<template>
    <v-row>
        <v-col v-if="mealTypeItems && mealTypeItems.length > 1" cols="12" md="3">
            <select-option
                :items="mealTypeItems"
                :label="$t('filterTitles.mealTypes')"
                :deletable-chips="false"
                :value="mealTypes"
                :multiple="false"
                name="mealTypes"
                autocomplete-disabled
                dense
                @change="change($event, 'mealTypes')"
            />
        </v-col>
        <v-col v-if="availabilityItems && availabilityItems.length > 1" cols="12" md="3">
            <select-option
                :items="availabilityItems"
                :label="$t('filterTitles.availability')"
                :deletable-chips="false"
                :value="availability"
                :multiple="false"
                name="availability"
                autocomplete-disabled
                dense
                @change="change($event, 'availability')"
            />
        </v-col>
        <v-col v-if="roomTypeItems && roomTypeItems.length > 1" cols="12" md="3">
            <select-option
                :items="roomTypeItems"
                :label="$t('filterTitles.roomTypes')"
                :deletable-chips="false"
                :multiple="false"
                name="roomTypes"
                autocomplete-disabled
                dense
                @change="change($event, 'roomTypes')"
            />
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import SelectOption from '@/components/snippets/AdditionalSearchOptions/SelectOption'

    @Component({
        components: {
            SelectOption,
        },
    })
    export default class RoomFilters extends Vue {
        @Prop({required: true}) filterValues

        mealTypeItems = []
        roomTypeItems = []
        availabilityItems = []

        mealTypes = null
        roomTypes = null
        availability = null

        async mounted() {
            this.setMealTypesItems()
            this.setRoomTypesItems()
            this.setAvailabilityItems()
        }

        @Emit()
        change(val, type) {
            if (type === 'mealTypes') {
                this.mealTypes = val.value
            } else if (type === 'roomTypes') {
                this.roomTypes = val.value
            } else if (type === 'availability') {
                this.availability = val.value
            }

            return {
                mealTypes: this.mealTypes,
                roomTypes: this.roomTypes,
                availability: this.availability,
            }
        }

        setMealTypesItems() {
            this.mealTypeItems = this.filterValues?.mealTypes.reduce((mealTypes, item) => {
                mealTypes.push({name: this.$t(`filters_mealTypes.${item.value}`), value: item.value})

                return mealTypes
            }, [])
        }

        setRoomTypesItems() {
            this.roomTypeItems = this.filterValues?.roomTypes.reduce((mealTypes, item) => {
                mealTypes.push({name: item.value, value: item.value})

                return mealTypes
            }, [])
        }

        setAvailabilityItems() {
            this.availabilityItems = this.filterValues?.availability.reduce((mealTypes, item) => {
                mealTypes.push({name: this.$t(`${item.value}`), value: item.value})

                return mealTypes
            }, [])
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-input {
            margin-top: 0;
        }
    }
</style>
