<template>
    <v-card class="mb-5">
        <!-- <v-alert border="top" color="primary" class="white--text mb-0 ">
            <div class="d-flex flex-wrap flex-md-nowrap">
                <div class="d-flex align-center">
                    <v-icon color="white">
                        mdi-bag
                    </v-icon>
                    <span class="ml-2 body-1">
                        {{ order.orderPackage.packageName }}
                    </span>
                </div>
            </div>
        </v-alert> -->
        <v-card-text>
            <order-info-base
                :service="hotel"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :is-package="false"
                :service-image="null"
                :order="order"
                :order-end-date="orderEndDate"
                :package-flight-status="order.orderPackage.status"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-slot:extraTime>
                    <div>
                        <v-icon small>mdi-airplane-takeoff</v-icon>
                        <span>
                            {{ flightCheckIn }}
                        </span>
                    </div>
                    <div>
                        <v-icon small>mdi-airplane-landing</v-icon>
                        <span>
                            {{ flightCheckOut }}
                        </span>
                    </div>
                </template>
                <div>
                    <v-icon left small>mdi-bed-empty</v-icon>
                    {{ room.roomTypeName }}
                </div>
                <div>
                    <v-icon left small>mdi-room-service</v-icon>
                    {{ room.mealTypeName }}
                </div>
                <div>
                    <v-icon left small>mdi-weather-night</v-icon>
                    {{ `${nights} ${$tc('night', nights)}` }}
                </div>
                <template v-slot:price>
                    <v-col cols="12" md="4" class="justify-center justify-md-end">
                        <order-prices
                            :supplier-cancel-penalties="order.orderPackage.supplierCancelPenalties"
                            :supplier-payment-status="order.supplierPaymentStatus"
                            :supplier-price="order.orderPackage.supplierPrice"
                            :client-cancel-penalties="order.orderPackage.clientCancelPenalties"
                            :customer-payment-status="order.customerPaymentStatus"
                            :client-price="order.orderPackage.clientPrice"
                        />
                    </v-col>
                </template>
            </order-info-base>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import OrderPrices from '~src/components/account/snippets/orderPrices.src'

    @Component({
        components: {
            OrderPrices,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
        },
    })
    export default class PackageInfo extends Vue {
        @Prop() order

        get hotel() {
            return this.order.services.find(({serviceType}) => serviceType === 'ACCOMMODATION')
        }

        get flight() {
            return this.order.services.find(({serviceType}) => serviceType === 'FLIGHT' || serviceType === 'OWNCHARTER')
        }

        get room() {
            return this.hotel.serviceDetails[0].rooms[0]
        }

        get serviceCheckIn() {
            const time = this.hotel.serviceDetails[0].checkInFrom
            const date = this.$options.filters.dateFormat(this.hotel.startDateTime)
            return time ? `${this.$t('time_from')} ${time} ${date}` : date
        }

        get serviceCheckOut() {
            const time = this.hotel.serviceDetails[0].checkOutTo
            const date = this.$options.filters.dateFormat(this.hotel.endDateTime)
            return time ? `${this.$t('time_before')} ${time} ${date}` : date
        }

        get flightCheckIn() {
            const startDate = this.flight.serviceDetails[0].itineraries[0].flightSegments[0].departureDate
            const date = this.$options.filters.dateTimeFormat(startDate)
            return `${this.$t('flights.departure')}: ${date}`
        }

        get flightCheckOut() {
            const itinerariesCount = this.flight.serviceDetails[0].itineraries.length
            const lastItinerarySegmentsCount =
                this.flight.serviceDetails[0].itineraries[itinerariesCount - 1].flightSegments.length
            const endDate =
                this.flight.serviceDetails[0].itineraries[itinerariesCount - 1].flightSegments[
                    lastItinerarySegmentsCount - 1
                ].arrivalDate
            const date = this.$options.filters.dateTimeFormat(endDate)
            return `${this.$t('flights.arrival')}: ${date}`
        }

        get nights() {
            const startDate = new Date(this.hotel.startDateTime.slice(0, 10))
            const endDate = new Date(this.hotel.endDateTime.slice(0, 10))
            return (new Date(endDate) - new Date(startDate)) / 8.64e7
        }

        getPaymentColor(status) {
            const failed = ['NOT_PAID', 'NO_BILL', 'OVERDUE', 'NO_SHOW']
            if (failed.includes(status)) {
                return 'error'
            }
            return 'success'
        }
    }
</script>
