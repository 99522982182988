<template>
    <v-dialog v-model="modal" width="700" :persistent="loading">
        <v-card :disabled="loading">
            <v-card-title class="d-flex justify-space-between">
                <span>
                    {{ $t('reservation_report') }}
                </span>
                <v-icon @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <span class="caption grey--text mb-2">
                    Select service(s) to send in Reservation Report e-mail to the corresponding supplier(s)
                </span>
                <div class="d-flex flex-column">
                    <div v-for="service in ownServicesObjects" :key="service.processId" class="d-flex flex-column">
                        <div class="font-weight-medium d-flex align-center">
                            <v-checkbox v-model="service.selected" :label="service.serviceName" />
                            <span v-if="service.serviceSentToSupplier" class="success--text ms-2 caption">
                                {{ $t('sent_to_supplier') }}
                                <template v-if="service.serviceSentToSupplierDateTime">
                                    (
                                    <span>
                                        {{ service.serviceSentToSupplierDateTime | dateFormat }}
                                    </span>
                                    <span>
                                        {{ service.serviceSentToSupplierDateTime | hoursFromDate }}
                                    </span>
                                    )
                                </template>
                            </span>
                            <span v-else class="error--text ms-2 caption">
                                {{ $t('not_sent_to_supplier') }}
                            </span>
                        </div>
                        <div class="ms-8 mt-n5 d-flex flex-column">
                            <span>
                                <span>
                                    {{ service.startDateTime | dateFormat }}
                                </span>
                                <span v-if="service.endDateTime">
                                    -
                                    {{ service.endDateTime | dateFormat }}
                                </span>
                            </span>
                            <span style="font-size: 10px">
                                {{ service.supplierCompanyName }}
                            </span>
                        </div>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    color="primary"
                    :block="$breakpoint.smAndDown"
                    :loading="loading"
                    :disabled="!servicesSelected.length"
                    @click="send"
                >
                    {{ $t('submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class ReservationReportOrderModal extends Vue {
        @VModel() modal
        @Prop() order

        loading = false
        ownServicesObjects = []

        @Watch('order.services', {deep: true})
        onServicesChange() {
            this.setServices()
        }

        mounted() {
            this.setServices()
        }

        setServices() {
            this.ownServicesObjects = this.order.services
                .filter(s => !!runtimeStore.ownProduct(s?.supplierCode))
                .map(s => ({
                    ...s,
                    selected: true,
                }))
        }

        async send() {
            try {
                this.loading = true

                await this.$api.reservationReportMail.post({
                    orderId: this.order.orderId,
                    processIds: this.servicesSelected.map(s => s.processId),
                })

                await runtimeStore.refreshOrder()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
                this.modal = false
            }
        }

        get servicesSelected() {
            return this.ownServicesObjects.filter(s => !!s.selected)
        }
    }
</script>
