<template>
    <div v-if="filters">
        <selected-filters
            :selected="filterValues"
            :translatable="['refundable', 'class']"
            @delete-filter="clearFilter"
        />
        <price-filter
            v-if="filters.price && filters.price[1] !== Infinity && filters.price[1]"
            v-model="filterValues"
            :filters="filters"
            :label="$t('price')"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <name-filter v-model="filterValues" :filters="filters" filter="flightNumber" @change="change($event)" />
        <checkbox-filter
            v-if="filters.stops && filters.stops.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="stops"
            :pluralization="true"
            :translate-labels="true"
            @change="change($event)"
        />
        <range-filter
            v-if="filters.duration && filters.duration[1] !== Infinity && filters.duration[1]"
            v-model="filterValues"
            :filters="filters"
            filter="duration"
            :pluralization="true"
            value-filter="durationFormat"
            :label="$t('filterTitles.duration')"
            @change="change($event)"
        />
        <range-filter
            v-model="filterValues"
            :filters="filters"
            filter="startTime"
            value-filter="durationFormat"
            :label="$t('filterTitles.flightStartTime')"
            @change="change($event)"
        />
        <range-filter
            v-model="filterValues"
            :filters="filters"
            filter="endTime"
            value-filter="durationFormat"
            :label="$t('filterTitles.flightEndTime')"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.airline && filters.airline.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="airline"
            :pluralization="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.brandedFareName && filters.brandedFareName.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="brandedFareName"
            :pluralization="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.class && filters.class.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="class"
            :translate-labels="true"
            :pluralization="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.refundable && filters.refundable.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="refundable"
            :pluralization="false"
            :translate-labels="true"
            @change="change($event)"
        />
        <filtered-mobile-button
            v-if="$breakpoint.smAndDown"
            class="sticky-button"
            :count="filteredOffersCount"
            :visible="Object.keys(filterValues).length > 0"
            @change-mode="goToOffers"
            @clear-filters="reset"
        />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import FilteredMobileButton from '~/components/snippets/FilteredMobileButton'
    import NameFilter from '~/components/filters/NameFilter'
    import SelectedFilters from '~/components/snippets/SeletedFilters'
    import RangeFilter from '~/components/search/filters/RangeFilter'
    import FiltersBase from '~/components/search/FiltersBase'

    @Component({
        components: {
            PriceFilter,
            CheckboxFilter,
            FilteredMobileButton,
            NameFilter,
            SelectedFilters,
            RangeFilter,
        },
    })
    export default class FlightFilters extends FiltersBase {}
</script>
