<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <template v-for="offer in offers">
            <tour-offer-activity-entry
                :key="offer.offerKey"
                :offer="offer"
                :offer-key="offerKey"
                :entry-name="entry.entryName"
            >
                <template v-slot:price="{offer}">
                    <tour-offer-delta-button :offer="offer" :offer-key="offerKey" :entry-name="entry.entryName" />
                </template>
            </tour-offer-activity-entry>
        </template>
    </v-dialog>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_ACTIVITY_INFO} from '@/utils/event-bus'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton'
    import TourOfferActivityEntry from '@/components/tours/search/offers/TourOfferActivityEntry'

    @Component({
        components: {
            TourOfferDeltaButton,
            TourOfferActivityEntry,
        },
    })
    export default class TourActivitiesInfoModal extends Vue {
        @Prop({required: true}) searchRequest

        modal = false
        entry = {}
        offerKey = null

        mounted() {
            EventBus.$on(SHOW_TOUR_ACTIVITY_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_ACTIVITY_INFO, this.showModal)
        }

        async showModal(entry, offerKey) {
            this.entry = entry
            this.offerKey = offerKey
            this.modal = true
        }

        get offers() {
            return this.entry.offers ? this.entry.offers[0].offers : []
        }
    }
</script>
