<template>
    <div class="d-flex flex-column">
        <v-row>
            <v-col md="4" cols="12">
                <date-picker
                    v-model="transactionDateTime"
                    outlined
                    dense
                    :label="$t('invoices.pay_date')"
                    hide-details
                />
            </v-col>
            <v-col v-if="canChangeExchangeRates" md="8" cols="12">
                <v-row v-for="(rate, i) in exchangeRates" :key="i">
                    <v-col md="4" cols="12">
                        <v-text-field
                            v-model="rate.exchangeRate"
                            outlined
                            :loading="isExchangeRatesLoading"
                            :disabled="isExchangeRatesLoading"
                            dense
                            :items="clientPriceCurrencies"
                            :label="`${$t('exchange_rate')}`"
                        />
                    </v-col>
                    <v-col v-show="clientPriceCurrencies && clientPriceCurrencies.length > 1" md="4" cols="12">
                        <v-select
                            v-model="rate.currencyTo"
                            outlined
                            :loading="isExchangeRatesLoading"
                            :disabled="isExchangeRatesLoading"
                            dense
                            :items="clientPriceCurrencies"
                            :label="`${$t('currency')}`"
                            @change="resetExchangeRate(rate)"
                        />
                    </v-col>
                    <v-col md="2" cols="12">
                        <div class="text--secondary mt-3">
                            {{ getNewExchangeAmount(rate) }}
                        </div>
                    </v-col>
                    <v-col v-if="multipleCurrencyChange" md="2" cols="12">
                        <v-btn
                            v-if="i === 0"
                            fab
                            dark
                            small
                            color="primary"
                            :disabled="isExchangeRatesLoading"
                            @click="addExchangeRate"
                        >
                            <v-icon dark> mdi-plus </v-icon>
                        </v-btn>

                        <v-btn v-else small depressed fab :disabled="isExchangeRatesLoading" @click="removeRate(rate)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="remark" hide-details rows="3" dense outlined :label="$t('remark')" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="paymentMethodObject" class="mt-0">
                    <template v-for="paymentMethod in paymentMethods">
                        <v-radio :key="paymentMethod.invoicePaymentKey" :value="paymentMethod">
                            <template v-slot:label>
                                <span class="me-2">{{
                                    capitalizeTheFirstLetterOfEachWord(
                                        paymentMethod.paymentMethodCode || $t('bank_transfer.bank_transfer')
                                    )
                                }}</span>
                                <span>
                                    {{
                                        {amount: paymentMethod.paymentAmount, currency: paymentMethod.currency}
                                            | formatPrice
                                    }}
                                </span>
                            </template>
                        </v-radio>
                    </template>
                </v-radio-group>
                <div id="dropin-container" />
            </v-col>
        </v-row>
        <v-expand-transition>
            <bank-transfer-form
                v-if="paymentMethodObject && isOfflinePayment"
                v-model="valid"
                class="mb-6"
                :_bank-transfer-details.sync="bankTransferDetails"
            />
        </v-expand-transition>
        <v-row>
            <v-col>
                <v-btn color="primary" :disabled="isButtonDisabled" :loading="isPaymentLoading" @click="pay">
                    {{ $t('pay') }}
                </v-btn>
            </v-col>
        </v-row>
        <payment-redirect-form ref="paymentRedirectForm" />
    </div>
</template>

<script>
    import {Component, Emit, mixins, Prop} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import {loadStripe} from '@stripe/stripe-js'
    import AdyenCheckout from '@adyen/adyen-web'
    import {EventBus, SHOW_NOTIFICATIONS} from '@/utils/event-bus'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import BankTransferForm from '@/components/account/forms/BankTransferForm'
    import {capitalizeTheFirstLetterOfEachWord} from '@/utils/helpers'
    import {appInstance} from '@/utils/app-accessor'

    @Component({
        components: {BankTransferForm, PaymentRedirectForm, DatePicker},
    })
    export default class OrderManualInvoicePaymentForm extends mixins(FormValidationRulesMixin) {
        @Prop() paymentMethods
        @Prop() orderId
        @Prop() clientPriceCurrencies
        @Prop() invoiceType
        @Prop({default: false, type: Boolean}) multipleCurrencyChange
        @Prop({default: false, type: Boolean}) disableMinPayDate

        isPaymentLoading = false

        paymentMethodObject = null
        transactionDateTime = null
        remark = null
        bankTransferDetails = {
            bank: '',
            iban: '',
            branch: '',
            swiftCode: '',
            account: '',
            reference: '',
            receivingBank: '',
            valueDate: '',
        }
        valid = false

        exchangeRatesFromAPI = []
        exchangeRates = []
        isExchangeRatesLoading = false

        @Emit()
        completePayment() {}

        capitalizeTheFirstLetterOfEachWord(val) {
            return capitalizeTheFirstLetterOfEachWord(val.toLowerCase())
        }

        async mounted() {
            this.transactionDateTime = this.$dateFns.format(new Date())

            if (this.canChangeExchangeRates) {
                this.exchangeRates = [
                    {
                        currencyFrom: this.currencyForPayment,
                        currencyTo: this.clientPriceCurrencies[0],
                        exchangeRate: null,
                    },
                ]

                try {
                    this.isExchangeRatesLoading = true
                    await this.initExchangeRates()
                    // eslint-disable-next-line no-empty
                } catch (e) {
                } finally {
                    this.isExchangeRatesLoading = false
                }
            }
        }

        async initExchangeRates() {
            this.exchangeRatesFromAPI = await appInstance.$api.exchangeRates.get({
                currencyCode: this.currencyForPayment,
                precision: 10,
            })

            this.resetExchangeRatesAll()
        }

        resetExchangeRatesAll() {
            this.exchangeRates.forEach(exchangeRate => {
                this.resetExchangeRate(exchangeRate)
            })
        }

        resetExchangeRate(rate) {
            rate.exchangeRate = (1 / this.exchangeRatesFromAPI[rate.currencyTo])?.toFixed(2)
        }

        addExchangeRate() {
            const newRate = {
                currencyFrom: this.currencyForPayment,
                currencyTo: this.clientPriceCurrencies[0],
                exchangeRate: null,
            }
            this.resetExchangeRate(newRate)
            this.exchangeRates.push(newRate)
        }

        removeRate(rate) {
            const rateIndex = this.exchangeRates.findIndex(el => el === rate)
            this.exchangeRates.splice(rateIndex, 1)
        }

        async pay() {
            this.isPaymentLoading = true
            const success = this.isOfflinePayment ? await this.makeOfflinePayment() : await this.makeOnlinePayment()
            this.isPaymentLoading = false

            if (success) {
                this.completePayment()
            }
        }

        async makeOfflinePayment() {
            try {
                await this.$api.manualInvoicePaymentByKey.put({
                    invoicePaymentKey: this.paymentMethodObject.invoicePaymentKey,
                    remark: this.remark,
                    bankTransferDetails: this.bankTransferDetails,
                    transactionDateTime: this.transactionDateTime,
                    ...(this.exchangeRates?.length && {exchangeRates: this.exchangeRates}),
                })

                return true

                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async makeOnlinePayment() {
            try {
                const paymentCompletePageQuery = status => {
                    return {
                        invoicePaymentKey: this.paymentMethodObject.invoicePaymentKey,
                        orderId: this.orderId,
                        status: status,
                        remark: this.remark,
                        transactionDateTime: this.transactionDateTime,
                    }
                }

                const getPaymentUrl = paymentStatus => {
                    return (
                        window.location.origin +
                        this.$router.resolve({
                            name: 'manualInvoicePaymentCompletePage',
                            query: paymentCompletePageQuery(paymentStatus),
                        }).href
                    )
                }

                const rq = {
                    invoicePaymentKey: this.paymentMethodObject.invoicePaymentKey,
                }
                const rqOnline = {
                    ...rq,
                    ...{
                        successUrl: getPaymentUrl('success'),
                        failureUrl: getPaymentUrl('failure'),
                        cancelUrl: getPaymentUrl('cancel'),
                    },
                }

                const paymentInitiateRs = await persistentStore.paymentInitiate(rqOnline)

                if (paymentInitiateRs.simpleRedirect) {
                    if (paymentInitiateRs.parameters.length) {
                        const query = new URLSearchParams(
                            paymentInitiateRs.parameters.map(({name, value}) => [name, value])
                        )
                        window.location = paymentInitiateRs.url + '?' + query
                    } else {
                        window.location = paymentInitiateRs.url
                    }
                } else {
                    const paymentMethod = this.paymentMethodObject
                    if (paymentMethod.paymentMethodCode === 'webpay' && this.$config.webpayContractDomain) {
                        const {url, parameters, paymentNumber, paymentTransactionId} = paymentInitiateRs
                        await this.$refs.paymentRedirectForm.submit({
                            url: `${this.$config.webpayContractDomain}/payment-redirect`,
                            parameters,
                            paymentNumber,
                            paymentTransactionId,
                            originUrl: url,
                        })
                    } else if (paymentMethod.paymentMethodCode === 'stripe') {
                        const {APIPublicKey, sessionId} = paymentInitiateRs.parameters.reduce(
                            (parameters, {name, value}) => {
                                parameters[name] = value
                                return parameters
                            },
                            {}
                        )
                        const stripe = await loadStripe(APIPublicKey)
                        await stripe.redirectToCheckout({sessionId})
                    } else if (paymentMethod.paymentMethodCode === 'adyen') {
                        const findParameter = (name, array) => {
                            return array.find(e => e.name === name)?.value
                        }
                        const checkout = await AdyenCheckout({
                            session: {
                                id: findParameter('SESSION_ID', persistentStore.paymentInitiateResponse.parameters),
                                sessionData: findParameter(
                                    'SESSION_DATA',
                                    persistentStore.paymentInitiateResponse.parameters
                                ),
                            },
                            clientKey: findParameter(
                                'CLIENT_API_KEY',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            environment: findParameter(
                                'ENVIRONMENT',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            onPaymentCompleted: res => {
                                if (res.resultCode.toLowerCase() === 'authorised') {
                                    const params = {
                                        paymentNumber: paymentInitiateRs.paymentNumber,
                                        ...res,
                                    }
                                    window.location = `${getPaymentUrl('success')}&${new URLSearchParams(
                                        params
                                    ).toString()}`
                                } else {
                                    window.location = getPaymentUrl('failure')
                                }
                            },
                            onError: () => {},
                        })

                        checkout.create('dropin').mount('#dropin-container')
                    } else {
                        await this.$refs.paymentRedirectForm.submit(paymentInitiateRs)
                    }
                }
                return true
            } catch (e) {
                EventBus.$emit(SHOW_NOTIFICATIONS, {
                    notifications: [this.$t('error_message.payment_initiate_error')],
                    color: 'error',
                })
                return false
            }
        }

        getNewExchangeAmount(rate) {
            return (rate.exchangeRate * this.amountForPayment)?.toFixed(2)
        }

        get isButtonDisabled() {
            return !this.paymentMethodObject || (this.isOfflinePayment && !this.valid)
        }

        get isOfflinePayment() {
            return this.paymentMethodObject?.paymentMethod !== 'PAYMENT_SYSTEM_PROVIDER'
        }

        get currencyForPayment() {
            return this.paymentMethods[0]?.currency
        }

        get amountForPayment() {
            return this.paymentMethods[0]?.paymentAmount
        }

        get isTO1() {
            return authStore.isTO1
        }

        get canChangeExchangeRates() {
            return this.isTO1 && this.invoiceType === 'SUPPLIER'
        }
    }
</script>
