<template>
    <v-form ref="form" v-model="valid" :disabled="disabled">
        <div>
            <span class="text-h6 text-capitalize">
                {{ `${$t(`transfers.${pointType === 'departure' ? 'from' : 'to'}`)}: ` }}
            </span>
            <v-icon>mdi-train</v-icon>
            <span class="text-subtitle-2">
                {{ formTitle }}
            </span>
        </div>
        <v-row>
            <v-col v-if="!isProductFieldDisabled('POINT_TRAIN_CITY')" cols="12" md="4">
                <city-autocomplete
                    v-model="city"
                    :single-line="false"
                    :label="
                        pointType === 'arrival'
                            ? productFieldLabel('transfers.departure_to_city', 'POINT_TRAIN_CITY')
                            : productFieldLabel('transfers.arriving_from_city', 'POINT_TRAIN_CITY')
                    "
                    :show-placeholder="false"
                    :rules="trainCityRules"
                    show-details
                />
            </v-col>
            <v-col v-if="!isProductFieldDisabled('POINT_TRAIN_NUMBER')" cols="12" md="2">
                <v-text-field
                    v-model="trainNumber"
                    :label="productFieldLabel('transfers.train_number', 'POINT_TRAIN_NUMBER')"
                    :rules="trainNumberRules"
                />
            </v-col>
            <template v-if="!isProductFieldDisabled('POINT_TRAIN_TIME')">
                <v-col cols="12" md="3">
                    <date-picker
                        v-model="date"
                        :label="productFieldLabel('date', 'POINT_TRAIN_TIME')"
                        :rules="trainDateRules"
                        :min="minDate"
                        :max="maxDate"
                    />
                </v-col>
                <v-col v-if="!isProductFieldDisabled('POINT_TRAIN_TIME')" cols="12" md="3">
                    <time-field
                        v-model="time"
                        :label="productFieldLabel('time', 'POINT_TRAIN_TIME')"
                        :rules="trainTimeRules"
                    />
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync, Watch} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferFormMixin from '@/components/transfers/mixins/TransferFormMixin'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    //TODO Need mixin with TransferAirportForm
    @Component({
        components: {TimeField, CityAutocomplete, DatePicker},
    })
    export default class TransferTrainStationForm extends mixins(
        TransferFormMixin,
        ProductBookingFieldsMixin,
        FormValidationRulesMixin
    ) {
        @Prop() pointType
        @Prop() disabled
        @Prop() searchRequest
        @PropSync('_valid', {default: false}) valid
        @Prop() returnTransferOffer

        city = null

        @Watch('city', {immediate: false})
        onTrainStationChange(city) {
            this.cityId = city?.id
        }

        async mounted() {
            await this.initData()
        }

        async initData() {
            const date = this.$dateFns.parseISO(this.date)
            if (this.returnTransferOffer) {
                this.setProp('dateTime', this.returnTransferOffer.date + 'T00:00')
            } else if (
                !this.date ||
                this.$dateFns.isAfter(date, this.$dateFns.parseISO(this.maxDate)) ||
                this.$dateFns.isBefore(date, this.$dateFns.parseISO(this.minDate))
            ) {
                this.setProp('dateTime', this.searchRequest.dateTime + 'T00:00')
            }
            if (this.cityId) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.cityId, limit: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {
                    this.cityId = null
                }
            }
        }

        splitDateTime() {
            let dateTime = this.getProp('dateTime')
            if (dateTime) {
                dateTime = dateTime.split('T')
            } else {
                dateTime = []
            }
            return dateTime
        }

        get trainDateRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_TRAIN_TIME')) {
                rules.push(this.requiredRule('date', 'POINT_TRAIN_TIME'))
            }
            return rules
        }

        get trainTimeRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_TRAIN_TIME')) {
                rules.push(this.requiredRule('time', 'POINT_TRAIN_TIME'))
            }
            return rules
        }

        get trainNumberRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_TRAIN_NUMBER')) {
                rules.push(this.requiredRule('transfers.arriving_from_city', 'POINT_TRAIN_NUMBER'))
            }
            return rules
        }

        get trainCityRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_TRAIN_CITY')) {
                rules.push(
                    this.pointType === 'arrival'
                        ? this.requiredRule('transfers.departure_to_city', 'POINT_TRAIN_CITY')
                        : this.requiredRule('transfers.arriving_from_city', 'POINT_TRAIN_CITY')
                )
            }
            return rules
        }

        get trainNumber() {
            return this.getProp('trainNumber')
        }

        set trainNumber(val) {
            this.setProp('trainNumber', val)
        }

        get date() {
            const dateTime = this.getProp('dateTime')
            if (!dateTime) return dateTime
            return dateTime.split('T')[0]
        }

        set date(val) {
            const dateTime = this.splitDateTime()
            dateTime[0] = val
            this.setProp('dateTime', dateTime.join('T'))
        }

        get time() {
            const dateTime = this.getProp('dateTime')
            if (!dateTime) return dateTime
            return dateTime.split('T')[1]
        }

        set time(val) {
            const dateTime = this.splitDateTime()
            dateTime[1] = val
            this.setProp('dateTime', dateTime.join('T'))
        }

        get cityId() {
            return this.getProp('cityId')
        }

        set cityId(val) {
            this.setProp('cityId', val)
        }

        get minDate() {
            if (this.pointType === 'arrival') {
                return this.searchRequest.dateTime
            } else {
                return this.$dateFns.format(
                    this.$dateFns.subDays(this.$dateFns.parseISO(this.searchRequest.dateTime), 1)
                )
            }
        }

        get maxDate() {
            if (this.returnTransferOffer) {
                return this.$dateFns.format(
                    this.$dateFns.addDays(this.$dateFns.parseISO(this.returnTransferOffer.date), 1)
                )
            }
            if (this.pointType === 'arrival') {
                return this.$dateFns.format(
                    this.$dateFns.addDays(this.$dateFns.parseISO(this.searchRequest.dateTime), 1)
                )
            } else {
                return this.searchRequest.dateTime
            }
        }
    }
</script>
