<template>
    <span>
        <template v-if="convertPrice">
            {{ price | price }}
        </template>
        <template v-else>
            {{ price.amount | priceFormat(price.currency) }}
        </template>
    </span>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class PriceLabel extends Vue {
        @Prop({required: true}) price
        @Prop({default: false, type: Boolean}) convertPrice
    }
</script>
