<template>
    <div>
        <v-dialog v-model="serviceTipsDialog" width="1200">
            <order-details-tips :tips="serviceTips" :loaded="true" show-close @close="serviceTipsDialog = false" />
        </v-dialog>
        <delete-service-dialog
            v-model="isShowDeleteServiceDialog"
            :process-id="service.processId"
            :service-name="service.serviceName"
            @update-order="$emit('modifyService', $event)"
        />
        <v-card outlined class="mb-2 service-card">
            <v-alert
                color="grey lighten-4 service-card-header"
                class="black--text mb-0"
                @click="isMinimized = !isMinimized"
            >
                <div class="mb-2 d-md-flex align-md-center">
                    <div
                        :class="{
                            'mb-1': isMinimized === true,
                            'full-width': isMobile,
                        }"
                        class="d-flex"
                    >
                        <div v-if="!isMobile" class="mt-1">
                            <slot name="checkbox" />
                        </div>
                        <template v-if="service.serviceType === 'EXTRASERVICE'">
                            <v-icon color="primary" class="mr-2 mt-1 align-self-start">
                                {{ 'mdi-cloud-off-outline' }}
                            </v-icon>
                            <v-icon color="primary" class="mr-2 align-self-start" :class="{'mt-1 ': !isMobile}">
                                {{ extraServiceTypeIcon }}
                            </v-icon>
                        </template>
                        <v-icon v-else color="primary" class="mr-2 mt-1 align-self-start">
                            {{ serviceTypeIcon }}
                        </v-icon>
                        <div class="d-flex" :class="{'flex-column me-1': isMobile}">
                            <span class="service-name mr-1 text-h6 font-weight-bold" v-text="serviceName" />
                            <hotel-stars
                                v-if="service.serviceDetails[0].category || service.serviceDetails[0].ratings"
                                :hotel="service.serviceDetails[0]"
                                :class="{'mt-1': !isMobile}"
                                :style="{
                                    'margin-bottom': isMobile ? '3px' : null,
                                    'margin-left': isMobile ? '-2px' : null,
                                }"
                            />
                        </div>
                        <div v-if="isMobile" class="ms-auto mt-1">
                            <slot name="checkbox" />
                        </div>
                    </div>
                    <div class="mt-1 mt-md-0 mb-4 mb-md-0 ml-6 ml-md-0 d-flex">
                        <v-chip v-if="serviceType && serviceType !== 'UNKNOWN'" label x-small class="mx-2">
                            {{ $t(`filters_${service.serviceType.toLowerCase()}Type.${serviceType}`) }}
                        </v-chip>
                        <v-icon class="info-icon me-2" right small color="black" @click.stop="serviceInfo">
                            mdi-information-outline
                        </v-icon>
                        <service-tips-icon
                            v-if="serviceTips && serviceTips.length"
                            class="info-icon cursor-pointer me-2"
                            @click.native.stop="serviceTipsDialog = true"
                        />
                        <v-icon
                            right
                            small
                            :title="$tc('internal_note', 2)"
                            color="black"
                            class="info-icon cursor-pointer me-2"
                            @click.stop="showServiceNotes"
                        >
                            mdi-note-plus-outline
                        </v-icon>
                        <v-tooltip v-if="isAddOn" top>
                            <template v-slot:activator="{on}">
                                <v-icon small color="primary" v-on="on">mdi-puzzle</v-icon>
                            </template>
                            {{
                                $t('add_on_to_service', {
                                    service: addOnBaseService
                                        ? addOnBaseService.serviceName
                                        : $t('error_message.not_found').toLocaleLowerCase(),
                                })
                            }}
                        </v-tooltip>
                    </div>
                    <div
                        class="ml-md-auto d-flex flex-md-row-reverse flex-wrap align-center"
                        :class="[{'justify-end': !isMobile}]"
                    >
                        <div class="mb-md-0 d-flex flex-column justify-md-center">
                            <div
                                class="d-flex flex-row align-center"
                                :class="[{'mb-1': !!lastTicketingDate}, {'ml-auto': !$breakpoint.smAndDown}]"
                            >
                                <v-btn
                                    v-if="service.status.toUpperCase() === 'QUOTE' && isTO1"
                                    icon
                                    :style="isMobile ? 'margin-left: -8px' : ''"
                                    class="me-1"
                                    @click.stop="deleteService"
                                >
                                    <v-icon> mdi-trash-can-outline</v-icon>
                                </v-btn>
                                <v-icon
                                    v-if="service.status.toUpperCase() === 'CONFIRMED'"
                                    :class="statusColor"
                                    class="mr-1"
                                >
                                    mdi-checkbox-marked-circle
                                </v-icon>
                                <v-icon v-else class="mr-1" :class="statusColor">mdi-checkbox-blank-circle</v-icon>
                                <span
                                    class="ml-1 font-weight-bold status-text"
                                    :class="[{'fs-16': $breakpoint.smAndDown}, {'fs-14': !$breakpoint.smAndDown}]"
                                    v-text="$t(`bookingStatus.${status}`)"
                                />
                                <v-icon
                                    color="primary"
                                    icon
                                    :class="['mx-2 align-self-end-sm', {'chevron-down': isMinimized}]"
                                >
                                    {{ isMinimized ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                                </v-icon>
                            </div>
                            <small v-if="lastTicketingDate" class="d-block mt-1 ml-1">
                                {{ $t('flights.last_ticketing_date') }}:
                                <strong>{{ lastTicketingDate | dateTimeFormat }}</strong>
                            </small>
                        </div>
                        <div v-if="isShowBookingMethod && service.bookingMethod" class="me-md-4">
                            <v-chip :color="bookingMethodColor" text-color="white" small>
                                <span>
                                    <span v-if="service.bookingMethod !== 'MANUALLY_AMENDED'">
                                        {{ $t('booked_from_label') }}
                                    </span>
                                    <span>
                                        {{ $t(`booking_methods.${service.bookingMethod}`) }}
                                    </span>
                                </span>
                            </v-chip>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center flex-column">
                    <v-expand-transition>
                        <div v-if="isMinimized === true" class="d-flex">
                            <div :style="{width: isMobile ? '36px' : '40px'}" />
                            <div>
                                <div class="d-flex date mb-2 text-caption">
                                    <span v-text="dateString" />
                                </div>
                                <div
                                    class="d-flex flex-row text-caption font-weight-bold grey--text text--darken-2"
                                    style="min-height: 1.25rem"
                                >
                                    <span class="travelers" v-text="travelersString" />
                                </div>
                            </div>
                        </div>
                    </v-expand-transition>
                </div>
            </v-alert>
            <v-expand-transition>
                <div v-show="!isMinimized">
                    <v-card-text>
                        <slot name="orderInfo" />
                        <v-divider class="mt-3" />
                        <slot name="serviceInfo" />
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import OrderDetailsTips from '@/components/account/OrderDetailsTips'
    import ServiceTipsIcon from '~src/components/account/orderServices/serviceTipsIcon.src'
    import {EventBus, SHOW_SERVICE_NOTES_MODAL} from '@/utils/event-bus'
    import DeleteServiceDialog from '@/components/account/orderServices/DeleteServiceDialog'

    @Component({
        components: {DeleteServiceDialog, ServiceTipsIcon, OrderDetailsTips, HotelStars},
    })
    export default class ServiceCard extends Vue {
        @Prop() icon
        @Prop() service
        @Prop() isFirst
        @Prop() extraServiceName
        @Prop() serviceTips
        @Prop({required: true}) order

        isShowDeleteServiceDialog = false
        isMinimized = false
        serviceTipsDialog = false

        created() {
            if (this.isFirst === false) {
                this.isMinimized = true
            }
        }

        serviceInfo() {
            this.$emit('showInfo')
        }

        showServiceNotes() {
            EventBus.$emit(SHOW_SERVICE_NOTES_MODAL, this.service)
        }

        deleteService() {
            this.isShowDeleteServiceDialog = true
        }

        get isShowBookingMethod() {
            return this.isTO1 && this.isOwnProduct
        }

        get bookingMethodColor() {
            switch (this.service.bookingMethod) {
                case 'MANUALLY_AMENDED':
                    return 'primary'
                case 'ALLOTMENT':
                    return 'success'
                case 'ON_REQUEST':
                    return 'warning'
                default:
                    return 'error'
            }
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service.supplierCode)
        }

        get serviceType() {
            return this.service.serviceDetails[0].type || this.service.serviceDetails[0].transferType
        }

        get statusColor() {
            return runtimeStore.orderServiceStatusColor(this.status)
        }

        get status() {
            return runtimeStore.orderServiceStatus(this.service.status)
        }

        get lastTicketingDate() {
            return this.service.serviceDetails[0].lastTicketingDate
        }

        get serviceTypeIcon() {
            return getServiceTypeIcon(this.service.serviceType)
        }

        get extraServiceTypeIcon() {
            return getServiceTypeIcon(this.service.serviceDetails[0]?.extraServiceProductType)
        }

        get serviceName() {
            return (
                this.service.serviceDetails[0].flightName ||
                this.service.serviceDetails[0].transferName ||
                this.service.serviceName
            )
        }

        get dateString() {
            const formattedDateStart = this.$options.filters.dateFormat(this.service.startDateTime)
            const formattedDateEnd = this.$options.filters.dateFormat(this.service.endDateTime)

            return (
                formattedDateStart + (formattedDateEnd === formattedDateStart ? '' : `\u00A0-\u00A0${formattedDateEnd}`)
            )
        }

        get travelersString() {
            if (this.service.travelers.length > 2) {
                let tourLead =
                    this.service.travelers.find(t => {
                        t.isTourLead
                    }) || this.service.travelers[0]
                return `${tourLead.name[0].firstName} ${tourLead.name[0].lastName || ''} + ${
                    this.service.travelers.length - 1
                }`
            } else {
                return this.service.travelers
                    .filter(t => t.name[0].firstName || t.name[0].lastName)
                    .map(e => {
                        return `${e.name[0].firstName || ''} ${e.name[0].lastName || ''}`
                    })
                    .join(', ')
            }
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isAddOn() {
            return !!this.service.addOnServiceId && this.service.serviceType !== this.addOnBaseService?.serviceType
        }

        get addOnBaseService() {
            return this.order.services.find(service => service.serviceId === this.service.addOnServiceId)
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
