<template>
    <div class="conditions-label">
        <a :class="color" @click.prevent="showConditions">
            <v-icon small>mdi-information-outline</v-icon>
            {{ label }}
        </a>
    </div>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_CONDITIONS_MODAL_EVENT} from '@/utils/event-bus'

    @Component
    export default class CancelPenaltyLabel extends ConditionsLabel {
        @Prop({default: () => []}) cancelPenalty
        @Prop({default: false}) xSmall
        @Prop({default: true}) small

        async load() {}

        showConditions() {
            EventBus.$emit(SHOW_CONDITIONS_MODAL_EVENT, null, this.conditionsInfo)
        }

        get isLoading() {
            return false
        }

        get conditionsInfo() {
            //Map for compatibility with ConditionsModal and ConditionsLabel
            return {
                cancellationItems: this.cancelPenalty
                    .map(cancelPenaltyItem => ({
                        dateFrom: cancelPenaltyItem.startDateTime,
                        dateTo: cancelPenaltyItem.endDateTime,
                        description: cancelPenaltyItem.description,
                        price: {
                            currency: cancelPenaltyItem.currency,
                            amount: cancelPenaltyItem.amount,
                        },
                        noShow: cancelPenaltyItem.noShow,
                    }))
                    .sort((a, b) =>
                        this.$dateFns.compareAsc(this.$dateFns.parseISO(a.dateFrom), this.$dateFns.parseISO(b.dateFrom))
                    ),
            }
        }
    }
</script>

<style scoped>
    small {
        min-height: 44px;
        max-width: 120px;
    }
    .conditions-label {
        line-height: 1;
        max-width: 150px;
    }
</style>
