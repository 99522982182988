<template>
    <div>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row>
            <v-col :md="3">
                <daterange-m
                    class="mt-2"
                    outlined
                    dense
                    :start-date.sync="infoByServiceType.accommodation.startDate"
                    :end-date.sync="infoByServiceType.accommodation.endDate"
                />
            </v-col>
        </v-row>
        <modify-manual-check-in-out
            v-if="info && isOwnProduct"
            v-model="infoByServiceType.accommodation"
            :hotel-info="info"
            :additional-options="additionalOptions"
        />
    </div>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import DaterangeM from '@/components/search/forms/DaterangeM'
    import ModifyManualCheckInOut from '@/components/account/modify/manual/serviceType/snippets/ModifyManualCheckInOut.vue'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component({
        components: {ModifyManualCheckInOut, DaterangeM},
    })
    export default class ModifyManualAccommodation extends Vue {
        @VModel() infoByServiceType
        @Prop() info
        @Prop() additionalOptions
        @Prop() service

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service?.supplierCode)
        }
    }
</script>
