<script>
    import {Vue, Component} from 'nuxt-property-decorator'

    @Component
    export default class LoadMixin extends Vue {
        async mounted() {
            await this.$store.restored
            this.load()
        }
    }
</script>
