<template>
    <v-navigation-drawer v-model="drawer" dark right style="z-index: 200" app>
        <v-list nav dense class="mt-5 pt-5 mb-2">
            <template v-if="!['authorization', 'b2b-auth', 'restore-password'].includes($route.name)">
                <v-list-item>
                    <account-menu @auth="drawer = false" />
                </v-list-item>
                <v-list-item class="pa-0">
                    <currency-switcher class="mx-0 px-0" />
                </v-list-item>
            </template>
            <v-list-item class="pa-0">
                <language-switcher class="mx-0 px-0" />
            </v-list-item>
        </v-list>
        <navigation-drawer-mobile-menu
            v-if="!['authorization', 'b2b-auth', 'restore-password'].includes($route.name)"
        />
        <contacts-block class="pa-4" />
    </v-navigation-drawer>
</template>

<script>
    import {Component, Model, Vue, Watch} from 'nuxt-property-decorator'
    import AccountMenu from '~src/components/account/accountMenu.src'
    import CurrencySwitcher from '~/components/CurrencySwitcher'
    import LanguageSwitcher from '~/components/LanguageSwitcher'
    import NavigationDrawerMobileMenu from '@/components/snippets/NavigationDrawerMobileMenu'
    import ContactsBlock from '@/components/snippets/ContactsBlock'

    @Component({
        components: {
            ContactsBlock,
            NavigationDrawerMobileMenu,
            AccountMenu,
            LanguageSwitcher,
            CurrencySwitcher,
        },
    })
    export default class NavigationDrawerMobile extends Vue {
        @Model('change') value

        drawer = false

        @Watch('drawer')
        onChangeDrawer(val) {
            this.$emit('change', val)
        }

        @Watch('value')
        onChangeValue(val) {
            this.drawer = val
        }
    }
</script>
