<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="searchResponse.product.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <transfer-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <transfer-search-summary :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <transfer-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.product.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <transfer-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <transfer-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <transfer-map :_expanded.sync="mapExpanded" :points="mapPoints" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '@/components/search/Pagination'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import TransferOffers from '@/components/transfers/search/offers/TransferOffers'
    import TransferFilters from '@/components/transfers/search/TransferFilters'
    import TransferSearchSummary from '@/components/transfers/search/TransferSearchSummary'
    import TransferMap from '@/components/transfers/search/TransferMap'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {transfersRuntimeStore} from '@/store'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import SortSelect from '@/components/search/SortSelect'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import Tmp_SearchPageBase from '@/mixins/Tmp_SearchPageBase'
    import SearchLayout from '~src/components/parts/searchLayout.src'

    @Component({
        components: {
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            TransferOffers,
            Pagination,
            MobileSearchPageTabs,
            TransferBreadcrumbs,
            TransferFilters,
            TransferSearchSummary,
            TransferMap,
            TransferInfoModal,
            SearchLayout,
        },
        layout: 'blank',
    })
    export default class TransfersPage extends mixins(
        SearchPageBase,
        Tmp_SearchPageBase,
        TransferPageMixin,
        TransferStoreMixin,
        SearchPageMixin
    ) {
        async fetch() {
            await this.loadRuntimeData()
        }

        get mapPoints() {
            return [transfersRuntimeStore.departurePoint, transfersRuntimeStore.arrivalPoint]
        }
    }
</script>
