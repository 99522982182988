<template>
    <div>
        <transition name="fade" mode="out-in">
            <div v-if="!paymentMethods || !paymentMethods.length">
                <v-row v-if="isShowTypeSwitcher" class="my-2">
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="invoiceType"
                            :items="invoiceTypes"
                            hide-details
                            solo
                            @change="loadManualInvoices"
                        />
                    </v-col>
                </v-row>
                <v-expansion-panels flat tile dense>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="pl-1 width-auto primary--text text-capitalize">
                            {{ $t('filters') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="py-4">
                                <v-col md="8" cols="12" class="filters-form">
                                    <v-row>
                                        <v-col md="6" cols="12">
                                            <order-service-daterange
                                                :_start-date.sync="filters.dueDateFrom"
                                                :_end-date.sync="filters.dueDateTo"
                                                :start-date-label="$t('due_date_from')"
                                                :end-date-label="$t('due_date_to')"
                                                :outlined="false"
                                                :single-line="false"
                                                :no-title="true"
                                                clearable
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="filters.orderId"
                                                :label="$t('order_number')"
                                                dense
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <private-client-and-companies-autocomplete
                                                :show-icon="false"
                                                :label="$t('client_name')"
                                                :relationship="['CLIENT']"
                                                dense
                                                @change="onClientNameFilterChange"
                                            />
                                        </v-col>
                                        <v-col md="6" cols="12">
                                            <order-service-daterange
                                                :_start-date.sync="filters.payDateFrom"
                                                :_end-date.sync="filters.payDateTo"
                                                :start-date-label="$t('pay_date_from')"
                                                :end-date-label="$t('pay_date_to')"
                                                :outlined="false"
                                                :single-line="false"
                                                :no-title="true"
                                                clearable
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select
                                                v-model="filters.paymentStatus"
                                                :items="paymentStatuses"
                                                :label="$t('status')"
                                                clearable
                                                :item-text="'text'"
                                                :item-value="'value'"
                                                dense
                                                hide-details
                                                multiple
                                            />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <supplier-autocomplete
                                                :show-icon="false"
                                                :label="$t('supplier_name')"
                                                dense
                                                @change="onSupplierNameFilterChange"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer />
                                <v-col md="2" cols="12">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn
                                                block
                                                :color="isSomeFiltersSelected ? 'primary' : 'white'"
                                                small
                                                @click="loadManualInvoices"
                                            >
                                                {{ $t('filter') }}
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn color="white" block small @click="resetFilters">
                                                {{ $t('reset') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <span v-if="manualInvoicesCount" class="caption text--secondary ms-1">
                    {{ `${$t('invoices.invoices')}: ${manualInvoicesCount}` }}
                </span>
                <v-data-table
                    :server-items-length="manualInvoicesCount"
                    :items="manualInvoices"
                    :headers="headers"
                    :hide-default-footer="isManualInvoicesLoading"
                    :loading="isManualInvoicesLoading"
                    disable-sort
                    :footer-props="{'items-per-page-options': [10, 15, 20, manualInvoicesCount]}"
                    :options.sync="options"
                >
                    <template v-slot:header.dueDate="{header}">
                        <th role="columnheader" scope="col" aria-label="Amount" class="text-center">
                            <div class="d-flex align-center">
                                <v-checkbox
                                    v-model="selectAll"
                                    :ripple="false"
                                    :value="false"
                                    dense
                                    class="mt-n1"
                                    hide-details
                                    @change="changeSelectAll"
                                />
                                <span>{{ header.text }}</span>
                            </div>
                        </th>
                    </template>
                    <template v-slot:item.dueDate="{item}">
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="selectedInvoices"
                                :disabled="item.paymentStatus !== 'BILL'"
                                :ripple="false"
                                :value="item"
                                dense
                                hide-details
                                class="mt-n1"
                                @click.stop
                            />
                            <template v-if="item !== editDueDateItem">
                                <div v-if="item.dueDate" class="text-no-wrap">
                                    {{ item.dueDate | dateShortFormat }}
                                </div>
                                <v-btn
                                    v-if="item.dueDate && isTO1"
                                    right
                                    small
                                    icon
                                    color="primary"
                                    class="ms-1"
                                    @click="editDueDate(item)"
                                >
                                    <v-icon small> mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <template v-else>
                                <date-picker
                                    v-if="item === editDueDateItem"
                                    v-model="newDueDate"
                                    outlined
                                    dense
                                    :min="$dateFns.format(new Date())"
                                    hide-details
                                />
                                <v-btn
                                    :disabled="!newDueDate"
                                    right
                                    small
                                    icon
                                    color="success"
                                    class="ms-1"
                                    @click="saveNewDueDate"
                                >
                                    <v-icon small> mdi-check-circle-outline</v-icon>
                                </v-btn>
                            </template>
                        </div>
                    </template>
                    <template v-slot:item.paymentStatus="{item}">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-center">
                                <span :class="getStatusColor(item)">
                                    {{ $t(`paymentStatus.${item.paymentStatus}`) }}
                                </span>
                                <v-tooltip v-if="item.remark || item.bankTransferDetails" top>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                            icon
                                            small
                                            v-on="item.remark ? on : null"
                                            @click="
                                                showedBankTransferInvoiceId === item.invoiceId
                                                    ? (showedBankTransferInvoiceId = null)
                                                    : (showedBankTransferInvoiceId = item.invoiceId)
                                            "
                                        >
                                            <v-icon small>mdi-information-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ item.remark }}</span>
                                </v-tooltip>
                            </div>
                            <v-expand-transition>
                                <div v-if="showedBankTransferInvoiceId === item.invoiceId" class="d-flex flex-column">
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.account') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.account }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.bank') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.bank }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.branch') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.branch }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.iban') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.iban }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.receiving_bank') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.receivingBank }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.reference') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.reference }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.swift_code') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.bankTransferDetails.swiftCode }}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {{ $t('bank_transfer.value_date') + ': ' }}
                                        </span>
                                        <span v-if="item.bankTransferDetails.valueDate">
                                            {{ item.bankTransferDetails.valueDate | dateShortFormat }}
                                        </span>
                                    </div>
                                    <div v-if="item.remark">
                                        <span>
                                            {{ $t('remark') + ': ' }}
                                        </span>
                                        <span>
                                            {{ item.remark }}
                                        </span>
                                    </div>
                                </div>
                            </v-expand-transition>
                        </div>
                    </template>
                    <template v-slot:item.payDate="{item}">
                        <div v-if="item.payDate" class="text-no-wrap">
                            {{ item.payDate | dateShortFormat }}
                        </div>
                    </template>
                    <template v-slot:item.billDate="{item}">
                        <div v-if="item.billDate" class="text-no-wrap">
                            {{ item.billDate | dateShortFormat }}
                        </div>
                    </template>
                    <template v-slot:item.requestReference="{item}">
                        <nuxt-link
                            no-prefetch
                            :to="{name: 'order-details', params: {id: +item.requestReference}}"
                            @click.native.stop
                        >
                            <div
                                v-if="item.requestReference"
                                class="text-no-wrap font-weight-medium"
                                v-text="+item.requestReference"
                            />
                        </nuxt-link>
                    </template>
                    <template v-slot:item.amount="{item}">
                        <span v-if="item.originalPrices && item.originalPrices[0]">
                            {{
                                {
                                    amount: item.originalPrices[0].originalAmount,
                                    currency: item.originalPrices[0].originalAmountCurrency,
                                } | formatPrice
                            }}
                        </span>
                    </template>
                    <template v-slot:item.amountToPay="{item}">
                        <span v-if="item.originalPrices && item.originalPrices[0]">
                            {{
                                {
                                    amount: item.paymentStatus === 'BILL' ? item.originalPrices[0].originalAmount : 0,
                                    currency: item.originalPrices[0].originalAmountCurrency,
                                } | formatPrice
                            }}
                        </span>
                    </template>
                    <template v-slot:item.pay="{item}">
                        <v-btn
                            v-if="item.paymentStatus === 'BILL'"
                            color="primary"
                            small
                            elevation="0"
                            :disabled="isLoading"
                            @click="initPay(item)"
                        >
                            <v-icon left small> mdi-plus-circle-multiple-outline</v-icon>
                            <span class="caption">
                                {{ $t('pay') }}
                            </span>
                        </v-btn>
                    </template>
                    <template v-slot:item.cancel="{item}">
                        <v-btn
                            v-if="item.dueDate && item.paymentStatus !== 'CANCELED'"
                            small
                            elevation="0"
                            :disabled="isLoading"
                            color="error"
                            @click="cancelInvoice(item)"
                        >
                            <span class="caption">
                                {{ $t('cancel') }}
                            </span>
                        </v-btn>
                    </template>
                </v-data-table>
                <v-row>
                    <v-col cols="12">
                        <v-btn
                            color="primary"
                            elevation="0"
                            class="me-2"
                            :disabled="isLoading || !selectedInvoices.length || isSelectedInvoicesDifferentCurrencies"
                            @click="initPay"
                        >
                            <v-icon left small> mdi-plus-circle-multiple-outline </v-icon>
                            <span class="caption">
                                {{ $t('pay') }}
                            </span>
                        </v-btn>
                        <template v-if="isSelectedInvoicesDifferentCurrencies">
                            <span class="caption error--text">
                                {{ $t('invoices.select_same_currency') }}
                            </span>
                        </template>
                        <template v-else>
                            <span class="caption"> {{ $t('invoices.total_for_selected') }}: </span>
                            <span class="caption">
                                {{ totalPriceToPay.amount | priceFormat(totalPriceToPay.currency) }}
                            </span>
                        </template>
                    </v-col>
                </v-row>
            </div>
            <v-row v-else>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-btn fab class="me-3" small elevation="0" @click="paymentMethods = []">
                            <v-icon> mdi-arrow-left</v-icon>
                        </v-btn>
                        <span class="text-h6">
                            {{ $t('payment') }}
                        </span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <order-manual-invoice-payment-form
                        :payment-methods="paymentMethods"
                        :order-id="orderId"
                        :invoice-type="invoiceType"
                        :client-price-currencies="currencies"
                        multiple-currency-change
                        @complete-payment="completePayment"
                    />
                </v-col>
            </v-row>
        </transition>
    </div>
</template>

<script>
    import {Component, Emit, Prop, PropSync, Watch} from 'nuxt-property-decorator'
    import OrderManualInvoicePaymentForm from '@/components/account/OrderManualInvoicePaymentForm'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {matches} from '@/utils/helpers'
    import OrderPaymentSchedule from '~src/components/account/OrderPaymentSchedule'
    import {convertPrice} from '@/utils/filters'
    import OrderServiceDaterange from '@/components/account/filters/OrderServiceDaterange'
    import PrivateClientAutocomplete from '@/components/search/forms/PrivateClientAutocomplete'
    import SupplierAutocomplete from '@/components/search/forms/SupplierAutocomplete'
    import PrivateClientAndCompaniesAutocomplete from '@/components/search/forms/PrivateClientAndCompaniesAutocomplete'

    @Component({
        components: {
            PrivateClientAndCompaniesAutocomplete,
            SupplierAutocomplete,
            PrivateClientAutocomplete,
            OrderServiceDaterange,
            OrderManualInvoicePaymentForm,
            DatePicker,
        },
    })
    export default class PaymentsPageSchedule extends OrderPaymentSchedule {
        @PropSync('_isManualInvoicesLoading') isManualInvoicesLoading
        @Prop() objectId
        @Prop() cancelInvoicesOnLoad
        @Prop() clientPriceCurrencies

        paymentOptionsLoading = false
        newDateSaveLoading = false
        cancelInvoiceLoading = false

        paymentMethods = []
        manualInvoices = []
        issueInfoItems = []

        newDueDate = null
        editDueDateItem = null
        showedBankTransferInvoiceId = null

        invoiceType = 'CUSTOMER'

        silentPay = false

        tableOptions = {
            itemsPerPage: 10,
            page: 1,
        }
        selectAll = false
        selectedInvoices = []
        manualInvoicesCount = 0

        filters = {}

        @Watch('filters', {deep: true})
        onFiltersChange() {
            Object.keys(this.filters).forEach(key =>
                this.filters[key] === undefined || this.filters[key] === null || this.filters[key] === ''
                    ? delete this.filters[key]
                    : {}
            )
        }

        onSupplierNameFilterChange(val) {
            this.filters = {...this.filters, supplierName: val?.label}
        }

        onClientNameFilterChange(val) {
            this.filters = {...this.filters, customerName: val?.label}
        }

        get options() {
            return this.tableOptions
        }

        set options(options) {
            if (this.isLoading) return
            const prev = this.tableOptions
            if (!prev || matches(options, prev)) return
            this.tableOptions = options
            this.loadManualInvoices()
        }

        @Emit()
        async completePayment() {
            this.reset()
            this.$toast.success(this.$t('invoices.marked_paid_success_message'))
            await this.loadManualInvoices()
        }

        async mounted() {
            await this.loadManualInvoices()
        }

        @Emit()
        async loadManualInvoices() {
            this.manualInvoices = []
            this.selectAll = false
            this.changeSelectAll(false)

            this.isManualInvoicesLoading = true

            try {
                let objectId

                //TODO  temporary solution, backend edits required "objectId"
                if (this.filters.orderId) {
                    const order = (
                        await this.$api.orders.get({
                            orderType: authStore.orderType,
                            responseType: 'SHORT',
                            orderId: this.filters.orderId,
                        })
                    )?.orders?.[0]

                    objectId = order.objectId
                }

                const manualInvoicesResponse = await this.$api.manualInvoices.get({
                    companyId: authStore.person.companyId,
                    invoiceType: this.invoiceType,
                    limit: this.options.itemsPerPage,
                    offset: this.options.itemsPerPage * (this.options.page - 1),
                    ...this.filters,
                    ...(objectId && {orderId: objectId}),
                })

                this.manualInvoicesCount = manualInvoicesResponse.invoicesCount

                let manualInvoices = manualInvoicesResponse.invoices
                    .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
                    .sort(a => (a.paymentStatus === 'CANCELED' ? 1 : -1))
                    .sort(a => (a.paymentStatus === 'BILL' ? -1 : 1))

                if (this.cancelInvoicesOnLoad && !this.silentPay) {
                    await this.cancelInvoiceList(manualInvoices.filter(invoice => invoice.paymentStatus === 'BILL'))
                }

                manualInvoices = manualInvoices.filter(el =>
                    this.$config.account.paymentSchedulerAutoPaymentMode ? el.paymentStatus !== 'CANCELED' : true
                )

                this.manualInvoices = manualInvoices

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isManualInvoicesLoading = false
            }

            await this.$nextTick(() => {})

            return this.manualInvoices
        }

        async getPaymentOptions() {
            this.paymentOptionsLoading = true

            try {
                return (
                    await this.$api.manualInvoicePaymentMethods.get({
                        invoiceType: this.invoiceType,
                        invoiceId: this.selectedInvoices.map(i => i.invoiceId),
                    })
                ).invoicePaymentMethods

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.paymentOptionsLoading = false
            }
        }

        async initPay() {
            this.paymentMethods = await this.getPaymentOptions()

            return this.paymentMethods
        }

        async saveNewDueDate() {
            this.newDateSaveLoading = true

            try {
                await this.$api.manualInvoices.put(this.editDueDateItem.invoiceId, {
                    dueToPay: `${this.newDueDate}T23:59`,
                })

                this.editDueDateItem.dueDate = `${this.newDueDate} 23:59`
                this.editDueDateItem = null
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.newDateSaveLoading = false
            }
        }

        async editDueDate(item) {
            this.newDueDate = this.$dateFns.format(item.dueDate)
            this.editDueDateItem = item
        }

        reset() {
            this.paymentMethods = []
        }

        changeSelectAll(selectAll) {
            if (selectAll) {
                this.selectedInvoices = this.manualInvoices.filter(el => el.paymentStatus === 'BILL')
            } else {
                this.selectedInvoices = []
            }
        }

        resetFilters() {
            this.filters = {}
            this.loadManualInvoices()
        }

        get isSomeFiltersSelected() {
            return Object.keys(this.filters).length !== 0
        }

        get isLoading() {
            return (
                this.isManualInvoicesLoading ||
                this.paymentOptionsLoading ||
                this.newDateSaveLoading ||
                this.cancelInvoiceLoading
            )
        }

        get headers() {
            const headers = [
                {text: this.$t('invoices.due_date'), value: 'dueDate'},
                {text: this.$t('invoices.amount'), value: 'amount', align: 'center'},
                {text: this.$t('invoices.amount_to_pay'), value: 'amountToPay', align: 'center'},
                {text: this.$t('status'), value: 'paymentStatus'},
                {text: this.$t('order_number'), value: 'requestReference'},
                {text: this.$t('client_name'), value: 'customerName'},
                {text: this.$t('supplier_name'), value: 'supplierName'},
                {text: this.$t('invoices.bill_date'), align: 'left', value: 'billDate'},
            ]

            if (this.manualInvoices.find(m => !!m.payDate)) {
                headers.push({text: this.$t('invoices.pay_date'), value: 'payDate'})
            }

            if (this.isTO1 && this.manualInvoices.find(m => m.dueDate && m.paymentStatus !== 'CANCELED')) {
                headers.push({text: this.$t('cancel'), value: 'cancel', align: 'center'})
            }

            return headers
        }

        get totalPriceToPay() {
            return this.selectedInvoices.reduce(
                (totalPrice, invoice) => {
                    const invoicePrice = {
                        amount: invoice.amountToPay,
                        currency: invoice.amountToPayCurrency,
                    }
                    const priceConverted = convertPrice(
                        invoicePrice,
                        this.selectedInvoices[0]?.originalPrices[0].originalAmountCurrency
                    )

                    totalPrice.amount += priceConverted.amount

                    return totalPrice
                },
                {
                    amount: 0,
                    currency:
                        this.selectedInvoices[0]?.originalPrices[0].originalAmountCurrency || persistentStore.currency,
                }
            )
        }

        get paymentStatuses() {
            return [
                {
                    text: this.$t('paymentStatus.BILL'),
                    value: 'BILL',
                },
                {
                    text: this.$t('paymentStatus.PAID'),
                    value: 'PAID',
                },
                {
                    text: this.$t('paymentStatus.CANCELED'),
                    value: 'CANCELED',
                },
            ]
        }

        get isSelectedInvoicesDifferentCurrencies() {
            if (!this.selectedInvoices.length) {
                return false
            }

            const currency = this.selectedInvoices[0]?.originalPrices[0].originalAmountCurrency

            return !this.selectedInvoices.every(
                invoice => invoice?.originalPrices[0]?.originalAmountCurrency === currency
            )
        }

        get currencies() {
            return Object.values(this.$config.currency.available).filter(currency =>
                Object.keys(runtimeStore.exchangeRates).includes(currency)
            )
        }
    }
</script>

<style scoped lang="scss">
    .filters-form::v-deep {
        .v-expansion-panel-header {
            min-height: auto;
            padding: 0;
            margin: 0 0 10px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
        .v-expansion-panel-content__wrap {
            padding: 0;
        }

        .v-text-field .v-label,
        .v-radio .v-label,
        .v-input--switch .v-label,
        .v-text-field input,
        .v-select__selections {
            font-size: 12px;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
