<template>
    <div>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row class="mt-1">
            <v-col :md="3">
                <daterange-m
                    class="mt-2"
                    dense
                    outlined
                    :start-date.sync="infoByServiceType.train.startDateTime"
                    :end-date.sync="infoByServiceType.train.endDateTime"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'

    @Component({
        components: {DatePicker},
    })
    export default class ModifyManualTrain extends Vue {
        @VModel() infoByServiceType
    }
</script>
