<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    @Component
    export default class FillServiceBookingDataMixin extends Vue {
        bookingFieldsLoading = false

        async loadTouristBookingFields() {
            this.bookingFieldsLoading = true
            try {
                await runtimeStore.loadTouristBookingFields({processId: this.service.processId})

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.bookingFieldsLoading = false
            }
        }

        async loadProductBookingFields() {
            this.bookingFieldsLoading = true
            try {
                await runtimeStore.loadProductBookingFields({processId: this.service.processId})

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.bookingFieldsLoading = false
            }
        }

        setTourists() {
            const tourists = this.initTourists(this.searchRequest, clone(persistentStore.tourists))
            const travelers = this.service.travelers

            tourists.forEach((tourist, index) => {
                const traveler = travelers[index]
                for (const prop in tourist) {
                    if (prop === 'type') continue
                    if (prop === 'passport') {
                        if (traveler.passports?.length) {
                            const {expiryDate, number} = traveler.passports[0]
                            Object.assign(tourist[prop], {
                                ...(expiryDate && {expiryDate}),
                                ...(number && {number}),
                            })
                        }
                        continue
                    }
                    if (prop === 'birthdate') {
                        tourist[prop] = traveler.dateOfBirth || tourist[prop]
                        continue
                    }
                    if (prop === 'firstName') {
                        tourist[prop] = traveler.name[0][prop] || tourist[prop]
                        continue
                    }
                    if (prop === 'lastName') {
                        tourist[prop] = traveler.name[0][prop] || tourist[prop]
                        continue
                    }
                    if (travelers[index] && travelers[index][prop]) {
                        tourist[prop] = travelers[index][prop]
                    }
                }
            })

            persistentStore.SET_TOURISTS(tourists)
        }
    }
</script>
