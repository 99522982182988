<template>
    <div v-if="operatingAirlines.length">
        <div class="text-caption mr-1">{{ $t('flights.operated_by') }}</div>
        <div>
            {{ operatingAirlines.map(({name}) => name).join(', ') }}
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {flightsStore} from '@/utils/store-accessor'

    @Component
    export default class FlightOperatingAirlines extends Vue {
        @Prop({required: true, type: Object}) flight

        get operatingAirlines() {
            if (!flightsStore.airlines.length) return []
            const operatingAirlines = []
            this.flight.itinerary.forEach(({segments}) => {
                segments.forEach(({operatingAirline, marketingAirline}) => {
                    if (
                        operatingAirline !== marketingAirline &&
                        !operatingAirlines.find(({code}) => code === operatingAirline)
                    ) {
                        const airline = flightsStore.airlines.find(airline => airline.codes.IATA === operatingAirline)
                        if (airline) {
                            operatingAirlines.push({
                                code: operatingAirline,
                                name: airline.name[this.$i18n.locale] || airline.name.en,
                            })
                        }
                    }
                })
            })
            return operatingAirlines
        }
    }
</script>
