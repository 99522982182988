<template>
    <extra-service-booking-page-layout v-if="loaded" :search-request="searchRequest" :offer="offer">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <extra-service-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:orderCommentsForm="{bookingKey}">
            <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
        </template>
        <template v-slot:sidebar="{bookingKey, prepareBookResponse, extraServices}">
            <extra-service-order-summary
                :product="product"
                :offer="offer"
                :info="basketItem.info"
                :prebooked="!!bookingKey"
                :prepare-book-response="prepareBookResponse"
                :extra-services="extraServices"
                :additional-options="productStore.bookingAdditionalOptions"
            >
                <template v-slot:summary>
                    <v-card flat tile class="my-2 pa-0">
                        <v-card-text class="pa-0">
                            <search-summary-content :search-request="searchRequest" show-date />
                        </v-card-text>
                    </v-card>
                </template>
            </extra-service-order-summary>
            <extra-service-info-modal />
        </template>
    </extra-service-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'
    import ExtraServiceOrderSummary from '~src/components/extraServices/booking/ExtraServiceOrderSummary'
    import {extraServicesStore, runtimeStore} from '@/utils/store-accessor'
    import ExtraServiceBookingPageLayout from '~src/components/extraServices/booking/ExtraServiceBookingPageLayout'
    import ExtraServiceStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import ExpiredOffersSnackbar from '~src/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import ExtraServiceInfoModal from '@/components/extraServices/info/ExtraServiceInfoModal'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import CarOrderSummary from '@/components/carsRent/booking/CarOrderSummary.vue'

    @Component({
        components: {
            CarOrderSummary,
            ExtraServiceInfoModal,
            ExpiredOffersSnackbar,
            ExtraServiceBreadcrumbs,
            ExtraServiceOrderSummary,
            ExtraServiceBookingPageLayout,
            BookingCommentsForm,
            SearchSummaryContent,
        },
        layout: 'blank',
    })
    export default class ExtraServiceBookingPage extends mixins(ExtraServiceStoreMixin, ExtraServicePageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            //await runtimeStore.loadTouristBookingFields({offerKey: this.$route.query.offerKey})
            runtimeStore.SET_DEFAULT_TOURIST_BOOKING_FIELDS()
            extraServicesStore.clearBookingAdditionalOptions()
            await extraServicesStore.getExtraServiceAdditionalOptions(this.$route.query.offerKey)
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !this.productStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        get basketItem() {
            return this.productStore.basket.find(item => item.offerKey === this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }
    }
</script>
