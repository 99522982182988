<template>
    <order-summary-layout :product-store="productStore" :total-price="totalPrice">
        <template v-slot>
            <div class="d-flex mb-3">
                <v-img
                    :src="imageSrc | imageUrl(600)"
                    :lazy-src="'/placeholder.png' | srcHost"
                    max-width="150"
                    tile
                    @error="imgError = true"
                >
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && imageSrc" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                    <div class="font-weight-bold text-truncate">
                        {{ info.name }}
                    </div>
                    <supplier-name-label v-if="isTO1" :supplier-name="offer.info.supplierName" />
                    <tour-general-info :info="offer.info" />
                </div>
            </div>
            <div v-text="info.shortDescription" />
            <v-divider class="my-2" />
            <v-card flat tile class="pa-0">
                <v-card-text class="pa-0">
                    <search-summary-content :search-request="searchRequest" tourists-label="guest" show-tourists />
                </v-card-text>
            </v-card>
            <template v-if="additionalServices.length">
                <v-divider class="my-3" />
                <div class="mb-2 text-h6">
                    {{ $t('tours.additional_services') }}
                </div>
                <div v-for="service in additionalServices" :key="service.selectedOfferKey">
                    {{ service.name }}
                </div>
            </template>
        </template>
    </order-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, toursRuntimeStore, toursStore} from '~/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import TourGeneralInfo from '@/components/tours/snippets/TourGeneralInfo'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            OrderSummaryLayout,
            TourGeneralInfo,
            ConditionsModal,
            ConditionsLabel,
            SearchSummaryContent,
            SupplierNameLabel,
        },
    })
    export default class TourOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true}) prebooked
        @Prop({default: true}) searchRequest

        imgError = false

        get productStore() {
            return toursStore
        }

        get additionalServices() {
            return this.offer.entries.reduce((additionalServices, entry) => {
                if (!entry.obligatory && entry.selectedOfferKey) additionalServices.push(entry)

                return additionalServices
            }, [])
        }

        get info() {
            return toursRuntimeStore.packageTourInfo
        }

        get imageSrc() {
            const images = this.info.images || []
            if (!images.length) return null
            const image = images.find(image => image.mainImage)
            return image ? image.url : images[0].url
        }

        get totalPrice() {
            return toursRuntimeStore.totalPrice(this.offer)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
