<template>
    <div>
        <slot name="mobileTabs" :tabs="mobileTabs">
            <portal v-if="isMobile" to="under-header">
                <mobile-search-page-tabs ref="mobileTabs" v-model="mobileTabs" />
            </portal>
        </slot>
        <v-container fluid>
            <slot v-if="!isMobile" name="breadcrumbs">
                <breadcrumbs />
            </slot>
            <v-row>
                <v-col cols="12" :xl="!mapExpanded ? 8 : 2" :md="!mapExpanded ? 9 : 3">
                    <v-row class="pt-0">
                        <slot v-if="!isMobile || (isMobile && mobileTabs === 3)" name="preinfo" />

                        <v-col
                            v-show="!isMobile || (isMobile && mobileTabs === 0)"
                            :md="!mapExpanded ? 3 : 12"
                            :xl="!mapExpanded ? 3 : 12"
                        >
                            <slot name="left-sidebar" />
                        </v-col>
                        <v-col v-show="(!mapExpanded && !isMobile) || (isMobile && mobileTabs === 1)" :md="9" xl="9">
                            <slot name="main-content" />
                            <no-offers-label
                                v-if="offersCount === 0 && !searchActive && $config.account.newReservationRequest"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 2)">
                    <slot name="right-sidebar" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '~/components/snippets/MobileSearchPageTabs'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus, FULL_SCREEN_MODE, SHOW_ON_MAP_EVENT} from '~/utils/event-bus'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import NoOffersLabel from '@/components/snippets/NoOffersLabel'

    @Component({
        components: {
            NoOffersLabel,
            MobileSearchPageTabs,
            Breadcrumbs,
        },
    })
    export default class SearchLayout extends Vue {
        @Prop({default: false}) mapExpanded
        @Prop() offersCount
        @Prop({type: Boolean, default: false}) searchActive
        @Prop({default: null}) tabs

        mobileTabs = 1

        @Watch('tabs')
        onTabsChange(val) {
            this.mobileTabs = val
        }

        @Watch('mobileTabs')
        onMobileTabsChange(tab, prevTab) {
            if (tab === 2) {
                EventBus.$emit(FULL_SCREEN_MODE, true)
            } else if (prevTab === 2) {
                EventBus.$emit(FULL_SCREEN_MODE, false)
            }
        }

        created() {
            EventBus.$on(CHANGE_TAB_MOBILE_EVENT, this.setMobileTab)
            if (this.isMobile) {
                EventBus.$on(SHOW_ON_MAP_EVENT, this.showMapTab)
            }
        }

        beforeDestroy() {
            EventBus.$emit(FULL_SCREEN_MODE, false)
            EventBus.$off(CHANGE_TAB_MOBILE_EVENT, this.setMobileTab)
            if (this.isMobile) {
                EventBus.$off(SHOW_ON_MAP_EVENT, this.showMapTab)
            }
        }

        setMobileTab(tab) {
            this.mobileTabs = tab
        }

        showMapTab() {
            this.mobileTabs = 2
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
