<template>
    <v-card class="elevation-0" tile>
        <v-card-title>
            <v-icon left color="primary">mdi-comment-text</v-icon>
            {{ $t('comment') }}
        </v-card-title>
        <v-card-text>
            <div class="text-subtitle-2 accent--text text-justify">
                {{ $t('notes.other_comment_note') }}
            </div>
            <v-form ref="form" v-model="valid" :disabled="disabled">
                <v-textarea v-model="comment" :placeholder="$t('type_here')" rows="1" />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, PropSync, Prop} from 'vue-property-decorator'

    @Component
    export default class BookingCommentsForm extends Vue {
        @Prop() disabled
        @Prop() productStore
        @PropSync('_valid', {default: false}) valid

        get comment() {
            return this.productStore.prepareBookRequest.comments
        }

        set comment(val) {
            this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'comments', val})
        }
    }
</script>
