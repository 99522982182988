<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {cruisesRuntimeStore} from '@/store'
    import Breadcrumbs from '@/components/Breadcrumbs'

    @Component
    export default class CruisesBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest
        @Prop({default: () => {}}) product

        get items() {
            const getSearchPageLabel = () =>
                cruisesRuntimeStore.departureCity?.name
                    ? `${cruisesRuntimeStore.departureCity?.name} - ${cruisesRuntimeStore.destinationCity.name}`
                    : ` ${cruisesRuntimeStore.destinationCity.name}`
            const addSearchPageLink = items => {
                    items.push({
                        text: getSearchPageLabel(),
                        to: cruisesRuntimeStore.searchPageLink(this.searchRequest),
                        nuxt: true,
                        exact: true,
                    })
                },
                addInfoPageLink = items => {
                    items.push({
                        text: this.product.name,
                        to: cruisesRuntimeStore.infoPageLink(this.product, this.searchRequest),
                        nuxt: true,
                        exact: true,
                    })
                }
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'cruises':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'cruise':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.name,
                        disabled: true,
                    })
                    break
                case 'cruiseBooking':
                    addSearchPageLink(items)
                    addInfoPageLink(items)
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'cruiseConfirmation':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.name,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
