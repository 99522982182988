<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="filteredOffers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <extra-service-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <extra-service-search-summary :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <extra-service-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="filteredOffers.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <extra-service-offers :offers="filteredOffers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <extra-service-conditions-modal-info />
                </template>
            </conditions-modal>
            <extra-service-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <extra-service-map :_expanded.sync="mapExpanded" :city="city" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '~src/components/snippets/MobileSearchPageTabs'
    import Pagination from '~src/components/search/Pagination'
    import ConditionsModal from '~src/components/modals/ConditionsModal'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'
    import ExtraServiceOffers from '~src/components/extraServices/search/offers/ExtraServiceOffers'
    import ExtraServiceFilters from '~src/components/extraServices/search/ExtraServiceFilters'
    import ExtraServiceSearchSummary from '~src/components/extraServices/search/ExtraServiceSearchSummary'
    import ExtraServiceMap from '@/components/extraServices/search/ExtraServiceMap'
    import ExtraServiceInfoModal from '~src/components/extraServices/info/ExtraServiceInfoModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import ExtraServicesStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import ExpiredOffersSnackbar from '~src/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '~src/components/search/SortSelect'
    import ExtraServiceConditionsModalInfo from '@/components/extraServices/snippets/ExtraServiceConditionsModalInfo'
    import Tmp_SearchPageBase from '@/mixins/Tmp_SearchPageBase'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'

    @Component({
        components: {
            ExtraServiceConditionsModalInfo,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            ExtraServiceOffers,
            Pagination,
            MobileSearchPageTabs,
            ExtraServiceBreadcrumbs,
            ExtraServiceFilters,
            ExtraServiceSearchSummary,
            ExtraServiceMap,
            ExtraServiceInfoModal,
            ConditionsModal,
            SearchLayout,
        },
        layout: 'blank',
    })
    export default class ExtraServicesPage extends mixins(
        ExtraServicePageMixin,
        ExtraServicesStoreMixin,
        SearchPageMixin,
        SearchPageBase,
        Tmp_SearchPageBase
    ) {
        validate({query}) {
            return query.cityId
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        get city() {
            return this.productRuntimeStore.city
        }

        get mapPoints() {
            const {latitude: lat, longitude: lng} = this.city
            return [{lat, lng}]
        }

        get filteredOffers() {
            const type = this.$route.query.type,
                offers = this.productRuntimeStore.offers
            return !type ? offers : offers.filter(offer => offer.info.name.toLowerCase().includes(type))
        }

        get sortItems() {
            return [
                ...Tmp_SearchPageBase.options.computed.sortItems.get.call(this),
                {
                    text: this.$t('sort.name'),
                    value: 'name',
                },
            ]
        }
    }
</script>
