<template>
    <communications-filters-wrapper :has-active-filters="hasActiveFilters">
        <v-form class="mb-5 table-filters">
            <v-row dense>
                <v-col cols="12" md="6">
                    <daterange
                        :start-date-label="$t('communication_date_from')"
                        :end-date-label="$t('communication_date_to')"
                        :_start-date.sync="filters.communicationDateFrom"
                        :_end-date.sync="filters.communicationDateTo"
                        dense
                        :outlined="false"
                        :single-line="false"
                        :hide-details="false"
                        :min-start-date="null"
                        same-date
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="filters.accountType"
                        :items="accountTypeItems"
                        :label="$t('account_type')"
                        clearable
                        dense
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="filters.subject" :label="$t('subject')" dense />
                </v-col>
                <v-col cols="12" md="6">
                    <daterange
                        :start-date-label="$t('created_date_from')"
                        :end-date-label="$t('created_date_to')"
                        :_start-date.sync="filters.createdDateFrom"
                        :_end-date.sync="filters.createdDateTo"
                        dense
                        :outlined="false"
                        :single-line="false"
                        :hide-details="false"
                        :min-start-date="null"
                        same-date
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="filters.accountName" :label="$t('account_name')" dense />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select v-model="filters.stage" :items="stageItems" :label="$t('stage')" clearable dense />
                </v-col>
                <v-col cols="12" md="6">
                    <daterange
                        :start-date-label="$t('modified_date_from')"
                        :end-date-label="$t('modified_date_to')"
                        :_start-date.sync="filters.modifiedDateFrom"
                        :_end-date.sync="filters.modifiedDateTo"
                        dense
                        :outlined="false"
                        :single-line="false"
                        :hide-details="false"
                        :min-start-date="null"
                        same-date
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="filters.type"
                        :items="typeItems"
                        :label="$t('communication_type')"
                        clearable
                        dense
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="filters.assignedToId"
                        :item-text="userName"
                        item-value="personId"
                        :items="users"
                        :label="$t('assigned_to')"
                        :loading="usersLoading"
                        :disabled="usersLoading"
                        dense
                    />
                    <v-checkbox
                        :value="personId === filters.assignedToId"
                        :label="$t('assigned_to_me')"
                        dense
                        hide-details
                        class="float-right"
                        @change="$event ? (filters.assignedToId = personId) : (filters.assignedToId = null)"
                    />
                </v-col>
            </v-row>
            <v-btn small color="primary" @click="submit">
                {{ $t('filter') }}
            </v-btn>
            <v-btn small color="white" @click="reset">
                {{ $t('reset') }}
            </v-btn>
        </v-form>
    </communications-filters-wrapper>
</template>

<script>
    import {Vue, Component, Emit, Prop, VModel} from 'nuxt-property-decorator'
    import Daterange from '@/components/search/forms/Daterange.vue'
    import {authStore} from '@/utils/store-accessor'
    import CommunicationsFiltersWrapper from '@/components/account/communications/CommunicationsFiltersWrapper.vue'

    @Component({
        components: {CommunicationsFiltersWrapper, Daterange},
    })
    export default class CommunicationsFilters extends Vue {
        @VModel() filters
        @Prop({required: true, type: Boolean}) hasActiveFilters

        usersLoading = true
        users = []

        @Emit()
        submit() {}

        @Emit()
        reset() {}

        async mounted() {
            try {
                this.usersLoading = true
                this.users = (await this.$api.persons.get({companyId: authStore.company.id})).users
            } catch (e) {
                this.users = []
            } finally {
                this.usersLoading = false
            }
        }

        userName({firstName, lastName}) {
            return `${firstName || ''} ${lastName || ''}`
        }

        get accountTypeItems() {
            const accountTypes = ['CORPORATE_CLIENT', 'PRIVATE_CLIENT', 'SUPPLIER', 'AGENCY_DISTRIBUTOR', 'SYSTEM']
            if (!this.$config.account.showCorporateClients) {
                accountTypes.splice(0, 1)
            }
            return accountTypes.map(value => ({value, text: this.$t(`accountTypes.${value}`)}))
        }

        get stageItems() {
            const accountTypes = ['HELD', 'NOT_HELD', 'PLANNED']
            return accountTypes.map(value => ({value, text: this.$t(`communicationStage.${value}`)}))
        }

        get typeItems() {
            const accountTypes = ['CALL', 'MEETING', 'EMAIL', 'OTHER']
            return accountTypes.map(value => ({value, text: this.$t(`communicationType.${value}`)}))
        }

        get personId() {
            return authStore.person.personId
        }
    }
</script>
