<template>
    <search-form-layout :popup="popup" title="transfers.transfers" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="6" cols="12" class="d-md-flex justify-center justify-md-space-between">
                        <transfer-point-autocomplete
                            ref="firstLocation"
                            v-model="departurePoint"
                            :rules="[v => !!v || `${$t('transfers.departure_location')} ${$t('validation.required')}`]"
                            label="transfers.departure_location"
                            single-line
                            filled
                            class="flex-grow-1 location-field"
                        />
                        <div class="text-center swap-btn">
                            <v-btn icon @click="swapLocations">
                                <v-icon color="white">
                                    {{ isMobile ? 'mdi-swap-vertical-bold' : 'mdi-swap-horizontal-bold' }}
                                </v-icon>
                            </v-btn>
                        </div>
                        <transfer-point-autocomplete
                            ref="secondLocation"
                            v-model="arrivalPoint"
                            :rules="[v => !!v || `${$t('transfers.arrival_location')} ${$t('validation.required')}`]"
                            :departure-location="departurePoint"
                            label="transfers.arrival_location"
                            single-line
                            filled
                            class="flex-grow-1 location-field"
                        />
                    </v-col>
                    <v-col md="2" cols="12">
                        <vc-date-picker
                            v-model="dateTimeData"
                            :locale="$i18n.locale"
                            :min-date="minDateTime"
                            is-required
                        >
                            <template v-slot="{inputEvents}">
                                <div v-on="inputEvents">
                                    <v-text-field
                                        :class="{caption: $breakpoint.smAndDown}"
                                        :label="$t('transfers.departure_date')"
                                        readonly
                                        single-line
                                        filled
                                        hide-details
                                        background-color="white"
                                        prepend-inner-icon="mdi-calendar"
                                        :value="formattedDate"
                                    />
                                </div>
                            </template>
                        </vc-date-picker>
                    </v-col>
                    <v-col md="2" cols="12">
                        <tourists-select-menu
                            :_adults.sync="adults"
                            :_children-ages.sync="childrenAges"
                            token="passenger"
                            :max-total="50"
                        />
                    </v-col>
                    <v-col md="2" cols="12" class="hidden-sm-and-down">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :suppliers="suppliers"
                :options="[
                    'transfer-type',
                    'promocode',
                    'citizenship',
                    'internalSuppliers',
                    'externalSuppliers',
                    'supplierId',
                ]"
            >
                <template v-slot:customOptions>
                    <custom-additional-options product-name="transfer" />
                </template>
            </additional-options-wrapper>
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import TransferPointAutocomplete from '@/components/transfers/search/forms/TransferPointAutocomplete'
    import {authStore, persistentStore, transfersRuntimeStore, transfersStore} from '@/store'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import {appInstance} from '@/utils/app-accessor'
    import CustomAdditionalOptions from '~src/components/snippets/AdditionalSearchOptions/CustomAdditionalOptions.vue'

    @Component({
        components: {
            CustomAdditionalOptions,
            TouristsSelectMenu,
            TransferPointAutocomplete,
            AdditionalOptionsWrapper,
            SearchFormLayout,
        },
        mixins: [AdditionalSearchOptionsMixin],
    })
    export default class TransferSearchForm extends Vue {
        @Prop({default: false}) popup

        valid = false
        departurePoint = {}
        arrivalPoint = {}
        //TODO Add time picker
        dateTime = null
        adults = 2
        childrenAges = []
        suppliers = []

        //TODO Add CHANGE_LOCALE_EVENT handler

        async mounted() {
            await this.$store.restored
            this.arrivalPoint =
                transfersStore.searchRequest.arrivalCityId && Object.keys(transfersRuntimeStore.arrivalPoint).length
                    ? transfersRuntimeStore.arrivalPoint
                    : null
            this.departurePoint =
                transfersStore.searchRequest.departureCityId && Object.keys(transfersRuntimeStore.departurePoint).length
                    ? transfersRuntimeStore.departurePoint
                    : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(transfersStore.searchRequest.dateTime), new Date())) {
                this.dateTime = transfersStore.searchRequest.dateTime
            } else {
                this.dateTime = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
            this.adults = authStore.user.isEmployeeOrExternalCorp ? 1 : transfersStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp
                ? []
                : [...transfersStore.searchRequest.childrenAges]
            if (this.isTO1) {
                try {
                    this.suppliers = (await this.$api.suppliers.get({productType: 'transfer'}))?.suppliers
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        newQuery() {
            const query = {
                dateTime: this.dateTime,
                adults: this.adults,
                childrenAges: this.childrenAges,
                convertToCurrency: persistentStore.currency,
            }
            const setRequestPoint = (
                point,
                pointPlace,
                pointCityId,
                pointCountryId,
                pointHotelCode,
                pointHotelName
            ) => {
                switch (point.type) {
                    case 'hotel':
                        query[pointPlace] = 'hotel'
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        query[pointHotelCode] = point.hotelCode
                        query[pointHotelName] = point.name
                        break
                    case 'city':
                        query[pointPlace] = 'hotel'
                        query[pointCityId] = point.id
                        query[pointCountryId] = point.countryId
                        break
                    case 'airport':
                        query[pointPlace] = point.iataCode
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        break
                    case 'trainStation':
                        query[pointPlace] = point.id
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        break
                    case 'cruisePort':
                        query[pointPlace] = point.id
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        break
                }
            }
            setRequestPoint(
                this.arrivalPoint,
                'arrivalPlace',
                'arrivalCityId',
                'arrivalCountryId',
                'arrivalHotelCode',
                'arrivalHotelName'
            )
            setRequestPoint(
                this.departurePoint,
                'departurePlace',
                'departureCityId',
                'departureCountryId',
                'departureHotelCode',
                'departureHotelName'
            )

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        async search() {
            const query = this.newQuery()
            this.$emit('close-popup')
            if (this.arrivalPoint.hotelCode && !this.arrivalPoint.longitude) {
                try {
                    const {
                        hotelDescriptionInfo: {latitude, longitude, address},
                    } = await appInstance.$api.hotelInfo.get({hotelId: this.arrivalPoint.id})
                    Object.assign(this.arrivalPoint, {latitude, longitude, address: address?.addressLine})
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            if (this.departurePoint.hotelCode && !this.departurePoint.longitude) {
                try {
                    const {
                        hotelDescriptionInfo: {latitude, longitude, address},
                    } = await appInstance.$api.hotelInfo.get({hotelId: this.departurePoint.id})
                    Object.assign(this.departurePoint, {latitude, longitude, address: address?.addressLine})
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            transfersRuntimeStore.SET_ARRIVAL_POINT(this.arrivalPoint)
            transfersRuntimeStore.SET_DEPARTURE_POINT(this.departurePoint)
            const name = 'transfers'
            if (this.$route.name !== name) {
                transfersRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await transfersRuntimeStore.search(transfersStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        swapLocations() {
            const first = {...this.$refs.firstLocation.city}
            const second = {...this.$refs.secondLocation.city}
            this.departurePoint = Object.keys(second).length ? second : null
            this.arrivalPoint = Object.keys(first).length ? first : null
        }

        get isTO1() {
            return authStore.isTO1
        }

        get dateTimeData() {
            return this.$dateFns.parseISO(this.dateTime)
        }

        set dateTimeData(val) {
            this.dateTime = val ? this.$dateFns.format(val) : null
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get formattedDate() {
            return this.dateTime !== null ? this.$options.filters.dateFormat(this.dateTime) : ''
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/settings/_variables.scss';

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        .location-field {
            flex-basis: 100%;
            min-width: 0;
        }
        .swap-btn {
            line-height: 56px;
        }
    }
</style>
