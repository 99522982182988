<template>
    <v-dialog v-model="modal" width="800px">
        <v-card v-if="infoLoading" color="primary" dark>
            <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else class="pa-3">
            <h2 class="pt-5 pt-md-2">
                {{ info.modelName }}
            </h2>
            <v-subheader>
                {{ info.carRentCompanyName }}
            </v-subheader>
            <v-tabs hide-slider>
                <v-tab class="text-uppercase">
                    {{ $t('description') }}
                </v-tab>
                <v-tab class="text-uppercase">
                    {{ `${$t('carsRent.pick-up_point')}` }}
                </v-tab>
                <v-tab-item>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-carousel
                                height="170px"
                                hide-delimiters
                                show-arrows-on-hover
                                :show-arrows="images.length > 1"
                            >
                                <v-carousel-item v-for="(image, imageIndex) in images" :key="imageIndex">
                                    <v-img :src="image.url | imageUrl(300)" height="170px" contain />
                                </v-carousel-item>
                            </v-carousel>
                        </v-col>
                        <v-col cols="12" md="8">
                            <p>
                                <strong> {{ $t('description_type.short') }} </strong><br />
                                <span v-html="info.shortDescription" />
                            </p>
                            <p>
                                <strong> {{ $t('description_type.general') }} </strong><br />
                                <span v-html="info.description" />
                            </p>
                            <p>
                                <strong> {{ `${$t('included')}:` }} </strong><br />
                            </p>
                            <ul>
                                <template v-for="feature in info.features">
                                    <li :key="feature" v-text="$t(`carsRent.${feature.toLowerCase()}`)" />
                                </template>
                            </ul>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <cars-map
                        :_expanded.sync="mapExpanded"
                        :offers="pickUpPoints"
                        :parent="true"
                        style="max-height: 500px; position: unset"
                    />
                </v-tab-item>
            </v-tabs>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_CARS_INFO_MODAL} from '@/utils/event-bus'
    import CarsMap from '@/components/carsRent/search/CarsMap'

    @Component({
        components: {CarsMap},
    })
    export default class CarInfoModal extends Vue {
        info = {
            modelName: '',
        }
        offer = {
            pickUpPoints: {},
        }
        modal = false
        infoLoading = true
        mapExpanded = false
        carInfo = {}

        created() {
            EventBus.$on(SHOW_CARS_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_CARS_INFO_MODAL, this.showModal)
        }

        async showModal(product) {
            this.modal = true
            this.offer = product.offer
            try {
                this.info = await this.$api.carRentInfo.get({offerKey: this.offer.offerKey})
            } catch (e) {
                this.info = product.info
                return
            }
            this.infoLoading = false
        }

        get pickUpPoints() {
            return this.offer.pickUpPoints.map(point => {
                const newPoint = {...point}
                newPoint.offerKey = this.offer.offerKey
                return newPoint
            })
        }

        get images() {
            if (!this.info.images) return []
            return this.info.images
        }
    }
</script>
