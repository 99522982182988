<template>
    <loading-page-layout :loaded="loaded">
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="goToSearchPage" />
        <mobile-info-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <transfer-breadcrumbs v-if="!$breakpoint.smAndDown" :search-request="searchRequest" :product="product" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <h1 v-if="product.info" class="mb-1 title">
                        {{ product.info.name }}
                        <v-chip
                            v-if="
                                product.offers &&
                                product.offers[0] &&
                                product.offers[0].transferType &&
                                product.offers[0].transferType !== 'UNKNOWN'
                            "
                            label
                            x-small
                        >
                            {{ $t(`filters_transferType.${product.offers[0].transferType}`) }}
                        </v-chip>
                    </h1>
                    <supplier-name-label v-if="isTO1 && product.info" :supplier-name="product.info.supplierName" />
                    <transfer-operated-by-label :product="product" class="mb-2" />
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="uniqueImages"
                    />
                    <template v-if="!$breakpoint.smAndDown">
                        <transfer-offer-card
                            v-for="(offer, offerIndex) in product.offers"
                            :key="offer.offerKey"
                            :offer="offer"
                            :data-cy="`transfer-offer-card-${offerIndex}`"
                            @images="images = [...images, ...$event]"
                        />
                    </template>
                    <transfer-info :product="product" />
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <transfer-search-summary :search-request="searchRequest" />
                    <template v-if="$breakpoint.smAndDown">
                        <transfer-offer-card
                            v-for="offer in product.offers"
                            :key="offer.offerKey"
                            :offer="offer"
                            @images="images = [...images, ...$event]"
                        />
                    </template>
                    <div style="position: relative">
                        <client-only>
                            <transfer-map
                                style="position: relative; max-height: 70vh; top: 0 !important"
                                :points="mapPoints"
                                class="disable-top"
                                :parent="true"
                            />
                        </client-only>
                    </div>
                    <!--transfer-allowed-points /-->
                </v-col>
            </v-row>
        </v-container>
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
            </template>
        </conditions-modal>
        <transfer-info-modal />
    </loading-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileInfoPageTabs from '@/components/snippets/MobileInfoPageTabs'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import TransferOfferCard from '~src/components/transfers/info/transferOfferCard.src'
    import TransferInfo from '@/components/transfers/info/TransferInfo'
    import TransferSearchSummary from '@/components/transfers/search/TransferSearchSummary'
    import TransferMap from '@/components/transfers/search/TransferMap'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal'
    import {authStore, transfersRuntimeStore} from '@/store'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import InfoPageMixin from '@/components/mixins/InfoPageMixin'
    import LoadingPageLayout from '@/components/LoadingPageLayout'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import TransferOperatedByLabel from '@/components/transfers/snippets/TransferOperatedByLabel.vue'

    @Component({
        components: {
            TransferOperatedByLabel,
            LoadingPageLayout,
            TransferConditionsModalInfo,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            TransferOfferCard,
            TransferBreadcrumbs,
            MobileInfoPageTabs,
            TransferInfo,
            TransferSearchSummary,
            TransferMap,
            ConditionsModal,
            TransferInfoModal,
            ProductGallery,
            ProductGalleryMobile,
            SupplierNameLabel,
        },
        layout: 'blank',
    })
    export default class TransferPage extends mixins(
        TransferPageMixin,
        TransferStoreMixin,
        SearchPageMixin,
        InfoPageMixin,
        SearchPageAuthMixin
    ) {
        get mapPoints() {
            return [transfersRuntimeStore.departurePoint, transfersRuntimeStore.arrivalPoint]
        }

        get uniqueImages() {
            return this.images.filter((v, i, a) => a.findIndex(t => t.url === v.url) === i)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style scoped lang="scss">
    .disable-top {
        top: 0 !important;
    }
</style>
