<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col cols="12">
                <div class="d-flex flex-column flex-md-row">
                    <div v-if="editMode" class="d-flex flex-column">
                        <div class="d-flex">
                            <span> {{ `${$t('created_by')}: ` }} &nbsp; </span>
                            <span> {{ editNoteInfo.created }} &nbsp; </span>
                            <span>
                                {{ editNoteInfo.createdUser }}
                            </span>
                        </div>
                        <div class="d-flex">
                            <span> {{ `${$t('modified_by')}: ` }} &nbsp; </span>
                            <span> {{ editNoteInfo.modified }} &nbsp; </span>
                            <span>
                                {{ editNoteInfo.modifiedUser }}
                            </span>
                        </div>
                    </div>
                    <v-spacer />
                    <v-checkbox v-model="info.active" :label="`${$t('active')}`" />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    v-if="preset || info.communicationId"
                    v-model="info.subject"
                    outlined
                    :label="`${$t('title')}*`"
                    persistent-placeholder
                    hide-details
                    :disabled="!!info.communicationId"
                    :rules="[requiredRule('title')]"
                />
                <v-autocomplete
                    v-else
                    :items="presetItems"
                    item-text="subject"
                    outlined
                    :label="$t('select_preset_note')"
                    persistent-placeholder
                    :filter="presetSearch"
                    return-object
                    clearable
                    hide-details
                    @change="presetSelect"
                />
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="info.description"
                    outlined
                    :label="`${$tc('internal_note', 1)}*`"
                    persistent-placeholder
                    :rules="[requiredRule('note')]"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-select
                    v-if="!specifiedService && !preset"
                    v-model="info.processId"
                    item-text="name"
                    item-value="value"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    :items="relatedToServices"
                    :label="`${$t('relates_to')}*`"
                    :rules="[v => !!v || v === null || `${$t('relates_to')} ${$t('relates_to')}`]"
                />
                <v-expand-transition>
                    <div v-if="personRole !== 'dispatcher' && info.visibleForClients" class="d-flex flex-column">
                        <div class="mt-5 text-subtitle-1">{{ $t('show_in') }}:</div>
                        <v-checkbox v-model="info.showInInvoice" :label="$t('show_note_invoice')" hide-details />
                        <v-checkbox v-model="info.showInQuotation" :label="$t('show_note_quotation')" hide-details />
                    </div>
                </v-expand-transition>
                <template v-if="isTO1">
                    <div class="mt-5 text-subtitle-1">{{ $t('visible_for') }}:</div>
                    <v-checkbox v-model="info.visibleForClients" :label="$t('for_clients')" hide-details />
                    <v-checkbox v-model="info.visibleForSuppliers" :label="$t('for_suppliers')" hide-details />
                    <v-checkbox input-value="true" disabled :label="$t('for_to1')" hide-details />
                </template>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync, VModel} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {TimeField, DatePicker},
    })
    export default class InternalNoteEditForm extends mixins(FormValidationRulesMixin) {
        @VModel() info
        @Prop() editMode
        @Prop() services
        @Prop() specifiedService
        @Prop() editNoteInfo
        @PropSync('_valid', {default: true}) valid
        @Prop({default: false, type: Boolean}) preset

        presetItems = []

        created() {
            if (!this.info.communicationDateTime) {
                this.info.communicationDateTime = this.$dateFns.format(new Date(), "yyyy-MM-dd'T'HH:mm")
            }
            if (!this.info.processId) {
                this.info.processId = this.relatedToServices[0].value
            }
        }

        async mounted() {
            if (this.preset) return
            try {
                const {communications} = await this.$api.communications.get({
                    type: 'NOTES',
                    presetOnly: true,
                })
                this.presetItems = communications
            } catch (e) {
                this.presetItems = []
            }
        }

        presetSearch({subject, description}, queryText) {
            return `${subject.toLowerCase()} ${description.toLowerCase()}`.indexOf(queryText.toLowerCase()) !== -1
        }

        presetSelect(preset) {
            if (preset) {
                const {subject, description, showInInvoice, showInQuotation, visibleForClients, visibleForSuppliers} =
                    preset
                Object.assign(this.info, {
                    subject,
                    description,
                    showInInvoice,
                    showInQuotation,
                    visibleForClients,
                    visibleForSuppliers,
                })
            } else {
                this.info.subject = null
            }
        }

        validateForm() {
            this.$refs.form.validate()
        }

        get relatedToServices() {
            if (this.specifiedService) {
                const {serviceName, processId} = this.specifiedService
                return [{name: serviceName, value: processId}]
            }
            const itemsByServices = this.services.map(e => ({
                name: `${e.serviceName}${e.roomTypeName ? `, ${e.roomTypeName}` : ''}`,
                value: e.processId,
            }))
            return [{name: this.$t('reservation'), value: null}, ...itemsByServices]
        }

        get personRole() {
            return authStore.personRole
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
