<template>
    <div class="text-center">
        <h1 class="text-center my-5" :class="[5, 6].includes(status) ? 'warning--text' : 'success--text'">
            <v-icon v-if="[5, 6].includes(status)" large class="warning--text">mdi-exclamation-thick</v-icon>
            <v-icon v-else large class="success--text">mdi-checkbox-marked-circle-outline</v-icon>
            <span v-if="status === 6 && !isTO1">{{ $t(`bookingStatus.${getBookingStatusLocaleKey(0)}`) }}</span>
            <span v-else>{{ $t(`bookingStatus.${getBookingStatusLocaleKey(status)}`) }}</span>
        </h1>
        <template v-if="status === 6 && !isTO1">
            <div>
                <strong>{{ $t('thanks_for_your_booking') }}</strong>
            </div>
            <div>
                <strong class="warning--text">{{ $t('order_need_manual_processing') }}</strong>
            </div>
        </template>
        <template v-else-if="errors && errors.length">
            <div v-for="(error, errorIndex) in errors" :key="errorIndex">
                <strong class="warning--text" v-html="parseErrorMessage(error.message)" />
            </div>
        </template>
        <p>
            {{ $t('your_booking_number') }} <strong data-cy="reservation-number">{{ orderId }}</strong>
        </p>
        <p v-if="personEmail">
            {{ status !== 10 ? $t('booking_details_sent_to') : $t('quote_details_sent_to') }}
            <strong>{{ personEmail }}</strong>
        </p>
        <p>
            {{ $t('confirm_questions') }}
            <span v-if="company.email">
                <strong>{{ company.email }}</strong> {{ $t('or') }}
            </span>
            <strong>{{ company.phones.join(',') }}</strong>
        </p>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {authStore, persistentStore} from '@/store'

    @Component
    export default class ConfirmationStatus extends Vue {
        @Prop({default: () => ({phones: []})}) company
        @Prop() tourlead

        parseErrorMessage(message) {
            if (message.indexOf('Extra seats were not added to booking') !== -1) {
                let message = this.$t('flights.warnings.seats_no_added')
                if (!this.isTO1) {
                    message += ' ' + this.$t('flights.warnings.please_contact_your_manager')
                }
                return message
            }
            if (message.indexOf('Extra services were not added to booking') !== -1) {
                let message = this.$t('flights.warnings.extra_services_no_added')
                if (!this.isTO1) {
                    message += ' ' + this.$t('flights.warnings.please_contact_your_manager')
                }
                return message
            }
            return message
        }

        getBookingStatusLocaleKey(status) {
            return [
                'CONFIRMATION_PENDING',
                'CONFIRMATION_PENDING',
                'CONFIRMED',
                'CANCELLATION_PENDING',
                'CANCELED',
                'REJECTED',
                'ERROR',
                'CANCELLATION_REJECTED',
                'ON_CONFIRM',
                'CANCELED_WITHOUT_FEE',
                'QUOTED',
                'ISSUED',
                'ESTIMATED',
                'TICKET_ISSUING',
                'NO_SHOW',
                'PRE_CONFIRMED',
            ][status]
        }

        get personEmail() {
            return this.tourlead
                ? this.tourlead.email
                : authStore.person
                ? authStore.person.email
                : authStore.registeredPerson
                ? authStore.registeredPerson.email
                : null
        }

        get orderId() {
            return persistentStore.bookResponse[0].orderId
        }

        get status() {
            if (persistentStore.bookResponse.length > 1) {
                const errorIndex = persistentStore.bookResponse.findIndex(entry => entry.status === '6')
                if (errorIndex >= 0) return parseInt(persistentStore.bookResponse[index].status, 10)

                const index = persistentStore.bookResponse.findIndex(
                    entry => entry.status === '0' || entry.status === '1'
                )
                if (index >= 0) return parseInt(persistentStore.bookResponse[index].status, 10)
            }
            return parseInt(persistentStore.bookResponse[0].status, 10)
        }

        get errors() {
            return persistentStore.bookResponse[0].errors
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
