<template>
    <v-dialog v-model="isShowPlaceholdersDialog" width="700">
        <template v-slot:activator="{on, attrs}">
            <v-btn color="secondary lighten-3" dark v-bind="attrs" v-on="on">
                <v-icon> mdi-help-circle-outline </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                <div>
                    {{ $t('email_editor.placeholders') }}
                </div>
                <v-icon class="v-card-close mt-3 me-3" @click="isShowPlaceholdersDialog = false"> mdi-close </v-icon>
            </v-card-title>
            <v-card-text style="white-space: pre-line">
                {{ $t('email_editor.placeholders_description') }}
                <br />
                {{ placeholderItem }}
            </v-card-text>
            <v-divider />
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class EmailEditorPlaceholdersDialog extends Vue {
        @Prop({required: true}) type
        @Prop({required: true}) recipientType

        isShowPlaceholdersDialog = false

        get placeholderItem() {
            const supplierConfirmationPending =
                '{{firstLevelTOName}} - name of company\n' +
                '{{orderNumber}} - reservation number\n' +
                '{{commonExtraOrderInfo.serviceName}} - name of a booked service\n' +
                '{{datePointFormat commonExtraOrderInfo.startDate}} - {{datePointFormat commonExtraOrderInfo.endDate}} - start and end date(s) of service\n' +
                '{{supplierReference}} - supplier’s reservation number\n' +
                '{{> productType}} - details on booked service obtained from reservation\n' +
                '{{sumToPay}} - payment amount\n' +
                '{{#if service.printableSupplierCommission}} - condition to check if there is commission from supplier\n' +
                '{{service.printableSupplierCommission}} -  commission amount from supplier\n' +
                '{{#if commonExtraOrderInfo.coments}} - condition to check if there are comments for booking\n' +
                '{{commonExtraOrderInfo.coments}} - comments for booking\n' +
                '{{#if orderConfirmationUrl}} - URL to redirect a user when ‘Confirm’ button is clicked\n' +
                '{{#if orderRejectionUrl}} - URL to redirect a user when ‘Reject’ button is clicked\n' +
                '{{companyInfo.phone}} - phone number from company’s profile\n' +
                '{{mailto companyInfo.email}} -  phone number from company’s profile\n' +
                '{{siteLink this}} - URL to your website\n'
            const supplierBookingCreation =
                '{{firstLevelTOName}} - name of company\n' +
                '{{orderNumber}} - reservation number\n' +
                '{{commonExtraOrderInfo.serviceName}} - name of a booked service\n' +
                '{{datePointFormat commonExtraOrderInfo.startDate}} - {{datePointFormat commonExtraOrderInfo.endDate}} - start and end date(s) of service\n' +
                '{{supplierReference}} - supplier’s reservation number\n' +
                '{{> productType}} - details on booked service obtained from reservation\n' +
                '{{sumToPay}} - payment amount\n' +
                '{{#if service.printableSupplierCommission}} - condition to check if there is commission from supplier\n' +
                '{{service.printableSupplierCommission}} -  commission amount from supplier\n' +
                '{{#if commonExtraOrderInfo.coments}} - condition to check if there are comments for booking\n' +
                '{{commonExtraOrderInfo.coments}} - comments for booking\n' +
                '{{companyInfo.phone}} - phone number from company’s profile\n' +
                '{{mailto companyInfo.email}} -  phone number from company’s profile\n' +
                '{{siteLink this}} - URL to your website\n'
            const supplierModification =
                supplierBookingCreation +
                '{{#if directPayment}} - condition to check if direct payment is used for the booking\n' +
                '{{#unless modifiedExtraOrderInfo.client.length}} - check if information on guests has been changed\n' +
                '{{#each modifiedExtraOrderInfo.client}} - information on modified name of guest(s)\n' +
                '{{#if modifiedExtraOrderInfo.startDate}} - condition to check if start date has been modified\n' +
                '{{#if modifiedExtraOrderInfo.endDate}} - condition to check if end date has been modified\n' +
                '{{datePointFormat modifiedExtraOrderInfo.startDate}} - modified start date\n' +
                '{{datePointFormat modifiedExtraOrderInfo.endDate}} - modified start date\n'
            const supplierCancellationPending =
                supplierBookingCreation +
                '{{#if orderCancellationUrl}} - URL to redirect a user when ‘Confirm cancellation’ button is clicked\n'
            const supplierTimeLimit =
                supplierBookingCreation + '{{formattedDueDateTime}} - changed ticketing time limit date and time\n'

            const b2cCreated = supplierBookingCreation
            const b2cPrebook =
                b2cCreated +
                '{{#if paymentInfo}} - condition to check if the payment info is available\n' +
                '{{paymentInfo}} - information on payment\n' +
                '{{#if printVoucherAllowed}} - condition to check if voucher printing is allowed after payment\n' +
                '{{#if isPaid}} - condition to check if payment is completed\n'
            const b2cConfirmationPending =
                b2cCreated +
                '{{touristsList tourists.tourist}} - list of guests/tourists\n' +
                '{{paymentMethodName}} - name of payment option used for booking\n'

            if (this.recipientType === 'SUPPLIER' || this.recipientType === 'COMPANY') {
                switch (this.type) {
                    case 'BOOKING_CONFIRMED':
                        return supplierBookingCreation
                    case 'MODIFICATION_PENDING':
                    case 'BOOKING_MODIFIED':
                        return supplierModification
                    case 'CANCELLATION_PENDING':
                        return supplierCancellationPending
                    case 'TICKET_TIME_LIMIT_CHANGED':
                        return supplierTimeLimit
                    default:
                        return supplierConfirmationPending
                }
            } else if (this.recipientType === 'B2C') {
                switch (this.type) {
                    case 'PENDING_TICKETING':
                    case 'ISSUED_E_TICKET':
                    case 'BOOKING_PRECONFIRMED':
                        return b2cPrebook
                    default:
                        return b2cConfirmationPending
                }
            }

            return supplierConfirmationPending
        }
    }
</script>
