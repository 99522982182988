<template>
    <v-snackbar v-model="$attrs.value" :timeout="-1" top primary>
        <v-row no-gutters>
            <v-col :cols="12">
                {{
                    offersToCompareCount
                        ? `${$t('selected_for_compare_count')}: ${offersToCompareCount}`
                        : $t('select_to_compare_text')
                }}
                <v-btn v-show="offersToCompareCount > 1" small color="primary" @click="redirectToCompare">
                    {{ $t('compare') }}
                </v-btn>
                <v-btn v-show="offersToCompareCount > 0" small outlined @click="clearOffersToCompare">
                    {{ $t('clear_compare') }}
                </v-btn>
            </v-col>
            <v-col v-show="offersToCompareCount" :cols="12" class="mt-2">
                <download-search-results product="accommodation" />
            </v-col>
        </v-row>
    </v-snackbar>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {hotelsStore} from '~/store'
    import DownloadSearchResults from '@/components/snippets/DownloadSearchResults'

    @Component({
        components: {
            DownloadSearchResults,
        },
    })
    export default class CompareSnackbar extends Vue {
        clearOffersToCompare() {
            hotelsStore.CLEAR_OFFERS_TO_COMPARE()
            this.$emit('close-comparison')
        }

        get offersToCompareCount() {
            return hotelsStore.offersToCompare.length
        }

        redirectToCompare() {
            this.$router.push({name: 'comparison'})
        }
    }
</script>
