<template>
    <div>
        <events-calendar v-model="groups" class="mx-auto" />
        <v-stepper v-model="stepper">
            <v-stepper-items>
                <v-stepper-content :step="1">
                    <groups-list-table
                        v-model="selectedGroup"
                        :group-items="groupItems"
                        :details-loading="detailsLoading"
                        @show-details="
                            loadDetails()
                            nextStep()
                        "
                    />
                </v-stepper-content>
                <v-stepper-content :step="2">
                    <v-btn color="primary" text @click="nextStep">back</v-btn>
                    <v-card v-if="!detailsLoading" elevation="0">
                        <v-card-title>
                            <group-details-info
                                v-if="selectedGroupDetails"
                                :_selected-group-details.sync="selectedGroupDetails"
                                :group-id="selectedGroup.groupId"
                            />
                        </v-card-title>
                        <v-card-text>
                            <group-details-table
                                v-model="selectedGroupDetails"
                                :selected-group="selectedGroup"
                                :city="city"
                            />
                        </v-card-text>
                    </v-card>
                    <v-card v-if="detailsLoading" elevation="0">
                        <v-card-text>
                            {{ $t('loading') }}
                            <v-progress-linear indeterminate color="primary" class="mb-0" />
                        </v-card-text>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import EventsCalendar from '@/components/scheduler/EventsCalendar'
    import GroupsListTable from '@/components/scheduler/GroupsListTable'
    import GroupDetailsInfo from '@/components/scheduler/GroupDetailsInfo'
    import GroupDetailsTable from '@/components/scheduler/GroupDetailsTable'
    import {authStore, runtimeStore} from '@/utils/store-accessor'

    @Component({
        components: {GroupDetailsTable, GroupDetailsInfo, GroupsListTable, EventsCalendar},
        middleware: ['auth', 'forbidden'],
    })
    export default class SchedulerPage extends Vue {
        groups = []
        selectedGroup = null
        selectedGroupDetails = null
        detailsLoading = false
        city = false

        defaultTabs = ['mdi-calendar', 'mdi-account-details']

        stepper = 1

        nextStep() {
            this.stepper = this.stepper === 1 ? 2 : 1
        }

        get groupItems() {
            return this.groups?.map(e => {
                return {
                    ...e,
                    time: e.dateTime.split(' ')[1],
                    date: e.dateTime.split(' ')[0],
                    service: {
                        serviceName: e.serviceName,
                        serviceType: e.serviceType,
                        country: e.country,
                        city: e.city,
                    },
                    statusColor: runtimeStore.orderStatusColor(e.status),
                }
            })
        }

        get isTO1() {
            return authStore.isTO1
        }

        get personRole() {
            return authStore.personRole
        }

        async loadDetails(groupId = null) {
            try {
                this.selectedGroupDetails = null
                this.detailsLoading = true
                this.selectedGuests = []

                let driver
                let guide

                const rs = await this.$api.schedulerGroupDetails.get({
                    groupId: groupId || this.selectedGroup.groupId,
                })

                await Promise.all([
                    (async () => {
                        this.city = (
                            await this.$api.locations.get({pattern: this.selectedGroup.city, limitCities: 1})
                        ).cities[0]
                    })(),
                    (async () => {
                        driver = rs?.groupDetails.driverId
                            ? (await this.$api.persons.get({id: rs.groupDetails.driverId})).users[0]
                            : null
                    })(),
                    (async () => {
                        guide = rs?.groupDetails.guideId
                            ? (await this.$api.persons.get({id: rs.groupDetails.guideId})).users[0]
                            : null
                    })(),
                ])

                this.selectedGroupDetails = {
                    ...rs.groupDetails,
                    guide,
                    driver,
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.detailsLoading = false
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
