<template>
    <div class="d-flex">
        <div v-if="findEntry('FLIGHT')" class="mr-2">
            <v-icon :small="small" color="grey lighten-1">mdi-airplane</v-icon>
        </div>
        <div v-if="findEntry('TRANSFER')" class="mr-2">
            <v-icon :small="small" color="grey lighten-1">mdi-bus</v-icon>
        </div>
        <div v-if="findEntry('CAR')" class="mr-2">
            <v-icon :small="small" color="grey lighten-1">mdi-car</v-icon>
        </div>
        <div v-if="findEntry('ACCOMMODATION')" class="mr-2">
            <v-icon :small="small" color="grey lighten-1">mdi-office-building</v-icon>
        </div>
        <div v-if="findEntry('EXCURSION') || findEntry('EVENT')" class="mr-2">
            <v-icon :small="small" color="grey lighten-1">mdi-lightning-bolt-outline</v-icon>
        </div>
        <div v-if="findEntry('INSURANCE')">
            <v-icon :small="small" color="grey lighten-1">mdi-umbrella</v-icon>
        </div>
        <div v-if="findEntry('CRUISE')">
            <v-icon :small="small" color="grey lighten-1">mdi-ferry</v-icon>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TourIncludedEntries extends Vue {
        @Prop({default: () => []}) entries
        @Prop({required: false, default: Boolean}) small

        findEntry(type) {
            return !!this.entries.find(entry => entry.type.toLowerCase() === type.toLowerCase())
        }
    }
</script>
