<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <component :is="component" :product="entry">
            <template v-slot:price="{offer}">
                <tour-offer-delta-button :offer="offer" :offer-key="offerKey" :entry-name="entry.entryName" />
            </template>
        </component>
    </v-dialog>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_TRANSFER_INFO} from '@/utils/event-bus'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton'

    @Component({
        components: {
            TransferCard: () => import('~src/components/transfers/search/offers/transferCard.src'),
            TransferCardMobile: () => import('~/components/transfers/search/offers/TransferCardMobile.vue'),
            TourOfferDeltaButton,
        },
    })
    export default class TourTransferInfoModal extends Vue {
        @Prop({required: true}) searchRequest

        modal = false
        entry = {}
        offerKey = null

        mounted() {
            EventBus.$on(SHOW_TOUR_TRANSFER_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_TRANSFER_INFO, this.showModal)
        }

        async showModal(entry, offerKey) {
            try {
                const info = await this.$api.transferInfo.get({offerKey: entry.offers[0].offerKey})
                const imageUrl =
                    info.images && info.images.length
                        ? info.images?.find(item => item.mainImage).url || info.images[0].url
                        : ''

                this.entry = {
                    ...entry,
                    info: {
                        name: info.name,
                        description: info.shortDescription ? info.shortDescription : '',
                        imageUrl,
                    },
                }
                this.offerKey = offerKey
                this.modal = true
            } catch (e) {
                this.entry = {}
                this.offerKey = null
                this.modal = false
            }
        }

        get component() {
            return !this.$breakpoint.smAndDown ? 'transfer-card' : 'transfer-card-mobile'
        }
    }
</script>
