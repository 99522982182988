<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col
                v-if="
                    pickUpList.length &&
                    (!isProductFieldDisabled('PICK_UP_HOTEL') || !isProductFieldDisabled('PICK_UP_PLACE'))
                "
                cols="12"
                md="4"
            >
                <v-select
                    v-model="point"
                    :label="productFieldLabel('select_pick-up_point', 'PICK_UP_HOTEL')"
                    :items="pickUpList"
                    :rules="hotelRules"
                    hide-details
                    clearable
                    class="mb-2"
                    @change="changePickUpPoint"
                />
                <div
                    v-if="selectedPickUpPoint && selectedPickUpPoint.latitude && selectedPickUpPoint.longitude"
                    class="primary--text text-decoration-underline text-lowercase cursor-pointer"
                    @click="mapModal = true"
                >
                    <v-icon color="accent" left>mdi-map-marker</v-icon>
                    <span v-if="selectedPickUpPoint.address" class="grey--text">{{ selectedPickUpPoint.address }}</span>
                    {{ $t('show_on_map') }}
                </div>
            </v-col>
            <v-col v-if="!isProductFieldDisabled('PICK_UP_TIME')" md="2" cols="12">
                <time-field
                    v-model="time"
                    :label="productFieldLabel('transfers.pickup_time', 'PICK_UP_TIME')"
                    :rules="timeRules"
                    :disabled="!!pickUpList.length"
                    @change="changeStartTime"
                />
            </v-col>
        </v-row>
        <client-only v-if="selectedPickUpPoint && selectedPickUpPoint.latitude && selectedPickUpPoint.longitude">
            <v-dialog v-model="mapModal" :max-width="500">
                <map-location
                    v-if="mapModal"
                    :latitude="selectedPickUpPoint.latitude"
                    :longitude="selectedPickUpPoint.longitude"
                    :height="`500px`"
                />
            </v-dialog>
        </client-only>
    </v-form>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel} from 'nuxt-property-decorator'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import TimeField from '@/components/snippets/forms/TimeField'
    import MapLocation from '@/components/info/MapLocation'

    @Component({
        components: {MapLocation, TimeField},
    })
    export default class ActivityBookingForm extends mixins(ProductBookingFieldsMixin, FormValidationRulesMixin) {
        @VModel() valid
        @Prop() info
        @Prop({default: () => ({}), type: Object}) offer
        @Prop({default: () => '00:00', type: String}) startTime

        mapModal = false
        point = null
        time = null

        @Emit()
        change({prop, val}) {
            return {prop, val}
        }

        mounted() {
            if (this.pickUpList?.length) {
                this.point = this.pickUpList[0].text
                this.changePickUpPoint(this.point)

                this.time = this.selectedPickUpPoint.startTime
                this.changeStartTime(this.time)
            }
        }

        changePickUpPoint(val) {
            if (!val) {
                this.change({prop: 'hotelPointKey', val: null})
                this.change({prop: 'pickUpPlace', val: null})
                this.time = null
                this.change({prop: 'startTime', val: null})
                return
            }
            if (this.selectedPickUpPoint?.hotelPointKey)
                this.change({prop: 'hotelPointKey', val: this.selectedPickUpPoint.hotelPointKey})
            else if (this.selectedPickUpPoint?.point) this.change({prop: 'pickUpPlace', val})

            this.time = this.selectedPickUpPoint.startTime
            this.changeStartTime(this.time)
        }

        changeStartTime(val) {
            this.change({prop: 'startTime', val: val})
        }

        get selectedPickUpPoint() {
            return this.pickUpList.find(el => el.text === this.point)
        }

        get pickUpList() {
            const list = []

            this.info?.departures?.map(departure => {
                if (departure.hotels) {
                    departure.hotels.map(hotel => {
                        let date = new Date()
                        date = this.$dateFns.setHours(date, this.startTime.split(':')[0])
                        date = this.$dateFns.setMinutes(date, this.startTime.split(':')[1])
                        const index = this.offer.pickUpPoints?.findIndex(el => el.name === hotel.point)

                        if (index >= 0) {
                            date = this.$dateFns.add(date, {
                                hours: this.offer.pickUpPoints[index].timeShift.split(':')[0],
                                minutes: this.offer.pickUpPoints[index].timeShift.split(':')[1],
                            })
                        }
                        const startTime = this.$dateFns.format(date, 'yyyy-MM-dd HH:mm').split(' ')[1]

                        list.push({
                            ...hotel,
                            text: hotel.hotelName,
                            startTime,
                        })
                    })
                } else {
                    let date = new Date()
                    date = this.$dateFns.setHours(date, this.startTime.split(':')[0])
                    date = this.$dateFns.setMinutes(date, this.startTime.split(':')[1])
                    const index = this.offer.pickUpPoints?.findIndex(el => el.name === departure.point)

                    if (index >= 0) {
                        date = this.$dateFns.add(date, {
                            hours: this.offer.pickUpPoints[index].timeShift.split(':')[0],
                            minutes: this.offer.pickUpPoints[index].timeShift.split(':')[1],
                        })
                    }
                    const startTime = this.$dateFns.format(date, 'yyyy-MM-dd HH:mm').split(' ')[1]

                    list.push({
                        ...departure,
                        text: departure.point,
                        startTime,
                    })
                }
            })

            return list
        }

        get timeRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_TIME')) {
                rules.push(this.requiredRule('transfers.pickup_time', 'PICK_UP_TIME'))
            }
            return rules
        }

        get hotelRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_HOTEL')) {
                rules.push(this.requiredRule('select_hotel', 'PICK_UP_HOTEL'))
            }
            return rules
        }

        get placeRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_PLACE')) {
                rules.push(this.requiredRule('pickup_location', 'PICK_UP_PLACE'))
            }
            return rules
        }
    }
</script>
