<template>
    <div>
        <breadcrumbs />
        <v-card outlined>
            <v-card-title v-text="pageTitle" />
            <v-card-text>
                <v-row v-if="!result">
                    <v-col cols="12" md="7">
                        <v-form ref="form" v-model="valid">
                            <v-row>
                                <v-col cols="12" md="12" class="pb-0">
                                    <v-radio-group v-model="model.type" row>
                                        <v-radio :label="`${$t('register_as_agency')}`" value="TOUR_AGENCY" />
                                        <v-radio :label="`${$t('register_as_supplier')}`" value="INTERNAL_SUPPLIER" />
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="model.name.en"
                                        :label="`${$t('company_name')}*`"
                                        :rules="validationRules('company_name', [latinRule, requiredRule])"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0">
                                    <v-autocomplete
                                        ref="option"
                                        v-model="model.countryId"
                                        :items="countries"
                                        item-text="name"
                                        deletable-chips
                                        item-value="value"
                                        hide-details
                                        light
                                        :label="`${$t('country')}*`"
                                        :rules="[requiredRule('country')]"
                                        required
                                        @change="loadCities"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0">
                                    <v-autocomplete
                                        ref="option"
                                        v-model="model.cityId"
                                        :items="cities"
                                        item-text="name"
                                        :loading="citiesLoading"
                                        deletable-chips
                                        item-value="id"
                                        hide-details
                                        light
                                        :label="`${$t('city')}*`"
                                        :rules="[requiredRule('city')]"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="9" class="pb-0">
                                    <v-text-field
                                        v-model="model.street.en"
                                        :label="`${$t('address')}*`"
                                        :rules="[requiredRule('address')]"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="3" class="pb-0">
                                    <v-text-field v-model="model.postalCode" :placeholder="`${$t('postal_code')}`" />
                                </v-col>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                    <v-select
                                        v-model="model.contactPerson.prefix"
                                        :items="['Mr', 'Ms', 'Mrs']"
                                        :label="`${$t('salutation')}`"
                                    />
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="model.contactPerson.firstName.en"
                                        :placeholder="`${$t('first_name')}*`"
                                        :rules="validationRules('first_name', [latinRule, requiredRule])"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="model.contactPerson.lastName.en"
                                        :placeholder="`${$t('last_name')}*`"
                                        :rules="validationRules('last_name', [latinRule, requiredRule])"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0">
                                    <phone-field v-model="model.phones" :placeholder="$t('phone')" required />
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0">
                                    <v-text-field
                                        v-model="model.emails"
                                        :placeholder="`${$t('email')}*`"
                                        type="email"
                                        :rules="validationRules('email', [emailRule, requiredRule])"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        v-model="model.comment.en"
                                        outlined
                                        :placeholder="`${$t('comment')}`"
                                        rows="3"
                                    />
                                </v-col>
                            </v-row>

                            <v-btn
                                :loading="isLoading"
                                :disabled="isLoading || !valid"
                                color="primary"
                                block
                                @click="register"
                            >
                                {{ $t('register') }}
                            </v-btn>
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="5">
                        <p v-if="model.type === 'TOUR_AGENCY'" v-html="$t('company_registration_text_agency')" />
                        <p
                            v-if="model.type === 'INTERNAL_SUPPLIER'"
                            v-html="$t('company_registration_text_supplier')"
                        />
                    </v-col>
                </v-row>
                <p v-if="result">
                    {{ $t('company_registration_success') }}
                </p>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import RegistrationForm from '~src/components/account/forms/registrationForm.src'
    import {Mixins} from 'vue-property-decorator'
    import FormValidationRulesMixin from '~/mixins/FormValidationRulesMixin'
    import {runtimeStore} from '~/utils/store-accessor'
    import PhoneField from '@/components/snippets/forms/PhoneField.vue'

    @Component({
        components: {
            PhoneField,
            Breadcrumbs,
            RegistrationForm,
        },
    })
    export default class CompanyRegistrationPage extends Mixins(FormValidationRulesMixin) {
        valid = false
        isLoading = false
        result = false
        citiesLoading = false
        model = {
            type: 'TOUR_AGENCY',
            name: {
                en: '',
            },
            countryId: '',
            cityId: '',
            contactPerson: {
                prefix: '',
                firstName: {
                    en: '',
                },
                lastName: {
                    en: '',
                },
            },
            street: {
                en: '',
            },
            postalCode: null,
            phones: null,
            emails: null,
            comment: {
                en: '',
            },
            language: 'en',
        }
        cities = []

        get pageTitle() {
            return this.$t(`page_title.${this.$route.name}`)
        }

        get countries() {
            return runtimeStore.countries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }

        async loadCities() {
            this.citiesLoading = true

            try {
                const rs = await this.$api.locations.get({
                    limitCities: -1,
                    orderBy: 'ALPHABET',
                    countryId: this.model.countryId,
                })

                this.cities = rs.cities

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.citiesLoading = false
            }
        }

        async register() {
            this.isLoading = true
            let rq = this.model
            rq.emails = Array.isArray(rq.emails) ? rq.emails : [rq.emails]
            rq.phones = Array.isArray(rq.phones) ? rq.phones : [rq.phones]
            if (!rq.phones.length) {
                delete rq.phones
            }
            if (!rq.postalCode) {
                delete rq.postalCode
            }
            try {
                await this.$api.companyRegistration.post(rq)
                this.result = true
            } catch (e) {
                if (e.errors && e.errors.length && e.errors[0].token) {
                    const token = e.errors[0].token.replace(/\./g, '_')
                    if (token === 'api_validation_error_in_field') {
                        this.$toast.error(this.$t(`api_error_token.${token}`))
                        // const prop = e.errors[0]?.parameters
                        //     ?.find(parameter => parameter.includes('companyForm'))
                        //     ?.split(':')[1]
                        // this.$toast.error(
                        //     this.$t(`api_error_token.${token}`, {
                        //         prop: prop ? `'${this.$t(prop).toLocaleLowerCase()}'` : '',
                        //     })
                        // )
                    } else this.$toast.error(this.$t('error_message.registration_error'))
                } else {
                    this.$toast.error(this.$t('error_message.registration_error'))
                }
            }
            this.isLoading = false
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-input--radio-group.v-input--radio-group--row .v-radio {
            min-height: 35px;
        }
    }
</style>
