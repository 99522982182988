<template>
    <div class="icons-container">
        <v-btn
            v-for="socialLink in socialLinks"
            :key="socialLink.icon"
            text
            icon
            :href="socialLink.url"
            target="_blank"
        >
            <v-icon>
                {{ socialLink.icon }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'

    @Component
    export default class SocialIcons extends Vue {
        /**
         * For vk need to add Font Awesome stylesheet in nuxt config and vk value for custom vuetify options
         * @returns {{icon: string, url: string}[]}
         */
        get socialLinks() {
            return cmsStore.layoutSettings.socialLinks.map(({icon, url}) => {
                icon = (['vk'].includes(icon) ? '$' : 'mdi-') + icon
                return {icon, url}
            })
        }
    }
</script>

<style lang="scss">
    .icons-container {
        min-width: 120px;
        display: flex;
        justify-content: space-between;
    }
</style>
