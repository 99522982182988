<template>
    <v-row no-gutters>
        <v-col v-if="showImage" :cols="12" md="2" class="mr-0 pr-md-5">
            <v-img
                tile
                class="thumb"
                :src="serviceImage | imageUrl(350)"
                :lazy-src="'/placeholder.png' | srcHost"
                min-height="100"
                :eager="true"
                :max-height="$breakpoint.smAndDown ? 350 : undefined"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && serviceImage" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <slot name="belowImg" />
        </v-col>
        <v-col v-if="serviceCheckIn || serviceCheckOut" :cols="12" :md="showImage ? 3 : 5">
            <div class="mb-3">
                <slot name="additionalInfo" />
            </div>
            <div v-if="serviceCheckIn" class="d-flex align-center font-weight-bold info-text">
                <v-icon left class="date-icon" small>mdi-clock-in</v-icon>
                <span v-html="serviceCheckIn" />
            </div>
            <div v-if="serviceCheckOut" class="d-flex align-center font-weight-bold info-text">
                <v-icon left class="date-icon" small>mdi-clock-out</v-icon>
                <span v-html="serviceCheckOut" />
            </div>
            <slot name="extraTime" />
            <slot />
            <div v-if="service.refNum" class="d-flex align-center">
                <v-icon small left> mdi-counter </v-icon>
                <span class="font-weight-bold me-1">
                    {{ `${$t('reference_number')}: ` }}
                </span>
                {{ service.refNum }}
            </div>
            <supplier-name-label
                v-if="isTO1 && service.supplierCompanyName"
                :supplier-name="service.supplierCompanyName"
            />
        </v-col>
        <template v-if="!notItineraryStart">
            <slot v-if="personRole !== 'dispatcher'" name="price">
                <v-col v-if="!isPackage || isTO1" cols="12" md="4" class="ml-auto mb-2">
                    <order-prices
                        v-if="showAllServicesPrice"
                        :service-card-guarantee="isPayAtHotel"
                        :supplier-cancel-penalties="supplierPrice.cancelPenalty"
                        :supplier-payment-status="service.supplierPaymentStatus"
                        :loyalty-points="!isGuest ? service.loyaltyPoints : null"
                        :supplier-price="supplierPrice.price"
                        :client-cancel-penalties="clientPrice.cancelPenalty"
                        :customer-payment-status="service.customerPaymentStatus"
                        :client-price="clientPrice.price"
                        :alignment="isSupplier && !isConfirmationPendingStatus ? 'justify-end' : 'justify-space-around'"
                        :service="service"
                    >
                        <template v-if="!isSupplier" v-slot:violation>
                            <div v-if="service.violation">
                                <corporate-policy-violation :policies="service.corporatePolicies" />
                            </div>
                        </template>
                    </order-prices>
                    <div v-else-if="clientDiscount && clientDiscount.name" class="full-width d-flex">
                        <v-spacer />
                        <span class="caption accent--text">
                            {{ $t('discount') }}:
                            <span v-if="isTO1">
                                {{ clientDiscount.amount | priceFormat(clientPrice.currency) }}
                                <br />
                            </span>
                            <span>
                                {{ clientDiscount.name }}
                            </span>
                        </span>
                    </div>
                </v-col>
            </slot>
            <v-col
                v-if="(!isPackage || isTO1) && !isSupplier && !isGuest && personRole !== 'dispatcher'"
                md="2"
                class="ml-auto flex-column text-center order-service-buttons"
            >
                <template v-if="service.status === 'Quote' && showPrice && service.serviceType !== 'EXTRASERVICE'">
                    <v-btn
                        depressed
                        color="primary"
                        :block="$breakpoint.smAndDown"
                        :small="$breakpoint.smAndDown"
                        class="mb-4 white--text order-info-btn"
                        @click="book"
                    >
                        {{ $t('book') }}
                    </v-btn>
                    <a
                        class="modify-link mb-3"
                        :class="checkAvailabilityActive ? 'secondary--text' : 'black--text'"
                        @click="checkAvailability"
                    >
                        {{ $t('modify.check_availability') }}
                    </a>
                </template>
                <v-btn
                    v-if="
                        showPaymentAndInvoiceStatus &&
                        canPayService &&
                        !isPayAtHotel &&
                        !$config.account.paymentScheduler
                    "
                    depressed
                    color="primary"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    @click="payService"
                >
                    {{ $t('pay_service') }}
                </v-btn>
                <voucher-dialog
                    v-model="isShowVoucherDialog"
                    :is-loading="voucherLoading"
                    :title="isFlight ? $t('flights.e_ticket') : $t('voucher')"
                    @download-voucher="downloadVoucher(service)"
                    @send-on-email="sendVoucherOnEmail(service, $event)"
                />
                <v-btn
                    v-if="!isFlight && voucherAvailable"
                    depressed
                    color="grey"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    :loading="voucherLoading"
                    :disabled="voucherLoading"
                    @click="isShowVoucherDialog = true"
                >
                    {{ $t('voucher') }}
                </v-btn>
                <template v-if="isFlight && !statusForbidden && $config.account.issueFlightTicket">
                    <v-btn
                        v-if="voucherAvailable"
                        depressed
                        color="grey"
                        :block="$breakpoint.smAndDown"
                        :small="$breakpoint.smAndDown"
                        class="mb-4 white--text order-info-btn"
                        :loading="voucherLoading"
                        :disabled="voucherLoading"
                        @click="isShowVoucherDialog = true"
                    >
                        {{ $t('flights.e_ticket') }}
                    </v-btn>
                    <v-btn
                        v-else
                        depressed
                        color="grey"
                        :block="$breakpoint.smAndDown"
                        :small="$breakpoint.smAndDown"
                        class="mb-4 white--text order-info-btn"
                        @click="$emit('issueTicket', service)"
                    >
                        {{ $t('flights.issue_ticket') }}
                    </v-btn>
                </template>
                <v-btn
                    v-if="isFlight && canRetrieve && !isCharter && showCheckStatusBtn"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 order-info-btn"
                    plain
                    :loading="checkStatusActive"
                    :disabled="checkStatusActive"
                    @click="checkStatus"
                >
                    {{ $t('check_status') }}
                </v-btn>
                <v-btn
                    v-if="ticketVoidingAllowed"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    plain
                    class="mb-4 order-info-btn"
                    @click="showTicketVoidingModal"
                >
                    {{ $t('voiding') }}
                </v-btn>
                <a
                    v-if="isTO1 && $config.account.modifyServiceManual && service.serviceType !== 'EXTRASERVICE'"
                    class="black--text modify-link mb-3"
                    @click="modifyServiceManual"
                >
                    {{ $t('manual_correction') }}
                </a>
                <add-offline-service-dialog
                    v-if="isTO1 && $config.account.modifyServiceManual && service.serviceType === 'EXTRASERVICE'"
                    :order-travelers="service.travelers"
                    :order-process-id="order.processId"
                    :service-to-modify="service"
                    :client-type="order.clientType"
                    :customer-company-id="order.customerCompanyId"
                    @send-request="$emit('modifyService', $event)"
                />
                <a
                    v-if="
                        (service.serviceType === 'ACCOMMODATION' || ownProduct) &&
                        service.modificationAllowed &&
                        (isTO1 || $config.account.editService) &&
                        service.serviceType !== 'EXTRASERVICE'
                    "
                    class="black--text modify-link mb-3"
                    @click="modifyService"
                >
                    {{ $t('edit') }}
                </a>
                <a v-if="isShowCancelButton && !isPackage && !isBankFee" class="red--text cancel-link" @click="cancel">
                    {{ $t('cancel') }}
                </a>
                <v-btn v-if="showSendEmailBtn && !isConfirmationPendingStatus" text small @click="sendEmail">
                    {{ $t('send_email') }}
                </v-btn>
                <v-btn
                    v-if="isShowDueToConfirmBtn"
                    block
                    text
                    small
                    class="text-caption"
                    @click="showDueToConfirmDateModal"
                >
                    {{ $t('due_to_confirm_by') }}<br />{{ service.dueToConfirmDate | dateTimeFormat }}
                </v-btn>
                <div
                    v-if="isTO1 && isOwnProduct && isReservationReportMailEnabled"
                    v-ripple
                    class="rounded pa-1 cursor-pointer"
                    style="text-transform: none"
                    @click="isShowSentToSupplierModal = true"
                >
                    <span
                        v-if="service.serviceSentToSupplier"
                        class="success--text ms-2 subtitle-2 font-weight-regular"
                    >
                        {{ $t('sent_to_supplier') }}
                        <template v-if="service.serviceSentToSupplierDateTime">
                            (
                            <span>
                                {{ service.serviceSentToSupplierDateTime | dateFormat }}
                            </span>
                            <span>
                                {{ service.serviceSentToSupplierDateTime | hoursFromDate }}
                            </span>
                            )
                        </template>
                    </span>
                    <span v-else class="error--text ms-2 subtitle-2 font-weight-regular">
                        {{ $t('not_sent_to_supplier') }}
                    </span>
                </div>
            </v-col>
            <v-col
                v-if="(isSupplier || isTO1) && isConfirmationPendingStatus && personRole !== 'dispatcher'"
                md="1"
                class="ml-auto flex-column text-center"
            >
                <v-btn
                    v-if="service.serviceType !== 'EXTRASERVICE' && ownProduct"
                    depressed
                    color="primary"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    :class="locale === 'ru' ? 'supplier-action-btn' : ''"
                    @click="showReservationsModal('CONFIRM')"
                >
                    {{ $t('supplier.confirm') }}
                </v-btn>
                <v-btn
                    v-if="service.serviceType !== 'EXTRASERVICE' && ownProduct"
                    depressed
                    color="red"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    :class="locale === 'ru' ? 'supplier-action-btn' : ''"
                    @click="showReservationsModal('REJECT')"
                >
                    {{ $t('supplier.reject') }}
                </v-btn>
                <v-btn
                    v-if="showSendEmailBtn"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    :class="locale === 'ru' ? 'supplier-action-btn' : ''"
                    text
                    class="order-info-btn"
                    @click="sendEmail"
                >
                    {{ $t('send_email') }}
                </v-btn>
            </v-col>
            <slot name="additionalInfo2" />
            <v-col cols="12" md="12" class="mt-3 mb-4">
                <slot name="subtitle" />
                <slot name="travelers" :empty-additional-fields="touristEmptyAdditionalFields">
                    <div v-if="serviceLabel" class="d-flex align-center text-subtitle-1 ms-3">
                        <v-icon left small> {{ getServiceTypeIcon(service.serviceType) }}</v-icon>
                        <div class="font-weight-bold black--text">
                            {{ serviceLabel }}
                        </div>
                    </div>
                    <travelers
                        :travelers="service.travelers"
                        :product="service.serviceType"
                        :details="service.serviceDetails"
                        :service="service"
                        :order-end-date="orderEndDate"
                        :empty-additional-fields="touristEmptyAdditionalFields"
                    >
                        <template
                            v-if="
                                service.serviceType === 'CRUISE' ||
                                service.serviceDetails[0].extraServiceProductType === 'ACCOMMODATION'
                            "
                            v-slot:mealType="{traveler}"
                        >
                            <v-col cols="12" md="2" class="pb-0">
                                <small class="d-block traveler-label">{{ $t('filterTitles.mealTypes') }}</small>
                                <span
                                    v-if="service.serviceType === 'CRUISE'"
                                    class="text-uppercase black--text font-weight-bold"
                                >
                                    {{ traveler.mealType || '' }}
                                </span>
                                <span
                                    v-if="service.serviceDetails[0].extraServiceProductType === 'ACCOMMODATION'"
                                    class="text-uppercase black--text font-weight-bold"
                                >
                                    {{ service.serviceDetails[0].mealType || '' }}
                                </span>
                            </v-col>
                        </template>
                    </travelers>
                </slot>
                <referrer v-if="referrer" :referrer="referrer" />
            </v-col>
            <v-col v-if="service.extraServices.length" cols="12" md="12" class="mb-4">
                <div class="text-subtitle-1">
                    {{ $t('extra_services') }}
                </div>
                <div v-for="extraService in service.extraServices" :key="extraService.id">
                    <span>
                        {{ extraService.name }}
                        <span v-if="extraService.quantity"> - {{ getServiceDescription(extraService) }} </span>
                        <span v-if="extraService.prices && showPrice">
                            ({{
                                extraServiceClientPrice(extraService.prices).amount
                                    | priceFormat(extraServiceClientPrice(extraService.prices).currency)
                            }})
                        </span>
                        <span
                            v-if="'EARLY_CHECKIN' === extraService.type"
                            :class="statusColor(service.serviceDetails[0].earlyCheckIn.status)"
                        >
                            {{ $t(`bookingStatus.${status(service.serviceDetails[0].earlyCheckIn.status)}`) }}
                        </span>
                        <span
                            v-if="'LATE_CHECKOUT' === extraService.type"
                            :class="statusColor(service.serviceDetails[0].lateCheckOut.status)"
                        >
                            {{ $t(`bookingStatus.${status(service.serviceDetails[0].lateCheckOut.status)}`) }}
                        </span>
                    </span>
                </div>
            </v-col>
            <v-col v-if="isTO1 || service.comments" cols="12" md="12">
                <div class="text-subtitle-1">
                    {{ $t('comments') }}
                </div>
                <div>
                    <span>{{ service.comments ? service.comments : $t('you_can_add_a_comment') }}</span>
                    <v-btn v-if="isTO1" color="primary" icon x-small @click="showCommentsEditingModal">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <sent-to-supplier-modal
                v-model="isShowSentToSupplierModal"
                :process-id="service.processId"
                :order-id="order.orderId"
                :status-initial="service.serviceSentToSupplier"
            />
        </template>
    </v-row>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import OrderPrices from '~src/components/account/snippets/orderPrices.src'
    import {
        EventBus,
        ORDER_SERVICE_BOOK,
        PAY_SERVICE_EVENT,
        SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL,
        SHOW_MODIFY_MANUAL_MODAL,
        SHOW_MODIFY_MODAL_EVENT,
        SHOW_NOTIFICATION_MODAL,
        SHOW_RESERVATION_STATUS_MODAL,
        SHOW_SEND_EMAIL_MODAL,
        SHOW_SERVICE_COMMENTS_EDITING_MODAL,
        SHOW_TICKET_VOIDING_MODAL,
    } from '@/utils/event-bus'
    import {authStore, runtimeStore} from '@/store'
    import FlightOrderInfoBaseMixin from '@/components/account/mixins/FlightOrderInfoBaseMixin'
    import VoucherMixin from '@/components/account/mixins/VoucherMixin'
    import Travelers from '~src/components/account/orderServices/travelers.src'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import Referrer from '@/components/account/orderServices/Referrer'
    import {getServiceTypeIcon, UTCToDate} from '@/utils/helpers'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import {prepareBookResult, WARNING_MESSAGE_PRICE_CHANGED} from '@/utils/PrepareBookAdapter'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel.vue'
    import AddOfflineServiceDialog from '~src/components/account/offlineServices/addOfflineServiceDialog.src'
    import SentToSupplierModal from '@/components/account/modals/SentToSupplierModal'

    //TODO Need to refactoring with related part of OrderServiceBookModal
    function availabilityNotificationHtml(text, color, discount, prepareBookResult) {
        let html =
            `<div class="text-body-1 d-flex flex-column ${color}--text">
            ${text}` +
            (discount?.name
                ? `<span class="caption accent--text">
                   ${discount.label}:
              <span>
                    ${discount.name}
              </span>
            </span>`
                : '') +
            '</div>'
        if (prepareBookResult?.availableForBooking) {
            const statusColor = prepareBookResult.onRequest ? 'warning' : 'success',
                statusLabel = prepareBookResult.onRequest
                    ? this.$t('filters_availability.on_request')
                    : this.$t('filters_availability.available')
            html +=
                '<div class="text-body-1 mt-2">' +
                `${this.$t('actual_availability')}: <span class="${statusColor}--text">${statusLabel}</span>` +
                '</div>'
        }
        return html
    }
    @Component({
        components: {
            SentToSupplierModal,
            AddOfflineServiceDialog,
            SupplierNameLabel,
            Referrer,
            HotelStars,
            OrderPrices,
            CorporatePolicyViolation,
            Travelers,
        },
    })
    export default class OrderInfoBase extends mixins(FlightOrderInfoBaseMixin, VoucherMixin, ServiceBase) {
        @Prop() service
        @Prop() serviceLabel
        @Prop({default: false}) isPackage
        @Prop() serviceCheckIn
        @Prop() serviceCheckOut
        @Prop({required: true}) orderEndDate
        @Prop({default: null}) serviceImage
        @Prop({default: false}) info
        @Prop({default: null}) packageFlightStatus
        @Prop({default: false, type: Boolean}) notItineraryStart

        imgError = false
        checkAvailabilityActive = false
        isShowSentToSupplierModal = false

        cancel() {
            this.$emit('cancelService', this.service)
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        showReservationsModal(type) {
            EventBus.$emit(
                SHOW_RESERVATION_STATUS_MODAL,
                {
                    type,
                    processId: this.service.processId,
                },
                this.order.orderId
            )
        }

        showTicketVoidingModal() {
            EventBus.$emit(SHOW_TICKET_VOIDING_MODAL, {processId: this.service.processId})
        }

        getServiceDescription(extraService) {
            if (extraService.type === 'EARLY_CHECKIN') {
                return this.service.serviceDetailsContainer.accommodation.earlyCheckIn.time
            }
            if (extraService.type === 'LATE_CHECKOUT') {
                return this.service.serviceDetailsContainer.accommodation.lateCheckOut.time
            }
            return extraService.quantity
        }

        onImgError() {
            this.imgError = true
            return false
        }

        modifyService() {
            EventBus.$emit(SHOW_MODIFY_MODAL_EVENT, this.service, this.order.orderId)
        }

        book() {
            EventBus.$emit(ORDER_SERVICE_BOOK, [this.service])
        }

        async checkAvailability() {
            if (this.checkAvailabilityActive) return
            this.checkAvailabilityActive = true
            try {
                const prepareBookResponse = await this.$api.prepareBookSaveOrder.post({
                    processIds: [this.service.processId.replace(/^.{6}/, '')],
                    checkAvailability: true,
                })
                const [result] = prepareBookResult(prepareBookResponse)
                if (result.availableForBooking) {
                    if (result.warningMessages.includes(WARNING_MESSAGE_PRICE_CHANGED)) {
                        const price = this.$options.filters.formatPrice(result.price)
                        EventBus.$emit(
                            SHOW_NOTIFICATION_MODAL,
                            availabilityNotificationHtml.call(
                                this,
                                this.$t('warning_message.service_current_price', {price}),
                                'warning',
                                {
                                    name: result.price.discountDetails,
                                    label: this.$t('discount'),
                                },
                                result
                            )
                        )
                    } else {
                        EventBus.$emit(
                            SHOW_NOTIFICATION_MODAL,
                            availabilityNotificationHtml.call(
                                this,
                                this.$t('success_message.offer_available'),
                                'success',
                                {
                                    name: result.price.discountDetails,
                                    label: this.$t('discount'),
                                },
                                result
                            )
                        )
                    }
                } else {
                    EventBus.$emit(
                        SHOW_NOTIFICATION_MODAL,
                        availabilityNotificationHtml.call(
                            this,
                            this.$t('error_message.offer_not_available'),
                            'error',
                            {
                                name: result.price.discountDetails,
                                label: this.$t('discount'),
                            },
                            result
                        )
                    )
                }
            } catch (e) {
                console.error(e)
                EventBus.$emit(
                    SHOW_NOTIFICATION_MODAL,
                    availabilityNotificationHtml.call(this, this.$t('error_message.offer_not_available'), 'error')
                )
            } finally {
                this.checkAvailabilityActive = false
            }
        }

        payService() {
            EventBus.$emit(PAY_SERVICE_EVENT, {orderId: this.orderId, objectId: this.objectId})
        }

        extraServiceClientPrice(prices) {
            return prices.find(({priceType}) => priceType === 'CLIENT')
        }

        statusColor(status) {
            return runtimeStore.orderServiceStatusColor(status)
        }

        status(status) {
            return runtimeStore.orderServiceStatus(status)
        }

        modifyServiceManual() {
            EventBus.$emit(SHOW_MODIFY_MANUAL_MODAL, this.service)
        }

        sendEmail() {
            EventBus.$emit(SHOW_SEND_EMAIL_MODAL, this.order, this.service)
        }

        showDueToConfirmDateModal() {
            EventBus.$emit(SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL, this.service, this.order.orderId)
        }

        showCommentsEditingModal() {
            EventBus.$emit(SHOW_SERVICE_COMMENTS_EDITING_MODAL, {service: this.service}, this.order.orderId)
        }

        get isShowDueToConfirmBtn() {
            if (['FLIGHT', 'OWNCHARTER'].includes(this.service.serviceType) && !this.isOwnProduct) {
                return false
            }

            return (
                this.service.dueToConfirmDate && !['Canceled', 'Confirmed'].includes(this.service.status) && this.isTO1
            )
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service?.supplierCode)
        }

        get clientDiscount() {
            return this.service.salesTerms.find(({type}) => type === 'CLIENT').discount
        }

        get isTO1() {
            return authStore.isTO1
        }

        get personRole() {
            return authStore.personRole
        }

        get isShowCancelButton() {
            return (
                this.service.cancellationAllowed ||
                (this.packageFlightStatus && this.packageFlightStatus === 'PRECONFIRMED') ||
                (this.service.status === 'Confirmation pending' &&
                    this.$config.account.cancelConfirmationPendingService) ||
                (this.service.serviceType === 'EXTRASERVICE' &&
                    this.service.status === 'Confirmed' &&
                    this.$dateFns.isAfter(this.$dateFns.parseISO(this.service.startDateTime), new Date()))
            )
        }

        get voucherAvailable() {
            return runtimeStore.isServiceVoucherAvailable(this.service)
        }

        get statusForbidden() {
            return runtimeStore.isServiceStatusForbidden(this.service)
        }

        get clientPrice() {
            return this.service.salesTerms.find(price => {
                return price.type === 'CLIENT'
            })
        }

        get supplierPrice() {
            return this.service.salesTerms.find(price => {
                return price.type === 'SUPPLIER'
            })
        }

        get canPayService() {
            return (
                authStore.isAgency &&
                this.service.status === 'Confirmed' &&
                !['PAID', 'PARTLY_PAID'].includes(this.service.supplierPaymentStatus)
            )
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get isGuest() {
            return authStore.isGuest
        }

        get isConfirmationPendingStatus() {
            return this.service.status === 'Confirmation pending'
        }

        get locale() {
            return this.$i18n.locale
        }

        get isPayAtHotel() {
            return this.service?.serviceDetailsContainer.accommodation
                ? this.service.serviceDetailsContainer.accommodation.cardGuarantee
                : false
        }

        get touristEmptyAdditionalFields() {
            return false
        }

        get showImage() {
            return true
        }

        get showPrice() {
            return true
        }

        get showCheckStatusBtn() {
            return this.service.status !== 'Quote'
        }

        get referrer() {
            return this.service?.referrer
        }

        get ticketVoidingAllowed() {
            if (this.isFlight && this.service.status === 'Confirmed' && this.service.serviceDetails[0].lastVoidDate) {
                const date = UTCToDate(this.$dateFns.parseISO(this.service.serviceDetails[0].lastVoidDate))
                return this.$dateFns.isBefore(new Date(), date)
            }

            return false
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }

        get showAllServicesPrice() {
            return this.isTO1 || this.order.clientPriceType === 'FOR_ALL_SERVICES' || !this.order.clientPriceType
        }

        get showSendEmailBtn() {
            if (this.isBankFee || this.service.serviceType === 'EXTRASERVICE') {
                return false
            }

            return this.isTO1 || authStore.isTO2 || authStore.isAgency
        }

        get ownProduct() {
            return runtimeStore.ownProduct(this.service.supplierCode)
        }

        get isReservationReportMailEnabled() {
            return this.$config.reservationReport
        }

        get isBankFee() {
            return this.service.serviceDetails?.[0]?.extraServiceProductType === 'BANK_FEE'
        }
    }
</script>

<style scoped lang="scss">
    .date-icon {
        display: block;
        float: left;
    }

    .traveler-label,
    .title {
        line-height: 1;
    }

    .cancel-link,
    .modify-link {
        font-size: 14px;
        font-weight: normal;
        display: block;
        text-align: center;
    }

    .order-info-btn {
        width: 100%;
        font-size: 0.75rem;
    }

    .supplier-action-btn {
        font-size: 0.6rem;
    }

    .order-service-buttons .v-btn {
        white-space: normal;
    }
</style>
