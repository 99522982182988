<template>
    <div>
        <div class="font-weight-regular">
            {{ $t(title) }}
        </div>
        <div v-for="(orderPrice, index) in orderPrices" :key="index">
            <div class="info-text title text-no-wrap black--text font-weight-bold">
                {{ orderPrice.amount | priceFormat(orderPrice.currency) }}
            </div>
            <div v-if="orderPrice.commission && commissionAndNettoCheckbox" class="d-flex flex-column">
                <span class="commission-block">
                    <span class="font-weight-regular"> {{ $t('commission') }}: </span>
                    {{ orderPrice.commission | priceFormat(orderPrice.currency) }}
                </span>
                <span class="commission-block">
                    <span class="font-weight-regular"> {{ $t('net_amount') }}: </span>
                    {{ (orderPrice.amount - orderPrice.commission) | priceFormat(orderPrice.currency) }}
                </span>
            </div>
        </div>
        <payment-status-label :status="paymentStatus" :card-guarantee="serviceCardGuarantee" />
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import {persistentStore} from '@/utils/store-accessor'

    @Component({
        components: {PaymentStatusLabel},
    })
    export default class TotalOrderPrices extends Vue {
        @Prop({required: true}) title
        @Prop({required: true}) orderPrices
        @Prop({required: true}) paymentStatus
        @Prop({required: true}) serviceCardGuarantee
        @Prop() services

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }
    }
</script>
