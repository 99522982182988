<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <activity-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <activity-order-summary
                :product="product"
                :offers="[offer]"
                :info="basketItem.info"
                :selected-add-ons="selectedAddOns"
                :extra-services="extraServices"
                :additional-options="bookingAdditionalOptions"
            />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import ActivityOrderSummary from '@/components/activities/booking/ActivityOrderSummary'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'

    @Component({
        components: {
            ActivityBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            ActivityOrderSummary,
        },
        layout: 'blank',
    })
    export default class ActivityConfirmationPage extends mixins(
        ActivityPageMixin,
        ActivityStoreMixin,
        ConfirmationPageMixin
    ) {
        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }
    }
</script>
