<template>
    <v-card outlined data-cy="hotel-room-offers">
        <v-card-title v-if="filteredOffer" class="title font-weight-light align-baseline">
            {{ $t('available_room_list') }}
            <span
                class="ml-4 text-body-2 primary--text cursor-pointer offers-filter-select-btn"
                @click="expanded = !expanded"
            >
                {{ $t('filters') }}
                <v-icon color="primary" small>
                    {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </span>
        </v-card-title>
        <v-card-title v-else-if="!isSearchActive && loaded" class="title font-weight-light">
            {{ $t('no_available_rooms') }}
        </v-card-title>
        <v-expand-transition v-model="expanded">
            <v-row v-if="expanded" no-gutters class="mx-4">
                <v-col cols="12" md="9" class="mb-6 mb-md-0">
                    <room-filters :filter-values="filtersValues" @change="setFilters" />
                </v-col>
                <v-col cols="12" md="3">
                    <group-offers-select
                        :items="['none', 'name', 'mealTypeStandardCode']"
                        :product-store="productStore"
                        token-base="roomGroupBy"
                    />
                </v-col>
            </v-row>
        </v-expand-transition>
        <hotel-room-offers
            v-if="loaded"
            :offer="filteredOffer"
            :_selected-offer-keys.sync="selectedOfferKeys"
            :search-request="searchRequest"
            :hotel-info="hotelInfo"
        >
            <template v-slot:price="{room}">
                <slot name="price" :room="room" />
            </template>
        </hotel-room-offers>
        <template v-if="isSearchActive && !filteredOffer">
            <v-card-title v-if="!filteredOffer" class="title font-weight-light">
                {{ $t('loading_room_offers') }}...
            </v-card-title>
            <v-skeleton-loader transition="fade-transition" height="100" type="list-item-three-line" />
        </template>
        <v-card-actions v-if="searchRoomsCount > 1 && filteredOffer" class="flex-column">
            <hotel-multiple-room-select-block
                :search-request="searchRequest"
                :selected-offer-keys="selectedOfferKeys"
                :offer="filteredOffer"
            />
        </v-card-actions>
        <conditions-modal>
            <template v-slot:info>
                <hotel-conditions-modal-info />
            </template>
            <template v-slot:hotelPriceNote>
                <hotel-price-note :search-request="searchRequest" />
            </template>
        </conditions-modal>
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
        <additional-fees-modal />
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '@/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import HotelRoomOffers from '~/components/search/offers/HotelRoomOffers'
    import HotelRoomInfoModal from '~/components/modals/HotelRoomInfoModal'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo'
    import HotelMultipleRoomSelectBlock from '~src/components/hotels/snippets/hotelMultipleRoomSelectBlock.src'
    import AdditionalFeesModal from '~src/components/modals/AdditionalFeesModal'
    import SortSelect from '@/components/search/SortSelect'
    import GroupOffersSelect from '~src/components/search/GroupOffersSelect'
    import RoomFilters from '~src/components/search/filters/RoomFilters'
    import hotelsFilter from '@/filters/hotelsFilter'

    @Component({
        components: {
            AdditionalFeesModal,
            HotelMultipleRoomSelectBlock,
            HotelConditionsModalInfo,
            HotelMealTypeInfoModal,
            HotelPriceNote,
            ConditionsModal,
            HotelRoomOffers,
            HotelRoomInfoModal,
            SortSelect,
            GroupOffersSelect,
            RoomFilters,
        },
    })
    export default class HotelInfoRoomOffersWrapper extends Vue {
        @Prop({required: true}) searchRequest
        @Prop({required: true}) offer

        selectedOfferKeys = []
        loaded = false
        filters = {
            mealTypes: [],
            roomTypes: [],
            availability: [],
        }
        expanded = false

        async mounted() {
            await this.$store.restored
            this.loaded = true
        }

        setFilters(val) {
            this.filters.mealTypes = val.mealTypes ? val.mealTypes : []
            this.filters.roomTypes = val.roomTypes ? val.roomTypes : []
            this.filters.availability = val.availability ? val.availability : []
        }

        get filteredOffer() {
            return this.offer ? hotelsFilter([this.offer], this.filters)[0] : null
        }

        get filtersValues() {
            return hotelsStore.singleHotelSearchResponse.filters
        }

        get isSearchActive() {
            return hotelsRuntimeStore.singleHotelSearchActive
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms?.length || 1
        }

        get hotelInfo() {
            return hotelsRuntimeStore.hotelInfoResponse
        }

        get productStore() {
            return hotelsStore
        }
    }
</script>
