<template>
    <div :style="contentStyle">
        <v-btn x-small fab class="cursor-pointer" elevation="0" @click="wishlisted ? unlike() : like()">
            <v-icon :color="wishlisted ? 'error' : 'secondary'"
                >{{ wishlisted ? 'mdi-cards-heart' : 'mdi-cards-heart-outline' }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {appInstance} from '@/utils/app-accessor'
    import {updateHotelWishlist} from '@/utils/hotels/hotel-helpers'

    @Component
    export default class WishlistLike extends Vue {
        @Prop() info
        @Prop() query
        @Prop() person
        @Prop() productRuntimeStore
        @Prop() inWishlist
        @Prop({default: 'position: absolute; right: 12px; top: 4px'}) contentStyle

        wishlisted = false

        async mounted() {
            if (this.userId) this.wishlisted = this.inWishlist
            else {
                const cachedOffers = (await appInstance.$localForage.getItem(`${this.query.productType}Wishlist`)) || []
                const index = cachedOffers.findIndex(
                    ({supplierCode, cityCode, hotelCode}) =>
                        this.query.supplierCode === supplierCode &&
                        this.query.cityCode === cityCode &&
                        this.query.hotelCode === hotelCode
                )
                if (index >= 0) this.wishlisted = cachedOffers[index].wishlist
            }
        }

        async like() {
            try {
                if (this.userId) {
                    await this.$api.wishlist.post({userId: this.userId, ...this.info, ...this.query})
                } else if (this.query.productType === 'hotel') {
                    const cachedOffers =
                        (await appInstance.$localForage.getItem(`${this.query.productType}Wishlist`)) || []
                    updateHotelWishlist(cachedOffers, {...this.info, ...this.query}, true)
                    appInstance.$localForage.setItem(`${this.query.productType}Wishlist`, cachedOffers)
                }
                this.wishlisted = true
                await this.productRuntimeStore.updateWishlist({
                    ...this.info,
                    ...this.query,
                    isWishlist: true,
                })
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.wishlisted = false
            }
        }

        async unlike() {
            try {
                if (this.userId) {
                    await this.$api.wishlist.remove({userId: this.userId, ...this.query})
                } else if (this.query.productType === 'hotel') {
                    const cachedOffers =
                        (await appInstance.$localForage.getItem(`${this.query.productType}Wishlist`)) || []
                    updateHotelWishlist(cachedOffers, {...this.info, ...this.query}, false)
                    appInstance.$localForage.setItem(`${this.query.productType}Wishlist`, cachedOffers)
                }
                this.wishlisted = false
                await this.productRuntimeStore.updateWishlist({
                    ...this.query,
                    isWishlist: false,
                })
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.wishlisted = true
            }
        }

        get userId() {
            return this.person?.personId
        }
    }
</script>
