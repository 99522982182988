<template>
    <v-card outlined hover @click="$emit('selected', destination.id)">
        <v-img :src="destination.img | srcHost" :lazy-src="'/placeholder.png' | srcHost" eager :height="250" />
        <v-card-text class="d-flex align-center">
            <span class="fi me-2" :class="`fi-${destination.countryCode}`" />
            <span class="headline">
                {{ destination.name }}
            </span>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'

    @Component
    export default class PopularDestination extends Vue {
        @Prop({required: true}) destination
    }
</script>
