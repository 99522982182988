<script>
    import {Component, Watch} from 'nuxt-property-decorator'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'

    @Component()
    export default class CountryAutocomplete extends CityAutocomplete {
        @Watch('value')
        onChangeValue(val) {
            this.city = val
            if (val && Object.keys(val).length && !this.entries.find(({id}) => id === val.id)) {
                this.entries = [val]
            } else if (!val || !Object.keys(val).length) {
                this.entries = this.defaultCities
            }
        }

        async searchCountries(val) {
            const rq = {
                pattern: val.trim() + '%',
                limitCountries: 10,
                orderBy: 'POPULARITY',
                ...(this.productType && {productType: this.productType}),
            }
            const rs = await this.$api.locations.get(rq)
            if (rs.countries.length === 0) {
                this.tryResearch.status = true
                this.tryResearch.message = this.isMobile
                    ? this.$t('try_rewrite_destination_mobile')
                    : this.$t('try_rewrite_destination')
            }
            return rs.countries
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                this.entries = await this.searchCountries(val)
            } finally {
                this.loading = false
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get items() {
            return this.entries.map(entry => {
                const label = entry.name
                return Object.assign({}, entry, {label})
            })
        }
    }
</script>
