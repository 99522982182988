<template>
    <div>
        <strong>{{ $t('from') }} {{ offer.price | price }}</strong>
        <discount-label :price="offer.price" />
        <commission-amount :commission="offer.price.commission" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'

    @Component({
        components: {CommissionAmount, DiscountLabel},
    })
    export default class CruiseCardPriceBlock extends Vue {
        @Prop({default: () => ({})}) offer
    }
</script>
