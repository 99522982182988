<template>
    <div>
        <selected-filters :selected="filterValues" :translatable="[]" @delete-filter="clearFilter" />
        <price-filter
            v-model="filterValues"
            :filters="filters"
            :label="$t('price')"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :label="$t('name')"
            :filters="filters"
            filter="name"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :label="$t('description')"
            :filters="filters"
            filter="shortDescription"
            @change="change($event)"
        />
        <filtered-mobile-button
            v-if="$breakpoint.smAndDown"
            class="sticky-button mt-6"
            :count="filteredOffersCount"
            :visible="Object.keys(filterValues).length > 0"
            @change-mode="goToOffers"
            @clear-filters="reset"
        />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import FilteredMobileButton from '~/components/snippets/FilteredMobileButton'
    import NameFilter from '~/components/filters/NameFilter'
    import SelectedFilters from '~/components/snippets/SeletedFilters'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import FiltersBase from '@/components/search/FiltersBase'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'

    @Component({
        components: {
            CheckboxFilter,
            PriceFilter,
            FilteredMobileButton,
            NameFilter,
            SelectedFilters,
        },
    })
    export default class ExtraServiceFilters extends FiltersBase {}
</script>
