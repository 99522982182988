<template>
    <v-card class="my-2 product-card" :class="{pointer: comparisonMode}" outlined :hover="comparisonMode">
        <component
            :is="comparisonMode || isPackage || isAddOn ? 'span' : 'nuxt-link'"
            no-prefetch
            :to="productLink"
            class="title text-decoration-none"
        >
            <v-img
                class="white--text align-end"
                height="200"
                :src="thumb | imageUrl(600)"
                :alt="name"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="imgError = true"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
                <v-card-title>
                    <slot name="cardTitle">
                        <div class="break-word text-subtitle-1">
                            {{ name }}
                        </div>
                    </slot>
                </v-card-title>
            </v-img>
            <recommended-icon v-if="isRecommended" class="recommended-thumb" />
        </component>
        <div class="mx-4">
            <slot />
            <div class="d-flex justify-space-between mt-3">
                <slot name="price" />
                <v-btn v-if="!isAddOn" depressed color="primary" nuxt :to="productLink">
                    {{ $t('book') }}
                </v-btn>
            </div>
        </div>
        <v-expand-transition>
            <div v-if="expandedCount" class="mt-3">
                <slot name="offers" />
            </div>
        </v-expand-transition>
        <v-btn block depressed class="mt-3" data-cy="product-card-toggle-offers" @click="toggleOffers">
            {{ expandedCount ? $t('hide') : $t('show') }}
            <v-icon>mdi-chevron-{{ expandedCount ? 'up' : 'down' }}</v-icon>
        </v-btn>
    </v-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ProductCardLayout from '~/components/search/ProductCardLayout'
    import RecommendedIcon from '@/components/search/RecommendedIcon'

    @Component({
        components: {RecommendedIcon},
    })
    export default class ProductCardLayoutMobile extends ProductCardLayout {
        @Prop({default: false, type: Boolean}) isPackage
        @Prop({default: false, type: Boolean}) isAddOn
    }
</script>

<style scoped lang="scss">
    .product-card {
        border: none !important;
    }

    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }

    .break-word {
        word-break: break-word;
    }

    ::v-deep .v-expansion-panel {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .recommended-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
    }
</style>
