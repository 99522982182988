<template>
    <td>
        <v-text-field
            class="text-center"
            :value="service.availableQuantity"
            flat
            hide-details
            @change="changeRoomAvailability"
        />
    </td>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component({})
    export default class RoomToSellCell extends Vue {
        @Prop() date
        @Prop() service

        get roomBg() {
            return 'yellow'
        }

        changeRoomAvailability(value) {
            this.$emit('update', {
                dateFrom: this.date,
                dateTo: this.date,
                categoryId: this.service.categoryId,
                serviceId: this.service.serviceId,
                operation: 'SET',
                dayOfWeek: [true, true, true, true, true, true, true],
                amount: value,
            })
        }
    }
</script>

<style scoped lang="scss"></style>
