import { render, staticRenderFns } from "./CalendarFilters.vue?vue&type=template&id=2a040375&scoped=true&"
import script from "./CalendarFilters.vue?vue&type=script&lang=js&"
export * from "./CalendarFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a040375",
  null
  
)

export default component.exports