<template>
    <div class="d-flex">
        <div v-for="(lang, i) in langCodes" :key="lang" class="d-flex">
            <language-label :language="lang" translate />
            <div v-if="langCodes.length > 1 && i !== langCodes.length - 1">&nbsp; | &nbsp;</div>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import LanguageLabel from '@/components/snippets/LanguageLabel'

    @Component({
        components: {LanguageLabel},
    })
    export default class TransferDriverLanguages extends Vue {
        @Prop({required: true}) langCodes
    }
</script>
