<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class NewOrderRequestNote extends Vue {
        @Prop({required: true}) tour

        render() {
            return null
        }
    }
</script>
