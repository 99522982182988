<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="filteredOffers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <hotel-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <hotel-search-summary :search-request="searchRequest" />
            <sort-select v-if="searchResponse.offersCount" v-model="sortKey" :items="sortItems" />
            <group-offers-select
                class="pt-3"
                :items="['none', 'name', 'mealTypeStandardCode']"
                :product-store="productStore"
                token-base="roomGroupBy"
            />
            <hotel-filters
                v-if="searchResponse.offersCount"
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :nights="nights"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
            <overlay-progress :active="filterActive && !searchActive" />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.offersCount"
                :search-active="searchActive"
                :hide-stop-btn="$route.name === 'flightsAvailability'"
                product-type="hotel"
                @stop="stopSearch"
            />
            <hotel-offers :page="page" />
            <pagination :page-prop.sync="page" :pages="pages" />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <hotel-map :city="city" :_expanded.sync="mapExpanded" :offers="filteredOffers" location-filter />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import HotelOffers from '~src/components/search/offers/hotelOffers.src'
    import HotelFilters from '~src/components/search/filters/hotelFilters.src'
    import Pagination from '~/components/search/Pagination'
    import HotelSearchSummary from '~src/components/search/hotelSearchSummary.src'
    import {hotelsRuntimeStore, hotelsStore, persistentStore} from '~/store'
    import {CHANGE_LOCALE_EVENT, EventBus, FILTER_EVENT, HIDE_CIRCLE_ON_MAP} from '~/utils/event-bus'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'
    import {clone, isEqual} from '@/utils/helpers'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '@/components/search/SortSelect'
    import OverlayProgress from '@/components/OverlayProgress'
    import HotelMap from '~src/components/search/offers/hotelMap.src'
    import HotelDataPageMixin from '~src/pages/hotels/mixins/hotelDataPageMixin.src'
    import GroupOffersSelect from '@/components/search/GroupOffersSelect'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import NuxtPageMixin from '@/mixins/NuxtPageMixin.vue'

    @Component({
        components: {
            OverlayProgress,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            HotelBreadcrumbs,
            MobileSearchPageTabs,
            HotelSearchSummary,
            HotelOffers,
            HotelFilters,
            Pagination,
            HotelMap,
            SearchLayout,
            GroupOffersSelect,
        },
        //middleware: 'searchHotels',
        //watchQuery: true,
        layout: 'blank',
    })
    export default class HotelsPage extends mixins(
        HotelStoreMixin,
        HotelDataPageMixin,
        SearchPageAuthMixin,
        NuxtPageMixin
    ) {
        mapExpanded = false
        loaded = false
        resetPageLock = false

        @Watch('$route.query')
        async onQueryChange() {
            if (!(await this.checkSearchDate())) return
            await hotelsRuntimeStore.search(this.searchRequest)
        }

        validate({query}) {
            return query.cityId
        }

        async fetch() {
            if (hotelsRuntimeStore.city.id !== this.cityId) {
                await hotelsRuntimeStore.loadCity(this.cityId)
            }
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        async load() {
            await this.fetchStatePending
            this.loaded = true
            if (!(await this.checkSearchDate())) return
            if (
                !isEqual(hotelsStore.searchRequest, this.searchRequest) ||
                hotelsStore.isOffersExpired() ||
                !hotelsStore.hasOffers
            ) {
                await this.search()
            } else if (hotelsRuntimeStore.filteredOffers.length === 0) {
                const offers = await this.$localForage.getItem('hotels')
                this.resetPageLock = true
                hotelsRuntimeStore.load(offers)
            }
        }

        created() {
            EventBus.$on(FILTER_EVENT, this.resetPage)
            EventBus.$on(CHANGE_LOCALE_EVENT, this.search)
        }

        beforeDestroy() {
            EventBus.$off(FILTER_EVENT, this.resetPage)
            hotelsRuntimeStore.SET_FILTERED_OFFERS([])
            EventBus.$off(CHANGE_LOCALE_EVENT, this.search)
        }

        resetPage() {
            if (this.resetPageLock) {
                this.resetPageLock = false
            } else {
                this.page = 1
            }
        }

        isOffersExpired() {
            return hotelsStore.isOffersExpired()
        }

        async search() {
            await hotelsRuntimeStore.search(this.searchRequest)
        }

        changeFilter(data) {
            hotelsStore.SET_FILTER(data)
            hotelsRuntimeStore.filter()
            if (data.key === 'distance' && !data.value?.length) {
                EventBus.$emit(HIDE_CIRCLE_ON_MAP)
            }
            if (data.key === 'distance' && data.value?.length) {
                const value = [...data.value][data.value.length - 1]
                const runtimeFilters = clone(hotelsRuntimeStore.runtimeFilters)

                if (value !== 'e_more_than_5') {
                    const radius = value.split('_')[3] * 1000
                    runtimeFilters.map.radius = radius
                    hotelsRuntimeStore.SET_RUNTIME_FILTERS(runtimeFilters)
                } else {
                    runtimeFilters.map.radius = 50000
                    hotelsRuntimeStore.SET_RUNTIME_FILTERS(runtimeFilters)
                }
            }
        }

        resetFilters() {
            hotelsStore.RESET_FILTERS()
            hotelsRuntimeStore.filter()
        }

        stopSearch() {
            this.productRuntimeStore.stopSearch()
        }

        get pages() {
            return Math.ceil(hotelsRuntimeStore.filteredOffers.length / this.$config.search.resultsOnPage)
        }

        get searchRequest() {
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.rooms === 'string') {
                rq.rooms = [decodeURIComponent(rq.rooms)]
            } else {
                rq.rooms = rq.rooms.map(room => decodeURIComponent(room))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.cityId = this.cityId
            if (this.$config.searchAccommodationByDistance && this.city.latitude && this.city.longitude) {
                Object.assign(rq, {
                    distance: rq.distance ? parseInt(rq.distance, 10) : this.$config.searchAccommodationByDistance,
                    latitude: this.city.latitude,
                    longitude: this.city.longitude,
                })
            }
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            rq.freeOnly = rq.freeOnly === 'true' || rq.freeOnly === true
            return rq
        }

        get city() {
            return hotelsRuntimeStore.city
        }

        get sortKey() {
            return hotelsStore.sortFnName
        }

        set sortKey(sortKey) {
            hotelsStore.SET_SORT_FN_NAME(sortKey)
            hotelsRuntimeStore.sort(this.filteredOffers)
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get searchResponse() {
            return hotelsStore.searchResponse
        }

        get filteredOffers() {
            return hotelsRuntimeStore.filteredOffers
        }

        get filterValues() {
            return hotelsStore.filters
        }

        get page() {
            return hotelsStore.searchPage
        }

        set page(page) {
            hotelsStore.SET_SEARCH_PAGE(page)
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get filterActive() {
            return this.productRuntimeStore.filterActive
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.recommended'),
                    value: 'recommended',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
                {
                    text: this.$t('sort.hotel_name'),
                    value: 'name',
                },
                {
                    text: this.$t('sort.category_desc'),
                    value: 'categoryDesc',
                },
                {
                    text: this.$t('sort.category_asc'),
                    value: 'categoryAsc',
                },
            ]
        }

        get currency() {
            return this.productStore.searchRequest.convertToCurrency !== 'ORIGINAL'
                ? this.productStore.searchRequest.convertToCurrency
                : this.filteredOffers[0]?.rooms[0].price.currency
        }
    }
</script>
