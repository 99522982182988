<template>
    <v-alert
        v-if="selectedAgent"
        dense
        dark
        color="grey darken-2"
        class="add-service-snackbar cursor-pointer mb-0 rounded-0 sticky-bar"
    >
        <template v-slot:prepend>
            <v-icon>mdi-account-search</v-icon>
            <span class="ms-2" v-text="snackbarText" />
        </template>
        <template v-slot:close>
            <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{on}">
                    <v-btn color="white" icon class="close-btn" v-on="on">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        {{ `${$t('close')} ${$t('choose.agent_dialog_header').toLowerCase()}?` }}
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn depressed color="primary" @click="close"> ok </v-btn>
                        <v-btn depressed @click="dialog = false">
                            {{ $t('cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-alert>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {persistentStore} from '~/store'

    @Component
    export default class AgentSearchSnackbar extends Vue {
        dialog = false

        get selectedAgent() {
            return persistentStore.selectedAgent
        }

        get snackbarText() {
            return `${this.$t('search')} ${this.$t('and')} ${this.$t('booking')} ${this.$t('to_room')} ${
                this.selectedAgent.companyName
            }${this.selectedAgent.firstName || this.selectedAgent.lastName ? ',' : ''} ${
                this.selectedAgent.firstName || ''
            }  ${this.selectedAgent.lastName || ''}`
        }

        close() {
            persistentStore.SET_SELECTED_AGENT(null)
            this.dialog = false
        }
    }
</script>
