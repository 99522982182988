<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {toursRuntimeStore, toursStore} from '@/store'

    @Component
    export default class TourStoreMixin extends Vue {
        get productStore() {
            return toursStore
        }

        get productRuntimeStore() {
            return toursRuntimeStore
        }

        get productType() {
            return 'tour'
        }
    }
</script>
