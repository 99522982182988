<template>
    <v-autocomplete
        v-model="airlineCode"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-value="code"
        :label="label"
        no-filter
        clearable
        :rules="rules"
        :disabled="disabled"
        :outlined="outlined"
        :hide-details="hideDetails"
        :single-line="singleLine"
        :dense="dense"
    >
        <template v-slot:item="{item}">
            <v-icon small left>mdi-airplane-takeoff</v-icon>
            {{ itemText ? `${item[itemText]}` : `${item.name.en}` }}
        </template>
        <template v-slot:selection="{item}"> {{ itemText ? `${item[itemText]}` : `${item.name.en}` }}</template>
    </v-autocomplete>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import AirlinesSearchMixin from '@/mixins/AirlinesSearchMixin'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component
    export default class AirlinesSearchInput extends mixins(AirlinesSearchMixin, LoadMixin) {
        @VModel() airlineCode
        @Prop() rules
        @Prop() label
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) hideDetails
        @Prop({default: false, type: Boolean}) singleLine
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: null}) itemText

        items = []
        search = null
        loading = false
        timer = null

        @Watch('search')
        onSearch(val) {
            if (!val) return
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.initSearch({
                    pattern: val,
                })
            }, 400)
        }

        async mounted() {
            await this.$nextTick

            if (this.airlineCode) {
                await this.initSearch({
                    pattern: this.airlineCode,
                })
            }
        }

        async initSearch(rq) {
            this.loading = true
            try {
                this.items = (await this.searchAirlines(rq)).map(airline => ({
                    code: airline.codes.IATA || airline.codes.SUPPL,
                    ...airline,
                }))
            } finally {
                this.loading = false
            }
        }

        async load() {
            if (!this.airlineCode) return
            try {
                this.loading = true
                this.items = await this.searchAirlines({
                    pattern: this.airlineCode,
                })
                this.airline = this.items[0]
            } finally {
                this.loading = false
            }
        }
    }
</script>
