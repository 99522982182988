<script>
    import {Component, Prop, Watch} from 'nuxt-property-decorator'
    import HotelMap from '@/components/search/offers/HotelMap'
    import {clone} from '@/utils/helpers'

    @Component
    export default class TransferMap extends HotelMap {
        @Prop() points
        @Prop({default: null}) parent

        directionsDisplay = null

        @Watch('points', {deep: true})
        onPointsChange() {
            if (!this.google) return
            this.removeMarkers()
            this.setMarkers()
        }

        async load() {
            await this.initMap()
            this.onPointsChange()
        }

        async setMarkers() {
            let points = clone(this.points)
            if (!points[0].latitude) {
                points[0] = await this.getCoordinates(points[0])
            }
            if (!points[1].latitude) {
                points[1] = await this.getCoordinates(points[1])
            }
            this.makeDirection(points)
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: true,
                zoomControl: true,
                fullscreenControl: true,
                zoom: 8,
            }
        }

        makeDirection(points) {
            if (this.directionsDisplay) {
                this.directionsDisplay.setMap(null)
            }
            const directionsDisplay = new this.google.maps.DirectionsRenderer()
            const request = {
                origin: new this.google.maps.LatLng(points[0].latitude, points[0].longitude),
                destination: new this.google.maps.LatLng(points[1].latitude, points[1].longitude),
                travelMode: this.google.maps.DirectionsTravelMode.DRIVING,
            }
            const directionsService = new this.google.maps.DirectionsService()
            directionsService.route(request, (response, status) => {
                if (status === this.google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response)
                    directionsDisplay.setMap(this.map)
                    this.directionsDisplay = directionsDisplay
                } else if (status === this.google.maps.DirectionsStatus.ZERO_RESULTS) {
                    this.addMarkersWithoutDirection(points)
                }
            })
        }

        addMarkersWithoutDirection(points) {
            const templateColor = this.$vuetify.theme.themes.light.primary

            const markerIcon = {
                path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                fillColor: templateColor,
                fillOpacity: 0.95,
                scale: 2,
                strokeColor: '#fff',
                strokeWeight: 3,
                anchor: new this.google.maps.Point(12, 24),
            }
            points.forEach(point => {
                const marker = new this.google.maps.Marker({
                    position: {
                        lat: point.latitude,
                        lng: point.longitude,
                    },
                    map: this.map,
                    icon: markerIcon,
                    info: point,
                })
                this.markers.push(marker)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            if (
                this.points.length === 2 &&
                this.points[0].latitude === this.points[1].latitude &&
                this.points[0].longitude === this.points[1].longitude
            ) {
                this.map?.setZoom(12)
                this.map?.setCenter({lat: this.points[0].latitude, lng: this.points[0].longitude})
            } else {
                this.map.fitBounds(this.bounds)
            }
        }

        removeMarkers() {
            this.markers.forEach(marker => {
                marker.setMap(null)
            })
            this.markers = []
        }

        async getCoordinates(point) {
            if (point.type === 'hotel') {
                const [hotel] = await this.$api.hotels.get({hotelId: point.id})
                if (hotel) {
                    point.latitude = hotel.lat
                    point.longitude = hotel.lng
                    return new Promise(resolve => resolve(point))
                }
            }
            return new Promise(resolve => {
                const address = point.hotelName
                    ? `${point.hotelName}, ${point.hotelAddress}`
                    : `${point.name}, ${point.cityName}, ${point.countryName}`
                this.geocoder.geocode({address}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        return
                    }
                    point.latitude = results[0].geometry.location.lat()
                    point.longitude = results[0].geometry.location.lng()
                    return resolve(point)
                })
            })
        }

        yPos() {
            const y = !this.isMobile
                ? this.$parent.$parent.$el.parentElement.getBoundingClientRect().y
                : this.getMobileYPosition()
            return y > 0 ? y : 0
        }

        getMobileYPosition() {
            return !this.parent ? this.$parent.$refs.mobileTabs.$el.offsetHeight : 0
        }

        saveMapPosition() {}
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/components/VTextField/_variables.scss';

    .map {
        position: fixed;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            margin-left: -$container-padding-x;
        }

        > div {
            height: 100%;
        }
    }
</style>
