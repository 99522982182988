<template>
    <div>
        <v-expand-transition>
            <v-data-table
                v-show="groupDetails"
                :headers="headers"
                :items="guestsPrepared"
                :items-per-page="15"
                :mobile-breakpoint="600"
                class="elevation-1"
            >
                <template v-slot:item.name="{item}">
                    <div class="d-flex align-center">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-center">
                                <v-slide-x-transition>
                                    <v-checkbox
                                        v-if="
                                            isExcursion &&
                                            (!selectedGuests[0] || selectedGuests[0].orderId === item.orderId)
                                        "
                                        v-model="selectedGuests"
                                        class="me-2"
                                        :value="item"
                                    />
                                </v-slide-x-transition>
                                <div class="d-flex flex-column py-6">
                                    <span class="font-weight-medium">{{ `${item.firstName} ${item.lastName}` }} </span>
                                    <span
                                        v-if="item.departure.pickUpSign"
                                        style="white-space: nowrap"
                                        class="text-caption text--secondary font-weight-medium"
                                    >
                                        {{ `${$t('transfers.pickup_sign')}: ${item.departure.pickUpSign}` }}
                                    </span>
                                    <span
                                        v-if="!item.adult"
                                        style="white-space: nowrap"
                                        class="text-caption text--secondary"
                                        >{{ `${$t('age')}: ${item.age}` }}</span
                                    >
                                </div>
                            </div>
                            <div
                                v-for="guest in item.extraGuests"
                                :key="guest.id"
                                class="d-flex align-center"
                                style="border-top: 1px solid #eeeeee"
                            >
                                <v-slide-x-transition>
                                    <v-checkbox
                                        v-if="
                                            isExcursion &&
                                            (!selectedGuests[0] || selectedGuests[0].orderId === guest.orderId)
                                        "
                                        v-model="selectedGuests"
                                        class="me-2"
                                        :value="guest"
                                    />
                                </v-slide-x-transition>
                                <div class="d-flex flex-column py-6">
                                    <span class="font-weight-medium">{{ `${guest.firstName} ${guest.lastName}` }}</span>
                                    <div class="d-flex">
                                        <span
                                            v-if="guest.departure.pickUpSign"
                                            style="white-space: nowrap"
                                            class="text-caption text--secondary font-weight-medium"
                                        >
                                            {{ `${$t('transfers.pickup_sign')}: ${guest.departure.pickUpSign}` }}
                                        </span>
                                    </div>
                                    <span
                                        v-if="!guest.adult"
                                        style="white-space: nowrap"
                                        class="text-caption text--secondary"
                                        >{{ `${$t('age')}: ${guest.age}` }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.orderId="{item}">
                    <span
                        class="primary--text text-decoration-underline cursor-pointer"
                        @click="showPreviewModal(item.orderId)"
                    >
                        {{ item.orderId }}
                    </span>
                </template>
                <template v-slot:item.arrived="{item}">
                    <v-switch v-model="item.arrivedAll" @change="setArrived(item, $event)" />
                </template>
                <template v-slot:item.orderStatus="{item}">
                    <span :class="item.statusColor">
                        {{ $t(`bookingStatus.${item.orderStatus.toUpperCase()}`) }}
                    </span>
                </template>
                <template v-slot:item.accommodation="{item}">
                    <div v-if="item.accommodation" class="d-flex flex-column py-2">
                        <div class="d-flex font-weight-medium justify-end justify-md-start">
                            <v-icon small class="me-1 ms-n1 d-inline-block">{{ 'mdi-office-building' }}</v-icon>
                            <span v-text="item.accommodation.hotelName" />
                        </div>
                        <div>
                            {{ `${item.accommodation.counrtyName}, ${item.accommodation.cityName}` }}
                        </div>
                        <div class="text--secondary">
                            {{
                                `${getDateFormatted(item.accommodation.startDateTime)} - ${getDateFormatted(
                                    item.accommodation.endDateTime
                                )}`
                            }}
                        </div>
                    </div>
                </template>
                <template v-slot:item.departure="{item}">
                    <div v-if="item.departure" class="d-flex flex-column py-2">
                        <div>
                            {{ `${item.departure.place}` }}
                        </div>
                    </div>
                </template>
                <template v-slot:item.arrival="{item}">
                    <div v-if="item.arrival" class="d-flex flex-column py-2">
                        <div>
                            {{ `${item.arrival.place}` }}
                        </div>
                    </div>
                </template>
                <template v-slot:item.pricePrintable="{item}">
                    <div class="d-flex flex-column">
                        <span> {{ `${item.pricePrintable}` }} </span>
                        <payment-status-label :status="item.paymentStatus" />
                    </div>
                </template>
            </v-data-table>
        </v-expand-transition>
        <v-dialog v-model="reservationModal" max-width="1200">
            <v-card>
                <v-card-text class="px-0">
                    <scheduler-reservation-preview
                        v-if="reservationModal"
                        :table-columns="tableColumns"
                        :selected-order-id="selectedOrderId"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="grey" depressed text @click="reservationModal = false">{{ $t('cancel') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-expand-transition>
            <div class="d-flex">
                <v-spacer v-if="isMobile" />
                <v-btn
                    v-if="selectedGuests[0] && isExcursion"
                    class="my-4"
                    :color="'primary'"
                    @click="showCalendarModal"
                >
                    {{ $t('up_sell') }}
                </v-btn>
            </div>
        </v-expand-transition>
        <activity-calendar-modal
            v-model="isShowCalendarModal"
            :city="city"
            :query-params="activityCalendarQueryParams"
            @close="clearSelectedOrderId"
        />
    </div>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import GroupDetailsMobileDialog from '@/components/scheduler/modals/GroupDetailsMobileDialog'
    import ActivityCalendarModal from '@/components/activities/modals/ActivityCalendarModal'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import {adult, child} from '@/utils/tourist-blank-states'
    import SchedulerReservationPreview from '@/components/scheduler/SchedulerReservationPreview'

    @Component({
        components: {PaymentStatusLabel, ActivityCalendarModal, GroupDetailsMobileDialog, SchedulerReservationPreview},
    })
    export default class GroupDetailsTable extends Vue {
        @VModel() groupDetails
        @Prop() selectedGroup
        @Prop() city

        selectedGuests = []
        isShowCalendarModal = false

        reservationModal = false
        selectedOrderId = null

        async setArrived({serviceId}, event) {
            try {
                await this.$api.schedulerGroupPresence.post({
                    serviceId,
                    groupId: this.selectedGroup.groupId,
                    arrived: event,
                })
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        getDateFormatted(dateStr) {
            return this.$dateFns.format(new Date(dateStr), 'dd MMM yyyy')
        }

        showCalendarModal() {
            const tourists = this.selectedGuests.map(e => {
                return e.adult
                    ? {
                          ...adult(),
                          firstName: e.firstName,
                          lastName: e.lastName,
                          age: e.age,
                      }
                    : {
                          ...child(),
                          firstName: e.firstName,
                          lastName: e.lastName,
                          age: e.age,
                      }
            })

            persistentStore.SET_TOURISTS(tourists)
            persistentStore.SET_SELECTED_ORDER_ID(this.selectedGuests[0].orderId)
            this.isShowCalendarModal = true
        }

        clearSelectedOrderId() {
            persistentStore.SET_SELECTED_ORDER_ID(null)
        }

        showPreviewModal(id) {
            this.selectedOrderId = id
            this.reservationModal = true
        }

        get activityCalendarQueryParams() {
            return {
                adults: this.selectedGuestsAdults,
                childrenAges: this.selectedGuestsChildrenAges,
            }
        }

        get selectedGuestsAdults() {
            return this.selectedGuests.reduce((adults, guest) => {
                return guest.adult ? adults + 1 : adults
            }, 0)
        }

        get selectedGuestsChildrenAges() {
            return this.selectedGuests.reduce((childrenAges, guest) => {
                if (!guest.adult) {
                    childrenAges.push(guest.age)
                }

                return childrenAges
            }, [])
        }

        get headers() {
            const headers = []

            const addHeaderIfValueExists = options => {
                if (this.guestsPrepared?.findIndex(e => !!e[options.value]) !== -1) {
                    headers.push(options)
                }
            }

            if (this.isMobile) {
                headers.push({text: this.$t('name'), value: 'name'})
                addHeaderIfValueExists({text: this.$t('order'), value: 'orderId'})
                headers.push({text: this.$t('arrived'), value: 'arrived'})
                addHeaderIfValueExists({text: 'Status', value: 'orderStatus'})
            } else {
                headers.push({text: this.$t('name'), value: 'name'})
                addHeaderIfValueExists({text: this.$t('order'), value: 'orderId'})
                headers.push({text: this.$t('arrived'), value: 'arrived'})

                if (this.isTransfer) {
                    addHeaderIfValueExists({text: this.$t('transfers.from'), value: 'departure'})
                    addHeaderIfValueExists({text: this.$t('transfers.to'), value: 'arrival'})
                }

                addHeaderIfValueExists({text: this.$t('status'), value: 'orderStatus'})
                addHeaderIfValueExists({text: this.$t('serviceType.ACCOMMODATION'), value: 'accommodation'})
                addHeaderIfValueExists({text: this.$t('contacts'), value: 'phoneNumber', align: 'center'})
                if (this.personRole !== 'dispatcher') {
                    addHeaderIfValueExists({text: this.$t('price'), value: 'pricePrintable', align: 'center'})
                }
            }

            return headers
        }

        get tableColumns() {
            const tableColumns = [
                {text: this.$t('status'), value: 'status'},
                {text: this.$t('order_service'), value: 'title'},
                {text: this.$t('dates'), value: 'date', align: 'center'},
                {text: this.$t('order_travelers'), value: 'travellers'},
            ]
            if (this.personRole !== 'dispatcher') {
                tableColumns.push({text: this.$t('netto'), value: 'supplierPrice', align: 'right'})
                tableColumns.push({text: this.$t('brutto'), value: 'price', align: 'right'})
            }

            return tableColumns
        }

        get guestsPrepared() {
            return this.groupDetails?.guests
                .reduce((arr, guest) => {
                    const guestOrderExists = arr.find(e => e.orderId === guest.orderId)

                    if (!guestOrderExists) {
                        const preparedGuest = {
                            ...guest,
                            extraGuests: [],
                            arrivedAll: guest.arrived,
                        }

                        arr.push(preparedGuest)
                    } else {
                        guestOrderExists.extraGuests.push(guest)

                        if (!guest.arrived) {
                            guestOrderExists.arrivedAll = false
                        }
                    }

                    return arr
                }, [])
                .map(e => {
                    return {
                        ...e,
                        pricePrintable: e.price.printableValue,
                        statusColor: runtimeStore.orderServiceStatusColor(e.orderStatus),
                    }
                })
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get isTransfer() {
            return this.groupDetails?.serviceType === 'TRANSFER'
        }

        get isExcursion() {
            return this.groupDetails?.serviceType === 'EXCURSION'
        }

        get personRole() {
            return authStore.personRole
        }
    }
</script>
