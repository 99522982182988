<template>
    <div>
        <line-chart v-if="!isLoading" :chart-data="chartData" />
        <v-skeleton-loader v-else type="card" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import LineChart from '@/components/supplier/LineChart'

    @Component({
        components: {
            LineChart,
        },
    })
    export default class DetailedSalesMonitoringReport extends Vue {
        @Prop() selectedProduct
        @Prop() selectedDate

        isLoading = false
        productStatistics = null
        chartData = null

        mounted() {
            this.load()
        }

        async load() {
            try {
                this.isLoading = true

                const {productStatistics} = await this.$api.detailedSalesMonitoringReport.get({
                    serviceDate: this.selectedDate,
                    productId: this.selectedProduct.productId,
                })
                this.productStatistics = productStatistics
                const totalAllocatedQuantity = this.selectedProduct.itemsByDate.find(
                    date => this.$dateFns.format(date.date) === this.selectedDate
                ).totalAllocatedQuantity
                const data = this.productStatistics.reduce(
                    (count, el) => {
                        count[0].push(el.productSalesQuantity)
                        count[1].push(totalAllocatedQuantity)

                        return count
                    },
                    [[], []]
                )
                const dates = this.productStatistics?.reduce((dates, el) => {
                    dates.push(this.$options.filters.dateFormat(el.salesDate))

                    return dates
                }, [])

                if (dates?.length === 1) {
                    dates.push(this.$options.filters.dateFormat(new Date()))
                    data[0].push(0)
                    data[1].push(totalAllocatedQuantity)
                }

                this.chartData = {
                    labels: dates,
                    datasets: [
                        {
                            label: this.selectedProduct.productName,
                            backgroundColor: '#f87979',
                            borderColor: '#1976d2',
                            data: data[0],
                            fill: false,
                        },
                    ],
                }

                if (totalAllocatedQuantity) {
                    this.chartData.datasets.push({
                        label: 'Total allocated quantity',
                        backgroundColor: '#f87979',
                        borderColor: 'green',
                        data: data[1],
                        fill: false,
                    })
                }

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }
    }
</script>
