<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import OrderSummaryMixin from '@/components/booking/mixins/OrderSummaryMixin.vue'

    //TODO Need total price calc global refactoring, now it is workaround for current order summary component solution
    @Component
    export default class OrderSummaryBase extends OrderSummaryMixin {
        @Prop({default: false, type: Boolean}) prebooked
        @Prop({default: () => [], type: Array}) extraServices
        @Prop({default: () => ({}), type: Object}) additionalOptions
        @Prop({default: () => false, type: Boolean}) free
        @Prop() prepareBookResponse
    }
</script>
