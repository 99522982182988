<template>
    <div class="d-flex justify-center align-center">
        <v-progress-linear :indeterminate="true" />
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {authStore, flightsStore, hotelsRuntimeStore, persistentStore} from '~/store'
    const pidCrypt = require('pidcrypt')
    require('pidcrypt/aes_cbc')

    @Component
    export default class AutologinPage extends Vue {
        async beforeMount() {
            const data = this.parseData()
            if (data) {
                const result = JSON.parse(data)
                await this.authorize(result)
                const query = this.makeSearchQuery(result)
                await this.$router.push({name: result.search.product, query})
            }
        }

        parseData() {
            const aes = new pidCrypt.AES.CBC()
            let data = this.$route.query.data
            while (data.indexOf(' ') >= 0) {
                data = data.replace(' ', '+')
            }
            return aes.decryptText(data, 'autologin', {nBits: 256})
        }

        async authorize({auth}) {
            const {companyCode: companyCodeOrAlias, login, password, type} = auth
            await authStore[this.getAuthMethod(type)]({
                companyCodeOrAlias,
                login,
                password,
            })
            const {TasCompany, TasNo} = auth
            persistentStore.SET_COMPANY_SETTINGS({TasCompany, TasNo})
        }

        getAuthMethod(type) {
            switch (type.toLowerCase()) {
                case 'corporate':
                    return 'authB2B'
                default:
                    return 'auth'
            }
        }

        makeSearchQuery({search}) {
            return this.getQuery(search)
        }

        getQuery({product, ...searchData}) {
            switch (product) {
                case 'hotels':
                    return this.makeHotelsQuery(searchData)
                case 'flights':
                case 'flights-schedule':
                    return this.makeFlightsQuery(searchData)
                case 'packages':
                    return this.makePackagesQuery(searchData)
                default:
                    break
            }
        }

        makeHotelsQuery(searchData) {
            const {rooms} = searchData
            searchData.rooms = rooms.map(room => hotelsRuntimeStore.roomQueryString(room))
            return searchData
        }

        makeFlightsQuery(searchData) {
            //TODO Remove mode instance from state store
            flightsStore.SET_SEARCH_MODE(searchData.routes.length === 2 ? 0 : searchData.routes.length === 1 ? 1 : 2)
            return {
                routes: this.makeFlightRoutes(searchData.routes),
                flightClass: searchData.flightClass.toUpperCase(),
                adults: searchData.adults,
                directFlight: searchData.nonstop || false,
                refundableOnly: searchData.nonrefundable || false,
                childrenAges: searchData.childrenAges || [],
            }
        }

        makeFlightRoutes(routes) {
            return routes.map(route => {
                return `${route.depart}-${route.arrive},${route.date}`
            })
        }

        makePackagesQuery(searchData) {
            const {departureCityId, arrivalCityId, startDate, endDate, adults, packageId} = searchData
            return {
                departureCityId,
                arrivalCityId,
                startDate,
                endDate,
                adults,
                packageId,
                childrenAges: searchData.childrenAges || [],
                directFlight: searchData.nonstop || false,
            }
        }
    }
</script>
