<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card>
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-title class="title font-weight-light">{{ $t('the_ticket_will_be_voided') }}</v-card-title>
            <v-card-text>
                <v-radio-group v-model="voidingType">
                    <v-radio :label="$t('void_ticket')" value="void" />
                    <v-radio :label="`${$t('void_ticket_and_cancel_pnr')}`" value="void_and_cancel" />
                </v-radio-group>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    :loading="isLoading"
                    :disabled="!voidingType"
                    color="primary"
                    depressed
                    small
                    @click="voidTicket"
                >
                    {{ $t('supplier.confirm') }}
                </v-btn>
                <v-btn color="grey" small depressed class="white--text" @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TICKET_VOIDING_MODAL, CHANGE_LOCALE_EVENT} from '@/utils/event-bus'
    import {appInstance} from '@/utils/app-accessor'

    @Component
    export default class TicketVoidingModal extends Vue {
        modal = false
        processId = null
        voidingType = ''
        isLoading = false

        created() {
            EventBus.$on(SHOW_TICKET_VOIDING_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TICKET_VOIDING_MODAL, this.showModal)
        }

        async showModal({processId}) {
            this.processId = processId
            this.modal = true
        }

        async voidTicket() {
            this.isLoading = true
            try {
                const voidRs = await appInstance.$api.voidFlightTicket.put({processId: this.processId})

                if (voidRs.status === 'PreConfirmed' && this.voidingType === 'void_and_cancel')
                    await appInstance.$api.cancelService.post({processId: this.processId})

                EventBus.$emit(CHANGE_LOCALE_EVENT)
                // eslint-disable-next-line no-empty
            } catch (e) {}

            this.isLoading = false
        }
    }
</script>
