<template>
    <div>
        <div class="mb-4">
            <h2 class="justify-center">{{ $t('invoices.pay_invoices_title') }} {{ orderId }}</h2>
        </div>
        <p class="mt-2 mb-0 py-0">
            {{ $t('invoices.pay_success_message') }}
        </p>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class ConfirmationStatus extends Vue {
        @Prop({default: null}) orderId
    }
</script>
