<template>
    <v-expansion-panels v-model="filtersExpanded" flat>
        <v-expansion-panel>
            <v-expansion-panel-header class="width-auto primary--text">
                {{ $t('filters') }}
                <template v-if="hasActiveFilters && filtersExpanded !== 0">
                    <div class="pl-1" />
                    <v-badge dot />
                    <div class="pl-1" />
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <slot ref="form" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class CommunicationsFiltersWrapper extends Vue {
        @Prop({required: true, type: Boolean}) hasActiveFilters

        filtersExpanded = null
    }
</script>
