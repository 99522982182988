<script>
    import {Component, Prop, Watch} from 'vue-property-decorator'
    import HotelMap from '../../search/offers/HotelMap.vue'

    @Component
    export default class CruiseMap extends HotelMap {
        @Prop() points
        @Prop({default: null}) parent

        markers = []
        cruisePath = null
        directionsDisplay = null

        @Watch('points', {immediate: false, deep: true})
        onPointsChange() {
            if (!this.map) return
            this.removeMarkers()
            this.setMarkers()
        }

        async load() {
            await this.initMap()
            this.onPointsChange()
        }

        async setMarkers() {
            let points = [...this.points]

            // this.makeDirection(points)
            const templateColor = this.$vuetify.theme.themes.light.primary

            const markerIcon = {
                path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                fillColor: templateColor,
                fillOpacity: 0.95,
                scale: 2,
                strokeColor: '#fff',
                strokeWeight: 3,
                anchor: new this.google.maps.Point(12, 24),
            }
            points.forEach(point => {
                const marker = new this.google.maps.Marker({
                    position: {
                        lat: point.latitude,
                        lng: point.longitude,
                    },
                    map: this.map,
                    icon: markerIcon,
                    info: point,
                })
                this.markers.push(marker)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            if (
                this.points.length === 2 &&
                this.points[0].latitude === this.points[1].latitude &&
                this.points[0].longitude === this.points[1].longitude
            ) {
                this.map?.setZoom(12)
                this.map?.setCenter({lat: this.points[0].latitude, lng: this.points[0].longitude})
            } else {
                this.map.fitBounds(this.bounds)
            }
        }

        removeMarkers() {
            this.markers.forEach(marker => {
                marker.setMap(null)
            })
            this.markers = []
            if (this.cruisePath) {
                this.cruisePath.setMap(null)
            }
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: true,
                zoomControl: true,
                fullscreenControl: true,
                zoom: 8,
            }
        }

        yPos() {
            const y = !this.isMobile
                ? this.$parent.$parent.$el.parentElement.getBoundingClientRect().y
                : this.getMobileYPosition()
            return y > 0 ? y : 0
        }

        getMobileYPosition() {
            return !this.parent ? this.$parent.$refs.mobileTabs?.$el?.offsetHeight : 0
        }

        saveMapPosition() {}
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .map {
        position: fixed;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            margin-left: -$container-padding-x;
        }

        > div {
            height: 100%;
        }
    }
</style>
