<template>
    <div>
        <div class="d-table-row">
            <div class="pr-5 pb-1 d-table-cell">
                <v-icon color="accent" class="mr-1">mdi-weather-sunny</v-icon>{{ $t('duration') }}:
            </div>
            <div class="d-table-cell">{{ info.packageDuration }} {{ $tc('day', info.packageDuration) }}</div>
        </div>
        <div class="d-table-row">
            <div class="pr-5 pb-1 d-table-cell">
                <v-icon color="accent" class="mr-1">mdi-calendar</v-icon>{{ $t('start_date') }}:
            </div>
            <div v-if="info.packageDate" class="d-table-cell">{{ info.packageDate | dateFormat }}</div>
        </div>
        <div class="d-table-row">
            <div class="pr-5 pb-1 d-table-cell">
                <v-icon color="accent" class="mr-1">mdi-calendar</v-icon>{{ $t('end_date') }}:
            </div>
            <div v-if="info.packageDate" class="d-table-cell">
                {{ $dateFns.addDays($dateFns.parseISO(info.packageDate), info.packageDuration - 1) | dateFormat }}
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class TourGeneralInfo extends Vue {
        @Prop({default: () => ({})}) info
    }
</script>
