<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {hotelsStore} from '~/store'

    //TODO Remove this mixin after refactoring?
    @Component
    export default class HotelCorporatePolicyViolationMixin extends Vue {
        get corporatePolicyViolated() {
            if (hotelsStore.prepareBookResponse.prepareBookResponses) {
                return hotelsStore.prepareBookResponse.prepareBookResponses[0].corporatePolicyViolation
            }
            return this.roomOffers.length ? this.roomOffers.some(room => room.corporatePolicyViolation) : false
        }

        get violatedPolicies() {
            if (hotelsStore.prepareBookResponse.prepareBookResponses) {
                return hotelsStore.prepareBookResponse.prepareBookResponses[0].corporatePolicies
            }
            if (this.roomOffers) {
                let result = []
                this.roomOffers.forEach(room => {
                    if (room.corporatePolicyViolation) {
                        result = [...result, ...room.corporatePolicy]
                    }
                })
                return result
            }
            return []
        }
    }
</script>
