<template>
    <v-card :outlined="outlined">
        <v-card-text class="black--text subtitle-2">
            <v-row no-gutters>
                <v-col :cols="12" md="12">
                    <v-row no-gutters>
                        <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('order') }}:</v-col>
                        <v-col :cols="6" md="6" class="d-flex flex-wrap font-weight-regular">
                            <span class="me-4">{{ order.orderId }}</span>
                            <div class="grey--text cursor-pointer" @click="historyModal = true">
                                <v-icon small>mdi-history</v-icon>
                                {{ $t('history_of_change').toLocaleLowerCase() }}
                            </div>
                            <client-only>
                                <v-dialog v-model="historyModal">
                                    <order-history :order-id="order.orderId" />
                                </v-dialog>
                            </client-only>
                        </v-col>
                    </v-row>
                    <v-row v-if="orderStatus" no-gutters>
                        <v-col cols="6" class="font-weight-regular"> {{ $t('order_status') }}:</v-col>
                        <v-col cols="6">
                            <div v-if="!editeReservationStatus">
                                <strong :class="statusColor">
                                    {{ orderStatus }}
                                    <v-btn
                                        v-if="isTO1"
                                        small
                                        icon
                                        @click="editeReservationStatus = !editeReservationStatus"
                                    >
                                        <v-icon small color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </strong>
                            </div>
                            <order-details-edit-reservation-status
                                v-else
                                :order-id="order.orderId"
                                :status="order.status"
                                :loading="reservationStatusLoading"
                                @save="onChangeReservationStatus"
                                @close="editeReservationStatus = false"
                            />
                        </v-col>
                        <template v-if="$config.showMoveToArchive && (isTourOperator || isAgency)">
                            <v-col cols="6 " />
                            <v-col cols="6 ">
                                <archive-reservation :order-id="order.orderId" :status="order.active" />
                            </v-col>
                        </template>
                    </v-row>
                    <div v-if="order.clientCompanyName || tripAgentName" class="mt-4">
                        <v-row v-if="order.clientCompanyName" no-gutters class="mt-4">
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('company') }}:</v-col>
                            <v-col :cols="6" md="6" class="font-weight-regular">
                                {{ order.clientCompanyName }}
                                <v-btn small icon @click="showContactDetailsModal('company')">
                                    <v-icon small color="primary">mdi-information-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="tripAgentName" no-gutters align="center">
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('agent') }}:</v-col>
                            <v-col :cols="6" md="6" class="font-weight-regular">
                                <div v-if="!editAgentMode" class="d-flex align-center">
                                    <span>
                                        {{ tripAgentName }}
                                        <v-btn v-if="isTO1" small icon @click="editAgentMode = !editAgentMode">
                                            <v-icon small color="primary">mdi-pencil</v-icon>
                                        </v-btn>
                                    </span>
                                </div>
                                <order-details-edit-agent
                                    v-else
                                    :order-id="order.orderId"
                                    :agent-id="order.agent.id"
                                    :company-id="order.agentCompanyId"
                                    :save-loading="agentSaveLoading"
                                    @save="onAgentChange"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="tripOrganaizerName || clientName || managerName" class="mt-4">
                        <v-row v-if="tripOrganaizerName" no-gutters>
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('trip_organaizer') }}:</v-col>
                            <v-col :cols="6" md="6" class="font-weight-regular">
                                {{ tripOrganaizerName }}
                            </v-col>
                        </v-row>
                        <v-row v-if="clientName" no-gutters>
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('client') }}:</v-col>
                            <v-col :cols="6" md="6" class="font-weight-regular">
                                <div v-if="!editClientMode" class="d-flex align-center">
                                    <span data-cy="client-name">{{ clientName }}</span>
                                    <v-btn small icon @click="showContactDetailsModal('client')">
                                        <v-icon small color="primary">mdi-information-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="isTO1 || isAgency"
                                        small
                                        icon
                                        data-cy="edit-client-button"
                                        @click="editClientMode = !editClientMode"
                                    >
                                        <v-icon small color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                                <order-details-edit-client
                                    v-else
                                    class="mb-2"
                                    :save-loading="clientSaveLoading"
                                    :client-person="order.clientPerson"
                                    @save="onClientChange"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="managerName" no-gutters align="center">
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('manager') }}:</v-col>
                            <v-col :cols="6" md="6" class="font-weight-regular">
                                <div v-if="!editManagerMode" class="d-flex align-center">
                                    <span data-cy="manager-name">{{ managerName }}</span>
                                    <v-btn
                                        v-if="isTO1"
                                        small
                                        icon
                                        data-cy="edit-manager-button"
                                        @click="editManagerMode = !editManagerMode"
                                    >
                                        <v-icon small color="primary" c>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                                <order-details-edit-agent
                                    v-else
                                    :order-id="order.orderId"
                                    :agent-id="order.manager.id"
                                    :company-id="person.companyId"
                                    :save-loading="managerSaveLoading"
                                    @save="onManagerChange"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-if="minDate || maxDate" no-gutters class="mt-4">
                        <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('dates') }}:</v-col>
                        <v-col :cols="6" md="6">
                            <strong>
                                {{ minDate | dateFormat }} <br />
                                <span v-if="minDate !== maxDate">{{ maxDate | dateFormat }}</span>
                            </strong>
                        </v-col>
                    </v-row>
                    <v-row v-if="duration" no-gutters>
                        <v-col :cols="6" md="6" class="font-weight-regular" />
                        <v-col :cols="6" md="6" class="font-weight-regular">
                            {{ duration }}
                        </v-col>
                    </v-row>
                    <template v-if="order.status !== 'NEW' && personRole !== 'dispatcher'">
                        <v-row v-if="isTourOperator" no-gutters class="my-4">
                            <v-col :cols="6" md="6">
                                <total-order-prices
                                    v-if="commissionAndNettoCheckbox"
                                    :order-prices="orderPrices('SUPPLIER')"
                                    :payment-status="supplierPaymentStatus"
                                    :service-card-guarantee="gotPayAtHotelService(order)"
                                    :services="order.services"
                                    :vat-calculated-amount-by-each-service-brutto="
                                        vatCalculatedAmountByEachServiceBrutto
                                    "
                                    title="supplier_price"
                                />
                                <cancel-penalty-label
                                    v-if="order.cancelPenalty && commissionAndNettoCheckbox"
                                    class="mt-2 font-weight-regular"
                                    :price="getTotalPrice(orderPrices('SUPPLIER'))"
                                    :cancel-penalty="order.cancelPenalty"
                                />
                            </v-col>
                            <v-col :cols="6" md="6">
                                <total-order-prices
                                    title="client_price"
                                    :order-prices="orderPrices('CLIENT')"
                                    :payment-status="customerPaymentStatus"
                                    :service-card-guarantee="gotPayAtHotelService(order)"
                                    :services="order.services"
                                    :vat-calculated-amount-by-each-service-brutto="
                                        vatCalculatedAmountByEachServiceBrutto
                                    "
                                />
                                <cancel-penalty-label
                                    v-if="order.cancelPenalty"
                                    class="mt-2 font-weight-regular"
                                    :price="getTotalPrice(orderPrices('CLIENT'))"
                                    :cancel-penalty="order.cancelPenalty"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-else no-gutters class="mt-4 font-weight-regular">
                            <v-col :cols="12">
                                <order-price-label
                                    :payment-status="customerPaymentStatus"
                                    :order-prices="orderPrices(isAgency ? 'SUPPLIER' : 'CLIENT')"
                                    :services="order.services"
                                    :vat-calculated-amount-by-each-service-netto="vatCalculatedAmountByEachServiceNetto"
                                    :cancel-penalties="order.cancelPenalty"
                                    :show-commission-and-netto-block="showCommissionAndNettoBlock"
                                    class="font-weight-bold"
                                />
                            </v-col>
                            <v-col :cols="6" md="6">
                                <cancel-penalty-label
                                    v-if="order.cancelPenalty"
                                    class="mt-2 font-weight-regular"
                                    :price="getTotalPrice(orderPrices(isAgency ? 'SUPPLIER' : 'CLIENT'))"
                                    :cancel-penalty="order.cancelPenalty"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="$config.account.paymentScheduler && !isSupplier" class="pb-4" no-gutters>
                            <v-col cols="6" />
                            <v-col cols="6">
                                <div v-if="isManualInvoicesLoading" class="d-flex justify-center full-width">
                                    <v-progress-circular indeterminate color="primary" />
                                </div>
                                <template v-else>
                                    <template v-if="nextManualInvoice">
                                        <div class="caption" :class="{'error--text': nextManualInvoice.expired}">
                                            {{ `${$t('next_payment_due')}:` }}
                                            {{
                                                nextManualInvoice.originalPrices[0].originalAmount
                                                    | priceFormat(
                                                        nextManualInvoice.originalPrices[0].originalAmountCurrency
                                                    )
                                            }}
                                        </div>
                                        <div class="caption" :class="{'error--text': nextManualInvoice.expired}">
                                            {{
                                                `${$t('before')} ${$options.filters.dateShortFormat(
                                                    nextManualInvoice.dueDate
                                                )}`
                                            }}
                                        </div>
                                    </template>
                                    <v-btn
                                        color="primary"
                                        text
                                        x-small
                                        class="px-0"
                                        @click="isShowPaymentSchedule = true"
                                    >
                                        <span v-if="!$config.account.paymentSchedulerAutoPaymentMode">
                                            {{ $t('view_payment_schedule') }}
                                        </span>
                                        <span v-else>
                                            {{ $t('view_payments') }}
                                        </span>
                                    </v-btn>
                                </template>
                            </v-col>
                            <v-col v-if="isShowAddPaymentButton" cols="12">
                                <v-btn
                                    outlined
                                    class="my-2 black--text"
                                    color="grey darken-1"
                                    block
                                    data-cy="add-service-button"
                                    :small="$breakpoint.smAndDown"
                                    @click="isShowAddDepositModal = true"
                                    v-text="
                                        $config.account.paymentSchedulerAutoPaymentMode
                                            ? $t('add_payment')
                                            : $t('add_payment_schedule')
                                    "
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
                <v-col v-if="!isSupplier && !isGuest" :cols="12" md="12">
                    <v-row dense class="mb-4">
                        <v-col v-if="personRole !== 'dispatcher'" cols="6" class="py-0 d-flex flex-column align-start">
                            <order-custom-documents
                                ref="orderCustomDocuments"
                                :order="order"
                                :checkbox-services-selected="checkboxServicesSelected"
                            />
                            <v-btn
                                v-if="
                                    isB2B &&
                                    isShowInvoiceBtn &&
                                    showPaymentAndInvoiceStatus &&
                                    !gotPayAtHotelService(order) &&
                                    order.status !== 'NEW'
                                "
                                plain
                                class="px-0"
                                @click="isAgency ? showInvoices('SUPPLIER') : showInvoices('CUSTOMER')"
                            >
                                <v-icon color="primary" left>mdi-receipt-text-outline</v-icon>
                                {{ $t('invoices.invoice') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="6" class="py-0 d-flex flex-column align-start">
                            <v-btn
                                v-if="voucherAvailable"
                                :disabled="voucherLoading"
                                :loading="voucherLoading"
                                plain
                                class="px-0"
                                @click="downloadVoucher"
                            >
                                <v-icon color="primary" left>mdi-file-document-outline</v-icon>
                                {{ $t('voucher') }}
                            </v-btn>
                            <v-btn
                                v-if="showItineraryBtn"
                                :disabled="loadItinerary"
                                :loading="loadItinerary"
                                plain
                                class="px-0"
                                @click="getItinerary"
                            >
                                <v-icon color="primary" left>mdi-map-legend</v-icon>
                                {{ $t('itinerary') }}
                            </v-btn>
                            <v-btn v-if="isTO1 || isTO2 || isAgency" plain class="px-0" @click="sendEmail">
                                <v-icon color="primary" left>mdi-email-outline</v-icon>
                                {{ $t('send_email') }}
                            </v-btn>
                            <v-btn
                                v-if="isTO1 && isReservationReportMailEnabled && hasOwnServices"
                                plain
                                class="px-0"
                                @click="isShowReservationReportModal = true"
                            >
                                <v-icon color="primary" left>mdi-email-outline</v-icon>
                                {{ $t('reservation_report') }}
                            </v-btn>
                            <v-btn v-if="isB2B" plain class="px-0" @click="getDocuments">
                                <v-icon color="primary" left>mdi-text-box-search-outline</v-icon>
                                {{ $t('attachments') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="d-flex flex-wrap justify-center">
                        <v-btn
                            v-if="
                                showPaymentAndInvoiceStatus &&
                                canPayOrder &&
                                !gotPayAtHotelService(order) &&
                                !$config.account.paymentScheduler
                            "
                            depressed
                            class="mb-2 white--text"
                            color="primary"
                            block
                            :small="$breakpoint.smAndDown"
                            @click="payOrder"
                        >
                            {{ $t('pay_order') }}
                        </v-btn>
                        <v-btn
                            v-if="!$config.account.hideAddServiceBtn && personRole !== 'dispatcher'"
                            color="primary"
                            class="mb-2 white--text"
                            block
                            data-cy="add-service-button"
                            :small="$breakpoint.smAndDown"
                            @click="isShowOrderAddServiceModal = true"
                            v-text="$t('add_service')"
                        />
                        <order-add-service-modal v-model="isShowOrderAddServiceModal" :order="order" />
                        <add-offline-service-dialog
                            v-if="
                                ((isB2B && !$config.account.addOfflineServiceTO1Only) || isTO1) &&
                                personRole !== 'dispatcher'
                            "
                            :order-travelers="orderTravelers"
                            :order-process-id="order.processId"
                            :client-type="order.clientType"
                            :customer-company-id="order.customerCompanyId"
                            @send-request="sendRequest"
                        />
                        <order-add-deposit-modal
                            v-model="isShowAddDepositModal"
                            :object-id="order.objectId"
                            @confirm="onAddDepositConfirm"
                        />
                        <reservation-report-order-modal
                            v-if="isReservationReportMailEnabled"
                            v-model="isShowReservationReportModal"
                            :order="order"
                        />
                        <order-payment-schedule
                            ref="orderPaymentSchedule"
                            v-model="isShowPaymentSchedule"
                            :object-id="order.objectId"
                            :order-id="order.orderId"
                            :client-price-currencies="clientPriceCurrencies"
                            :_is-manual-invoices-loading.sync="isManualInvoicesLoading"
                            :cancel-invoices-on-load="$config.account.paymentSchedulerAutoPaymentMode"
                            @load-manual-invoices="onManualInvoicesLoad"
                            @complete-payment="sendRequest"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Emit, mixins, Prop} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import {downloadData} from '@/utils/helpers'
    import {convertPrice} from '@/utils/filters'
    import VoucherMixin from '@/components/account/mixins/VoucherMixin'
    import {
        EventBus,
        PAY_ORDER_EVENT,
        SHOW_CONTACT_DETAILS_MODAL,
        SHOW_INVOICES_MODAL_EVENT,
        SHOW_SEND_EMAIL_MODAL,
        SHOW_SERVICE_DOCUMENTS_MODAL,
    } from '@/utils/event-bus'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import TotalOrderPrices from '~src/components/account/snippets/totalOrderPrices.src'
    import OrderPriceLabel from '~src/components/account/snippets/orderPriceLabel.src'
    import ChatModal from '@/components/modals/ChatModal'
    import OrderAddServiceModal from '~src/components/account/modals/orderAddServiceModal.src'
    import AddOfflineServiceDialog from '~src/components/account/offlineServices/addOfflineServiceDialog.src'
    import OrderCustomDocuments from '~src/components/account/snippets/orderCustomDocuments.src'
    import OrderDetailsEditAgent from '@/components/account/OrderDetailsEditAgent'
    import OrderDetailsEditReservationStatus from '@/components/account/OrderDetailsEditReservationStatus'
    import CancelPenaltyLabel from '@/components/snippets/CancelPenaltyLabel'
    import OrderAddDepositModal from '~src/components/account/orderAddDepositModal.src'
    import OrderPaymentSchedule from '@/components/account/OrderPaymentSchedule'
    import OrderDetailsEditClient from '@/components/account/OrderDetailsEditClient'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin.vue'
    import ReservationReportOrderModal from '@/components/account/modals/ReservationReportOrderModal'
    import ArchiveReservation from '@/components/account/ArchiveReservation'
    import OrderHistory from '@/components/account/OrderHistory'

    @Component({
        components: {
            ReservationReportOrderModal,
            OrderPaymentSchedule,
            OrderAddDepositModal,
            OrderDetailsEditClient,
            CancelPenaltyLabel,
            OrderDetailsEditAgent,
            OrderCustomDocuments,
            PaymentStatusLabel,
            TotalOrderPrices,
            OrderPriceLabel,
            ChatModal,
            OrderAddServiceModal,
            AddOfflineServiceDialog,
            OrderDetailsEditReservationStatus,
            ArchiveReservation,
            OrderHistory,
        },
    })
    export default class OrderDetailsSummary extends mixins(VoucherMixin, DocumentTemplatesMixin) {
        @Prop({default: false, type: Boolean}) outlined
        @Prop({required: true}) orderTravelers
        @Prop() checkboxServicesSelected
        @Prop() vatCalculatedAmountByEachServiceBrutto
        @Prop() vatCalculatedAmountByEachServiceNetto

        isShowOrderAddServiceModal = false
        isShowAddDepositModal = false
        isShowReservationReportModal = false
        isShowPaymentSchedule = null
        historyModal = false

        editAgentMode = false
        editManagerMode = false

        loadItinerary = false
        agentSaveLoading = false
        managerSaveLoading = false
        reservationStatusLoading = false

        editeReservationStatus = false

        isManualInvoicesLoading = false
        nextManualInvoice = null

        editClientMode = false
        clientSaveLoading = false
        newClient = {}

        async mounted() {
            await this.$store.restored
            await this.load()
            this.isLoading = true
            await this.loadDocumentTemplates()
            this.isLoading = false
        }

        async load() {
            await this.loadClientPerson()
            await this.loadManualInvoices()
        }

        async onAddDepositConfirm({autoPaymentMode, invoiceId}) {
            if (autoPaymentMode) {
                if (invoiceId) {
                    await this.$refs?.orderPaymentSchedule?.initSilentPay(invoiceId)
                }

                this.isShowAddDepositModal = false
            } else {
                await this.loadManualInvoices()
            }
        }

        async loadManualInvoices() {
            return await this.$refs?.orderPaymentSchedule?.loadManualInvoices()
        }

        onManualInvoicesLoad(invoices) {
            const nextManualInvoice = invoices?.find(m => m.paymentStatus === 'BILL')
            if (nextManualInvoice) {
                const expired = this.$dateFns.isBefore(new Date(nextManualInvoice.dueDate), new Date())

                this.nextManualInvoice = {...nextManualInvoice, expired}
            }
        }

        async loadClientPerson() {
            try {
                if (this.order.clientType === 'TA' && !this.order.clientPerson) {
                    const {persons} = await this.$api.privateClients.get({
                        companyId: this.order.companyId,
                        id: this.order.orderClientInfo.clientId,
                        active: true,
                        limit: 1,
                    })

                    if (persons[0]) {
                        runtimeStore.SET_ORDER_DETAILS({...this.order, clientPerson: persons[0]})
                    }
                }

                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        @Emit()
        sendRequest() {}

        async onClientChange(rq) {
            this.clientSaveLoading = true

            try {
                await this.$api.updateCrmPerson.put(this.order.orderClientInfo.clientId, rq)

                runtimeStore.SET_ORDER_DETAILS({
                    ...this.order,
                    clientPerson: {...this.order.clientPerson, ...rq},
                })

                this.editClientMode = false
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.clientSaveLoading = false
            }
        }

        async onAgentChange(agent) {
            this.agentSaveLoading = true

            try {
                await this.$api.orders.put(this.order.orderId, {agentId: agent.id})

                runtimeStore.SET_ORDER_DETAILS({...this.order, agent})

                this.editAgentMode = false
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.agentSaveLoading = false
            }
        }

        async onManagerChange(manager) {
            this.managerSaveLoading = true

            try {
                await this.$api.orders.put(this.order.orderId, {managerId: manager.id})

                runtimeStore.SET_ORDER_DETAILS({...this.order, manager})

                this.editManagerMode = false
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.managerSaveLoading = false
            }
        }

        async onChangeReservationStatus(status) {
            this.reservationStatusLoading = true

            try {
                await this.$api.orders.put(this.order.orderId, {status})

                runtimeStore.SET_ORDER_DETAILS({...this.order, status})
                this.editeReservationStatus = false
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.reservationStatusLoading = false
            }
        }

        orderPrices(type = 'CLIENT') {
            return this.order.orderPrices.find(orderPrice => orderPrice.type === type).orderPrices
        }

        getTotalPrice(prices) {
            const total = {amount: 0}

            prices.forEach(e => {
                const convertedPrice = convertPrice(e)

                if (!total.currency) {
                    total.currency = convertedPrice.currency
                }

                total.amount += convertedPrice.amount
            })

            return total
        }

        showInvoices(invoiceType) {
            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: this.order.orderId,
                objectId: this.order.objectId,
                invoiceType: invoiceType,
                order: this.order,
            })
        }

        getDocuments() {
            EventBus.$emit(SHOW_SERVICE_DOCUMENTS_MODAL, {
                orderId: this.order.orderId,
                companyId: this.order.companyId,
                services: this.order.services,
            })
        }

        payOrder() {
            EventBus.$emit(PAY_ORDER_EVENT, {orderId: this.order.orderId, objectId: this.order.objectId})
        }

        gotPayAtHotelService(order) {
            if (order.services && order.services.length === 1) {
                return order.services.some(service =>
                    service.serviceDetailsContainer.accommodation
                        ? service.serviceDetailsContainer.accommodation.cardGuarantee
                        : false
                )
            }
            return false
        }

        async getItinerary() {
            this.loadItinerary = true
            try {
                const data = await this.$api.itinerary.get({
                    orderId: this.order.orderId,
                })
                downloadData(data, `Itinerary-${this.order.orderId}.pdf`)
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            } finally {
                this.loadItinerary = false
            }
        }

        sendEmail() {
            const {templates, isShowCustomDocuments} = this.$refs.orderCustomDocuments
            EventBus.$emit(SHOW_SEND_EMAIL_MODAL, this.order, null, isShowCustomDocuments ? templates : [])
        }

        showContactDetailsModal(type) {
            const rq = {}
            if (type === 'company') rq.id = this.order.clientId
            else if (type === 'client' && this.order.clientType === 'TA') {
                rq.id = this.order.orderClientInfo.clientId
                rq.companyId = this.order.clientId
            } else if (type === 'client' && this.order.clientType !== 'TA') rq.id = this.order.orderClientInfo.clientId

            EventBus.$emit(SHOW_CONTACT_DETAILS_MODAL, {
                type,
                rq,
            })
        }

        get clientPriceCurrencies() {
            return this.order.services.reduce((currencies, service) => {
                const serviceClientPriceCurrency = service.salesTerms.find(({type}) => type === 'CLIENT')?.price
                    ?.currency

                if (!currencies.includes(serviceClientPriceCurrency)) {
                    currencies.push(serviceClientPriceCurrency)
                }

                return currencies
            }, [])
        }

        get isShowAddPaymentButton() {
            if (!this.$config.account.paymentScheduler) {
                return false
            }
            if (this.$config.account.paymentSchedulerAutoPaymentMode) {
                return true
            } else {
                return this.isTO1 || this.isTO2 || this.isAgency
            }
        }

        get isShowInvoiceBtn() {
            return this.$config.account.showOrderInvoiceBtn
        }

        get isB2C() {
            return authStore.isB2C
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get order() {
            return runtimeStore.orderDetails
        }

        get orderStatus() {
            if (!this.order.status) return null
            return this.$t(`order_statuses.${this.order.status.toUpperCase().replace(/[\s.]/g, '_')}`)
        }

        get statusColor() {
            return runtimeStore.orderStatusColor(this.order.status)
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }

        get showCommissionAndNettoBlock() {
            return (
                this.isAgency &&
                this.orderPrices('SUPPLIER').some(orderPrice => !!orderPrice.commission) &&
                this.commissionAndNettoCheckbox
            )
        }

        get isShowPaymentStatus() {
            return true
        }

        get canPayOrder() {
            return (
                authStore.isAgency &&
                ['COMPLETED', 'IN_PROGRESS'].includes(this.order.status) &&
                !['PAID', 'PARTLY_PAID'].includes(this.order.supplierPaymentStatus)
            )
        }

        get supplierPaymentStatus() {
            return this.order.supplierPaymentStatus
        }

        get customerPaymentStatus() {
            return this.order.customerPaymentStatus
        }

        get person() {
            return authStore.person
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get isGuest() {
            return authStore.isGuest
        }

        get isB2B() {
            return authStore.isB2B
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get personRole() {
            return authStore.personRole
        }

        get managerName() {
            if (this.order.manager && (this.order.manager.lastName || this.order.manager.firstName)) {
                return `${this.order.manager.prefix || ''} ${this.order.manager.firstName || ''} ${
                    this.order.manager.lastName || ''
                }`
            }
            return null
        }

        get tripOrganaizerName() {
            if (this.order.tripOrganizer && (this.order.tripOrganizer.firstName || this.order.tripOrganizer.lastName)) {
                return `${this.order.tripOrganizer.prefix || ''} ${this.order.tripOrganizer.firstName || ''} ${
                    this.order.tripOrganizer.lastName || ''
                }`
            }
            return null
        }

        get tripAgentName() {
            if (this.order.agent && (this.order.agent.firstName || this.order.agent.lastName)) {
                return `${this.order.agent.prefix || ''} ${this.order.agent.firstName || ''} ${
                    this.order.agent.lastName || ''
                }`
            }
            return null
        }

        get clientName() {
            if (this.order.clientPerson) {
                return `${this.order.clientPerson.prefix || ''} ${this.order.clientPerson.firstName} ${
                    this.order.clientPerson.lastName
                }`
            }
            return ''
        }

        get minDate() {
            const services = this.order?.services.filter(
                s => s.serviceDetails?.[0]?.extraServiceProductType !== 'BANK_FEE'
            )

            if (services && services.length) {
                const dates = services.map(service => {
                    return new Date(service.startDateTime.slice(0, 10)).getTime()
                })
                return dates.sort()[0]
            }
            return null
        }

        get maxDate() {
            const services = this.order?.services.filter(
                s => s.serviceDetails?.[0]?.extraServiceProductType !== 'BANK_FEE'
            )

            if (services && services.length) {
                const dates = services.map(service => {
                    return new Date(service.endDateTime.slice(0, 10)).getTime()
                })
                return dates.sort()[dates.length - 1]
            }
            return null
        }

        get duration() {
            if (!this.maxDate && !this.minDate) return null
            const day = Math.ceil((this.maxDate - this.minDate) / 8.64e7) + 1
            const night = day > 1 ? day - 1 : 1
            if (night > 1) return day + ` ${this.$tc('day', day)} / ` + night + ` ${this.$tc('night', night)}`
            return day + ` ${this.$tc('day', day)}`
        }

        get voucherAvailable() {
            return this.order.services.some(service => runtimeStore.isServiceVoucherAvailable(service))
        }

        get sortedServices() {
            return this.getSortedServices(this.order.services)
        }

        get showItineraryBtn() {
            return !this.$config.account.hideItineraryBtn && this.order.status !== 'NEW'
        }

        get isReservationReportMailEnabled() {
            return this.$config.reservationReport
        }

        get hasOwnServices() {
            return this.order.services.some(service => runtimeStore.ownProduct(service.supplierCode))
        }
    }
</script>
