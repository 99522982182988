<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <component :is="`extra-service-card${$breakpoint.smAndDown ? '-mobile' : ''}`" :product="entry">
            <template v-slot:price="{offer}">
                <tour-offer-delta-button :offer="offer" :offer-key="offerKey" :entry-name="entry.entryName" />
            </template>
        </component>
    </v-dialog>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_EXTRA_SERVICE_INFO} from '@/utils/event-bus'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton'

    @Component({
        components: {
            TourOfferDeltaButton,
            ExtraServiceCard: () => import('@/components/extraServices/search/offers/ExtraServiceCard'),
            ExtraServiceCardMobile: () => import('@/components/extraServices/search/offers/ExtraServiceCardMobile'),
        },
    })
    export default class TourExtraServiceInfoModal extends Vue {
        @Prop({required: true}) searchRequest

        modal = false
        entry = {}
        offerKey = null

        mounted() {
            EventBus.$on(SHOW_TOUR_EXTRA_SERVICE_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_EXTRA_SERVICE_INFO, this.showModal)
        }

        async showModal(entry, offerKey) {
            try {
                const info = await this.$api.ownExtraServiceInfo.get({offerKey: entry.offers[0].offerKey})
                this.entry = {
                    ...entry,
                    info: {
                        name: info.productName,
                        description: info.productDescription ? info.productDescription : '',
                        imageUrl: info.images.find(item => item.mainImage).url || info.images[0].url,
                    },
                }
                this.offerKey = offerKey
                this.modal = true
            } catch (e) {
                this.entry = {}
                this.offerKey = null
                this.modal = false
            }
        }
    }
</script>
