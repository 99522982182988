<template>
    <div class="mb-5">
        <strong class="d-block" v-html="offer.info.modelName" />
        <strong class="d-block">{{ $t('date') }}: {{ searchRequest.pickUpDateTime | dateFormat }}</strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_CARS_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class CarConditionsModalInfo extends Vue {
        searchRequest = {}
        offer = {info: {}}

        mounted() {
            EventBus.$on(SHOW_CARS_CONDITIONS_INFO, this.showInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_CARS_CONDITIONS_INFO, this.showInfo)
        }

        showInfo(searchRequest, offer) {
            this.searchRequest = searchRequest
            this.offer = offer
        }
    }
</script>
