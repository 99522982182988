<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {toursRuntimeStore, toursStore} from '@/store'

    @Component
    export default class ToursStoreMixin extends Vue {
        get productStore() {
            return toursStore
        }

        get productRuntimeStore() {
            return toursRuntimeStore
        }

        get productType() {
            return 'tours'
        }
    }
</script>
