<template>
    <v-select
        hide-details
        outlined
        dense
        :disabled="$attrs.disabled"
        :label="$t('flights.select_class')"
        :items="offerClasses"
        :value="value ? value.classType : offerClasses[0]"
        eager
        @change="saveClass($event)"
    >
        <template v-slot:item="{item}">
            {{ $t(`filters_class.${item}`) }}
        </template>
        <template v-slot:selection="{item}">
            {{ $t(`filters_class.${item}`) }}
        </template>
    </v-select>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class FlightClassSelector extends Vue {
        @Prop() offer
        @Prop({default: null}) value

        order = ['ECONOMY', 'PREMIUM_ECONOMY', 'BUSINESS', 'FIRST_CLASS']

        mounted() {
            this.saveClass(this.value ? this.value.classType : this.offerClasses[0])
        }

        saveClass(cls) {
            if (this.offer.itinerary[0].segments[0].categoryClasses) {
                const categoryClass = this.offer.itinerary[0].segments[0].categoryClasses.find(
                    ({classType}) => classType === cls
                )
                this.$emit('select-class', categoryClass)
            }
        }

        get offerClasses() {
            if (!this.offer.itinerary[0].segments[0].categoryClasses) {
                return []
            }
            const classes = this.offer.itinerary[0].segments[0].categoryClasses.map(({classType}) => classType)
            classes.sort((a, b) => {
                return this.order.indexOf(a) + 1 - (this.order.indexOf(b) + 1)
            })
            return classes
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .v-select__selections {
        font-size: 14px;
    }
</style>
