<script>
    import {Component} from 'vue-property-decorator'
    import OrderCreateDaterange from '~/components/account/filters/OrderCreateDaterange'

    @Component
    export default class OrderServiceDaterange extends OrderCreateDaterange {
        get maxStartDate() {
            return undefined
        }

        get maxEndDate() {
            return undefined
        }
    }
</script>
