<template>
    <span
        v-if="visible"
        :class="{caption: isMobile}"
        data-cy="supplier-price"
        class="text-center supplier-price"
        :style="{'white-space': isMobile ? 'pre-line' : ''}"
    >
        <span class="text-no-nowrap"> {{ $t('supplier_price') }}: </span>
        <span>
            {{ price }}
        </span>
    </span>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore} from '~/store'

    @Component
    export default class SupplierPrice extends Vue {
        @Prop({required: true}) supplierPrice

        get price() {
            return this.$options.filters.price(this.supplierPrice)
        }

        get visible() {
            return this.supplierPrice && authStore.companyType && authStore.companyType !== 'CORPORATE'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped>
    span.supplier-price {
        color: rgba(0, 0, 0, 0.6);
    }
</style>
