<template>
    <v-dialog v-model="dialog" :width="newSupplierMode ? null : 1200">
        <template v-slot:activator="{on}">
            <v-btn
                v-if="!serviceToModify"
                outlined
                class="mb-2 black--text"
                color="grey darken-1"
                block
                data-cy="add-service-button"
                :small="$breakpoint.smAndDown"
                v-on="on"
                v-text="$t('add_offline_service')"
            />
            <a v-else class="black--text modify-link mb-3" v-on="on">
                {{ $t('manual_correction') }}
            </a>
        </template>
        <v-card>
            <v-card-title>
                <div class="py-3 d-flex full-width align-center">
                    <v-btn v-if="newSupplierMode" fab small elevation="0" class="me-3" @click="newSupplierMode = false">
                        <v-icon> mdi-arrow-left</v-icon>
                    </v-btn>
                    {{
                        newSupplierMode
                            ? $t('add_supplier')
                            : serviceToModify
                            ? $t('manual_correction')
                            : $t('add_offline_service')
                    }}
                    <v-spacer />
                    <v-btn icon class="close-btn" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <create-supplier-form v-if="newSupplierMode" @save="saveSupplier" />
                <template v-else>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" :md="2">
                                <v-select
                                    v-model="type"
                                    outlined
                                    dense
                                    :disabled="!!serviceToModify"
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('type')}`"
                                    hide-details
                                    :items="types"
                                />
                            </v-col>
                            <v-col cols="12" :md="2">
                                <v-select
                                    v-model="status"
                                    outlined
                                    dense
                                    :label="`${$t('status')}`"
                                    hide-details
                                    :items="statuses"
                                />
                            </v-col>
                            <v-col cols="12" :md="3">
                                <v-text-field
                                    v-model="name"
                                    dense
                                    outlined
                                    :label="nameLabel"
                                    hide-details
                                    :rules="[v => !!v || `${$t('name')} ${$t('validation.required')}`]"
                                />
                            </v-col>
                            <v-col cols="12" :md="2">
                                <v-text-field
                                    v-model="refNumber"
                                    dense
                                    outlined
                                    :label="`${$t('reference_number')}`"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" :md="3">
                                <v-autocomplete
                                    v-model="supplierId"
                                    dense
                                    :disabled="suppliersLoading || !!serviceToModify"
                                    :items="suppliers"
                                    :loading="suppliersLoading"
                                    item-text="name"
                                    item-value="id"
                                    hide-no-data
                                    :label="`${$t('supplier_id')}*`"
                                    persistent-placeholder
                                    outlined
                                    background-color="white"
                                    :clearable="true"
                                />
                                <div v-if="!serviceToModify" class="full-width d-flex flex-row align-center mt-n4">
                                    <v-spacer />
                                    <span
                                        style="z-index: 1"
                                        class="primary--text cursor-pointer font-weight-bold ms-auto"
                                        @click="newSupplierMode = true"
                                    >
                                        {{ $t('add_supplier') }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="12" :md="4" class="mt-n8">
                                <date-picker
                                    v-if="type === 'BANK_FEE'"
                                    v-model="startDate"
                                    outlined
                                    dense
                                    :label="$t('modify.subtitle_dates')"
                                    hide-details
                                />
                                <daterange-m
                                    v-else
                                    outlined
                                    dense
                                    :label="$t('modify.subtitle_dates')"
                                    class="mt-2"
                                    :start-date.sync="startDate"
                                    :end-date.sync="endDate"
                                />
                            </v-col>
                        </v-row>
                        <offline-service-product-form v-model="product" :product-type="type" :tourists="tourists" />
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="description"
                                    hide-details
                                    rows="2"
                                    dense
                                    outlined
                                    :label="`${$t('description')}`"
                                />
                            </v-col>
                            <v-col v-if="showATOLSwitcher && type === 'FLIGHT'" cols="12">
                                <div class="caption mb-n3">
                                    {{ $t('include_atol') }}
                                </div>
                                <v-switch v-model="isATOL" hide-details inset :label="`ATOL`" />
                            </v-col>
                            <v-col v-else cols="12">
                                <v-textarea
                                    v-model="comment"
                                    dense
                                    hide-details
                                    rows="1"
                                    outlined
                                    :label="`${$t('comment')}`"
                                />
                            </v-col>
                        </v-row>
                        <template v-if="pricingRulesLoaded && salesSettingsLoaded">
                            <v-row>
                                <v-col>
                                    <modify-manual-sales-term
                                        :ref="`SUPPLIERSaleTerm`"
                                        v-model="supplierSalesTermValid"
                                        :client-type="clientType"
                                        select-currency
                                        :supplier-price="salesTermSupplier.price"
                                        :service-exchange-rate="1"
                                        :disable-cancel-penalty="type === 'BANK_FEE'"
                                        :_sales-term="salesTermSupplier"
                                        :pricing-rules="pricingRules"
                                        :sales-settings="salesSettings"
                                        @recalculate-client-price="recalculateClientPrice"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <modify-manual-sales-term
                                        :ref="`CLIENTSaleTerm`"
                                        v-model="clientSalesTermValid"
                                        :client-type="clientType"
                                        select-currency
                                        :supplier-price="salesTermSupplier.price"
                                        :service-exchange-rate="1"
                                        :disable-cancel-penalty="type === 'BANK_FEE'"
                                        :_sales-term="salesTermClient"
                                        :pricing-rules="pricingRules"
                                        :sales-settings="salesSettings"
                                    />
                                </v-col>
                            </v-row>
                        </template>
                        <v-row v-else>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-progress-circular indeterminate size="64" color="primary" />
                            </v-col>
                        </v-row>
                        <template v-if="type !== 'BANK_FEE'">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <div class="d-flex align-center">
                                        <v-radio-group
                                            v-model="singleTouristMode"
                                            dense
                                            row
                                            hide-details
                                            class="ma-0 pa-0"
                                        >
                                            <v-radio :label="$t('traveler_specify_primary')" :value="true" />
                                            <v-radio :label="$t('traveler_specify_all')" :value="false" />
                                        </v-radio-group>
                                        <v-btn
                                            v-if="!singleTouristMode"
                                            fab
                                            small
                                            dark
                                            color="primary"
                                            @click="addTourist({})"
                                        >
                                            <v-icon dark> mdi-plus</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-if="singleTouristMode">
                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="singleTourist"
                                        outlined
                                        dense
                                        :label="$t('order_travelers')"
                                        :items="touristsSelect"
                                        item-text="name"
                                        item-value="value"
                                    />
                                </v-col>
                            </v-row>
                            <template v-else>
                                <v-row v-for="(tourist, i) in tourists" :key="i" class="d-flex align-center">
                                    <v-col cols="12" md="2">
                                        <v-select
                                            v-model="tourist.prefix"
                                            outlined
                                            dense
                                            hide-details
                                            :items="['Mr', 'Ms', 'Mrs']"
                                            :label="`${$t('salutation')}`"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                            v-model="tourist.firstName"
                                            outlined
                                            dense
                                            :label="`${$t('first_name')}${isFirstNameRequired ? '*' : ''}`"
                                            :rules="isFirstNameRequired ? [requiredRule('first_name')] : []"
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                            v-model="tourist.lastName"
                                            outlined
                                            dense
                                            :label="`${$t('last_name')}${isLastNameRequired ? '*' : ''}`"
                                            :rules="isLastNameRequired ? [requiredRule('last_name')] : []"
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <date-picker
                                            v-model="tourist.birthdate"
                                            :label="$t('birthdate')"
                                            outlined
                                            dense
                                            hide-details
                                            :max="$dateFns.format(new Date())"
                                            year-active-picker
                                            :rules="[birthDateInfantRule(tourist), birthDateAdultRule(tourist)]"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-select
                                            v-model="tourist.type"
                                            :items="touristTypes"
                                            outlined
                                            dense
                                            :label="`${$t('traveler_type')}`"
                                            hide-details
                                            @change="onTypeChange"
                                        />
                                    </v-col>
                                    <v-col v-if="!(isFirstNameRequired && tourists.length === 1)" cols="12" md="1">
                                        <v-btn icon class="close-btn" @click="tourists.splice(i, 1)">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="mb-8">
                                    <v-col cols="12" :class="{'d-flex': !$breakpoint.smAndDown}">
                                        <div :class="{'mr-4': !$breakpoint.smAndDown, 'mb-4': $breakpoint.smAndDown}">
                                            <a
                                                href="/example_names_list.csv"
                                                class="text-body-1 primary--text text-decoration-underline"
                                            >
                                                {{ $t('download_sample') }}
                                            </a>
                                        </div>
                                        <div>
                                            <label
                                                class="text-body-1 primary--text text-decoration-underline cursor-pointer"
                                            >
                                                {{ $t('import_names') }}
                                                <v-icon color="primary" right>mdi-plus-circle</v-icon>
                                                <v-file-input
                                                    id="fileObject"
                                                    v-model="fileObject"
                                                    accept=".txt, .csv"
                                                    style="visibility: hidden; width: 0; height: 0; position: absolute"
                                                    class="ma-0 pa-0"
                                                    @change="importTravelers"
                                                />
                                            </label>
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>
                        </template>
                    </v-form>
                </template>
            </v-card-text>
            <v-card-actions v-if="!newSupplierMode" class="justify-center">
                <div class="d-flex py-4">
                    <v-btn
                        class="mx-1"
                        depressed
                        color="primary"
                        :loading="loading"
                        :disabled="isSaveDisabled"
                        @click="sendRequest"
                    >
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn class="mx-1" depressed @click="dialog = false">
                        {{ $t('close') }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import ModifyManualTraveler from '@/components/account/modify/manual/ModifyManualTraveler'
    import ModifyManualStatus from '@/components/account/modify/manual/ModifyManualStatus'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import ModifyManualSalesTerm from '@/components/account/modify/manual/ModifyManualSalesTerm'
    import {addDays, format} from 'date-fns'
    import CreateSupplierForm from '@/components/account/offlineServices/CreateSupplierForm'
    import OfflineServiceProductForm from '@/components/account/offlineServices/OfflineServiceProductForm'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {StrToArray} from '@/utils/helpers'
    import {blobToString} from '@/utils/file-utils'

    @Component({
        components: {
            OfflineServiceProductForm,
            CreateSupplierForm,
            ModifyManualSalesTerm,
            ModifyManualStatus,
            ModifyManualTraveler,
            DaterangeM,
            TimeField,
            DatePicker,
        },
    })
    export default class AddOfflineServiceDialog extends mixins(FormValidationRulesMixin) {
        @Prop() orderTravelers
        @Prop() orderProcessId
        @Prop() serviceToModify
        @Prop({required: true}) clientType
        @Prop({required: true}) customerCompanyId

        dialog = false
        valid = false
        supplierSalesTermValid = false
        clientSalesTermValid = false
        newSupplierMode = false
        loading = false
        suppliers = []
        suppliersLoading = false
        singleTouristMode = false
        fileObject = null

        type = null
        status = null
        refNumber = null
        name = null
        supplierId = null

        startDate = null
        endDate = null
        description = null
        comment = null
        salesTermSupplier = {
            type: 'SUPPLIER',
            price: {amount: 0, currency: 'EUR', commission: {amount: 0}},
            cancelPenalty: [],
        }
        salesTermClient = {
            type: 'CLIENT',
            price: {amount: 0, currency: 'EUR', commission: {amount: 0}},
            cancelPenalty: [],
        }
        singleTourist = null
        tourists = []
        product = {}
        isATOL = false
        pricingRules = null
        pricingRulesLoaded = false
        salesSettings = null
        salesSettingsLoaded = false

        async mounted() {
            await this.getSuppliers()
        }

        @Watch('type')
        async onProductTypeChange() {
            if (!this.serviceToModify) {
                this.product = this.type === 'FLIGHT' ? {segments: [{}]} : {}
            }
            await this.loadPricingRules()
        }

        @Watch('supplierId')
        async onSupplierIdChange() {
            await this.loadPricingRules()
        }

        @Watch('dialog')
        async onDialogChange() {
            this.reset()
            await this.loadSalesSettings()
        }

        @Watch('singleTouristMode', {immediate: true})
        onTouristModeChange() {
            if (this.singleTouristMode) {
                this.singleTourist = this.touristsSelect[0]?.value
            } else {
                this.tourists = this.touristsSelect.map(e => e.value)
            }
        }

        fillProductData() {
            this.singleTouristMode = false
            this.type = this.serviceToModify.serviceDetails[0].extraServiceProductType
            this.status =
                this.serviceToModify.status === 'Quote'
                    ? 'QUOTED'
                    : this.serviceToModify.status.split(' ').join('_').toUpperCase()
            this.name = this.serviceToModify.serviceName
            this.refNumber = this.serviceToModify.refNum
            this.supplierId = this.serviceToModify.supplierId
            this.description = this.serviceToModify.serviceDetails[0].description
            this.comment = this.serviceToModify.comments
            if (this.comment === 'ATOL') {
                this.isATOL = true
            }

            const supplierSalesTerms = this.serviceToModify.salesTerms.find(e => e.type === 'SUPPLIER')
            const clientSalesTerms = this.serviceToModify.salesTerms.find(e => e.type === 'CLIENT')

            this.salesTermSupplier = {
                type: 'SUPPLIER',
                price: {
                    amount: supplierSalesTerms.price.amount || 0,
                    currency: supplierSalesTerms.price.currency,
                    commission: {amount: supplierSalesTerms.price.commission || 0},
                },
                cancelPenalty: supplierSalesTerms.cancelPenalty.map(c => ({
                    amount: c.amount,
                    startDateTime: c.startDateTime?.split(' ')[0],
                    endDateTime: c.endDateTime?.split(' ')[0],
                })),
            }

            this.salesTermClient = {
                type: 'CLIENT',
                price: {
                    amount: clientSalesTerms.price.amount || 0,
                    currency: clientSalesTerms.price.currency,
                    commission: {amount: clientSalesTerms.price.commission || 0},
                    ...(this.isShowClientCommissionVAT && {
                        commissionVatAmount: clientSalesTerms.commissionVatAmount || 0,
                    }),
                },
                cancelPenalty: clientSalesTerms.cancelPenalty.map(c => ({
                    amount: c.amount,
                    startDateTime: c.startDateTime?.split(' ')[0],
                    endDateTime: c.endDateTime?.split(' ')[0],
                })),
            }

            switch (this.serviceToModify.serviceDetails[0].extraServiceProductType) {
                case 'ACCOMMODATION':
                    this.product = {
                        ...(this.serviceToModify.serviceDetails[0].roomType && {
                            roomType: this.serviceToModify.serviceDetails[0].roomType,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].mealType && {
                            mealType: this.serviceToModify.serviceDetails[0].mealType,
                        }),
                    }
                    break
                case 'TRANSFER':
                    this.product = {
                        ...(this.serviceToModify.serviceDetails[0].pickUpPoint && {
                            pickUpPoint: this.serviceToModify.serviceDetails[0].pickUpPoint,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].pickUpTime && {
                            pickUpTime: this.serviceToModify.serviceDetails[0].pickUpTime,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].dropOffPoint && {
                            dropOffPoint: this.serviceToModify.serviceDetails[0].dropOffPoint,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].dropOffTime && {
                            dropOffTime: this.serviceToModify.serviceDetails[0].dropOffTime,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].oneWay && {
                            oneWay: this.serviceToModify.serviceDetails[0].oneWay,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].groupTransfer && {
                            groupTransfer: this.serviceToModify.serviceDetails[0].groupTransfer,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].duration && {
                            duration: this.serviceToModify.serviceDetails[0].duration,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].contactNumber && {
                            contactNumber: this.serviceToModify.serviceDetails[0].contactNumber,
                        }),
                    }
                    break
                case 'EXCURSION':
                    this.product = {
                        ...(this.serviceToModify.serviceDetails[0].durationTime && {
                            durationTime: this.serviceToModify.serviceDetails[0].durationTime,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].durationDays && {
                            durationDays: this.serviceToModify.serviceDetails[0].durationDays,
                        }),
                    }
                    break
                case 'CAR_RENT':
                    this.product = {
                        ...(this.serviceToModify.serviceDetails[0].pickUpPoint && {
                            pickUpPoint: this.serviceToModify.serviceDetails[0].pickUpPoint,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].pickUpTime && {
                            pickUpTime: this.serviceToModify.serviceDetails[0].pickUpTime,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].dropOffPoint && {
                            dropOffPoint: this.serviceToModify.serviceDetails[0].dropOffPoint,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].dropOffTime && {
                            dropOffTime: this.serviceToModify.serviceDetails[0].dropOffTime,
                        }),
                        ...(this.serviceToModify.serviceDetails[0].vehicleCategory && {
                            vehicleCategory: this.serviceToModify.serviceDetails[0].vehicleCategory,
                        }),
                    }
                    break
                case 'FLIGHT':
                    this.product = {
                        ...(this.serviceToModify.serviceDetails[0].marketingAirline && {
                            marketingAirline: this.serviceToModify.serviceDetails[0].marketingAirline,
                        }),
                        segments: this.serviceToModify.serviceDetails[0].segments
                            ? this.serviceToModify.serviceDetails[0].segments.map(
                                  // eslint-disable-next-line no-unused-vars
                                  ({flightSegmentName, ...segment}) => ({
                                      ...segment,
                                      departureDate: segment.departureDate?.split(' ').join('T') || null,
                                      arrivalDate: segment.arrivalDate?.split(' ').join('T') || null,
                                      seats: [...segment.seats.map(seat => ({...seat}))],
                                  })
                              )
                            : [{}],
                    }

                    break
            }
            this.startDate = this.serviceToModify.startDateTime?.split(' ')?.[0]
            this.endDate = this.serviceToModify.endDateTime?.split(' ')?.[0]
        }

        reset() {
            this.type = this.types[0].value
            this.status = this.statuses[2].value
            this.startDate = format(new Date(), 'yyyy-MM-dd')
            this.endDate = format(addDays(new Date(), 6), 'yyyy-MM-dd')
            this.newSupplierMode = false
            this.singleTouristMode = true
            if (this.isShowClientCommissionVAT) {
                this.salesTermClient.price.commissionVatAmount = 0
            }
            if (this.serviceToModify) {
                this.fillProductData()
            }
        }

        async loadSalesSettings() {
            this.salesSettingsLoaded = false

            try {
                this.salesSettings = await this.$api.salesSettings.get({clientCompanyId: this.customerCompanyId})

                // eslint-disable-next-line no-empty
            } catch (e) {}

            this.salesSettingsLoaded = true
        }

        async loadPricingRules() {
            this.pricingRulesLoaded = false

            let type
            switch (this.type) {
                case 'ACCOMMODATION':
                    type = 'accommodation'
                    break
                case 'TRANSFER':
                    type = 'transfer'
                    break
                case 'FLIGHT':
                    type = 'flight'
                    break
                case 'ACTIVITY':
                case 'EXCURSION':
                    type = 'excursion'
                    break
                case 'CAR_RENT':
                    type = 'carRent'
                    break
                case 'INSURANCE':
                    type = 'insurance'
                    break
                case 'TOUR':
                    type = 'tour'
                    break
                case 'VISA':
                    type = 'ownVisa'
                    break
                default:
                    break
            }

            if (this.supplierId) {
                try {
                    this.pricingRules = await this.$api.pricingRules.get({
                        clientCompanyId: this.customerCompanyId,
                        productType: type,
                        supplierCode: this.suppliers.find(s => s.id === this.supplierId)?.companyCode,
                    })

                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            this.pricingRulesLoaded = true
        }

        async getSuppliers() {
            try {
                this.suppliersLoading = true
                this.suppliers = (await this.$api.companies.get({type: 'INTERNAL_SUPPLIER', active: true}))?.object

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.suppliersLoading = false
            }
        }

        saveSupplier() {
            this.newSupplierMode = false
            this.supplierId = null
            this.getSuppliers()
        }

        addTourist(data) {
            const {prefix, firstName, lastName, dateOfBirth, type} = data
            this.tourists.push({
                ...(prefix && {prefix}),
                ...(firstName && {firstName}),
                ...(lastName && {lastName}),
                ...(dateOfBirth && {birthdate: dateOfBirth}),
                type: type ? type.toUpperCase() : 'ADULT',
            })
        }

        async importTravelers() {
            const strData = await blobToString(this.fileObject)
            const arr = StrToArray(strData)
            const travelersList = arr.splice(1, arr.length - 1)

            travelersList.map(item => {
                const traveler = {
                    prefix: item[0] && ['mr', 'ms', 'mrs'].includes(item[0].toLowerCase()) ? item[0] : '',
                    firstName: item[1] || '',
                    middleName: item[2] || '',
                    lastName: item[3] || '',
                    dateOfBirth: item[4] ? this.$dateFns.format(new Date(item[4])) : '',
                    type: item[5] && ['child', 'adult'].includes(item[5].toLowerCase()) ? item[5].toLowerCase() : null,
                }

                if (item.length > 1) this.addTourist(traveler)
            })
            this.fileObject = null
        }

        birthDateInfantRule() {
            return () => true
        }

        birthDateAdultRule() {
            return () => true
        }

        onTypeChange() {
            this.$refs.form?.validate()
        }

        recalculateClientPrice() {
            this.$refs.CLIENTSaleTerm?.calculatePriceAmount()
            this.$refs.CLIENTSaleTerm?.calculateMarkup()
            this.$refs.CLIENTSaleTerm?.calculateCommission()
            this.$refs.CLIENTSaleTerm?.calculateCommissionVat()
        }

        async sendRequest() {
            try {
                this.loading = true
                if (this.serviceToModify) {
                    //TODO '|' for processId slashes replace in put requests

                    await this.$api.orderServices.put(
                        this.serviceToModify.processId.replace(/^.{6}/, '').split('/').join('|'),
                        this.requestBody
                    )
                } else {
                    await this.$api.orderServices.post(this.requestBody)
                }

                this.$emit('send-request')
                this.$toast.success(this.$t('success_message.saved_successfully'))

                // eslint-disable-next-line no-empty
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.loading = false
            }
        }

        get isFirstNameRequired() {
            return this.status === 'CONFIRMED' || this.status === 'CONFIRMATION_PENDING'
        }

        get isLastNameRequired() {
            return this.status === 'CONFIRMED' || this.status === 'CONFIRMATION_PENDING'
        }

        get touristTypes() {
            return ['ADULT', 'CHILD']
        }

        get nameLabel() {
            return `${this.$t('name')}*`
        }

        get showATOLSwitcher() {
            return false
        }

        get touristsSelect() {
            return this.orderTravelers.map(e => ({
                name: `${e.prefix || ''} ${e.name[0].firstName || ''} ${e.name[0].lastName || ''}`,
                value: {
                    prefix: e.prefix,
                    firstName: e.name[0].firstName,
                    lastName: e.name[0].lastName,
                    birthdate: e.dateOfBirth,
                    type: e.isChild ? 'CHILD' : 'ADULT',
                },
            }))
        }

        get types() {
            const types = [
                {
                    name: this.$t('serviceType.ACCOMMODATION'),
                    value: 'ACCOMMODATION',
                },
                {
                    name: this.$t('serviceType.TRANSFER'),
                    value: 'TRANSFER',
                },
                {
                    name: this.$t('serviceType.FLIGHT'),
                    value: 'FLIGHT',
                },
                {
                    name: this.$t('serviceType.EXCURSION'),
                    value: 'EXCURSION',
                },
                {
                    name: this.$t('serviceType.CARRENT'),
                    value: 'CAR_RENT',
                },
                {
                    name: this.$t('serviceType.INSURANCE'),
                    value: 'INSURANCE',
                },
                {
                    name: this.$t('serviceType.TOUR'),
                    value: 'TOUR',
                },
                {
                    name: this.$t('serviceType.OWNVISA'),
                    value: 'VISA',
                },
                {
                    name: this.$t('serviceType.TOUR'),
                    value: 'TOUR',
                },
            ]

            if (this.$config.bankServiceFee) {
                types.push({
                    name: this.$t('serviceType.BANK_FEE'),
                    value: 'BANK_FEE',
                })
            }

            types.push({
                name: this.$t('serviceType.OTHER'),
                value: 'OTHER',
            })

            return types
        }

        get isSaveDisabled() {
            return (
                !this.supplierId ||
                !this.valid ||
                !this.supplierSalesTermValid ||
                !this.clientSalesTermValid ||
                (this.isFirstNameRequired && this.tourists?.length === 0)
            )
        }

        get statuses() {
            return ['CONFIRMED', 'CONFIRMATION_PENDING', 'QUOTED', ...(this.serviceToModify ? ['REJECTED'] : [])].map(
                value => ({value, text: this.$t(`bookingStatus.${value}`)})
            )
        }

        get requestBody() {
            return {
                orderProcessId: this.orderProcessId,
                productType: this.type,
                name: this.name,
                status: this.status,
                refNum: this.refNumber,
                supplierId: this.supplierId,
                startDate: this.startDate.split(' ').join('T'),
                endDate:
                    this.type === 'BANK_FEE' ? this.startDate.split(' ').join('T') : this.endDate.split(' ').join('T'),
                description: this.description,
                comment:
                    this.showATOLSwitcher && this.type === 'FLIGHT' ? (this.isATOL ? 'ATOL' : 'NO ATOL') : this.comment,
                nettoPrice: {
                    ...this.salesTermSupplier.price,
                },
                bruttoPrice: {
                    ...this.salesTermClient.price,
                },
                supplierPenalties: this.salesTermSupplier.cancelPenalty.map(e => {
                    return {
                        dateFrom: e.startDateTime,
                        dateTo: e.endDateTime,
                        price: {
                            currency: this.salesTermSupplier.price.currency,
                            amount: e.amount,
                        },
                    }
                }),
                clientPenalties: this.salesTermClient.cancelPenalty.map(e => {
                    return {
                        dateFrom: e.startDateTime,
                        dateTo: e.endDateTime,
                        price: {
                            currency: this.salesTermClient.price.currency,
                            amount: e.amount,
                        },
                    }
                }),
                tourists:
                    this.type === 'BANK_FEE'
                        ? []
                        : this.singleTouristMode
                        ? this.singleTourist
                            ? [this.singleTourist]
                            : []
                        : this.tourists,
                product: this.product,
            }
        }

        get isShowClientCommissionVAT() {
            return this.clientType !== 'DIRECT_SALES' && this.clientType !== 'CORPORATE'
        }
    }
</script>

<style scoped lang="scss">
    .modify-link {
        font-size: 14px;
        font-weight: normal;
        display: block;
        text-align: center;
    }
    //
    //::v-deep .v-select__selection.v-select__selection--comma {
    //    //white-space: nowrap !important;
    //    //overflow: hidden !important;
    //    //text-overflow: ellipsis !important;
    //}
</style>
