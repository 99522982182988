<template>
    <search-form-layout :popup="popup" :title="productName" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="6" cols="12">
                        <city-autocomplete
                            ref="locationAutocomplete"
                            v-model="city"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                        />
                    </v-col>
                    <v-col md="4" cols="12">
                        <vc-date-picker
                            v-model="startDate"
                            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                            :locale="$i18n.locale"
                            :min-date="minDateTime"
                            :first-day-of-week="$config.firstDayOfWeek || undefined"
                            is-required
                        >
                            <template v-slot="{inputEvents}">
                                <div v-on="inputEvents">
                                    <v-text-field
                                        :class="$breakpoint.smAndDown ? 'caption' : ''"
                                        :label="$t('date')"
                                        readonly
                                        single-line
                                        filled
                                        hide-details
                                        background-color="white"
                                        prepend-inner-icon="mdi-calendar"
                                        :value="formattedDate"
                                    />
                                </div>
                            </template>
                        </vc-date-picker>
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :options="['citizenship', 'internalSuppliers', 'externalSuppliers', 'supplierId']"
                :suppliers="suppliers"
            />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import {authStore, extraServicesRuntimeStore, extraServicesStore, persistentStore} from '@/store'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import AdditionalSearchOptionsMixin from '@/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            CityAutocomplete,
            SearchFormLayout,
            AdditionalOptionsWrapper,
        },
    })
    export default class ExtraServiceSearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false}) popup
        @Prop({default: null}) variant
        @Prop({default: ''}) productName

        city = {}
        startDate = null
        adults = 1
        childrenAges = []
        valid = false
        popularDestinations = []
        suppliers = []

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            await this.loadPopularDestinations()
            this.$refs.locationAutocomplete.clear()
            if (this.city && this.city.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.city.id, limitCities: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            } else {
                this.$refs.locationAutocomplete.load()
            }
        }

        newQuery() {
            const query = {
                countryId: this.city.countryId,
                cityId: this.city.id,
                startDate: this.startDate,
                adults: this.adults,
                childrenAges: this.childrenAges,
            }

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        async search() {
            const query = this.newQuery()
            extraServicesRuntimeStore.SET_CITY(this.city)
            this.$emit('close-popup')
            if (this.variant) {
                Object.assign(query, {type: this.variant})
            }
            const name = 'extraServices'
            if (this.$route.name !== name) {
                extraServicesRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await extraServicesRuntimeStore.search(extraServicesStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            await this.loadPopularDestinations()
            if (extraServicesStore.searchRequest.cityId !== extraServicesRuntimeStore.city?.id)
                await extraServicesRuntimeStore.loadCity(extraServicesStore.searchRequest.cityId)
            this.city =
                extraServicesStore.searchRequest.cityId && Object.keys(extraServicesRuntimeStore.city).length
                    ? extraServicesRuntimeStore.city
                    : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(extraServicesStore.searchRequest.startDate), new Date())) {
                this.startDate = extraServicesStore.searchRequest.startDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
            //TODO Need refactoring with other products
            this.adults = authStore.user.isEmployeeOrExternalCorp ? 1 : extraServicesStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp
                ? []
                : [...extraServicesStore.searchRequest.childrenAges]
            if (this.isTO1) {
                try {
                    this.suppliers = (await this.$api.suppliers.get({productType: 'extraService'}))?.suppliers
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async loadPopularDestinations() {
            try {
                this.popularDestinations = (await this.$api.locations.get({limitCities: 7})).cities
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get isTO1() {
            return authStore.isTO1
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get formattedDate() {
            return this.startDate !== null ? this.$options.filters.dateFormat(this.startDate) : ''
        }
    }
</script>
