var render = function render(){var _vm=this,_c=_vm._self._c;return _c('service-card',{attrs:{"service-tips":_vm.serviceTips,"service":_vm.service,"icon":"mdi-airplane","is-first":_vm.isFirst,"order":_vm.order},on:{"showInfo":_vm.showInfo,"modifyService":function($event){return _vm.$emit('modifyService', $event)}},scopedSlots:_vm._u([{key:"checkbox",fn:function(){return [_vm._t("checkbox")]},proxy:true},{key:"orderInfo",fn:function(){return [_c('order-info-base',{attrs:{"service":_vm.service,"service-check-in":_vm.serviceCheckIn,"service-check-out":_vm.serviceCheckOut,"is-package":_vm.isPackage,"service-image":_vm.mainImage,"order":_vm.order,"order-end-date":_vm.orderEndDate,"not-itinerary-start":_vm.notItineraryStart},on:{"cancelService":function($event){return _vm.$emit('cancelService', $event)},"issueTicket":function($event){return _vm.$emit('issueTicket', $event)},"modifyService":function($event){return _vm.$emit('modifyService', $event)}},scopedSlots:_vm._u([{key:"additionalInfo2",fn:function(){return [_c('flight-service-additional-info',{attrs:{"service":_vm.service}}),_vm._v(" "),(
                        _vm.service.serviceType !== 'OWNCHARTER' &&
                        _vm.$refs.baggageRules &&
                        _vm.$refs.baggageRules.baggageInfo.length
                    )?_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.baggageRulesModal = true}}},[_vm._v("\n                    "+_vm._s(_vm.$t('flights.fare_baggage_rules'))+"\n                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-information-outline")])],1):_vm._e(),_vm._v(" "),_c('flight-baggage-rules',{ref:"baggageRules",attrs:{"service":_vm.service},model:{value:(_vm.baggageRulesModal),callback:function ($$v) {_vm.baggageRulesModal=$$v},expression:"baggageRulesModal"}})]},proxy:true},{key:"belowImg",fn:function(){return [_c('flight-operating-airlines',{attrs:{"flight":_vm.flight}})]},proxy:true}])},[(_vm.service.status === 'Quote')?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":"","small":"","color":"primary"},on:{"click":function($event){_vm.fillFlightBookingDataModal = true}}},[_vm._v("mdi-pencil")]),_vm._v(" "),_c('span',{staticClass:"text-caption cursor-pointer primary--text",on:{"click":function($event){_vm.fillFlightBookingDataModal = true}}},[_vm._v("\n                    "+_vm._s(_vm.$t('enter_details'))+"\n                ")]),_vm._v(" "),_c('fill-flight-booking-data-modal',{attrs:{"service":_vm.service,"order":_vm.order},model:{value:(_vm.fillFlightBookingDataModal),callback:function ($$v) {_vm.fillFlightBookingDataModal=$$v},expression:"fillFlightBookingDataModal"}})],1):_vm._e(),_vm._v(" "),(
                    _vm.service.serviceDetails[0].airlinesConfirmationNumbers &&
                    _vm.service.serviceDetails[0].airlinesConfirmationNumbers.length
                )?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-ticket-confirmation-outline")]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold me-1"},[_vm._v("\n                    "+_vm._s(`${_vm.$t('flights.airline_pnr')}: `)+"\n                ")]),_vm._v("\n                "+_vm._s(_vm.service.serviceDetails[0].airlinesConfirmationNumbers
                        .map(({airlineConfirmationNumber}) => airlineConfirmationNumber)
                        .join(', '))+"\n            ")],1):_vm._e()])]},proxy:true},{key:"serviceInfo",fn:function(){return [_c('v-dialog',{attrs:{"width":"600","hide-overlay":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',{staticClass:"modal-close-btn-block"},[_c('v-icon',{staticClass:"v-card-close",on:{"click":function($event){_vm.modal = false}}},[_vm._v("mdi-close")])],1),_vm._v(" "),_c('service-info-base',{attrs:{"service":_vm.service},scopedSlots:_vm._u([{key:"serviceInfo",fn:function(){return [_vm._l((_vm.itineraries),function(itinerary,index){return _c('flight-itinerary',{key:`itinerary-${index}`,attrs:{"itinerary":itinerary}})}),_vm._v(" "),_c('detail-modal',{attrs:{"offer":{processId: _vm.processId, itinerary: _vm.itineraries}}}),_vm._v(" "),_c('fare-rules',{attrs:{"process-id":_vm.processId}})]},proxy:true}])})],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }