<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ServiceAdditionalOptionsMixin extends Vue {
        additionalOptions = {}
        additionalOptionsLoading = false
        extraServices = []

        async loadAdditionalOptions({serviceType, processId}) {
            try {
                this.additionalOptionsLoading = true
                const additionalOptionsFnName = (() => {
                    switch (serviceType) {
                        case 'ACCOMMODATION':
                            return 'additionalOptions'
                        case 'FLIGHT':
                        case 'OWNCHARTER':
                            return 'additionalFlightOptions'
                        case 'ACTIVITY':
                            return 'activityAdditionalOptions'
                        case 'CARRENT':
                            return 'additionalCarRentOptions'
                        case 'TRANSFER':
                            return 'transferAdditionalOptions'
                    }
                })()
                this.additionalOptions = await this.$api[additionalOptionsFnName].get({processId})
                if (this.additionalOptions?.extraServices?.length) {
                    this.extraServices = this.additionalOptions.extraServices.reduce((extraServices, {serviceRPH}) => {
                        const extraService = this.service.extraServices.find(({id}) => +serviceRPH === id)
                        if (extraService) {
                            extraServices.push(this.mapExtraServiceData(extraService))
                        }
                        return extraServices
                    }, [])
                }
            } catch (e) {
                this.additionalOptions = {}
                this.extraServices = []
            } finally {
                this.additionalOptionsLoading = false
            }
        }

        mapExtraServiceData({id, quantity}) {
            return {serviceRPH: id.toString(), quantity}
        }
    }
</script>
