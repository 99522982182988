<template>
    <search-layout v-if="loaded" :offers-count="filteredOffers.length" :search-active="searchActive">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <insurance-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.products.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <component
                :is="component"
                v-for="(offer, index) in filteredOffers"
                :key="index"
                :product="offer"
                :data-cy="`insurance-offer-${index}`"
                class="my-2"
            />
            <conditions-modal>
                <template v-slot:info>
                    <insurance-conditions-modal-info />
                </template>
            </conditions-modal>
            <insurance-info-modal />
        </template>
        <template v-slot:left-sidebar>
            <insurance-search-summary :search-request="searchRequest" show-dates show-days-count show-tourists />
            <sort-select v-model="sortKey" :items="sortItems" />
            <insurance-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <insurance-map v-if="location" :city="location" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import InsuranceStoreMixin from '@/mixins/InsuranceStoreMixin'
    import InsurancePageMixin from '@/components/insurance/mixins/InsurancePageMixin'
    import {insuranceRuntimeStore, insuranceStore, persistentStore} from '~/store'
    import {searchRequest} from '@/utils/insurance/insurance-blank-states'
    import {isEqual} from '@/utils/helpers'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import InsuranceSearchSummary from '@/components/insurance/search/InsuranceSearchSummary'
    import InsuranceBreadcrumbs from '@/components/insurance/InsuranceBreadcrumbs'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import InsuranceOfferCard from '@/components/insurance/search/offers/InsuranceOfferCard'
    import InsuranceOfferCardMobile from '@/components/insurance/search/offers/InsuranceOfferCardMobile'
    import InsuranceConditionsModalInfo from '@/components/insurance/snippets/InsuranceConditionsModalInfo.vue'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import InsuranceMap from '@/components/insurance/search/InsuranceMap'
    import InsuranceInfoModal from '@/components/insurance/info/InsuranceInfoModal'
    import SortSelect from '@/components/search/SortSelect'
    import InsuranceFilters from '@/components/insurance/search/InsuranceFilters'
    import {appInstance} from '@/utils/app-accessor'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            SearchLayout,
            ExpiredOffersSnackbar,
            InsuranceSearchSummary,
            InsuranceBreadcrumbs,
            SearchProgress,
            InsuranceOfferCard,
            InsuranceOfferCardMobile,
            ConditionsModal,
            InsuranceConditionsModalInfo,
            InsuranceMap,
            InsuranceInfoModal,
            SortSelect,
            InsuranceFilters,
        },
        layout: 'blank',
    })
    export default class InsuranceSearchPage extends mixins(
        InsuranceStoreMixin,
        InsurancePageMixin,
        SearchPageAuthMixin
    ) {
        loaded = false
        location = null

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.search)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.search)
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(insuranceStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !insuranceStore.hasOffers
            ) {
                await this.search()
            } else if (insuranceRuntimeStore.offers.length === 0) {
                insuranceRuntimeStore.filter()
            }
            this.getLocation()
        }

        async search() {
            await insuranceRuntimeStore.search(this.searchRequest)
        }

        changeFilter(data) {
            insuranceStore.SET_FILTER(data)
            insuranceRuntimeStore.filter()
        }

        resetFilters() {
            insuranceStore.RESET_FILTERS()
            insuranceRuntimeStore.filter()
        }

        isOffersExpired() {
            return insuranceStore.isOffersExpired()
        }

        stopSearch() {
            insuranceRuntimeStore.stopSearch()
        }

        get searchRequest() {
            //TODO Move to store (as in transfers)
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            rq.convertToCurrency = persistentStore.currency
            rq.locationId = parseInt(rq.locationId, 10)
            rq.daysCount = parseInt(rq.daysCount, 10)
            rq.adults = parseInt(rq.adults, 10)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            } else {
                rq.childrenAges = rq.childrenAges.map(age => parseInt(age, 10))
            }
            rq.birthdates = new Array(rq.adults + rq.childrenAges.length).fill('1980-01-01')
            return rq
        }

        get searchResponse() {
            return insuranceStore.searchResponse
        }

        async getLocation() {
            try {
                const {results} = await appInstance.$api.geocode.get({address: insuranceRuntimeStore.location.name})
                this.location = {
                    latitude: results[0].geometry.location.lat,
                    longitude: results[0].geometry.location.lng,
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get filteredOffers() {
            return insuranceRuntimeStore.offers
        }

        get filterValues() {
            return insuranceStore.filters
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get currency() {
            return insuranceStore.searchRequest.convertToCurrency
        }

        get sortKey() {
            return insuranceStore.sortFnName
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
            ]
        }

        get component() {
            return !this.$breakpoint.smAndDown ? 'insurance-offer-card' : 'insurance-offer-card-mobile'
        }

        set sortKey(sortKey) {
            insuranceStore.SET_SORT_FN_NAME(sortKey)
            insuranceRuntimeStore.sort(this.filteredOffers)
        }
    }
</script>
