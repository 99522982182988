<script>
    import {Component, Emit, Prop} from 'nuxt-property-decorator'
    import {hotelsStore} from '@/store'
    import TouristPassportForm from '~src/components/booking/forms/touristPassportForm.src'

    @Component
    export default class HotelTouristPassportForm extends TouristPassportForm {
        @Prop({required: true}) roomIndex

        getProp(prop) {
            return hotelsStore.tourists[this.roomIndex][this.index]?.passport[prop]
        }

        @Emit()
        setProp(prop, val) {
            const index = this.index,
                roomIndex = this.roomIndex
            hotelsStore.SET_TOURIST_PASSPORT_PROP({prop, val, index, roomIndex})
        }

        get personId() {
            return hotelsStore.tourists[this.roomIndex][this.index].personId
        }

        get isTourLead() {
            return this.roomIndex === 0 && this.index === 0
        }

        get isChild() {
            return hotelsStore.tourists[this.roomIndex][this.index]?.type === 'child'
        }

        get expanded() {
            return this.isTourLead ? 0 : null
        }
    }
</script>
