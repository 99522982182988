<script>
    import {Component, Prop} from 'vue-property-decorator'
    import {packagesRuntimeStore, packagesStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component
    export default class PackageBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest

        get items() {
            const getSearchPageLabel = () =>
                `${packagesRuntimeStore.departurePoint.name}, ${packagesRuntimeStore.departurePoint.countryName} - ${
                    packagesRuntimeStore.arrivalPoint.name
                }, ${packagesRuntimeStore.arrivalPoint.countryName} + ${this.$tc('hotel', 1)}`
            const addSearchPageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: packagesRuntimeStore.searchPageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'packages':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'packageDetail':
                    addSearchPageLink(items)
                    items.push({
                        text: `${packagesStore.selectedHotel.name} + ${this.$t('flights.flight')}`,
                        disabled: true,
                    })
                    break
                case 'packageBooking':
                    addSearchPageLink(items)
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'packageConfirmation':
                    addSearchPageLink(items)
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
