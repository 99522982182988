<template>
    <div>
        <v-data-table :headers="headers" :items="documentTemplates" :loading="isLoading" disable-sort class="mt-3">
            <template v-slot:top>
                <div class="text-h6 ps-3">
                    {{ $t('admin.document_templates') }}
                </div>
                <v-btn class="ms-3 mb-2" color="primary" :to="{name: 'newDocumentEditor'}">
                    {{ $t('add') }}
                </v-btn>
            </template>
            <template v-slot:item.notificationType="{item}">
                <span>
                    {{ item.documentType[0].toUpperCase() + item.documentType.slice(1) }}
                </span>
            </template>
            <template v-slot:item.lang="{item}">
                {{ item.templateLocales.join(', ') }}
            </template>
            <template v-slot:item.visibility="{item}">
                <div :key="key">
                    <div v-if="!isEditingTitle[item.id]">
                        <span v-for="(role, index) in item.documentVisibility" :key="role">
                            {{ $t(`roleTypes.${role}`) }}
                            <span v-if="index !== item.documentVisibility.length - 1">,</span>
                        </span>
                        <v-btn icon x-small @click="editeTitle(item)">
                            <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                    <div v-else class="d-flex">
                        <v-select
                            v-model="roleSelect"
                            :label="`${$t('select')} ${$t('visibility')}`"
                            :items="roleList"
                            item-text="text"
                            item-value="value"
                            multiple
                            dense
                            hide-details
                        />
                        <v-btn :loading="isLoading" icon x-small @click="saveTitle(item)">
                            <v-icon color="success">mdi-check</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="editeTitle(item)">
                            <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </template>
            <template v-slot:item.active="{item}">
                <v-switch
                    v-model="item.active"
                    :disabled="isChangeActive[item.id]"
                    :loading="isChangeActive[item.id]"
                    :label="item.active ? $t('active') : $t('hidden')"
                    color="primary"
                    hide-details
                    @change="changeActive(item)"
                />
            </template>
            <template v-slot:item.edit="{item}">
                <v-btn
                    nuxt
                    :to="{
                        name: 'documentEditor',
                        params: {id: item.id},
                    }"
                    icon
                    small
                    color="primary"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.actions="{item}">
                <v-btn
                    :disabled="isCopying[item.id]"
                    :loading="isCopying[item.id]"
                    text
                    small
                    color="primary"
                    class="text-decoration-underline text-lowercase"
                    @click="copyDocument(item)"
                >
                    {{ $t('copy') }}
                </v-btn>
                <v-btn
                    :disabled="isDeleting[item.id]"
                    :loading="isDeleting[item.id]"
                    color="error"
                    text
                    small
                    class="text-decoration-underline text-lowercase"
                    @click="
                        showDeleteModal = true
                        selectedDocumentId = item.id
                    "
                >
                    {{ $t('delete') }}
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="showDeleteModal" hide-overlay width="500">
            <v-card>
                <v-card-title class="title font-weight-light text-nobreak">
                    {{ $t('admin.want_to_delete_document_template') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" small class="mr-4" @click="deleteDocument(selectedDocumentId)">
                        {{ $t('delete') }}
                    </v-btn>
                    <v-btn
                        small
                        @click="
                            showDeleteModal = false
                            selectedDocumentId = null
                        "
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import EmailTemplatesPage from '@/pages/admin/EmailTemplatesPage'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component
    export default class DocumentTemplatesPage extends mixins(EmailTemplatesPage, DocumentTemplatesMixin) {
        isDeleting = {}
        isCopying = {}
        isChangeActive = {}
        isEditingTitle = {}
        key = 0
        roleSelect = null
        showDeleteModal = false
        selectedDocumentId = null

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.load)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.load)
        }

        async mounted() {
            await this.load()
        }

        async load() {
            this.isLoading = true
            await this.loadDocumentTemplates({locale: this.locale})
            this.isLoading = false
        }

        async editeTitle(item) {
            this.roleSelect = item.documentVisibility
            this.isEditingTitle[item.id] = !this.isEditingTitle[item.id]
            this.key++
        }

        async saveTitle(item) {
            try {
                this.isLoading = true
                await this.$api.documentTemplates.put(item.id, {documentVisibility: this.roleSelect})
                await this.loadDocumentTemplates()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                await this.editeTitle(item)
                this.isLoading = false
            }
        }

        async changeActive(item) {
            try {
                this.isChangeActive[item.id] = true
                this.isLoading = true
                await this.$api.documentTemplates.put(item.id, {active: item.active})
                await this.loadDocumentTemplates()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isChangeActive[item.id] = false
                this.isLoading = false
            }
        }

        async copyDocument(item) {
            try {
                this.isCopying[item.id] = true
                this.isLoading = true
                const originTemplate =
                    this.locale === 'en'
                        ? item
                        : (await this.$api.documentTemplates.get({id: item.id, localeCode: 'en'})).templates[0]
                const copyTemplate = await this.$api.documentTemplates.post({
                    active: false,
                    documentType: originTemplate.documentType,
                    documentVisibility: originTemplate.documentVisibility,
                    htmlTemplate: originTemplate.htmlTemplate,
                    title: `${originTemplate.title} - ${this.$t('copy').toLocaleLowerCase()}`,
                    localeCode: 'en',
                })
                const templateLocales = item.templateLocales.filter(el => el !== 'en')
                const promises = []
                const setTemplates = async (localeCode, id) => {
                    let origin = (await this.$api.documentTemplates.get({id: item.id, localeCode})).templates[0]
                    await this.$api.documentTemplates.put(id, {
                        htmlTemplate: origin.htmlTemplate,
                        title: `${origin.title} - copy`,
                        localeCode,
                    })
                }
                if (templateLocales.length) {
                    templateLocales.map(locale => promises.push(setTemplates(locale, copyTemplate.id)))
                    await Promise.all(promises)
                }
                await this.loadDocumentTemplates()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isCopying[item.id] = false
                this.isLoading = false
            }
        }

        async deleteDocument(id) {
            try {
                this.showDeleteModal = false
                this.isDeleting[id] = true
                this.isLoading = true
                await this.$api.documentTemplates.remove(id)
                await this.loadDocumentTemplates()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isDeleting[id] = false
                this.isLoading = false
                this.selectedDocumentId = null
            }
        }

        get headers() {
            return [
                {text: '#', value: 'id'},
                {text: this.$t('title'), value: 'title'},
                {text: this.$t('document_type'), value: 'documentType'},
                {text: this.$t('language'), value: 'lang'},
                {text: this.$t('visibility'), value: 'visibility', width: 300},
                {text: this.$t('active'), value: 'active'},
                {text: this.$t('edit'), value: 'edit', align: 'center'},
                {text: this.$t('actions'), value: 'actions', align: 'center'},
            ]
        }

        get roleList() {
            return ['TOUR_OPERATOR_1LEVEL', 'TOUR_OPERATOR', 'TOUR_AGENCY', 'CORPORATOR', 'PRIVATE_CLIENT'].map(el => ({
                value: el,
                text: this.$t(`roleTypes.${el}`),
                disabled: el === 'TOUR_OPERATOR_1LEVEL',
            }))
        }
    }
</script>
