<template>
    <v-dialog v-model="modal" :width="$config.account.paymentSchedulerAutoPaymentMode ? 500 : 600">
        <v-card :loading="isLoading">
            <v-card-title>
                {{ $config.account.paymentSchedulerAutoPaymentMode ? $t('add_payment') : $t('add_payment_schedule') }}
            </v-card-title>
            <v-card-text>
                <div class="caption mt-n4" style="white-space: pre-line">
                    {{ $t('add_payment_schedule_description') }}
                </div>
                <v-row v-if="isShowTypeSwitcher" class="mt-2">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="invoiceType"
                            :items="invoiceTypes"
                            hide-details
                            solo
                            @change="loadManualInvoiceIssueInfo"
                        />
                    </v-col>
                </v-row>
                <v-radio-group v-if="suppliers && suppliers.length > 1" v-model="supplierId">
                    <v-radio
                        v-for="supplier in suppliers"
                        :key="supplier.supplierId"
                        :label="`${supplier.supplierName}`"
                        :value="supplier.supplierId"
                    />
                </v-radio-group>
                <v-form
                    v-if="issueItemBySupplier"
                    ref="form"
                    v-model="valid"
                    :disabled="isLoading"
                    align="center"
                    class="pt-6"
                >
                    <v-row v-for="(price, i) in issueItemBySupplier.prices" :key="price.currency">
                        <v-col
                            :md="$config.account.paymentSchedulerAutoPaymentMode ? 4 : 3"
                            :cols="$config.account.paymentSchedulerAutoPaymentMode ? 8 : 10"
                        >
                            <div class="full-width full-height d-flex align-start justify-start flex-column">
                                <span class="caption mt-n2"> Balance owing </span>
                                <span v-if="price" class="text-h6 black--text mt-n1">
                                    {{ {amount: price.totalAmount, currency: price.currency} | formatPrice }}
                                </span>
                            </div>
                        </v-col>
                        <v-col
                            v-if="
                                isMobile &&
                                issueItemBySupplier.prices.length > 1 &&
                                $config.account.paymentSchedulerAutoPaymentMode !== true
                            "
                            cols="2"
                        >
                            <v-btn icon @click="removePrice(i)">
                                <v-icon> mdi-close </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col :md="4" cols="12">
                            <v-text-field
                                v-model="price.amount"
                                :prefix="price && price.currency"
                                outlined
                                hide-details
                                dense
                                :label="`${$t('invoices.amount')}*`"
                                :rules="[
                                    requiredRule('invoices.amount_to_pay'),
                                    v => +v <= price.amount || `${$t('max_value_is')} ${price.amount}`,
                                ]"
                            />
                        </v-col>
                        <v-col v-if="!$config.account.paymentSchedulerAutoPaymentMode" md="4" cols="12">
                            <date-picker
                                v-model="price.dueToPay"
                                outlined
                                dense
                                :label="`${$t('invoices.due_date')}*`"
                                :min="$dateFns.format(new Date())"
                                hide-details
                                :rules="[requiredRule('invoices.due_date')]"
                            />
                        </v-col>
                        <v-col
                            v-if="
                                !isMobile &&
                                issueItemBySupplier.prices.length > 1 &&
                                $config.account.paymentSchedulerAutoPaymentMode !== true
                            "
                            cols="1"
                        >
                            <v-btn icon @click="removePrice(i)">
                                <v-icon> mdi-close </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            v-if="$config.account.paymentSchedulerAutoPaymentMode"
                            md="4"
                            cols="12"
                            class="d-flex align-center"
                        >
                            <v-spacer />
                            <v-btn
                                color="primary"
                                elevation="0"
                                block
                                :disabled="!price.amount"
                                :loading="loadingConfirm"
                                @click="silentPayPrice(i)"
                            >
                                <v-icon small left> mdi-plus-circle-multiple-outline </v-icon>
                                <span class="caption">
                                    {{ $t('pay') }}
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center py-5">
                <div v-if="$config.account.paymentSchedulerAutoPaymentMode !== true" @click="confirm">
                    <v-btn color="primary" small class="me-2" :disabled="!valid || isLoading">
                        {{ $t('confirm') }}
                    </v-btn>
                </div>
                <v-btn color="white" small @click="modal = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {DatePicker},
    })
    export default class OrderAddDepositModal extends mixins(FormValidationRulesMixin) {
        @VModel() modal
        @Prop() objectId

        valid = false
        loadingConfirm = false
        loadingInfo = false

        invoiceType = 'CUSTOMER'
        supplierId = null

        issueInfoItems = []

        @Watch('modal')
        async onModalChange() {
            if (this.modal) {
                this.reset()
                await this.loadManualInvoiceIssueInfo()
            }
        }

        async loadManualInvoiceIssueInfo() {
            this.issueInfoItems = []
            this.supplierId = null
            this.loadingInfo = true
            this.$refs.form?.reset()

            try {
                const manualInvoiceIssueInfo = await this.$api.manualInvoiceIssueInfo.get({
                    orderId: this.objectId,
                    invoiceType: this.invoiceType,
                })
                const issueInfoItems = manualInvoiceIssueInfo.items

                issueInfoItems.forEach(item => {
                    item.prices.forEach(p => {
                        p.totalAmount = p.amount
                        if (this.setDueDateOnLoad) {
                            const dueToPayFromInfo = manualInvoiceIssueInfo.dueToPay?.split(' ')[0]

                            p.dueToPay = this.$dateFns.isAfter(new Date(), new Date(dueToPayFromInfo))
                                ? this.$dateFns.format(new Date())
                                : dueToPayFromInfo
                        }
                    })
                })

                this.issueInfoItems = issueInfoItems
                this.supplierId = this.issueInfoItems?.[0]?.supplierId
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loadingInfo = false
            }
        }

        @Emit()
        async confirm(event, autoPaymentMode = false) {
            let silentPayInvoiceId = null

            this.$refs.form?.validate()

            if (this.valid) {
                this.loadingConfirm = true

                try {
                    const result = await Promise.all(
                        this.issueItemBySupplier.prices.map(price => {
                            const priceToSend = {...price}
                            const dueToPay = price.dueToPay

                            delete priceToSend.dueToPay
                            delete priceToSend.totalAmount

                            return this.$api.issueManualInvoice.post({
                                orderId: this.objectId,
                                invoiceType: this.invoiceType,
                                supplierId: this.supplierId,
                                prices: [priceToSend],
                                ...(dueToPay && {dueToPay: `${dueToPay}T23:59`}),
                            })
                        })
                    )

                    silentPayInvoiceId = result[0].invoices[0]?.invoiceId

                    if (!autoPaymentMode) {
                        this.$toast.success(this.$t('success_message.saved_successfully'))
                    }
                    // eslint-disable-next-line no-empty
                } catch (e) {
                    this.$toast.error(this.$t('error_message.save_error'))
                } finally {
                    if (!autoPaymentMode) {
                        this.loadingConfirm = false
                        this.modal = false
                    }
                }

                if (autoPaymentMode) {
                    return {autoPaymentMode: true, invoiceId: silentPayInvoiceId}
                }
            }
        }

        async silentPayPrice(i) {
            this.issueItemBySupplier.prices = [this.issueItemBySupplier.prices[i]]
            await this.$nextTick(() => {})
            await this.confirm(null, true)
        }

        reset() {
            this.loadingConfirm = false
            this.loadingInfo = false
        }

        removePrice(i) {
            this.issueItemBySupplier.prices.splice(i, 1)
        }

        get setDueDateOnLoad() {
            return false
        }

        get isShowTypeSwitcher() {
            if (this.$config.account.paymentSchedulerAutoPaymentMode) {
                return this.isTO1 || this.isTO2 || this.isAgency
            } else {
                return this.isTO1
            }
        }

        get issueItemBySupplier() {
            if (!this.issueInfoItems?.length) {
                return null
            }

            return this.invoiceType === 'CUSTOMER'
                ? this.issueInfoItems[0]
                : this.issueInfoItems.find(i => i.supplierId === this.supplierId)
        }

        set issueItemBySupplier(val) {}

        get suppliers() {
            return this.issueInfoItems.map(i => ({supplierId: i.supplierId, supplierName: i.supplierName}))
        }

        get invoiceTypes() {
            return [
                {text: this.$t('supplier_payments'), value: 'SUPPLIER'},
                {text: this.$t('client_payments'), value: 'CUSTOMER'},
            ]
        }

        get isLoading() {
            return this.loadingConfirm || this.loadingInfo
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>
