<template>
    <div class="d-flex flex-column align-start">
        <download-custom-document-button
            v-for="(template, index) in templates"
            :key="index"
            :order="order"
            :checkbox-services-selected="checkboxServicesSelected"
            :template="template"
            class="px-0"
        />
    </div>
</template>
<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import DownloadCustomDocumentButton from '@/components/account/snippets/DownloadCustomDocumentButton'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {DownloadCustomDocumentButton},
    })
    export default class OrderCustomDocuments extends mixins(DocumentTemplatesMixin) {
        @Prop({required: true}) order
        @Prop() checkboxServicesSelected

        async mounted() {
            this.isLoading = true
            await this.loadDocumentTemplates()
            this.isLoading = false
        }

        get templates() {
            return this.documentTemplates.filter(
                template =>
                    template.active &&
                    template.documentVisibility.includes(this.isB2C ? 'PRIVATE_CLIENT' : authStore.companyType)
            )
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isB2C() {
            return authStore.isB2C
        }

        get companyType() {
            return authStore.companyType
        }
    }
</script>
