<template>
    <v-form ref="form">
        <v-row>
            <v-col md="4">
                <v-text-field v-model="name" :label="$t('name')" dense hide-details single-line />
            </v-col>
            <v-col md="2">
                <v-text-field
                    v-model="latitude"
                    :label="`${$t('latitude')}`"
                    type="number"
                    dense
                    class="input--no-arrows"
                    @change="latitude = Number(latitude)"
                />
            </v-col>
            <v-col md="2">
                <v-text-field
                    v-model="longitude"
                    :label="`${$t('longitude')}`"
                    type="number"
                    dense
                    class="input--no-arrows"
                    @change="longitude = Number(longitude)"
                />
            </v-col>
            <v-col cols="2">
                <v-btn text left small @click="mapModal = true">
                    <v-icon color="accent">mdi-map-marker</v-icon>
                    {{ $t('show') }}
                </v-btn>
            </v-col>
        </v-row>
        <client-only>
            <v-dialog v-if="latitude && longitude" v-model="mapModal" :max-width="500">
                <map-location v-if="mapModal" :latitude="latitude" :longitude="longitude" :height="`500px`" />
            </v-dialog>
        </client-only>
    </v-form>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import AirportsSearchInput from '~/components/snippets/AirportsSearchInput'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferManualForm from '@/components/transfers/booking/forms/TransferManualForm'

    @Component({
        components: {TimeField, AirportsSearchInput, DatePicker},
    })
    export default class ManualForm extends TransferManualForm {
        @Prop() serviceType
        @Prop({required: false}) transferInfo

        getProp(prop) {
            if (!this.request[this.pointType]) return null

            return this.request[this.pointType][this.serviceType][prop]
        }

        setProp(prop, val) {
            this.request[this.pointType][this.serviceType][prop] = val
        }

        initData() {}

        get name() {
            return this.getProp('name')
        }

        set name(value) {
            this.setProp('name', value)
        }

        get latitude() {
            return this.getProp('latitude')
        }

        set latitude(value) {
            this.setProp('latitude', value)
        }

        get longitude() {
            return this.getProp('longitude')
        }

        set longitude(value) {
            this.setProp('longitude', value)
        }
    }
</script>
