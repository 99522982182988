<template>
    <h2 class="text-center mx-auto text--secondary">
        {{ title }}
    </h2>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class MainHeader extends Vue {
        @Prop() title
    }
</script>
