<template>
    <v-card
        v-if="!loaded || (tips && tips.length)"
        :outlined="outlined"
        :elevation="!outlined ? 0 : null"
        data-cy="general-tip"
    >
        <v-card-title style="word-break: break-word" :class="{'d-flex': true, 'justify-center': center}">
            {{ $t('tips_title') }}
            <v-icon v-if="showClose" class="v-card-close mt-3 me-3" @click="close"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader v-if="!loaded" type="list-item-three-line" />
            <template v-else>
                <div v-for="(tip, index) in uniqueTips" :key="tip.title" class="mb-2">
                    <div class="mb-1 font-weight-bold" v-text="tip.title" />
                    <div class="overflow-hidden" v-html="tip.content" />
                    <v-btn
                        v-if="tip.attach"
                        :loading="loadingPdf"
                        class="cursor-pointer"
                        @click="downloadTipAttach(tip.attach, tip.title)"
                    >
                        <v-icon> mdi-file-download-outline </v-icon>
                    </v-btn>
                    <v-divider v-if="index !== uniqueTips.length - 1" class="my-4" />
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {downloadDataAsync} from '@/utils/helpers'

    @Component
    export default class OrderDetailsTips extends Vue {
        @Prop() tips
        @Prop() loaded
        @Prop({type: Boolean, default: false}) showClose
        @Prop({default: true, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) center

        loadingPdf = false

        @Emit()
        close() {}

        async downloadTipAttach(pdf, title) {
            this.loadingPdf = true
            await downloadDataAsync(`data:application/octet-stream;base64,${pdf}`, `${title}.pdf`).then(() => {
                this.loadingPdf = false
            })
        }

        get uniqueTips() {
            return [...new Map(this.tips.map(item => [item['title'], item])).values()]
        }
    }
</script>
