<template>
    <v-dialog v-model="modal" hide-overlay width="800" content-class="overflow--visible">
        <v-card>
            <v-card-title class="white--text primary">
                {{ service.serviceName }}
            </v-card-title>
            <v-card-text class="text-capitalize mt-6">
                <div v-if="!edit">
                    {{ $t('due_to_confirm_by') }}
                    {{ service.dueToConfirmDate | dateTimeFormat }}
                    <v-btn x-small color="primary" icon @click="edit = !edit"><v-icon>mdi-pencil</v-icon></v-btn>
                </div>
                <vc-date-picker
                    v-else
                    v-model="dueToConfirmDate"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DDTHH:mm'}"
                    :locale="$i18n.locale"
                    :min-date="$dateFns.format(new Date())"
                    :max-date="service.startDateTime"
                    :first-day-of-week="$config.firstDayOfWeek || undefined"
                    mode="dateTime"
                    is24hr
                    is-required
                >
                    <template v-slot="{inputEvents}">
                        <div v-on="inputEvents">
                            <v-text-field
                                :label="$t('due_to_confirm_by')"
                                readonly
                                filled
                                hide-details
                                background-color="white"
                                prepend-inner-icon="mdi-av-timer"
                                :append-outer-icon="modifyActive ? 'mdi-loading mdi-spin' : 'mdi-check'"
                                :value="$options.filters.dateTimeFormat(dueToConfirmDate)"
                                dense
                                @click:append-outer="modify"
                            />
                        </div>
                    </template>
                </vc-date-picker>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    depressed
                    color="primary"
                    :loading="confirmPrebookingActive"
                    :disabled="disabled"
                    @click="confirmPrebooking"
                >
                    {{ $t('book') }}
                </v-btn>
                <v-btn
                    depressed
                    color="warning"
                    :loading="cancelServiceActive"
                    :disabled="disabled"
                    @click="cancelService"
                >
                    {{ $t('cancel_prebooking') }}
                </v-btn>
                <v-btn depressed :disabled="disabled" @click="modal = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL} from '@/utils/event-bus'

    @Component
    export default class DueToConfirmDateServiceModal extends Vue {
        modal = false
        service = {}
        confirmPrebookingActive = false
        cancelServiceActive = false
        modifyActive = false
        dueToConfirmDate = null
        edit = false
        orderId = null

        @Emit()
        loadOrder() {}

        mounted() {
            EventBus.$on(SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL, this.show)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL, this.show)
        }

        show(service, orderId) {
            this.service = service
            this.dueToConfirmDate = service.dueToConfirmDate
            this.orderId = orderId
            this.modal = true
        }

        async confirmPrebooking() {
            try {
                this.confirmPrebookingActive = true
                await this.$api.confirmPrebooking.put({processId: this.service.processId})
                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }
                this.modal = false
                this.loadOrder()
            } catch (e) {
                console.error(e)
            } finally {
                this.confirmPrebookingActive = false
            }
        }

        async cancelService() {
            try {
                this.cancelServiceActive = true
                await this.$api.cancelService.post({processId: this.service.processId})
                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }
                this.modal = false
                this.loadOrder()
            } catch (e) {
                console.error(e)
            } finally {
                this.cancelServiceActive = false
            }
        }

        async modify() {
            if (this.dueToConfirmDate === this.service.dueToConfirmDate || this.modifyActive) return
            try {
                this.modifyActive = true
                await this.$api.manualModification.post({
                    processId: this.service.processId,
                    status: 'PRE-BOOKING',
                    dueToConfirmDate: this.dueToConfirmDate,
                })
                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }
                this.modal = false
                this.loadOrder()
            } catch (e) {
                console.error(e)
            } finally {
                this.modifyActive = false
            }
        }

        async markAsNotSentToSupplier() {
            await this.$api.orders.put(this.orderId, {
                servicesInfo: [
                    {
                        processId: this.service.processId,
                        serviceSentToSupplier: false,
                    },
                ],
            })
        }

        get disabled() {
            return this.confirmPrebookingActive || this.cancelServiceActive
        }
    }
</script>
