<template>
    <v-card>
        <v-data-table
            :headers="headers"
            :items="entries"
            :loading="isLoading"
            :items-per-page="10"
            :hide-default-header="isMobile"
            :mobile-breakpoint="0"
            :footer-props="{'items-per-page-options': [10, 25, 50]}"
        >
            <template v-slot:item.time="{item}">
                {{ item.time | dateTimeFormat }}
            </template>
            <template v-slot:item.user="{item}">
                <div v-if="item.user?.login">{{ item.user.login }}</div>
                <div v-if="item.user?.name">{{ item.user.name }}</div>
                <div v-if="item.user?.companyName" class="grey--text">{{ `(${item.user.companyName})` }}</div>
            </template>
            <template v-slot:item.operationResult="{item}">
                <div class="py-4" v-html="item.operationResult" />
            </template>
            <template v-slot:item.mobile="{item}">
                <div class="py-2 d-flex full-width">
                    <div class="d-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden">
                        <div>{{ item.time | dateTimeFormat }}</div>
                        <div>{{ item.serviceName }}</div>
                        <div v-html="item.operationResult" />
                    </div>
                    <div class="pl-2 d-flex flex-column flex-grow-0 flex-shrink-0 align-end overflow-hidden">
                        <div v-if="item.user?.login">{{ item.user.login }}</div>
                        <div v-if="item.user?.name">{{ item.user.name }}</div>
                        <div v-if="item.user?.companyName" class="grey--text">{{ `(${item.user.companyName})` }}</div>
                    </div>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component({
        components: {},
    })
    export default class OrderHistory extends Vue {
        @Prop() orderId

        isLoading = false
        entries = []

        async mounted() {
            try {
                this.isLoading = true
                const {entries} = await this.$api.orderHistory.get({orderId: this.orderId})

                this.entries = entries
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        get headers() {
            if (this.isMobile) return [{value: 'mobile'}]

            return [
                {text: this.$t('time'), value: 'time'},
                {text: this.$t('order_service'), value: 'serviceName'},
                {text: this.$t('user'), value: 'user'},
                {text: this.$t('operation'), value: 'operation'},
                {text: this.$t('operation_result'), value: 'operationResult'},
            ]
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
