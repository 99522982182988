<template>
    <v-card outlined>
        <v-list-item three-line class="pl-0">
            <v-list-item-avatar class="my-0" size="125" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="product.info.imageUrl | imageUrl(200)"
                    :alt="product.info.name"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @error="imgError = true"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && product.info.imageUrl"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start">
                <v-row dense no-gutters>
                    <v-col cols="9">
                        <v-list-item-title :title="product.info.name" class="wrap-words">
                            <nuxt-link class="text-decoration-none font-weight-medium" :to="productLink" no-prefetch>
                                {{ product.info.name }}
                            </nuxt-link>
                            <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" />
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="product.info.description"
                            class="short-description mt-2"
                            v-html="$options.filters.stripHtml(product.info.description)"
                        />
                    </v-col>
                    <v-col v-if="product.price" cols="3" class="text-right">
                        <extra-service-card-price-block :offer="minPriceOffer" />
                    </v-col>
                    <v-col v-if="product.offers[0].price" cols="3" class="text-right">
                        <strong data-cy="client-price">{{ $t('from') }} {{ minPriceOffer.price | price }}</strong>
                        <discount-label :price="minPriceOffer.price" />
                        <small class="mt-2">
                            <commission-amount :commission="minPriceOffer.price.commission" />
                        </small>
                    </v-col>
                </v-row>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn text icon @click.stop="toggleOffers">
                    <v-icon v-if="!expanded">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-expand-transition>
            <div v-if="expanded">
                <extra-service-offer-card
                    v-for="(offer, offerIndex) in sortedOffers"
                    :key="offerIndex"
                    :offer="offer"
                    :full-card="true"
                    :data-cy="`extra-service-offer-card-${offerIndex}`"
                >
                    <template v-slot:price>
                        <slot name="price" :offer="offer" />
                    </template>
                </extra-service-offer-card>
                <v-list-item
                    v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                    dense
                    class="d-flex justify-center"
                    @click.stop="expanded = product.offers.length"
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-list-item>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ExtraServiceOfferCard from '@/components/extraServices/info/ExtraServiceOfferCard'
    import ExtraServiceCardPriceBlock from '~src/components/extraServices/search/offers/ExtraServiceCardPriceBlock'
    import {authStore, extraServicesStore, extraServicesRuntimeStore} from '@/utils/store-accessor'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            CommissionAmount,
            DiscountLabel,
            ExtraServiceOfferCard,
            ExtraServiceCardPriceBlock,
            SupplierNameLabel,
        },
    })
    export default class ExtraServiceCard extends Vue {
        @Prop({default: () => []}) product

        expanded = 0
        imgError = false

        get sortedOffers() {
            let offers = [...this.product.offers]
            if (this.expandedLimit <= offers.length) {
                this.expanded = offers.length
            }

            return offers.slice(0, this.expanded)
        }

        get expandedLimit() {
            return 3
        }

        get minPriceOffer() {
            return this.product.offers.reduce(
                (a, b) => (a.price.amount < b.price.amount ? a : b),
                this.product.offers[0]
            )
        }

        get isTO1() {
            return authStore.isTO1
        }

        get productLink() {
            return extraServicesRuntimeStore.infoPageLink(this.product, extraServicesStore.searchRequest)
        }

        toggleOffers() {
            let expanded = 0
            if (!this.expanded) {
                expanded = this.expandedLimit
            }
            this.expanded = expanded
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }

    .short-description {
        -webkit-line-clamp: 4;
    }
</style>
