<template>
    <div>
        <breadcrumbs v-if="!isMobile" class="mb-8" />
        <portal v-if="isMobile" to="under-header">
            <v-tabs v-model="selectedTab" :icons-and-text="isMobile" fixed-tabs class="sticky-bar">
                <v-tab v-for="tab in tabs" :key="tab.label">
                    {{ isMobile ? $t(`${tab.label}`) : '' }}
                    <v-icon>{{ tab.icon }}</v-icon>
                </v-tab>
            </v-tabs>
        </portal>
        <v-row dense>
            <v-col
                v-if="(!isMobile || selectedTab === 0) && !isSupplier && personRole !== 'dispatcher'"
                cols="12"
                sm="4"
                md="3"
            >
                <v-btn class="primary" small to="/">
                    {{ $t('new_order') }}
                </v-btn>
                <v-btn
                    v-if="$config.account.newReservationRequest"
                    small
                    class="grey ms-2 lighten-4"
                    @click="newOrderRequestModal = true"
                >
                    {{ $t('new_reservation_request') }}
                </v-btn>
                <new-order-request-modal v-model="newOrderRequestModal" />
            </v-col>
            <v-col v-if="!isMobile || selectedTab === 1" cols="12" md="2">
                <order-quick-filter
                    ref="quickFilter"
                    :person="person"
                    @change="
                        $refs.orders.filter()
                        $refs.filters.load()
                        selectedTab = 0
                    "
                />
            </v-col>
        </v-row>
        <div v-show="!isMobile || selectedTab === 1">
            <order-selected-filters
                @filter="
                    $refs.orders.filter()
                    $refs.filters.load()
                    $refs.quickFilter.clearQuickFilter()
                "
            >
                <template v-slot:save-btn>
                    <custom-filter-modal
                        v-if="isSomeFiltersSelected"
                        :filters="ordersFilters"
                        :person="person"
                        @create-custom-filter="$refs.quickFilter.loadCustomFilters()"
                    />
                </template>
            </order-selected-filters>
            <order-filters
                ref="filters"
                :filters-list="filtersList"
                @change-filters="hiddenFilters.filters = $event"
                @filter="
                    $refs.orders.filter()
                    selectedTab = 0
                "
            />
        </div>
        <div v-show="!isMobile || selectedTab === 0">
            <div v-if="isB2B && !isMobile" class="text-right">
                <v-divider />
                <div class="d-flex align-center">
                    <div class="d-flex align-center">
                        <div v-if="ordersCount" class="me-2 grey--text text-caption">
                            {{ `${$t('orders')}: ${ordersCount}` }}
                        </div>
                        <orders-download-menu :orders="selectedOrders" :sort-order="sortOrder" class="d-block" />
                        <order-emails-menu v-if="showOrderEmailsMenu" v-model="selectedOrders" class="d-block" />
                        <agents-payments-upload v-if="isTO1" />
                        <edite-reservations-modal
                            v-if="(isTourOperator || isAgency) && $config.showEditeReservations"
                            :order-ids="selectedOrderIds"
                        />
                        <reservations-summary-modal v-if="isTO1 && summaryKey" :summary-key="summaryKey" />
                    </div>
                    <v-spacer />
                    <cancelled-services-checkbox v-if="showCancelledServicesCheckbox" class="me-4" />
                    <show-commission-and-netto-checkbox
                        v-show="(isTourOperator || isAgency) && personRole !== 'dispatcher'"
                        class="me-8"
                    />
                    <div style="min-width: 125px">
                        <v-switch
                            v-model="activeFilter"
                            :label="activeFilter ? $t('active') : $t('orderStatus.ARCHIVED')"
                            color="primary"
                            dense
                            hide-details
                            class="mt-0"
                            @change="$refs.filters.filter()"
                        />
                    </div>
                    <reservations-table-options-modal
                        :table-columns="tableColumns"
                        class="me-2"
                        @change="hiddenColumns.columns = $event"
                    />
                    <v-btn-toggle v-model="expandedAllButtonGroup" class="text-right" mandatory>
                        <v-btn @click.native="expandAll">
                            <v-icon>mdi-arrow-expand-vertical</v-icon>
                        </v-btn>
                        <v-btn @click.native="collapseAll">
                            <v-icon>mdi-format-align-justify</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <orders
                        ref="orders"
                        v-model="selectedOrders"
                        :is-expanded-all="!expandedAllButtonGroup"
                        :selectable="selectable"
                        :table-columns="tableColumns"
                        @change-sort-order="sortOrder = $event"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import Orders from '~src/components/account/orders.src'
    import OrderSelectedFilters from '@/components/account/filters/OrderSelectedFilters'
    import OrderQuickFilter from '@/components/account/filters/OrderQuickFilter'
    import CustomFilterModal from '@/components/account/filters/CustomFilterModal'
    import OrderFilters from '~src/components/account/filters/orderFilters.src'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import NewOrderRequestModal from '~src/components/account/modals/newOrderRequest/newOrderRequestModal.src'
    import ShowCommissionAndNettoCheckbox from '~src/components/account/showCommissionAndNettoCheckbox.src'
    import CancelledServicesCheckbox from '@/components/account/CancelledServicesCheckbox'
    import ReservationsTableOptionsModal from '@/components/account/ReservationsTableOptionsModal'
    import OrdersDownloadMenu from '@/components/account/snippets/OrdersDownloadMenu'
    import OrderEmailsMenu from '@/components/account/snippets/OrderEmailsMenu'
    import AgentsPaymentsUpload from '~src/components/account/snippets/AgentsPaymentsUpload.vue'
    import AuthInfoMixin from '~src/mixins/authInfoMixin.src'
    import EditeReservationsModal from '@/components/account/EditeReservationsModal'
    import ReservationsSummaryModal from '@/components/account/ReservationsSummaryModal'

    @Component({
        components: {
            AgentsPaymentsUpload,
            ShowCommissionAndNettoCheckbox,
            NewOrderRequestModal,
            Orders,
            Breadcrumbs,
            OrderSelectedFilters,
            OrderFilters,
            OrderQuickFilter,
            CustomFilterModal,
            CancelledServicesCheckbox,
            ReservationsTableOptionsModal,
            OrdersDownloadMenu,
            OrderEmailsMenu,
            EditeReservationsModal,
            ReservationsSummaryModal,
        },
        middleware: 'auth',
    })
    export default class OrdersPage extends mixins(AuthInfoMixin) {
        expandedAllButtonGroup = 0
        newOrderRequestModal = false
        selectedTab = 0
        hiddenColumns = {
            columns: [],
        }
        hiddenFilters = {
            filters: [],
        }

        selectedOrders = []
        sortOrder = 'none'

        async created() {
            this.expandedAllButtonGroup = this.$config.isOrderListExpandedByDefault ? 0 : 1
            await this.getHiddenColumns()
            await this.getHiddenFilters()
        }

        async mounted() {
            await this.$store.restored
            if (persistentStore.selectedOrderId) {
                persistentStore.SET_SELECTED_ORDER_ID(null)
            }
        }

        async getHiddenColumns() {
            try {
                const res = await this.$api.reservationsTableOptions.get({userId: authStore.person.personId})
                if (res?.length) this.hiddenColumns = res[0]
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async getHiddenFilters() {
            try {
                const res = await this.$api.reservationsFiltersConfiguration.get({
                    userId: authStore.person.personId,
                })
                if (res?.length) this.hiddenFilters = res[0]
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        collapseAll() {
            this.$refs.orders.collapseAll()
        }

        expandAll() {
            this.$refs.orders.expandAll()
        }

        get selectedOrderIds() {
            return this.selectedOrders.reduce((orderIds, order) => {
                if (order.orderId) orderIds.push(order.orderId)

                return orderIds
            }, [])
        }

        get ordersFilters() {
            return persistentStore.orderFilters
        }

        get activeFilter() {
            return this.ordersFilters.active
        }

        set activeFilter(val) {
            persistentStore.SET_ORDER_FILTER({key: 'active', val})
        }

        get isSomeFiltersSelected() {
            const filtersCopy = {...this.ordersFilters}

            for (let key in filtersCopy) {
                if (Array.isArray(filtersCopy[key])) {
                    if (filtersCopy[key].length > 0) {
                        return true
                    }
                } else if (filtersCopy[key] instanceof String) {
                    if (filtersCopy[key].length > 0) {
                        return true
                    }
                } else if (filtersCopy[key]) {
                    return true
                }
            }

            return false
        }

        get filtersList() {
            const list = [
                {
                    value: 'orderId',
                    label: this.$t('order_number'),
                    show: 'orderId',
                },
                {
                    value: 'travelerName',
                    label: this.$t('traveler_name'),
                    show: 'travelerName',
                },
                {
                    value: 'processStatuses',
                    label: this.$t('services_status'),
                    show: 'processStatuses',
                },
                {
                    value: 'filterServices',
                    label: this.$t('apply_filter_to_services'),
                    show: 'filterServices',
                },
                {
                    value: 'createdDate',
                    label: this.$t('create_date'),
                    show: 'createdDate',
                },
                {
                    value: 'modifiedDate',
                    label: this.$t('modified_date'),
                    show: 'modifiedDate',
                },
                {
                    value: 'serviceStartDate',
                    label: this.$t('service_start_date'),
                    show: 'serviceStartDate',
                },
                {
                    value: 'serviceEndDate',
                    label: this.$t('service_end_date'),
                    show: 'serviceEndDate',
                },
                {
                    value: 'serviceTypes',
                    label: this.$t('service_types'),
                    show: 'serviceTypes',
                },
                {
                    value: 'serviceName',
                    label: this.$t('service_name'),
                    show: 'serviceName',
                },
                {
                    value: 'city',
                    label: this.$t('city'),
                    show: 'city',
                },
            ]

            if (this.isTO1) {
                list.push({
                    value: 'bookingMethod',
                    label: this.$t('booked_from'),
                    show: 'bookingMethod',
                })
                list.push({
                    value: 'customerPaymentDueDate',
                    label: this.$t('customer_payment_due_date'),
                    show: 'customerPaymentDueDate',
                })
                list.push({
                    value: 'supplierPaymentDueDate',
                    label: this.$t('supplier_payment_due_date'),
                    show: 'supplierPaymentDueDate',
                })
            }

            if (this.optionOrdersEnabled || this.activeProducts.includes('flights')) {
                list.push({
                    value: 'lastTicketingDate',
                    label: this.$t('last_ticketing_date'),
                    show: 'lastTicketingDate',
                })
            }
            if (this.activeProducts.includes('flights')) {
                list.push({
                    value: 'ticketNumber',
                    label: this.$t('ticket_number'),
                    show: 'ticketNumber',
                })
            }
            if (!this.isAgency && this.$config.reservationFilters.reservationStatus) {
                list.push({
                    value: 'reservationStatus',
                    label: this.$t('reservation_status'),
                    show: 'reservationStatus',
                })
            }
            if (!this.isSupplier && this.$config.reservationFilters.reservationName) {
                list.push({
                    value: 'reservationName',
                    label: this.$t('reservation_title'),
                    show: 'reservationName',
                })
            }
            if (!this.isB2C && !this.isSupplier) {
                list.push({
                    value: 'reservationManager',
                    label: this.$t('reservation_manager'),
                    show: 'reservationManager',
                })
                if (this.$config.reservationFilters.serviceManager) {
                    list.push({
                        value: 'serviceManager',
                        label: this.$t('service_manager'),
                        show: 'serviceManager',
                    })
                }
                if (this.$config.reservationFilters.promoCode) {
                    list.push({
                        value: 'promoCode',
                        label: this.$t('promocode'),
                        show: 'promoCode',
                    })
                }
                if (this.$config.reservationFilters.loyaltyPoints) {
                    list.push({
                        value: 'loyaltyPoints',
                        label: this.$t('loyalty_points'),
                        show: 'loyaltyPoints',
                    })
                }
            }
            if (this.$config.reservationFilters.waitingApprovalFromMyself && (this.isAgency || this.isCorporate)) {
                list.push({
                    value: 'waitingApprovalFromMyself',
                    label: this.$t('waiting_approval_from_myself'),
                    show: 'waitingApprovalFromMyself',
                })
            }
            if (this.isTourOperator || this.isAgency || this.isCorporate) {
                if (this.$config.reservationFilters.corporatePolicyViolated) {
                    list.push({
                        value: 'corporatePolicyViolated',
                        label: this.$t('corporate_policy_violation'),
                        show: 'corporatePolicyViolated',
                    })
                }
                if (this.$config.reservationFilters.waitAuthorize) {
                    list.push({
                        value: 'waitAuthorize',
                        label: this.$t('approval_required'),
                        show: 'waitAuthorize',
                    })
                }
            }
            if (this.isTourOperator) {
                list.push({
                    value: 'paymentMethod',
                    label: this.$t('payment_method'),
                    show: 'paymentMethod',
                })
                list.push({
                    value: 'client',
                    label: this.$t('client_name'),
                    show: 'client',
                })
                list.push({
                    value: 'customerCompany',
                    label: this.$t('customer_company'),
                    show: 'customerCompany',
                })
                list.push({
                    value: 'agent',
                    label: this.$t('agent_name'),
                    show: 'agent',
                })
                if (this.$config.reservationFilters.clientType) {
                    list.push({
                        value: 'clientType',
                        label: this.$t('client_type'),
                        show: 'clientType',
                    })
                }
                if (this.$config.reservationFilters.tripOrganizer) {
                    list.push({
                        value: 'tripOrganizer',
                        label: this.$t('trip_organizer_name'),
                        show: 'tripOrganizer',
                    })
                }
                if (this.$config.reservationFilters.crmPriority) {
                    list.push({
                        value: 'crmPriority',
                        label: this.$t('client_priority'),
                        show: 'crmPriority',
                    })
                }
                list.push({
                    value: 'customerPenalty',
                    label: this.$t('client_penalty'),
                    show: 'customerPenalty',
                })
                if (this.$config.reservationFilters.waitingApprovalFromMyself) {
                    list.push({
                        value: 'waitingApprovalFromMyself',
                        label: this.$t('waiting_approval_from_myself'),
                        show: 'waitingApprovalFromMyself',
                    })
                }
                list.push({
                    value: 'supplier',
                    label: this.$t('supplier_name'),
                    show: 'supplier',
                })
                list.push({
                    value: 'noSupplierRef',
                    label: this.$t('empty_supplier_ref'),
                    show: 'noSupplierRef',
                })
                list.push({
                    value: 'supplierRef',
                    label: this.$t('supplier_ref'),
                    show: 'supplierRef',
                })
                if (this.$config.reservationFilters.channelManagerCode) {
                    list.push({
                        value: 'channelManagerCode',
                        label: this.$t('channel_manager_name'),
                        show: 'channelManagerCode',
                    })
                }
                if (this.$config.reservationFilters.chmPartnerBookingNumber) {
                    list.push({
                        value: 'chmPartnerBookingNumber',
                        label: this.$t('channel_manager_ref'),
                        show: 'chmPartnerBookingNumber',
                    })
                }
                list.push({
                    value: 'supplierPenalty',
                    label: this.$t('supplier_penalty'),
                    show: 'supplierPenalty',
                })
                if (this.showPaymentAndInvoiceStatus) {
                    list.push({
                        value: 'customerPaymentStatuses',
                        label: this.$t('client_payment_status'),
                        show: 'customerPaymentStatuses',
                    })
                    list.push({
                        value: 'supplierPaymentStatuses',
                        label: this.$t('supplier_payment_status'),
                        show: 'supplierPaymentStatuses',
                    })
                    list.push({
                        value: 'supplierInvoiceStatuses',
                        label: this.$t('supplier_invoice_status'),
                        show: 'supplierInvoiceStatuses',
                    })
                }
            }
            if (this.isSupplier) {
                list.push({
                    value: 'supplierRef',
                    label: this.$t('supplier_ref'),
                    show: 'supplierRef',
                })
                list.push({
                    value: 'noSupplierRef',
                    label: this.$t('empty_supplier_ref'),
                    show: 'noSupplierRef',
                })
            }

            if (this.hiddenFilters?.filters?.length) {
                this.hiddenFilters.filters.forEach(item => {
                    const index = list.findIndex(el => el.value === item)
                    if (index >= 0) list[index].show = null
                })
            }

            return list
        }

        get tableColumns() {
            const tableColumns = [
                {text: this.$t('statement_of_account.refNumber'), value: 'id', show: 'id'},
                {text: this.$t('created'), value: 'created', show: 'created'},
                {text: this.$t('modified'), value: 'modified', show: 'modified'},
            ]

            tableColumns.push(
                this.isShowOrderStatusByServicesTA
                    ? {text: this.$t('status_by_services'), value: 'statusByServices', show: 'statusByServices'}
                    : {text: this.$t('status'), value: 'status', show: 'status'}
            )
            tableColumns.push({text: this.$t('order_service'), value: 'title', show: 'title'})
            tableColumns.push({text: this.$t('dates'), value: 'date', show: 'date', align: 'center'})
            tableColumns.push({text: this.$t('order_travelers'), value: 'travellers', show: 'travellers'})
            if (this.personRole !== 'dispatcher') {
                if (this.isAgency) {
                    tableColumns.push({
                        text: this.$t('price'),
                        value: 'supplierPrice',
                        show: 'supplierPrice',
                        align: 'right',
                    })
                } else if (this.isTourOperator && this.commissionAndNettoCheckbox) {
                    tableColumns.push({
                        text: this.$t('netto'),
                        value: 'supplierPrice',
                        show: 'supplierPrice',
                        align: 'right',
                    })
                }
            }

            if (!this.isAgency && this.personRole !== 'dispatcher') {
                tableColumns.push({
                    text: this.$t('price'),
                    value: 'price',
                    show: 'price',
                    align: 'right',
                })
            }
            if (this.isShowDueToPay) {
                tableColumns.push({
                    text: this.isAgency ? this.$t('due_to_pay') : this.$t('due_to_pay_from_client'),
                    value: 'dueToPay',
                    show: 'dueToPay',
                    align: 'right',
                })
            }
            if (!this.isB2C && !this.isCorporate && this.commissionAndNettoCheckbox && !this.isSupplier) {
                tableColumns.push({text: this.$t('income'), value: 'income', show: 'income', align: 'right'})
            }
            if (this.isTO1) {
                tableColumns.splice(3, 0, {
                    text: this.$t('company') + ' / ' + this.$t('agent'),
                    value: 'clientCompanyName',
                    show: 'clientCompanyName',
                })
            }
            if (this.showPaymentAndInvoiceStatus && this.$config.account.showOrderInvoiceBtn) {
                tableColumns.push({
                    text: this.$t('invoices.invoice'),
                    value: 'invoice',
                    show: 'invoice',
                    align: 'center',
                })
            }
            tableColumns.push({text: this.$t('voucher'), value: 'voucher', show: 'voucher', align: 'center'})

            if (this.hiddenColumns?.columns?.length) {
                this.hiddenColumns.columns.forEach(item => {
                    const index = tableColumns.findIndex(el => el.value === item)
                    if (index >= 0) tableColumns[index].show = null
                })
            }

            if (this.isDefaultTableColumns && this.isB2C) {
                tableColumns.forEach(column => {
                    if (column.value === 'created') {
                        column.show = null
                    }
                    if (column.value === 'modified') {
                        column.show = null
                    }
                    if (column.value === 'invoice') {
                        column.show = null
                    }

                    return column
                })
            }

            return tableColumns
        }

        get isDefaultTableColumns() {
            return !this.hiddenColumns.userId
        }

        get tabs() {
            return [
                {icon: 'mdi-format-list-text', label: 'reservations'},
                {icon: 'mdi-tune', label: 'filters'},
            ]
        }

        get selectable() {
            return authStore.isTourOperator || authStore.isAgency
        }

        get ordersCount() {
            const num = runtimeStore.ordersResponse.ordersCount || 0

            return new Intl.NumberFormat().format(num)
        }

        get summaryKey() {
            return runtimeStore.ordersResponse.summaryKey || ''
        }

        get isB2B() {
            return authStore.isB2B
        }

        get isB2C() {
            return authStore.isB2C
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get isSupplier() {
            return authStore.isSupplier
        }

        get person() {
            return authStore.person
        }

        get personRole() {
            return authStore.personRole
        }

        get isShowDueToPay() {
            return (this.isTourOperator || this.isAgency) && this.personRole !== 'dispatcher'
        }

        get isShowOrderStatusByServicesTA() {
            return this.$config.account.showOrderStatusByServicesTA && (this.isAgency || this.isTO2)
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus && this.personRole !== 'dispatcher'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get optionOrdersEnabled() {
            return runtimeStore.ordersResponse.orders.some(order =>
                order.services.find(
                    service =>
                        service.status.toLowerCase() === 'preconfirmed' ||
                        service.status.toLowerCase() === 'pre_confirmed'
                )
            )
        }

        get showCancelledServicesCheckbox() {
            if (this.personRole === 'dispatcher') return false

            return runtimeStore.ordersResponse.orders.some(
                order =>
                    order.services.findIndex(
                        service =>
                            service.status.toLowerCase() === 'canceled' &&
                            service.salesTerms.find(item => item.type === 'CLIENT').price.amount === 0
                    ) >= 0
            )
        }

        get showOrderEmailsMenu() {
            return true
        }
    }
</script>
