<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card v-if="isLoading" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-title class="title font-weight-light">
                {{ $t('cancellation') }}
            </v-card-title>
            <v-card-text class="text-center">
                <div v-if="error" class="error--text">
                    {{ $t('cancelInfoMessage.error') }}
                </div>
                <div v-else-if="!haveCancelPricesAll" class="error--text">
                    <div v-text="isB2B ? $t('cancellation_order_text_b2b') : $t('cancellation_order_text_b2c')" />
                </div>
                <template v-else>
                    <cancel-service-status-label
                        :customer-payment-status="order.orderPackage.customerPaymentStatus"
                        :cancel-price="totalCancelPrice"
                        :service-price="order.orderPackage.clientPrice"
                        :is-not-paid="isNotPaid(order.orderPackage)"
                        :company="company"
                    />
                </template>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn v-if="cancellationAllowedAll" class="warning" @click="cancelAll">
                    {{ $t('confirm_cancellation') }}
                </v-btn>
                <v-btn @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import CancelServicesAllModal from '@/components/account/modals/CancelServicesAllModal'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class CancelTourModal extends CancelServicesAllModal {
        order = null

        get isB2B() {
            return authStore.isB2B
        }

        async show(services, order) {
            this.order = order

            CancelServicesAllModal.options.methods.show.call(this, services, order)
        }

        get haveCancelPricesAll() {
            return this.servicesWithCancelPrices?.every(service => {
                return service.cancelPrice
            })
        }

        get cancellationAllowedAll() {
            return (
                this.totalCancelPrice &&
                this.order.orderPackage.customerPaymentStatus !== 'PARTIAL_PAID' &&
                (this.totalCancelPrice.amount === 0 ||
                    (this.totalCancelPrice.amount < this.order.orderPackage.clientPrice?.amount &&
                        !this.isNotPaid(this.order.orderPackage)))
            )
        }

        get cancelRequest() {
            return {containerId: this.order.orderPackage.serviceContainerId}
        }
    }
</script>
