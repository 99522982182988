<template>
    <div class="d-flex align-center">
        <div class="d-flex justify-start day-checkbox">
            <v-checkbox v-for="(day, index) in days" :key="day" v-model="dayOfWeeks[index]" class="mx-1" hide-details>
                <template v-slot:label>
                    <p class="mb-0">
                        {{ $t(`supplier.days.${day}`) }}
                    </p>
                </template>
            </v-checkbox>
        </div>
        <v-radio-group v-model="checker" row class="ml-3" hide-details @change="checkDays()">
            <v-radio color="blue" value="all" :label="$t('supplier.all')" />
            <v-radio color="blue" value="none" :label="$t('supplier.none')" />
        </v-radio-group>
    </div>
</template>

<script>
    import {Component, PropSync, Vue} from 'vue-property-decorator'

    @Component
    export default class DaysPicker extends Vue {
        @PropSync('_dayOfWeeks', {default: () => []}) dayOfWeeks

        checker = 'all'
        days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        checkDays() {
            this.checker === 'all'
                ? (this.dayOfWeeks = [true, true, true, true, true, true, true])
                : (this.dayOfWeeks = [false, false, false, false, false, false, false])
        }
    }
</script>

<style scoped lang="scss">
    .day-checkbox {
        ::v-deep .v-input__slot {
            flex-direction: column-reverse;
            align-items: baseline;
        }
    }
</style>
