<template>
    <service-card
        :service="formattedService"
        icon="mdi-hotel"
        :is-first="isFirst"
        :service-tips="serviceTips"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-if="info" v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :is-package="isPackage"
                :service-image="mainImage"
                :order="order"
                :order-end-date="orderEndDate"
                :not-itinerary-start="notItineraryStart"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-if="info && info.address" v-slot:additionalInfo>
                    <div
                        v-if="info.latitude && info.longitude"
                        class="mt-2 cursor-pointer hotel-address"
                        @click="mapModal = true"
                    >
                        <small class="d-flex align-start">
                            <v-icon color="accent" small style="line-height: 10px">mdi-map-marker</v-icon>
                            {{ hotelAddress }}
                        </small>
                        <div class="ms-4 text-caption primary--text text-decoration-underline text-lowercase">
                            {{ $t('show_on_map') }}
                        </div>
                    </div>
                    <div v-else class="mt-2 hotel-address">
                        <small>{{ hotelAddress }}</small>
                    </div>
                </template>
                <div class="d-flex">
                    <v-icon left small>mdi-weather-night</v-icon>
                    <strong class="black--text">{{ `${nights} ${$tc('night', nights)}` }}</strong>
                </div>
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillAccommodationBookingDataModal = true"
                        >mdi-pencil</v-icon
                    >
                    <span
                        class="text-caption cursor-pointer primary--text"
                        @click="fillAccommodationBookingDataModal = true"
                    >
                        {{ $t('enter_details') }}
                    </span>
                    <fill-accommodation-booking-data-modal
                        v-model="fillAccommodationBookingDataModal"
                        :service="service"
                        :order="order"
                    />
                </div>
                <div v-if="!$breakpoint.smAndDown" class="mt-4" />
                <template v-slot:travelers="{emptyAdditionalFields}">
                    <v-row v-for="room in rooms" :key="room.roomId">
                        <v-col>
                            <div class="d-flex text-subtitle-1">
                                <div>
                                    <v-icon left small>mdi-bed-empty</v-icon>
                                    <strong class="font-weight-bold black--text">
                                        {{ room.roomTypeName }}
                                    </strong>
                                </div>
                                <hotel-bed-type-label
                                    v-if="room.bedTypes && room.bedTypes.length"
                                    :bed-types="room.bedTypes"
                                    class="ms-3"
                                />
                                <!--v-icon right small @click="showRoomModal(room)">
                                    mdi-information-outline
                                </v-icon-->
                            </div>
                            <travelers
                                :service="service"
                                :travelers="roomTravelers(room)"
                                :product="service.serviceType"
                                :details="service.serviceDetails"
                                :order-end-date="orderEndDate"
                                :empty-additional-fields="emptyAdditionalFields"
                            >
                                <template v-slot:mealType="{traveler}">
                                    <v-col cols="12" md="2">
                                        <small class="d-block traveler-label">{{ $t('filterTitles.mealTypes') }}</small>
                                        <span class="text-uppercase black--text font-weight-bold">
                                            {{ traveler.mealType ? traveler.mealType : room.mealTypeName }}
                                        </span>
                                    </v-col>
                                </template>
                            </travelers>
                        </v-col>
                    </v-row>
                    <v-dialog v-if="roomData" v-model="roomModal" width="600" hide-overlay>
                        <div class="modal-close-btn-block">
                            <v-icon class="v-card-close" @click="roomModal = false">mdi-close</v-icon>
                        </div>
                        <div class="mb-2 text-h3">
                            {{ service.serviceName }}
                        </div>
                        <div v-if="roomData.roomTypeName" class="mb-1">
                            <span class="font-weight-medium">{{ $t('roomGroupBy.name') }}</span>
                            : {{ roomData.roomTypeName }}
                        </div>
                        <div v-if="roomData.mealTypeName" class="mb-1">
                            <span class="font-weight-medium">{{ $t('roomGroupBy.name') }}</span>
                            : {{ roomData.mealTypeName }}
                        </div>
                    </v-dialog>
                </template>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base
                    v-if="info"
                    ref="serviceInfoBase"
                    :service="formattedService"
                    :info="infoData"
                    :image="mainImage"
                    enable-custom-product-info
                    @close-modal="closeModal"
                >
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                    <template v-slot:serviceInfo>
                        <div class="d-flex align-center">
                            <v-icon left small color="accent">mdi-map-marker</v-icon>
                            <small>{{ hotelAddress }}</small>
                        </div>
                        <div v-if="info.email && showContacts" class="d-flex align-center text-caption">
                            <v-icon left small>mdi-at</v-icon>
                            {{ info.email }}
                        </div>
                        <div v-if="info.url" class="d-flex align-center text-caption">
                            <v-icon left small>mdi-web</v-icon>
                            <a :href="info.url" target="_blank" class="text-caption">
                                {{ info.url }}
                            </a>
                        </div>
                        <v-row no-gutters>
                            <v-col v-if="info.phone && showContacts" :cols="12" md="12">
                                <div class="d-flex align-center text-caption">
                                    <v-icon left small>mdi-phone</v-icon>
                                    {{ info.phone }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col v-if="info.fax && showContacts" :cols="12" md="12">
                                <div class="d-flex align-center text-caption">
                                    <v-icon left small>mdi-fax</v-icon>
                                    {{ info.fax }}
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:extraInfo>
                        <v-col :cols="12" md="12" class="mt-5">
                            <p
                                v-for="desc in info.descriptions.description"
                                :key="desc.type"
                                v-html="desc.description"
                            />
                        </v-col>
                        <v-col :cols="12" md="8" class="mt-3">
                            <div class="hidden-lg-and-down">
                                <v-row no-gutters>
                                    <v-col class="col-title">
                                        <v-icon left small>mdi-star-circle-outline</v-icon>
                                        {{ $t('services') }}:
                                    </v-col>
                                    <v-col class="pl-2 d-flex flex-wrap">
                                        <v-chip
                                            v-for="service in info.services.service"
                                            :key="service.supplierSpecificCode"
                                            small
                                            class="px-2 mr-1 mt-1"
                                        >
                                            {{ service.otaname || service.supplierServiceName }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </template>
                </service-info-base>
            </v-dialog>
            <client-only>
                <v-dialog v-if="info && info.latitude && info.longitude" v-model="mapModal" :max-width="500">
                    <map-location
                        v-if="mapModal"
                        :latitude="info.latitude"
                        :longitude="info.longitude"
                        :height="`500px`"
                    />
                </v-dialog>
            </client-only>
        </template>
    </service-card>
</template>

<script>
    import {Component, Emit, Prop} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import ServiceInfoBase from '~/components/account/orderServices/ServiceInfoBase'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import Travelers from '~src/components/account/orderServices/travelers.src'
    import MapLocation from '~src/components/info/mapLocation.src'
    import OrderInfoBase from '~src/components/account/orderServices/orderInfoBase.src'
    import HotelBedTypeLabel from '@/components/hotels/snippets/HotelBedTypeLabel'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import FillAccommodationBookingDataModal from '@/components/account/modals/newOrderRequest/FillAccommodationBookingDataModal'

    @Component({
        components: {
            FillAccommodationBookingDataModal,
            MapLocation,
            Travelers,
            ServiceCard,
            OrderInfoBase,
            ServiceInfoBase,
            HotelBedTypeLabel,
        },
    })
    export default class AccommodationService extends ServiceBase {
        @Prop({required: true}) service
        @Prop() itineraryService
        @Prop({default: false}) isPackage
        @Prop() isFirst

        info = null
        moreInfo = false
        hasImages = true
        modal = false
        mapModal = false
        roomModal = false
        roomData = null
        fillAccommodationBookingDataModal = false

        @Emit()
        addMarker(latitude, longitude) {
            return {lat: latitude, lng: longitude, type: 'hotel'}
        }

        @Emit()
        infoLoaded(info) {
            return info
        }

        closeModal() {
            this.modal = false
        }

        async created() {
            const {hotelId, cityCode, hotelCode} = this.service.serviceDetails[0]
            const rq = hotelId ? {hotelId} : {cityCode, hotelCode, supplierCode: this.service.supplierCode}
            try {
                const response = await this.$api.hotelInfo.get(rq)
                this.info = response.hotelDescriptionInfo
                if (this.info.latitude && this.info.longitude) {
                    this.addMarker(this.info.latitude, this.info.longitude)
                }
                this.infoLoaded(this.info)
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        showInfo() {
            if (this.$refs.serviceInfoBase) {
                this.$refs.serviceInfoBase.customProductInfoMode = false
            }

            this.modal = true
        }

        showRoomModal(data) {
            this.roomModal = true
            this.roomData = data
        }

        getTravelerName(traveler) {
            return `${traveler.prefix} ${traveler.name[0].firstName} ${traveler.name[0].lastName}`
        }

        roomTravelers(room) {
            return this.service.travelers.filter(({travelerId}) => room.travelerIds.travelerId.includes(travelerId))
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service.supplierCode)
        }

        get showContacts() {
            return true
        }

        get formattedService() {
            // TODO: temporary solution(block by core), GPFE-4133
            if (this.info?.rating) {
                const serviceDetails = JSON.parse(JSON.stringify(this.service.serviceDetails))
                serviceDetails[0].ratings = [
                    {
                        ratingSource: 'INTERNAL',
                        rating: this.info.rating,
                    },
                ]

                return {
                    ...this.service,
                    serviceDetails,
                }
            }
            return this.service
        }

        get hotelAddress() {
            if (this.info) {
                const address = Object.values(this.info.address).join(', ')
                return address.startsWith(',') ? address.replace(/^.{2}/, '') : address
            }
            return ''
        }

        get rooms() {
            return this.service.serviceDetails[0].rooms
        }

        get serviceCheckIn() {
            const time = this.service.serviceDetails[0].checkInFrom
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return time ? `<span>${date}</span> <small>${this.$t('time_from')} ${time}</small>` : date
        }

        get serviceCheckOut() {
            const time = this.service.serviceDetails[0].checkOutTo
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return time ? `<span>${date}</span> <small>${this.$t('time_before')} ${time}</small>` : date
        }

        get mainImage() {
            return this.info && this.info.mainImage ? this.info.mainImage.url : ''
        }

        get nights() {
            const startDate = new Date(this.service.startDateTime.slice(0, 10))
            const endDate = new Date(this.service.endDateTime.slice(0, 10))
            return (new Date(endDate) - new Date(startDate)) / 8.64e7
        }

        get infoData() {
            return {...this.info, ...{mainImage: this.mainImage}}
        }

        get roomTypes() {
            //TODO roomCategory !== name need discuss
            /*return this.info.roomTypes.roomType.reduce((roomTypes, roomType) => {
                const nameKey = roomType.roomCategory ? 'roomCategory' : 'name'
                if (this.$t('description_type.other') === roomType[nameKey]) {
                    return roomTypes
                }
                if (roomTypes.findIndex(rt => roomType[nameKey] === rt[nameKey]) === -1) {
                    roomTypes.push(roomType)
                }
                return roomTypes
            }, [])*/
            return this.info.roomTypes.roomType
        }

        get notItineraryStart() {
            return this.itineraryService?.type?.start === false
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    .thumb {
        max-width: 350px;
    }

    .col-title {
        white-space: nowrap;
        max-width: fit-content;
    }

    .info-text {
        color: black;
        font-size: 1rem;
        font-style: italic;
    }

    .payment-status {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        min-width: fit-content;
        max-width: fit-content;
    }

    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .hotel-address {
        line-height: 1;
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
