<template>
    <v-list dense class="d-flex account-menu" :class="isMobile ? 'flex-column full-width' : ''">
        <account-menu-custom-items />
        <v-list-item v-if="person" data-cy="account-orders-button" :ripple="false" @click.prevent="closeMenuAll">
            <v-list-item-content :class="isMobile ? 'pa-0' : ''">
                <v-list-item-title>
                    <div class="d-flex">
                        <v-fade-transition :hide-on-leave="true">
                            <div v-if="quickSearchOrdersShow" class="d-flex align-center">
                                <v-text-field
                                    ref="quickSearch"
                                    v-model="quickSearchOrdersText"
                                    :placeholder="$t('quick_search')"
                                    :loading="quickSearchOrdersLoading"
                                    prepend-icon="mdi-magnify"
                                    dense
                                    flat
                                    outlined
                                    hide-details
                                    style="width: 173px"
                                    class="text-caption"
                                    @blur="quickSearchOrdersShow = false"
                                    @keyup.enter="onQuickSearch"
                                />
                            </div>
                        </v-fade-transition>
                        <v-fade-transition :hide-on-leave="true">
                            <div v-if="!quickSearchOrdersShow" class="d-flex align-center">
                                <v-btn small icon @click.stop="onQuickSearchClick">
                                    <v-icon> mdi-magnify </v-icon>
                                </v-btn>
                                <v-btn :to="{name: 'orders'}" text :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                                    {{ $t('orders') }}
                                </v-btn>
                            </div>
                        </v-fade-transition>
                    </div>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showScheduler" :to="{name: 'scheduler'}">
            <v-list-item-content>
                <v-list-item-title>
                    <v-btn text :class="isMobile ? 'pa-0' : ''">
                        {{ $t('scheduler') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider v-if="person" vertical />
        <v-list-item
            v-if="person && isSupplier"
            :to="{name: 'supplierProducts'}"
            data-cy="account-supplier-products-button"
            @click="closeMenuAll"
        >
            <v-list-item-content>
                <v-list-item-title>
                    <v-btn text>
                        {{ $t('products') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider v-if="person && isSupplier" vertical />
        <v-list-item v-if="showFavorites && !isTO1" :to="{name: 'favorites'}">
            <v-list-item-content>
                <v-list-item-title>
                    <v-btn text :class="isMobile ? 'pa-0' : ''">
                        <v-icon v-if="!isMobile" left>mdi-cards-heart-outline</v-icon>
                        {{ $t('favorites') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isShowFinanceBtn">
            <v-menu v-model="menuFinance" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text :class="$breakpoint.smAndDown ? 'pa-0' : ''" v-on="on">
                        {{ $t('finance') }}
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item v-if="isB2B" :to="{name: 'payments'}" @click="closeMenuAll">
                        <v-list-item-icon>
                            <v-icon left>mdi-cash </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('payments') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item>
        <v-list-item
            v-if="isShowReportsBtn"
            :to="{name: 'reports'}"
            data-cy="account-reports-button"
            @click="closeMenuAll"
        >
            <v-list-item-content :class="isMobile ? 'pa-0' : ''">
                <v-list-item-title>
                    <v-btn text :class="isMobile ? 'pa-0' : ''">
                        {{ $t('reports') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasBusinessIntelligenceAccess" :to="{name: 'businessIntelligence'}">
            <v-list-item-content>
                <v-list-item-title>
                    <v-btn text :class="isMobile ? 'pa-0' : ''">
                        <v-icon left>mdi-finance</v-icon>
                        {{ $t('bi') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider
            v-if="hasBusinessIntelligenceAccess || isShowReportsBtn || isShowFinanceBtn || (showFavorites && !isTO1)"
            vertical
        />
        <v-list-item v-if="person && (isB2B || isTourOperator) && personRole !== 'dispatcher' && !isSupplier">
            <v-menu v-model="menuCompany" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text :class="isMobile ? 'pa-0' : ''" v-on="on">
                        {{ $t('my_company') }}
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item v-if="isB2B" :to="{name: 'company-profile'}" @click="closeMenuAll">
                        <v-list-item-icon>
                            <v-icon left>mdi-domain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('my_company') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="isB2B" :to="{name: 'companyPersons'}" @click="closeMenuAll">
                        <v-list-item-icon>
                            <v-icon left>mdi-account-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('users') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item v-if="isB2B && !isCorporate" :to="{name: 'companyClients'}" @click="closeMenuAll">
                        <v-list-item-icon>
                            <v-icon left>mdi-human-male-female</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('private_clients') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && (isAgency || isTourOperator) && $config.account.showCorporateClients"
                        :to="{name: 'companyCorporateClients'}"
                        @click="closeMenuAll"
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-account-tie</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('corporate_clients') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="isB2B && isTourOperator" :to="{name: 'companyAgencies'}" @click="closeMenuAll">
                        <v-list-item-icon>
                            <v-icon left>mdi-brightness-auto</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('agencies') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                        v-if="isTO1 && $config.account.communicationsPage"
                        :to="{name: 'communications'}"
                        @click="closeMenuAll"
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-account-group-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('communications_and_notes') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item>
        <v-list-item>
            <v-menu v-model="menu" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text data-cy="login-menu-button" :class="isMobile ? 'pa-0' : ''" v-on="on">
                        <person-label v-if="person" :person="person" />
                        <span v-else>{{ $t('log_in') }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list v-if="person" dense>
                    <v-list-item
                        :to="isB2B ? {name: 'companyPerson', params: {id: person.personId}} : {name: 'user-settings'}"
                        @click="closeMenuAll"
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('account') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="showFavorites && isTO1" :to="{name: 'favorites'}" @click="closeMenuAll">
                        <v-list-item-icon>
                            <v-icon left>mdi-cards-heart-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('favorites') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon left>mdi-exit-to-app</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('log_out') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-card v-else-if="!forgotPassword" min-width="350" max-width="550">
                    <v-card-title class="title font-weight-light">
                        {{ $t('sign_in') }} {{ $t('or') }}&nbsp;
                        <a href="#" class="font-weight-medium text-decoration-none" @click.prevent="goToRegister">
                            {{ $t('register') }}
                        </a>
                    </v-card-title>
                    <v-card-text>
                        <a
                            v-if="!gpLoginLink && b2bLogin"
                            href="#"
                            data-cy="b2b-auth"
                            class="font-weight-medium text-decoration-none"
                            @click.prevent="goToB2BAuth"
                        >
                            {{ $t('sign_in_as_b2b') }}
                        </a>
                        <a
                            v-else-if="gpLoginLink && b2bLogin"
                            class="font-weight-medium text-decoration-none"
                            :href="gpLoginLink"
                        >
                            {{ $t('sign_in_as_b2b') }}
                        </a>
                        <authorization-form @switch-forgot-password="forgotPassword = true" />
                    </v-card-text>
                </v-card>
                <v-card v-else width="350">
                    <v-card-title class="title font-weight-light">
                        {{ $t('forgot_your_password') }}
                    </v-card-title>
                    <v-card-text>
                        <forgot-password-form
                            @switch-login="forgotPassword = false"
                            @forgot-password-success="onForgotPassword"
                        />
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-list-item>
        <v-list-item v-if="hasEmailTemplatesAccess || hasCMSAccess">
            <v-menu v-model="menuSettings" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text data-cy="login-menu-button" :class="isMobile ? 'pa-0' : ''" v-on="on">
                        <v-icon v-if="!isMobile" left>mdi-cog</v-icon>
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item-group :value="showTranslationKeys" active-class="" @change="toggleTranslationKeys">
                        <v-list-item
                            v-if="hasEmailTemplatesAccess"
                            :to="{name: 'emailTemplates'}"
                            @click="closeMenuAll"
                        >
                            <v-list-item-icon>
                                <v-icon left>mdi-file-document-edit-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('admin.templates') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="hasCMSAccess" @click="cmsLogin">
                            <v-list-item-icon>
                                <v-icon left>mdi-rocket</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('admin.cms') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="$config.admin.showTranslationKeys" :value="true">
                            <template v-slot="{active}">
                                <v-list-item-action class="mr-5">
                                    <v-checkbox dense hide-details :input-value="active" />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('admin.translation_keys') }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-list-item>
    </v-list>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {adminRuntimeStore, authStore} from '@/store'
    import AuthorizationForm from '~src/components/account/forms/authorizationForm.src'
    import ForgotPasswordForm from '~/components/account/forms/ForgotPasswordForm'
    import {AUTH_EVENT, EventBus} from '@/utils/event-bus'
    import PersonLabel from '~src/components/account/snippets/personLabel.src'
    import AccountMenuCustomItems from '~src/components/account/snippets/accountMenuCustomItems.src'
    import AuthInfoMixin from '~src/mixins/authInfoMixin.src'

    @Component({
        components: {
            AccountMenuCustomItems,
            PersonLabel,
            ForgotPasswordForm,
            AuthorizationForm,
        },
    })
    export default class AccountMenu extends mixins(AuthInfoMixin) {
        menu = false
        menuCompany = false
        menuSettings = false
        menuFinance = false
        forgotPassword = false
        quickSearchOrdersText = ''
        quickSearchOrdersLoading = false
        quickSearchOrdersShow = false

        async logout() {
            this.closeMenuAll()
            const isSupplier = authStore.isSupplier
            if (
                this.$route.name !== 'home' &&
                !isSupplier &&
                !this.isGuide &&
                !this.isDriver &&
                this.personRole !== 'dispatcher'
            ) {
                await this.$router.push({name: 'home'})
            }
            authStore.logOut()
            if (
                this.$config.onlyRegistered ||
                isSupplier ||
                this.isGuide ||
                this.isDriver ||
                this.personRole === 'dispatcher'
            ) {
                await this.$router.push({
                    name: this.$config.onlyRegistered === 'client' ? 'authorization' : 'b2b-auth',
                })
            } else {
                await authStore.auth()
            }
            this.$toast.success(this.$t('messages.success.logout'))
        }

        goToRegister() {
            this.closeMenuAll()
            this.$router.push({name: 'register'})
        }

        onForgotPassword() {
            this.closeMenuAll()
            this.forgotPassword = false
        }

        created() {
            EventBus.$on(AUTH_EVENT, this.onAuth)
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.onAuth)
        }

        onAuth() {
            if (this.menu) {
                this.$toast.success(this.$t('messages.success.authorized'))
            }
            this.closeMenuAll()
            this.$emit('auth')
        }

        async cmsLogin() {
            try {
                const {token, user} = await this.$api.cmsAuth.post()
                const form = document.createElement('form')
                form.method = 'POST'
                form.target = '_blank'
                form.action = `${this.$config.routerBase || '/'}cms/admin/auth/auto`
                const jwtField = document.createElement('input')
                jwtField.type = 'hidden'
                jwtField.name = 'jwt'
                jwtField.value = token
                form.appendChild(jwtField)
                const userField = document.createElement('input')
                userField.type = 'hidden'
                userField.name = 'user'
                userField.value = JSON.stringify(user)
                form.appendChild(userField)
                document.body.appendChild(form)
                form.submit()
                document.body.removeChild(form)
                this.closeMenuAll()
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        closeMenuAll() {
            this.menu = false
            this.menuCompany = false
            this.menuFinance = false
        }

        goToB2BAuth() {
            this.closeMenuAll()
            this.$router.push({name: 'b2b-auth'})
        }

        async onQuickSearchClick() {
            this.quickSearchOrdersShow = true
            await this.$nextTick()
            this.$refs.quickSearch.focus()
        }

        async onQuickSearch() {
            this.quickSearchOrdersLoading = true

            try {
                const orders = (
                    await this.$api.orders.get({
                        orderType: authStore.orderType,
                        responseType: 'SHORT',
                        orderId: this.quickSearchOrdersText,
                    })
                ).orders

                if (orders.length) {
                    await this.$router.push({name: 'order-details', params: {id: this.quickSearchOrdersText}})
                } else {
                    this.$toast.error(this.$t('quick_search_error'), {
                        y: 'bottom',
                    })
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.$toast.error(this.$t('quick_search_error'), {
                    y: 'bottom',
                })
            } finally {
                this.quickSearchOrdersLoading = false
            }
        }

        toggleTranslationKeys(showTranslationKeys) {
            adminRuntimeStore.TOGGLE_TRANSLATION_KEYS({showTranslationKeys, $i18n: this.$i18n})
        }

        get isB2B() {
            return authStore.hasCorporateAccess
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get gpLoginLink() {
            return this.$config.gpLoginLink
        }

        get b2bLogin() {
            return this.$config.b2bLogin
        }

        get hasCMSAccess() {
            return this.isB2B && ['to1l.supervisor', 'to1l.director', 'to1l.support'].includes(authStore.person.role)
        }

        get hasEmailTemplatesAccess() {
            return this.isB2B && ['to1l.supervisor', 'to1l.director', 'to1l.support'].includes(authStore.person.role)
        }

        get hasBusinessIntelligenceAccess() {
            return (
                this.$config.account.hasBusinessIntelligenceAccess &&
                this.isB2B &&
                ['to1l.supervisor', 'to1l.director'].includes(authStore.person.role)
            )
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isAgency() {
            return authStore.isAgency
        }

        get person() {
            return authStore.person
        }

        get personRole() {
            return authStore.personRole
        }

        get showFavorites() {
            return (
                !!this.person &&
                (this.activeProducts.includes('hotels') || this.activeProducts.includes('activities')) &&
                this.personRole !== 'dispatcher' &&
                !this.isSupplier
            )
        }

        get showScheduler() {
            const paymentScheduler = this.$config.driverScheduler

            return paymentScheduler && this.isTO1 && ['supervisor', 'director', 'dispatcher'].includes(this.personRole)
        }

        get isShowReportsBtn() {
            return (
                this.person &&
                (this.isB2B || this.isTourOperator) &&
                this.personRole !== 'dispatcher' &&
                !this.isSupplier &&
                (this.$config.showReportsTab || (this.isTO1 && this.$config.showReportsTabTO1))
            )
        }

        get isShowFinanceBtn() {
            return this.person && this.isTO1 && this.$config.account.paymentScheduler
        }

        get isGuide() {
            return authStore.isGuide
        }

        get isDriver() {
            return authStore.isDriver
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get showTranslationKeys() {
            return adminRuntimeStore.showTranslationKeys
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-list-item:hover::before,
        .v-list-item:active::before,
        .v-list-item:focus::before,
        .v-list-item--active::before {
            opacity: 0 !important;
        }

        .v-list-item--active .v-btn::before {
            opacity: 0.04 !important;
        }

        .v-list-item__icon {
            margin-right: 12px !important;
        }
    }
</style>
