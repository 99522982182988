<script>
    import {Vue, Component} from 'nuxt-property-decorator'

    @Component
    export default class SupplierFilter extends Vue {
        render() {
            return null
        }
    }
</script>
