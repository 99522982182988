<template>
    <v-row dense>
        <v-col cols="12" md="6">
            <v-select
                :items="pickUpItems"
                :disabled="disabled"
                :label="$t('carsRent.pick-up_point')"
                dense
                @input="setPickUpLocation"
            />
        </v-col>
        <v-col cols="12" md="6">
            <v-select
                :items="pickUpItems"
                :disabled="disabled"
                :label="$t('carsRent.drop-off_point')"
                dense
                @input="setDropOffLocation"
            />
        </v-col>
    </v-row>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import CarDetailsForm from '@/components/carsRent/booking/forms/CarDetailsForm'

    @Component
    export default class CarRentPoints extends CarDetailsForm {
        clearLocations() {}
    }
</script>
