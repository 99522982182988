<template>
    <v-form>
        <span class="text-subtitle-1 font-weight-medium">{{ `${$t(`details`)} ${index + 1}` }} </span>
        <v-row dense>
            <v-col cols="12" md="2">
                <airports-search-input
                    v-model="product.departureAirportCode"
                    outlined
                    dense
                    :label="`${$t('flights.departure_airport')}`"
                    hide-details
                    single-line
                    :item-text="'iataCode'"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.departureTerminal"
                    outlined
                    dense
                    :label="`${$t('flights.departure_terminal')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <date-picker v-model="dateDeparture" :label="$t('departure_date')" outlined dense />
            </v-col>
            <v-col cols="12" md="2">
                <time-field v-model="timeDeparture" outlined dense :label="`${$t('departure_time')}`" />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="2">
                <airports-search-input
                    v-model="product.arrivalAirportCode"
                    outlined
                    dense
                    :label="`${$t('flights.arrival_airport')}`"
                    hide-details
                    single-line
                    :item-text="'iataCode'"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.arrivalTerminal"
                    outlined
                    dense
                    :label="`${$t('flights.arrival_terminal')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <date-picker v-model="dateArrival" :label="$t('arrival_date')" outlined dense />
            </v-col>
            <v-col cols="12" md="2">
                <time-field v-model="timeArrival" outlined dense :label="`${$t('arrival_time')}`" />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.flightNumber"
                    outlined
                    dense
                    :label="`${$t('flights.flight_number')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.flightClass"
                    outlined
                    dense
                    :label="`${$t('flights.flight_class')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.bookingClass"
                    outlined
                    dense
                    :label="`${$t('flights.booking_class')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    v-model="product.seatsPrebooked"
                    outlined
                    dense
                    :label="`${$t('flights.seats_prebooked')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    v-model="product.mealIncluded"
                    outlined
                    dense
                    :label="`${$t('meal_included')}`"
                    hide-details
                />
            </v-col>
        </v-row>
        <div v-if="product.seats" class="mt-8">
            <v-row v-for="seat in product.seats" :key="seat.travelerRPH" dense align="center" class="mb-2">
                <v-col cols="12" md="2">
                    <v-text-field
                        :value="getFullName(tourists[seat.travelerRPH])"
                        outlined
                        dense
                        :label="`${$tc('order_travelers', 1)}`"
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        v-model="seat.seatNumber"
                        outlined
                        dense
                        :label="`${$t('flights.seat_number')}`"
                        hide-details
                    />
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import AirportsSearchInput from '@/components/snippets/AirportsSearchInput'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'

    @Component({
        components: {TimeField, AirportsSearchInput, DatePicker},
    })
    export default class OfflineServiceFormFlight extends Vue {
        @Prop() productType
        @Prop() index
        @Prop() tourists
        @VModel() product

        airports = []
        departureDateTimeValue = ''
        arrivalDateTimeValue = ''

        mounted() {
            this.departureDateTimeValue = this.product.departureDate || ''
            this.arrivalDateTimeValue = this.product.arrivalDate || ''
        }

        getFullName(tourist) {
            return `${tourist.prefix || ''} ${tourist.firstName || ''} ${tourist.lastName || ''}`
        }

        @Watch('product.seatsPrebooked', {immediate: true})
        onSeatsPrebookedChange() {
            if (this.product.seatsPrebooked) {
                this.product.seats = this.tourists.map((el, i) => ({
                    travelerRPH: i,
                    seatNumber: this.product.seats?.[i]?.seatNumber || '',
                }))
            } else {
                delete this.product.seats
            }
        }

        @Watch('tourists', {immediate: true, deep: true})
        onTouristsChange() {
            if (this.product.seatsPrebooked) {
                this.product.seats = this.tourists.map((el, i) => ({
                    travelerRPH: i,
                    seatNumber: this.product.seats?.[i]?.seatNumber || '',
                }))
            }
        }

        get timeDeparture() {
            return this.departureDateTimeValue?.split('T')?.[1] || ''
        }

        set timeDeparture(val) {
            const dateTime = this.departureDateTimeValue?.split('T') || ['', '']
            dateTime[1] = val
            this.departureDateTimeValue = dateTime.join('T')
            this.product.departureDate = dateTime.join('T')
        }

        get dateDeparture() {
            return this.departureDateTimeValue?.split('T')?.[0] || ''
        }

        set dateDeparture(val) {
            const dateTime = this.departureDateTimeValue?.split('T') || ['', '']
            dateTime[0] = val
            this.departureDateTimeValue = dateTime.join('T')
            this.product.departureDate = dateTime.join('T')
        }

        get timeArrival() {
            return this.arrivalDateTimeValue?.split('T')?.[1] || ''
        }

        set timeArrival(val) {
            const dateTime = this.arrivalDateTimeValue?.split('T') || ['', '']
            dateTime[1] = val
            this.arrivalDateTimeValue = dateTime.join('T')
            this.product.arrivalDate = dateTime.join('T')
        }

        get dateArrival() {
            return this.arrivalDateTimeValue?.split('T')?.[0] || ''
        }

        set dateArrival(val) {
            const dateTime = this.arrivalDateTimeValue?.split('T') || ['', '']
            dateTime[0] = val
            this.arrivalDateTimeValue = dateTime.join('T')
            this.product.arrivalDate = dateTime.join('T')
        }
    }
</script>
