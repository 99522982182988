<template>
    <div ref="map" class="map" />
</template>

<script>
    import {Component, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import gmapsInit from '~/utils/gmaps'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'

    @Component
    export default class OrderDetailsMap extends mixins(HotelMapMixin) {
        @Prop({default: null}) markerCoordinates

        google = null
        map = null
        markers = []
        bounds = null

        @Watch('markerCoordinates.length')
        onMarkersChange() {
            if (!this.map) return
            this.initMarkers()
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        async load() {
            try {
                this.google = await gmapsInit()
                this.map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    fullscreenControl: true,
                    zoom: 6,
                })
                this.initMarkers()
            } catch (error) {
                console.error(error)
            }
        }

        initMarkers() {
            this.markerCoordinates.forEach(e => {
                this.addMarker(e)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            if (this.markers.length > 1) {
                this.map.fitBounds(this.bounds)
            } else {
                this.map.setZoom(16)
                this.map.setCenter(this.markers[0].position)
            }
        }

        addMarker(data) {
            let labelText

            if (data.type === 'activity') {
                labelText = '󱐌'
            } else if (data.type === 'hotel') {
                labelText = '󰦑'
            }

            this.markers.push(
                new this.google.maps.Marker({
                    position: {
                        lat: data.lat,
                        lng: data.lng,
                    },
                    map: this.map,
                    label: {
                        text: labelText,
                        fontFamily: '"Material Design Icons',
                        color: '#ffffff',
                        fontSize: '18px',
                    },
                })
            )
        }
    }
</script>

<style scoped lang="scss">
    .map {
        height: 280px;
        z-index: 1;
    }
</style>
