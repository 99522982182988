<template>
    <div v-if="address" :class="{'cursor-pointer': !!offer}" @click.stop="showOnMap">
        <small class="d-flex align-start">
            <v-icon color="accent" small style="line-height: 20px">mdi-map-marker</v-icon>
            {{ address }}
        </small>
        <div class="ms-4 text-caption primary--text text-decoration-underline text-lowercase">
            {{ $t('show_on_map') }}
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus, SHOW_ON_MAP_EVENT} from '@/utils/event-bus'

    @Component
    export default class HotelAddressLabel extends Vue {
        @Prop({required: true}) address
        @Prop() offer

        showOnMap() {
            if (!this.offer) return
            if (this.$breakpoint.smAndDown) {
                EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 2)
            }
            EventBus.$emit(SHOW_ON_MAP_EVENT, this.offer)
        }
    }
</script>
