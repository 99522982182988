<template>
    <div v-if="!regular || refundable !== undefined" v-intersect.once="onIntersect">
        <a v-if="regular" :class="color">
            {{ label }}
        </a>
        <a v-else :class="color" class="d-flex align-center" @click.prevent="showConditions">
            <v-icon small left class="info--text">mdi-bell-alert-outline</v-icon>
            {{ label }}
        </a>
    </div>
</template>
<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'

    @Component
    export default class FlightConditionsLabel extends ConditionsLabel {
        @Prop() refundable
        @Prop() regular

        async onIntersect(entries, observer, isIntersecting) {
            if (!this.regular) {
                ConditionsLabel.options.methods.onIntersect.call(this, entries, observer, isIntersecting)
            }
        }

        get isLoading() {
            if (!this.regular) {
                return ConditionsLabel.options.computed.isLoading.get.call(this)
            } else {
                return false
            }
        }

        get hasErrors() {
            return !this.regular && ConditionsLabel.options.computed.hasErrors.get.call(this)
        }

        get isFree() {
            return (!this.regular && ConditionsLabel.options.computed.isFree.get.call(this)) || this.refundable
        }

        get isNonRefundable() {
            return (
                (!this.regular && ConditionsLabel.options.computed.isNonRefundable.get.call(this)) ||
                this.refundable === false
            )
        }

        get isFreeLabel() {
            return this.$t('refundable')
        }
    }
</script>

<style scoped>
    a {
        font-size: inherit;
        font-weight: inherit;
    }
</style>
