<template>
    <div v-if="order">
        <v-snackbar v-model="snackbar.show" color="error">
            {{ snackbar.message }}
        </v-snackbar>
        <v-row class="show-scroll-up-mobile">
            <v-col cols="12" md="9" class="pb-0">
                <div class="text-h6 full-width d-flex">
                    <v-btn
                        v-if="isMobile"
                        :to="{name: 'orders'}"
                        fab
                        small
                        class="mt-n1 me-3"
                        elevation="1"
                        style="float: left"
                    >
                        <v-icon class="primary--text">mdi-arrow-left</v-icon>
                    </v-btn>
                    <div style="float: left" class="me-2">
                        <div class="font-weight-bold text-nowrap" :data-cy="`reservation-number-${order.orderId}`">
                            {{ `${$t('order')} #${order.orderId}` }}
                        </div>
                        <div v-if="order.parentOrderId">
                            <v-icon class="mx-1" color="grey lighten-1" dense>mdi-arrow-right-bold</v-icon>
                            <div class="font-weight-bold grey--text text--lighten-1">
                                {{ ` #${order.parentOrderId}` }}
                            </div>
                        </div>
                        <template v-if="isMobile">
                            <div v-if="!orderTitleEdit" class="text-subtitle-1">
                                {{ order.title ? order.title : `${$t('add')} ${$t('title').toLocaleLowerCase()}` }}
                                <v-icon
                                    v-if="isAgency || isTourOperator"
                                    small
                                    color="primary"
                                    @click="
                                        orderTitleEdit = true
                                        orderTitle = order.title
                                    "
                                >
                                    mdi-pencil
                                </v-icon>
                            </div>
                            <div v-else class="d-flex flex-grow-1">
                                <v-text-field v-model="orderTitle" dense hide-details class="me-1" />
                                <v-btn depressed small color="primary" @click="saveOrderTitle">
                                    {{ $t('save') }}
                                </v-btn>
                            </div>
                        </template>
                    </div>
                    <template v-if="!isMobile">
                        <div v-if="!orderTitleEdit" class="ms-2">
                            {{ order.title }}
                            <v-icon
                                v-if="isAgency || isTourOperator"
                                small
                                color="primary"
                                @click="
                                    orderTitleEdit = true
                                    orderTitle = order.title
                                "
                            >
                                mdi-pencil
                            </v-icon>
                        </div>
                        <div v-else class="d-flex ms-2 flex-grow-1">
                            <v-text-field v-model="orderTitle" dense hide-details class="me-1" />
                            <v-btn depressed small color="primary" @click="saveOrderTitle">
                                {{ $t('save') }}
                            </v-btn>
                        </div>
                    </template>
                    <v-btn v-if="isShowSettingsBtn" icon class="ms-auto" @click="showSettingsModal = true">
                        <v-icon> mdi-cog </v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col v-if="!isMobile" cols="12" md="3" class="pb-0" />
            <v-col cols="12" md="9" class="py-0 d-sm-flex justify-space-between">
                <div class="px-md-2">
                    <v-tabs v-model="selectedTabIndex" icons-and-text fixed-tabs show-arrows center-active>
                        <v-tab
                            v-for="(tab, i) in tabs"
                            :key="`${i}-${tab.name}`"
                            :disabled="getTabDisabled(tab)"
                            :style="isMobile ? 'min-width: 60px; padding: 0 6px' : ''"
                            :data-cy="`${tab.name}-tab`"
                        >
                            <v-badge
                                v-if="tab.name === 'communications' && communicationsWithoutInternalNotes.length"
                                color="green"
                                :content="`${
                                    communicationsWithoutInternalNotes.filter(i => i.status === 'ACTIVE').length
                                }`"
                                :offset-x="6"
                                :offset-y="12"
                            >
                                <v-icon>{{ tab.icon }}</v-icon>
                                <span style="font-size: 8px; margin-top: 2px">
                                    {{ $t(tab.label) }}
                                </span>
                            </v-badge>
                            <v-badge
                                v-else-if="tab.name === 'internalNotes' && internalNotes.length"
                                color="green"
                                :content="`${internalNotes.filter(i => i.status === 'ACTIVE').length}`"
                                :offset-x="6"
                                :offset-y="12"
                            >
                                <v-icon>{{ tab.icon }}</v-icon>
                                <span style="font-size: 8px; margin-top: 2px">
                                    {{ $t(tab.label) }}
                                </span>
                            </v-badge>
                            <template v-else>
                                <span style="font-size: 8px; margin-top: 2px">
                                    {{ $t(tab.label) }}
                                </span>
                                <v-icon>{{ tab.icon }}</v-icon>
                            </template>
                        </v-tab>
                        <chat-modal v-if="order && $config.isChatEnabled" :order-id="order.orderId" />
                    </v-tabs>
                </div>
                <v-expand-transition v-if="!isMobile">
                    <div class="d-flex flex-wrap align-center">
                        <div v-if="tabSelectedName === 'info'">
                            <span class="display-format-label">{{ $t('display_format') }}</span>
                            <v-btn
                                :color="displayFormat === 'infoView' ? 'primary' : ''"
                                icon
                                @click="displayFormat = 'infoView'"
                            >
                                <v-icon>mdi-format-list-bulleted</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="itineraryViewServices.length && !$config.account.hideItineraryTab"
                                :color="displayFormat === 'itineraryView' ? 'primary' : ''"
                                icon
                                @click="displayFormat = 'itineraryView'"
                            >
                                <v-icon>mdi-map-legend</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="(isTourOperator || isAgency) && personRole !== 'dispatcher'"
                                :color="displayFormat === 'tableView' ? 'primary' : ''"
                                icon
                                @click="displayFormat = 'tableView'"
                            >
                                <v-icon>mdi-table</v-icon>
                            </v-btn>
                        </div>
                        <div class="px-4 d-flex flex-column">
                            <cancelled-services-checkbox v-if="showCancelledServicesCheckbox" class="me-4" />
                            <show-commission-and-netto-checkbox v-if="isShowCommissionAndNettoCheckbox" />
                        </div>
                        <v-btn icon @click="showSettingsModal = true">
                            <v-icon> mdi-cog </v-icon>
                        </v-btn>
                    </div>
                </v-expand-transition>
            </v-col>
            <v-col v-if="!isMobile" cols="12" md="3" class="py-0" />
        </v-row>
        <v-expand-transition>
            <v-row v-if="displayFormat === 'itineraryView'">
                <v-col :cols="12" :md="6">
                    <div v-if="daysBeforeTrip > 0" class="success--text mb-2">
                        {{ `${daysBeforeTrip} ${$tc('day', daysBeforeTrip)} ${$t('before_trip')} :)` }}
                    </div>
                    <v-slide-group>
                        <v-btn
                            v-for="day in itineraryDays"
                            :key="day.number"
                            :href="`${$route.path}#day-${day.number}`"
                        >
                            {{ `${$tc('day', 1)}  ${day.number}` }}
                        </v-btn>
                        <edit-itinerary-modal
                            v-if="isTO1"
                            :order-id="order.orderId"
                            :order-itinerary="orderItinerary"
                            @save="$emit('modifyService')"
                        />
                    </v-slide-group>
                </v-col>
            </v-row>
        </v-expand-transition>
        <template
            v-if="
                tabSelectedName === 'info' ||
                tabSelectedName === 'communications' ||
                tabSelectedName === 'internalNotes'
            "
        >
            <v-row>
                <v-col v-if="tabSelectedName === 'communications'" md="9">
                    <communications
                        :communications="communicationsWithoutInternalNotes"
                        :order-id="order.orderId"
                        :communications-loading="communicationsLoading"
                        :services="services"
                        @get-communications="loadCommunications"
                    />
                </v-col>
                <v-col v-if="tabSelectedName === 'internalNotes'" md="9">
                    <internal-notes
                        :communications="internalNotes"
                        :order-id="order.orderId"
                        :services="services"
                        :communications-loading="communicationsLoading"
                        @get-communications="loadCommunications"
                    />
                </v-col>
                <v-col v-if="tabSelectedName !== 'communications' && tabSelectedName !== 'internalNotes'" md="9">
                    <div :class="{'order-wrapper': order.orderPackage}">
                        <order-details-traveler-list
                            v-if="$config.account.orderTravelerList && tabSelectedName === 'info'"
                            :order-travelers="orderTravelers"
                            :order-end-date="orderEndDate"
                        />
                        <v-alert
                            v-if="order.orderPackage"
                            border="bottom"
                            color="info"
                            class="white--text text-uppercase mb-0"
                            v-text="order.orderPackage.packageName"
                        />
                        <div class="px-2">
                            <div v-if="order.orderPackage">
                                <component
                                    :is="
                                        order.orderPackage.packageType === 'DYNAMIC_PACKAGE'
                                            ? 'package-info'
                                            : 'package-tour-info'
                                    "
                                    :order="order"
                                    @cancelService="showCancelServiceModal($event)"
                                    @cancel-tour="showCancelTourModal"
                                    @book-all-quote="bookAllQuote"
                                    @issueTicket="$refs.issueTicketModal.show($event)"
                                />
                            </div>
                            <template v-if="itineraryView">
                                <itinerary-info
                                    v-if="orderItinerary.description || orderItinerary.images"
                                    :info="orderItinerary"
                                />
                                <div v-for="(itineraryDay, index) in itineraryDays" :key="index">
                                    <div>
                                        <v-card :id="`day-${itineraryDay.number}`" outlined class="mb-2">
                                            <v-alert color="grey lighten-4" class="black--text mb-0">
                                                <div class="d-flex justify-space-between">
                                                    <div class="d-flex">
                                                        <strong
                                                            class="text-body-1 text-capitalize me-2 font-weight-medium"
                                                        >
                                                            <v-icon>mdi-weather-sunny</v-icon>
                                                            {{ $tc('day', 1) }} {{ itineraryDay.number }}
                                                        </strong>
                                                        <span class="text-body-1 text-capitalize">
                                                            {{ itineraryDay.date | dateFormat }}
                                                        </span>
                                                    </div>
                                                    <weather-service
                                                        v-if="itineraryDay.services"
                                                        :city="{
                                                            id: findAccommodationService(
                                                                itineraryDay.services.times,
                                                                'cityId'
                                                            ),
                                                            cityName: findAccommodationService(
                                                                itineraryDay.services.times,
                                                                'cityName'
                                                            ),
                                                        }"
                                                        :start-date="itineraryDay.services.date"
                                                        show-single-day
                                                    />
                                                </div>
                                            </v-alert>
                                        </v-card>
                                        <v-row class="mb-0">
                                            <v-col
                                                cols="9"
                                                v-html="itineraryDay.description && itineraryDay.description[locale]"
                                            />
                                            <v-col cols="3">
                                                <order-itinerary-day-images
                                                    :images="itineraryDayImages(itineraryDay.number)"
                                                    @show-photo-swipe="showPhotoSwipe($event)"
                                                />
                                            </v-col>
                                        </v-row>
                                        <template v-if="itineraryDay.services">
                                            <div
                                                v-for="(time, timeIndex) in itineraryDay.services.times"
                                                :key="time + timeIndex"
                                                class="d-md-flex"
                                            >
                                                <div class="mr-4 text-subtitle-1 font-weight-regular time">
                                                    <span v-if="time.type.text">
                                                        {{ time.type.text() }}
                                                    </span>
                                                    <span v-if="time.type.textFlight">
                                                        {{ time.type.textFlight() }}&nbsp;
                                                    </span>
                                                    <span v-if="time.time && time.time !== '00:00'">
                                                        {{ time.time }}
                                                    </span>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <component
                                                        :is="serviceComponent(service.serviceType)"
                                                        v-for="(service, i) in time.service"
                                                        :key="service.serviceId + i"
                                                        :service="service"
                                                        :service-tips="
                                                            tips && tips.filter(t => t.serviceId === service.serviceId)
                                                        "
                                                        :itinerary-service="time"
                                                        :is-package="!!service.serviceGroupId"
                                                        class="mb-5"
                                                        :order="order"
                                                        :order-id="order.orderId"
                                                        :object-id="order.objectId"
                                                        :order-end-date="orderEndDate"
                                                        :data-cy="`service-${i}`"
                                                        @cancelService="showCancelServiceModal($event)"
                                                        @issueTicket="$refs.issueTicketModal.show($event)"
                                                        @modifyService="$emit('modifyService')"
                                                        @add-marker="addMarker"
                                                        @info-loaded="info = $event"
                                                        @show-on-map="$emit('show-on-map', $event)"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-if="displayFormat === 'tableView'">
                                <v-card class="mb-6 pb-4">
                                    <v-data-table
                                        :headers="headers"
                                        :items="services"
                                        :hide-default-header="isMobile"
                                        :single-expand="isMobile"
                                        :class="{sortable: isTO1}"
                                        :options="{itemsPerPage: 1000}"
                                        disable-sort
                                        hide-default-footer
                                        class="table"
                                    >
                                        <template v-slot:header.fxGainLoss="{header}">
                                            <span class="cursor-pointer">
                                                {{ header.text }}
                                                <v-btn
                                                    v-if="$config.account.paymentScheduler"
                                                    small
                                                    icon
                                                    right
                                                    @click="isShowFxGainModal = true"
                                                >
                                                    <v-icon small class="cursor-pointer">
                                                        mdi-information-outline
                                                    </v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <template v-slot:item.mobile="{item}">
                                            <v-row class="py-4">
                                                <v-col cols="8" class="text-left">
                                                    <div class="mb-2" :class="item.statusColor">
                                                        {{ item.status }}
                                                    </div>
                                                    <div>
                                                        <v-icon small left>
                                                            {{ getServiceTypeIcon(item.serviceType) }}
                                                        </v-icon>
                                                        <span
                                                            class="text-subtitle-2 font-weight-medium"
                                                            v-html="item.pureTitle"
                                                        />
                                                    </div>
                                                    <div v-html="item.title" />
                                                    <div class="text--secondary" v-html="item.location" />
                                                    <div class="text-no-wrap" v-html="item.date" />
                                                    <div class="mt-2" v-html="item.travellers" />
                                                </v-col>
                                                <v-col cols="4">
                                                    <template v-if="!isAgency && commissionAndNettoCheckbox">
                                                        <span class="font-weight-bold">{{ $t('netto') }}</span>
                                                        <orders-supplier-price-label
                                                            v-if="!item.isEmptyOrder"
                                                            :item="item"
                                                        />
                                                    </template>
                                                    <span class="font-weight-bold">
                                                        {{ $t(!isAgency ? 'brutto' : 'price') }}
                                                    </span>
                                                    <orders-price-label v-if="!item.isEmptyOrder" :item="item" />
                                                    <template v-if="showExchangeRates">
                                                        <template v-if="!$config.account.paymentScheduler">
                                                            <div v-if="item.bookingXR">
                                                                <div class="font-weight-bold">
                                                                    {{ $t('bookingXR') }}
                                                                </div>
                                                                {{ item.bookingXR }}
                                                            </div>
                                                            <div v-if="item.paymentXR">
                                                                <div class="font-weight-bold">
                                                                    {{ $t('paymentXR') }}
                                                                </div>
                                                                {{ item.paymentXR }}
                                                            </div>
                                                        </template>
                                                        <div v-if="item.fxGainLoss.amount">
                                                            <div class="font-weight-bold">{{ $t('fxGainLoss') }}</div>
                                                            {{
                                                                item.fxGainLoss.amount
                                                                    | priceFormat(item.fxGainLoss.currency)
                                                            }}
                                                        </div>
                                                    </template>
                                                    <v-btn
                                                        v-if="!item.isEmptyOrder && voucherAvailable(item)"
                                                        elevation="0"
                                                        small
                                                        class="cursor-pointer mt-auto mb-1"
                                                        @click="openVoucherDialog(item)"
                                                    >
                                                        {{ $t('voucher') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template v-slot:item.id="{item, index}">
                                            {{ `${item.orderId}-${index + 1}` }}
                                            <v-icon @click="openServiceModal(item)">mdi-magnify</v-icon>
                                        </template>
                                        <template v-slot:item.title="{item}">
                                            <div v-if="!item.isEmptyOrder">
                                                <div class="d-flex justify-end justify-md-start">
                                                    <v-icon small class="mr-1">
                                                        {{ getServiceTypeIcon(item.serviceType) }}
                                                    </v-icon>
                                                    <div v-html="item.pureTitle" />
                                                </div>
                                                <div class="ps-5" v-html="item.title + item.location" />
                                            </div>
                                        </template>
                                        <template v-slot:item.date="{item}">
                                            <div class="text-no-wrap" v-html="item.date" />
                                        </template>
                                        <template v-slot:item.travellers="{item}">
                                            <span v-html="item.travellers" />
                                        </template>
                                        <template v-slot:item.status="{item}">
                                            <span :class="item.statusColor">{{ item.status }}</span>
                                            <div v-if="isTO1">
                                                <span>
                                                    {{ item.supplierCompanyName }}
                                                </span>
                                            </div>
                                        </template>
                                        <template v-if="!isAgency" v-slot:item.supplierPrice="{item}">
                                            <orders-supplier-price-label
                                                v-if="!item.isEmptyOrder && item.showAllServicesPrice"
                                                :show-payment-status="showNettoPaymentStatus"
                                                :item="item"
                                                :show-system-currency="showSystemNettoCurrency"
                                            />
                                        </template>
                                        <template v-slot:item.price="{item}">
                                            <orders-price-label
                                                v-if="!item.isEmptyOrder && item.showAllServicesPrice"
                                                :show-payment-status="showPaymentAndInvoiceStatus"
                                                :item="item"
                                            />
                                        </template>
                                        <template v-slot:item.income="{item}">
                                            <template v-if="item.showAllServicesPrice">
                                                <div>{{ item.income.amount | priceFormat(item.income.currency) }}</div>
                                                <div v-if="item.income.percent" class="grey--text">
                                                    {{ `${item.income.percent}%` }}
                                                </div>
                                            </template>
                                        </template>
                                        <template v-slot:item.bookingXR="{item}">
                                            <div v-if="item.bookingXR">
                                                {{ item.bookingXR }}
                                            </div>
                                        </template>
                                        <template v-slot:item.paymentXR="{item}">
                                            <div v-if="item.paymentXR">
                                                {{ item.paymentXR }}
                                            </div>
                                        </template>
                                        <template v-slot:item.fxGainLoss="{item}">
                                            <div v-if="item.fxGainLoss.amount">
                                                {{ item.fxGainLoss.amount | priceFormat(item.fxGainLoss.currency) }}
                                            </div>
                                        </template>
                                        <template v-slot:item.voucher="{item}">
                                            <v-btn
                                                v-if="!item.isEmptyOrder && voucherAvailable(item)"
                                                small
                                                text
                                                class="cursor-pointer"
                                                @click="openVoucherDialog(item)"
                                            >
                                                <v-icon>mdi-file-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-if="!isMobile" v-slot:foot>
                                            <br />
                                            <tr class="mb-4 text-body-2 font-weight-bold th-footer">
                                                <td class="px-4 text-start">
                                                    <br />
                                                    {{ $t('total') }}
                                                </td>
                                                <td />
                                                <td />
                                                <td />
                                                <td v-if="!isAgency && commissionAndNettoCheckbox" />
                                                <td class="px-4 text-right">
                                                    <br />
                                                    <template v-if="!isAgency && commissionAndNettoCheckbox">
                                                        <div v-for="(item, i) in nettoPrice" :key="`nettoTotal-${i}`">
                                                            <div>
                                                                <span data-cy="supplier-total">
                                                                    {{ item.amount | priceFormat(item.currency) }}
                                                                </span>
                                                                <span
                                                                    v-if="
                                                                        showSystemNettoCurrency &&
                                                                        currency !== item.currency
                                                                    "
                                                                >
                                                                    / {{ item | price }}
                                                                </span>
                                                            </div>
                                                            <div
                                                                v-if="item.commission && commissionAndNettoCheckbox"
                                                                class="commission font-weight-regular grey--text"
                                                            >
                                                                {{ $t('commission') }}
                                                                {{ item.commission | priceFormat(item.currency) }}
                                                            </div>
                                                            <div
                                                                v-if="item.commission && commissionAndNettoCheckbox"
                                                                class="commission font-weight-regular grey--text"
                                                            >
                                                                {{ $t('net_amount') }}:
                                                                {{
                                                                    (item.amount - item.commission)
                                                                        | priceFormat(item.currency)
                                                                }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </td>
                                                <td class="px-4 text-right">
                                                    <br />
                                                    <div v-for="(item, i) in bruttoPrice" :key="`bruttoTotal-${i}`">
                                                        <div data-cy="client-total">
                                                            {{ item.amount | priceFormat(item.currency) }}
                                                        </div>
                                                        <div
                                                            v-if="item.commission && commissionAndNettoCheckbox"
                                                            class="commission font-weight-regular grey--text"
                                                        >
                                                            {{ $t('commission') }}
                                                            {{ item.commission | priceFormat(item.currency) }}
                                                        </div>
                                                        <div
                                                            v-if="item.commission && commissionAndNettoCheckbox"
                                                            class="commission font-weight-regular grey--text"
                                                        >
                                                            {{ $t('net_amount') }}
                                                            {{
                                                                (item.amount - item.commission)
                                                                    | priceFormat(item.currency)
                                                            }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <template v-if="showExchangeRates">
                                                    <template v-if="!$config.account.paymentScheduler">
                                                        <td />
                                                        <td />
                                                    </template>
                                                    <td v-if="totalFxGainLoss.amount" class="px-4 text-right">
                                                        <br />
                                                        {{
                                                            totalFxGainLoss.amount
                                                                | priceFormat(totalFxGainLoss.currency)
                                                        }}
                                                    </td>
                                                    <td v-else />
                                                </template>
                                                <td
                                                    v-if="
                                                        !((isAgency || isTourOperator) && !commissionAndNettoCheckbox)
                                                    "
                                                    class="px-4 text-right"
                                                >
                                                    <change-income-dialog
                                                        v-if="isTO1 && !isEmptyOrder && quoteStatusAll"
                                                        :total-brutto-price="totalBruttoPrice"
                                                        :total-netto-price="totalNettoPrice"
                                                        :total-brutto-commission="totalBruttoCommission"
                                                        :total-netto-commission="totalNettoCommission"
                                                        :total-income="totalIncome"
                                                        :netto-price="nettoPrice"
                                                        :services="order.services"
                                                        @modifyService="$emit('modifyService')"
                                                    />
                                                    <div v-else-if="totalIncome" class="text-lowercase">
                                                        {{ $t('income') }}
                                                    </div>
                                                    <div v-if="totalIncome">
                                                        <div>
                                                            {{ totalIncome.amount | priceFormat(totalIncome.currency) }}
                                                        </div>
                                                        <div v-if="totalIncome.percent" class="grey--text">
                                                            {{ `${totalIncome.percent.toFixed(2)}%` }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <br />
                                            <tr
                                                v-if="showPaymentAndInvoiceStatus"
                                                class="grey--text text-body-2 font-weight-bold th-footer"
                                            >
                                                <td class="px-4 text-start">{{ $t('paid') }}</td>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td
                                                    v-if="!isAgency && commissionAndNettoCheckbox"
                                                    class="px-4 text-right"
                                                >
                                                    <div v-for="(item, i) in nettoPaidPrice" :key="`nettoPaid-${i}`">
                                                        <span data-cy="supplier-paid">
                                                            {{ item.amount | priceFormat(item.currency) }}
                                                        </span>
                                                        <span
                                                            v-if="showSystemNettoCurrency && item.currency !== currency"
                                                        >
                                                            /
                                                            {{ item | price }}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="px-4 text-right">
                                                    <div v-for="(item, i) in bruttoPaidPrice" :key="`bruttoPaid-${i}`">
                                                        <span data-cy="client-paid">
                                                            {{ item.amount | priceFormat(item.currency) }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr
                                                v-if="showPaymentAndInvoiceStatus && commissionAndNettoCheckbox"
                                                class="grey--text text-body-2 font-weight-bold th-footer"
                                            >
                                                <td class="px-4 text-start">{{ $t('due_to_pay') }}</td>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td
                                                    v-if="!isAgency && commissionAndNettoCheckbox"
                                                    class="px-4 text-right"
                                                >
                                                    <div v-for="(item, i) in nettoDueToPay" :key="`nettoDueToPay-${i}`">
                                                        <span data-cy="supplier-due-to-pay">
                                                            {{ item.dueToPay | priceFormat(item.currency) }}
                                                        </span>
                                                        <span
                                                            v-if="showSystemNettoCurrency && item.currency !== currency"
                                                        >
                                                            /
                                                            {{ {...item, amount: item.dueToPay} | price }}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="px-4 text-right">
                                                    <div
                                                        v-for="(item, i) in bruttoDueToPay"
                                                        :key="`bruttoDueToPay-${i}`"
                                                    >
                                                        <span data-cy="client-due-to-pay">
                                                            {{ item.dueToPay | priceFormat(item.currency) }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else v-slot:foot>
                                            <div class="mb-2 px-4 text-caption">
                                                <span class="font-weight-bold text-body-2">{{ $t('total') }}</span>
                                                <v-row>
                                                    <v-col v-if="!isAgency && commissionAndNettoCheckbox" cols="5">
                                                        <span class="font-weight-bold">{{ $t('netto') }}</span>
                                                        <div
                                                            v-for="(item, i) in nettoPrice"
                                                            :key="`nettoTotal-${i}mob`"
                                                        >
                                                            <div class="text-start">
                                                                {{ item.amount | priceFormat(item.currency) }}
                                                                <span
                                                                    v-if="
                                                                        showSystemNettoCurrency &&
                                                                        currency !== item.currency
                                                                    "
                                                                >
                                                                    / {{ item | price }}
                                                                </span>
                                                            </div>
                                                            <div
                                                                v-if="item.commission && commissionAndNettoCheckbox"
                                                                class="grey--text text-start text-lowercase"
                                                            >
                                                                {{ $t('commission') }}
                                                                {{ item.commission | priceFormat(item.currency) }}
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <span class="font-weight-bold">
                                                            {{ $t(!isAgency ? 'brutto' : 'price') }}
                                                        </span>
                                                        <div
                                                            v-for="(item, i) in bruttoPrice"
                                                            :key="`bruttoTotal-${i}mob`"
                                                        >
                                                            <div class="text-start">
                                                                {{ item.amount | priceFormat(item.currency) }}
                                                            </div>
                                                            <div
                                                                v-if="item.commission && commissionAndNettoCheckbox"
                                                                class="grey--text text-start text-lowercase"
                                                            >
                                                                {{ $t('commission') }}
                                                                {{ item.commission | priceFormat(item.currency) }}
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col
                                                        v-if="commissionAndNettoCheckbox"
                                                        cols="3"
                                                        class="text-right"
                                                    >
                                                        <span v-if="totalIncome" class="font-weight-bold">{{
                                                            $t('income')
                                                        }}</span>
                                                        <div v-if="totalIncome">
                                                            <div>
                                                                {{
                                                                    totalIncome.amount
                                                                        | priceFormat(totalIncome.currency)
                                                                }}
                                                            </div>
                                                            <div v-if="totalIncome.percent" class="grey--text">
                                                                {{ `${totalIncome.percent.toFixed(2)}%` }}
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="showExchangeRates && totalFxGainLoss.amount > 0" dense>
                                                    <v-col cols="12" class="text-right">
                                                        <div class="font-weight-bold">{{ $t('fxGainLoss') }}</div>
                                                        {{
                                                            totalFxGainLoss.amount
                                                                | priceFormat(totalFxGainLoss.currency)
                                                        }}
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div v-if="showPaymentAndInvoiceStatus" class="mb-2 px-4 text-caption">
                                                <span class="font-weight-bold grey--text text-body-2">
                                                    {{ $t('paid') }}
                                                </span>

                                                <v-row>
                                                    <v-col
                                                        v-if="!isAgency && commissionAndNettoCheckbox"
                                                        cols="5"
                                                        class="font-weight-bold"
                                                    >
                                                        <div
                                                            v-for="(item, i) in nettoPaidPrice"
                                                            :key="`nettoPaid-${i}mob`"
                                                        >
                                                            {{ item.amount | priceFormat(item.currency) }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4" class="font-weight-bold">
                                                        <div
                                                            v-for="(item, i) in bruttoPaidPrice"
                                                            :key="`bruttoPaid-${i}mob`"
                                                        >
                                                            {{ item.amount | priceFormat(item.currency) }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div
                                                v-if="showPaymentAndInvoiceStatus && commissionAndNettoCheckbox"
                                                class="px-4 text-caption"
                                            >
                                                <span class="font-weight-bold grey--text text-body-2">
                                                    {{ $t('due_to_pay') }}
                                                </span>
                                                <v-row>
                                                    <v-col
                                                        v-if="!isAgency && commissionAndNettoCheckbox"
                                                        cols="5"
                                                        class="font-weight-bold"
                                                    >
                                                        <div
                                                            v-for="(item, i) in nettoPrice"
                                                            :key="`nettoDueToPay-${i}`"
                                                        >
                                                            {{ item.dueToPay | priceFormat(item.currency) }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4" class="font-weight-bold">
                                                        <div
                                                            v-for="(item, i) in bruttoDueToPay"
                                                            :key="`bruttoDueToPay-${i}`"
                                                        >
                                                            {{ item.dueToPay | priceFormat(item.currency) }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-card>
                                <v-dialog v-model="serviceModal" content-class="selected-service-modal">
                                    <component
                                        :is="serviceComponent(selectedService.serviceType)"
                                        v-if="selectedService.serviceType"
                                        :service="selectedService"
                                        :is-package="!!order.orderPackage"
                                        :order="order"
                                        :service-tips="
                                            tips && tips.filter(t => t.serviceId === selectedService.serviceId)
                                        "
                                        :order-id="order.orderId"
                                        :object-id="order.objectId"
                                        :order-end-date="orderEndDate"
                                        is-first
                                        @cancelService="showCancelServiceModal($event)"
                                        @issueTicket="$refs.issueTicketModal.show($event)"
                                        @add-marker="addMarker"
                                        @modifyService="$emit('modifyService')"
                                        @info-loaded="info = $event"
                                        @show-on-map="$emit('show-on-map', $event)"
                                    />
                                </v-dialog>
                                <fx-gain-modal
                                    v-model="isShowFxGainModal"
                                    :order-id="order.orderId"
                                    :hide-total="order.fxGainLoss === undefined"
                                />
                                <voucher-dialog
                                    v-model="isShowVoucherDialog"
                                    :is-loading="voucherLoading"
                                    :title="$t('voucher')"
                                    @download-voucher="downloadVoucher(voucherService)"
                                    @send-on-email="sendVoucherOnEmail(voucherService, $event)"
                                />
                            </template>
                            <template v-else>
                                <component
                                    :is="serviceComponent(service.serviceType)"
                                    v-for="(service, index) in packageServices"
                                    :key="service.serviceId"
                                    :service="service"
                                    :is-package="!!order.orderPackage"
                                    class="mb-5"
                                    :service-tips="tips && tips.filter(t => t.serviceId === service.serviceId)"
                                    :order="order"
                                    :order-id="order.orderId"
                                    :object-id="order.objectId"
                                    :is-first="index === 0"
                                    :order-end-date="orderEndDate"
                                    :data-cy="`service-${index}`"
                                    @cancelService="showCancelServiceModal($event)"
                                    @issueTicket="$refs.issueTicketModal.show($event)"
                                    @add-marker="addMarker"
                                    @modifyService="$emit('modifyService')"
                                    @info-loaded="info = $event"
                                    @show-on-map="$emit('show-on-map', $event)"
                                />
                            </template>
                        </div>
                    </div>
                    <div
                        v-if="!itineraryView && displayFormat !== 'tableView'"
                        ref="serviceCards"
                        class="pa-2"
                        :class="{sortable: isTO1}"
                    >
                        <component
                            :is="serviceComponent(service.serviceType)"
                            v-for="(service, index) in nonPackageServices"
                            :key="service.serviceId"
                            :service="service"
                            :service-tips="tips && tips.filter(t => t.serviceId === service.serviceId)"
                            :is-package="!!service.serviceGroupId"
                            class="mb-5"
                            :order="order"
                            :order-end-date="orderEndDate"
                            :is-first="index === 0 && !packageServices.length"
                            :data-cy="`service-${index}`"
                            @cancelService="showCancelServiceModal($event)"
                            @issueTicket="$refs.issueTicketModal.show($event)"
                            @modifyService="$emit('modifyService')"
                            @add-marker="addMarker"
                            @info-loaded="info = $event"
                            @show-on-map="$emit('show-on-map', $event)"
                        >
                            <template v-slot:checkbox>
                                <v-checkbox
                                    v-if="isTO1"
                                    v-model="checkboxServicesSelected"
                                    :value="service.serviceId"
                                    hide-details
                                    class="mt-0 pt-0 me-1"
                                    @click.stop
                                />
                            </template>
                        </component>
                    </div>
                    <div v-if="displayFormat !== 'tableView'">
                        <v-btn
                            v-if="servicesCanBeCancelled.length > 0 && sortedServices.length > 1 && !order.orderPackage"
                            depressed
                            class="mb-5"
                            @click="showCancelServicesAllModal"
                        >
                            {{ $t('cancel') + ' ' + $t('all') }}
                        </v-btn>
                        <v-btn
                            v-if="servicesQuote.length > 0 && sortedServices.length > 1 && !order.orderPackage"
                            depressed
                            color="primary"
                            class="mb-5"
                            @click="bookAllQuote"
                        >
                            {{ $t('book') + ' ' + $t('all') }}
                        </v-btn>
                    </div>
                </v-col>
                <v-col v-if="!$breakpoint.smAndDown" md="3">
                    <div class="order-summary">
                        <order-details-summary
                            class="mb-4"
                            :checkbox-services-selected="checkboxServicesSelected"
                            :order-travelers="orderTravelers"
                            :vat-calculated-amount-by-each-service-brutto="vatCalculatedAmountByEachServiceBrutto"
                            :vat-calculated-amount-by-each-service-netto="vatCalculatedAmountByEachServiceNetto"
                            @send-request="$emit('modifyService')"
                        />
                        <weather-service
                            v-if="order.services[0]"
                            :city="{
                                id: order.services[0].serviceDetails[0].cityId,
                                cityName: order.services[0].serviceDetails[0].cityName,
                            }"
                            :start-date="weatherStartDate"
                            :end-date="weatherEndDate"
                            class="mb-4"
                        />
                        <client-only>
                            <v-card v-if="orderDetailsMapMarkers.length && !isMobile" class="mb-4" outlined>
                                <v-card-text>
                                    <order-details-map :marker-coordinates="orderDetailsMapMarkers" />
                                </v-card-text>
                            </v-card>
                        </client-only>
                        <order-details-tips
                            v-if="!isMobile && $config.account.showTips"
                            :tips="tips && tips.filter(t => !t.serviceId)"
                            class="mb-2"
                            :order-id="order.orderId"
                            :loaded="tipsLoaded"
                        />
                    </div>
                </v-col>
            </v-row>
        </template>
        <v-row v-if="tabSelectedName === 'summary'">
            <order-details-summary
                :checkbox-services-selected="checkboxServicesSelected"
                outlined
                :vat-calculated-amount-by-each-service-brutto="vatCalculatedAmountByEachServiceBrutto"
                :vat-calculated-amount-by-each-service-netto="vatCalculatedAmountByEachServiceNetto"
                :order-travelers="orderTravelers"
                @send-request="$emit('modifyService')"
            />
        </v-row>
        <v-row v-if="tabSelectedName === 'weather'">
            <v-col>
                <weather-service
                    :city="{
                        id: order.services[0].serviceDetails[0].cityId,
                        cityName: order.services[0].serviceDetails[0].cityName,
                    }"
                    :start-date="weatherStartDate"
                    :end-date="weatherEndDate"
                    class="mb-4"
                />
            </v-col>
        </v-row>
        <v-row v-if="tabSelectedName === 'map'">
            <v-col>
                <client-only>
                    <v-card outlined>
                        <v-card-text>
                            <order-details-map :marker-coordinates="orderDetailsMapMarkers" />
                        </v-card-text>
                    </v-card>
                </client-only>
            </v-col>
        </v-row>
        <v-row v-if="tabSelectedName === 'tips' && $config.account.showTips">
            <v-col>
                <order-details-tips
                    class="mb-2"
                    :tips="tips && tips.filter(t => !t.serviceId)"
                    :order-id="order.orderId"
                    :loaded="tipsLoaded"
                />
            </v-col>
        </v-row>
        <conditions-modal disable-currency-conversion />
        <cancel-service-modal ref="cancelServiceModal" @cancelService="$emit('cancelService')" />
        <cancel-services-all-modal ref="cancelServicesAllModal" @cancelService="$emit('cancelService')" />
        <cancel-tour-modal ref="cancelTourModal" @cancelService="$emit('cancelService')" />
        <cancel-service-manual-modal ref="cancelServiceManualModal" />
        <invoices-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
        <modify-modal @modifyService="$emit('modifyService')" />
        <modify-manual-modal
            :info="info"
            :client-type="order.clientType"
            :order-id="order.orderId"
            :customer-company-id="order.customerCompanyId"
            @modifyService="$emit('modifyService')"
        />
        <invoices-pay-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
        <issue-ticket-modal ref="issueTicketModal" @issuedTicket="$emit('issuedTicket')" />
        <service-documents-modal />
        <reservations-status-modal />
        <ticket-voiding-modal />
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
        <service-notes-modal
            :communications="internalNotes"
            :communications-loading="communicationsLoading"
            :order-id="order.orderId"
            @get-communications="loadCommunications"
        />
        <due-to-confirm-date-service-modal :order-id="order.orderId" @load-order="loadOrder" />
        <service-comments-modal />
        <order-add-service-modal v-if="isMobile" v-model="isShowOrderAddServiceModal" :order="order" />
        <div
            v-if="
                !$config.account.hideAddServiceBtn &&
                isMobile &&
                tabSelectedName === 'info' &&
                personRole !== 'dispatcher'
            "
            class="d-flex flex-column full-width"
            style="position: fixed; bottom: 0; left: 0; background: white"
        >
            <v-btn
                color="primary"
                class="white--text rounded-0 mb-2"
                block
                data-cy="add-service-button"
                :small="$breakpoint.smAndDown"
                @click="isShowOrderAddServiceModal = true"
                v-text="$t('add_service')"
            />
            <add-offline-service-dialog
                :order-travelers="orderTravelers"
                :order-process-id="order.processId"
                :client-type="order.clientType"
                :customer-company-id="order.customerCompanyId"
                @send-request="$emit('modifyService', $event)"
            />
        </div>
        <custom-mobile-modal
            v-model="showSettingsModal"
            :transition="isMobile ? 'dialog-bottom-transition' : ''"
            :content-class="isMobile ? 'modal-content' : ''"
            :fullscreen="isMobile"
        >
            <div v-if="isMobile" class="py-4 px-4">
                <cancelled-services-checkbox v-if="showCancelledServicesCheckbox" class="d-block" />
                <show-commission-and-netto-checkbox v-if="isAgency || isTourOperator" />
            </div>
            <div v-if="isMobile" class="mb-4 px-4">
                <span class="display-format-label">{{ $t('display_format') }}</span>
                <v-btn :color="displayFormat === 'infoView' ? 'primary' : ''" icon @click="displayFormat = 'infoView'">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
                <v-btn
                    v-if="itineraryViewServices.length && !$config.account.hideItineraryTab"
                    :color="displayFormat === 'itineraryView' ? 'primary' : ''"
                    icon
                    @click="displayFormat = 'itineraryView'"
                >
                    <v-icon>mdi-map-legend</v-icon>
                </v-btn>
                <v-btn
                    v-if="(isTourOperator || isAgency) && personRole !== 'dispatcher'"
                    :color="displayFormat === 'tableView' ? 'primary' : ''"
                    icon
                    @click="displayFormat = 'tableView'"
                >
                    <v-icon>mdi-table</v-icon>
                </v-btn>
            </div>
            <v-divider v-if="isMobile" class="px-1" />
            <order-calculations-and-settings :order-id="order.orderId" elevation="0" />
        </custom-mobile-modal>
    </div>
</template>

<script>
    import {Component, Emit, mixins, Watch} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import FlightService from '~/components/account/orderServices/FlightService'
    import AccommodationService from '~src/components/account/orderServices/accommodationService.src'
    import TransferService from '~src/components/account/orderServices/transferService.src'
    import CarService from '@/components/account/orderServices/CarService'
    import InsuranceService from '@/components/account/orderServices/InsuranceService'
    import ActivityService from '~/components/account/orderServices/ActivityService'
    import CruiseService from '~/components/account/orderServices/CruiseService'
    import VisaService from '~/components/account/orderServices/VisaService'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import CancelServiceModal from '@/components/account/modals/CancelServiceModal'
    import CancelServiceManualModal from '@/components/account/modals/CancelServiceManualModal'
    import InvoicesModal from '~src/components/modals/invoicesModal.src'
    import OrderPrice from '@/components/account/snippets/OrderPrice'
    import {clone, getOrderTravelers, getServiceTypeIcon} from '@/utils/helpers'
    import ModifyModal from '@/components/account/modify/ModifyModal'
    import InvoicesPayModal from '@/components/modals/InvoicesPayModal'
    import IssueTicketModal from '@/components/modals/IssueTicketModal'
    import ChatModal from '@/components/modals/ChatModal'
    import TotalOrderPrices from '~src/components/account/snippets/totalOrderPrices.src'
    import ItineraryInfo from '@/components/info/ItineraryInfo'
    import VPhotoSwipe from '@/components/snippets/VPhotoSwipe'
    import OrderItineraryDayImages from '@/components/account/snippets/OrderItineraryDayImages'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import OrderAddServiceModal from '~src/components/account/modals/orderAddServiceModal.src'
    import PriceLabel from '~src/components/snippets/priceLabel.src'
    import CancelServicesAllModal from '@/components/account/modals/CancelServicesAllModal'
    import CancelTourModal from '@/components/account/modals/CancelTourModal'
    import ReservationsStatusModal from '@/components/account/modals/ReservationsStatusModal'
    import TicketVoidingModal from '@/components/account/modals/TicketVoidingModal'
    import ModifyManualModal from '@/components/account/modify/manual/ModifyManualModal'
    import ServiceDocumentsModal from '@/components/account/modals/ServiceDocumentsModal'
    import OrderDetailsMap from '@/components/account/OrderDetailsMap'
    import OrderDetailsTips from '@/components/account/OrderDetailsTips'
    import WeatherService from '@/components/WeatherService'
    import Communications from '@/components/account/communications/Communications'
    import AddOfflineServiceDialog from '~src/components/account/offlineServices/addOfflineServiceDialog.src'
    import VoucherMixin from '@/components/account/mixins/VoucherMixin'
    import OrderDetailsSummary from '~src/components/account/orderDetailsSummary.src'
    import OrderHistory from '@/components/account/OrderHistory'
    import OrdersPriceLabel from '~src/components/account/snippets/ordersPriceLabel.src'
    import OrdersSupplierPriceLabel from '~src/components/account/snippets/ordersSupplierPriceLabel.src'
    import ChangeTotalPriceDialog from '@/components/booking/ChangeTotalPriceDialog'
    import ChangeIncomeDialog from '@/components/account/ChangeIncomeDialog'
    import InternalNotes from '@/components/account/internalNotes/InternalNotes'
    import EditItineraryModal from '@/components/account/modals/EditItineraryModal'
    import ShowCommissionAndNettoCheckbox from '~src/components/account/showCommissionAndNettoCheckbox.src'
    import CancelledServicesCheckbox from '@/components/account/CancelledServicesCheckbox'
    import {EventBus, ORDER_SERVICE_BOOK} from '@/utils/event-bus'
    import {convertPrice} from '@/utils/filters'
    import OrderDetailsTravelerList from '~src/components/account/orderDetailsTravelerList.src'
    import ServiceNotesModal from '@/components/account/modals/ServiceNotesModal.vue'
    import DueToConfirmDateServiceModal from '@/components/account/modals/DueToConfirmDateServiceModal.vue'
    import FxGainModal from '@/components/account/modals/FxGainModal'
    import Sortable from 'sortablejs'
    import ServiceCommentsModal from '@/components/account/modals/ServiceCommentsModal'
    import OrderCalculationsAndSettings from '@/components/account/OrderCalculationsAndSettings'
    import CustomMobileModal from '@/components/snippets/CustomMobileModal'

    @Component({
        components: {
            FxGainModal,
            DueToConfirmDateServiceModal,
            ServiceNotesModal,
            OrderDetailsTravelerList,
            ShowCommissionAndNettoCheckbox,
            InternalNotes,
            ChangeIncomeDialog,
            ChangeTotalPriceDialog,
            EditItineraryModal,
            AddOfflineServiceDialog,
            Communications,
            OrderDetailsTips,
            OrderDetailsMap,
            ModifyManualModal,
            CancelTourModal,
            CancelServicesAllModal,
            PriceLabel,
            OrderAddServiceModal,
            OrderItineraryDayImages,
            ItineraryInfo,
            TotalOrderPrices,
            InvoicesPayModal,
            CancelServiceModal,
            CancelServiceManualModal,
            ConditionsModal,
            FlightService,
            AccommodationService,
            TransferService,
            CarService,
            InsuranceService,
            ActivityService,
            VisaService,
            PackageInfo: () => import('~/components/account/orderServices/PackageInfo'),
            PackageTourInfo: () => import('~/components/account/orderServices/PackageTourInfo'),
            ExtraserviceService: () => import('~/components/account/orderServices/ExtraserviceService'),
            OfflineService: () => import('~/components/account/orderServices/OfflineService'),
            InvoicesModal,
            OrderPrice,
            ModifyModal,
            IssueTicketModal,
            ChatModal,
            VPhotoSwipe,
            PaymentStatusLabel,
            ServiceDocumentsModal,
            ReservationsStatusModal,
            TicketVoidingModal,
            WeatherService,
            OrderDetailsSummary,
            OrdersPriceLabel,
            OrdersSupplierPriceLabel,
            CancelledServicesCheckbox,
            CruiseService,
            ServiceCommentsModal,
            OrderHistory,
            OrderCalculationsAndSettings,
            CustomMobileModal,
        },
    })
    export default class OrderDetails extends mixins(VoucherMixin) {
        snackbar = {
            show: false,
            message: '',
        }
        orderTitleEdit = false
        orderTitle = ''
        itineraryView = false
        orderItinerary = {}
        photoSwipeIsOpen = false
        photoSwipeOptions = {index: 0}
        photoSwipeItems = 0
        itineraryLink = ''
        orderDetailsMapMarkers = []
        selectedTabIndex = 0
        tips = null
        tipsLoaded = false
        communications = []
        communicationsLoading = false
        serviceModal = false
        selectedService = {}
        weatherStartDate = null
        weatherEndDate = null
        checkboxServicesSelected = []
        isShowFxGainModal = false
        servicesOrder = []
        info = null
        showSettingsModal = false
        isShowOrderAddServiceModal = false
        displayFormat = 'infoView'

        @Emit()
        loadOrder() {}

        @Watch('displayFormat')
        async switchDisplayFormat() {
            if (this.displayFormat === 'itineraryView') {
                await this.openItineraryView(true)
            } else {
                await this.openItineraryView(false)
            }
            if (this.displayFormat === 'tableView') {
                this.servicesOrder.forEach((item, index) => {
                    item.orderNumber = index + 1
                })
                await this.$nextTick()
                const table = document.querySelector('tbody')
                this.initSortable(table)
            } else if (this.displayFormat === 'infoView') {
                this.servicesOrder.forEach((item, index) => {
                    item.orderNumber = index + 1
                })
                await this.$nextTick()
                const serviceCards = this.$refs.serviceCards
                this.initSortable(serviceCards)
            }
        }

        @Watch('showCancelledServices')
        async changeShowCancelledServices() {
            this.initServiceOrder()
        }

        async mounted() {
            await this.initServiceOrder()
            await this.$store.restored
            this.selectedTabIndex = !this.order.services?.length ? 0 : persistentStore.selectedTabIndex
            if (this.tabSelectedName === 'info') {
                await this.switchDisplayFormat()
            }
            this.checkboxServicesSelected = this.nonPackageServices.map(s => s.serviceId)
            this.initItineraryView()
            if (this.itineraryView) {
                await this.loadOrderItinerary()
                this.displayFormat = 'infoView'
            }
            if (this.$config.account.showTips) {
                this.tips = await this.loadTips()
            }

            await this.loadCommunications()
            if (this.order.services?.length) this.prepareWeatherDate()
        }

        beforeDestroy() {
            persistentStore.SET_SELECTED_TAB_INDEX(this.selectedTabIndex)
        }

        initSortable(el) {
            if (!this.isTO1 || !this.order.services?.length) return
            const sort = async ({newIndex, oldIndex}) => {
                this.servicesOrder.splice(newIndex, 0, ...this.servicesOrder.splice(oldIndex, 1))
                try {
                    await this.$api.orders.put(this.order.orderId, {
                        servicesOrder: this.servicesOrder.map(({processId}, index) => ({
                            processId,
                            orderNumber: index + 1,
                        })),
                    })
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            Sortable.create(el, {
                onEnd({newIndex, oldIndex}) {
                    sort({newIndex, oldIndex})
                },
            })
        }

        initServiceOrder() {
            const servicesOrder = this.services.map(({orderNumber, processId}, index) => ({
                processId,
                orderNumber: orderNumber || index + 1,
            }))
            servicesOrder.sort((a, b) => a.orderNumber - b.orderNumber)
            this.servicesOrder = servicesOrder
        }

        async loadTips() {
            try {
                this.tipsLoaded = false
                return (await this.$api.tips.get({orderId: this.order.orderId, orderType: this.order.orderType})).tips
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.tipsLoaded = true
            }
        }

        initItineraryView() {
            if (
                ((authStore.isCorporate && authStore.user.isEmployeeOrExternalCorp && this.gotMoreTwoServices) ||
                    persistentStore.isItineraryView) &&
                this.itineraryViewServices.length
            ) {
                this.itineraryView = true
            }
        }

        async openItineraryView(param) {
            persistentStore.SET_ITINERARY_VIEW(param)
            this.itineraryView = param
            await this.loadOrderItinerary()
        }

        async loadOrderItinerary() {
            const orderId = this.order.orderId
            try {
                await Promise.all([
                    (async () => {
                        this.orderItinerary = await this.$api.orderItinerary.get({orderId})
                    })(),
                    /*(async () => {
                        const key = await this.$api.itineraryKey.get(orderId)
                        this.itineraryLink =
                            window.location.origin +
                            this.$router.resolve({
                                name: 'order-details',
                                params: {id: orderId},
                                query: {key},
                            }).href
                    })(),*/
                ])
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async saveOrderTitle() {
            this.orderTitleEdit = false
            const data = {title: this.orderTitle}
            try {
                await this.$api.orders.put(this.order.orderId, data)
                runtimeStore.SET_ORDER_DETAILS({...this.order, ...data})
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async loadCommunications() {
            try {
                this.communicationsLoading = true

                this.communications = (
                    await this.$api.communications.get({
                        orderId: this.order.orderId,
                    })
                ).communications

                this.communicationsLoading = false
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.communicationsLoading = false
            }
        }

        addMarker(marker) {
            if (!this.orderDetailsMapMarkers.find(e => e.lat === marker.lat && e.lng === marker.lng)) {
                this.orderDetailsMapMarkers.push(marker)
            }
        }

        serviceComponent(serviceType) {
            if (serviceType === 'OWNCHARTER') {
                serviceType = 'FLIGHT'
            } else if (serviceType === 'OWNEXTRASERVICE') {
                serviceType = 'EXTRASERVICE'
            } else if (serviceType === 'CARRENT') {
                serviceType = 'CAR'
            } else if (serviceType === 'OWNVISA') {
                serviceType = 'VISA'
            } else if (serviceType === 'EXTRASERVICE') {
                serviceType = 'OFFLINE'
            }
            return `${serviceType.toLowerCase()}-service`
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        buildItineraryForAccommodation(services) {
            const createNewProp = (dateTime, sourceDateTime, service, firstPart) => {
                dateTime[sourceDateTime[0]] = {
                    date: sourceDateTime[0],
                    times: [
                        {
                            type: {
                                text:
                                    service.serviceType === 'ACCOMMODATION'
                                        ? () => (firstPart ? this.$t('check_in') : this.$t('check_out'))
                                        : null,
                                textFlight:
                                    service.serviceType === 'FLIGHT'
                                        ? () => (firstPart ? this.$t('flights.departure') : this.$t('flights.arrival'))
                                        : null,
                                start: !!firstPart && service.firstFlightSegment !== false,
                            },
                            time: sourceDateTime[1],
                            service: [service],
                        },
                    ],
                }
            }
            const addToPropNewTime = (dateTime, sourceDateTime, service, firstPart) => {
                dateTime[sourceDateTime[0]].times.push({
                    type: {
                        text:
                            service.serviceType === 'ACCOMMODATION'
                                ? () => (firstPart ? this.$t('check_in') : this.$t('check_out'))
                                : null,
                        textFlight:
                            service.serviceType === 'FLIGHT'
                                ? () => (firstPart ? this.$t('flights.departure') : this.$t('flights.arrival'))
                                : null,
                        start: !!firstPart,
                    },
                    time: sourceDateTime[1],
                    service: [service],
                })
            }
            const addToExistTime = (dateTime, sourceDateTime, service, firstPart, index) => {
                dateTime[sourceDateTime[0]].times[index].service.push(service)
            }
            const getNormalizedStartTime = (start, service) => {
                if (service.serviceType === 'TRANSFER') {
                    start[1] = service.serviceDetailsContainer.transfer.pickupTime
                        ? service.serviceDetailsContainer.transfer.pickupTime
                        : start[1]
                }

                if (service.serviceType === 'ACTIVITY') {
                    start[1] = service.serviceDetails[0].pickupTime ? service.serviceDetails[0].pickupTime : start[1]
                }
            }
            const dateTime = {}
            services.forEach(service => {
                const start = service.startDateTime.split(' ')
                getNormalizedStartTime(start, service)
                const end = service.endDateTime.split(' ')
                if (!dateTime[start[0]]) {
                    createNewProp(dateTime, start, service, true)
                } else {
                    const gotSameTimeStart = dateTime[start[0]].times.findIndex(time => time.time === start[1])
                    if (gotSameTimeStart > -1) {
                        addToExistTime(dateTime, start, service, true, gotSameTimeStart)
                    } else {
                        addToPropNewTime(dateTime, start, service, true)
                    }
                }
                if (end[1] !== '00:00') {
                    const serviceEndCopy = clone(service)
                    serviceEndCopy.end = true
                    if (!dateTime[end[0]]) {
                        createNewProp(dateTime, end, serviceEndCopy, false)
                    } else {
                        const gotSameTimeEnd = dateTime[end[0]].times.findIndex(time => time.time === end[1])
                        if (gotSameTimeEnd > -1) {
                            addToExistTime(dateTime, end, serviceEndCopy, false, gotSameTimeEnd)
                        } else {
                            addToPropNewTime(dateTime, end, serviceEndCopy, false)
                        }
                    }
                }
            })
            return dateTime
        }

        itineraryDayImages(dayNumber) {
            return this.orderItinerary?.images?.filter(image => image.dayNumber === dayNumber) || []
        }

        showPhotoSwipe({images, index}) {
            this.photoSwipeItems = images.map(({url, linkedEntityName}) => ({
                src: this.$options.filters.imageUrl(url, 800),
                w: 0,
                h: 0,
                title: linkedEntityName,
            }))
            this.photoSwipeOptions.index = index
            this.photoSwipeIsOpen = true
        }

        showCancelServiceModal(service) {
            if (this.$config.account.cancelServiceManualModal || service.status === 'Confirmation pending') {
                this.$refs.cancelServiceManualModal.show(service)
            } else {
                this.$refs.cancelServiceModal.show(service, this.order)
            }
        }

        showCancelServicesAllModal() {
            this.$refs.cancelServicesAllModal?.show(this.sortedServices, this.order)
        }

        bookAllQuote() {
            EventBus.$emit(
                ORDER_SERVICE_BOOK,
                this.servicesQuote.filter(({serviceType}) => serviceType !== 'EXTRASERVICE')
            )
        }

        showCancelTourModal() {
            this.$refs.cancelTourModal?.show(
                this.sortedServices.filter(s => !!s.serviceContainerId),
                this.order
            )
        }

        getTabDisabled(tab) {
            return (
                (tab.name === 'tips' && !this.tips?.length) ||
                (tab.name === 'map' && !this.orderDetailsMapMarkers?.length)
            )
        }

        voucherAvailable(service) {
            return runtimeStore.isServiceVoucherAvailable(service)
        }

        openVoucherDialog(service) {
            this.isShowVoucherDialog = true
            this.voucherService = service
        }

        async openServiceModal(item) {
            this.selectedService = await this.order.services.find(service => service.serviceId === item.serviceId)
            this.serviceModal = true
        }

        prepareWeatherDate() {
            this.order.services.forEach((service, index) => {
                if (index === 0) {
                    this.weatherStartDate = service.startDateTime
                    this.weatherEndDate = service.endDateTime
                } else {
                    if (
                        this.$dateFns.isAfter(
                            this.$dateFns.parseISO(this.weatherStartDate),
                            this.$dateFns.parseISO(service.startDateTime)
                        )
                    ) {
                        this.weatherStartDate = service.startDateTime
                    }
                    if (
                        this.$dateFns.isBefore(
                            this.$dateFns.parseISO(this.weatherEndDate),
                            this.$dateFns.parseISO(service.endDateTime)
                        )
                    ) {
                        this.weatherEndDate = service.endDateTime
                    }
                }
            })
        }

        steppedSubtraction() {
            let i = 0
            let sum = 0

            while (i < arguments.length) {
                if (i === 0) sum = arguments[i]
                else sum = +(sum - arguments[i]).toFixed(2)

                i++
            }

            return sum
        }

        // TODO: temporary solution, backend edits required
        findAccommodationService(times, key) {
            const index = times.findIndex(el => el.service[0].serviceType === 'ACCOMMODATION')
            if (index >= 0) return times[index]?.service[0].serviceDetails[0][key]

            return times[0]?.service[0].serviceDetails[0][key]
        }

        getTotalPrice(price) {
            let totalPrice
            const systemCurrency = authStore.systemCurrency

            price.forEach(p => {
                const converted = convertPrice(p, systemCurrency || persistentStore.currency)

                if (!totalPrice) {
                    totalPrice = {...converted, amountCent: converted.amount * 100}
                } else {
                    totalPrice.amount += converted.amount
                    totalPrice.amountCent += converted.amount * 100
                }
            })

            return totalPrice
        }

        filterCanceledServices(services) {
            return services.filter(
                service =>
                    service.status.toLowerCase() !== 'canceled' ||
                    (service.status.toLowerCase() === 'canceled' &&
                        (service.salesTerms.find(item => item.type === 'CLIENT').price.amount !== 0 ||
                            service.salesTerms.find(item => item.type === 'SUPPLIER').price.amount !== 0))
            )
        }

        get orderEndDate() {
            if (!this.order.services?.length) return null

            return [...this.order.services].sort((a, b) => {
                return this.$dateFns.isAfter(new Date(a.endDateTime), new Date(b.endDateTime)) ? -1 : 1
            })[0].endDateTime
        }

        get internalNotes() {
            return this.communications.filter(e => e.communicationType === 'NOTES')
        }

        get communicationsWithoutInternalNotes() {
            return this.communications.filter(e => e.communicationType !== 'NOTES')
        }

        get daysBeforeTrip() {
            const isAfter = this.$dateFns.isAfter(new Date(this.itineraryDays[0].date), new Date())
            const dayDiff = this.$dateFns.differenceInDays(new Date(this.itineraryDays[0].date), new Date())

            return isAfter ? dayDiff + 1 : dayDiff
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get tabSelectedName() {
            return this.tabs[this.selectedTabIndex]?.name
        }

        get tabs() {
            const tabs = [{icon: 'mdi-format-list-bulleted', name: 'info', label: 'services'}]

            if (!this.order.services?.length) return tabs

            if (this.$config.account.showCommunications && this.isTO1) {
                tabs.push({icon: 'mdi-account-group-outline', name: 'communications', label: 'communications'})
            }

            if (this.$config.account.showCommunications && this.isTO1) {
                tabs.push({icon: 'mdi-note-multiple-outline', name: 'internalNotes', label: 'internal_notes'})
            }

            if (this.isMobile) {
                tabs.push({icon: 'mdi-application-outline', name: 'summary', label: 'summary'})
                if (this.isShowWeather) {
                    tabs.push({icon: 'mdi-weather-partly-cloudy', name: 'weather', label: 'weather'})
                }

                tabs.push({icon: 'mdi-map-marker', name: 'map', label: 'map'})

                if (this.$config.account.showTips) {
                    tabs.push({icon: 'mdi-lightbulb-on-outline', name: 'tips', label: 'tips_title'})
                }

                const mobileTabsOrderNames = [
                    'summary',
                    'info',
                    'communications',
                    'internalNotes',
                    'tips',
                    'map',
                    'weather',
                ]

                tabs.sort((a, b) => {
                    if (a.name !== b.name) {
                        const firstIndex = mobileTabsOrderNames.indexOf(a.name)
                        const secondIndex = mobileTabsOrderNames.indexOf(b.name)
                        if (firstIndex === -1) {
                            return 1
                        }
                        if (secondIndex === -1) {
                            return -1
                        }
                        return firstIndex - secondIndex
                    } else {
                        return 0
                    }
                })
            }

            return tabs
        }

        get isShowWeather() {
            if (!this.weatherStartDate && !this.weatherEndDate) return false

            const start = this.$dateFns.differenceInCalendarDays(
                this.$dateFns.parseISO(this.weatherStartDate),
                new Date()
            )
            const end = this.$dateFns.differenceInCalendarDays(this.$dateFns.parseISO(this.weatherEndDate), new Date())
            const isWithinInterval = this.$dateFns.isWithinInterval(new Date(), {
                start: new Date(this.weatherStartDate),
                end: new Date(this.weatherEndDate),
            })

            return (start >= -4 && start <= 4) || (end >= 0 && end <= 4) || isWithinInterval
        }

        get servicesCanBeCancelled() {
            return this.sortedServices.filter(s => {
                return (
                    !!s.cancellationAllowed ||
                    (s.status === 'Confirmation pending' && this.$config.account.cancelConfirmationPendingService)
                )
            })
        }

        get servicesQuote() {
            return this.sortedServices.filter(s => {
                return s.status === 'Quote'
            })
        }

        get sortedServices() {
            const sortedServices = !this.showCancelledServices
                ? this.filterCanceledServices(this.getSortedServices(this.order.services))
                : this.getSortedServices(this.order.services)

            return this.servicesOrder.length
                ? sortedServices.sort(
                      (a, b) =>
                          this.servicesOrder.find(({processId}) => processId === a.processId)?.orderNumber -
                          this.servicesOrder.find(({processId}) => processId === b.processId)?.orderNumber
                  )
                : sortedServices
        }

        get packageServices() {
            return this.sortedServices.filter(e => !!e.serviceContainerId)
        }

        get nonPackageServices() {
            const nonPackageServices = this.sortedServices.filter(e => !e.serviceContainerId)

            if (!this.showCancelledServices) {
                return this.filterCanceledServices(nonPackageServices)
            }

            return nonPackageServices
        }

        get order() {
            return runtimeStore.orderDetails
        }

        get isEmptyOrder() {
            return !this.order.services?.length
        }

        get gotMoreTwoServices() {
            return this.order.services?.length >= 2
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get personRole() {
            return authStore.personRole
        }

        get tourLead() {
            const tourLead =
                this.order && this.order.services && this.order.services[0].travelers.find(t => t.isTourLead === true)
            return tourLead
                ? `${tourLead.prefix} ${tourLead.name[0].firstName} ${tourLead.name[0].lastName} (${tourLead.citizenshipName})`
                : false
        }

        get itineraryViewServices() {
            const showService = service =>
                    !['ERROR', 'CANCELED', 'REJECTED'].includes(runtimeStore.orderServiceStatus(service.status)),
                buildItineraryForFlights = () => {
                    let segments,
                        rebuildServiceSegments = []
                    this.order.services
                        .filter(s => showService(s))
                        .forEach(service => {
                            if (service.serviceType === 'FLIGHT') {
                                segments = service.serviceDetails[0].itineraries.map(
                                    ({flightSegments}) => flightSegments
                                )
                                segments = segments.flat()
                                rebuildServiceSegments = rebuildServiceSegments.concat(
                                    segments.map((segment, index) => {
                                        return {
                                            ...segment,
                                            ...service,
                                            startDateTime: segment.departureDate,
                                            endDateTime: segment.arrivalDate,
                                            firstFlightSegment: index === 0,
                                        }
                                    })
                                )
                            }
                        })
                    return this.buildItineraryForAccommodation(rebuildServiceSegments)
                }
            if (this.order.services) {
                const services = clone(this.order.services).filter(service => showService(service))
                services.forEach(s => (s.icon = this.getServiceTypeIcon(s.serviceType)))
                const restServices = this.buildItineraryForAccommodation(
                    services.filter(s => s.serviceType !== 'FLIGHT' && showService(s))
                )
                const dateTimeFlights = buildItineraryForFlights(
                    services.filter(s => s.serviceType === 'FLIGHT' && showService(s))
                )
                const uniqKeys = Array.from(new Set([...Object.keys(restServices), ...Object.keys(dateTimeFlights)]))
                const dirtyResult = {...restServices, ...dateTimeFlights}
                uniqKeys.forEach(key => {
                    if (restServices[key] && dateTimeFlights[key]) {
                        dirtyResult[key].times = dirtyResult[key].times.concat(restServices[key].times)
                    }
                })
                const result = Object.values(dirtyResult)
                result.forEach(date => {
                    date.times.sort((a, b) => {
                        return a.time > b.time ? 1 : -1
                    })
                })
                const sortByDate = (a, b) => {
                    return this.$dateFns.isAfter(new Date(a.date), new Date(b.date)) ? 1 : -1
                }
                return result.sort(sortByDate)
            }
            return []
        }

        get itineraryDays() {
            if (!this.itineraryViewServices.length) return this.orderItinerary?.itineraryDays || []
            const firstDate = this.$dateFns.parseISO(this.itineraryViewServices[0].date)
            if (!this.orderItinerary.itineraryDays?.length) {
                return this.itineraryViewServices.reduce((itineraryViewServices, services, index) => {
                    const date = this.$dateFns.parseISO(services.date)
                    const number =
                        index === 0
                            ? 1
                            : this.$dateFns.differenceInCalendarDays(date, itineraryViewServices[0].date) + 1
                    itineraryViewServices.push({number, services, date})

                    return itineraryViewServices
                }, [])
            }
            return this.orderItinerary.itineraryDays.map((itineraryDay, index) => {
                const date = this.$dateFns.addDays(firstDate, index),
                    services = this.itineraryViewServices.find(services =>
                        this.$dateFns.isSameDay(date, this.$dateFns.parseISO(services.date))
                    )
                return {...itineraryDay, services, date}
            })
        }

        get headers() {
            if (this.isMobile) {
                return [{value: 'mobile', align: 'left'}]
            }

            const headers = [
                {text: '#', value: 'id'},
                {text: this.$t('order_service'), value: 'title'},
                {text: this.$t('dates'), value: 'date', align: 'center'},
                {text: this.$t('order_travelers'), value: 'travellers'},
                {text: this.$t('status'), value: 'status'},
            ]

            if (this.isTourOperator && this.commissionAndNettoCheckbox) {
                headers.push({text: this.$t('netto'), value: 'supplierPrice', align: 'right'})
            }
            headers.push({text: this.$t(!this.isAgency ? 'brutto' : 'price'), value: 'price', align: 'right'})

            if (this.showExchangeRates) {
                if (!this.$config.account.paymentScheduler) {
                    headers.push({text: this.$t('bookingXR'), value: 'bookingXR', align: 'right'})
                    headers.push({text: this.$t('paymentXR'), value: 'paymentXR', align: 'right'})
                }
                headers.push({text: this.$t('fxGainLoss'), value: 'fxGainLoss', align: 'right'})
            }

            if (!((this.isAgency || this.isTourOperator) && !this.commissionAndNettoCheckbox)) {
                headers.push({text: this.$t('income'), value: 'income', align: 'right'})
            }

            headers.push({text: this.$t('voucher'), value: 'voucher', align: 'center'})

            return headers
        }

        get services() {
            const orderServices = []
            const travellersLabel = service => {
                if (!service || !service.travelers || !service.travelers.length) return '-'
                let travellers = `${service.travelers[0].prefix || ''} ${
                    service.travelers[0].name[0].firstName || ''
                } ${service.travelers[0].name[0].lastName || ''}`
                if (service.travelers.length > 1) {
                    travellers += ` (+${service.travelers.length - 1})`
                }
                return travellers
            }
            let sortedServices
            if (!this.showCancelledServices) {
                sortedServices = this.getSortedServices(this.filterCanceledServices(this.order.services))
            } else sortedServices = this.getSortedServices(this.order.services)

            if (!this.order.services?.length) {
                orderServices.push({
                    orderId: this.order.orderId,
                    statusColor: runtimeStore.orderServiceStatusColor(
                        runtimeStore.orderServiceStatus(this.order.status)
                    ),
                    isEmptyOrder: true,
                })
            }

            sortedServices.forEach(service => {
                let location = '',
                    pureTitle = `<strong>${service.serviceName}</strong>`,
                    title = '',
                    roomTypeName = null,
                    date = this.$options.filters.dateShortFormat(service.startDateTime),
                    created = this.$options.filters.dateShortFormat(service.created),
                    modified = this.$options.filters.dateShortFormat(this.order.modified),
                    countryName,
                    cityName,
                    travelersMealType,
                    serviceId = service.serviceId
                //const transferPlace = place => place.address || place.airportCode || ''
                switch (service.serviceType) {
                    case 'ACCOMMODATION':
                        travelersMealType = [
                            ...new Set(
                                service.travelers.reduce((mealTypes, traveler) => {
                                    if (traveler.mealType) {
                                        mealTypes.push(traveler.mealType)
                                    }
                                    return mealTypes
                                }, [])
                            ),
                        ].join(', ')
                        service.serviceDetailsContainer.accommodation.rooms.forEach(room => {
                            title += `${room.roomTypeName}, ${travelersMealType || room.mealTypeName}<br>`
                            roomTypeName = `${room.roomTypeName} `
                        })
                        countryName = service.serviceDetailsContainer.accommodation.countryName
                            ? service.serviceDetailsContainer.accommodation.countryName
                            : ''
                        cityName = service.serviceDetailsContainer.accommodation.cityName
                            ? service.serviceDetailsContainer.accommodation.cityName
                            : ''
                        location = []
                        if (countryName) {
                            location.push(countryName)
                        }
                        if (cityName) {
                            location.push(cityName)
                        }
                        location = location.join(', ')
                        date +=
                            `${this.isMobile ? ' - ' : '<br>'}` +
                            this.$options.filters.dateShortFormat(service.endDateTime)
                        break
                    case 'FLIGHT':
                        location = service.serviceDetailsContainer.flight.flightName
                        break
                    case 'ACTIVITY':
                        location = service.serviceDetailsContainer.activity.location
                        break
                    case 'TRANSFER':
                        break
                    case 'CARRENT':
                        date +=
                            `${this.isMobile ? ' - ' : '<br>'}` +
                            this.$options.filters.dateShortFormat(service.endDateTime)
                        break
                }
                const orderService = {
                    orderId: this.order.orderId,
                    created: created,
                    modified: modified,
                    clientCompanyName: this.order.clientCompanyName,
                    pureTitle,
                    title,
                    ...(roomTypeName && {roomTypeName}),
                    serviceType: service.serviceType,
                    location,
                    date,
                    travellers: travellersLabel(service),
                    //TODO [API] Fix statuses
                    status: this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(service.status)}`),
                    originStatus: service.status,
                    statusColor: runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(service.status)),
                    supplierCompanyName: service.supplierCompanyName,
                    supplier: {
                        paymentStatus: service.supplierPaymentStatus,
                        cardGuarantee: service.serviceDetailsContainer.accommodation
                            ? service.serviceDetailsContainer.accommodation.cardGuarantee
                            : false,
                        price: service.salesTerms.find(salesTerm => salesTerm.type === 'SUPPLIER').price,
                        commissionVatAmount: service.salesTerms.find(salesTerm => salesTerm.type === 'SUPPLIER')
                            .commissionVatAmount,
                    },
                    customer: {
                        paymentStatus: service.customerPaymentStatus,
                        price: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price,
                        commissionVatAmount: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT')
                            .commissionVatAmount,
                    },
                    violation: service.violation,
                    corporatePolicies: service.corporatePolicies || [],
                    processId: service.processId,
                    serviceName: service.serviceName,
                    serviceId,
                    income: {},
                    showAllServicesPrice:
                        this.isTO1 || this.order.clientPriceType === 'FOR_ALL_SERVICES' || !this.order.clientPriceType,
                    orderNumber: service.orderNumber,
                }

                if (this.showExchangeRates) {
                    orderService.bookingXR = service.serviceExchangeRates?.bookingXR
                        ? service.serviceExchangeRates.bookingXR
                        : 0
                    orderService.paymentXR = service.serviceExchangeRates?.paymentXR
                        ? service.serviceExchangeRates.paymentXR
                        : 0
                    orderService.fxGainLoss = {
                        amount: service.serviceExchangeRates?.fxGainLoss ? service.serviceExchangeRates.fxGainLoss : 0,
                        currency: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price.currency,
                    }
                }

                // TODO: temporary solution, need fix on the backend
                if (this.isAgency && !orderService.customer.price.commission)
                    orderService.customer.price.commission = orderService.supplier.price.commission

                const convertBruttoPrice = orderService.customer.price
                const convertBruttoCommision = {
                    amount: orderService.customer.price.commission,
                    currency: orderService.customer.price.currency,
                }

                let convertNettoAmountByXr, convertNettoCommisionByXr

                if (orderService.bookingXR) {
                    convertNettoAmountByXr = {
                        amount: orderService.supplier.price.amount * orderService.bookingXR,
                        currency: orderService.customer.price.currency,
                    }
                    convertNettoCommisionByXr = {
                        amount: orderService.supplier.price.commission * orderService.bookingXR,
                        currency: orderService.customer.price.currency,
                    }
                } else {
                    convertNettoAmountByXr = convertPrice(
                        orderService.supplier.price,
                        orderService.customer.price.currency
                    )
                    convertNettoCommisionByXr = convertPrice(
                        {
                            amount: orderService.supplier.price.commission,
                            currency: orderService.supplier.price.currency,
                        },
                        orderService.customer.price.currency
                    )
                }

                let income
                if (this.isAgency) {
                    income = convertBruttoCommision.amount
                } else {
                    income =
                        convertBruttoPrice.amount -
                        convertBruttoCommision.amount -
                        (convertNettoAmountByXr.amount - convertNettoCommisionByXr.amount)
                }

                const percent = (income * 100) / convertBruttoPrice.amount
                orderService.income.amount = income
                orderService.income.currency = convertBruttoPrice.currency
                orderService.income.percent = Math.round(percent * 100) / 100

                const systemCurrency = authStore.systemCurrency
                const incomeConverted = systemCurrency
                    ? convertPrice(
                          {
                              amount: orderService.income.amount,
                              currency: orderService.income.currency,
                          },
                          systemCurrency
                      )
                    : orderService.income

                orderService.income = {...orderService.income, ...incomeConverted}

                orderServices.push(orderService)
            })

            return this.servicesOrder.length
                ? orderServices.sort(
                      (a, b) =>
                          this.servicesOrder.find(({processId}) => processId === a.processId)?.orderNumber -
                          this.servicesOrder.find(({processId}) => processId === b.processId)?.orderNumber
                  )
                : orderServices
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get nettoPrice() {
            return this.order.orderPrices.find(item => item.type === 'SUPPLIER').orderPrices
        }

        get bruttoPrice() {
            return this.order.orderPrices.find(item => item.type === 'CLIENT').orderPrices
        }

        get totalNettoPrice() {
            return this.getTotalPrice(this.nettoPrice)
        }

        get totalBruttoPrice() {
            return this.getTotalPrice(this.bruttoPrice)
        }

        get totalBruttoCommission() {
            return this.getTotalPrice(this.bruttoPrice.map(e => ({amount: e.commission || 0, currency: e.currency})))
        }

        get totalNettoCommission() {
            return this.getTotalPrice(this.nettoPrice.map(e => ({amount: e.commission || 0, currency: e.currency})))
        }

        get totalIncome() {
            if (this.isEmptyOrder) {
                return null
            }

            const totalIncome = this.getTotalPrice(this.services.map(s => s.income))

            totalIncome.amount += this.totalFxGainLoss.amount
            totalIncome.amountCent += this.totalFxGainLoss.amountCent

            const incomePercent = (totalIncome.amountCent * 100) / this.totalBruttoPrice.amountCent
            const percentRounded = Math.round(incomePercent * 100) / 100

            return {...totalIncome, percent: percentRounded}
        }

        get totalAmountDueBrutto() {
            return this.getTotalPrice(
                this.services.map(s => ({
                    amount: s.customer.price.amountDue,
                    currency: s.customer.price.currency,
                }))
            )
        }

        get totalFxGainLoss() {
            if (!this.$config.account.paymentScheduler) {
                const amount = this.order.services.reduce((total, service) => {
                    total += service.serviceExchangeRates?.fxGainLoss ? service.serviceExchangeRates.fxGainLoss : 0

                    return total
                }, 0)
                const currency = this.order.orderPrices.find(el => el.type === 'CLIENT').orderPrices[0].currency

                return this.getTotalPrice([{amount, currency}])
            } else {
                const amount = this.order.fxGainLoss || 0
                const currency = this.bruttoPrice[0].currency

                return this.getTotalPrice([{amount, currency}])
            }
        }

        get showExchangeRates() {
            return this.$config.showExchangeRates && this.isTO1
        }

        get orderTravelers() {
            return getOrderTravelers(this.order)
        }

        get vatCalculatedAmountByEachServiceBrutto() {
            return +this.order.services
                ?.reduce((sum, service) => {
                    let sumCent = sum * 100
                    const salesTerm = service.salesTerms.find(item => item.type === 'CLIENT')

                    sumCent += salesTerm?.commissionVatAmount ? salesTerm.commissionVatAmount * 100 : 0

                    return sumCent / 100
                }, 0)
                .toFixed(2)
        }

        get vatCalculatedAmountByEachServiceNetto() {
            return +this.order.services
                ?.reduce((sum, service) => {
                    let sumCent = sum * 100
                    const salesTerm = service.salesTerms.find(item => item.type === 'SUPPLIER')

                    sumCent += salesTerm?.commissionVatAmount ? salesTerm.commissionVatAmount * 100 : 0

                    return sumCent / 100
                }, 0)
                .toFixed(2)
        }

        get nettoPaidPrice() {
            return this.nettoPrice.reduce((paidPrice, item) => {
                const amount =
                    this.steppedSubtraction(item.amount, item.commission, item.dueToPay) -
                    this.vatCalculatedAmountByEachServiceNetto
                const {currency} = item
                paidPrice.push({amount, currency})

                return paidPrice
            }, [])
        }

        get bruttoPaidPrice() {
            if (this.isAgency) {
                return this.bruttoPrice.reduce((paidPrice, item, index) => {
                    const amount =
                        this.steppedSubtraction(item.amount, item.commission, this.bruttoDueToPay[index].dueToPay) -
                        this.vatCalculatedAmountByEachServiceBrutto
                    const {currency} = item
                    paidPrice.push({amount, currency})

                    return paidPrice
                }, [])
            }

            return this.bruttoPrice.reduce((paidPrice, item) => {
                const amount =
                    this.steppedSubtraction(item.amount, item.commission, item.dueToPay) -
                    this.vatCalculatedAmountByEachServiceBrutto
                const {currency} = item
                paidPrice.push({amount, currency})

                return paidPrice
            }, [])
        }

        get nettoDueToPay() {
            return this.nettoPrice
        }

        get bruttoDueToPay() {
            if (this.isAgency) return this.nettoPrice

            return this.bruttoPrice
        }

        get quoteStatusAll() {
            return this.services.every(service => service.originStatus === 'Quote')
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }

        get showNettoPaymentStatus() {
            return true
        }

        get locale() {
            return this.$i18n.locale
        }

        get showCancelledServices() {
            return persistentStore.showCancelledServices
        }

        get showCancelledServicesCheckbox() {
            return this.order.services.some(
                service =>
                    service.status.toLowerCase() === 'canceled' &&
                    service.salesTerms.find(item => item.type === 'CLIENT').price.amount === 0
            )
        }

        get isShowCommissionAndNettoCheckbox() {
            return (this.isAgency || this.isTourOperator) && this.tabSelectedName === 'info'
        }

        get isShowSettingsBtn() {
            return this.isMobile && (this.showCancelledServicesCheckbox || this.isAgency || this.isTourOperator)
        }

        get showSystemNettoCurrency() {
            return false
        }

        get currency() {
            return persistentStore.currency
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/settings/_variables.scss';
    ::v-deep {
        .v-slide-group__next--disabled,
        .v-slide-group__prev--disabled {
            display: none;
        }
    }

    .commission {
        font-size: 10px;
        line-height: 1rem;
    }

    .display-format-label {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
        .time {
            width: 90px;
        }
    }

    .order-wrapper {
        border: 3px solid rgb(52, 150, 243);
        border-radius: 5px;
        margin-bottom: 10px;

        & .services {
            padding: 10px;
        }
    }

    @media (min-width: 991px) {
        .order-summary {
            position: sticky;
            top: 0;
        }
    }

    .view-summary-button {
        position: sticky;
        bottom: 0;
        z-index: 200;
    }

    .order-summary::v-deep .commission-block {
        font-size: 12px !important;
    }

    ::v-deep .selected-service-modal .service-card {
        margin-bottom: 0 !important;
    }

    table .th-footer {
        vertical-align: top;
    }

    .v-data-table.sortable::v-deep table tbody tr,
    .sortable::v-deep .service-card > .v-alert {
        cursor: pointer;
    }

    @media (max-width: 960px) {
        .table::v-deep tbody .v-data-table__mobile-row__cell {
            width: 100%;
        }
    }
</style>
