<template>
    <v-dialog v-model="modal" width="700">
        <v-card>
            <v-card-title>{{ $t('send_email') }}</v-card-title>
            <v-card-text>
                <v-form v-model="valid" :disabled="loading">
                    <v-text-field
                        v-model="message.subject"
                        :label="`${$t('email_form.subject')}*`"
                        outlined
                        :rules="[requiredRule('email_form.subject')]"
                    />
                    <emails-field
                        v-model="message.to"
                        :label="`${$t('email_form.to')}${toFieldRequired ? '*' : ''}`"
                        :required="toFieldRequired"
                    />
                    <emails-field v-model="message.cc" :label="$t('email_form.cc')" />
                    <emails-field
                        v-model="message.bcc"
                        :label="`${$t('email_form.bcc')}${!toFieldRequired ? '*' : ''}`"
                        :required="!toFieldRequired"
                    />
                    <v-textarea
                        v-model="message.text"
                        :label="`${$t('email_form.text')}*`"
                        outlined
                        :rules="[requiredRule('email_form.text')]"
                    />
                    <v-file-input
                        v-model="attachments"
                        :label="$t('email_form.attachments')"
                        multiple
                        outlined
                        chips
                        counter
                        :hint="$t('hint.multiple_files')"
                        persistent-hint
                    />
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    depressed
                    large
                    block
                    color="primary"
                    :disabled="!valid || loading"
                    :loading="loading"
                    @click="send"
                >
                    {{ $t('send') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import EmailsField from '@/components/snippets/forms/EmailsField'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {blobToBase64} from '@/utils/helpers'

    const message = () => ({
        subject: '',
        to: [],
        cc: [],
        bcc: [],
        text: '',
        attachments: [],
    })

    @Component({
        components: {EmailsField},
    })
    export default class SendClientsEmailModal extends mixins(FormValidationRulesMixin) {
        @VModel() modal
        @Prop() emails

        valid = false
        loading = false
        message = message()
        toFieldRequired = false
        attachments = []

        @Emit()
        sent() {}

        @Watch('modal')
        toggleModal() {
            this.message = message()

            if (this.emails.length === 1) {
                this.message.to = this.emails
                this.toFieldRequired = true
            } else {
                this.message.bcc = this.emails
                this.toFieldRequired = false
            }
        }

        async send() {
            if (!this.valid || this.loading) return
            try {
                this.loading = true
                this.message.attachments = await Promise.all(
                    this.attachments.map(async file => {
                        const path = await blobToBase64(file)
                        return {
                            path,
                            filename: file.name,
                        }
                    })
                )
                await this.$api.mail.post({message: this.message})
                this.$toast.success(this.$t('success_message.send_email'))
                this.modal = false
                this.message = message()
                this.sent()
            } catch (e) {
                this.$toast.error(this.$t('error_message.send_email_error'))
            } finally {
                this.loading = false
            }
        }
    }
</script>
