<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class HotelMapMixin extends Vue {
        markerColor(data) {
            return data.rooms?.find(e => e.specialOffer)
                ? this.$vuetify.theme.themes.light.success
                : this.$vuetify.theme.themes.light.primary
        }

        createMarkerIcon(data) {
            return {
                ...this.markerIconOptions,
                fillColor: this.markerColor(data),
                anchor: new this.google.maps.Point(12, 24),
            }
        }

        addMarker(pointInfo) {
            let position
            if (pointInfo?.latitude && pointInfo?.longitude) {
                position = {
                    lat: pointInfo.latitude,
                    lng: pointInfo.longitude,
                }
            } else if (pointInfo.coordinates) {
                position = {
                    lat: pointInfo.coordinates[0],
                    lng: pointInfo.coordinates[1],
                }
            } else {
                return
                /*this.geocoder.geocode({address: offer.address}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }
                    this.addMarker(results[0].geometry.location);
                });*/
            }
            const marker = new this.google.maps.Marker({
                position,
                map: this.map,
                icon: this.createMarkerIcon(pointInfo),
                data: pointInfo,
            })

            this.markers.push(marker)
        }

        get markerIconOptions() {
            return {
                path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                fillOpacity: 0.95,
                scale: 2,
                strokeColor: '#fff',
                strokeWeight: 3,
            }
        }
    }
</script>
