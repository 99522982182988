<template>
    <client-only>
        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    :placeholder="$t('start_typing')"
                    :disabled="isLoading"
                    append-icon="search"
                    single-line
                    hide-details
                />
            </v-card-title>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :items-per-page="5"
                :loading="isLoading"
                :footer-props="{'items-per-page-options': [10, 20, 50]}"
                :hide-default-header="isMobile"
                :search="search"
                :group-by="isMobile ? 'code' : []"
                sort-by="name"
                show-select
                class="elevation-1"
                data-cy="companies-table"
            >
                <template v-if="isMobile" v-slot:group.header="{items}">
                    <div class="px-4 py-2 d-flex align-center justify-space-between">
                        <div class="text-subtitle-2 primary--text text-decoration-underline">
                            {{ items[0].name }}
                            <v-icon color="primary" right small @click="edit(items[0].id)">mdi-pencil</v-icon>
                        </div>
                        <v-switch
                            v-model="items[0].active"
                            :label="items[0].active ? $t('active') : $t('inactive')"
                            color="primary"
                            dense
                            hide-details
                            class="mt-0"
                            @change="changeActive({id: items[0].id, active: items[0].active})"
                        />
                    </div>
                </template>
                <template v-if="isMobile" v-slot:item="{item}">
                    <div class="py-2 px-4">
                        <div class="d-flex justify-space-between">
                            <div>
                                {{ item.code }}, <span class="font-weight-bold">{{ item.alias }}</span>
                            </div>
                            <div>{{ item.date }}</div>
                        </div>
                        <div>{{ $t(`companyTypes.${item.type.toLowerCase()}`) }}</div>
                        <div>{{ `${item.country}${item.city ? ', ' + item.city : ''}` }}</div>
                        <div>{{ item.emails.join(', ') }}</div>
                        <div>{{ item.phone }}</div>
                    </div>
                </template>
                <template v-if="!isMobile" v-slot:item.type="{item}">
                    {{ $t(`companyTypes.${item.type.toLowerCase()}`) }}
                </template>
                <template v-if="!isMobile" v-slot:item.emails="{item}">
                    {{ item.emails.join(', ') }}
                </template>
                <template v-if="!isMobile" v-slot:item.active="{item}">
                    <v-switch
                        v-model="item.active"
                        color="primary"
                        :label="item.active ? $t('active') : $t('inactive')"
                        @change="changeActive(item)"
                    />
                </template>
                <template v-if="!isMobile" v-slot:item.edit="{item}">
                    <v-icon color="primary" @click="edit(item.id)">mdi-pencil</v-icon>
                </template>
            </v-data-table>
        </v-card>
    </client-only>
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class CompaniesTable extends Vue {
        @Prop({default: []}) companyType

        search = ''
        selected = []

        @Watch('selectedEmails')
        onChangeSelectedEmails() {
            this.changeSelectedEmails(this.selectedEmails)
        }

        @Emit()
        changeSelectedEmails(emails) {
            return emails
        }

        async mounted() {
            await this.$store.restored
            await runtimeStore.loadCompanies({
                relationship: 'CLIENT',
                shortResponse: true,
            })
        }

        edit(id) {
            this.$router.push({name: 'company-client-profile', params: {id}})
        }

        async changeActive(item) {
            const {id, active} = item
            try {
                await this.$api.companies.put(id, {active})
            } catch (e) {
                await runtimeStore.loadCompanies({
                    companyId: authStore.person.companyId,
                    relationship: 'CLIENT',
                    active: false,
                })
            }
        }

        get headers() {
            return [
                {text: this.$t('company_name'), value: 'name'},
                {text: this.$t('company_alias'), value: 'alias'},
                {text: this.$t('company_code'), value: 'code'},
                {text: this.$t('company_type'), value: 'type'},
                {text: this.$t('country'), value: 'country'},
                {text: this.$t('city'), value: 'city'},
                {text: this.$t('phone'), value: 'phone'},
                {text: this.$t('email'), value: 'emails'},
                {text: this.$t('registration'), value: 'date'},
                {text: this.$t('status'), value: 'active', width: '10%', sortable: false},
                {text: this.$t('edit'), value: 'edit', align: 'center', sortable: false},
            ]
        }

        get items() {
            return runtimeStore.companies
                .filter(company => {
                    return this.companyType.includes(company.type)
                })
                .map(company => ({
                    id: company.id,
                    name: company.name,
                    alias: company.alias,
                    code: company.companyCode,
                    type: company.type,
                    country: company.countryName,
                    city: company.cityName,
                    phone: company.phones.join(', '),
                    emails: company.emails,
                    date: this.$options.filters.dateShortFormat(company.creationDate),
                    active: company.active,
                }))
        }

        get isLoading() {
            return runtimeStore.loading
        }

        get selectedEmails() {
            return [
                ...new Set(
                    this.selected.reduce((selectedEmails, {emails}) => {
                        if (emails.length) {
                            selectedEmails = [...selectedEmails, ...emails]
                        }
                        return selectedEmails
                    }, [])
                ),
            ]
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-label {
        font-size: 0.75rem !important;
    }
    ::v-deep .v-input__icon--append {
        display: none;
    }
</style>
