<template>
    <v-form ref="form" v-model="valid" :disabled="disabled">
        <div class="pb-8 d-flex align-center">
            <span class="text-h6 text-capitalize">
                {{ `${$t(`transfers.${pointType === 'departure' ? 'from' : 'to'}`)}: ` }}
            </span>
            <v-btn icon left @click="mapModal = true">
                <v-icon color="accent">mdi-map-marker</v-icon>
            </v-btn>
            <span class="text-subtitle-2">
                {{
                    `${transferPoint && transferPoint.name} ${point.cityName ? ', ' + point.cityName : ''} ${
                        point.countryName ? ', ' + point.countryName : ''
                    }`
                }}
            </span>
        </div>
        <client-only>
            <v-dialog
                v-if="transferPoint && transferPoint.latitude && transferPoint.longitude"
                v-model="mapModal"
                :max-width="500"
            >
                <map-location
                    v-if="mapModal"
                    :latitude="transferPoint.latitude"
                    :longitude="transferPoint.longitude"
                    :height="`500px`"
                />
            </v-dialog>
        </client-only>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferFormMixin from '@/components/transfers/mixins/TransferFormMixin'
    import {transfersStore} from '@/utils/store-accessor'
    import MapLocation from '~src/components/info/mapLocation.src'

    //TODO Need mixin with TransferAirportForm
    @Component({
        components: {MapLocation, TimeField, CityAutocomplete, DatePicker},
    })
    export default class TransferManualForm extends mixins(TransferFormMixin) {
        @Prop() pointType
        @Prop() disabled
        @Prop() searchRequest
        @Prop({required: true}) transferInfo
        @PropSync('_valid', {default: false}) valid

        mapModal = false

        transferPoint = null

        async mounted() {
            this.initData()
        }

        initData() {
            this.transferPoint = this.transferInfo?.transferPoints?.find(t => t.pointType === this.pointType)

            this.setProp('type', 'manual')
            this.setProp('latitude', this.transferPoint?.latitude)
            this.setProp('longitude', this.transferPoint?.longitude)
            this.setProp('name', this.transferPoint?.name)
        }

        get basketItem() {
            return transfersStore.basketItem(this.$route.query.offerKey)
        }
    }
</script>
