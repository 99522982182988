<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class TransferFormMixin extends Vue {
        @Prop({default: false, type: Boolean}) isReturnTransfer
        @Prop({required: true}) request
        @Prop() point
        @Prop() departurePointName
        @Prop() arrivalPointName

        @Emit()
        change({prop, val}) {
            return {prop, val}
        }

        @Emit()
        changeLocation({prop, val, pointType}) {
            return {prop, val, pointType}
        }

        /*get prepareBookRequestPropMutationName() {
            return this.isReturnTransfer
                ? 'SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP'
                : 'SET_PREPARE_BOOK_REQUEST_PROP'
        }

        get prepareBookRequestStoreName() {
            return this.isReturnTransfer ? 'prepareBookReturnTransferRequest' : 'prepareBookRequest'
        }

        get prepareBookLocationPropMutationName() {
            return this.isReturnTransfer
                ? 'SET_PREPARE_BOOK_RETURN_TRANSFER_LOCATION_PROP'
                : 'SET_PREPARE_BOOK_LOCATION_PROP'
        }*/

        getProp(prop) {
            if (!this.request[this.pointType]) return null

            return this.request[this.pointType][prop]
        }

        setProp(prop, val) {
            this.changeLocation({prop, val, pointType: this.pointType})
        }

        /*get pointTypeReversed() {
            return this.pointType === 'departure' ? 'arrival' : this.pointType === 'arrival' ? 'departure' : ''
        }

        get point() {
            const pointKey = this.isReturnTransfer ? `${this.pointTypeReversed}Point` : `${this.pointType}Point`

            return transfersRuntimeStore[pointKey]
        }*/

        get formTitle() {
            if (this.destinationPointName) return this.destinationPointName

            return `${this.point.name}${this.point.cityName ? ', ' + this.point.cityName : ''}${
                this.point.countryName ? ', ' + this.point.countryName : ''
            }`
        }
    }
</script>
