<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {packagesRuntimeStore, packagesStore} from '@/store'
    import {PRODUCT_NAME} from '@/utils/packages/packages-const'

    @Component
    export default class PackagesStoreMixin extends Vue {
        get productStore() {
            return packagesStore
        }

        get productRuntimeStore() {
            return packagesRuntimeStore
        }

        get productType() {
            return 'package'
        }

        get productName() {
            return PRODUCT_NAME
        }
    }
</script>
