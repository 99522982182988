<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class NuxtPageMixin extends Vue {
        get fetchStatePending() {
            return new Promise(resolve => {
                if (!this.$fetchState.pending) resolve()
                this.$watch('$fetchState.pending', pending => {
                    if (!pending) resolve()
                })
            })
        }
    }
</script>
