<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <hotel-breadcrumbs :search-request="searchRequest" :offer="offer" />
        </template>
        <template v-slot:sidebar>
            <hotel-order-summary
                :offer="offer"
                :room-offers="roomOffers"
                :search-request="searchRequest"
                :selected-add-ons="selectedAddOns"
                :extra-services="extraServices"
                :additional-options="bookingAdditionalOptions"
                :tourists="tourists"
                prebooked
                :selected-meal-types="[]"
            />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import HotelInfoSearchSummary from '@/components/info/HotelInfoSearchSummary'
    import HotelOrderSummary from '~src/components/booking/hotelOrderSummary.src'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin.vue'

    @Component({
        components: {
            HotelBreadcrumbs,
            ConfirmationPageLayout,
            HotelOrderSummary,
            HotelInfoSearchSummary,
            HotelInfoTime,
        },
        layout: 'blank',
    })
    export default class HotelBookingConfirmationPage extends mixins(
        ConfirmationPageMixin,
        HotelPageMixin,
        HotelStoreMixin
    ) {
        //TODO Need refactoring with ConfirmationPageMixin
        async mounted() {
            await this.$store.restored

            //TODO Remove it
            const tourists = hotelsStore.tourists.map(room => {
                return room.map(tourist => {
                    const trst = {...tourist}
                    delete trst.alternativeMealTypeId
                    return trst
                })
            })
            hotelsStore.SET_TOURISTS(tourists)

            await hotelsRuntimeStore.loadOwnProductPaxes()
        }

        get basketItem() {
            if (this.basketAddOns) {
                return hotelsStore.basketItem(this.basketAddOns.offerKeys)
            }
            return hotelsStore.basketItem(hotelsStore.prepareBookRequest.map(({offerKey}) => offerKey))
        }

        get offer() {
            return this.basketItem.offer || {}
        }

        get roomOffers() {
            return this.basketItem.roomOffers || []
        }

        get searchRequest() {
            return this.basketItem.searchRequest || {}
        }

        get hotelInfoRequest() {
            const {supplierCode, cityCode, hotelCode, hotelId} = this.offer
            return {supplierCode, cityCode, hotelCode, hotelId}
        }

        get cityId() {
            return this.searchRequest.cityId
        }

        get extraServices() {
            return hotelsStore.prepareBookRequest.map(({extraServices}) => extraServices)
        }

        get tourists() {
            return hotelsStore.prepareBookRequest.map(({tourists}) => tourists)
        }
    }
</script>
