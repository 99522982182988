var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"800px","max-width":"100%"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.info)?_c('v-card',{staticClass:"pa-md-3",attrs:{"width":"800px","max-width":"100%"}},[_c('v-icon',{staticClass:"v-card-close",on:{"click":function($event){_vm.modal = false}}},[_vm._v("mdi-close")]),_vm._v(" "),_c('v-card-text',{staticClass:"black--text"},[_c('h2',[_vm._v("\n                "+_vm._s(_vm.info.name)+"\n            ")]),_vm._v(" "),(_vm.info.location)?_c('v-subheader',[_vm._v("\n                "+_vm._s(_vm.info.location)+"\n            ")]):_vm._e(),_vm._v(" "),(
                    (_vm.info.images && _vm.info.images.length) ||
                    (_vm.info.descriptions && _vm.info.descriptions.length) ||
                    (_vm.info.comments && _vm.info.comments.length) ||
                    _vm.info.fullDescription ||
                    _vm.info.shortDescription
                )?_c('v-tabs',[_c('v-tab',[_vm._v("\n                    "+_vm._s(_vm.$t('description'))+"\n                ")]),_vm._v(" "),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-carousel',{attrs:{"height":"170px","hide-delimiters":"","show-arrows-on-hover":"","show-arrows":_vm.info.images && _vm.info.images.length > 1}},_vm._l((_vm.info.images),function(image,imageIndex){return _c('v-carousel-item',{key:imageIndex},[_c('v-img',{attrs:{"src":_vm._f("imageUrl")(image.url,300),"contain":""}})],1)}),1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.offer.serviceDescription)?[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('description')))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.offer.serviceDescription)}})]:_vm._e(),_vm._v(" "),(_vm.info.comments && _vm.info.comments.length)?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('comments'))+" ")]),_c('br'),_vm._v(" "),_vm._l((_vm.info.comments),function(comment,commentIndex){return _c('div',{key:commentIndex},[_vm._v("\n                                    "+_vm._s(comment.title)+": "+_vm._s(comment.description)+"\n                                ")])})],2):_vm._e()],2)],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }