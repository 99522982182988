<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_PASSPORT_MODAL_EVENT} from '@/utils/event-bus'

    @Component
    export default class PassportMixin extends Vue {
        createPassport(type, personId) {
            EventBus.$emit(SHOW_PASSPORT_MODAL_EVENT, type, null, personId)
        }

        editPassport(type, passport, personId) {
            EventBus.$emit(SHOW_PASSPORT_MODAL_EVENT, type, passport, personId)
        }
    }
</script>
