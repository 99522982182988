<template>
    <div>
        <div class="d-flex">
            <v-img
                :aspect-ratio="4 / 3"
                :src="mainImage.url | imageUrl(250)"
                :lazy-src="'/placeholder.png' | srcHost"
                max-width="150"
                max-height="112.5"
                tile
                class="me-3"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && mainImage.url" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                <div
                    :title="product.info.name"
                    class="me-2 primary--text font-weight-medium text-subtitle-1 text-truncate"
                >
                    {{ product.info.name }}
                </div>
                <v-chip v-if="offers[0].transferType && offers[0].transferType !== 'UNKNOWN'" label x-small>
                    {{ $t(`filters_transferType.${offers[0].transferType}`) }}
                </v-chip>
                <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" />
                <transfer-operated-by-label :product="product" class="mb-2" />
                <div v-if="info.shortDescription" class="mb-4 text-body-2 text-wrap">
                    {{ info.shortDescription }}
                </div>
            </div>
        </div>
        <slot name="summary" />
        <v-divider class="mb-2" />
        <div v-for="offer in offers" :key="offer.offerKey" class="relative-position">
            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo(offer)">
                <v-icon small left>mdi-information-outline</v-icon>
                <span>
                    {{ offer.info.description }}
                </span>
                <span v-if="offerTime(offer)" class="ms-2 grey--text font-weight-bold text-body-2">
                    {{ `(${offerTime(offer)})` }}
                </span>
            </div>
            <div v-if="offer.available" class="caption d-flex align-center">
                <v-icon :title="$t('available')" left small class="success--text"> mdi-checkbox-marked-circle </v-icon>
                <span class="success--text" v-text="$t('available')" />
            </div>
            <div v-else class="caption d-flex align-center">
                <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                <span class="warning--text" v-text="$t('on_request')" />
            </div>
            <conditions-label
                v-if="showPrice"
                :offer-key="offer.offerKey"
                :price="offer.price"
                class="text-caption"
                @conditions="showConditionsInfo(offer)"
            />
            <div v-if="offer.info.tariffName" class="caption d-flex align-center">
                <v-icon left small class="info--text">mdi-tag-text</v-icon>
                <span>
                    {{ offer.info.tariffName }}
                </span>
            </div>
            <transfer-driver-languages :lang-codes="offer.langCodes" />
            <div v-if="showPrice" class="mt-1 font-weight-bold">
                <span>
                    {{ offerPrice(offer) | price }}
                </span>
                <commission-amount :commission="offer.price.commission" />
                <net-amount :price="offer.price" />
            </div>
            <div v-else-if="free" class="mt-1 font-weight-bold">{{ $t('free') }}</div>
            <slot name="removeBtn" :offer-key="offer.offerKey" />
        </div>
        <extra-services-summary
            v-if="extraServices[0] && additionalOptions[0]"
            :extra-services="extraServices[0]"
            :additional-options="additionalOptions[0]"
        />
        <template v-if="!!returnTransferOffer">
            <v-divider class="my-8 d-flex" />
            <transfer-return-order-summary-card
                :info-return-transfer="infoReturnTransfer"
                :return-transfer-offer="returnTransferOffer"
                :search-request-return-transfer="searchRequestReturnTransfer"
                :extra-services="extraServices"
                :additional-options="productStore.bookingAdditionalOptions"
            />
        </template>
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {clone} from '@/utils/helpers'
    import {authStore, transfersRuntimeStore, transfersStore} from '~/store'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import TransferReturnOrderSummaryCard from '@/components/transfers/booking/TransferReturnOrderSummaryCard'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import OrderSummaryBase from '@/components/booking/OrderSummaryBase.vue'
    import TransferOperatedByLabel from '@/components/transfers/snippets/TransferOperatedByLabel.vue'

    @Component({
        components: {
            TransferOperatedByLabel,
            TransferReturnOrderSummaryCard,
            TransferDriverLanguages,
            ExtraServicesSummary,
            OrderSummaryLayout,
            ConditionsLabel,
            CommissionAmount,
            NetAmount,
            SupplierNameLabel,
        },
    })
    export default class TransferOrderSummary extends OrderSummaryBase {
        @Prop({required: true, type: Array}) offers
        @Prop({required: true}) info
        @Prop({required: true}) product
        @Prop({required: true}) searchRequest
        @Prop() returnTransferOffer
        @Prop() infoReturnTransfer
        @Prop() searchRequestReturnTransfer
        @Prop({required: true, type: Array}) additionalOptions

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo(offer) {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequest, offer)
        }

        offerInfo(offer) {
            EventBus.$emit(SHOW_TRANSFER_INFO_MODAL, {offer, info: this.info})
        }

        offerPrice(offer) {
            const price = this.prebooked && offer?.currentPrice ? clone(offer.currentPrice) : clone(offer.price)
            const mandatory = this.additionalOptions?.[0]?.extraServices?.filter(({mandatory}) => mandatory) || []
            if (mandatory.length) {
                mandatory.forEach(item => {
                    price.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount
                    price.originalAmount -= item.salesTerms.find(el => el.type === 'CLIENT').originalAmount
                })
            }
            return price
        }

        offerTime(offer) {
            if (offer.time?.length) {
                return offer.time[0]
            }
            if (offer.departureTimeFrom && offer.departureTimeTo) {
                return [offer.departureTimeFrom, offer.departureTimeTo].join(' - ')
            }

            return null
        }

        get productStore() {
            return transfersStore
        }

        get mainImage() {
            return transfersRuntimeStore.offerMainImage(this.offers[0], this.info) || null
        }

        get isTO1() {
            return authStore.isTO1
        }

        get showPrice() {
            return !this.free
        }
    }
</script>
