<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class AirlinesSearchMixin extends Vue {
        async searchAirlines(rq) {
            const defaults = {
                pattern: null,
                limit: 10,
            }

            return (await this.$api.airlines.get({...defaults, ...rq})).airlines
        }
    }
</script>
