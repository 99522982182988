<template>
    <product-card-layout-mobile
        :expanded.sync="expanded"
        :expanded-limit.sync="expandedLimit"
        :is-add-on="isAddOn"
        :thumb="product.info.imageUrl"
        :name="product.info.name"
        :product-link="productLink"
        :offers-count="product.offers && product.offers.length"
        :is-recommended="product.recommended"
    >
        <div class="d-flex flex-column my-2">
            <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" />
            <div v-if="product.info.description" class="short-description my-1">
                {{ product.info.description }}
            </div>
            <transfer-operated-by-label :product="product" class="mb-2" />
            <transfer-driver-languages :lang-codes="langCodes" />
            <v-divider class="my-3" />
        </div>
        <template v-slot:price>
            <slot name="price" :offer="minPriceOffer" :offer-key="minPriceOffer.offerKey">
                <div v-if="product.offers[0] && product.offers[0].price && !hidePrice">
                    <nuxt-link no-prefetch :to="productLink" class="title text-decoration-none">
                        <strong data-cy="client-price" class="black--text">
                            {{ $t('from') }} {{ minPriceOffer.price | price }}
                        </strong>
                    </nuxt-link>
                    <discount-label :price="minPriceOffer.price" />
                    <commission-amount :commission="minPriceOffer.price.commission" />
                </div>
            </slot>
        </template>
        <template v-slot:offers>
            <transfer-offer-card
                v-for="(offer, offerIndex) in product.offers.slice(0, expanded)"
                :key="offerIndex"
                :offer="offer"
                :full-card="false"
                :data-cy="`transfer-offer-card-${offerIndex}`"
            >
                <template v-slot:price>
                    <slot name="price" :offer="offer" :offer-key="offer.offerKey" />
                </template>
            </transfer-offer-card>
        </template>
    </product-card-layout-mobile>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import TransferCard from '~src/components/transfers/search/offers/transferCard.src'
    import TransferOfferCard from '~src/components/transfers/info/transferOfferCard.src'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import ProductCardLayoutMobile from '@/components/search/ProductCardLayoutMobile.vue'
    import ActivityGroupedOffers from '@/components/activities/search/offers/ActivityGroupedOffers.vue'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard.vue'

    @Component({
        components: {
            ActivityOfferCard,
            ActivityGroupedOffers,
            ProductCardLayoutMobile,
            TransferDriverLanguages,
            DiscountLabel,
            CommissionAmount,
            TransferOfferCard,
        },
    })
    export default class TransferCardMobile extends TransferCard {
        open() {
            if (!this.expanded || this.expanded >= this.product.offers.length) {
                this.toggleOffers()
            } else {
                this.showAllOffers()
            }
        }

        showAllOffers() {
            this.expanded = this.product.offers.length
        }
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
</style>
