<template>
    <v-list-item ref="infoWindow" three-line class="pl-0">
        <v-list-item-avatar :size="isCompactMode ? '50' : '125'" tile class="my-0">
            <v-img
                tile
                class="thumb"
                :src="photoUrl | imageUrl(250)"
                :alt="location.name"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="onImgError"
                @load="load"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && photoUrl" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
        </v-list-item-avatar>
        <div :class="isCompactMode ? 'small-content' : ''">
            <v-list-item-title :title="location.name" class="mb-4">
                <span class="text-wrap">
                    {{ location.name }}
                </span>
            </v-list-item-title>
            <div>
                <v-icon color="primary" small left>mdi-shoe-print</v-icon>
                {{ walkingDistance }}
            </div>
            <div>
                <v-icon color="primary" small left>mdi-clock-outline</v-icon>
                {{ walkingDuration }}
            </div>
            <v-divider class="my-2" />
            <div>
                <v-icon color="primary" small left>mdi-car</v-icon>
                {{ drivingDistance }}
            </div>
            <div>
                <v-icon color="primary" small left>mdi-clock-outline</v-icon>
                {{ drivingDuration }}
            </div>
        </div>
    </v-list-item>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class HotelDirectionMapInfoWindow extends Vue {
        @Prop() location
        @Prop() drivingDirectionData
        @Prop() walkingDirectionData

        imgError = false
        clientWidth = 201

        load() {
            this.setSize()
        }

        setSize() {
            this.clientWidth = this.$refs.infoWindow.$el.clientWidth
        }

        onImgError() {
            this.imgError = true
            return false
        }

        get photoUrl() {
            return this.location.photos?.length ? this.location.photos[0].getUrl() : null
        }

        get walkingDistance() {
            const value = this.walkingDirectionData.legs[0].distance.value
            if (value >= 1000) return `${(value / 1000).toFixed(2)} ${this.$t('km')}`

            return `${value} ${this.$t('m')}`
        }

        get drivingDistance() {
            const value = this.drivingDirectionData.legs[0].distance.value
            if (value >= 1000) return `${(value / 1000).toFixed(2)} ${this.$t('km')}`

            return `${value} ${this.$t('m')}`
        }

        get walkingDuration() {
            const min = Math.ceil(this.walkingDirectionData.legs[0].duration.value / 60)

            return `${min} ${this.$tc('minute', min)}`
        }

        get drivingDuration() {
            const min = Math.ceil(this.drivingDirectionData.legs[0].duration.value / 60)

            return `${min} ${this.$tc('minute', min)}`
        }

        get isCompactMode() {
            return this.clientWidth < 200
        }
    }
</script>

<style scoped>
    .small-content {
        font-size: 0.8rem !important;
    }
</style>
