<template>
    <v-row>
        <v-col cols="12" md="2">
            <v-select
                v-if="roomTypesList.length"
                v-model="room.roomId"
                :label="$t('room_type')"
                :items="roomTypesList"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
            />
            <v-text-field v-else v-model="room.roomTypeName" :label="$t('room_type')" outlined dense hide-details />
        </v-col>
        <v-col cols="12" md="2">
            <v-select
                v-if="mealTypesList.length"
                v-model="room.roomMealTypeId"
                :label="$t('meal_type')"
                :items="mealTypesList"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                @change="$emit('change-meal-type')"
            />
            <v-text-field
                v-else
                v-model="room.mealTypeName"
                :label="$t('meal_type')"
                outlined
                dense
                hide-details
                @input="$emit('change-meal-type')"
            />
        </v-col>
        <slot />
    </v-row>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ModifyManualRoom extends Vue {
        @VModel() room
        @Prop({required: true}) index
        @Prop() roomTypesList
        @Prop() mealTypesList
    }
</script>
