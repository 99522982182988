<template>
    <div v-if="arrivalPoint && departurePoint" class="pa-4">
        <div v-if="showTitle" class="mb-2">
            <span class="text-h6">{{ productOffer.date | dateFormat }}</span>
            <span class="text-subtitle-1 font-weight-bold">{{ product.name }}</span>
        </div>
        <transfer-booking-forms
            v-model="valid"
            :arrival-point="arrivalPoint"
            :departure-point="departurePoint"
            :search-request="searchRequest"
            :disabled="disabled"
            :offer="offerForTransferForms"
            :product="productForTransferForms"
            :request="request"
            :transfer-info="transferInfo"
            @change-location="changeLocation"
            @change="change"
        />
    </div>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import {toursStore} from '@/utils/store-accessor'
    import TransferBookingForms from '@/components/transfers/booking/forms/TransferBookingForms'
    import TimeField from '@/components/snippets/forms/TimeField'

    @Component({
        components: {TimeField, TransferBookingForms},
    })
    export default class TourTransferForm extends Vue {
        @PropSync('_valid', {default: false}) valid
        @Prop({required: true}) product
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) showTitle

        hotels = []
        transferInfo = null
        departureAllowedHotels = null
        arrivalAllowedHotels = null

        async mounted() {
            try {
                this.transferInfo = await this.$api.transferInfo.get({offerKey: this.productOffer.offerKey})

                const initPoint = async pointType => {
                    const point = {...this.productOffer?.[`${pointType}Info`]}
                    const typeLowerCased = point.type.toLowerCase()
                    const typeAdapted = typeLowerCased === 'station' ? 'train' : typeLowerCased

                    this.changeLocation({prop: 'type', val: typeAdapted, pointType})
                }

                await initPoint('departure')
                await initPoint('arrival')

                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        changeLocation({prop, val, pointType}) {
            const entryParam = toursStore.prepareBookRequest.entryParams.find(
                entry => entry.entryName === this.product.entryName
            )
            const entryParamPoint = entryParam[pointType]

            toursStore.SET_PREPARE_BOOK_ENTRY_PARAM_PROP({
                entryName: this.product.entryName,
                prop: pointType,
                val: {
                    ...entryParamPoint,
                    ...{[prop]: val},
                },
            })
        }

        change({prop, val}) {
            toursStore.SET_PREPARE_BOOK_ENTRY_PARAM_PROP({
                entryName: this.product.entryName,
                prop: prop,
                val: val,
            })
        }

        get productOffer() {
            return this.product.offers.find(offer => offer.offerKey === this.product.selectedOfferKey)
        }

        get arrivalPoint() {
            const transferPointInfo = this.transferInfo?.transferPoints?.find(t => t.pointType === 'arrival')
            if (this.productOffer?.hotelArrival) {
                this.changeLocation({
                    prop: 'hotelCode',
                    val: this.productOffer?.hotelArrival.hotelCode,
                    pointType: 'arrival',
                })
                this.changeLocation({
                    prop: 'hotelName',
                    val: this.productOffer?.hotelArrival.hotelName,
                    pointType: 'arrival',
                })
            }

            const point = {...this.request.arrival, name: transferPointInfo?.name}

            if (this.productOffer?.hotelArrival) {
                point.name = this.productOffer?.hotelArrival.hotelName
            }

            return point
        }

        get departurePoint() {
            const transferPointInfo = this.transferInfo?.transferPoints?.find(t => t.pointType === 'departure')

            if (this.productOffer?.hotelDeparture) {
                this.changeLocation({
                    prop: 'hotelCode',
                    val: this.productOffer?.hotelDeparture.hotelCode,
                    pointType: 'departure',
                })
                this.changeLocation({
                    prop: 'hotelName',
                    val: this.productOffer?.hotelDeparture.hotelName,
                    pointType: 'departure',
                })
            }

            const point = {...this.request.departure, name: transferPointInfo?.name}

            if (this.productOffer?.hotelDeparture) {
                point.name = this.productOffer?.hotelDeparture.hotelName
            }

            return point
        }

        get searchRequest() {
            return {
                ...toursStore.searchRequest,
                dateTime: this.productOffer.date,
            }
        }

        get offerForTransferForms() {
            const {transferType} = this.productOffer
            return {
                transferType,
                time: [],
            }
        }

        get productForTransferForms() {
            const {supplierCode} = this.productOffer
            return {
                info: {
                    supplierCode,
                },
            }
        }

        get request() {
            return toursStore.prepareBookRequest.entryParams.find(entry => entry.entryName === this.product.entryName)
        }
    }
</script>
