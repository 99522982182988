<template>
    <div>
        <v-btn color="primary" nuxt :to="{name: 'home'}">
            {{ $t('book_other_services') }}
        </v-btn>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'

    @Component
    export default class ConfirmationPageButtons extends Vue {}
</script>
