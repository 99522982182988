<template>
    <activity-booking-page-layout
        v-if="loaded"
        :search-request="searchRequest"
        :offer="offer"
        :product="product"
        :product-store="productStore"
        :search-request-country-id="searchRequestCountryId"
        tourist-passport-form
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <activity-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:forms>
            <v-card outlined class="mb-5">
                <v-card-title>
                    <v-icon left color="primary">mdi-map-marker</v-icon>
                    {{
                        product.productType.toLowerCase() === 'event'
                            ? $t('activities.time_and_location')
                            : $t('activities.pick-up_point_and_time')
                    }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <activity-booking-form
                        :offer="offer"
                        :start-time="offer.startTimes[0]"
                        :info="basketItem.info"
                        @change="setPickupPointField"
                    />
                </v-card-text>
            </v-card>
        </template>
        <template v-slot:orderCommentsForm="{bookingKey, basketKey}">
            <booking-comments-form :disabled="!!bookingKey || !!basketKey" :product-store="productStore" />
        </template>
        <template v-slot:orderSummary="{bookingKey, basketKey, selectedAddOns, extraServices}">
            <activity-order-summary
                :product="product"
                :offers="[offer]"
                :info="basketItem.info"
                :prebooked="!!bookingKey || !!basketKey"
                :selected-add-ons="selectedAddOns"
                :extra-services="extraServices"
                :additional-options="productStore.bookingAdditionalOptions"
            >
                <template v-slot:summary>
                    <v-card flat tile class="my-2 pa-0">
                        <v-card-text class="pa-0">
                            <search-summary-content
                                :search-request="searchRequest"
                                :date-prop="offer.date"
                                tourists-label="guest"
                                show-date
                                show-tourists
                            />
                        </v-card-text>
                    </v-card>
                </template>
            </activity-order-summary>
        </template>
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
                <activity-conditions-modal-info />
                <hotel-conditions-modal-info />
            </template>
        </conditions-modal>
        <hotel-meal-type-info-modal />
        <hotel-room-info-modal />
        <additional-fees-modal />
        <transfer-info-modal />
        <activity-info-modal />
    </activity-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'
    import ActivityOrderSummary from '@/components/activities/booking/ActivityOrderSummary'
    import {runtimeStore} from '@/utils/store-accessor'
    import ActivityBookingPageLayout from '~/components/activities/booking/ActivityBookingPageLayout'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {clone, UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import ActivityBookingForm from '@/components/activities/booking/forms/ActivityBookingForm'
    import ConditionsModal from '@/components/modals/ConditionsModal.vue'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo.vue'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo.vue'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo.vue'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote.vue'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal.vue'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal.vue'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal.vue'
    import AdditionalFeesModal from '@/components/modals/AdditionalFeesModal.vue'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal.vue'

    @Component({
        components: {
            TransferInfoModal,
            AdditionalFeesModal,
            HotelRoomInfoModal,
            HotelMealTypeInfoModal,
            ActivityInfoModal,
            HotelPriceNote,
            HotelConditionsModalInfo,
            ActivityConditionsModalInfo,
            TransferConditionsModalInfo,
            ConditionsModal,
            ActivityBookingForm,
            ExpiredOffersSnackbar,
            ActivityBreadcrumbs,
            ActivityOrderSummary,
            ActivityBookingPageLayout,
            BookingCommentsForm,
            SearchSummaryContent,
        },
        layout: 'blank',
    })
    export default class ActivityBookingPage extends mixins(ActivityStoreMixin, ActivityPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            await runtimeStore.loadTouristBookingFields({offerKey: this.$route.query.offerKey})
            await runtimeStore.loadProductBookingFields({offerKey: this.$route.query.offerKey})
            this.productStore.clearBookingAdditionalOptions()
            this.clearPickupPointFields()
            await this.productStore.getExtraServiceAdditionalOptions(this.$route.query.offerKey)
            this.loaded = true
        }

        clearPickupPointFields() {
            this.setPickupPointField({prop: 'startTime', val: null})
            this.setPickupPointField({prop: 'hotelPointKey', val: null})
            this.setPickupPointField({prop: 'pickUpPlace', val: null})
        }

        setPickupPointField({prop, val}) {
            const rqCopy = clone(this.prepareBookRequest)

            if (!val) {
                delete rqCopy[prop]
            }

            this.productStore.SET_PREPARE_BOOK_REQUEST({
                ...rqCopy,
                ...(val && {[prop]: val}),
            })
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !this.productStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        get prepareBookRequest() {
            return this.productStore.prepareBookRequest
        }

        get searchRequestCountryId() {
            return this.productRuntimeStore.city.countryId
        }

        get basketItem() {
            return this.productStore.basketItem(this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }
    }
</script>
