<script>
    import {Component} from 'vue-property-decorator'
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'
    import {hotelsStore, hotelsRuntimeStore} from '~/store'
    import {encodeSupplierCode, hotelNameToUrl} from '@/utils/helpers'

    @Component
    export default class HotelInfoSearchForm extends HotelSearchForm {
        async search() {
            this.$emit('close-popup')
            const query = this.newQuery()
            if (!query.hotelId) {
                Object.assign(query, hotelsRuntimeStore.hotelInfoRequest)
                if (query.supplierCode) query.supplierCode = encodeSupplierCode(query.supplierCode)
            } else {
                delete query.hotelCode
                delete query.supplierCode
                delete query.cityCode
            }
            try {
                await this.$router.push({name: 'hotel', params: {name: hotelNameToUrl(this.$route.params.name)}, query})
            } catch (e) {
                await hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
            }
        }

        get isSingleHotelSearch() {
            return true
        }

        get searchRequest() {
            return hotelsStore.singleHotelSearchRequest
        }

        get searchRequestTourists() {
            return hotelsStore.singleHotelSearchRequestTourists
        }
    }
</script>
