<template>
    <div>
        <div v-if="filteredOffers.length" class="d-flex justify-end">
            <v-btn :disabled="tableView" depressed tile x-small @click="toggleTableView(true)">
                <v-icon small>mdi-table-large</v-icon>
            </v-btn>
            <v-btn :disabled="!tableView" depressed tile x-small @click="toggleTableView(false)">
                <v-icon small>mdi-table-of-contents</v-icon>
            </v-btn>
        </div>
        <template v-if="groupOffersBy === 'tourName' && !tableView">
            <component
                :is="component"
                v-for="(group, groupIndex) in groupedOffers"
                :key="groupIndex"
                :offers="group"
                :group-type="'tourName'"
                :page="page"
                :data-cy="`tour-grouped-offer-${groupIndex}`"
            />
        </template>
        <template v-else-if="groupOffersBy === 'none' && !tableView">
            <component
                :is="component"
                v-for="(offer, index) in filteredOffers"
                :key="index"
                :offers="[offer]"
                :page="page"
                :data-cy="`tour-offer-${index}`"
            />
        </template>
        <template v-else-if="tableView && filteredOffers.length">
            <tour-offer-table-view :offers="filteredOffers" />
        </template>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import TourOfferCard from '@/components/tours/search/offers/TourOfferCard'
    import TourOfferCardMobile from '@/components/tours/search/offers/TourOfferCardMobile'
    import TourOfferTableView from '@/components/tours/search/offers/TourOfferTableView'
    import {toursRuntimeStore, toursStore} from '@/utils/store-accessor'

    @Component({
        components: {
            TourOfferCard,
            TourOfferCardMobile,
            TourOfferTableView,
        },
    })
    export default class TourOffers extends Vue {
        @Prop() page

        tableView = this.$config.search.tours.tableViewByDefault

        toggleTableView(val) {
            if (val) {
                toursStore.SET_GROUP_OFFERS_BY('none')
                toursStore.SET_SORT_FN_NAME('dateAsc')
                toursRuntimeStore.sort()
                this.tableView = true
            } else {
                this.tableView = false
            }
        }

        get filteredOffers() {
            const index = this.$config.search.resultsOnPage * (this.page - 1)
            return toursRuntimeStore.offers.slice(index, index + this.$config.search.resultsOnPage)
        }

        get groupedOffers() {
            return this.filteredOffers.reduce((groupedOffers, offer) => {
                if (groupedOffers.length) {
                    if (groupedOffers.some(el => el.some(item => item.info.name === offer.info.name))) {
                        groupedOffers.forEach((group, index) => {
                            if (group.some(el => el.info.name === offer.info.name)) groupedOffers[index].push(offer)
                        })
                    } else {
                        groupedOffers.push([offer])
                    }
                } else groupedOffers = [[offer]]

                return groupedOffers
            }, [])
        }

        get groupOffersBy() {
            return toursStore.groupOffersBy
        }

        get component() {
            return !this.$breakpoint.smAndDown ? 'tour-offer-card' : 'tour-offer-card-mobile'
        }
    }
</script>
