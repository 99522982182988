<template>
    <client-only>
        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :placeholder="$t('start_typing')"
                    single-line
                    hide-details
                    :disabled="isLoading"
                />
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                :loading="isLoading"
                :footer-props="{'items-per-page-options': [10, 20, 50]}"
                :hide-default-header="isMobile"
                :mobile-breakpoint="0"
                :search="search"
                :group-by="isMobile ? 'id' : []"
                sort-by="name"
                class="elevation-1"
                data-cy="persons-table"
            >
                <template v-if="isMobile" v-slot:group.header="{items}">
                    <div class="px-4 py-2 d-flex align-center justify-space-between">
                        <div class="text-subtitle-2 primary--text text-decoration-underline">
                            {{ items[0].name }}
                            <v-icon color="primary" right small @click="edit(items[0].id)">mdi-pencil</v-icon>
                        </div>
                        <v-switch
                            v-model="items[0].active"
                            :label="items[0].active ? $t('active') : $t('inactive')"
                            color="primary"
                            dense
                            hide-details
                            class="mt-0"
                            @change="changeActive({id: items[0].id, active: items[0].active})"
                        />
                    </div>
                </template>
                <template v-if="isMobile" v-slot:item="{item}">
                    <div class="py-2 px-4">
                        <div class="font-weight-bold">{{ item.login }}</div>
                        <div>{{ item.role }}</div>
                        <div>
                            <a v-if="item.email" :href="`mailto:${item.email}`" class="text-decoration-none">
                                {{ item.email }}
                                <span v-if="item.phone">,</span>
                            </a>
                            <a v-if="item.phone" :href="`tel:${item.phone}`" class="text-decoration-none">
                                {{ item.phone }}
                            </a>
                        </div>
                    </div>
                </template>
                <template v-if="!isMobile" v-slot:item.active="{item}">
                    <v-switch
                        v-model="item.active"
                        color="primary"
                        :label="item.active ? $t('active') : $t('inactive')"
                        @change="changeActive(item)"
                    />
                </template>
                <template v-if="!isMobile" v-slot:item.edit="{item}">
                    <v-icon color="primary" @click="edit(item.id)">mdi-pencil</v-icon>
                </template>
            </v-data-table>
        </v-card>
    </client-only>
</template>

<script>
    import {Component, Mixins} from 'vue-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component
    export default class PersonsTable extends Mixins(LoadMixin) {
        search = ''

        get headers() {
            return [
                {text: this.$t('name'), value: 'name'},
                {text: this.$t('login'), value: 'login'},
                {text: this.$t('role'), value: 'role'},
                {text: this.$t('email'), value: 'email'},
                {text: this.$t('status'), value: 'active', width: '10%', sortable: false},
                {text: this.$t('edit'), value: 'edit', align: 'center', sortable: false},
            ]
        }

        get items() {
            return runtimeStore.persons.map(person => {
                const firstName = person.firstName || person.firstNameLocalized || ''
                const lastName = person.lastName || person.lastNameLocalized || ''

                return {
                    id: person.personId,
                    name: `${person.prefix || ''} ${firstName} ${lastName}`,
                    login: person.login,
                    role: this.$t(`roles.${person.role.split('.')[1]}`),
                    email: person.email,
                    phone: person.contactPhone,
                    active: person.active === 'true',
                }
            })
        }

        get serverItemsLength() {
            return runtimeStore.persons.length
        }

        get isLoading() {
            return runtimeStore.loading
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        load() {
            runtimeStore.loadPersons({
                companyId: this.$route.query.company ? this.$route.query.company : authStore.person.companyId,
            })
        }

        edit(id) {
            this.$router.push({name: 'companyPerson', params: {id}})
        }

        async changeActive(item) {
            const {id, active} = item
            try {
                await this.$api.persons.put(id, {active})
            } catch (e) {
                await runtimeStore.loadPersons({companyId: authStore.person.companyId})
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-label {
        font-size: 0.75rem !important;
    }
    ::v-deep .v-input__icon--append {
        display: none;
    }
</style>
