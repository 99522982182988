<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-car"
        :is-first="isFirst"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :service-image="mainImage"
                :service-label="serviceDetails.transport"
                :is-package="isPackage"
                :order="order"
                :order-end-date="orderEndDate"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div class="mt-4 d-flex align-top">
                    <v-btn
                        v-if="mapPointDeparture"
                        left
                        small
                        icon
                        style="margin-left: -6px; margin-top: -4px"
                        @click="mapDepartureModal = true"
                    >
                        <v-icon small color="primary">
                            {{ departureIcon }}
                        </v-icon>
                    </v-btn>
                    <v-icon v-else left small style="height: max-content; margin-top: 3px">
                        {{ departureIcon }}
                    </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('from') }}
                    </span>
                    <div v-html="departurePoint.destinationPointName" />
                    <client-only>
                        <v-dialog
                            v-if="mapPointDeparture && mapPointDeparture.latitude && mapPointDeparture.longitude"
                            v-model="mapDepartureModal"
                            :max-width="500"
                        >
                            <map-location
                                v-if="mapDepartureModal"
                                :latitude="mapPointDeparture.latitude"
                                :longitude="mapPointDeparture.longitude"
                                :height="`500px`"
                            />
                        </v-dialog>
                    </client-only>
                </div>
                <div v-if="departurePointName" class="d-flex align-center ms-6">
                    <div class="font-weight-bold me-1">
                        {{ `${$t('departure_trip')}: ` }}
                    </div>
                    <div v-html="departurePointName" />
                </div>
                <div class="d-flex align-top">
                    <v-btn
                        v-if="mapPointArrival"
                        left
                        small
                        icon
                        style="margin-left: -6px; margin-top: -4px"
                        @click="mapArrivalModal = true"
                    >
                        <v-icon small color="primary">
                            {{ arrivalIcon }}
                        </v-icon>
                    </v-btn>
                    <v-icon v-else left small style="height: max-content; margin-top: 3px">
                        {{ arrivalIcon }}
                    </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('to') }}
                    </span>
                    <div v-html="arrivalPoint.destinationPointName" />
                    <client-only>
                        <v-dialog
                            v-if="mapPointArrival && mapPointArrival.latitude && mapPointArrival.longitude"
                            v-model="mapArrivalModal"
                            :max-width="500"
                        >
                            <map-location
                                v-if="mapArrivalModal"
                                :latitude="mapPointArrival.latitude"
                                :longitude="mapPointArrival.longitude"
                                :height="`500px`"
                            />
                        </v-dialog>
                    </client-only>
                </div>
                <div v-if="arrivalPointName" class="d-flex align-center ms-6">
                    <div class="font-weight-bold me-1">
                        {{ `${$t('arrival_trip')}: ` }}
                    </div>
                    <div v-html="arrivalPointName" />
                </div>
                <div v-if="pickUpSign" class="d-flex align-center mt-4">
                    <v-icon left small>mdi-card-bulleted-outline</v-icon>
                    <strong class="me-1">{{ $t('transfers.pickup_sign') }}:</strong>
                    {{ pickUpSign }}
                </div>
                <template v-slot:extraTime>
                    <div v-if="info.duration" class="d-flex align-center">
                        <v-icon left small>mdi-timer-outline</v-icon>
                        <strong class="black--text me-1">
                            {{ transferDuration }}
                        </strong>
                    </div>
                </template>
                <transfer-driver-languages :lang-codes="langCodes" />
                <transfer-operated-by-label
                    :product="{
                        info: {operatedBy: service.serviceDetails[0].operatedBy, supplierCode: service.supplierCode},
                    }"
                />
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillTransferBookingDataModal = true">mdi-pencil</v-icon>
                    <span
                        class="text-caption cursor-pointer primary--text"
                        @click="fillTransferBookingDataModal = true"
                    >
                        {{ $t('enter_details') }}
                    </span>
                    <fill-transfer-booking-data-modal
                        v-model="fillTransferBookingDataModal"
                        :service="service"
                        :order="order"
                        :transfer-info="transferInfo"
                        :departure-point-prop="departurePoint"
                        :arrival-point-prop="arrivalPoint"
                        @save-booking-data="getDestinationPoint"
                    />
                </div>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base
                    ref="serviceInfoBase"
                    :service="service"
                    :service-type="
                        service.serviceDetails[0].transferType && service.serviceDetails[0].transferType !== 'UNKNOWN'
                            ? $t(`filters_transferType.${service.serviceDetails[0].transferType}`)
                            : null
                    "
                    enable-custom-product-info
                    :info="info"
                    :image="mainImage"
                    @close-modal="closeModal"
                >
                    <template v-if="transferInfo" v-slot:serviceInfo>
                        <div v-if="serviceDetails.languages && serviceDetails.languages.length">
                            <strong>{{ $t('language') }}: {{ serviceDetails.languages.join(', ') }}</strong>
                        </div>
                        <div v-if="info.shortDescription" class="d-flex align-center">
                            {{ info.shortDescription }}
                        </div>
                        <div v-if="info.fullDescription" v-html="info.fullDescription" />
                        <div v-if="transferInfo.descriptions.length">
                            <div v-for="(desc, index) in transferInfo.descriptions" :key="index">
                                {{ desc }}
                            </div>
                        </div>
                        <div v-if="transferInfo.comments && transferInfo.comments.length">
                            <div v-for="(comm, index) in transferInfo.comments" :key="index">
                                {{ comm }}
                            </div>
                        </div>
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import OrderInfoBase from '~src/components/account/orderServices/orderInfoBase.src'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import FillTransferBookingDataModal from '@/components/account/modals/newOrderRequest/FillTransferBookingDataModal'
    import MapLocation from '@/components/info/MapLocation'
    import {authStore} from '@/utils/store-accessor'
    import TransferOperatedByLabel from '@/components/transfers/snippets/TransferOperatedByLabel.vue'

    @Component({
        components: {
            TransferOperatedByLabel,
            MapLocation,
            FillTransferBookingDataModal,
            TransferDriverLanguages,
            ServiceCard,
            OrderInfoBase,
            ServiceInfoBase,
        },
    })
    export default class TransferService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() isFirst

        transferInfo = null
        modal = false
        departurePoint = {destinationPointName: null}
        departurePointName = null
        arrivalPointName = null
        arrivalPoint = {destinationPointName: null}
        fillTransferBookingDataModal = false
        mapDepartureModal = false
        mapArrivalModal = false

        async mounted() {
            await this.getDestinationPoint()
        }

        showInfo() {
            if (this.$refs.serviceInfoBase) {
                this.$refs.serviceInfoBase.customProductInfoMode = false
            }

            this.modal = true
        }

        closeModal() {
            this.modal = false
        }

        async getDestinationPoint() {
            const destinationPoint = async destination => {
                const place = this.serviceDetails[`${destination}Place`],
                    id = this.serviceDetails[`${destination}CityId`]

                if (place.otherAirportCode || place.flightNumber) {
                    const [airport] = place.otherAirportCode
                        ? await this.$api.airports.get({iataCode: place.otherAirportCode, limit: 1})
                        : [{}]
                    if (destination === 'arrival') {
                        const flightNumber = place.flightNumber
                            ? `${this.$t('flight_number')}: ${place.flightNumber}`
                            : ''

                        this.arrivalPointName =
                            `${flightNumber ? flightNumber + ', ' : ''}` +
                            `${airport.name ? this.$t('to') + ' ' + airport.name + ', ' : ''} ` +
                            `${airport.cityName || ''} ${this.$options.filters.dateTimeFormat(place.dateTime)}`
                    }
                    if (destination === 'departure') {
                        const flightNumber = place.flightNumber
                            ? `${this.$t('flight_number')}: ${place.flightNumber}`
                            : ''

                        this.departurePointName =
                            `${flightNumber ? flightNumber + ', ' : ''}` +
                            `${airport.name ? this.$t('from') + ' ' + airport.name + ', ' : ''} ` +
                            `${airport.cityName || ''} ${this.$options.filters.dateTimeFormat(place.dateTime)}`
                    }
                }

                if (place.airportCode) {
                    const [airport] = await this.$api.airports.get({
                        iataCode: place.airportCode,
                        limit: 1,
                    })
                    const destinationPointName = `${airport.name ? airport.name + ', ' : ''} ${airport.cityName}`

                    return {...airport, destinationPointName}
                } else {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({
                        id,
                        limitCities: 1,
                    })
                    if (place.hotelName) {
                        const {
                            hotels: [hotel],
                        } = await this.$api.suggestHotels.get({
                            pattern: place.hotelName,
                            locationId: city.id,
                            limit: 1,
                        })

                        let addressLine
                        if (hotel && !hotel.address) {
                            const {
                                hotelDescriptionInfo: {address},
                            } = await this.$api.hotelInfo.get({hotelId: hotel.id})

                            addressLine = address?.addressLine
                        } else if (hotel?.address) addressLine = hotel.address

                        const destinationPointName = `<strong class="black--text">${
                            place.hotelName ? place.hotelName : ''
                        }</strong>
                            ${place.hotelName ? ', ' : ''} ${city.name}${
                            addressLine ? `<div class="text-caption">${addressLine}</div>` : ''
                        }`

                        return {...hotel, destinationPointName}
                    } else if (place.trainNumber) {
                        let pointName = city.name

                        if (destination === 'departure') {
                            const departureCityId = this.serviceDetails[`${destination}Place`].cityId
                            const {
                                cities: [departureCity],
                            } = await this.$api.locations.get({id: departureCityId, limitCities: 1})
                            const trainNumberText = `${this.$t('transfers.train_number')}: ${place.trainNumber}`
                            const cityText = `${this.$t('from')} ${departureCity.name}`
                            const dateTimeFormatted = this.$options.filters.dateTimeFormat(place.dateTime)

                            this.departurePointName = `${trainNumberText}, ${cityText}, ${dateTimeFormatted}`
                        }
                        if (destination === 'arrival') {
                            const arrivalCityId = this.serviceDetails[`${destination}Place`].cityId
                            const {
                                cities: [arrivalCity],
                            } = await this.$api.locations.get({id: arrivalCityId, limitCities: 1})
                            const trainNumberText = `${this.$t('transfers.train_number')}: ${place.trainNumber}`
                            const cityText = `${this.$t('to')} ${arrivalCity.name}`
                            const dateTimeFormatted = this.$options.filters.dateTimeFormat(place.dateTime)

                            this.arrivalPointName = `${trainNumberText}, ${cityText}, ${dateTimeFormatted}`
                        }

                        if (place.iataCode) {
                            const {
                                trainStations: [trainStation],
                            } = await this.$api.trainStations.get({
                                iataCode: place.iataCode,
                                limit: 1,
                                productType: 'TRANSFER',
                            })
                            const destinationPointName = `${pointName ? pointName + ', ' : ''} ${this.$t(
                                'transfers.train_station'
                            )}: ${trainStation.name})`

                            return {...trainStation, destinationPointName}
                        }
                        if (place.trainStationCode) {
                            const locationId = this.serviceDetails[`${destination}CityId`]
                            const productType = 'TRANSFER'
                            const trainStation = (await this.$api.trainStations.get({locationId, productType})).find(
                                station => station.code === place.trainStationCode
                            )

                            pointName = `${city.name}, ${trainStation.name}`
                        }

                        return {...place, destinationPointName: pointName}
                    } else if (place.shipNumber || place.portName) {
                        let pointName = city.name

                        if (place.portName) {
                            pointName = `${this.$t('port')}: ${place.portName}, ${city.name}`
                        }
                        if (destination === 'departure' && place.shipNumber) {
                            const departureCityId = this.serviceDetails[`${destination}Place`].cityId
                            const {
                                cities: [departureCity],
                            } = await this.$api.locations.get({id: departureCityId, limitCities: 1})
                            const shipNumberText = `${this.$t('cruises.ship_number')}: ${place.shipNumber}`
                            const cityText = `${this.$t('from')} ${departureCity.name}`
                            const dateTimeFormatted = this.$options.filters.dateTimeFormat(place.dateTime)

                            this.departurePointName = `${shipNumberText}, ${cityText}, ${dateTimeFormatted}`
                        }
                        if (destination === 'arrival' && place.shipNumber) {
                            const arrivalCityId = this.serviceDetails[`${destination}Place`].cityId
                            const {
                                cities: [arrivalCity],
                            } = await this.$api.locations.get({id: arrivalCityId, limitCities: 1})
                            const shipNumberText = `${this.$t('cruises.ship_number')}: ${place.shipNumber}`
                            const cityText = `${this.$t('to')} ${arrivalCity.name}`
                            const dateTimeFormatted = this.$options.filters.dateTimeFormat(place.dateTime)

                            this.arrivalPointName = `${shipNumberText}, ${cityText}, ${dateTimeFormatted}`
                        }

                        return {...place, destinationPointName: pointName}
                    } else if (place.trainStationCode) {
                        const locationId = this.serviceDetails[`${destination}CityId`]
                        const productType = 'TRANSFER'
                        const trainStation = (await this.$api.trainStations.get({locationId, productType})).find(
                            station => station.code === place.trainStationCode
                        )
                        const pointName = `${trainStation.name}`

                        return {...place, destinationPointName: `${pointName} ${city.name}`}
                    } else if (place.name) {
                        return {
                            ...place,
                            destinationPointName:
                                place.name + `${!place.latitude && !place.longitude ? ', ' + city.name : ''}`,
                        }
                    } else {
                        return {
                            ...place,
                            destinationPointName: `${place.address ? place.address + ',' : ''} ${
                                place.postalCode ? place.postalCode + ',' : ''
                            } ${city.name}`,
                        }
                    }
                }
            }
            try {
                this.transferInfo = await this.$api.transferInfo.get({
                    processId: this.service.processId.replace(/^.{6}/, ''),
                })
                const [arrivalPoint, departurePoint] = await Promise.all([
                    destinationPoint('arrival'),
                    destinationPoint('departure'),
                ])
                this.arrivalPoint = arrivalPoint
                this.departurePoint = departurePoint
                // eslint-disable-next-line no-empty
            } catch (e) {
                console.error(e)
            }
        }

        getIcon(place) {
            switch (this.getPointType(place)) {
                case 'airport':
                    return 'mdi-airport'
                case 'hotel':
                    return 'mdi-hotel'
                case 'train':
                    return 'mdi-train'
                case 'port':
                    return 'mdi-ferry'
                case 'manual':
                    return 'mdi-map-marker'
                default:
                    return 'mdi-city'
            }
        }

        getPointType(place) {
            const pointType = place === 'arrivalPlace' ? 'arrival' : 'departure'
            const transferPoint = this.transferInfo?.transferPoints?.find(point => point.pointType === pointType)

            if (transferPoint?.type === 'MANUAL') {
                return 'manual'
            }
            if (this.serviceDetails[place].airportCode) {
                return 'airport'
            } else if (this.serviceDetails[place].hotelCode) {
                return 'hotel'
            } else if (this.serviceDetails[place].trainNumber || this.serviceDetails[place].trainStationCode) {
                return 'train'
            } else if (this.serviceDetails[place].shipNumber || this.serviceDetails[place].portName) {
                return 'port'
            }
            return 'address'
        }

        get langCodes() {
            return this.serviceDetails.languages
        }

        get serviceCheckIn() {
            const pickUpTime = this.serviceDetails.pickupTime
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            if (pickUpTime) {
                return `<span> ${this.$t('transfers.pick_up')}: ${date}</span> <small>${pickUpTime}</small>`
            }
            return `<span> ${this.$t('transfers.pick_up')}: ${date}</span>`
        }

        get serviceCheckOut() {
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `<span>${this.$t('transfers.drop_off')}: ${date}</span>`
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get departureIcon() {
            return this.getIcon('departurePlace')
        }

        get arrivalIcon() {
            return this.getIcon('arrivalPlace')
        }

        get mapPointDeparture() {
            return this.serviceDetails.departurePlace?.latitude && this.serviceDetails.departurePlace?.longitude
                ? this.serviceDetails.departurePlace
                : null
        }

        get mapPointArrival() {
            return this.serviceDetails.arrivalPlace?.latitude && this.serviceDetails.arrivalPlace?.longitude
                ? this.serviceDetails.arrivalPlace
                : null
        }

        get pickUpSign() {
            return this.service.travelers[0].pickUpSign
        }

        get mainImage() {
            if (this.transferInfo && this.transferInfo.images && this.transferInfo.images.length) {
                let image = this.transferInfo.images.find(el => el.mainImage)
                if (!image) {
                    image = this.transferInfo.images[0]
                }
                return image.url
            }
            return null
        }

        get info() {
            return {...this.transferInfo, ...{mainImage: this.mainImage}}
        }

        get transferDuration() {
            const hours = Math.floor(this.info.duration / 60)
            const minutes = this.info.duration % 60
            if (minutes) return `${hours} ` + this.$tc('hour', hours) + ` ${minutes} ` + this.$tc('minute', minutes)

            return `${hours} ` + this.$tc('hour', hours)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    .thumb {
        max-width: 350px;
    }

    .col-title {
        white-space: nowrap;
        max-width: fit-content;
    }

    .info-text {
        color: black;
        font-size: 1rem;
        font-style: italic;
    }

    .payment-status {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        min-width: fit-content;
        max-width: fit-content;
    }

    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
