<template>
    <span v-if="person" class="person-label text-truncate" v-text="`${person.firstName || person.lastName}`" />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class PersonLabel extends Vue {
        @Prop({default: () => ({})}) person
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .text-truncate {
        max-width: 300px;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
            max-width: 160px;
        }
    }
</style>
