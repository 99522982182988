<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col cols="12" md="2">
                <v-select
                    v-model="prefix"
                    :items="['Mr', 'Ms', 'Mrs']"
                    :label="`${$t('salutation')}*`"
                    :rules="[requiredRule('salutation')]"
                    required
                />
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="firstName"
                    :placeholder="`${$t('first_name')}*`"
                    :rules="validationRules('first_name', [latinRule, requiredRule])"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="lastName"
                    :placeholder="`${$t('last_name')}*`"
                    :rules="validationRules('last_name', [latinRule, requiredRule])"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <phone-field v-model="contactPhone" :placeholder="$t('phone')" />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="email"
                    :placeholder="`${$t('email')}*`"
                    type="email"
                    :rules="validationRules('email', [emailRule, requiredRule])"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-if="person.memberLevel === 'REGISTERED'"
                    v-model="password"
                    :placeholder="`${$t('password')}*`"
                    type="password"
                    autocomplete="new-password"
                    :rules="[requiredRule('password')]"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-if="person.memberLevel === 'REGISTERED'"
                    v-model="passwordToMatch"
                    :placeholder="`${$t('password_confirm')}*`"
                    type="password"
                    autocomplete="new-password"
                    :rules="passwordConfirmationRules"
                    required
                />
            </v-col>
        </v-row>
        <v-btn
            v-if="hideBtn === false"
            :loading="isLoading"
            :disabled="isLoading || !valid"
            color="primary"
            block
            @click="register"
        >
            {{ $t('register') }}
        </v-btn>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {registeredPerson} from '@/utils/auth-blank-states'
    import PhoneField from '@/components/snippets/forms/PhoneField.vue'

    const propHelper = {
        get: prop => authStore.registeredPerson[prop],
        set: (prop, val) => authStore.SET_REGISTERED_PERSON_PROP({prop, val}),
    }

    @Component({
        components: {PhoneField},
    })
    export default class RegistrationForm extends mixins(FormValidationRulesMixin) {
        @Prop({default: false, type: Boolean}) hideBtn

        valid = false
        passwordToMatch = null

        get isLoading() {
            return runtimeStore.authActive
        }

        get person() {
            return authStore.registeredPerson
        }

        async register() {
            try {
                await authStore.register(authStore.registeredPerson)
                this.$emit('registered')
            } catch (e) {
                if (e.errors.length && e.errors[0].token) {
                    this.$toast.error(this.$t(`api_error_token.${e.errors[0].token.replace(/\./g, '_')}`))
                } else {
                    this.$toast.error(this.$t('error_message.registration_error'))
                }
            }
        }

        get passwordConfirmationRules() {
            return [
                () =>
                    this.password === this.passwordToMatch ||
                    `${this.$t('password_confirm')} ${this.$t('validation.match')}`,
                this.requiredRule('password_confirm'),
            ]
        }

        mounted() {
            this.$refs.form.resetValidation()
        }

        get prefix() {
            return propHelper.get('prefix')
        }

        set prefix(val) {
            propHelper.set('prefix', val)
        }

        get firstName() {
            return propHelper.get('firstName')
        }

        set firstName(val) {
            propHelper.set('firstName', val)
        }

        get lastName() {
            return propHelper.get('lastName')
        }

        set lastName(val) {
            propHelper.set('lastName', val)
        }

        get contactPhone() {
            return propHelper.get('contactPhone')
        }

        set contactPhone(val) {
            propHelper.set('contactPhone', val)
        }

        get email() {
            return propHelper.get('email')
        }

        set email(val) {
            propHelper.set('email', val)
        }

        get password() {
            return propHelper.get('password')
        }

        set password(val) {
            this.passwordToMatch = null
            propHelper.set('password', val)
        }

        beforeDestroy() {
            authStore.SET_REGISTERED_PERSON(registeredPerson())
        }
    }
</script>
