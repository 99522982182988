<template>
    <v-dialog v-model="showDetailsMobileDialog">
        <v-card>
            <v-card-title>
                <v-spacer />
                <v-btn icon @click="showDetailsMobileDialog = false">
                    <v-icon class="me-n4">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-simple-table class="d-flex align-center">
                <thead>
                    <tr>
                        <th v-if="detailsMobileDialogItem.accommodation" class="text-left">Hotel</th>
                        <th class="text-left">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-if="detailsMobileDialogItem.accommodation">
                            <div class="d-flex flex-column py-2 align-start">
                                <div class="d-flex font-weight-medium justify-end justify-md-start">
                                    <v-icon small class="me-1 ms-n1 d-inline-block">{{ 'mdi-office-building' }}</v-icon>
                                    <span v-text="detailsMobileDialogItem.accommodation.hotelName" />
                                </div>
                                <div>
                                    {{
                                        `${detailsMobileDialogItem.accommodation.counrtyName}, ${detailsMobileDialogItem.accommodation.cityName}`
                                    }}
                                </div>
                                <div class="text--secondary">
                                    {{
                                        `${getDateFormatted(
                                            detailsMobileDialogItem.accommodation.startDateTime
                                        )} - ${getDateFormatted(detailsMobileDialogItem.accommodation.endDateTime)}`
                                    }}
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="ml-auto">{{ detailsMobileDialogItem.pricePrintable }}</span>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class GroupDetailsMobileDialog extends Vue {
        @Prop() detailsMobileDialogItem
        @VModel() showDetailsMobileDialog

        getDateFormatted(dateStr) {
            return this.$dateFns.format(new Date(dateStr), 'dd MMM yyyy')
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-data-table__wrapper {
            width: 100%;
        }
    }
</style>
