<template>
    <v-navigation-drawer permanent expand-on-hover absolute>
        <v-list nav dense>
            <v-list-item :to="{name: 'emailTemplates'}">
                <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('admin.email_templates') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'documentTemplates'}">
                <v-list-item-icon>
                    <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('admin.document_templates') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'

    @Component
    export default class AdminNavigation extends Vue {}
</script>
