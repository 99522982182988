<template>
    <v-form ref="form" v-model="valid" :disabled="disabled">
        <div>
            <span class="text-h6 text-capitalize">
                {{ `${$t(`transfers.${pointType === 'departure' ? 'from' : 'to'}`)}: ` }}
            </span>
            <v-icon>mdi-office-building</v-icon>
            <span class="text-subtitle-2">
                {{ locationName }}
            </span>
        </div>
        <v-row>
            <v-col v-if="!isProductFieldDisabled('POINT_HOTEL_NAME')" cols="12" md="5">
                <hotel-autocomplete
                    v-model="hotel"
                    :city-id="cityId"
                    :show-icon="false"
                    :base-label="productFieldLabel('select_hotel', 'POINT_HOTEL_NAME')"
                    :rules="hotelRules"
                    show-details
                    @change="selectHotel"
                />
            </v-col>
            <v-col
                v-if="!isProductFieldDisabled('POINT_HOTEL_NAME') && !isProductFieldDisabled('POINT_ADDRESS')"
                cols="12"
                md="1"
            >
                <div :style="{'line-height': isMobile ? '1rem' : '70px'}" :class="{'text-center': !isMobile}">
                    {{ $t('or') }}
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col v-if="!isProductFieldDisabled('POINT_ADDRESS')" cols="12" md="8">
                        <v-text-field
                            v-model="address"
                            :placeholder="$t('address')"
                            :rules="addressRules"
                            :label="productFieldLabel('type_address_manually', 'POINT_ADDRESS')"
                        />
                    </v-col>
                    <v-col v-if="!isProductFieldDisabled('POINT_POSTAL_CODE')" cols="12" md="4">
                        <v-text-field
                            v-model="postalCode"
                            :label="productFieldLabel('transfers.postal_code', 'POINT_POSTAL_CODE')"
                            :rules="postalCodeRules"
                            width="200"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync} from 'nuxt-property-decorator'
    import {transfersRuntimeStore} from '@/utils/store-accessor'
    import HotelAutocomplete from '@/components/search/forms/HotelAutocomplete'
    import TransferFormMixin from '@/components/transfers/mixins/TransferFormMixin'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {HotelAutocomplete},
    })
    export default class TransferAddressForm extends mixins(
        TransferFormMixin,
        ProductBookingFieldsMixin,
        FormValidationRulesMixin
    ) {
        @Prop({required: true}) pointType
        @Prop() product
        @Prop({default: false, type: Boolean}) disabled
        @PropSync('_valid', {default: false}) valid

        hotel = null

        async mounted() {
            await this.initData()
        }

        //TODO Need investigate what there hell
        async initData() {
            const transferPoint = this.request[this.pointType]
            if (transferPoint?.type === 'hotel') {
                if (transferPoint.name) {
                    this.hotel = transferPoint
                } else if (transferPoint.hotelCode && transferPoint.hotelName) {
                    //TODO It is fast hotfix - need move to other place
                    try {
                        const {
                            hotels: [hotel],
                        } = await this.$api.suggestHotels.get({
                            pattern: transferPoint.hotelName,
                            contentProviderId: this.contentProviderId,
                            limit: 1,
                        })
                        const location = hotel.location
                        delete hotel.location
                        Object.assign(hotel, location, {parentName: location.countryName, type: 'hotel'})
                        this.hotel = hotel
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }
            }
            if (this.point?.hotelCode && this.point?.hotelName) {
                this.hotel = {
                    ...this.point,
                    type: 'hotel',
                    name: this.point.hotelName,
                }
            }
        }

        selectHotel(hotel) {
            const val = hotel
                ? {
                      type: 'hotel',
                      hotelName: hotel.name,
                      hotelCode: hotel.hotelCode,
                  }
                : {
                      type: 'address',
                      address: this.address,
                      ...(this.postalCode && {postalCode: this.postalCode}),
                  }
            this.change({
                prop: this.pointType,
                val,
            })
        }

        get addressRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_ADDRESS') && !this.hotel) {
                rules.push(this.requiredRule('type_address_manually', 'POINT_ADDRESS'))
            }
            return rules
        }

        get hotelRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_HOTEL_NAME') && !this.address) {
                rules.push(this.requiredRule('select_hotel', 'POINT_HOTEL_NAME'))
            }
            return rules
        }

        get postalCodeRules() {
            const rules = []
            if (this.isProductFieldMandatory('POINT_POSTAL_CODE')) {
                rules.push(this.requiredRule('transfers.postal_code', 'POINT_POSTAL_CODE'))
            }
            return rules
        }

        get address() {
            return this.getProp('address')
        }

        set address(value) {
            this.hotel = null
            this.setProp('address', value)
            this.selectHotel()
        }

        get postalCode() {
            return this.getProp('postalCode')
        }

        set postalCode(value) {
            this.hotel = null
            this.setProp('postalCode', value)
            this.selectHotel()
        }

        get ownProduct() {
            return transfersRuntimeStore.ownProduct(this.product)
        }

        get cityId() {
            return this.point.type === 'city' ? this.point.id : this.point.cityId
        }

        get locationName() {
            return `${this.point.name}${this.point.countryName ? ', ' + this.point.countryName : ''}`
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
