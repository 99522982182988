<template>
    <v-form ref="form" v-model="valid" data-cy="b2b-authorization-form" @keyup.native.enter="auth">
        <v-text-field
            v-model="companyCodeOrAlias"
            :placeholder="$t('company_code_or_alias')"
            :rules="[v => !!v || `${$t('company_code_or_alias')} ${$t('validation.required')}`]"
            required
            outlined
            dense
            background-color="white"
            data-cy="authorization-code-input"
            name="organization"
            autocomplite="organization"
        />
        <v-text-field
            v-model="login"
            :placeholder="$t('login')"
            :rules="[v => !!v || `${$t('login')} ${$t('validation.required')}`]"
            required
            outlined
            dense
            background-color="white"
            data-cy="authorization-login-input"
            name="username"
            autocomplite="username"
        />
        <v-text-field
            v-model="password"
            :placeholder="$t('password')"
            type="password"
            :rules="[v => !!v || `${$t('password')} ${$t('validation.required')}`]"
            required
            outlined
            dense
            background-color="white"
            data-cy="authorization-password-input"
            name="current-password"
            autocomplite="current-password"
        />
        <div class="d-flex align-center">
            <v-btn
                :loading="isLoading"
                :disabled="isLoading || !valid"
                dark
                color="primary"
                class="mr-2"
                data-cy="authorization-submit-button"
                @click="auth"
            >
                {{ $t('sign_in') }}
            </v-btn>
            <div class="d-flex">
                <a href="#" class="white--text" @click.prevent="$emit('switch-forgot-password')">
                    {{ $t('forgot_your_password') }}?
                </a>
                <nuxt-link
                    v-if="!$config.account.disableB2BRegistration"
                    no-prefetch
                    :to="'b2b-register'"
                    class="ml-5 white--text"
                >
                    {{ $t('register') }}
                </nuxt-link>
            </div>
        </div>
    </v-form>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {authStore, runtimeStore} from '@/store'

    @Component
    export default class B2bAuthorizationForm extends Vue {
        companyCodeOrAlias = null
        login = null
        password = null
        valid = false

        async auth() {
            if (!this.valid) return

            try {
                await authStore.authB2B({
                    companyCodeOrAlias: this.companyCodeOrAlias,
                    login: this.login,
                    password: this.password,
                })
                this.$emit('auth-success', authStore.companyType)
            } catch (e) {
                if (e.status === 401) {
                    this.$toast.error(this.$t('incorrect_credentials'))
                }
            }
        }

        get isLoading() {
            return runtimeStore.authActive
        }

        mounted() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        }
    }
</script>
