<template>
    <v-data-table
        :headers="headers"
        :items="isLoading ? [] : services"
        :loading="isLoading"
        :mobile-breakpoint="600"
        disable-sort
        hide-default-footer
        class="elevation-0"
    >
        <template v-slot:item.status="{item}">
            <div class="d-flex align-center">
                <span :class="item.statusColor">{{ item.status }}</span>
            </div>
        </template>
        <template v-slot:item.title="{item}">
            <div v-if="!item.isEmptyOrder" class="d-flex flex-column">
                <span class="d-flex justify-end justify-md-start">
                    <v-icon small class="mr-1">{{ getServiceTypeIcon(item.serviceType) }}</v-icon>
                    <span v-html="item.pureTitle" />
                </span>
                <div v-html="item.title + item.location" />
            </div>
        </template>
        <template v-slot:item.date="{item}">
            <div class="text-no-wrap" v-html="item.date" />
        </template>
        <template v-slot:item.travellers="{item}">
            <span v-html="item.travellers" />
        </template>
        <template v-slot:item.supplierPrice="{item}">
            <orders-supplier-price-label v-if="!item.isEmptyOrder && item.showAllServicesPrice" :item="item" />
        </template>
        <template v-slot:item.price="{item}">
            <orders-price-label :item="item" />
        </template>
    </v-data-table>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import OrdersPriceLabel from '~src/components/account/snippets/ordersPriceLabel.src'
    import OrdersSupplierPriceLabel from '~src/components/account/snippets/ordersSupplierPriceLabel.src'
    import VoucherMixin from '@/components/account/mixins/VoucherMixin'

    @Component({
        components: {
            OrdersPriceLabel,
            OrdersSupplierPriceLabel,
        },
    })
    export default class SchedulerReservationPreview extends mixins(VoucherMixin) {
        @Prop() tableColumns
        @Prop() selectedOrderId

        order = null
        isLoading = false

        async mounted() {
            await this.loadOrder()
        }

        async loadOrder() {
            try {
                this.isLoading = true
                this.order = await runtimeStore.loadOrder({
                    orderType: authStore.orderType,
                    orderId: this.selectedOrderId,
                })
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        get headers() {
            return this.tableColumns
        }

        get services() {
            const orderServices = []
            const order = this.order
            if (!this.order) return []

            const travellersLabel = service => {
                if (!service || !service.travelers || !service.travelers.length) return '-'
                let travellers = `${service.travelers[0].prefix || ''} ${
                    service.travelers[0].name[0].firstName || ''
                } ${service.travelers[0].name[0].lastName || ''}`
                if (service.travelers.length > 1) {
                    travellers += ` (+${service.travelers.length - 1})`
                }
                return travellers
            }
            let sortedServices = this.getSortedServices(order.services)

            if (!order.services?.length) {
                orderServices.push({
                    orderId: order.orderId,
                    statusColor: order.status
                        ? runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(order.status))
                        : '',
                    isEmptyOrder: true,
                })
            }

            if (order.orderPackage) {
                orderServices.push({
                    orderId: order.orderId,
                    clientCompanyName: order.clientCompanyName,
                    pureTitle: `<strong>${order.orderPackage.packageName}</strong><br>`,
                    title: '',
                    serviceType: order.orderPackage.packageType,
                    location: '',
                    date: this.isMobile
                        ? `${this.$options.filters.dateShortFormat(
                              order.orderPackage.startDateTime
                          )} - ${this.$options.filters.dateShortFormat(order.orderPackage.endDateTime)}`
                        : `${this.$options.filters.dateShortFormat(
                              order.orderPackage.startDateTime
                          )}<br>${this.$options.filters.dateShortFormat(order.orderPackage.endDateTime)}`,
                    travellers: travellersLabel(order.services[0]),
                    status: order.orderPackage.status
                        ? this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(order.orderPackage.status)}`)
                        : '',
                    statusColor: order.orderPackage.status
                        ? runtimeStore.orderServiceStatusColor(
                              runtimeStore.orderServiceStatus(order.orderPackage.status)
                          )
                        : '',
                    supplierCompanyName: order.orderPackage.supplierCompanyName,
                    supplier: {
                        paymentStatus: order.orderPackage.supplierPaymentStatus,
                        price: order.orderPackage.supplierPrice,
                    },
                    customer: {
                        paymentStatus: order.orderPackage.customerPaymentStatus,
                        price: order.orderPackage.clientPrice,
                    },
                    violation: null,
                    corporatePolicies: [],
                    showAllServicesPrice:
                        this.isTO1 || order.clientPriceType === 'FOR_ALL_SERVICES' || !order.clientPriceType,
                })

                sortedServices = sortedServices.filter(s => !s.serviceContainerId)
            }

            sortedServices.forEach(service => {
                let location = '',
                    pureTitle = `<strong>${service.serviceName}</strong>`,
                    title = '',
                    date = this.$options.filters.dateShortFormat(service.startDateTime),
                    countryName,
                    cityName,
                    travelersMealType

                switch (service.serviceType) {
                    case 'ACCOMMODATION':
                        travelersMealType = [
                            ...new Set(
                                service.travelers.reduce((mealTypes, traveler) => {
                                    if (traveler.mealType) {
                                        mealTypes.push(traveler.mealType)
                                    }
                                    return mealTypes
                                }, [])
                            ),
                        ].join(', ')
                        service.serviceDetailsContainer.accommodation.rooms.forEach(room => {
                            title += `${room.roomTypeName}, ${travelersMealType || room.mealTypeName}<br>`
                        })
                        countryName = service.serviceDetailsContainer.accommodation.countryName
                            ? service.serviceDetailsContainer.accommodation.countryName
                            : ''
                        cityName = service.serviceDetailsContainer.accommodation.cityName
                            ? service.serviceDetailsContainer.accommodation.cityName
                            : ''
                        location = []
                        if (countryName) {
                            location.push(countryName)
                        }
                        if (cityName) {
                            location.push(cityName)
                        }
                        location = location.join(', ')
                        date +=
                            `${this.isMobile ? ' - ' : '<br>'}` +
                            this.$options.filters.dateShortFormat(service.endDateTime)
                        break
                    case 'FLIGHT':
                        location = service.serviceDetailsContainer.flight.flightName
                        break
                    case 'ACTIVITY':
                        location = service.serviceDetailsContainer.activity.location
                        break
                    case 'TRANSFER':
                        break
                    case 'CARRENT':
                        date +=
                            `${this.isMobile ? ' - ' : '<br>'}` +
                            this.$options.filters.dateShortFormat(service.endDateTime)
                        break
                }
                const orderService = {
                    orderId: order.orderId,
                    clientCompanyName: order.clientCompanyName,
                    pureTitle,
                    title,
                    serviceType: service.serviceType,
                    location,
                    date,
                    travellers: travellersLabel(service),
                    //TODO [API] Fix statuses
                    status: service.status
                        ? this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(service.status)}`)
                        : '',
                    statusColor: service.status
                        ? runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(service.status))
                        : '',
                    statusOriginal: service.status,
                    supplierCompanyName: service.supplierCompanyName,
                    supplier: {
                        paymentStatus: service.supplierPaymentStatus,
                        cardGuarantee: service.serviceDetailsContainer.accommodation
                            ? service.serviceDetailsContainer.accommodation.cardGuarantee
                            : false,
                        price: service.salesTerms.find(salesTerm => salesTerm.type === 'SUPPLIER').price,
                    },
                    customer: {
                        paymentStatus: service.customerPaymentStatus,
                        price: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price,
                    },
                    violation: service.violation,
                    corporatePolicies: service.corporatePolicies || [],
                    processId: service.processId,
                    serviceName: service.serviceName,
                    showAllServicesPrice:
                        this.isTO1 || order.clientPriceType === 'FOR_ALL_SERVICES' || !order.clientPriceType,
                }

                orderServices.push(orderService)
            })

            return orderServices
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }
    }
</script>
