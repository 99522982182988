<template>
    <v-dialog
        v-model="value"
        :transition="transition"
        :content-class="contentClass"
        :fullscreen="fullscreen"
        max-width="500"
    >
        <v-card height="100%">
            <slot>
                <v-card-text class="pt-6">
                    <slot name="card-text" />
                </v-card-text>
            </slot>
            <v-icon class="v-card-close" @click="value = false">mdi-close</v-icon>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, ModelSync, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class CustomMobileModal extends Vue {
        @ModelSync('showSettingsModal', 'change', {type: Boolean}) value
        @Prop({default: () => 'dialog-bottom-transition', type: String}) transition
        @Prop({default: () => 'modal-content', type: String}) contentClass
        @Prop({default: () => false, type: Boolean}) fullscreen
    }
</script>

<style lang="scss" scoped>
    ::v-deep .v-dialog {
        background-color: white;
    }

    //@import '~vuetify/src/styles/styles.sass';
    //@media #{map-get($display-breakpoints, 'sm-and-down')} {
    //    ::v-deep {
    //        .v-dialog {
    //            margin: 0;
    //            //height: 70vh;
    //            height: fit-content;
    //        }
    //        .modal-content {
    //            position: relative;
    //            top: 15vh;
    //        }
    //    }
    //}
</style>
