<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import PrivateClientAutocomplete from '@/components/search/forms/PrivateClientAutocomplete'

    @Component
    export default class PrivateClientAndCompaniesAutocomplete extends PrivateClientAutocomplete {
        @Prop({default: () => [], type: Array}) relationship

        async searchPrivateClients(val) {
            const rqPClients = {
                pattern: val,
                limit: 10,
                active: true,
            }
            const companyRequests = this.relationship.map(relationship => ({
                name: val,
                limit: 10,
                order: 'ALPHABET',
                ascending: true,
                active: true,
                ...(relationship && {relationship}),
            }))
            const [{persons}, ...rs] = await Promise.all([
                this.$api.privateClients.get(rqPClients),
                ...companyRequests.map(rq => this.$api.companies.get(rq)),
            ])
            return [...persons, ...rs.map(({object}) => object).flat()]
        }

        get items() {
            return this.entries.map(entry => {
                const prefix = entry.prefix ? entry.prefix : ''
                const firstName = entry.firstName ? entry.firstName : ''
                const lastName = entry.lastName ? entry.lastName : ''
                const companyName = entry.name
                let label = companyName
                    ? companyName
                    : (prefix ? `${prefix}. ` : '') +
                      (firstName ? `${firstName} ` : '') +
                      (lastName ? `${lastName}` : '')
                return Object.assign({}, entry, {label})
            })
        }
    }
</script>
