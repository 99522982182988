<template>
    <div class="wrapper">
        <div class="ck-row">
            <div class="document-editor__toolbar" />
        </div>
        <div class="ck-row row-editor">
            <div class="editor-container">
                <div ref="editor" class="editor" v-html="html" />
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import '@/assets/ckeditor.css'
    import '@/assets/documents/ck-main.css'
    import '~src/assets/documents/ck-custom.css'

    function loadScript(src) {
        return new Promise(function (resolve, reject) {
            const s = document.createElement('script')
            s.src = src
            s.onload = resolve
            s.onerror = reject
            document.head.appendChild(s)
        })
    }

    @Component
    export default class HtmlEditor extends Vue {
        @Prop() html

        editor = null

        @Emit()
        loaded(editor) {
            return editor
        }

        async mounted() {
            if (!window.CKEDITOR_VERSION) {
                await loadScript('/ckeditor5/build/ckeditor.js')
            }
            // eslint-disable-next-line no-undef
            DecoupledDocumentEditor.create(this.$refs.editor, {
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true,
                        },
                    ],
                },
            })
                .then(editor => {
                    this.editor = editor
                    this.loaded(editor)
                    // Set a custom container for the toolbar.
                    document.querySelector('.document-editor__toolbar').appendChild(editor.ui.view.toolbar.element)
                    document.querySelector('.ck-toolbar').classList.add('ck-reset_all')

                    /*editor.model.document.on('change:data', () => {
                        this.model = editor.getData()
                    })*/
                })
                .catch(error => {
                    console.error('Oops, something went wrong!')
                    console.error(
                        'Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:'
                    )
                    console.warn('Build id: n4rvqe5ro4cr-jnwuddhnohr')
                    console.error(error)
                })
        }

        beforeDestroy() {
            this.editor.destroy()
        }

        get ckContent() {
            return this.$refs.editor.innerHTML
        }
    }
</script>

<style scoped lang="scss">
    .wrapper {
        padding-top: 40px;
        overflow: hidden;
    }

    .document-editor__toolbar {
        position: fixed;
        width: 100%;
        z-index: 100;
        margin-top: -40px;
    }
</style>
