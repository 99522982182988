<template>
    <div>
        <template v-if="offer.services.length && icons.length">
            <v-icon v-for="(icon, index) in icons" :key="index" color="success" class="me-3" :title="$t(icon.title)">
                {{ icon.ico }}
            </v-icon>
        </template>
        <div v-else>
            <span>&nbsp;</span>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {popularServicesMapping} from '@/utils/hotels/hotel-transformers'

    //TODO Make mapping on api response transform for size optimization

    @Component
    export default class HotelServices extends Vue {
        @Prop({required: true}) offer

        get icons() {
            return Object.values(
                this.offer.services.reduce((icons, groupName) => {
                    const serviceWithCode = this.offer.otaCodeServices.find(e => e.name === groupName)
                    if (serviceWithCode && popularServicesMapping[serviceWithCode.otaCode]) {
                        icons[serviceWithCode.otaCode] = popularServicesMapping[serviceWithCode.otaCode]
                    }
                    return icons
                }, {})
            )
        }
    }
</script>
