<template>
    <div class="d-flex text-caption align-center">
        <span v-if="flagIconCode" class="fi" :class="[`fi-${flagIconCode}`, 'me-2']" />
        <v-icon v-else small left> mdi-translate </v-icon>
        <span
            :class="{'text-button': textButton, 'font-weight-bold': bold}"
            v-text="showCurrentLang ? $t('lang') : translate ? langISOTranslate : $t(`languages.${language}`)"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {capitalizeTheFirstLetterOfEachWord} from '@/utils/helpers'

    @Component
    export default class LanguageLabel extends Vue {
        @Prop({required: true}) language
        @Prop({default: false, type: Boolean}) showCurrentLang
        @Prop({default: false, type: Boolean}) bold
        @Prop({default: false, type: Boolean}) textButton
        @Prop({default: false, type: Boolean}) translate

        get flagIconCode() {
            const langCode = this.language.toLowerCase()

            switch (langCode) {
                case 'en':
                    return 'gb'
                case 'ar':
                    return 'sa'
                case 'kk':
                    return 'kz'
                case 'local':
                    return null
                default:
                    return langCode
            }
        }

        get langISOTranslate() {
            if (this.language === 'LOCAL') {
                return this.$t('languages.local')
            }

            const languageNames = new Intl.DisplayNames([this.$i18n.locale], {type: 'language'})

            return capitalizeTheFirstLetterOfEachWord(languageNames.of(this.language))
        }
    }
</script>
