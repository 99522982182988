var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tourists.length)?_c('div',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},_vm._l((_vm.tourists),function(tourist,touristIndex){return _c('div',{key:tourist.personId},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.ownProduct ? 6 : 12}},[_c('v-row',{staticClass:"align-end text-center"},[_c('v-col',{staticClass:"d-md-flex align-end",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"me-2 text-no-wrap"},[_c('v-icon',{attrs:{"color":"black","small":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(touristIndex + 1)+": ")])],1),_vm._v(" "),_c('v-select',{attrs:{"items":['Mr', 'Ms', 'Mrs'],"label":`${_vm.$t('salutation')}`,"rules":_vm.validationRules('salutation', [_vm.getRequiredRule(touristIndex, 'salutation')]),"hide-details":""},model:{value:(_vm.tourists[touristIndex].prefix),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex], "prefix", $$v)},expression:"tourists[touristIndex].prefix"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('first_name')}`,"rules":_vm.validationRules('firstName', [
                                        _vm.latinRule,
                                        _vm.getRequiredRule(touristIndex, 'firstName'),
                                    ]),"hide-details":""},model:{value:(_vm.tourists[touristIndex].firstName),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex], "firstName", $$v)},expression:"tourists[touristIndex].firstName"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('last_name')}`,"rules":_vm.validationRules('lastName', [
                                        _vm.latinRule,
                                        _vm.getRequiredRule(touristIndex, 'lastName'),
                                    ]),"hide-details":""},model:{value:(_vm.tourists[touristIndex].lastName),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex], "lastName", $$v)},expression:"tourists[touristIndex].lastName"}})],1),_vm._v(" "),(_vm.ownProduct)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('citizenship'),"items":_vm.countries,"item-text":"name","item-value":"id","hide-details":""},model:{value:(_vm.tourists[touristIndex].citizenshipId),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex], "citizenshipId", $$v)},expression:"tourists[touristIndex].citizenshipId"}})],1):_vm._e()],1)],1),_vm._v(" "),(_vm.ownProduct)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"align-end text-center"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('date-picker',{attrs:{"label":_vm.$t('birthdate'),"max":_vm.$dateFns.format(new Date()),"hide-details":""},model:{value:(_vm.tourists[touristIndex].birthdate),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex], "birthdate", $$v)},expression:"tourists[touristIndex].birthdate"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('document_number')}`,"hide-details":""},model:{value:(_vm.tourists[touristIndex].passport.number),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex].passport, "number", $$v)},expression:"tourists[touristIndex].passport.number"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('date-picker',{attrs:{"label":_vm.$t('expire_date'),"min":_vm.$dateFns.format(new Date()),"hide-details":""},model:{value:(_vm.tourists[touristIndex].passport.expiryDate),callback:function ($$v) {_vm.$set(_vm.tourists[touristIndex].passport, "expiryDate", $$v)},expression:"tourists[touristIndex].passport.expiryDate"}})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-divider',{staticClass:"mt-8 mb-4"})],1)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }