<template>
    <div class="d-flex flex-wrap">
        <v-icon v-if="mapping" small :title="$t(mapping.title)">
            {{ mapping.ico }}
        </v-icon>
        <v-chip v-else x-small class="mr-1 mt-1" v-text="groupName" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {popularServicesMapping} from '@/utils/hotels/hotel-transformers'

    //TODO Make mapping on api response transform for size optimization

    @Component
    export default class HotelServiceIcon extends Vue {
        @Prop() groupName
        @Prop() map

        get mapping() {
            const serviceWithCode = this.map.find(e => e.name === this.groupName)
            if (serviceWithCode) {
                return popularServicesMapping[serviceWithCode.otaCode]
            } else {
                return false
            }
        }
    }
</script>
