<template>
    <transfer-booking-page-layout
        v-if="loaded"
        :search-request="searchRequest"
        :offer="offer"
        :is-return-transfer="!!returnTransferOffer"
        :search-request-country-id="arrivalPoint.countryId"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <transfer-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:forms="{bookingKey, extraServices}">
            <v-card outlined class="mb-5">
                <v-card-title class="text-capitalize">
                    <v-icon left color="primary">mdi-car</v-icon>
                    {{ $t('productType.transfer') }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <transfer-booking-forms
                        :request="prepareBookRequest"
                        :departure-point="departurePoint"
                        :product="product"
                        :disabled="!!bookingKey"
                        :search-request="searchRequest"
                        :arrival-point="arrivalPoint"
                        :offer="offer"
                        :flight-service="flightService"
                        :transfer-info="info"
                        @change="setPrepareBookRequest"
                        @change-location="setPrepareBookLocation"
                    />
                </v-card-text>
                <extra-services-form
                    v-if="
                        productStore.bookingAdditionalOptions[0] &&
                        productStore.bookingAdditionalOptions[0].extraServices &&
                        productStore.bookingAdditionalOptions[0].extraServices.length
                    "
                    v-model="extraServices[0]"
                    :disabled="!!bookingKey"
                    :additional-options="productStore.bookingAdditionalOptions[0]"
                    class="extra-services"
                    @update="bookingKey = false"
                />
                <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
            </v-card>
            <v-card outlined class="mb-5">
                <v-card-title class="text-capitalize">
                    <v-icon left color="primary">mdi-car</v-icon>
                    {{ $t('transfer_return') }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-checkbox
                        v-model="returnTransferCheckbox"
                        :label="$t('add_transfer_return')"
                        @change="onReturnTransferCheckboxChange"
                    />
                    <transfer-booking-forms
                        v-if="returnTransferOffer"
                        :request="prepareBookReturnTransferRequest"
                        :departure-point="arrivalPoint"
                        :product="product"
                        :disabled="!!bookingKey"
                        :search-request="searchRequest"
                        :transfer-info="infoReturnTransfer"
                        :arrival-point="departurePoint"
                        :offer="offer"
                        :return-transfer-offer="returnTransferOffer"
                        @change="setPrepareBookReturnRequest"
                        @change-location="setPrepareBookReturnLocation"
                    />
                </v-card-text>
                <template v-if="returnTransferOffer">
                    <extra-services-form
                        v-if="
                            productStore.bookingAdditionalOptions[1] &&
                            productStore.bookingAdditionalOptions[1].extraServices &&
                            productStore.bookingAdditionalOptions[1].extraServices.length
                        "
                        v-model="extraServices[1]"
                        :disabled="!!bookingKey"
                        :additional-options="productStore.bookingAdditionalOptions[1]"
                        class="extra-services"
                        @update="bookingKey = false"
                    />
                    <transfer-return-booking-comments-form :disabled="!!bookingKey" />
                </template>
            </v-card>
            <transfer-return-dialog
                v-if="returnTransferCheckbox"
                v-model="isShowAddReturnTransferDialog"
                :order="order"
                :selected-transfer="offer"
                @on-search-request-inverted-change="setSearchRequestReturnTransfer"
                @on-return-transfer-change="setReturnTransferOffer"
                @on-info-changed="setReturnTransferInfo"
                @close="onReturnTransferDialogClose"
            />
        </template>
        <template v-slot:orderSummary="{bookingKey, basketKey, extraServices}">
            <transfer-order-summary
                :offers="[offer]"
                :info="info"
                :product="product"
                :search-request="searchRequest"
                :prebooked="!!bookingKey || !!basketKey"
                :return-transfer-offer="returnTransferOffer"
                :info-return-transfer="infoReturnTransfer"
                :search-request-return-transfer="searchRequestReturnTransfer"
                :extra-services="extraServices"
                :additional-options="productStore.bookingAdditionalOptions"
            >
                <template v-slot:summary>
                    <v-card flat tile class="my-2 pa-0">
                        <v-card-text class="pa-0">
                            <search-summary-content :search-request="searchRequest" show-date show-tourists />
                        </v-card-text>
                    </v-card>
                </template>
            </transfer-order-summary>
        </template>
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
            </template>
        </conditions-modal>
        <hotel-meal-type-info-modal />
        <hotel-room-info-modal />
        <additional-fees-modal />
        <transfer-info-modal />
        <activity-info-modal />
    </transfer-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import TransferOrderSummary from '~src/components/transfers/booking/transferOrderSummary.src'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import {
        authStore,
        persistentStore,
        runtimeStore,
        transfersRuntimeStore,
        transfersStore,
    } from '@/utils/store-accessor'
    import {appInstance} from '@/utils/app-accessor'
    import TransferBookingPageLayout from '~/components/transfers/booking/TransferBookingPageLayout'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import TransferReturnDialog from '@/pages/transfers/TransferReturnDialog'
    import TransferReturnBookingCommentsForm from '@/components/transfers/booking/forms/TransferReturnBookingCommentsForm'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import TransferBookingForms from '@/components/transfers/booking/forms/TransferBookingForms.vue'
    import ConditionsModal from '@/components/modals/ConditionsModal.vue'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo.vue'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo.vue'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo.vue'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal.vue'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal.vue'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal.vue'
    import AdditionalFeesModal from '@/components/modals/AdditionalFeesModal.vue'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal.vue'

    @Component({
        components: {
            ActivityInfoModal,
            AdditionalFeesModal,
            HotelRoomInfoModal,
            HotelMealTypeInfoModal,
            TransferInfoModal,
            ActivityConditionsModalInfo,
            HotelConditionsModalInfo,
            TransferConditionsModalInfo,
            ConditionsModal,
            TransferBookingForms,
            ExtraServicesForm,
            TransferReturnBookingCommentsForm,
            TransferReturnDialog,
            ExpiredOffersSnackbar,
            BookingCommentsForm,
            TransferBreadcrumbs,
            TransferOrderSummary,
            TransferBookingPageLayout,
            SearchSummaryContent,
        },
        layout: 'blank',
    })
    export default class TransferBookingPage extends mixins(TransferPageMixin, TransferStoreMixin) {
        loaded = false
        info = {
            images: [],
        }
        infoReturnTransfer = {
            images: [],
        }
        returnTransferCheckbox = false
        isShowAddReturnTransferDialog = false
        returnTransferOffer = null
        searchRequestReturnTransfer = null
        order = {}

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            const offerKey = this.$route.query.offerKey

            this.productStore.clearBookingAdditionalOptions()
            this.clearReturnTransfer()

            try {
                await Promise.all([
                    this.loadRuntimeData(),
                    runtimeStore.loadTouristBookingFields({offerKey: offerKey}),
                    runtimeStore.loadProductBookingFields({offerKey: this.$route.query.offerKey}),
                    this.productStore.loadBookingAdditionalOptions(offerKey),
                    (async () => {
                        this.info = await this.$api.transferInfo.get({offerKey})
                    })(),
                ])
            } catch (e) {
                this.$nuxt.error({statusCode: 404})
            }
            const setTransferLocation = (point, pointType) => {
                let val = {}
                switch (point.type) {
                    case 'airport':
                        val.type = 'airport'
                        break
                    case 'city':
                        val.type = this.product[
                            `allowedHotels${pointType.charAt(0).toUpperCase()}${pointType.slice(1)}`
                        ]
                            ? 'hotel'
                            : 'address'
                        break
                    case 'trainStation':
                        val.type = 'train'
                        break
                    case 'cruisePort':
                        val.type = 'port'
                        break
                    case 'hotel':
                        val.type = 'hotel'
                        break
                }
                if (this.productStore.prepareBookRequest[pointType]?.type !== val.type) {
                    this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({
                        prop: pointType,
                        val,
                    })
                }
            }
            setTransferLocation(this.departurePoint, 'departure')
            setTransferLocation(this.arrivalPoint, 'arrival')

            await this.loadOrder()

            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !transfersStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(transfersRuntimeStore.searchPageLink(this.searchRequest))
        }

        setReturnTransferOffer(offer) {
            this.returnTransferOffer = offer
        }

        setSearchRequestReturnTransfer(req) {
            this.searchRequestReturnTransfer = req
        }

        setReturnTransferInfo(info) {
            this.infoReturnTransfer = info
        }

        clearReturnTransfer() {
            this.setReturnTransferOffer(null)
            this.setSearchRequestReturnTransfer(null)
            this.setReturnTransferInfo({
                images: [],
            })

            this.productStore.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST({
                departure: {},
                arrival: {},
            })
        }

        onReturnTransferCheckboxChange() {
            if (this.returnTransferCheckbox) {
                this.isShowAddReturnTransferDialog = true

                const arrival = {...this.productStore.prepareBookRequest.arrival}
                const departure = {...this.productStore.prepareBookRequest.departure}

                if (arrival.flightNumber) {
                    arrival.flightNumber = null
                }

                if (departure.flightNumber) {
                    departure.flightNumber = null
                }

                this.productStore.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({
                    prop: 'departure',
                    val: arrival,
                })

                this.productStore.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({
                    prop: 'arrival',
                    val: departure,
                })
            } else {
                this.clearReturnTransfer()
            }
        }

        onReturnTransferDialogClose() {
            if (!this.returnTransferOffer) {
                this.returnTransferCheckbox = false
                this.clearReturnTransfer()
            }
        }

        setPrepareBookRequest({prop, val}) {
            transfersStore.SET_PREPARE_BOOK_REQUEST_PROP({prop, val})
        }

        setPrepareBookLocation({prop, val, pointType}) {
            transfersStore.SET_PREPARE_BOOK_LOCATION_PROP({prop, val, pointType})
        }

        setPrepareBookReturnRequest({prop, val}) {
            transfersStore.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({prop, val})
        }

        setPrepareBookReturnLocation({prop, val, pointType}) {
            transfersStore.SET_PREPARE_BOOK_RETURN_TRANSFER_LOCATION_PROP({prop, val, pointType})
        }

        async loadOrder() {
            if (persistentStore.selectedOrderId) {
                try {
                    const rq = {orderId: persistentStore.selectedOrderId, orderType: authStore.orderType}
                    const {
                        orders: [order],
                    } = await appInstance.$api.orders.get(rq)

                    if (order) this.order = order
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        get flightService() {
            return this.order.services?.find(
                service => service.serviceType === 'FLIGHT' || service.serviceType === 'OWNCHARTER'
            )
        }

        get departurePoint() {
            return transfersRuntimeStore.departurePoint
        }

        get arrivalPoint() {
            return transfersRuntimeStore.arrivalPoint
        }

        get product() {
            return this.basketItem.product
        }

        get basketItem() {
            return transfersStore.basketItem(this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }

        get prepareBookRequest() {
            return transfersStore.prepareBookRequest
        }

        get prepareBookReturnTransferRequest() {
            return transfersStore.prepareBookReturnTransferRequest
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .extra-services.theme--light.v-card {
        border-left: unset;
        border-right: unset;
    }
</style>
