<template>
    <vc-date-picker
        v-model="range"
        is-range
        :model-config="modelConfig"
        :locale="$i18n.locale"
        :min-date="disableMinStartDate ? null : minStartDate"
        :max-date="maxEndDate"
        :columns="$breakpoint.smAndDown ? 1 : 2"
        :rows="1"
        :popover="popover"
        :first-day-of-week="$config.firstDayOfWeek || undefined"
        @dayclick="dayclick"
        @popoverWillShow="popoverWillShow"
    >
        <template v-slot="{inputEvents}">
            <div v-on="inputEvents.start">
                <v-text-field
                    :class="textFieldClass"
                    :label="$t('supplier.dates_range')"
                    readonly
                    :single-line="singleLine"
                    :outlined="outlined"
                    filled
                    hide-details
                    :background-color="color || 'white'"
                    :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
                    :value="formattedDate"
                />
            </div>
        </template>
    </vc-date-picker>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import DateRangeM from '~/components/search/forms/DaterangeM'

    @Component
    export default class DateRange extends DateRangeM {
        @Prop({default: false}) disableMinStartDate
        @Prop({default: ''}) color
    }
</script>

<style scoped lang="scss">
    .date-range-input::v-deep {
        @media (min-width: 961px) and (max-width: 1635px) {
            input {
                font-size: 16px;
            }
        }
    }
</style>
