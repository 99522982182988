<template>
    <v-dialog v-model="modal" hide-overlay width="800" content-class="overflow--visible">
        <v-card>
            <v-card-title>
                {{ $t('sent_to_supplier_status') }}
            </v-card-title>
            <v-card-text class="pb-0">
                <span class="my-4">
                    {{ $t('select_status_to_apply') }}
                </span>
                <v-checkbox v-model="status" :label="$t('sent_to_supplier')" />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn depressed color="primary" :loading="loading" @click="send">
                    {{ $t('save') }}
                </v-btn>
                <v-btn depressed @click="modal = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class SentToSupplierModal extends Vue {
        @VModel() modal
        @Prop({required: true}) orderId
        @Prop({required: true}) processId
        @Prop() statusInitial

        loading = false
        status = null

        mounted() {
            this.status = this.statusInitial
        }

        async send() {
            try {
                this.loading = true

                await this.$api.orders.put(this.orderId, {
                    servicesInfo: [
                        {
                            processId: this.processId,
                            serviceSentToSupplier: this.status,
                        },
                    ],
                })
                await runtimeStore.refreshOrder()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
                this.modal = false
            }
        }
    }
</script>
