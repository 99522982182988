<template>
    <div>
        <breadcrumbs :company="company" class="mb-3" />
        <h2 v-if="company?.name">{{ company.name }}</h2>
        <v-tabs v-model="tab">
            <v-tab nuxt :to="link">
                {{ $t('information') }}
            </v-tab>
            <v-tab>
                {{ $t('users') }}
            </v-tab>
        </v-tabs>
        <v-btn
            nuxt
            :to="{name: 'companyPerson', params: {id: 0}, query: {company: $route.query.company}}"
            class="primary my-4"
        >
            {{ $t('new_user_profile') }}
        </v-btn>
        <persons-table />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import PersonsTable from '@/components/account/PersonsTable'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {
            Breadcrumbs,
            PersonsTable,
        },
        middleware: 'auth',
    })
    export default class CompanyPersonsPage extends Vue {
        tab = 1
        company = {}

        async asyncData({$api, params, query}) {
            if (params.id === 0) return {company: {contactPerson: {}, phones: []}}

            const id = params.id || query.company
            const rq = !id ? {relationship: 'OWN', shortResponse: false} : {id, shortResponse: false, active: false}
            try {
                const company = (await $api.companies.get(rq)).object[0]
                if (!company.contactPerson) {
                    company.contactPerson = {}
                }
                return {company}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get person() {
            return authStore.person
        }

        get link() {
            if (this.company.id !== this.person.companyId)
                return {name: 'company-client-profile', params: {id: this.company.id}}

            return {name: 'company-profile'}
        }
    }
</script>
