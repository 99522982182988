<template>
    <div v-if="selectedAddOns && selectedAddOns.length">
        <v-divider class="my-4" />
        <div class="font-weight-bold">
            {{ $t('add_ons') }}
        </div>
        <v-card
            v-for="(addOn, index) in selectedAddOns"
            :key="index"
            :outlined="$breakpoint.lgAndUp"
            elevation="0"
            class="pa-lg-2 mt-lg-2"
        >
            <component
                :is="productComponent(addOn)"
                v-if="productComponent(addOn)"
                :key="hash(addOn)"
                :product="addOn.product[0]"
                :offer="addOn.product[0]"
                :offers="addOn.product[0].offers"
                :room-offers="addOn.product[0].rooms"
                :info="addOnInfo(addOn)"
                :selected-add-ons="[]"
                :selected-meal-types="[]"
                :additional-options="additionalOptions(addOn)"
                :search-request="null"
                :free="addOn.free"
            >
                <template v-slot:summary="{groupedOffers}">
                    <v-card flat tile class="my-2 pa-0">
                        <v-card-text class="pa-0">
                            <search-summary-content
                                v-if="!groupedOffers || groupedOffers.length === 1"
                                :search-request="searchSummaryContentData(addOn)"
                                :show-date="!searchSummaryContentData(addOn).startDate"
                                :show-dates="!!searchSummaryContentData(addOn).startDate"
                            />
                        </v-card-text>
                    </v-card>
                </template>
                <template v-slot:removeBtn="{offerKey}">
                    <v-btn icon x-small class="remove-btn" @click="select(offerKey)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </component>
            <v-divider v-if="index < selectedAddOns.length - 1" class="my-5 d-lg-none add-ons-divider" />
        </v-card>
    </div>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent.vue'
    import {hash} from '@/utils/helpers'
    import {EventBus, SELECT_ADD_ON} from '@/utils/event-bus'

    @Component({
        components: {
            SearchSummaryContent,
            ActivityOrderSummary: () => import('@/components/activities/booking/ActivityOrderSummary.vue'),
            HotelOrderSummary: () => import('@/components/booking/HotelOrderSummary.vue'),
            TransferOrderSummary: () => import('@/components/transfers/booking/TransferOrderSummary.vue'),
        },
    })
    export default class AddOnsSelectedList extends Vue {
        @Prop({required: true, type: Array}) selectedAddOns

        info = []

        @Watch('selectedAddOns')
        async loadInfo() {
            for (const {
                productType,
                product: [{offers, info}],
            } of this.selectedAddOns) {
                for (const {offerKey} of offers) {
                    if (this.info.findIndex(info => info.offerKey === offerKey) !== -1) continue
                    if (productType === 'excursion') {
                        try {
                            const info = await this.$api.activityInfo.get({offerKey})
                            this.info.push({offerKey, ...info})
                        } catch (e) {
                            this.info.push({offerKey, ...info})
                        }
                    } else if (productType === 'transfer') {
                        try {
                            const info = await this.$api.transferInfo.get({offerKey})
                            this.info.push({offerKey, ...info})
                        } catch (e) {
                            this.info.push({offerKey, ...info})
                        }
                    }
                }
            }
        }

        select(offerKey) {
            EventBus.$emit(SELECT_ADD_ON, offerKey)
        }

        addOnInfo(addOn) {
            const info = this.info.find(info => info.offerKey === addOn.product[0].offers[0].offerKey)
            if (info) return info
            switch (addOn.productType) {
                case 'excursion':
                case 'transfer':
                    return addOn.product[0].info
                default:
                    return null
            }
        }

        productComponent(addOn) {
            switch (addOn.productType) {
                case 'excursion':
                    return 'ActivityOrderSummary'
                case 'accommodation':
                    return 'HotelOrderSummary'
                case 'transfer':
                    return 'TransferOrderSummary'
                default:
                    return null
            }
        }

        additionalOptions(addOn) {
            return ['accommodation', 'transfer'].includes(addOn.productType) ? [] : {}
        }

        searchSummaryContentData(addOn) {
            switch (addOn.productType) {
                case 'excursion':
                    return {
                        dateTime: `${addOn.product[0].offers[0].date} ${addOn.product[0].offers[0].time?.[0] || ''}`,
                    }
                case 'accommodation':
                    return {startDate: addOn.product[0].startDate, endDate: addOn.product[0].endDate}
                case 'transfer':
                    return {
                        dateTime: `${addOn.product[0].offers[0].date} ${addOn.product[0].offers[0].time?.[0] || ''}`,
                    }
                default:
                    return {}
            }
        }

        hash(addOn) {
            return hash(addOn)
        }
    }
</script>

<style scoped lang="scss">
    .remove-btn {
        position: absolute;
        right: 0;
        top: 0;
    }

    .add-ons-divider {
        border-width: medium 0 0 0;
    }
</style>
