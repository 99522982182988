<template>
    <v-card outlined hover @click="$emit('selected', hotel)">
        <v-img
            :src="hotel.img"
            :lazy-src="'/placeholder.png' | srcHost"
            eager
            :height="250"
            class="white--text align-end"
        >
            <v-card-title class="word-break">
                {{ hotel.title }}
            </v-card-title>
            <v-card-subtitle v-if="hotel.address" class="white--text">
                {{ hotel.address.cityName }}, {{ hotel.address.countryName }}
            </v-card-subtitle>
        </v-img>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class PopularHotel extends Vue {
        @Prop({required: true}) hotel
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-responsive__content {
        background: rgba(0, 0, 0, 0.4);
    }

    .word-break {
        word-break: break-word;
    }
</style>
