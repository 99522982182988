<template>
    <div>
        <div class="float-right text-right">
            <hotel-offer-price-block v-if="offer" :offer="offer" :search-request="searchRequest" />
            <hotel-tripadvisor-rating-label
                v-if="offer && offer.tripadvisorRating"
                :tripadvisor-rating="offer.tripadvisorRating"
            />
            <supplier-rating v-if="offer && offer.supplierRating" :rating-object="offer.supplierRating" class="my-2" />
        </div>
        <hotel-stars v-if="offer" :hotel="{category, ratings: offer.ratings}" />
        <div class="d-sm-flex align-sm-center">
            <h1 class="title" data-cy="hotel-name">
                {{ info.hotelName }}
                <v-chip v-if="type" small>
                    {{ type }}
                </v-chip>
            </h1>
            <hotel-own-label v-if="offer && offer.rooms" class="ms-1" :offer="offer" />
            <v-img
                v-if="offer && offer.recommended && showPreferred"
                :src="'/preferred.png' | srcHost"
                width="120px"
                class="preferred-thumb flex-grow-0 mx-1"
            />
        </div>
        <supplier-name-label
            v-if="isTO1 && offer"
            :supplier-name="isMultipleSuppliers ? $t('multiple_suppliers') : offer.supplierName"
        />
        <div v-if="address" @click="$emit('showMapTab')">
            <v-icon color="accent">mdi-map-marker</v-icon>
            {{ address }}
        </div>
        <template v-if="showContacts">
            <div v-if="info.email" class="d-sm-inline text-caption">
                <v-icon small>mdi-email</v-icon>
                <a :href="`mailto:${info.email}`">{{ info.email }}</a>
            </div>
            <div v-if="info.phone" class="d-sm-inline text-caption">
                <v-icon small>mdi-phone</v-icon>
                <a :href="`tel:${info.phone}`">{{ info.phone }}</a>
            </div>
            <div v-if="info.fax" class="d-sm-inline text-caption">
                <v-icon small>mdi-fax</v-icon>
                <a :href="`tel:${info.fax}`">{{ info.fax }}</a>
            </div>
            <div v-if="info.url" class="d-sm-inline nowrap text-caption">
                <v-icon small>mdi-home</v-icon>
                <a :href="info.url" target="_blank">{{ info.url }}</a>
            </div>
        </template>
        <div class="d-flex align-center">
            <template v-for="(classification, classificationIndex) in classifications">
                <v-chip v-if="classification.showOnUI" :key="classificationIndex" small class="ma-1">
                    {{ classification.supplierClassificationName || classification.otaName }}
                </v-chip>
            </template>
            <hotel-services v-if="offer" :offer="offer" :size="20" />
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, hotelsRuntimeStore, hotelsStore} from '~/store'
    import HotelServiceIcon from '@/components/snippets/HotelServiceIcon'
    import HotelTripadvisorRatingLabel from '~src/components/snippets/hotelTripadvisorRatingLabel.src'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import HotelOfferPriceBlock from '~src/components/search/offers/hotelOfferPriceBlock.src'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import HotelServices from '~src/components/hotels/snippets/hotelServices.src'
    import HotelOwnLabel from '~src/components/hotels/snippets/HotelOwnLabel'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import SupplierRating from '@/components/hotels/snippets/SupplierRating'

    @Component({
        components: {
            HotelServices,
            HotelStars,
            DiscountLabel,
            HotelTripadvisorRatingLabel,
            HotelServiceIcon,
            HotelOfferPriceBlock,
            HotelOwnLabel,
            SupplierNameLabel,
            SupplierRating,
        },
    })
    export default class HotelInfo extends Vue {
        @Prop({default: null}) offerProp
        @Prop({default: null}) infoProp
        @Prop({required: true}) searchRequest

        get offer() {
            return (
                this.offerProp ||
                (typeof hotelsStore.singleHotelSearchResponse.offers !== 'undefined' &&
                hotelsStore.singleHotelSearchResponse.offers.length
                    ? hotelsStore.singleHotelSearchResponse.offers[0]
                    : null)
            )
        }

        get info() {
            return this.infoProp || hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }

        get type() {
            return this.info.hotelInfoType
        }

        get category() {
            return hotelsStore.category(this.info.stdCategory)
        }

        get address() {
            return this.info.address ? this.info.address.addressLine : null
        }

        get classifications() {
            return this.info.classifications ? this.info.classifications.classification : []
        }

        get showContacts() {
            return this.$config.showContacts
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isMultipleSuppliers() {
            return this.offer.rooms?.some(room => room.hotelSupplierName !== this.offer.supplierName)
        }

        get showPreferred() {
            return false
        }
    }
</script>
