<template>
    <div>
        <div v-if="item.violation && item.customer.price">
            <span>
                {{ item.customer.price.amount | priceFormat(item.customer.price.currency) }}
            </span>
            <span>
                <corporate-policy-violation :policies="item.corporatePolicies" />
            </span>
        </div>
        <span v-else>
            {{ item.customer.price.amount | priceFormat(item.customer.price.currency) }}
            <v-tooltip
                v-if="
                    item.customerPenaltyStatus &&
                    item.customerPenaltiesTooltip &&
                    $config.account.showCustomerPenaltiesTooltip
                "
                top
            >
                <template v-slot:activator="{on, attrs}">
                    <v-btn small icon v-bind="attrs" v-on="on">
                        <v-icon :color="item.customerPenaltyStatus" small>
                            {{ item.customerPenaltyStatus === 'red' ? 'mdi-cloud-remove' : 'mdi-message-alert' }}
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ item.customerPenaltiesTooltip }}</span>
            </v-tooltip>
        </span>
        <template v-if="(isAgency || isTourOperator) && item.customer.price.commission && commissionAndNettoCheckbox">
            <div class="commission grey--text">
                {{ $t('commission') }}:
                {{ item.customer.price.commission | priceFormat(item.customer.price.currency) }}
            </div>
            <div class="commission grey--text">
                {{ $t('net_amount') }}:
                {{
                    (item.customer.price.amount - item.customer.price.commission)
                        | priceFormat(item.customer.price.currency)
                }}
            </div>
        </template>
        <payment-status-label
            v-if="showPaymentStatus && !$config.account.paymentScheduler"
            :status="item.customer.paymentStatus"
            class="text-no-wrap"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'

    @Component({
        components: {
            PaymentStatusLabel,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
        },
    })
    export default class OrdersPriceLabel extends Vue {
        @Prop({required: true}) item
        @Prop({default: true, type: Boolean}) showPaymentStatus

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }
    }
</script>

<style scoped lang="scss">
    .commission {
        font-size: 10px;
    }
</style>
