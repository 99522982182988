<template>
    <div>
        <v-row>
            <v-col>
                <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col :md="3" cols="12">
                <daterange-m
                    outlined
                    dense
                    :start-date.sync="infoByServiceType.cruise.startDate"
                    :end-date.sync="infoByServiceType.cruise.endDate"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span class="text-subtitle-1 font-weight-medium">{{ $t(`info`) }} </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.cruiseLine"
                    outlined
                    dense
                    :label="`${$t('cruises.cruise_line')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.shipName"
                    outlined
                    dense
                    :label="`${$t('cruises.ship_name')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.cabinCategory"
                    outlined
                    dense
                    :label="`${$t('cruises.cabin_category')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.cabinTypeName"
                    outlined
                    dense
                    :label="`${$t('cruises.cabin_type_name')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.deckName"
                    outlined
                    dense
                    :label="`${$t('cruises.deck_name')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.deckNumber"
                    outlined
                    dense
                    :label="`${$t('cruises.deck_number')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2" cols="12">
                <v-text-field
                    v-model="infoByServiceType.cruise.cabinNumber"
                    outlined
                    dense
                    :label="`${$t('cruises.cabin_number')}`"
                    hide-details
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DaterangeM from '@/components/search/forms/DaterangeM'

    @Component({
        components: {DaterangeM},
    })
    export default class ModifyManualAccommodation extends Vue {
        @VModel() infoByServiceType
    }
</script>
