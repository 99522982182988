<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {cruisesRuntimeStore, cruisesStore} from '@/store'
    import {CRUISE, PRODUCT_NAME} from '@/utils/cruises/cruises-const'

    @Component
    export default class CruisesStoreMixin extends Vue {
        get productStore() {
            return cruisesStore
        }

        get productRuntimeStore() {
            return cruisesRuntimeStore
        }

        get productType() {
            return CRUISE
        }

        get productName() {
            return PRODUCT_NAME
        }
    }
</script>
