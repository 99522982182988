<template>
    <div class="d-flex align-center">
        <div class="d-flex flex-column">
            <v-select
                v-model="newClient.prefix"
                outlined
                dense
                :items="['Mr', 'Ms', 'Mrs']"
                :label="`${$t('title')}`"
                class="pb-3"
                hide-details
            />
            <v-text-field
                v-model="newClient.firstName"
                outlined
                dense
                :label="`${$t('first_name')}`"
                class="pb-3"
                hide-details
            />
            <v-text-field v-model="newClient.lastName" outlined dense :label="`${$t('last_name')}`" hide-details />
        </div>
        <v-btn class="ms-2" icon @click="save">
            <v-icon class="success--text">mdi-check-circle-outline</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class OrderDetailsEditClient extends Vue {
        @Prop({required: true}) saveLoading
        @Prop({required: true}) clientPerson

        newClient = {}

        async mounted() {
            await this.load()
        }

        async load() {
            this.newClient = {...this.clientPerson}
        }

        @Emit()
        save() {
            return {
                firstName: this.newClient.firstName,
                lastName: this.newClient.lastName,
                prefix: this.newClient.prefix,
            }
        }
    }
</script>
