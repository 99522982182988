<template>
    <v-dialog v-model="dialog" width="600">
        <v-overlay v-if="loading">
            <v-progress-circular color="white" indeterminate size="180" width="10">
                <strong class="px-5">{{ $t('searching') }}...</strong>
            </v-progress-circular>
        </v-overlay>
        <v-card v-else>
            <v-card-title />
            <template v-if="notFound">
                <v-card-text class="warning--text text-center">
                    {{ goBackLabel }}
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed nuxt :to="goBackLink" class="primary mx-auto">
                        {{ goBackButtonText }}
                    </v-btn>
                </v-card-actions>
            </template>
            <template v-else>
                <v-card-text class="warning--text text-center">
                    {{ $t('warning_message.external_price_has_been_changed') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed class="primary mx-auto" @click="refresh">
                        {{ $t('continue') }}
                    </v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {arrayEquals} from '@/utils/helpers'
    import {appInstance} from '@/utils/app-accessor'
    import {hotelsRuntimeStore, hotelsStore} from '@/utils/store-accessor'

    @Component
    export default class HotelRefreshOffersDialog extends Vue {
        @Prop({required: true}) expiredOfferKeys

        dialog = false
        loading = true
        notFound = false
        basketItemIndex = 0
        roomOffers = []
        searchRequest = {}
        offer = {}
        offerKeys = []

        @Emit()
        async refresh() {
            hotelsStore.REFRESH_BASKET_ROOM_OFFERS({
                index: this.basketItemIndex,
                roomOffers: this.roomOffers,
                offerKeys: this.offerKeys,
                offer: this.offer,
            })
            this.dialog = false
            await this.$router.push({
                name: 'hotelBooking',
                query: {offerKey: this.offerKeys},
            })
        }

        get goBackButtonText() {
            if (!this.offer?.rooms.length) {
                return this.$t('try_to_research')
            }

            return this.$t('go_back')
        }

        get goBackLabel() {
            if (!this.offer?.rooms.length) {
                return this.$t('warning_message.rooms_not_found')
            }

            return this.$t('warning_message.offer_not_found')
        }

        get goBackLink() {
            if (!this.offer?.rooms.length) {
                return hotelsRuntimeStore.hotelsPageLink(this.searchRequest)
            }

            return this.hotelPageLink
        }

        async search(hotelId) {
            this.loading = true
            this.notFound = false
            this.dialog = true
            this.basketItemIndex = hotelsStore.basket.findIndex(({offerKeys}) =>
                arrayEquals(offerKeys, this.expiredOfferKeys)
            )
            this.offerKeys = []
            const basketItem = hotelsStore.basket[this.basketItemIndex]
            const {
                offer: {supplierCode, cityCode, hotelCode},
                searchRequest: {startDate, endDate, rooms, convertToCurrency},
                roomOffers: expiredRoomOffers,
            } = basketItem
            const citizenshipId = hotelsStore.searchRequest.citizenshipId
            const cityId = hotelsStore.searchRequest.cityId
            this.searchRequest = {...basketItem.searchRequest, citizenshipId}
            this.offer = basketItem.offer

            let supplierId

            if (supplierCode.indexOf('company.') === 0) {
                supplierId = +supplierCode.replace('company.', '')
            } else {
                try {
                    const {
                        object: [{id}],
                    } = await this.$api.companies.get({
                        name: supplierCode,
                        shortResponse: true,
                    })
                    supplierId = id
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }

            try {
                const hotelSearchInfo = hotelId ? {hotelId, cityId} : {supplierId, cityCode, hotelCode}

                const {
                    offers: [hotel],
                } = await appInstance.$api.searchAccommodation.get({
                    startDate,
                    endDate,
                    rooms,
                    convertToCurrency,
                    citizenshipId,
                    ...hotelSearchInfo,
                })
                if (hotel) {
                    this.offer = hotel
                    let priceChange = false
                    this.roomOffers = expiredRoomOffers.reduce((newRoomOffers, room, index) => {
                        const newRoomOffer = hotelsRuntimeStore.findSameRoomOffer(hotel.rooms, room)
                        if (newRoomOffer) {
                            if (room.price.amount !== newRoomOffer.price.amount) {
                                priceChange = true
                            }
                            newRoomOffers.push(newRoomOffer)
                            const {rph} = room.groupedOffers.find(
                                ({offerKey}) => offerKey === this.expiredOfferKeys[index]
                            )
                            const {offerKey} = newRoomOffer.groupedOffers.find(groupedOffer => groupedOffer.rph === rph)
                            this.offerKeys.push(offerKey)
                        }
                        return newRoomOffers
                    }, [])
                    if (this.roomOffers.length !== expiredRoomOffers.length) {
                        this.notFound = true
                        this.loading = false
                    } else if (!priceChange) {
                        await this.refresh()
                    } else {
                        this.loading = false
                    }
                } else {
                    this.notFound = true
                    this.loading = false
                }
            } catch (e) {
                this.notFound = true
                this.loading = false
            }
        }

        get hotelPageLink() {
            return hotelsRuntimeStore.hotelPageLink(this.offer, this.searchRequest)
        }
    }
</script>
