<template>
    <div class="text-center">
        <supplier-price v-if="!isSupplierPriceTooltip && !isAgency" :supplier-price="sumSupplierPrice" />
        <hotel-price-note
            v-if="selectedOfferKeys.length === searchRoomsCount"
            :search-request="searchRequest"
            :rooms-count="selectedOfferKeys.length"
            class="text-center"
        />
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn
                    :disabled="selectedOfferKeys.length < searchRoomsCount"
                    depressed
                    class="primary"
                    min-width="250"
                    v-on="isSupplierPriceTooltip && isSupplierPriceVisible ? on : null"
                    @click="selectForBooking"
                >
                    {{ showPrice && sumPrice.amount ? `${btnText}  ${$options.filters.price(sumPrice)}` : btnText }}
                </v-btn>
            </template>
            <supplier-price v-if="isSupplierPriceTooltip" class="white--text" :supplier-price="sumSupplierPrice" />
        </v-tooltip>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, hotelsStore, persistentStore} from '@/store'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import SupplierPrice from '@/components/snippets/supplierPrice.src'

    @Component({
        components: {
            SupplierPrice,
            HotelPriceNote,
        },
    })
    export default class HotelMultipleRoomSelectBlock extends Vue {
        @Prop({required: true}) selectedOfferKeys
        @Prop({required: true}) searchRequest
        @Prop({required: true}) offer

        selectForBooking() {
            const offerKey = this.selectedOfferKeys
            hotelsStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'hotelBooking',
                query: {offerKey},
            })
        }

        get isAgency() {
            return authStore.isAgency
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms.length
        }

        get sumPrice() {
            const sumPrice = {amount: 0, currency: persistentStore.getCurrency(this.offer.rooms[0]?.price.currency)}
            if (!this.selectedOfferKeys) return sumPrice
            return this.offer.rooms.reduce((sumPrice, room) => {
                if (room.groupedOffers.findIndex(({offerKey}) => this.selectedOfferKeys.includes(offerKey)) !== -1) {
                    sumPrice.amount += this.$options.filters.convertPrice(room.price).amount
                }
                return sumPrice
            }, sumPrice)
        }

        get sumSupplierPrice() {
            if (!this.offer.rooms[0]?.supplierPrice) {
                return null
            }

            const sumPrice = {
                amount: 0,
                currency: persistentStore.getCurrency(this.offer.rooms[0]?.supplierPrice.currency),
            }
            if (!this.selectedOfferKeys) return sumPrice
            return this.offer.rooms.reduce((sumPrice, room) => {
                if (room.groupedOffers.findIndex(({offerKey}) => this.selectedOfferKeys.includes(offerKey)) !== -1) {
                    sumPrice.amount += this.$options.filters.convertPrice(room.supplierPrice)?.amount
                }
                return sumPrice
            }, sumPrice)
        }

        get showPrice() {
            return true
        }

        get btnText() {
            return this.$t('book')
        }

        get isSupplierPriceTooltip() {
            return this.$config.isSupplierPriceTooltip || false
        }

        get isSupplierPriceVisible() {
            return this.sumSupplierPrice && authStore.companyType && authStore.companyType !== 'CORPORATE'
        }
    }
</script>
