<template>
    <div class="d-flex flex-column align-start justify-start text-left">
        <slot name="title" />
        <div v-if="!cancelPrice" class="error--text">
            <div v-if="isB2B && $t('cancellation_order_text_b2b').length">
                {{ $t('cancellation_order_text_b2b') }}
            </div>
            <div v-else-if="$t('cancellation_order_text_b2c').length">
                {{ $t('cancellation_order_text_b2c') }}
            </div>
            <div v-else>
                {{ $t('cancelInfoMessage.error') }}
            </div>
        </div>
        <div v-else-if="!isTO1 && customerPaymentStatus === 'PARTIAL_PAID'" class="error--text">
            {{
                $t('cancelInfoMessage.contact_to_agency', {
                    email: company.emails.join(','),
                    phone: company.phones.join(','),
                })
            }}
        </div>
        <div v-else-if="isTO1 && cancelPrice.amount === (servicePrice && servicePrice.amount)" class="error--text">
            {{ $t('cancelInfoMessage.cancellation_is_free_100') }}
        </div>
        <div v-else-if="cancelPrice.amount >= (servicePrice && servicePrice.amount)" class="error--text">
            {{ $t('cancelInfoMessage.not_refundable') }}
        </div>
        <template v-else-if="cancelPrice.amount === 0">
            <div class="success--text">
                {{ $t('cancelInfoMessage.cancellation_free') }}
            </div>
            <div v-if="!isNotPaid">
                {{
                    $t('cancelInfoMessage.amount_paid', {
                        amount: $options.filters.priceFormat(servicePrice.amount, servicePrice.currency),
                    })
                }}
            </div>
        </template>
        <template v-else>
            <template v-if="isNotPaid">
                <div class="error--text">
                    {{
                        $t('cancelInfoMessage.cancellation_fee', {
                            fee: $options.filters.priceFormat(cancelPrice.amount, cancelPrice.currency),
                        })
                    }}
                </div>
                <div class="text-left">
                    {{
                        $t('cancelInfoMessage.contact_to_agency', {
                            email: company.emails.join(','),
                            phone: company.phones.join(','),
                        })
                    }}
                </div>
            </template>
            <template v-else>
                <div class="error--text">
                    {{
                        $t('cancelInfoMessage.cancellation_fee', {
                            fee: $options.filters.priceFormat(cancelPrice.amount, cancelPrice.currency),
                        })
                    }}
                </div>
                <div>
                    {{
                        $t('cancelInfoMessage.amount_paid', {
                            amount: $options.filters.priceFormat(servicePrice.amount, servicePrice.currency),
                        })
                    }}
                </div>
                <div>
                    {{
                        $t('cancelInfoMessage.refund_difference', {
                            difference: $options.filters.priceFormat(
                                servicePrice.amount - cancelPrice.amount,
                                servicePrice.currency
                            ),
                        })
                    }}
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class CancelServiceStatusLabel extends Vue {
        @Prop() customerPaymentStatus
        @Prop() cancelPrice
        @Prop() servicePrice
        @Prop() isNotPaid
        @Prop() company

        get isB2B() {
            return authStore.isB2B
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
