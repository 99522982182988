<template>
    <v-form v-model="valid">
        <v-select
            :items="packagePrograms"
            :label="$t('tours.select_program')"
            :loading="packageProgramsLoading"
            :disabled="packageProgramsLoading"
            item-value="id"
            item-text="name"
            clearable
            @change="loadPackageTours"
        />
        <v-select
            v-model="searchRequest.packageTourId"
            :items="packageTours"
            :label="$t('tours.select_package')"
            :rules="[requiredRule('tours.package')]"
            :loading="packageToursLoading"
            :disabled="packageToursLoading"
            item-value="packageTourId"
            item-text="name"
        />
        <city-airport-autocomplete
            v-if="isShowFlyFrom"
            v-model="departure"
            :rules="[requiredRule('fly_from')]"
            :label="`${$t('fly_from')}*`"
            :single-line="false"
            dense
            filled
            class="mb-6 city-airport-field"
            @change="updateSearchRequest"
        />
        <date-picker v-model="searchRequest.startDateFrom" :label="$t('start_date')" :clearable="false" />
        <v-row>
            <v-col cols="12">
                <rooms-select-list
                    v-model="rooms"
                    :max-tourists="$config.account.newReservationRequestMaxTourists"
                    class="mt-5"
                    @change-age="onRoomsChange"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, toursRuntimeStore} from '@/utils/store-accessor'
    import {appInstance} from '@/utils/app-accessor'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import RoomsSelectList from '@/components/search/forms/RoomsSelectList.vue'
    import {searchRequest} from '@/utils/tours/tours-blank-states'
    import CityAirportAutocomplete from '~/components/flights/search/forms/CityAirportAutocomplete'

    @Component({components: {CityAirportAutocomplete, RoomsSelectList, DatePicker}})
    export default class SelectPackageTourForm extends mixins(FormValidationRulesMixin) {
        @VModel() valid
        @Prop({required: true}) searchRequest

        packagePrograms = []
        packageProgramsLoading = false
        packageToursLoading = false
        rooms = (() => {
            // eslint-disable-next-line no-unused-vars
            const {adults, childrenAges, ...sr} = searchRequest()
            return [{adults, childrenAges}]
        })()
        departure = {}

        @Watch('rooms', {deep: true})
        onRoomsChange() {
            this.searchRequest.rooms = this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room))
        }

        @Watch('isShowFlyFrom')
        onPackageTourChange() {
            if (!this.isShowFlyFrom) {
                this.searchRequest.departureAirportCode = null
            }
        }

        async mounted() {
            await this.loadPackagePrograms()
            if (!toursRuntimeStore.packageTours.length) {
                await toursRuntimeStore.loadPackageTours()
            }
            this.rooms = hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)
        }

        async loadPackagePrograms() {
            try {
                this.packageProgramsLoading = true
                this.packagePrograms = await appInstance.$api.packagePrograms.get()
                this.packageProgramsLoading = false
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async loadPackageTours(id) {
            this.packageToursLoading = true
            const request = {}
            if (id) {
                request.programId = id
                this.searchRequest.packageTourId = null
            }
            await toursRuntimeStore.loadPackageTours(request)
            this.packageToursLoading = false
        }

        updateSearchRequest(val) {
            if (!val) return
            if (val.type === 'city') {
                delete this.searchRequest.departureAirportCode
                this.searchRequest.departureCityId = val.cityId
            } else {
                this.searchRequest.departureAirportCode = val.iataCode
                this.searchRequest.departureCityId = null
            }
        }

        get packageTours() {
            return toursRuntimeStore.packageTours
        }

        get isShowFlyFrom() {
            const selectedPackageTour = this.packageTours.find(
                p => p.packageTourId === this.searchRequest.packageTourId
            )

            return selectedPackageTour?.itinerary?.find(el => el.serviceType === 'FLIGHT')
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .city-airport-field .v-input__slot {
            padding: 0 !important;
        }
    }
</style>
