<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="filteredOffers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <cruises-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <cruises-search-summary :dates="dates" :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <group-offers-select
                class="pt-3"
                :items="['none', 'serviceName', 'mealTypeName']"
                :product-store="productStore"
                token-base="cruiseGroupBy"
            />
            <cruises-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.products.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <cruises-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <cruise-conditions-modal-info />
                </template>
            </conditions-modal>
            <cruise-offer-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <cruise-map :_expanded.sync="mapExpanded" :points="mapPoints" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '@/components/search/SortSelect'
    import GroupOffersSelect from '@/components/search/GroupOffersSelect'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import Tmp_SearchPageBase from '@/mixins/Tmp_SearchPageBase'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import CruisesSearchSummary from '@/components/cruises/search/CruisesSearchSummary'
    import CruisesOffers from '@/components/cruises/search/offers/CruiseOffers'
    import CruisesBreadcrumbs from '@/components/cruises/CruisesBreadcrumbs'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'
    import CruisesPageMixin from '@/components/cruises/mixins/CruisesPageMixin.vue'
    import CruisesFilters from '@/components/cruises/search/CruisesFilters'
    import CruiseConditionsModalInfo from '@/components/cruises/snippets/CruiseConditionsModalInfo'
    import {cruisesRuntimeStore} from '@/utils/store-accessor'
    import CruiseMap from '@/components/cruises/search/CruiseMap'
    import CruiseOfferInfoModal from '@/components/cruises/info/CruiseOfferInfoModal'

    @Component({
        components: {
            CruiseOfferInfoModal,
            CruiseMap,
            CruiseConditionsModalInfo,
            CruisesFilters,
            CruisesBreadcrumbs,
            CruisesOffers,
            CruisesSearchSummary,
            Breadcrumbs,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            Pagination,
            MobileSearchPageTabs,
            ConditionsModal,
            SearchLayout,
            GroupOffersSelect,
        },
        layout: 'blank',
    })
    export default class CruisesSearchPage extends mixins(
        SearchPageBase,
        Tmp_SearchPageBase,
        SearchPageMixin,
        CruisesStoreMixin,
        CruisesPageMixin
    ) {
        async fetch() {
            await this.loadRuntimeData()
        }

        get dates() {
            return `${this.formattedDate(this.searchRequest.startDate)} - ${this.formattedDate(
                this.searchRequest.endDate
            )}`
        }

        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        get mapPoints() {
            if (!cruisesRuntimeStore.departureCity) {
                return [cruisesRuntimeStore.destinationCity, cruisesRuntimeStore.destinationCity]
            }

            return [cruisesRuntimeStore.departureCity, cruisesRuntimeStore.destinationCity]
        }
    }
</script>
