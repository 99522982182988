<template>
    <v-list>
        <v-divider light />
        <v-list-item
            v-for="(item, index) in footerMenu"
            :key="index"
            nuxt
            :to="!item.externalLink ? {name: 'infoPage', params: {link: item.link}} : null"
            :href="item.externalLink ? item.link : null"
            :target="item.externalLink ? '_blank' : null"
            class="ps-8"
        >
            <v-list-item-title>
                {{ item.name }}
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {cmsStore} from '@/store'

    @Component
    export default class NavigationDrawerMobileMenu extends Vue {
        get footerMenu() {
            return cmsStore.layoutSettings.footerMenu
        }
    }
</script>
