<template>
    <v-row>
        <v-col
            v-for="(route, index) in routes.length"
            :key="`selected-route-${route}`"
            :class="{'col-12': $breakpoint.smAndDown}"
        >
            <v-card :elevation="activeRoute === index ? 12 : 2" min-height="100%">
                <v-card-title>
                    {{ routes[index].departure.cityName }} - {{ routes[index].arrival.cityName }}
                </v-card-title>
                <v-card-text>
                    <div v-if="offers[index]">
                        <flight-itinerary
                            v-for="(itinerary, itineraryIndex) in offers[index].itinerary"
                            :key="itineraryIndex"
                            :itinerary="itinerary"
                            :minify="false"
                        />
                        <div v-if="$route.name !== 'packages'">
                            <v-row>
                                <v-col cols="6">
                                    <detail-modal
                                        :offer="{
                                            unitKey: offers[index].unitKey,
                                            itinerary: offers[index].itineraries,
                                        }"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <flight-class-selector
                                        :offer="offers[index]"
                                        :value="selectedScheduleItems[index].categoryClass"
                                        @select-class="
                                            setSelectedScheduleItem({
                                                index,
                                                offer: offers[index],
                                                categoryClass: $event,
                                            })
                                        "
                                    />
                                </v-col>
                            </v-row>
                            <!-- <fare-rules :unit-key="selectedFlight.offerKey" /> -->
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="index === activeRoute">
                            {{ $t('flights.select_offer') }}
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="index !== activeRoute && offers[index]" @click="$emit('change-route', index)">
                        {{ $t('change') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn block :disabled="offers.length !== routes.length" @click="checkAvailability">
                {{ $t('flights.continue') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FlightItinerary from '@/components/flights/snippets/FlightItinerary'
    import {flightsRuntimeStore, flightsStore} from '~/store'
    import DetailModal from '~/components/flights/DetailModal'
    import FlightClassSelector from '~/components/flights/search/schedule/FlightClassSelector'
    import {hash} from '@/utils/helpers'

    @Component({
        components: {
            FlightItinerary,
            DetailModal,
            FlightClassSelector,
        },
    })
    export default class SelectedScheduleOffers extends Vue {
        @Prop({default: 0}) activeRoute

        setSelectedScheduleItem({index, offer, categoryClass}) {
            flightsStore.SET_SELECTED_SCHEDULE_ITEM({index, offer, categoryClass})
        }

        checkAvailability() {
            const units = this.offers.map(({unitKey, itinerary}, index) => {
                const segments = itinerary[0].segments.map(({rph}) => {
                    const {classType, code} = this.selectedScheduleItems[index].categoryClass
                    return {
                        rph,
                        categoryClass: {classType, code},
                    }
                })
                return {
                    unitKey,
                    segments,
                }
            })
            const query = {units}
            flightsStore.SET_AVAILABILITY_REQUEST(query)
            this.$router.push({name: 'flightsAvailability', params: {hash: hash(query)}})
        }

        get routes() {
            return flightsRuntimeStore.routes
        }

        get selectedScheduleItems() {
            return flightsStore.selectedScheduleItems
        }

        get offers() {
            return this.selectedScheduleItems.map(({offer}) => offer)
        }
    }
</script>
