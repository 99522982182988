<template>
    <div class="main-page-tabs-container d-flex align-center justify-center">
        <client-only>
            <hotel-search-form is-iframe :city-id="cityId" />
        </client-only>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'

    @Component({
        components: {
            HotelSearchForm,
        },
        layout: 'pure',
    })
    export default class SearchFormPage extends Vue {
        scrollHeight = 0
        intervalId

        async fetch() {
            await cmsStore.loadPopularDestinations()
        }

        async mounted() {
            this.intervalId = setInterval(() => {
                if (document.documentElement.scrollHeight !== this.scrollHeight) {
                    this.scrollHeight = document.documentElement.scrollHeight
                    window.parent.postMessage({height: this.scrollHeight}, '*')
                }
            }, 300)
        }

        beforeDestroy() {
            clearInterval(this.intervalId)
        }

        get cityId() {
            return this.$route.query.cityId
        }
    }
</script>
