<template>
    <v-dialog v-model="dialog" :width="newAgentMode ? 800 : 500">
        <template v-slot:activator="{on}">
            <div
                v-ripple
                class="text-right py-1 text-subtitle-2 cursor-pointer d-flex align-center justify-end"
                style="background-color: rgba(0, 0, 0, 0.6); color: rgba(255, 255, 255, 0.6)"
                :class="{'px-10': !isMobile, 'px-4': isMobile, 'py-2': isMobile}"
                v-on="on"
                v-text="`${$t('choose.choose')} ${$t('choose.agent_client')}`"
            />
        </template>
        <v-card>
            <v-card-title class="text-h5 primary--text pt-5 pb-2" align="center">
                <v-btn v-if="newAgentMode" fab class="me-3" small elevation="0" @click="newAgentMode = false">
                    <v-icon color="primary"> mdi-arrow-left</v-icon>
                </v-btn>
                {{ newAgentMode ? `${$t('create_new_agent')}` : `${$t('choose.agent_dialog_header')}` }}
            </v-card-title>
            <div v-show="!newAgentMode">
                <v-card-text>
                    <v-radio-group
                        v-if="salesChannelValues.length > 1"
                        v-model="salesChannel"
                        @change="onSalesChannelChange"
                    >
                        <v-radio
                            v-for="value in salesChannelValues"
                            :key="value"
                            :label="`${$t(`sales_channel.${value}`)}`"
                            :value="value"
                        />
                    </v-radio-group>
                    <v-form ref="form" v-model="valid">
                        <v-autocomplete
                            v-model="selectedCompany"
                            :items="companies"
                            :search-input.sync="companySearchInput"
                            :loading="isLoadingCompanies"
                            :item-text="getCompanyItemText"
                            hide-no-data
                            :label="`${$t('choose.company_long')} *`"
                            return-object
                            persistent-placeholder
                            outlined
                            background-color="white"
                            :clearable="true"
                            :rules="[v => !!v || `${$t('choose.company_validation')}`]"
                            @change="onCompanyChange"
                            @update:search-input="onCompanyInput"
                        />
                        <v-autocomplete
                            v-if="salesChannel !== 'corporate' && !newAgentMode"
                            v-model="selectedAgent"
                            :items="persons"
                            :item-text="agentInputText"
                            :loading="isLoadingPersons"
                            hide-no-data
                            :label="`${$t('choose.agent_long')} ${selectedCompanyIsCorporate ? '' : '*'}`"
                            return-object
                            :disabled="!selectedCompany || isLoadingPersons"
                            outlined
                            persistent-placeholder
                            background-color="white"
                            :clearable="true"
                            :rules="!selectedCompanyIsCorporate ? [v => !!v || `${$t('choose.agent_validation')}`] : []"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item ripple @mousedown.prevent @click="newAgentMode = true">
                                    <v-list-item-title class="primary--text font-weight-medium">
                                        {{ $t('create_new_agent') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-form>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-4">
                    <v-spacer />
                    <v-btn color="primary" :disabled="!valid" @click="save">
                        {{ $t('continue') }}
                    </v-btn>
                    <v-btn text @click="dialog = false">
                        {{ $t('cancel') }}
                    </v-btn>
                </v-card-actions>
            </div>
            <person-form
                v-if="selectedCompany && newAgentMode"
                class="px-8 py-8"
                :person="{}"
                :role-prefix="selectedCompany.type === 'TOUR_AGENCY' ? 'ta' : 'to'"
                :company-id="selectedCompany.id"
                :custom-roles="[
                    'accountant',
                    'agent',
                    'director',
                    'guest',
                    'manager',
                    'sales_manager',
                    'sales_staff',
                    'supervisor',
                ]"
                allow-no-credentials
                :redirect="false"
                @save="onNewAgentSave"
            />
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import {PHONE_REGEXP} from '@/utils/helpers'
    import PersonForm from '~src/components/account/forms/personForm.src'

    @Component({
        components: {PersonForm},
    })
    export default class ChooseAgentDialog extends Vue {
        dialog = false
        salesChannel = null
        valid = false
        newAgentMode = false

        companies = []
        isLoadingCompanies = false
        selectedCompany = null
        companySearchInput = null
        companyPhoneString = null
        companySearchTimeout = null

        persons = []
        isLoadingPersons = false
        selectedAgent = null

        mounted() {
            this.salesChannel = this.salesChannelValues[0]
        }

        onCompanyInput() {
            this.companies = []

            if (this.companySearchTimeout) {
                clearTimeout(this.companySearchTimeout)
            }
            if (!this.selectedCompany) {
                this.companySearchTimeout = setTimeout(() => {
                    this.loadCompanies()
                }, 500)
            }
        }

        async loadCompanies() {
            try {
                this.selectedCompany = null
                this.isLoadingCompanies = true

                let types = []

                if (this.salesChannel === 'corporate') {
                    types = ['CORPORATOR']
                } else if (this.salesChannel === 'agency') {
                    types = this.isTO1 ? ['TOUR_OPERATOR', 'TOUR_AGENCY'] : this.isTO2 ? ['TOUR_AGENCY'] : []
                }

                const result = []
                this.companyPhoneString = PHONE_REGEXP.test(this.companySearchInput) ? this.companySearchInput : null

                const promises = types.map(type => {
                    return async () => {
                        const [dataByPhone, dataByName] = await Promise.all([
                            (async () => {
                                return this.companyPhoneString
                                    ? (
                                          await this.$api.companies.get({
                                              type,
                                              shortResponse: true,
                                              phone: this.companySearchInput,
                                          })
                                      ).object
                                    : []
                            })(),
                            (async () => {
                                return (
                                    await this.$api.companies.get({
                                        type,
                                        shortResponse: true,
                                        name: this.companySearchInput,
                                    })
                                ).object
                            })(),
                        ])

                        result.push(...dataByName, ...dataByPhone)
                    }
                })

                await Promise.all(promises.map(e => e()))

                result.sort((a, b) => a.name.localeCompare(b.name))

                this.companies = result

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoadingCompanies = false
            }
        }

        async loadPersons() {
            this.selectedAgent = null
            try {
                this.isLoadingPersons = true

                const data = await this.$api.persons.get({companyId: this.selectedCompany.id})

                data.users.sort((a, b) =>
                    `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(`${b.firstName || ''} ${b.lastName || ''}`)
                )

                this.persons = data.users
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoadingPersons = false
            }
        }

        async onSalesChannelChange() {
            this.reset()
            await this.loadCompanies()
        }

        onCompanyChange() {
            if (!this.selectedCompanyIsCorporate) {
                this.loadPersons()
            }
        }

        onNewAgentSave() {
            this.newAgentMode = false
            this.loadPersons()
        }

        reset() {
            this.companies = []
            this.persons = []
            this.selectedCompany = null
            this.selectedAgent = null
        }

        agentInputText(val) {
            const email = val.email
            return `${val.prefix || ''} ${val.firstName || ''} ${val.lastName || ''}` + (email ? ` (${email})` : '')
        }

        save() {
            if (!this.selectedAgent) {
                persistentStore.SET_SELECTED_AGENT({
                    companyId: this.selectedCompany.id,
                    companyName: this.selectedCompany.name,
                    companyCountryId: this.selectedCompany.countryId,
                    takeClientMethod: this.selectedCompanyIsCorporate ? 'persons' : 'privateClients',
                })
            } else {
                persistentStore.SET_SELECTED_AGENT({
                    ...this.selectedAgent,
                    companyCountryId: this.selectedCompany.countryId,
                    takeClientMethod: 'privateClients',
                })
            }

            this.dialog = false
        }

        getCompanyItemText(item) {
            const phone = item.phones.find(p => p.includes(this.companyPhoneString)) || null
            return item.name + (phone ? ` (${phone})` : '')
        }

        get salesChannelValues() {
            if (!this.$config.account.showCorporateClients) {
                return this.isAgency ? [] : ['agency']
            }
            return this.isAgency ? ['corporate'] : ['agency', 'corporate']
        }

        get selectedCompanyIsCorporate() {
            return this.selectedCompany?.type === 'CORPORATOR'
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss"></style>
