<template>
    <loading-page-layout :loaded="loaded">
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="goToSearchPage" />
        <mobile-info-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <activity-breadcrumbs v-if="!$breakpoint.smAndDown" :search-request="searchRequest" :product="product" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <h1 v-if="product.info" class="title">
                        {{ product.info.name }}
                        <v-chip v-if="product.type && product.type !== 'UNKNOWN'" label x-small>
                            {{ $t(`filters_activityType.${product.type}`) }}
                        </v-chip>
                    </h1>
                    <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" class="mb-2" />
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="uniqueImages"
                    />
                    <template v-if="!$breakpoint.smAndDown">
                        <activity-grouped-offers
                            :offers="product.offers"
                            :date-filter="dateFilter"
                            full-card
                            class="mt-4"
                            @images="images = [...images, ...$event]"
                            @info="offersInfo = [...offersInfo, $event]"
                        />
                        <template v-if="groupOffersBy === 'none'">
                            <activity-offer-card
                                v-for="offer in product.offers"
                                :key="offer.offerKey"
                                :offer="offer"
                                @images="images = [...images, ...$event]"
                                @info="offersInfo = [...offersInfo, $event]"
                            />
                        </template>
                    </template>
                    <activity-duration
                        v-if="offersInfo[0]"
                        :time="offersInfo[0].durationTime"
                        :days="offersInfo[0].durationDays"
                        class="mt-4"
                    />
                    <template v-if="showShortDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`description_type.short`) }}
                        </div>
                        <div v-html="shortDescription" />
                    </template>
                    <template v-if="fullDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`description_type.general`) }}
                        </div>
                        <div v-html="fullDescription" />
                    </template>
                    <template v-if="descriptions && descriptions.length">
                        <strong>{{ $t('description') }} </strong><br />
                        <div v-for="(desc, descIndex) in descriptions" :key="descIndex" v-html="desc" />
                    </template>
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <activity-search-summary :search-request="searchRequest" show-dates />
                    <template v-if="$breakpoint.smAndDown">
                        <activity-grouped-offers
                            :offers="product.offers"
                            :date-filter="dateFilter"
                            full-card
                            @images="images = [...images, ...$event]"
                            @info="offersInfo = [...offersInfo, $event]"
                        />
                        <template v-if="groupOffersBy === 'none'">
                            <activity-offer-card
                                v-for="(offer, offerIndex) in product.offers"
                                :key="offer.offerKey"
                                :offer="offer"
                                :data-cy="`activity-offer-card-${offerIndex}`"
                                @images="images = [...images, ...$event]"
                                @info="offersInfo = [...offersInfo, $event]"
                            />
                        </template>
                    </template>
                    <div v-if="!$breakpoint.smAndDown" style="position: relative">
                        <client-only>
                            <activity-map :offers="mapPoints" :city="city" />
                        </client-only>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <conditions-modal>
            <template v-slot:info>
                <activity-conditions-modal-info />
            </template>
        </conditions-modal>
        <activity-info-modal />
    </loading-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileInfoPageTabs from '@/components/snippets/MobileInfoPageTabs'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard'
    import ActivityInfo from '@/components/activities/info/ActivityInfo'
    import ActivitySearchSummary from '@/components/activities/search/ActivitySearchSummary'
    import ActivityMap from '@/components/activities/search/ActivityMap'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import ActivityGroupedOffers from '~/components/activities/search/offers/ActivityGroupedOffers'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo'
    import {handleNewLine} from '@/utils/filters'
    import LoadingPageLayout from '@/components/LoadingPageLayout'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import InfoPageMixin from '@/components/mixins/InfoPageMixin'
    import ActivityDuration from '@/components/activities/search/offers/ActivityDuration'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import {authStore} from '@/utils/store-accessor'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            ActivityDuration,
            LoadingPageLayout,
            ActivityConditionsModalInfo,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            ActivityGroupedOffers,
            ActivityOfferCard,
            ActivityBreadcrumbs,
            MobileInfoPageTabs,
            ActivityInfo,
            ActivitySearchSummary,
            ActivityMap,
            ConditionsModal,
            ActivityInfoModal,
            ProductGallery,
            ProductGalleryMobile,
            SupplierNameLabel,
        },
        layout: 'blank',
    })
    export default class ActivityPage extends mixins(
        ActivityPageMixin,
        ActivityStoreMixin,
        SearchPageMixin,
        InfoPageMixin,
        SearchPageAuthMixin
    ) {
        offersInfo = []
        dateFilter = null

        get fullDescription() {
            return handleNewLine(this.offersInfo.find(({fullDescription}) => fullDescription)?.fullDescription)
        }

        get shortDescription() {
            return handleNewLine(this.offersInfo.find(({shortDescription}) => shortDescription)?.shortDescription)
        }

        get descriptions() {
            return this.offersInfo.find(({descriptions}) => descriptions.length)?.descriptions
        }

        get showShortDescription() {
            return (
                this.shortDescription &&
                (!this.fullDescription || this.fullDescription.indexOf(this.shortDescription) === -1)
            )
        }

        get uniqueImages() {
            return this.images.filter((v, i, a) => a.findIndex(t => t.url === v.url) === i)
        }

        get city() {
            return this.productRuntimeStore.city
        }

        get groupOffersBy() {
            return this.productStore.groupOffersBy
        }

        get mapPoints() {
            return this.product.offers?.reduce((departures, offer) => {
                if (offer.pickUpPoints && offer.pickUpPoints.length && this.offersInfo.length) {
                    offer.pickUpPoints.forEach(point => {
                        if (
                            point.latitude &&
                            point.longitude &&
                            !departures.some(
                                item => item.latitude === point.latitude && item.longitude === point.longitude
                            )
                        ) {
                            const data = {
                                pointName: point.name,
                                startTimes: offer.startTimes,
                                latitude: point.latitude,
                                longitude: point.longitude,
                                name: this.offersInfo[0].name,
                            }
                            departures.push(data)
                        }
                    })
                }

                return departures
            }, [])
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style scoped lang="scss">
    .disable-top {
        top: 0 !important;
    }
</style>
