<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class TemplateEditorPageMixin extends Vue {
        async saveTemplate({jsonTemplate, htmlTemplate, info}) {
            try {
                await this.$api.notificationTemplates.put(this.$route.params.id, {
                    jsonTemplate,
                    htmlTemplate,
                    info,
                })
                this.$toast.success(this.$t('success_message.saved_successfully'))
                await this.$router.push({name: this.templatesPageName})
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            }
        }

        async saveNewTemplate(template) {
            try {
                await this.$api.notificationTemplates.post(template)
                this.$toast.success(this.$t('success_message.saved_successfully'))
                await this.$router.push({name: this.templatesPageName})
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            }
        }
    }
</script>
