<template>
    <v-layout :fill-height="!loaded" column>
        <car-booking-page-layout
            v-if="loaded"
            ref="layout"
            :search-request="searchRequest"
            :offer="offer"
            :offer-key="offerKey"
        >
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
                <car-breadcrumbs :search-request="searchRequest" :product="product" />
            </template>
            <template v-slot:touristsCard="{disabled, tourists, personSelected, isSavingWithoutDetails}">
                <v-card outlined class="mb-5">
                    <v-card-title class="text-capitalize">
                        <v-icon left color="primary">mdi-account</v-icon>
                        {{ $tc('carsRent.drivers', 1) }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <tourist-form
                            v-model="tourists[0]"
                            :index="0"
                            :hide-index="true"
                            :disabled="disabled"
                            :corporate-policy-violation="offer.corporatePolicyViolation"
                            :selected-tourists="tourists"
                            :start-date="startDate"
                            :disable-required-rule="isSavingWithoutDetails"
                            data-cy="tourist-0"
                            label="carsRent.drivers"
                            disable-birth-date-limits
                            @person-selected="personSelected"
                        />
                    </v-card-text>
                </v-card>
            </template>
            <template v-slot:forms="{bookingKey}">
                <car-details-form
                    :disabled="!!bookingKey"
                    :offer="offer"
                    :product="product"
                    outlined
                    class="mb-4"
                    @show-on-map="
                        latitude = $event.latitude
                        longitude = $event.longitude
                        mapModal = true
                    "
                />
                <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" :outlined="false" />
            </template>
            <!--eslint-disable-next-line vue/no-unused-vars-->
            <template v-slot:sidebar="{bookingKey, prepareBookResponse, extraServices}">
                <car-order-summary
                    :search-request="searchRequest"
                    :info="product.info"
                    :offer="product.offer"
                    :prebooked="!!bookingKey"
                    :prepare-book-response="prepareBookResponse"
                    :extra-services="extraServices"
                    :additional-options="productStore.bookingAdditionalOptions"
                >
                    <template v-slot:summary>
                        <v-card flat tile class="mb-1 pa-0">
                            <v-card-text class="pa-0">
                                <search-summary-content
                                    :search-request="searchRequest"
                                    :pick-up-location="pickUpLocation"
                                    :drop-off-location="dropOffLocation"
                                    show-passengers
                                    @show-on-map="
                                        latitude = $event.latitude
                                        longitude = $event.longitude
                                        mapModal = true
                                    "
                                />
                            </v-card-text>
                        </v-card>
                    </template>
                </car-order-summary>
            </template>
        </car-booking-page-layout>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
        <conditions-modal>
            <template v-slot:info>
                <car-conditions-modal-info />
            </template>
        </conditions-modal>
        <car-info-modal />
        <client-only>
            <v-dialog v-model="mapModal" :max-width="500">
                <map-location :latitude="latitude" :longitude="longitude" :height="`500px`" />
            </v-dialog>
        </client-only>
    </v-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import CarBookingPageLayout from '~/components/carsRent/booking/CarBookingPageLayout'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import CarBreadcrumbs from '@/components/carsRent/CarBreadcrumbs'
    import {runtimeStore, carsRentRuntimeStore, carsRentStore} from '@/utils/store-accessor'
    import CarsSearchPageMixin from '@/components/carsRent/mixins/CarsSearchPageMixin'
    import CarsStoreMixin from '@/mixins/CarsStoreMixin'
    import {UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import CarOrderSummary from '@/components/carsRent/booking/CarOrderSummary'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import CarConditionsModalInfo from '@/components/carsRent/snippets/CarConditionsModalInfo'
    import CarInfoModal from '@/components/carsRent/CarInfoModal'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import CarDetailsForm from '@/components/carsRent/booking/forms/CarDetailsForm'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import MapLocation from '~src/components/info/mapLocation.src'
    import TransferOrderSummary from '@/components/transfers/booking/transferOrderSummary.src'

    @Component({
        components: {
            TransferOrderSummary,
            CarBookingPageLayout,
            ExpiredOffersSnackbar,
            CarBreadcrumbs,
            BookingCommentsForm,
            CarOrderSummary,
            ConditionsModal,
            CarConditionsModalInfo,
            CarInfoModal,
            TouristForm,
            CarDetailsForm,
            SearchSummaryContent,
            MapLocation,
        },
        layout: 'blank',
    })
    export default class CarBookingPage extends mixins(CarsStoreMixin, CarsSearchPageMixin) {
        loaded = false
        mapModal = false
        latitude = null
        longitude = null

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            const promises = []
            promises.push(this.loadRuntimeData())
            promises.push(runtimeStore.loadTouristBookingFields({offerKey: this.offerKey}))
            carsRentStore.clearBookingAdditionalOptions()
            promises.push(carsRentStore.getExtraServiceAdditionalOptions(this.offerKey))

            await Promise.all(promises)
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !this.productStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(carsRentRuntimeStore.searchPageLink(this.searchRequest))
        }

        get basketItem() {
            return carsRentStore.basketItem(this.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offer
        }

        get offerKey() {
            return this.$route.query.offerKey
        }

        get startDate() {
            return this.searchRequest.pickUpDateTime
        }

        get pickUpLocation() {
            return carsRentStore.pickUpLocation
        }

        get dropOffLocation() {
            return carsRentStore.dropOffLocation
        }
    }
</script>
