<template>
    <v-app>
        <nuxt />
    </v-app>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import BaseLayout from '@/components/BaseLayout'

    @Component
    export default class PureLayout extends BaseLayout {}
</script>
