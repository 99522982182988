<template>
    <search-form-layout :popup="popup" title="flights.flights" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-tabs v-model="searchMode" background-color="transparent" dark class="mb-6">
                <v-tab>
                    <v-icon class="hidden-md-and-up">mdi-airplane mdi-rotate-270</v-icon>
                    <v-icon class="hidden-md-and-up">mdi-airplane mdi-rotate-90</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('flights.round_trip') }}</span>
                </v-tab>
                <v-tab>
                    <v-icon class="hidden-md-and-up">mdi-airplane mdi-rotate-90</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('flights.one_way') }}</span>
                </v-tab>
                <v-tab>
                    <v-icon class="hidden-md-and-up">mdi-format-list-bulleted</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('flights.multi_city') }}</span>
                </v-tab>
            </v-tabs>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" md="8" class="d-flex">
                                <component
                                    :is="searchForm"
                                    v-model="locations"
                                    :init-search="searchInitialized"
                                    @form-data="setFormData"
                                />
                            </v-col>
                            <v-col cols="12" md="2" class="d-flex">
                                <tourists-select-menu
                                    :_adults.sync="adults"
                                    :_children-ages.sync="childrenAges"
                                    token="passenger"
                                    :max-total="9"
                                />
                            </v-col>
                            <v-col md="2" cols="12" class="d-md-flex d-lg-flex hidden-sm-and-down">
                                <v-btn block dark x-large color="primary" :disabled="!valid" @click="initSearch">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <additional-options-wrapper
                ref="addForm"
                :options="[
                    'flightSearchType',
                    'promocode',
                    'citizenship',
                    'flight-options',
                    'flight-class',
                    'airlines',
                    'internalSuppliers',
                    'externalSuppliers',
                    'supplierId',
                ]"
                :suppliers="suppliers"
            >
                <template v-slot>
                    <v-radio-group
                        v-if="$config.searchFormOptions.flightSearchType !== false"
                        v-model="searchType"
                        dark
                        row
                    >
                        <v-radio :label="$t('flights.searchType.by_price')" value="price" />
                        <v-radio :label="$t('flights.searchType.by_schedule')" value="schedule" />
                    </v-radio-group>
                </template>
            </additional-options-wrapper>
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="initSearch">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import {authStore, flightsRuntimeStore, flightsStore, persistentStore} from '~/store'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import RoundTrip from '~/components/flights/search/forms/RoundTrip'
    import OneWay from '~/components/flights/search/forms/OneWay'
    import MultiCity from '~/components/flights/search/forms/MultiCity'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            SearchFormLayout,
            RoundTrip,
            OneWay,
            MultiCity,
            AdditionalOptionsWrapper,
        },
    })
    export default class FlightSearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false}) popup

        mods = {
            roundTrip: 0,
            oneWay: 1,
            multiCity: 2,
        }
        routes = []
        searchMode = 0
        adults = 1
        childrenAges = []
        valid = false
        searchInitialized = false
        searchType = 'price'
        suppliers = []
        locations = {from: null, to: null}

        @Watch('routes')
        onRoutesChange() {
            this.search()
        }

        //TODO Add CHANGE_LOCALE_EVENT handler

        async mounted() {
            await this.$store.restored

            if (flightsRuntimeStore.arrivalLocation?.id && !this.routes.length) {
                this.locations.to = flightsRuntimeStore.arrivalLocation
            }

            let searchRequest
            if (this.$route.name !== 'flightsSchedule') {
                searchRequest = flightsStore.searchRequest
                this.searchType = 'price'
            } else {
                searchRequest = flightsStore.scheduleSearchRequest
                this.searchType = 'schedule'
            }
            this.adults = authStore.user.isEmployeeOrExternalCorp ? 1 : searchRequest.adults
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp ? [] : [...searchRequest.childrenAges]

            if (this.isTO1) {
                try {
                    this.suppliers = (await this.$api.suppliers.get({productType: 'flight'}))?.suppliers
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        setSearchMode() {
            if (this.$route.name === 'home') {
                return this.mods.roundTrip
            }
            return flightsStore.searchMode
        }

        created() {
            this.searchMode = this.setSearchMode()
        }

        initSearch() {
            this.searchInitialized = true
            this.$emit('close-popup')
        }

        setFormData(data) {
            this.routes = [...data]
            this.searchInitialized = false
        }

        newQuery() {
            const query = {
                routes: this.makeRoutes(),
                adults: this.adults,
                childrenAges: this.childrenAges,
                directFlight: false,
            }

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        async search() {
            this.searchInitialized = false
            const query = this.newQuery()
            flightsRuntimeStore.SET_ROUTES(this.routes)
            flightsStore.SET_SEARCH_MODE(this.searchMode)
            const name = this.searchType === 'price' ? 'flights' : 'flightsSchedule'
            if (this.$route.name !== name) {
                if (name === 'flights') {
                    flightsRuntimeStore.newSearch()
                } else {
                    flightsRuntimeStore.newScheduleSearch()
                }
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                if (name === 'flights') {
                    await flightsRuntimeStore.search(flightsStore.searchRequest)
                } else {
                    await flightsRuntimeStore.scheduleSearch(flightsStore.scheduleSearchRequest)
                }
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        makeRoutes() {
            return this.routes.map(route => {
                return `${route.departure.iataCode}-${route.arrival.iataCode},${route.date}`
            })
        }

        get isTO1() {
            return authStore.isTO1
        }

        get searchForm() {
            switch (this.searchMode) {
                case this.mods.roundTrip:
                    return 'round-trip'
                case this.mods.oneWay:
                    return 'one-way'
                case this.mods.multiCity:
                    return 'multi-city'
            }
            return 'round-trip'
        }
    }
</script>

<style scoped>
    @media (max-width: 960px) {
        ::v-deep .v-slide-group__content {
            justify-content: space-between;
        }
    }
</style>
