<template>
    <div>
        <div class="d-flex mb-4">
            <v-img
                tile
                :src="offer.thumb | imageUrl(350)"
                :alt="offer.name"
                eager
                width="100"
                max-width="100"
                height="100"
                :lazy-src="'/placeholder.png' | srcHost"
                class="me-2"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                <div class="d-flex align-center flex-wrap">
                    <hotel-stars :hotel="offer" />
                    <hotel-type-label class="ms-1" :offer="offer" />
                    <v-img
                        v-if="offer && offer.recommended && showPreferred"
                        :src="'/preferred.png' | srcHost"
                        width="80px"
                        class="preferred-thumb flex-grow-0 mx-1"
                    />
                </div>
                <div class="font-weight-bold text-truncate" :title="offer.name">
                    {{ offer.name }}
                </div>
                <supplier-name-label v-if="isTO1" :supplier-name="offer.supplierName" />
                <hotel-address-label
                    :address="offer.address"
                    :class="{'cursor-pointer': offer.coordinates && offer.coordinates.length}"
                    @click.native="mapModal = true"
                />
            </div>
        </div>
        <slot name="summary" />
        <hotel-info-time class="mb-3" />
        <div v-for="(room, roomIndex) in roomOffers" :key="roomIndex" class="relative-position">
            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="roomInfo(room)">
                <v-icon small left>mdi-information-outline</v-icon>
                {{ room.name }}
            </div>
            <div class="caption">
                <hotel-available-label :room="room" />
                <hotel-meal-type-label
                    :hotel="offer"
                    :room="room"
                    :selected-meal-types="selectedMealTypes[roomIndex]"
                />
                <tariff-label :room="room" :hotel="offer" />
                <conditions-label
                    :offer-key="room.groupedOffers[0].offerKey"
                    :price="room.price"
                    :offer="offer"
                    @conditions="showConditionsInfo(room)"
                />
            </div>
            <div v-if="showPrice" class="room-price mt-1">
                <room-price-label :price="roomPrice(roomIndex)" :room="room" />
                <commission-amount :commission="room.price.commission" />
                <net-amount :price="room.price" />
                <rate-note :room="room" />
            </div>
            <div v-else-if="free" class="mt-1 font-weight-bold">{{ $t('free') }}</div>
            <div
                v-if="
                    selectedMealTypes.length &&
                    selectedMealTypes[roomIndex].filter(({price}) => price.amount !== 0).length
                "
                class="d-block my-4 px-0"
            >
                <div class="font-weight-bold mb-2">
                    {{ $t('meal_type') }}
                </div>
                <div
                    v-for="({name, quantity, price}, index) in selectedMealTypes[roomIndex].filter(
                        ({price}) => price.amount !== 0
                    )"
                    :key="index"
                    class="py-0 d-flex caption"
                >
                    <span>{{ name }}</span>
                    <span v-if="quantity > 1">&nbsp;* {{ quantity }} </span>
                    <v-spacer />
                    <span class="font-weight-bold">
                        {{ {amount: price.amount * quantity, currency: price.currency} | price }}
                    </span>
                </div>
            </div>
            <extra-services-summary
                v-if="extraServices[roomIndex] && additionalOptions[roomIndex]"
                :extra-services="extraServices[roomIndex]"
                :additional-options="additionalOptions[roomIndex]"
            />
            <v-divider v-if="roomCheckInOutExtraServices(roomIndex).length" class="mb-4" />
            <v-list-item v-if="roomCheckInOutExtraServices(roomIndex).length" class="d-block my-4 px-0">
                <v-row class="flex-column">
                    <v-col
                        v-for="service in roomCheckInOutExtraServices(roomIndex)"
                        :key="service.time + service.text"
                        class="py-0 d-flex align-center caption"
                    >
                        <span>{{ `${$t(service.text)} ` }}{{ service.time }}</span>
                        <v-spacer />
                        <span>{{ service.price | price }}</span>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-divider v-if="roomIndex < roomOffers.length - 1" class="my-4" />
            <slot name="removeBtn" :offer-key="room.groupedOffers[0].offerKey" />
        </div>
        <add-ons-summary-list :selected-add-ons="selectedAddOns" />
        <client-only>
            <v-dialog v-if="offer.coordinates && offer.coordinates.length" v-model="mapModal" :max-width="500">
                <map-location
                    v-if="mapModal"
                    :latitude="offer.coordinates[0]"
                    :longitude="offer.coordinates[1]"
                    :height="`500px`"
                    :city="city"
                />
            </v-dialog>
        </client-only>
    </div>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {authStore, hotelsRuntimeStore, hotelsStore, runtimeStore} from '~/store'
    import {EventBus, SHOW_HOTEL_CONDITIONS_INFO, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import HotelMealTypeLabel from '~src/components/hotels/snippets/hotelMealTypeLabel.src'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import RateNote from '~src/components/snippets/RateNote'
    import OrderSummaryLayout from '~src/components/booking/orderSummaryLayout.src'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import HotelAvailableLabel from '~src/components/hotels/snippets/hotelAvailableLabel.src'
    import HotelTypeLabel from '@/components/hotels/snippets/HotelTypeLabel'
    import HotelOwnLabel from '~src/components/hotels/snippets/HotelOwnLabel'
    import HotelAddressLabel from '~src/components/hotels/snippets/hotelAddressLabel.src'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import RoomPriceLabel from '~src/components/hotels/booking/snippets/roomPriceLabel.src'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import NetAmount from '@/components/snippets/NetAmount'
    import MapLocation from '~src/components/info/mapLocation.src'
    import AddOnsSummaryList from '@/components/booking/addOns/AddOnsSummaryList.vue'
    import OrderSummaryBase from '@/components/booking/OrderSummaryBase.vue'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin.vue'

    @Component({
        components: {
            AddOnsSummaryList,
            HotelInfoTime,
            RoomPriceLabel,
            ExtraServicesSummary,
            OrderSummaryLayout,
            HotelStars,
            RateNote,
            OriginalCurrencyNotification,
            HotelMealTypeLabel,
            ConditionsLabel,
            CommissionAmount,
            CorporatePolicyViolation,
            TariffLabel,
            HotelAvailableLabel,
            HotelTypeLabel,
            HotelOwnLabel,
            HotelAddressLabel,
            SupplierNameLabel,
            NetAmount,
            MapLocation,
        },
    })
    export default class HotelOrderSummary extends mixins(OrderSummaryBase, HotelStoreMixin) {
        @Prop({required: true}) offer
        @Prop({required: true}) roomOffers
        @Prop({required: true}) searchRequest
        @Prop({required: true, type: Array}) selectedAddOns
        @Prop({required: true, type: Array}) additionalOptions
        @Prop({required: true, type: Array}) selectedMealTypes

        imgError = false
        mapModal = false

        onImgError() {
            this.imgError = true
            return false
        }

        roomPrice(roomIndex) {
            const roomPrice = this.$options.filters.convertPrice(this.roomOffers[roomIndex].price),
                {taxesAndFeesIncludedSum, serviceFee} = this.roomOffers[roomIndex].price,
                convertPrice = this.$options.filters.convertPrice
            return runtimeStore.netPrice({
                ...roomPrice,
                taxesAndFeesIncludedSum: convertPrice(
                    taxesAndFeesIncludedSum || {currency: this.roomOffers[roomIndex].price.currency, amount: 0}
                ),
                ...(serviceFee && {serviceFee: convertPrice(serviceFee)}),
            })
        }

        showConditionsInfo(room) {
            EventBus.$emit(SHOW_HOTEL_CONDITIONS_INFO, this.searchRequest, this.offer, room)
        }

        roomCheckInOutExtraServices(roomIndex) {
            return hotelsStore.checkInOutExtraService[roomIndex]
                ? Object.values(hotelsStore.checkInOutExtraService[roomIndex])
                : []
        }

        roomInfo(room) {
            EventBus.$emit(SHOW_ROOM_INFO_MODAL_EVENT, this.offer, room.groupedOffers[0].offerKey)
        }

        get showPreferred() {
            return false
        }

        get productStore() {
            return hotelsStore
        }

        get showPrice() {
            return !this.free
        }

        get isTO1() {
            return authStore.isTO1
        }

        get city() {
            return hotelsRuntimeStore.city
        }
    }
</script>
