<template>
    <div v-if="offers.length">
        <v-row>
            <v-col cols="12" md="4">
                <v-card
                    outlined
                    :class="{'info white--text': mode === mods.best}"
                    class="full-height d-flex flex-column"
                    @click="sort(mods.best)"
                >
                    <v-card-title class="text-nobreak">
                        {{ $t('flights.best') }}
                    </v-card-title>
                    <v-card-text class="align-self-end">
                        <price-label
                            :price="best.price"
                            convert-price
                            class="title blue--text text--darken-1 font-weight-black"
                        />
                        <div class="duration">
                            {{ best.sumDuration | durationFormat }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    outlined
                    :class="{'info white--text': mode === mods.fastest}"
                    class="full-height"
                    @click="sort(mods.fastest)"
                >
                    <v-card-title class="text-nobreak">
                        {{ $t('flights.fastest') }}
                    </v-card-title>
                    <v-card-text>
                        <price-label
                            :price="fastest.price"
                            convert-price
                            class="title blue--text text--darken-1 font-weight-black"
                        />
                        <div class="duration">
                            {{ fastest.sumDuration | durationFormat }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    outlined
                    :class="{'info white--text': mode === mods.cheapest}"
                    class="full-height"
                    @click="sort(mods.cheapest)"
                >
                    <v-card-title class="text-nobreak">
                        {{ $t('flights.cheapest') }}
                    </v-card-title>
                    <v-card-text>
                        <price-label
                            :price="cheapest.price"
                            convert-price
                            class="title blue--text text--darken-1 font-weight-black"
                        />
                        <div class="duration">
                            {{ cheapest.sumDuration | durationFormat }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {flightsStore} from '@/store'
    import {flipArray} from '@/utils/helpers'
    import PriceLabel from '~src/components/snippets/priceLabel.src'

    @Component({
        components: {PriceLabel},
    })
    export default class FlightBestOffers extends Vue {
        @Prop({required: true}) offers

        mods = {
            best: 'best',
            fastest: 'durationAsc',
            cheapest: 'priceAsc',
        }

        @Emit()
        sort(sortKey) {
            return sortKey
        }

        get fastest() {
            let best = null
            const offersLength = this.offers.length
            for (let i = 0; i < offersLength; i++) {
                if (!best || this.offers[i].sumDuration < best.sumDuration) {
                    best = this.offers[i]
                }
            }
            return best
        }

        get cheapest() {
            let best = null
            const offersLength = this.offers.length
            for (let i = 0; i < offersLength; i++) {
                if (!best || this.offers[i].price.amount < best.price.amount) {
                    best = this.offers[i]
                }
            }
            return best
        }

        get best() {
            const offersLength = this.offers.length
            const prices = []
            const durations = []
            for (let i = 0; i < offersLength; i++) {
                prices.push(this.offers[i].price.amount)
                durations.push(this.offers[i].sumDuration)
            }
            const uniquePrices = [...new Set(prices)]
            const uniqueDurations = [...new Set(durations)]
            uniquePrices.sort((a, b) => a - b)
            uniqueDurations.sort((a, b) => a - b)
            const flippedPrices = flipArray(uniquePrices)
            const flippedDurations = flipArray(uniqueDurations)
            const offers = [...this.offers]
            for (let i = 0; i < offersLength; i++) {
                offers[i].bestCoef = flippedPrices[offers[i].price.amount] + flippedDurations[offers[i].sumDuration]
            }
            offers.sort((a, b) => a.bestCoef - b.bestCoef)
            return offers[0]
        }

        get mode() {
            return flightsStore.sortFnName
        }
    }
</script>

<style scoped>
    >>> .info .blue--text {
        color: white !important;
    }

    .info .duration {
        color: white;
    }

    .full-height {
        height: 100%;
    }
</style>
