<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderSummaryMixin from '@/components/booking/mixins/OrderSummaryMixin.vue'
    import {activitiesStore} from '@/utils/store-accessor'

    @Component
    export default class ActivityOrderSummaryMixin extends OrderSummaryMixin {
        get orderTotalPrice() {
            return activitiesStore.orderTotalPrice(
                this.offer,
                this.prebooked,
                this.selectedAddOns,
                this.prepareBookResponse
            )
        }
    }
</script>
