<template>
    <div class="caption">
        <v-divider class="my-4" />
        <i>{{ $t('original_currency_notification', {currency}) }}</i>
        <i>{{
            $t('original_currency_amount_notification', {
                currency: originalCurrency,
                price: $options.filters.priceFormat(originalAmount, originalCurrency),
            })
        }}</i>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {persistentStore} from '@/utils/store-accessor'

    @Component
    export default class OriginalCurrencyNotification extends Vue {
        @Prop({required: true}) price

        get currency() {
            return persistentStore.currency
        }

        get originalCurrency() {
            return this.price.originalCurrency || this.price.currency
        }

        get originalAmount() {
            return this.price.originalAmount || this.price.amount
        }
    }
</script>
