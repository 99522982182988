<script>
    import BookingPageBase from '@/components/parts/BookingPageBase'
    import {persistentStore} from '@/utils/store-accessor'
    import {Component} from 'vue-property-decorator'

    @Component
    export default class BookingPageBaseMod extends BookingPageBase {
        async getPaymentOptions() {
            const convertToCurrency =
                this.tourLead.citizenshipId === 3854 || this.tourLead.citizenshipId === 23677 ? 'BYN' : 'EUR'
            try {
                await persistentStore.getPaymentOptions({
                    bookingKey: this.productStore.prepareBookResponse.bookingKey,
                    convertToCurrency,
                })
            } catch (e) {
                this.$toast.warning(this.$t('error_message.get_payment_options_unknown_error'))
            }
        }
    }
</script>
