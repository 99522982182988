<template>
    <div v-intersect.once="onIntersect" class="mt-5 mb-5">
        <div ref="map" class="map" />
        <div ref="controls" class="mt-3 ml-3 d-flex align-center controls">
            <hotel-map-direction-filter
                v-if="locationFilter"
                ref="filter"
                :google="google"
                :map="map"
                :markers="markers"
                :city="city"
            />
            <v-btn v-if="othersOffers.length" color="white" small class="me-3" @click="viewAllHotels">
                {{ !showAllHotels ? $t('show_all_hotels') : $t('hide_all_hotels') }}
            </v-btn>
        </div>
        <div v-if="distance">
            <strong>{{ $t('distance') }}:</strong>
            {{ distance }} {{ $t('km_from_center') }}
        </div>
        <div v-if="district">
            <strong>{{ $t('district') }}:</strong>
            {{ district }}
        </div>
    </div>
</template>

<script>
    import {Component, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'
    import gmapsInit from '~/utils/gmaps'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'
    import HotelMapDirectionFilter from '@/components/hotels/snippets/HotelMapDirectionFilter'

    @Component({components: {HotelMapDirectionFilter}})
    export default class HotelInfoMap extends mixins(HotelMapMixin) {
        @Prop({default: null}) city
        @Prop({default: false, type: Boolean}) locationFilter

        google = null
        map = null
        markers = []
        showAllHotels = false

        @Watch('city')
        changeCity() {
            if (!this.map) return
            this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        async load() {
            try {
                this.google = await gmapsInit()
                const map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    //mapTypeControl: true,
                    zoomControl: true,
                    fullscreenControl: true,
                    zoom: 8,
                })
                if (this.$refs.controls) {
                    map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(this.$refs.controls)
                }
                this.map = map
                this.addMarker(this.info)
                if (this.markers.length) {
                    this.map.setZoom(16)
                    this.map.setCenter(this.markers[0].position)
                } else if (this.city) {
                    this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
                }
            } catch (error) {
                console.error(error)
            }
        }

        viewAllHotels() {
            this.showAllHotels = !this.showAllHotels

            if (this.showAllHotels) {
                const icon = this.createMarkerIcon(this.info)
                icon.scale = 3
                this.markers[0].setIcon(icon)
                this.othersOffers.map(offer => {
                    this.addMarker(offer)
                })
            } else {
                this.markers[0].setIcon(this.createMarkerIcon(this.info))
                this.markers.map((marker, index) => {
                    if (index !== 0) {
                        marker.setMap(null)
                    }
                })
                this.markers.splice(1, this.markers.length - 2)
            }
        }

        onIntersect() {}

        get info() {
            return {
                ...hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo,
                supplierCode: hotelsRuntimeStore.hotelInfoRequest.supplierCode,
                ...(this.$route.query.own && {own: true}),
                ...(this.offer?.internalNote && {internalNote: this.offer?.internalNote}),
            }
        }

        get offer() {
            return hotelsStore.singleHotelSearchResponse.offers.length
                ? hotelsStore.singleHotelSearchResponse.offers[0]
                : null
        }

        get othersOffers() {
            return hotelsRuntimeStore.filteredOffers.filter(offer => offer.hotelCode !== this.offer?.hotelCode) || []
        }

        get distance() {
            return this.info.distanceFromCenter
        }

        get district() {
            return this.offer ? this.offer.district : null
        }
    }
</script>

<style scoped lang="scss">
    .map {
        height: 400px;
        z-index: 1;
    }

    ::v-deep .gm-style-iw button:focus {
        outline: 0;
    }
</style>
