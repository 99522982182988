<template>
    <v-dialog v-model="modal" width="1200">
        <v-card :loading="loading">
            <v-card-title>
                {{ $t('fxGainLoss') }}
                <v-icon class="v-card-close mt-3 me-3" @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    v-if="info"
                    :headers="headers"
                    :items="info.items"
                    :sort-by="'supplierName'"
                    :hide-default-header="isMobile"
                    :single-expand="isMobile"
                >
                    <template v-slot:item.name="{item}">
                        <span class="font-weight-bold">
                            <v-icon v-if="item.name === 'Payment'" left small> mdi-cash </v-icon>
                            {{ item.name }}
                        </span>
                    </template>
                    <template v-slot:item.date="{item}">
                        <span>
                            {{ item.date | dateShortFormat }}
                        </span>
                    </template>
                    <template v-slot:item.amount="{item}">
                        <span>
                            {{ item.amount | priceFormat(item.currency) }}
                        </span>
                    </template>
                    <template v-slot:item.convertedAmount="{item}">
                        <span :class="{'red--text': item.name !== 'Payment', 'success--text': item.name === 'Payment'}">
                            {{ item.convertedAmount | priceFormat(item.convertedCurrency) }}
                        </span>
                    </template>
                    <template v-slot:foot>
                        <tr v-if="totalFxGainLoss && !hideTotal">
                            <td class="px-4 py-4 text-subtitle-1">
                                <span class="font-weight-bold">{{ $t('total_fx') }}</span>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td class="px-4 py-4 text-subtitle-1" style="text-align: end">
                                <span class="font-weight-bold">
                                    {{ totalFxGainLoss | price }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'

    @Component
    export default class FxGainModal extends Vue {
        @VModel() modal
        @Prop() orderId
        @Prop({default: false}) hideTotal

        loading = false
        info = null

        @Watch('modal')
        async onModalChange() {
            if (this.modal) {
                this.info = null
                await this.loadFxGainLossInfo()
            }
        }

        async loadFxGainLossInfo() {
            this.loading = true

            try {
                this.info = await this.$api.orderFxGainLossDetailedInfo.get({orderId: this.orderId})
                this.info.items.sort(a => (a.name === 'Payment' ? 1 : -1))

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        get headers() {
            return [
                {text: this.$t('service_or_payment'), value: 'name'},
                {text: this.$t('booking_or_payment_date'), value: 'date'},
                {text: this.$t('supplier_name'), value: 'supplierName'},
                {text: this.$t('amount'), value: 'amount'},
                {text: this.$t('booking_or_payment_xr'), value: 'exchangeRate', align: 'right'},
                {text: this.$t('converted_amount'), value: 'convertedAmount', align: 'right'},
            ]
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get totalFxGainLoss() {
            return this.info?.fxGainLossAmount
                ? {amount: this.info?.fxGainLossAmount, currency: this.info?.fxGainLossCurrency}
                : null
        }
    }
</script>
