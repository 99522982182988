<template>
    <flight-booking-page-layout
        v-if="loaded"
        :offer="offer"
        :search-request="searchRequest"
        :special-remark-r-m="specialRemarkRM"
        :search-request-country-id="searchRequestCountryId"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:tourists="{tourists, index, disabled, personSelected, isSavingWithoutDetails}">
            <flight-tourist-form
                v-model="tourists[index]"
                :disabled="disabled"
                :index="index"
                :offer="offer"
                :hide-index="tourists.length === 1"
                :selected-tourists="tourists"
                :start-date="startDate"
                :end-date="endDate"
                :data-cy="`tourist-${index}`"
                :disable-required-rule="isSavingWithoutDetails"
                @person-selected="personSelected"
            />
            <extra-baggage-form
                v-model="prepareBookRequest"
                :index="index"
                :disabled="disabled"
                :offer="offer"
                :extra-baggage-options="extraBaggageOptions"
            />
        </template>
        <template v-slot:orderCommentsForm="{bookingKey}">
            <special-remark-r-m v-if="isShowSpecialRemarkRM" v-model="specialRemarkRM" :disabled="!!bookingKey" />
        </template>
        <template v-slot:forms="{bookingKey, extraServices}">
            <extra-services-form
                v-if="
                    productStore.bookingAdditionalOptions[0] &&
                    productStore.bookingAdditionalOptions[0].extraServices &&
                    productStore.bookingAdditionalOptions[0].extraServices.length
                "
                v-model="extraServices[0]"
                :disabled="!!bookingKey"
                :additional-options="productStore.bookingAdditionalOptions[0]"
                class="extra-services"
                @update="bookingKey = false"
            />
            <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
            <flight-seats-map
                v-model="prepareBookRequest"
                :offer="offer"
                :disabled="!!bookingKey"
                :search-request="searchRequest"
                :tourists="tourists"
                @map-loaded="flightSeatsMap = $event"
            />
        </template>
        <template v-slot:sidebar="{bookingKey, extraServices, prepareBookResponse}">
            <flight-order-summary
                :offer="offer"
                :prebooked="!!bookingKey"
                :flight-seats-map="flightSeatsMap"
                :extra-services="extraServices"
                :extra-baggage-options="extraBaggageOptions"
                :additional-options="productStore.bookingAdditionalOptions"
                :prepare-book-response="prepareBookResponse"
            >
                <template v-slot:summary>
                    <v-divider class="my-2 mb-3" />
                    <v-card flat tile class="mb-1 pa-0">
                        <v-card-text class="pa-0">
                            <search-summary-content :search-request="searchRequest" show-tourists />
                            <pricing-source-type-label :service="offer" />
                        </v-card-text>
                    </v-card>
                </template>
            </flight-order-summary>
        </template>
    </flight-booking-page-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {flightsRuntimeStore, flightsStore, persistentStore, runtimeStore} from '@/store'
    import FlightBookingPageLayout from '~/components/flights/booking/FlightBookingPageLayout'
    import FlightTouristForm from '~src/components/booking/forms/flightTouristForm.src'
    import FlightOrderSummary from '~/components/flights/booking/FlightOrderSummary'
    import FlightBreadcrumbs from '~/components/flights/FlightBreadcrumbs'
    import FlightPageMixin from '~/components/flights/mixins/FlightPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import FlightSeatsMap from '@/components/flights/booking/FlightSeatsMap'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import ExtraBaggageForm from '@/components/flights/booking/ExtraBaggageForm.vue'
    import PricingSourceTypeLabel from '@/components/snippets/PricingSourceTypeLabel.vue'
    import SpecialRemarkRM from '~~/mods/prestigetravel/components/booking/forms/SpecialRemarkRM.vue'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm'

    @Component({
        components: {
            ExtraServicesForm,
            SpecialRemarkRM,
            PricingSourceTypeLabel,
            ExtraBaggageForm,
            FlightSeatsMap,
            ExpiredOffersSnackbar,
            FlightBookingPageLayout,
            FlightTouristForm,
            FlightOrderSummary,
            FlightBreadcrumbs,
            BookingCommentsForm,
            SearchSummaryContent,
        },
        layout: 'blank',
    })
    export default class FlightBookingPage extends mixins(FlightPageMixin) {
        loaded = false
        flightSeatsMap = {}
        specialRemarkRM = ''

        validate({query}) {
            return query.offerKey
        }

        @Watch('locale')
        loadFlightPreferences() {
            const {offerKey} = this.$route.query
            flightsStore.loadFlightPreferences(offerKey)
        }

        async mounted() {
            const {offerKey} = this.$route.query
            await this.$store.restored
            this.productStore.clearBookingAdditionalOptions()

            await Promise.all([
                this.loadRuntimeData(),
                runtimeStore.loadTouristBookingFields({offerKey: offerKey}),
                this.productStore.loadBookingAdditionalOptions(offerKey),
                flightsStore.loadFlightPreferences(offerKey),
            ])
            this.loaded = true
        }

        refreshExpiredOffers() {
            this.$router.push(flightsRuntimeStore.flightsPageLink(this.searchRequest))
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !flightsStore.prepareBookResponse.bookingKey
            )
        }

        get extraBaggageOptions() {
            return this.productStore.bookingAdditionalOptions?.extraBaggageOptions
        }

        get basketItem() {
            return flightsStore.basketItem(this.$route.query.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get locale() {
            return this.$i18n.locale
        }

        get productStore() {
            return flightsStore
        }

        get searchRequestCountryId() {
            return flightsRuntimeStore.routes[0]?.arrival?.countryId
        }

        get startDate() {
            return flightsRuntimeStore.routes[0].date
        }

        get endDate() {
            return this.offer.itinerary[this.offer.itinerary.length - 1].segments[
                this.offer.itinerary[this.offer.itinerary.length - 1].segments.length - 1
            ].arrival.date?.split(' ')?.[0]
        }

        get prepareBookRequest() {
            return new Proxy(flightsStore.prepareBookRequest, {
                set: (target, prop, val) => {
                    flightsStore.SET_PREPARE_BOOK_REQUEST_PROP({prop, val})
                    return true
                },
                get: (target, prop) => {
                    return target[prop]
                },
            })
        }

        get tourists() {
            return persistentStore.tourists
        }

        get isShowSpecialRemarkRM() {
            return false
        }
    }
</script>
