<template>
    <div>
        <div v-for="(description, index) in descriptions" :key="index">
            <h4>{{ descriptionTypeLabel(description) }}</h4>
            <div v-for="(text, textIndex) in description.descriptions" :key="textIndex" class="mb-2" v-html="text" />
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'
    import {unescape} from 'lodash'

    @Component
    export default class HotelInfoDescriptions extends Vue {
        @Prop({default: null}) descriptionsProp

        descriptionTypeLabel(description) {
            if (this.$te(`description_type.${description?.type.toLowerCase()}`)) {
                return this.$t(`description_type.${description?.type.toLowerCase()}`)
            }

            return description?.type
        }

        get descriptions() {
            const descriptions =
                    this.descriptionsProp ||
                    (hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.descriptions
                        ? hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.descriptions.description
                        : []),
                groupedDescriptions = []
            descriptions.forEach(description => {
                const groupedDescriptionIndex = groupedDescriptions.findIndex(
                    groupedDescription => groupedDescription.type === description.type
                )
                if (groupedDescriptionIndex === -1) {
                    groupedDescriptions.push({
                        type: description.type,
                        descriptions: [unescape(description.description)],
                    })
                } else {
                    groupedDescriptions[groupedDescriptionIndex].descriptions.push(unescape(description.description))
                }
            })
            groupedDescriptions
                .sort(item => (item.type === 'general' ? -1 : 1))
                .sort(item => (item.type === 'short' ? -1 : 1))
            if (
                groupedDescriptions[0] &&
                groupedDescriptions[0].type === 'short' &&
                groupedDescriptions[1] &&
                groupedDescriptions[1].type === 'general' &&
                groupedDescriptions[1].descriptions[0].indexOf(groupedDescriptions[0].descriptions[0]) !== -1
            ) {
                groupedDescriptions.splice(0, 1)
            }
            return groupedDescriptions
        }
    }
</script>

<style scoped></style>
