<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelOwnLabel extends Vue {
        @Prop({required: true}) offer

        render() {
            return null
        }
    }
</script>
