<script>
    import {Component, Vue, Watch} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'

    //TODO Need to remove this fucking shit
    @Component
    export default class AdditionalSearchOptionsMixin extends Vue {
        selectedCitizenship = null

        @Watch('selectedCitizenship.value')
        onCitizenshipChanged() {
            this.setCurrencyByCitizenship()
        }

        mounted() {
            const oldQuery = this.newQuery
            Object.defineProperty(this, 'newQuery', {
                value: () => {
                    const query = oldQuery()
                    const add = this.getAdditionalOptions()
                    return Object.assign(query, add)
                },
            })
            this.setDefaultCitizenship()
        }

        setCurrencyByCitizenship() {
            const currencyToCountryMap = this.$config.currency.bundledToCountry

            if (!currencyToCountryMap) {
                return
            }

            const countryIdSelected = +this.city?.countryId
            const citizenshipIdSelected = +this.selectedCitizenship?.value

            let currencyChanged = false

            for (const [currencyCode, countryBundle] of Object.entries(currencyToCountryMap)) {
                const configCountryId = +countryBundle?.countryId
                const configCitizenshipId = +countryBundle?.citizenshipId

                if (configCountryId && configCitizenshipId) {
                    if (configCountryId === countryIdSelected && citizenshipIdSelected === configCitizenshipId) {
                        currencyChanged = true
                        return persistentStore.SET_CURRENCY(currencyCode)
                    }
                } else if (configCountryId || configCitizenshipId) {
                    if (configCountryId === countryIdSelected || citizenshipIdSelected === configCitizenshipId) {
                        currencyChanged = true
                        return persistentStore.SET_CURRENCY(currencyCode)
                    }
                }
            }

            if (!currencyChanged) {
                return persistentStore.SET_CURRENCY(this.$config.currency.default)
            }
        }

        getAdditionalOptions() {
            const form = this.$refs.addForm.$refs['additional-form']
            if (form) {
                const values = [
                    ...form.$children.map(child => child.$refs.option),
                    ...form.$children.map(child => child.$refs.altOption).filter(Boolean),
                ]
                const params = {}
                values.forEach(el => {
                    if (el?.$attrs?.name) {
                        params[el.$attrs.name] =
                            el.$parent.value !== undefined
                                ? el.$parent.valueName
                                    ? el.$parent.valueName
                                    : el.$parent.value
                                : el.value
                    }
                })
                const query = Object.fromEntries(Object.entries(params).filter(el => el[1] !== undefined))

                //Workaround for GPFE-4983 [prestige]
                if (+(query.transferCategoryCode || query.excursionCategoryCode) === query.productVariantId) {
                    delete query.transferCategoryCode
                    delete query.excursionCategoryCode
                }
                const {transferCategoryCode, excursionCategoryCode, productVariantId} = query
                const categoryCode = transferCategoryCode || excursionCategoryCode
                if (categoryCode && productVariantId) {
                    query.internalSuppliers = true
                    query.externalSuppliers = true
                } else if (productVariantId) {
                    query.externalSuppliers = false
                    query.internalSuppliers = true
                } else if (categoryCode) {
                    query.internalSuppliers = false
                    query.externalSuppliers = true
                }

                return query
            }
            return {}
        }

        changeCitizenship(value) {
            this.selectedCitizenship = {value}
        }

        setDefaultCitizenship() {
            const defaultCitizenshipId =
                +this.$route.query.citizenshipId ||
                persistentStore.selectedAgent?.companyCountryId ||
                authStore.person?.citizenshipId ||
                this.$config.defaultCitizenshipId
            if (defaultCitizenshipId) {
                this.selectedCitizenship = this.citizenshipItems.find(e => e.value === defaultCitizenshipId)
            }
        }

        get isCitizenshipMainOption() {
            return this.$config.isCitizenshipMainOption
        }

        get citizenshipItems() {
            return runtimeStore.restrictedCountries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }
    }
</script>
