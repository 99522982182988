<template>
    <div class="checkbox-wrapper">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ $t(`filterTitles.${filter}`) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="checkbox-container">
                        <v-col
                            v-for="(group, groupName) in groupedValues"
                            :key="groupName"
                            cols="12"
                            class="pe-4 py-0 pb-4"
                        >
                            <span
                                class="text-caption font-weight-bold text--secondary"
                                v-text="
                                    $te(`service.${groupName.toLowerCase()}`)
                                        ? $t(`service.${groupName.toLowerCase()}`)
                                        : capitalizeTheFirstLetterOfEachWord(groupName)
                                "
                            />
                            <div v-for="(item, index) in group" :key="`${index}-${item.value}`" class="ps-2">
                                <v-checkbox
                                    v-model="checked"
                                    small
                                    :hide-details="true"
                                    class="py-0 my-0"
                                    :value="item.value"
                                    :ripple="!$breakpoint.smAndDown"
                                >
                                    <template v-slot:label>
                                        <div class="d-flex label-wrapper full-width">
                                            <div class="label-text">
                                                {{ getValue(item) }}
                                            </div>
                                            <div class="ms-auto filter-qty font-weight-bold">
                                                {{ `${item.count}` }}
                                            </div>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                    <v-btn v-if="values.length > showLimit" block x-small text @click="showAll = !showAll">
                        {{ showAll ? $t('hide') : $t('show') }} {{ $t('all') }}
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import {capitalizeTheFirstLetterOfEachWord} from '@/utils/helpers'

    @Component
    export default class CheckboxFilterGrouped extends CheckboxFilter {
        capitalizeTheFirstLetterOfEachWord(val) {
            return capitalizeTheFirstLetterOfEachWord(val.toLowerCase())
        }

        get groupedValues() {
            const grouped = {}

            this.partOfValues.forEach(filterValue => {
                if (filterValue.groupName) {
                    grouped[filterValue.groupName]
                        ? grouped[filterValue.groupName].push(filterValue)
                        : (grouped[filterValue.groupName] = [filterValue])
                } else {
                    grouped.other ? grouped.other.push(filterValue) : (grouped.other = [filterValue])
                }
            })

            return grouped
        }
    }
</script>
