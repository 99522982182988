<template>
    <v-btn v-if="$breakpoint.smAndDown" block color="primary" class="sticky" @click="$emit('showOffers')">
        {{ $t('view_offers') }}
    </v-btn>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class ViewOffersButton extends Vue {}
</script>

<style scoped>
    .sticky {
        position: sticky;
        bottom: 8px;
        left: 0;
    }
</style>
