<template>
    <v-dialog v-model="modal" hide-overlay :persistent="loading">
        <v-card :loading="bookingFieldsLoading">
            <v-card-title class="white--text primary">
                {{ serviceDetails.transferName }}
            </v-card-title>
            <v-card-text
                v-if="bookingFieldsLoading || !dataReady"
                class="d-flex full-width align-center justify-center pa-8"
            >
                <v-progress-circular color="primary" indeterminate size="64" />
            </v-card-text>
            <template v-else>
                <v-card-text>
                    <div class="my-5">
                        <template v-for="(tourist, index) in tourists">
                            <tourist-form
                                :key="index"
                                :ref="`tourist${index}`"
                                v-model="tourists[index]"
                                :index="index"
                                :disabled="loading"
                                :hide-index="tourists.length === 1"
                                :selected-tourists="tourists"
                                :start-date="startDate"
                                :data-cy="`tourist-${index}`"
                                label="passenger"
                                @person-selected="personSelected($event)"
                            />
                            <v-divider v-if="tourists.length > index + 1" :key="index + '_divider'" class="mb-4" />
                        </template>
                    </div>
                    <transfer-booking-forms
                        v-model="transferFormsValid"
                        :request="prepareBookRequest"
                        :departure-point="departurePoint"
                        :product="product"
                        :disabled="loading"
                        :search-request="searchRequest"
                        :arrival-point="arrivalPoint"
                        :offer="offer"
                        :transfer-info="transferInfo"
                        @change="setPrepareBookRequest"
                        @change-location="setPrepareBookLocation"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" :disabled="!valid || loading" :loading="loading" @click="save">
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn text :disabled="loading" @click="modal = false">{{ $t('cancel') }}</v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore, transfersStore} from '@/utils/store-accessor'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import BookingTouristsMixin from '@/components/booking/mixins/BookingTouristsMixin'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import TransferBookingForms from '@/components/transfers/booking/forms/TransferBookingForms.vue'
    import FillServiceBookingDataMixin from '@/components/account/mixins/FillServiceBookingDataMixin'

    //TODO [P1] Need review and refactoring all transfer form components and them usage
    @Component({
        components: {
            TransferBookingForms,
            TouristForm,
        },
    })
    export default class FillTransferBookingDataModal extends mixins(
        TransferPageMixin,
        BookingTouristsMixin,
        TransferStoreMixin,
        FillServiceBookingDataMixin
    ) {
        @VModel() modal
        @Prop({required: true}) service
        @Prop({required: true}) order
        @Prop() transferInfo
        @Prop() departurePointProp
        @Prop() arrivalPointProp

        loading = false
        dataReady = false
        transferFormsValid = false
        allTouristsValid = false

        @Watch('tourists', {deep: true})
        async onTouristsChange() {
            await this.$nextTick(() => {})
            this.setAllTouristsValid()
        }

        @Watch('modal')
        async onModalOpen() {
            if (this.modal) {
                this.dataReady = false
                this.setTourists()
                await Promise.all([this.loadTouristBookingFields(), this.loadProductBookingFields()])
                const {pickupTime: time, arrivalPlace, departurePlace} = this.serviceDetails
                const pickupSign = this.service.travelers[0].pickUpSign
                const point = ({
                    hotelName,
                    hotelCode,
                    airportCode,
                    trainStationCode,
                    trainNumber,
                    dateTime,
                    flightNumber,
                    otherAirportCode,
                    latitude,
                    portCode,
                    portName,
                    shipNumber,
                    address,
                    postalCode,
                    date,
                    time,
                }) => {
                    return {
                        ...(hotelName && {hotelName}),
                        ...(dateTime && {dateTime: dateTime.replace(' ', 'T')}),
                        ...(flightNumber && {flightNumber}),
                        ...(trainNumber && {trainNumber}),
                        ...(otherAirportCode && {otherAirportCode}),
                        ...(shipNumber && {shipNumber}),
                        ...(address && !(hotelName || hotelCode) && {address}),
                        ...(postalCode && {postalCode}),
                        ...(date && {date}),
                        ...(time && {time}),
                        ...(hotelCode && {hotelCode}),
                        type: this.pointType({
                            airportCode,
                            trainStationCode,
                            trainNumber,
                            latitude,
                            portName,
                            portCode,
                            shipNumber,
                            address,
                            hotelName,
                            hotelCode,
                        }),
                    }
                }
                const prepareBookRequest = {
                    departure: point({
                        ...this.departurePointProp.location,
                        ...this.departurePointProp,
                        ...departurePlace,
                    }),
                    arrival: point({
                        ...this.arrivalPointProp.location,
                        ...this.arrivalPointProp,
                        ...arrivalPlace,
                    }),
                    ...(time && {time}),
                    ...(pickupSign && {pickupSign}),
                }
                const startDateTime = this.service.serviceDetails[0].departurePlace.dateTime
                const endDateTime = this.service.serviceDetails[0].arrivalPlace.dateTime

                if (prepareBookRequest.arrival && endDateTime)
                    prepareBookRequest.arrival.dateTime = endDateTime.split(' ').join('T')
                if (prepareBookRequest.departure && startDateTime)
                    prepareBookRequest.departure.dateTime = startDateTime.split(' ').join('T')

                transfersStore.SET_PREPARE_BOOK_REQUEST(prepareBookRequest)
                this.dataReady = true
                await this.$nextTick()
                this.setAllTouristsValid()
            } else {
                this.beforeLeave()
            }
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
        }

        beforeLeave() {
            transfersStore.SET_PREPARE_BOOK_REQUEST({
                departure: {},
                arrival: {},
            })
        }

        async save() {
            try {
                this.loading = true
                await this.$api.fillTransferBookingData.post({
                    ...transfersStore.prepareBookRequest,
                    arrival:
                        transfersStore.prepareBookRequest.arrival.hotelName &&
                        transfersStore.prepareBookRequest.arrival.type === 'hotel'
                            ? {...transfersStore.prepareBookRequest.arrival, type: 'hotel'}
                            : transfersStore.prepareBookRequest.arrival.address &&
                              transfersStore.prepareBookRequest.arrival.type === 'hotel'
                            ? {...transfersStore.prepareBookRequest.arrival, type: 'address'}
                            : transfersStore.prepareBookRequest.arrival.type === 'cruisePort'
                            ? {...transfersStore.prepareBookRequest.arrival, type: 'port'}
                            : transfersStore.prepareBookRequest.arrival.type === 'city'
                            ? {...transfersStore.prepareBookRequest.arrival, type: 'address'}
                            : transfersStore.prepareBookRequest.arrival.type === 'trainStation'
                            ? {...transfersStore.prepareBookRequest.arrival, type: 'train'}
                            : {...transfersStore.prepareBookRequest.arrival},
                    departure:
                        transfersStore.prepareBookRequest.departure.hotelName &&
                        transfersStore.prepareBookRequest.departure.type === 'hotel'
                            ? {...transfersStore.prepareBookRequest.departure, type: 'hotel'}
                            : transfersStore.prepareBookRequest.departure.address &&
                              transfersStore.prepareBookRequest.departure.type === 'hotel'
                            ? {...transfersStore.prepareBookRequest.departure, type: 'address'}
                            : transfersStore.prepareBookRequest.departure.type === 'cruisePort'
                            ? {...transfersStore.prepareBookRequest.departure, type: 'port'}
                            : transfersStore.prepareBookRequest.departure.type === 'city'
                            ? {...transfersStore.prepareBookRequest.departure, type: 'address'}
                            : transfersStore.prepareBookRequest.departure.type === 'trainStation'
                            ? {...transfersStore.prepareBookRequest.departure, type: 'train'}
                            : {...transfersStore.prepareBookRequest.departure},
                    tourists: this.prepareBookTourists(this.tourists),
                    processId: this.service.processId,
                })
                await runtimeStore.refreshOrder()
                this.$emit('save-booking-data')
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.modal = false
                this.loading = false
            }
        }

        pointType({
            airportCode,
            trainStationCode,
            trainNumber,
            latitude,
            portCode,
            portName,
            shipNumber,
            hotelName,
            hotelCode,
        }) {
            if (airportCode) {
                return 'airport'
            } else if (trainStationCode || trainNumber) {
                return 'trainStation'
            } else if (portCode || portName || shipNumber) {
                return 'cruisePort'
            } else if (latitude) {
                return 'manual'
            } else if (hotelName && hotelCode) {
                return 'hotel'
            }
            return 'city'
        }

        personSelected({person, index}) {
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                persistentStore.SET_TOURIST({index, val})
            } else {
                persistentStore.SET_TOURIST_PROP({index, prop: 'personId', val: null})
            }
        }

        setPrepareBookRequest({prop, val}) {
            transfersStore.SET_PREPARE_BOOK_REQUEST_PROP({prop, val})
        }

        setPrepareBookLocation({prop, val, pointType}) {
            transfersStore.SET_PREPARE_BOOK_LOCATION_PROP({prop, val, pointType})
        }

        get departurePoint() {
            const name = this.departurePointProp.name

            return {type: this.pointType(this.serviceDetails.departurePlace), name}
        }

        get arrivalPoint() {
            const name = this.arrivalPointProp.name

            return {type: this.pointType(this.serviceDetails.arrivalPlace), name}
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get offer() {
            const {transferType} = this.serviceDetails
            return {
                transferType,
                time: [],
            }
        }

        get product() {
            const {supplierCode} = this.service
            return {
                info: {
                    supplierCode,
                },
            }
        }

        setAllTouristsValid() {
            const validation = []

            this.tourists.forEach((t, i) => {
                validation.push(this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.validate())
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.resetValidation()
            })

            this.allTouristsValid = validation.every(el => el === true)
        }

        get searchRequest() {
            const {arrivalCityId, arrivalPlace, departureCityId, departurePlace} = this.serviceDetails

            return {
                dateTime: this.service.startDateTime.split(' ')[0],
                ...(arrivalPlace.hotelName && {arrivalHotelName: arrivalPlace.hotelName}),
                arrivalPlace: arrivalPlace.airportCode || 'hotel',
                arrivalCityId,
                ...(departurePlace.hotelName && {departureHotelName: departurePlace.hotelName}),
                departurePlace: departurePlace.airportCode || 'hotel',
                departureCityId,
                adults: this.service.travelers.filter(({isChild}) => !isChild).length,
                childrenAges: this.service.travelers.reduce((childrenAges, {isChild, dateOfBirth}) => {
                    //TODO Missing age prop in get orders
                    if (isChild) {
                        if (dateOfBirth) {
                            childrenAges.push(
                                this.$dateFns.differenceInYears(
                                    new Date(this.service.startDateTime),
                                    this.$dateFns.parseISO(dateOfBirth)
                                )
                            )
                        } else {
                            childrenAges.push(4)
                        }
                    }
                    return childrenAges
                }, []),
            }
        }

        get valid() {
            return this.transferFormsValid && this.allTouristsValid
        }

        get startDate() {
            return this.searchRequest.dateTime
        }

        get prepareBookRequest() {
            return transfersStore.prepareBookRequest

            // const pickupSign = this.service.travelers[0].pickUpSign
            // const startDateTime = this.service.startDateTime
            // const endDateTime = this.service.endDateTime
            // const prepareBookRequest = clone(transfersStore.prepareBookRequest)

            // prepareBookRequest.departure = Object.assign(
            //     prepareBookRequest.departure,
            //     this.departurePointProp.location,
            //     this.departurePointProp,
            //     this.serviceDetails.departurePlace
            // )
            // prepareBookRequest.arrival = Object.assign(
            //     prepareBookRequest.arrival,
            //     this.arrivalPointProp.location,
            //     this.arrivalPointProp,
            //     this.serviceDetails.arrivalPlace
            // )

            // if (pickupSign) prepareBookRequest.pickupSign
            // if (prepareBookRequest.arrival && endDateTime)
            //     prepareBookRequest.arrival.dateTime = endDateTime.split(' ').join('T')
            // if (prepareBookRequest.departure && startDateTime)
            //     prepareBookRequest.departure.dateTime = startDateTime.split(' ').join('T')

            // return prepareBookRequest
        }
    }
</script>
