<template>
    <v-text-field
        :key="key"
        ref="option"
        :hint="$t('carsRent.from_18_to_120').toLocaleLowerCase()"
        :name="name"
        :prefix="`${$t('age')}:`"
        :rules="required ? [requiredRule('age')] : []"
        :value="age"
        background-color="white"
        class="input--no-arrows"
        data-cy="adults-selector"
        outlined
        single-line
        type="number"
        @input="inputAge"
    />
</template>

<script>
    import {Component, Prop, VModel} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin.vue'

    @Component
    export default class AgeSelect extends FormValidationRulesMixin {
        @VModel() age
        @Prop({required: true}) name
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) required

        key = 0

        inputAge(v) {
            if (!v) {
                this.age = null
                return
            }
            let value = parseInt(v.length > 3 ? v.slice(-1) : v, 10)
            if (value < 18 && String(value).length > 1) value = 18
            else if (value === 0 && String(value).length <= 1) value = 18
            else if (value > 120) value = 120
            if (this.age === value) this.key++
            this.age = value
        }
    }
</script>
