<template>
    <v-tooltip top>
        <template v-slot:activator="{on, attrs}">
            <v-chip
                v-bind="attrs"
                :color="specialOfferChipColor"
                small
                class="caption cursor-pointer"
                v-on="details && !isMobile ? on : null"
            >
                {{ $t('special_offer') }}</v-chip
            >
        </template>
        <span>{{ details }}</span>
    </v-tooltip>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class SpecialOfferLabel extends Vue {
        @Prop() details

        get specialOfferChipColor() {
            return 'success'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
