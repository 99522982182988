<template>
    <div v-if="product.info">
        <div v-if="product.info.description">
            {{ product.info.description }}
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class TransferInfo extends Vue {
        @Prop({required: true}) product
    }
</script>
