<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {packagesRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class PackagePageMixin extends Vue {
        async loadRuntimeData() {
            const {arrivalCityId, departureCityId} = this.searchRequest
            if (
                packagesRuntimeStore.arrivalPoint.id !== arrivalCityId ||
                packagesRuntimeStore.departurePoint.id !== departureCityId
            ) {
                await packagesRuntimeStore.loadPoints({arrivalCityId, departureCityId})
            }
        }
    }
</script>
