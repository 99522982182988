<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderSummaryMixin from '@/components/booking/mixins/OrderSummaryMixin.vue'
    import {transfersStore} from '@/utils/store-accessor'
    import {calcExtraServicesTotalPrice, calcTotalPrice} from '@/utils/price-utils'

    @Component
    export default class TransferOrderSummaryMixin extends OrderSummaryMixin {
        get orderTotalPrice() {
            const orderTotalPrice = transfersStore.orderTotalPrice(
                this.offer,
                this.prebooked,
                [],
                this.prepareBookResponse
            )
            if (this.prebooked) return orderTotalPrice
            if (this.returnTransferOffer) {
                return calcTotalPrice([
                    orderTotalPrice,
                    transfersStore.orderTotalPrice(this.returnTransferOffer, this.prebooked),
                ])
            }
            return orderTotalPrice
        }

        get extraPrices() {
            return this.extraServices.map((extraServices, index) =>
                calcExtraServicesTotalPrice(this.additionalOptions[index], extraServices)
            )
        }
    }
</script>
