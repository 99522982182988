<template>
    <v-card
        class="hotel-card"
        :class="{pointer: compareMode}"
        outlined
        :hover="compareMode"
        @click.native="selectToCompare(offer)"
    >
        <component
            :is="imageWrapper"
            no-prefetch
            :to="hotelPageLink"
            :href="$router.resolve(hotelPageLink).href"
            target="_blank"
            class="title text-decoration-none"
        >
            <v-img
                class="white--text align-end"
                height="200"
                :src="offer.thumb | imageUrl(600)"
                :alt="offer.name"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
                <v-card-title>
                    <hotel-stars class="me-3" :hotel="offer" />
                    <div class="break-word text-subtitle-1">
                        {{ offer.name }}
                    </div>
                </v-card-title>
            </v-img>
            <recommended-icon v-if="offer.recommended && !showPreferred" class="recommended-thumb" />
            <v-img
                v-if="offer.recommended && showPreferred"
                :src="'/preferred.png' | srcHost"
                :width="120"
                class="preferred-thumb"
            />
            <v-img
                v-if="offer.tariffRecommended && showPreferred"
                :src="'/long-stay.png' | srcHost"
                :width="120"
                class="long-stay"
            />
        </component>
        <div>
            <supplier-name-label v-if="isTO1" :supplier-name="offer.supplierName" class="mt-1" />
            <div class="d-flex align-center mt-1">
                <hotel-type-label :offer="offer" />
                <!--v-chip
                    v-for="(classification, classificationIndex) in offer.classifications"
                    :key="classificationIndex"
                    x-small
                    class="ma-1"
                    v-text="classification"
                /-->
                <hotel-own-label class="ms-1" :offer="offer" />
            </div>
            <hotel-location-label :offer="offer" />
            <hotel-address-label :address="offer.address" :offer="offer" />
            <hotel-services :offer="offer" class="mt-3" />
            <tripadvisor-rating-wrapper :tripadvisor-rating="offer.tripadvisorRating" />
            <template v-if="showRating">
                <v-divider class="my-2" />
                <supplier-rating :rating-object="offer.supplierRating" />
            </template>
            <slot
                v-if="!(isMultiRoom && isPackage)"
                name="price"
                :room="minPriceRoomOffer"
                :offer="minPriceRoomOffer"
                :offer-key="minPriceRoomOffer.groupedOffers && minPriceRoomOffer.groupedOffers[0].offerKey"
            >
                <hotel-offer-price-block
                    v-if="!isAllSoldOut"
                    :offer="offer"
                    :search-request="searchRequest"
                    :hotel-page-link="hotelPageLink"
                    mobile
                    class="d-flex justify-space-between my-3"
                />
                <soul-out-modal v-else />
            </slot>
        </div>
        <v-expand-transition>
            <div v-if="expanded">
                <hotel-room-offers-wrapper
                    :offer="offer"
                    :compare-mode="compareMode"
                    :_selected-offer-keys.sync="selectedOfferKeys"
                    :search-request="searchRequest"
                    :is-package="isPackage"
                >
                    <template v-slot:price="{room}">
                        <slot name="price" :room="room" :offer="room" :offer-key="room.groupedOffers[0].offerKey" />
                    </template>
                </hotel-room-offers-wrapper>
                <slot v-if="isMultiRoom" name="multiRoomSelect">
                    <hotel-multiple-room-select-block
                        :search-request="searchRequest"
                        :offer="offer"
                        :selected-offer-keys="selectedOfferKeys"
                        class="pa-4 pt-0"
                    />
                </slot>
            </div>
        </v-expand-transition>
        <v-btn block depressed class="mt-3" data-cy="hotel-card-toggle-offers" @click="expanded = !expanded">
            {{ expanded ? $t('hide_room_offers') : $t('view_room_offers') }}
            <v-icon>mdi-chevron-{{ expanded ? 'up' : 'down' }}</v-icon>
        </v-btn>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOfferCard from '~src/components/search/offers/hotelOfferCard.src'
    import RecommendedIcon from '@/components/search/RecommendedIcon'

    @Component({
        components: {RecommendedIcon},
    })
    export default class HotelOfferCardMobile extends HotelOfferCard {
        get imageWrapper() {
            return this.compareMode
                ? 'span'
                : this.$route.name === 'hotels' && this.infoInNewTab
                ? 'nuxt-link'
                : this.$route.name === 'hotels'
                ? 'a'
                : 'span'
        }
    }
</script>

<style scoped lang="scss">
    .hotel-card {
        border: none !important;
    }

    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }

    .break-word {
        word-break: break-word;
    }

    .recommended-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
    }

    .preferred-thumb {
        position: absolute;
        top: 8px;
        left: 8px;
    }

    .long-stay {
        position: absolute;
        top: 8px;
        right: 8px;
    }
</style>
