<template>
    <div v-if="roomInfo.services.length">
        <strong>{{ $t('room_amenities') }}</strong>
        <v-row dense class="mb-5">
            <v-col v-for="(service, index) in roomInfo.services" :key="index" cols="6" class="text-capitalize">
                {{ service.supplierServiceName }}
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelRoomServices extends Vue {
        @Prop({required: true}) roomInfo
    }
</script>
