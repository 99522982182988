<template>
    <product-card-layout
        :thumb="product.mainImage"
        :name="product.name"
        :product-link="productLink"
        :offers-count="product.offers && product.offers.length"
        :expanded="expanded"
        :expanded-limit="expandedLimit"
        :is-recommended="product.recommended"
    >
        <template v-slot>
            <div class="d-flex flex-column full-height">
                <div class="font-weight-medium text-truncate">
                    <nuxt-link
                        v-if="$route.name === 'cruises'"
                        no-prefetch
                        :title="product.name"
                        :to="productLink"
                        class="text-decoration-none"
                    >
                        {{ product.name }}
                    </nuxt-link>
                    <span v-else :title="product.name">
                        {{ product.name }}
                    </span>
                </div>
                <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" />
                <div class="caption mt-2 d-flex flex-column">
                    <div v-if="product.duration" class="d-flex align-center">
                        <v-icon left small>mdi-timer-outline</v-icon>
                        <strong class="black--text me-1">
                            {{ `${product.duration} ${$tc('day', product.duration)}` }}
                        </strong>
                    </div>
                    <div v-if="product.cruiseLine" class="d-flex align-top">
                        <v-icon left small> mdi-ferry </v-icon>
                        <span class="me-1 font-weight-bold">
                            {{ $t('cruises.cruise_line') }}
                        </span>
                        <span>
                            {{ `${product.cruiseLine}` }}
                        </span>
                    </div>
                    <div v-if="product.shipName" class="d-flex align-top">
                        <v-icon left small> mdi-ship-wheel </v-icon>
                        <span class="me-1 font-weight-bold">
                            {{ $t('cruises.ship_name') }}
                        </span>
                        <span>
                            {{ `${product.shipName}` }}
                        </span>
                    </div>
                    <div
                        v-if="product.shortDescription"
                        class="short-description mt-1"
                        v-html="$options.filters.stripHtml(product.shortDescription)"
                    />
                </div>
            </div>
        </template>
        <template v-slot:priceCol>
            <slot name="price" :offer="minPriceOffer">
                <cruise-card-price-block :offer="minPriceOffer" />
                <v-btn depressed block color="primary" class="mt-2" nuxt :to="productLink">
                    {{ $t('book') }}
                </v-btn>
            </slot>
            <div class="primary--text cursor-pointer text-no-wrap mt-2" @click="toggleOffers">
                <span>
                    {{ expanded ? $t('hide') : $t('show') }}
                </span>
                <v-icon color="primary">
                    {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </div>
        </template>
        <template v-slot:offers>
            <div v-if="expanded" class="mt-3">
                <template v-if="groupOffersBy !== 'none'">
                    <cruise-grouped-offers :product="product">
                        <template v-slot:price="{offer, offerKey}">
                            <slot name="price" :offer="offer" :offer-key="offerKey" />
                        </template>
                    </cruise-grouped-offers>
                </template>
                <template v-else>
                    <cruise-offer-card
                        v-for="(offer, offerIndex) in limitedOffers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="false"
                    >
                        <template v-slot:price="{offer}">
                            <slot name="price" :offer="offer" />
                        </template>
                    </cruise-offer-card>
                    <v-list-item
                        v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                        dense
                        class="d-flex justify-center"
                        @click.stop="
                            expanded = product.offers.length
                            expandedLimit = product.offers.length
                        "
                    >
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-list-item>
                </template>
            </div>
        </template>
    </product-card-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import {authStore, cruisesRuntimeStore, cruisesStore} from '@/utils/store-accessor'
    import ProductCardLayout from '~/components/search/ProductCardLayout'
    import CruiseOfferCard from '@/components/cruises/info/CruiseOfferCard'
    import CruiseCardPriceBlock from '@/components/cruises/search/offers/CruiseCardPriceBlock'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import CruiseGroupedOffers from '@/components/cruises/search/offers/CruiseGroupedOffers'

    @Component({
        components: {
            CruiseGroupedOffers,
            SupplierNameLabel,
            CruiseCardPriceBlock,
            CruiseOfferCard,
            CommissionAmount,
            DiscountLabel,
            ProductCardLayout,
        },
    })
    export default class CruiseCard extends Vue {
        @Prop({required: true}) product
        @Prop({default: false, type: Boolean}) isPackage

        expanded = 0
        imgError = false

        get groupOffersBy() {
            return cruisesStore.groupOffersBy
        }

        get limitedOffers() {
            let offers = [...this.product.offers]
            if (this.expandedLimit >= offers.length) {
                this.expanded = offers.length
            }

            return offers.slice(0, this.expanded)
        }

        get expandedLimit() {
            return 3
        }

        set expandedLimit(val) {}

        get minPriceOffer() {
            const priceKey = this.product.offers[0].price ? 'price' : 'deltaPrice'
            return this.product.offers.reduce(
                (a, b) => (a[priceKey].amount < b[priceKey].amount ? a : b),
                this.product.offers[0]
            )
        }

        toggleOffers() {
            let expanded = 0
            if (!this.expanded) {
                expanded = this.expandedLimit
            }
            this.expanded = expanded
        }

        get productLink() {
            return cruisesRuntimeStore.infoPageLink(this.product, cruisesStore.searchRequest)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style scoped lang="scss">
    .short-description {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
</style>
