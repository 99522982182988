<template>
    <div v-if="showPrice && price && currency">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ labelText }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters class="mb-5">
                        <v-col>
                            <v-text-field
                                :value="
                                    minPriceFocused ? minPrice : $options.filters.price({amount: price[0], currency})
                                "
                                :type="minPriceFocused ? 'number' : 'text'"
                                single-line
                                outlined
                                hide-details
                                dense
                                background-color="white"
                                @focus="minPriceFocus"
                                @blur="setMinPrice"
                                @input="minPriceInput"
                                @change="setMinPrice"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                :value="
                                    maxPriceFocused ? maxPrice : $options.filters.price({amount: price[1], currency})
                                "
                                :type="maxPriceFocused ? 'number' : 'text'"
                                single-line
                                outlined
                                hide-details
                                dense
                                background-color="white"
                                @focus="maxPriceFocus"
                                @blur="setMaxPrice"
                                @input="maxPriceInput"
                                @change="setMaxPrice"
                            />
                        </v-col>
                    </v-row>
                    <v-range-slider
                        v-model="price"
                        :min="min"
                        :max="max"
                        :thumb-label="false"
                        :disabled="disabled"
                        @start="start"
                        @end="end"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Component, Model, Prop, Vue} from 'nuxt-property-decorator'
    import {persistentStore} from '~/store'

    @Component
    export default class PriceFilter extends Vue {
        @Model() value
        @Prop() filters
        @Prop({default: null}) label
        @Prop({default: null}) nights
        @Prop({required: true}) currency

        sliderPrice = [0, Infinity]
        sliderStart = false
        expanded = 0
        minPrice = null
        minPriceFocused = false
        maxPrice = null
        maxPriceFocused = false

        start() {
            this.sliderStart = true
        }

        end() {
            this.$emit('change', {key: 'price', value: this.sliderPrice})
            this.sliderStart = false
        }

        minPriceFocus() {
            this.minPrice = null
            this.minPriceFocused = true
        }

        minPriceInput(val) {
            this.minPriceFocused = true
            this.minPrice = val
        }

        setMinPrice() {
            const minPrice = +parseFloat(this.minPrice).toFixed(2)
            if (minPrice >= this.min && minPrice <= this.price[1]) {
                const price1Converted = this.$options.filters.convertPrice(
                    {amount: minPrice, currency: this.selectedCurrency},
                    this.currency
                ).amount

                const price2Converted = this.$options.filters.convertPrice(
                    {amount: this.price[1], currency: this.selectedCurrency},
                    this.currency
                ).amount

                this.$emit('change', {key: 'price', value: [price1Converted, price2Converted]})
            }
            this.minPriceFocused = false
        }

        maxPriceFocus() {
            this.maxPrice = null
            this.maxPriceFocused = true
        }

        maxPriceInput(val) {
            this.maxPriceFocused = true
            this.maxPrice = val
        }

        setMaxPrice() {
            const maxPrice = +parseFloat(this.maxPrice).toFixed(2)
            if (maxPrice <= this.max && maxPrice >= this.price[0]) {
                const price1Converted = this.$options.filters.convertPrice(
                    {amount: this.price[0], currency: this.selectedCurrency},
                    this.currency
                ).amount

                const price2Converted = this.$options.filters.convertPrice(
                    {amount: maxPrice, currency: this.selectedCurrency},
                    this.currency
                ).amount

                this.$emit('change', {key: 'price', value: [price1Converted, price2Converted]})
            }
            this.maxPriceFocused = false
        }

        get price() {
            return this.sliderStart ? this.sliderPrice : this.value['price']
        }

        set price(value) {
            this.sliderPrice = value
        }

        get min() {
            return Math.floor(this.filters['price'][0])
        }

        get max() {
            return Math.ceil(this.filters['price'][1])
        }

        get selectedCurrency() {
            return persistentStore.currency !== 'ORIGINAL' ? persistentStore.currency : this.currency
        }

        get labelText() {
            return (
                this.label ||
                `${this.$t('price_for')}  ${this.nights} ${this.$tc('night', this.nights)} ${this.$t('in')} ${
                    this.selectedCurrency
                }`
            )
        }

        get showPrice() {
            return true
        }

        get disabled() {
            return this.max === Infinity
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
    }
</style>
