import { render, staticRenderFns } from "./HotelInfoRelativePositions.vue?vue&type=template&id=304f9c3e&scoped=true&"
import script from "./HotelInfoRelativePositions.vue?vue&type=script&lang=js&"
export * from "./HotelInfoRelativePositions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304f9c3e",
  null
  
)

export default component.exports