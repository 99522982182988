<template>
    <div>
        <v-menu v-model="showMenu" :close-on-content-click="false" offset-x>
            <template v-slot:activator="{on, attrs}">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-excel</v-icon>
                </v-btn>
            </template>
            <v-card class="px-4 pt-2 pb-4">
                <v-radio-group v-model="radioGroup" dense>
                    <v-radio
                        :label="`${$t('selected_orders')} (${orders.length})`"
                        :disabled="!orders.length"
                        value="selected"
                    />
                    <v-radio :label="$t('all_orders')" value="all" />
                </v-radio-group>
                <div class="d-table-row text-body-2">
                    <div class="d-table-cell pe-2">{{ $t('download') }}:</div>
                    <div class="d-table-cell">
                        <v-tooltip v-for="format in formats" :key="format.fileType" top>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    :disabled="(!radioGroup && !orders.length) || loading"
                                    :loading="loading"
                                    icon
                                    @click="download(format)"
                                    v-on="on"
                                >
                                    <v-icon>
                                        {{ format.icon }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ format.fileType }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {clean, clone, downloadData} from '@/utils/helpers'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import {format} from 'date-fns'

    @Component
    export default class OrdersDownloadMenu extends Vue {
        @Prop({required: true}) orders
        @Prop() sortOrder

        showMenu = false
        radioGroup = ''
        loading = false

        async download(format) {
            this.loading = true
            try {
                let data
                if (this.radioGroup === 'selected') {
                    data = await this.$api.downloadOrders.get({
                        orderIds: this.orders.map(({orderId}) => orderId),
                        fileType: format.fileType,
                        orderType: authStore.orderType,
                    })
                } else {
                    const filters = await this.loadFilters()

                    data = await this.$api.downloadOrders.get({
                        fileType: format.fileType,
                        orderType: authStore.orderType,
                        ...filters,
                    })
                }
                await downloadData(data, `orders.${format.extension}`)
                this.radioGroup = ''
                this.showMenu = false
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.loading = false
        }

        loadFilters() {
            const filters = clone(persistentStore.orderFilters)

            if (this.sortOrder !== 'none') {
                filters.sortCriteria = 'earliestServiceDate'
                filters.sortOrder = this.sortOrder
                if (!filters.serviceStartDateFrom)
                    filters.serviceStartDateFrom = format(this.$dateFns.subYears(new Date(), 5), 'yyyy-MM-dd')
            }

            if (filters.orderId) {
                filters.orderId = parseInt(filters.orderId, 10)
            }
            if (filters.city) {
                filters.cityId = filters.city.id
                delete filters.city
            }
            if (filters.paymentMethod && filters.paymentMethod.name) {
                filters.paymentMethod = filters.paymentMethod.name
            }
            if (filters.supplier) {
                filters.supplierId = filters.supplier.id
                delete filters.supplier
            }
            if (filters.agent) {
                filters.agentId = filters.agent.id
                delete filters.agent
            }
            if (filters.reservationManager) {
                filters.managerId = filters.reservationManager.personId
                delete filters.reservationManager
            }
            if (filters.serviceManager) {
                filters.serviceManagerId = filters.serviceManager.personId
                delete filters.serviceManager
            }
            if (filters.customerCompany) {
                filters.customerCompany = filters.customerCompany.name
            }
            if (filters.client) {
                filters.clientId = filters.client.personId
                delete filters.client
            }
            if (filters.tripOrganizer) {
                filters.tripOrganizerId = filters.tripOrganizer.personId
                delete filters.tripOrganizer
            }
            if (filters.waitingApprovalFromMyself) {
                filters.authorizeUserId = this.person.personId
                delete filters.waitingApprovalFromMyself
            }
            if (filters.waitAuthorize === false) {
                delete filters.waitAuthorize
            }
            if (filters.noSupplierRef === false) {
                delete filters.noSupplierRef
            }
            if (filters.supplierInvoiceStatuses.length) {
                filters.supplierPaymentStatuses = [
                    ...filters.supplierPaymentStatuses,
                    ...filters.supplierInvoiceStatuses,
                ]
                delete filters.supplierInvoiceStatuses
            }
            if (filters.customerInvoiceStatuses.length) {
                filters.customerPaymentStatuses = [
                    ...filters.customerPaymentStatuses,
                    ...filters.customerInvoiceStatuses,
                ]
                delete filters.customerInvoiceStatuses
            }
            const addTime = (rq, date, time) => {
                if (rq[date]) {
                    rq[date] += time
                }
            }
            addTime(filters, 'createdDateFrom', 'T00:00')
            addTime(filters, 'createdDateTo', 'T23:59')
            addTime(filters, 'serviceStartDateFrom', 'T00:00')
            addTime(filters, 'serviceStartDateTo', 'T23:59')
            addTime(filters, 'serviceEndDateFrom', 'T00:00')
            addTime(filters, 'serviceEndDateTo', 'T23:59')
            addTime(filters, 'modifiedDateFrom', 'T00:00')
            addTime(filters, 'modifiedDateTo', 'T23:59')
            addTime(filters, 'lastTicketingDateFrom', 'T00:00')
            addTime(filters, 'lastTicketingDateTo', 'T23:59')
            clean(filters)

            return filters
        }

        get formats() {
            return [
                {
                    fileType: 'EXCEL',
                    icon: 'mdi-file-excel',
                    extension: 'xlsx',
                },
            ]
        }
    }
</script>
