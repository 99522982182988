<template>
    <v-card outlined hover @click="tourPageLink">
        <v-img
            :src="item.mainImageUrl | imageUrl(800)"
            class="white--text align-end"
            :lazy-src="'/placeholder.png' | srcHost"
            eager
            :height="250"
        >
            <v-card-title class="word-break">
                {{ item.name }}
            </v-card-title>
            <v-card-subtitle class="white--text"> {{ item.duration }} {{ $tc('day', item.duration) }} </v-card-subtitle>
        </v-img>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'
    import {searchRequest} from '@/utils/tours/tours-blank-states'

    @Component
    export default class TopTourCard extends Vue {
        @Prop() item

        tourPageLink() {
            const query = Object.assign(searchRequest(), {
                packageTourId: this.item.packageTourId,
                arrivalCityId: this.item.locations[0].cityId,
                departureCityId: this.item.locations[0].cityId,
                durationFrom: this.item.duration,
                durationTo: this.item.duration,
            })
            this.$router.push({name: 'tour', query})
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-responsive__content {
        background: rgba(0, 0, 0, 0.4);
    }

    .word-break {
        word-break: break-word;
    }
</style>
