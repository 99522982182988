<template>
    <tour-booking-page-layout
        v-if="loaded"
        :search-request="searchRequest"
        :offer="offer"
        :search-request-country-id="searchRequestCountryId"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <tour-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:tourists="{tourists, index, disabled, personSelected, isSavingWithoutDetails}">
            <component
                :is="hasFlightOffers ? 'flight-tourist-form' : 'tourist-form'"
                v-model="tourists[index]"
                :disabled="disabled"
                :index="index"
                :offer="hasFlightOffers ? firstFlightOffer : null"
                :hide-index="tourists.length === 1"
                :start-date="startDate"
                :disable-required-rule="isSavingWithoutDetails"
                @person-selected="personSelected"
            />
        </template>
        <template v-slot:forms="{bookingKey}">
            <v-card v-if="firstTransfer || lastTransfer" outlined class="mb-5">
                <v-card-title class="text-capitalize">
                    <v-icon left color="primary">mdi-bus</v-icon>
                    {{ $t('productType.transfer') }}
                </v-card-title>
                <v-divider />
                <tour-transfer-form
                    v-if="firstTransfer"
                    :product="firstTransfer"
                    :disabled="!!bookingKey"
                    :show-title="!!lastTransfer"
                />
                <v-divider v-if="firstTransfer && lastTransfer" />
                <tour-transfer-form
                    v-if="lastTransfer"
                    :product="lastTransfer"
                    :disabled="!!bookingKey"
                    :show-title="!!firstTransfer"
                />
            </v-card>
            <v-card v-if="hasCarOffers" outlined class="mb-5">
                <v-card-title class="text-capitalize">
                    <v-icon left color="primary">mdi-car</v-icon>
                    {{ $t('productType.carRent') }}
                </v-card-title>
                <v-divider />
                <car-details-form :disabled="!!bookingKey" :offer="carEntry.offer" />
            </v-card>
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <tour-order-summary :offer="offer" :prebooked="!!bookingKey" :search-request="searchRequest" />
        </template>
    </tour-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import TourBreadcrumbs from '@/components/tours/TourBreadcrumbs'
    import TourBookingPageLayout from '@/components/tours/booking/TourBookingPageLayout'
    import {UTCToDate} from '@/utils/helpers'
    import {runtimeStore, toursRuntimeStore, toursStore} from '@/utils/store-accessor'
    import {bookingFields} from '@/utils/tours/tours-blank-states'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import FlightTouristForm from '~src/components/booking/forms/flightTouristForm.src'
    import TourOrderSummary from '@/components/tours/booking/TourOrderSummary'
    import TourPageMixin from '@/components/tours/mixins/TourPageMixin'
    import TourStoreMixin from '@/mixins/TourStoreMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import TourTransferForm from '@/components/tours/booking/form/TourTransferForm'
    import CarDetailsForm from '@/components/carsRent/booking/forms/CarDetailsForm'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            TourOrderSummary,
            TourBookingPageLayout,
            TourBreadcrumbs,
            TouristForm,
            FlightTouristForm,
            TourTransferForm,
            CarDetailsForm,
        },
    })
    export default class TourBookingPage extends mixins(TourPageMixin, TourStoreMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            this.setCustomTouristBookingFields()
            await Promise.all([
                this.loadRuntimeData(),
                //TODO Not work in API
                //runtimeStore.loadTouristBookingFields({offerKey: this.$route.query.offerKey}),
            ])
            this.setEntryParams()
            this.loaded = true
        }

        setEntryParams() {
            const val = []
            if (this.firstTransfer) {
                val.push({
                    entryName: this.firstTransfer.entryName,
                    entryType: this.firstTransfer.type,
                    time: '',
                    departure: {},
                    arrival: {},
                })
            }
            if (this.lastTransfer) {
                val.push({
                    entryName: this.lastTransfer.entryName,
                    entryType: this.lastTransfer.type,
                    time: '',
                    departure: {},
                    arrival: {},
                })
            }
            toursStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'entryParams', val})
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !toursStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            const {packageTourId, departureCityId, startDateFrom, startDateTo, durationFrom, adults, childrenAges} =
                this.searchRequest
            this.$router.push({
                name: 'tour',
                query: {
                    packageTourId,
                    locationId: departureCityId,
                    startDateFrom,
                    startDateTo,
                    duration: durationFrom,
                    adults,
                    childrenAges,
                },
            })
        }

        setCustomTouristBookingFields() {
            if (this.offer.entries.find(entry => entry.type === 'FLIGHT')) {
                const bf = bookingFields()

                bf.BIRTHDATE = {
                    mainTouristStatus: 'MANDATORY',
                    allTouristsStatus: 'MANDATORY',
                    childrenStatus: 'MANDATORY',
                }

                runtimeStore.SET_CUSTOM_TOURIST_BOOKING_FIELDS(bf)
            } else {
                const bf = bookingFields()
                const disabled = () => ({
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                })
                bf.PASSPORT_EXPIRE_DATE = disabled()
                bf.PASSPORT_ISSUE_DATE.status = disabled()
                bf.PASSPORT_NUMBER.status = disabled()
                runtimeStore.SET_CUSTOM_TOURIST_BOOKING_FIELDS(bf)
            }
        }

        get firstTransfer() {
            let transfer = null

            if (this.offer.entries[0].type === 'FLIGHT' && this.offer.entries[0].offers[0].itinerary.length === 2)
                return transfer

            const checkIndex = this.offer.entries.findIndex(
                entry => entry.type === 'ACCOMMODATION' || entry.type === 'FLIGHT'
            )
            const transferIndex = this.offer.entries.findIndex(entry => entry.type === 'TRANSFER')

            if (checkIndex > transferIndex && transferIndex >= 0) transfer = this.offer.entries[transferIndex]

            const firstHotelSelected = this.offer.entries.find(entry => entry.type === 'ACCOMMODATION')
            const transferOffer = transfer?.offers.find(offer => offer.offerKey === transfer?.selectedOfferKey)
            const firstHotelSelectedOffer = firstHotelSelected?.offers.find(offer =>
                offer?.rooms.find(room =>
                    room?.groupedOffers?.find(g => g.offerKey === firstHotelSelected?.selectedOfferKey)
                )
            )

            if (transferOffer?.arrivalInfo?.type === 'HOTEL' && firstHotelSelectedOffer) {
                transferOffer.hotelArrival = {...firstHotelSelectedOffer, hotelName: firstHotelSelectedOffer.name}
            }

            return transfer
        }

        get lastTransfer() {
            let transfer = null
            let checkIndex = 0
            let transferIndex = 0

            if (this.offer.entries[0].type === 'FLIGHT' && this.offer.entries[0].offers[0].itinerary.length === 2)
                return transfer

            this.offer.entries.forEach((entry, index) => {
                if (entry.type === 'ACCOMMODATION' || entry.type === 'FLIGHT') checkIndex = index
                else if (entry.type === 'TRANSFER') transferIndex = index
            })

            if (checkIndex < transferIndex) transfer = this.offer.entries[transferIndex]

            const lastHotelSelected = [...this.offer.entries]?.reverse().find(entry => entry.type === 'ACCOMMODATION')
            const transferOffer = transfer?.offers.find(offer => offer.offerKey === transfer?.selectedOfferKey)
            const lastHotelSelectedOffer = lastHotelSelected?.offers.find(offer =>
                offer?.rooms.find(room =>
                    room?.groupedOffers?.find(g => g.offerKey === lastHotelSelected?.selectedOfferKey)
                )
            )

            if (transferOffer?.departureInfo?.type === 'HOTEL' && lastHotelSelectedOffer) {
                transferOffer.hotelDeparture = {...lastHotelSelectedOffer, hotelName: lastHotelSelectedOffer.name}
            }

            return transfer
        }

        get searchRequestCountryId() {
            return toursRuntimeStore.arrivalCity.countryId
        }

        get hasCarOffers() {
            return this.offer.entries.some(entry => entry.type === 'CAR' && entry.selectedOfferKey)
        }

        get carEntry() {
            return this.offer.entries.find(entry => entry.type === 'CAR' && entry.selectedOfferKey)
        }

        get hasFlightOffers() {
            return toursRuntimeStore.hasFlightOffers(this.offer)
        }

        get firstFlightOffer() {
            return toursRuntimeStore.firstFlightOffer(this.offer)
        }

        get packageTourId() {
            return this.searchRequest.packageTourId
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get basketItem() {
            return toursStore.basketItem(this.$route.query.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }

        get startDate() {
            return this.offer.info.packageDate
        }
    }
</script>
