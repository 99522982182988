<script>
    import {Component} from 'vue-property-decorator'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import {transfersStore} from '@/utils/store-accessor'

    @Component
    export default class TransferReturnBookingCommentsForm extends BookingCommentsForm {
        get comment() {
            return transfersStore.prepareBookReturnTransferRequest.comments
        }

        set comment(val) {
            transfersStore.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({prop: 'comments', val})
        }
    }
</script>
