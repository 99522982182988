<template>
    <v-menu
        v-model="dateFilterPicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
    >
        <template v-slot:activator="{on}">
            <v-btn color="primary" plain text small class="pa-0" v-on="on">
                <v-icon small>mdi-calendar</v-icon>
                {{ `${$t('tours.more_dates')} ...` }}
            </v-btn>
        </template>
        <vc-date-picker
            v-if="isShowDatePicker"
            v-model="selectedDate"
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :attributes="attributes"
            :available-dates="availableDates"
            :color="isAvailable"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            is-required
            @input="onDateChange"
        >
            <template v-slot:day-popover="{attributes}">
                <div v-for="attr in attributes" :key="attr.key">
                    {{ attr.customData.price | price }}
                </div>
            </template>
        </vc-date-picker>
    </v-menu>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class TourDateFilter extends Vue {
        @VModel() selectedDate
        @Prop({required: true}) offers

        dateFilterPicker = false
        isShowDatePicker = true

        onDateChange() {
            //v-calendar bug bypass
            this.isShowDatePicker = false

            this.$nextTick(() => {
                this.isShowDatePicker = true
            })
        }

        get isAvailable() {
            return this.attributes.find(attr => attr.dates === this.selectedDate).highlight.color
        }

        get availableDates() {
            return this.offers.reduce((allowedDates, offer) => {
                allowedDates.push({
                    start: this.$dateFns.format(offer.info.packageDate),
                    end: this.$dateFns.format(offer.info.packageDate),
                })

                return allowedDates
            }, [])
        }

        get attributes() {
            return this.offers.reduce((attributes, offer, index) => {
                const isSelectedDay = this.selectedDate === this.$dateFns.format(offer.info.packageDate)
                const isAvailable = offer.entries.every(entry =>
                    entry.type === 'ACCOMMODATION'
                        ? entry.offers[0].rooms.every(room => room.available)
                        : entry.offers[0].available
                )
                const data = {
                    dates: this.$dateFns.format(offer.info.packageDate),
                    key: `day-${index}`,
                    highlight: {
                        color: isAvailable ? 'green' : 'orange',
                        fillMode: isSelectedDay ? 'solid' : 'outline',
                        class: 'opacity-75',
                        contentStyle: {
                            color: 'inherit',
                            fontWeight: 500,
                        },
                    },
                    popover: {
                        visibility: 'hover',
                    },
                    customData: offer,
                }
                attributes.push(data)

                return attributes
            }, [])
        }
    }
</script>

<style lang="scss" scoped>
    .date-field {
        max-width: 160px;
    }

    ::v-deep .vc-highlight {
        border-width: 1px !important;
    }
</style>
