<template>
    <div v-if="bedTypes.length" class="d-flex align-center">
        <template v-for="(bed, index) in bedTypes">
            <div v-if="bed.nameToken !== 'undefined'" :key="index">
                <span v-if="index !== 0" class="px-2 caption">
                    {{ bed.alternate || bed.alternative ? $t('or') : $t('and') }}
                </span>
                <v-icon>{{ getIcon(bed.nameToken) }}</v-icon>
                <span class="caption">{{ bed.quantity }}</span>
                <span v-if="bed.nameToken" class="caption">{{ getName(bed.nameToken, bed.quantity) }}</span>
            </div>
        </template>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'

    @Component
    export default class HotelBedTypeLabel extends Vue {
        @Prop({required: true}) bedTypes

        getIcon(token) {
            switch (token) {
                case 'double_bed':
                    return 'mdi-bed-king-outline'
                case 'full_bed':
                    return 'mdi-bed-king-outline'
                case 'king_bed':
                    return 'mdi-bed-king-outline'
                case 'queen_bed':
                    return 'mdi-bed-king-outline'
                case 'twin_bed':
                    return 'mdi-bed-single-outline'
                case 'single_bed':
                    return 'mdi-bed-single-outline'
                case 'sofa_bed_double':
                    return 'mdi-sofa-outline'
                case 'sofa_bed_king':
                    return 'mdi-sofa-outline'
                case 'sofa_bed_queen':
                    return 'mdi-sofa-outline'
                case 'sofa_bed_twin':
                    return 'mdi-sofa-single-outline'
                case 'sofa_bed_single':
                    return 'mdi-sofa-single-outline'
            }
        }

        getName(token, quantity) {
            return this.$tc(`bed_types.${token}`, quantity)
        }
    }
</script>
