<template>
    <span v-if="visible" class="d-block red--text text-no-nowrap caption">
        {{ $t('commission') }}:
        {{ commission | price }}
    </span>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {authStore, persistentStore} from '~/store'

    @Component
    export default class CommissionAmount extends Vue {
        @Prop({required: true}) commission
        @Prop({required: false, type: Boolean}) showCommission

        get visible() {
            return (
                (this.commission &&
                    authStore.companyType &&
                    authStore.companyType !== 'CORPORATE' &&
                    this.$route.query.showCommission) ||
                (this.commission && this.commissionAndNettoCheckbox && this.$route.query.showCommission)
            )
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }
    }
</script>

<style scoped>
    span.caption {
        line-height: 1rem;
    }
</style>
