<script>
    import {Component} from 'vue-property-decorator'
    import Daterange from '~/components/search/forms/Daterange'

    @Component
    export default class OrderCreateDaterange extends Daterange {
        checkEndDate(newStartDate) {
            if (this.$dateFns.parseISO(newStartDate) > this.$dateFns.parseISO(this.endDate) && this.endDate) {
                const differenceInDays = this.$dateFns.differenceInDays(
                    this.$dateFns.parseISO(this.endDate),
                    this.$dateFns.parseISO(this.startDate)
                )
                const newEndDate = this.$dateFns.addDays(this.$dateFns.parseISO(newStartDate), differenceInDays)
                if (this.maxEndDate && newEndDate > this.$dateFns.parseISO(this.maxEndDate)) {
                    this.endDate = this.maxEndDate
                } else {
                    this.endDate = this.$dateFns.format(newEndDate)
                }
            }
        }

        onChangeStartDate(newStartDate) {
            this.showStartDatePicker = false
            this.checkEndDate(newStartDate)
        }

        get minStartDate() {
            return undefined
        }

        get maxStartDate() {
            return this.$dateFns.format(new Date())
        }

        get minEndDate() {
            return this.startDate
        }

        get maxEndDate() {
            return this.$dateFns.format(new Date())
        }
    }
</script>
