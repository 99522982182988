<template>
    <v-dialog v-model="modal" width="800px" max-width="100%">
        <v-card v-if="offer" width="800px" max-width="100%" class="pa-md-3">
            <v-card-text class="black--text">
                <h2 class="pt-5 pt-md-2">
                    {{ offer.planName }}
                </h2>
                <v-subheader
                    v-if="offer.planDescription"
                    class="modal-window__description"
                    v-html="offer.planDescription"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_INSURANCE_INFO_MODAL} from '@/utils/event-bus'

    @Component
    export default class InsuranceInfoModal extends Vue {
        offer = null
        modal = false

        created() {
            EventBus.$on(SHOW_INSURANCE_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_INSURANCE_INFO_MODAL, this.showModal)
        }

        showModal({offer}) {
            this.offer = offer
            this.modal = true
        }
    }
</script>

<style lang="scss" scoped>
    .modal-window__description {
        padding: 0;

        ::v-deep p {
            margin: 0;
        }
    }
</style>
