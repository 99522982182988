<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'
    import {utcToZonedTime} from 'date-fns-tz'

    @Component
    export default class HotelDataPageMixin extends Vue {
        async checkSearchDate(pageName = 'hotels') {
            const cityDate = utcToZonedTime(new Date(), hotelsRuntimeStore.city.timeZone)
            const {startDate, endDate} = this.$route.query
            if (
                startDate &&
                endDate &&
                this.$dateFns.isBefore(this.$dateFns.parseISO(startDate), cityDate.setHours(0, 0, 0, 0))
            ) {
                const nights = this.$dateFns.differenceInDays(
                    this.$dateFns.parseISO(endDate),
                    this.$dateFns.parseISO(startDate)
                )
                await this.$router.push({
                    name: pageName,
                    query: {
                        ...this.$route.query,
                        startDate: this.$dateFns.format(cityDate),
                        endDate: this.$dateFns.format(this.$dateFns.addDays(cityDate, nights)),
                    },
                })
                return false
            }
            return true
        }

        get cityId() {
            return parseInt(this.$route.query.cityId, 10)
        }
    }
</script>
