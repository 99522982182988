<template>
    <v-text-field
        ref="option"
        :key="key"
        :value="value"
        :name="name"
        :label="label"
        append-outer-icon="mdi-plus"
        prepend-icon="mdi-minus"
        data-cy="adults-selector"
        type="number"
        min="0"
        max="8"
        class="input--no-arrows"
        @click:append-outer="incrementAdults"
        @click:prepend="decrementAdults"
        @input="inputAdults"
    />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {authStore} from '~/store'

    @Component
    export default class AdultsSelect extends Vue {
        @Prop({required: true}) name
        @Prop() label

        key = 0
        value = 0

        created() {
            this.value = this.$route.query[this.name] ? this.$route.query[this.name] : 0
        }

        inputAdults(v) {
            let value = !v ? 0 : parseInt(v.length > 1 ? v.slice(-1) : v, 10)
            if (value < 0) value = 0
            else if (value > 8) value = 8
            if (this.value === value) {
                this.key++
            }
            this.value = value
        }

        incrementAdults() {
            if (this.value === 8) return
            this.value = this.value + 1
        }

        decrementAdults() {
            if (this.value === 0) return
            this.value = this.value - 1
        }

        get disabled() {
            return authStore.user.isEmployeeOrExternalCorp
        }
    }
</script>
