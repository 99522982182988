<template>
    <div ref="transferForm">
        <component
            :is="departureComponent"
            point-type="departure"
            :disabled="disabled"
            :hotels="product.allowedHotelsDeparture"
            :product="product"
            :search-request="searchRequest"
            :point="departurePoint"
            :request="request"
            :_valid.sync="departureFormValid"
            :flight-service="flightService"
            :transfer-info="transferInfo"
            :departure-point-name="departurePoint.destinationPointName"
            :return-transfer-offer="returnTransferOffer"
            @change="change"
            @change-location="changeLocation"
        />
        <transfer-details-form
            v-if="offer"
            :disabled="disabled"
            :offer="offer"
            data-cy="transfer-details-form"
            class="mb-5"
            :request="request"
            :_valid.sync="detailsFormValid"
            @change="change"
        />
        <component
            :is="arrivalComponent"
            point-type="arrival"
            :disabled="disabled"
            :hotels="product.allowedHotelsArrival"
            :product="product"
            :search-request="searchRequest"
            :point="arrivalPoint"
            :request="request"
            :_valid.sync="arrivalFormValid"
            :transfer-info="transferInfo"
            :flight-service="flightService"
            :arrival-point-name="arrivalPoint.destinationPointName"
            :return-transfer-offer="returnTransferOffer"
            @change="change"
            @change-location="changeLocation"
        />
    </div>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import TransferDetailsForm from '@/components/transfers/booking/forms/TransferDetailsForm'

    @Component({
        components: {
            TransferAirportForm: () => import('@/components/transfers/booking/forms/TransferAirportForm'),
            TransferHotelForm: () => import('@/components/transfers/booking/forms/TransferHotelForm'),
            TransferAddressForm: () => import('@/components/transfers/booking/forms/TransferAddressForm'),
            TransferTrainStationForm: () => import('@/components/transfers/booking/forms/TransferTrainStationForm'),
            TransferManualForm: () => import('@/components/transfers/booking/forms/TransferManualForm'),
            TransferCruisePortForm: () => import('@/components/transfers/booking/forms/TransferCruisePortForm'),
            TransferDetailsForm,
        },
    })
    export default class TransferBookingForms extends Vue {
        @VModel() model
        @Prop({required: true}) product
        @Prop() transferInfo
        @Prop({required: true}) offer
        @Prop({required: true}) disabled
        //TODO searchRequest is only for dateTime
        @Prop({required: true}) searchRequest
        @Prop({required: true}) departurePoint
        @Prop({required: true}) arrivalPoint
        @Prop({required: true}) request
        @Prop() productBookingFields
        @Prop() flightService
        @Prop() returnTransferOffer

        departureFormValid = false
        detailsFormValid = false
        arrivalFormValid = false

        @Emit()
        change({prop, val}) {
            return {prop, val}
        }

        @Emit()
        changeLocation({prop, val, pointType}) {
            return {prop, val, pointType}
        }

        @Watch('valid')
        onValidChange(valid) {
            this.model = valid
        }

        pointComponent(point, pointType) {
            const transferPoint = this.transferInfo?.transferPoints?.find(t => t.pointType === pointType)

            if (transferPoint?.type === 'MANUAL') {
                return 'transfer-manual-form'
            }

            switch (point.type) {
                case 'airport':
                    return 'transfer-airport-form'
                case 'city':
                    return this.product[`allowedHotels${pointType.charAt(0).toUpperCase()}${pointType.slice(1)}`]
                        ? 'transfer-hotel-form'
                        : 'transfer-address-form'
                case 'trainStation':
                case 'train':
                    return 'transfer-train-station-form'
                case 'cruisePort':
                case 'port':
                    return 'transfer-cruise-port-form'
                case 'hotel':
                    return 'transfer-hotel-form'
            }
        }

        get departureComponent() {
            return this.pointComponent(this.departurePoint, 'departure')
        }

        get arrivalComponent() {
            return this.pointComponent(this.arrivalPoint, 'arrival')
        }

        get valid() {
            return this.departureFormValid && this.detailsFormValid && this.arrivalFormValid
        }
    }
</script>
