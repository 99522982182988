<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class FormValidationRulesMixin extends Vue {
        requiredRule(prop) {
            return v => !!v || v === 0 || `${this.$t(prop)} ${this.$t('validation.required')}`
        }

        emailRule(prop) {
            return v => !v || /^.+@.+\.[a-zA-Z]{2,}$/.test(v) || `${this.$t(prop)} ${this.$t('validation.valid')}`
        }

        latinRule(prop) {
            const reqRules = {
                latin: 'a-zA-Z\u0080-\uFFFF',
                latinAndSpacesOnly: 'a-zA-Z',
                hebrew: '\u0590-\u05FF',
            }
            const regArr = []
            let regExp = new RegExp('')
            let validationText

            if (
                this.$config.personNameValidation &&
                (this.$config.personNameValidation.all === 'latin' ||
                    this.$config.personNameValidation.all === 'latinAndSpacesOnly')
            ) {
                validationText = this.$t('validation.latin', {
                    prop: this.$t(prop),
                })
                regArr.push(reqRules[this.$config.personNameValidation.all])
            }
            if (this.$config.personNameValidation && this.tourist && this.tourist.citizenshipId === this.countryId) {
                for (let key in this.$config.personNameValidation) {
                    if (key === String(this.countryId)) {
                        regArr.push(reqRules[this.$config.personNameValidation[key]])
                        validationText +=
                            ` ${this.$t('or')} ` + this.$t(`validation.${this.$config.personNameValidation[key]}`)
                    }
                }
            }
            if (regArr.length) {
                if (this.$config.personNameValidation.all === 'latinAndSpacesOnly') {
                    regExp = new RegExp(`^[${regArr.join('')}\\s]*$`, 'i')
                } else {
                    regExp = new RegExp(`^[${regArr.join('')}\\s-\`'-]*$`, 'i')
                }
            }

            return v => !this.$config.personNameValidation || regExp.test(v) || validationText
        }

        validationRules(prop, ruleFns, fieldKey) {
            return ruleFns.map(ruleFn => ruleFn(prop, fieldKey))
        }

        lengthRule(length) {
            return value => value.length >= length || `${length} ${this.$t('validation.length')}`
        }

        positiveNumberRule(prop) {
            return value => +value >= 0 || this.$t('validation.positiveNumber', {prop})
        }
    }
</script>
