<template>
    <search-form-layout :popup="popup" :title="productName" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="10" cols="12">
                        <v-row>
                            <v-col md="6" cols="12" class="d-md-flex justify-center justify-md-space-between">
                                <city-autocomplete
                                    ref="firstLocation"
                                    v-model="pickUpPoint"
                                    :rules="[
                                        v => !!v || `${$t('carsRent.pick-up_point')} ${$t('validation.required')}`,
                                    ]"
                                    label="carsRent.pick-up_point"
                                    single-line
                                    filled
                                    class="flex-grow-1 location-field"
                                />
                                <div class="text-center swap-btn">
                                    <v-btn icon @click="swapLocations">
                                        <v-icon color="white">
                                            {{ isMobile ? 'mdi-swap-vertical-bold' : 'mdi-swap-horizontal-bold' }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                                <city-autocomplete
                                    ref="secondLocation"
                                    v-model="dropOffPoint"
                                    :rules="[
                                        v => !!v || `${$t('carsRent.drop-off_point')} ${$t('validation.required')}`,
                                    ]"
                                    label="carsRent.drop-off_point"
                                    single-line
                                    filled
                                    class="flex-grow-1 location-field"
                                />
                            </v-col>
                            <v-col md="4" cols="12">
                                <daterange-m
                                    :start-date.sync="pickUpDateTime"
                                    :end-date.sync="dropOffDateTime"
                                    mode="dateTime"
                                />
                            </v-col>
                            <v-col md="2" cols="12">
                                <age-select
                                    v-model="driverAge"
                                    name="driverAge"
                                    :disabled="isEmployeeOrExternalCorp"
                                    required
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="2" cols="12" class="ml-md-auto hidden-sm-and-down">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :options="[
                    'driverCitizenship',
                    'passengers',
                    'availableOnly',
                    'internalSuppliers',
                    'externalSuppliers',
                    'supplierId',
                ]"
                :suppliers="suppliers"
            />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, carsRentRuntimeStore, carsRentStore, persistentStore} from '@/store'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import DaterangeM from '~/components/search/forms/DaterangeM'
    import AgeSelect from '@/components/snippets/AdditionalSearchOptions/AgeSelect'

    @Component({
        components: {
            TouristsSelectMenu,
            CityAutocomplete,
            AdditionalOptionsWrapper,
            SearchFormLayout,
            DaterangeM,
            AgeSelect,
        },
        mixins: [AdditionalSearchOptionsMixin],
    })
    export default class CarsSearchForm extends Vue {
        @Prop({default: false}) popup
        @Prop() productName

        valid = false
        pickUpPoint = {}
        dropOffPoint = {}
        pickUpDateTime = null
        dropOffDateTime = null
        driverAge = 18
        suppliers = []

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        async mounted() {
            await this.load()
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        newQuery() {
            const query = this.getAdditionalOptions()

            Object.assign(query, {
                pickUpCityId: this.pickUpPoint.id,
                dropOffCityId: this.dropOffPoint.id,
                pickUpDateTime: this.pickUpDateTime,
                dropOffDateTime: this.dropOffDateTime,
                driverAge: this.driverAge,
                convertToCurrency: persistentStore.currency,
            })

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        async search() {
            const query = this.newQuery()
            this.$emit('close-popup')
            carsRentRuntimeStore.SET_PICK_UP_POINT(this.pickUpPoint)
            carsRentRuntimeStore.SET_DROP_OFF_POINT(this.dropOffPoint)
            const name = 'carsRent'
            if (this.$route.name !== name) {
                carsRentRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await carsRentRuntimeStore.search(this.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async load() {
            await this.$store.restored
            await this.$nextTick()

            const pickUpCityId = this.pickUpPoint.id || this.searchRequest.pickUpCityId
            const dropOffCityId = this.dropOffPoint.id || this.searchRequest.dropOffCityId
            if (pickUpCityId && carsRentRuntimeStore.pickUpPoint.id !== pickUpCityId) {
                await carsRentRuntimeStore.loadPickUpPoint(pickUpCityId)
            }
            if (dropOffCityId && carsRentRuntimeStore.dropOffPoint.id !== dropOffCityId) {
                await carsRentRuntimeStore.loadDropOffPoint(dropOffCityId)
            }
            this.pickUpPoint =
                pickUpCityId && carsRentRuntimeStore.pickUpPoint.id ? carsRentRuntimeStore.pickUpPoint : null
            this.dropOffPoint =
                dropOffCityId && carsRentRuntimeStore.dropOffPoint.id ? carsRentRuntimeStore.dropOffPoint : null
            if (
                this.$dateFns.isAfter(
                    this.$dateFns.parseISO(this.searchRequest.pickUpDateTime),
                    new Date().setHours(10, 0, 0)
                )
            ) {
                this.pickUpDateTime = this.searchRequest.pickUpDateTime
                this.dropOffDateTime = this.searchRequest.dropOffDateTime
            } else {
                this.pickUpDateTime = this.$dateFns.format(
                    this.$dateFns.addDays(new Date().setHours(10, 0, 0), 1),
                    "yyyy-MM-dd'T'HH:mm"
                )
                this.dropOffDateTime = this.$dateFns.format(
                    this.$dateFns.addDays(new Date().setHours(10, 0, 0), 6),
                    "yyyy-MM-dd'T'HH:mm"
                )
            }
            this.driverAge = this.$route.query.driverAge ? this.$route.query.driverAge : this.searchRequest.driverAge
            if (this.isTO1) {
                try {
                    this.suppliers = (await this.$api.suppliers.get({productType: 'carRent'}))?.suppliers
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async reload() {
            if (this.pickUpPoint && this.pickUpPoint.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.pickUpPoint.id, limitCities: 1})
                    this.pickUpPoint = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            if (this.dropOffPoint && this.dropOffPoint.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.dropOffPoint.id, limitCities: 1})
                    this.dropOffPoint = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        swapLocations() {
            const first = {...this.$refs.firstLocation.city}
            const second = {...this.$refs.secondLocation.city}
            this.pickUpPoint = Object.keys(second).length ? second : null
            this.dropOffPoint = Object.keys(first).length ? first : null
        }

        get isTO1() {
            return authStore.isTO1
        }

        get searchRequest() {
            return carsRentStore.searchRequest
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get isEmployeeOrExternalCorp() {
            return authStore.user.isEmployeeOrExternalCorp
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/settings/_variables.scss';

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        .location-field {
            flex-basis: 100%;
        }
        .swap-btn {
            line-height: 56px;
        }
    }
</style>
