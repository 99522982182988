<template>
    <div v-if="!$fetchState.pending">
        <main-page-form-wrapper />
        <v-container fluid>
            <vue-markdown v-if="homePageSettings.topBlock" :source="homePageSettings.topBlock" />
            <resorts />
            <template v-if="popularHotels.length && popularHotelsGroups.length">
                <popular-hotels
                    v-for="(popularHotelGroup, groupIndex) in popularHotelsGroups"
                    :key="popularHotelGroup.groupBy"
                    :group-by="popularHotelGroup.name"
                    :group-index="groupIndex"
                    :hotels="popularHotelGroup.hotels"
                />
            </template>
            <popular-destinations v-if="popularDestinations.length" :destinations="popularDestinations" />
            <top-tours />
            <blog v-if="blogItems.length" :items="blogItems" />
            <vue-markdown v-if="homePageSettings.bottomBlock" :source="homePageSettings.bottomBlock" />
            <v-sheet v-if="$config.hotels.homeMap" width="85%" class="mx-auto mb-10">
                <hotel-home-map />
            </v-sheet>
            <new-order-request-modal v-model="newOrderRequestModal" />
            <v-btn
                v-if="$config.account.newReservationRequest && !hideNewReservationRequest"
                class="d-block reservation-btn"
                :color="'warning'"
                :elevation="'0'"
                @click="newOrderRequestModal = true"
            >
                <span>
                    {{ $t('new_reservation_request') }}
                </span>
            </v-btn>
        </v-container>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import PopularDestinations from '~src/components/parts/popularDestinations.src'
    import MainPageFormWrapper from '~src/components/parts/mainPageFormWrapper.src'
    import {cmsStore, persistentStore} from '@/store'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import VueMarkdown from 'vue-markdown'
    import TopTours from '~src/components/tours/topTours.src'
    import HotelHomeMap from '@/components/hotels/HotelHomeMap'
    import Resorts from '@/components/parts/Resorts'
    import PopularHotels from '~src/components/parts/popularHotels.src'
    import Blog from '~src/components/parts/blog.src'
    import NewOrderRequestModal from '~src/components/account/modals/newOrderRequest/newOrderRequestModal.src'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {
            NewOrderRequestModal,
            PopularHotels,
            Resorts,
            HotelHomeMap,
            TopTours,
            PopularDestinations,
            MainPageFormWrapper,
            VueMarkdown,
            Blog,
        },
        layout: 'blank',
        middleware: 'home',
    })
    export default class IndexPage extends Vue {
        newOrderRequestModal = false

        async fetch() {
            await Promise.all([
                cmsStore.loadPopularDestinations(),
                cmsStore.loadPopularHotels(),
                cmsStore.loadHomePageSettings(),
                cmsStore.loadBlogItems(),
            ])
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadPopularDestinations)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadPopularHotels)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadHomePageSettings)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadBlogItems)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadPopularDestinations)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadPopularHotels)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadHomePageSettings)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadBlogItems)
        }

        mounted() {
            persistentStore.SET_TOURISTS([])
        }

        get hideNewReservationRequest() {
            return false
        }

        get popularDestinations() {
            return cmsStore.popularDestinations
        }

        get popularHotelsGroups() {
            return this.popularHotels
                .reduce((groups, hotel) => {
                    const hotelCopy = clone(hotel)
                    if (!hotelCopy.groupBy) {
                        hotelCopy.groupBy = 'no_group'
                    }
                    if (!groups.find(g => g.name === hotelCopy.groupBy)) {
                        groups.push({name: hotelCopy.groupBy, hotels: []})
                    }
                    const group = groups.find(g => g.name === hotelCopy.groupBy)
                    group.hotels.push(hotelCopy)
                    return groups
                }, [])
                .sort(a => (a.name === 'no_group' ? -1 : 0))
                .sort(a => (a.name?.toLowerCase()?.trim() === 'signature collection' ? -1 : 0))
        }

        get popularHotels() {
            return cmsStore.popularHotels
        }

        get homePageSettings() {
            return cmsStore.homePageSettings
        }

        get blogItems() {
            return cmsStore.blogItems
        }
    }
</script>

<style scoped lang="scss">
    .reservation-btn {
        position: fixed;
        top: 50%;
        transform: translateY(-50%) translateX(calc(-50% + 4px)) rotate(270deg);
        z-index: 1;
    }
</style>
