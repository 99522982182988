<template>
    <div>
        <div v-if="!editable" class="text--secondary">
            {{ entry.duration }} {{ $tc('night', entry.duration) }}
            <v-icon color="primary" small @click="editable = true">mdi-pencil</v-icon>
        </div>
        <v-tooltip v-else :key="key" top>
            <template v-slot:activator="{on}">
                <v-select
                    :value="duration"
                    :items="items"
                    single-line
                    hide-details
                    outlined
                    dense
                    :disabled="loading"
                    :loading="loading"
                    class="pe-5"
                    @change="change"
                    v-on="on"
                >
                    <template v-slot:selection="{item}">
                        <span class="text-no-wrap">
                            {{ `${item} ${$tc('night', item)}` }}
                        </span>
                    </template>
                </v-select>
            </template>
            {{ $t('tours.notification.related_offers_was_changed') }}
        </v-tooltip>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {getSupplierIdByCode} from '@/store/modules/hotels/hotelsRuntime'
    import {hotelsRuntimeStore, toursRuntimeStore} from '@/utils/store-accessor'
    import {getMethod} from '~/api'
    import {salesTermsRsTransformer} from '@/utils/api-helpers'

    @Component
    export default class HotelServiceDurationSelect extends Vue {
        @Prop({required: true}) entry
        @Prop({required: true}) offer
        @Prop({required: true}) searchRequest
        @Prop() citizenshipId

        loading = false
        key = 0
        editable = false

        async change(duration) {
            try {
                this.loading = true
                const diff = duration - this.entry.duration
                const {convertToCurrency, rooms} = this.searchRequest
                let adults = 0,
                    childrenAges = []
                for (const room of hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)) {
                    adults += room.adults
                    childrenAges.push(...room.childrenAges)
                }
                const startDate = this.entry.entryDate.split(' ')[0],
                    prevEndDateObj = this.$dateFns.addDays(this.$dateFns.parseISO(startDate), this.entry.duration),
                    endDateObj = this.$dateFns.addDays(this.$dateFns.parseISO(startDate), duration)

                const newHotels = await Promise.all(
                    this.entry.offers.map(async ({supplierCode, cityCode, hotelCode}) => {
                        const supplierId = await getSupplierIdByCode(supplierCode)
                        const {
                            offers: [hotel],
                        } = await this.$api.searchAccommodation.get({
                            cityCode,
                            hotelCode,
                            supplierId,
                            convertToCurrency,
                            rooms,
                            startDate,
                            endDate: this.$dateFns.format(endDateObj),
                        })
                        hotel.rooms.forEach(room => {
                            room.notDeltaPrice = room.price
                            delete room.price
                        })
                        return hotel
                    })
                )
                const oldHotel = hotelsRuntimeStore.findHotelByOfferKey(this.entry.offers, this.entry.selectedOfferKey)
                const oldRoom = hotelsRuntimeStore.findRoomOffer(oldHotel.rooms, this.entry.selectedOfferKey)
                const newHotel = hotelsRuntimeStore.findSameHotel(newHotels, oldHotel)
                const newRoom = hotelsRuntimeStore.findSameRoomOffer(newHotel.rooms, oldRoom)
                const selectedOfferKey = newRoom.groupedOffers[0].offerKey
                const allOldRooms = this.entry.offers.map(({rooms}) => rooms).flat()
                const zeroDeltaPriceOldRoom =
                    allOldRooms.find(({deltaPrice: {amount}}) => amount === 0) ||
                    allOldRooms.find(({deltaPrice: {amount}}) => !amount)
                const zeroDeltaPriceNewRoom = hotelsRuntimeStore.findSameRoomOffer(
                    newHotels.map(({rooms}) => rooms).flat(),
                    zeroDeltaPriceOldRoom
                )
                newHotels.forEach(({rooms}) => {
                    rooms.forEach(room => {
                        if (room.notDeltaPrice.amount) {
                            const {amount, currency} = room.notDeltaPrice
                            room.deltaPrice = {amount: amount - zeroDeltaPriceNewRoom.notDeltaPrice.amount, currency}
                        } else {
                            room.deltaPrice = {}
                        }
                    })
                })
                const updatedEntries = [{...this.entry, selectedOfferKey, offers: newHotels, duration}]

                for (const entry of this.offer.entries.filter(({type}) => ['FLIGHT', 'OWNCHARTER'].includes(type))) {
                    let needUpdate = false
                    const {itinerary} = entry.offers[0]
                    const routes = itinerary.map(({segments}) => {
                        const first = segments[0],
                            last = segments[segments.length - 1]
                        let departureDate = first.departure.date.split(' ')[0]
                        const departureDateObj = this.$dateFns.parseISO(departureDate)
                        if (this.$dateFns.isEqual(departureDateObj, prevEndDateObj)) {
                            needUpdate = true
                            departureDate = this.$dateFns.format(this.$dateFns.addDays(departureDateObj, diff))
                        }
                        return `${first.departure.airportCode}*-${last.arrival.airportCode}*,${departureDate}`
                    })
                    if (needUpdate) {
                        const {offers: flights} = await this.$api.searchFlight.get({
                            convertToCurrency,
                            routes,
                            adults,
                            childrenAges,
                            citizenshipId: this.citizenshipId,
                            directFlight: false,
                        })
                        const offers = flights.map(flight => {
                            flight.notDeltaPrice = flight.price
                            const {currency} = flight.notDeltaPrice
                            flight.deltaPrice = {amount: 0, currency}
                            delete flight.price
                            return flight
                        })
                        offers
                            .sort((a, b) => a.notDeltaPrice.amount - b.notDeltaPrice.amount)
                            .sort((a, b) => {
                                const maxStops = ({itinerary}) =>
                                        Math.max(...itinerary.map(({segments}) => segments.length)),
                                    maxStopsA = maxStops(a),
                                    maxStopsB = maxStops(b)
                                return maxStopsA === maxStopsB ? 0 : maxStopsA > maxStopsB ? 1 : -1
                            })
                        updatedEntries.push({...entry, selectedOfferKey: offers[0].offerKey, offers})
                    }
                }
                for (const entry of this.offer.entries.filter(({type}) => type === 'TRANSFER')) {
                    const offer = entry.offers[0]
                    const startDateObj = this.$dateFns.addDays(
                        this.$dateFns.parseISO(this.offer.info.packageDate.split(' ')[0]),
                        entry.dayNumber - 1
                    )
                    if (this.$dateFns.isEqual(startDateObj, prevEndDateObj)) {
                        const dateTime = this.$dateFns.format(this.$dateFns.addDays(startDateObj, diff))
                        const place = ({type, code}) =>
                            type === 'AIRPORT' ? code : type === 'STATION' ? code : type === 'HOTEL' ? 'hotel' : 'hotel'
                        const {products} = await getMethod('/api/searchTransfer', {
                            convertToCurrency,
                            departurePlace: place(offer.departureInfo),
                            departureCityId: offer.departureInfo.cityId,
                            departureCountryId: offer.departureInfo.countryId,
                            arrivalPlace: place(offer.arrivalInfo),
                            arrivalCityId: offer.arrivalInfo.cityId,
                            arrivalCountryId: offer.arrivalInfo.countryId,
                            adults,
                            childrenAges,
                            dateTime,
                        })
                        let transfer
                        products.find(({offers, info}) => {
                            if (info.name !== entry.name) return false
                            transfer = offers.find(({info}) => info.name === offer.info.name)
                            return !!transfer
                        })
                        if (transfer) {
                            const {price} = salesTermsRsTransformer(transfer.salesTerms)
                            transfer.deltaPrice = {amount: 0, currency: price.currency}
                            //TODO Need to check - deltaPrice 0 is can't use in total calc
                            transfer.notDeltaPrice = price
                            delete transfer.salesTerms
                            const selectedOfferKey = transfer.offerKey
                            updatedEntries.push({
                                ...entry,
                                ...(entry.selectedOfferKey && {selectedOfferKey}),
                                offers: [transfer],
                                dayNumber: entry.dayNumber + diff,
                            })
                        }
                    }
                }
                for (const entry of this.offer.entries.filter(({type}) => type === 'CAR')) {
                    const endDateObj = this.$dateFns.parseISO(entry.offer.dropOffDate)
                    if (this.$dateFns.isEqual(endDateObj, prevEndDateObj)) {
                        const {products} = await getMethod('/api/searchCarRent', {
                            convertToCurrency,
                            dropOffCityId: entry.offer.dropOffCityId,
                            pickUpCityId: entry.offer.pickUpCityId,
                            pickUpDateTime: entry.offer.pickUpDate,
                            dropOffDateTime: this.$dateFns.format(this.$dateFns.addDays(endDateObj, diff)),
                            driverCitizenshipId: this.citizenshipId,
                            driverAge: 30,
                        })
                        if (products.length) {
                            const product = products.find(({info: {modelName}}) => modelName === entry.info.modelName)
                            const offer = product?.offers.find(({rateName}) => rateName === entry.offer.rateName)
                            const {price} = salesTermsRsTransformer(offer.salesTerms)
                            //TODO Need to check - deltaPrice 0 is can't use in total calc
                            offer.deltaPrice = {amount: 0, currency: price.currency}
                            offer.notDeltaPrice = price
                            delete offer.salesTerms
                            const selectedOfferKey = offer.offerKey
                            updatedEntries.push({
                                ...entry,
                                ...(entry.selectedOfferKey && {selectedOfferKey}),
                                offer,
                                info: products[0].info,
                                duration: diff + entry.duration,
                            })
                        }
                    }
                }
                toursRuntimeStore.UPDATE_ENTRIES(updatedEntries)
            } catch (e) {
                this.key++
                console.error(e)
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            } finally {
                this.loading = false
            }
        }

        get duration() {
            return this.entry.duration
        }

        get items() {
            const arr = Array.from(Array(181).keys())
            arr.shift()
            return arr
        }
    }
</script>
