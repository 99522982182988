<template>
    <v-dialog>
        <template v-slot:activator="{on, attrs}">
            <v-btn
                v-if="!isMobile"
                v-bind="attrs"
                text
                dark
                class="text-none font-weight-regular dialog__action-btn"
                v-on="on"
                @click="loadHotels"
            >
                {{ $t('view_on_map') }}
            </v-btn>
            <v-btn v-else v-bind="attrs" icon dark v-on="on" @click="loadHotels">
                <v-icon>mdi-map</v-icon>
            </v-btn>
        </template>
        <template v-slot>
            <v-card v-if="hotels.length">
                <hotel-map-modified :_expanded.sync="mapExpanded" :offers="hotels" :parent="true" class="dialog__map" />
            </v-card>
            <v-card v-else color="primary" dark>
                <v-card-text>
                    {{ $t('loading') }}
                    <v-progress-linear indeterminate color="white" class="mb-0" />
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import HotelMapModified from '@/components/search/offers/HotelMapModified'

    @Component({
        components: {HotelMapModified},
    })
    export default class OwnHotelMapModal extends Vue {
        mapExpanded = false
        hotels = []

        async loadHotels() {
            this.hotels = await this.$api.hotels.get({limit: 0, contentProviderId: 1})
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    .dialog {
        &__action-btn {
            font-size: 0.9375rem;
            letter-spacing: normal;
        }
        &__map {
            max-height: 600px;
            position: unset;
        }
    }
</style>
