<template>
    <v-card>
        <v-card-title class="py-5">
            <v-btn v-if="createMode || editMode" fab class="me-3" small elevation="0" @click="goToTable">
                <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <span v-if="createMode">
                {{ `${$t('create_new_communication')}` }}
            </span>
            <span v-else-if="editMode">
                {{ `${$t('edit_communication')}` }}
            </span>
            <span v-else>
                {{ `${$t('communications')}` }}
            </span>
        </v-card-title>
        <v-card-text>
            <communication-edit-form
                v-if="createMode || editMode"
                ref="form"
                v-model="communicationData"
                :services="services"
            />
            <template v-else>
                <slot :get-communications="getCommunications" />
                <v-data-table
                    :headers="headers"
                    :items="communications"
                    :loading="loading || communicationsLoading"
                    :server-items-length="communicationsTotalCount || -1"
                    :footer-props="{'items-per-page-options': [5, 15, 30, -1]}"
                    :options.sync="tableOptions"
                    :hide-default-footer="
                        !communicationsTotalCount && communications.length <= tableOptions.itemsPerPage
                    "
                    :hide-default-header="isMobile"
                    :mobile-breakpoint="0"
                    disable-sort
                    class="elevation-1"
                    @click:row="editCommunication"
                >
                    <template v-slot:item.communicationDateTime="{item}">
                        {{ item.communicationDateTime | dateShortFormat }}
                    </template>
                    <template v-slot:item.assignedUser="{item}">
                        {{ item.assignedUser ? userName(item.assignedUser) : '' }}
                    </template>
                    <template v-slot:item.communicationType="{item}">
                        {{ $t(`communicationType.${item.communicationType}`) }}
                    </template>
                    <template v-slot:item.communicationStage="{item}">
                        {{ item.communicationStage ? $t(`communicationStage.${item.communicationStage}`) : '' }}
                    </template>
                    <template v-slot:item.status="{item}">
                        {{ $t(`${item.status.toLowerCase()}`) }}
                    </template>
                    <template v-slot:footer.prepend>
                        <span v-if="communicationsTotalCount" class="caption text--secondary">
                            {{ $t('total') }}: {{ communicationsTotalCount }}
                        </span>
                    </template>
                    <template v-slot:item.mobile="{item}">
                        <div class="py-2 d-flex full-width">
                            <div class="d-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden">
                                <div>{{ item.communicationDateTime | dateShortFormat }}</div>
                                <div>{{ $t(`communicationType.${item.communicationType}`) }}</div>
                                <div>{{ `${$t('email_form.to')}: ${item.recepientTo}` }}</div>
                                <div>{{ item.subject }}</div>
                                <div>
                                    <span class="font-weight-bold">{{ $t('order') }}</span>
                                    {{ item.reservationNumber }}
                                </div>
                                <div>
                                    <span class="font-weight-bold">{{ $t('stage') }}</span>
                                    {{
                                        item.communicationStage
                                            ? $t(`communicationStage.${item.communicationStage}`)
                                            : ''
                                    }}
                                </div>
                            </div>
                            <div class="pl-2 d-flex flex-column flex-grow-0 flex-shrink-0 align-end overflow-hidden">
                                <div>{{ item.assignedUser ? userName(item.assignedUser) : '' }}</div>
                                <div>{{ $t(`${item.status.toLowerCase()}`) }}</div>
                            </div>
                        </div>
                    </template>
                </v-data-table>
            </template>
            <v-btn v-if="!createMode && !editMode" color="primary" class="mt-4" @click="create">
                {{ $t('create') }}
            </v-btn>
        </v-card-text>
        <v-card-actions class="py-4">
            <v-spacer />
            <v-btn v-if="createMode || editMode" color="primary" :loading="loading" @click="save">
                {{ $t('save') }}
            </v-btn>
            <v-btn v-if="editMode" text @click="cancel">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import CommunicationEditForm from '@/components/account/communications/CommunicationEditForm'
    import goTo from 'vuetify/es5/services/goto'

    const blankCommunication = (orderId = null) => ({
        type: null,
        stage: null,
        communicationDateTime: null,
        subject: null,
        details: null,
        assignedToId: null,
        orderId,
        accountId: null,
        accountType: null,
        contactId: null,
        processId: null,
    })

    @Component({
        components: {CommunicationEditForm, TimeField, DatePicker},
    })
    export default class Communications extends Vue {
        @Prop() orderId
        @Prop({default: () => [], type: Array}) communications
        @Prop() communicationsLoading
        @Prop() services
        @Prop() communicationsTotalCount

        loading = false
        createMode = false
        editMode = false

        communicationData = blankCommunication()
        tableOptions = {
            itemsPerPage: 15,
            page: 1,
        }

        mounted() {
            this.resetData()
        }

        @Emit()
        getCommunications() {
            return this.tableOptions
        }

        @Watch('tableOptions', {deep: true})
        onChangeTableOptions() {
            this.getCommunications()
        }

        async sendCreateCommunication() {
            try {
                this.loading = true

                await this.$api.communications.post(this.requestData)
                this.getCommunications()

                if (
                    this.$config.reservationReport &&
                    this.requestData.visibleForSuppliers &&
                    this.requestData.processId
                ) {
                    await this.markAsNotSentToSupplier()
                }

                this.goToTable()
                // eslint-disable-next-line no-empty
            } catch (e) {
                if (e.msg) {
                    this.$toast.error(e.msg)
                }
            } finally {
                this.loading = false
            }
        }

        async sendEditCommunication() {
            try {
                this.loading = true
                await this.$api.communications.put(this.requestData.communicationId, this.requestData)
                if (
                    this.$config.reservationReport &&
                    this.requestData.visibleForSuppliers &&
                    this.requestData.processId
                ) {
                    await this.markAsNotSentToSupplier()
                }
                this.getCommunications()
                this.goToTable()
                // eslint-disable-next-line no-empty
            } catch (e) {
                if (e.msg) {
                    this.$toast.error(e.msg)
                }
            } finally {
                this.loading = false
            }
        }

        async save() {
            this.$refs.form.validateForm()
            if (!this.$refs.form.valid) return
            this.editMode ? await this.sendEditCommunication() : await this.sendCreateCommunication()
            this.resetData()
        }

        async markAsNotSentToSupplier() {
            await this.$api.orders.put(this.orderId, {
                servicesInfo: [
                    {
                        processId: this.requestData?.processId,
                        serviceSentToSupplier: false,
                    },
                ],
            })
            await runtimeStore.refreshOrder()
        }

        resetData() {
            this.communicationData = blankCommunication(this.orderId)
        }

        async editCommunication(e) {
            let services = this.services
            if (!services && e.reservationNumber) {
                try {
                    services = (
                        await this.$api.orders.get({
                            orderType: authStore.orderType,
                            orderId: +e.reservationNumber,
                            responseType: 'SHORT',
                        })
                    ).orders[0].services
                } catch (e) {
                    services = []
                }
            }
            this.communicationData = {
                assignedToId: e.assignedUser?.id,
                accountId: e.accountId,
                accountType: e.accountType,
                stage: e.communicationStage,
                communicationDateTime: e.communicationDateTime?.split(' ').join('T'),
                subject: e.subject,
                description: e.description,
                orderId: +e.reservationNumber,
                active: true,
                communicationId: e.communicationId,
                contactId: e.contactId,
                processId: services?.find(item => item.serviceId === e.serviceId)?.processId,
                type: e.communicationType,
            }
            this.editMode = true
            goTo(0)
        }

        goToTable() {
            this.resetData()
            this.createMode = false
            this.editMode = false
        }

        cancel() {
            if (!this.createMode && !this.editMode) {
                this.close()
            }

            this.goToTable()
        }

        close() {
            this.goToTable()
        }

        userName(user) {
            if (!user) return ''
            const {firstName, lastName} = user
            return `${firstName || ''} ${lastName || ''}`
        }

        create() {
            goTo(0)
            this.createMode = !this.createMode
        }

        get requestData() {
            return {
                accountId: this.communicationData.accountId,
                accountType: this.communicationData.accountType,
                type: this.communicationData.type,
                stage: this.communicationData.stage,
                communicationDateTime: this.communicationData.communicationDateTime,
                subject: this.communicationData.subject,
                description: this.communicationData.description,
                orderId: this.communicationData.orderId,
                active: true,
                ...(this.editMode && {communicationId: this.communicationData.communicationId}),
                ...(this.communicationData.processId && {processId: this.communicationData.processId}),
                ...(this.communicationData.contactId && {contactId: this.communicationData.contactId}),
                ...(this.communicationData.assignedToId && {assignedToId: this.communicationData.assignedToId}),
            }
        }

        get headers() {
            if (this.isMobile) return [{value: 'mobile'}]

            return [
                {text: `${this.$t('date')}/${this.$t('time')}`, value: 'communicationDateTime'},
                {text: this.$t('account'), value: 'accountName'},
                {text: this.$t('assigned_to'), value: 'assignedUser'},
                {text: this.$t('type'), value: 'communicationType'},
                {text: this.$t('recipient_email'), value: 'recepientTo'},
                {text: this.$t('subject'), value: 'subject'},
                {text: this.$t('order'), value: 'reservationNumber'},
                {text: this.$t('stage'), value: 'communicationStage'},
                {text: this.$t('status'), value: 'status'},
            ]
        }

        get person() {
            return authStore.person
        }

        get company() {
            return authStore.company
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get isB2B() {
            return authStore.isB2B
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .v-data-table__wrapper tr:hover {
        cursor: pointer;
    }
</style>
