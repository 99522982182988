<template>
    <v-row>
        <v-col cols="12" md="4" class="d-flex align-center">
            <div class="d-flex align-center ml-2">
                <v-checkbox
                    v-model="checked"
                    :disabled="hideAction"
                    @change="
                        $emit('update', {
                            service: extraService,
                            qty,
                            operation: $event,
                            nettoPriceValue,
                            bruttoPriceValue,
                        })
                    "
                />
                <div class="h1 text-wrap">
                    {{ extraService.name }}
                    <v-icon v-if="extraService.description" small @click="showDescription = true">
                        mdi-information-outline
                    </v-icon>
                </div>
            </div>
            <v-dialog v-model="showDescription" width="500" class="description-modal">
                <v-card class="pa-4" v-html="extraService.description" />
            </v-dialog>
        </v-col>
        <v-col v-if="manualModification" cols="3" md="2">
            <v-text-field
                v-model="nettoPriceValue"
                type="number"
                :rules="[positiveNumberRule('price')]"
                :label="$t('netto')"
                :prefix="supplierCurrency"
            />
        </v-col>
        <v-col v-if="manualModification" cols="3" md="2">
            <v-text-field
                v-model="bruttoPriceValue"
                type="number"
                :rules="[positiveNumberRule('price')]"
                :label="$t('brutto')"
                :prefix="clientCurrency"
            />
        </v-col>
        <v-col v-if="showPrice && !manualModification" cols="3" md="2">
            <span class="caption">{{ $t('price') }}</span>
            <div>
                {{ extraServicePrice | price }}
            </div>
        </v-col>
        <v-col cols="3" md="2">
            <span class="caption">{{ $t('price_per') }}</span>
            <div>
                {{ extraService.perPax ? $t('for_guest') : $t('order_service') }}
                {{
                    extraService.calculationType !== 'BY_SERVICE' || extraService.perPax
                        ? $t(`extra_services_details.type.${extraService.calculationType}`)
                        : ''
                }}
            </div>
        </v-col>
        <v-col cols="3" md="2">
            <v-text-field
                v-model="qty"
                :label="$t('quantity')"
                type="number"
                :min="1"
                :max="100"
                :disabled="hideAction"
                @keyup="validateMaxQty"
            />
        </v-col>
        <v-col v-if="showPrice && !manualModification" cols="3" md="2">
            <span class="caption">{{ $t('total') }}</span>
            <div>
                {{ totalPrice | price }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, Watch} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin.vue'
    import {salesTermsRsTransformer} from '@/utils/api-helpers'

    //TODO Need refactoring
    @Component
    export default class ExtraService extends FormValidationRulesMixin {
        @Prop() extraService
        @Prop({default: false}) hideAction
        @Prop({type: Array, default: () => []}) extraServices
        @Prop({default: false, type: Boolean}) manualModification

        qty = 1
        checked = false
        showDescription = false
        nettoPriceValue = null
        bruttoPriceValue = null

        @Watch('qty')
        @Watch('nettoPriceValue')
        @Watch('bruttoPriceValue')
        update() {
            if (this.checked) {
                this.$emit('update', {
                    service: this.extraService,
                    qty: this.qty,
                    operation: true,
                    nettoPriceValue: +this.nettoPriceValue,
                    bruttoPriceValue: +this.bruttoPriceValue,
                })
            }
        }

        mounted() {
            const extraService = !this.manualModification
                ? this.extraServices.find(({serviceRPH}) => this.extraService.serviceRPH === serviceRPH)
                : this.extraServices.find(({extraServiceId}) => +this.extraService.serviceRPH === extraServiceId)
            if (extraService) {
                this.qty = extraService.quantity
                this.checked = true
                if (this.manualModification) {
                    this.nettoPriceValue = extraService.nettoPriceValue
                    this.bruttoPriceValue = extraService.bruttoPriceValue
                }
                return
            }
            if (this.manualModification) {
                this.nettoPriceValue =
                    this.extraServicePrices.supplierPrice.originalAmount || this.extraServicePrices.supplierPrice.amount
                this.bruttoPriceValue =
                    this.extraServicePrices.price.originalAmount || this.extraServicePrices.price.amount
            }
            //TODO Remove this and add initial mandatory extraServices in parent components
            if (this.hideAction) {
                this.checked = true
                this.$emit('update', {service: this.extraService, qty: this.qty, operation: true})
            }
        }

        validateMaxQty(event) {
            if (event.target.value.length > 2) {
                event.preventDefault()
            }
            this.qty = Number(event.target.value) > 100 ? 100 : event.target.value
        }

        get extraServicePrice() {
            return this.extraService.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT')
        }

        get priceType() {
            return this.extraService.perPax ? this.$t('tourist') : this.$t('by_service')
        }

        get totalPrice() {
            return {
                amount: this.extraServicePrice.originalAmount * this.qty,
                currency: this.extraServicePrice.originalCurrency,
            }
        }

        get showPrice() {
            return true
        }

        get extraServicePrices() {
            return salesTermsRsTransformer(this.extraService.salesTerms)
        }

        get clientCurrency() {
            return this.extraServicePrices.price.originalCurrency || this.extraServicePrices.price.currency
        }

        get supplierCurrency() {
            return (
                this.extraServicePrices.supplierPrice.originalCurrency || this.extraServicePrices.supplierPrice.currency
            )
        }
    }
</script>

<style scoped lang="scss">
    .without-line::v-deep {
        .v-input__slot::before {
            border: none;
            display: none;
        }
    }

    ::v-deep input[type='number']::-webkit-inner-spin-button {
        opacity: 1;
    }

    .description-modal {
        p {
            margin-bottom: 0;
        }
    }
</style>
