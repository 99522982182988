<template>
    <div>
        <div class="d-flex text-subtitle-1">
            <v-icon left small>mdi-account-heart</v-icon>
            <strong class="font-weight-bold black--text">
                {{ $t('referrer') }}
            </strong>
        </div>
        <v-row>
            <v-col cols="12" md="2">
                <small class="d-block traveler-label">{{ referrer.prefix || '&nbsp;' }}</small>
                <span
                    class="text-uppercase black--text font-weight-bold"
                    v-text="`${referrer.firstName} ${referrer.lastName}`"
                />
            </v-col>
            <v-col v-if="referrer.phone" cols="12" md="2">
                <small class="d-block traveler-label">{{ $t('phone') }}</small>
                <span class="black--text font-weight-bold">
                    {{ referrer.phone }}
                </span>
            </v-col>
            <v-col v-if="referrer.email" cols="12" md="2">
                <small class="d-block traveler-label">{{ $t('email') }}</small>
                <span class="black--text font-weight-bold">
                    {{ referrer.email }}
                </span>
            </v-col>
            <v-col v-if="referrer.organization" cols="12" md="2">
                <small class="d-block traveler-label">{{ $t('organization') }}</small>
                <span class="black--text font-weight-bold">
                    {{ referrer.organization }}
                </span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class Referrer extends Vue {
        @Prop({required: true}) referrer
    }
</script>
