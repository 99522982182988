<template>
    <div v-if="locales.length > 1" class="language-switcher" :class="$breakpoint.mdAndUp ? 'ma-2' : 'full-width mx-4'">
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{on}">
                <v-btn text :class="isMobile ? 'pa-0' : ''" v-on="on">
                    <language-label :language="locale" show-current-lang text-button />
                    <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item
                    v-for="code in locales"
                    :key="code"
                    :disabled="showTranslationKeys"
                    @click="
                        switchLanguage(code)
                        menu = false
                    "
                >
                    <language-label :language="code" bold />
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import {axiosInstance} from '@/utils/axios-accessor'
    import LanguageLabel from '@/components/snippets/LanguageLabel'
    import {adminRuntimeStore} from '@/utils/store-accessor'

    @Component({
        components: {LanguageLabel},
    })
    export default class LanguageSwitcher extends Vue {
        menu = false

        switchLanguage(localeCode) {
            this.$cookies.set('locale', localeCode)
            axiosInstance.setHeader('Accept-Language', localeCode)
            this.$i18n.locale = localeCode
            this.$vuetify.lang.current = localeCode
            this.$vuetify.rtl = localeCode === 'ar'
            EventBus.$emit(CHANGE_LOCALE_EVENT, localeCode)
        }

        get locales() {
            return Object.values(this.$config.locales)
        }

        get locale() {
            return this.$i18n.locale
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get showTranslationKeys() {
            return adminRuntimeStore.showTranslationKeys
        }
    }
</script>

<style>
    .language-switcher {
        display: flex;
        padding: 1rem;
    }

    .languages {
        display: flex;
        justify-content: flex-end;
    }

    .language {
        padding-left: 0.25rem;
        cursor: pointer;
    }

    .language.active {
        text-decoration: underline;
    }

    .language:hover span {
        text-decoration: underline;
    }

    .language:not(:last-child):after {
        content: '|';
        padding-left: 0.25rem;
    }

    @media (max-width: 991px) {
        .language-switcher {
            padding: 0;
        }
    }
</style>
