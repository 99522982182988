<template>
    <v-card>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="4">
                        <daterange
                            :start-date.sync="model.dateFrom"
                            :end-date.sync="model.dateTo"
                            :popover="{placement: 'bottom'}"
                            :show-icon="false"
                            :single-line="false"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <days-picker :_day-of-weeks.sync="model.dayOfWeeks" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="2" class="d-flex flex-column align-center">
                        <v-text-field
                            v-model.number="model.minNights"
                            readonly
                            append-outer-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            class="text-center shrink"
                            :rules="minRules"
                            hide-details
                            style="width: 90px"
                            @click:append-outer="incrementMinNights"
                            @click:prepend="decrementMinNights"
                        />
                        <span>{{ $t('supplier.min_nights') }}</span>
                    </v-col>
                    <v-col cols="12" md="2" class="d-flex flex-column align-center">
                        <v-text-field
                            v-model.number="model.maxNights"
                            readonly
                            append-outer-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            class="text-center shrink"
                            :rules="maxRules"
                            hide-details
                            style="width: 90px"
                            @click:append-outer="incrementMaxNights"
                            @click:prepend="decrementMaxNights"
                        />
                        <span>{{ $t('supplier.max_nights') }}</span>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="model.serviceId"
                            :label="$t('supplier.room_type')"
                            :items="services"
                            item-text="name"
                            item-value="id"
                            :rules="[requiredRule('supplier.room_type')]"
                            placeholder=""
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="model.tariffId"
                            :label="$t('tariff')"
                            :items="tariffs"
                            item-text="name"
                            item-value="id"
                            :rules="[requiredRule('tariff')]"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            v-model="model.amount"
                            type="number"
                            :label="$t('price')"
                            :rules="[requiredRule('price')]"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn block color="primary" :disabled="!valid" @click="$emit('update', model)">
                            {{ $t('supplier.update') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import Daterange from '@/components/supplier/DateRange'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import DaysPicker from '@/components/supplier/DaysPicker'

    @Component({
        components: {DaysPicker, Daterange},
    })
    export default class MassEditPrices extends Mixins(FormValidationRulesMixin) {
        @Prop() startDate
        @Prop() endDate
        @Prop() services
        @Prop() tariffs

        model = {
            dateFrom: null,
            dateTo: null,
            dayOfWeeks: [true, true, true, true, true, true, true],
            minNights: null,
            maxNights: null,
            serviceId: null,
            tariffId: null,
            amount: null,
        }
        valid = false

        mounted() {
            this.$set(this.model, 'dateFrom', this.startDate)
            this.$set(this.model, 'dateTo', this.endDate)
        }

        incrementMinNights() {
            this.model.minNights = (parseInt(this.model.minNights) || 0) + 1
        }

        decrementMinNights() {
            this.model.minNights = (parseInt(this.model.minNights) || 0) - 1
            if (this.model.minNights < 0) {
                this.model.minNights = 0
            }
        }

        incrementMaxNights() {
            this.model.maxNights = (parseInt(this.model.maxNights) || 0) + 1
        }

        decrementMaxNights() {
            this.model.maxNights = (parseInt(this.model.maxNights) || 0) - 1
            if (this.model.maxNights < 0) {
                this.model.maxNights = 0
            }
        }

        get minRules() {
            return [
                v =>
                    !this.model.maxNights ||
                    this.model.maxNights >= v ||
                    `${this.$t('supplier.invalid_number_interval')}`,
            ]
        }

        get maxRules() {
            return [
                v =>
                    !this.model.minNights ||
                    this.model.minNights <= v ||
                    `${this.$t('supplier.invalid_number_interval')}`,
            ]
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-text-field.text-center input {
            text-align: center;
        }
    }
</style>
