<template>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{on}">
            <v-btn color="primary" dark icon v-on="on">
                <v-icon>mdi-mail</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>{{ $t('send_email') }} {{ $t('for') }}</v-card-title>
            <v-card-text>
                <v-checkbox v-model="emailFor" value="suppliers" :label="$tc('suppliers', 2)" />
                <v-checkbox v-model="emailFor" value="clients" :label="$t('clients')" />
                <v-checkbox v-model="emailFor" value="travelers" :label="$t('travelers')" />
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :disabled="!emailFor.length || loading"
                    :loading="loading"
                    color="primary"
                    depressed
                    block
                    @click="select"
                >
                    {{ $t('select') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <send-clients-email-modal v-model="emailModal" :emails="emails" @sent="orders = []" />
    </v-menu>
</template>

<script>
    import {Component, VModel} from 'nuxt-property-decorator'
    import SendClientsEmailModal from '@/components/account/modals/SendClientsEmailModal'
    import OrderEmailsMixin from '@/components/account/mixins/OrderEmailsMixin.vue'

    @Component({
        components: {SendClientsEmailModal},
    })
    export default class OrderEmailsMenu extends OrderEmailsMixin {
        @VModel() orders

        menu = false
        loading = false
        emailModal = false
        emails = []

        async select() {
            this.loading = true
            this.emails = await this.getOrderEmails(this.orders)
            this.menu = false
            this.emailModal = true
            this.loading = false
        }
    }
</script>
