<template>
    <v-dialog v-model="isShowVoucherDialog" hide-overlay max-width="500">
        <v-card>
            <v-card-title>
                {{ title }}
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid" @submit.prevent>
                    <v-radio-group v-model="voucherType">
                        <v-radio v-for="(type, i) in voucherTypes" :key="type" :label="`${type}`" :value="i" />
                    </v-radio-group>
                    <v-text-field
                        v-if="voucherType === 1"
                        v-model="voucherEmail"
                        :label="`${$t('email')}*`"
                        type="email"
                        autocomplete="email"
                        :rules="validationRules('email', [emailRule, requiredRule])"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    depressed
                    :disabled="!valid && voucherType === 1"
                    color="primary"
                    :loading="isLoading"
                    @click="processVoucher"
                >
                    {{ $t('ok') }}
                </v-btn>
                <v-btn depressed @click="isShowVoucherDialog = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class VoucherDialog extends mixins(FormValidationRulesMixin) {
        @VModel() isShowVoucherDialog
        @Prop({required: true}) title
        @Prop({required: true}) isLoading

        voucherType = 0
        voucherEmail = ''
        valid = false

        processVoucher() {
            switch (this.voucherType) {
                case 0:
                    this.downloadVoucher()
                    break
                default:
                    this.sendOnEmail()
                    break
            }
        }

        @Emit()
        sendOnEmail() {
            return this.voucherEmail
        }

        @Emit()
        downloadVoucher() {}

        get voucherTypes() {
            return [`${this.$t('download')} PDF`, `${this.$t('send_to_email')}`]
        }

        get order() {
            return runtimeStore.orderDetails
        }
    }
</script>
