<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="180px"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                ref="input"
                v-model="time"
                :label="label"
                :rules="rules"
                :outlined="outlined"
                :dense="dense"
                :hide-details="hideDetails"
                :disabled="disabled"
                background-color="white"
                prepend-icon="mdi-clock"
                :type="type"
                :placeholder="type === 'text' ? '__:__' : ''"
                required
                v-on="on"
            />
        </template>
        <time-picker v-model="time" :min="min" :max="max" @update="menu = false" />
    </v-menu>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import TimePicker from '@/components/snippets/forms/TimePicker'
    import Cleave from 'cleave.js'

    //TODO Move TimePicker here and rename this to TimePicker
    @Component({
        components: {TimePicker},
    })
    export default class TimeField extends Vue {
        @VModel() time
        @Prop() label
        @Prop() rules
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: false, type: Boolean}) hideDetails
        @Prop({default: false, type: Boolean}) disabled
        @Prop() min
        @Prop() max
        @Prop({default: 'time', type: String}) type

        menu = false

        mounted() {
            if (this.type === 'text') {
                const input = this.$refs.input.$el.querySelector('input')
                new Cleave(input, {
                    time: true,
                    timePattern: ['h', 'm'],
                })
                input.oninput = e => {
                    this.time = e.target.value
                }
            }
        }
    }
</script>
