<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import TrainStationsSearchMixin from '~/mixins/TrainStationsSearchMixin'
    import CruisePortsSearchMixin from '~/mixins/CruisePortsSearchMixin'
    import CityHotelAutocomplete from '~/components/search/forms/CityHotelAutocomplete'

    @Component({
        mixins: [TrainStationsSearchMixin, CruisePortsSearchMixin],
    })
    export default class TransferPointAutocomplete extends CityHotelAutocomplete {
        @Prop({default: 'TRANSFER'}) productType
        @Prop() departureLocation

        async searchHotels(pattern) {
            const locationId = this.departureLocation
                ? this.departureLocation.type === 'city'
                    ? this.departureLocation.id
                    : this.departureLocation.cityId
                : null
            let rs = await this.$api.suggestHotels.get({
                pattern,
                limit: 10,
                ...(locationId && {locationId}),
            })
            if (this.departureLocation && !rs.hotels.length) {
                rs = await this.$api.suggestHotels.get({
                    pattern,
                    limit: 10,
                    locationId: this.departureLocation.countryId,
                })
            }
            if (this.departureLocation && !rs.hotels.length) {
                rs = await this.$api.suggestHotels.get({
                    pattern,
                    limit: 10,
                })
            }
            return this.mapHotels(rs.hotels)
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                const [cities, hotels] = await Promise.all([this.searchCities(val), this.searchHotels(val)])
                this.entries = [...cities, ...hotels]
                const citiesWithAirportsAndTrainStations = await Promise.all(cities.map(this.cityMapper))
                this.entries = [...citiesWithAirportsAndTrainStations.flat(), ...hotels]

                if (!this.entries.length) {
                    this.tryResearch.status = true
                    this.tryResearch.message = this.$t('try_rewrite_destination')
                }
            } finally {
                this.loading = false
            }
        }

        async cityMapper(city) {
            const [airports, trainStations, cruisePorts] = await Promise.all([
                this.searchAirports({
                    locationId: city.id,
                    limit: 0,
                    ...(!this.$config.account.hasTransferExternalSuppliers && {productType: this.productType}),
                }),
                this.searchTrainStations({
                    locationId: city.id,
                    limit: 0,
                    ...(!this.$config.account.hasTransferExternalSuppliers && {productType: this.productType}),
                }),
                this.searchCruisePorts({
                    locationId: city.id,
                    limit: 0,
                    ...(!this.$config.account.hasTransferExternalSuppliers && {productType: this.productType}),
                }),
            ])
            trainStations.map(trainStation => {
                trainStation.cityName = city.name
                trainStation.countryName = city.countryName
                trainStation.parentName = city.countryName
                trainStation.countryId = city.countryId
            })
            return [...[city], ...airports, ...trainStations, ...cruisePorts]
        }

        async searchAirports(rq) {
            const defaults = {
                pattern: null,
                limit: 10,
                orderBy: 'POPULARITY',
            }

            //TODO Fix limit in API
            const rs = await this.$api.airports.get({...defaults, ...rq})

            return rs.map(airport => {
                return Object.assign(airport, {parentName: airport.countryName, type: 'airport'})
            })
        }

        icon(type) {
            switch (type) {
                case 'hotel':
                    return this.hotelIcon
                case 'airport':
                    return this.airportIcon
                case 'trainStation':
                    return this.trainStationIcon
                case 'cruisePort':
                    return this.cruisePortIcon
                default:
                    return this.locationIcon
            }
        }

        get airportIcon() {
            return 'mdi-airplane-takeoff'
        }

        get trainStationIcon() {
            return 'mdi-train'
        }

        get cruisePortIcon() {
            return 'mdi-ferry'
        }
    }
</script>
