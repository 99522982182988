<template>
    <v-row dense>
        <v-col :cols="canEdit ? 9 : 12">
            <v-select
                v-if="!isEditing"
                v-model="quickFilter"
                :loading="isLoading"
                :disabled="isLoading"
                :items="filterItems"
                :label="$t('quick_filter')"
                clearable
                dense
                @change="changeFilter"
                @click:clear="
                    clearQuickFilter()
                    clearFilters()
                "
            />
            <v-text-field
                v-else
                v-model="newFilterName"
                :label="$t('filter_name')"
                :placeholder="$t('please_enter_a_new_name')"
                autofocus
                clearable
                dense
            />
        </v-col>
        <v-col v-if="canEdit" cols="3" class="d-flex align-center">
            <template v-if="!isEditing">
                <v-btn color="primary" icon x-small @click="isEditing = !isEditing">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-else>
                <v-btn
                    :loading="isLoading"
                    :disabled="isLoading || !newFilterName"
                    color="success"
                    icon
                    x-small
                    @click="save"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn color="error" icon x-small @click="isEditing = !isEditing">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="error" icon x-small class="ml-2" @click="deleteCustomFilter">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </template>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'
    import {orderFilters} from '@/utils/store-blank-states'

    @Component
    export default class OrderQuickFilter extends Vue {
        @Prop() person

        quickFilter = {}
        filters = {}
        customFilters = []
        isLoading = false
        isEditing = false
        canEdit = false
        newFilterName = ''

        mounted() {
            this.loadCustomFilters()
        }

        async loadCustomFilters() {
            this.isLoading = true

            try {
                const customFilters = await this.$api.customFilters.get()

                this.customFilters = customFilters.filter(
                    el =>
                        el.customFilter.creatorId === this.person.personId ||
                        (el.customFilter.companyId === this.person.companyId && el.customFilter.type === 'all')
                )
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        changeFilter(val) {
            this.canEdit = false
            this.load()

            if (val === 'not_paid_by_clients') {
                this.filters['customerInvoiceStatuses'] = [
                    'BILL',
                    'NO_BILL',
                    'IN_TRANSIT',
                    'OVERDUE',
                    'NO_FULLY_BILL',
                    'INVOICE_WAITING',
                ]
            } else if (val === 'not_paid_by_suppliers') {
                this.filters['supplierInvoiceStatuses'] = [
                    'BILL',
                    'NO_BILL',
                    'IN_TRANSIT',
                    'OVERDUE',
                    'NO_FULLY_BILL',
                    'INVOICE_WAITING',
                ]
            } else if (val === 'nearest_trips') {
                const startDateFrom = this.$dateFns.format(new Date())
                const startDateTo = this.$dateFns.format(this.$dateFns.add(new Date(), {weeks: 2}))
                this.filters['serviceStartDateFrom'] = startDateFrom
                this.filters['serviceStartDateTo'] = startDateTo
            } else if (val === 'recently_created') {
                const createdDateFrom = this.$dateFns.format(this.$dateFns.sub(new Date(), {weeks: 2}))
                const createdDateTo = this.$dateFns.format(new Date())
                this.filters['createdDateFrom'] = createdDateFrom
                this.filters['createdDateTo'] = createdDateTo
            } else if (val === 'recently_modified') {
                const modifiedDateFrom = this.$dateFns.format(this.$dateFns.sub(new Date(), {weeks: 2}))
                const modifiedDateTo = this.$dateFns.format(new Date())
                this.filters['modifiedDateFrom'] = modifiedDateFrom
                this.filters['modifiedDateTo'] = modifiedDateTo
            } else {
                const index = this.customFilters.findIndex(el => el.id === val)

                if (index >= 0) {
                    for (const filterName in this.customFilters[index].customFilter.filters) {
                        this.filters[filterName] = this.customFilters[index].customFilter.filters[filterName]
                    }
                    this.canEdit = this.customFilters[index].customFilter.creatorId === this.person.personId
                }
            }

            persistentStore.SET_ORDER_FILTERS(this.filters)
            this.$emit('change')
        }

        async deleteCustomFilter() {
            this.isLoading = true

            try {
                await this.$api.customFilters.remove({id: this.quickFilter})
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                await this.loadCustomFilters()
                this.clearQuickFilter()
                this.clearFilters()
            }
        }

        save() {
            this.isLoading = true

            try {
                const data = this.customFilters.find(el => el.id === this.quickFilter)
                data.customFilter.text = this.newFilterName

                this.$api.customFilters.post(data)
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
                this.isEditing = false
                this.newFilterName = ''
            }
        }

        load() {
            const filters = clone(persistentStore.orderFilters)
            filters['customerInvoiceStatuses'] = []
            filters['supplierInvoiceStatuses'] = []
            filters['serviceStartDateFrom'] = null
            filters['serviceStartDateTo'] = null
            filters['createdDateFrom'] = null
            filters['createdDateTo'] = null
            filters['modifiedDateFrom'] = null
            filters['modifiedDateTo'] = null
            this.filters = filters
        }

        clearQuickFilter() {
            this.quickFilter = null
            this.isEditing = false
        }

        clearFilters() {
            this.filters = orderFilters()
            persistentStore.SET_ORDER_FILTERS(this.filters)
            this.$emit('change')
        }

        get filterItems() {
            let quickFilter = [
                'not_paid_by_clients',
                'not_paid_by_suppliers',
                'nearest_trips',
                'recently_created',
                'recently_modified',
            ]

            if (this.isAgency || this.isCorporate)
                quickFilter = quickFilter.filter(filter => filter !== 'not_paid_by_clients')

            quickFilter = quickFilter.map(value => ({value, text: this.$t(`quickFilter.${value}`)}))

            if (this.customFilters.length) {
                this.customFilters.map(el => {
                    quickFilter.push({value: el.id, text: el.customFilter.text})
                })
            }

            return quickFilter
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isCorporate() {
            return authStore.isCorporate
        }
    }
</script>
