<template>
    <div class="text-center">
        <supplier-price v-if="!isSupplierPriceTooltip && !isAgency" :supplier-price="room.supplierPrice" />
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn
                    v-if="searchRoomsCount === 1"
                    color="primary"
                    small
                    block
                    depressed
                    max-height="36px"
                    data-cy="client-price"
                    class="mb-2 body-2"
                    @click="selectForBooking"
                    v-on="isSupplierPriceTooltip && isSupplierPriceVisible ? on : null"
                >
                    {{ btnText }}
                    <corporate-policy-violation
                        v-if="room.corporatePolicyViolation && !$breakpoint.smAndDown"
                        :policies="room.corporatePolicy"
                    />
                </v-btn>
                <span
                    v-else-if="showPrice"
                    :class="{'cursor-pointer': isSupplierPriceTooltip && isSupplierPriceVisible}"
                    data-cy="client-price"
                    v-on="isSupplierPriceTooltip && isSupplierPriceVisible ? on : null"
                    >{{ room.price | price }}</span
                >
            </template>
            <supplier-price v-if="isSupplierPriceTooltip" class="white--text" :supplier-price="room.supplierPrice" />
        </v-tooltip>
        <div v-if="searchRoomsCount !== 1 && showPrice">
            <corporate-policy-violation
                v-if="room.corporatePolicyViolation && !$breakpoint.smAndDown"
                :policies="room.corporatePolicy"
            />
        </div>
        <template v-if="showPrice">
            <hotel-price-note :loyalty-points="room.loyaltyPoints" :search-request="searchRequest" :rph="rph" />
            <discount-label :price="room.price" class="d-inline-block mt-1" />
            <corporate-policy-violation
                v-if="room.corporatePolicyViolation && $breakpoint.smAndDown"
                :policies="room.corporatePolicy"
            />
            <commission-amount :commission="room.price.commission" />
            <net-amount :price="room.price" />
            <rate-note :room="room" />
        </template>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, hotelsStore} from '@/store'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import RateNote from '~src/components/snippets/RateNote'
    import SupplierPrice from '~src/components/snippets/supplierPrice.src'

    @Component({
        components: {
            HotelPriceNote,
            DiscountLabel,
            CommissionAmount,
            NetAmount,
            CorporatePolicyViolation,
            RateNote,
            SupplierPrice,
        },
    })
    export default class HotelRoomOfferPriceBlock extends Vue {
        @Prop({default: () => ({})}) room
        @Prop({required: true}) searchRequest
        @Prop({default: () => ({})}) offer
        @Prop({default: 0}) rph

        selectForBooking() {
            const offerKey = [this.room.groupedOffers[0].offerKey]
            hotelsStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'hotelBooking',
                query: {offerKey},
            })
        }

        get isSupplierPriceTooltip() {
            return this.$config.isSupplierPriceTooltip || false
        }

        get isSupplierPriceVisible() {
            return this.room.supplierPrice && authStore.companyType && authStore.companyType !== 'CORPORATE'
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms.length
        }

        get showPrice() {
            return true
        }

        get btnText() {
            return this.$options.filters.price(this.room.price)
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>
