<template>
    <v-expansion-panels v-if="selectedGroupBy !== 'none'" v-model="panels" multiple flat focusable accordion tile>
        <v-expansion-panel
            v-for="(rooms, index) in groupedRooms"
            :key="`group-${index}`"
            :class="isMultiRoom ? 'my-2' : ''"
        >
            <v-expansion-panel-header
                class="text-nobreak"
                :class="$breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                color="blue-grey lighten-5"
            >
                <hotel-room-name-label
                    v-if="selectedGroupBy === 'name'"
                    :hotel="offer"
                    :room="rooms[0]"
                    :compare-mode="compareMode"
                >
                    <v-icon
                        left
                        small
                        class="info--text"
                        @click.native.stop="roomInfo(offer, rooms[0].groupedOffers[0].offerKey)"
                    >
                        mdi-information-outline
                    </v-icon>
                    {{ rooms[0].name }}
                </hotel-room-name-label>
                <div v-else>
                    {{ $t(`filters_mealTypes.${rooms[0].mealTypeStandardCode}`) }}
                </div>
                <strong class="text-right pr-4">
                    <v-chip
                        v-if="selectedGroupBy !== 'mealTypeStandardCode'"
                        label
                        x-small
                        :color="minPriceRoomOffer(rooms).available ? 'green darken-1' : '#d79600'"
                        class="flex-grow-0 mr-4 white--text"
                    >
                        {{ minPriceRoomOffer(rooms).available ? $t('available') : $t('on_request') }}
                    </v-chip>
                    <template v-if="minPriceRoomOffer(rooms).price && showPrice">
                        {{ $t('from') }} {{ minPriceRoomOffer(rooms).price | price }}
                    </template>
                </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content :class="isMultiRoom ? 'border' : ''">
                <div class="d-flex flex-column flex-md-row align-center">
                    <v-img
                        v-if="getImgSrc(rooms[0])"
                        tile
                        class="mx-md-4 cursor-pointer"
                        :src="getImgSrc(rooms[0]) | imageUrl(300)"
                        :alt="rooms[0].name"
                        eager
                        :width="$breakpoint.smAndDown ? '100%' : 150"
                        :max-width="$breakpoint.smAndDown ? '100%' : 150"
                        height="100"
                        :lazy-src="'/placeholder.png' | srcHost"
                        @click="roomInfo(offer, rooms[0].groupedOffers[0].offerKey)"
                    />
                    <div class="full-width" :class="getImgSrc(rooms[0]) ? 'side-border' : ''">
                        <div v-for="(room, roomIndex) in rooms.slice(0, expanded)" :key="`room-${roomIndex}-${index}`">
                            <div class="d-flex">
                                <slot name="radio" :room="room" />
                                <hotel-room
                                    :group-by="selectedGroupBy"
                                    :search-request="searchRequest"
                                    :room="room"
                                    :hotel="offer"
                                    :hotel-info="hotelInfo"
                                    :rph="1"
                                    :class="isMultiRoom ? 'py-2' : 'pa-4'"
                                    :compare-mode="compareMode"
                                    :data-cy="`room-offer-card-${roomIndex}`"
                                >
                                    <template v-slot:price>
                                        <slot name="price" :room="room" />
                                    </template>
                                </hotel-room>
                            </div>
                            <v-divider v-if="roomIndex !== rooms.length - 1" />
                        </div>
                    </div>
                </div>
                <div v-if="rooms.length > expanded" class="d-flex my-4 justify-center">
                    <v-btn depressed small color="primary" @click.stop="expanded = expanded + 5">
                        {{ $t('show_more_rooms') }}
                    </v-btn>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import {Component, Vue, Prop, Watch, PropSync} from 'nuxt-property-decorator'
    import {EventBus, SEARCH_EVENT, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import CorporatePolicyViolation from '@/components/snippets/CorporatePolicyViolation'
    import HotelRoomOfferPriceBlock from '~src/components/search/offers/hotelRoomOfferPriceBlock.src'
    import SupplierPrice from '~src/components/snippets/supplierPrice.src'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMealTypeLabel from '~src/components/hotels/snippets/hotelMealTypeLabel.src'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'
    import HotelRoom from '~src/components/hotels/search/offers/hotelRoom.src'
    import HotelRoomNameLabel from '@/components/hotels/snippets/HotelRoomNameLabel'

    @Component({
        components: {
            HotelRoom,
            HotelMealTypeLabel,
            HotelPriceNote,
            DiscountLabel,
            ConditionsLabel,
            CommissionAmount,
            CorporatePolicyViolation,
            HotelRoomOfferPriceBlock,
            SupplierPrice,
            TariffLabel,
            HotelRoomNameLabel,
        },
    })
    export default class HotelGroupedRoomOffers extends Vue {
        @Prop({default: () => []}) rooms
        @Prop() offer
        @Prop({required: true}) searchRequest
        @Prop({required: true}) hotelInfo
        @Prop({default: false}) compareMode
        @PropSync('_selectedOfferKeys', {default: () => []}) selectedOfferKeys

        expanded = 5
        panels = [0]
        selectedOffers = []
        roomGroupIds = []
        firstChosenRoom = null

        @Watch('selectedGroupBy')
        onGroupByChanged() {
            this.panels = [0]
        }

        created() {
            EventBus.$on(SEARCH_EVENT, this.clearSelectedOfferKeys)
        }

        beforeDestroy() {
            EventBus.$off(SEARCH_EVENT, this.clearSelectedOfferKeys)
        }

        getImgSrc(room) {
            if (this.selectedGroupBy !== 'name') return null
            const roomType = this.hotelInfo.hotelDescriptionInfo.roomTypes?.roomType.find(
                roomType => roomType.code === room.code
            )
            return roomType?.images.image[0]?.url
        }

        clearSelectedOfferKeys() {
            this.selectedOfferKeys = []
        }

        roomInfo(hotel, offerKey) {
            EventBus.$emit(SHOW_ROOM_INFO_MODAL_EVENT, hotel, offerKey)
        }

        minPriceRoomOffer(rooms) {
            return hotelsRuntimeStore.minPriceRoomOffer({rooms})
        }

        get groupedRooms() {
            const mealTypesSortOrder = ['RO', 'BB', 'HB', 'FB', 'AI', 'BBT', 'HBT', 'FBT', 'other']
            const result = {}

            this.rooms.forEach(room => {
                if (!result[room[this.selectedGroupBy]]) {
                    result[room[this.selectedGroupBy]] = []
                }
                result[room[this.selectedGroupBy]].push(room)
            })

            const arr = Object.values(result)
            arr.sort((a, b) => {
                const aIndex = mealTypesSortOrder.indexOf(a[0].mealTypeStandardCode)
                const bIndex = mealTypesSortOrder.indexOf(b[0].mealTypeStandardCode)
                return aIndex - bIndex
            })

            return arr
        }

        get selectedGroupBy() {
            return hotelsStore.groupOffersBy
        }

        set selectedGroupBy(val) {
            hotelsStore.SET_GROUP_OFFERS_BY(val)
        }

        get isMultiRoom() {
            return this.searchRequest.rooms?.length > 1
        }

        get showPrice() {
            return true
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .title {
        text-transform: capitalize;
    }

    ::v-deep .v-select__selections {
        font-size: 13px;
    }

    ::v-deep .v-list-item--link {
        font-size: 12px;
    }

    ::v-deep .v-list {
        padding: 0;
    }

    ::v-deep .v-expansion-panel-content__wrap {
        padding-bottom: 0;
    }

    .border {
        border-color: rgba(map-get($shades, 'black'), 0.12);
        border-style: solid;
        border-left-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-top-width: 0;
    }

    .side-border {
        border-left: 1px solid rgba(map-get($shades, 'black'), 0.12);
    }
</style>
