<template>
    <v-form v-model="valid" class="mb-5">
        <v-checkbox v-model="termsOfUse" :rules="[v => !!v || $t('confirmation_is_required')]" hide-details>
            <template v-slot:label>
                <div class="confirmation-text" @click.stop v-html="termsOfUseHtml" />
            </template>
        </v-checkbox>
        <v-checkbox v-model="privacyPolicy" :rules="[v => !!v || $t('confirmation_is_required')]" hide-details>
            <template v-slot:label>
                <div class="confirmation-text" @click.stop v-html="privacyPolicyHtml" />
            </template>
        </v-checkbox>
        <due-to-confirm-date-checkbox
            v-if="selectedAddOns.length === 0"
            :start-date="startDate"
            :due-to-confirm-date.sync="_dueToConfirmDate"
            :due-to-confirm-date-active.sync="_dueToConfirmDateActive"
        />
        <additional-agreement-checkboxes :room-offers="roomOffers" />
    </v-form>
</template>

<script>
    import {Component, Prop, PropSync, VModel, Vue} from 'nuxt-property-decorator'
    import DueToConfirmDateCheckbox from '@/components/booking/forms/fields/DueToConfirmDateCheckbox.vue'
    import AdditionalAgreementCheckboxes from '~src/components/booking/forms/AdditionalAgreementCheckboxes.vue'

    @Component({
        components: {AdditionalAgreementCheckboxes, DueToConfirmDateCheckbox},
    })
    export default class BookingAgreementForm extends Vue {
        @VModel() valid
        @PropSync('dueToConfirmDate') _dueToConfirmDate
        @PropSync('dueToConfirmDateActive') _dueToConfirmDateActive
        @Prop() countryId
        @Prop({required: true}) startDate
        @Prop({required: true}) selectedAddOns
        @Prop() roomOffers

        privacyPolicy = false
        termsOfUse = false

        get termsOfUseHtml() {
            return this.$t('confirmation_terms_of_use').replace('%s', `-${this.countryId}` || '')
        }

        get privacyPolicyHtml() {
            return this.$t('confirmation_privacy_policy').replace('%s', `-${this.countryId}` || '')
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .confirmation-text {
        font-size: 0.875rem;
    }
</style>
