<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <component :is="component" :product="entry">
            <template v-slot:price="{offer}">
                <tour-offer-delta-button
                    :offer="offer"
                    :offer-key="offerKey"
                    :entry-name="entry.entryName"
                    class="my-2"
                />
            </template>
        </component>
        <insurance-info-modal />
    </v-dialog>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_INSURANCE_INFO} from '@/utils/event-bus'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton'
    import InsuranceInfoModal from '@/components/insurance/info/InsuranceInfoModal'

    @Component({
        components: {
            TourOfferDeltaButton,
            InsuranceInfoModal,
            InsuranceOfferCard: () => import('@/components/insurance/search/offers/InsuranceOfferCard'),
            InsuranceOfferCardMobile: () => import('@/components/insurance/search/offers/InsuranceOfferCardMobile'),
        },
    })
    export default class TourInsuranceInfoModal extends Vue {
        @Prop({required: true}) searchRequest

        modal = false
        entry = {}
        offerKey = null

        mounted() {
            EventBus.$on(SHOW_TOUR_INSURANCE_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_INSURANCE_INFO, this.showModal)
        }

        async showModal(entry, offerKey) {
            this.entry = entry
            this.offerKey = offerKey
            this.modal = true
        }

        get component() {
            return !this.$breakpoint.smAndDown ? 'insurance-offer-card' : 'insurance-offer-card-mobile'
        }
    }
</script>
