<template>
    <v-expansion-panels v-if="showPassport" flat :value="expanded">
        <v-expansion-panel>
            <v-expansion-panel-header class="px-0 width-auto primary--text">
                {{ $t('passport_data') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
                <v-form ref="form">
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="type"
                                :items="passportTypes"
                                :label="fieldLabel('document_type', 'PASSPORT_TYPE')"
                                clearable
                                :rules="[requiredRule('document_type', 'PASSPORT_TYPE')]"
                                :disabled="isDisabled"
                            >
                                <template v-slot:item="{item}">
                                    {{ $t(`documentTypes.${item}`) }}
                                </template>
                                <template v-slot:selection="{item}">
                                    {{ $t(`documentTypes.${item}`) }}
                                </template>
                            </v-select>
                        </v-col>
                        <v-col v-if="!isFieldDisabled('PASSPORT_NUMBER')" cols="12" md="3">
                            <v-text-field
                                v-model="number"
                                :label="fieldLabel('document_number', 'PASSPORT_NUMBER')"
                                :rules="[requiredRule('document_number', 'PASSPORT_NUMBER')]"
                                :disabled="isDisabled"
                            />
                        </v-col>
                        <v-col v-if="!isFieldDisabled('PASSPORT_ISSUE_DATE')" cols="12" md="3">
                            <date-picker
                                v-model="issueDate"
                                :label="fieldLabel('issue_date', 'PASSPORT_ISSUE_DATE')"
                                :rules="[requiredRule('issue_date', 'PASSPORT_ISSUE_DATE')]"
                                :max="today"
                                :disabled="isDisabled"
                                year-active-picker
                            />
                        </v-col>
                        <v-col v-if="!isFieldDisabled('PASSPORT_EXPIRE_DATE')" cols="12" md="3">
                            <date-picker
                                v-model="expiryDate"
                                :label="fieldLabel('expire_date', 'PASSPORT_EXPIRE_DATE')"
                                :rules="[requiredRule('expire_date', 'PASSPORT_EXPIRE_DATE')]"
                                :min="today"
                                :disabled="isDisabled"
                                year-active-picker
                            />
                        </v-col>
                        <v-col
                            v-if="!isFieldDisabled('PASSPORT_ISSUE_COUNTRY') && isShowCountryOfIssueId"
                            cols="12"
                            md="3"
                        >
                            <v-select
                                v-model="countryOfIssueId"
                                clearable
                                item-text="name"
                                :items="citizenshipItems"
                                :label="$t('issue_country')"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import {Component, Emit, mixins, Prop} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import BookingFieldsMixin from '@/components/booking/mixins/BookingFieldsMixin'
    import DatePicker from '@/components/snippets/forms/DatePicker'

    @Component({
        components: {DatePicker},
    })
    export default class TouristPassportForm extends mixins(BookingFieldsMixin) {
        @Prop({required: true}) index
        @Prop() disabled
        @Prop() corporatePolicyViolation
        @Prop({required: false, type: Boolean}) expandedAll

        getProp(prop) {
            return persistentStore.tourists[this.index].passport[prop]
        }

        @Emit()
        setProp(prop, val) {
            const index = this.index
            persistentStore.SET_TOURIST_PASSPORT_PROP({prop, val, index})
        }

        get passportTypes() {
            return ['PASSPORT', 'PASSPORT_EXTERNAL', 'BIRTH_CERTIFICATE', 'OTHER']
        }

        get today() {
            return new Date().toISOString()
        }

        get type() {
            return this.getProp('type')
        }

        set type(val) {
            this.setProp('type', val)
        }

        get number() {
            return this.getProp('number')
        }

        set number(val) {
            this.setProp('number', val)
        }

        get issueDate() {
            return this.getProp('issueDate')
        }

        set issueDate(val) {
            this.setProp('issueDate', val)
        }

        get expiryDate() {
            return this.getProp('expiryDate')
        }

        set expiryDate(val) {
            this.setProp('expiryDate', val)
        }

        get countryOfIssueId() {
            return this.getProp('countryOfIssueId')
        }

        set countryOfIssueId(val) {
            this.setProp('countryOfIssueId', val)
        }

        get isShowCountryOfIssueId() {
            return false
        }

        get personId() {
            return persistentStore.tourists[this.index].personId
        }

        get showTouristSelector() {
            return authStore.person && ['corp.trip_coordinator', 'corp.director'].includes(authStore.person.role)
        }

        get isDisabled() {
            return (
                (authStore.isCorporate && !!this.personId) || authStore.user.isEmployeeOrExternalCorp || this.disabled
            )
        }

        get citizenshipItems() {
            return runtimeStore.restrictedCountries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }

        get showPassport() {
            const bookingFields = runtimeStore.touristBookingFields.bookingFields
            const passportFieldKeys = [
                // 'PASSPORT_ISSUE_COUNTRY',
                'PASSPORT_EXPIRE_DATE',
                'PASSPORT_ISSUE_DATE',
                'PASSPORT_NUMBER',
            ]
            return (
                bookingFields &&
                passportFieldKeys.findIndex(fieldKey => !this.isFieldDisabled(fieldKey)) !== -1 &&
                (!this.corporatePolicyViolation || (this.corporatePolicyViolation && !!this.personId))
            )
        }

        get expanded() {
            if (this.expandedAll) return 0

            return this.index === 0 ? 0 : null
        }

        get tourist() {
            return persistentStore.tourists[this.index]
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
            color: var(--v-primary-base) !important;
        }

        .v-expansion-panel-content__wrap {
            padding-left: 0;
            padding-right: 0;
        }
    }
</style>
