<template>
    <v-form ref="form">
        <v-row class="d-flex" align="center">
            <v-col cols="12" md="2" lg="1">
                <v-select
                    v-model="traveler.prefix"
                    outlined
                    dense
                    :items="['Mr', 'Ms', 'Mrs']"
                    :label="`${$t('salutation')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field v-model="traveler.firstName" outlined dense :label="`${$t('first_name')}`" hide-details />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="traveler.middleName"
                    outlined
                    dense
                    :label="`${$t('middle_name')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field v-model="traveler.lastName" outlined dense :label="`${$t('last_name')}`" hide-details />
            </v-col>
            <v-col cols="12" md="2">
                <date-picker
                    v-model="traveler.dateOfBirth"
                    :label="$t('birthdate')"
                    :max="$dateFns.format(new Date())"
                    :rules="traveler.type === 'child' || traveler.delete ? [requiredRule('birthdate')] : []"
                    hide-details
                    outlined
                    dense
                    year-active-picker
                />
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field
                    v-model="type"
                    outlined
                    dense
                    disabled
                    :label="$t('flights.passenger_type')"
                    hide-details
                />
            </v-col>
            <v-col cols="12" :md="mealTypesList.length ? 2 : 1" class="d-flex">
                <v-select
                    v-if="mealTypesList.length"
                    v-model="traveler.alternativeMealTypeId"
                    :label="$t('meal_type')"
                    :items="mealTypesList"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    @change="$emit('change-meal-type')"
                />
                <v-btn icon @click="remove">
                    <v-icon>mdi-close-box-outline</v-icon>
                </v-btn>
            </v-col>
            <slot />
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Emit, mixins, Prop, PropSync} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {capitalizeTheFirstLetterOfEachWord} from '@/utils/helpers'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {DatePicker},
    })
    export default class ModifyManualTraveler extends mixins(FormValidationRulesMixin) {
        @PropSync('_traveler', {default: () => ({}), type: Object}) traveler
        @Prop({required: true}) index
        @Prop() mealTypesList

        @Emit()
        remove() {
            return this.index
        }

        get type() {
            return capitalizeTheFirstLetterOfEachWord(this.$tc(this.traveler.type, 1))
        }

        set type(v) {}
    }
</script>
