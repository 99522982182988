<template>
    <v-card outlined class="d-flex flex-column width-max-content">
        <div v-if="date">
            <vc-date-picker
                v-model="date"
                :attributes="attributes"
                :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                :locale="$i18n.locale"
                :columns="isMobile ? 1 : 2"
                mode="date"
                is-required
                @update:from-page="pageChange"
            />
        </div>
        <v-progress-linear v-if="loading" indeterminate />
    </v-card>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class EventsCalendar extends Vue {
        @VModel() groups

        loading = false
        date = null

        async mounted() {
            await this.pageChange({year: this.$dateFns.getYear(new Date()), month: this.$dateFns.getMonth(new Date())})
            this.date = this.$dateFns.format(new Date(), 'yyyy-MM-dd')
        }

        async loadGroups(startDate, endDate) {
            try {
                this.groups = []
                this.loading = true

                this.groups = (
                    await this.$api.schedulerGroups.get({
                        dateStart: startDate,
                        dateEnd: endDate,
                        ...(this.isDriver && {driverId: this.personId}),
                        ...(this.isGuide && {guideId: this.personId}),
                    })
                ).schedulerGroups
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        async pageChange(e) {
            const {year, month} = e

            const startDate = this.$dateFns.format(new Date(+year, +month - 1, 1), 'yyyy-MM-dd')
            let endDate = this.$dateFns.format(this.$dateFns.addMonths(new Date(+year, +month, 0), 1), 'yyyy-MM-dd')
            if (this.isMobile) endDate = this.$dateFns.format(new Date(+year, +month, 0), 'yyyy-MM-dd')

            await this.loadGroups(startDate, endDate)
        }

        get attributes() {
            return this.groups.reduce((attributes, group) => {
                const filteredGroups = this.groups?.filter(
                    g => g.dateTime.split(' ')[0] === group.dateTime.split(' ')[0]
                )

                attributes.push({
                    dates: this.$dateFns.format(group.dateTime),
                    key: group.dateTime,
                    highlight:
                        filteredGroups?.length && filteredGroups.every(g => g.status === 'DONE') ? 'green' : 'yellow',
                })

                return attributes
            }, [])
        }

        get firstDayOfWeekByLocale() {
            return this.$dateFns.localeOptions[this.$i18n.locale].weekStartsOn
        }

        get personId() {
            return authStore.person.personId
        }

        get isGuide() {
            return authStore.isGuide
        }

        get isDriver() {
            return authStore.isDriver
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-date-picker-table__events {
            width: 100%;
            height: 100%;
            z-index: -1;
            bottom: 1px;
            left: -2px;

            div {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
