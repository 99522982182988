<template>
    <div v-if="isShow" class="d-flex align-center">
        <v-icon v-if="room.available" :title="$t('available')" small left class="success--text">
            mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else :title="$t('on_request')" small left class="warning--text">mdi-alert-circle</v-icon>
        <span
            v-if="room.availableRooms && room.availableRooms === 1"
            class="warning--text"
            v-text="$t('available_one_room')"
        />
        <span
            v-else-if="room.availableRooms && room.availableRooms < 5"
            class="warning--text"
            v-text="$tc('available_rooms', room.availableRooms)"
        />
        <span v-else-if="room.availableRooms" class="success--text" v-text="$t('enough_available_rooms')" />
        <span v-else-if="!room.available" class="warning--text" v-text="$t('on_request')" />
        <span v-else class="success--text" v-text="$t('available')" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class HotelAvailableLabel extends Vue {
        @Prop({required: true}) room

        get isShow() {
            return true
        }
    }
</script>
