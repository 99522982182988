<template>
    <v-select
        v-model="selectedStatus"
        :loading="loading"
        :disabled="loading"
        :items="statuses"
        append-outer-icon="mdi-close"
        dense
        hide-details
        return-object
        class="ma-0"
        @change="save"
        @click:append-outer="close"
    />
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class OrderDetailsEditReservationStatus extends Vue {
        @Prop({required: true}) status
        @Prop({required: true}) orderId
        @Prop({default: false, type: Boolean}) loading

        selectedStatus = null
        actualStatuses = []

        @Emit()
        save() {
            if (this.selectedStatus.value !== this.status) return this.selectedStatus.value
        }

        @Emit()
        close() {}

        mounted() {
            this.selectedStatus = {value: this.status, text: this.$t(`order_statuses.${this.status}`)}
            this.actualStatuses = [this.status]
            this.load()
        }

        async load() {
            try {
                const {statuses} = await this.$api.currentOrderStatuses.get({orderId: this.orderId})

                statuses.forEach(item => {
                    if (item !== this.actualStatuses[0]) this.actualStatuses.push(item)
                })
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get statuses() {
            return this.actualStatuses.map(value => ({value, text: this.$t(`order_statuses.${value}`)}))
        }
    }
</script>
