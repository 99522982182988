<script>
    import {Component} from 'vue-property-decorator'
    import MobileSearchPageTabs from '~/components/snippets/MobileSearchPageTabs'
    import {EventBus, PACKAGE_HOTEL_SELECTED, PACKAGE_FLIGHT_SELECTED} from '~/utils/event-bus'

    @Component
    export default class PackageMobileSearchPageTabs extends MobileSearchPageTabs {
        get tabs() {
            const tabs = this.defaultTabs
            return [...tabs, 'mdi-bag-carry-on']
        }

        created() {
            EventBus.$on(PACKAGE_FLIGHT_SELECTED, () => {
                this.mobileTabs = 3
            })
            EventBus.$on(PACKAGE_HOTEL_SELECTED, () => {
                this.mobileTabs = 3
            })
            if (this.$breakpoint.smAndDown) {
                this.mobileTabs = 3
            }
        }

        beforeDestroy() {
            EventBus.$off(PACKAGE_FLIGHT_SELECTED)
            EventBus.$off(PACKAGE_HOTEL_SELECTED)
        }
    }
</script>
