<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'
    import {isOwnProduct} from '@/utils/api-helpers'
    import {persistentStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    @Component
    export default class CruiseBookingPageLayout extends mixins(BookingPageBase, CruisesStoreMixin) {
        @Prop({required: true}) product

        setTourists() {
            const tourists = this.initTourists(this.searchRequest, clone(persistentStore.tourists))
            const {onExtraBed, onWithoutPlace} = clone(this.offer)

            const onExtraBedCounter = this.offer.onExtraBed?.reduce((counter, item) => {
                if (item.quantity) counter += item.quantity

                return counter
            }, 0)
            const onWithoutPlaceCounter = this.offer.onWithoutPlace?.reduce((counter, item) => {
                if (item.quantity) counter += item.quantity

                return counter
            }, 0)
            const indexStartCounter =
                onExtraBedCounter && onWithoutPlaceCounter
                    ? tourists?.length - (onExtraBedCounter + onWithoutPlaceCounter) - 1
                    : 0

            tourists.forEach((tourist, index) => {
                if (index) {
                    const onExtraBedItem = onExtraBed?.find(
                        ({type, quantity, age}) => tourist.type === type && tourist.age === age && quantity
                    )
                    if (onExtraBedItem && index > indexStartCounter) {
                        onExtraBedItem.quantity--
                        tourist.onExtrabed = true
                    } else {
                        delete tourist.onExtrabed
                    }
                }
            })
            tourists.forEach((tourist, index) => {
                if (index) {
                    const onWithoutPlaceItem = onWithoutPlace?.find(
                        ({type, quantity, age}) =>
                            tourist.type === type && tourist.age === age && quantity && !tourist.onExtrabed
                    )
                    if (onWithoutPlaceItem && index > indexStartCounter) {
                        onWithoutPlaceItem.quantity--
                        tourist.onWithoutPlace = true
                    } else {
                        delete tourist.onWithoutPlace
                    }
                }
            })
            persistentStore.SET_TOURISTS(tourists)
        }

        get productSearchPageName() {
            return 'cruises'
        }

        get productConfirmationPageName() {
            return 'cruiseConfirmation'
        }

        get productName() {
            return 'cruise'
        }

        get productBookingPageName() {
            return 'cruiseBooking'
        }

        get corporatePolicyType() {
            return 'cruise'
        }

        get touristToken() {
            return 'guest'
        }

        get startDate() {
            return this.offer.startDate
        }

        get showAddOns() {
            return isOwnProduct(this.product.supplierId)
        }
    }
</script>
