<template>
    <v-dialog v-model="isShowActivityCalendar">
        <v-card>
            <v-card-title class="d-flex align-center justify-center">
                <div class="d-flex justify-center align-center">
                    <v-btn icon class="ma-2" color="primary" @click="prev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <span v-if="$refs.calendar" class="primary--text text-h6 text-capitalize">
                        {{ $refs.calendar.title }}
                    </span>
                    <v-btn color="primary" icon class="ma-2" @click="next">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
                <v-icon class="v-card-close mt-5 me-5" @click="isShowActivityCalendar = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-calendar
                    ref="calendar"
                    v-model="calendarValue"
                    :type="$breakpoint.smAndDown ? 'day' : 'week'"
                    :events="events"
                    :event-overlap-mode="'column'"
                    :event-height="null"
                    :weekdays="weekday"
                    :locale="$i18n.locale"
                    :event-color="'white'"
                    :start="firstStartDate || null"
                    :event-margin-bottom="6"
                    @change="loadEventsCalendar"
                    @click:event="goToActivityPage"
                >
                    <template v-slot:event="{event}">
                        <div
                            class="d-flex flex-column px-2 black--text event-wrapper"
                            :class="{[getEventColor(event)]: event.isHover, 'white--text': event.isHover}"
                            @mouseover="event.isHover = true"
                            @mouseout="event.isHover = false"
                        >
                            <div
                                class="d-flex font-weight-medium"
                                :class="{'white--text': event.isHover, 'primary--text': !event.isHover}"
                            >
                                <span v-text="event.time" />
                                <div class="ms-auto">
                                    <span v-for="lang in event.languages" :key="lang.langCode" v-text="lang.langCode" />
                                </div>
                            </div>
                            <span class="font-weight-bold">
                                {{ event.name }}
                            </span>
                            <span>{{ event.price | price }}</span>
                        </div>
                    </template>
                </v-calendar>
                <template v-if="eventsLoading">
                    <div v-for="n in 5" :key="n" class="d-flex flex-column">
                        <div v-if="$breakpoint.smAndDown" class="full-width d-flex flex-row justify-space-around my-1">
                            <v-skeleton-loader type="table-cell" />
                        </div>
                        <div v-else class="full-width d-flex flex-row justify-space-around my-1">
                            <v-skeleton-loader v-for="i in 7" :key="i" type="table-cell" />
                        </div>
                    </div>
                </template>
                <div v-else-if="events.length === 0" class="d-flex justify-center text-h6 py-4">
                    {{ $t('calendar_not_found') }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import ActivitiesStoreMixin from '@/mixins/ActivityStoreMixin'
    import {appInstance} from '@/utils/app-accessor'
    import {persistentStore} from '@/utils/store-accessor'

    @Component
    export default class ActivityCalendarModal extends mixins(ActivitiesStoreMixin) {
        @Prop() queryParams
        @Prop({required: true}) city
        @VModel() isShowActivityCalendar
        @Prop() firstStartDate

        calendarValue = ''
        events = []
        eventsLoading = false
        weekday = [1, 2, 3, 4, 5, 6, 0]
        offers = []

        @Emit()
        close() {}

        @Watch('isShowActivityCalendar')
        async onShowActivityCalendarChange() {
            if (!this.isShowActivityCalendar) {
                this.close()
            } else {
                await this.loadEventsCalendar()
            }
        }

        getEventColor(event) {
            const dayOfWeek = new Date(event.start).getDay()
            const isWeekend = dayOfWeek === 6 || dayOfWeek === 0

            return isWeekend ? 'primary lighten-2' : 'primary'
        }

        prev() {
            this.$refs.calendar.prev()
        }

        next() {
            this.$refs.calendar.next()
        }

        goToActivityPage(e) {
            this.productStore.SET_SEARCH_REQUEST(this.searchRequest)
            this.$router.push(this.activityPageLink(e.event.name, e.day.date))
        }

        activityPageLink(name, date) {
            return this.productRuntimeStore.infoPageLink(
                {info: {name}},
                {...this.searchRequest, startDate: date, endDate: date}
            )
        }

        get searchRequest() {
            const query = {}

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            let lastStart = this.$refs.calendar?.lastStart.date

            if (lastStart) {
                lastStart = this.$dateFns.isBefore(new Date(lastStart), new Date())
                    ? this.$dateFns.format(new Date())
                    : lastStart
            }

            const firstStartDate = this.firstStartDate || this.$dateFns.format(new Date())
            const firstEndDate = this.$dateFns.format(
                this.$dateFns.addDays(new Date(firstStartDate), this.isMobile ? 0 : 7)
            )

            return {
                ...query,
                startDate: lastStart || firstStartDate,
                endDate: this.$refs.calendar?.lastEnd.date || firstEndDate,
                cityId: this.city.id,
                partialResponse: false,
                sharedActivity: true,
                privateActivity: true,
                ...this.queryParams,
            }
        }

        async loadEventsCalendar() {
            this.events = []

            if (this.city?.id) {
                try {
                    this.eventsLoading = true

                    const rs = await appInstance.$api.searchActivities.get(this.searchRequest)
                    this.info = rs.product.info
                    this.offers = rs.product

                    this.events = rs.product
                        .reduce((offersAcc, pr) => {
                            offersAcc = [
                                ...offersAcc,
                                ...pr.offers.map(e => ({
                                    ...e,
                                    supplierId: pr.supplierId,
                                    productName: pr.info.name,
                                    info: pr.info,
                                })),
                            ]
                            return offersAcc
                        }, [])
                        .filter(offer => offer.supplierId.indexOf('company.') === 0)
                        .map(e => ({
                            name: e.productName,
                            start: e.date,
                            end: e.date,
                            offerKey: e.offerKey,
                            price: e.price,
                            info: e.info,
                            time: e.startTimes[0],
                            languages: e.languages,
                            isHover: false,
                        }))
                        .sort((a, b) => {
                            return a.time?.localeCompare(b.time)
                        })

                    // eslint-disable-next-line no-empty
                } catch {
                } finally {
                    this.eventsLoading = false
                }
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-calendar-daily__body,
    ::v-deep .v-calendar-daily__intervals-head {
        display: none;
    }

    ::v-deep .v-skeleton-loader__text {
        height: 40px;
    }

    ::v-deep .v-calendar-daily_head-day {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .event-wrapper {
        background: rgba(0, 0, 0, 0.05);
    }
</style>
