<template>
    <div>
        <v-btn
            :disabled="!summaryKey"
            :color="summaryKey ? 'primary' : 'grey'"
            depressed
            text
            x-small
            @click="showModal"
        >
            {{ $t('summary_of_reservations') }}
        </v-btn>
        <v-dialog v-model="modal" max-width="600">
            <v-card>
                <v-card-title class="title font-weight-light">
                    {{ $t('summary_of_reservations') }}
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12" sm="5">
                            <div class="text-subtitle-2">{{ $tc('suppliers', 2) }}</div>
                            <v-data-table
                                :headers="headers"
                                :items="supplierSummaryItems"
                                disable-sort
                                hide-default-footer
                            />
                        </v-col>
                        <v-col v-if="!isMobile" cols="2" />
                        <v-col cols="12" sm="5">
                            <div class="text-subtitle-2">{{ $t('clients') }}</div>
                            <v-data-table
                                :headers="headers"
                                :items="clientSummaryItems"
                                disable-sort
                                hide-default-footer
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="grey" small depressed class="white--text" @click="modal = false">
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class ReservationsSummaryModal extends Vue {
        @Prop({default: '', type: String}) summaryKey

        modal = false
        isLoading = false
        clientSummary = []
        supplierSummary = []

        async showModal() {
            try {
                const {clientSummary, supplierSummary} = await this.$api.ordersSummary.get({
                    summaryKey: this.summaryKey,
                })

                this.clientSummary = clientSummary
                this.supplierSummary = supplierSummary
                this.modal = true
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get headers() {
            return [
                {text: this.$t('turnovers'), value: 'turnoverAmount'},
                {text: this.$t('due_to_pay'), value: 'dueToPayAmount'},
            ]
        }

        get supplierSummaryItems() {
            return this.supplierSummary.reduce((list, summary) => {
                list.push({
                    dueToPayAmount: this.$options.filters.priceFormat(summary.dueToPayAmount, summary.currency),
                    turnoverAmount: this.$options.filters.priceFormat(summary.turnoverAmount, summary.currency),
                })

                return list
            }, [])
        }

        get clientSummaryItems() {
            return this.clientSummary.reduce((list, summary) => {
                list.push({
                    dueToPayAmount: this.$options.filters.priceFormat(summary.dueToPayAmount, summary.currency),
                    turnoverAmount: this.$options.filters.priceFormat(summary.turnoverAmount, summary.currency),
                })

                return list
            }, [])
        }

        get user() {
            return authStore.user
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
