<template>
    <v-form>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`details`) }} </span>
        <v-row>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.pickUpPoint"
                    outlined
                    dense
                    :label="`${$t('pick_up_point')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <time-field
                    v-model="product.pickUpTime"
                    outlined
                    dense
                    hide-details
                    class="cursor-pointer"
                    :label="`${$t('pick_up_time')}`"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.dropOffPoint"
                    outlined
                    dense
                    :label="`${$t('drop_off_point')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <time-field
                    v-model="product.dropOffTime"
                    outlined
                    dense
                    hide-details
                    class="cursor-pointer"
                    :label="`${$t('drop_off_time')}`"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    v-model="product.oneWay"
                    outlined
                    dense
                    :label="`${$t('transfers.one_way_or_return')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    v-model="product.groupTransfer"
                    outlined
                    dense
                    :label="`${$t('transfers.shared_or_private')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="4">
                <time-field
                    v-model="product.duration"
                    outlined
                    dense
                    hide-details
                    class="cursor-pointer"
                    :label="`${$t('duration')}`"
                    type="text"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.contactNumber"
                    outlined
                    dense
                    :label="`${$t('number_or_transfer_provider')}`"
                    hide-details
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'

    @Component({
        components: {TimeField},
    })
    export default class OfflineServiceFormTransfer extends Vue {
        @Prop() productType
        @Prop() tourists
        @VModel() product
    }
</script>
