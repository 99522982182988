<template>
    <div v-intersect.once="onIntersect" class="map">
        <div ref="map" />
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import CarsMap from '@/components/carsRent/search/CarsMap'

    @Component
    export default class CarMapPoints extends CarsMap {
        @Prop() pickUpPoints
        @Prop() returnPoints
        @Prop({default: '500px'}) height

        async load() {
            await this.initMap()
            this.setMarkers(this.pickUpPoints, this.returnPoints)
        }

        setMarkers(pickUpPoints, returnPoints) {
            this.markers.forEach(marker => marker.setMap(null))
            this.markers = []
            if (!pickUpPoints?.length && !returnPoints?.length) return
            if (pickUpPoints?.length) pickUpPoints.forEach(point => this.addMarker(point, 'pickUpPoints'))
            if (returnPoints?.length) pickUpPoints.forEach(point => this.addMarker(point, 'returnPoints'))

            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            this.setMapPosition()
        }

        saveMapPosition() {}
    }
</script>
