<template>
    <div v-if="searchRequest" class="text-caption text--secondary">
        {{ $t('for') }}
        <span v-if="roomsCount">{{ roomsCount }} {{ $tc('room', roomsCount) }},</span>
        {{ touristsTotal }} {{ $tc('guest', touristsTotal) }}, {{ nights }} {{ $tc('night', nights) }}
        <div v-if="loyaltyPoints">{{ `${$t('loyalty')}: ${loyaltyPoints}` }}</div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class HotelPriceNote extends Vue {
        @Prop({required: true}) searchRequest
        @Prop({default: 0}) roomsCount
        @Prop({default: 0}) rph
        @Prop() loyaltyPoints

        get nights() {
            if (!this.searchRequest) return 0
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get touristsTotal() {
            if (!this.searchRequest) return 0
            if (this.rph) {
                const tourists = hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)[this.rph - 1]
                return tourists.adults + tourists.childrenAges.length
            }
            return hotelsRuntimeStore.searchRequestTouristsTotal(this.searchRequest)
        }
    }
</script>

<style scoped>
    .text-caption {
        line-height: 1rem;
    }
</style>
