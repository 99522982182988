<template>
    <v-dialog v-model="modal" overlay-opacity="0" width="100%" max-width="800" @click:outside="closeModal">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else justify-center align-center>
            <div class="mb-4">
                <v-icon class="v-card-close" @click="closeModal">mdi-close</v-icon>
                <v-card-title class="justify-center">
                    {{ $t('invoices.pay_invoices_title') }} {{ orderId }}
                </v-card-title>
            </div>
            <v-card-subtitle v-if="paymentMethods.length" class="mt-2 mb-0 py-0">
                {{ $t('invoices.pay_invoices_subtitle') }}
            </v-card-subtitle>
            <v-card-text v-if="paymentMethods.length">
                <v-radio-group v-model="invoicePaymentKey">
                    <template v-for="paymentMethod in paymentMethods">
                        <v-radio :key="paymentMethod.id" :value="paymentMethod.invoicePaymentKey">
                            <template v-slot:label>
                                <span>
                                    <span>
                                        {{
                                            paymentMethod.paymentMethodCode
                                                ? paymentMethod.paymentMethodCode
                                                : paymentMethod.paymentMethod === 'BANK_TRANSFER'
                                                ? $t('bank_transfer.bank_transfer')
                                                : $t('deposit')
                                        }}
                                    </span>
                                    <strong>
                                        {{ paymentMethod.paymentAmount | priceFormat(paymentMethod.currency) }}
                                    </strong>
                                    <span v-if="paymentMethod.pspBankFeeAmount">
                                        (<span
                                            v-html="
                                                $t('psp_bank_fee', {
                                                    bookingAmount: $options.filters.priceFormat(
                                                        paymentMethod.originalPaymentAmount,
                                                        paymentMethod.currency
                                                    ),
                                                    bankFee: $options.filters.priceFormat(
                                                        paymentMethod.pspBankFeeAmount,
                                                        paymentMethod.currency
                                                    ),
                                                })
                                            "
                                        />)
                                    </span>
                                </span>
                            </template>
                        </v-radio>
                        <span
                            v-if="paymentMethod.pspBankFeeAmount"
                            :key="`${paymentMethod.id}_description`"
                            class="text-caption ms-8 mb-2"
                        >
                            {{ $t('psp_bank_fee_note') }}
                        </span>
                    </template>
                </v-radio-group>
                <div id="dropin-container" />
            </v-card-text>
            <v-alert v-if="depositError" prominent type="error" dismissible @input="closeAlert">
                <div>{{ depositError }}</div>
            </v-alert>
            <v-card-actions v-if="paymentMethods.length" class="pa-6 flex-wrap">
                <div v-if="partialPaymentAllowed && invoicePaymentKey" class="d-flex align-center text--secondary">
                    <div class="mr-2">{{ $t('invoices.amount_to_be_paid') }}</div>
                    <v-text-field
                        v-model="partialPaymentPercent"
                        outlined
                        dense
                        hide-details
                        type="number"
                        class="percent-input flex-grow-0"
                        @change="
                            partialPaymentAmount =
                                Math.round(selectedPaymentMethod.originalPaymentAmount * $event) / 100
                        "
                    />
                    <div class="mx-2">%=</div>
                    <v-text-field
                        v-model="partialPaymentAmount"
                        outlined
                        dense
                        hide-details
                        type="number"
                        class="amount-input flex-grow-0"
                        @change="
                            partialPaymentPercent =
                                Math.round(($event / selectedPaymentMethod.originalPaymentAmount) * 10000) / 100
                        "
                    />
                </div>
                <v-spacer />
                <div>
                    <v-btn class="black--text mr-4" color="white" :disabled="payLoading" @click="closeModal">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="white--text"
                        color="primary"
                        :disabled="!invoicePaymentKey"
                        :loading="payLoading"
                        @click="pay"
                    >
                        {{ $t('pay') }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
        <payment-redirect-form ref="paymentRedirectForm" />
    </v-dialog>
</template>

<script>
    import {Component, Vue, Watch} from 'nuxt-property-decorator'
    import {EventBus, PAY_ORDER_EVENT, PAY_SERVICE_EVENT, SHOW_NOTIFICATIONS} from '@/utils/event-bus'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import {loadStripe} from '@stripe/stripe-js'
    import AdyenCheckout from '@adyen/adyen-web'

    //TODO Need refactoring with BookingMixin
    @Component({
        components: {
            PaymentRedirectForm,
        },
    })
    export default class InvoicesPayModal extends Vue {
        modal = false
        loaded = false
        orderId = null
        objectId = null
        paymentMethods = []
        partialPaymentAllowed = false
        invoicePaymentKey = null
        payLoading = false
        invoiceType = 'SUPPLIER'
        depositError = false
        invoiceId = null
        partialPaymentAmount = 0
        partialPaymentPercent = 100

        @Watch('invoicePaymentKey')
        onChangeInvoicePaymentKey(invoicePaymentKey) {
            if (invoicePaymentKey) {
                this.partialPaymentAmount = this.selectedPaymentMethod.originalPaymentAmount
                this.partialPaymentPercent = 100
            }
        }

        created() {
            EventBus.$on(PAY_SERVICE_EVENT, this.payServiceModal)
            EventBus.$on(PAY_ORDER_EVENT, this.payServiceModal)
        }

        beforeDestroy() {
            EventBus.$off(PAY_SERVICE_EVENT, this.payServiceModal)
            EventBus.$off(PAY_ORDER_EVENT, this.payServiceModal)
        }

        closeModal() {
            this.modal = false
            this.depositError = false
            this.invoicePaymentKey = false
        }

        closeAlert() {
            this.depositError = false
        }

        async payServiceModal(obj) {
            this.loaded = false
            this.modal = true
            this.orderId = obj.orderId
            this.objectId = obj.objectId
            this.invoiceId = obj.invoiceId
            if (obj.invoiceType) {
                this.invoiceType = obj.invoiceType
            }
            this.loaded = await this.invoicePaymentMethods()
        }

        async invoicePaymentMethods() {
            try {
                let invoiceIds

                if (!this.invoiceId) {
                    const issueInvoicesInfo = await this.$api.invoiceIssueInfo.get({
                        orderId: this.objectId,
                        invoiceType: this.invoiceType,
                    })

                    if (issueInvoicesInfo.transactions.length) {
                        const serviceIds = issueInvoicesInfo.transactions.map(transaction => {
                            return transaction.serviceId
                        })

                        await this.$api.issueInvoice.post({
                            invoiceIssueTransactions: serviceIds.map(serviceId => ({serviceId})),
                            invoiceType: this.invoiceType,
                        })
                    }

                    const orderInvoices = await this.$api.invoices.get({
                        orderId: this.objectId,
                        invoiceType: this.invoiceType,
                    })

                    invoiceIds = orderInvoices.invoices
                        .filter(
                            ({paymentStatus, amountToPay}) =>
                                ['BILL', 'PARTIAL_PAID'].includes(paymentStatus) && (authStore.isTO1 || amountToPay > 0)
                        )
                        .map(({invoiceId}) => invoiceId)
                } else {
                    invoiceIds = [this.invoiceId]
                }
                if (invoiceIds.length) {
                    const paymentMethods = await this.$api.invoicePaymentMethods.get({
                        orderId: this.objectId,
                        invoiceId: invoiceIds,
                        invoiceType: this.invoiceType,
                    })
                    this.paymentMethods = paymentMethods.invoicePaymentMethods.filter(paymentMethod => {
                        return ['DEPOSIT_PAYMENT', 'PAYMENT_SYSTEM_PROVIDER', 'BANK_TRANSFER'].includes(
                            paymentMethod.paymentMethod
                        )
                    })
                    this.partialPaymentAllowed = paymentMethods.partialPaymentAllowed
                } else {
                    this.$toast.warning(this.$t('warning_message.payment_not_allowed'))
                    this.modal = false
                    return false
                }
                return true
            } catch (error) {
                this.$toast.error(error.errors[0].message)
                this.modal = false
                return false
            }
        }

        async pay() {
            this.payLoading = true

            const paymentMethod = this.selectedPaymentMethod
            const partialPaymentAmount =
                this.partialPaymentAllowed && +this.partialPaymentPercent !== 100 ? +this.partialPaymentAmount : 0

            // DEPOSIT
            if (!paymentMethod.paymentMethodCode) {
                try {
                    await this.$api.invoicePaymentByKey.put({
                        invoicePaymentKey: this.invoicePaymentKey,
                        ...(partialPaymentAmount && {partialPaymentAmount}),
                    })
                    this.modal = false
                    this.$emit('issuedInvoice', this.invoiceType)
                } catch (error) {
                    this.depositError = this.$t('error_message.deposit_error')
                }
            } else {
                await this.makeOnlinePayment(partialPaymentAmount)
            }

            this.payLoading = false
        }

        //TODO MAKE REFACTORING IN ALL 3 PLACES
        async makeOnlinePayment(partialPaymentAmount) {
            try {
                const paymentCompletePageQuery = status => {
                    return {
                        invoicePaymentKey: this.invoicePaymentKey,
                        orderId: this.orderId,
                        status: status,
                        ...(partialPaymentAmount && {partialPaymentAmount}),
                    }
                }

                const getPaymentUrl = paymentStatus => {
                    return (
                        window.location.origin +
                        this.$router.resolve({
                            name: 'invoicePaymentComplete',
                            query: paymentCompletePageQuery(paymentStatus),
                        }).href
                    )
                }

                const rq = {
                    invoicePaymentKey: this.invoicePaymentKey,
                    partialPaymentAmount,
                }
                const rqOnline = {
                    ...rq,
                    ...{
                        successUrl: getPaymentUrl('success'),
                        failureUrl: getPaymentUrl('failure'),
                        cancelUrl: getPaymentUrl('cancel'),
                    },
                }

                const paymentInitiateRs = await persistentStore.paymentInitiate(rqOnline)

                if (paymentInitiateRs.simpleRedirect) {
                    if (paymentInitiateRs.parameters.length) {
                        const query = new URLSearchParams(
                            paymentInitiateRs.parameters.map(({name, value}) => [name, value])
                        )
                        window.location = paymentInitiateRs.url + '?' + query
                    } else {
                        window.location = paymentInitiateRs.url
                    }
                } else {
                    const paymentMethod = this.selectedPaymentMethod
                    if (paymentMethod.paymentMethodCode === 'webpay' && this.$config.webpayContractDomain) {
                        const {url, parameters, paymentNumber, paymentTransactionId} = paymentInitiateRs
                        await this.$refs.paymentRedirectForm.submit({
                            url: `${this.$config.webpayContractDomain}/payment-redirect`,
                            parameters,
                            paymentNumber,
                            paymentTransactionId,
                            originUrl: url,
                        })
                    } else if (paymentMethod.paymentMethodCode === 'stripe') {
                        const {APIPublicKey, sessionId} = paymentInitiateRs.parameters.reduce(
                            (parameters, {name, value}) => {
                                parameters[name] = value
                                return parameters
                            },
                            {}
                        )
                        const stripe = await loadStripe(APIPublicKey)
                        await stripe.redirectToCheckout({sessionId})
                    } else if (paymentMethod.paymentMethodCode === 'adyen') {
                        const findParameter = (name, array) => {
                            return array.find(e => e.name === name)?.value
                        }
                        const checkout = await AdyenCheckout({
                            session: {
                                id: findParameter('SESSION_ID', persistentStore.paymentInitiateResponse.parameters),
                                sessionData: findParameter(
                                    'SESSION_DATA',
                                    persistentStore.paymentInitiateResponse.parameters
                                ),
                            },
                            clientKey: findParameter(
                                'CLIENT_API_KEY',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            environment: findParameter(
                                'ENVIRONMENT',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            onPaymentCompleted: res => {
                                if (res.resultCode.toLowerCase() === 'authorised') {
                                    const params = {
                                        paymentNumber: paymentInitiateRs.paymentNumber,
                                        ...res,
                                    }
                                    window.location = `${getPaymentUrl('success')}&${new URLSearchParams(
                                        params
                                    ).toString()}`
                                } else {
                                    window.location = getPaymentUrl('failure')
                                }
                            },
                            onError: () => {},
                        })

                        checkout.create('dropin').mount('#dropin-container')
                    } else {
                        await this.$refs.paymentRedirectForm.submit(paymentInitiateRs)
                    }
                }
                return true
            } catch (e) {
                EventBus.$emit(SHOW_NOTIFICATIONS, {
                    notifications: [this.$t('error_message.payment_initiate_error')],
                    color: 'error',
                })
                return false
            }
        }

        get selectedPaymentMethod() {
            return this.paymentMethods.find(paymentMethod => paymentMethod.invoicePaymentKey === this.invoicePaymentKey)
        }
    }
</script>

<style scoped lang="scss">
    .percent-input {
        width: 5em;
    }

    .amount-input {
        width: 8em;
    }
</style>
