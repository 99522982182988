<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'

    @Component
    export default class PersonsSearchAutocomplete extends CityAutocomplete {
        @Prop() companyId

        async searchPersons() {
            const rq = {
                companyId: this.companyId,
            }
            const {users} = await this.$api.persons.get(rq)

            return users
        }

        async initSearch(val) {
            if (this.loading || !val) return
            this.loading = true
            try {
                this.entries = await this.searchPersons()
            } finally {
                this.loading = false
            }
        }

        get items() {
            const items = this.entries.map(entry => {
                const label = `${entry.firstName ? entry.firstName : ''} ${entry.lastName ? entry.lastName : ''}`

                return Object.assign({}, entry, {label})
            })

            return items?.filter(item => item.label.toLowerCase().includes(this.search?.toLowerCase()))
        }

        get locationIcon() {
            return ''
        }
    }
</script>
