<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class AdditionalFeesModal extends Vue {
        render() {
            return null
        }
    }
</script>
