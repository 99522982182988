<template>
    <client-only>
        <v-dialog v-model="modal" max-width="800">
            <v-card>
                <v-card-title class="text-nobreak">{{ $t('comments_modal_title') }}</v-card-title>
                <v-card-text>
                    <v-textarea v-model="comments" :placeholder="$t('type_here')" rows="1" />
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn :loading="isLoading" :disabled="isLoading" color="primary" small @click="saveComments">
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn small @click="modal = false">{{ $t('cancel') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </client-only>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_SERVICE_COMMENTS_EDITING_MODAL} from '@/utils/event-bus'
    import {clone} from '@/utils/helpers'
    import {appInstance} from '@/utils/app-accessor'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class ServiceCommentsModal extends Vue {
        modal = false
        isLoading = false
        service = null
        orderId = null
        comments = null

        created() {
            EventBus.$on(SHOW_SERVICE_COMMENTS_EDITING_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_SERVICE_COMMENTS_EDITING_MODAL, this.showModal)
        }

        showModal({service}, orderId) {
            this.service = service
            this.orderId = orderId
            this.comments = service.comments ? clone(service.comments) : ''
            this.modal = true
        }

        async saveComments() {
            this.isLoading = true
            try {
                const productType = this.service.processId.split('/').splice(2, 2)[0]
                const processId = this.service.processId.split('/').splice(2, 2)[1]

                const res = await appInstance.$api.serviceComments.put(
                    {productType, processId},
                    {comment: this.comments}
                )

                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }

                if (res.comment || res.comment === '') {
                    runtimeStore.UPDATE_SERVICE_COMMENT({
                        serviceId: this.service.serviceId,
                        comment: this.comments,
                    })
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
                this.modal = false
            }
        }

        async markAsNotSentToSupplier() {
            await this.$api.orders.put(this.orderId, {
                servicesInfo: [
                    {
                        processId: this.service.processId,
                        serviceSentToSupplier: false,
                    },
                ],
            })
            await runtimeStore.refreshOrder()
        }
    }
</script>
