<template>
    <v-dialog v-model="modal" hide-overlay :persistent="loading">
        <v-card>
            <v-card-title class="white--text primary">
                {{ service.serviceName }}
            </v-card-title>
            <v-card-text v-if="bookingFieldsLoading" class="d-flex full-width align-center justify-center pa-8">
                <v-progress-circular color="primary" indeterminate size="64" />
            </v-card-text>
            <template v-else>
                <v-card-text>
                    <div class="my-5">
                        <template v-for="(tourist, index) in tourists">
                            <tourist-form
                                :key="index"
                                :ref="`tourist${index}`"
                                v-model="tourists[index]"
                                :index="index"
                                label="passenger"
                                :disabled="loading"
                                :hide-index="tourists.length === 1"
                                :selected-tourists="tourists"
                                :start-date="startDate"
                                disable-birth-date-limits
                                @person-selected="personSelected($event)"
                            />
                        </template>
                    </div>
                    <v-row v-if="showPickUpPoint || showDropOffList">
                        <v-col v-if="showPickUpPoint" cols="12" md="3">
                            <v-select
                                v-model="pickUpPoint"
                                :items="pickUpList"
                                item-value="locationCode"
                                item-text="itemText"
                                :label="$t('carsRent.pick-up_point')"
                                dense
                            />
                        </v-col>
                        <v-col v-if="showDropOffList" cols="12" md="3">
                            <v-select
                                v-model="dropOffPoint"
                                :items="dropOffList"
                                item-value="locationCode"
                                item-text="itemText"
                                :label="$t('carsRent.drop-off_point')"
                                dense
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <div @click="validateTouristsWithErrors">
                        <v-btn
                            color="primary"
                            :disabled="!allTouristsValid || loading"
                            :loading="loading"
                            @click="save"
                        >
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                    <v-btn text :disabled="loading" @click="modal = false">{{ $t('cancel') }}</v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import BookingTouristsMixin from '@/components/booking/mixins/BookingTouristsMixin'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import CarsSearchPageMixin from '@/components/carsRent/mixins/CarsSearchPageMixin'
    import FillServiceBookingDataMixin from '@/components/account/mixins/FillServiceBookingDataMixin'
    import {clone} from '@/utils/helpers'

    //TODO Add train stations
    @Component({
        components: {
            TouristForm,
        },
    })
    export default class FillCarBookingDataModal extends mixins(
        BookingTouristsMixin,
        CarsSearchPageMixin,
        FillServiceBookingDataMixin
    ) {
        @VModel() modal
        @Prop({required: true}) service
        @Prop({required: true}) order

        loading = false
        allTouristsValid = false
        carBookingData = null

        pickUpPoint = null
        dropOffPoint = null

        @Watch('modal')
        async onModalOpen() {
            if (this.modal) {
                this.setTourists()
                const tourists = clone(persistentStore.tourists)

                tourists[0].birthdate = this.service.travelers[0].dateOfBirth
                tourists[0].age = this.$dateFns.differenceInYears(
                    new Date(this.service.startDateTime),
                    this.$dateFns.parseISO(this.service.travelers[0].dateOfBirth)
                )

                persistentStore.SET_TOURISTS(tourists)
                await Promise.all([this.loadTouristBookingFields(), this.loadProductBookingFields()])
                this.$nextTick(() => {
                    this.setAllTouristsValid()
                })
            }
        }

        @Watch('tourists', {deep: true})
        async onTouristsChange() {
            await this.$nextTick(() => {})
            this.setAllTouristsValid()
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            await this.loadCarBookingData()
            await this.initCarBookingData()
        }

        async loadCarBookingData() {
            this.bookingFieldsLoading = true
            try {
                this.carBookingData = await this.$api.carBookingData.get({processId: this.service.processId})

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.bookingFieldsLoading = false
            }
        }

        initCarBookingData() {
            if (this.carBookingData?.pickUpPoints?.length) {
                const index = this.carBookingData.pickUpPoints?.findIndex(
                    point => point.name === this.serviceDetails.pickUpPoint?.pointName
                )

                if (index >= 0) {
                    const pickUpPoint = this.carBookingData.pickUpPoints[index]

                    this.pickUpPoint = pickUpPoint.locationCode
                }
            } else if (this.pickUpList.length === 1) {
                this.pickUpPoint = this.pickUpList[0]
            }

            if (this.carBookingData?.returnPoints?.length) {
                const index = this.carBookingData.returnPoints?.findIndex(
                    point => point.name === this.serviceDetails.dropOffPoint?.pointName
                )
                if (index >= 0) {
                    const dropOffPoint = this.carBookingData.returnPoints[index]
                    this.dropOffPoint = dropOffPoint.locationCode
                }
            } else if (this.dropOffList.length === 1) {
                this.dropOffPoint = this.dropOffList[0]
            }
        }

        async save() {
            try {
                this.loading = true
                const touristsWithoutPassport = this.tourists.map(t => {
                    return {...t, passport: {}}
                })
                const driver = this.prepareBookTourists(touristsWithoutPassport)[0]
                driver.phoneNumber = driver.phone
                delete driver.phone
                await this.$api.fillCarBookingData.post({
                    driver,
                    ...(this.pickUpPoint && {pickUpPoint: this.pickUpPoint}),
                    ...(this.dropOffPoint && {dropOffPoint: this.dropOffPoint}),
                    processId: this.service.processId,
                })
                await runtimeStore.refreshOrder()
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.modal = false
                this.loading = false
            }
        }

        setAllTouristsValid() {
            const validation = []

            this.tourists.forEach((t, i) => {
                validation.push(this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.validate())
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.resetValidation()
            })

            this.allTouristsValid = validation.every(el => el === true)
        }

        validateTouristsWithErrors() {
            this.tourists.forEach((t, i) => {
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.validate()
            })
        }

        personSelected({person, index}) {
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                persistentStore.SET_TOURIST({index, val})
            } else {
                persistentStore.SET_TOURIST_PROP({index, prop: 'personId', val: null})
            }
        }

        get pickUpList() {
            return (
                this.carBookingData?.pickUpPoints
                    .filter(e => e.name || e.address || e.locationCode)
                    .map(e => ({...e, itemText: e.name || e.address || e.locationCode})) || []
            )
        }

        get dropOffList() {
            return (
                this.carBookingData?.returnPoints
                    .filter(e => e.name || e.address || e.locationCode)
                    .map(e => ({...e, itemText: e.name || e.address || e.locationCode})) || []
            )
        }

        get showPickUpPoint() {
            return this.pickUpList.length
        }

        get showDropOffList() {
            return this.dropOffList.length
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get searchRequest() {
            return {
                dateTime: this.service.startDateTime.split(' ')[0],
                adults: this.service.travelers.filter(({isChild}) => !isChild).length,
                childrenAges: this.service.travelers.reduce((childrenAges, {isChild, dateOfBirth}) => {
                    //TODO Missing age prop in get orders
                    if (isChild) {
                        if (dateOfBirth) {
                            childrenAges.push(
                                this.$dateFns.differenceInYears(
                                    new Date(this.service.startDateTime),
                                    this.$dateFns.parseISO(dateOfBirth)
                                )
                            )
                        } else {
                            childrenAges.push(4)
                        }
                    }
                    return childrenAges
                }, []),
            }
        }

        get startDate() {
            return this.searchRequest.dateTime
        }
    }
</script>
