<template>
    <vc-date-picker
        v-model="range"
        is-range
        :model-config="modelConfig"
        :locale="$i18n.locale"
        :min-date="minStartDate"
        :max-date="maxEndDate"
        :columns="$breakpoint.smAndDown ? 1 : 2"
        :rows="1"
        :popover="{placement: 'bottom'}"
        :first-day-of-week="$config.firstDayOfWeek || undefined"
        @dayclick="dayclick"
        @popoverWillShow="popoverWillShow"
    >
        <template v-slot="{inputEvents}">
            <div v-on="inputEvents.start">
                <v-text-field
                    :class="textFieldClass"
                    :label="$t('check_in') + ' - ' + $t('check_out')"
                    readonly
                    :single-line="singleLine"
                    :outlined="outlined"
                    filled
                    hide-details
                    background-color="white"
                    :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
                    :value="formattedDate"
                />
            </div>
        </template>
    </vc-date-picker>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'

    @Component
    export default class ModifyDaterange extends DaterangeM {}
</script>
