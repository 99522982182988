var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":12,"md":"12"}},[_c('div',{staticClass:"mb-2 d-flex align-center"},[(_vm.customProductInfoMode)?_c('v-btn',{staticClass:"me-3",attrs:{"fab":"","small":"","elevation":"0"},on:{"click":function($event){_vm.customProductInfoMode = false}}},[_c('v-icon',[_vm._v(" mdi-arrow-left")])],1):_vm._e(),_vm._v(" "),_c('strong',{staticClass:"info-text title"},[_vm._v("\n                "+_vm._s(_vm.service.serviceDetails[0].transferName
                        ? _vm.service.serviceDetails[0].transferName
                        : _vm.service.serviceName)+"\n            ")]),_vm._v(" "),(_vm.serviceType)?_c('v-chip',{staticClass:"ms-2",attrs:{"label":"","x-small":""}},[_vm._v("\n                "+_vm._s(_vm.serviceType)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.service.serviceDetails[0].category || _vm.service.serviceDetails[0].ratings)?_c('hotel-stars',{attrs:{"hotel":_vm.service.serviceDetails[0]}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.customProductInfoMode)?_c('div',[(_vm.image)?_c('v-img',{staticClass:"thumb mb-2",attrs:{"tile":"","src":_vm._f("imageUrl")(_vm.image,800),"lazy-src":_vm._f("srcHost")('/placeholder.png'),"height":"250","eager":true},on:{"error":_vm.onImgError},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.imgError && _vm.image),expression:"!imgError && image"}],staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,2290429914)}):_vm._e(),_vm._v(" "),(
                        _vm.isTO1 &&
                        _vm.enableCustomProductInfo &&
                        _vm.$config.account.enableCustomProductInfo &&
                        _vm.service.supplierCompanyName === 'Jonview'
                    )?_c('div',{staticClass:"full-width d-flex align-center justify-center"},[(!_vm.customProductInfoMode)?[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.customProductInfoMode = true}}},[_vm._v("\n                            "+_vm._s(_vm.$t('edit_custom_product_info'))+"\n                        ")])]:_vm._e()],2):_vm._e(),_vm._v(" "),_vm._t("serviceInfo"),_vm._v(" "),_vm._t("extraInfo")],2):_vm._e(),_vm._v(" "),(_vm.customProductInfoMode)?_c('custom-product-info-form',{attrs:{"service":_vm.service},on:{"close":function($event){_vm.customProductInfoMode = false},"close-modal":_vm.closeModal}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }