<template>
    <div>
        <v-subheader class="pl-0 v-expansion-panel-header">
            {{ label }}
        </v-subheader>
        <v-row no-gutters class="mb-5">
            <v-col>
                <v-banner single-line outlined hide-details dense background-color="white">
                    {{ formatValue(sliderModel[0]) }}
                </v-banner>
            </v-col>
            <v-col>
                <v-banner single-line outlined hide-details dense background-color="white">
                    {{ formatValue(sliderModel[1]) }}
                </v-banner>
            </v-col>
        </v-row>
        <v-range-slider
            v-model="sliderModel"
            :min="min"
            :max="max"
            :thumb-label="false"
            :disabled="disabled"
            @end="end"
        />
        <v-divider />
    </div>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {Model} from 'vue-property-decorator'

    @Component
    export default class RangeFilter extends Vue {
        @Model({required: true}) value
        @Prop({required: true}) filters
        @Prop({required: true}) label
        @Prop() valueFilter
        @Prop({required: true}) filter

        sliderModel = [Infinity, 0]
        min = 0
        max = Infinity

        mounted() {
            if (this.filters[this.filter][0] <= this.filters[this.filter][1]) {
                this.min = this.filters[this.filter][0]
                this.max = this.filters[this.filter][1]
                this.sliderModel = [this.filters[this.filter][0], this.filters[this.filter][1]]
            }
        }

        @Watch('firstFilterValue')
        onFirstFilterValueChange(val) {
            this.min = val
            this.sliderModel = [val, this.sliderModel[1]]
            this.end()
        }

        @Watch('secondFilterValue')
        onSecondFilterValueChange(val) {
            this.max = val
            this.sliderModel = [this.sliderModel[0], val]
            this.end()
        }

        end() {
            this.$emit('change', {key: this.filter, value: this.sliderModel})
        }

        formatValue(val) {
            return this.$options.filters[this.valueFilter]?.(val) || val
        }

        get firstFilterValue() {
            return this.filters[this.filter][0]
        }

        get secondFilterValue() {
            return this.filters[this.filter][1]
        }

        get disabled() {
            return this.max === Infinity
        }
    }
</script>
