<template>
    <div v-intersect="onIntersect" class="map-wrapper">
        <div ref="map" :style="{height}" class="map" />
        <div ref="controls" class="mt-3 ml-3 d-flex align-center controls">
            <hotel-map-direction-filter
                ref="filter"
                :google="google"
                :map="map"
                :markers="markers"
                :city="city || {latitude, longitude}"
            />
        </div>
    </div>
</template>

<script>
    import {Component, Prop, mixins} from 'nuxt-property-decorator'
    import gmapsInit from '~/utils/gmaps'
    import HotelMapMixin from '@/components/hotels/mixins/hotelMapMixin.src'
    import HotelMapDirectionFilter from '@/components/hotels/snippets/HotelMapDirectionFilter'

    @Component({components: {HotelMapDirectionFilter}})
    export default class MapLocation extends mixins(HotelMapMixin) {
        @Prop({required: true}) latitude
        @Prop({required: true}) longitude
        @Prop({default: '300px'}) height
        @Prop({default: null}) city

        google = null
        map = null
        markers = []

        async load() {
            try {
                this.google = await gmapsInit()
                const map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    fullscreenControl: true,
                    zoomControl: true,
                    zoom: 16,
                    center: {
                        lat: this.latitude,
                        lng: this.longitude,
                    },
                })
                if (this.$refs.controls) {
                    map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(this.$refs.controls)
                }
                this.map = map
                this.addMarker({latitude: this.latitude, longitude: this.longitude})
            } catch (error) {
                console.error(error)
            }
        }

        onIntersect() {
            this.load()
        }
    }
</script>

<style scoped lang="scss">
    .map-wrapper {
        min-height: 300px;
        z-index: 1;
    }

    ::v-deep .gm-style-iw button:focus {
        outline: 0;
    }
</style>
