<template>
    <v-form>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`details`) }} </span>
        <v-row>
            <v-col cols="12" md="2">
                <v-text-field v-model="product.roomType" outlined dense :label="`${$t('room_type')}`" hide-details />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field v-model="product.mealType" outlined dense :label="`${$t('meal_type')}`" hide-details />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class OfflineServiceFormAccommodation extends Vue {
        @Prop() productType
        @Prop() tourists
        @VModel() product
    }
</script>
