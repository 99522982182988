<template>
    <div class="d-flex">
        <template v-for="(date, index) in packageDatesPreview">
            <div v-if="index < 3" :key="index" class="mr-2 d-flex flex-column">
                <v-chip
                    :color="!index ? 'primary' : 'indigo'"
                    :class="!index ? 'lighten-3' : 'lighten-5'"
                    label
                    small
                    class="py-0 black--text"
                >
                    {{ date }}
                </v-chip>
                <span v-if="index < 1 && isAvailable" class="caption success--text" v-text="$t('available')" />
                <span v-else-if="index < 1 && !isAvailable" class="caption orange--text" v-text="$t('on_request')" />
            </div>
        </template>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TourAvailableDateLabel extends Vue {
        @Prop({required: true}) offers
        @Prop({required: true}) selectedDate

        get packageDatesPreview() {
            const selectedDate = this.$dateFns.format(this.selectedOffer.info.packageDate, 'dd MMM')
            return this.offers.reduce(
                (dates, offer, index) => {
                    if (
                        index <= 2 &&
                        dates.every(date => date !== this.$dateFns.format(offer.info.packageDate, 'dd MMM'))
                    ) {
                        dates.push(this.$dateFns.format(offer.info.packageDate, 'dd MMM'))
                    }

                    return dates
                },
                [selectedDate]
            )
        }

        get selectedOffer() {
            if (this.offers.length >= 2) {
                return this.offers.find(offer => this.selectedDate === this.$dateFns.format(offer.info.packageDate))
            } else return this.offers[0]
        }

        get isAvailable() {
            return this.selectedOffer.entries.every(entry => {
                if (entry.type === 'ACCOMMODATION') return entry.offers[0].rooms.every(room => room.available)
                else if (entry.type === 'CAR') return entry.offer.available
                else return entry.offers[0].available
            })
        }
    }
</script>

<style lang="scss" scoped>
    .v-chip:hover::before {
        opacity: 0;
    }
</style>
