<template>
    <v-card class="my-2 tour-card" outlined>
        <div>
            <v-img
                tile
                :src="mainImg | imageUrl(200)"
                :alt="offer.info.name"
                height="200"
                lazy-src="/placeholder.png"
                eager
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && offer.mainImageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 d-flex flex-column justify-space-between">
                <div>
                    <div class="mb-2 font-weight-medium text-truncate primary--text">
                        <nuxt-link
                            v-if="$route.name === 'tourSearch'"
                            :to="{name: 'tour', query}"
                            no-prefetch
                            class="text-decoration-none"
                        >
                            {{ offer.info.name }}
                        </nuxt-link>
                        <span v-else>
                            {{ offer.info.name }}
                        </span>
                    </div>
                    <tour-included-entries :entries="offer.entries" class="mb-1" />
                    <div class="mb-3 d-flex align-center">
                        <span class="mr-4 font-weight-medium">
                            <v-icon color="blue">mdi-clock-outline</v-icon>
                            {{
                                `${offer.info.packageDuration} ${$tc('day', offer.info.packageDuration)} / ${
                                    offer.info.nightsDuration
                                } ${$tc('night', offer.info.nightsDuration)}`
                            }}
                        </span>
                    </div>
                    <div v-if="offer.info.description" class="mb-3 tour-description" v-html="offer.info.description" />
                    <v-divider class="mb-3" />
                    <div class="mb-1 d-flex">
                        <tour-available-date-label :offers="offers" :selected-date="selectedDate" />
                        <tour-date-filter
                            v-if="groupType === 'tourName' && packageDates.length > 1"
                            v-model="selectedDate"
                            :offers="offers"
                        />
                    </div>
                </div>
            </div>
            <div class="ma-4 price-col flex-shrink-0 text-right">
                <div data-cy="client-price" class="font-weight-medium">
                    {{ totalPrice | price }}
                </div>
                <discount-label :price="offer.price" />
                <div class="text-caption text--secondary">
                    {{
                        `${$t('for')} ${touristsTotal} ${$tc('guest', touristsTotal)}, ${offer.info.nightsDuration}
                            ${$tc('night', offer.info.nightsDuration)}`
                    }}
                </div>
                <v-btn depressed block color="primary" class="my-2" nuxt @click="selectForBooking">
                    {{ $t('book') }}
                </v-btn>
                <div class="primary--text cursor-pointer text-no-wrap" @click="expanded = !expanded">
                    <span>
                        {{ $t('tours.detailed_info') }}
                    </span>
                    <v-icon color="primary">
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
            </div>
        </div>
        <v-expand-transition>
            <tour-offer-entries-list v-if="expanded" class="pa-4" :offer="offer" />
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import TourOfferCard from '@/components/tours/search/offers/TourOfferCard'

    @Component
    export default class TourOfferCardMobile extends TourOfferCard {}
</script>

<style lang="scss" scoped>
    .tour-card {
        border: none !important;
    }

    ::v-deep .tour-description p {
        margin-bottom: 0;
    }
</style>
