<template>
    <component :is="!mainPageUrl ? 'nuxt-link' : 'a'" :to="{name: 'home'}" :href="mainPageUrl">
        <img :src="logo | srcHost" alt="" class="d-block logo" :style="logoStyle" />
    </component>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'

    @Component
    export default class HeaderBlock extends Vue {
        get logo() {
            return cmsStore.layoutSettings.logo || '/logo.png'
        }

        get mainPageUrl() {
            return this.$config.mainPageUrl
        }

        get logoStyle() {
            return /\.svg$/i.test(this.logo) ? {width: '100%'} : {width: 'auto'}
        }
    }
</script>

<style scoped lang="scss">
    .logo {
        max-height: 60px;
        max-width: 100%;
    }
</style>
