<template>
    <v-card class="d-lg-flex">
        <v-img
            tile
            :src="mainImage | imageUrl(250)"
            :alt="info.name"
            eager
            width="100"
            max-width="100"
            height="100"
            :lazy-src="'/placeholder.png' | srcHost"
            class="mx-auto mx-lg-0 me-lg-2"
            @error="onImgError"
        >
            <template v-slot:placeholder>
                <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
            </template>
        </v-img>
        <div class="ma-2">
            <div class="primary--text font-weight-medium text-truncate">{{ info.name }}</div>
            <div class="grey--text subtitle-2">{{ offer.serviceName }}</div>
            <div v-if="info.shortDescription" v-html="info.shortDescription" />
        </div>
        <div class="ma-4 mx-auto mr-lg-4 price-col text-right">
            <slot name="price" :offer="offer" />
        </div>
    </v-card>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TourOfferActivityEntry extends Vue {
        @Prop({required: false, default: () => ({})}) offer

        info = {}
        imgError = false

        async mounted() {
            this.info = await this.$api.activityInfo.get({offerKey: this.offer.offerKey})
        }

        onImgError() {
            this.imgError = true
            return false
        }

        get mainImage() {
            return this.info?.images?.find(item => item.mainImage).url || null
        }
    }
</script>

<style scoped lang="scss">
    .price-col {
        width: 180px;
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;
        }
    }
</style>
