<template>
    <div>
        <component
            :is="`extra-service-card${$breakpoint.smAndDown ? '-mobile' : ''}`"
            v-for="(product, index) in offers"
            :key="product.info.name"
            :product="product"
            :data-cy="`extra-service-offer-${index}`"
            class="my-2"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import ExtraServiceCard from '@/components/extraServices/search/offers/ExtraServiceCard'
    import ExtraServiceCardMobile from '@/components/extraServices/search/offers/ExtraServiceCardMobile'

    @Component({
        components: {
            ExtraServiceCard,
            ExtraServiceCardMobile,
        },
    })
    export default class ExtraServiceOffers extends Vue {
        @Prop({default: () => []}) offers
    }
</script>
