<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {toursRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TourPageMixin extends Vue {
        async loadRuntimeData() {
            const {arrivalCityId} = this.searchRequest
            let departureCityId = null
            if (this.searchRequest.departureCityId) departureCityId = this.searchRequest.departureCityId

            if (toursRuntimeStore.departureCity?.id !== departureCityId)
                await toursRuntimeStore.loadDepartureCity(departureCityId)
            if (toursRuntimeStore.arrivalCity.id !== arrivalCityId)
                await toursRuntimeStore.loadArrivalCity(arrivalCityId)
            if (this.packageTourId && toursRuntimeStore.packageTourId !== this.packageTourId)
                await toursRuntimeStore.loadPackageTourInfo(this.packageTourId)
        }
    }
</script>
