<template>
    <div>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row>
            <v-col :md="3">
                <daterange-m
                    outlined
                    dense
                    class="mt-2"
                    :start-date.sync="infoByServiceType.insurance.startDateTime"
                    :end-date.sync="infoByServiceType.insurance.endDateTime"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DaterangeM from '@/components/search/forms/DaterangeM'

    @Component({
        components: {DaterangeM},
    })
    export default class ModifyManualInsurance extends Vue {
        @VModel() infoByServiceType
    }
</script>
