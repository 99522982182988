<template>
    <v-menu
        v-model="dateFilterPicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                :value="dateFilter | dateFormat"
                :label="$t('date')"
                clearable
                readonly
                background-color="white"
                prepend-inner-icon="mdi-calendar"
                hide-details
                dense
                v-on="on"
                @click:clear="dateFilter = null"
            />
        </template>
        <v-date-picker
            v-model="dateFilter"
            :locale="$i18n.locale"
            :allowed-dates="allowedDates"
            :events="allowedDates"
            :min="minDate"
            :picker-date.sync="pickerDate"
            event-color="green lighten-1"
            :first-day-of-week="$config.firstDayOfWeek ? $config.firstDayOfWeek - 1 : firstDayOfWeekByLocale"
            @change="dateFilterPicker = false"
        />
    </v-menu>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ActivityDateFilter extends Vue {
        @VModel() dateFilter
        @Prop({required: true}) offers

        dateFilterPicker = false
        pickerDate = null

        mounted() {
            this.pickerDate = this.$dateFns.format(this.$dateFns.parseISO(this.minDate), 'yyyy-MM')
        }

        allowedDates(date) {
            return this.offers.findIndex(offer => offer.date === date) !== -1
        }

        get firstDayOfWeekByLocale() {
            return this.$dateFns.localeOptions[this.$i18n.locale].weekStartsOn
        }

        get minDate() {
            if (!this.offers?.length) return this.$dateFns.format(new Date())
            return this.$dateFns.format(this.$dateFns.min(this.offers.map(offer => new Date(offer.date))))
        }
    }
</script>
