<template>
    <div class="footer-menu ml-auto ml-md-5 d-flex flex-column flex-wrap flex-md-row">
        <v-btn
            v-for="(item, index) in footerMenu"
            :key="index"
            nuxt
            :to="!item.externalLink ? {name: 'infoPage', params: {link: item.link}} : null"
            text
            small
            :href="item.externalLink ? item.link : null"
            :target="item.externalLink ? '_blank' : null"
            class="v-btn--router"
        >
            {{ item.name }}
        </v-btn>
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'

    @Component
    export default class FooterMenu extends Vue {
        get footerMenu() {
            return cmsStore.layoutSettings.footerMenu
        }
    }
</script>
