import { render, staticRenderFns } from "./SearchFormTabs.vue?vue&type=template&id=20657199&scoped=true&"
import script from "./SearchFormTabs.vue?vue&type=script&lang=js&"
export * from "./SearchFormTabs.vue?vue&type=script&lang=js&"
import style0 from "./SearchFormTabs.vue?vue&type=style&index=0&id=20657199&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20657199",
  null
  
)

export default component.exports