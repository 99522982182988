<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        disable-sort
    >
        <template v-slot:item="{item}">
            <tr>
                <td>
                    <div class="primary--text text-decoration-underline cursor-pointer">
                        {{ item.productName }}
                    </div>
                    <div v-if="type === 'EXCURSION'">{{ `${item.cityName}, ${item.countryName}` }}</div>
                </td>
                <template v-for="(date, index) in item.itemsByDate">
                    <td
                        v-if="date.totalAllocatedQuantity || date.totalAvailableQuantity || date.totalBookedQuantity"
                        :key="index"
                        class="px-2 py-4"
                    >
                        <div class="mb-2 font-weight-bold">
                            {{ `${date.totalAllocatedQuantity} /` }}
                            <span class="success--text">
                                {{ date.totalBookedQuantity }}
                            </span>
                            <sup class="error--text">{{ date.totalAvailableQuantity }}</sup>
                            <span v-if="date.productRealizationPercent" class="grey--text">
                                {{ `(${date.productRealizationPercent}%)` }}
                            </span>
                            <v-btn
                                small
                                icon
                                class="cursor-pointer"
                                @click="$emit('show-details', {product: item, dayIndex: index})"
                            >
                                <v-icon>mdi-finance</v-icon>
                            </v-btn>
                        </div>
                        <div class="grey--text">
                            {{ `${$t('gross_sales').toLowerCase()}:` }}
                            {{ date.grossSales | priceFormat(date.currency) }}
                        </div>
                        <!--<div class="grey&#45;&#45;text">-->
                        <!--    {{ `${$t('price').toLowerCase()} ${$t('from').toLowerCase()}:` }}-->
                        <!--    {{ 999.99 | priceFormat(date.currency) }}-->
                        <!--</div>-->
                    </td>
                    <td v-else :key="index" />
                </template>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class SalesMonitoringReportTable extends Vue {
        @Prop({default: () => [], type: Array}) statisticsData
        @Prop({default: () => false, type: Boolean}) isLoading
        @Prop({type: String}) type

        get headers() {
            const headers = [{text: '', value: 'name', width: '200px'}]

            this.statisticsData.map(product => {
                product.itemsByDate.reduce((list, item) => {
                    const date = item.date.split(' ')[0]

                    if (list.every(header => header.value !== date)) {
                        list.push({
                            text: this.$options.filters.dateFormat(item.date),
                            value: date,
                            width: '160px',
                        })
                    }

                    return list
                }, headers)
            })

            return headers
        }

        get items() {
            return this.statisticsData.sort((a, b) => (a.productName > b.productName ? 1 : -1))
        }
    }
</script>
