<template>
    <loading-page-layout :loaded="loaded">
        <v-container fluid>
            <slot name="breadcrumbs">
                <breadcrumbs />
            </slot>
            <v-row>
                <v-col cols="12" md="8" class="text-center">
                    <confirmation-status :company="company" :tourlead="tourlead" />
                    <confirmation-page-buttons />
                </v-col>
                <v-col cols="12" md="4">
                    <slot name="sidebar" />
                </v-col>
            </v-row>
        </v-container>
    </loading-page-layout>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import LoadingPageLayout from '@/components/LoadingPageLayout'
    import ConfirmationPageButtons from '~src/components/booking/snippets/ConfirmationPageButtons'

    @Component({
        components: {ConfirmationPageButtons, LoadingPageLayout, ConfirmationStatus, Breadcrumbs},
    })
    export default class ConfirmationPageLayout extends Vue {
        @Prop({required: true}) company
        @Prop({required: true}) loaded
        @Prop({required: true}) tourlead
    }
</script>
