<template>
    <div v-if="isLoading" class="loading-anim">
        <div class="icon-anim">
            <v-icon>mdi-airplane</v-icon>
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'

    @Component
    export default class FlightLoader extends Vue {
        @Prop({
            default: false,
        })
        isLoading
    }
</script>

<style lang="scss" scoped>
    .loading-anim {
        position: relative;
        height: 30px;

        & .icon-anim {
            position: absolute;
            // transition: alls;
            transform: rotate(45deg);
            animation: flight 5s infinite linear;
        }
    }

    @keyframes flight {
        0% {
            top: 100%;
            left: 0;
            //transform: rotate(45deg);
        }
        50% {
            top: 0%;
        }

        100% {
            left: 100%;
            top: 100%;
            //transform: rotate(115deg);
        }
    }
</style>
