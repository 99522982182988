<template>
    <v-row no-gutters>
        <v-col :cols="12" md="12">
            <div class="mb-2 d-flex align-center">
                <v-btn
                    v-if="customProductInfoMode"
                    fab
                    class="me-3"
                    small
                    elevation="0"
                    @click="customProductInfoMode = false"
                >
                    <v-icon> mdi-arrow-left</v-icon>
                </v-btn>
                <strong class="info-text title">
                    {{
                        service.serviceDetails[0].transferName
                            ? service.serviceDetails[0].transferName
                            : service.serviceName
                    }}
                </strong>
                <v-chip v-if="serviceType" label x-small class="ms-2">
                    {{ serviceType }}
                </v-chip>
                <hotel-stars
                    v-if="service.serviceDetails[0].category || service.serviceDetails[0].ratings"
                    :hotel="service.serviceDetails[0]"
                />
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="!customProductInfoMode">
                    <v-img
                        v-if="image"
                        tile
                        class="thumb mb-2"
                        :src="image | imageUrl(800)"
                        :lazy-src="'/placeholder.png' | srcHost"
                        height="250"
                        :eager="true"
                        @error="onImgError"
                    >
                        <template v-slot:placeholder>
                            <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5" />
                            </v-row>
                        </template>
                    </v-img>
                    <div
                        v-if="
                            isTO1 &&
                            enableCustomProductInfo &&
                            $config.account.enableCustomProductInfo &&
                            service.supplierCompanyName === 'Jonview'
                        "
                        class="full-width d-flex align-center justify-center"
                    >
                        <template v-if="!customProductInfoMode">
                            <v-spacer />
                            <v-btn text color="primary" @click="customProductInfoMode = true">
                                {{ $t('edit_custom_product_info') }}
                            </v-btn>
                        </template>
                    </div>
                    <slot name="serviceInfo" />
                    <slot name="extraInfo" />
                </div>
                <custom-product-info-form
                    v-if="customProductInfoMode"
                    :service="service"
                    @close="customProductInfoMode = false"
                    @close-modal="closeModal"
                />
            </transition>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import CustomProductInfoForm from '@/components/account/orderServices/CustomProductInfoForm'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {CustomProductInfoForm, HotelStars},
    })
    export default class ServiceInfoBase extends Vue {
        @Prop() service
        @Prop() serviceType
        @Prop() info
        @Prop({default: null}) image
        @Prop({default: false, type: Boolean}) enableCustomProductInfo

        imgError = false
        customProductInfoMode = false

        @Emit()
        closeModal() {}

        onImgError() {
            this.imgError = true
            return false
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
