<template>
    <v-form class="filters-form elevation-1 px-4 py-2 mb-4" @keyup.native.enter="filter">
        <v-row dense>
            <v-col cols="12">
                <v-radio-group
                    v-model="tripsFilterModel"
                    :row="!isXS"
                    dense
                    hide-details
                    class="ma-0 pa-0"
                    @change="changeActive"
                >
                    <v-radio :label="$t('all_bookings')" value="all" />
                    <v-radio :label="$t('future_trips')" value="future" />
                    <v-radio :label="$t('past_trips')" value="past" />
                    <v-radio :label="$t('cancelled_trips')" value="cancelled" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col v-if="availableFilters.orderId" cols="12" sm="6" md="2">
                <v-text-field v-model="filters.orderId" dense :label="$t('order_number')" />
            </v-col>
            <v-col v-if="availableFilters.travelerName" cols="12" sm="6" md="2">
                <v-text-field v-model="filters.travelerName" dense :label="$t('traveler_name')" />
            </v-col>
            <v-col v-if="availableFilters.processStatuses" cols="12" sm="6" md="2">
                <v-select
                    v-model="filters.processStatuses"
                    :items="processStatuses"
                    :label="$t('services_status')"
                    clearable
                    multiple
                    dense
                />
            </v-col>
            <v-col v-if="availableFilters.reservationStatus" cols="12" sm="6" md="2">
                <v-select
                    v-model="filters.reservationStatus"
                    :items="reservationStatuses"
                    :label="$t('reservation_status')"
                    clearable
                    multiple
                    dense
                />
            </v-col>
            <v-col cols="12" sm="6" md="2">
                <v-switch
                    v-model="filters.filterServices"
                    :label="$t('apply_filter_to_services')"
                    hide-details
                    class="mt-0"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-row>
                    <v-col cols="5">
                        <v-btn block :color="isSomeFiltersSelected ? 'primary' : 'white'" small @click="filter">
                            {{ $t('filter') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="5">
                        <v-btn color="white" block small @click="resetFilters">
                            {{ $t('reset') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="2">
                        <reservations-filters-configuration-modal :filters-list="filtersList" @change="changeFilters" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-expansion-panels flat tile dense>
            <v-expansion-panel>
                <v-expansion-panel-header class="pl-1 width-auto primary--text">
                    {{ $t('additional_parameters') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="isTourOperator" class="text-subtitle-2 grey--text">{{ $t('trip_details') }}</div>
                    <v-row dense class="mb-4">
                        <v-col cols="12" md="8">
                            <v-row dense>
                                <v-col v-if="availableFilters.createdDate" cols="12" md="6">
                                    <order-create-daterange
                                        :_start-date.sync="filters.createdDateFrom"
                                        :_end-date.sync="filters.createdDateTo"
                                        :start-date-label="$t('create_date_from')"
                                        :end-date-label="$t('create_date_to')"
                                        :outlined="false"
                                        :single-line="false"
                                        :no-title="true"
                                        clearable
                                        dense
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.serviceTypes" cols="12" md="3">
                                    <v-select
                                        v-model="filters.serviceTypes"
                                        :items="serviceTypes"
                                        :label="$t('service_types')"
                                        clearable
                                        dense
                                        hide-details
                                        multiple
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.ticketNumber" cols="12" md="3">
                                    <v-text-field
                                        v-model="filters.ticketNumber"
                                        :label="$t('ticket_number')"
                                        dense
                                        hide-details
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.modifiedDate" cols="12" md="6">
                                    <order-create-daterange
                                        :_start-date.sync="filters.modifiedDateFrom"
                                        :_end-date.sync="filters.modifiedDateTo"
                                        :start-date-label="$t('modified_date_from')"
                                        :end-date-label="$t('modified_date_to')"
                                        :outlined="false"
                                        :single-line="false"
                                        :no-title="true"
                                        clearable
                                        dense
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.serviceName" cols="12" md="3">
                                    <v-text-field
                                        v-model="filters.serviceName"
                                        :label="$t('service_name')"
                                        dense
                                        hide-details
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.city" cols="12" md="3">
                                    <city-autocomplete v-model="filters.city" dense class="pb-6" />
                                </v-col>
                                <v-col v-if="availableFilters.serviceStartDate" cols="12" md="6">
                                    <order-service-daterange
                                        :_start-date.sync="filters.serviceStartDateFrom"
                                        :_end-date.sync="filters.serviceStartDateTo"
                                        :start-date-label="$t('start_date_from')"
                                        :end-date-label="$t('start_date_to')"
                                        :outlined="false"
                                        :single-line="false"
                                        :no-title="true"
                                        clearable
                                        dense
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.reservationName" cols="12" md="3">
                                    <v-text-field
                                        v-model="filters.reservationName"
                                        :label="$t('reservation_title')"
                                        dense
                                        hide-details
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.promoCode" cols="12" md="3">
                                    <v-text-field
                                        v-model="filters.promoCode"
                                        :label="$t('promocode')"
                                        dense
                                        hide-details
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.serviceEndDate" cols="12" md="6">
                                    <order-service-daterange
                                        :_start-date.sync="filters.serviceEndDateFrom"
                                        :_end-date.sync="filters.serviceEndDateTo"
                                        :start-date-label="$t('end_date_from')"
                                        :end-date-label="$t('end_date_to')"
                                        :outlined="false"
                                        :single-line="false"
                                        :no-title="true"
                                        clearable
                                        dense
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.reservationManager" cols="12" md="3">
                                    <persons-search-autocomplete
                                        v-model="filters.reservationManager"
                                        :company-id="person.companyId"
                                        :label="$t('reservation_manager')"
                                        :show-icon="false"
                                        dense
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.loyaltyPoints" cols="12" md="3">
                                    <div class="pb-6 d-flex">
                                        <v-text-field
                                            v-model="filters.loyaltyPointsMin"
                                            :label="$t('loyalty_from')"
                                            dense
                                            hide-details
                                            class="mr-4"
                                        />
                                        <v-text-field
                                            v-model="filters.loyaltyPointsMax"
                                            :label="`${$t('to')}..`"
                                            dense
                                            hide-details
                                        />
                                    </div>
                                </v-col>
                                <v-col v-if="availableFilters.lastTicketingDate" cols="12" md="6">
                                    <order-service-daterange
                                        :_start-date.sync="filters.lastTicketingDateFrom"
                                        :_end-date.sync="filters.lastTicketingDateTo"
                                        :start-date-label="$t('last_ticketing_date_from')"
                                        :end-date-label="$t('last_ticketing_date_to')"
                                        :outlined="false"
                                        :single-line="false"
                                        :no-title="true"
                                        clearable
                                        dense
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.serviceManager" cols="12" md="3">
                                    <persons-search-autocomplete
                                        v-model="filters.serviceManager"
                                        :company-id="person.companyId"
                                        :label="$t('service_manager')"
                                        :show-icon="false"
                                        dense
                                        class="pb-6"
                                    />
                                </v-col>
                                <v-col v-if="availableFilters.bookingMethod" cols="12" md="3">
                                    <v-select
                                        v-model="filters.bookingMethod"
                                        :items="bookingMethods"
                                        :label="$t('booked_from')"
                                        clearable
                                        dense
                                        hide-details
                                        multiple
                                        class="pb-6"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div v-if="isTourOperator" class="text-subtitle-2 grey--text">{{ $t('client_details') }}</div>
                    <v-row v-if="isTourOperator" dense class="mb-4">
                        <v-col v-if="availableFilters.client" cols="12" md="2">
                            <private-client-autocomplete
                                v-model="filters.client"
                                :show-icon="false"
                                :label="$t('client_name')"
                                relationship="CLIENT"
                                dense
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.customerCompany" cols="12" md="2">
                            <supplier-autocomplete
                                v-model="filters.customerCompany"
                                :show-icon="false"
                                :label="$t('customer_company')"
                                relationship="CLIENT"
                                dense
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.agent" cols="12" md="2">
                            <persons-search-autocomplete
                                v-model="filters.agent"
                                :company-id="isAgency ? person.personId : person.companyId"
                                :label="$t('agent_name')"
                                :show-icon="false"
                                dense
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.clientType" cols="12" md="2">
                            <v-select
                                v-model="filters.clientType"
                                :items="clientTypes"
                                :label="$t('client_type')"
                                clearable
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col
                            v-if="availableFilters.crmPriority && showPaymentAndInvoiceStatus && showNettoPaymentStatus"
                            md="2"
                            cols="12"
                        >
                            <v-select
                                v-model="filters.crmPriority"
                                :items="crmPriorities"
                                :label="$t('client_priority')"
                                clearable
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col
                            v-if="availableFilters.customerInvoiceStatuses && !$config.account.paymentScheduler"
                            cols="12"
                            md="2"
                        >
                            <v-select
                                v-model="filters.customerInvoiceStatuses"
                                :items="invoiceStatuses"
                                :label="$t('client_invoice_status')"
                                clearable
                                dense
                                hide-details
                                multiple
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.customerPaymentStatuses" cols="12" md="2">
                            <v-select
                                v-model="filters.customerPaymentStatuses"
                                :items="paymentStatuses"
                                :label="$t('client_payment_status')"
                                clearable
                                dense
                                hide-details
                                multiple
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.customerPenalty" cols="12" md="2">
                            <v-select
                                v-model="filters.customerPenalty"
                                :items="penalties"
                                :label="$t('client_penalty')"
                                clearable
                                dense
                                hide-details
                                multiple
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.paymentMethod" cols="12" md="2">
                            <v-select
                                v-model="filters.paymentMethod"
                                :items="paymentMethods"
                                :label="$t('payment_method')"
                                item-text="name"
                                item-value="name"
                                clearable
                                dense
                                multiple
                            />
                        </v-col>
                        <v-col v-if="availableFilters.tripOrganizer" cols="12" md="2">
                            <persons-search-autocomplete
                                v-model="filters.tripOrganizer"
                                :company-id="isAgency ? person.personId : person.companyId"
                                :label="$t('trip_organizer_name')"
                                :show-icon="false"
                                dense
                                class="pb-6"
                            />
                        </v-col>
                        <v-col
                            v-if="
                                availableFilters.corporatePolicyViolated &&
                                showPaymentAndInvoiceStatus &&
                                showNettoPaymentStatus
                            "
                            md="2"
                            cols="12"
                        >
                            <v-select
                                v-model="filters.corporatePolicyViolated"
                                :items="booleanItems"
                                :label="$t('corporate_policy_violation')"
                                clearable
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.waitAuthorize" cols="12" md="2">
                            <v-select
                                v-model="filters.waitAuthorize"
                                :items="booleanItems"
                                :label="`${$t('approval_required')}?`"
                                clearable
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.waitingApprovalFromMyself" cols="12" md="2">
                            <v-checkbox v-model="filters.waitingApprovalFromMyself" dense hide-details>
                                <template v-slot:label>
                                    <div class="text-body-2" v-text="$t('waiting_approval_from_myself')" />
                                </template>
                            </v-checkbox>
                        </v-col>
                        <v-col v-if="availableFilters.agentCompanyConsortium" cols="12" md="2">
                            <v-text-field
                                v-model="filters.agentCompanyConsortium"
                                :label="$t('agent_consortium')"
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.agentCompanySubconsortium" cols="12" md="2">
                            <v-text-field
                                v-model="filters.agentCompanySubconsortium"
                                :label="$t('agent_sub_consortium')"
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.customerPaymentDueDate" cols="12" md="4">
                            <order-service-daterange
                                :_start-date.sync="filters.customerPaymentDueDateFrom"
                                :_end-date.sync="filters.customerPaymentDueDateTo"
                                :start-date-label="$t('customer_payment_due_date_from')"
                                :end-date-label="$t('customer_payment_due_date_to')"
                                :outlined="false"
                                :single-line="false"
                                :no-title="true"
                                clearable
                                dense
                            />
                        </v-col>
                    </v-row>
                    <div v-if="isTourOperator" class="text-subtitle-2 grey--text">{{ $t('supplier_details') }}</div>
                    <v-row v-if="isTourOperator" dense>
                        <v-col v-if="availableFilters.supplier" cols="12" md="2">
                            <supplier-autocomplete
                                v-model="filters.supplier"
                                :show-icon="false"
                                :label="$t('supplier_name')"
                                dense
                            />
                        </v-col>
                        <v-col v-if="availableFilters.supplierRef" cols="12" md="2">
                            <v-text-field
                                v-model="filters.supplierRef"
                                :label="$t('supplier_ref')"
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.channelManagerCode" cols="12" md="2">
                            <v-select
                                v-model="filters.channelManagerCode"
                                :items="channelManagerCodeItems"
                                :label="$t('channel_manager_name')"
                                clearable
                                dense
                            />
                        </v-col>
                        <v-col v-if="availableFilters.chmPartnerBookingNumber" cols="12" md="2">
                            <v-text-field
                                v-model="filters.chmPartnerBookingNumber"
                                :label="$t('channel_manager_ref')"
                                clearable
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col
                            v-if="availableFilters.supplierInvoiceStatuses && !$config.account.paymentScheduler"
                            md="2"
                            cols="12"
                        >
                            <v-select
                                v-model="filters.supplierInvoiceStatuses"
                                :items="invoiceStatuses"
                                :label="$t('supplier_invoice_status')"
                                clearable
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col v-if="availableFilters.supplierPaymentStatuses" md="2" cols="12">
                            <v-select
                                v-model="filters.supplierPaymentStatuses"
                                :items="paymentStatuses"
                                :label="$t('supplier_payment_status')"
                                clearable
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col v-if="availableFilters.supplierPenalty" md="2" cols="12">
                            <v-select
                                v-model="filters.supplierPenalty"
                                :items="penalties"
                                :label="$t('supplier_penalty')"
                                clearable
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col v-if="availableFilters.noSupplierRef" md="2" cols="12">
                            <v-checkbox v-model="filters.noSupplierRef" dense hide-details>
                                <template v-slot:label>
                                    <div class="text-body-2" v-text="$t('empty_supplier_ref')" />
                                </template>
                            </v-checkbox>
                        </v-col>
                        <v-col v-if="availableFilters.supplierPaymentDueDate" cols="12" md="4">
                            <order-service-daterange
                                :_start-date.sync="filters.supplierPaymentDueDateFrom"
                                :_end-date.sync="filters.supplierPaymentDueDateTo"
                                :start-date-label="$t('supplier_payment_due_date_from')"
                                :end-date-label="$t('supplier_payment_due_date_to')"
                                :outlined="false"
                                :single-line="false"
                                :no-title="true"
                                clearable
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="isAgency || isCorporate" dense>
                        <v-col v-if="availableFilters.paymentMethod" cols="12" md="2">
                            <v-select
                                v-model="filters.paymentMethod"
                                :items="paymentMethods"
                                :label="$t('payment_method')"
                                item-text="name"
                                item-value="name"
                                clearable
                                dense
                                multiple
                            />
                        </v-col>
                        <v-col v-if="availableFilters.corporatePolicyViolated" cols="12" md="2">
                            <v-select
                                v-model="filters.corporatePolicyViolated"
                                :items="booleanItems"
                                :label="$t('corporate_policy_violation')"
                                clearable
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col v-if="availableFilters.waitAuthorize" cols="12" md="2">
                            <v-select
                                v-model="filters.waitAuthorize"
                                :items="booleanItems"
                                :label="`${$t('approval_required')}?`"
                                clearable
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.supplier" cols="12" md="2">
                            <supplier-autocomplete
                                v-model="filters.supplier"
                                :show-icon="false"
                                :label="$t('supplier_name')"
                                dense
                            />
                        </v-col>
                        <v-col v-if="availableFilters.waitingApprovalFromMyself" cols="12" md="2">
                            <v-checkbox v-model="filters.waitingApprovalFromMyself" dense hide-details>
                                <template v-slot:label>
                                    <div class="text-body-2" v-text="$t('waiting_approval_from_myself')" />
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row v-if="isSupplier" dense>
                        <v-col v-if="availableFilters.supplierRef" cols="12" md="2">
                            <v-text-field
                                v-model="filters.supplierRef"
                                :label="$t('supplier_ref')"
                                dense
                                hide-details
                                class="pb-6"
                            />
                        </v-col>
                        <v-col v-if="availableFilters.noSupplierRef" cols="12" md="2">
                            <v-checkbox v-model="filters.noSupplierRef" dense hide-details>
                                <template v-slot:label>
                                    <div class="text-body-2" v-text="$t('empty_supplier_ref')" />
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-form>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {clone, isEqual} from '@/utils/helpers'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import OrderCreateDaterange from '~/components/account/filters/OrderCreateDaterange'
    import OrderServiceDaterange from '~/components/account/filters/OrderServiceDaterange'
    import SupplierAutocomplete from '@/components/search/forms/SupplierAutocomplete'
    import PrivateClientAutocomplete from '@/components/search/forms/PrivateClientAutocomplete'
    import PersonsSearchAutocomplete from '@/components/search/forms/PersonsSearchAutocomplete'
    import ReservationsFiltersConfigurationModal from '@/components/account/filters/ReservationsFiltersConfigurationModal.vue'
    import {format} from 'date-fns'

    @Component({
        components: {
            CityAutocomplete,
            OrderCreateDaterange,
            OrderServiceDaterange,
            SupplierAutocomplete,
            PrivateClientAutocomplete,
            PersonsSearchAutocomplete,
            ReservationsFiltersConfigurationModal,
        },
    })
    export default class OrderFilter extends Vue {
        @Prop({required: true}) filtersList
        filters = {}
        paymentMethods = []
        tripsFilterModel = 'all'

        @Emit()
        changeFilters(filters) {
            return filters
        }

        async created() {
            if (this.isTourOperator) {
                try {
                    this.paymentMethods = await this.$api.paymentMethods.get({'is active': true})
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async mounted() {
            await this.$store.restored
            this.load()
        }

        load() {
            const filters = clone(persistentStore.orderFilters)

            if (this.$config.account.plainPaymentStatus || this.$config.account.paymentScheduler) {
                filters.customerPaymentStatuses = runtimeStore.plainPaymentStatuses(filters.customerPaymentStatuses)
                filters.supplierPaymentStatuses = runtimeStore.plainPaymentStatuses(filters.supplierPaymentStatuses)
            }

            this.filters = filters
        }

        filter() {
            this.filters.active = persistentStore.orderFilters.active
            const orderFilters = clone(this.filters)

            if (this.$config.account.plainPaymentStatus || this.$config.account.paymentScheduler) {
                const reducePaymentStatuses = statuses =>
                    statuses.reduce((statuses, status) => {
                        if (status === 'NOT_PAID') {
                            statuses = [
                                ...statuses,
                                'NO_BILL',
                                'BILL',
                                'IN_TRANSIT',
                                'OVERDUE',
                                'OVERDUE',
                                'NO_FULLY_BILL',
                                'INVOICE_WAITING',
                            ]
                        } else {
                            statuses.push(status)
                        }
                        return statuses
                    }, [])

                orderFilters.customerPaymentStatuses = reducePaymentStatuses(orderFilters.customerPaymentStatuses)
                orderFilters.supplierPaymentStatuses = reducePaymentStatuses(orderFilters.supplierPaymentStatuses)
            }

            persistentStore.SET_ORDER_FILTERS(orderFilters)
            this.$emit('filter')
        }

        resetFilters() {
            persistentStore.CLEAR_ORDER_FILTERS()
            this.load()
            this.$emit('filter')
        }

        changeActive(val) {
            const orderFilters = clone(this.filters)

            orderFilters.reservationStatus = []
            orderFilters.serviceStartDateFrom = null
            orderFilters.serviceStartDateTo = null

            if (val === 'cancelled') orderFilters.reservationStatus = ['CANCELLED']
            if (val === 'future') orderFilters.serviceStartDateFrom = format(new Date(), 'yyyy-MM-dd')
            if (val === 'past')
                orderFilters.serviceStartDateTo = format(this.$dateFns.subDays(new Date(), 1), 'yyyy-MM-dd')

            this.filters = orderFilters
        }

        get availableFilters() {
            return this.filtersList.reduce((filters, item) => {
                if (item.show) filters[item.value] = true

                return filters
            }, {})
        }

        get serviceTypes() {
            const serviceTypes = [
                'productType.accommodation',
                'productType.flight',
                'productType.transfer',
                'productType.train',
                'productType.aeroexpressTransfer',
                'productType.carRent',
                'productType.insurance',
                'productType.ownCharter',
                'productType.excursion',
                'productType.ownCruise',
                'productType.ownVisa',
                'productType.tour',
                'productType.extraService',
                'productType.ownExtraService',
                'productType.cruise',
            ]

            return serviceTypes
                .filter(serviceType => authStore.availableProducts.includes(serviceType.split('.')[1]))
                .map(value => ({value, text: this.$t(value)}))
        }

        get bookingMethods() {
            return [
                {value: 'ALLOTMENT', text: this.$t('booking_methods.ALLOTMENT')},
                {value: 'ON_REQUEST', text: this.$t('booking_methods.ON_REQUEST')},
                {value: 'MANUALLY_AMENDED', text: this.$t('booking_methods.MANUALLY_AMENDED')},
            ]
        }

        // TODO
        get processStatuses() {
            const processStatuses = [
                'CONFIRMATION_PENDING',
                'CONFIRMED',
                'CANCELLATION_PENDING',
                'MODIFICATION_PENDING',
                'CANCELED',
                'REJECTED',
                'PRECONFIRMED',
                'PENDING_TICKETING',
                'ESTIMATED',
                'QUOTE',
            ]

            return processStatuses.map(value => ({value, text: this.$t(`bookingStatus.${value}`)}))
        }

        get reservationStatuses() {
            const processStatuses = ['NEW', 'IN_PROGRESS', 'COMPLETED', 'QUOTE']

            if (this.$config.reservationStatuses.completedAndChecked) processStatuses.push('COMPLETED_AND_CHECKED')
            processStatuses.push('CANCELLED')
            processStatuses.push('QUOTE')
            if (this.$config.reservationStatuses.archived) processStatuses.push('ARCHIVED')
            if (this.$config.reservationStatuses.vouchered) processStatuses.push('VOUCHERED')

            return processStatuses.map(value => ({value, text: this.$t(`orderStatus.${value}`)}))
        }

        get clientTypes() {
            const processStatuses = ['DIRECT_SALES', 'TA', 'CORPORATE']

            return processStatuses.map(value => ({value, text: this.$t(`clientTypes.${value}`)}))
        }

        get crmPriorities() {
            const processStatuses = ['VIP', 'HIGH', 'NORMAL', 'LOW']

            return processStatuses.map(value => ({value, text: this.$t(`crmPriority.${value}`)}))
        }

        get channelManagerCodeItems() {
            const items = ['SM', 'OPEN_GDS', 'RateDock']

            return items.map(value => ({value, text: this.$t(`channelManagerCodes.${value}`)}))
        }

        get booleanItems() {
            return [
                {value: true, text: this.$t('yes')},
                {value: false, text: this.$t('no')},
            ]
        }

        get paymentStatuses() {
            if (!this.$config.account.plainPaymentStatus && !this.$config.account.paymentScheduler) {
                const paymentStatuses = ['PAID', 'PARTIAL_PAID', 'IN_TRANSIT', 'OVERDUE']

                return paymentStatuses.map(value => ({value, text: this.$t(`paymentStatus.${value}`)}))
            }

            return ['PAID', 'PARTIAL_PAID', 'NOT_PAID'].map(value => ({
                value,
                text: this.$t(`plainPaymentStatus.${value}`),
            }))
        }

        get invoiceStatuses() {
            const invoiceStatuses = ['NO_BILL', 'BILL', 'NO_FULLY_BILL', 'INVOICE_WAITING']

            return invoiceStatuses.map(value => ({value, text: this.$t(`paymentStatus.${value}`)}))
        }

        get penalties() {
            const penalties = ['FREE', 'PAID', 'INFO', 'WARNING', 'ERROR']

            return penalties.map(value => ({value, text: this.$t(`penalty.${value}`)}))
        }

        get person() {
            return authStore.person
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get isSupplier() {
            return authStore.isSupplier
        }

        get showPaymentAndInvoiceStatus() {
            return authStore.showPaymentAndInvoiceStatus
        }

        get showNettoPaymentStatus() {
            return true
        }

        get isSomeFiltersSelected() {
            const filtersStoreClone = clone(persistentStore.orderFilters)

            return !isEqual(filtersStoreClone, this.filters)
        }

        get stateFilters() {
            return persistentStore.orderFilters
        }

        get isXS() {
            return this.$breakpoint.xs
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    .filters-form::v-deep {
        .v-expansion-panel-header {
            min-height: auto;
            padding: 0;
            margin: 0 0 10px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
        .v-expansion-panel-content__wrap {
            padding: 0;
        }

        .v-text-field .v-label,
        .v-radio .v-label,
        .v-input--switch .v-label,
        .v-text-field input,
        .v-select__selections {
            font-size: 12px;
        }
    }
</style>
