<template>
    <v-dialog v-model="dialog" hide-overlay width="600">
        <v-card>
            <v-card-title class="white--text primary mb-5">
                {{ $t('admin.new_template') }}
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid">
                    <v-text-field
                        v-model="template.info"
                        :label="$t('admin.templateInfo')"
                        :rules="[requiredRule('admin.templateInfo')]"
                        outlined
                    />
                    <v-text-field
                        v-model="template.notificationType"
                        :label="$t('admin.templateType')"
                        :rules="[requiredRule('admin.templateType')]"
                        outlined
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn depressed color="primary" :disabled="!valid" @click="save">
                    {{ $t('save') }}
                </v-btn>
                <v-btn text @click="dialog = false">{{ $t('cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class NewTemplateDialog extends mixins(FormValidationRulesMixin) {
        @VModel() dialog
        @Prop() template

        valid = false

        @Emit()
        save() {
            return this.template
        }
    }
</script>
