<script>
    import {Vue, Component} from 'nuxt-property-decorator'

    @Component
    export default class SwiperMixin extends Vue {
        showSwiper = false

        onResize() {
            this.showSwiper = true
        }

        get loop() {
            return !(
                (this.$breakpoint.lgAndUp && this.items.length < 5) ||
                (this.$breakpoint.mdOnly && this.items.length < 4)
            )
        }
    }
</script>
