<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import CarsStoreMixin from '@/mixins/CarsStoreMixin'
    import {clone} from '@/utils/helpers'
    import {persistentStore, carsRentStore} from '@/utils/store-accessor'

    @Component
    export default class CarBookingPageLayout extends mixins(BookingPageBase, CarsStoreMixin) {
        @Prop({required: true}) offer

        initTourists(rq, tourists) {
            const touristsList = BookingPageBase.options.methods.initTourists.call(this, rq, tourists)
            touristsList[0].age = rq.driverAge
            return touristsList
        }

        setTourists() {
            persistentStore.SET_TOURISTS(
                this.initTourists({...this.searchRequest, adults: 1, childrenAges: []}, clone(persistentStore.tourists))
            )
        }

        prepareBookRequest(specifiedTouristBookingFields, includeOptionalTourists = false) {
            const prepareBookRequest = BookingPageBase.options.methods.prepareBookRequest.call(
                this,
                specifiedTouristBookingFields,
                includeOptionalTourists
            )
            const driver = this.prepareBookTourists(
                this.tourists,
                specifiedTouristBookingFields,
                includeOptionalTourists
            )[0]
            driver.phoneNumber = driver.phone
            delete driver.phone
            delete prepareBookRequest.tourists
            prepareBookRequest.driver = driver
            prepareBookRequest.pickUpPoint = carsRentStore.pickUpLocation.locationCode
            prepareBookRequest.dropOffPoint = carsRentStore.dropOffLocation.locationCode
            return prepareBookRequest
        }

        get productSearchPageName() {
            return 'carsRent'
        }

        get productConfirmationPageName() {
            return 'carConfirmation'
        }

        get productName() {
            return 'car'
        }

        get productBookingPageName() {
            return 'carsRentBooking'
        }

        get corporatePolicyType() {
            return 'carsRent'
        }

        get startDate() {
            return this.searchRequest.pickUpDateTime
        }
    }
</script>
