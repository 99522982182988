<template>
    <v-app>
        <header-bar v-model="drawer" />
        <notifications-snackbar />
        <notification-modal />
        <add-service-snackbar />
        <agent-search-snackbar />
        <navigation-drawer-mobile v-if="$breakpoint.smAndDown" v-model="drawer" />
        <v-main class="content" :class="{'pb-0': ['authorization', 'b2b-auth'].includes($route.name)}">
            <nuxt />
        </v-main>
        <footer-bar />
        <cookies-alert
            v-if="showCookiesAlert && $config.showCookiesAlert"
            style="position: fixed; right: 12px; bottom: 12px; z-index: 200"
            @close-dialog="closeCookieAlert"
        />
        <div v-if="script" v-html="script" />
    </v-app>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import AddServiceSnackbar from '~/components/snippets/AddServiceSnackbar'
    import {EventBus, LOGOUT_REDIRECT_EVENT} from '@/utils/event-bus'
    import HeaderBar from '~src/components/headerBar.src'
    import FooterBar from '~src/components/footerBar.src'
    import {cmsStore, persistentStore} from '@/store'
    import BaseLayout from '@/components/BaseLayout'
    import AgentSearchSnackbar from '@/components/snippets/AgentSearchSnackbar'
    import NavigationDrawerMobile from '~src/components/snippets/navigationDrawerMobile.src'
    import NotificationsSnackbar from '@/components/NotificationsSnackbar'
    import CookiesAlert from '@/components/CookiesAlert'
    import NotificationModal from '@/components/modals/NotificationModal.vue'

    @Component({
        components: {
            NotificationModal,
            CookiesAlert,
            NotificationsSnackbar,
            NavigationDrawerMobile,
            AgentSearchSnackbar,
            HeaderBar,
            FooterBar,
            AddServiceSnackbar,
        },
    })
    export default class BlankLayout extends BaseLayout {
        showCookiesAlert = false

        created() {
            EventBus.$on(LOGOUT_REDIRECT_EVENT, () => {
                if (this.$toast) {
                    this.$toast.error(this.$t('authorization_timeout'))
                }
            })
        }

        async mounted() {
            await this.$store.restored
            this.showCookiesAlert = !persistentStore.cookieAlertShown
        }

        closeCookieAlert() {
            this.showCookiesAlert = false

            persistentStore.SET_COOKIE_ALERT_SHOWN(true)
        }

        get script() {
            return cmsStore.layoutSettings.script
        }
    }
</script>
