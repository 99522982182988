import { render, staticRenderFns } from "./ContractPage.vue?vue&type=template&id=50dee934&scoped=true&"
import script from "./ContractPage.vue?vue&type=script&lang=js&"
export * from "./ContractPage.vue?vue&type=script&lang=js&"
import style0 from "./ContractPage.vue?vue&type=style&index=0&id=50dee934&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50dee934",
  null
  
)

export default component.exports