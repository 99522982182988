<template>
    <div v-if="offer" class="mb-5">
        <strong v-for="(itinerary, index) in offer.itinerary" :key="index" class="d-block">
            {{ itinerary.segments[0].departure.airportCode }},
            {{ itinerary.segments[0].departure.date | dateTimeFormat }} -
            {{ itinerary.segments[itinerary.segments.length - 1].arrival.airportCode }},
            {{ itinerary.segments[itinerary.segments.length - 1].arrival.date | dateTimeFormat }}
        </strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_FLIGHT_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class FlightConditionsModalInfo extends Vue {
        offer = null

        mounted() {
            EventBus.$on(SHOW_FLIGHT_CONDITIONS_INFO, this.showInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_FLIGHT_CONDITIONS_INFO, this.showInfo)
        }

        showInfo(offer) {
            this.offer = offer
        }
    }
</script>
