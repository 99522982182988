<template>
    <div>
        <v-row no-gutters>
            <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('price') }}:</v-col>
            <v-col :cols="6" md="6">
                <div v-for="(price, index) in orderPrices" :key="index">
                    <price-label :price="price" />
                </div>
            </v-col>
        </v-row>
        <template v-if="showCommissionAndNettoBlock">
            <v-row no-gutters class="font-weight-regular">
                <v-col :cols="6" md="6">{{ $t('commission') }}:</v-col>
                <v-col :cols="6" md="6">
                    <template v-for="(orderPrice, index) in orderPrices">
                        <div v-if="orderPrice.commission" :key="index" class="font-weight-bold">
                            {{ orderPrice.commission | priceFormat(orderPrice.currency) }}
                        </div>
                    </template>
                </v-col>
            </v-row>
            <v-row v-if="vatCalculatedAmountByEachServiceNetto" no-gutters class="font-weight-regular">
                <v-col :cols="6" md="6"> {{ `${$t('commission')} VAT` }}:</v-col>
                <v-col :cols="6" md="6">
                    <div class="font-weight-bold">
                        {{ vatCalculatedAmountByEachServiceNetto | priceFormat(orderPrices[0].currency) }}
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="font-weight-regular">
                <v-col :cols="6" md="6">{{ $t('net_amount') }}:</v-col>
                <v-col :cols="6" md="6">
                    <template v-for="(orderPrice, index) in orderPrices">
                        <div v-if="orderPrice.commission" :key="index" class="font-weight-bold">
                            {{ (orderPrice.amount - orderPrice.commission) | priceFormat(orderPrice.currency) }}
                        </div>
                    </template>
                </v-col>
            </v-row>
        </template>
        <v-row v-if="$config.account.paymentScheduler" no-gutters>
            <v-col :cols="6" md="6" />
            <v-col :cols="6" md="6">
                <payment-status-label :status="paymentStatus" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import PriceLabel from '~src/components/snippets/priceLabel.src'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import CancelPenaltyLabel from '@/components/snippets/CancelPenaltyLabel'

    @Component({components: {CancelPenaltyLabel, PaymentStatusLabel, PriceLabel}})
    export default class OrderPriceLabel extends Vue {
        @Prop({required: true}) orderPrices
        @Prop({required: true}) services
        @Prop({required: true}) paymentStatus
        @Prop({required: true}) showCommissionAndNettoBlock
        @Prop({required: true}) vatCalculatedAmountByEachServiceNetto
    }
</script>
