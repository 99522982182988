<template>
    <div>
        <v-tabs v-model="entryTab" class="mb-5">
            <v-tab v-for="(productName, index) in productNames" :key="index">{{ productName }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="entryTab">
            <v-tab-item v-for="({component, entry}, index) in serviceForms" :key="index">
                <component
                    :is="component"
                    v-model="prepareBookRequests[selectedTourEntryIndex(entry)]"
                    :entry="entry"
                    :search-request="searchRequest"
                    :disabled="disabled"
                    @change-price="changePrice($event, selectedTourEntryIndex(entry))"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'

    @Component({
        components: {
            FlightExtraData: () => import('@/components/account/modals/newOrderRequest/forms/FlightExtraData.vue'),
        },
    })
    export default class PackageTourExtraData extends Vue {
        @Prop({required: true}) selectedTourEntries
        @Prop({required: true}) searchRequest
        @Prop({required: true}) prepareBookRequests
        @Prop({required: true, type: Boolean}) disabled

        entryTab = 0

        @Emit()
        changePrice(price, index) {
            return {price, index}
        }

        selectedTourEntryIndex(entry) {
            return this.selectedTourEntries.findIndex(({entryName}) => entry.entryName === entryName)
        }

        get specifiedTourEntries() {
            return this.selectedTourEntries.filter(({type}) => type === 'FLIGHT')
        }

        get productNames() {
            return this.specifiedTourEntries.map(({productName}) => productName)
        }

        get serviceForms() {
            return this.specifiedTourEntries.map(entry => {
                switch (entry.type) {
                    case 'FLIGHT':
                        return {
                            entry,
                            component: 'FlightExtraData',
                        }
                }
            })
        }
    }
</script>
