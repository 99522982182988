<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderSummaryMixin from '@/components/booking/mixins/OrderSummaryMixin.vue'
    import {hotelsRuntimeStore, hotelsStore} from '@/utils/store-accessor'
    import {calcExtraServicesTotalPrice, calcTotalPrice} from '@/utils/price-utils'

    @Component
    export default class HotelOrderSummaryMixin extends OrderSummaryMixin {
        get orderTotalPrice() {
            return hotelsStore.orderTotalPrice(
                this.roomOffers,
                this.prebooked,
                this.selectedAddOns,
                this.offersData,
                this.prepareBookResponse
            )
        }

        get extraPrices() {
            return this.extraServices.map((extraServices, roomIndex) => {
                const {price, supplierPrice} = calcExtraServicesTotalPrice(
                    this.additionalOptions[roomIndex],
                    extraServices
                )
                const selectedMealTypePrices = this.selectedMealTypes[roomIndex]
                    .map(({price, quantity}) => ({
                        ...price,
                        amount: price.amount * quantity,
                    }))
                    .filter(({amount}) => amount !== 0)
                return {
                    price: calcTotalPrice([price, ...selectedMealTypePrices].filter(Boolean)),
                    supplierPrice,
                }
            })
        }

        get selectedMealTypes() {
            return this.roomOffers.map((roomOffer, roomIndex) => {
                const alternativeMeals = this.additionalOptions[roomIndex].alternativeMeals
                if (!alternativeMeals || !this.tourists[roomIndex]) return []
                const defaultMealType = alternativeMeals.find(({name}) => name === roomOffer.mealTypeName)
                return this.tourists[roomIndex].reduce((selectedMealTypes, tourist) => {
                    const touristMealTypeId = tourist.alternativeMealTypeId || defaultMealType.id
                    const selectedMealType = selectedMealTypes.find(({id}) => id === touristMealTypeId)
                    if (selectedMealType) {
                        selectedMealType.quantity++
                    } else {
                        if (!defaultMealType) return selectedMealTypes
                        const defaultMealTypePrice = hotelsRuntimeStore.mealTypePrice(defaultMealType.prices, tourist)
                        const {id, code, name, prices} = alternativeMeals.find(({id}) => id === touristMealTypeId)
                        selectedMealTypes.push({
                            id,
                            name: code,
                            mealTypeName: name,
                            price: {
                                currency: defaultMealTypePrice.currency,
                                amount:
                                    hotelsRuntimeStore.mealTypePrice(prices, tourist).amount -
                                    defaultMealTypePrice.amount,
                            },
                            quantity: 1,
                        })
                    }
                    return selectedMealTypes
                }, [])
            })
        }
    }
</script>
