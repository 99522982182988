<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {insuranceRuntimeStore} from '@/store'

    @Component
    export default class InsurancePageMixin extends Vue {
        async loadRuntimeData() {
            const {locationId} = this.searchRequest
            if (insuranceRuntimeStore.location.id !== locationId) await insuranceRuntimeStore.loadLocation(locationId)
        }
    }
</script>
