<template>
    <v-layout :fill-height="!loaded" column>
        <slot v-if="loaded" />
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
    </v-layout>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class LoadingPageLayout extends Vue {
        @Prop({required: false, type: Boolean}) loaded
    }
</script>
