<template>
    <v-layout :fill-height="$fetchState.pending" column>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
        <portal v-if="isMobile" to="under-header">
            <hotel-info-mobile-tabs v-model="mobileTabs" />
        </portal>
        <v-container v-if="!$fetchState.pending" fluid>
            <hotel-breadcrumbs v-if="!isMobile" :search-request="searchRequest" />
            <v-row>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 0)" md="8">
                    <hotel-info
                        :offer-prop="offer"
                        :search-request="searchRequest"
                        class="mb-3"
                        @showMapTab="showMapTab"
                    />
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="info.images.image"
                        class="mb-3"
                    />
                    <hotel-info-room-offers-wrapper
                        v-if="!isMobile"
                        class="mb-5"
                        :search-request="searchRequest"
                        :offer="offer"
                    />
                    <hotel-info-descriptions class="mb-5" />
                    <hotel-info-time v-if="isMobile" card class="mb-5" />
                    <hotel-info-services class="mb-5" />
                    <similar-hotels v-if="!isMobile" :search-request="searchRequest" :city="city" class="mb-5" />
                    <recent-view-hotels v-if="!isMobile" :search-request="searchRequest" :offer="offer" class="mb-5" />
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 1)" md="4">
                    <slot name="sidebar" />
                    <hotel-info-search-summary :search-request="searchRequest" />
                    <hotel-info-time v-if="!isMobile" card class="mb-5" />
                    <hotel-info-room-offers-wrapper
                        v-if="isMobile"
                        class="mb-5"
                        :search-request="searchRequest"
                        :offer="offer"
                    />
                    <div v-if="!isMobile">
                        <client-only>
                            <hotel-info-map :city="city" location-filter />
                        </client-only>
                        <hotel-info-relative-positions />
                    </div>
                </v-col>
                <v-col v-show="isMobile && mobileTabs === 2" :cols="12">
                    <client-only>
                        <hotel-info-map :city="city" location-filter />
                    </client-only>
                </v-col>
            </v-row>
        </v-container>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
    </v-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {cmsStore, hotelsRuntimeStore, hotelsStore, persistentStore} from '@/store'
    import {clone, decodeSupplierCode, isEqual} from '@/utils/helpers'
    import HotelInfo from '~src/components/info/hotelInfo.src'
    import HotelInfoSearchSummary from '@/components/info/HotelInfoSearchSummary'
    import HotelInfoRoomOffersWrapper from '@/components/info/offers/HotelInfoRoomOffersWrapper'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import HotelInfoRelativePositions from '@/components/info/HotelInfoRelativePositions'
    import HotelInfoServices from '~src/components/info/hotelInfoServices.src'
    import HotelInfoDescriptions from '@/components/info/HotelInfoDescriptions'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'
    import NuxtPageMixin from '@/mixins/NuxtPageMixin'
    import OverlayProgress from '@/components/OverlayProgress'
    import HotelInfoMobileTabs from '~src/components/hotels/info/snippets/hotelInfoMobileTabs.src'
    import HotelDataPageMixin from '~src/pages/hotels/mixins/hotelDataPageMixin.src'
    import {AUTH_EVENT, CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import SimilarHotels from '~src/components/search/offers/similarHotels.src'
    import RecentViewHotels from '@/components/search/offers/RecentViewHotels'
    import {fullUrl, srcHost} from '~src/utils/host-adapter'

    @Component({
        components: {
            OverlayProgress,
            HotelBreadcrumbs,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            HotelInfoServices,
            HotelInfoRelativePositions,
            HotelInfo,
            HotelInfoSearchSummary,
            HotelInfoRoomOffersWrapper,
            HotelInfoMap: () => import('@/components/info/hotelInfoMap.src'),
            HotelInfoTime,
            HotelInfoDescriptions,
            ProductGallery,
            ProductGalleryMobile,
            HotelInfoMobileTabs,
            SimilarHotels,
            RecentViewHotels,
        },
        layout: 'blank',
    })
    export default class HotelPage extends mixins(HotelStoreMixin, HotelDataPageMixin, HotelPageMixin, NuxtPageMixin) {
        mobileTabs = 0

        @Watch('$route.query')
        async onQueryChange() {
            if (!(await this.checkSearchDate('hotel'))) return
            hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
        }

        async validate({query, params, $axios}) {
            const {supplierCode, cityCode, hotelCode, cityId, hotelId} = query
            if (cityId && (hotelId || (supplierCode && cityCode && hotelCode))) return true
            const hotel = await $axios.$get(srcHost(`/proxy/hotel/${params.name}`))
            return !!hotel
        }

        async fetch() {
            await hotelsRuntimeStore.loadRouteMap(this.$route.params.name)
            await this.loadRuntimeData(this.cityId || hotelsRuntimeStore.routeMap.cityId)
        }

        created() {
            EventBus.$on(AUTH_EVENT, this.refreshExpiredOffers)
            EventBus.$on(CHANGE_LOCALE_EVENT, this.refreshExpiredOffers)
        }

        async mounted() {
            await this.fetchStatePending
            await this.loadOffers()
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.refreshExpiredOffers)
            EventBus.$off(CHANGE_LOCALE_EVENT, this.refreshExpiredOffers)
        }

        head() {
            const head = clone(cmsStore.layoutSettings.head)
            const addMeta = (name, content) => {
                const meta = head.meta.find(({hid}) => hid === name)
                if (meta) {
                    meta.content = content
                } else {
                    head.meta.push({
                        hid: name,
                        name,
                        content,
                    })
                }
            }
            if (this.$config.host) {
                head.link = [
                    {
                        rel: 'canonical',
                        href: fullUrl(this.$route.path),
                    },
                ]
            }
            if (head.titleTemplate.indexOf('%s') === -1) {
                head.titleTemplate = `%s | ${head.titleTemplate}`
            }
            head.title = `${this.info.hotelName}, ${this.city.name}`
            addMeta('og:title', head.titleTemplate.replace('%s', head.title))
            const shortDescription = this.info.descriptions?.description.find(({type}) => type === 'short')
            if (shortDescription) {
                const description = shortDescription.description.replace(/<\/?[^>]+(>|$)/g, '')
                addMeta('description', description)
            }
            if (this.info.images.image[0]) {
                addMeta('og:image', this.$options.filters.imageUrl(this.info.images.image[0].url, 300))
            }
            return head
        }

        async loadOffers() {
            await this.$store.restored
            if (!(await this.checkSearchDate('hotel'))) return
            if (!isEqual(hotelsStore.singleHotelSearchRequest, this.searchRequest) || this.isOffersExpired()) {
                await hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
            }
        }

        beforeRouteLeave(to, from, next) {
            hotelsRuntimeStore.stopSingleHotelSearch()
            next()
        }

        async refreshExpiredOffers() {
            await hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
        }

        isOffersExpired() {
            return hotelsStore.isSingleHotelOffersExpired()
        }

        showMapTab() {
            this.mobileTabs = 2
        }

        get searchRequest() {
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.rooms === 'string') {
                rq.rooms = [decodeURIComponent(rq.rooms)]
            } else {
                rq.rooms = rq.rooms.map(room => decodeURIComponent(room))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.cityId = this.cityId || hotelsRuntimeStore.routeMap.cityId
            if (this.hotelId) {
                rq.hotelId = this.hotelId
            }
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            //For search from suggestHotels (by hotelId)
            if (rq.hotelId && !rq.supplierCode) {
                delete rq.supplierCode
                delete rq.hotelCode
                delete rq.cityCode
            } else {
                if (this.$config.searchAccommodationByDistance) {
                    delete rq.distance
                    delete rq.latitude
                    delete rq.longitude
                }
                if (rq.supplierCode) {
                    rq.supplierCode = decodeSupplierCode(rq.supplierCode)
                }
            }
            rq.freeOnly = rq.freeOnly === 'true' || rq.freeOnly === true

            return rq
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get hotelGalleryComponent() {
            return this.isMobile ? 'hotel-gallery-mobile' : 'hotel-gallery'
        }

        get info() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }

        get city() {
            return hotelsRuntimeStore.city
        }

        get hotelInfoRequest() {
            let {supplierCode, cityCode, hotelCode} = this.$route.query
            const hotelId = this.hotelId
            if (supplierCode) supplierCode = decodeSupplierCode(supplierCode)
            return {supplierCode, cityCode, hotelCode, hotelId}
        }

        get offer() {
            return hotelsStore.singleHotelSearchResponse.offers[0]
        }

        get hotelId() {
            return parseInt(this.$route.query.hotelId, 10) || hotelsRuntimeStore.routeMap.hotelId || undefined
        }
    }
</script>
