<template>
    <v-footer v-show="visible" v-intersect="onIntersect" app absolute min-height="100px">
        <div class="d-md-flex align-center">
            <social-icons v-if="socialLinks.length" />
            <footer-menu v-if="footerMenu.length && !$breakpoint.smAndDown" />
            <img src="/payments-logo.png" class="payments-logo" alt="WebPay, Visa, Mastercard" />
            <div class="ml-auto overline">
                <span v-html="$t('copyright')" /> <span v-if="!hideAppVersion">v{{ version }}</span>
            </div>
        </div>
        <div class="text--secondary caption ml-md-8">
            {{ $t('registration_certificate') }}
        </div>
    </v-footer>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import SocialIcons from '~/components/snippets/SocialIcons'
    import FooterMenu from '~/components/snippets/FooterMenu'
    import FooterBar from '~/components/FooterBar'

    @Component({
        components: {
            SocialIcons,
            FooterMenu,
        },
    })
    export default class FooterBarMod extends FooterBar {}
</script>

<style scoped lang="scss">
    .v-footer {
        > div {
            width: 100%;
        }
    }
    .payments-logo {
        max-height: 30px;
        max-width: 100%;
    }
</style>
