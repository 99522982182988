<template>
    <v-card outlined class="mb-5">
        <template v-for="(relativePositionGroup, index) in relativePositionGroups">
            <template v-if="relativePositionGroup.relativePositions.length">
                <v-card-title :key="index + '_0'" class="title font-weight-light">
                    {{ $t(relativePositionGroup.title) }}
                </v-card-title>
                <v-list :key="index + '_1'" dense>
                    <template
                        v-for="(relativePosition, relativePositionIndex) in relativePositionGroup.relativePositions"
                    >
                        <v-list-item :key="relativePositionIndex + '_0'">
                            <v-list-item-title class="text-capitalize">
                                {{ relativePosition.name }}
                            </v-list-item-title>
                            <v-list-item-action-text v-if="relativePosition.distance">
                                {{ relativePosition.distance }}&nbsp;{{ $t('km') }}
                            </v-list-item-action-text>
                        </v-list-item>
                        <v-divider
                            v-if="relativePositionIndex + 1 < relativePositionGroup.relativePositions.length"
                            :key="relativePositionIndex + '_1'"
                        />
                    </template>
                </v-list>
            </template>
        </template>
    </v-card>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'

    const transportTypes = ['Airport', 'RailwayStation', 'OutsideCity']

    @Component
    export default class HotelInfoRelativePositions extends Vue {
        get relativePositionGroups() {
            const relativePositions = hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.relativePositions
                ? hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.relativePositions.relativePosition
                : []
            return [
                {
                    title: 'transport',
                    relativePositions: relativePositions.filter(
                        relativePosition => transportTypes.indexOf(relativePosition.type) !== -1
                    ),
                },
                {
                    title: 'tourist_attractions',
                    relativePositions: relativePositions.filter(
                        relativePosition => transportTypes.indexOf(relativePosition.type) === -1
                    ),
                },
            ]
        }
    }
</script>

<style scoped></style>
