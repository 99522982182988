<template>
    <v-card class="my-2" outlined>
        <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :footer-props="{'items-per-page-options': [50, 100]}"
            :custom-sort="customSort"
        >
            <template v-slot:item.tourName="{item}">
                <div class="font-weight-medium text-truncate">
                    <nuxt-link
                        v-if="$route.name === 'tourSearch'"
                        no-prefetch
                        :to="{name: 'tour', query: item.query}"
                        class="text-decoration-none"
                    >
                        {{ item.tourName }}
                    </nuxt-link>
                    <span v-else>{{ item.tourName }}</span>
                </div>
            </template>
            <template v-slot:item.duration="{item}">
                <span class="nowrap">
                    {{ item.duration.days }}
                    <v-icon small>mdi-weather-sunny</v-icon>
                    {{ `/ ${item.duration.nights}` }}
                    <v-icon small>mdi-weather-night</v-icon>
                </span>
            </template>
            <template v-slot:item.accommodation="{item}">
                <div v-if="item.accommodation">
                    {{ item.accommodation }}
                </div>
                <span v-else>-</span>
            </template>
            <template v-slot:item.services="{item}">
                <tour-included-entries :entries="item.services" />
            </template>
            <template v-slot:item.price="{item}">
                <v-btn color="primary" depressed x-small @click="selectForBooking(item)">
                    {{ item.price | price }}
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import TourIncludedEntries from '@/components/tours/snippets/TourIncludedEntries'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import {toursRuntimeStore, toursStore} from '@/utils/store-accessor'

    @Component({
        components: {TourIncludedEntries, HotelStars},
    })
    export default class TourOfferTableView extends Vue {
        @Prop({required: true}) offers

        async selectForBooking(val) {
            const offerKey = val.offerKey
            const selectedOfferKey = val.roomKey
            await toursRuntimeStore.SELECT_OFFER({offerKey, selectedOfferKey})
            await toursStore.ADD_TO_BASKET(offerKey)
            await this.$router.push({
                name: 'tourBooking',
                query: {offerKey},
            })
        }

        customSort(items) {
            items.sort((a, b) => {
                if (a.startDate === b.startDate) {
                    if (a.duration.days - b.duration.days === 0) {
                        if (a.accommodation === b.accommodation) {
                            if (a.roomType === b.roomType) {
                                return a.mealType > b.mealType ? 1 : -1
                            } else return a.roomType > b.roomType ? 1 : -1
                        } else return a.accommodation > b.accommodation ? 1 : -1
                    } else return a.duration.days - b.duration.days
                } else return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1
            })

            return items
        }

        get tableHeaders() {
            return [
                {
                    text: 'Tour Name',
                    align: 'start',
                    value: 'tourName',
                    sortable: false,
                },
                {
                    text: 'Start Date',
                    align: 'start',
                    value: 'startDate',
                    sortable: false,
                },
                {
                    text: 'Duration',
                    align: 'start',
                    value: 'duration',
                    sortable: false,
                },
                {
                    text: 'Accommodation',
                    align: 'start',
                    value: 'accommodation',
                    sortable: false,
                },
                {
                    text: 'Room Type',
                    align: 'start',
                    value: 'roomType',
                    sortable: false,
                },
                {
                    text: 'Meal Type',
                    align: 'start',
                    value: 'mealType',
                    sortable: false,
                },
                {
                    text: 'Services',
                    align: 'start',
                    value: 'services',
                    sortable: false,
                },
                {
                    text: 'Price',
                    align: 'start',
                    value: 'price',
                    sortable: false,
                },
            ]
        }

        get tableItems() {
            return this.offers.reduce((tableItems, tour) => {
                const hotelIndex = tour.entries.findIndex(entry => entry.type === 'ACCOMMODATION' && entry.obligatory)

                if (hotelIndex >= 0) {
                    tour.entries[hotelIndex].offers.forEach(hotelOffer => {
                        hotelOffer.rooms
                            .filter(room => !room.soldOut)
                            .forEach(room => {
                                const data = {
                                    query: {
                                        ...this.searchRequest,
                                        packageTourId: tour.info.id,
                                        startDateFrom: this.$dateFns.format(tour.info.packageDate),
                                    },
                                    offerKey: tour.offerKey,
                                    roomKey: room.groupedOffers[0].offerKey,
                                    tourName: tour.info.name,
                                    startDate: this.$options.filters.dateShortFormat(tour.info.packageDate),
                                    duration: {
                                        days: tour.info.packageDuration,
                                        nights: tour.info.nightsDuration,
                                    },
                                    accommodation: hotelOffer.name,
                                    roomType: room.name,
                                    mealType: room.mealTypeName,
                                    services: tour.entries,
                                    price: {
                                        ...tour.price,
                                        amount: tour.price.amount + room.deltaPrice.amount,
                                    },
                                }

                                tableItems.push(data)
                            })
                    })
                } else {
                    tableItems.push({
                        query: {
                            ...this.searchRequest,
                            packageTourId: tour.info.id,
                            startDateFrom: this.$dateFns.format(tour.info.packageDate),
                        },
                        offerKey: tour.offerKey,
                        tourName: tour.info.name,
                        startDate: this.$options.filters.dateShortFormat(tour.info.packageDate),
                        duration: {
                            days: tour.info.packageDuration,
                            nights: tour.info.nightsDuration,
                        },
                        accommodation: null,
                        roomType: '-',
                        mealType: '-',
                        services: tour.entries,
                        price: tour.price,
                    })
                }

                return tableItems
            }, [])
        }

        get searchRequest() {
            return toursStore.searchRequest
        }
    }
</script>
