<template>
    <v-dialog v-model="model" width="900">
        <v-card height="800" class="iframeWrapper">
            <iframe :srcdoc="html" />
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class EmailPreviewModal extends Vue {
        @VModel() model
        @Prop({required: true}) html
    }
</script>

<style scoped lang="scss">
    .iframeWrapper {
        line-height: 0;
    }

    iframe {
        border: none;
        width: 100%;
        height: 100%;
    }
</style>
