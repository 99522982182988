<template>
    <div ref="map" class="map" />
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import gmapsInit from '~/utils/gmaps'
    import {toursRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TourMap extends Vue {
        google = null
        map = null
        markers = []

        async mounted() {
            try {
                this.google = await gmapsInit()
                this.map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    fullscreenControl: true,
                    maxZoom: 16,
                })
                this.initMarkers()
            } catch (error) {
                console.error(error)
            }
        }

        initMarkers() {
            if (!this.map || !toursRuntimeStore.packageTourInfo.locations.length) return
            const bounds = new this.google.maps.LatLngBounds()
            toursRuntimeStore.packageTourInfo.locations.forEach(location => {
                const marker = this.addMarker(location)
                bounds.extend(marker.getPosition())
            })
            this.google.maps.event.addListener(this.map, 'idle', () => {
                this.map.fitBounds(bounds)
            })
            this.map.fitBounds(bounds)
        }

        addMarker(info) {
            let position
            if (info.latitude && info.longitude) {
                position = {
                    lat: info.latitude,
                    lng: info.longitude,
                }
            }
            const markerIcon = {
                path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                fillColor: this.$vuetify.theme.themes.light.primary,
                fillOpacity: 0.95,
                scale: 2,
                strokeColor: '#fff',
                strokeWeight: 3,
                anchor: new this.google.maps.Point(12, 24),
            }
            const marker = new this.google.maps.Marker({
                position,
                map: this.map,
                icon: markerIcon,
                data: info,
            })
            this.markers.push(marker)
            return marker
        }
    }
</script>

<style scoped lang="scss">
    .map {
        height: 300px;
    }
</style>
