<template>
    <v-form>
        <v-row v-show="!!formComponent">
            <template v-if="productType === 'FLIGHT'">
                <v-col cols="4">
                    <airlines-search-input
                        v-model="product.marketingAirline"
                        class="mt-4"
                        outlined
                        dense
                        :label="`${$t('flights.airline')}`"
                        hide-details
                        single-line
                    />
                </v-col>
                <v-col cols="12" class="py-0">
                    <div class="full-width d-flex align-center">
                        <v-spacer />
                        <v-btn class="mb-n4" color="primary" fab dark small @click="addSegment">
                            <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col v-for="(segment, index) in product.segments" :key="index" cols="12">
                    <div class="d-flex align-start full-width">
                        <component
                            :is="formComponent"
                            v-model="product.segments[index]"
                            class="full-width"
                            :index="index"
                            :product-type="productType"
                            :tourists="tourists"
                        />
                        <v-btn v-if="index !== 0" class="mt-n4" fab small elevation="0" @click="removeSegment(index)">
                            <v-icon> mdi-close </v-icon>
                        </v-btn>
                    </div>
                    <v-divider class="my-8" />
                </v-col>
            </template>
            <v-col v-else cols="12" class="py-8">
                <component :is="formComponent" v-model="product" :product-type="productType" :tourists="tourists" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import OfflineServiceFormFlight from '@/components/account/offlineServices/forms/OfflineServiceFormFlight'
    import OfflineServiceFormAccommodation from '@/components/account/offlineServices/forms/OfflineServiceFormAccommodation'
    import OfflineServiceFormTransfer from '@/components/account/offlineServices/forms/OfflineServiceFormTransfer'
    import OfflineServiceFormCars from '@/components/account/offlineServices/forms/OfflineServiceFormCars'
    import OfflineServiceFormExcursion from '@/components/account/offlineServices/forms/OfflineServiceFormExcursion'
    import AirlinesSearchInput from '@/components/snippets/AirlinesSearchInput'

    @Component({
        components: {
            AirlinesSearchInput,
            OfflineServiceFormExcursion,
            OfflineServiceFormCars,
            OfflineServiceFormTransfer,
            OfflineServiceFormAccommodation,
            OfflineServiceFormFlight,
        },
    })
    export default class OfflineServiceProductForm extends Vue {
        @Prop() productType
        @Prop() tourists
        @VModel() product

        addSegment() {
            this.product.segments?.push({})
        }

        removeSegment(i) {
            this.product.segments?.splice(i, 1)
        }

        get formComponent() {
            switch (this.productType) {
                case 'FLIGHT':
                    return 'offline-service-form-flight'
                case 'ACCOMMODATION':
                    return 'offline-service-form-accommodation'
                case 'TRANSFER':
                    return 'offline-service-form-transfer'
                case 'CAR_RENT':
                    return 'offline-service-form-cars'
                case 'EXCURSION':
                    return 'offline-service-form-excursion'
                default:
                    return null
            }
        }
    }
</script>
