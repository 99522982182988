<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card>
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-title class="title font-weight-light">
                {{ $t('cancellation') }}
            </v-card-title>
            <v-card-text>
                <div v-html="isB2B ? $t('cancellation_order_text_b2b') : $t('cancellation_order_text_b2c')" />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class CancelServiceManualModal extends Vue {
        modal = false

        async show() {
            this.modal = true
        }

        get isB2B() {
            return authStore.isB2B
        }
    }
</script>
