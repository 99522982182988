<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {extraServicesRuntimeStore, extraServicesStore} from '@/store'
    import {PRODUCT_NAME} from '@/utils/extraServices/extra-services-const'

    @Component
    export default class ExtraServiceStoreMixin extends Vue {
        get productStore() {
            return extraServicesStore
        }

        get productRuntimeStore() {
            return extraServicesRuntimeStore
        }

        get productType() {
            return 'extraService'
        }

        get productName() {
            return PRODUCT_NAME
        }
    }
</script>
