<template>
    <search-form-layout
        :popup="popup"
        :width="isIframe ? '100%' : undefined"
        title="hotels"
        @close-popup="$emit('close-popup')"
    >
        <template v-slot:form>
            <v-form ref="form" v-model="valid" data-cy="hotels-form">
                <v-row>
                    <v-col v-if="!isSingleHotelSearch" :md="isCitizenshipMainOption ? 10 : 4" cols="12">
                        <city-hotel-autocomplete
                            ref="locationAutocomplete"
                            v-model="city"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                        />
                    </v-col>
                    <v-col :md="isCitizenshipMainOption ? 4 : isSingleHotelSearch ? 5 : 3" cols="12">
                        <daterange-m :start-date.sync="startDate" :end-date.sync="endDate" />
                    </v-col>
                    <v-col :md="isCitizenshipMainOption ? 4 : isSingleHotelSearch ? 5 : 3" cols="12">
                        <rooms-select v-model="rooms" />
                    </v-col>
                    <v-col v-if="isCitizenshipMainOption" cols="12" md="2">
                        <v-select
                            v-model="selectedCitizenship"
                            background-color="white"
                            filled
                            :prepend-inner-icon="'mdi-account-tie'"
                            return-object
                            item-text="name"
                            :items="citizenshipItems"
                            :label="$t('citizenship')"
                            single-line
                            :menu-props="{bottom: true, offsetY: true}"
                            :rules="[v => !!v || `${$t('citizenship')} ${$t('validation.required')}`]"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :options="searchAdditionalOptions"
                :suppliers="suppliers"
                :is-citizenship-main-option="isCitizenshipMainOption"
                @change-citizenship="changeCitizenship"
            >
                <template v-slot:header>
                    <div class="d-flex align-center">
                        <v-expansion-panel-header class="pl-1 width-auto">
                            {{ $t('extra_options') }}
                        </v-expansion-panel-header>
                        <own-hotel-map-modal v-if="$config.ownHotelMapModal" />
                    </div>
                </template>
            </additional-options-wrapper>
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import CityHotelAutocomplete from '~src/components/search/forms/cityHotelAutocomplete.src'
    import RoomsSelect from '~src/components/search/forms/roomsSelect.src'
    import {authStore, hotelsRuntimeStore, hotelsStore, persistentStore} from '~/store'
    import AdditionalSearchOptionsMixin from '~src/mixins/additionalSearchOptionsMixin.src'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import TouristsSelect from '~src/components/search/forms/touristsSelect.src'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import {Watch} from 'nuxt-property-decorator'
    import OwnHotelMapModal from '@/components/search/offers/OwnHotelMapModal'
    import {hotelNameToUrl} from '@/utils/helpers'

    @Component({
        components: {
            CityHotelAutocomplete,
            DaterangeM,
            TouristsSelect,
            RoomsSelect,
            AdditionalOptionsWrapper,
            SearchFormLayout,
            OwnHotelMapModal,
        },
    })
    export default class HotelSearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false, type: Boolean}) popup
        @Prop({default: false, type: Boolean}) isIframe
        @Prop() cityId

        //TODO Rename city to location (may be city or hotel)
        city = {}
        startDate = null
        endDate = null
        rooms = []
        valid = false
        loaded = false
        popularDestinations = []
        suppliers = []

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        @Watch('city.countryId')
        onCityChanged() {
            this.setCurrencyByCitizenship()
        }

        //TODO Need refactoring
        async reload() {
            await this.loadPopularDestinations()
            this.$refs.locationAutocomplete?.clear()
            if (this.city && this.city.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.city.id, limitCities: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            } else {
                this.$refs.locationAutocomplete?.load()
            }
        }

        async mounted() {
            await this.load()
        }

        async load() {
            await this.$store.restored
            await this.loadPopularDestinations()
            this.loaded = true
            await this.$nextTick()
            const cityId = this.cityId || this.searchRequest.cityId
            if (cityId && hotelsRuntimeStore.city.id !== cityId) {
                await hotelsRuntimeStore.loadCity(cityId)
            }
            this.city = cityId && Object.keys(hotelsRuntimeStore.city).length ? hotelsRuntimeStore.city : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(this.searchRequest.startDate), new Date())) {
                this.startDate = this.searchRequest.startDate
                this.endDate = this.searchRequest.endDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7))
            }
            this.rooms = !this.onlySinglePassenger ? this.searchRequestTourists : [{adults: 1, childrenAges: []}]
            if (this.isTO1) {
                try {
                    this.suppliers = (await this.$api.suppliers.get({productType: 'accommodation'}))?.suppliers
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async loadPopularDestinations() {
            try {
                this.popularDestinations = (
                    await this.$api.locations.get({limitCities: 7, productType: 'ACCOMMODATION'})
                ).cities
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        newQuery() {
            const query = {
                cityId: this.city.id,
                startDate: this.startDate,
                endDate: this.endDate,
                rooms: this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room)),
                convertToCurrency: persistentStore.currency,
            }
            if (this.city.hotelCode) {
                //TODO Add in API GET /suggestHotels response cityCode if it possible
                Object.assign(query, {
                    hotelCode: this.city.hotelCode,
                    cityId: this.city.cityId,
                    hotelId: this.city.id,
                    cityCode: null,
                    supplierCode: null,
                })
            }

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
                query.citizenshipId = persistentStore.selectedAgent.companyCountryId
            }

            if (this.isCitizenshipMainOption && this.selectedCitizenship?.value) {
                query.citizenshipId = this.selectedCitizenship.value
            }

            return query
        }

        get isTO1() {
            return authStore.isTO1
        }

        async search() {
            this.$emit('close-popup')
            const query = this.newQuery()
            hotelsRuntimeStore.SET_CITY(this.city)
            const route = {query}
            if (this.city.hotelCode) {
                route.name = 'hotel'
                route.params = {name: hotelNameToUrl(this.city.name)}
            } else {
                route.name = 'hotels'
                if (this.$config.searchAccommodationByDistance && this.$route.query.distance) {
                    route.query.distance = this.$route.query.distance
                }
            }
            if (this.$route.name === 'hotels') {
                try {
                    await this.$router.push(route)
                } catch (e) {
                    await hotelsRuntimeStore.search(this.searchRequest)
                }
            } else {
                if (this.city.hotelCode) {
                    hotelsRuntimeStore.newSingleHotelSearch()
                } else {
                    hotelsRuntimeStore.newSearch()
                }
                if (this.isIframe) {
                    window.top.location.href = window.location.origin + this.$router.resolve(route).href
                } else {
                    await this.$router.push(route)
                }
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        get searchRequest() {
            return hotelsStore.searchRequest
        }

        get searchRequestTourists() {
            return hotelsStore.searchRequestTourists
        }

        get isSingleHotelSearch() {
            return false
        }

        get onlySinglePassenger() {
            return authStore.user.isEmployeeOrExternalCorp
        }

        get searchAdditionalOptions() {
            return this.$config.hotels.searchAdditionalOptions
        }
    }
</script>
