<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {carsRentRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class CarsSearchPageMixin extends Vue {
        async loadRuntimeData() {
            const {pickUpCityId, dropOffCityId} = this.searchRequest
            const promises = []
            if (pickUpCityId && pickUpCityId !== carsRentRuntimeStore.pickUpPoint.id) {
                promises.push(carsRentRuntimeStore.loadPickUpPoint(pickUpCityId))
            }
            if (dropOffCityId && dropOffCityId !== carsRentRuntimeStore.dropOffPoint.id) {
                promises.push(carsRentRuntimeStore.loadDropOffPoint(dropOffCityId))
            }
            await Promise.all(promises)
        }
    }
</script>
