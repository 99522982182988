<script>
    import {Component, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import {EventBus, RESET_BOOKING_DATA} from '@/utils/event-bus'
    import {isPrepareBookResultClean, prepareBookResult} from '@/utils/PrepareBookAdapter'
    import AddOnMixin from '@/components/booking/addOns/AddOnMixin'
    import BookingPageMixin from '@/mixins/BookingPageMixin.vue'
    import TransferOrderSummaryMixin from '@/components/transfers/mixins/TransferOrderSummaryMixin.vue'

    @Component
    export default class TransferBookingPageLayout extends mixins(
        BookingPageBase,
        TransferStoreMixin,
        TransferOrderSummaryMixin
    ) {
        @Prop() isReturnTransfer

        extraServices = [[]]

        @Watch('isReturnTransfer')
        onReturnTransferChange(isReturnTransfer) {
            this.resetBookResponse()
            if (isReturnTransfer) {
                this.extraServices.push([])
            } else {
                this.extraServices.pop()
            }
        }

        prepareBookRequest(specifiedTouristBookingFields, includeOptionalTourists = false) {
            const prepareBookRequest = BookingPageMixin.options.methods.prepareBookRequest.call(
                this,
                specifiedTouristBookingFields,
                includeOptionalTourists
            )
            if (this.extraServices[0]?.length) {
                prepareBookRequest.extraServices = this.extraServices[0]
            } else {
                delete prepareBookRequest.extraServices
            }
            return prepareBookRequest
        }

        async sendPrepareBookRequest(prepareBookRequest) {
            try {
                await this.productStore.prepareBook({
                    rq: prepareBookRequest,
                    isReturnTransfer: this.isReturnTransfer,
                    returnTransferExtraServices: this.extraServices[1],
                })

                /*let success = true
                if (this.isReturnTransfer) {
                    success = this.prepareBookResponseProcessing(
                        prepareBookResponse.transferReturnRs,
                        transfersStore.prepareBookReturnTransferRequest.offerKey,
                        transfersStore.prepareBookReturnTransferRequest
                    )
                }
                return (
                    success &&
                    this.prepareBookResponseProcessing(
                        prepareBookResponse.transferRs,
                        transfersStore.prepareBookRequest.offerKey,
                        transfersStore.prepareBookRequest
                    )
                )*/

                if (isPrepareBookResultClean(prepareBookResult(this.prepareBookResponse, this.offersData))) {
                    await this.paymentStepInit()
                }
            } catch (e) {
                return this.prepareBookErrorProcessing(e)
            }
        }

        async getPaymentOptions(loyaltyPointsOptions) {
            try {
                const rq = {
                    ...loyaltyPointsOptions,
                    convertToCurrency: persistentStore.currency,
                }

                if (this.isReturnTransfer) {
                    rq.basketKey = this.productStore.prepareBookResponse.basketKey
                } else {
                    rq.bookingKey = this.productStore.prepareBookResponse.bookingKey
                }

                await persistentStore.getPaymentOptions(rq)
            } catch (e) {
                this.$toast.warning(this.$t('error_message.get_payment_options_unknown_error'))
            }
        }

        //TODO Need Refactoring with base component
        async makeOfflinePayment() {
            try {
                const rq = {
                    paymentMethodId: this.paymentMethodId,
                    ...(this.corporatePolicyViolationCodeId && {
                        corporatePolicyViolationCodeId: this.corporatePolicyViolationCodeId,
                    }),
                    ...(this.dueToConfirmDateActive && {dueToConfirmDate: this.dueToConfirmDate}),
                }

                if (this.isReturnTransfer) {
                    rq.basketKey = this.productStore.prepareBookResponse.basketKey
                } else {
                    rq.bookingKey = this.productStore.prepareBookResponse.bookingKey
                }

                const bookRs = await persistentStore.book(rq)

                if (!persistentStore.selectedOrderId && !authStore.person) {
                    await this.$router.push({
                        name: this.productConfirmationPageName,
                        query: this.productConfirmationPageQuery(bookRs[0].orderId),
                    })
                } else {
                    await this.redirectAfterPayment(
                        bookRs[0].orderId,
                        this.productConfirmationPageName,
                        this.productConfirmationPageQuery(bookRs[0].orderId)
                    )
                    if (persistentStore.selectedOrderId) {
                        persistentStore.SET_SELECTED_ORDER_ID(null)
                    } else if (authStore.isB2B) {
                        EventBus.$emit(RESET_BOOKING_DATA)
                    }
                }
                return true
            } catch (e) {
                this.$toast.error(this.$t('error_message.unknown_booking_error'))
                return false
            }
        }

        productConfirmationPageQuery(orderId) {
            return {orderId, returnTransfer: this.isReturnTransfer}
        }

        initBookingKey() {
            AddOnMixin.options.methods.initBookingKey.call(this)
            if (this.isReturnTransfer) {
                this.basketKey = this.prepareBookResponse.basketKey
            }
        }

        get hasExtraServices() {
            return false
        }

        get productSearchPageName() {
            return 'transfers'
        }

        get productConfirmationPageName() {
            return 'transferConfirmation'
        }

        get productName() {
            return 'transfer'
        }

        get productBookingPageName() {
            return 'transferBooking'
        }

        get corporatePolicyType() {
            return 'transfer'
        }

        get startDate() {
            return this.searchRequest.dateTime
        }
    }
</script>
