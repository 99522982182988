var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":_vm.outlined,"elevation":_vm.elevation,"tile":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.$t('carsRent.pick-up_and_drop-off_points'))+"\n    ")]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[(_vm.pickUpItems.length)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.pickUpItems,"label":_vm.$t('carsRent.pick-up_point'),"rules":[v => !!v || `${_vm.$t('carsRent.pick-up_point')} ${_vm.$t('validation.required')}`],"disabled":_vm.disabled,"dense":""},on:{"input":_vm.setPickUpLocation}}),_vm._v(" "),(_vm.pickUpLocation.address)?_c('div',[_c('v-icon',{attrs:{"color":"primary","left":"","small":""}},[_vm._v("mdi-map-marker")]),_vm._v("\n                        "+_vm._s(_vm.pickUpLocation.address)+"\n                        "),(_vm.pickUpLocation.latitude && _vm.pickUpLocation.longitude)?_c('div',{staticClass:"ms-2 d-inline-block text-caption primary--text text-decoration-underline text-lowercase cursor-pointer",on:{"click":function($event){return _vm.$emit('show-on-map', {
                                    latitude: _vm.pickUpLocation.latitude,
                                    longitude: _vm.pickUpLocation.longitude,
                                })}}},[_vm._v("\n                            "+_vm._s(_vm.$t('show_on_map'))+"\n                        ")]):_vm._e()],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.dropOffItems.length)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.dropOffItems,"label":_vm.$t('carsRent.drop-off_point'),"rules":[
                            v =>
                                !!v ||
                                _vm.supplierCode === 'jonview' ||
                                `${_vm.$t('carsRent.drop-off_point')} ${_vm.$t('validation.required')}`,
                        ],"disabled":_vm.disabled,"dense":""},on:{"input":_vm.setDropOffLocation}}),_vm._v(" "),(_vm.dropOffLocation.address)?_c('div',[_c('v-icon',{attrs:{"color":"primary","left":"","small":""}},[_vm._v("mdi-map-marker")]),_vm._v("\n                        "+_vm._s(_vm.dropOffLocation.address)+"\n                        "),(_vm.dropOffLocation.latitude && _vm.dropOffLocation.longitude)?_c('div',{staticClass:"ms-2 d-inline-block text-caption primary--text text-decoration-underline text-lowercase cursor-pointer",on:{"click":function($event){return _vm.$emit('show-on-map', {
                                    latitude: _vm.dropOffLocation.latitude,
                                    longitude: _vm.dropOffLocation.longitude,
                                })}}},[_vm._v("\n                            "+_vm._s(_vm.$t('show_on_map'))+"\n                        ")]):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }