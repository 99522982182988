<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'
    import HotelCorporatePolicyViolationMixin from '@/mixins/HotelCorporatePolicyViolationMixin'
    import {authStore, hotelsRuntimeStore, hotelsStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'
    import {PRODUCT_NAME} from '@/utils/hotels/hotels-const'
    import {isOwnProduct} from '@/utils/api-helpers'
    import BookingPageMixin from '@/mixins/BookingPageMixin.vue'
    import HotelOrderSummaryMixin from '@/components/hotels/mixins/HotelOrderSummaryMixin.vue'
    import PrepareBookTouristsAdapter from '@/utils/PrepareBookTouristsAdapter'

    @Component
    export default class HotelBookingPageLayout extends mixins(
        BookingPageBase,
        HotelStoreMixin,
        HotelCorporatePolicyViolationMixin,
        HotelOrderSummaryMixin
    ) {
        @Prop({required: true}) offerKeys
        @Prop({required: true}) roomOffers

        async load() {
            this.extraServices = new Array(this.offerKeys.length).fill([])
            await BookingPageMixin.options.methods.load.call(this)
        }

        get tourLead() {
            return hotelsStore.tourists[0] ? hotelsStore.tourists[0][0] : undefined
        }

        set tourLead(val) {
            hotelsStore.SET_TOURIST({val, index: 0, roomIndex: 0})
        }

        clearAdjustedPrice() {
            hotelsStore.prepareBookRequest.forEach((_, i) => {
                this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({
                    prop: 'adjustedPriceAmount',
                    val: null,
                    index: i,
                })
            })
        }

        setTourists() {
            let tourists = clone(hotelsStore.tourists)
            const searchRequestTourists = hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)
            searchRequestTourists.forEach((room, roomIndex) => {
                if (!tourists[roomIndex]) {
                    tourists[roomIndex] = []
                }
                tourists[roomIndex] = this.initTourists(room, tourists[roomIndex], roomIndex)
                tourists[roomIndex] = PrepareBookTouristsAdapter(tourists[roomIndex], this.roomOffers[roomIndex])
            })
            hotelsStore.SET_TOURISTS(tourists.slice(0, searchRequestTourists.length))
        }

        clearCommentsAfterBooking() {
            const allTourists = this.productStore.tourists.length
            for (let i = 0; i < allTourists; i++) {
                this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'comments', val: '', index: i})
            }
        }

        prepareBookRequest(specifiedTouristBookingFields, includeOptionalTourists = false) {
            const addCheckInOut = roomIndex => {
                const sourceExtraService = this.productStore.checkInOutExtraService[roomIndex]
                const {earlyCheckIn, lateCheckOut} = {...sourceExtraService}
                return {
                    ...(lateCheckOut && {
                        lateCheckOut: {
                            serviceRPH: lateCheckOut.serviceRPH,
                            time: lateCheckOut.time,
                            required: false,
                        },
                    }),
                    ...(earlyCheckIn && {
                        earlyCheckIn: {
                            serviceRPH: earlyCheckIn.serviceRPH,
                            time: earlyCheckIn.time,
                            required: false,
                        },
                    }),
                }
            }
            return this.tourists.map((tourists, roomIndex) => {
                const resultCheckInOutService = addCheckInOut(roomIndex)
                const rq = {
                    tourists: this.prepareBookTourists(
                        tourists,
                        specifiedTouristBookingFields,
                        includeOptionalTourists
                    ),
                    offerKey: this.offerKeys[roomIndex],
                    ...resultCheckInOutService,
                }
                if (authStore.isB2C) {
                    const person = authStore.person || authStore.registeredPerson
                    rq.personId = person.personId
                } else if (this.selectedPersonId) {
                    rq.personId = this.selectedPersonId
                }
                if (persistentStore.selectedOrderId) {
                    rq.orderId = persistentStore.selectedOrderId
                }
                if (hotelsStore.prepareBookRequest[roomIndex] && hotelsStore.prepareBookRequest[roomIndex].comments) {
                    rq.comments = hotelsStore.prepareBookRequest[roomIndex].comments
                }
                if (this.extraServices[roomIndex]?.length) {
                    rq.extraServices = this.extraServices[roomIndex]
                }
                if (
                    hotelsStore.prepareBookRequest[roomIndex] &&
                    hotelsStore.prepareBookRequest[roomIndex].adjustedPriceAmount
                ) {
                    rq.adjustedPriceAmount = hotelsStore.prepareBookRequest[roomIndex].adjustedPriceAmount
                }
                return rq
            })
        }

        productBookingPageQuery(paymentStatus) {
            return {
                paymentStatus,
                offerKey: this.offerKeys,
            }
        }

        get tourists() {
            return hotelsStore.tourists.map((room, roomIndex) => {
                const roomTourists =
                    runtimeStore.touristBookingFields.supplierConfigDetails?.guestsInformationRequiredType ===
                    'ONLY_TOURLEAD'
                        ? room.slice(0, 1)
                        : room
                return roomTourists.map(
                    (tourist, index) =>
                        new Proxy(tourist, {
                            set: (target, prop, val) => {
                                hotelsStore.SET_TOURIST_PROP({prop, val, roomIndex, index})
                                return true
                            },
                            get: (target, prop) => {
                                return hotelsStore.tourists[roomIndex][index][prop]
                            },
                        })
                )
            })
        }

        get productSearchPageName() {
            return 'hotels'
        }

        get productConfirmationPageName() {
            return 'hotelsConfirmation'
        }

        get productName() {
            return PRODUCT_NAME
        }

        get productBookingPageName() {
            return 'hotelBooking'
        }

        get corporatePolicyType() {
            return 'accommodation'
        }

        get countryId() {
            return hotelsRuntimeStore.city.countryId
        }

        get showAddOns() {
            return isOwnProduct(this.offer.supplierCode)
        }
    }
</script>
