<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {flightsRuntimeStore, flightsStore} from '@/store'
    import {PRODUCT_NAME} from '@/utils/flights/flights-const'

    @Component
    export default class FlightStoreMixin extends Vue {
        get productStore() {
            return flightsStore
        }

        get productRuntimeStore() {
            return flightsRuntimeStore
        }

        get productType() {
            return 'flight'
        }

        get productName() {
            return PRODUCT_NAME
        }
    }
</script>
