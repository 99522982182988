<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import PackagesStoreMixin from '@/mixins/PackagesStoreMixin'
    import {packagesStore} from '@/utils/store-accessor'

    @Component
    export default class PackageBookingPageLayout extends mixins(BookingPageBase, PackagesStoreMixin) {
        clearCommentsAfterBooking() {}

        get productSearchPageName() {
            return 'packages'
        }

        get productConfirmationPageName() {
            return 'packageConfirmation'
        }

        get productName() {
            return 'package'
        }

        get productBookingPageName() {
            return 'packageBooking'
        }

        get corporatePolicyType() {
            return 'package'
        }

        get corporatePolicyViolated() {
            return packagesStore.prepareBookResponse && packagesStore.prepareBookResponse.corporatePolicyViolation
                ? packagesStore.prepareBookResponse.corporatePolicyViolation
                : false
        }

        get violatedPolicies() {
            return this.productStore.prepareBookResponse ? this.productStore.prepareBookResponse.corporatePolicies : []
        }
    }
</script>
