<template>
    <v-dialog v-model="display" max-width="1000">
        <v-card>
            <v-card-title />
            <v-card-text class="text-center" v-html="text" />
            <v-card-actions class="justify-center">
                <v-btn depressed @click="display = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_NOTIFICATION_MODAL} from '@/utils/event-bus'

    @Component
    export default class NotificationModal extends Vue {
        display = false
        text = null

        mounted() {
            EventBus.$on(SHOW_NOTIFICATION_MODAL, this.show)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_NOTIFICATION_MODAL, this.show)
        }

        show(text) {
            this.text = text
            this.display = true
        }
    }
</script>
