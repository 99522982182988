<template>
    <div>
        <n-link no-prefetch :to="{name: 'orders'}" class="d-block text-right">
            <strong>{{ $t('full_view') }}</strong>
        </n-link>
        <v-data-table
            :headers="headers"
            :items="services"
            :loading="isLoading"
            disable-sort
            :no-data-text="noDataText"
            class="elevation-1"
            :hide-default-footer="true"
        >
            <template v-slot:item.orderId="{item}">
                <div>
                    <n-link no-prefetch :to="{name: 'order-details', params: {id: item.orderId}}">
                        <strong>{{ item.orderId }}</strong>
                    </n-link>
                </div>
            </template>
            <template v-slot:item.created="{item}">
                <div class="text-no-wrap" v-html="item.created" />
            </template>
            <template v-slot:item.title="{item}">
                <div class="d-flex align-start">
                    <v-icon small class="mr-1">
                        {{ getServiceTypeIcon(item.serviceType) }}
                    </v-icon>
                    <div v-html="item.title + item.location" />
                </div>
            </template>
            <template v-slot:item.date="{item}">
                <div class="text-no-wrap" v-html="item.date" />
            </template>
            <template v-slot:item.travellers="{item}">
                <span v-html="item.travellers" />
            </template>
            <template v-slot:item.status="{item}">
                <span :class="item.statusColor">{{ item.statusText }}</span>
                <div v-if="isTO1">
                    <span class="commission">
                        {{ item.supplierCompanyName }}
                    </span>
                </div>
            </template>
            <template v-slot:item.supplierPrice="{item}">
                <span v-if="item.supplier.price">
                    {{ item.supplier.price.amount | priceFormat(item.supplier.price.currency) }}
                </span>
                <payment-status-label
                    :status="item.supplier.paymentStatus"
                    :card-guarantee="item.supplier.cardGuarantee"
                    class="text-no-wrap"
                />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import Orders from '~src/components/account/orders.src'
    import {runtimeStore, supplierStore} from '@/utils/store-accessor'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'

    @Component({
        components: {PaymentStatusLabel},
    })
    export default class ShortOrders extends Orders {
        load() {
            return false
        }

        get services() {
            const orderServices = []
            const orders = supplierStore.orders
                .slice(0, 5)
                .concat()
                .sort((o1, o2) =>
                    this.$dateFns.isAfter(this.$dateFns.parseISO(o1.modified), this.$dateFns.parseISO(o2.modified))
                        ? -1
                        : 1
                )
            const travallersLabel = service => {
                if (!service || !service.travelers || !service.travelers.length) return '-'
                let travellers = `${service.travelers[0].prefix} ${service.travelers[0].name[0].firstName} ${service.travelers[0].name[0].lastName}`
                if (service.travelers.length > 1) {
                    travellers += ` (+${service.travelers.length - 1})`
                }
                return travellers
            }
            orders.forEach(order => {
                if (order.orderPackage) {
                    orderServices.push({
                        orderId: order.orderId,
                        created: this.$options.filters.dateShortFormat(order.created),
                        clientCompanyName: order.clientCompanyName,
                        title: `<strong>${order.orderPackage.packageName}</strong><br>`,
                        serviceType: order.orderPackage.packageType,
                        location: '',
                        date: `${this.$options.filters.dateShortFormat(
                            order.orderPackage.startDateTime
                        )}<br>${this.$options.filters.dateShortFormat(order.orderPackage.endDateTime)}`,
                        travellers: travallersLabel(order.services[0]),
                        status: order.orderPackage.status,
                        statusText: this.$t(
                            `bookingStatus.${runtimeStore.orderServiceStatus(order.orderPackage.status)}`
                        ),
                        statusColor: runtimeStore.orderServiceStatusColor(
                            runtimeStore.orderServiceStatus(order.orderPackage.status)
                        ),
                        supplierCompanyName: order.orderPackage.supplierCompanyName,
                        supplier: {
                            paymentStatus: order.orderPackage.supplierPaymentStatus,
                            price: order.orderPackage.supplierPrice,
                        },
                        customer: {
                            paymentStatus: order.orderPackage.customerPaymentStatus,
                            price: order.orderPackage.clientPrice,
                        },
                        violation: null,
                        corporatePolicies: [],
                    })
                    return
                }
                order.services.forEach(service => {
                    let location = '',
                        title = `<strong>${service.serviceName}</strong><br>`,
                        date = this.$options.filters.dateShortFormat(service.startDateTime),
                        created = this.$options.filters.dateShortFormat(service.created),
                        countryName,
                        cityName
                    //const transferPlace = place => place.address || place.airportCode || ''
                    switch (service.serviceType) {
                        case 'ACCOMMODATION':
                            service.serviceDetailsContainer.accommodation.rooms.forEach(room => {
                                title += `${room.roomTypeName}, ${room.mealTypeName}<br>`
                            })
                            countryName = service.serviceDetailsContainer.accommodation.countryName
                                ? service.serviceDetailsContainer.accommodation.countryName
                                : ''
                            cityName = service.serviceDetailsContainer.accommodation.cityName
                                ? service.serviceDetailsContainer.accommodation.cityName
                                : ''
                            location = []
                            if (countryName) {
                                location.push(countryName)
                            }
                            if (cityName) {
                                location.push(cityName)
                            }
                            location = location.join(', ')
                            date += '<br>' + this.$options.filters.dateShortFormat(service.endDateTime)
                            break
                        case 'FLIGHT':
                            location = service.serviceDetailsContainer.flight.flightName
                            break
                        case 'ACTIVITY':
                            location = service.serviceDetailsContainer.activity.location
                            break
                        case 'TRANSFER':
                            /*console.log(service.serviceDetailsContainer.transfer)
                if (service.serviceDetailsContainer.transfer.departurePlace) {
                    location = `${transferPlace(
                        service.serviceDetailsContainer.transfer.departurePlace
                    )} - `
                } else {
                    location = ''
                }
                if (service.serviceDetailsContainer.transfer.departurePlace) {
                    location += `${transferPlace(service.serviceDetailsContainer.transfer.arrivalPlace)}`
                }*/
                            break
                    }
                    const orderService = {
                        orderId: order.orderId,
                        created: created,
                        clientCompanyName: order.clientCompanyName,
                        title,
                        serviceType: service.serviceType,
                        location,
                        date,
                        travellers: travallersLabel(service),
                        status: service.status,
                        statusText: this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(service.status)}`),
                        statusColor: runtimeStore.orderServiceStatusColor(
                            runtimeStore.orderServiceStatus(service.status)
                        ),
                        supplierCompanyName: service.supplierCompanyName,
                        supplier: {
                            paymentStatus: service.supplierPaymentStatus,
                            price: service.salesTerms.find(salesTerm => salesTerm.type === 'SUPPLIER').price,
                        },
                        customer: {
                            paymentStatus: service.customerPaymentStatus,
                            price: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price,
                        },
                        violation: service.violation,
                        corporatePolicies: service.corporatePolicies || [],
                    }
                    orderServices.push(orderService)
                })
            })

            return orderServices
        }

        get headers() {
            return [
                {text: this.$t('order'), value: 'orderId'},
                {text: this.$t('created_date'), value: 'created'},
                {text: this.$t('status'), value: 'status'},
                {text: this.$t('order_service'), value: 'title'},
                {text: this.$t('dates'), value: 'date', align: 'center'},
                {text: this.$t('order_travelers'), value: 'travellers'},
                {text: this.$t('price'), value: 'supplierPrice'},
            ]
        }
    }
</script>
