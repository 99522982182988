<template>
    <div
        v-if="currencies.length > 1"
        class="currency-switcher"
        :class="$breakpoint.mdAndUp ? 'ma-2' : 'full-width mx-3'"
    >
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            offset-y
            max-height="200"
            content-class="currency-switcher-menu"
        >
            <template v-slot:activator="{on}">
                <v-btn text :class="isMobile ? 'pa-0' : ''" v-on="on">
                    {{ currency }}
                    <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item
                    v-for="currencyCode in currencies"
                    :key="currencyCode"
                    @click="switchCurrency(currencyCode)"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ currencyCode }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore} from '@/store'

    @Component
    export default class CurrencySwitcher extends Vue {
        menu = false

        switchCurrency(currencyCode) {
            this.menu = false
            persistentStore.SET_CURRENCY(currencyCode)
        }

        get currencies() {
            return Object.values(this.$config.currency.available).filter(currency =>
                Object.keys(runtimeStore.exchangeRates).includes(currency)
            )
        }

        get currency() {
            return persistentStore.currency
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
