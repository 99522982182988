<template>
    <div v-if="images.length" :class="!!nextImages.length ? 'wrapper' : 'd-flex justify-end'">
        <div :class="{main: !!nextImages.length}">
            <v-img
                v-if="firstImage.url"
                tile
                :src="firstImage.url | imageUrl(250)"
                :lazy-src="'/placeholder.png' | srcHost"
                eager
                height="100%"
                :max-width="!nextImages.length ? 200 : 'auto'"
                @click="showPhotoSwipe(0)"
            />
        </div>
        <div v-if="nextImages.length" class="imgs">
            <v-img
                v-for="(image, index) in nextImages"
                :key="index"
                tile
                :src="image.url | imageUrl(250)"
                :lazy-src="'/placeholder.png' | srcHost"
                eager
                height="100%"
                @click="showPhotoSwipe(index + 1)"
            />
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop, Emit} from 'nuxt-property-decorator'

    @Component
    export default class OrderItineraryDayImages extends Vue {
        @Prop({required: true}) images

        @Emit()
        showPhotoSwipe(index) {
            return {images: this.images, index}
        }

        get firstImage() {
            return this.images[0]
        }

        get nextImages() {
            return this.images.slice(1, 5)
        }
    }
</script>

<style scoped lang="scss">
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 4px 4px;
        grid-auto-flow: row;
        grid-template-areas:
            'main main imgs imgs'
            'main main imgs imgs';
    }

    .main {
        grid-area: main;
    }

    .imgs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 4px 4px;
        grid-auto-flow: row;
        grid-template-areas:
            '. .'
            '. .';
        grid-area: imgs;
    }
</style>
