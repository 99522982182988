<template>
    <v-card class="my-2" :elevation="!fullCard ? 0 : undefined" :outlined="fullCard">
        <v-list-item three-line>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <v-list-item-title class="wrap-words">
                    <div
                        class="wrap-words d-flex align-center text-subtitle-1 font-weight-bold cursor-pointer mb-1"
                        @click="offerInfo()"
                    >
                        <v-icon small left>mdi-information-outline</v-icon>
                        <span>
                            {{ `${offer.serviceName} / ${offer.categoryName}` }}
                        </span>
                    </div>
                    <div v-if="offer.available" class="d-flex align-center caption">
                        <v-icon :title="$t('available')" left small class="success--text">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <span class="success--text" v-text="$t('available')" />
                    </div>
                    <div v-else class="d-flex align-center caption">
                        <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                        <span class="warning--text" v-text="$t('on_request')" />
                    </div>
                    <cruise-meal-type-label v-if="offer.mealType" :offer="offer" />
                    <div class="d-flex align-center caption">
                        <v-icon small left>mdi-calendar</v-icon>
                        <span>
                            {{ `${$options.filters.dateFormat(offer.startDate)}` }}
                        </span>
                    </div>
                    <discount-label v-if="offer.price" :price="offer.price" class="d-inline-block" />
                    <span v-if="offer && offer.price" class="caption">
                        <conditions-label
                            :offer-key="offer.offerKey"
                            :price="offer.price"
                            @conditions="showConditionsInfo"
                        />
                    </span>
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center d-flex flex-column align-center">
                <slot name="price" :offer="offer">
                    <supplier-price v-if="!isAgency" :supplier-price="offer.supplierPrice" />
                    <v-btn
                        depressed
                        nuxt
                        color="primary"
                        small
                        no-prefetch
                        block
                        height="36px"
                        max-height="36px"
                        :loading="!info.shipName"
                        :disabled="!info.shipName"
                        @click="selectForBooking"
                    >
                        {{ offer.price | price }}
                    </v-btn>
                    <price-note :search-request="searchRequest" />
                    <commission-amount :commission="offer.price.commission" />
                </slot>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_CRUISE_CONDITIONS_INFO, SHOW_CRUISE_OFFER_INFO_MODAL} from '@/utils/event-bus'
    import {authStore, cruisesStore} from '~/store'
    import PriceNote from '@/components/snippets/PriceNote'
    import CruiseMealTypeLabel from '@/components/hotels/snippets/CruiseMealTypeLabel'

    @Component({
        components: {
            CruiseMealTypeLabel,
            PriceNote,
            CommissionAmount,
            DiscountLabel,
            ConditionsLabel,
            SupplierPrice: () => import('~src/components/snippets/supplierPrice.src'),
        },
    })
    export default class CruiseOfferCard extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true}) fullCard

        imgError = false
        info = {
            images: [],
        }

        async mounted() {
            try {
                this.info = await this.$api.cruiseInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard) {
                    this.$emit('info', this.info)
                }
                if (this.fullCard && this.info.images) {
                    this.$emit('images', this.info.images)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            cruisesStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
            this.$router.push({
                name: 'cruiseBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        offerInfo() {
            EventBus.$emit(SHOW_CRUISE_OFFER_INFO_MODAL, {offer: this.offer})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_CRUISE_CONDITIONS_INFO, this.offer)
        }

        get mainImage() {
            let mainImage = this.info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images.length > 0) {
                mainImage = this.info.images[0]
            }
            return mainImage
        }

        get searchRequest() {
            return cruisesStore.searchRequest
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
