<template>
    <v-dialog v-model="modal" width="800px" max-width="100%">
        <v-card v-if="info" width="800px" max-width="100%" class="pa-md-3">
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-text class="black--text">
                <h2>
                    {{ info.serviceName }}
                </h2>
                <v-tabs v-if="(info.images && info.images.length) || info.serviceDescription || info.customFields">
                    <v-tab>
                        {{ $t('description') }}
                    </v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-img
                                    class="thumb"
                                    :src="image | imageUrl(600)"
                                    :lazy-src="'/placeholder.png' | srcHost"
                                    @error="onImgError"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            v-show="!imgError && image"
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular indeterminate color="grey lighten-5" />
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-col>
                            <v-col cols="12" md="8">
                                <div v-if="info.serviceDescription">
                                    <strong>{{ $t('description') }} </strong><br />
                                    <div v-html="info.serviceDescription" />
                                </div>
                                <ul>
                                    <li v-for="(field, key) in info.customFields" :key="key">{{ key }}: {{ field }}</li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_EXTRA_SERVICE_INFO_MODAL} from '@/utils/event-bus'

    @Component({})
    export default class ExtraServiceInfoModal extends Vue {
        info = null
        offer = null
        modal = false
        conditionsInfo = {}
        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        created() {
            EventBus.$on(SHOW_EXTRA_SERVICE_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_EXTRA_SERVICE_INFO_MODAL, this.showModal)
        }

        showModal({offer, info}) {
            this.offer = offer
            this.info = info
            this.modal = true
        }

        get image() {
            if (!this.info.images) return null
            let mainImage = this.info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images.length > 0) {
                mainImage = this.info.images[0]
            }
            if (
                !mainImage.linkedEntityName ||
                (mainImage.linkedEntityName && mainImage.linkedEntityName !== this.info.serviceName)
            ) {
                mainImage =
                    this.info.images.find(image => image.linkedEntityName === this.info.serviceName) || mainImage
            }
            return mainImage && mainImage.url ? mainImage.url : null
        }
    }
</script>
