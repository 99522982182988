<template>
    <v-dialog v-model="isShowAddReturnTransferDialog" width="900" hide-overlay>
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span>
                    {{ $t('add_transfer_return') }}
                </span>
                <v-icon @click="close">mdi-close</v-icon>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col>
                        <date-picker v-model="returnTransferDate" :outlined="true" :min="searchRequest.dateTime" />
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block x-large @click="searchReturnTransfer">
                            {{ $t('modify.check_availability') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <transfer-offers
                    v-if="returnTransferOffers"
                    :offers="returnTransferOffers"
                    :is-return-transfer="true"
                    @select-transfer-offer="setReturnTransferOfferSelected"
                />
                <v-skeleton-loader
                    v-if="returnTransferSearchActive"
                    transition="fade-transition"
                    height="100"
                    type="paragraph"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import TransferOffers from '@/components/transfers/search/offers/TransferOffers'
    import {transfersRuntimeStore, transfersStore} from '@/utils/store-accessor'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {format, parseISO} from 'date-fns'

    @Component({
        components: {TransferOffers, DatePicker},
    })
    export default class TransferReturnDialog extends Vue {
        @VModel() isShowAddReturnTransferDialog
        @Prop() order
        @Prop() selectedTransfer

        returnTransferOffers = null
        returnTransferSearchActive = false
        returnTransferDate = null
        returnTransferOfferSelected = null
        searchRequestInverted = null
        info = null

        @Emit()
        onReturnTransferChange() {
            return this.returnTransferOfferSelected
        }

        @Emit()
        onSearchRequestInvertedChange() {
            return this.searchRequestInverted
        }

        @Emit()
        onInfoChanged() {
            return this.info
        }

        @Watch('returnTransferOfferSelected')
        returnTransferChanged() {
            this.onReturnTransferChange()
        }

        @Watch('searchRequestInverted')
        searchRequestInvertedChanged() {
            this.onSearchRequestInvertedChange()
        }

        @Watch('info')
        infoChanged() {
            this.onInfoChanged()
        }

        mounted() {
            const accommodationService = this.order.services?.find(service => service.serviceType === 'ACCOMMODATION')

            if (accommodationService) {
                this.returnTransferDate = format(parseISO(accommodationService.endDateTime), 'yyyy-MM-dd')
            } else this.returnTransferDate = this.searchRequest.dateTime
        }

        async searchReturnTransfer() {
            this.returnTransferSearchActive = true
            this.returnTransferOffers = null

            this.searchRequestInverted = {
                ...this.searchRequest,
                arrivalCityId: this.searchRequest.departureCityId,
                arrivalCountryId: this.searchRequest.departureCountryId,
                departureCityId: this.searchRequest.arrivalCityId,
                departureCountryId: this.searchRequest.arrivalCountryId,
                dateTime: this.returnTransferDate,
                departurePlace: this.searchRequest.arrivalPlace,
                arrivalPlace: this.searchRequest.departurePlace,
                partialResponse: false,
                arrivalHotelCode: this.searchRequest.departureHotelCode,
                departureHotelCode: this.searchRequest.arrivalHotelCode,
            }

            delete this.searchRequestInverted.departureHotelName
            delete this.searchRequestInverted.arrivalHotelName

            for (let key in this.searchRequestInverted) {
                if (this.searchRequestInverted[key] === undefined) {
                    delete this.searchRequestInverted[key]
                }
            }

            const response = (await this.$api.searchTransfer.get(this.searchRequestInverted)).product.filter(
                product => product.info.supplierCode === this.basketItem.product.info.supplierCode
            )
            const filteredProducts = response.reduce((filteredProducts, product) => {
                const offers = product.offers.filter(offer => offer.transferType === this.selectedTransfer.transferType)
                if (offers.length > 0) filteredProducts.push({info: product.info, offers})

                return filteredProducts
            }, [])
            transfersRuntimeStore.SET_OFFERS(response)

            const offersArray = filteredProducts?.reduce((arr, item) => {
                arr.push(...item.offers)
                return arr
            }, [])

            if (!offersArray || offersArray?.length === 0) {
                this.$toast.warning(this.$t('return_transfer_warning'))
                this.close()
            } else if (offersArray?.length === 1) {
                await this.setReturnTransferOfferSelected(offersArray[0])
            } else {
                this.returnTransferOffers = filteredProducts
            }

            this.returnTransferSearchActive = false
        }

        async setReturnTransferOfferSelected(offer) {
            transfersStore.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({
                prop: 'offerKey',
                val: offer.offerKey,
            })

            try {
                await Promise.all([
                    transfersStore.loadBookingAdditionalOptions(offer.offerKey),
                    (async () => {
                        this.info = await this.$api.transferInfo.get({offerKey: offer.offerKey})
                    })(),
                ])
            } catch (e) {
                this.$nuxt.error({statusCode: 404})
            }

            transfersStore.ADD_TO_BASKET({info: this.info, offerKey: offer.offerKey})

            this.returnTransferOfferSelected = offer

            this.$nextTick(() => {
                this.close()
            })
        }

        @Emit()
        close() {
            this.isShowAddReturnTransferDialog = false
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get basketItem() {
            return transfersStore.basketItem(this.$route.query.offerKey)
        }

        get searchKey() {
            return transfersStore.searchResponse.searchKey
        }
    }
</script>
