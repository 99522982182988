<template>
    <v-list-item ref="infoWindow" three-line class="pl-0">
        <div v-if="infoLoading">
            {{ $t('loading') }}
            <v-progress-linear indeterminate color="primary" />
        </div>
        <div v-else class="align-self-start" :class="isCompactMode ? 'small-content' : ''">
            <v-list-item-title :title="pointInfo.name" class="mb-1">
                <span class="text-wrap">{{ pointInfo.name }}</span>
            </v-list-item-title>
            <v-list-item-content class="mb-2">
                <div v-if="pointInfo.address" class="text-wrap">
                    <v-icon small>mdi-map-marker</v-icon>
                    <span v-if="pointInfo.pointType === 'pickup'">
                        {{ `${$t('carsRent.pick-up_address')}:` }}
                    </span>
                    <span v-else-if="pointInfo.pointType === 'return'">
                        {{ `${$t('carsRent.drop-off_address')}:` }}
                    </span>
                    {{ pointInfo.address }}
                </div>
                <div v-if="pointInfo.workingHoursAsString" class="text-wrap">
                    <v-icon small>mdi-clock-outline</v-icon>
                    {{ pointInfo.workingHoursAsString }}
                </div>
                <div v-if="pointInfo.phones" class="text-wrap">
                    <v-icon small>mdi-cellphone</v-icon>
                    {{ pointInfo.phones.join(', ') }}
                </div>
            </v-list-item-content>
            <div v-if="propData.offerKey" class="text-right">
                <v-btn color="primary" x-small @click="changeFilter">
                    {{ $t('carsRent.show_cars') }}
                </v-btn>
            </div>
        </div>
    </v-list-item>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {carsRentStore, carsRentRuntimeStore} from '~/store'

    @Component
    export default class CarMapInfoWindow extends Vue {
        @Prop() propData

        imgError = false
        clientWidth = 201
        infoLoading = true
        pointInfo = {}

        async mounted() {
            if (!this.propData.pointType) {
                try {
                    this.pointInfo = await this.$api.pointInfo.get({
                        offerKey: this.propData.offerKey,
                        pointType: 'pickup',
                        locationCode: this.propData.locationCode,
                    })
                } catch (e) {
                    this.pointInfo = this.propData
                }
            } else this.pointInfo = this.propData

            this.infoLoading = false
        }

        load() {
            this.setSize()
        }

        onImgError() {
            this.imgError = true
            return false
        }

        setSize() {
            this.clientWidth = this.$refs.infoWindow.$el.clientWidth
        }

        changeFilter() {
            carsRentStore.SET_FILTER({
                key: 'pickUpPoints',
                value: [this.propData.name],
            })
            carsRentRuntimeStore.filter()
        }

        get isCompactMode() {
            return this.clientWidth < 200
        }
    }
</script>

<style scoped>
    .small-content {
        font-size: 0.8rem !important;
    }
</style>
