<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="filteredOffers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <activity-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <activity-search-summary :search-request="searchRequest" show-dates />
            <sort-select v-model="sortKey" :items="sortItems" />
            <group-offers-select class="pt-3" :items="['none', 'serviceName']" :product-store="productStore" />
            <activity-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.product.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <activity-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <activity-conditions-modal-info />
                </template>
            </conditions-modal>
            <activity-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <activity-map :_expanded.sync="mapExpanded" :offers="mapPoints" :city="city" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'
    import ActivityOffers from '@/components/activities/search/offers/ActivityOffers'
    import ActivityFilters from '@/components/activities/search/ActivityFilters'
    import ActivitySearchSummary from '@/components/activities/search/ActivitySearchSummary'
    import ActivityMap from '@/components/activities/search/ActivityMap'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import ActivitiesStoreMixin from '@/mixins/ActivityStoreMixin'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '@/components/search/SortSelect'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo'
    import GroupOffersSelect from '@/components/search/GroupOffersSelect'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import Tmp_SearchPageBase from '@/mixins/Tmp_SearchPageBase'

    @Component({
        components: {
            ActivityConditionsModalInfo,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            ActivityOffers,
            Pagination,
            MobileSearchPageTabs,
            ActivityBreadcrumbs,
            ActivityFilters,
            ActivitySearchSummary,
            ActivityMap,
            ActivityInfoModal,
            ConditionsModal,
            SearchLayout,
            GroupOffersSelect,
        },
        layout: 'blank',
    })
    export default class ActivitiesPage extends mixins(
        ActivityPageMixin,
        ActivitiesStoreMixin,
        SearchPageBase,
        Tmp_SearchPageBase,
        SearchPageMixin
    ) {
        validate({query}) {
            return query.cityId
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        get mapPoints() {
            return this.filteredOffers.reduce((departures, product) => {
                product.offers.forEach(offer => {
                    if (offer.pickUpPoints.length) {
                        offer.pickUpPoints.forEach(point => {
                            if (
                                point.latitude &&
                                point.longitude &&
                                !departures.some(
                                    item => item.latitude === point.latitude && item.longitude === point.longitude
                                )
                            ) {
                                const data = {
                                    pointName: point.name,
                                    startTimes: offer.startTimes,
                                    latitude: point.latitude,
                                    longitude: point.longitude,
                                    name: product.info.name,
                                }
                                departures.push(data)
                            }
                        })
                    }
                })

                return departures
            }, [])
        }

        get city() {
            return this.productRuntimeStore.city
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.recommended'),
                    value: 'recommended',
                },
                {
                    text: this.$t('sort.favorites'),
                    value: 'favorites',
                },
                ...Tmp_SearchPageBase.options.computed.sortItems.get.call(this),
            ]
        }
    }
</script>
