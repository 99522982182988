<template>
    <search-summary-layout :search-disabled="searchDisabled" form-name="cruises-search-form">
        <template v-slot:title>
            {{ locationLabel }}
        </template>
        <template v-slot:content>
            <div>
                {{ dates }}
            </div>
            <div>
                {{ touristsTotal }}
                {{ $tc('guest', touristsTotal) }}
            </div>
        </template>
    </search-summary-layout>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {cruisesRuntimeStore} from '~/store'
    import SearchSummaryLayout from '~src/components/parts/searchSummaryLayout.src'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            SearchSummaryLayout,
        },
    })
    export default class CruisesSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest
        @Prop({required: true}) dates

        searchForm = false

        @Watch('isSearchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }

        get searchFormComponent() {
            return 'cruises-search-form'
        }

        get isSearchActive() {
            return cruisesRuntimeStore.searchActive
        }

        get locationLabel() {
            return cruisesRuntimeStore.departureCity?.name
                ? `${cruisesRuntimeStore.departureCity?.name}, ${cruisesRuntimeStore.departureCity?.countryName} - ${cruisesRuntimeStore.destinationCity.name}, ${cruisesRuntimeStore.destinationCity.countryName}`
                : `${cruisesRuntimeStore.destinationCity.name}, ${cruisesRuntimeStore.destinationCity.countryName}`
        }

        get touristsTotal() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.length
        }

        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        checkDisabled(on) {
            if (this.searchDisabled) return
            return on
        }
    }
</script>
