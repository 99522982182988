<template>
    <v-row v-if="earlyCheckIn || lateCheckOut">
        <v-col cols="12">
            <h4 class="text-subtitle-1 font-weight-bold mb-2">
                {{ $t('earlyCheckInTitle') + '/' + $t('lateCheckOutTitle') }}
            </h4>
            <div class="d-flex">
                <div v-if="checkInFrom || checkInTo" class="d-flex">
                    <div class="font-weight-bold me-1">
                        {{ `${$t('earlyCheckInTitle')}:` }}
                    </div>
                    <div v-if="checkInFrom" class="mx-1">
                        {{ `${$t('time_from')} ${checkInFrom}` }}
                    </div>
                    <div v-if="checkInTo" class="mx-1">
                        {{ `${$t('time_until')} ${checkInTo}` }}
                    </div>
                </div>
                <div v-if="checkOutFrom || checkOutTo" class="ml-4 d-flex">
                    <div class="font-weight-bold me-1">
                        {{ `${$t('lateCheckOutTitle')}:` }}
                    </div>
                    <div v-if="checkOutFrom" class="mx-1">
                        {{ `${$t('time_from')} ${checkOutFrom}` }}
                    </div>
                    <div v-if="checkOutTo" class="mx-1">
                        {{ `${$t('time_until')} ${checkOutTo}` }}
                    </div>
                </div>
            </div>
        </v-col>
        <v-col v-if="earlyCheckIn" cols="12" md="6">
            <v-row>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="accommodationRequest.earlyCheckIn.time"
                        :disabled="disabled"
                        :items="earlyCheckIn"
                        :label="$t('earlyCheckIn')"
                        clearable
                        dense
                        item-text="time"
                        item-value="time"
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="accommodationRequest.earlyCheckIn.nettoPriceValue"
                        :label="$t('netto')"
                        :prefix="supplierCurrency"
                        :rules="[positiveNumberRule('price')]"
                        dense
                        outlined
                        type="number"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="accommodationRequest.earlyCheckIn.bruttoPriceValue"
                        :label="$t('brutto')"
                        :prefix="clientCurrency"
                        :rules="[positiveNumberRule('price')]"
                        dense
                        outlined
                        type="number"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col v-if="lateCheckOut" cols="12" md="6">
            <v-row>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="accommodationRequest.lateCheckOut.time"
                        :disabled="disabled"
                        :items="lateCheckOut"
                        :label="$t('lateCheckOut')"
                        clearable
                        dense
                        item-text="time"
                        item-value="time"
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="accommodationRequest.lateCheckOut.nettoPriceValue"
                        :label="$t('netto')"
                        :prefix="supplierCurrency"
                        :rules="[positiveNumberRule('price')]"
                        dense
                        outlined
                        type="number"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="accommodationRequest.lateCheckOut.bruttoPriceValue"
                        :label="$t('brutto')"
                        :prefix="clientCurrency"
                        :rules="[positiveNumberRule('price')]"
                        dense
                        outlined
                        type="number"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, VModel} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin.vue'

    @Component
    export default class ModifyManualCheckInOut extends FormValidationRulesMixin {
        @VModel() accommodationRequest
        @Prop({required: true}) additionalOptions
        @Prop({required: true}) hotelInfo
        @Prop({default: false, type: Boolean}) disabled

        get earlyCheckIn() {
            return this.additionalOptions.earlyCheckIn
        }

        get lateCheckOut() {
            return this.additionalOptions.lateCheckOut
        }

        get checkInFrom() {
            return this.hotelInfo.checkInFrom
        }

        get checkInTo() {
            return this.hotelInfo.checkInTo
        }

        get checkOutFrom() {
            return this.hotelInfo.checkOutFrom
        }

        get checkOutTo() {
            return this.hotelInfo.checkOutTo
        }

        get clientCurrency() {
            return (this.earlyCheckIn[0] || this.lateCheckOut[0])?.price.currency
        }

        get supplierCurrency() {
            return (this.earlyCheckIn[0] || this.lateCheckOut[0])?.price.currency
        }
    }
</script>
