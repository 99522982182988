<template>
    <div>
        <span v-if="item.supplier.price">
            {{ item.supplier.price.amount | priceFormat(item.supplier.price.currency) }}
            <span v-if="showSystemCurrency && currency !== item.currency">/ {{ item.supplier.price | price }}</span>
        </span>
        <template v-if="(isAgency || isTourOperator) && item.supplier.price.commission && commissionAndNettoCheckbox">
            <div class="commission grey--text">
                {{ $t('commission') }}:
                {{ item.supplier.price.commission | priceFormat(item.supplier.price.currency) }}
            </div>
            <div class="commission grey--text">
                {{ $t('net_amount') }}:
                {{
                    (item.supplier.price.amount - item.supplier.price.commission)
                        | priceFormat(item.supplier.price.currency)
                }}
            </div>
        </template>
        <payment-status-label
            v-if="showPaymentStatus && !$config.account.paymentScheduler"
            :status="item.supplier.paymentStatus"
            :card-guarantee="item.supplier.cardGuarantee"
            class="text-no-wrap"
        />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'
    import {authStore, persistentStore} from '@/utils/store-accessor'

    @Component({
        components: {PaymentStatusLabel},
    })
    export default class OrdersSupplierPriceLabel extends Vue {
        @Prop({required: true}) item
        @Prop({default: true, type: Boolean}) showPaymentStatus
        @Prop({default: false}) showSystemCurrency

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        get currency() {
            return persistentStore.currency
        }
    }
</script>

<style scoped lang="scss">
    .commission {
        font-size: 10px;
    }
</style>
