<template>
    <v-sheet dark color="rgba(0,0,0,0.87)" width="300" class="d-flex flex-column align-start justify-center pa-4">
        <span class="text-h6"> {{ $t('cookies') }} </span>
        <span class="caption my-2"> {{ $t('cookies_alert') }} </span>
        <v-btn color="success caption" elevation="0" @click="closeDialog">
            <span> {{ $t('understood') }} </span>
        </v-btn>
    </v-sheet>
</template>

<script>
    import {Component, Emit, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class CookiesAlert extends Vue {
        @VModel() showDialog

        @Emit()
        closeDialog() {}
    }
</script>
