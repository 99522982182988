<template>
    <span>{{ $t('total') }}: {{ totalPrice | price }}</span>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TotalPriceLabel extends Vue {
        @Prop({required: true}) totalPrice
        @Prop() offer
    }
</script>
