<template>
    <div v-if="showHoldBooking" class="d-flex flex-column flex-md-row align-md-center">
        <v-checkbox v-model="_dueToConfirmDateActive" hide-details class="me-2">
            <template v-slot:label>
                <div class="confirmation-text" @click.stop>
                    {{ $t('need_hold_booking') }}
                </div>
            </template>
        </v-checkbox>
        <vc-date-picker
            v-model="_dueToConfirmDate"
            :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm'}"
            :locale="$i18n.locale"
            :min-date="minDateTime"
            :max-date="initialDueToConfirmDate"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            mode="dateTime"
            is24hr
            is-required
        >
            <template v-slot="{inputEvents}">
                <div v-on="_dueToConfirmDateActive && isTO1 ? inputEvents : null">
                    <v-text-field
                        :label="$t('prebooking_until')"
                        readonly
                        single-line
                        filled
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-av-timer"
                        :value="formattedDate"
                        dense
                        :disabled="!(_dueToConfirmDateActive && isTO1)"
                        class="date-input"
                    />
                </div>
            </template>
        </vc-date-picker>
    </div>
</template>

<script>
    import {Vue, Component, PropSync, Prop} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class DueToConfirmDateCheckbox extends Vue {
        @Prop({required: true}) startDate
        @PropSync('dueToConfirmDate') _dueToConfirmDate
        @PropSync('dueToConfirmDateActive') _dueToConfirmDateActive

        initialDueToConfirmDate = null

        mounted() {
            this.initialDueToConfirmDate = this._dueToConfirmDate
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get isTO1() {
            return authStore.isTO1
        }

        get formattedDate() {
            return this._dueToConfirmDate !== null ? this.$options.filters.dateTimeFormat(this._dueToConfirmDate) : ''
        }

        get showHoldBooking() {
            return (
                this._dueToConfirmDate &&
                this.$dateFns.isAfter(
                    this.$dateFns.parseISO(this._dueToConfirmDate),
                    this.$dateFns.subDays(new Date(), 1)
                ) &&
                this.$dateFns.isBefore(
                    this.$dateFns.parseISO(this._dueToConfirmDate),
                    this.$dateFns.addDays(this.$dateFns.parseISO(this.startDate), 1)
                ) &&
                this.isTO1
            )
        }
    }
</script>

<style scoped lang="scss">
    .confirmation-text {
        font-size: 0.875rem;
    }

    .date-input {
        min-width: 210px;
    }
</style>
