<template>
    <v-card outlined>
        <div class="d-flex wrapper">
            <v-img
                tile
                :src="mainImg | imageUrl(400)"
                :alt="product.info.modelName"
                :lazy-src="'/placeholder.png' | srcHost"
                width="200"
                max-width="200"
                height="200"
                eager
                class="me-2"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && product.info.imageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 d-flex flex-column justify-space-between text-truncate">
                <div>
                    <div class="mb-1 font-weight-medium text-truncate primary--text" :title="product.info.modelName">
                        {{ product.info.modelName }}
                    </div>
                    <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" class="mb-1" />
                    <car-feature-labels :info="product.info" small class="mb-2" />
                    <car-characteristic :info="product.info" />
                    <div v-if="!hideMap" class="cursor-pointer" @click="mapModal = true">
                        <v-icon small color="accent">mdi-map-marker</v-icon>
                        <span class="text-caption primary--text text-decoration-underline text-lowercase">
                            {{ $t('show_on_map') }}
                        </span>
                    </div>
                    <div v-if="product.info.shortDescription" v-html="product.info.shortDescription" />
                </div>
                <div>
                    <div class="wrap-words">
                        <div v-if="product.offer.available" class="d-flex align-center caption">
                            <v-icon :title="$t('available')" left small class="success--text">
                                mdi-checkbox-marked-circle
                            </v-icon>
                            <span class="success--text" v-text="$t('available')" />
                        </div>
                        <div v-else class="d-flex align-center caption">
                            <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                            <span class="warning--text" v-text="$t('on_request')" />
                        </div>
                    </div>
                    <span v-if="product.offer && product.offer.price" class="caption mt-1">
                        <conditions-label
                            :offer-key="product.offer.offerKey"
                            :price="product.offer.price"
                            @conditions="showConditionsInfo"
                        />
                    </span>
                    <feature-includes class="mt-1" :features="product.info.features" />
                </div>
                <v-row v-if="$route.name === 'home'" dense>
                    <v-col cols="12">
                        <car-rent-points :offer="product.offer" class="mt-2" />
                    </v-col>
                </v-row>
            </div>
            <div v-if="!bookDisabled" class="ma-4 price-col flex-shrink-0 text-right">
                <discount-label :price="product.offer.price" class="d-inline-block" />
                <div data-cy="client-price" class="font-weight-medium">
                    {{ product.offer.price | price }}
                </div>
                <commission-amount :commission="product.offer.price.commission" />
                <v-btn depressed block color="primary" class="my-2" nuxt @click="selectForBooking">
                    {{ $t('book') }}
                </v-btn>
                <div class="primary--text text-center cursor-pointer text-no-wrap" @click="showOfferInfo">
                    <v-icon dense color="primary">mdi-information-outline</v-icon>
                    <span class="show-description-label">
                        {{ `${$t('show')} ${$t('description').toLowerCase()}` }}
                    </span>
                </div>
            </div>
        </div>
        <client-only v-if="!hideMap">
            <v-dialog v-model="mapModal" :max-width="600">
                <car-map-points
                    :_expanded.sync="mapExpanded"
                    :pick-up-points="product.offer.pickUpPoints"
                    :return-points="product.offer.returnPoints"
                    :parent="true"
                    style="max-height: 500px; position: unset"
                />
            </v-dialog>
        </client-only>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import FeatureIncludes from '@/components/carsRent/snippets/FeatureIncludes'
    import CarFeatureLabels from '@/components/carsRent/snippets/CarFeatureLabels'
    import CarCharacteristic from '@/components/carsRent/snippets/CarCharacteristic'
    import {authStore, carsRentStore} from '~/store'
    import {EventBus, SHOW_CARS_CONDITIONS_INFO, SHOW_CARS_INFO_MODAL} from '@/utils/event-bus'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import CarMapPoints from '@/components/carsRent/snippets/CarMapPoints'
    import CarRentPoints from '@/components/carsRent/snippets/CarRentPoints'

    @Component({
        components: {
            CommissionAmount,
            ConditionsLabel,
            DiscountLabel,
            FeatureIncludes,
            CarFeatureLabels,
            CarCharacteristic,
            SupplierNameLabel,
            CarMapPoints,
            CarRentPoints,
        },
    })
    export default class CarOfferCard extends Vue {
        @Prop({default: () => ({})}) product
        @Prop({default: false, type: Boolean}) bookDisabled
        @Prop({default: false, type: Boolean}) hideMap

        expanded = false
        imgError = false
        mapModal = false
        mapExpanded = false

        selectForBooking() {
            const offerKey = this.product.offer.offerKey
            carsRentStore.ADD_TO_BASKET({offerKey})
            this.$router.push({
                name: 'carBooking',
                query: {offerKey},
            })
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_CARS_CONDITIONS_INFO, this.searchRequest, this.product)
        }

        showOfferInfo() {
            EventBus.$emit(SHOW_CARS_INFO_MODAL, this.product)
        }

        get mainImg() {
            return this.product.info.images?.find(item => item.mainImage)?.url || this.product.info.images?.length
                ? this.product.info.images[0]?.url
                : ''
        }

        get searchRequest() {
            return carsRentStore.searchRequest
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    .price-col {
        width: 180px;
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;
        }
    }
</style>
