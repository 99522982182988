<template>
    <v-dialog v-model="dialog" max-width="800">
        <internal-notes
            :specified-service="service"
            :communications="communications"
            :communications-loading="communicationsLoading"
            :order-id="orderId"
            @get-communications="getCommunications"
        />
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_SERVICE_NOTES_MODAL} from '@/utils/event-bus'
    import InternalNotes from '@/components/account/internalNotes/InternalNotes.vue'

    @Component({
        components: {InternalNotes},
    })
    export default class ServiceNotesModal extends Vue {
        @Prop({required: true}) orderId
        @Prop({required: true}) communications
        @Prop({required: true}) communicationsLoading

        @Emit()
        getCommunications() {}

        dialog = false
        service = null

        mounted() {
            EventBus.$on(SHOW_SERVICE_NOTES_MODAL, this.show)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_SERVICE_NOTES_MODAL, this.show)
        }

        show(service) {
            this.service = service
            this.dialog = true
        }
    }
</script>
