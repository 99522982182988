<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card>
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-title class="title font-weight-light">
                {{ type === 'CONFIRM' ? $t('do_you_confirm_the_booking') : $t('do_you_reject_the_booking') }}
            </v-card-title>
            <v-card-text v-if="type === 'CONFIRM'">
                {{ $t('enter_reference') }}
                <v-text-field v-if="type === 'CONFIRM'" v-model="refNumber" single-line />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="type === 'CONFIRM'"
                    :loading="isLoading"
                    :disabled="isLoading"
                    color="primary"
                    small
                    @click="reservationAction"
                >
                    {{ $t('supplier.confirm') }}
                </v-btn>
                <v-btn
                    v-if="type === 'REJECT'"
                    :loading="isLoading"
                    :disabled="isLoading"
                    color="error"
                    depressed
                    small
                    @click="reservationAction"
                >
                    {{ $t('supplier.reject') }}
                </v-btn>
                <v-btn color="grey" small class="white--text" @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {CHANGE_LOCALE_EVENT, EventBus, SHOW_RESERVATION_STATUS_MODAL} from '@/utils/event-bus'

    @Component
    export default class ReservationsStatusModal extends Vue {
        modal = false
        type = ''
        processId = ''
        refNumber = ''
        orderId = null
        isLoading = false

        created() {
            EventBus.$on(SHOW_RESERVATION_STATUS_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_RESERVATION_STATUS_MODAL, this.showModal)
        }

        async showModal({type, processId}, orderId) {
            this.type = type
            this.processId = processId
            this.orderId = orderId
            this.modal = true
        }

        async reservationAction() {
            this.isLoading = true
            try {
                const data = {
                    processId: this.processId,
                    status: this.type,
                }
                if (this.type === 'CONFIRM') data.refNumber = this.refNumber

                await this.$api.modifyStatus.put(data)

                if (this.$config.reservationReport && this.processId) {
                    await this.markAsNotSentToSupplier()
                }
                EventBus.$emit(CHANGE_LOCALE_EVENT)
            } catch (error) {
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            }
            this.isLoading = false
        }

        async markAsNotSentToSupplier() {
            await this.$api.orders.put(this.orderId, {
                servicesInfo: [
                    {
                        processId: this.processId,
                        serviceSentToSupplier: false,
                    },
                ],
            })
        }
    }
</script>
