<template>
    <v-card v-if="modal" outlined class="mb-5" data-cy="payment-methods">
        <v-card-title class="justify-center">
            {{ $t('loyalty') }}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-radio-group v-model="payWithLoyaltyPoints" width="100%" :disabled="disabled">
                        <v-radio
                            :key="0"
                            :label="`${$t('accumulate_loyalty')} (+${loyaltyPointsForOrder})`"
                            :value="0"
                        />
                        <v-radio :key="1" :label="$t('spend_loyalty', {points: totalMyLoyalty})" :value="1" />
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row v-if="payWithLoyaltyPoints">
                <v-form ref="loyaltyPoints" v-model="goodSocials" :disabled="disabled">
                    <v-row>
                        <v-col cols="12" md="3" align-self="center">
                            <span> {{ `${$t('loyalty')}` }}</span>
                        </v-col>
                        <v-col cols="12" md="3" align-self="center" class="d-flex">
                            <v-text-field
                                v-model="paidByLoyalty"
                                :rules="[validatorAmountByLoyalty]"
                                :label="`${$t('loyalty')}`"
                                @input="paidByLoyaltyInput"
                            />
                            <span class="align-self-center"> = </span>
                        </v-col>
                        <v-col cols="12" md="3" align-self="center" class="d-flex">
                            <v-text-field
                                v-model="paidByLoyaltyInCurrency"
                                :rules="[validatorAmountInCurrency]"
                                :label="`${$t('currency')}`"
                                @input="paidByLoyaltyInCurrencyInput"
                            />
                            <span class="align-self-center"> {{ `${currency}` }}</span>
                        </v-col>
                    </v-row>
                </v-form>
                <v-col cols="12">
                    <div class="red--text">
                        {{
                            `${$t('restrict_loyalty', {
                                percent: percentageToPayByLoyalty ? percentageToPayByLoyalty + '%' : '100%',
                            })}`
                        }}
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'nuxt-property-decorator'
    import {EventBus, SHOW_LOYALTY_POINTS} from '@/utils/event-bus'

    @Component
    export default class LoyaltyPoints extends Vue {
        @Prop() disabled
        @Prop() isLoading
        @PropSync('resolveLoyalty') loyalty

        modal = false
        resolve = null
        payWithLoyaltyPoints = 0
        loyaltyPointsForOrder = null
        totalMyLoyalty = null
        currency = ''
        percentageToPayByLoyalty = ''
        paidByLoyalty = 0
        paidByLoyaltyInCurrency = 0
        loyaltyPointConversionRate = 1
        paidByLoyaltyMax = 0
        paidByLoyaltyInCurrencyMax = 0
        goodSocials = false

        created() {
            EventBus.$on(SHOW_LOYALTY_POINTS, this.show)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_LOYALTY_POINTS, this.show)
        }

        paidByLoyaltyInput(val) {
            this.paidByLoyaltyInCurrency = val * this.loyaltyPointConversionRate
        }

        paidByLoyaltyInCurrencyInput(val) {
            this.paidByLoyalty = +val / this.loyaltyPointConversionRate
        }

        validatorAmountByLoyalty(v) {
            return !!(+v <= this.paidByLoyaltyMax) || `${this.$t('to_much_loyalty')}`
        }

        validatorAmountInCurrency(v) {
            return !!(+v <= this.paidByLoyaltyInCurrencyMax) || `${this.$t('to_much_loyalty')}`
        }

        show({prepareBookResponse, resolve}) {
            this.loyalty = false
            this.modal = true
            this.resolve = resolve

            this.loyaltyPointsForOrder = prepareBookResponse.prepareBookResponses.reduce(
                (accumulator, currentValue) => {
                    return (accumulator += currentValue.loyaltyPoints || 0)
                },
                0
            )

            const firstLoyaltyAtResponse = prepareBookResponse.prepareBookResponses[0].paymentByLoyaltyPoint

            this.totalMyLoyalty = firstLoyaltyAtResponse.totalLoyaltyPoints

            this.currency = firstLoyaltyAtResponse.paidByLoyaltyCurrency

            this.percentageToPayByLoyalty = firstLoyaltyAtResponse.percentageToPayByLoyalty

            this.loyaltyPointConversionRate = firstLoyaltyAtResponse.loyaltyPointConversionRate

            this.paidByLoyalty = firstLoyaltyAtResponse.paidByLoyalty

            this.paidByLoyaltyMax = this.paidByLoyalty

            this.paidByLoyaltyInCurrency = firstLoyaltyAtResponse.paidByLoyaltyInCurrency

            this.paidByLoyaltyInCurrencyMax = this.paidByLoyaltyInCurrency
        }

        makeLoyaltyOptions() {
            if (!this.goodSocials && this.payWithLoyaltyPoints === 1) {
                return
            }
            if (this.payWithLoyaltyPoints) {
                this.resolve({spentLoyaltyPoints: this.paidByLoyalty})
            } else {
                this.resolve({})
            }
            this.loyalty = true
        }

        close() {
            this.loyalty = true
            this.modal = false
        }
    }
</script>
