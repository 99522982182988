<template>
    <div v-html="service.supplierServiceName" />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelInfoServicesLabel extends Vue {
        @Prop({required: true}) service
    }
</script>
