<template>
    <div class="d-flex align-center text-capitalize">
        <v-icon class="info--text" left small>mdi-silverware-fork-knife</v-icon>
        <span class="caption">{{ label }}</span>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class CruiseMealTypeLabel extends Vue {
        @Prop({required: true}) offer

        get label() {
            return this.offer.mealType.name?.toLowerCase()
        }
    }
</script>
