<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class FiltersBase extends Vue {
        @Prop({required: true}) filters
        @Prop({required: true}) filterValues
        @Prop({required: true, default: 0}) filteredOffersCount
        @Prop({required: true}) currency

        @Emit()
        reset() {}

        @Emit()
        change({key, value}) {
            return {key, value}
        }

        async clearFilter({key, value}) {
            const index = this.filterValues[key].indexOf(value)
            if (index !== -1) {
                const value = this.filterValues[key].slice()
                value.splice(index, 1)
                this.change({key, value})
            }
        }

        goToOffers() {
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    .sticky-button {
        position: sticky;
        bottom: 0;
        left: 0;
    }
</style>
