<template>
    <client-only>
        <div>
            <h6 class="text-h6">
                {{ $t('tours.available_travel_start_dates') }}
            </h6>
            <vc-date-picker
                v-model="date"
                :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                :locale="$i18n.locale"
                :columns="$breakpoint.lgAndDown ? 1 : 2"
                is-expanded
                :available-dates="availableDates"
                :first-day-of-week="$config.firstDayOfWeek || undefined"
                is-required
            />
        </div>
    </client-only>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import TourDatepickerMixin from '@/components/tours/mixins/TourDatepickerMixin'

    @Component
    export default class TourDatepicker extends mixins(TourDatepickerMixin) {
        @Prop({required: true}) searchRequest

        get date() {
            return this.searchRequest.startDateFrom
        }

        set date(startDateFrom) {
            try {
                this.$router.push({
                    name: 'tour',
                    query: Object.assign({}, this.$route.query, {
                        startDateFrom,
                    }),
                })
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>
