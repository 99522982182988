<template>
    <v-card outlined>
        <div class="d-flex wrapper">
            <v-img
                :alt="product.name"
                src="/insurance.jpg"
                width="200"
                max-width="200"
                height="200"
                lazy-src="/placeholder.png"
                tile
                eager
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && product.offers[0].mainImageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 d-flex text-truncate">
                <div class="mb-2 font-weight-medium text-truncate primary--text">
                    <span :title="product.name">
                        {{ product.name }}
                    </span>
                    <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" />
                </div>
                <div v-if="product.shortDescription" v-html="product.shortDescription" />
            </div>
            <div class="ma-4 price-col flex-shrink-0 text-right">
                <slot name="price" :offer="product.offers[0]">
                    <div data-cy="client-price" class="font-weight-medium">
                        {{ $t('from') }} {{ minPriceOffer.price | price }}
                    </div>
                    <discount-label :price="product.offers[0].price" />
                    <commission-amount :commission="minPriceOffer.price.commission" />
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="my-2"
                        nuxt
                        @click="selectForBooking(product.offers[0])"
                    >
                        {{ $t('book') }}
                    </v-btn>
                </slot>
                <div class="primary--text cursor-pointer text-no-wrap" @click="expanded = !expanded">
                    <span>
                        {{ $t('tours.detailed_info') }}
                    </span>
                    <v-icon color="primary">
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
            </div>
        </div>
        <v-expand-transition>
            <v-list v-if="expanded">
                <template v-for="(offer, index) in product.offers">
                    <v-list-item :key="offer.offerKey" :data-cy="`insurance-offer-card-${index}`">
                        <v-list-item-content class="align-self-start">
                            <div
                                class="wrap-words d-flex align-center text-subtitle-1 font-weight-medium cursor-pointer mb-1"
                                @click="offerInfo(offer)"
                            >
                                <v-icon small left>mdi-information-outline</v-icon>
                                <span class="font-weight-bold">
                                    {{ offer.planName }}
                                </span>
                            </div>
                            <div class="caption">
                                <span v-if="isAvailable">
                                    <v-icon :title="$t('available')" small left class="success--text">
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    <span class="success--text" v-text="$t('available')" />
                                </span>
                                <span v-else>
                                    <v-icon left :title="$t('on_request')" small class="warning--text">
                                        mdi-alert-circle
                                    </v-icon>
                                    <span class="warning--text" v-text="$t('on_request')" />
                                </span>
                                <div v-if="offer.price">
                                    <conditions-label
                                        v-if="offer"
                                        :offer-key="offer.offerKey"
                                        :price="offer.price"
                                        @conditions="showConditionsInfo(offer)"
                                    />
                                </div>
                            </div>
                        </v-list-item-content>
                        <div class="price-col ms-auto d-flex flex-column align-center">
                            <slot name="price" :offer="offer">
                                <v-btn
                                    color="primary"
                                    class="my-2"
                                    block
                                    depressed
                                    nuxt
                                    data-cy="client-price"
                                    @click="selectForBooking(offer)"
                                >
                                    {{ offer.price | price }}
                                </v-btn>
                                <div class="text-caption text--secondary">
                                    {{
                                        `${$t('for')} ${touristsTotal} ${$tc('tourist', touristsTotal)},
                                         ${searchRequest.daysCount} ${$tc('day', searchRequest.daysCount)}`
                                    }}
                                </div>
                                <commission-amount :commission="offer.price.commission" />
                                <net-amount :price="offer.price" />
                            </slot>
                        </div>
                    </v-list-item>
                    <v-divider v-if="index !== product.offers.length - 1" :key="index" />
                </template>
            </v-list>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, insuranceRuntimeStore, insuranceStore} from '~/store'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_INSURANCE_CONDITIONS_INFO, SHOW_INSURANCE_INFO_MODAL} from '@/utils/event-bus'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            DiscountLabel,
            ConditionsLabel,
            CommissionAmount,
            NetAmount,
            SupplierNameLabel,
        },
    })
    export default class InsuranceOfferCard extends Vue {
        @Prop({required: true}) product

        imgError = false
        expanded = false

        selectForBooking(offer) {
            insuranceStore.ADD_TO_BASKET(offer.offerKey)
            this.$router.push({
                name: 'insuranceBooking',
                query: {offerKey: offer.offerKey},
            })
        }

        offerInfo(offer) {
            EventBus.$emit(SHOW_INSURANCE_INFO_MODAL, {offer})
        }

        showConditionsInfo(offer) {
            EventBus.$emit(SHOW_INSURANCE_CONDITIONS_INFO, this.searchRequest, offer)
        }

        onImgError() {
            this.imgError = true
            return false
        }

        get minPriceOffer() {
            return this.product.offers.reduce(
                (a, b) => (a.price.amount < b.price.amount ? a : b),
                this.product.offers[0]
            )
        }

        get isAvailable() {
            // TODO: availability
            return true
        }

        get mainImg() {
            //TODO: img for insurance
            return ''
        }

        get searchRequest() {
            return insuranceStore.searchRequest
        }

        get touristsTotal() {
            return insuranceRuntimeStore.touristsTotal(this.searchRequest)
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .insurance-description p {
        margin-bottom: 0;
    }

    .price-col {
        width: 180px;
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;
        }
    }
</style>
