<template>
    <div>
        {{ $t(label) }}
        <v-chip color="success" label small>{{ ratingObject.rating }}</v-chip>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class SupplierRating extends Vue {
        @Prop({default: () => 'review_score'}) label
        @Prop() ratingObject
    }
</script>
