<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '@/store'
    import {HOTEL} from '@/utils/hotels/hotels-const'

    @Component
    export default class HotelStoreMixin extends Vue {
        get productStore() {
            return hotelsStore
        }

        get productRuntimeStore() {
            return hotelsRuntimeStore
        }

        get productType() {
            return HOTEL
        }
    }
</script>
