<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, FILTER_EVENT} from '@/utils/event-bus'

    //TODO Need refactoring and remove unusable methods
    @Component
    export default class SearchPageBase extends Vue {
        mapExpanded = false
        resetPageLock = false

        created() {
            EventBus.$on(FILTER_EVENT, this.resetPage)
        }

        beforeDestroy() {
            EventBus.$off(FILTER_EVENT, this.resetPage)
        }

        resetPage() {
            if (this.resetPageLock) {
                this.resetPageLock = false
            } else {
                this.page = 1
            }
        }

        get page() {
            return this.productStore.searchPage
        }

        set page(page) {
            this.productStore.SET_SEARCH_PAGE(page)
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get offers() {
            const index = this.resultsOnPage * (this.page - 1)
            return this.productRuntimeStore.offers.slice(index, index + this.resultsOnPage)
        }

        get pages() {
            return Math.ceil(this.productRuntimeStore.offers.length / this.resultsOnPage)
        }

        get resultsOnPage() {
            return this.$config.search.resultsOnPage
        }
    }
</script>
