<template>
    <v-select class="pt-3" :value="value" dense :items="items" :label="$t('sort.sort')" @change="change" />
</template>

<script>
    import {Vue, Component, Prop, Emit, Model} from 'vue-property-decorator'

    @Component
    export default class SortSelect extends Vue {
        @Model('change', {required: true}) value
        @Prop({required: true}) items

        @Emit()
        change(sortKey) {
            return sortKey
        }
    }
</script>

<style scoped>
    ::v-deep .v-select__selections {
        font-size: 13px;
    }

    ::v-deep .v-list-item--link {
        font-size: 12px;
    }
</style>
