<template>
    <v-card>
        <v-card-title class="py-5 full-width">
            <v-btn v-if="createMode || editMode" fab class="me-3" small elevation="0" @click="goToTable">
                <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <span v-if="createMode">
                {{ !preset ? $t('create_internal_note') : $t('create_preset_note') }}
            </span>
            <span v-else-if="editMode">
                {{ !preset ? $t('edit_internal_note') : $t('edit_preset_note') }}
            </span>
            <span v-else>
                {{ !preset ? $tc('internal_note', 2) : $t('preset_notes') }}
            </span>
            <v-btn v-if="!(createMode || editMode) && isTO1" color="primary" right text absolute @click="toggleActive">
                <v-icon v-if="!active" left> mdi-arrow-left </v-icon>
                <v-icon left> mdi-archive-arrow-{{ active ? 'down' : 'up' }} </v-icon>
                {{ $t(active ? 'archive' : 'standard_mode') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <internal-note-edit-form
                v-if="createMode || editMode"
                ref="form"
                v-model="communicationData"
                :edit-note-info="editNoteInfo"
                :edit-mode="editMode"
                :services="orderServices"
                :specified-service="specifiedService"
                :_valid.sync="valid"
                :preset="preset"
            />
            <template v-else>
                <slot :get-communications="getCommunications" />
                <v-data-table
                    :headers="headers"
                    :items="communicationsFiltered"
                    :loading="loading || communicationsLoading"
                    class="elevation-1"
                    :server-items-length="communicationsTotalCount || -1"
                    :footer-props="{'items-per-page-options': [5, 15, 30, -1]}"
                    :options.sync="tableOptions"
                    :item-class="() => (!active ? 'inactive-item' : '')"
                    :hide-default-header="isMobile"
                    :mobile-breakpoint="0"
                    disable-sort
                    @click:row="isTO1 ? editCommunication($event) : null"
                >
                    <template v-slot:item.status="{item}">
                        {{ $t(`${item.status.toLowerCase()}`) }}
                    </template>
                    <template v-slot:item.DR="{item}">
                        <div>
                            {{
                                `${item.service ? item.service.serviceName : item.serviceName || ''}${
                                    item.service.roomTypeName ? `, ${item.service.roomTypeName}` : ''
                                }`
                            }}
                        </div>
                    </template>
                    <template v-slot:item.communicationDateTime="{item}">
                        {{ item.communicationDateTime | dateTimeFormat }}
                    </template>
                    <template v-slot:item.mobile="{item}">
                        <div class="py-2 d-flex full-width">
                            <div class="d-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden">
                                <div>{{ item.communicationDateTime | dateTimeFormat }}</div>
                                <div>{{ item.description }}</div>
                            </div>
                            <div class="pl-2 d-flex flex-column flex-grow-0 flex-shrink-0 align-end overflow-hidden">
                                <div>{{ item.createdByUserName }}</div>
                                <div>{{ item.status }}</div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:footer.prepend>
                        <span v-if="communicationsTotalCount" class="caption text--secondary">
                            {{ $t('total') }}: {{ communicationsTotalCount }}
                        </span>
                    </template>
                </v-data-table>
            </template>
            <v-btn
                v-if="!createMode && !editMode && isTO1 && (orderId || preset)"
                color="primary"
                class="mt-4"
                @click="createMode = !createMode"
            >
                {{ $t('create') }}
            </v-btn>
        </v-card-text>
        <v-card-actions class="py-4">
            <v-spacer />
            <v-btn v-if="createMode || editMode" color="primary" :loading="loading" :disabled="!valid" @click="save">
                {{ $t('save') }}
            </v-btn>
            <v-btn v-if="editMode" text @click="cancel">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import InternalNoteEditForm from '@/components/account/internalNotes/InternalNoteEditForm'
    import Communications from '@/components/account/communications/Communications'
    import {authStore} from '@/utils/store-accessor'
    import goTo from 'vuetify/es5/services/goto'

    const blankCommunication = (communicationDateTime, orderId = null) => ({
        type: 'NOTES',
        communicationDateTime,
        description: null,
        active: true,
        showInInvoice: false,
        showInQuotation: false,
        processId: null,
        visibleForClients: false,
        visibleForSuppliers: false,
        orderId,
        subject: null,
    })

    @Component({
        components: {InternalNoteEditForm, TimeField, DatePicker, Communications},
    })
    export default class InternalNotes extends Communications {
        @Prop() specifiedService
        @Prop({default: false, type: Boolean}) preset

        valid = false
        communicationData = blankCommunication()
        editNoteInfo = null
        active = true
        orderServices = []

        toggleActive() {
            this.active = !this.active
        }

        resetData() {
            this.communicationData = blankCommunication(this.$dateFns.format(new Date(), "yyyy-MM-dd'T'HH:mm"))
        }

        async editCommunication(e) {
            this.orderServices = this.services
            if (!this.services && e.reservationNumber) {
                try {
                    this.orderServices = (
                        await this.$api.orders.get({
                            orderType: authStore.orderType,
                            orderId: +e.reservationNumber,
                            responseType: 'SHORT',
                        })
                    ).orders[0].services
                } catch (e) {
                    this.orderServices = []
                }
            } else {
                this.orderServices = this.services || []
            }
            const processId =
                this.specifiedService?.processId ||
                this.orderServices.find(item => item.serviceName === e.serviceName)?.processId

            this.communicationData = {
                assignedToId: e.assignedUser.id,
                accountId: e.accountId,
                accountType: e.accountType,
                communicationDateTime: e.communicationDateTime?.split(' ').join('T'),
                description: e.description,
                orderId: e.reservationNumber,
                processId,
                active: e.status === 'ACTIVE',
                showInInvoice: e.showInInvoice,
                showInQuotation: e.showInQuotation,
                visibleForClients: e.visibleForClients,
                visibleForSuppliers: e.visibleForSuppliers,
                communicationId: e.communicationId,
                subject: e.subject,
            }

            this.editNoteInfo = {
                created: e.createdDateTime,
                modified: e.modifiedDateTime,
                createdUser: e.createdByUserName,
                modifiedUser: e.modifiedByUserName,
            }

            this.editMode = true
            goTo(0)
        }

        get communicationsFiltered() {
            return this.communications
                .filter(({processId}) => !this.specifiedService || this.specifiedService.processId === processId)
                .filter(e => (this.active ? e.status === 'ACTIVE' : e.status === 'INACTIVE'))
                .map(el => ({
                    ...el,
                    ...(this.services?.find(s => s.processId === el.processId) && {
                        service: this.services?.find(s => s.processId === el.processId),
                    }),
                    visibleNote: [
                        this.$t('for_to1'),
                        ...(el.visibleForClients ? [this.$t('for_clients')] : []),
                        ...(el.visibleForSuppliers ? [this.$t('for_suppliers')] : []),
                    ].join(', '),
                }))
        }

        get requestData() {
            const {
                type,
                communicationDateTime,
                description,
                processId,
                active,
                showInInvoice,
                showInQuotation,
                visibleForClients,
                visibleForSuppliers,
                communicationId,
                subject,
            } = this.communicationData
            return {
                assignedToId: this.person.personId,
                accountId: this.accountId,
                accountType: this.accountType,
                type,
                communicationDateTime,
                description,
                orderId: this.orderId,
                processId,
                active,
                showInInvoice,
                showInQuotation,
                visibleForClients,
                visibleForSuppliers,
                ...(this.editMode && {communicationId}),
                preset: this.preset,
                subject,
            }
        }

        get headers() {
            if (this.isMobile) return [{value: 'mobile'}]

            return [
                {text: `${this.$t('date')}/${this.$t('time')}`, value: 'communicationDateTime'},
                {text: this.$t('title'), value: 'subject'},
                {text: this.$tc('internal_note', 1), value: 'description'},
                ...(!this.preset ? [{text: this.$t('order_number'), value: 'reservationNumber'}] : []),
                ...(!this.specifiedService && !this.preset
                    ? [{text: this.$t('relates_to'), value: 'serviceName'}]
                    : []),
                {text: this.$t('visible_for'), value: 'visibleNote'},
                ...(!this.specifiedService ? [{text: this.$t('user'), value: 'createdByUserName'}] : []),
                {text: this.$t('status'), value: 'status'},
            ]
        }

        get accountType() {
            if (this.isCorporate) {
                return 'CORPORATE_CLIENT'
            }
            if (this.isTO1 || this.isTO2 || this.isAgency) {
                return 'AGENCY_DISTRIBUTOR'
            }
            if (this.isSupplier) {
                return 'SUPPLIER'
            }

            return 'PRIVATE_CLIENT'
        }

        get accountId() {
            if (this.isB2B) {
                return this.company.id
            }
            return this.person.personId
        }
    }
</script>
