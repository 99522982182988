<template>
    <v-row dense>
        <template v-for="(item, index) in routesQuantity">
            <v-col
                v-if="routesQuantity.length > 1"
                :key="`delete-${index}`"
                cols="1"
                class="px-0 d-flex justify-center align-center"
            >
                <v-btn text @click="deleteRoute(index)">
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </v-col>
            <v-col :key="`route-${index}`" :cols="routesQuantity.length > 1 ? 11 : 12">
                <one-way :form-index="index" :init-search="initSearch" @form-data="addRoute(index, $event)" />
                <v-divider v-if="index < routesQuantity.length - 1" class="mt-4 mb-2 d-md-none" />
            </v-col>
        </template>
        <v-col class="px-0" cols="1">
            <v-btn text class="white--text h6" @click="addNewRoute">
                {{ $t('flights.add_route') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Watch, Prop, Vue} from 'nuxt-property-decorator'
    import OneWay from '@/components/flights/search/forms/OneWay'
    import {flightsRuntimeStore} from '@/store'

    @Component({
        components: {
            OneWay,
        },
    })
    export default class MultiCity extends Vue {
        @Prop() initSearch

        routesQuantity = ['route-0']
        routes = []

        @Watch('initSearch')
        onSearchInit() {
            this.routes = []
        }

        @Watch('routes', {deep: true})
        onRoutesChange() {
            if (this.routes.length === this.routesQuantity.length) {
                this.$emit('form-data', this.routes)
            }
        }

        created() {
            for (let i = 1; i < flightsRuntimeStore.routes.length; i++) {
                this.addNewRoute()
            }
        }

        addNewRoute() {
            this.routesQuantity.push(`route-${this.routesQuantity.length}`)
        }

        addRoute(index, route) {
            this.routes.push(route[0])
        }

        deleteRoute(index) {
            this.routesQuantity.splice(index, 1)
        }
    }
</script>

<style scoped lang="scss">
    .v-divider {
        border-color: white;
    }
</style>
