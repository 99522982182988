<template>
    <div>
        <breadcrumbs class="pb-3" />
        <v-row>
            <v-col cols="12" md="12">
                <div class="elevation-1">
                    <contracts :show-link="false" :limit="15" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import Contracts from '@/components/supplier/Contracts'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component({
        components: {
            Contracts,
            Breadcrumbs,
        },
        middleware: 'auth',
    })
    export default class ProductsPage extends Vue {}
</script>

<style scoped></style>
