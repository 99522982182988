<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class FlightBookingExtraMixin extends Vue {
        get extraBaggagePrice() {
            const extraBaggagePrice = {amount: 0, currency: this.currency, originalAmount: 0}
            if (!this.prepareBookRequest.extraBaggage) return extraBaggagePrice
            return this.prepareBookRequest.extraBaggage.reduce((segmentTotalPrice, extraBaggage) => {
                const extraBaggageOption = this.extraBaggageOptions.find(
                    extraBaggageOption => extraBaggage.serviceRPH === extraBaggageOption.serviceRPH
                )
                if (!extraBaggageOption || !extraBaggageOption.price || extraBaggageOption.mandatory)
                    return segmentTotalPrice
                segmentTotalPrice.amount +=
                    this.$options.filters.convertPrice(extraBaggageOption.price).amount * extraBaggage.quantity

                const originalAmount = extraBaggageOption.price.originalAmount || extraBaggageOption.price.amount,
                    originalCurrency = extraBaggageOption.price.originalCurrency || extraBaggageOption.price.currency
                if (!segmentTotalPrice.originalCurrency) {
                    segmentTotalPrice.originalCurrency = originalCurrency
                }
                if (segmentTotalPrice.originalCurrency === originalCurrency) {
                    segmentTotalPrice.originalAmount += originalAmount * extraBaggage.quantity
                }

                return segmentTotalPrice
            }, extraBaggagePrice)
        }

        get extraBaggageMandatoryPrice() {
            const extraBaggagePrice = {amount: 0, currency: this.currency, originalAmount: 0}
            if (!this.prepareBookRequest.extraBaggage) return extraBaggagePrice
            return this.prepareBookRequest.extraBaggage.reduce((segmentTotalPrice, extraBaggage) => {
                const extraBaggageOption = this.extraBaggageOptions.find(
                    extraBaggageOption => extraBaggage.serviceRPH === extraBaggageOption.serviceRPH
                )
                if (!extraBaggageOption || !extraBaggageOption.price || !extraBaggageOption.mandatory)
                    return segmentTotalPrice

                segmentTotalPrice.amount +=
                    this.$options.filters.convertPrice(extraBaggageOption.price).amount * extraBaggage.quantity

                const originalAmount = extraBaggageOption.price.originalAmount || extraBaggageOption.price.amount,
                    originalCurrency = extraBaggageOption.price.originalCurrency || extraBaggageOption.price.currency
                if (!segmentTotalPrice.originalCurrency) {
                    segmentTotalPrice.originalCurrency = originalCurrency
                }
                if (segmentTotalPrice.originalCurrency === originalCurrency) {
                    segmentTotalPrice.originalAmount += originalAmount * extraBaggage.quantity
                }

                return segmentTotalPrice
            }, extraBaggagePrice)
        }

        get seatsPrice() {
            const seatsPrice = {amount: 0, currency: this.currency, originalAmount: 0}
            if (!this.prepareBookRequest.seats || !this.tourists) return seatsPrice
            return this.prepareBookRequest.seats.reduce(
                (seatsPrice, {rowNumber, seatNumber, touristIndex, segmentRPH}) => {
                    if (!this.flightSeatsMap[segmentRPH]) return seatsPrice
                    this.flightSeatsMap[segmentRPH].forEach(seatsCabinClass => {
                        const seatRow = seatsCabinClass.seatRows.find(seatRow => seatRow.rowNumber === +rowNumber)
                        if (seatRow) {
                            const seat = seatRow.seats.find(seat => seat.seatNumber === seatNumber)
                            if (seat) {
                                const passengerType = this.tourists[touristIndex].type === 'adult' ? 'ADT' : 'CHD'
                                const passengerFare =
                                    seat.passengerFares.find(
                                        passengerFare => passengerFare.passengerType === passengerType
                                    ) || seat.passengerFares[0]
                                if (passengerFare) {
                                    seatsPrice.amount += this.$options.filters.convertPrice(passengerFare.price).amount

                                    const originalAmount =
                                            passengerFare.price.originalAmount || passengerFare.price.amount,
                                        originalCurrency =
                                            passengerFare.price.originalCurrency || passengerFare.price.currency
                                    if (!seatsPrice.originalCurrency) {
                                        seatsPrice.originalCurrency = originalCurrency
                                    }
                                    if (seatsPrice.originalCurrency === originalCurrency) {
                                        seatsPrice.originalAmount += originalAmount
                                    }
                                }
                            }
                        }
                    })
                    return seatsPrice
                },
                seatsPrice
            )
        }
    }
</script>
