<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelMapInfoWindow from '~/components/search/offers/HotelMapInfoWindow'
    import {hotelsStore} from '~/utils/store-accessor'
    import {hotelNameToUrl} from '@/utils/helpers'

    @Component
    export default class HotelHomeMapInfoWindow extends HotelMapInfoWindow {
        cityId = null

        async created() {
            const {name, cityId, own} = this.offer
            try {
                if (!own) {
                    const {
                        hotels: [
                            {
                                location: {cityId},
                            },
                        ],
                    } = await this.$api.suggestHotels.get({pattern: name})
                    this.cityId = cityId
                } else {
                    this.cityId = cityId
                }
            } catch (e) {
                this.cityId = null
            }
        }

        get hotelPageLink() {
            if (!this.cityId) return null
            const searchRequest = hotelsStore.searchRequest,
                {id, own} = this.offer,
                // eslint-disable-next-line no-unused-vars
                {partialResponse, convertToCurrency, hotelId, distance, latitude, longitude, ...query} = searchRequest
            return {
                name: 'hotel',
                params: {name: hotelNameToUrl(this.offer.name)},
                query: {...query, hotelId: id, cityId: this.cityId, ...(own && {own})},
            }
        }

        get minPriceRoomOffer() {
            return null
        }
    }
</script>
