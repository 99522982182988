<template>
    <v-card>
        <v-card-text>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" class="red--text">
                        {{ $t('booking_policy_violation_warning') }}
                    </v-col>
                    <v-col v-for="policy in policies" :key="policy.corporationPolicyId" cols="12">
                        {{ policy.corporatePolicyName }}
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            v-model="selected"
                            :items="reasons"
                            :disabled="$attrs.disabled"
                            :label="$t('select_violation_reason')"
                            item-text="code"
                            item-value="id"
                            :rules="[v => !!v || ``]"
                            @change="$emit('input', selected)"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {authStore} from '~/store'

    @Component
    export default class CorporateViolationReason extends Vue {
        @Prop({default: () => []}) policies
        @Prop() type

        reasons = []
        selected = null

        created() {
            this.getReasons()
        }

        async getReasons() {
            const rq = {
                companyId: authStore.person.companyId,
                serviceType: this.type,
                active: true,
            }
            const res = await this.$api.corporatePolicyViolationCode.get(rq)
            this.reasons = res.violateionReasonCodes
        }
    }
</script>
