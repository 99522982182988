<template>
    <v-dialog v-model="modal" :max-width="1350" scrollable>
        <v-card ref="modifyManualModal" :loading="loadingInitial">
            <div class="d-flex flex-wrap">
                <div class="me-md-6 flex-grow-0 flex-shrink-1">
                    <v-card-title>
                        {{ title }}
                        <v-icon class="v-card-close mt-3 me-3" @click="modal = false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-subtitle>
                        <div class="d-flex mt-2 align-end">
                            <v-icon class="me-1">{{ serviceIcon }}</v-icon>
                            <span>
                                {{ service && service.serviceName }}
                            </span>
                        </div>
                    </v-card-subtitle>
                </div>
                <div v-if="isTO1" class="pa-4 flex-grow-1 d-flex align-center">
                    <v-icon color="red" x-large left>mdi-alert-circle-outline</v-icon>
                    <div class="text-caption red--text">
                        <span class="text-uppercase">{{ `${$t('warning')}!!!` }}</span>
                        <div>{{ $t('manual_correction_warning') }}</div>
                        <div>{{ $t('manual_correction_info') }}</div>
                    </div>
                </div>
            </div>
            <v-card-text v-if="!loadingInitial" class="pt-2">
                <v-row>
                    <v-col :md="2">
                        <modify-manual-status :_status.sync="status" :status-list="statusList" />
                    </v-col>
                    <v-col :md="2">
                        <v-text-field
                            v-model="refNumber"
                            outlined
                            dense
                            :label="`${$t('reference_number')}`"
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row v-if="isTO1 && status === 'PRE-BOOKING'" class="mb-4">
                    <v-col :md="4">
                        <vc-date-picker
                            v-model="dueToConfirmDate"
                            :model-config="{type: 'string', mask: 'YYYY-MM-DDTHH:mm'}"
                            :locale="$i18n.locale"
                            :min-date="$dateFns.format(new Date())"
                            :max-date="service.startDateTime"
                            :first-day-of-week="$config.firstDayOfWeek || undefined"
                            mode="dateTime"
                            is24hr
                            is-required
                        >
                            <template v-slot="{inputEvents}">
                                <div v-on="inputEvents">
                                    <v-text-field
                                        :label="$t('due_to_confirm_by')"
                                        readonly
                                        hide-details
                                        background-color="white"
                                        prepend-inner-icon="mdi-av-timer"
                                        :value="$options.filters.dateTimeFormat(dueToConfirmDate)"
                                        outlined
                                        dense
                                    />
                                </div>
                            </template>
                        </vc-date-picker>
                    </v-col>
                </v-row>
                <template v-if="service && service.serviceType === 'ACCOMMODATION'">
                    <div class="mb-4 text-h6">
                        {{ $t('please_specify_hotel_meal_and_room_types') }}
                    </div>
                    <div class="mb-4 text-subtitle-1 font-weight-medium">{{ $t('order_rooms') }}</div>
                    <v-form
                        v-for="(room, roomIndex) in rooms"
                        :key="roomIndex"
                        :class="{'mb-8': roomIndex === rooms.length - 1}"
                        class="mb-4"
                    >
                        <modify-manual-room
                            v-model="rooms[roomIndex]"
                            :index="roomIndex"
                            :room-types-list="ownHotelRooms"
                            :meal-types-list="ownHotelMealTypes"
                            @change-meal-type="changeTravelerMealType(roomIndex)"
                        />
                        <template v-for="(traveler, index) in travelers">
                            <modify-manual-traveler
                                v-if="!traveler.delete && traveler.roomRph === roomIndex"
                                :key="traveler.travelerId"
                                :index="index"
                                :_traveler="traveler"
                                :meal-types-list="ownHotelMealTypes"
                                :room-types-list="ownHotelRooms"
                                @remove="removeTraveler"
                                @change-meal-type="changeRoomMealType(traveler.travelerId)"
                            />
                        </template>
                        <v-row>
                            <v-col cols="12" md="10" :class="{'d-flex': !$breakpoint.smAndDown}">
                                <div :class="{'mr-4': !$breakpoint.smAndDown, 'mb-4': $breakpoint.smAndDown}">
                                    <a
                                        href="/example_names_list.csv"
                                        class="text-body-1 primary--text text-decoration-underline"
                                    >
                                        {{ $t('download_sample') }}
                                    </a>
                                </div>
                                <label class="text-body-1 primary--text text-decoration-underline cursor-pointer">
                                    {{ $t('import_names') }}
                                    <v-icon color="primary" right>mdi-plus-circle</v-icon>
                                    <v-file-input
                                        id="fileObject"
                                        v-model="fileObject"
                                        accept=".txt, .csv"
                                        style="visibility: hidden; width: 0; height: 0; position: absolute"
                                        class="ma-0 pa-0"
                                        @change="importTravelers(roomIndex)"
                                    />
                                </label>
                            </v-col>
                            <v-col cols="12" md="2" class="d-flex align-center">
                                <v-checkbox
                                    v-model="isNewTravelerChild"
                                    :label="`${$tc('child', 1)}`"
                                    dense
                                    hide-details
                                    class="text-capitalize"
                                />
                                <v-btn :color="'primary'" icon @click="addTraveler(roomIndex)">
                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>
                <template v-else>
                    <div class="py-4 text-subtitle-1 font-weight-medium">{{ $t('order_travelers') }}</div>
                    <v-form v-for="(traveler, index) in travelers" :key="traveler.travelerId">
                        <modify-manual-traveler
                            v-if="!traveler.delete"
                            :index="index"
                            :_traveler="traveler"
                            :meal-types-list="ownHotelMealTypes"
                            :room-types-list="ownHotelRooms"
                            @remove="removeTraveler"
                            @change-meal-type="changeRoomMealType(traveler.travelerId)"
                        />
                    </v-form>
                    <v-row>
                        <v-col cols="12" md="10" :class="{'d-flex': !$breakpoint.smAndDown}">
                            <div :class="{'mr-4': !$breakpoint.smAndDown, 'mb-4': $breakpoint.smAndDown}">
                                <a
                                    href="/example_names_list.csv"
                                    class="text-body-1 primary--text text-decoration-underline"
                                >
                                    {{ $t('download_sample') }}
                                </a>
                            </div>
                            <div>
                                <label class="text-body-1 primary--text text-decoration-underline cursor-pointer">
                                    {{ $t('import_names') }}
                                    <v-icon color="primary" right>mdi-plus-circle</v-icon>
                                    <v-file-input
                                        id="fileObject"
                                        v-model="fileObject"
                                        accept=".txt, .csv"
                                        style="visibility: hidden; width: 0; height: 0; position: absolute"
                                        class="ma-0 pa-0"
                                        @change="importTravelers"
                                    />
                                </label>
                            </div>
                        </v-col>
                        <v-col cols="12" md="2" class="d-flex align-center">
                            <v-checkbox
                                v-model="isNewTravelerChild"
                                class="text-capitalize mt-5 mr-2"
                                :label="`${$tc('child', 1)}`"
                            />
                            <v-btn :color="'primary'" icon @click="addTraveler">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="salesTerms && salesSettingsLoaded && pricingRulesLoaded">
                    <div v-for="salesTerm in salesTerms" :key="salesTerm.type" class="d-flex flex-column mb-8">
                        <modify-manual-sales-term
                            :ref="`${salesTerm.type}SaleTerm`"
                            :_sales-term="salesTerm"
                            :client-type="clientType"
                            :supplier-price="salesTerms.find(e => e.type === 'SUPPLIER')?.price"
                            :sales-settings="salesSettings"
                            :pricing-rules="pricingRules"
                            :service-exchange-rate="
                                service.serviceExchangeRates && service.serviceExchangeRates.serviceCreationXR
                            "
                            @recalculate-client-price="recalculateClientPrice"
                        />
                    </div>
                    <v-checkbox v-model="applyClientAdjustedPrice">
                        <template v-slot:label>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <span v-bind="attrs" v-on="on">
                                        {{ $t('ModifyManualModal.apply_amended_client_price') }}
                                    </span>
                                </template>
                                <span>{{ $t('ModifyManualModal.apply_amended_client_price_tooltip') }}</span>
                            </v-tooltip>
                        </template>
                    </v-checkbox>
                </template>
                <div v-else class="d-flex justify-center full-width">
                    <v-progress-circular indeterminate size="64" color="primary" />
                </div>
                <div v-if="infoByServiceType" class="mb-6 pt-4">
                    <component
                        :is="infoComponent"
                        v-model="infoByServiceType"
                        :info="info"
                        :additional-options="additionalOptions"
                        :service="service"
                        :car-points-data="carBookingData"
                    />
                </div>
                <extra-services-form
                    v-if="isOwnProduct && additionalOptions.extraServices && additionalOptions.extraServices.length"
                    v-model="extraServices"
                    :additional-options="additionalOptions"
                    manual-modification
                />
            </v-card-text>
            <v-card-actions class="justify-center py-5">
                <v-btn color="primary" small class="mr-5" :loading="loading" @click="confirm">
                    {{ $t('confirm') }}
                </v-btn>
                <v-btn color="white" small @click="modal = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {EventBus, SHOW_MODIFY_MANUAL_MODAL} from '@/utils/event-bus'
    import ModifyManualStatus from '@/components/account/modify/manual/ModifyManualStatus'
    import {clone, getServiceTypeIcon, StrToArray} from '@/utils/helpers'
    import ModifyManualTraveler from '@/components/account/modify/manual/ModifyManualTraveler'
    import ModifyManualRoom from '@/components/account/modify/manual/ModifyManualRoom'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import ModifyManualSalesTerm from '@/components/account/modify/manual/ModifyManualSalesTerm'
    import ModifyManualAccommodation from '@/components/account/modify/manual/serviceType/ModifyManualAccommodation'
    import ModifyManualFlight from '@/components/account/modify/manual/serviceType/ModifyManualFlight'
    import ModifyManualActivity from '@/components/account/modify/manual/serviceType/ModifyManualActivity'
    import ModifyManualCarRent from '@/components/account/modify/manual/serviceType/ModifyManualCarRent'
    import ModifyManualTrain from '@/components/account/modify/manual/serviceType/ModifyManualTrain'
    import ModifyManualTransfer from '@/components/account/modify/manual/serviceType/ModifyManualTransfer'
    import ModifyManualInsurance from '@/components/account/modify/manual/serviceType/ModifyManualInsurance'
    import ModifyManualCruise from '@/components/account/modify/manual/serviceType/ModifyManualCruise'
    import {appInstance} from '@/utils/app-accessor'
    import ServiceAdditionalOptionsMixin from '@/components/account/mixins/ServiceAdditionalOptionsMixin.vue'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm.vue'
    import {blobToString} from '@/utils/file-utils'

    @Component({
        components: {
            ExtraServicesForm,
            ModifyManualActivity,
            ModifyManualFlight,
            ModifyManualCarRent,
            ModifyManualAccommodation,
            ModifyManualInsurance,
            ModifyManualTrain,
            ModifyManualTransfer,
            ModifyManualCruise,
            ModifyManualSalesTerm,
            ModifyManualTraveler,
            ModifyManualRoom,
            ModifyManualStatus,
        },
    })
    export default class ModifyManualModal extends ServiceAdditionalOptionsMixin {
        @Prop() info
        @Prop({required: true}) clientType
        @Prop() orderId
        @Prop() customerCompanyId

        modal = false
        errors = null
        service = null
        loading = false
        loadingInitial = false
        title = 'Manual modify service'
        fileObject = null

        travelers = []
        rooms = []
        status = null
        refNumber = null
        salesTerms = null
        salesSettings = null
        salesSettingsLoaded = false
        pricingRules = null
        pricingRulesLoaded = false

        infoByServiceType = null
        isNewTravelerChild = false
        dueToConfirmDate = null

        ownHotelMealTypes = []
        ownHotelRooms = []
        carBookingData = {}
        applyClientAdjustedPrice = false

        isValid = false

        created() {
            EventBus.$on(SHOW_MODIFY_MANUAL_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_MODIFY_MANUAL_MODAL, this.showModal)
        }

        async showModal(service) {
            this.modal = true
            this.loadingInitial = true
            this.service = service
            if (this.service.serviceType === 'ACCOMMODATION') await this.initDataRoomAndMealTypesData()
            if (this.service.serviceType === 'CARRENT') await this.initCarBookingData()
            this.errors = null
            this.loading = false
            this.travelers = this.service.travelers.map(traveler => {
                const alternativeMealTypeId = this.ownHotelMealTypes.find(
                    el => el.name?.toLowerCase() === traveler.mealType?.toLowerCase()
                )?.id
                const roomRph = this.serviceDetails.rooms?.findIndex(room =>
                    room.travelerIds.travelerId.some(t => t === traveler.travelerId)
                )

                return {
                    prefix: traveler.prefix,
                    firstName: traveler.name[0].firstName,
                    lastName: traveler.name[0].lastName,
                    middleName: traveler.name[0].middleName,
                    dateOfBirth: traveler.dateOfBirth,
                    travelerId: traveler.travelerId,
                    type: traveler.isChild ? 'child' : 'adult',
                    ...(this.isOwnProduct && alternativeMealTypeId && {alternativeMealTypeId}),
                    ...(roomRph >= 0 && {roomRph}),
                    delete: false,
                }
            })

            let status = runtimeStore.orderServiceStatus(this.service.status)
            if (status === 'PRECONFIRMED') {
                status = 'PRE-BOOKING'
            } else if (status === 'CONFIRMATION_PENDING') {
                status = 'CONFIRMATION PENDING'
            }
            this.dueToConfirmDate = this.service.dueToConfirmDate
            this.status = status
            this.refNumber = this.service.refNum
            this.salesTerms = [...this.service.salesTerms].map(e => ({
                type: e.type,
                price: {
                    amount: e.price.amount,
                    currency: e.price.currency,
                    commission: {
                        amount: e.price.commission,
                    },
                    ...(this.isShowClientCommissionVAT &&
                        e.type === 'CLIENT' && {commissionVatAmount: e.commissionVatAmount || 0}),
                },
                cancelPenalty: [...e.cancelPenalty]
                    .filter(p => !p.noShow)
                    .map(p => ({
                        amount: p.amount,
                        endDateTime: p.endDateTime?.split(' ')[0] || '',
                        startDateTime: p.startDateTime?.split(' ')[0] || '',
                    }))
                    .sort((a, b) => {
                        return new Date(a.startDateTime) - new Date(b.startDateTime)
                    }),
            }))
            this.applyClientAdjustedPrice = false

            if (this.isOwnProduct) await this.loadAdditionalOptions(this.service)

            switch (this.service.serviceType) {
                case 'ACCOMMODATION':
                    this.rooms = this.service.serviceDetails[0].rooms?.reduce((rooms, room) => {
                        const data = {}
                        if (this.ownHotelRooms.length) {
                            data.roomId = this.ownHotelRooms.find(
                                el => el.name.toLowerCase() === room.roomTypeName.toLowerCase()
                            )?.id
                        } else {
                            data.roomTypeName = room.roomTypeName || null
                        }
                        if (this.ownHotelMealTypes.length) {
                            data.roomMealTypeId = this.ownHotelMealTypes.find(
                                el => el.name.toLowerCase() === room.mealTypeName.toLowerCase()
                            )?.id
                        } else {
                            data.mealTypeName = room.mealTypeName || null
                        }

                        rooms.push(data)

                        return rooms
                    }, [])
                    ;(() => {
                        const infoByServiceType = {
                            accommodation: {
                                startDate: this.service.startDateTime.split(' ')[0],
                                endDate: this.service.endDateTime.split(' ')[0],
                                rooms: this.rooms,
                            },
                        }
                        const {earlyCheckIn, lateCheckOut} = this.service.serviceDetails[0]
                        const checkInExtraService = this.service.extraServices.find(
                            ({type}) => type === 'EARLY_CHECKIN'
                        )
                        if (checkInExtraService) {
                            const {nettoPriceValue, bruttoPriceValue} = this.mapExtraServiceData(checkInExtraService)
                            infoByServiceType.accommodation.earlyCheckIn = {
                                time: earlyCheckIn?.time,
                                nettoPriceValue,
                                bruttoPriceValue,
                                name: this.additionalOptions?.earlyCheckIn?.[0]?.name || this.$t('earlyCheckIn'),
                            }
                        } else {
                            const val =
                                this.additionalOptions.earlyCheckIn?.find(({time}) => time === earlyCheckIn?.time) ||
                                this.additionalOptions.earlyCheckIn?.length
                                    ? this.additionalOptions.earlyCheckIn[0]
                                    : null
                            infoByServiceType.accommodation.earlyCheckIn = {
                                time: earlyCheckIn?.time,
                                nettoPriceValue: val?.price.amount || 0,
                                bruttoPriceValue: val?.price.amount || 0,
                                name: this.additionalOptions.earlyCheckIn?.[0]?.name || this.$t('earlyCheckIn'),
                            }
                        }
                        const checkOutExtraService = this.service.extraServices.find(
                            ({type}) => type === 'LATE_CHECKOUT'
                        )
                        if (checkOutExtraService) {
                            const {nettoPriceValue, bruttoPriceValue} = this.mapExtraServiceData(checkOutExtraService)
                            infoByServiceType.accommodation.lateCheckOut = {
                                time: lateCheckOut?.time,
                                nettoPriceValue,
                                bruttoPriceValue,
                                name: this.additionalOptions?.lateCheckOut?.[0]?.name || this.$t('lateCheckOut'),
                            }
                        } else {
                            const val =
                                this.additionalOptions.lateCheckOut?.find(({time}) => time === lateCheckOut?.time) ||
                                this.additionalOptions.lateCheckOut?.length
                                    ? this.additionalOptions.lateCheckOut[0]
                                    : null

                            infoByServiceType.accommodation.lateCheckOut = {
                                time: lateCheckOut?.time,
                                nettoPriceValue: val?.price.amount || 0,
                                bruttoPriceValue: val?.price.amount || 0,
                                name: this.additionalOptions?.lateCheckOut?.[0]?.name || this.$t('lateCheckOut'),
                            }
                        }
                        this.infoByServiceType = infoByServiceType
                    })()
                    break
                case 'FLIGHT':
                case 'OWNCHARTER':
                    ;(() => {
                        const baggageInfo = [],
                            seats = [],
                            segments = []
                        const initialBaggage = this.service.serviceDetails[0].flightExtraServices.filter(
                            ({type}) => type === 'BAGGAGE'
                        )
                        this.service.serviceDetails[0].itineraries.forEach(({flightSegments}, itineraryIndex) => {
                            flightSegments.forEach(
                                (
                                    {
                                        departureAirportCode,
                                        departureDate,
                                        arrivalAirportCode,
                                        arrivalDate,
                                        departureTerminal,
                                        arrivalTerminal,
                                        flightNumber,
                                        serviceClass,
                                        seats: initialSeats,
                                    },
                                    segmentIndex
                                ) => {
                                    const segmentRPH = `${itineraryIndex + 1}${segmentIndex + 1}`
                                    segments.push({
                                        departureAirportCode,
                                        departureDate: departureDate.replace(' ', 'T'),
                                        arrivalAirportCode,
                                        arrivalDate: arrivalDate.replace(' ', 'T'),
                                        departureTerminal,
                                        arrivalTerminal,
                                        flightNumber,
                                        serviceClass: serviceClass.toUpperCase().replace(' ', '_'),
                                        itineraryIndex, //For identify segment in ModifyManualFLight
                                    })
                                    this.travelers.forEach((tr, travelerIndex) => {
                                        const initialBaggageItem = initialBaggage.find(
                                            ({travelerRPH, segmentRphs}) =>
                                                travelerRPH === `${travelerIndex + 1}` &&
                                                segmentRphs
                                                    .map(segmentRPH => segmentRPH[0])
                                                    .includes(`${itineraryIndex + 1}`)
                                        )
                                        const baggageInfoIndex = baggageInfo.findIndex(
                                            ({segmentRphs, travelerRPH}) =>
                                                String(segmentRphs[0])[0] === `${itineraryIndex + 1}` &&
                                                travelerRPH === `${travelerIndex + 1}`
                                        )
                                        if (baggageInfoIndex === -1) {
                                            baggageInfo.push({
                                                travelerRPH: `${travelerIndex + 1}`,
                                                quantity: initialBaggageItem?.quantity || 0,
                                                segmentRphs: [segmentRPH],
                                                status: initialBaggageItem?.status,
                                                name: initialBaggageItem?.name || '',
                                                type: 'BAGGAGE',
                                            })
                                        } else {
                                            baggageInfo[baggageInfoIndex].segmentRphs.push(segmentRPH)
                                        }
                                        const initialSeatItem = initialSeats.find(
                                            ({travelerRPH}) => `${travelerIndex + 1}` === travelerRPH
                                        )
                                        seats.push({
                                            travelerRPH: `${travelerIndex + 1}`,
                                            segmentRPH,
                                            seatNumber: initialSeatItem?.seatNumber || '',
                                            rowNumber: initialSeatItem?.rowNumber || '',
                                        })
                                    })
                                }
                            )
                        })
                        this.infoByServiceType = {
                            flight: {
                                lastTicketingDate: this.service.serviceDetailsContainer.flight.lastTicketingDate
                                    ?.split(' ')
                                    .join('T'),
                                marketingAirline: this.serviceDetails.itineraries[0].flightSegments[0].marketingAirline,
                                segments,
                                flightExtraServices: baggageInfo,
                                seats,
                            },
                        }
                    })()
                    break
                case 'TRANSFER':
                    this.infoByServiceType = {
                        transfer: {
                            startDateTime: this.serviceDetails.pickupTime
                                ? [this.service.startDateTime.split(' ')[0], this.serviceDetails.pickupTime].join('T')
                                : this.service.startDateTime.split(' ').join('T'),
                            departure: {
                                ...(this.serviceDetails.departurePlace.airportCode && {
                                    flightInfo: {
                                        otherAirportCode: this.serviceDetails.departurePlace.otherAirportCode,
                                        flightNumber: this.serviceDetails.departurePlace.flightNumber,
                                        dateTime: this.serviceDetails.departurePlace?.dateTime?.split(' ').join('T'),
                                    },
                                }),
                                ...((this.serviceDetails.departurePlace.trainNumber ||
                                    this.serviceDetails.departurePlace.trainStationCode) && {
                                    trainInfo: {
                                        cityId: this.serviceDetails.departurePlace.cityId,
                                        trainNumber: this.serviceDetails.departurePlace.trainNumber,
                                        dateTime: this.serviceDetails.departurePlace?.dateTime?.split(' ').join('T'),
                                    },
                                }),
                                ...((this.serviceDetails.departurePlace.address ||
                                    !Object.keys(this.serviceDetails.departurePlace).length) && {
                                    address: {
                                        address: this.serviceDetails.departurePlace.address,
                                        postalCode: this.serviceDetails.departurePlace.postalCode,
                                    },
                                }),
                                ...(this.serviceDetails.departurePlace.hotelName && {
                                    hotel: {
                                        hotelName: this.serviceDetails.departurePlace.hotelName,
                                    },
                                }),
                                ...((this.serviceDetails.departurePlace.shipNumber ||
                                    this.serviceDetails.departurePlace.portCode) && {
                                    shipInfo: {
                                        shipNumber: this.serviceDetails.departurePlace.shipNumber || '',
                                        cityId: this.serviceDetails.departurePlace.cityId,
                                        dateTime: this.serviceDetails.departurePlace?.dateTime?.split(' ').join('T'),
                                    },
                                }),
                                ...((this.serviceDetails.departurePlace.latitude ||
                                    this.serviceDetails.departurePlace.longitude) && {
                                    manualInfo: {
                                        name: this.serviceDetails.departurePlace.name,
                                        latitude: this.serviceDetails.departurePlace.latitude,
                                        longitude: this.serviceDetails.departurePlace.longitude,
                                    },
                                }),
                            },
                            arrival: {
                                ...(this.service.serviceDetails[0].arrivalPlace.airportCode && {
                                    flightInfo: {
                                        otherAirportCode: this.service.serviceDetails[0].arrivalPlace.otherAirportCode,
                                        flightNumber: this.service.serviceDetails[0].arrivalPlace.flightNumber,
                                        dateTime: this.service.serviceDetails[0].arrivalPlace?.dateTime
                                            ?.split(' ')
                                            .join('T'),
                                    },
                                }),
                                ...((this.service.serviceDetails[0].arrivalPlace.trainNumber ||
                                    this.serviceDetails.departurePlace.trainStationCode) && {
                                    trainInfo: {
                                        cityId: this.serviceDetails.arrivalPlace.cityId,
                                        trainNumber: this.service.serviceDetails[0].arrivalPlace.trainNumber,
                                        dateTime: this.service.serviceDetails[0].arrivalPlace?.dateTime
                                            ?.split(' ')
                                            .join('T'),
                                    },
                                }),
                                ...((this.serviceDetails.arrivalPlace.address ||
                                    !Object.keys(this.serviceDetails.arrivalPlace).length) && {
                                    address: {
                                        address: this.serviceDetails.arrivalPlace.address,
                                        postalCode: this.serviceDetails.arrivalPlace.postalCode,
                                    },
                                }),
                                ...(this.serviceDetails.arrivalPlace.hotelName && {
                                    hotel: {
                                        hotelName: this.serviceDetails.arrivalPlace.hotelName,
                                    },
                                }),
                                ...((this.serviceDetails.arrivalPlace.shipNumber ||
                                    this.serviceDetails.arrivalPlace.portCode) && {
                                    shipInfo: {
                                        shipNumber: this.serviceDetails.arrivalPlace.shipNumber || '',
                                        cityId: this.serviceDetails.arrivalPlace.cityId,
                                        dateTime: this.serviceDetails.arrivalPlace?.dateTime?.split(' ').join('T'),
                                    },
                                }),
                                ...((this.serviceDetails.arrivalPlace.latitude ||
                                    this.serviceDetails.arrivalPlace.longitude) && {
                                    manualInfo: {
                                        name: this.serviceDetails.arrivalPlace.name,
                                        latitude: this.serviceDetails.arrivalPlace.latitude,
                                        longitude: this.serviceDetails.arrivalPlace.longitude,
                                    },
                                }),
                            },
                        },
                    }
                    break
                case 'ACTIVITY':
                    this.infoByServiceType = {
                        activity: {
                            startDateTime: [
                                this.service.serviceDetails[0].startDate,
                                this.service.serviceDetails[0].pickupTime,
                            ].join('T'),
                            ...(this.serviceDetails.durationTime && {durationTime: this.serviceDetails.durationTime}),
                            ...(this.serviceDetails.durationDays && {durationDays: this.serviceDetails.durationDays}),
                        },
                    }
                    break
                case 'CARRENT':
                    this.infoByServiceType = {
                        carRent: {
                            startDateTime: this.service.startDateTime.split(' ').join('T'),
                            endDateTime: this.service.endDateTime.split(' ').join('T'),
                            pickUpPoint: this.serviceDetails?.pickUpPoint?.pointName
                                ? this.carBookingData.pickUpPoints.find(
                                      point => point.name === this.serviceDetails.pickUpPoint.pointName
                                  ).locationCode
                                : null,
                            dropOffPoint: this.serviceDetails?.dropOffPoint?.pointName
                                ? this.carBookingData.returnPoints.find(
                                      point => point.name === this.serviceDetails.dropOffPoint.pointName
                                  ).locationCode
                                : null,
                        },
                    }
                    break
                case 'TRAIN':
                    this.infoByServiceType = {
                        train: {
                            startDateTime: this.service.startDateTime.split(' ')[0],
                            endDateTime: this.service.endDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'INSURANCE':
                    this.infoByServiceType = {
                        insurance: {
                            startDateTime: this.service.startDateTime.split(' ')[0],
                            endDateTime: this.service.endDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'OWNVISA':
                    this.infoByServiceType = {
                        visa: {
                            startDate: this.service.startDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'CRUISE':
                    this.infoByServiceType = {
                        cruise: {
                            startDate: this.service.startDateTime.split(' ')[0],
                            endDate: this.service.endDateTime.split(' ')[0],
                            cruiseLine: this.service.serviceDetails[0].cruiseLine,
                            shipName: this.service.serviceDetails[0].shipName,
                            cabinCategory: this.service.serviceDetails[0].cabinCategoryName,
                            cabinTypeName: this.service.serviceDetails[0].cabinTypeName,
                            deckName: this.service.serviceDetails[0].deckName,
                            deckNumber: this.service.serviceDetails[0].deckNumber,
                            cabinNumber: this.service.serviceDetails[0].cabinNumber,
                        },
                    }
                    break
                default:
                    this.infoByServiceType = null
            }

            await this.loadSalesSettings()
            if (
                this.salesTerms.find(e => e.type === 'SUPPLIER')?.price?.amount === 0 ||
                this.salesTerms.find(e => e.type === 'CLIENT')?.price?.amount === 0
            ) {
                await this.loadPricingRules()
            }
            this.pricingRulesLoaded = true

            this.loadingInitial = false
        }

        async loadSalesSettings() {
            this.salesSettingsLoaded = false

            try {
                this.salesSettings = await this.$api.salesSettings.get({clientCompanyId: this.customerCompanyId})

                // eslint-disable-next-line no-empty
            } catch (e) {}

            this.salesSettingsLoaded = true
        }

        async loadPricingRules() {
            this.pricingRulesLoaded = false

            let type
            switch (this.service.serviceType) {
                case 'ACCOMMODATION':
                    type = 'accommodation'
                    break
                case 'TRANSFER':
                    type = 'transfer'
                    break
                case 'FLIGHT':
                    type = 'flight'
                    break
                case 'ACTIVITY':
                case 'EXCURSION':
                    type = 'excursion'
                    break
                case 'CAR_RENT':
                    type = 'carRent'
                    break
                case 'INSURANCE':
                    type = 'insurance'
                    break
                case 'TOUR':
                    type = 'tour'
                    break
                case 'VISA':
                    type = 'ownVisa'
                    break
                default:
                    break
            }

            try {
                this.pricingRules = await this.$api.pricingRules.get({
                    clientCompanyId: this.customerCompanyId,
                    productType: type,
                    supplierCode: this.service.supplierCode,
                })

                // eslint-disable-next-line no-empty
            } catch (e) {}

            this.pricingRulesLoaded = true
        }

        async initDataRoomAndMealTypesData() {
            if (!this.service.serviceDetails[0].hotelId) return

            try {
                const {contracts} = await appInstance.$api.contracts.get({
                    hotelId: this.service.serviceDetails[0].hotelId,
                })
                if (contracts.length) {
                    this.ownHotelRooms = contracts[0].contractDetails.ownHotelRooms.filter(el => el.active)
                    this.ownHotelMealTypes = contracts[0].contractDetails.ownHotelMealTypes.filter(el => el.active)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async initCarBookingData() {
            try {
                const res = await this.$api.carBookingData.get({processId: this.service.processId})

                if (res.pickUpPoints && res.returnPoints) {
                    res.pickUpPoints.forEach(point => (point.itemText = point.name || point.address))
                    res.returnPoints.forEach(point => (point.itemText = point.name || point.address))
                    this.carBookingData = res
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        changeTravelerMealType(roomIndex) {
            if (!this.isOwnProduct) return

            const travelerIds = this.serviceDetails.rooms[roomIndex].travelerIds.travelerId
            this.travelers.forEach(traveler => {
                if (travelerIds.some(id => id === traveler.travelerId)) {
                    traveler.alternativeMealTypeId = this.rooms[roomIndex].roomMealTypeId
                }
            })
        }

        changeRoomMealType(trevelerId) {
            const roomIndex = this.serviceDetails.rooms.findIndex(room =>
                room.travelerIds.travelerId.some(id => id === trevelerId)
            )

            if (roomIndex >= 0) this.rooms[roomIndex].roomMealTypeId = null
        }

        async confirm() {
            this.makeValidation(this.$refs.modifyManualModal)
            if (!this.isValid) return

            try {
                this.loading = true
                await this.$api.manualModification.post(this.request)

                if (this.$config.reservationReport && this.service.processId) {
                    await this.markAsNotSentToSupplier()
                }

                this.$emit('modifyService')
            } catch (e) {
                console.error(e)
                if (e.msg) {
                    this.errors = [{message: e.msg}]
                } else if (e.length) {
                    this.errors = []
                    e.forEach(error => this.errors.push({message: error.msg}))
                } else {
                    this.errors = e.errors
                }
                this.errors.forEach(error => {
                    this.$toast.error(error.message)
                })
            } finally {
                this.loading = false
                this.modal = false
            }
        }

        async markAsNotSentToSupplier() {
            await this.$api.orders.put(this.orderId, {
                servicesInfo: [
                    {
                        processId: this.service?.processId,
                        serviceSentToSupplier: false,
                    },
                ],
            })
        }

        removeTraveler(index) {
            this.travelers[index].delete = true
        }

        addTraveler(roomIndex = null, data = {}) {
            const {prefix, firstName, middleName, lastName, dateOfBirth, type} = data

            this.travelers.push({
                ...(prefix && {prefix}),
                ...(firstName && {firstName}),
                ...(middleName && {middleName}),
                ...(lastName && {lastName}),
                ...(dateOfBirth && {dateOfBirth}),
                type: type ? type : this.isNewTravelerChild ? 'child' : 'adult',
                ...(this.ownHotelMealTypes.length && {
                    alternativeMealTypeId: this.rooms[roomIndex].roomMealTypeId || this.ownHotelMealTypes[0].id,
                }),
                ...(roomIndex >= 0 && {roomRph: roomIndex}),
                delete: false,
            })
        }

        recalculateClientPrice() {
            this.$refs.CLIENTSaleTerm?.[0]?.calculatePriceAmount()
            this.$refs.CLIENTSaleTerm?.[0]?.calculateMarkup()
            this.$refs.CLIENTSaleTerm?.[0]?.calculateCommission()
            this.$refs.CLIENTSaleTerm?.[0]?.calculateCommissionVat()
        }

        async importTravelers(roomIndex) {
            const strData = await blobToString(this.fileObject)
            const arr = StrToArray(strData)
            const travelersList = arr.splice(1, arr.length - 1)

            travelersList.map(item => {
                const traveler = {
                    prefix: item[0] && ['mr', 'ms', 'mrs'].includes(item[0].toLowerCase()) ? item[0] : '',
                    firstName: item[1] || '',
                    middleName: item[2] || '',
                    lastName: item[3] || '',
                    dateOfBirth: item[4] ? this.$dateFns.format(new Date(item[4])) : '',
                    type: item[5] && ['child', 'adult'].includes(item[5].toLowerCase()) ? item[5].toLowerCase() : null,
                }

                if (item.length > 1) this.addTraveler(roomIndex, traveler)
            })
            this.fileObject = null
        }

        mapExtraServiceData({id, quantity, prices, required}) {
            const nettoPriceValue = prices.find(({priceType}) => priceType === 'SUPPLIER').amount,
                bruttoPriceValue = prices.find(({priceType}) => priceType === 'CLIENT').amount
            return {extraServiceId: id, quantity, nettoPriceValue, bruttoPriceValue, ...(required && {required})}
        }

        makeValidation(selectiveForm = null) {
            const validation = this.validNestedForms(selectiveForm)
            this.isValid = validation.validForms.every(valid => valid)
            if (!this.isValid) {
                const indexLink = validation.validForms.findIndex(el => !el)
                this.$vuetify.goTo(validation.links[indexLink], {
                    duration: 500,
                    easing: 'linear',
                })
            }
        }

        validNestedForms(comp) {
            let validForms = []
            let links = []
            if (comp.$refs.form && comp.$refs.form.validate) {
                validForms.push(comp.$refs.form.validate())
                links.push(comp)
            }
            if (comp.$children.length) {
                comp.$children.forEach(childComp => {
                    validForms = [...validForms, ...this.validNestedForms(childComp).validForms]
                    links.push(...this.validNestedForms(childComp).links)
                })
            }
            return {validForms, links}
        }

        get isShowClientCommissionVAT() {
            return this.clientType !== 'DIRECT_SALES' && this.clientType !== 'CORPORATE'
        }

        get request() {
            const infoByServiceType = clone(this.infoByServiceType)

            if (infoByServiceType?.flight?.flightExtraServices) {
                infoByServiceType.flight.flightExtraServices = infoByServiceType.flight.flightExtraServices?.filter(
                    el => el.name && el.quantity > 0 && el.status
                )
            }

            const request = {
                processId: this.service.processId,
                status: this.status,
                refNum: this.refNumber,
                salesTerm: this.salesTerms.map(s => {
                    const salesTermCopy = {...s}
                    delete salesTermCopy.price.currency
                    if (salesTermCopy.price.commissionVatAmount !== undefined) {
                        salesTermCopy.price.commission.commissionVatAmount = salesTermCopy.price.commissionVatAmount
                        delete salesTermCopy.price.commissionVatAmount
                    }
                    return salesTermCopy
                }),
                travelers: clone(this.travelers),
                ...infoByServiceType,
                ...(this.status === 'PRE-BOOKING' && {dueToConfirmDate: this.dueToConfirmDate.split(' ').join('T')}),
                ...(this.isOwnProduct && {
                    extraServices: [
                        ...this.extraServices,
                        ...this.service.extraServices
                            .filter(({required}) => required)
                            .map(extraService => this.mapExtraServiceData(extraService)),
                    ],
                }),
                applyClientAdjustedPrice: this.applyClientAdjustedPrice,
            }

            if (request.flight) {
                // eslint-disable-next-line no-unused-vars
                request.flight.segments = request.flight.segments.map(({itineraryIndex, ...segment}) => segment)
                if (this.isOwnProduct) {
                    delete request.flight.flightExtraServices
                    delete request.flight.seats
                    request.extraServices.push(
                        ...this.service.extraServices
                            .filter(({id}) =>
                                this.additionalOptions.extraBaggageOptions
                                    ?.map(({serviceRPH}) => +serviceRPH)
                                    .includes(id)
                            )
                            .map(extraService => this.mapExtraServiceData(extraService))
                    )
                }
            }
            if (request.accommodation) {
                if (!request.accommodation.earlyCheckIn.time) {
                    request.accommodation.earlyCheckIn = null
                }
                if (!request.accommodation.lateCheckOut.time) {
                    request.accommodation.lateCheckOut = null
                }
            }
            if (request.travelers.some(t => t?.roomRph >= 0)) {
                request.travelers.forEach(t => (t.roomRph += 1))
            }

            return request
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get infoComponent() {
            switch (this.service.serviceType) {
                case 'ACCOMMODATION':
                    return 'modify-manual-accommodation'
                case 'FLIGHT':
                case 'OWNCHARTER':
                    return 'modify-manual-flight'
                case 'ACTIVITY':
                    return 'modify-manual-activity'
                case 'TRANSFER':
                    return 'modify-manual-transfer'
                case 'CARRENT':
                    return 'modify-manual-car-rent'
                case 'TRAIN':
                    return 'modify-manual-train'
                case 'INSURANCE':
                    return 'modify-manual-insurance'
                case 'OWNVISA':
                    return 'modify-manual-visa'
                case 'CRUISE':
                    return 'modify-manual-cruise'
                default:
                    return null
            }
        }

        get serviceIcon() {
            return getServiceTypeIcon(this.service?.serviceType)
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service?.supplierCode)
        }

        get statusList() {
            const list = ['CONFIRMED', 'CANCELED', 'REJECTED', 'CONFIRMATION PENDING', 'QUOTE', 'PRE-BOOKING']

            if (
                ['OWNCHARTER', 'FLIGHT'].includes(this.service?.serviceType) &&
                !this.service?.supplierCode.includes('company.')
            ) {
                const index = list.findIndex(el => el === 'CONFIRMATION PENDING')
                list.splice(index, 1)
            }

            return list
        }
    }
</script>
