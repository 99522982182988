<template>
    <v-dialog v-model="modal" width="600">
        <v-card>
            <v-card-title class="justify-space-between">
                {{ $t('flights.baggage_rules') }}
                <v-icon class="justify-end" @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>
                                {{ $t('flights.flight') }}
                            </th>
                            <th class="text-capitalize">
                                {{ $tc('passenger', 1) }}
                            </th>
                            <th>
                                {{ $t('flights.baggage') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(
                                {itineraryName, passengerType, baggage: {unitCode, unitQuantity}}, index
                            ) of baggageInfo"
                            :key="index"
                        >
                            <td>
                                {{ itineraryName }}
                            </td>
                            <td>
                                {{ $t(`passengerType.${passengerType}`) }}
                            </td>
                            <td v-if="+unitQuantity > 0">
                                {{ unitQuantity }} {{ $tc(`flights.baggageType.${unitCode}`, +unitQuantity) }}
                            </td>
                            <td v-else>
                                {{ $t('not_included') }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class FlightBaggageRules extends Vue {
        @VModel() modal
        @Prop({required: true, type: Object}) service

        get baggageInfo() {
            return this.service.serviceDetailsContainer.flight.itineraries
                .map(({itineraryName, flightSegments: [{baggageInfo}]}) =>
                    baggageInfo.map(baggageInfo => ({itineraryName, ...baggageInfo}))
                )
                .flat()
        }
    }
</script>
