<template>
    <strong class="font-weight-bold">{{ price | price }}</strong>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class RoomPriceLabel extends Vue {
        @Prop({required: true}) price
        @Prop() room
    }
</script>
