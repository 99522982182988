<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {AUTH_EVENT, EventBus} from '@/utils/event-bus'

    @Component
    export default class SearchPageAuthMixin extends Vue {
        created() {
            EventBus.$on(AUTH_EVENT, this.search)
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.search)
        }
    }
</script>
