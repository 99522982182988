<template>
    <div>
        <breadcrumbs />
        <div class="mt-4">
            <payments-page-schedule :_is-manual-invoices-loading.sync="isManualInvoicesLoading" />
        </div>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import PaymentsPageSchedule from '@/components/account/PaymentsPageSchedule'

    @Component({
        components: {
            PaymentsPageSchedule,
            Breadcrumbs,
        },
        middleware: ['auth', 'forbidden'],
    })
    export default class PaymentsPage extends Vue {
        isManualInvoicesLoading = false
    }
</script>
