<template>
    <div v-if="tripadvisorRating.rating || tripadvisorRating.reviewsURL" class="tripadvisor">
        <template v-if="showLabel">
            <div v-if="!tripadvisorRating.reviewsURL" class="text-caption">
                {{ $t('tripadvisor_rating') }}
            </div>
            <div v-else class="text-caption">
                <a :href="tripadvisorRating.reviewsURL" target="_blank">{{ $t('tripadvisor_rating') }}</a>
            </div>
        </template>
        <img v-if="!tripadvisorRating.rating" :src="'/tripadvisor/ta.svg' | srcHost" alt="Tripadvisor" height="20" />
        <img
            v-else
            :src="`/tripadvisor/ta-${tripadvisorRating.rating * 10}.svg` | srcHost"
            :alt="tripadvisorRating.rating"
        />
        <div v-if="tripadvisorRating.reviewsCount" class="text-caption">
            {{ $t('based_on') }} <strong>{{ $tc('review', tripadvisorRating.reviewsCount) }}</strong>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    //TODO Implement reviewsURL
    @Component
    export default class HotelTripadvisorRatingLabel extends Vue {
        @Prop() tripadvisorRating

        get showLabel() {
            return true
        }
    }
</script>
