<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" max-width="300" max-height="400" offset-y>
        <template v-slot:activator="{on}">
            <v-text-field
                :value="nightsLabel"
                background-color="white"
                filled
                outlined
                single-line
                hide-details
                readonly
                v-on="on"
            />
        </template>
        <v-card>
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-row dense>
                            <v-col cols="6" class="text-center">
                                <label>{{ $t('from') }}</label>
                                <v-text-field
                                    :key="keyNightsFrom"
                                    :value="nightsFrom"
                                    append-outer-icon="mdi-plus"
                                    prepend-icon="mdi-minus"
                                    type="number"
                                    class="pa-0 ma-0 input--no-arrows"
                                    @click:append-outer="increment('nightsFrom')"
                                    @click:prepend="decrement('nightsFrom')"
                                    @input="inputNightsFrom"
                                    @change="changeNightsFrom"
                                />
                            </v-col>
                            <v-col cols="6" class="text-center">
                                <label>{{ $t('tours.to') }}</label>
                                <v-text-field
                                    :key="keyNightsTo"
                                    :value="nightsTo"
                                    append-outer-icon="mdi-plus"
                                    prepend-icon="mdi-minus"
                                    type="number"
                                    class="pa-0 ma-0 input--no-arrows"
                                    @click:append-outer="increment('nightsTo')"
                                    @click:prepend="decrement('nightsTo')"
                                    @input="inputNightsTo"
                                    @change="changeNightsTo"
                                />
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    import {Vue, Component, PropSync} from 'nuxt-property-decorator'

    @Component
    export default class NightsSelectMenu extends Vue {
        @PropSync('_nightsFrom', {default: 2}) nightsFrom
        @PropSync('_nightsTo', {default: 5}) nightsTo

        menu = false
        keyNightsFrom = 0
        keyNightsTo = 0

        beforeDestroy() {
            this.$refs.menu.activatorNode = null
        }

        increment(type) {
            if (type === 'nightsFrom') {
                if (this.nightsFrom === 30) {
                    this.nightsTo = this.nightsFrom
                    return
                }
                this.nightsFrom++
            }
            if (type === 'nightsTo') {
                if (this.nightsTo === 30) return
                else this.nightsTo++
            }

            this.$nextTick(() => {
                if (this.nightsFrom >= this.nightsTo) this.nightsTo = this.nightsFrom
            })
        }

        decrement(type) {
            if (type === 'nightsFrom') {
                if (this.nightsFrom === 1) return
                this.nightsFrom--
            }
            if (type === 'nightsTo') {
                if (this.nightsTo === 1) {
                    this.nightsFrom = this.nightsTo
                    return
                }
                this.nightsTo--
            }

            this.$nextTick(() => {
                if (this.nightsTo <= this.nightsFrom) this.nightsFrom = this.nightsTo
            })
        }

        inputNightsFrom(v) {
            let value = !v ? 0 : parseInt(v.length > 2 ? v.slice(-1) : v, 10)
            if (value < 1 && String(value).length > 1) value = 1
            else if (value === 0 && String(value).length <= 1) value = 1
            else if (value > 30) value = 30
            if (this.nightsFrom === value) this.keyNightsFrom++
            this.nightsFrom = value
        }

        inputNightsTo(v) {
            let value = !v ? 0 : parseInt(v.length > 2 ? v.slice(-1) : v, 10)
            if (value < 1 && String(value).length > 1) value = 1
            else if (value === 0 && String(value).length <= 1) value = 1
            else if (value > 30) value = 30
            if (this.nightsTo === value) this.keyNightsTo++
            this.nightsTo = value
        }

        changeNightsFrom() {
            if (this.nightsFrom > this.nightsTo) this.nightsTo = this.nightsFrom
        }

        changeNightsTo() {
            if (this.nightsFrom > this.nightsTo) this.nightsFrom = this.nightsTo
        }

        get nightsLabel() {
            return `${this.nightsFrom} - ${this.nightsTo}  ${this.$tc('night', this.nightsTo)}`
        }
    }
</script>
