<template>
    <v-data-table
        :headers="headers"
        :items="templates"
        :loading="loading"
        disable-sort
        :search="recipientType"
        :custom-filter="recipientTypeFilter"
        class="mt-3"
    >
        <template v-slot:top>
            <div class="text-h6 pl-3">
                {{ $t('admin.email_templates') }}
            </div>
            <v-select
                v-if="recipientTypes.length"
                v-model="recipientType"
                :items="recipientTypes"
                :label="$t('admin.recipient_type')"
                class="pl-3 filter-input"
            >
                <template v-slot:selection="{item}">
                    {{ $t(`admin.recipientType.${item}`) }}
                </template>
                <template v-slot:item="{item}">
                    {{ $t(`admin.recipientType.${item}`) }}
                </template>
            </v-select>
        </template>
        <template v-slot:item.notificationType="{item}">
            {{ $t(`admin.notificationType.${item.notificationType}`) }}
        </template>
        <template v-slot:item.edit="{item}">
            <v-btn
                nuxt
                :to="{
                    name: 'emailEditor',
                    params: {id: item.id},
                }"
                icon
                small
                color="primary"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {adminRuntimeStore} from '@/store'
    import AdminNavigation from '~/components/admin/AdminNavigation'

    @Component({
        components: {
            AdminNavigation,
        },
        middleware: ['auth', 'authorization'],
        layout: 'admin',
    })
    export default class EmailTemplatesPage extends Vue {
        loading = true

        get recipientType() {
            return adminRuntimeStore.notificationTemplatesRecipientType
        }

        set recipientType(val) {
            adminRuntimeStore.SET_NOTIFICATION_TEMPLATES_RECIPIENT_TYPE(val)
        }

        async mounted() {
            this.loading = true
            await adminRuntimeStore.loadNotificationTemplates()
            this.loading = false
        }

        recipientTypeFilter(value, search, item) {
            return item.recipientType === search
        }

        get headers() {
            return [
                {text: '#', value: 'id'},
                {text: this.$t('type'), value: 'notificationType'},
                {text: this.$t('edit'), value: 'edit', align: 'center'},
            ]
        }

        get templates() {
            return adminRuntimeStore.notificationTemplates
                .filter(({notificationType}) => !notificationType || !notificationType.includes('DOC'))
                .sort((a, b) =>
                    this.$t(`admin.notificationType.${a.notificationType}`).localeCompare(
                        this.$t(`admin.notificationType.${b.notificationType}`)
                    )
                )
        }

        get recipientTypes() {
            return Array.from(new Set(this.templates.map(({recipientType}) => recipientType)))
                .filter(Boolean)
                .sort((a, b) => this.$t(`admin.recipientType.${a}`).localeCompare(this.$t(`admin.recipientType.${b}`)))
        }
    }
</script>

<style lang="scss" scoped>
    td {
        display: flex;
        align-items: center;
    }

    .filter-input {
        width: 300px;
    }
</style>
