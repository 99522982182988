<template>
    <v-list dense>
        <v-list-item v-for="(tourists, index) in rooms" :key="index" :data-cy="'room-selector-' + index">
            <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{ $tc('room', 1) }} {{ index + 1 }}</v-list-item-title>
                <tourists-select
                    :adults-prop.sync="tourists.adults"
                    :children-prop.sync="tourists.childrenAges"
                    :max-total="maxTourists"
                    @change-age="changeAge"
                />
            </v-list-item-content>
            <v-list-item-action v-if="rooms.length > 1">
                <v-btn icon @click="removeRoom(index)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn text :disabled="isDisabled" @click="addRoom()">
                {{ $t('add_room') }}
            </v-btn>
            <slot />
        </v-card-actions>
    </v-list>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import TouristsSelect from '~src/components/search/forms/touristsSelect.src'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {TouristsSelect},
    })
    export default class RoomsSelectList extends Vue {
        @VModel() rooms
        @Prop({default: 9}) maxTourists

        @Emit()
        changeAge() {}

        addRoom() {
            this.rooms.push({adults: this.isDisabled ? 1 : 2, childrenAges: []})
        }

        removeRoom(index) {
            this.rooms.splice(index, 1)
        }

        get isDisabled() {
            return authStore.user.isEmployeeOrExternalCorp || this.rooms.length > 19
        }
    }
</script>
