<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {transfersRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TransferPageMixin extends Vue {
        async loadRuntimeData() {
            const {arrivalHotelCode, arrivalPlace, arrivalCityId, departureHotelCode, departurePlace, departureCityId} =
                this.searchRequest
            const promises = []
            if (
                (arrivalHotelCode && arrivalHotelCode !== transfersRuntimeStore.arrivalPoint.hotelCode) ||
                (!arrivalHotelCode &&
                    arrivalPlace === 'hotel' &&
                    arrivalCityId !== transfersRuntimeStore.arrivalPoint.id) ||
                (!arrivalHotelCode &&
                    arrivalPlace !== 'hotel' &&
                    isNaN(arrivalPlace) &&
                    arrivalPlace !== transfersRuntimeStore.arrivalPoint.iataCode) ||
                (!arrivalHotelCode && !isNaN(arrivalPlace) && arrivalPlace !== transfersRuntimeStore.arrivalPoint.id)
            ) {
                promises.push(transfersRuntimeStore.loadArrivalPoint(this.searchRequest))
            }
            if (
                (departureHotelCode && departureHotelCode !== transfersRuntimeStore.departurePoint.hotelCode) ||
                (!departureHotelCode &&
                    departurePlace === 'hotel' &&
                    departureCityId !== transfersRuntimeStore.departurePoint.id) ||
                (!departureHotelCode &&
                    departurePlace !== 'hotel' &&
                    isNaN(departurePlace) &&
                    departurePlace !== transfersRuntimeStore.departurePoint.iataCode) ||
                (!departureHotelCode &&
                    !isNaN(departurePlace) &&
                    departurePlace !== transfersRuntimeStore.departurePoint.id)
            ) {
                promises.push(transfersRuntimeStore.loadDeparturePoint(this.searchRequest))
            }
            await Promise.all(promises)
        }
    }
</script>
