<template>
    <div class="d-flex flex-nowrap align-center justify-end full-width text-right">
        <div>
            <v-btn
                depressed
                :block="$breakpoint.smAndDown"
                :color="selected ? 'success' : 'primary'"
                class="caption"
                :disabled="disabled"
                @click="select"
            >
                {{ free ? $t('free') : $options.filters.price(offer.price) }}
                <v-icon v-if="selected" right>mdi-check</v-icon>
            </v-btn>
            <commission-amount v-if="!free" :commission="offer.price.commission" class="mt-1" />
        </div>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount.vue'

    @Component({
        components: {CommissionAmount},
    })
    export default class AddOnPriceButton extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) offerKey
        @Prop({required: true, type: Array}) selectedOfferKeys
        @Prop({required: true, type: Boolean}) disabled
        @Prop({required: true, type: Boolean}) free

        @Emit()
        select() {}

        get selected() {
            return this.selectedOfferKeys.includes(this.offerKey)
        }
    }
</script>
