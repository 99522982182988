<template>
    <div class="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div class="pswp__bg" />
        <div class="pswp__scroll-wrap">
            <div class="pswp__container">
                <div class="pswp__item" />
                <div class="pswp__item" />
                <div class="pswp__item" />
            </div>
            <div class="pswp__ui pswp__ui--hidden">
                <div class="pswp__top-bar">
                    <div class="pswp__counter" />
                    <button class="pswp__button pswp__button--close" :title="$t('photoSwipe.close')" />
                    <button class="pswp__button pswp__button--share" :title="$t('photoSwipe.share')" />
                    <button class="pswp__button pswp__button--fs" :title="$t('photoSwipe.fullscreen')" />
                    <button class="pswp__button pswp__button--zoom" :title="$t('photoSwipe.zoom')" />
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                            <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip" />
                </div>
                <button class="pswp__button pswp__button--arrow--left" :title="$t('photoSwipe.previous')" />
                <button class="pswp__button pswp__button--arrow--right" :title="$t('photoSwipe.next')" />
                <div class="pswp__caption">
                    <div class="pswp__caption__center" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    const {PhotoSwipe} = require('v-photoswipe')

    @Component
    export default class PhotoSwipeM extends mixins(PhotoSwipe) {}
</script>
