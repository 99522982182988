<template>
    <v-text-field
        :value="asYouTypeInput"
        :dense="dense"
        :label="label && required ? `${label}*` : label"
        :outlined="outlined"
        :placeholder="placeholder && required ? `${placeholder}*` : placeholder"
        :prepend-inner-icon="prependInnerIcon"
        :required="required"
        :rules="rules"
        :persistent-placeholder="persistentPlaceholder"
        :validate-on-blur="validateOnBlur"
        type="tel"
        @input="input"
        @blur="blur"
    />
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import {AsYouType} from 'libphonenumber-js'

    @Component
    export default class PhoneField extends Vue {
        @VModel() phone
        @Prop({default: false, type: Boolean}) required
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: false, type: Boolean}) persistentPlaceholder
        @Prop({default: false, type: Boolean}) validateOnBlur
        @Prop() label
        @Prop() placeholder
        @Prop() prependInnerIcon

        @Emit()
        blur() {}

        @Watch('phone')
        onPhoneChange() {
            if (this.phone) {
                this.input(this.phone)
            } else {
                this.input('')
            }
        }

        asYouType
        asYouTypeInput = ''

        mounted() {
            if (this.phone) {
                this.input(this.phone)
            } else {
                this.input('')
            }
        }

        get rules() {
            return [
                v => !this.required || !!v || `${this.label || this.placeholder} ${this.$t('validation.required')}`,
                v =>
                    !v ||
                    !this.asYouType ||
                    this.asYouType.isValid() ||
                    `${this.label || this.placeholder} ${this.$t('validation.valid')}`,
            ]
        }

        //TODO For best input responsiveness need implement it by mousedown with detect printable keys
        async input(val) {
            const asYouType = new AsYouType()
            const asYouTypeInput = asYouType.input(`${val[0] && val[0] !== '+' ? '+' : ''}${val}`)
            if (this.asYouType?.isValid() && !asYouType.isValid() && val.length > asYouTypeInput.length) {
                this.asYouTypeInput = this.asYouTypeInput + ' '
                await this.$nextTick
                this.asYouTypeInput = this.asYouTypeInput.slice(0, this.asYouTypeInput.length - 1)
            } else {
                this.asYouTypeInput = asYouTypeInput + ' '
                await this.$nextTick
                this.asYouTypeInput = asYouTypeInput
                this.asYouType = asYouType
            }
            this.phone = this.asYouType.getNumber()?.number || ''
        }
    }
</script>
