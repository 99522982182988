<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:sidebar>
            <flight-search-summary :search-request="searchRequest" search-disabled />
            <flight-order-summary
                :offer="offer"
                :extra-baggage-options="extraBaggageOptions"
                :flight-seats-map="flightSeatsMap"
                :extra-services="extraServices"
                :additional-options="bookingAdditionalOptions"
                :prepare-book-response="prepareBookResponse"
            />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import FlightPageMixin from '@/components/flights/mixins/FlightPageMixin'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import FlightSearchSummary from '~/components/flights/search/FlightSearchSummary'
    import FlightOrderSummary from '~/components/flights/booking/FlightOrderSummary'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import FlightBreadcrumbs from '@/components/flights/FlightBreadcrumbs'
    import {flightsStore} from '@/store'

    //TODO Single confirmation page layout for all services with summary slot
    @Component({
        components: {
            FlightBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            FlightSearchSummary,
            FlightOrderSummary,
        },
        layout: 'blank',
    })
    export default class FlightConfirmationPage extends mixins(FlightPageMixin, ConfirmationPageMixin) {
        extraBaggageOptions = []
        flightSeatsMap = {}

        async mounted() {
            await this.$store.restored
            await Promise.all([
                this.loadAdditionalFlightOptions(this.offer.offerKey),
                this.loadFlightSeatsMap(this.offer.offerKey),
            ])
        }

        async loadAdditionalFlightOptions(offerKey) {
            try {
                const {extraBaggageOptions} = await this.$api.additionalFlightOptions.get({offerKey})
                if (extraBaggageOptions) {
                    this.extraBaggageOptions = extraBaggageOptions
                } else {
                    this.extraBaggageOptions = []
                }
            } catch (e) {
                this.extraBaggageOptions = []
            }
        }

        async loadFlightSeatsMap(offerKey) {
            try {
                const segmentRphs = this.offer.itinerary.map(segmentRphs => segmentRphs)
                const promises = segmentRphs.map(segmentRph => this.$api.flightSeatsMap.get({offerKey, segmentRph}))
                const result = await Promise.all(promises)
                this.flightSeatsMapData = result.reduce((flightSeatsMap, {seatsCabinClasses}, index) => {
                    if (seatsCabinClasses) {
                        flightSeatsMap[segmentRphs[index]] = seatsCabinClasses
                    }
                    return flightSeatsMap
                }, {})
            } catch (e) {
                console.error(e)
                this.flightSeatsMapData = {}
            }
        }

        get basketItem() {
            return flightsStore.basketItem(flightsStore.prepareBookRequest.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }
    }
</script>
