<template>
    <div>
        <breadcrumbs :text="personLabel" />
        <person-form :person="person" />
        <passport-modal @saved="refresh" />
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import CompanyProfile from '~src/components/account/companyProfile.src'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import PersonForm from '~src/components/account/forms/personForm.src'
    import PassportModal from '@/components/modals/PassportModal'

    @Component({
        components: {
            PassportModal,
            PersonForm,
            CompanyProfile,
            Breadcrumbs,
        },
        middleware: 'auth',
    })
    export default class CompanyPersonPage extends Vue {
        person = {}

        async asyncData({$api, params}) {
            if (!params.id) return {person: {}}
            try {
                const person = (await $api.persons.get({id: params.id})).users[0]
                person.active = person.active === 'true'
                if (!person.firstName && person.firstNameLocalized) {
                    person.firstName = person.firstNameLocalized
                }
                if (!person.lastName && person.lastNameLocalized) {
                    person.lastName = person.lastNameLocalized
                }
                return {person}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        async refresh() {
            this.person = (await this.$api.persons.get({id: this.person.personId})).users[0]
        }

        get personLabel() {
            if (this.person.personId) {
                const {prefix, firstName, lastName} = this.person
                return `${prefix || ''} ${firstName || ''} ${lastName || ''}`
            }
            return this.$t('new_user_profile')
        }
    }
</script>
