<template>
    <div>
        <breadcrumbs :company="company" />
        <h2 v-if="company?.name">{{ company.name }}</h2>
        <v-tabs v-if="company.type !== 'CORPORATOR'" class="mb-4">
            <v-tab>
                {{ $t('information') }}
            </v-tab>
            <v-tab nuxt :to="link">
                {{ $t('users') }}
            </v-tab>
        </v-tabs>
        <company-profile :company="company" />
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import CompanyProfile from '~src/components/account/companyProfile.src'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {
            CompanyProfile,
            Breadcrumbs,
        },
        middleware: 'auth',
    })
    export default class CompanyProfilePage extends Vue {
        company = {}

        async asyncData({$api, params}) {
            if (params.id === 0) return {company: {contactPerson: {}, phones: []}}

            const rq = !params.id ? {relationship: 'OWN'} : {id: params.id}
            try {
                const company = (await $api.companies.get(rq)).object[0]
                if (!company.contactPerson) {
                    company.contactPerson = {}
                }
                return {company}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get link() {
            if (this.company.id !== this.person.companyId)
                return {name: 'companyPersons', params: {id: this.company.id}, query: {company: this.company.id}}

            return {name: 'companyPersons', params: {id: this.company.id}}
        }

        get tabs() {
            return ['information', 'users']
        }

        get person() {
            return authStore.person
        }
    }
</script>
