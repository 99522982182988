<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-car"
        :is-first="isFirst"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :service-image="mainImage"
                :is-package="isPackage"
                :order-end-date="orderEndDate"
                :order="order"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div v-if="pickUpPoint" class="mt-4">
                    <v-icon left small>
                        {{ pickUpIcon }}
                    </v-icon>
                    <strong>
                        {{ $t('from') }}
                    </strong>
                    {{ pickUpPoint.pointName }}
                    <div v-if="pickUpPointInfo.address" class="ms-7 grey--text">
                        {{ pickUpPointInfo.address }}
                        <div
                            v-if="pickUpPointInfo.latitude && pickUpPointInfo.longitude"
                            class="ms-2 d-inline-block text-caption primary--text text-decoration-underline text-lowercase cursor-pointer"
                            @click="
                                $emit('show-on-map', {
                                    latitude: pickUpPointInfo.latitude,
                                    longitude: pickUpPointInfo.longitude,
                                })
                            "
                        >
                            {{ $t('show_on_map') }}
                        </div>
                    </div>
                </div>
                <div v-if="dropOffPoint">
                    <v-icon left small>
                        {{ dropOffIcon }}
                    </v-icon>
                    <strong>
                        {{ $t('to') }}
                    </strong>
                    {{ dropOffPoint.pointName }}
                    <div v-if="pickUpPointInfo.address" class="ms-7 grey--text">
                        {{ dropOffPointInfo.address }}
                        <div
                            v-if="dropOffPointInfo.latitude && dropOffPointInfo.longitude"
                            class="ms-2 d-inline-block text-caption primary--text text-decoration-underline text-lowercase cursor-pointer"
                            @click="
                                $emit('show-on-map', {
                                    latitude: dropOffPointInfo.latitude,
                                    longitude: dropOffPointInfo.longitude,
                                })
                            "
                        >
                            {{ $t('show_on_map') }}
                        </div>
                    </div>
                </div>
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillCarBookingDataModal = true">mdi-pencil</v-icon>
                    <span class="text-caption cursor-pointer primary--text" @click="fillCarBookingDataModal = true">
                        {{ $t('enter_details') }}
                    </span>
                    <fill-car-booking-data-modal v-model="fillCarBookingDataModal" :service="service" :order="order" />
                </div>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <v-card v-if="carInfo" class="pa-1 pa-md-3">
                    <div class="modal-close-btn-block">
                        <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                    </div>
                    <div class="text-h6">
                        {{ info.modelName }}
                    </div>
                    <v-subheader>
                        {{ info.carRentCompanyName }}
                    </v-subheader>
                    <div
                        v-if="
                            isTO1 &&
                            $config.account.enableCustomProductInfo &&
                            service.supplierCompanyName === 'Jonview'
                        "
                        class="full-width d-flex align-center justify-center"
                    >
                        <template v-if="!customProductInfoMode">
                            <v-spacer />
                            <v-btn text color="primary" @click="customProductInfoMode = true">
                                {{ $t('edit_custom_product_info') }}
                            </v-btn>
                        </template>
                    </div>
                    <custom-product-info-form
                        v-if="customProductInfoMode"
                        :service="service"
                        @close="customProductInfoMode = false"
                        @close-modal="closeModal"
                    />
                    <template v-else>
                        <v-tabs hide-slider>
                            <v-tab class="text-uppercase">
                                {{ $t('description') }}
                            </v-tab>
                            <v-tab class="text-uppercase">
                                {{ `${$t('map')}` }}
                            </v-tab>
                            <v-tab-item>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-carousel
                                            height="170px"
                                            hide-delimiters
                                            show-arrows-on-hover
                                            :show-arrows="info.images && info.images.length > 1"
                                        >
                                            <v-carousel-item
                                                v-for="(image, imageIndex) in info.images"
                                                :key="imageIndex"
                                            >
                                                <v-img :src="image.url | imageUrl(300)" height="170px" contain />
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <div v-if="info.shortDescription">
                                            <span class="d-block text-subtitle-1 font-weight-medium">
                                                {{ $t('description_type.short') }}
                                            </span>
                                            <span v-html="info.shortDescription" />
                                        </div>
                                        <div v-if="info.description">
                                            <span class="d-block text-subtitle-1 font-weight-medium">
                                                {{ $t('description_type.general') }}
                                            </span>
                                            <span v-html="info.description" />
                                        </div>
                                        <template v-if="info.features && info.features.length">
                                            <span class="d-block text-subtitle-1 font-weight-medium">
                                                {{ `${$t('included')}:` }}
                                            </span>
                                            <ul>
                                                <template v-for="feature in info.features">
                                                    <li
                                                        :key="feature"
                                                        class="text-body-2"
                                                        v-text="$t(`carsRent.${feature.toLowerCase()}`)"
                                                    />
                                                </template>
                                            </ul>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <cars-map
                                    :_expanded.sync="mapExpanded"
                                    :offers="mapPoints"
                                    :parent="true"
                                    style="max-height: 500px; position: unset"
                                />
                            </v-tab-item>
                        </v-tabs>
                    </template>
                </v-card>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import CarsMap from '@/components/carsRent/search/CarsMap'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import FillCarBookingDataModal from '@/components/account/modals/newOrderRequest/FillCarBookingDataModal'
    import {authStore} from '@/utils/store-accessor'
    import CustomProductInfoForm from '@/components/account/orderServices/CustomProductInfoForm'

    @Component({
        components: {
            CustomProductInfoForm,
            FillCarBookingDataModal,
            ServiceCard,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            CarsMap,
        },
    })
    export default class CarService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() isFirst

        modal = false
        carInfo = null
        mapExpanded = false
        mapPoints = []
        fillCarBookingDataModal = false
        pickUpPointInfo = {}
        dropOffPointInfo = {}
        customProductInfoMode = false

        mounted() {
            this.getAdditionalInfo()
        }

        closeModal() {
            this.modal = false
        }

        async getAdditionalInfo() {
            this.carInfo = await this.getCarInfo()
            const promises = []
            promises.push(this.getPickUpPointInfo('pickup'))
            promises.push(this.getPickUpPointInfo('return'))
            const [pickUpPointInfo, dropOffPointInfo] = await Promise.all(promises)

            pickUpPointInfo.pointType = 'pickup'
            dropOffPointInfo.pointType = 'return'
            this.pickUpPointInfo = pickUpPointInfo
            this.dropOffPointInfo = dropOffPointInfo

            this.mapPoints = [pickUpPointInfo, dropOffPointInfo]
        }

        async getCarInfo() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            return await this.$api.carRentInfo.get({processId})
        }

        async getPickUpPointInfo(type) {
            const processId = this.service.processId
            return await this.$api.pointInfo.get({processId, pointType: type})
        }

        showInfo() {
            this.customProductInfoMode = false
            this.modal = true
        }

        getIcon(place) {
            switch (this.getPointType(place)) {
                case 'airport':
                    return 'mdi-airport'
                case 'hotel':
                    return 'mdi-hotel'
                default:
                    return 'mdi-city'
            }
        }

        getPointType(place) {
            if (this.serviceDetails[place]?.airportCode) {
                return 'airport'
            } else if (this.serviceDetails[place]?.hotelCode) {
                return 'hotel'
            }
            return 'address'
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get serviceCheckIn() {
            const pickUpTime = this.$options.filters.hoursFromDate(this.service.startDateTime)
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return `<span> ${this.$t('transfers.pick_up')}: ${date}</span> <small>${pickUpTime}</small>`
        }

        get serviceCheckOut() {
            const dropOffTime = this.$options.filters.hoursFromDate(this.service.endDateTime)
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `<span> ${this.$t('transfers.drop_off')}: ${date}</span> <small>${dropOffTime}</small>`
        }

        get pickUpPoint() {
            return this.serviceDetails.pickUpPoint
        }

        get dropOffPoint() {
            return this.serviceDetails.dropOffPoint
        }

        get pickUpIcon() {
            return this.getIcon('pickUpPoint')
        }

        get dropOffIcon() {
            return this.getIcon('dropOffPoint')
        }

        get mainImage() {
            if (this.carInfo && this.carInfo.images && this.carInfo.images.length) {
                let image = this.carInfo.images.find(el => el.mainImage)
                if (!image) {
                    image = this.carInfo.images[0]
                }
                return image.url
            }
            return null
        }

        get info() {
            return {...this.carInfo, ...{mainImage: this.mainImage}}
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
