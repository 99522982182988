<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-credit-card-outline"
        :is-first="isFirst"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :service-image="mainImage"
                :is-package="isPackage"
                :order="order"
                :order-end-date="orderEndDate"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div class="mt-4">
                    {{ `${$t('visa.entry_type')}: ${service.serviceDetails[0].entryType}` }}
                </div>
            </order-info-base>
        </template>
        <template v-if="info" v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <v-card class="pa-1 pa-md-3">
                    <div class="modal-close-btn-block">
                        <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                    </div>
                    <service-info-base :service="service" :info="info" :image="mainImage" />
                </v-card>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import OrderInfoBase from '~src/components/account/orderServices/orderInfoBase.src'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'

    @Component({
        components: {
            ServiceCard,
            OrderInfoBase,
            ServiceInfoBase,
        },
    })
    export default class VisaService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() isFirst

        modal = false
        info = null

        mounted() {
            this.getInfo()
        }

        async getInfo() {
            // TODO: CORE return only productName now
            // this.info = await this.$api.visaInfo.get({processId: this.service.processId})
        }

        showInfo() {
            // this.modal = true
        }

        get serviceCheckIn() {
            const time = this.service.serviceDetails[0].checkInFrom
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return time ? `<span>${date}</span> <small>${this.$t('time_from')} ${time}</small>` : date
        }

        get serviceCheckOut() {
            const time = this.service.serviceDetails[0].checkOutTo
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return time ? `<span>${date}</span> <small>${this.$t('time_before')} ${time}</small>` : date
        }

        get mainImage() {
            return this.info && this.info.mainImage ? this.info.mainImage.url : ''
        }
    }
</script>

<style scoped></style>
