<script>
    import {Component, Prop} from 'vue-property-decorator'
    import {flightsRuntimeStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component
    export default class FlightBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest

        get items() {
            const getSearchPageLabel = () => {
                const labels = flightsRuntimeStore.routes.map(
                    route => `${route.departure.iataCode} - ${route.arrival.iataCode}`
                )
                if (labels.length > 2) {
                    return labels.join(', ')
                } else {
                    return labels[0]
                }
            }
            const addSearchPageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: flightsRuntimeStore.flightsPageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }
            const addSchedulePageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: flightsRuntimeStore.flightsSchedulePageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'flights':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'flightsSchedule':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'flightsAvailability':
                    addSchedulePageLink(items)
                    items.push({
                        text: this.$t('availability'),
                        disabled: true,
                    })
                    break
                case 'flightBooking':
                    addSearchPageLink(items)
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'flightConfirmation':
                    addSearchPageLink(items)
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
