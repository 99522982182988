<template>
    <div>
        <breadcrumbs />
        <v-tabs v-model="tab">
            <v-tab>{{ $t('communications') }}</v-tab>
            <v-tab>{{ $t('notesLabel') }}</v-tab>
            <v-tab>{{ $t('preset_notes') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <communications
                    :communications="communications"
                    :communications-loading="communicationsLoading"
                    :communications-total-count="communicationsCount"
                    @get-communications="getCommunications"
                >
                    <template v-slot="{getCommunications}">
                        <communications-filters
                            v-model="communicationFilters"
                            :has-active-filters="hasActiveCommunicationFilters"
                            @submit="getCommunications"
                            @reset="resetCommunicationFilters(getCommunications)"
                        />
                    </template>
                </communications>
            </v-tab-item>
            <v-tab-item>
                <internal-notes
                    :communications="internalNotes"
                    :communications-loading="internalNotesLoading"
                    :communications-total-count="internalNotesCount"
                    @get-communications="getInternalNotes"
                >
                    <template v-slot="{getCommunications}">
                        <internal-notes-filters
                            v-model="noteFilters"
                            :has-active-filters="hasActiveNoteFilters"
                            @submit="getCommunications"
                            @reset="resetNoteFilters(getCommunications)"
                        />
                    </template>
                </internal-notes>
            </v-tab-item>
            <v-tab-item>
                <internal-notes
                    :communications="presetNotes"
                    :communications-loading="presetNotesLoading"
                    :communications-total-count="presetNotesCount"
                    preset
                    @get-communications="getPresetNotes"
                >
                    <template v-slot="{getCommunications}">
                        <internal-notes-filters
                            v-model="presetNoteFilters"
                            :has-active-filters="hasActivePresetNoteFilters"
                            preset
                            @submit="getCommunications"
                            @reset="resetPresetNoteFilters(getCommunications)"
                        />
                    </template>
                </internal-notes>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import Communications from '@/components/account/communications/Communications.vue'
    import {clean, clone, isEqual} from '@/utils/helpers'
    import InternalNotes from '@/components/account/internalNotes/InternalNotes.vue'
    import CommunicationsFilters from '@/components/account/communications/CommunicationsFilters.vue'
    import InternalNotesFilters from '@/components/account/communications/InternalNotesFilters.vue'

    const blankCommunicationFilters = () => ({
        communicationDateFrom: null,
        communicationDateTo: null,
        accountType: null,
        subject: null,
        createdDateFrom: null,
        createdDateTo: null,
        accountName: null,
        modifiedDateFrom: null,
        modifiedDateTo: null,
        type: null,
        assignedToId: null,
    })

    const blankNoteFilters = () => ({
        subject: null,
        createdDateFrom: null,
        createdDateTo: null,
        modifiedDateFrom: null,
        modifiedDateTo: null,
        description: null,
        orderId: null,
    })

    @Component({
        components: {
            InternalNotesFilters,
            CommunicationsFilters,
            InternalNotes,
            Communications,
            Breadcrumbs,
        },
        middleware: ['auth', 'to1AccessOnly'],
    })
    export default class CommunicationsPage extends Vue {
        communicationsLoading = false
        communications = []
        communicationsCount = 0

        internalNotesLoading = false
        internalNotes = []
        internalNotesCount = 0

        presetNotesLoading = false
        presetNotes = []
        presetNotesCount = 0

        communicationFilters = blankCommunicationFilters()
        noteFilters = blankNoteFilters()
        presetNoteFilters = blankNoteFilters()

        tab = null

        async getCommunications({itemsPerPage, page}) {
            try {
                this.communicationsLoading = true
                const filters = clone(this.communicationFilters)
                clean(filters)
                if (filters.communicationDateFrom) filters.communicationDateFrom += 'T00:00'
                if (filters.createdDateFrom) filters.createdDateFrom += 'T00:00'
                if (filters.modifiedDateFrom) filters.modifiedDateFrom += 'T00:00'
                if (filters.communicationDateTo) filters.communicationDateTo += 'T23:59'
                if (filters.createdDateTo) filters.createdDateTo += 'T23:59'
                if (filters.modifiedDateTo) filters.modifiedDateTo += 'T23:59'
                const {communications, count} = await this.$api.communications.get({
                    ...filters,
                    limit: itemsPerPage,
                    offset: itemsPerPage * (page - 1),
                    typeNEQ: 'NOTES',
                })
                this.communications = communications
                this.communicationsCount = count
                this.communicationsLoading = false
            } catch (e) {
                this.communicationsCount = 0
                this.communications = []
            } finally {
                this.communicationsLoading = false
            }
        }

        async getInternalNotes({itemsPerPage, page}) {
            try {
                this.internalNotesLoading = true
                const filters = clone(this.noteFilters)
                clean(filters)
                if (filters.createdDateFrom) filters.createdDateFrom += 'T00:00'
                if (filters.modifiedDateFrom) filters.modifiedDateFrom += 'T00:00'
                if (filters.createdDateTo) filters.createdDateTo += 'T23:59'
                if (filters.modifiedDateTo) filters.modifiedDateTo += 'T23:59'
                const {communications, count} = await this.$api.communications.get({
                    ...filters,
                    limit: itemsPerPage,
                    offset: itemsPerPage * (page - 1),
                    type: 'NOTES',
                    presetOnly: false,
                })
                this.internalNotes = communications
                this.internalNotesCount = count
                this.internalNotesLoading = false
            } catch (e) {
                this.internalNotesCount = 0
                this.internalNotes = []
            } finally {
                this.internalNotesLoading = false
            }
        }

        async getPresetNotes({itemsPerPage, page}) {
            try {
                this.presetNotesLoading = true
                const filters = clone(this.presetNoteFilters)
                filters.orderId = null
                clean(filters)
                if (filters.createdDateFrom) filters.createdDateFrom += 'T00:00'
                if (filters.modifiedDateFrom) filters.modifiedDateFrom += 'T00:00'
                if (filters.createdDateTo) filters.createdDateTo += 'T23:59'
                if (filters.modifiedDateTo) filters.modifiedDateTo += 'T23:59'
                const {communications, count} = await this.$api.communications.get({
                    ...filters,
                    limit: itemsPerPage,
                    offset: itemsPerPage * (page - 1),
                    type: 'NOTES',
                    presetOnly: true,
                })
                this.presetNotes = communications
                this.presetNotesCount = count
                this.presetNotesLoading = false
            } catch (e) {
                this.presetNotesCount = 0
                this.presetNotes = []
            } finally {
                this.presetNotesLoading = false
            }
        }

        resetCommunicationFilters(getCommunications) {
            this.communicationFilters = blankCommunicationFilters()
            getCommunications()
        }

        resetNoteFilters(getCommunications) {
            this.noteFilters = blankNoteFilters()
            getCommunications()
        }

        resetPresetNoteFilters(getCommunications) {
            this.presetNoteFilters = blankNoteFilters()
            getCommunications()
        }

        get hasActiveCommunicationFilters() {
            return !isEqual(blankCommunicationFilters(), this.communicationFilters)
        }

        get hasActiveNoteFilters() {
            return !isEqual(blankNoteFilters(), this.noteFilters)
        }

        get hasActivePresetNoteFilters() {
            return !isEqual(blankNoteFilters(), this.presetNoteFilters)
        }
    }
</script>
