<template>
    <template-editor-page-layout content-class="flex-column">
        <template v-slot:appBar>
            <div class="d-flex">
                <v-form ref="form" v-model="valid" class="mr-4 d-flex">
                    <v-text-field
                        v-if="isNewTemplate"
                        v-model="documentType"
                        :label="`${$t('type')}*`"
                        :rules="[requiredRule('type')]"
                        validate-on-blur
                        class="mr-4"
                    />
                    <v-text-field
                        v-model="documentTitle"
                        :label="`${$t('title')}*`"
                        :rules="[requiredRule('title')]"
                        validate-on-blur
                    />
                </v-form>
                <v-select
                    v-model="documentLanguage"
                    :items="templateLocales"
                    :disabled="isNewTemplate || isLoading"
                    style="max-width: 50px"
                    class="mr-4"
                    @change="changeLanguage"
                />
                <keep-alive>
                    <div class="mr-4"><code-editor-dialog :ckeditor="editor" /></div>
                </keep-alive>
                <v-btn
                    :loading="isLoading"
                    :disabled="isLoading"
                    depressed
                    color="primary"
                    class="mr-4"
                    @click="isNewTemplate ? saveNew() : save()"
                >
                    {{ isNewTemplate ? $t('save') : $t('save_changes') }}
                </v-btn>
                <v-btn :to="{name: 'documentTemplates'}" color="secondary" depressed nuxt>
                    {{ $t('cancel') }}
                </v-btn>
            </div>
        </template>
        <keep-alive>
            <html-editor
                v-if="template.htmlTemplate"
                ref="editor"
                :html="template.htmlTemplate"
                @loaded="editor = $event"
            />
        </keep-alive>
    </template-editor-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import TemplateEditorPageLayout from '@/components/admin/TemplateEditorPageLayout'
    import TemplateEditorPageMixin from '@/components/account/mixins/TemplateEditorPageMixin'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {
            CodeEditorDialog: () => import('@/components/admin/modals/CodeEditorDialog'),
            HtmlEditor: () => import('@/components/admin/HtmlEditor'),
            TemplateEditorPageLayout,
        },
        middleware: ['auth', 'authorization'],
        layout: 'pure',
    })
    export default class DocumentEditorPage extends mixins(
        DocumentTemplatesMixin,
        TemplateEditorPageMixin,
        FormValidationRulesMixin
    ) {
        template = {
            htmlTemplate: '',
        }
        editor = null
        documentLanguage = null
        documentTitle = null
        documentType = null
        isNewTemplate = false
        valid = false

        head() {
            return {
                bodyAttrs: {
                    'data-editor': 'DecoupledDocumentEditor',
                    'data-collaboration': false,
                    'data-revision-history': false,
                },
            }
        }

        async mounted() {
            this.isLoading = true
            if (!this.$route.params.id) {
                this.isNewTemplate = true
                this.template = {
                    active: false,
                    documentVisibility: ['TOUR_OPERATOR_1LEVEL'],
                    localeCode: 'en',
                    htmlTemplate: '<h3 class="text-center">Here you can add your html code</h3>',
                }
                this.documentLanguage = 'en'
                this.isLoading = false
                return
            }
            await this.loadDocumentTemplates({locale: this.locale, id: +this.$route.params.id})
            this.template = this.documentTemplates[0]
            if (!this.template) {
                this.$nuxt.error({statusCode: 404})
            }

            this.documentLanguage = this.template.templateLocales.includes(this.locale) ? this.locale : 'en'
            this.documentTitle = this.template.title
            this.isLoading = false
        }

        async changeLanguage() {
            this.isLoading = true
            await this.loadDocumentTemplates({id: this.template.id, locale: this.documentLanguage})
            this.template = this.documentTemplates[0]
            this.documentTitle = this.template.title
            this.editor.setData(this.template.htmlTemplate)
            this.isLoading = false
        }

        async save() {
            await this.$refs.form.validate()
            if (!this.valid) return

            try {
                this.isLoading = true
                await this.$api.documentTemplates.put(this.template.id, {
                    localeCode: this.documentLanguage,
                    htmlTemplate: this.editor.getData(),
                    title: this.documentTitle,
                })
                await this.$router.push({name: 'documentTemplates'})
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        async saveNew() {
            await this.$refs.form.validate()
            if (!this.valid) return

            try {
                this.isLoading = true
                await this.$api.documentTemplates.post({
                    ...this.template,
                    localeCode: this.documentLanguage,
                    htmlTemplate: this.editor.getData(),
                    title: this.documentTitle,
                    documentType: this.documentType,
                })
                await this.$router.push({name: 'documentTemplates'})
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        get templateLocales() {
            return this.$config.locales
        }
    }
</script>
