<template>
    <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
        <v-row class="mt-4">
            <v-col cols="12" md="2">
                <v-select
                    v-model="info.type"
                    item-text="name"
                    item-value="value"
                    :menu-props="{bottom: true, offsetY: true}"
                    outlined
                    hide-details
                    :items="types"
                    :label="`${$t('type')}*`"
                    :rules="[requiredRule('type')]"
                />
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                    v-model="info.assignedToId"
                    :item-text="userName"
                    item-value="personId"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    :items="users"
                    :label="`${$t('assigned_to')}*`"
                    :loading="usersLoading"
                    :disabled="usersLoading"
                    :rules="[requiredRule('assigned_to')]"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    v-model="info.stage"
                    item-text="name"
                    item-value="value"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    :items="stages"
                    :label="`${$t('stage')}*`"
                    :rules="[requiredRule('stage')]"
                />
            </v-col>
            <v-col :md="2">
                <vc-date-picker
                    v-model="date"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                    :locale="$i18n.locale"
                    :first-day-of-week="$config.firstDayOfWeek || undefined"
                    is-expanded
                    is-required
                >
                    <template v-slot="{inputEvents}">
                        <div v-on="!disabled ? inputEvents : ''">
                            <v-text-field
                                :value="formattedDate"
                                :label="`${$t('date')}*`"
                                background-color="white"
                                prepend-inner-icon="mdi-calendar"
                                outlined
                                readonly
                                single-line
                                filled
                                hide-details
                            />
                        </div>
                    </template>
                </vc-date-picker>
            </v-col>
            <v-col :md="2">
                <time-field
                    v-model="time"
                    outlined
                    class="cursor-pointer"
                    :label="`${$t('time')}*`"
                    :rules="[requiredRule('time')]"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="info.subject" outlined :label="$t('subject')" persistent-placeholder />
            </v-col>
        </v-row>
        <div class="text-h6 mb-4">{{ $t('relates_to') }}</div>
        <v-row>
            <v-col cols="12" md="4">
                <private-client-and-companies-autocomplete
                    v-model="account"
                    :show-icon="false"
                    :base-label="`${$t('account')}*`"
                    label=""
                    outlined
                    persistent-placeholder
                    :single-line="false"
                    :disabled="accountLoading"
                    :base-loading="accountLoading"
                    :rules="[requiredRule('account')]"
                    :relationship="['CLIENT', 'SUPPLIER']"
                    :filter="item => item.type !== 'TOUR_OPERATOR_1LEVEL'"
                    :no-filter="false"
                    @change="setAccount"
                >
                    <template v-slot:item="{item}">
                        <span v-html="item.label" />
                        <span v-if="item" class="text--secondary">
                            &nbsp;({{
                                item.type
                                    ? $t(`accountType.${companyTypeToCommunicationInfoType(item.type)}`)
                                    : $t('private_clients')
                            }})
                        </span>
                    </template>
                    <template v-slot:selection="{item}">
                        {{ item.selectedLabel || item.label }}
                        <span v-if="item && (item.selectedLabel || item.label)" class="text--secondary">
                            &nbsp;({{
                                item.type
                                    ? $t(`accountType.${companyTypeToCommunicationInfoType(item.type)}`)
                                    : $t('private_clients')
                            }})
                        </span>
                    </template>
                </private-client-and-companies-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
                <v-select
                    v-model="info.contactId"
                    :item-text="userName"
                    item-value="personId"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    clearable
                    :items="contactPersons"
                    :label="$t('contact_person')"
                    :loading="contactPersonsLoading"
                    :disabled="contactPersonsDisabled || contactPersonsLoading"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="info.orderId"
                    type="number"
                    outlined
                    :label="$t('order_number')"
                    persistent-placeholder
                    :disabled="!!services"
                    @change="loadOrderServices"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    v-model="info.processId"
                    item-text="serviceName"
                    item-value="processId"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    :items="orderServices"
                    :label="$t('service')"
                    :loading="orderServicesLoading"
                    :disabled="orderServicesLoading || !info.orderId"
                    clearable
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="info.description" outlined :label="$t('details')" persistent-placeholder />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'
    import {authStore} from '@/utils/store-accessor'
    import PrivateClientAndCompaniesAutocomplete from '@/components/search/forms/PrivateClientAndCompaniesAutocomplete.vue'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin.vue'
    import {CompanyTypeToCommunicationInfoType} from '@/utils/api-data-mapings'

    @Component({
        components: {PrivateClientAndCompaniesAutocomplete, TimeField},
    })
    export default class CommunicationEditForm extends FormValidationRulesMixin {
        @VModel() info
        @Prop() services

        usersLoading = true
        users = []
        orderServicesLoading = false
        orderServices = []
        account = null
        accountLoading = true
        contactPersons = []
        contactPersonsLoading = false
        contactPersonsDisabled = true
        valid = false

        async mounted() {
            if (!this.info.communicationDateTime) {
                this.info.communicationDateTime = this.$dateFns.format(new Date(), "yyyy-MM-dd'T'HH:mm")
            }
            if (!this.info.type) {
                this.info.type = this.types[0].value
            }
            if (!this.info.stage) {
                this.info.stage = this.stages[0].value
            }
            try {
                this.usersLoading = true
                this.users = (await this.$api.persons.get({companyId: authStore.company.id})).users
                if (!this.info.communicationId) {
                    this.info.assignedToId = this.users[0].personId
                }
            } catch (e) {
                this.users = []
            } finally {
                this.usersLoading = false
            }
            if (this.services) {
                this.orderServices = this.services
            }
            if (this.info.accountId) {
                try {
                    this.accountLoading = true
                    if (this.info.accountType === 'PRIVATE_CLIENT') {
                        this.account = (await this.$api.privateClients.get({id: this.info.accountId})).persons[0]
                    } else {
                        this.account = (await this.$api.companies.get({id: this.info.accountId})).object[0]
                    }
                } catch (e) {
                    this.account = null
                } finally {
                    this.accountLoading = false
                }
                await this.loadContactPersons()
            } else {
                this.accountLoading = false
            }
        }

        userName({firstName, lastName}) {
            return `${firstName || ''} ${lastName || ''}`
        }

        async loadOrderServices(orderId) {
            this.info.processId = null
            if (!orderId) {
                this.orderServices = []
                return
            }
            try {
                this.orderServicesLoading = true
                this.orderServices = (
                    await this.$api.orders.get({
                        orderType: authStore.orderType,
                        orderId: this.info.orderId,
                    })
                ).orders[0].services
            } catch (e) {
                this.orderServices = []
            } finally {
                this.orderServicesLoading = false
            }
        }

        async loadContactPersons() {
            try {
                this.contactPersonsLoading = true
                this.contactPersonsDisabled = true
                this.contactPersons = (await this.$api.persons.get({companyId: this.info.accountId})).users
            } catch (e) {
                this.contactPersons = []
            } finally {
                this.contactPersonsLoading = false
                this.contactPersonsDisabled = false
            }
        }

        async setAccount(val) {
            if (val) {
                this.info.accountId = val.personId || val.id
                this.info.accountType = val.personId ? 'PRIVATE_CLIENT' : CompanyTypeToCommunicationInfoType(val.type)
                if (val.personId) {
                    this.info.contactId = null
                    this.contactPersonsDisabled = true
                } else {
                    await this.loadContactPersons()
                }
            } else {
                this.info.accountId = null
                this.info.accountType = null
                this.info.contactId = null
                this.contactPersonsDisabled = true
            }
        }

        validateForm() {
            this.$refs.form.validate()
        }

        get types() {
            return [
                {
                    name: this.$t('communicationType.CALL'),
                    value: 'CALL',
                },
                {
                    name: this.$t('communicationType.EMAIL'),
                    value: 'EMAIL',
                },
                {
                    name: this.$t('communicationType.MEETING'),
                    value: 'MEETING',
                },
                {
                    name: this.$t('communicationType.OTHER'),
                    value: 'OTHER',
                },
            ]
        }

        get stages() {
            return [
                {
                    name: this.$t('communicationStage.PLANNED'),
                    value: 'PLANNED',
                },
                {
                    name: this.$t('communicationStage.HELD'),
                    value: 'HELD',
                },
                {
                    name: this.$t('communicationStage.NOT_HELD'),
                    value: 'NOT_HELD',
                },
            ]
        }

        get date() {
            return this.info.communicationDateTime?.split('T')[0]
        }

        set date(date) {
            this.info.communicationDateTime = `${date}T${this.info.communicationDateTime?.split('T')[1]}`
        }

        get time() {
            return this.info.communicationDateTime?.split('T')[1]
        }

        set time(time) {
            this.info.communicationDateTime = `${this.info.communicationDateTime?.split('T')[0]}T${time}`
        }

        get companyTypeToCommunicationInfoType() {
            return CompanyTypeToCommunicationInfoType
        }

        get disabled() {
            return this.info.type === 'EMAIL' && ['SYSTEM', 'TO1L'].includes(this.info.accountType)
        }

        get formattedDate() {
            return this.date !== null ? this.$options.filters.dateShortFormat(this.date) : ''
        }
    }
</script>
