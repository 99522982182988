<template>
    <span v-if="visible" data-cy="net-amount" class="d-block text-no-nowrap caption">
        {{ $t('net_amount') }}:
        {{ amount | price }}
    </span>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {persistentStore} from '@/utils/store-accessor'

    @Component
    export default class NetAmount extends Vue {
        @Prop({required: true}) price

        get amount() {
            return {amount: this.price.amount - this.price.commission.amount, currency: this.price.currency}
        }

        get visible() {
            return this.price?.amount && this.price?.commission?.amount && this.commissionAndNettoCheckbox
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }
    }
</script>

<style scoped>
    span.caption {
        color: rgba(0, 0, 0, 0.6);
        line-height: 1rem;
    }
</style>
