<template>
    <client-only>
        <v-card outlined>
            <v-card-title class="d-flex justify-center">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-account-search"
                    :placeholder="$t('start_typing')"
                    single-line
                    :disabled="isLoading"
                    clearable
                    @click:clear="clearSearch"
                />
                <v-btn nuxt class="primary mb-4 mt-2 ml-2" @click="searchClient">
                    {{ $t('search') }}
                </v-btn>
            </v-card-title>
            <v-card-subtitle v-if="showSwitchActive">
                <v-switch
                    v-model="active"
                    color="primary"
                    :disabled="isLoading"
                    :label="active ? $t('active') : $t('inactive')"
                    @change="switchActive"
                />
            </v-card-subtitle>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :footer-props="{'items-per-page-options': [10, 15, 100]}"
                :loading="isLoading"
                :options.sync="options"
                sort-by="name"
                class="elevation-1"
                data-cy="persons-table"
                show-select
            >
                <template v-slot:item.active="{item}">
                    <v-switch
                        v-model="item.active"
                        color="primary"
                        :label="item.active ? $t('active') : $t('inactive')"
                        @change="changeActive(item)"
                    />
                </template>
                <template v-slot:item.edit="{item}">
                    <v-icon color="primary" @click="edit(item.id)">mdi-pencil</v-icon>
                </template>
            </v-data-table>
        </v-card>
    </client-only>
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import {persistentStore} from '@/store'
    import {matches} from '@/utils/helpers'

    @Component
    export default class ClientsTable extends Vue {
        @Prop({default: false}) isMember

        search = ''
        loaded = false
        active = true
        selected = []

        @Watch('selectedEmails')
        onChangeSelectedEmails() {
            this.changeSelectedEmails(this.selectedEmails)
        }

        @Emit()
        changeSelectedEmails(emails) {
            return emails
        }

        async mounted() {
            await this.$store.restored
            await this.loadPrivateClients()
        }

        async changeActive({id, active}) {
            try {
                await this.$api.privateClients.put(id, {active})
            } catch (e) {
                await this.loadPrivateClients()
            }
        }

        async loadPrivateClients() {
            const options = persistentStore.clientsTableOptions
            await runtimeStore.loadPrivateClients({
                companyId: authStore.person.companyId,
                active: this.active,
                // limit: options.itemsPerPage,
                offset: options.itemsPerPage * (options.page - 1),
                ...(this.search && {pattern: this.search}),
                showClientsWithoutNames: false,
            })
            this.loaded = true
        }

        switchActive() {
            this.options = {page: 1, itemsPerPage: this.options.itemsPerPage}
            this.loadPrivateClients()
        }

        edit(id) {
            const routeName = this.isMember ? 'member' : 'companyClient'
            this.$router.push({name: routeName, params: {id}})
        }

        searchClient() {
            this.options = {page: 1, itemsPerPage: this.options.itemsPerPage}
            this.loadPrivateClients()
        }

        clearSearch() {
            this.options = {page: 1, itemsPerPage: this.options.itemsPerPage}
            this.search = ''
            this.loadPrivateClients()
        }

        get headers() {
            return [
                {text: this.$t('name'), value: 'name'},
                {text: this.$t('phone'), value: 'phone'},
                {text: this.$t('email'), value: 'email'},
                {text: this.$t('reservations_number'), value: 'reservations_number'},
                {text: this.$t('loyalty'), value: 'totalLoyaltyPoints'},
                {text: this.$t('status'), value: 'active', width: '10%', sortable: false},
                {text: this.$t('edit'), value: 'edit', align: 'center', sortable: false},
            ]
        }

        get items() {
            return runtimeStore.clients.map(client => ({
                id: client.personId,
                name: `${(!this.isMember && client.prefix) || ''} ${client.firstName || ''} ${client.lastName || ''}`,
                phone: client.contactPhone,
                reservations_number: client.reservationsNumber,
                totalLoyaltyPoints: client.totalLoyaltyPoints,
                email: client.email,
                active: client.active === 'true',
            }))
        }

        get clientsCount() {
            return runtimeStore.clientsCount
        }

        get isLoading() {
            return runtimeStore.loading
        }

        get clientsOnPage() {
            return persistentStore.clientsTableOptions.itemsPerPage
        }

        get options() {
            return persistentStore.clientsTableOptions
        }

        set options(options) {
            if (!this.loaded) return
            const prev = persistentStore.clientsTableOptions
            if (!prev || matches(options, prev)) return
            persistentStore.SET_CLIENTS_TABLE_OPTIONS(options)
            this.loadPrivateClients()
        }

        get showSwitchActive() {
            return true
        }

        get selectedEmails() {
            return [...new Set(this.selected.filter(({email}) => email).map(({email}) => email))]
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-label {
        font-size: 0.75rem !important;
    }

    ::v-deep .v-input__icon--append {
        display: none;
    }
</style>
