<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelMap from '~src/components/search/offers/hotelMap.src'
    import {MarkerClusterer} from '@googlemaps/markerclusterer'
    import {searchRequest} from '~src/utils/hotels/hotels-blank-states.src'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class HotelMapModified extends HotelMap {
        hideExpandButton = true

        setMarkers(offers) {
            this.markers.forEach(marker => marker.setMap(null))
            if (this.markerCluster) {
                this.markerCluster.clearMarkers()
            }
            this.markers = []
            if (!offers.length) return
            offers.forEach(offer => {
                this.addMarker(offer)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            this.setMapPosition()
            this.markerCluster = new MarkerClusterer({
                map: this.map,
                markers: this.markers,
                renderer: {
                    render: ({count, position}) => {
                        const color = this.$vuetify.theme.themes.light.primary
                        const svg = btoa(`
                            <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                                <ellipse style="stroke: ${color}; fill: rgb(255, 255, 255); stroke-width: 4px;" cx="30" cy="30" rx="28" ry="28"></ellipse>
                                <text style="fill: ${color}; font-family: sans-serif; font-size: 14px; white-space: pre; text-anchor: middle;" x="30" y="35">${count}</text>
                            </svg>
                        `)
                        return new this.google.maps.Marker({
                            position,
                            icon: {
                                url: `data:image/svg+xml;base64,${svg}`,
                                scaledSize: new this.google.maps.Size(45, 45),
                            },
                            label: {
                                text: ' ',
                            },
                            // adjust zIndex to be above other markers
                            zIndex: Number(this.google.maps.Marker.MAX_ZINDEX) + count,
                        })
                    },
                },
            })
        }

        addMarker(offer) {
            let position
            if (offer.lat && offer.lng) {
                position = {
                    lat: offer.lat,
                    lng: offer.lng,
                }
            } else {
                return
            }

            if (this.markers.findIndex(marker => marker.position === position) !== -1) return

            const marker = new this.google.maps.Marker({
                position,
                icon: this.createMarkerIcon(offer),
                data: offer,
            })

            marker.addListener('click', () => {
                const route = hotelsRuntimeStore.hotelPageLink(
                    {name: marker.data.name},
                    {
                        ...searchRequest(),
                        citizenshipId: this.$config.defaultCitizenshipId,
                        cityId: marker.data.cityId,
                    }
                )

                route.query.hotelId = marker.data.id

                this.$router.push(route)
            })

            this.markers.push(marker)
            return marker
        }

        setMapPosition() {
            this.map.fitBounds(this.bounds)
            this.mapPositionInit = true
        }

        saveMapPosition() {}
    }
</script>
