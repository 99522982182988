<template>
    <v-dialog v-model="modal" hide-overlay width="600">
        <v-card v-if="infoLoading" color="primary" dark>
            <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-title class="justify-space-between">
                {{ title }}
                <v-icon class="justify-end" @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <template v-if="mealTypes.length">
                <v-card-text v-for="(mealType, index) in mealTypes" :key="index">
                    <template
                        v-if="
                            mealType.shortDescription &&
                            (!mealType.description || mealType.description.indexOf(mealType.shortDescription) === -1)
                        "
                    >
                        <h4>{{ $t('description_type.short') }}</h4>
                        <div v-html="mealType.shortDescription" />
                    </template>
                    <template v-if="mealType.description">
                        <h4>{{ $t('description_type.general') }}</h4>
                        <div v-html="mealType.description" />
                    </template>
                </v-card-text>
            </template>
            <v-card-text v-else>
                {{ $t('no_details') }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '@/store'
    import {isEqual} from '@/utils/helpers'
    import {EventBus, SHOW_MEAL_TYPE_INFO_EVENT} from '@/utils/event-bus'

    @Component
    export default class HotelMealTypeInfoModal extends Vue {
        infoLoading = false
        modal = false
        mealTypeNames = []

        mounted() {
            EventBus.$on(SHOW_MEAL_TYPE_INFO_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_MEAL_TYPE_INFO_EVENT, this.showModal)
        }

        async showModal(hotel, mealTypeNames) {
            this.mealTypeNames = mealTypeNames
            this.modal = true
            this.infoLoading = true
            const {supplierCode, cityCode, hotelCode} = hotel,
                rq = {supplierCode, cityCode, hotelCode}
            if (!isEqual(rq, hotelsRuntimeStore.hotelInfoRequest)) {
                await hotelsRuntimeStore.loadHotelInfo(rq)
            }
            this.infoLoading = false
        }

        get title() {
            return this.mealTypeNames.join(', ')
        }

        get mealTypes() {
            return this.mealTypeNames
                .map(mealTypeName =>
                    hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.mealTypes.mealType.find(
                        mealType => mealType.name === mealTypeName
                    )
                )
                .filter(mealType => mealType && (mealType.description || mealType.shortDescription))
        }
    }
</script>
