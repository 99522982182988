<template>
    <div class="d-flex flex-column align-center">
        <flight-class-selector :offer="offer" class="mb-5" @select-class="categoryClass = $event" />
        <v-btn color="primary" @click="selectOffer">
            {{ $t('select') }}
        </v-btn>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FlightClassSelector from '~/components/flights/search/schedule/FlightClassSelector'
    import {flightsStore} from '@/utils/store-accessor'

    @Component({
        components: {
            FlightClassSelector,
        },
    })
    export default class ScheduleFlightSelector extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) index

        categoryClass = null

        selectOffer() {
            const {index, offer, categoryClass} = this
            flightsStore.SET_SELECTED_SCHEDULE_ITEM({index, offer, categoryClass})
            this.$emit('select-offer')
        }
    }
</script>
