<template>
    <v-dialog v-model="modal" hide-overlay :persistent="loading">
        <v-card>
            <v-card-title class="white--text primary">
                {{ serviceDetails.hotelName }}
            </v-card-title>
            <v-card-text v-if="bookingFieldsLoading" class="d-flex full-width align-center justify-center pa-8">
                <v-progress-circular color="primary" indeterminate size="64" />
            </v-card-text>
            <template v-else>
                <v-card-text>
                    <div class="my-5">
                        <template v-for="(tourist, index) in tourists">
                            <hotel-tourist-form
                                :key="'_' + index"
                                :ref="`tourist${index}`"
                                v-model="tourists[index]"
                                :room-index="0"
                                :index="index"
                                :standard-meal-name="getMealName(index)"
                                :start-date="startDate"
                                :hide-index="tourists.length === 1"
                                :hotel="service"
                                @change-passport="onTouristsChange"
                            />
                        </template>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div @click="validateTouristsWithErrors">
                        <v-btn
                            color="primary"
                            :disabled="!allTouristsValid || loading"
                            :loading="loading"
                            @click="save"
                        >
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                    <v-btn text :disabled="loading" @click="modal = false">{{ $t('cancel') }}</v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import BookingTouristsMixin from '@/components/booking/mixins/BookingTouristsMixin'
    import HotelTouristForm from '@/components/booking/forms/HotelTouristForm'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import FillServiceBookingDataMixin from '@/components/account/mixins/FillServiceBookingDataMixin'

    //TODO Add train stations
    @Component({
        components: {
            HotelTouristForm,
        },
    })
    export default class FillAccommodationBookingDataModal extends mixins(
        BookingTouristsMixin,
        HotelPageMixin,
        FillServiceBookingDataMixin
    ) {
        @VModel() modal
        @Prop({required: true}) service
        @Prop({required: true}) order

        loading = false
        allTouristsValid = false

        @Watch('modal')
        async onModalOpen() {
            if (this.modal) {
                this.setTourists()
                hotelsStore.SET_TOURISTS([persistentStore.tourists])
                await this.loadTouristBookingFields()
                this.$nextTick(() => {
                    this.setAllTouristsValid()
                })
            }
        }

        @Watch('tourists', {deep: true})
        async onTouristsChange() {
            await this.$nextTick(() => {})
            this.setAllTouristsValid()
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
        }

        async save() {
            try {
                this.loading = true
                const touristsWithPassport = this.tourists.map((t, i) => {
                    return {...t, passport: hotelsStore.tourists[0][i].passport}
                })
                await this.$api.fillAccommodationBookingData.post({
                    tourists: this.prepareBookTourists(touristsWithPassport),
                    processId: this.service.processId,
                })
                await runtimeStore.refreshOrder()
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.modal = false
                this.loading = false
            }
        }

        getMealName(index) {
            const travelerId = this.service.travelers[index]?.travelerId
            const room = this.serviceDetails.rooms.find(room => {
                return room.travelerIds.travelerId.includes(travelerId)
            })

            return room?.mealTypeName
        }

        setAllTouristsValid() {
            const validation = []

            this.tourists.forEach((t, i) => {
                validation.push(this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.$refs['form']?.validate())
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.$refs['form']?.resetValidation()
            })

            this.allTouristsValid = validation.every(el => el === true)
        }

        validateTouristsWithErrors() {
            this.tourists.forEach((t, i) => {
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.$refs['form']?.validate()
            })
        }

        get ownProduct() {
            return hotelsRuntimeStore.ownProduct(this.service)
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get searchRequest() {
            return {
                dateTime: this.service.startDateTime.split(' ')[0],
                adults: this.service.travelers.filter(({isChild}) => !isChild).length,
                childrenAges: this.service.travelers.reduce((childrenAges, {isChild, dateOfBirth}) => {
                    //TODO Missing age prop in get orders
                    if (isChild) {
                        if (dateOfBirth) {
                            childrenAges.push(
                                this.$dateFns.differenceInYears(
                                    new Date(this.service.startDateTime),
                                    this.$dateFns.parseISO(dateOfBirth)
                                )
                            )
                        } else {
                            childrenAges.push(4)
                        }
                    }
                    return childrenAges
                }, []),
            }
        }

        get startDate() {
            return this.searchRequest.dateTime
        }
    }
</script>
