<template>
    <div class="d-flex">
        <div v-if="info.passengerQuantity" class="mr-3 body-2">
            <v-icon :small="small">mdi-account</v-icon>
            {{ info.passengerQuantity }}
        </div>
        <div v-if="info.doorCount" class="mr-3 body-2">
            <v-icon :small="small">mdi-car-door</v-icon>
            {{ info.doorCount }}
        </div>
        <div v-if="info.baggageQuantity" class="mr-3 body-2">
            <v-icon :small="small">mdi-bag-checked</v-icon>
            {{ info.baggageQuantity }}
        </div>
        <div v-if="info.airConditioning" class="body-2">
            <v-icon :small="small">mdi-snowflake</v-icon>
            {{ $t('carsRent.air_conditioning') }}
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class CarCharacteristic extends Vue {
        @Prop({type: Object, required: true}) info
        @Prop({default: () => false, type: Boolean}) small
    }
</script>
