<template>
    <div v-if="pages > 1" class="text-xs-center">
        <v-pagination v-model="page" :length="pages" :disabled="pages === 0" total-visible="7" @input="change" />
    </div>
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'vue-property-decorator'
    import goTo from 'vuetify/es5/services/goto'

    @Component
    export default class Pagination extends Vue {
        @Prop() pages
        @PropSync('pageProp') page

        change() {
            goTo(0)
        }
    }
</script>

<style scoped></style>
