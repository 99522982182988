<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                v-model="date"
                :label="label"
                :disabled="disabled"
                :clearable="clearable"
                background-color="white"
                prepend-inner-icon="mdi-calendar"
                :rules="rules"
                :persistent-placeholder="persistentPlaceholder"
                :type="textFieldType"
                :min="min"
                :max="max"
                :dense="dense"
                :hint="hint"
                :hide-details="hideDetails"
                :outlined="outlined"
                :class="contentClass"
                required
                @change="change"
                @click="$event.preventDefault()"
                v-on="on"
            />
        </template>
        <v-date-picker
            v-model="date"
            :locale="$i18n.locale"
            :min="min"
            :max="max"
            :active-picker.sync="activePicker"
            :first-day-of-week="$config.firstDayOfWeek ? $config.firstDayOfWeek - 1 : firstDayOfWeekByLocale"
            @change="menu = false"
        />
    </v-menu>
</template>

<script>
    import {Component, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import {capitalizeTheFirstLetterOfEachWord} from '@/utils/helpers'

    @Component
    export default class DatePicker extends Vue {
        @VModel({type: String}) date
        @Prop({default: '1900-01-01', type: String}) min
        @Prop({default: '2100-01-01', type: String}) max
        @Prop() label
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) hideDetails
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) yearActivePicker
        @Prop({default: false, type: Boolean}) persistentPlaceholder
        @Prop({default: 'date'}) textFieldType
        @Prop({default: () => []}) rules
        @Prop() contentClass
        @Prop({default: true, type: Boolean}) clearable

        menu = false
        activePicker = 'YEAR'

        @Watch('menu')
        onChangeMenu(val) {
            val && this.yearActivePicker && setTimeout(() => (this.activePicker = 'YEAR'))
        }

        change(val) {
            const date = this.$dateFns.parseISO(val),
                min = this.$dateFns.parseISO(this.min),
                max = this.$dateFns.parseISO(this.max)
            if (this.$dateFns.isBefore(date, min)) {
                this.date = this.min
            } else if (this.$dateFns.isAfter(date, max)) {
                this.date = this.max
            }
        }

        get firstDayOfWeekByLocale() {
            return this.$dateFns.localeOptions[this.$i18n.locale].weekStartsOn
        }

        get hint() {
            const dateShortFormat = this.$options.filters.dateShortFormat
            if (this.max !== '2100-01-01' && this.min !== '1900-01-01') {
                return `${capitalizeTheFirstLetterOfEachWord(this.$t('from'))} ${dateShortFormat(
                    this.$dateFns.parseISO(this.min)
                )} ${this.$t('to')} ${dateShortFormat(this.$dateFns.parseISO(this.max))}`
            } else if (this.max !== '2100-01-01') {
                return `${capitalizeTheFirstLetterOfEachWord(this.$t('to'))} ${dateShortFormat(
                    this.$dateFns.parseISO(this.max)
                )}`
            } else if (this.min && this.min !== '1900-01-01') {
                return `${capitalizeTheFirstLetterOfEachWord(this.$t('from'))} ${dateShortFormat(
                    this.$dateFns.parseISO(this.min)
                )}`
            }
            return ''
        }
    }
</script>

<style scoped lang="scss">
    @-moz-document url-prefix() {
        ::v-deep.v-text-field {
            .v-input__prepend-inner {
                display: none !important;
            }
        }
    }
</style>
