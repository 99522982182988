<template>
    <v-card class="my-2" :class="{'elevation-0': !fullCard}" :outlined="fullCard">
        <v-list-item three-line class="pl-0">
            <v-list-item-avatar v-if="fullCard" class="my-0" :size="!$breakpoint.smAndDown ? '125px' : '25%'" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="mainImage.url | imageUrl(200)"
                    :alt="offer.info.description"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && mainImage.url"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <div
                    class="wrap-words d-flex align-center text-subtitle-1 font-weight-bold cursor-pointer mb-1"
                    @click="offerInfo()"
                >
                    <v-icon small left>mdi-information-outline</v-icon>
                    <span>{{ offer.info.description }}</span>
                    <span v-if="offerTime" class="ms-2 grey--text font-weight-bold text-body-2">
                        {{ `(${offerTime})` }}
                    </span>
                </div>
                <div v-if="offer.available" class="d-flex align-center caption">
                    <v-icon :title="$t('available')" left small class="success--text">
                        mdi-checkbox-marked-circle
                    </v-icon>
                    <span class="success--text" v-text="$t('available')" />
                </div>
                <div v-else class="d-flex align-center caption">
                    <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                    <span class="warning--text" v-text="$t('on_request')" />
                </div>
                <span v-if="offer && offer.price" class="caption">
                    <conditions-label
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </span>
                <div v-if="offer.info.tariffName" class="d-flex align-center caption">
                    <v-icon left small class="info--text">mdi-tag-text</v-icon>
                    <span>
                        {{ offer.info.tariffName }}
                    </span>
                </div>
                <transfer-driver-languages :lang-codes="offer.langCodes" />
                <div v-if="isAddOn" class="d-flex align-center caption">
                    <v-icon :title="$t('date')" left small>mdi-calendar</v-icon>
                    <span>{{ offer.date | dateFormat }}</span>
                </div>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center d-flex flex-column align-center">
                <slot name="price" :offer="offer" :offer-key="offer.offerKey">
                    <discount-label v-if="offer.price" :price="offer.price" class="mb-2" />
                    <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        height="36px"
                        max-height="36px"
                        block
                        depressed
                        small
                        data-cy="client-price"
                        @click="selectForBooking"
                    >
                        {{ offer.price | price }}
                    </v-btn>
                    <price-note :search-request="searchRequest" tourist-label="passenger" />
                    <commission-amount :commission="offer.price.commission" />
                    <net-amount :price="offer.price" />
                </slot>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'
    import {authStore, transfersRuntimeStore, transfersStore} from '~/store'
    import PriceNote from '@/components/snippets/PriceNote'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import NetAmount from '@/components/snippets/NetAmount'

    @Component({
        components: {
            TransferDriverLanguages,
            PriceNote,
            CommissionAmount,
            DiscountLabel,
            TransferInfoModal,
            ConditionsLabel,
            NetAmount,
        },
    })
    export default class TransferOfferCard extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true}) fullCard
        @Prop() isReturnTransfer
        @Prop({default: false, type: Boolean}) isAddOn

        imgError = false
        info = {
            images: [],
        }
        loading = true

        async mounted() {
            try {
                this.info = await this.$api.transferInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard && this.info.images) {
                    this.$emit('images', this.info.images)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            if (this.isReturnTransfer) {
                this.selectTransferOffer()
            } else {
                transfersStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
                this.$router.push({
                    name: 'transferBooking',
                    query: {offerKey: this.offer.offerKey},
                })
            }
        }

        offerInfo() {
            EventBus.$emit(SHOW_TRANSFER_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequest, this.offer)
        }

        @Emit()
        selectTransferOffer() {
            return this.offer
        }

        get mainImage() {
            return transfersRuntimeStore.offerMainImage(this.offer, this.info)
        }

        get offerTime() {
            if (this.offer.time?.length) {
                return this.offer.time[0]
            }
            if (this.offer.departureTimeFrom && this.offer.departureTimeTo) {
                return [this.offer.departureTimeFrom, this.offer.departureTimeTo].join(' - ')
            }

            return null
        }

        get searchRequest() {
            return transfersStore.searchRequest
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
