<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import TourStoreMixin from '@/mixins/TourStoreMixin'

    @Component
    export default class TourBookingPageLayout extends mixins(BookingPageBase, TourStoreMixin) {
        async prepareBook() {
            const data = {
                offerKey: this.offer.offerKey,
                entries: [],
            }
            this.offer.entries.forEach(entry => {
                if (entry.selectedOfferKey) data.entries.push({name: entry.name, offerKey: entry.selectedOfferKey})
            })
            await this.$api.updatePackageTourPrice.put(data)
            await BookingPageBase.options.methods.prepareBook.call(this)
        }

        prepareBookRequest(specifiedTouristBookingFields, includeOptionalTourists = false) {
            return BookingPageBase.options.methods.prepareBookRequest.call(
                this,
                specifiedTouristBookingFields,
                includeOptionalTourists
            )
        }

        productConfirmationPageQuery(orderId) {
            return {
                orderId,
                packageTourId: this.$route.query.packageTourId,
            }
        }

        productBookingPageQuery(paymentStatus) {
            return {
                paymentStatus,
                offerKey: this.$route.query.offerKey,
                packageTourId: this.$route.query.packageTourId,
            }
        }

        clearCommentsAfterBooking() {}

        get productSearchPageName() {
            return 'tour'
        }

        get productConfirmationPageName() {
            return 'tourConfirmation'
        }

        get productName() {
            return 'tour'
        }

        get productBookingPageName() {
            return 'tourBooking'
        }

        get corporatePolicyType() {
            return null
        }
    }
</script>
