<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {activitiesRuntimeStore, activitiesStore} from '@/store'
    import {ACTIVITY, PRODUCT_NAME} from '@/utils/activities/activities-const'

    @Component
    export default class ActivityStoreMixin extends Vue {
        get productStore() {
            return activitiesStore
        }

        get productRuntimeStore() {
            return activitiesRuntimeStore
        }

        get productType() {
            return ACTIVITY
        }

        get productName() {
            return PRODUCT_NAME
        }
    }
</script>
