<template>
    <v-dialog v-model="modal" width="700">
        <v-card>
            <v-card-title>{{ $t('send_email') }}</v-card-title>
            <v-card-text>
                <v-form v-model="valid" :disabled="loading">
                    <v-checkbox
                        v-if="isTO1"
                        v-model="emailFor"
                        value="suppliers"
                        :label="
                            $tc(
                                'suppliers',
                                !specificService && order && order.services && order.services.length > 1 ? 2 : 1
                            )
                        "
                        hide-details
                    />
                    <v-checkbox
                        v-if="isTO1 || isTO2"
                        v-model="emailFor"
                        value="clients"
                        :label="$t('client')"
                        hide-details
                    />
                    <v-checkbox
                        v-if="isTO1 || isTO2"
                        v-model="emailFor"
                        value="travelers"
                        :label="$t('travelers')"
                        hide-details
                        class="mb-6"
                    />
                    <v-text-field
                        v-model="message.subject"
                        :label="`${$t('email_form.subject')}*`"
                        outlined
                        :rules="[requiredRule('email_form.subject')]"
                    />
                    <emails-field
                        v-model="message.to"
                        :label="`${$t('email_form.to')}${toFieldRequired ? '*' : ''}`"
                        :required="toFieldRequired"
                    />
                    <emails-field v-model="message.cc" :label="$t('email_form.cc')" />
                    <emails-field
                        v-model="message.bcc"
                        :label="`${$t('email_form.bcc')}${!toFieldRequired ? '*' : ''}`"
                        :required="!toFieldRequired"
                    />
                    <v-select v-model="templateId" :items="templateItems" :label="$t('template')" outlined clearable />
                    <v-textarea
                        v-if="!templateId"
                        v-model="message.text"
                        :label="`${$t('email_form.text')}*`"
                        outlined
                        :rules="[requiredRule('email_form.text')]"
                    />
                    <v-expansion-panels
                        v-if="services.length || invoices.length || documents.length || customDocuments.length"
                        flat
                        class="mb-5"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                {{ $t('documents') }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-expansion-panels flat>
                                    <v-expansion-panel v-if="services.length">
                                        <v-expansion-panel-header>
                                            <v-checkbox
                                                :input-value="allSelected(services)"
                                                :label="`${$t('voucher')} (${services.length})`"
                                                hide-details
                                                dense
                                                @click.stop="toggleAllSelected(services)"
                                            />
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-list>
                                                <v-list-item v-for="service in services" :key="service.processId">
                                                    <v-checkbox
                                                        v-model="service.selected"
                                                        :label="service.serviceName"
                                                        hide-details
                                                        dense
                                                    />
                                                </v-list-item>
                                            </v-list>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel v-if="invoices.length">
                                        <v-expansion-panel-header>
                                            <v-checkbox
                                                :input-value="allSelected(invoices)"
                                                :label="`${$t('invoices.invoice')} (${invoices.length})`"
                                                hide-details
                                                dense
                                                @click.stop="toggleAllSelected(invoices)"
                                            />
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-list>
                                                <v-list-item v-for="invoice in invoices" :key="invoice.invoiceId">
                                                    <v-checkbox
                                                        v-model="invoice.selected"
                                                        :label="invoice.invoiceName"
                                                        hide-details
                                                        dense
                                                    />
                                                </v-list-item>
                                            </v-list>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel v-if="documents.length">
                                        <v-expansion-panel-header>
                                            <v-checkbox
                                                :input-value="allSelected(documents)"
                                                :label="`${$t('documents')} (${documents.length})`"
                                                hide-details
                                                dense
                                                @click.stop="toggleAllSelected(documents)"
                                            />
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-list>
                                                <v-list-item v-for="document in documents" :key="document.id">
                                                    <v-checkbox
                                                        v-model="document.selected"
                                                        :label="document.name"
                                                        hide-details
                                                        dense
                                                    />
                                                </v-list-item>
                                            </v-list>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-list v-if="customDocuments.length" class="ml-2">
                                    <v-list-item v-for="(document, index) in customDocuments" :key="index">
                                        <v-checkbox
                                            v-model="document.selected"
                                            :label="document.name"
                                            hide-details
                                            dense
                                        />
                                    </v-list-item>
                                </v-list>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-file-input
                        v-model="attachments"
                        :label="$t('email_form.attachments')"
                        multiple
                        outlined
                        chips
                        counter
                        :hint="$t('hint.multiple_files')"
                        persistent-hint
                    />
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <template v-if="templateId">
                    <v-btn depressed large :disabled="!valid || loading" @click="edit = true">
                        {{ $t('edit') }}
                    </v-btn>
                    <v-btn depressed large color="secondary" :disabled="!valid || loading" @click="preview = true">
                        {{ $t('preview') }}
                    </v-btn>
                </template>
                <v-btn depressed large color="primary" :disabled="!valid || loading" :loading="loading" @click="send">
                    {{ $t('send') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <email-preview-modal v-model="preview" :html="emailHtml" />
        <email-edit-modal v-model="edit" :json="emailJson" @change="editedTemplateHtml = $event" />
    </v-dialog>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {EventBus, SHOW_SEND_EMAIL_MODAL} from '@/utils/event-bus'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import EmailsField from '@/components/snippets/forms/EmailsField'
    import {blobToBase64} from '@/utils/helpers'
    import VoucherMixin from '@/components/account/mixins/VoucherMixin'
    import EmailPreviewModal from '@/components/account/modals/EmailPreviewModal.vue'
    import EmailEditModal from '@/components/account/modals/EmailEditModal.vue'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import OrderEmailsMixin from '@/components/account/mixins/OrderEmailsMixin.vue'
    import {customDocumentFileName} from '@/utils/document-utils'

    const Handlebars = require('handlebars')
    const message = () => ({
        subject: '',
        to: [],
        cc: [],
        bcc: [],
        text: '',
        attachments: [],
    })

    @Component({
        components: {EmailEditModal, EmailPreviewModal, EmailsField},
    })
    export default class SendEmailModal extends mixins(FormValidationRulesMixin, VoucherMixin, OrderEmailsMixin) {
        modal = false
        valid = false
        loading = false
        message = message()
        attachments = []

        orderId = null
        directSales = true
        templateId = null
        templateData = null
        order = null
        services = []
        invoices = []
        documents = []
        customDocuments = []

        templates = []
        preview = false
        edit = false
        editedTemplateHtml = null
        toFieldRequired = true
        specificService = false

        @Watch('emailFor.length')
        async setEmails() {
            const emails = await this.getOrderEmails(
                this.services.length === 1 ? [{...this.order, services: this.services}] : [this.order]
            )
            if (emails.length === 1) {
                this.message.to = emails
                this.message.bcc = []
                //this.toFieldRequired = true
            } else {
                this.message.to = []
                this.message.bcc = emails
                //this.toFieldRequired = false
            }
        }

        @Watch('message.to.length')
        checkIsToFieldRequired() {
            if (this.message.to.length === 0 && this.message.bcc.length > 0) {
                this.toFieldRequired = false
            } else {
                this.toFieldRequired = true
            }
        }

        async mounted() {
            EventBus.$on(SHOW_SEND_EMAIL_MODAL, this.showModal)
            try {
                const {templates} = await this.$api.notificationTemplates.get()
                this.templates = templates
                    .filter(({notificationType}) => notificationType === 'PAYMENT_RECEIPT')
                    .map(({id, notificationType, recipientType, htmlTemplate, jsonTemplate}) => ({
                        value: id,
                        text: this.$t(`admin.notificationType.${notificationType}`),
                        recipientType,
                        htmlTemplate,
                        jsonTemplate,
                    }))
            } catch (e) {
                this.templates = []
            }
        }

        beforeDestroy() {
            EventBus.$off(SHOW_SEND_EMAIL_MODAL, this.showModal)
        }

        async showModal(order, service, documentTemplates) {
            this.order = order
            const {orderId, objectId, services, clientType, clientPerson, customerPaymentDate, orderPrices} = order
            this.modal = true
            const docsList = async fn => {
                try {
                    return (await fn()).map(item => ({selected: true, ...item}))
                } catch (e) {
                    return []
                }
            }
            if (!service) {
                this.specificService = false
                this.services = this.getSortedServices(services)
                    .filter(service => runtimeStore.isServiceVoucherAvailable(service))
                    .map(item => ({selected: true, ...item}))
                const loadInvoices = async () => {
                    this.invoices = await docsList(
                        async () =>
                            (
                                await this.$api.invoices.get({
                                    orderId: objectId,
                                    invoiceType: 'CUSTOMER',
                                    active: true,
                                })
                            ).invoices
                    )
                }
                this.initCustomDocuments(documentTemplates)
                const loadDocuments = async () => {
                    this.documents = await docsList(async () => await this.$api.documents.get({orderId}))
                }
                await Promise.all([loadInvoices(), loadDocuments()])
            } else {
                this.specificService = true
                this.services = [service]
                    .filter(service => runtimeStore.isServiceVoucherAvailable(service))
                    .map(item => ({selected: true, ...item}))
                this.documents = []
                this.customDocuments = []
                this.invoices = []
            }
            this.orderId = orderId
            this.directSales = clientType === 'DIRECT_SALES'
            let clientName
            if (this.directSales) {
                clientName = `${clientPerson.prefix} ${clientPerson.lastName}`
            } else {
                const firstService = service || this.getSortedServices(services)[0]
                const tourLead = firstService.travelers.find(({isTourLead}) => isTourLead)
                clientName = `${tourLead.prefix} ${tourLead.name[0].lastName}`
            }
            const paidAmount = orderPrices
                .find(({type}) => type === 'CLIENT')
                .orderPrices.reduce((paidAmount, orderPrice) => {
                    const printableAmount = this.$options.filters.priceFormat(
                        orderPrice.amount - orderPrice.dueToPay,
                        orderPrice.currency
                    )
                    paidAmount.push(printableAmount)
                    return paidAmount
                }, [])
            this.templateData = {
                orderId,
                clientName,
                customerPaymentDate: customerPaymentDate ? customerPaymentDate.split(' ')[0] : '',
                paidAmount: paidAmount.join(' '),
            }
        }

        initCustomDocuments(documentTemplates) {
            this.customDocuments = documentTemplates.map(({documentType, id}) => ({
                selected: false,
                name: documentType,
                id,
                filename: customDocumentFileName(this.order, {documentType}),
            }))
        }

        async send() {
            if (!this.valid || this.loading) return
            try {
                this.loading = true
                this.message.attachments = await Promise.all(
                    this.attachments.map(async file => {
                        const path = await blobToBase64(file)
                        return {
                            path,
                            filename: file.name,
                        }
                    })
                )
                const docs = {
                    vouchers: this.services
                        .filter(({selected}) => selected)
                        .map(service => ({
                            processId: service.processId,
                            filename: this.getVoucherFileName(service, this.orderId),
                        })),
                    invoices: this.invoices
                        .filter(({selected}) => selected)
                        .map(({invoiceId, invoiceName}) => ({invoiceId, filename: `${invoiceName}.pdf`})),
                    documents: this.documents.filter(({selected}) => selected).map(document => document.id),
                    customDocuments: this.customDocuments.filter(({selected}) => selected),
                }
                const msg = {...this.message}
                if (this.templateId) {
                    delete msg.text
                    msg.html = this.emailHtml
                }
                await this.$api.mail.post({
                    message: msg,
                    docs,
                    orderId: this.orderId,
                    //TODO Need refactoring with DownloadCustomDocumentButton
                    orderType: authStore.orderType,
                    isAgency: authStore.isAgency,
                })
                this.$toast.success(this.$t('success_message.send_email'))
                this.modal = false
                this.message = message()
                this.attachments = []
                this.invoices = []
                this.documents = []
                this.services = []
                this.orderId = null
            } catch (e) {
                console.error(e)
                this.$toast.error(this.$t('error_message.send_email_error'))
            } finally {
                this.loading = false
            }
        }

        getVoucherFileName(service, orderId) {
            return (
                this.$t(`serviceType.${service.serviceType}`) +
                `_Voucher_${orderId}_` +
                service.serviceName.replace(/\s/g, '_') +
                '.pdf'
            )
        }

        allSelected(list) {
            return list.every(({selected}) => selected)
        }

        toggleAllSelected(list) {
            const selected = !this.allSelected(list)
            list.forEach(item => {
                item.selected = selected
            })
        }

        get templateItems() {
            return this.templates.filter(
                ({recipientType}) =>
                    (this.directSales && recipientType === 'B2C') || (!this.directSales && recipientType === 'COMPANY')
            )
        }

        get emailHtml() {
            if (!this.templateId || !this.templateData) return null
            const htmlTemplate =
                this.editedTemplateHtml || this.templates.find(({value}) => value === this.templateId).htmlTemplate
            const template = Handlebars.compile(htmlTemplate)
            return template(this.templateData)
        }

        get emailJson() {
            if (!this.templateId) return null
            return this.templates.find(({value}) => value === this.templateId).jsonTemplate
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }
    }
</script>
