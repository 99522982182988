<template>
    <order-summary-layout :total-price="totalPrice" :product-store="productStore" :extra-prices="extraPrices">
        <car-offer-card-mobile :product="{offer, info}" :book-disabled="true" hide-map>
            <template v-slot:summary>
                <slot name="summary" />
            </template>
        </car-offer-card-mobile>
        <div class="mt-1 font-weight-bold">
            <span>
                {{ offerPrice | price }}
            </span>
            <commission-amount :commission="offer.price.commission" />
            <net-amount :price="offer.price" />
        </div>
        <extra-services-summary
            v-if="extraServices && additionalOptions"
            :extra-services="extraServices"
            :additional-options="additionalOptions"
        />
    </order-summary-layout>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import {carsRentStore} from '@/utils/store-accessor'
    import CarOfferCardMobile from '@/components/carsRent/search/offers/CarOfferCardMobile'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import {clone} from '@/utils/helpers'
    import OrderSummaryBase from '@/components/booking/OrderSummaryBase.vue'

    @Component({
        components: {
            ExtraServicesSummary,
            OrderSummaryLayout,
            CarOfferCardMobile,
            CommissionAmount,
            NetAmount,
        },
    })
    export default class CarOrderSummary extends OrderSummaryBase {
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({default: true}) prebooked

        get productStore() {
            return carsRentStore
        }

        get offerPrice() {
            const price = clone(this.offer.price)
            const mandatory =
                this.productStore.bookingAdditionalOptions?.extraServices?.filter(
                    extraService => extraService.mandatory
                ) || []

            if (mandatory.length) {
                mandatory.forEach(item => {
                    price.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount
                    price.originalAmount -= item.salesTerms.find(el => el.type === 'CLIENT').originalAmount
                })
            }

            return price
        }

        get orderTotalPrice() {
            return carsRentStore.orderTotalPrice(this.offer, this.prebooked, [], this.prepareBookResponse)
        }
    }
</script>
