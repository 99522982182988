<template>
    <v-expand-transition>
        <v-data-table
            v-if="groupItems"
            :headers="headers"
            :items="groupItems"
            :loading="detailsLoading"
            :items-per-page="15"
            :mobile-breakpoint="600"
            group-by="date"
            disable-sort
            class="elevation-1"
        >
            <template v-slot:group.header="{items, isOpen, toggle}">
                <th colspan="12" class="cursor-pointer" @click="toggle">
                    <v-icon>{{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }} </v-icon>
                    <span>
                        {{ `${items[0].date} ( ${getDayOfWeek(items[0].date)} )` }}
                    </span>
                </th>
            </template>
            <template v-slot:item.groupId="{item}">
                <span>{{ item.groupId }}</span>
            </template>
            <template v-slot:item.service="{item}">
                <div class="d-md-flex flex-md-column py-3 align-md-start">
                    <div class="font-weight-medium">
                        <v-icon small left class="d-inline-block">
                            {{ getServiceTypeIcon(item.serviceType) }}
                        </v-icon>
                        <span class="text-subtitle-2" v-html="item.serviceName" />
                    </div>
                    <span class="text-caption text--secondary">
                        {{ `${item.city}, ${item.country}` }}
                    </span>
                    <span v-if="isMobile">
                        {{ `${item.touristsQuantity}  ${$tc('guest', item.touristsQuantity)}` }}
                    </span>
                </div>
            </template>

            <template v-slot:item.status="{item}">
                <span :class="item.statusColor">{{ $t(`extranetGroupStatus.${item.status}`) }}</span>
            </template>
            <template v-slot:item.programType="{item}">
                {{ $t(`${item.programType.toLowerCase()}`) }}
            </template>
            <template v-slot:item.details="{item}">
                <div class="d-flex flex-column align-end py-3">
                    <span v-if="isMobile" :class="item.statusColor" class="pb-1">
                        {{ $t(`extranetGroupStatus.${item.status}`) }}
                    </span>
                    <v-btn :color="'primary'" small :disabled="detailsLoading" @click="showDetails(item)">
                        {{ $t('details') }}
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-expand-transition>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {getServiceTypeIcon} from '@/utils/helpers'

    @Component
    export default class GroupsTable extends Vue {
        @VModel() selectedGroup
        @Prop() groupItems
        @Prop() detailsLoading

        getDayOfWeek(dateString) {
            const date = new Date(dateString)

            return new Intl.DateTimeFormat(this.$i18n.locale, {weekday: 'short'}).format(date)
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        showDetails(group) {
            this.selectedGroup = group
            this.$emit('show-details')
        }

        get headers() {
            return this.isMobile
                ? [
                      {text: 'Id', value: 'groupId', align: 'center'},
                      {text: this.$t('time'), value: 'time'},
                      {text: this.$t('order_service'), value: 'service'},
                      {text: this.$t('details'), value: 'details', align: 'center'},
                  ]
                : [
                      {text: 'Id', value: 'groupId', align: 'center'},
                      {text: this.$t('time'), value: 'time'},
                      {text: this.$t('status'), value: 'status'},
                      {text: this.$t('order_service'), value: 'service'},
                      {text: this.$t('guests'), value: 'touristsQuantity', align: 'center'},
                      {text: this.$t('type'), value: 'programType'},
                      {text: this.$t('details'), value: 'details', align: 'center'},
                  ]
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
