<template>
    <div class="d-flex flex-nowrap align-center justify-end full-width">
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <div v-on="on">
                    <v-icon v-if="!packagesNotDeltaPrice && calcPrice.amount > 0" left> mdi-plus </v-icon>
                    {{ packagesNotDeltaPrice ? product.notDeltaPrice : calcPrice | price }}
                    <v-icon v-if="currentlySelected" right>mdi-check</v-icon>
                </div>
            </template>
            <span>
                {{ $t('packages.total_price') }}:
                <span>
                    {{ calcTotalPrice(calcPrice) | price }}
                </span>
            </span>
        </v-tooltip>
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton.vue'

    @Component
    export default class TourMultipleRoomOfferDelta extends TourOfferDeltaButton {
        @Prop({required: true}) hotelOffer

        get selectedOffer() {
            return this.hotelOffer.rooms.find(
                ({groupedOffers}) =>
                    this.entry.selectedOfferKeys.includes(groupedOffers[0].offerKey) &&
                    this.room.groupedOffers[0].rph === groupedOffers[0].rph
            )
        }
    }
</script>
