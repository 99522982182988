<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {carsRentRuntimeStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component
    export default class CarBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest
        @Prop({default: () => {}}) product

        get items() {
            const getSearchPageLabel = () =>
                `${carsRentRuntimeStore.pickUpPoint.name}, ${carsRentRuntimeStore.pickUpPoint.countryName} - ${carsRentRuntimeStore.dropOffPoint.name}, ${carsRentRuntimeStore.dropOffPoint.countryName}`
            const addSearchPageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: carsRentRuntimeStore.searchPageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'carsRent':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'carBooking':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.info.modelName,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'carConfirmation':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.info.modelName,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
