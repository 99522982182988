<template>
    <div>
        <strong data-cy="client-price">{{ $t('from') }} {{ offer.price | price }}</strong>
        <discount-label :price="offer.price" />
        <commission-amount :commission="offer.price.commission" />
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    @Component({
        components: {CommissionAmount, DiscountLabel},
    })
    export default class ActivityCardPriceBlock extends Vue {
        @Prop({default: () => ({})}) offer
    }
</script>
