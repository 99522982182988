<template>
    <v-row class="d-flex px-8 py-4 align-end">
        <v-col v-if="mealTypeNameItems.length > 1" cols="12" md="2">
            <v-select
                :items="mealTypeNameItems"
                :label="$t('filterTitles.mealTypes')"
                :deletable-chips="false"
                :value="availability"
                :multiple="false"
                name="mealTypeName"
                item-text="name"
                clearable
                item-value="value"
                dense
                @change="change($event, 'mealTypeName')"
            />
        </v-col>
        <v-col v-if="availabilityItems.length > 1" cols="12" md="2">
            <v-select
                :items="availabilityItems"
                :label="$t('filterTitles.availability')"
                :deletable-chips="false"
                :value="availability"
                :multiple="false"
                name="availability"
                item-text="name"
                clearable
                item-value="value"
                dense
                @change="change($event, 'availability')"
            />
        </v-col>
        <v-col v-if="cabinTypeNameItems.length > 1" cols="12" md="2">
            <v-select
                :items="cabinTypeNameItems"
                :label="$t('filterTitles.cabinType')"
                :deletable-chips="false"
                :value="availability"
                :multiple="false"
                name="cabinType"
                item-text="name"
                clearable
                item-value="value"
                dense
                @change="change($event, 'cabinType')"
            />
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <group-offers-select
                :items="['none', 'serviceName', 'mealTypeName']"
                :product-store="productStore"
                hide-details
                token-base="cruiseGroupBy"
            />
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Emit, mixins, Prop} from 'nuxt-property-decorator'
    import SelectOption from '@/components/snippets/AdditionalSearchOptions/SelectOption'
    import GroupOffersSelect from '@/components/search/GroupOffersSelect'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'

    @Component({
        components: {
            GroupOffersSelect,
            SelectOption,
        },
    })
    export default class CruisePageFilters extends mixins(CruisesStoreMixin) {
        @Prop({required: true}) product

        availability = null
        availabilityItems = []
        mealTypeNameItems = []
        cabinTypeNameItems = []

        mounted() {
            this.setAvailabilityItems()
            this.setMealTypeNameItems()
            this.setCabinTypeNameItems()
        }

        @Emit()
        change(value, key) {
            return value ? {value: [value], key} : {value: [], key}
        }

        setAvailabilityItems() {
            this.availabilityItems = this.product.offers.map(el => {
                const value = el.available ? 'available' : 'on_request'

                return {name: this.$t(`${value}`), value}
            })
        }

        setMealTypeNameItems() {
            this.mealTypeNameItems = this.product.offers.map(el => ({
                name: this.$t(`${el.mealTypeName}`),
                value: el.mealTypeName,
            }))
        }

        setCabinTypeNameItems() {
            this.cabinTypeNameItems = this.product.offers.map(el => ({
                name: this.$t(`${el.serviceName}`),
                value: el.serviceName,
            }))
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-input {
            margin-top: 0;
        }
    }
</style>
