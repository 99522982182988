<template>
    <v-card class="mx-md-auto elevation-0" :href="item.url" :link="true" target="_blank">
        <v-img :src="item.img | srcHost" lazy-src="/placeholder.png" :height="250" class="mb-4" eager />
        <v-card-subtitle class="pa-0 justify-space-between d-flex">
            <span>{{ item.category }}</span>
            <span>{{ item.articleDate || item.createdAt | dateShortFormat }}</span>
        </v-card-subtitle>
        <v-card-title class="pa-0">
            {{ item.title }}
        </v-card-title>
    </v-card>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class BlogItem extends Vue {
        @Prop({required: true}) item
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        word-break: keep-all;
    }
    .v-card:hover .v-card__title {
        color: var(--v-primary-base);
    }
</style>
