<template>
    <product-card-layout
        :thumb="product.info.imageUrl"
        :name="product.info.name"
        :product-link="productLink"
        :offers-count="product.offers && product.offers.length"
        :expanded="expanded"
        :expanded-limit="expandedLimit"
        :is-recommended="product.recommended"
    >
        <template v-slot>
            <div>
                <div class="d-flex align-center text-truncate">
                    <nuxt-link no-prefetch :to="productLink" class="text-decoration-none font-weight-medium me-2">
                        {{ product.info.name }}
                    </nuxt-link>
                    <v-chip v-if="minPriceOffer.transferType && minPriceOffer.transferType !== 'UNKNOWN'" label x-small>
                        {{ $t(`filters_transferType.${minPriceOffer.transferType}`) }}
                    </v-chip>
                </div>
                <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" />
                <div v-if="product.info.description" class="short-description mt-1">
                    {{ product.info.description }}
                </div>
                <transfer-driver-languages class="mt-1" :lang-codes="langCodes" />
                <div><slot /></div>
            </div>
        </template>
        <template v-if="minPriceOffer" v-slot:priceCol>
            <slot name="price" :offer="minPriceOffer" :offer-key="minPriceOffer.offerKey">
                <transfer-card-price-block v-if="product.offers[0].price && !hidePrice" :offer="minPriceOffer" />
                <v-btn depressed block color="primary" class="my-2" nuxt :to="productLink">
                    {{ $t('book') }}
                </v-btn>
            </slot>
            <div class="primary--text cursor-pointer text-no-wrap" @click="toggleOffers">
                <span>
                    {{ expanded ? $t('hide') : $t('show') }}
                </span>
                <v-icon color="primary">
                    {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </div>
        </template>
        <template v-if="product.offers.length" v-slot:offers>
            <div v-if="expanded">
                <transfer-offer-card
                    v-for="(offer, offerIndex) in product.offers.slice(0, expanded)"
                    :key="offer.offerKey"
                    :offer="offer"
                    :is-return-transfer="isReturnTransfer"
                    :full-card="false"
                    :data-cy="`transfer-offer-card-${offerIndex}`"
                    :is-add-on="isAddOn"
                    @select-transfer-offer="selectTransferOffer"
                >
                    <template v-slot:price>
                        <slot name="price" :offer="offer" :offer-key="offer.offerKey" />
                    </template>
                </transfer-offer-card>
                <v-list-item
                    v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                    dense
                    class="d-flex justify-center"
                    @click.stop="expanded = product.offers.length"
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-list-item>
            </div>
        </template>
    </product-card-layout>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import TransferOfferCard from '~src/components/transfers/info/transferOfferCard.src'
    import {authStore, transfersRuntimeStore, transfersStore} from '@/utils/store-accessor'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import TransferOperatedByLabel from '@/components/transfers/snippets/TransferOperatedByLabel.vue'
    import ProductCardLayout from '@/components/search/ProductCardLayout.vue'
    import TransferCardPriceBlock from '@/components/transfers/search/offers/TransferCardPriceBlock.vue'

    @Component({
        components: {
            TransferCardPriceBlock,
            ProductCardLayout,
            TransferOperatedByLabel,
            TransferDriverLanguages,
            TransferOfferCard,
            SupplierNameLabel,
        },
    })
    export default class TransferCard extends Vue {
        @Prop({default: () => []}) product
        @Prop() isReturnTransfer
        @Prop() isFirst
        @Prop({default: false, type: Boolean}) hidePrice
        @Prop({default: false, type: Boolean}) isAddOn

        expanded = 0
        imgError = false

        mounted() {
            if (this.isReturnTransfer && this.isFirst) {
                this.expanded = this.expandedLimit
            }
        }

        toggleOffers() {
            let expanded = 0
            if (!this.expanded) {
                expanded = this.expandedLimit
            }
            this.expanded = expanded
        }

        @Emit()
        selectTransferOffer(offer) {
            return offer
        }

        get langCodes() {
            return this.product.offers.reduce((arr, e) => {
                e.langCodes.forEach(lang => {
                    if (!arr.includes(lang)) {
                        arr.push(lang)
                    }
                })
                return arr
            }, [])
        }

        get expandedLimit() {
            if (this.$config.expandOnClickAllTransfers === true) {
                return this.product.offers.length
            }

            return 3
        }

        get minPriceOffer() {
            if (this.$route.name.includes('tour') || this.$route.name === 'home') {
                return this.product.offers.reduce(
                    (a, b) => (a.notDeltaPrice.amount < b.notDeltaPrice.amount ? a : b),
                    this.product.offers[0]
                )
            }

            return this.product.offers.reduce(
                (a, b) => (a.price.amount < b.price.amount ? a : b),
                this.product.offers[0]
            )
        }

        get productLink() {
            return transfersRuntimeStore.infoPageLink(this.product, transfersStore.searchRequest)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
