<script>
    import {Component} from 'vue-property-decorator'
    import HotelSearchSummary from '../../search/HotelSearchSummary.vue'
    import {packagesRuntimeStore} from '~/store'

    @Component
    export default class PackagesSearchSummary extends HotelSearchSummary {
        get isSearchActive() {
            return packagesRuntimeStore.searchActive
        }

        get rooms() {
            return []
        }

        get searchFormComponent() {
            return 'packages-search-form'
        }

        get locationLabel() {
            if (!packagesRuntimeStore.arrivalPoint || !packagesRuntimeStore.departurePoint) return null
            return `${packagesRuntimeStore.departurePoint.name}, ${packagesRuntimeStore.departurePoint.countryName} - ${packagesRuntimeStore.arrivalPoint.name}, ${packagesRuntimeStore.arrivalPoint.countryName}`
        }

        get touristsTotal() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.length
        }
    }
</script>
