<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '~/store'

    @Component
    export default class ConfirmationPageMixin extends Vue {
        company = {
            phones: [],
        }
        loaded = false
        tourlead = null

        validate({query}) {
            return query.orderId
        }

        async asyncData({$api, query: {orderId}}) {
            try {
                const {
                    orders: [
                        {
                            services: [
                                {
                                    travelers: [tourlead],
                                },
                            ],
                        },
                    ],
                } = await $api.orders.get({orderId, responseType: 'SHORT', orderType: 'TA'})
                return {tourlead}
            } catch (e) {
                console.error(e)
                return {tourlead: null}
            }
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            if (this.$route.query.orderId !== persistentStore.bookResponse[0].orderId) {
                return this.$nuxt.error({statusCode: 404})
            }
            try {
                const {
                    object: [company],
                } = await this.$api.companies.get({relationship: this.relationship})
                this.company = company
                // eslint-disable-next-line no-empty
            } catch (e) {}
            persistentStore.SET_TOURISTS([])
            const person = authStore.person
            if (person && person.memberLevel !== 0 && person.memberLevel !== 'GUEST') {
                this.setUser(person)
            }
            this.loaded = true
        }

        setUser(person) {
            const {prefix, firstName, lastName, email, contactPhone, citizenshipId, personId, birthdate} = person
            const user = authStore.user
            const tourist = {
                prefix,
                firstName,
                lastName,
                citizenshipId,
                email,
                phone: contactPhone,
                birthdate,
            }
            if (user.isCorporate) {
                tourist.personId = personId
            }
            if (user.isEmployeeOrExternalCorp && Object.keys(user.passport).length) {
                tourist.passport = user.passport
            }
            persistentStore.SET_TOURIST({
                val: Object.assign({}, tourist),
                index: 0,
            })
        }

        get relationship() {
            return authStore.isCorporate || authStore.isTO2 || authStore.isAgency ? 'SUPPLIER' : 'OWN'
        }

        get basketAddOns() {
            const basketKey = this.$route.query.basketKey
            if (!basketKey) return null
            return persistentStore.basketAddOns.find(basketAddOns => basketAddOns.basketKey === basketKey)
        }

        get selectedAddOns() {
            return this.basketAddOns?.selectedAddOns || []
        }

        get extraServices() {
            return this.productStore.prepareBookRequest.extraServices
        }

        get bookingAdditionalOptions() {
            return this.productStore.bookingAdditionalOptions
        }

        get prepareBookResponse() {
            return this.productStore.prepareBookResponse
        }
    }
</script>
