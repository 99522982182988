<template>
    <div class="d-flex align-center">
        <v-autocomplete
            ref="input"
            v-model="location"
            :items="items"
            :placeholder="$t('search_on_map')"
            background-color="white"
            dense
            hide-no-data
            hide-selected
            hide-details
            no-filter
            clearable
            return-object
            single-line
            outlined
            filled
            class="me-3 location"
            @change="change"
            @click:clear="clear"
            @update:search-input="onSearch"
        >
            <template v-slot:append>
                <v-icon v-if="!loading">mdi-magnify</v-icon>
                <v-progress-circular v-else indeterminate size="24" color="grey" />
            </template>
            <template v-slot:item="{item}">
                <span v-text="item.formatted_address" />
            </template>
            <template v-slot:selection="{item}">
                {{ item.formatted_address }}
            </template>
        </v-autocomplete>
        <v-text-field
            v-show="!hideRadius && circle"
            v-model="radius"
            :disabled="!circle"
            :min="0"
            :max="1000"
            :suffix="$t('km')"
            background-color="white"
            type="number"
            dense
            hide-details
            single-line
            outlined
            class="me-2 radius-field input--no-arrows"
            @input="setRadius"
        />
        <v-btn v-if="!hideRadius" color="white" @click="!circle ? setCircle(cityLocation) : clear()">
            <v-icon>{{ !circle ? `mdi-circle-double` : 'mdi-close-circle' }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Component, Prop, mixins} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'
    import {EventBus, HIDE_CIRCLE_ON_MAP, SEARCH_EVENT} from '@/utils/event-bus'
    import HotelMapMixin from '@/components/hotels/mixins/HotelMapMixin'
    import {distanceTransformer} from '@/utils/hotels/hotel-transformers'

    const centerFilter = center => {
        const runtimeFilters = clone(hotelsRuntimeStore.runtimeFilters)
        runtimeFilters.map.center = center
        hotelsRuntimeStore.SET_RUNTIME_FILTERS(runtimeFilters)
        hotelsRuntimeStore.filter()
    }

    @Component
    export default class HotelMapLocationFilter extends mixins(HotelMapMixin) {
        @Prop({required: true}) google
        @Prop({required: true}) map
        @Prop({required: true}) city
        @Prop({default: false, type: Boolean}) hideRadius

        timer = null
        items = []
        loading = false
        location = null
        circle = null
        radius = null
        marker = null

        created() {
            EventBus.$on(SEARCH_EVENT, this.clear)
            EventBus.$on(HIDE_CIRCLE_ON_MAP, this.clear)
        }

        mounted() {
            this.radius = (Number(hotelsRuntimeStore.runtimeFilters.map.radius) / 1000).toFixed(2)
        }

        beforeDestroy() {
            EventBus.$off(SEARCH_EVENT, this.clear)
            EventBus.$off(HIDE_CIRCLE_ON_MAP, this.clear)
        }

        onSearch(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                this.initSearch(val)
            }, 500)
        }

        async initSearch(val) {
            if (this.loading || (this.items.length > 0 && this.items.indexOf(val) !== -1) || !val) return
            this.loading = true
            const location = this.cityLocation
            /*await this.$axios.$get('https://maps.googleapis.com/maps/api/place/findplacefromtext/json', {
                params: {
                    input: val,
                    locationbias: {
                        point: `${location.lat},${location.lng}`,
                        radius: 10000,
                    },
                    key: this.$config.googleapisKey,
                    language: this.$i18n.locale,
                    fields: ['name', 'formatted_address', 'geometry'],
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            })*/
            const service = new this.google.maps.places.PlacesService(this.map)
            service.textSearch(
                {
                    query: val,
                    radius: 30000,
                    location,
                },
                (results, status) => {
                    if (status === this.google.maps.places.PlacesServiceStatus.OK) {
                        this.items = results
                    }
                    this.loading = false
                }
            )
        }

        change() {
            if (this.location) {
                const center = this.location.geometry.location.toJSON()

                if (!this.hideRadius) {
                    this.setCircle(center)
                } else {
                    this.setCenter(center)
                }
            }
            this.$refs.input.blur()
        }

        setRadius() {
            this.circle.setRadius(Number(this.radius) * 1000)
        }

        setCircle(center) {
            // eslint-disable-next-line no-console
            console.log('setCircle')
            this.radius = (Number(hotelsRuntimeStore.runtimeFilters.map.radius) / 1000).toFixed(2)
            this.circle?.setMap(null)
            this.marker?.setMap(null)
            this.circle = new this.google.maps.Circle({
                strokeColor: this.$vuetify.theme.themes.light.primary,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.$vuetify.theme.themes.light.primary,
                fillOpacity: 0.35,
                center,
                // radius: hotelsRuntimeStore.runtimeFilters.map.radius,
                radius: Number(this.radius) * 1000,
                editable: true,
            })
            this.circle.setMap(this.map)
            centerFilter(center)
            this.marker = new this.google.maps.Marker({
                position: center,
                icon: this.createMarkerIcon(),
                map: this.map,
            })
            this.circle.addListener('radius_changed', () => {
                const runtimeFilters = clone(hotelsRuntimeStore.runtimeFilters)
                runtimeFilters.map.radius = this.circle.radius
                this.radius = (this.circle.radius / 1000).toFixed(2)
                hotelsRuntimeStore.SET_RUNTIME_FILTERS(runtimeFilters)
                hotelsRuntimeStore.filter()
                this.setDistanceFilter()
            })
            this.circle.addListener('center_changed', () => {
                this.location = null
                const center = this.circle.center.toJSON()
                centerFilter(center)
                this.marker.setPosition(center)
                this.setDistanceFilter()
            })
            this.setDistanceFilter()
        }

        setDistanceFilter() {
            const data = {
                key: 'distance',
                value: this.location ? [] : [distanceTransformer(Number(this.radius))],
            }

            hotelsStore.SET_FILTER(data)
            hotelsRuntimeStore.filter()
        }

        setCenter(center) {
            this.marker?.setMap(null)
            centerFilter(center)
            this.marker = new this.google.maps.Marker({
                position: center,
                icon: this.createMarkerIcon(),
                map: this.map,
            })
        }

        clear() {
            if (!this.hideRadius) {
                if (!this.circle) return
                this.circle.setMap(null)
                this.circle = null
            }
            if (this.marker) this.marker.setMap(null)
            this.marker = null
            centerFilter(null)
            this.location = null
        }

        markerColor() {
            return this.$vuetify.theme.themes.light.success
        }

        get cityLocation() {
            return {lat: this.city.latitude, lng: this.city.longitude}
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .location {
        width: 15vw;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            width: 60vw;
        }
    }

    .radius-field {
        width: 5vw;
    }
</style>
