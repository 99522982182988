<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {downloadData} from '@/utils/helpers'
    import {runtimeStore} from '@/utils/store-accessor'
    import VoucherDialog from '@/components/account/VoucherDialog'
    import {PDFDocument} from 'pdf-lib'
    import {appInstance} from '@/utils/app-accessor'

    const mergePdfs = async pdfsToMerges => {
        const mergedPdf = await PDFDocument.create()
        for (const pdfBuffer of pdfsToMerges) {
            const pdf = await PDFDocument.load(pdfBuffer)
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
            copiedPages.forEach(page => {
                mergedPdf.addPage(page)
            })
        }
        return await mergedPdf.save()
    }

    @Component({
        components: {VoucherDialog},
    })
    export default class VoucherMixin extends Vue {
        isShowVoucherDialog = false
        voucherLoading = false
        voucherService = null

        //TODO Need this shit simplify and refactoring
        async downloadVoucher(voucherService, orderId) {
            this.voucherLoading = orderId || true
            this.voucherService = voucherService && voucherService.orderId ? voucherService : null
            if (this.orderId) {
                this.voucherService = {...voucherService, orderId: this.orderId}
            }
            const errorMessages = []
            const downloadVoucher = async service => {
                try {
                    return await this.$api.voucher.get({
                        processId: service.processId.replace(/^.{6}/, ''),
                    })
                } catch (error) {
                    errorMessages.push(error.errors[0]?.message)
                    return null
                }
            }
            let data
            const order =
                runtimeStore.orderDetails.services && runtimeStore.orderDetails.services.length
                    ? runtimeStore.orderDetails
                    : runtimeStore.ordersResponse.orders.find(
                          order => order.orderId === this.voucherService?.orderId || order.orderId === orderId
                      )
            const fileName = this.getVoucherFileName(order)
            if (!this.voucherService) {
                const services = this.sortedServices || this.getSortedServices(order.services)
                const servicesForVoucher = [...services].filter(s => runtimeStore.isServiceVoucherAvailable(s))
                const results = []
                for (const service of servicesForVoucher) {
                    const result = await downloadVoucher(service)
                    if (result) results.push(result)
                }
                if (results.length) data = await mergePdfs(results)
            } else {
                data = await downloadVoucher(this.voucherService)
            }
            if (data) downloadData(data, fileName)
            else {
                this.$toast.warning(errorMessages.join(' '))
            }

            this.voucherLoading = false
        }

        getVoucherFileName(order) {
            const tourLead = order.services[0]?.travelers?.find(e => e.isTourLead) || order.services[0]?.travelers[0]
            const tourLeadLastName = tourLead.name[0].lastName?.toUpperCase().replace(' ', '_')
            const dateReverse = new Date().toISOString().slice(0, 10).replace(/-/g, '')

            return `${order.orderId ? order.orderId + '-' : ''}${
                tourLeadLastName ? tourLeadLastName + '-' : ''
            }Voucher${dateReverse ? '-' + dateReverse : ''}.pdf`
        }

        getSortedServices(services) {
            const sortedServices = [...services]

            sortedServices.sort((a, b) => {
                const sortByTypeOrder = [
                    'FLIGHT',
                    'OWNCHARTER',
                    'TRAIN',
                    'TRANSFER',
                    'ACCOMMODATION',
                    'ACTIVITY',
                    'EXTRASERVICE',
                    'OWNEXTRASERVICE',
                    'CARRENT',
                    'PACKAGE_TOUR',
                    'DYNAMIC_PACKAGE',
                    'INSURANCE',
                ]
                if (a.serviceType !== b.serviceType) {
                    const firstIndex = sortByTypeOrder.indexOf(a.serviceType)
                    const secondIndex = sortByTypeOrder.indexOf(b.serviceType)
                    if (firstIndex === -1) {
                        return 1
                    }
                    if (secondIndex === -1) {
                        return -1
                    }
                    return firstIndex - secondIndex
                } else {
                    return 0
                }
            })

            sortedServices.sort((a, b) => {
                if (appInstance.$dateFns.isSameDay(new Date(a.startDateTime), new Date(b.startDateTime))) {
                    if (
                        a.serviceType === 'TRANSFER' &&
                        (b.serviceType === 'FLIGHT' || b.serviceType === 'OWNCHARTER')
                    ) {
                        if (
                            a.serviceDetails?.[0]?.arrivalPlace?.airportCode ===
                            b.serviceDetails?.[0]?.itineraries?.[0]?.flightSegments?.[0]?.departureAirportCode
                        ) {
                            return -1
                        }
                    }

                    return 0
                }
                return appInstance.$dateFns.isAfter(new Date(a.startDateTime), new Date(b.startDateTime)) ? 1 : -1
            })

            sortedServices.sort((a, b) => {
                return a.status === b.status ? 0 : b.status === 'Canceled' ? -1 : 1
            })

            sortedServices.sort((a, b) => {
                return a.serviceDetails?.[0]?.extraServiceProductType === b.serviceDetails?.[0]?.extraServiceProductType
                    ? 0
                    : b.serviceDetails?.[0]?.extraServiceProductType === 'BANK_FEE'
                    ? -1
                    : 1
            })

            return sortedServices
        }

        async sendVoucherOnEmail(service, voucherEmail) {
            const successMessage = runtimeStore.isServiceFlight(service)
                ? this.$t('success_message.ticket_email')
                : this.$t('success_message.voucher_email')
            const errorMessage = runtimeStore.isServiceFlight(service)
                ? this.$t('error_message.ticket_email')
                : this.$t('error_message.voucher_email')

            try {
                this.voucherLoading = true
                await this.$api.voucher.get({
                    processId: service.processId.replace(/^.{6}/, ''),
                    emails: voucherEmail,
                })
                this.$toast.success(successMessage)
            } catch (error) {
                if (error.errors[0].message === "Internal error. Recipient's email is not formed correctly.") {
                    this.$toast.error(this.$t('error_message.voucher_email_not_valid'))
                } else {
                    this.$toast.error(errorMessage)
                }
            } finally {
                this.voucherLoading = false
                this.isShowVoucherDialog = false
            }
        }
    }
</script>
