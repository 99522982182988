<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" md="4">
                <city-autocomplete
                    v-model="city"
                    :single-line="false"
                    :show-placeholder="false"
                    :label="
                        pointType === 'arrival'
                            ? productFieldLabel('transfers.departure_to_city', 'POINT_SHIP_CITY')
                            : productFieldLabel('transfers.arriving_from_city', 'POINT_SHIP_CITY')
                    "
                    show-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="trainNumber"
                    :label="productFieldLabel('transfers.train_number', 'POINT_TRAIN_NUMBER')"
                    :rules="trainNumberRules"
                />
            </v-col>
            <v-col cols="12" md="3">
                <date-picker
                    v-model="date"
                    :label="productFieldLabel('date', 'POINT_TRAIN_TIME')"
                    :rules="trainDateRules"
                />
            </v-col>
            <v-col cols="12" md="1">
                <time-field
                    v-model="time"
                    :label="productFieldLabel('time', 'POINT_TRAIN_TIME')"
                    :rules="trainTimeRules"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import AirportsSearchInput from '~/components/snippets/AirportsSearchInput'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TransferTrainStationForm from '@/components/transfers/booking/forms/TransferTrainStationForm'

    @Component({
        components: {TimeField, AirportsSearchInput, DatePicker},
    })
    export default class TrainForm extends TransferTrainStationForm {
        @Prop() serviceType

        getProp(prop) {
            if (!this.request[this.pointType]) return null

            return this.request[this.pointType][this.serviceType][prop]
        }

        setProp(prop, val) {
            this.request[this.pointType][this.serviceType][prop] = val
        }

        async initData() {
            if (this.cityId) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.cityId, limit: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {
                    this.cityId = null
                }
            }
        }

        get cityId() {
            return this.getProp('cityId')
        }

        set cityId(val) {
            this.setProp('cityId', val)
        }
    }
</script>
