<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {cruisesRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class CruisesPageMixin extends Vue {
        async loadRuntimeData() {
            if (cruisesRuntimeStore.departureCity?.id !== this.searchRequest.departureCityId) {
                await cruisesRuntimeStore.loadDepartureCity(this.searchRequest.departureCityId)
            }
            if (cruisesRuntimeStore.destinationCity.id !== this.searchRequest.destinationCityId) {
                await cruisesRuntimeStore.loadDestinationCity(this.searchRequest.destinationCityId)
            }
        }
    }
</script>
