<template>
    <div v-if="product.info">
        <h1 class="title">
            {{ product.info.name }}
        </h1>
        <div v-if="product.shortDescription" v-html="product.shortDescription" />
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    //TODO Remove this component (not used)
    @Component
    export default class ActivityInfo extends Vue {
        @Prop({
            required: true,
        })
        product
    }
</script>
