<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {flightsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class FlightPageMixin extends Vue {
        async loadRuntimeData() {
            const isSameRoutes = this.searchRequest.routes?.every((route, index) => {
                const split = route.split(',')[0].split('-')
                const storeRoute = flightsRuntimeStore.routes[index]
                if (!storeRoute) return false
                const date = route.split(',')[1]
                if (date !== storeRoute.date) {
                    flightsRuntimeStore.SET_ROUTE_DATE({index, date})
                }
                return storeRoute.departure.iataCode === split[0] && storeRoute.arrival.iataCode === split[1]
            })
            if (!isSameRoutes) {
                await flightsRuntimeStore.loadRoutes(this.searchRequest.routes)
            }
        }
    }
</script>
