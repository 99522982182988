<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="filteredOffers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <flight-search-summary :search-request="searchRequest" :search-active="searchActive" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <flight-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
            <overlay-progress :active="filterActive && !searchActive" />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.offers.length"
                :search-active="searchActive"
                :hide-stop-btn="$route.name === 'flightsAvailability'"
                @stop="stopSearch"
            />
            <v-dialog v-if="$route.name === 'flights'" v-model="flexibleFlightsModal" width="100%" max-width="1200px">
                <template v-slot:activator="{on}">
                    <v-btn depressed block @click="getFlexibleFlights()" v-on="on">
                        {{ $t('flights.flexible') }}
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        <flexible-flights :flights="flexibleFlights" :dates="flexibleDates" />
                    </v-card-text>
                </v-card>
            </v-dialog>
            <flight-best-offers :offers="filteredOffers" class="my-3" @sort="sortKey = $event" />
            <flight-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <flight-conditions-modal-info />
                </template>
            </conditions-modal>
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <flight-map :_expanded.sync="mapExpanded" :points="mapPoints" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import Pagination from '@/components/search/Pagination'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import FlightBreadcrumbs from '@/components/flights/FlightBreadcrumbs'
    import FlightSearchSummary from '@/components/flights/search/FlightSearchSummary'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import FlightMap from '@/components/flights/search/FlightMap'
    import FlightFilters from '~src/components/flights/search/flightFilters.src'
    import FlightOffers from '@/components/flights/search/offers/FlightOffers'
    import FlightBestOffers from '@/components/parts/Flights/FlightBestOffers'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import FlightStoreMixin from '@/mixins/FlightStoreMixin'
    import {flightsRuntimeStore, persistentStore} from '@/store'
    import SearchLayout from '@/components/parts/SearchLayout'
    import FlightPageMixin from '@/components/flights/mixins/FlightPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SortSelect from '@/components/search/SortSelect'
    import FlightConditionsModalInfo from '@/components/flights/snippets/FlightConditionsModalInfo'
    import Tmp_SearchPageBase from '@/mixins/Tmp_SearchPageBase'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import OverlayProgress from '@/components/OverlayProgress'

    @Component({
        components: {
            OverlayProgress,
            FlightConditionsModalInfo,
            SortSelect,
            ExpiredOffersSnackbar,
            FlightSearchSummary,
            SearchProgress,
            FlightMap,
            FlightFilters,
            FlightOffers,
            Pagination,
            FlightBreadcrumbs,
            ConditionsModal,
            SearchLayout,
            FlightBestOffers,
            FlexibleFlights: () => import('@/components/flights/FlexibleFlights'),
        },
        layout: 'blank',
    })
    export default class FlightsPage extends mixins(
        FlightPageMixin,
        FlightStoreMixin,
        SearchPageMixin,
        SearchPageBase,
        Tmp_SearchPageBase
    ) {
        flexibleFlightsModal = false

        async fetch() {
            flightsRuntimeStore.SET_SEARCH_TYPE(this.$route.name === 'flights' ? 'price' : 'schedule')
            await this.loadRuntimeData()
        }

        getFlexibleFlights() {
            const rq = {...this.$route.query}
            rq.flexibleDays = 3
            rq.convertToCurrency = persistentStore.currency
            this.productRuntimeStore.searchFlexibleFlights(rq)
        }

        async search() {
            this.flexibleFlightsModal = false
            await this.productRuntimeStore.search(this.searchRequest)
        }

        get mapPoints() {
            let result = []
            flightsRuntimeStore.routes.forEach(({arrival, departure}) => {
                result = [...result, departure, arrival]
            })
            return result
        }

        get flexibleFlights() {
            return this.productRuntimeStore.flexibleFlights
        }

        get flexibleDates() {
            return this.productRuntimeStore.flexibleDates
        }

        get sortItems() {
            return [
                ...Tmp_SearchPageBase.options.computed.sortItems.get.call(this),
                {
                    text: this.$t('sort.best'),
                    value: 'best',
                },
                {
                    text: this.$t('sort.duration_asc'),
                    value: 'durationAsc',
                },
                {
                    text: this.$t('sort.duration_desc'),
                    value: 'durationDesc',
                },
                {
                    text: this.$t('sort.airline'),
                    value: 'airline',
                },
                {
                    text: this.$t('sort.departure_time'),
                    value: 'departureTime',
                },
                {
                    text: this.$t('sort.arrival_time'),
                    value: 'arrivalTime',
                },
            ]
        }
    }
</script>
