<template>
    <div v-if="activityDuration" class="d-flex align-center">
        <v-icon left small dense>mdi-timer-outline</v-icon>
        {{ activityDuration }}
    </div>
</template>

<script>
    import {Prop, Vue} from 'nuxt-property-decorator'
    import {Component} from 'vue-property-decorator'

    @Component
    export default class ActivityDuration extends Vue {
        @Prop() days
        @Prop() time

        get activityDuration() {
            if (this.time) {
                const hm = this.time.split(':')

                const hours = +hm[0]
                const minutes = +hm[1]

                return `${hours ? hours + ' ' + this.$tc('hour', hours) : ''} ${
                    minutes ? minutes + ' ' + this.$tc('minute', minutes) : ''
                } `
            } else {
                return this.days ? `${this.days} ${this.$tc('day', this.days)}` : undefined
            }
        }
    }
</script>
