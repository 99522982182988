<template>
    <div v-if="service.pricingSourceType === 'PRIVATE'" class="accent--text">
        {{ $t(`pricingSourceType.${service.pricingSourceType}`) }}
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class PricingSourceTypeLabel extends Vue {
        @Prop({required: true, type: Object}) service
    }
</script>
