<template>
    <v-form ref="form">
        <v-row class="mb-2">
            <v-col cols="12" md="3">
                <vc-date-picker
                    v-model="infoByServiceType.flight.lastTicketingDate"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DDTHH:mm'}"
                    :locale="$i18n.locale"
                    :min-date="$dateFns.format(new Date())"
                    :max-date="service.startDateTime"
                    :first-day-of-week="$config.firstDayOfWeek || undefined"
                    mode="dateTime"
                    is24hr
                    is-required
                >
                    <template v-slot="{inputEvents}">
                        <div v-on="inputEvents">
                            <v-text-field
                                :label="`${$t('flights.last_ticketing_date')}`"
                                readonly
                                hide-details
                                background-color="white"
                                prepend-inner-icon="mdi-av-timer"
                                :value="$options.filters.dateTimeFormat(infoByServiceType.flight.lastTicketingDate)"
                                outlined
                                dense
                            />
                        </div>
                    </template>
                </vc-date-picker>
            </v-col>
            <v-col v-if="isOwnProduct" :cols="12" :md="4">
                <v-select
                    v-model="infoByServiceType.flight.marketingAirline"
                    :items="airlines"
                    :label="$t('flights.marketing_airline')"
                    dense
                    outlined
                />
            </v-col>
        </v-row>
        <v-card v-for="(itinerary, itineraryIndex) of itineraries" :key="itineraryIndex" outlined class="mb-10">
            <v-card-text>
                <v-row
                    v-for="(segment, segmentIndex) in infoByServiceType.flight.segments.filter(
                        segment => segment.itineraryIndex === itineraryIndex
                    )"
                    :key="`${itineraryIndex}${segmentIndex}`"
                >
                    <v-col :cols="6" :md="1">
                        <v-text-field
                            v-model="segment.departureAirportCode"
                            :label="$t('flights.departure_from')"
                            dense
                            disabled
                            hide-details
                            outlined
                        />
                    </v-col>
                    <v-col :cols="6" :md="1">
                        <v-text-field
                            v-model="segment.arrivalAirportCode"
                            :label="$t('flights.arrival_to')"
                            dense
                            disabled
                            hide-details
                            outlined
                        />
                    </v-col>
                    <v-col :cols="12" :md="4">
                        <daterange-m
                            :end-date.sync="segment.arrivalDate"
                            :label="$t('flights.departure_arrival_date')"
                            :start-date.sync="segment.departureDate"
                            dense
                            mode="dateTime"
                            outlined
                            :single-line="false"
                            :disabled="!isOwnProduct"
                            :popover="{placement: 'bottom', positionFixed: true}"
                        />
                    </v-col>
                    <v-col :cols="12" :md="3">
                        <v-text-field
                            v-model="segment.departureTerminal"
                            :label="$t('flights.departure_terminal')"
                            dense
                            hide-details
                            outlined
                            :disabled="!isOwnProduct"
                        />
                    </v-col>
                    <v-col :cols="12" :md="3">
                        <v-text-field
                            v-model="segment.arrivalTerminal"
                            :label="$t('flights.arrival_terminal')"
                            dense
                            hide-details
                            outlined
                            :disabled="!isOwnProduct"
                        />
                    </v-col>
                    <v-col :cols="12" :md="2">
                        <v-text-field
                            v-model="segment.flightNumber"
                            :label="$t('flights.flight_number')"
                            dense
                            hide-details
                            outlined
                            :disabled="!isOwnProduct"
                        />
                    </v-col>
                    <v-col :cols="12" :md="2">
                        <v-select
                            v-model="segment.serviceClass"
                            :label="$t('flights.class')"
                            :items="serviceClasses"
                            dense
                            hide-details
                            outlined
                            clearable
                            :disabled="!isOwnProduct"
                        />
                    </v-col>
                    <v-col v-if="!isOwnProduct" :cols="12">
                        <v-row v-for="(traveler, travelerIndex) of travelers" :key="travelerIndex">
                            <v-col :cols="12" class="text-capitalize text-subtitle-1">
                                {{
                                    `${$tc('passenger', 1)} ${travelerIndex + 1} ${
                                        traveler.type === 'child' ? `(${$tc('child')})` : ''
                                    }`
                                }}
                            </v-col>
                            <v-col :cols="12" :md="2">
                                <v-text-field
                                    v-model="findSeat(travelerIndex, itineraryIndex, segmentIndex).rowNumber"
                                    :label="$t('flights.row_number')"
                                    dense
                                    hide-details
                                    outlined
                                />
                            </v-col>
                            <v-col :cols="12" :md="2">
                                <v-text-field
                                    v-model="findSeat(travelerIndex, itineraryIndex, segmentIndex).seatNumber"
                                    :label="$t('flights.seat_number')"
                                    dense
                                    hide-details
                                    outlined
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <template v-if="!isOwnProduct">
                    <v-divider class="my-5" />
                    <div class="text-capitalize text-subtitle-1 font-weight-medium">
                        {{ $t('flights.baggage') }}
                    </div>
                    <v-row v-for="(traveler, travelerIndex) of travelers" :key="travelerIndex">
                        <v-col :cols="12" class="text-capitalize text-subtitle-1">
                            {{
                                `${$tc('passenger', 1)} ${travelerIndex + 1} ${
                                    traveler.type === 'child' ? `(${$tc('child')})` : ''
                                }`
                            }}
                        </v-col>
                        <v-col :cols="12" :md="2">
                            <v-text-field
                                v-model="flightExtraServices[itineraryIndex * travelers.length + travelerIndex].name"
                                :label="$t('flights.baggage')"
                                :rules="
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].quantity ||
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].status
                                        ? [requiredRule('flights.baggage')]
                                        : []
                                "
                                dense
                                hide-details
                                outlined
                            />
                        </v-col>
                        <v-col :cols="12" :md="2">
                            <v-text-field
                                v-model="
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].quantity
                                "
                                :label="$t('flights.baggage_quantity')"
                                :rules="
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].name ||
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].status
                                        ? [
                                              requiredRule('flights.baggage_quantity'),
                                              positiveNumberRule('flights.baggage_quantity'),
                                          ]
                                        : []
                                "
                                min="0"
                                max="25"
                                dense
                                hide-details
                                outlined
                                type="number"
                                @keyup="
                                    changeBaggageQuantity($event, itineraryIndex * travelers.length + travelerIndex)
                                "
                            />
                        </v-col>
                        <v-col :cols="12" :md="4">
                            <v-select
                                v-model="flightExtraServices[itineraryIndex * travelers.length + travelerIndex].status"
                                :items="statusItems"
                                :label="$t('status')"
                                :rules="
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].name ||
                                    flightExtraServices[itineraryIndex * travelers.length + travelerIndex].quantity
                                        ? [requiredRule('status')]
                                        : []
                                "
                                dense
                                hide-details
                                outlined
                                clearable
                            />
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, VModel} from 'nuxt-property-decorator'
    import DaterangeM from '@/components/search/forms/DaterangeM'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {flightsStore, runtimeStore} from '@/utils/store-accessor'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {DaterangeM, DatePicker},
    })
    export default class ModifyManualFlight extends mixins(FormValidationRulesMixin) {
        @VModel() infoByServiceType
        @Prop() service

        async mounted() {
            if (this.isOwnProduct) {
                await flightsStore.loadAirlines()
            }
        }

        findSeat(travelerIndex, itineraryIndex, segmentIndex) {
            return this.infoByServiceType.flight.seats.find(
                ({travelerRPH, segmentRPH}) =>
                    travelerRPH === `${travelerIndex + 1}` && segmentRPH === `${itineraryIndex + 1}${segmentIndex + 1}`
            )
        }

        changeBaggageQuantity(e, q) {
            if ((e.key === 'Backspace' || e.key === 'Delete') && this.flightExtraServices[q].quantity === '')
                this.flightExtraServices[q].quantity = 0
            if (!this.flightExtraServices[q].quantity || this.flightExtraServices[q].quantity < 0)
                this.flightExtraServices[q].quantity = 0
        }

        get flightExtraServices() {
            return this.infoByServiceType.flight.flightExtraServices
        }

        get travelers() {
            return this.service.travelers
        }

        get itineraries() {
            return this.service.serviceDetails[0].itineraries
        }

        get serviceClasses() {
            return [
                {text: this.$t('flights.classes.ECONOMY'), value: 'ECONOMY'},
                {text: this.$t('flights.classes.PREMIUM_ECONOMY'), value: 'PREMIUM_ECONOMY'},
                {text: this.$t('flights.classes.FIRST'), value: 'FIRST'},
                {text: this.$t('flights.classes.BUSINESS'), value: 'BUSINESS'},
            ]
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service?.supplierCode)
        }

        get statusItems() {
            return [
                'DECLINED',
                'CANCELLED',
                'PENDING_CONFIRMATION',
                'PLACING_ON_WAITLIST',
                'HOLDS_CONFIRMED_EMD_REQUIRED',
                'HOLDS_CONFIRMED_NO_EMD_REQUIRED',
                'HOLDS_CONFIRMED_EMD_ISSUED',
            ].map(value => ({text: this.$t(`flights.flightExtraServiceStatus.${value}`), value}))
        }

        get airlines() {
            return flightsStore.airlines.map(({codes, name}) => {
                return {
                    text: name[this.$i18n.locale] || name['en'],
                    value: codes.IATA || codes.SUPPL,
                }
            })
        }
    }
</script>
