<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import ConfirmationRedirectMixin from '~src/mixins/confirmationRedirectMixin.src'
    import BookingMixin from '@/components/mixins/BookingMixin.vue'
    import BookingTouristsMixin from '@/components/booking/mixins/BookingTouristsMixin.vue'
    import AddOnMixin from '@/components/booking/addOns/AddOnMixin.vue'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {
        EventBus,
        OFFER_EXPIRED_EVENT,
        PREPARE_BOOK,
        RESET_BOOKING_DATA,
        SHOW_LOYALTY_POINTS,
    } from '@/utils/event-bus'
    import {isPrepareBookResultClean, prepareBookResult} from '@/utils/PrepareBookAdapter'
    import {clean, clone} from '@/utils/helpers'

    @Component
    export default class BookingPageMixin extends mixins(
        ConfirmationRedirectMixin,
        BookingMixin,
        BookingTouristsMixin,
        AddOnMixin,
        ProductBookingFieldsMixin
    ) {
        mobileTabs = 0
        isValid = false
        bookingKey = ''
        paymentMethodId = null
        numberOfPayments = null
        pageLock = false
        isAgreementsConfirmed = false
        corporatePolicyViolationCodeId = null
        userRegistration = false
        selectedPersonId = null
        resolveLoyalty = true
        isSavingWithoutDetails = false
        dueToConfirmDate = null
        dueToConfirmDateActive = false
        extraServices = []
        loaded = false

        async load() {
            await this.$store.restored
            this.resetBookResponse()
            this.setTourists()
            this.checkPaymentStatus()
            this.clearAdjustedPrice()
            if (this.productType !== 'extraService' && this.productType !== 'insurance') {
                const offerKeys = [this.$route.query.offerKey]
                await persistentStore.getPaymentOptions({offerKeys})
            }
            this.loaded = true
        }

        clearAdjustedPrice() {
            if (this.productStore.prepareBookRequest.adjustedPriceAmount) {
                this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({
                    prop: 'adjustedPriceAmount',
                    val: null,
                })
            }
        }

        resetBookResponse() {
            this.bookingKey = null
            this.basketKey = null
            this.productStore.SET_PREPARE_BOOK_RESPONSE({})
            this.basketPrepareBookResponses = null
            this.tmpBasketKey = null
            persistentStore.CLEAR_BASKET_ADD_ONS()
        }

        checkPaymentStatus() {
            switch (this.$route.query.paymentStatus) {
                case 'cancel':
                    this.$toast.warning(this.$t('warning_message.payment_was_cancelled'))
                    break
                case 'failure':
                    this.$toast.error(this.$t('error_message.payment_error'))
                    break
            }
        }

        @Watch('registeredPerson', {immediate: false, deep: true})
        onUpdateRegisteredPerson(person) {
            this.setTourLead(person)
        }

        @Watch('person', {immediate: false, deep: true})
        onUpdatePerson(person) {
            if (this.user.canBeTourlead) {
                this.setTourLead(person)
            }
        }

        //TODO Need additional analyse how to refactoring
        setTourLead(person, selectedPerson = false) {
            if (!person || this.userRegistration || person.memberLevel === 'GUEST') return
            const tourist = this.touristFromPerson(person)
            if (!tourist) return
            this.tourLead = tourist
            if (selectedPerson) {
                this.selectedPersonId = person.personId
            }
        }

        created() {
            EventBus.$on(OFFER_EXPIRED_EVENT, this.onOfferExpired)
            EventBus.$on(PREPARE_BOOK, this.prepareBook)
        }

        beforeDestroy() {
            EventBus.$off(OFFER_EXPIRED_EVENT, this.onOfferExpired)
            EventBus.$off(PREPARE_BOOK, this.prepareBook)
            this.productStore.SET_PREPARE_BOOK_RESPONSE({})
        }

        onOfferExpired() {
            this.pageLock = true
        }

        makeValidation(selectiveForm = null) {
            const validation = this.validNestedForms(selectiveForm || this.$refs.mainForm)
            this.isValid = validation.validForms.every(valid => valid)
            if (!this.isValid) {
                const indexLink = validation.validForms.findIndex(el => !el)
                this.$vuetify.goTo(validation.links[indexLink], {
                    duration: 500,
                    easing: 'linear',
                })
            }
        }

        validNestedForms(comp) {
            let validForms = []
            let links = []
            if (comp.$refs.form && comp.$refs.form.validate) {
                validForms.push(comp.$refs.form.validate())
                links.push(comp)
            }
            if (comp.$children.length) {
                comp.$children.forEach(childComp => {
                    validForms = [...validForms, ...this.validNestedForms(childComp).validForms]
                    links.push(...this.validNestedForms(childComp).links)
                })
            }
            return {validForms, links}
        }

        async prepareBook() {
            this.isSavingWithoutDetails = false
            await this.nextTick
            if (this.isB2C && this.isLoyaltyAvailable && !this.resolveLoyalty) {
                this.$refs.loyaltyPoints.makeLoyaltyOptions()
                return
            }

            this.makeValidation()
            if (!this.isValid) return

            //1) Auth user step
            if (!authStore.person && authStore.registeredPerson.memberLevel === 'REGISTERED') {
                if (!(await this.registerPerson())) return
            }

            //2) Prepare book step
            await this.prepareBookSecondStep()
        }

        async prepareBookSecondStep() {
            if (this.selectedAddOns.length) {
                await this.prepareBookWithAddOns()
                return
            }
            const prepareBookRequest = this.prepareBookRequest()
            await this.sendPrepareBookRequest(prepareBookRequest)
        }

        loyaltyPointsPaymentOptions(prepareBookResponse) {
            if (this.isB2C && this.isLoyaltyAvailable) {
                return new Promise(resolve => {
                    EventBus.$emit(SHOW_LOYALTY_POINTS, {resolve, prepareBookResponse})
                })
            } else {
                return {}
            }
        }

        get isLoyaltyAvailable() {
            return this.offer.rooms?.some(room => !!room.loyaltyPoints) && authStore.person?.totalLoyaltyPoints
        }

        prepareBookRequest(specifiedTouristBookingFields, includeOptionalTourists = false) {
            const prepareBookRequest = Object.assign({}, this.productStore.prepareBookRequest, {
                tourists: this.prepareBookTourists(
                    this.tourists,
                    specifiedTouristBookingFields,
                    includeOptionalTourists
                ),
                offerKey: this.$route.query.offerKey,
            })
            if (this.isB2C && authStore.person) {
                prepareBookRequest.personId = authStore.person.personId
            } else if (this.selectedPersonId) {
                prepareBookRequest.personId = this.selectedPersonId
            } else {
                delete prepareBookRequest.personId
            }
            if (persistentStore.selectedOrderId) {
                prepareBookRequest.orderId = persistentStore.selectedOrderId
            } else if (prepareBookRequest.orderId) {
                delete prepareBookRequest.orderId
            }
            if (this.extraServices?.length) {
                prepareBookRequest.extraServices = this.extraServices
            } else if (prepareBookRequest.extraServices?.length) {
                delete prepareBookRequest.extraServices
            }
            if (this.specialRemarkRM && this.specialRemarkRM?.length) {
                prepareBookRequest.specialRemarkRM = this.specialRemarkRM
            }
            return prepareBookRequest
        }

        async sendPrepareBookRequest(prepareBookRequest) {
            try {
                await this.productStore.prepareBook(prepareBookRequest)
                if (isPrepareBookResultClean(prepareBookResult(this.prepareBookResponse, this.offersData))) {
                    await this.paymentStepInit()
                }
                return true
            } catch (e) {
                return this.prepareBookErrorProcessing(e)
            }
        }

        save() {
            this.paymentMethodId = this.saveMethod.id
            this.book()
        }

        isSomeTouristFieldFilled(tourists) {
            return tourists.some(tourist => {
                let result = false
                for (let key in tourist) {
                    if (tourist[key]) {
                        result = true
                        break
                    }
                }
                return result
            })
        }

        async saveWithoutDetails() {
            this.isSavingWithoutDetails = true
            await this.nextTick
            const prepareBookRequest = clone(this.prepareBookRequest(null, true))
            const isSomeTransferFieldFilled = point => {
                let result = false

                for (let key in point) {
                    if (key === 'address' || key === 'hotelName')
                        result =
                            this.isProductFieldMandatory('POINT_HOTEL_NAME') ||
                            this.isProductFieldMandatory('POINT_ADDRESS')
                    else if (key === 'postalCode') result = this.isProductFieldMandatory('POINT_POSTAL_CODE')
                    else if (key === 'flightNumber') result = this.isProductFieldMandatory('POINT_FLIGHT_NUMBER')
                    else if (key === 'otherAirportCode') result = this.isProductFieldMandatory('POINT_AIRPORT')
                    else if (key === 'city')
                        result =
                            this.isProductFieldMandatory('POINT_SHIP_CITY') ||
                            this.isProductFieldMandatory('POINT_MANUAL_CITY') ||
                            this.isProductFieldMandatory('POINT_TRAIN_CITY')
                    else if (key === 'shipNumber') result = this.isProductFieldMandatory('POINT_SHIP_NUMBER')
                    else if (key === 'trainNumber') result = this.isProductFieldMandatory('POINT_TRAIN_NUMBER')

                    if (result) break
                }

                return result
            }
            const findForm = (el, nestedForm) => {
                let form = {}
                if (el?.$refs[nestedForm]) {
                    form = el.$refs[nestedForm]
                } else if (el?.$children?.length) {
                    form = el.$children.find(child => {
                        let f = findForm(child, nestedForm)
                        return f && Object.keys(f).length
                    })
                }

                if (form && Object.keys(form).length) return form
            }

            let rq
            if (this.productType !== 'hotel') {
                rq = {
                    offerKey: prepareBookRequest.offerKey,
                }
                if (prepareBookRequest.personId) rq.personId = prepareBookRequest.personId
                if (prepareBookRequest.orderId) rq.orderId = prepareBookRequest.orderId
                if (prepareBookRequest.extraServices?.length) rq.extraServices = prepareBookRequest.extraServices
                if (prepareBookRequest.dropOffPoint) rq.dropOffPoint = prepareBookRequest.dropOffPoint
                if (prepareBookRequest.pickUpPoint) rq.pickUpPoint = prepareBookRequest.pickUpPoint
                if (prepareBookRequest.pickupSign) rq.pickupSign = prepareBookRequest.pickupSign
                if (prepareBookRequest.time) rq.time = prepareBookRequest.time
                if (this.productType === 'tour' && prepareBookRequest.entryParams)
                    rq.entryParams = prepareBookRequest.entryParams
                if (this.productType !== 'carsRent' && this.isSomeTouristFieldFilled(prepareBookRequest.tourists)) {
                    this.makeValidation(findForm(this.$refs.mainForm, 'touristForm'))
                    if (!this.isValid) return
                    rq.tourists = prepareBookRequest.tourists
                }

                if (this.productType === 'carsRent' && this.isSomeTouristFieldFilled([prepareBookRequest.driver])) {
                    this.makeValidation(findForm(this.$refs.mainForm, 'touristForm'))
                    if (!this.isValid) return
                    rq.driver = prepareBookRequest.driver
                }

                if (prepareBookRequest.arrival && Object.keys(prepareBookRequest.arrival).length > 1) {
                    rq.arrival = prepareBookRequest.arrival
                    clean(rq.arrival)
                }
                if (prepareBookRequest.departure && Object.keys(prepareBookRequest.departure).length > 1) {
                    rq.departure = prepareBookRequest.departure
                    clean(rq.departure)
                }
                if (
                    this.productType === 'transfer' &&
                    Object.keys(runtimeStore.productBookingFields)?.length &&
                    (isSomeTransferFieldFilled(rq.arrival) || isSomeTransferFieldFilled(rq.departure))
                ) {
                    this.makeValidation(findForm(this.$refs.mainForm, 'transferForm'))
                    if (!this.isValid) return
                }
                if (prepareBookRequest.comments) rq.comments = prepareBookRequest.comments
            } else {
                rq = []
                prepareBookRequest.forEach(el => {
                    const data = {
                        offerKey: el.offerKey,
                    }

                    if (el.personId) data.personId = el.personId
                    if (el.orderId) data.orderId = el.orderId
                    if (el.extraServices?.length) data.extraServices = el.extraServices
                    if (el.comments) data.comments = el.comments

                    if (this.isSomeTouristFieldFilled(el.tourists)) {
                        data.tourists = el.tourists
                        this.makeValidation()
                    }

                    rq.push(data)
                })
                if (!this.isValid && prepareBookRequest.some(el => this.isSomeTouristFieldFilled(el.tourists))) return
            }

            if (this.selectedAddOns.length) {
                await this.prepareBookWithAddOns()
                return
            }

            if (this.productType === 'transfer') {
                await this.productStore.prepareBook({
                    rq,
                    isReturnTransfer: this.isReturnTransfer,
                })
            } else {
                if (prepareBookRequest.extraBaggage) {
                    rq.extraBaggage = prepareBookRequest.extraBaggage
                }
                if (prepareBookRequest.seats) {
                    rq.seats = prepareBookRequest.seats
                }
                if (prepareBookRequest.specialRemarkRM) {
                    rq.specialRemarkRM = prepareBookRequest.specialRemarkRM
                }
                await this.productStore.prepareBook(rq)
            }

            if (
                !this.isPrepareBookResponseExist ||
                (this.$refs.prepareBookMessagesModal && this.$refs.prepareBookMessagesModal.messages.length === 0)
            ) {
                await this.bookWithoutDetails()
            } else {
                if (this.$refs.prepareBookMessagesModal) this.$refs.prepareBookMessagesModal.show = true
            }
        }

        async bookWithoutDetails() {
            this.initBookingKey()
            await this.getPaymentOptions({}, this.basketKey)
            this.paymentMethodId = this.saveMethod.id
            await this.book()
            this.isSavingWithoutDetails = false
        }

        async getPaymentOptions(loyaltyPointsOptions, basketKey) {
            try {
                const offer = this.offer.rooms ? this.offer.rooms[0] : this.offer
                const price = offer.price || offer.notDeltaPrice
                await persistentStore.getPaymentOptions({
                    ...loyaltyPointsOptions,
                    ...(!basketKey && {bookingKey: this.productStore.prepareBookResponse.bookingKey}),
                    ...(basketKey && {basketKey}),
                    convertToCurrency: persistentStore.getCurrency(price.currency),
                })
            } catch (e) {
                this.$toast.warning(this.$t('error_message.get_payment_options_unknown_error'))
            }
        }

        async registerPerson() {
            this.userRegistration = true
            try {
                await authStore.register(authStore.registeredPerson)
                return true
            } catch (e) {
                if (e.response && e.response.status === 500) {
                    return false
                }
                if (e.errors.length && e.errors[0].token === 'api.person.already.exist') {
                    //TODO In API make tokens with _
                    this.$toast.error(this.$t(`api_error_token.${e.errors[0].token.replace(/\./g, '_')}`))
                    return false
                } else {
                    this.$toast.error(this.$t('error_message.registration_on_booking_step_error'))
                    return false
                }
            } finally {
                this.userRegistration = false
            }
        }

        async book() {
            //3) Book step
            await this.$nextTick()
            this.pageLock = true
            const paymentMethod = persistentStore.paymentMethods.find(
                paymentMethod => paymentMethod.id === this.paymentMethodId
            )
            const success = !paymentMethod.onlinePSP ? await this.makeOfflinePayment() : await this.makeOnlinePayment()
            if (!success) this.pageLock = false
            this.clearCommentsAfterBooking()
            if (authStore.person && authStore.isB2C) {
                try {
                    const {totalLoyaltyPoints} = (await this.$api.privateClients.get({id: authStore.person.personId}))
                        .persons[0]
                    authStore.SET_PERSON({...authStore.person, totalLoyaltyPoints})

                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        clearCommentsAfterBooking() {
            this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'comments', val: ''})
        }

        cancelFlow() {
            this.resetBookResponse()
            this.resolveLoyalty = true
            if (this.$refs.loyaltyPoints) {
                this.$refs.loyaltyPoints.close()
            }
            if (this.isSavingWithoutDetails) this.isSavingWithoutDetails = false
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }

        async makeOfflinePayment() {
            try {
                const bookRs = await persistentStore.book({
                    ...(this.bookingKey && {bookingKey: this.bookingKey}),
                    ...(this.basketKey && {basketKey: this.basketKey}),
                    paymentMethodId: this.paymentMethodId,
                    ...(this.corporatePolicyViolationCodeId && {
                        corporatePolicyViolationCodeId: this.corporatePolicyViolationCodeId,
                    }),
                    ...(this.dueToConfirmDateActive && {dueToConfirmDate: this.dueToConfirmDate}),
                })
                if (!persistentStore.selectedOrderId && !authStore.person) {
                    await this.$router.push({
                        name: this.productConfirmationPageName,
                        query: this.productConfirmationPageQuery(bookRs[0].orderId),
                    })
                } else {
                    await this.redirectAfterPayment(
                        bookRs[0].orderId,
                        this.productConfirmationPageName,
                        this.productConfirmationPageQuery(bookRs[0].orderId)
                    )
                    if (persistentStore.selectedOrderId) {
                        persistentStore.SET_SELECTED_ORDER_ID(null)
                    } else if (authStore.isB2B) {
                        EventBus.$emit(RESET_BOOKING_DATA)
                    }
                    if (this.isDriver || this.isGuide) {
                        this.$toast.success(this.$t('thanks_for_your_booking'))
                    }
                }
                return true
            } catch (e) {
                this.$toast.error(this.$t('error_message.unknown_booking_error'))
                return false
            }
        }

        productConfirmationPageQuery(orderId) {
            return {
                orderId,
                ...(this.bookingKey && {bookingKey: this.bookingKey}),
                ...(this.basketKey && {basketKey: this.basketKey}),
            }
        }

        getPaymentCancelUrl(paymentStatus) {
            return (
                window.location.origin +
                this.$router.resolve({
                    name: this.productBookingPageName,
                    query: this.productBookingPageQuery(paymentStatus),
                }).href
            )
        }

        productBookingPageQuery(paymentStatus) {
            return {
                paymentStatus,
                offerKey: this.$route.query.offerKey,
            }
        }

        get memberLevel() {
            return authStore.registeredPerson.memberLevel
        }

        get person() {
            return authStore.person
        }

        get saveMethod() {
            return persistentStore.paymentMethods?.find(paymentMethod => !paymentMethod.book)
        }

        get isLoading() {
            return runtimeStore.bookingActive || runtimeStore.authActive
        }

        get isB2C() {
            return authStore.isB2C
        }

        personSelected({person, index}) {
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                persistentStore.SET_TOURIST({index, val})
            } else {
                persistentStore.SET_TOURIST_PROP({index, prop: 'personId', val: null})
            }
        }

        get registeredPerson() {
            return authStore.registeredPerson
        }

        /**
         * @returns {CorporateUser|TourAgency|TourOperator|User}
         */
        get user() {
            return authStore.user
        }

        get isShowAccountBookingInfo() {
            return true
        }

        get isSaveMethodButtonTextStyle() {
            return true
        }

        get corporatePolicyViolated() {
            return this.productStore.prepareBookResponse &&
                this.productStore.prepareBookResponse.corporatePolicyViolation
                ? this.productStore.prepareBookResponse.corporatePolicyViolation
                : this.offer
                ? this.offer.corporatePolicyViolation
                : false
        }

        get violatedPolicies() {
            return this.productStore.prepareBookResponse
                ? this.productStore.prepareBookResponse.corporatePolicies
                : this.offer.corporatePolicy
        }

        get showTouristSelector() {
            return (
                !this.$config.booking.hideTouristSelector &&
                (authStore.isAgency || authStore.isTourOperator) &&
                !persistentStore.selectedOrderId
            )
        }

        get b2BAutoCompliteCitezenship() {
            return authStore.isTO1 || authStore.isAgency || authStore.isCorporate
        }

        get touristToken() {
            return 'passenger'
        }

        get canPay() {
            return true
        }

        get hasBookingFields() {
            return Object.keys(runtimeStore.touristBookingFields.bookingFields).length
        }

        get startDate() {
            return this.searchRequest.startDate
        }

        get formTitle() {
            let text = ''
            if (this.$config.personNameValidation) {
                text = this.$config.personNameValidation.all === 'latin' ? this.$t('notes.fill_in_english') : ''

                for (let key in this.$config.personNameValidation) {
                    if (key === String(this.countryId) && key === String(this.tourLead?.citizenshipId)) {
                        text += ` ${this.$t('or')} ` + this.$t(`notes.${this.$config.personNameValidation[key]}`)
                    }
                }
            }

            return text
        }

        get countryId() {
            return null
        }

        get canSaveWithoutDetails() {
            return this.saveMethod && this.productType !== 'extraService' && this.productType !== 'insurance'
        }

        get showAddOns() {
            return false
        }

        get bookingAdditionalOptions() {
            return this.productStore.bookingAdditionalOptions
        }

        //TODO Tmp for compatibility
        get additionalOptions() {
            return this.bookingAdditionalOptions
        }
    }
</script>
