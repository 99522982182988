<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'

    @Component
    export default class CityHotelAutocomplete extends CityAutocomplete {
        @Prop({default: 'ACCOMMODATION'}) productType

        get items() {
            return this.entries.map(entry => {
                const selectedLabel =
                        entry.type === 'city'
                            ? `${entry.name}, ${entry.parentName}` +
                              (entry.parentName !== entry.countryName ? `, ${entry.countryName}` : '')
                            : `${entry.name}, ${entry.cityName}, ${entry.countryName}`,
                    regex = new RegExp(this.search, 'i'),
                    label = selectedLabel.replace(regex, '<strong>$&</strong>')
                return Object.assign({}, entry, {label, selectedLabel})
            })
        }

        async searchHotels(val) {
            const rs = await this.$api.suggestHotels.get({
                pattern: val,
                limit: 10,
            })

            return this.mapHotels(rs.hotels)
        }

        async searchCities(val) {
            this.tryResearch.status = false
            const value = val.trim().toLowerCase()

            const rq = {
                pattern: `%${value}%`,
                limitCities: 10,
                orderBy: 'POPULARITY',
                ...(this.countryId && {countryId: this.countryId}),
                ...(this.productType && {productType: this.productType}),
                ...(this.productType === 'ACCOMMODATION' && {hotelsQuantity: 1}),
            }
            const rs = await this.$api.locations.get(rq)

            return rs.cities
        }

        mapHotels(hotels) {
            return hotels.map(hotel => {
                const location = hotel.location
                delete hotel.location
                return Object.assign(hotel, location, {parentName: location.countryName, type: 'hotel'})
            })
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                let cities = [],
                    hotels = []
                await Promise.all([
                    (async () => {
                        cities = await this.searchCities(val)
                    })(),
                    (async () => {
                        hotels = await this.searchHotels(val)
                    })(),
                ])
                this.entries = [...cities, ...hotels]

                if (!this.entries.length) {
                    this.tryResearch.status = true
                    this.tryResearch.message =
                        this.isMobile && this.showDialog
                            ? this.$t('try_rewrite_destination_mobile')
                            : this.$t('try_rewrite_destination')
                }
            } finally {
                this.loading = false
            }
        }

        icon(type) {
            switch (type) {
                case 'hotel':
                    return this.hotelIcon
                default:
                    return this.locationIcon
            }
        }

        get hotelIcon() {
            return 'mdi-office-building'
        }
    }
</script>
