<template>
    <div v-if="offer" class="mb-5">
        <strong class="d-block">{{ offer.info.description }}</strong>
        <strong v-if="searchRequest" class="d-block">
            {{ $t('date') }}: {{ searchRequest.dateTime | dateFormat }}
        </strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, HIDE_CONDITIONAL_MODAL_INFO, SHOW_TRANSFER_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class TransferConditionsModalInfo extends Vue {
        show = false
        searchRequest = {}
        offer = null

        mounted() {
            EventBus.$on(SHOW_TRANSFER_CONDITIONS_INFO, this.showInfo)
            EventBus.$on(HIDE_CONDITIONAL_MODAL_INFO, this.hideInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TRANSFER_CONDITIONS_INFO, this.showInfo)
            EventBus.$off(HIDE_CONDITIONAL_MODAL_INFO, this.hideInfo)
        }

        showInfo(searchRequest, offer) {
            this.searchRequest = searchRequest
            this.offer = offer
        }

        hideInfo() {
            this.offer = null
        }
    }
</script>
