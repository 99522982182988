<template>
    <v-tabs v-if="isMobile" v-model="mobileTabs" fixed-tabs class="sticky-bar">
        <v-tab v-for="tab in tabs" :key="tab.label">
            {{ isMobile ? $tc(`${tab.label}`) : '' }}
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'

    @Component
    export default class CruiseInfoMobileTabs extends Vue {
        @VModel() mobileTabs

        defaultTabs = [
            {icon: 'mdi-office-building', label: 'info'},
            {icon: 'mdi-bed', label: 'offers'},
            {icon: 'mdi-map-marker', label: 'map'},
        ]

        get tabs() {
            return this.defaultTabs
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
