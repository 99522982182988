<template>
    <v-card elevation="0">
        <div v-if="fullCard" class="mb-5">
            <v-card-title class="title font-weight-light align-baseline">
                {{ $t('available_offers') }}
                <span class="ml-4 text-body-2 primary--text cursor-pointer" @click="expanded = !expanded">
                    {{ $t('filters') }}
                    <v-icon color="primary" small>
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </span>
            </v-card-title>
            <v-expand-transition v-model="expanded">
                <div v-if="expanded">
                    <cruise-page-filters :product="product" @change="setFilters" />
                </div>
            </v-expand-transition>
        </div>
        <v-expansion-panels v-if="groupOffersBy !== 'none'" v-model="panels" multiple flat focusable accordion>
            <v-expansion-panel v-for="(offers, groupIndex) in groupedOffers" :key="`group-${groupIndex}`">
                <v-expansion-panel-header
                    class="text-nobreak font-weight-bold"
                    :class="$breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                    color="blue-grey lighten-5"
                >
                    <div>
                        {{ offers[0][groupOffersBy] }}
                    </div>
                    <div class="d-flex align-center justify-space-between font-weight-bold text-right pr-4">
                        <v-spacer />
                        <template v-if="minPriceOffer(offers).price && !hidePrice">
                            {{ $t('from') }} {{ minPriceOffer(offers).price | price }}
                        </template>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <cruise-offer-card
                        v-for="(offer, offerIndex) in offers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="fullCard"
                        :grouped-by="groupOffersBy"
                        :data-cy="`cruise-offer-card-${offerIndex}`"
                        @images="$emit('images', $event)"
                        @info="$emit('info', $event)"
                    >
                        <template v-slot:price>
                            <slot name="price" :offer="offer" :offer-key="offer.offerKey" />
                        </template>
                    </cruise-offer-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import {Component, Emit, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import {cruisesStore} from '@/utils/store-accessor'
    import SortSelect from '@/components/search/SortSelect'
    import GroupOffersSelect from '@/components/search/GroupOffersSelect'
    import CruiseOfferCard from '@/components/cruises/info/CruiseOfferCard'
    import CruisePageFilters from '@/components/search/filters/CruisePageFilters'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'

    @Component({
        components: {
            CruisePageFilters,
            CruiseOfferCard,
            SortSelect,
            GroupOffersSelect,
        },
    })
    export default class CruiseGroupedOffers extends mixins(CruisesStoreMixin) {
        @Prop({required: true}) product
        @Prop({default: false, type: Boolean}) fullCard
        @Prop({default: false, type: Boolean}) hidePrice

        panels = [0]
        expanded = false

        @Watch('groupOffersBy')
        onGroupOffersByChange() {
            this.panels = [0]
        }

        minPriceOffer(offers) {
            return offers.reduce((a, b) => (a.price.amount < b.price.amount ? a : b), offers[0])
        }

        @Emit()
        setFilters(filter) {
            return filter
        }

        get searchResponse() {
            return this.productStore.searchResponse
        }

        get groupedOffers() {
            if (!this.product?.offers) return []

            const sortFns = {
                priceAsc: (a, b) => a.price.amount - b.price.amount,
                priceDesc: (a, b) => b.price.amount - a.price.amount,
            }
            let offers = [...this.product.offers]
            offers.sort(sortFns[this.sortKey])
            return offers.reduce((groupedOffers, offer) => {
                let group = groupedOffers.find(group => group[0][this.groupOffersBy] === offer[this.groupOffersBy])
                if (!group) {
                    group = []
                    groupedOffers.push(group)
                }
                group.push(offer)
                return groupedOffers
            }, [])
        }

        get sortKey() {
            return cruisesStore.sortFnName
        }

        set sortKey(sortKey) {
            cruisesStore.SET_SORT(sortKey)
        }

        get productStore() {
            return cruisesStore
        }

        get groupOffersBy() {
            return this.productStore.groupOffersBy
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
            ]
        }
    }
</script>

<style scoped>
    >>> .v-expansion-panel-content__wrap {
        padding-left: 0;
        padding-right: 0;
    }
</style>
