<template>
    <search-summary-layout
        :search-disabled="searchDisabled"
        :search-request="searchRequest"
        :pick-up-location="pickUpLocation"
        :drop-off-location="dropOffLocation"
        form-name="cars-search-form"
        show-passengers
    >
        <template v-slot:title>
            {{ locationLabel }}
        </template>
    </search-summary-layout>
</template>

<script>
    import {Vue, Component, Watch, Prop} from 'nuxt-property-decorator'
    import {carsRentStore, carsRentRuntimeStore} from '@/utils/store-accessor'
    import SearchSummaryLayout from '~src/components/parts/searchSummaryLayout.src'
    import CarsSearchForm from '~/components/carsRent/search/forms/CarsSearchForm'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            SearchSummaryLayout,
            CarsSearchForm,
        },
    })
    export default class CarsSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest
        @Prop({default: false, type: Boolean}) showPassengers

        searchForm = false

        @Watch('isSearchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }

        get isSearchActive() {
            return carsRentRuntimeStore.searchActive
        }

        get locationLabel() {
            return `${carsRentRuntimeStore.pickUpPoint.name}, ${carsRentRuntimeStore.pickUpPoint.parentName} -
            ${carsRentRuntimeStore.dropOffPoint.name}, ${carsRentRuntimeStore.dropOffPoint.parentName}`
        }

        get pickUpLocation() {
            return carsRentStore.pickUpLocation
        }

        get dropOffLocation() {
            return carsRentStore.dropOffLocation
        }
    }
</script>
