<template>
    <div>
        <v-skeleton-loader v-if="loading && !offer" type="list-item@3" />
        <div v-else-if="!loading && !offer" class="text-center title grey--text text--lighten-1 py-10">
            {{ $t('no_available_offers') }}
        </div>
        <template v-else>
            <overlay-progress :active="loading" />
            <tour-offer-entries-list
                selectable
                :offer="offer"
                :search-request="searchRequest"
                :citizenship-id="citizenshipId"
            />
            <tour-hotel-info-modal :search-request="searchRequest" />
            <tour-flight-info-modal />
            <tour-transfer-info-modal :search-request="searchRequest" />
            <tour-activities-info-modal :search-request="searchRequest" />
            <tour-extra-service-info-modal :search-request="searchRequest" />
            <tour-insurance-info-modal :search-request="searchRequest" />
            <tour-cruise-info-modal :search-request="searchRequest" />
            <tour-car-info-modal :search-request="searchRequest" />
            <hotel-room-info-modal />
            <hotel-meal-type-info-modal />
            <additional-fees-modal />
            <client-only>
                <v-dialog v-if="mapModal" v-model="mapModal" :max-width="500">
                    <map-location
                        :latitude="mapOffer.coordinates[0]"
                        :longitude="mapOffer.coordinates[1]"
                        height="500px"
                    />
                </v-dialog>
            </client-only>
        </template>
    </div>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {toursRuntimeStore} from '@/utils/store-accessor'
    import TourOfferEntriesList from '@/components/tours/search/offers/TourOfferEntriesList'
    import OverlayProgress from '@/components/OverlayProgress'
    import TourHotelInfoModal from '@/components/tours/modals/TourHotelInfoModal'
    import TourFlightInfoModal from '@/components/tours/modals/TourFlightInfoModal'
    import TourTransferInfoModal from '@/components/tours/modals/TourTransferInfoModal'
    import TourActivitiesInfoModal from '@/components/tours/modals/TourActivitiesInfoModal'
    import TourExtraServiceInfoModal from '@/components/tours/modals/TourExtraServiceInfoModal'
    import TourInsuranceInfoModal from '@/components/tours/modals/TourInsuranceInfoModal'
    import TourCruiseInfoModal from '@/components/tours/modals/TourCruiseInfoModal.vue'
    import TourCarInfoModal from '@/components/tours/modals/TourCarInfoModal'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal.vue'
    import AdditionalFeesModal from '~src/components/modals/AdditionalFeesModal'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal.vue'
    import MapLocation from '~src/components/info/mapLocation.src'
    import {EventBus, SHOW_ON_MAP_EVENT} from '@/utils/event-bus'

    @Component({
        components: {
            MapLocation,
            HotelMealTypeInfoModal,
            AdditionalFeesModal,
            HotelRoomInfoModal,
            TourCruiseInfoModal,
            OverlayProgress,
            TourOfferEntriesList,
            TourHotelInfoModal,
            TourFlightInfoModal,
            TourTransferInfoModal,
            TourActivitiesInfoModal,
            TourExtraServiceInfoModal,
            TourInsuranceInfoModal,
            TourCarInfoModal,
        },
    })
    export default class PackageTourEntries extends Vue {
        @VModel() loading
        @Prop({required: true}) searchRequest
        @Prop() citizenshipId

        mapModal = false
        mapOffer = null

        @Emit()
        setTour(offer) {
            return offer
        }

        async mounted() {
            this.setTour(null)
            toursRuntimeStore.SET_OFFERS([])
            try {
                this.loading = true
                const {
                    offers: [offer],
                } = await this.$api.searchPackageTours.get(this.searchRequest)

                //Workaround for select obligatory offers
                offer.entries.forEach(entry => {
                    if (entry.obligatory) {
                        entry.obligatory = false
                        entry.base = true
                    }
                })
                offer.price.amount = 0

                toursRuntimeStore.SET_OFFERS([offer])
                this.setTour(offer)
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
            EventBus.$on(SHOW_ON_MAP_EVENT, this.showMap)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_ON_MAP_EVENT, this.showMap)
        }

        showMap(mapOffer) {
            this.mapOffer = mapOffer
            this.mapModal = true
        }

        get offer() {
            return toursRuntimeStore.offers[0]
        }
    }
</script>
