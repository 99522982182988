<script>
    import {Component, Prop} from 'vue-property-decorator'
    import {extraServicesRuntimeStore} from '~/store'
    import Breadcrumbs from '~src/components/Breadcrumbs'

    @Component
    export default class ExtraServiceBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest
        @Prop({default: () => {}}) product

        get items() {
            const getSearchPageLabel = () =>
                extraServicesRuntimeStore.city.name
                    ? `${extraServicesRuntimeStore.city.name}, ${extraServicesRuntimeStore.city.countryName}`
                    : null
            const addSearchPageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: extraServicesRuntimeStore.searchPageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }
            const addInfoPageLink = items => {
                items.push({
                    text: this.product.info.name,
                    to: {name: 'extraService', query: {name: this.product.info.name}},
                    nuxt: true,
                    exact: true,
                })
            }

            const items = [this.homeItem]
            //TODO Add all routes
            switch (this.$route.name) {
                case 'extraServices':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'extraService':
                    addSearchPageLink(items)
                    items.push({
                        text: decodeURIComponent(this.$route.query.name),
                        disabled: true,
                    })
                    break
                case 'extraServiceBooking':
                    addSearchPageLink(items)
                    addInfoPageLink(items)
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'extraServiceConfirmation':
                    addSearchPageLink(items)
                    addInfoPageLink(items)
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
