<template>
    <v-layout :fill-height="!loaded" column>
        <insurance-booking-page-layout
            v-if="loaded"
            :search-request="searchRequest"
            :offer="offer"
            :search-request-country-id="searchRequestCountryId"
        >
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
                <insurance-breadcrumbs :search-request="searchRequest" />
            </template>
            <template
                v-slot:tourists="{tourists, index, personSelected, bookingKey, touristToken, isSavingWithoutDetails}"
            >
                <tourist-form
                    :key="index"
                    v-model="tourists[index]"
                    :index="index"
                    :label="touristToken"
                    :disabled="!!bookingKey"
                    :corporate-policy-violation="offer ? offer.corporatePolicyViolation : undefined"
                    :hide-index="tourists.length === 1"
                    :selected-tourists="tourists"
                    :start-date="startDate"
                    :data-cy="`tourist-${index}`"
                    :disable-required-rule="isSavingWithoutDetails"
                    @person-selected="personSelected($event)"
                >
                    <template v-slot="{tourist, requiredRule, fieldLabel}">
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="tourist.passportNumber"
                                :label="fieldLabel('insurance.passport_number', 'PASSPORT_NUMBER')"
                                :rules="[requiredRule('insurance.passport_number', 'PASSPORT_NUMBER')]"
                                :disabled="!!bookingKey"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="tourist.address"
                                :label="`${$t('address')}*`"
                                :rules="[requiredRule('address')]"
                                :disabled="!!bookingKey"
                                data-cy="tourist-field-address"
                            />
                        </v-col>
                    </template>
                </tourist-form>
            </template>
            <template v-slot:forms="{bookingKey}">
                <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" :outlined="false" />
            </template>
            <template v-slot:sidebar="{bookingKey, prepareBookResponse}">
                <insurance-order-summary
                    :search-request="searchRequest"
                    :product="product"
                    :offer="offer"
                    :prepare-book-response="prepareBookResponse"
                    :prebooked="!!bookingKey"
                >
                    <template v-slot:summary>
                        <v-card flat tile class="mb-2 pa-0">
                            <v-card-text class="pa-0">
                                <search-summary-content
                                    :search-request="searchRequest"
                                    tourists-label="guest"
                                    show-dates
                                    show-days-count
                                    show-tourists
                                />
                            </v-card-text>
                        </v-card>
                    </template>
                </insurance-order-summary>
                <insurance-info-modal />
            </template>
        </insurance-booking-page-layout>
        <conditions-modal>
            <template v-slot:info>
                <insurance-conditions-modal-info />
            </template>
        </conditions-modal>
    </v-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import InsurancePageMixin from '@/components/insurance/mixins/InsurancePageMixin'
    import InsuranceBookingPageLayout from '@/components/insurance/booking/InsuranceBookingPageLayout'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import InsuranceBreadcrumbs from '@/components/insurance/InsuranceBreadcrumbs'
    import {insuranceRuntimeStore, insuranceStore, runtimeStore} from '@/utils/store-accessor'
    import {bookingFields} from '@/utils/insurance/insurance-blank-states'
    import {UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import InsuranceOrderSummary from '@/components/insurance/booking/InsuranceOrderSummary'
    import InsuranceConditionsModalInfo from '@/components/insurance/snippets/InsuranceConditionsModalInfo.vue'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import InsuranceStoreMixin from '@/mixins/InsuranceStoreMixin'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import InsuranceInfoModal from '@/components/insurance/info/InsuranceInfoModal'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'

    @Component({
        components: {
            InsuranceInfoModal,
            InsuranceBookingPageLayout,
            ExpiredOffersSnackbar,
            InsuranceBreadcrumbs,
            BookingCommentsForm,
            InsuranceOrderSummary,
            ConditionsModal,
            InsuranceConditionsModalInfo,
            TouristForm,
            SearchSummaryContent,
        },
    })
    export default class InsuranceBookingPage extends mixins(InsurancePageMixin, InsuranceStoreMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await Promise.all([
                this.loadRuntimeData(),
                //TODO Not work in API
                //runtimeStore.loadTouristBookingFields({offerKey: this.$route.query.offerKey}),
            ])
            runtimeStore.SET_CUSTOM_TOURIST_BOOKING_FIELDS(bookingFields()) // TODO: temporary solution

            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !insuranceStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(insuranceRuntimeStore.searchPageLink(this.searchRequest))
        }

        get searchRequestCountryId() {
            return insuranceRuntimeStore.location.id
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get basketItem() {
            return insuranceStore.basketItem(this.$route.query.offerKey)
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }

        get startDate() {
            return this.searchRequest.startDate
        }
    }
</script>
