<template>
    <v-icon right @click="showPriceDetails">mdi-information-outline</v-icon>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {EventBus, SHOW_FLIGHT_PRICE_DETAIL} from '@/utils/event-bus'

    @Component
    export default class PriceDetailsBtn extends Vue {
        @Prop({required: true}) offer

        showPriceDetails() {
            EventBus.$emit(SHOW_FLIGHT_PRICE_DETAIL, this.offer)
        }
    }
</script>
