<template>
    <v-row class="d-flex flex-wrap justify-space-between fareWrap">
        <v-col v-if="isLoading" cols="12">
            <flight-loader :is-loading="isLoading" />
        </v-col>

        <v-carousel hide-delimiters light height="max-content">
            <v-carousel-item v-for="(chunck, index) in chunked" :key="`${index}-chunk`">
                <v-row class="px-5 px-md-10">
                    <v-col v-for="offer in chunck" :key="offer.offerKey" cols="12" md="6">
                        <fare-offer :offer="offer" />
                    </v-col>
                </v-row>
            </v-carousel-item>
        </v-carousel>
    </v-row>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FareOffer from '~/components/flights/search/offers/FareOffer'
    import {chunkArray} from '~/utils/helpers'
    import FlightLoader from '@/components/flights/snippets/FlightLoader'

    @Component({
        components: {
            FareOffer,
            FlightLoader,
        },
    })
    export default class FlightFareOptions extends Vue {
        @Prop() offerKey

        options = []
        chunked = []
        isLoading = false

        created() {
            this.getFareOptions()
        }

        async getFareOptions() {
            this.isLoading = true
            const rq = {
                offerKey: this.offerKey,
            }
            const options = await this.$api.flightFareOptions.get(rq)
            this.options = this.formatOptions(options)
            this.isLoading = false
        }

        formatOptions(options) {
            const offers = options.offers
            if (offers.length) {
                const result = offers.map(offer => {
                    const s = {}
                    offer.itinerary.forEach(({segments}) => {
                        segments.forEach(
                            ({
                                brandedFareName,
                                departureAirportDescription,
                                arrivalAirportDescription,
                                fareDescriptions,
                                departureDate,
                                arrivalDate,
                                categoryClass,
                            }) => {
                                if (!s[brandedFareName]) {
                                    s[brandedFareName] = {
                                        name: brandedFareName,
                                        description: fareDescriptions,
                                        routes: [],
                                        classes: [],
                                    }
                                    s[brandedFareName].routes.push({
                                        departureAirportDescription,
                                        arrivalAirportDescription,
                                        departureDate,
                                        arrivalDate,
                                    })
                                    if (categoryClass && categoryClass.classType) {
                                        s[brandedFareName].classes.push(categoryClass.classType)
                                    }
                                } else {
                                    s[brandedFareName].routes.push({
                                        departureAirportDescription,
                                        arrivalAirportDescription,
                                        departureDate,
                                        arrivalDate,
                                    })
                                }
                            }
                        )
                    })
                    offer.tariff = s
                    return offer
                })
                this.chunked = chunkArray(result, !this.$breakpoint.smAndDown ? 2 : 1)
                return result
            }
            return []
        }
    }
</script>

<style scoped>
    >>> .v-window__prev,
    >>> .v-window__next {
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }
</style>
