<template>
    <v-autocomplete
        v-model="emails"
        chips
        small-chips
        deletable-chips
        multiple
        outlined
        :label="label"
        hide-no-data
        hide-selected
        :search-input.sync="searchInput"
        no-filter
        :items="items"
        append-icon=""
        :rules="rules"
        @update:search-input="updateSearchInput"
        @keydown="keydown"
        @input="input"
    />
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class EmailField extends mixins(FormValidationRulesMixin) {
        @VModel() emails
        @Prop() label
        @Prop({default: false, type: Boolean}) required

        searchInput = ''
        items = []

        @Watch('emails')
        onEmailsChange(emails) {
            this.items = emails
        }

        mounted() {
            this.items = this.emails
        }

        updateSearchInput(val) {
            if (this.emailRule()(val) === true && !this.emails.includes(val)) {
                this.items = [val, ...this.emails]
            } else {
                this.items = this.emails
            }
        }

        keydown(event) {
            if (
                ['Enter', 'NumpadEnter', 'Space', 'Comma'].includes(event.code) &&
                this.emailRule()(this.searchInput) === true &&
                !this.emails.includes(this.searchInput)
            ) {
                this.emails.push(this.searchInput)
                this.searchInput = ''
            }
        }

        input() {
            this.searchInput = ''
        }

        get rules() {
            return this.required ? [v => !!v.length || `${this.label} ${this.$t('validation.required')}`] : []
        }
    }
</script>
