<template>
    <v-card>
        {{ offer.offerKey }}
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class FlightCardMobile extends Vue {
        @Prop({required: true})
        offer
    }
</script>
