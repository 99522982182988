<script>
    import {Component, Vue, Prop, Watch, VModel} from 'nuxt-property-decorator'
    import {flightsRuntimeStore} from '~/store'

    @Component
    export default class FlightSearchFormMixin extends Vue {
        @VModel({default: () => ({from: null, to: null}), type: Object}) locations
        @Prop() initSearch
        @Prop({default: 0}) formIndex

        @Watch('initSearch')
        onSearchInit() {
            if (this.initSearch) {
                const routes = this.getRoutes()
                this.$emit('form-data', routes)
            }
        }

        mounted() {
            this.initDestinations()
            this.initDates()
        }

        initDestinations() {
            if (flightsRuntimeStore.routes.length) {
                this.locations.from = flightsRuntimeStore.routes[this.formIndex].departure
                this.$refs.firstLocation.selectCity(this.locations.from)
                this.locations.to = flightsRuntimeStore.routes[this.formIndex].arrival
                this.$refs.secondLocation.selectCity(this.locations.to)
            }
        }

        swapLocations() {
            const first = {...this.$refs.firstLocation.city}
            const second = {...this.$refs.secondLocation.city}
            this.locations.from = Object.keys(second).length ? second : null
            this.locations.to = Object.keys(first).length ? first : null
        }
    }
</script>
