<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class OrderEmailsMixin extends Vue {
        emailFor = []

        async getOrderEmails(orders) {
            const personIds = [],
                privateClientIds = [],
                companyIds = [],
                emails = []
            orders.forEach(order => {
                if (this.emailFor.includes('clients')) {
                    switch (order.clientType) {
                        case 'TA':
                            if (order.agent?.email) {
                                emails.push(order.agent.email)
                            } else {
                                companyIds.push(order.clientId)
                            }
                            break
                        case 'DIRECT_SALES':
                            privateClientIds.push(order.orderClientInfo.clientId)
                            break
                        default:
                            companyIds.push(order.clientId)
                    }
                }
                if (this.emailFor.includes('suppliers') || this.emailFor.includes('travelers')) {
                    order.services.forEach(service => {
                        if (this.emailFor.includes('suppliers')) {
                            companyIds.push(service.supplierId)
                        }
                        if (this.emailFor.includes('travelers')) {
                            service.travelers.forEach(({email}) => {
                                if (email) {
                                    emails.push(email)
                                }
                            })
                        }
                    })
                }
            })
            const personPromises = [...new Set(personIds)].map(async id => {
                try {
                    const {
                        users: [user],
                    } = await this.$api.persons.get({id})
                    if (user?.email) {
                        emails.push(user.email)
                    }
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            })
            const privateClientPromises = [...new Set(privateClientIds)].map(async id => {
                try {
                    const {
                        persons: [person],
                    } = await this.$api.privateClients.get({id})
                    if (person?.email) {
                        emails.push(person.email)
                    }
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            })
            const companyPromises = [...new Set(companyIds)].map(async id => {
                try {
                    const {
                        object: [company],
                    } = await this.$api.companies.get({id})
                    if (company?.emails) {
                        Array.prototype.push.apply(emails, company.emails)
                    }
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            })
            await Promise.all([...personPromises, ...privateClientPromises, ...companyPromises])
            return [...new Set(emails)]
        }
    }
</script>
