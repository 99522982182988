<script>
    import {Component, Prop} from 'vue-property-decorator'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'

    @Component
    export default class HotelCommentsForm extends BookingCommentsForm {
        @Prop() index

        get comment() {
            return this.productStore.prepareBookRequest[this.index]
                ? this.productStore.prepareBookRequest[this.index].comments
                : null
        }

        set comment(val) {
            this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'comments', val, index: this.index})
        }
    }
</script>
