<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col :md="6" :cols="12">
                <span class="text-subtitle-1 font-weight-medium"
                    >{{ $t(`${salesTerm.type.toLowerCase()}_price`) }}
                </span>
                <v-row v-if="isShowMarkup" class="mt-1">
                    <v-col cols="12" md="3">
                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="markupPercent"
                                outlined
                                persistent-placeholder
                                type="number"
                                dense
                                :label="`${$t('markup_percent')}`"
                                hide-details
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="markup"
                                outlined
                                persistent-placeholder
                                type="number"
                                dense
                                :label="`${$t('markup_value')}`"
                                hide-details
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="d-flex align-center">
                            <v-text-field
                                v-model.number="exchangeRate"
                                outlined
                                :loading="exchangeRatesLoading"
                                persistent-placeholder
                                type="number"
                                dense
                                :label="`${$t('exchange_rate')}`"
                                hide-details
                                :rules="rulesExchangeRate"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div
                            v-ripple
                            style="height: 90%"
                            class="d-flex align-center font-weight-medium cursor-pointer px-3 rounded-sm"
                            @click="applyExchangeRate"
                        >
                            <span class="text-wrap full-width primary--text">
                                {{ $t('apply_actual_rate') }}
                            </span>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col v-if="selectCurrency" cols="12" md="3">
                        <v-select
                            v-model="salesTerm.price.currency"
                            :label="`${$t('currency')}`"
                            :items="currencies"
                            item-text="text"
                            item-value="value"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            v-model="priceAmount"
                            :prefix="salesTerm.price.currency"
                            outlined
                            class="font-weight-bold"
                            type="number"
                            dense
                            :label="`${$t('invoices.amount')}*`"
                            hide-details
                            :rules="[requiredRule('invoices.amount')]"
                        />
                    </v-col>
                    <v-col v-if="isShowCommission" cols="12" md="3">
                        <v-text-field
                            v-model="commissionPercent"
                            :prefix="'%'"
                            :label="`${$t('commission_percent')}`"
                            outlined
                            type="number"
                            dense
                            persistent-placeholder
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="isShowCommission" cols="12" md="3">
                        <v-text-field
                            v-model="commission"
                            :prefix="salesTerm.price.currency"
                            :label="`${$t('commission')}`"
                            outlined
                            type="number"
                            dense
                            hide-details
                            @input="validateForm"
                        />
                    </v-col>
                    <template v-if="isShowCommissionVat">
                        <v-col cols="12" md="3" />
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="commissionVatPercent"
                                :prefix="'%'"
                                :label="`${$t('commission_VAT_percent')}`"
                                outlined
                                type="number"
                                dense
                                @input="validateForm"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="commissionVat"
                                :prefix="salesTerm.price.currency"
                                :label="`${$t('commission_VAT')}`"
                                outlined
                                type="number"
                                dense
                                :rules="rulesVAT"
                                @input="validateForm"
                            />
                        </v-col>
                    </template>
                </v-row>
                <v-row v-if="salesTerm.type === 'SUPPLIER'" no-gutters>
                    <v-col cols="12">
                        <v-checkbox v-model="autoCalculateClientPrice" :label="$t('auto_calculate_client_price')" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="!disableCancelPenalty" :md="6" :cols="12">
                <span class="text-subtitle-1 font-weight-medium"> {{ $t('cancellation_penalties') }} </span>
                <v-radio-group v-model="cancelPenaltyEnabled" row class="pt-2">
                    <v-radio :label="$t('no_fees')" :value="false" />
                    <v-radio :label="$t('calculate_cancellation_fees')" :value="true" />
                </v-radio-group>
                <v-row
                    v-for="(penalty, i) in salesTerm.cancelPenalty"
                    :key="`${i}-${penalty.startDateTime}`"
                    align="center"
                >
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="penalty.amount"
                            :prefix="salesTerm.price.currency"
                            :label="`${$t('invoices.amount')}`"
                            outlined
                            class="mt-2"
                            type="number"
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" md="5">
                        <daterange-m
                            class="mt-2"
                            :label="$t('modify.subtitle_dates')"
                            outlined
                            dense
                            :start-date.sync="penalty.startDateTime"
                            :end-date.sync="penalty.endDateTime"
                        />
                    </v-col>
                    <v-col cols="12" :md="1" class="d-flex justify-md-end justify-center align-center mt-md-2">
                        <v-btn icon @click="removeCancelPenalty(i)">
                            <v-icon>mdi-close-box-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="cancelPenaltyEnabled" no-gutters class="mt-2">
                    <v-col :cols="12" :md="10" class="d-flex justify-center justify-md-end">
                        <v-btn :color="'primary'" class="mx-auto" icon @click="addCancelPenalty">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Emit, mixins, Prop, PropSync, VModel, Watch} from 'nuxt-property-decorator'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import {runtimeStore} from '@/utils/store-accessor'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {appInstance} from '@/utils/app-accessor'

    @Component({
        components: {DaterangeM},
    })
    export default class ModifyManualSalesTerm extends mixins(FormValidationRulesMixin) {
        @VModel() valid
        @PropSync('_salesTerm', {default: () => []}) salesTerm
        @Prop({default: false, type: Boolean}) selectCurrency
        @Prop({default: false, type: Boolean}) disableCancelPenalty
        @Prop({required: true}) clientType
        @Prop({required: true}) serviceExchangeRate
        @Prop({required: true}) supplierPrice
        @Prop({required: true}) salesSettings
        @Prop() pricingRules

        cancelPenaltyEnabled = false

        commissionPercentValue = null
        commissionVatPercentValue = null
        markupPercentValue = null
        markupValue = null
        exchangeRateValue = null

        exchangeRatesFromAPI = null
        exchangeRatesLoading = false

        autoCalculateClientPrice = true

        @Watch('cancelPenaltyEnabled')
        onCancelPenaltyEnabledChange() {
            if (this.cancelPenaltyEnabled) {
                if (!this.salesTerm.cancelPenalty.length) {
                    this.addCancelPenalty()
                }
            } else {
                this.salesTerm.cancelPenalty = []
            }
        }

        async mounted() {
            this.exchangeRateValue = this.serviceExchangeRate
            await this.initCalculatedValues()
        }

        async applyExchangeRate() {
            await this.loadExchangeRates()

            const supplierCurrency = this.supplierPrice?.currency
            const clientCurrency = this.salesTerm.price.currency
            this.exchangeRate = this.exchangeRatesFromAPI[supplierCurrency] / this.exchangeRatesFromAPI[clientCurrency]
        }

        async loadExchangeRates() {
            try {
                this.exchangeRatesLoading = true

                this.exchangeRatesFromAPI = await appInstance.$api.exchangeRates.get({
                    precision: 10,
                    currencyCode: this.$config.currency.available[0],
                })
            } catch (e) {
                /* empty */
            } finally {
                this.exchangeRatesLoading = false
            }
        }

        addCancelPenalty() {
            this.salesTerm.cancelPenalty.push({
                amount: 0,
                startDateTime: null,
                endDateTime: null,
            })
        }

        async initCalculatedValues() {
            if (this.pricingRules) {
                if (this.salesTerm.type === 'CLIENT' && this.salesSettings?.performSalesMode === 'CONTRACT_CURRENCY') {
                    this.salesTerm.price.currency = this.salesSettings?.currency
                    await this.loadExchangeRates()
                    const supplierCurrency = this.supplierPrice?.currency
                    const clientCurrency = this.salesTerm.price.currency
                    this.exchangeRateValue =
                        this.exchangeRatesFromAPI[supplierCurrency] / this.exchangeRatesFromAPI[clientCurrency]
                }

                const pricingRule = this.pricingRules.pricingRule

                if (this.pricingRules.commissionVatPricingRule) {
                    this.commissionVatPercentValue = this.pricingRules.commissionVatPricingRule.vatValue
                }

                if (pricingRule.commissionInCurrency === true && this.isShowCommission) {
                    this.salesTerm.price.commission.amount = pricingRule.commissionValue
                } else if (pricingRule.commissionInCurrency === false && this.isShowCommission) {
                    this.commissionPercentValue = pricingRule.commissionValue
                }

                if (pricingRule.markupValueType === 'PERCENTAGE' && this.isShowMarkup) {
                    this.markupPercentValue = pricingRule.markupValue
                } else if (pricingRule.markupValueType === 'ABSOLUTE' && this.isShowMarkup) {
                    this.markupValue = pricingRule.markupValue
                }
            } else {
                this.calculateCommissionPercent()
                this.calculateCommissionVatPercent()
                this.calculateMarkupPercent()
                this.calculateMarkup()
            }
        }

        calculateMarkupPercent() {
            if (this.supplierPrice?.amount) {
                this.markupPercentValue =
                    ((this.salesTerm.price.amount / this.exchangeRateValue - this.supplierPrice?.amount) /
                        this.supplierPrice?.amount) *
                    100
            }
        }

        calculateMarkup() {
            this.markupValue = this.salesTerm.price.amount / this.exchangeRateValue - this.supplierPrice?.amount
        }

        calculatePriceAmount() {
            if (this.markupPercentValue !== null) {
                const amount =
                    this.supplierPrice?.amount * ((100 + this.markupPercentValue) / 100) * this.exchangeRateValue

                this.salesTerm.price.amount =
                    this.salesSettings?.roundUpPrices && this.salesTerm.type === 'CLIENT' ? Math.ceil(amount) : amount
            }
        }

        calculateCommission() {
            if (this.commissionPercentValue !== null) {
                const amount = (this.salesTerm.price.amount * this.commissionPercentValue) / 100

                this.salesTerm.price.commission.amount =
                    this.salesSettings?.roundUpCommission && this.salesTerm.type === 'CLIENT'
                        ? Math.ceil(amount)
                        : amount
            }
        }

        calculateCommissionPercent() {
            if (this.salesTerm.price.amount) {
                this.commissionPercentValue =
                    (this.salesTerm.price.commission.amount / this.salesTerm.price.amount) * 100
            }
        }

        calculateCommissionVat() {
            if (this.isShowCommissionVat) {
                const amount = (this.salesTerm.price.commission.amount * this.commissionVatPercentValue) / 100

                this.salesTerm.price = {
                    ...this.salesTerm.price,
                    commissionVatAmount: this.salesSettings?.roundUpCommission ? Math.ceil(amount) : amount,
                }
            }
        }

        calculateCommissionVatPercent() {
            if (this.salesTerm.price.commission.amount) {
                this.commissionVatPercentValue =
                    (this.salesTerm.price.commissionVatAmount / this.salesTerm.price.commission.amount) * 100
            }
        }

        removeCancelPenalty(i) {
            this.salesTerm.cancelPenalty.splice(i, 1)
        }

        validateForm() {
            const form = this.$refs.form
            form.validate()
        }

        @Emit()
        recalculateClientPrice() {}

        get commissionVat() {
            return +this.salesTerm.price.commissionVatAmount?.toFixed(2)
        }

        set commissionVat(val) {
            this.salesTerm.price.commissionVatAmount = +val
            this.calculateCommissionVatPercent()
        }

        get commission() {
            return +this.salesTerm.price.commission.amount?.toFixed(2)
        }

        set commission(val) {
            this.salesTerm.price.commission.amount = +val
            this.calculateCommissionPercent()
            this.calculateCommissionVat()
        }

        get priceAmount() {
            return +this.salesTerm.price.amount?.toFixed(2)
        }

        set priceAmount(val) {
            this.salesTerm.price.amount = +val
            this.calculateMarkupPercent()
            this.calculateMarkup()

            //check percent
            this.calculateCommission()

            this.calculateCommissionVat()
            if (this.salesTerm.type === 'SUPPLIER' && this.autoCalculateClientPrice) {
                this.recalculateClientPrice()
            }
        }

        get commissionVatPercent() {
            return +this.commissionVatPercentValue?.toFixed(2)
        }

        set commissionVatPercent(val) {
            this.commissionVatPercentValue = +val
            this.calculateCommissionVat()
        }

        get exchangeRate() {
            return +this.exchangeRateValue?.toFixed(2)
        }

        set exchangeRate(val) {
            this.exchangeRateValue = +val
            this.calculatePriceAmount()
            this.calculateCommission()
            this.calculateCommissionVat()
        }

        get markupPercent() {
            return +this.markupPercentValue?.toFixed(2)
        }

        set markupPercent(val) {
            this.markupPercentValue = +val
            this.calculatePriceAmount()
            this.calculateMarkup()
            this.calculateCommission()
            this.calculateCommissionVat()
        }

        get markup() {
            return +this.markupValue?.toFixed(2)
        }

        set markup(val) {
            this.markupValue = +val
            const priceAmount = this.supplierPrice?.amount * this.exchangeRateValue + this.markupValue
            this.salesTerm.price.amount =
                this.salesSettings?.roundUpPrices && this.salesTerm.type === 'CLIENT'
                    ? Math.ceil(priceAmount)
                    : priceAmount
            this.calculateMarkupPercent()
            this.calculateCommission()
            this.calculateCommissionVat()
        }

        get commissionPercent() {
            return +this.commissionPercentValue?.toFixed(2)
        }

        set commissionPercent(val) {
            this.commissionPercentValue = +val
            this.calculateCommission()
            this.calculateCommissionVat()
        }

        get rulesVAT() {
            const valRule = v => {
                if (+v > 0 && this.salesTerm.price.commission.amount < 0) {
                    return this.$t('commission_VAT_validation.commission_zero')
                } else if (+v > this.salesTerm.price.commission.amount) {
                    return this.$t('commission_VAT_validation.more_than_commission')
                }

                return true
            }
            return [valRule]
        }

        get rulesExchangeRate() {
            const valRule = v => {
                if (+v <= 0) {
                    return this.$t('exchange_rate_validation.less_zero')
                }

                return true
            }
            return [valRule]
        }

        get isShowCommission() {
            if (this.salesTerm.type === 'CLIENT') {
                return this.clientType !== 'DIRECT_SALES' && this.clientType !== 'CORPORATE'
            }

            return true
        }

        get isShowMarkup() {
            return this.salesTerm.type === 'CLIENT'
        }

        get currencies() {
            return Object.values(this.$config.currency.available).filter(currency =>
                Object.keys(runtimeStore.exchangeRates).includes(currency)
            )
        }

        get isShowCommissionVat() {
            return this.isShowCommission && this.salesTerm.price.commissionVatAmount !== undefined
        }
    }
</script>
