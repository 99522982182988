<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class LoadDataStatusMixin extends Vue {
        loadDataError = false

        showLoadDataStatusMessage() {
            if (this.loadDataError) {
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            }
        }

        get noDataText() {
            return this.loadDataError
                ? `${this.$t('$vuetify.noDataText')}. ${this.$t('error_message.load_error')}`
                : this.$t('$vuetify.noDataText')
        }
    }
</script>
