<template>
    <v-list-item ref="infoWindow" three-line class="pl-0">
        <div v-if="infoLoading">
            {{ $t('loading') }}
            <v-progress-linear indeterminate color="primary" />
        </div>
        <div v-else class="align-self-start">
            <v-list-item-title :title="pointInfo.name" class="mb-1">
                <span class="text-wrap">{{ pointInfo.name }}</span>
            </v-list-item-title>
            <v-list-item-content class="mb-2">
                <div v-if="pointInfo.pointName" class="text-wrap">
                    <v-icon small>mdi-map-marker</v-icon>
                    {{ `${pointInfo.pointName}, ${pointInfo.startTimes.join(', ')}` }}
                </div>
            </v-list-item-content>
        </div>
    </v-list-item>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class ActivityMapInfoWindow extends Vue {
        @Prop() propData

        pointInfo = {}
        infoLoading = true

        async mounted() {
            this.pointInfo = this.propData
            this.infoLoading = false
        }
    }
</script>
