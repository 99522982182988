<template>
    <v-card>
        <v-card-text>
            <v-row dense>
                <v-col cols="6">
                    <v-select v-model="hours" :items="filteredHourItems" label="HH" hide-details />
                </v-col>
                <v-col cols="6">
                    <v-select v-model="minutes" :items="filteredMinuteItems" label="mm" hide-details />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Model, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class TimePicker extends Vue {
        @Model('change') value
        @Prop() min
        @Prop() max

        selections = 0

        get hourItems() {
            return [...Array(24).keys()].map(hour => (hour < 10 ? '0' + hour : hour.toString()))
        }

        get filteredHourItems() {
            if (this.min && this.max) {
                const minHour = this.min.split(':')[0]
                const maxHour = this.max.split(':')[0]
                return this.hourItems.filter(item => item >= minHour && item <= maxHour)
            }

            return this.hourItems
        }

        get minuteItems() {
            return [...Array(60).keys()].map(hour => (hour < 10 ? '0' + hour : hour.toString()))
        }

        get filteredMinuteItems() {
            if (this.min && this.max) {
                const minuteItems = this.minuteItems
                const minMinute = this.min.split(':')[1]
                const maxMinute = this.max.split(':')[1]

                if (this.hours === this.filteredHourItems[this.filteredHourItems.length - 1]) {
                    return minuteItems.filter(item => item <= maxMinute)
                }
                if (this.hours === this.filteredHourItems[0]) {
                    return minuteItems.filter(item => item >= minMinute)
                }

                return minuteItems
            }

            return this.minuteItems
        }

        get hours() {
            return this.value ? this.value.split(':')[0] : ''
        }

        set hours(val) {
            let minutes = this.minutes
            if (!minutes) {
                minutes = '00'
            }
            this.$emit('change', `${val}:${minutes}`)
            this.selections++
            if (this.selections === 2) {
                this.selections = 0
                this.$emit('update')
            }
        }

        get minutes() {
            return this.value ? this.value.split(':')[1] : ''
        }

        set minutes(val) {
            let hours = this.hours
            if (!hours) {
                hours = '00'
            }
            this.$emit('change', `${hours}:${val}`)
            this.selections++
            if (this.selections === 2) {
                this.selections = 0
                this.$emit('update')
            }
        }
    }
</script>
