<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'
    import {SHOW_NOTIFICATIONS, EventBus} from '@/utils/event-bus'

    @Component
    export default class FlightOrderInfoBaseMixin extends Vue {
        checkStatusActive = false

        async checkStatus() {
            this.checkStatusActive = true
            try {
                const rs = await this.$api.checkstatus.get({processId: this.service.processId.replace(/^.{6}/, '')})
                if (rs.warnings) {
                    EventBus.$emit(SHOW_NOTIFICATIONS, {notifications: rs.warnings})
                }
            } catch (e) {
                if (e.msg) {
                    EventBus.$emit(SHOW_NOTIFICATIONS, {notifications: [e.msg], color: 'error'})
                }
            } finally {
                await runtimeStore.refreshOrder()
                this.checkStatusActive = false
            }
        }

        get canRetrieve() {
            return !['CANCELED', 'REJECTED', 'CANCELED_WITHOUT_FEE'].includes(
                runtimeStore.orderServiceStatus(this.service.status)
            )
        }

        get isFlight() {
            return runtimeStore.isServiceFlight(this.service)
        }

        get isCharter() {
            return this.service.serviceType === 'OWNCHARTER'
        }
    }
</script>
