<template>
    <v-layout fill-height :class="{'d-flex flex-column': isMobile}">
        <mobile-booking-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <slot name="breadcrumbs" />
            <v-row v-if="loaded">
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <client-only>
                        <v-card v-if="showTouristSelector" outlined class="mb-4">
                            <v-card-text>
                                <tourist-selector @selected="setTourLead($event, true)" />
                            </v-card-text>
                        </v-card>
                        <v-form v-if="hasBookingFields" ref="mainForm">
                            <account-booking-info
                                v-if="isShowAccountBookingInfo"
                                :disabled="!!bookingKey || !!basketKey"
                            />
                            <template v-if="memberLevel !== 'AUTH' || person">
                                <div v-if="formTitle" class="text-subtitle-2 accent--text mb-4">
                                    {{ formTitle }}
                                </div>
                                <div style="position: relative">
                                    <slot
                                        name="touristsCard"
                                        :tourists="tourists"
                                        :bookingKey="bookingKey"
                                        :basketKey="basketKey"
                                        :pageLock="pageLock"
                                        :extraServices="extraServices"
                                        :isSavingWithoutDetails="isSavingWithoutDetails"
                                    >
                                        <v-card outlined class="mb-5">
                                            <v-card-title class="text-capitalize">
                                                <v-icon left color="primary">mdi-account</v-icon>
                                                {{ $t(`${touristToken}s`) }}
                                            </v-card-title>
                                            <v-divider />
                                            <v-card-text>
                                                <div
                                                    v-for="(tourist, index) in tourists"
                                                    :key="index"
                                                    :class="{'mb-8': index !== tourists.length - 1}"
                                                >
                                                    <slot
                                                        name="tourists"
                                                        :disabled="!!bookingKey || !!basketKey"
                                                        :index="index"
                                                        :tourists="tourists"
                                                        :personSelected="personSelected"
                                                        :isSavingWithoutDetails="isSavingWithoutDetails"
                                                    >
                                                        <tourist-form
                                                            :key="index"
                                                            v-model="tourists[index]"
                                                            :index="index"
                                                            :label="touristToken"
                                                            :disabled="!!bookingKey || !!basketKey"
                                                            :corporate-policy-violation="
                                                                offer ? offer.corporatePolicyViolation : undefined
                                                            "
                                                            :hide-index="tourists.length === 1"
                                                            :selected-tourists="tourists"
                                                            :start-date="startDate"
                                                            :data-cy="`tourist-${index}`"
                                                            :disable-required-rule="isSavingWithoutDetails"
                                                            class="mb-4"
                                                            @person-selected="personSelected($event)"
                                                        />
                                                        <tourist-passport-form
                                                            v-if="touristPassportForm"
                                                            :key="`ptf_${index}`"
                                                            :index="index"
                                                            :disabled="!!bookingKey || !!basketKey"
                                                            :corporate-policy-violation="
                                                                offer ? offer.corporatePolicyViolation : undefined
                                                            "
                                                            :disable-required-rule="isSavingWithoutDetails"
                                                            expanded-all
                                                        />
                                                    </slot>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </slot>
                                    <slot
                                        name="forms"
                                        :bookingKey="bookingKey"
                                        :basketKey="basketKey"
                                        :extraServices="extraServices"
                                    />
                                    <extra-services-form
                                        v-if="
                                            bookingAdditionalOptions &&
                                            bookingAdditionalOptions.extraServices &&
                                            bookingAdditionalOptions.extraServices.length
                                        "
                                        v-model="extraServices"
                                        :additional-options="bookingAdditionalOptions"
                                        :disabled="!!bookingKey || !!basketKey"
                                        @update="
                                            bookingKey = null
                                            basketKey = null
                                        "
                                    />
                                    <corporate-violation-reason
                                        v-if="corporatePolicyViolated && corporatePolicyType"
                                        v-model="corporatePolicyViolationCodeId"
                                        :type="corporatePolicyType"
                                        :policies="violatedPolicies"
                                        :disabled="!!bookingKey || !!basketKey"
                                        class="mb-2"
                                    />
                                    <add-on-booking-forms
                                        :selected-add-ons="selectedAddOns"
                                        :disabled="isLoading || pageLock || !!bookingKey || !!basketKey"
                                    />
                                    <add-ons
                                        v-if="showAddOns"
                                        :offer-key="offerKeys ? offerKeys[0] : $route.query.offerKey"
                                        :initial-tourist-booking-fields="touristBookingFields"
                                        class="pb-5"
                                        :disabled="
                                            isLoading || pageLock || !!bookingKey || !!basketKey || !resolveLoyalty
                                        "
                                        :product-type="productType"
                                        :offer="offer"
                                        @select="selectAddOn"
                                    />
                                    <loyalty-points
                                        v-if="isB2C"
                                        ref="loyaltyPoints"
                                        :is-loading="isLoading"
                                        :disabled="isLoading || pageLock || !!bookingKey || !!basketKey"
                                        :resolve-loyalty.sync="resolveLoyalty"
                                    />
                                    <slot name="orderCommentsForm" :bookingKey="bookingKey" :basketKey="basketKey" />
                                    <v-btn
                                        v-if="(bookingKey || basketKey) && !pageLock && !isSavingWithoutDetails"
                                        absolute
                                        dark
                                        fab
                                        bottom
                                        right
                                        small
                                        color="accent"
                                        class="mb-8"
                                        @click="resetBookResponse"
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                                <template v-if="!isSavingWithoutDetails">
                                    <payment-options
                                        v-if="(bookingKey || basketKey) && canPay"
                                        v-model="paymentMethodId"
                                        :disabled="isLoading || pageLock"
                                        :_number-of-payments.sync="numberOfPayments"
                                        outlined
                                        class="mb-5"
                                    />
                                    <div id="dropin-container" />
                                    <booking-agreement-form
                                        v-if="bookingKey || basketKey"
                                        v-model="isAgreementsConfirmed"
                                        :country-id="searchRequestCountryId"
                                        :due-to-confirm-date.sync="dueToConfirmDate"
                                        :due-to-confirm-date-active.sync="dueToConfirmDateActive"
                                        :start-date="startDate"
                                        :selected-add-ons="selectedAddOns"
                                        :room-offers="roomOffers"
                                    />
                                </template>
                                <v-row v-if="(!bookingKey && !basketKey) || isSavingWithoutDetails">
                                    <v-col :cols="canSaveWithoutDetails ? 6 : 12">
                                        <v-btn
                                            :loading="isLoading && !isSavingWithoutDetails"
                                            :disabled="isLoading || pageLock"
                                            color="primary"
                                            block
                                            depressed
                                            class="main-booking-button"
                                            @click="prepareBook"
                                        >
                                            {{ $t('next_step') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="canSaveWithoutDetails" cols="6">
                                        <v-btn
                                            :disabled="isLoading || pageLock"
                                            :loading="isLoading && isSavingWithoutDetails"
                                            :text="isSaveMethodButtonTextStyle"
                                            color="primary"
                                            block
                                            class="save-method-book-button"
                                            @click="saveWithoutDetails"
                                        >
                                            <v-icon>mdi-basket-fill</v-icon>
                                            {{ saveMethod.name }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="(bookingKey || basketKey) && !isSavingWithoutDetails" dense>
                                    <v-col v-if="canPay" :cols="saveMethod && !$breakpoint.smAndDown ? 6 : 12">
                                        <v-btn
                                            :loading="isLoading"
                                            :disabled="
                                                isLoading || !paymentMethodId || pageLock || !isAgreementsConfirmed
                                            "
                                            block
                                            depressed
                                            color="primary"
                                            class="main-booking-button"
                                            @click="book"
                                        >
                                            {{ $t('book') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="saveMethod" cols="12" md="6">
                                        <v-btn
                                            :disabled="
                                                isLoading ||
                                                pageLock ||
                                                !isAgreementsConfirmed ||
                                                dueToConfirmDateActive
                                            "
                                            :text="isSaveMethodButtonTextStyle"
                                            color="primary"
                                            block
                                            class="save-method-book-button"
                                            @click="save"
                                        >
                                            <v-icon>mdi-basket-fill</v-icon> {{ saveMethod.name }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-form>
                    </client-only>
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <slot
                        name="sidebar"
                        :booking-key="bookingKey"
                        :basket-key="basketKey"
                        :selected-add-ons="selectedAddOns"
                        :extra-services="extraServices"
                        :tourists="tourists"
                        :prepare-book-response="prepareBookResponse"
                    >
                        <order-summary-layout
                            :total-price="totalPrice"
                            :product-store="productStore"
                            :extra-prices="extraPrices"
                            :room-offers="roomOffers"
                            :offer="offer"
                        >
                            <slot
                                name="orderSummary"
                                :booking-key="bookingKey"
                                :basket-key="basketKey"
                                :selected-add-ons="selectedAddOns"
                                :extra-services="extraServices"
                                :tourists="tourists"
                                :selected-meal-types="selectedMealTypes"
                            />
                            <template v-slot:violatedPolicies>
                                <slot
                                    name="violatedPolicies"
                                    :corporate-policy-violated="corporatePolicyViolated"
                                    :violated-policies="violatedPolicies"
                                />
                            </template>
                        </order-summary-layout>
                    </slot>
                </v-col>
            </v-row>
            <v-layout v-else fill-height class="align-center justify-center">
                <v-progress-circular indeterminate size="64" color="primary" />
            </v-layout>
        </v-container>
        <payment-redirect-form ref="paymentRedirectForm" />
        <prepare-book-messages-modal
            v-if="isPrepareBookResponseExist"
            ref="prepareBookMessagesModal"
            :prepare-book-response="prepareBookResponse"
            :offers-data="offersData"
            @approve="isSavingWithoutDetails ? bookWithoutDetails() : paymentStepInit()"
            @cancel="cancelFlow"
        />
        <slot />
    </v-layout>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import MobileBookingPageTabs from '@/components/snippets/MobileBookingPageTabs'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import AccountBookingInfo from '~src/components/booking/accountBookingInfo.src'
    import PaymentOptions from '@/components/booking/PaymentOptions'
    import BookingAgreementForm from '@/components/booking/forms/BookingAgreementForm'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm'
    import LoyaltyPoints from '@/components/booking/LoyaltyPoints'
    import '@adyen/adyen-web/dist/adyen.css'
    import AddOns from '@/components/booking/addOns/AddOns.vue'
    import AddOnBookingForms from '@/components/booking/addOns/AddOnBookingForms.vue'
    import PrepareBookMessagesModal from '~src/components/booking/modals/PrepareBookMessagesModal.vue'
    import BookingPageMixin from '@/mixins/BookingPageMixin.vue'
    import TouristPassportForm from '~src/components/booking/forms/touristPassportForm.src'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout.vue'

    @Component({
        components: {
            OrderSummaryLayout,
            TouristPassportForm,
            PrepareBookMessagesModal,
            AddOnBookingForms,
            AddOns,
            ExtraServicesForm,
            PaymentRedirectForm,
            BookingAgreementForm,
            MobileBookingPageTabs,
            AccountBookingInfo,
            TouristForm,
            PaymentOptions,
            CorporateViolationReason: () => import('~/components/booking/forms/fields/CorporateViolationReason'),
            TouristSelector: () => import('~/components/parts/Booking/TouristSelector'),
            LoyaltyPoints,
        },
    })
    export default class BookingPageBase extends BookingPageMixin {
        @Prop({required: true}) searchRequest
        @Prop({required: true}) offer
        @Prop({required: false}) searchRequestCountryId
        @Prop() offerKeys
        @Prop({default: false, type: Boolean}) touristPassportForm
        @Prop() roomOffers

        async mounted() {
            await this.load()
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get prebooked() {
            return !!this.bookingKey || !!this.basketKey
        }

        get selectedMealTypes() {
            return null
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    ::v-deep .adyen-checkout__payment-methods-list {
        padding-left: 0 !important;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .save-method-book-button {
            padding: 0 !important;
            font-size: 0.65rem;
        }
    }
</style>
