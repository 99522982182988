<template>
    <search-layout
        v-if="loaded"
        :map-expanded="mapExpanded"
        :offers-count="filteredOffers.length"
        :search-active="searchActive"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <car-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.products.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <component
                :is="component"
                v-for="(product, index) in offers"
                :key="product.info.name"
                :product="product"
                :data-cy="`car-offer-${index}`"
                class="my-2"
            />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <car-conditions-modal-info />
                </template>
            </conditions-modal>
            <car-info-modal />
        </template>
        <template v-slot:left-sidebar>
            <cars-search-summary :search-request="searchRequest" show-passengers />
            <sort-select v-model="sortKey" :items="sortItems" />
            <cars-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <cars-map :_expanded.sync="mapExpanded" :offers="mapPoints" :city="city" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import CarBreadcrumbs from '@/components/carsRent/CarBreadcrumbs'
    import CarOfferCard from '@/components/carsRent/search/offers/CarOfferCard'
    import CarOfferCardMobile from '@/components/carsRent/search/offers/CarOfferCardMobile'
    import CarsFilters from '@/components/carsRent/search/CarsFilters'
    import CarsSearchSummary from '@/components/carsRent/search/CarsSearchSummary'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {carsRentRuntimeStore, carsRentStore, persistentStore} from '~/store'
    import {searchRequest} from '@/utils/cars/cars-rent-blank-state'
    import CarsSearchPageMixin from '@/components/carsRent/mixins/CarsSearchPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {isEqual} from '@/utils/helpers'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import CarsStoreMixin from '@/mixins/CarsStoreMixin'
    import SortSelect from '@/components/search/SortSelect'
    import CarsMap from '@/components/carsRent/search/CarsMap'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import CarConditionsModalInfo from '@/components/carsRent/snippets/CarConditionsModalInfo'
    import CarInfoModal from '@/components/carsRent/CarInfoModal'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import {postMessageLoad} from '@/utils/worker-helpers'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            CarOfferCard,
            CarOfferCardMobile,
            Pagination,
            MobileSearchPageTabs,
            CarBreadcrumbs,
            CarsFilters,
            CarsSearchSummary,
            CarsMap,
            ConditionsModal,
            CarConditionsModalInfo,
            CarInfoModal,
            SearchLayout: () => import('~src/components/parts/searchLayout.src'),
        },
        layout: 'blank',
    })
    export default class CarsSearchPage extends mixins(
        SearchPageBase,
        CarsSearchPageMixin,
        CarsStoreMixin,
        SearchPageAuthMixin
    ) {
        mapExpanded = false
        loaded = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.search)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.search)
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(carsRentStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !carsRentStore.hasOffers
            ) {
                await this.search()
            } else if (carsRentRuntimeStore.offers.length === 0) {
                const offers = await this.$localForage.getItem('carsRent')
                postMessageLoad('carsRent', this.productStore, offers)
            }
        }

        async search() {
            await carsRentRuntimeStore.search(this.searchRequest)
        }

        changeFilter(data) {
            carsRentStore.SET_FILTER(data)
            carsRentRuntimeStore.filter()
        }

        resetFilters() {
            carsRentStore.RESET_FILTERS()
            carsRentRuntimeStore.filter()
        }

        isOffersExpired() {
            return carsRentStore.isOffersExpired()
        }

        stopSearch() {
            carsRentRuntimeStore.stopSearch()
        }

        get searchRequest() {
            const rq = Object.assign(searchRequest(), this.$route.query)
            rq.convertToCurrency = persistentStore.currency
            return rq
        }

        get mapPoints() {
            return this.filteredOffers.reduce((points, product) => {
                product.offer.pickUpPoints.forEach(point => {
                    if (!points.find(({locationCode}) => locationCode === point.locationCode)) {
                        const newPoint = {...point}
                        newPoint.offerKey = product.offer.offerKey
                        points.push(newPoint)
                    }
                })
                return points
            }, [])
        }

        get city() {
            return carsRentRuntimeStore.pickUpPoint
        }

        get sortKey() {
            return carsRentStore.sortFnName
        }

        set sortKey(sortKey) {
            carsRentStore.SET_SORT(sortKey)
            carsRentRuntimeStore.sort(this.filteredOffers)
        }

        get filteredOffers() {
            return carsRentRuntimeStore.offers
        }

        get filterValues() {
            return carsRentStore.filters
        }

        get searchActive() {
            return carsRentRuntimeStore.searchActive
        }

        get searchResponse() {
            return carsRentStore.searchResponse
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDesc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.name'),
                    value: 'name',
                },
                {
                    text: this.$t('availability'),
                    value: 'availability',
                },
            ]
        }

        get currency() {
            return carsRentStore.searchRequest.convertToCurrency
        }

        get component() {
            return !this.$breakpoint.smAndDown ? 'car-offer-card' : 'car-offer-card-mobile'
        }
    }
</script>
