<template>
    <div class="mb-5">
        <strong class="d-block">{{ offer.info.service }} ({{ offer.info.tariff }})</strong>
        <strong class="d-block">{{ $t('date') }}: {{ offer.startDate | dateFormat }}</strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_EXTRA_SERVICE_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class ExtraServiceConditionsModalInfo extends Vue {
        offer = {info: {}}

        mounted() {
            EventBus.$on(SHOW_EXTRA_SERVICE_CONDITIONS_INFO, this.showInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_EXTRA_SERVICE_CONDITIONS_INFO, this.showInfo)
        }

        showInfo(offer) {
            this.offer = offer
        }
    }
</script>
