<template>
    <div class="text-subtitle-2 font-weight-regular full-width">
        <div class="d-flex">
            <v-icon small class="mr-1">{{ serviceTypeIcon }}</v-icon>
            <span class="font-weight-medium">
                {{ selectedGroupDetails.serviceName }}
            </span>
        </div>
        <span class="mb-4 text--secondary">
            {{ `${formattedDate}` }}
        </span>
        <div class="d-flex align-center info-item">
            <div class="mr-4 font-weight-medium nowrap" v-text="`${$t('status')}:`" />
            <v-select
                v-if="isEditing.status"
                v-model="status"
                :items="statusesList"
                :placeholder="$t('change_status')"
                :loading="isLoading.status"
                :disabled="isLoading.status"
                item-text="text"
                item-value="value"
                single-line
                dense
                class="mt-0 mr-2 info-input"
            />
            <span v-else :class="statusColor" class="mr-2" v-text="orderStatus" />
            <template v-if="isTO1">
                <v-btn
                    v-if="!isEditing.status"
                    :loading="isLoading.status"
                    :disabled="isLoading.status"
                    color="primary"
                    icon
                    x-small
                    @click="startEditing('status')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.status"
                    :loading="isLoading.status"
                    :disabled="isLoading.status"
                    color="error"
                    icon
                    x-small
                    @click="cancelEditing('status')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.status"
                    :loading="isLoading.status"
                    :disabled="isLoading.status"
                    color="success"
                    icon
                    x-small
                    @click="saveDetailsInfo('status')"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </template>
        </div>
        <div v-if="isTO1 || guide" class="d-flex align-center info-item">
            <span class="mr-4 font-weight-medium nowrap" v-text="`${$t('roles.guide')}:`" />
            <v-select
                v-if="isEditing.guide"
                v-model="guideId"
                :items="guidesList"
                :placeholder="$t('add_guide')"
                :loading="isLoading.guide"
                :disabled="isLoading.guide"
                item-value="personId"
                item-text="fullName"
                dense
                class="mt-0 mr-2 info-input"
            />
            <span v-else class="mr-2" v-text="guide ? `${guide.firstName} ${guide.lastName}` : $t('add_guide')" />
            <template v-if="isTO1">
                <v-btn
                    v-if="!isEditing.guide"
                    :loading="isLoading.guide"
                    :disabled="isLoading.guide"
                    color="primary"
                    icon
                    x-small
                    @click="startEditing('guide')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.guide"
                    :loading="isLoading.guide"
                    :disabled="isLoading.guide"
                    color="error"
                    icon
                    x-small
                    @click="cancelEditing('guide')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.guide"
                    :loading="isLoading.guide"
                    :disabled="isLoading.guide || !guideId"
                    color="success"
                    icon
                    x-small
                    @click="saveDetailsInfo('guide')"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </template>
        </div>
        <div v-if="isTO1 || driver" class="d-flex align-center info-item">
            <span class="mr-4 font-weight-medium nowrap" v-text="`${$t('roles.driver')}:`" />
            <v-select
                v-if="isEditing.driver"
                v-model="driverId"
                :items="driversList"
                :placeholder="$t('add_driver')"
                :loading="isLoading.driver"
                :disabled="isLoading.driver"
                item-value="personId"
                item-text="fullName"
                dense
                class="mt-0 mr-2 info-input"
            />
            <span v-else class="mr-2" v-text="driver ? `${driver.firstName} ${driver.lastName}` : $t('add_driver')" />
            <template v-if="isTO1">
                <v-btn
                    v-if="!isEditing.driver"
                    :loading="isLoading.driver"
                    :disabled="isLoading.driver"
                    color="primary"
                    icon
                    x-small
                    @click="startEditing('driver')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.driver"
                    :loading="isLoading.driver"
                    :disabled="isLoading.driver"
                    color="error"
                    icon
                    x-small
                    @click="cancelEditing('driver')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.driver"
                    :loading="isLoading.driver"
                    :disabled="isLoading.driver || !driverId"
                    color="success"
                    icon
                    x-small
                    @click="saveDetailsInfo('driver')"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </template>
        </div>
        <div
            v-if="isTO1 || selectedGroupDetails.comment"
            class="d-flex flex-column flex-md-row align-md-center info-item"
        >
            <span class="mr-4 font-weight-medium" v-text="`${$t('comments')}:`" />
            <div v-if="isEditing.comment" class="d-flex align-center">
                <v-textarea
                    v-model="comment"
                    :loading="isLoading.comment"
                    :disabled="isLoading.comment"
                    :placeholder="$t('add_comment')"
                    rows="1"
                    dense
                    class="mt-0"
                />
                <v-btn
                    v-if="isEditing.comment"
                    :loading="isLoading.comment"
                    :disabled="isLoading.comment"
                    color="error"
                    icon
                    x-small
                    @click="cancelEditing('comment')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                    v-if="isEditing.comment"
                    :loading="isLoading.comment"
                    :disabled="isLoading.comment"
                    color="success"
                    icon
                    x-small
                    @click="saveDetailsInfo('comment')"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </div>
            <div v-else>
                <span class="mr-2" v-text="comment ? comment : $t('add_comment')" />
                <v-btn
                    v-if="isTO1"
                    :loading="isLoading.comment"
                    :disabled="isLoading.comment"
                    color="primary"
                    icon
                    x-small
                    @click="startEditing('comment')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>
        </div>
        <div v-if="selectedGroupDetails.touristsQuantity" class="d-flex info-item">
            <span class="font-weight-medium" v-text="`${$t('tourists')}:`" />&nbsp;
            <span v-html="selectedGroupDetails.touristsQuantity" />
        </div>
    </div>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import {authStore, runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class GroupDetailsInfo extends Vue {
        @PropSync('_selectedGroupDetails') selectedGroupDetails
        @Prop() groupId

        isEditingComment = false
        isLoading = {
            guide: false,
            driver: false,
            comment: false,
            status: false,
        }
        isEditing = {
            guide: false,
            driver: false,
            comment: false,
            status: false,
        }
        status = null
        comment = null
        driverId = null
        guideId = null
        driversList = []
        guidesList = []

        async mounted() {
            this.comment = this.selectedGroupDetails?.comment ? this.selectedGroupDetails.comment : ''
            this.status = this.selectedGroupDetails?.status ? this.selectedGroupDetails.status : null
            this.driverId = this.selectedGroupDetails?.driver?.personId
                ? this.selectedGroupDetails.driver.personId
                : null
            this.guideId = this.selectedGroupDetails?.guide?.personId ? this.selectedGroupDetails.guide.personId : null

            if (this.isTO1 && this.user.person.companyId) {
                try {
                    const {users} = await this.$api.persons.get({companyId: this.user.person.companyId})

                    this.driversList = users
                        .filter(u => u.role.includes('driver'))
                        .map(u => {
                            u.fullName = [u.firstName, u.lastName].join(' ')
                            return u
                        })
                    this.guidesList = users
                        .filter(u => u.role.includes('guide'))
                        .map(u => {
                            u.fullName = [u.firstName, u.lastName].join(' ')
                            return u
                        })
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        startEditing(type) {
            this.isEditing[type] = true
            for (let key in this.isEditing) {
                if (key !== type) this.cancelEditing(key)
            }
        }

        cancelEditing(type) {
            this.isEditing[type] = false

            if (type === 'comment') {
                this.comment = this.selectedGroupDetails?.comment ? this.selectedGroupDetails.comment : ''
            } else if (type === 'status') {
                this.status = this.selectedGroupDetails.status ? this.selectedGroupDetails.status : null
            } else if (type === 'guide') {
                this.guideId = this.selectedGroupDetails?.guide?.personId
                    ? this.selectedGroupDetails.guide.personId
                    : null
            } else if (type === 'driver') {
                this.driverId = this.selectedGroupDetails?.driver?.personId
                    ? this.selectedGroupDetails.driver.personId
                    : null
            }
        }

        async saveDetailsInfo(type) {
            this.isLoading[type] = true
            let data = {}

            if (this.comment) data.comment = this.comment
            if (this.status) data.status = this.status
            if (this.guideId) data.guideId = this.guideId
            if (this.driverId) data.driverId = this.driverId

            try {
                await this.$api.modifySchedulerGroup.put(this.groupId, data)

                if (type === 'comment') this.selectedGroupDetails.comment = this.comment
                if (type === 'status') this.selectedGroupDetails.status = this.status
                if (type === 'driver') {
                    this.selectedGroupDetails.driver = this.driversList.find(
                        driver => driver.personId === this.driverId
                    )
                }
                if (type === 'guide')
                    this.selectedGroupDetails.guide = this.guidesList.find(guide => guide.personId === this.guideId)

                // eslint-disable-next-line no-empty
            } catch (e) {
                this.cancelEditing(type)
            } finally {
                this.isLoading[type] = false
                this.isEditing[type] = false
            }
        }

        get serviceTypeIcon() {
            return getServiceTypeIcon(this.selectedGroupDetails.serviceType)
        }

        get formattedDate() {
            return this.$dateFns.format(new Date(this.selectedGroupDetails.dateTime), 'dd MMM yyyy (eee) p')
        }

        get guide() {
            return this.selectedGroupDetails?.guide
        }

        get driver() {
            return this.selectedGroupDetails?.driver
        }

        get statusesList() {
            const statusesList = ['NEW', 'IN_PROGRESS', 'DONE', 'CANCELLED']

            return statusesList.map(value => ({value, text: this.$t(`orderStatus.${value}`)}))
        }

        get orderStatus() {
            if (!this.status) return null

            return this.$t(`order_statuses.${this.status.toUpperCase().replace(/[\s.]/g, '_')}`)
        }

        get statusColor() {
            return runtimeStore.orderStatusColor(this.selectedGroupDetails.status)
        }

        get isTO1() {
            return authStore.isTO1
        }

        get user() {
            return authStore.user
        }
    }
</script>

<style scoped>
    .info-item {
        min-height: 44px;
    }
    .info-input {
        max-width: 300px;
    }
</style>
