<template>
    <company-profile
        no-redirect
        disable-type
        :company="{type: 'INTERNAL_SUPPLIER', contactPerson: {}, phones: []}"
        @save="save"
    />
</template>

<script>
    import {Component, Emit, Vue} from 'nuxt-property-decorator'
    import CompanyProfile from '~src/components/account/companyProfile.src'

    @Component({
        components: {CompanyProfile},
    })
    export default class CreateSupplierForm extends Vue {
        @Emit()
        save() {}
    }
</script>
