<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'

    @Component
    export default class RateNote extends Vue {
        @Prop({required: true}) room

        render() {
            return null
        }
    }
</script>
