<template>
    <v-card outlined>
        <v-card-title>
            <v-icon left color="primary">mdi-star</v-icon>
            {{ $t('extra_services') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-row v-if="mandatoryExtraServices.length">
                <v-col cols="12">
                    <h4 class="text-subtitle-1 font-weight-bold">
                        {{ $t('extra_services_included') }}
                    </h4>
                </v-col>
            </v-row>
            <extra-service
                v-for="(extraService, extraServiceIndex) in mandatoryExtraServices"
                :key="`${extraServiceIndex}-mandatory`"
                :extra-service="extraService"
                :hide-action="!manualModification"
            />
            <v-row v-if="optionalExtraServices.length || mandatoryExtraServices.length">
                <v-col cols="12">
                    <h4 class="text-subtitle-1 font-weight-bold">
                        {{ $t('extra_services') }}
                    </h4>
                </v-col>
            </v-row>
            <extra-service
                v-for="(extraService, extraServiceIndex) in optionalExtraServices"
                :key="`${extraServiceIndex}-optional`"
                :extra-service="extraService"
                :extra-services="extraServices"
                :hide-action="disabled"
                :manual-modification="manualModification"
                @update="update"
            />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import ExtraService from '~src/components/booking/forms/fields/extraService.src'

    @Component({
        components: {ExtraService},
    })
    export default class ExtraServicesForm extends Vue {
        @VModel({type: Array}) extraServices
        @Prop({required: true}) additionalOptions
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) manualModification

        @Emit()
        update({service: {serviceRPH}, operation, qty, nettoPriceValue, bruttoPriceValue}) {
            const index = !this.manualModification
                ? this.extraServices.findIndex(service => service.serviceRPH === serviceRPH)
                : this.extraServices.findIndex(service => service.extraServiceId === +serviceRPH)
            if (!operation) {
                this.extraServices.splice(index, 1)
            } else {
                const service = !this.manualModification
                    ? {serviceRPH, quantity: +qty}
                    : {extraServiceId: +serviceRPH, quantity: +qty, nettoPriceValue, bruttoPriceValue}
                if (index !== -1) {
                    this.$set(this.extraServices, index, service)
                } else {
                    this.extraServices.push(service)
                }
            }
        }

        get optionalExtraServices() {
            return this.additionalOptions?.extraServices.filter(extraService => !extraService.mandatory) || []
        }

        get mandatoryExtraServices() {
            return this.additionalOptions?.extraServices.filter(extraService => extraService.mandatory) || []
        }
    }
</script>
