<template>
    <div class="full-width d-flex justify-center align-center flex-column">
        <v-progress-circular v-if="customProductInfoLoading" color="primary" indeterminate size="48" class="py-8" />
        <template v-else-if="customProductInfo">
            <v-img
                v-if="customProductInfo.imageUrl && !loadedImage"
                tile
                class="thumb mb-2"
                :src="customProductInfo.imageUrl"
                :lazy-src="'/placeholder.png' | srcHost"
                height="250"
                :eager="true"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && customProductInfo.imageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <v-img
                v-if="loadedImage"
                tile
                class="thumb mb-2"
                :src="loadedImage"
                :lazy-src="'/placeholder.png' | srcHost"
                height="250"
                :eager="true"
                @error="onImgError"
            />
            <v-file-input
                prepend-icon="mdi-camera"
                small-chips
                outlined
                dense
                accept="image/jpg, image/jpeg, image/png"
                class="cursor-pointer full-width"
                hide-details
                :label="$t('select_photo')"
                @change="onPhotoLoad"
            />
            <span> {{ $t('or') }} </span>
            <v-text-field
                v-model="customProductInfo.imageUrl"
                prepend-icon="mdi-pencil"
                :disabled="loadedImage"
                dense
                class="full-width"
                outlined
                :label="$t('input_image_url')"
            />
            <v-textarea
                v-model="customProductInfoDescription"
                hide-details
                rows="8"
                dense
                class="full-width mt-4"
                outlined
                :label="$t('description')"
            />
        </template>
        <v-card-actions class="full-width">
            <v-spacer />
            <v-btn
                :disabled="customProductInfoLoading || customProductInfoSaveLoading"
                :loading="customProductInfoSaveLoading"
                color="primary"
                @click="saveCustomProductInfo"
            >
                {{ $t('save') }}
            </v-btn>
            <v-btn text :disabled="customProductInfoSaveLoading" @click="close">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {imageUrl} from '@/utils/filters'

    @Component
    export default class CustomProductInfoForm extends Vue {
        @Prop() service

        imgError = false
        customProductInfo = null
        customProductInfoLoading = false
        customProductInfoSaveLoading = false
        loadedImage = null

        @Emit()
        close() {}

        @Emit()
        closeModal() {}

        onImgError() {
            this.imgError = true
            return false
        }

        async mounted() {
            await this.loadCustomProductInfo()
        }

        async loadCustomProductInfo() {
            try {
                this.customProductInfoLoading = true

                this.customProductInfo = await this.$api.customProductInfo.get({
                    serviceId: this.service.serviceId,
                })
                this.customProductInfo.imageUrl = imageUrl(this.customProductInfo.imageUrl, 800)
                if (!this.customProductInfo.descriptions) {
                    this.customProductInfo.descriptions = [
                        {
                            value: '',
                            locale: 'en',
                        },
                    ]
                }

                // eslint-disable-next-line no-empty
            } catch (e) {
                this.$toast.error(this.$t('error_message.load_error'))
                this.close()
            } finally {
                this.customProductInfoLoading = false
            }
        }

        async saveCustomProductInfo() {
            try {
                this.customProductInfoSaveLoading = true
                const request = {
                    descriptions: this.customProductInfo?.descriptions,
                    ...(this.loadedImage && {
                        fileNames: {
                            en: 'image',
                        },
                    }),
                    ...(this.loadedImage && {fileStream: this.loadedImage.split(',')[1]}),
                    ...(!this.loadedImage &&
                        this.customProductInfo.imageUrl && {imageUrl: this.customProductInfo.imageUrl}),
                }

                await this.$api.customProductInfo.put(this.service.serviceId, request)
                this.$toast.success(this.$t('success_message.saved_successfully'))
                this.loadedImage = null
                this.closeModal()
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.customProductInfoSaveLoading = false
            }
        }

        async onPhotoLoad(file) {
            if (file) {
                this.loadedImage = await this.toBase64(file)
            } else {
                this.loadedImage = null
            }
        }

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }

        get customProductInfoDescription() {
            return this.customProductInfo?.descriptions?.find(d => d.locale === 'en')?.value || ''
        }

        set customProductInfoDescription(val) {
            let description = this.customProductInfo?.descriptions.find(d => d.locale === 'en')
            if (!description) {
                this.customProductInfo?.descriptions.push({
                    locale: 'en',
                    value: '',
                })
            }
            description = this.customProductInfo?.descriptions.find(d => d.locale === 'en')
            description.value = val
        }
    }
</script>
