var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":_vm.outlined,"data-cy":"payment-methods"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('payment')))]),_vm._v(" "),_c('v-card-text',{staticClass:"px-1 px-md-3"},[(!_vm.paymentMethods.length)?_c('div',{staticClass:"px-1",staticStyle:{"white-space":"pre-line"},domProps:{"textContent":_vm._s(_vm.$t('no_payment_options'))}}):_c('v-radio-group',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.paymentMethodId),callback:function ($$v) {_vm.paymentMethodId=$$v},expression:"paymentMethodId"}},[_vm._l((_vm.paymentMethods),function(paymentMethod){return [_c('v-radio',{key:paymentMethod.id,attrs:{"value":paymentMethod.id},on:{"click":function($event){return _vm.onRadioClick(paymentMethod.id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_c('span',[_vm._v(_vm._s(paymentMethod.name))]),_vm._v(" "),(_vm.totalCost(paymentMethod) > 0)?_c('strong',[_vm._v("\n                                "+_vm._s(_vm._f("priceFormat")(_vm.totalCost(paymentMethod),paymentMethod.totalAmount.currency))+"\n                            ")]):_vm._e(),_vm._v(" "),(_vm.pspBankFeeAmount(paymentMethod) && _vm.isB2B)?_c('span',[_vm._v("\n                                ("),_c('span',{domProps:{"innerHTML":_vm._s(
                                        _vm.$t('psp_bank_fee', {
                                            bookingAmount: _vm.totalCostWithoutPspBankFeePrintable(paymentMethod),
                                            bankFee: _vm.pspBankFeePrintable(paymentMethod),
                                        })
                                    )}}),_vm._v(")\n                            ")]):_vm._e()])]},proxy:true}],null,true)}),_vm._v(" "),_c('payment-option-img',{key:`${paymentMethod.id}_img`,staticClass:"ms-8",attrs:{"payment-method":paymentMethod.pspCode}}),_vm._v(" "),_c('div',{key:`${paymentMethod.id}_description`,staticClass:"text-caption ms-8 mb-2",domProps:{"innerHTML":_vm._s(paymentMethod.description)}}),_vm._v(" "),(
                        paymentMethod.splitPaymentOptions &&
                        paymentMethod.splitPaymentOptions.length &&
                        paymentMethod.id === _vm.paymentMethodId
                    )?_c('div',{key:`select-${paymentMethod.id}`,staticClass:"pl-8"},[_c('v-select',{staticClass:"payments-counter",attrs:{"items":_vm.paymentsCounterList,"label":_vm.$t('number_of_payments')},model:{value:(_vm.numberOfPayments),callback:function ($$v) {_vm.numberOfPayments=$$v},expression:"numberOfPayments"}}),_vm._v("\n                    "+_vm._s(`${_vm.$t('amount_of_first_and_next_payments')}:`)+"\n                    "),_c('span',[_vm._v(_vm._s(_vm._f("priceFormat")((_vm.totalCost(paymentMethod) / _vm.numberOfPayments),paymentMethod.totalAmount.currency)))])],1):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }