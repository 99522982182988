<template>
    <div class="mx-auto">
        <v-progress-circular :color="!error ? 'primary' : 'error'" :indeterminate="!error" size="200" width="10">
            <strong v-if="!error">{{ paymentComplete ? $t('booking') : $t('payment_confirmation') }}...</strong>
            <strong v-else>{{ $t('error') }}</strong>
        </v-progress-circular>
    </div>
</template>

<script>
    import {persistentStore} from '@/utils/store-accessor'
    import {Component, mixins} from 'nuxt-property-decorator'
    import LoadMixin from '@/mixins/LoadMixin'
    import ConfirmationRedirectMixin from '@/mixins/confirmationRedirectMixin.src'

    @Component({
        layout: 'centered',
    })
    export default class InvoicePaymentCompletePage extends mixins(LoadMixin, ConfirmationRedirectMixin) {
        error = false
        paymentComplete = false

        async load() {
            const {invoicePaymentKey, paymentNumber, orderId, status, partialPaymentAmount} = this.$route.query

            if (status !== 'success') {
                persistentStore.SET_SELECTED_ORDER_ID(null)
                await this.orderDetailsRedirect(orderId)
                return
            }

            let pspResponse = this.$route.query
            delete pspResponse.status
            delete pspResponse.invoicePaymentKey
            delete pspResponse.orderId
            pspResponse = new URLSearchParams(pspResponse).toString()
            try {
                const paymentCompleteRs = await persistentStore.paymentComplete({
                    invoicePaymentKey,
                    paymentNumber,
                    pspResponse,
                })
                if (paymentCompleteRs.result === 'Success') {
                    this.paymentComplete = true
                    try {
                        await this.$api.invoicePaymentByKey.put({
                            invoicePaymentKey: invoicePaymentKey,
                            ...(partialPaymentAmount && {partialPaymentAmount: +partialPaymentAmount}),
                        })
                        persistentStore.SET_SELECTED_ORDER_ID(null)
                        await this.orderDetailsRedirect(orderId)
                    } catch (e) {
                        this.$toast.error('Something wrong on change invoice step')
                        this.error = true
                    }
                } else {
                    this.$toast.error('Something wrong on payment complete step')
                    console.error(paymentCompleteRs)
                    this.error = true
                }
            } catch (e) {
                this.$toast.error('Something wrong on payment complete step')
                this.error = true
            }
        }
    }
</script>
