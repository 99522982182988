<template>
    <v-row :dense="$breakpoint.smAndDown">
        <v-col v-for="(item, index) in items" :key="index" class="d-flex child-flex" cols="4" sm="2">
            <v-img
                :src="item.url ? galleryThumb(item) : item.thumb"
                aspect-ratio="1"
                class="grey lighten-2 cursor-pointer"
                eager
                @click="showPhotoSwipe(index)"
            >
                <template v-if="item.thumb" v-slot>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-icon size="96" color="white">mdi-play</v-icon>
                    </v-row>
                </template>
            </v-img>
        </v-col>
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </v-row>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import VPhotoSwipe from '@/components/snippets/VPhotoSwipe'

    @Component({components: {VPhotoSwipe}})
    export default class CmsGallery extends Vue {
        @Prop({required: true}) items

        photoSwipeIsOpen = false
        photoSwipeOptions = {
            index: 0,
        }

        galleryThumb(img) {
            if (this.$breakpoint.smAndDown) {
                return img.formats.medium?.url || img.url
            } else {
                return img.formats.small?.url || img.url
            }
        }

        showPhotoSwipe(index) {
            this.photoSwipeOptions.index = index
            this.photoSwipeIsOpen = true
        }

        get photoSwipeItems() {
            return this.items.map(({url, caption, formats, src}) =>
                url
                    ? {
                          src: formats.large?.url || url,
                          w: 0,
                          h: 0,
                          title: caption,
                      }
                    : {
                          html: `<div class="wrapper"><div class="video-wrapper"><iframe class="pswp__video" width="960" height="640" src="${src}" frameborder="0" allowfullscreen></iframe></div></div>`,
                          title: caption,
                      }
            )
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .pswp__zoom-wrap {
        text-align: center;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }

        .wrapper {
            line-height: 0;
            width: 100%;
            max-width: 900px;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin: 0 auto;
            text-align: left;
            z-index: 1045;

            .video-wrapper {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                padding-top: 25px;
                height: 0;
                width: 100%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            video {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
</style>
