<template>
    <v-switch
        v-model="value"
        :loading="isLoading"
        :disabled="isLoading"
        :label="value ? $t('active') : $t('orderStatus.ARCHIVED')"
        color="primary"
        dense
        hide-details
        class="mt-0"
        @change="save"
    />
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ArchiveReservation extends Vue {
        @Prop({required: true}) orderId
        @Prop({required: true, type: Boolean, default: false}) status

        isLoading = false
        value = false

        mounted() {
            this.value = this.status
        }

        async save() {
            try {
                this.isLoading = true
                await this.$api.orders.put(this.orderId, {active: this.value})

                // eslint-disable-next-line no-empty
            } catch (e) {
                this.selectedStatus = this.value = this.status
            } finally {
                this.isLoading = false
            }
        }
    }
</script>
