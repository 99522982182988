<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" offset-y max-width="300" max-height="425">
        <template v-slot:activator="{on}">
            <v-text-field
                :value="touristsLabel"
                :single-line="singleLine"
                :outlined="outlined"
                filled
                background-color="white"
                :prepend-inner-icon="showIcon ? 'mdi-account' : null"
                hide-details
                readonly
                :class="{caption: $breakpoint.smAndDown}"
                :label="$t(label)"
                v-on="on"
            />
        </template>
        <v-card class="tourists-drop-down">
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <tourists-select
                            :adults-prop.sync="adults"
                            :children-prop.sync="childrenAges"
                            :max-adults="maxAdults"
                            :max-children="maxChildren"
                            :max-total="maxTotal"
                        />
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    import {Vue, Component, PropSync, Prop} from 'vue-property-decorator'
    import TouristsSelect from '~src/components/search/forms/touristsSelect.src'

    @Component({
        components: {
            TouristsSelect,
        },
    })
    export default class TouristsSelectMenu extends Vue {
        @Prop({default: 'tourist'}) token
        @PropSync('_adults', {default: 1}) adults
        @PropSync('_childrenAges', {default: () => []}) childrenAges
        @Prop({default: false}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: ''}) label
        @Prop({default: true}) showIcon
        @Prop({default: 5}) maxAdults
        @Prop({default: 5}) maxChildren
        @Prop() maxTotal

        menu = false
        rooms = []

        beforeDestroy() {
            this.$refs.menu.activatorNode = null
        }

        get touristsLabel() {
            const touristsTotal = this.adults + this.childrenAges.length
            return `${touristsTotal} ${this.$tc(this.token, touristsTotal)}`
        }
    }
</script>

<style scoped lang="scss">
    @media (max-width: 960px) {
        .tourists-drop-down {
            max-width: 100% !important;
        }
    }
    ::v-deep {
        .v-list-item__content {
            overflow: visible;
        }
    }
</style>
