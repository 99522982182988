<template>
    <div class="d-flex flex-column">
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row class="mt-1">
            <v-col :md="2">
                <date-picker v-model="startDate" outlined dense :label="`${$t('transfers.departure_date')}`" />
            </v-col>
            <v-col :md="2">
                <time-field v-model="startTime" outlined dense :label="`${$t('transfers.pickup_time')}`" />
            </v-col>
        </v-row>
        <span class="text-subtitle-1 font-weight-medium">
            {{ $t('flights.departure') }}
        </span>
        <airport-form
            v-if="infoByServiceType.transfer.departure.flightInfo"
            :request="infoByServiceType.transfer"
            point-type="departure"
            service-type="flightInfo"
        />
        <train-form
            v-if="infoByServiceType.transfer.departure.trainInfo"
            :request="infoByServiceType.transfer"
            point-type="departure"
            service-type="trainInfo"
        />
        <address-form
            v-if="infoByServiceType.transfer.departure.address"
            :request="infoByServiceType.transfer"
            point-type="departure"
            service-type="address"
        />
        <v-row v-if="infoByServiceType.transfer.departure.hotel" class="mt-1">
            <v-col md="4">
                <v-text-field
                    v-model="infoByServiceType.transfer.departure.hotel.hotelName"
                    :label="$tc('hotel', 1)"
                    dense
                    hide-details
                />
            </v-col>
        </v-row>
        <cruise-port-form
            v-if="infoByServiceType.transfer.departure.shipInfo"
            :request="infoByServiceType.transfer"
            point-type="departure"
            service-type="shipInfo"
        />
        <manual-form
            v-if="infoByServiceType.transfer.departure.manualInfo"
            :request="infoByServiceType.transfer"
            point-type="departure"
            service-type="manualInfo"
        />
        <span class="text-subtitle-1 font-weight-medium">
            {{ $t('flights.arrival') }}
        </span>
        <airport-form
            v-if="infoByServiceType.transfer.arrival.flightInfo"
            :request="infoByServiceType.transfer"
            point-type="arrival"
            service-type="flightInfo"
        />
        <train-form
            v-if="infoByServiceType.transfer.arrival.trainInfo"
            :request="infoByServiceType.transfer"
            point-type="arrival"
            service-type="trainInfo"
        />
        <address-form
            v-if="infoByServiceType.transfer.arrival.address"
            :request="infoByServiceType.transfer"
            point-type="arrival"
            service-type="address"
        />
        <v-row v-if="infoByServiceType.transfer.arrival.hotel" class="mt-1">
            <v-col md="4">
                <v-text-field
                    v-model="infoByServiceType.transfer.arrival.hotel.hotelName"
                    :label="$tc('hotel', 1)"
                    dense
                    hide-details
                />
            </v-col>
        </v-row>
        <cruise-port-form
            v-if="infoByServiceType.transfer.arrival.shipInfo"
            :request="infoByServiceType.transfer"
            point-type="arrival"
            service-type="shipInfo"
        />
        <manual-form
            v-if="infoByServiceType.transfer.arrival.manualInfo"
            :request="infoByServiceType.transfer"
            point-type="arrival"
            service-type="manualInfo"
        />
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import AirportForm from '@/components/account/modify/manual/serviceType/forms/transfer/AirportForm'
    import TrainForm from '@/components/account/modify/manual/serviceType/forms/transfer/TrainForm'
    import AddressForm from '@/components/account/modify/manual/serviceType/forms/transfer/AddressForm'
    import CruisePortForm from '@/components/account/modify/manual/serviceType/forms/transfer/CruisePortForm'
    import ManualForm from '@/components/account/modify/manual/serviceType/forms/transfer/ManualForm'

    @Component({
        components: {TimeField, DatePicker, AirportForm, TrainForm, AddressForm, CruisePortForm, ManualForm},
    })
    export default class ModifyManualTransfer extends Vue {
        @VModel() infoByServiceType

        get startDate() {
            return this.infoByServiceType.transfer.startDateTime.split('T')[0]
        }

        set startDate(date) {
            this.infoByServiceType.transfer.startDateTime = `${date}T${
                this.infoByServiceType.transfer.startDateTime.split('T')[1]
            }`
        }

        get startTime() {
            return this.infoByServiceType.transfer.startDateTime.split('T')[1]
        }

        set startTime(time) {
            this.infoByServiceType.transfer.startDateTime = `${
                this.infoByServiceType.transfer.startDateTime.split('T')[0]
            }T${time}`
        }
    }
</script>
