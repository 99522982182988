<template>
    <div>
        <component
            :is="componentName"
            v-for="(offer, index) in offers"
            :key="offer.offerKey"
            :offer="offer"
            :data-cy="`flight-offer-${index}`"
        >
            <template v-slot:price>
                <slot name="price" :offer="offer" />
            </template>
        </component>
        <flight-price-detail-modal />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import FlightPriceDetailModal from '~/components/parts/Flights/FlightPriceDetailModal'

    @Component({
        components: {
            FlightCard: () => import('~/components/flights/search/offers/FlightCard'),
            FlightCardMobile: () => import('~/components/flights/search/offers/FlightCardMobile'),
            FlightPriceDetailModal,
        },
    })
    export default class FlightOffers extends Vue {
        @Prop({required: true}) offers

        get componentName() {
            return !this.$breakpoint.smAndDown ? 'flight-card' : 'flight-card'
        }
    }
</script>
