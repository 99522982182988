<template>
    <div v-if="groups.length">
        <main-header :title="$t('resorts')" />
        <v-row class="ma-4">
            <v-col v-for="group in groups" :key="group.countryCode" md="3">
                <ul>
                    <li>
                        <span class="fi" :class="`fi-${group.countryCode}`" />
                        <b>{{ group.countryName }}</b>
                    </li>
                    <li v-for="resort in group.resorts" :key="resort.id">
                        <nuxt-link
                            class="text-decoration-none text--secondary"
                            no-prefetch
                            :to="{name: 'resort', params: {link: resort.link}}"
                        >
                            {{ resort.cityName }}
                        </nuxt-link>
                    </li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import MainHeader from '~src/components/snippets/mainHeader.src'
    import {cmsStore} from '@/utils/store-accessor'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({components: {MainHeader}})
    export default class Resorts extends Vue {
        async fetch() {
            await cmsStore.loadResorts()
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadResorts)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadResorts)
        }

        get groups() {
            return cmsStore.resorts.reduce((groups, {countryCode, countryName, ...resort}) => {
                let group = groups.find(group => group.countryCode === countryCode)
                if (!group) {
                    group = {
                        countryCode,
                        countryName,
                        resorts: [],
                    }
                    groups.push(group)
                }
                group.resorts.push(resort)
                return groups
            }, [])
        }
    }
</script>

<style scoped lang="scss">
    ul {
        list-style: none;
        position: relative;
        padding-left: 36px;

        li .fi {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            background-size: cover;
            box-shadow: 0 0 0 1px rgba(178, 191, 200, 0.6);
        }
    }
</style>
