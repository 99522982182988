<template>
    <div v-if="features.length" class="d-flex">
        <!--eslint-disable-next-line vue/singleline-html-element-content-newline-->
        <p class="mb-0 text-no-wrap success--text font-weight-medium caption">{{ $t('carsRent.price_includes') }}:</p>
        <div class="d-flex flex-wrap align-center">
            <small v-for="name in features.slice(0, 2)" :key="name" class="ml-3">
                <v-icon :title="$t(`carsRent.${name.toLowerCase()}`)" color="success" left small class="mr-1">
                    mdi-checkbox-marked-circle
                </v-icon>
                <span v-text="$t(`carsRent.${name.toLowerCase()}`)" />
            </small>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class FeatureIncludes extends Vue {
        @Prop({default: () => [], type: Array, required: true}) features
    }
</script>
