<template>
    <div v-if="!isLoading && formattedWeather.length">
        <v-card v-if="!showSingleDay" class="pa-2 d-flex justify-space-between">
            <template v-for="(item, index) in formattedWeather">
                <div :key="index">
                    <div class="text-center">
                        <span class="text-body-2 primary--text">
                            {{ $dateFns.format(item.day, 'EEE') }}
                        </span>
                        <span class="text-caption grey--text">{{ $dateFns.format(item.day, 'dd/MM') }}</span>
                    </div>
                    <v-img
                        :src="`http://openweathermap.org/img/wn/${item.icon}@2x.png`"
                        width="50"
                        height="50"
                        class="mx-auto"
                    />
                    <div class="text-subtitle-2 text-center">
                        <span v-if="Number.isInteger(item.tempNight)">
                            {{ `${item.tempDay}&deg;/${item.tempNight}&deg;` }}
                        </span>
                        <span v-else>{{ `${item.tempDay}&deg;` }}</span>
                    </div>
                    <div class="text-center text-caption">{{ item.weather }}</div>
                </div>
            </template>
        </v-card>
        <div v-else-if="formattedWeather && formattedWeather[0]">
            <span v-if="Number.isInteger(formattedWeather[0].tempNight)">
                {{ `${formattedWeather[0].tempDay}&deg;/${formattedWeather[0].tempNight}&deg;` }}
            </span>
            <span v-else-if="Number.isInteger(formattedWeather[0].tempDay)">
                {{ `${formattedWeather[0].tempDay}&deg;` }}
            </span>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {appInstance} from '@/utils/app-accessor'
    import {weatherStore} from '@/store'

    @Component
    export default class WeatherService extends Vue {
        @Prop() city
        @Prop({required: false}) startDate
        @Prop({required: false}) endDate
        @Prop({default: false, type: Boolean}) showSingleDay

        isLoading = true
        weatherData = null
        cityId = null
        cityName = null

        async mounted() {
            if (!this.city?.id || !this.city?.cityName) return

            this.cityId = this.city.id
            this.cityName = this.city.cityName
            const weather = await appInstance.$localForage.getItem('weather')

            if (
                weather &&
                weather[this.cityId] &&
                this.$dateFns.isAfter(this.$dateFns.parseISO(weather[this.cityId].expirationTime), new Date())
            ) {
                weatherStore.SET_WEATHER(weather)
            } else {
                await this.loadWeatherData({cityName: this.cityName, cityId: this.cityId})
            }

            this.isLoading = false
        }

        async loadWeatherData({cityName, cityId}) {
            await weatherStore.loadWeather({cityName, cityId})
        }

        get weather() {
            return weatherStore.weather
        }

        get formattedWeather() {
            let counter = this.$dateFns.parseISO(this.startDate)

            if (!this.showSingleDay) {
                const start = this.$dateFns.differenceInCalendarDays(this.$dateFns.parseISO(this.startDate), new Date())
                const end = this.$dateFns.differenceInCalendarDays(this.$dateFns.parseISO(this.endDate), new Date())
                const isWithinInterval = this.$dateFns.isWithinInterval(new Date(), {
                    start: new Date(this.startDate),
                    end: new Date(this.endDate),
                })

                if ((start >= -4 && start <= 4) || (end >= 0 && end <= 4) || isWithinInterval) {
                    counter = new Date()
                }
            }

            return (
                this.weather[this.cityId]?.list.reduce((formattedWeather, item) => {
                    const data = {
                        day: item.dt_txt,
                        icon: item.weather[0].icon,
                    }
                    const itemHours = this.$dateFns.getHours(this.$dateFns.parseISO(item.dt_txt))
                    const isSameDay = this.$dateFns.isSameDay(counter, this.$dateFns.parseISO(item.dt_txt))

                    if (isSameDay && itemHours >= 12) {
                        data.tempDay = Math.floor(item.main.temp)
                        data.weather = item.weather[0].main
                        data.tempNight = Math.floor(
                            this.weather[this.cityId]?.list.find(el => {
                                return (
                                    this.$dateFns.isSameDay(counter, this.$dateFns.parseISO(el.dt_txt)) &&
                                    this.$dateFns.getHours(this.$dateFns.parseISO(el.dt_txt)) >= 21
                                )
                            })?.main.temp
                        )

                        formattedWeather.push(data)
                        counter = this.$dateFns.addDays(counter, 1)
                    }

                    return formattedWeather
                }, []) || []
            )
        }
    }
</script>
