<template>
    <v-dialog v-model="dialog" hide-overlay>
        <template v-slot:activator="{on}">
            <v-btn depressed v-on="on">
                <v-icon>mdi-pencil</v-icon>
                {{ $t('admin.code') }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="white--text primary">
                {{ $t('admin.code') }}
            </v-card-title>
            <v-card-text>
                <div ref="editor" class="wrapper full-width" />
            </v-card-text>
            <v-card-actions>
                <v-btn depressed color="primary" @click="save">
                    {{ $t('ok') }}
                </v-btn>
                <v-btn text @click="dialog = false">{{ $t('cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import loader from '@monaco-editor/loader'
    import {timeout} from '@/utils/helpers'

    @Component
    export default class CodeEditorDialog extends Vue {
        @Prop({required: true}) ckeditor

        dialog = false
        editor = null

        @Watch('dialog')
        async onDialogChange(dialog) {
            if (!dialog) return
            const value = this.ckeditor.getData()
            if (!this.editor) {
                await this.$nextTick
                const monaco = await loader.init()
                this.editor = monaco.editor.create(this.$refs.editor, {
                    //value,
                    language: 'text/html',
                    autoIndent: true,
                    formatOnPaste: true,
                    formatOnType: true,
                })
            }
            this.editor.getModel().setValue(value)
            await timeout(100)
            await this.editor.getAction('editor.action.formatDocument').run()
        }

        save() {
            this.ckeditor.data.set(this.editor.getValue())
            this.dialog = false
        }
    }
</script>

<style scoped lang="scss">
    .wrapper {
        height: 800px;
    }
</style>
