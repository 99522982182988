<template>
    <order-summary-layout :product-store="productStore" :total-price="totalPrice" :extra-prices="extraPrices">
        <template v-slot>
            <div class="d-flex mb-3">
                <v-img
                    tile
                    class="me-3"
                    max-width="150"
                    :src="image | imageUrl(600)"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <div class="text-truncate">
                    <div :title="info.productName" class="primary--text font-weight-medium text-subtitle-1">
                        {{ info.productName }}
                    </div>
                    <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" class="mb-1" />
                    <div v-if="info.productDescription" class="mb-4 text-body-2" v-html="info.productDescription" />
                </div>
            </div>
            <slot name="summary" />
            <v-divider class="mb-2" />
            <div>
                <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo()">
                    <v-icon left small>mdi-information-outline</v-icon>
                    <span>
                        {{ info.serviceName }}
                    </span>
                </div>
                <div>
                    <div v-if="offer.available" class="d-flex align-center caption">
                        <v-icon :title="$t('available')" left small class="success--text">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <span class="success--text" v-text="$t('available')" />
                    </div>
                    <div v-else class="d-flex align-center caption">
                        <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                        <span class="warning--text" v-text="$t('on_request')" />
                    </div>
                </div>
                <conditions-label
                    :offer-key="offer.offerKey"
                    :price="offer.price"
                    class="text-caption"
                    @conditions="showConditionsInfo"
                />
                <div v-if="info.tariff" class="d-flex align-center caption">
                    <v-icon left small class="info--text">mdi-tag-text</v-icon>
                    {{ info.tariff }}
                </div>
                <div class="mt-1 font-weight-bold">
                    {{ offerPrice | price }}
                </div>
                <commission-amount :commission="offer.price.commission" />
                <net-amount :price="offer.price" />
            </div>
            <extra-services-summary
                v-if="extraServices && additionalOptions"
                :extra-services="extraServices"
                :additional-options="additionalOptions"
            />
            <conditions-modal>
                <template v-slot:info>
                    <extra-service-conditions-modal-info />
                </template>
            </conditions-modal>
        </template>
    </order-summary-layout>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {clone} from '@/utils/helpers'
    import {authStore, extraServicesStore} from '@/store'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import ConditionsLabel from '@/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_EXTRA_SERVICE_CONDITIONS_INFO, SHOW_EXTRA_SERVICE_INFO_MODAL} from '@/utils/event-bus'
    import ExtraServiceConditionsModalInfo from '@/components/extraServices/snippets/ExtraServiceConditionsModalInfo'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import OrderSummaryBase from '@/components/booking/OrderSummaryBase.vue'

    @Component({
        components: {
            ExtraServicesSummary,
            OrderSummaryLayout,
            ExtraServiceConditionsModalInfo,
            ConditionsModal,
            ConditionsLabel,
            CommissionAmount,
            NetAmount,
            SupplierNameLabel,
        },
    })
    export default class ExtraServiceOrderSummary extends OrderSummaryBase {
        @Prop({required: true}) product
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({default: true}) prebooked

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_CONDITIONS_INFO, this.offer)
        }

        offerInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        get image() {
            if (!this.info.images) return null
            let mainImage = this.info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images.length > 0) {
                mainImage = this.info.images[0]
            }
            if (
                !mainImage.linkedEntityName ||
                (mainImage.linkedEntityName && mainImage.linkedEntityName !== this.info.serviceName)
            ) {
                mainImage =
                    this.info.images.find(image => image.linkedEntityName === this.info.serviceName) || mainImage
            }
            return mainImage && mainImage.url ? mainImage.url : null
        }

        get productStore() {
            return extraServicesStore
        }

        get orderTotalPrice() {
            return extraServicesStore.orderTotalPrice(this.offer, this.prebooked, [], this.prepareBookResponse)
        }

        get offerPrice() {
            const price =
                this.prebooked && this.offer?.currentPrice ? clone(this.offer.currentPrice) : clone(this.offer.price)
            const mandatory =
                extraServicesStore.bookingAdditionalOptions.extraServices?.filter(
                    extraService => extraService.mandatory
                ) || []

            if (mandatory.length) {
                mandatory.forEach(item => {
                    price.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount
                    price.originalAmount -= item.salesTerms.find(el => el.type === 'CLIENT').originalAmount
                })
            }

            return price
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
