<template>
    <v-tabs v-model="mobileTabs" :icons-and-text="isMobile" class="sticky-bar">
        <v-tab v-for="tab in tabs" :key="tab.label">
            {{ isMobile ? $t(`${tab.label}`) : '' }}
            <v-icon>{{ tab.icon }}</v-icon>
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Vue, Component, Model, Watch} from 'vue-property-decorator'

    @Component
    export default class MobileSearchPageTabs extends Vue {
        @Model('change') value
        mobileTabs = 1
        defaultTabs = [
            {icon: 'mdi-format-list-checks', label: 'filters'},
            {icon: 'mdi-format-list-text', label: 'offers'},
            {icon: 'mdi-map-marker', label: 'map'},
        ]

        @Watch('value')
        onChangeValue(val) {
            this.mobileTabs = val
        }

        @Watch('mobileTabs')
        onChangeMobileTabs(val) {
            this.$emit('change', val)
        }

        get tabs() {
            return this.defaultTabs
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
