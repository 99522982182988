<template>
    <div>
        <v-checkbox
            v-if="compareMode"
            v-model="compareOfferKeys"
            dense
            :ripple="false"
            hide-details
            :value="room.groupedOffers[0].offerKey"
            @click.stop
        >
            <template v-slot:label>
                <slot />
            </template>
        </v-checkbox>
        <slot v-else />
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {hotelsStore} from '@/utils/store-accessor'

    @Component
    export default class HotelRoomNameLabel extends Vue {
        @Prop({default: false, type: Boolean}) compareMode
        @Prop({required: true}) hotel
        @Prop({required: true}) room

        get compareOfferKeys() {
            return hotelsStore.offersToCompare.find(hotel => hotel.hotelCode === this.hotel.hotelCode)?.rooms || []
        }

        set compareOfferKeys(rooms) {
            hotelsStore.ADD_ROOM_TO_COMPARE({hotelCode: this.hotel.hotelCode, rooms})
        }
    }
</script>
