<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import HotelMap from '@/components/search/offers/HotelMap'
    import ActivityMapInfoWindow from '@/components/activities/search/ActivityMapInfoWindow'

    @Component
    export default class ActivityMap extends HotelMap {
        pointInfo = {}

        async load() {
            await this.initMap()
            this.setMarkers(this.offers)
        }

        setMarkers(offers) {
            this.markers.forEach(marker => marker.setMap(null))
            this.markers = []
            if (!offers.length) return
            offers.forEach(offer => {
                this.addMarker(offer)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            this.setMapPosition()
        }

        addMarker(offer) {
            let position
            if (offer.latitude && offer.longitude) {
                position = {
                    lat: Number(offer.latitude),
                    lng: Number(offer.longitude),
                }
            } else {
                return
            }

            if (this.markers.findIndex(marker => marker.position === position) !== -1) return

            const marker = new this.google.maps.Marker({
                position,
                icon: this.createMarkerIcon(offer),
                map: this.map,
                data: offer,
            })

            marker.addListener('click', () => {
                if (!marker.infoWindow) {
                    this.showInfoWindow(marker)
                }
            })

            this.markers.push(marker)
            return marker
        }

        async showInfoWindow(marker) {
            const markerWithInfoWindow = this.markers.find(marker => marker.infoWindow)
            if (markerWithInfoWindow) {
                markerWithInfoWindow.infoWindow.close()
                markerWithInfoWindow.infoWindow = null
            }
            const InfoWindowComponent = Vue.extend(ActivityMapInfoWindow)
            const instance = new InfoWindowComponent({
                propsData: {
                    propData: marker.data,
                },
                parent: this,
            })
            instance.$mount()
            marker.infoWindow = new this.google.maps.InfoWindow({
                content: instance.$el,
            })
            this.google.maps.event.addListener(marker.infoWindow, 'closeclick', () => {
                marker.infoWindow.close()
                marker.infoWindow = null
            })
            marker.infoWindow.open(this.map, marker)
        }

        setMapPosition() {
            this.map.fitBounds(this.bounds)
            this.mapPositionInit = true
        }

        saveMapPosition() {}
    }
</script>
