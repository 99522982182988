<template>
    <div>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row class="mt-1">
            <v-col :md="2">
                <v-select
                    v-if="carPointsData.pickUpPoints"
                    v-model="pickUpPoint"
                    :items="carPointsData.pickUpPoints"
                    item-value="locationCode"
                    item-text="itemText"
                    :label="$t('carsRent.pick-up_point')"
                    dense
                />
            </v-col>
            <v-col :md="2">
                <date-picker v-model="startDate" outlined dense :label="`${$t('transfers.departure_date')}`" />
            </v-col>
            <v-col :md="2">
                <time-field v-model="startTime" outlined dense :label="`${$t('transfers.pickup_time')}`" />
            </v-col>
        </v-row>
        <v-row>
            <v-col :md="2">
                <v-select
                    v-if="carPointsData.returnPoints"
                    v-model="dropOffPoint"
                    :items="carPointsData.returnPoints"
                    item-value="locationCode"
                    item-text="itemText"
                    :label="$t('carsRent.drop-off_point')"
                    dense
                />
            </v-col>
            <v-col :md="2">
                <date-picker v-model="endDate" outlined dense :label="`${$t('transfers.arrival_date')}`" />
            </v-col>
            <v-col :md="2">
                <time-field v-model="endTime" outlined dense :label="`${$t('transfers.drop-off_time')}`" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'

    @Component({
        components: {TimeField, DatePicker},
    })
    export default class ModifyManualCarRent extends Vue {
        @VModel() infoByServiceType
        @Prop({default: () => ({}), type: Object}) carPointsData

        get startDate() {
            return this.infoByServiceType.carRent.startDateTime.split('T')[0]
        }

        set startDate(date) {
            this.infoByServiceType.carRent.startDateTime = `${date}T${
                this.infoByServiceType.carRent.startDateTime.split('T')[1]
            }`
        }

        get startTime() {
            return this.infoByServiceType.carRent.startDateTime.split('T')[1]
        }

        set startTime(time) {
            this.infoByServiceType.carRent.startDateTime = `${
                this.infoByServiceType.carRent.startDateTime.split('T')[0]
            }T${time}`
        }

        get endDate() {
            return this.infoByServiceType.carRent.endDateTime.split('T')[0]
        }

        set endDate(date) {
            this.infoByServiceType.carRent.endDateTime = `${date}T${
                this.infoByServiceType.carRent.endDateTime.split('T')[1]
            }`
        }

        get endTime() {
            return this.infoByServiceType.carRent.endDateTime.split('T')[1]
        }

        set endTime(time) {
            this.infoByServiceType.carRent.endDateTime = `${
                this.infoByServiceType.carRent.endDateTime.split('T')[0]
            }T${time}`
        }

        get pickUpPoint() {
            return this.infoByServiceType.carRent.pickUpPoint
        }

        set pickUpPoint(val) {
            this.infoByServiceType.carRent.pickUpPoint = val
        }

        get dropOffPoint() {
            return this.infoByServiceType.carRent.dropOffPoint
        }

        set dropOffPoint(val) {
            this.infoByServiceType.carRent.dropOffPoint = val
        }
    }
</script>
