<template>
    <div>
        <v-card
            v-for="(
                {product: [transfer], arrivalPoint, departurePoint, prepareBookRequest}, index
            ) in transferSelectedAddOns"
            :key="index"
            outlined
            class="mb-5"
        >
            <v-card-title class="text-capitalize">
                <v-icon left color="primary">mdi-car</v-icon>
                {{ transfer.info.name }}
            </v-card-title>
            <v-divider />
            <v-card-text>
                <transfer-booking-forms
                    :request="prepareBookRequest"
                    :departure-point="departurePoint"
                    :product="transfer"
                    :disabled="disabled"
                    :search-request="{dateTime: transfer.offers[0].date}"
                    :arrival-point="arrivalPoint"
                    :offer="transfer.offers[0]"
                    @change="setPrepareBookRequest($event, prepareBookRequest)"
                    @change-location="setPrepareBookLocation($event, prepareBookRequest)"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {TRANSFER} from '@/utils/transfers/transfers-const'
    import TransferBookingForms from '@/components/transfers/booking/forms/TransferBookingForms.vue'

    @Component({
        components: {TransferBookingForms},
    })
    export default class AddOnBookingForms extends Vue {
        @Prop({required: true, type: Array}) selectedAddOns
        @Prop({default: false, type: Boolean}) disabled

        setPrepareBookRequest({prop, val}, prepareBookRequest) {
            this.$set(prepareBookRequest, prop, val)
        }

        setPrepareBookLocation({prop, val, pointType}, prepareBookRequest) {
            this.$set(prepareBookRequest[pointType], prop, val)
        }

        get transferSelectedAddOns() {
            return this.selectedAddOns.filter(({productType}) => productType === TRANSFER)
        }
    }
</script>
