<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-lightning-bolt-outline"
        :is-first="isFirst"
        :extra-service-name="info.serviceName"
        :order="order"
        @modifyService="$emit('modifyService', $event)"
        @showInfo="showInfo"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :service="service"
                :service-image="mainImage"
                :order-end-date="orderEndDate"
                :is-package="isPackage"
                :service-label="''"
                :order="order"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-slot:subtitle>
                    <div v-if="roomType" class="d-flex align-top font-weight-bold">
                        <v-icon small left>mdi-bed-empty</v-icon>
                        {{ roomType }}
                    </div>
                </template>
                <template v-slot:extraTime>
                    <activity-duration v-if="duration" :time="duration" />
                    <activity-duration v-if="durationDays || durationTime" :time="durationTime" :days="durationDays" />
                </template>
                <div v-if="offlineServiceType === 'ACCOMMODATION'" class="d-flex">
                    <v-icon left small>mdi-weather-night</v-icon>
                    <strong class="black--text">{{ `${nights} ${$tc('night', nights)}` }}</strong>
                </div>
                <div class="mt-4" />
                <div
                    v-if="mealType && offlineServiceType !== 'ACCOMMODATION'"
                    class="d-flex align-top font-weight-bold"
                >
                    <v-icon small left>mdi-silverware-fork-knife</v-icon>
                    {{ mealType }}
                </div>
                <div v-if="departureAirportCode" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.departure_airport') }}
                    </span>
                    <span>
                        {{ departureAirportCode }}
                    </span>
                </div>
                <div v-if="departureTerminal" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.departure_terminal') }}
                    </span>
                    <span>
                        {{ departureTerminal }}
                    </span>
                </div>
                <div v-if="arrivalAirportCode" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.arrival_airport') }}
                    </span>
                    <span>
                        {{ arrivalAirportCode }}
                    </span>
                </div>
                <div v-if="arrivalTerminal" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.arrival_terminal') }}
                    </span>
                    <span>
                        {{ arrivalTerminal }}
                    </span>
                </div>
                <div v-if="flightNumber" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.flight_number') }}
                    </span>
                    <span>
                        {{ flightNumber }}
                    </span>
                </div>
                <div v-if="flightClass" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.flight_class') }}
                    </span>
                    <span>
                        {{ flightClass }}
                    </span>
                </div>
                <div v-if="bookingClass" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.booking_class') }}
                    </span>
                    <span>
                        {{ bookingClass }}
                    </span>
                </div>
                <div v-if="seatsPrebooked" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.seats_prebooked') }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="mealIncluded" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('meal_included') }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="pickUpPoint" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{
                            offlineServiceType === 'CAR_RENT' || offlineServiceType === 'TRANSFER'
                                ? $t('from')
                                : $t('pick_up_point')
                        }}
                    </span>
                    <span>
                        {{ pickUpPoint }}
                    </span>
                </div>
                <div
                    v-if="pickUpTime && offlineServiceType !== 'CAR_RENT' && offlineServiceType !== 'TRANSFER'"
                    class="d-flex align-top"
                >
                    <span class="me-1 font-weight-bold">
                        {{ $t('pick_up_time') }}
                    </span>
                    <span>
                        {{ pickUpTime }}
                    </span>
                </div>
                <div v-if="dropOffPoint" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{
                            offlineServiceType === 'CAR_RENT' || offlineServiceType === 'TRANSFER'
                                ? $t('to')
                                : $t('drop_off_point')
                        }}
                    </span>
                    <span>
                        {{ dropOffPoint }}
                    </span>
                </div>
                <div
                    v-if="dropOffTime && offlineServiceType !== 'CAR_RENT' && offlineServiceType !== 'TRANSFER'"
                    class="d-flex align-top"
                >
                    <span class="me-1 font-weight-bold">
                        {{ $t('drop_off_time') }}
                    </span>
                    <span>
                        {{ dropOffTime }}
                    </span>
                </div>
                <div v-if="vehicleCategory" class="d-flex align-top mt-4">
                    <span class="me-1 font-weight-bold">
                        {{ $t('filterTitles.vehicle_type') }}
                    </span>
                    <span>
                        {{ vehicleCategory }}
                    </span>
                </div>
                <div v-if="oneWay !== undefined" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ `${oneWay ? $t('transfers.one_way_or_return') : $t('transfers.return_transfer')}` }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="groupTransfer !== undefined" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ `${groupTransfer ? $t('transfers.shared_transfer') : $t('transfers.private_transfer')}` }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>

                <div v-if="contactNumber" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('number_or_transfer_provider') }}
                    </span>
                    <span class="text-no-wrap">
                        {{ contactNumber }}
                    </span>
                </div>
                <div class="mt-4" />
                <template v-slot:additionalInfo2>
                    <flight-service-additional-info v-if="offlineServiceType === 'FLIGHT'" :service="service" />
                </template>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base :service="service" :info="info">
                    <template v-slot:serviceInfo>
                        <div
                            v-if="service.serviceDetails[0].description"
                            class="make-new-line"
                            v-html="service.serviceDetails[0].description"
                        />
                        <div v-if="info.productDescription" v-html="info.productDescription" />
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ServiceCard from '@/components/account/orderServices/ServiceCard'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import FlightServiceAdditionalInfo from '~src/components/account/snippets/FlightServiceAdditionalInfo.vue'
    import ActivityDuration from '@/components/activities/search/offers/ActivityDuration'
    import {fullUrl} from '~src/utils/host-adapter'

    @Component({
        components: {
            ActivityDuration,
            FlightServiceAdditionalInfo,
            ServiceInfoBase,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceCard,
        },
    })
    export default class OfflineService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() isFirst

        info = {}

        modal = false

        showInfo() {
            this.modal = true
        }

        async mounted() {
            try {
                //TODO maybe will be returned later
                // if (this.offlineServiceType === 'FLIGHT') {
                //     await Promise.all(
                //         this.service.serviceDetails[0].segments.map(async itinerary => {
                //             const [departureAirport] = await this.$api.airports.get({
                //                 pattern: itinerary.departureAirportCode,
                //                 limit: 1,
                //             })
                //
                //             const [arrivalAirport] = await this.$api.airports.get({
                //                 pattern: itinerary.arrivalAirportCode,
                //                 limit: 1,
                //             })
                //
                //             itinerary.flightSegmentName = `${departureAirport.cityName} - ${arrivalAirport.cityName}`
                //
                //             return itinerary
                //         })
                //     )
                // }

                this.info = {}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get mainImage() {
            if (this.isBankFee) {
                return fullUrl('/bank-fee.jpg')
            }
            if (this.info.images && this.info.images.length) {
                let image = this.info.images.find(el => el.mainImage)
                if (!image) {
                    image = this.info.images[0]
                }
                return image.url
            }
            return null
        }

        get mealType() {
            return this.service.serviceDetails[0]?.mealType
        }

        get roomType() {
            return this.service.serviceDetails[0]?.roomType
        }

        get arrivalAirportCode() {
            return this.service.serviceDetails[0]?.arrivalAirportCode
        }

        get arrivalTerminal() {
            return this.service.serviceDetails[0]?.arrivalTerminal
        }

        get bookingClass() {
            return this.service.serviceDetails[0]?.bookingClass
        }

        get departureAirportCode() {
            return this.service.serviceDetails[0]?.departureAirportCode
        }

        get departureTerminal() {
            return this.service.serviceDetails[0]?.departureTerminal
        }

        get flightClass() {
            return this.service.serviceDetails[0]?.flightClass
        }

        get flightNumber() {
            return this.service.serviceDetails[0]?.flightNumber
        }

        get seatsPrebooked() {
            return this.service.serviceDetails[0]?.seatsPrebooked
        }

        get mealIncluded() {
            return this.service.serviceDetails[0]?.mealIncluded
        }

        get durationTime() {
            return this.service.serviceDetails[0]?.durationTime
        }

        get durationDays() {
            return this.service.serviceDetails[0]?.durationDays
        }

        get pickUpPoint() {
            return this.service.serviceDetails[0]?.pickUpPoint
        }

        get pickUpTime() {
            return this.service.serviceDetails[0]?.pickUpTime || ''
        }

        get dropOffPoint() {
            return this.service.serviceDetails[0]?.dropOffPoint
        }

        get dropOffTime() {
            return this.service.serviceDetails[0]?.dropOffTime || ''
        }

        get vehicleCategory() {
            return this.service.serviceDetails[0]?.vehicleCategory
        }

        get oneWay() {
            return this.service.serviceDetails[0]?.oneWay
        }

        get groupTransfer() {
            return this.service.serviceDetails[0]?.groupTransfer
        }

        get duration() {
            return this.service.serviceDetails[0]?.duration
        }

        get contactNumber() {
            return this.service.serviceDetails[0]?.contactNumber
        }

        get nights() {
            const startDate = new Date(this.service.startDateTime.slice(0, 10))
            const endDate = new Date(this.service.endDateTime.slice(0, 10))
            return (new Date(endDate) - new Date(startDate)) / 8.64e7
        }

        get offlineServiceType() {
            return this.service.serviceDetails[0]?.extraServiceProductType
        }

        get serviceCheckIn() {
            switch (this.offlineServiceType) {
                case 'CAR_RENT':
                case 'TRANSFER':
                    return this.carRentCheckIn
                case 'FLIGHT':
                    return this.flightCheckIn
                case 'ACCOMMODATION':
                    return this.$options.filters.dateFormat(this.service.startDateTime)
            }

            return this.$options.filters.dateTimeFormat(this.service.startDateTime)
        }

        get serviceCheckOut() {
            switch (this.offlineServiceType) {
                case 'CAR_RENT':
                case 'TRANSFER':
                    return this.carRentCheckOut
                case 'FLIGHT':
                    return this.flightCheckOut
                case 'ACCOMMODATION':
                    return this.$options.filters.dateFormat(this.service.endDateTime)
            }

            return this.service.startDateTime !== this.service.endDateTime
                ? this.$options.filters.dateTimeFormat(this.service.endDateTime)
                : null
        }

        get carRentCheckIn() {
            const pickUpTime = this.pickUpTime
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return `<span> ${this.$t('transfers.pick_up')}: ${date}</span> <small>${pickUpTime}</small>`
        }

        get carRentCheckOut() {
            const dropOffTime = this.dropOffTime
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `<span> ${this.$t('transfers.drop_off')}: ${date}</span> <small>${dropOffTime}</small>`
        }

        get flightCheckIn() {
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return `${this.$t('flights.departure')}: ${date}`
        }

        get flightCheckOut() {
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `${this.$t('flights.arrival')}: ${date}`
        }

        get isBankFee() {
            return this.service.serviceDetails?.[0]?.extraServiceProductType === 'BANK_FEE'
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }

    .make-new-line {
        white-space: pre-line;
    }
</style>
