<template>
    <div>
        <v-row dense>
            <v-col cols="6" class="text-center">
                <label>{{ $t('adults') }}</label>
                <v-text-field
                    ref="adults"
                    :key="adultsKey"
                    :value="adultsCount"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    data-cy="adults-selector"
                    :disabled="disabled"
                    class="pt-0"
                    @click:append-outer="inputAdults(adultsCount + 1)"
                    @click:prepend="inputAdults(adultsCount - 1)"
                    @input="inputAdults"
                />
            </v-col>
            <v-col cols="6" class="text-center">
                <label>{{ $t('children') }}</label>
                <v-text-field
                    ref="children"
                    :key="childrenKey"
                    :value="childrenAges.length"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    data-cy="children-selector"
                    :disabled="disabled"
                    class="pt-0"
                    @click:append-outer="inputChildren(childrenAges.length + 1)"
                    @click:prepend="inputChildren(childrenAges.length - 1)"
                    @input="inputChildren"
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col v-show="childrenAges.length > 0" :cols="12">
                <label>
                    {{ $t('children_ages') }}
                </label>
            </v-col>
            <v-col v-for="(age, index) in childrenAges" :key="index" cols="4" md="3">
                <v-select
                    :key="index"
                    class="pt-0"
                    :value="age"
                    :items="childrenAgeItems"
                    data-cy="children-age-selector"
                    @change="changeAge($event, index)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Emit, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import {authStore} from '~/store'

    const restrictNumber = (v, min, max) => {
        const maxLength = String(max).length
        let value = !v ? min : parseInt(v.length > maxLength ? v.slice(-1) : v, 10)
        if (value === 0 || (String(value).length === maxLength && value < min)) value = min
        else if (value > max) value = max
        return value
    }

    @Component
    export default class TouristsSelect extends Vue {
        @PropSync('adultsProp') adults
        @PropSync('childrenProp') childrenAges
        @Prop({default: 5}) maxAdults
        @Prop({default: 5}) maxChildren
        @Prop() maxTotal

        //TODO Need refactoring - create snippets/NumberField
        adultsKey = 0
        childrenKey = 0

        @Emit()
        changeAge(age, index) {
            this.childrenAges[index] = age
        }

        async inputChildren(childrenCount) {
            childrenCount = restrictNumber(childrenCount, 0, this.maxChildrenTotal)
            const childrenAgesLength = this.childrenAges.length
            if (childrenAgesLength === childrenCount) {
                this.childrenKey++
                await this.$nextTick
                this.$refs.children.focus()
            }
            if (childrenAgesLength < childrenCount) {
                for (let i = 0; i < childrenCount - childrenAgesLength; i++) {
                    this.childrenAges.push(2)
                }
            } else {
                this.childrenAges = this.childrenAges.slice(0, childrenCount)
            }
        }

        async inputAdults(v) {
            const value = restrictNumber(v, 1, this.maxAdultsTotal)
            if (this.adults === value) {
                this.adultsKey++
                await this.$nextTick
                this.$refs.adults.focus()
            }
            this.adults = value
        }

        get childrenAgeItems() {
            return Array.from(Array(18).keys())
        }

        get adultsCount() {
            return authStore.user.isEmployeeOrExternalCorp ? 1 : this.adults
        }

        get disabled() {
            return authStore.user.isEmployeeOrExternalCorp
        }

        get maxAdultsTotal() {
            return this.maxTotal ? this.maxTotal - this.childrenAges.length : this.maxAdults
        }

        get maxChildrenTotal() {
            return this.maxTotal ? this.maxTotal - this.adults : this.maxChildren
        }
    }
</script>

<style scoped>
    label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
    }
</style>
