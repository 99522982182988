<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600px">
        <template v-slot:activator="{on}">
            <v-tooltip top>
                <template v-slot:activator="{on: onHover}">
                    <div v-on="reason && isShowReason ? onHover : null">
                        <v-btn
                            :block="!mobile"
                            :class="{'my-2': !mobile}"
                            :small="small"
                            color="grey lighten-1"
                            depressed
                            class="white--text"
                            v-on="on"
                        >
                            {{ $t('unavailable') }}
                        </v-btn>
                    </div>
                </template>
                <span>{{ reason }}</span>
            </v-tooltip>
        </template>
        <v-card class="pt-5">
            <v-card-text>
                {{ $t('sold_out_modal_text') }}
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="dialog = !dialog">{{ $t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class SoldOutModal extends Vue {
        @Prop({default: false, type: Boolean}) mobile
        @Prop({default: false, type: Boolean}) small
        @Prop() reason

        dialog = false

        get isShowReason() {
            return false
        }
    }
</script>
