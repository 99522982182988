<template>
    <v-text-field
        :key="key"
        :value="value"
        :name="name"
        :prefix="`${$tc('day', value)}:`"
        background-color="white"
        data-cy="days-selector"
        type="number"
        single-line
        outlined
        class="input--no-arrows"
        @input="inputDay"
    />
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'nuxt-property-decorator'

    @Component
    export default class DaysSelect extends Vue {
        @PropSync('_value') value
        @Prop({required: true}) name
        @Prop({default: 365}) max

        key = 0

        inputDay(v) {
            let value = !v ? 0 : parseInt(v.length > String(this.max).length ? v.slice(-1) : v, 10)
            if (value < 1 && String(value).length > 1) value = 1
            else if (value === 0 && String(value).length <= 1) value = 1
            else if (value > this.max) value = this.max
            if (this.value === value) this.key++
            this.value = value
        }
    }
</script>
