<template>
    <v-dialog v-model="newOrderRequestModal" persistent :width="newAgentMode ? 800 : selectTourStep >= 2 ? 1000 : 700">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <div v-if="newAgentMode" class="d-flex align-center">
                    <v-btn fab class="me-3" small elevation="0" @click="newAgentMode = false">
                        <v-icon color="primary"> mdi-arrow-left </v-icon>
                    </v-btn>
                    <span class="text-h5 primary--text" style="word-break: break-word">
                        {{ $t('create_new_agent') }}
                    </span>
                </div>
                <template v-else>
                    <span class="text-h5 primary--text" style="word-break: break-word">
                        {{
                            selectTourStep >= 2 && selectedTourOffer
                                ? selectedTourOffer.info.name
                                : $t('new_reservation_request')
                        }}
                    </span>
                    <span v-if="selectTourStep >= 2 && selectedTourOffer">
                        {{ tourTotalPrice | price }}
                    </span>
                </template>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-8">
                <div v-show="!newAgentMode">
                    <v-form v-if="!selectTourStep" v-model="valid">
                        <v-expand-transition>
                            <v-radio-group v-if="clientTypeValues.length > 1" v-model="clientType" row>
                                <v-radio
                                    v-for="type in clientTypeValues"
                                    :key="type"
                                    :label="`${$t(`statement_of_account.client_types.${type}`)}`"
                                    :value="type"
                                />
                            </v-radio-group>
                        </v-expand-transition>
                        <v-expand-transition>
                            <private-client
                                v-if="clientType === 'pc'"
                                v-model="selectedClient"
                                :_new-client.sync="newClient"
                                :_is-new-client-mode.sync="isNewClientMode"
                            />
                            <corporate-client v-if="clientType === 'cc'" v-model="selectedCompany" />
                            <tour-agency
                                v-if="clientType === 'ta'"
                                :_selected-client.sync="selectedClient"
                                :_new-client.sync="newClient"
                                :_new-agent-mode.sync="newAgentMode"
                                :_selected-company.sync="selectedCompany"
                            />
                        </v-expand-transition>
                        <div class="mt-8 d-flex flex-column">
                            <v-text-field
                                v-model="tripTitle"
                                outlined
                                :label="$t('title')"
                                :disabled="disableTripTitle"
                                persistent-placeholder
                                @keyup="tripTitleChange"
                            />
                            <v-textarea v-model="details" outlined :label="$t('details')" persistent-placeholder />
                        </div>
                    </v-form>
                    <div v-else>
                        <select-package-tour-form
                            v-if="selectTourStep === 1"
                            v-model="tourFormValid"
                            :search-request="tourSearchRequest"
                        />
                        <package-tour-entries
                            v-else-if="selectTourStep === 2"
                            v-model="loading"
                            :search-request="{
                                ...tourSearchRequest,
                                ...(packageToursClientId && {clientId: packageToursClientId}),
                                ...(packageToursAgentId && {agentId: packageToursAgentId}),
                            }"
                            :citizenship-id="
                                (selectedClient && selectedClient.citizenshipId) || $config.defaultCitizenshipId
                            "
                            @set-tour="tourOffer = $event"
                        />
                        <package-tour-extra-data
                            v-else
                            :selected-tour-entries="selectedTourEntries"
                            :search-request="tourSearchRequest"
                            :prepare-book-requests="prepareBookRequests"
                            :disabled="loading"
                            @change-price="changeExtraPrice"
                        />
                    </div>
                </div>
                <person-form
                    v-if="selectedCompany && newAgentMode"
                    class="px-8 py-8"
                    :person="{}"
                    :role-prefix="selectedCompany.type === 'TOUR_AGENCY' ? 'ta' : 'to'"
                    :company-id="selectedCompany.id"
                    :custom-roles="[
                        'accountant',
                        'agent',
                        'director',
                        'guest',
                        'manager',
                        'sales_manager',
                        'sales_staff',
                        'supervisor',
                    ]"
                    allow-no-credentials
                    :redirect="false"
                    @save="newAgentMode = false"
                />
            </v-card-text>
            <v-card-actions class="py-4">
                <v-btn
                    v-if="isToursActive && !selectTourStep && $config.account.newReservationRequestFromPackage"
                    text
                    color="primary"
                    :disabled="!valid"
                    :loading="loading"
                    @click="selectTourStep++"
                >
                    {{ $t('tours.select_package') }}
                </v-btn>
                <v-btn
                    v-else-if="isToursActive && selectTourStep"
                    text
                    :disabled="loading"
                    @click="
                        selectTourStep--
                        tourOffer = null
                    "
                >
                    {{ $t('back') }}
                </v-btn>
                <new-order-request-note :tour="tourOffer" />
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    :disabled="
                        loading ||
                        (selectTourStep === 0 && !valid) ||
                        (selectTourStep === 1 && !tourFormValid) ||
                        (selectTourStep === 2 &&
                            (selectedTourEntries.length === 0 || isSelectedTourEntriesSoldOutHotelExists))
                    "
                    :loading="loading"
                    @click="
                        selectTourStep === 1
                            ? searchPackageTour()
                            : isSelectTourStepWithFlight
                            ? fillExtraData()
                            : createRequest()
                    "
                >
                    {{ selectTourStep === 1 || isSelectTourStepWithFlight ? $t('next') : $t('submit') }}
                </v-btn>
                <v-btn text :disabled="loading" @click="cancel">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, VModel, Watch} from 'nuxt-property-decorator'
    import ClientAutocomplete from '@/components/account/modals/newOrderRequest/ClientAutocomplete'
    import CompanyAutocomplete from '@/components/account/modals/newOrderRequest/CompanyAutocomplete'
    import CorporateClient from '@/components/account/modals/newOrderRequest/forms/CorporateClient'
    import PrivateClient from '@/components/account/modals/newOrderRequest/forms/PrivateClient'
    import TourAgency from '@/components/account/modals/newOrderRequest/forms/TourAgency'
    import {
        authStore,
        carsRentStore,
        hotelsRuntimeStore,
        persistentStore,
        toursRuntimeStore,
    } from '@/utils/store-accessor'
    import SelectPackageTourForm from '@/components/account/modals/newOrderRequest/forms/SelectPackageTourForm'
    import {searchRequest} from '@/utils/tours/tours-blank-states'
    import PackageTourEntries from '@/components/account/modals/newOrderRequest/PackageTourEntries'
    import {authorizationPersonRsTransformer} from '@/store/modules/auth'
    import {EventBus, SHOW_NOTIFICATIONS} from '@/utils/event-bus'
    import PersonForm from '~src/components/account/forms/personForm.src'
    import NewOrderRequestNote from '~src/components/account/modals/newOrderRequest/snippets/NewOrderRequestNote.vue'
    import PackageTourExtraData from '@/components/account/modals/newOrderRequest/PackageTourExtraData.vue'
    import {sumPrice} from '@/utils/api-helpers'
    import AuthInfoMixin from '~src/mixins/authInfoMixin.src'

    const tourSearchRequest = () => {
        // eslint-disable-next-line no-unused-vars
        const {adults, childrenAges, durationFrom, durationTo, ...sr} = searchRequest()
        sr.rooms = [hotelsRuntimeStore.roomQueryString({adults, childrenAges})]
        sr.startDateTo = sr.startDateFrom
        sr.departureAirportCode = null
        return sr
    }

    @Component({
        components: {
            PackageTourExtraData,
            NewOrderRequestNote,
            PersonForm,
            PackageTourEntries,
            SelectPackageTourForm,
            TourAgency,
            PrivateClient,
            CorporateClient,
            ClientAutocomplete,
            CompanyAutocomplete,
        },
    })
    export default class NewOrderRequestModal extends mixins(AuthInfoMixin) {
        @VModel() newOrderRequestModal

        loading = false
        clientType = ''
        selectedCompany = null
        selectedClient = null
        tripTitle = ''
        details = null
        isNewClientMode = false
        newClient = {
            prefix: null,
            firstName: null,
            lastName: null,
            contactPhone: null,
            email: null,
        }
        valid = false
        selectTourStep = 0
        tourFormValid = false
        tourSearchRequest = tourSearchRequest()
        newAgentMode = false
        tourOffer = null
        prepareBookRequests = []
        extraPrices = []

        @Watch('newOrderRequestModal')
        async onDialogToggle() {
            this.resetSelected()
            this.newAgentMode = false
        }

        @Watch('clientType')
        onClientTypeChange() {
            this.resetSelected()
        }

        @Watch('selectedTourEntries.length')
        initPrepareBookRequests() {
            this.prepareBookRequests = this.selectedTourEntries.map(
                () =>
                    new Proxy(
                        {},
                        {
                            set: (target, prop, val) => {
                                this.$set(target, prop, val)
                                return true
                            },
                            get: (target, prop) => {
                                return target[prop]
                            },
                        }
                    )
            )
            this.extraPrices = this.selectedTourEntries.map(() => ({
                amount: 0,
                currency: this.tourSearchRequest.convertToCurrency,
                originalAmount: 0,
            }))
        }

        async mounted() {
            this.resetSelected()
            this.clientType = this.clientTypeValues[0]
        }

        searchPackageTour() {
            this.tourSearchRequest.convertToCurrency = persistentStore.currency
            this.tourSearchRequest.startDateTo = this.tourSearchRequest.startDateFrom
            this.selectTourStep++
        }

        fillExtraData() {
            this.selectTourStep++
        }

        async createRequest() {
            try {
                this.loading = true

                if (this.isNewClientMode) {
                    const rs = await this.$api.registration.post({
                        ...this.newClient,
                        directSales: true,
                        companyId: this.selectedCompany?.id,
                    })
                    this.selectedClient = authorizationPersonRsTransformer(rs)
                }

                const rq = {
                    title: this.tripTitle,
                    comment: this.details,
                    clientPriceType: 'ONLY_TOTAL',
                }

                if (this.selectedTourOffer) {
                    rq.title = rq.title
                        ? `${rq.title} (${this.selectedTourOffer.info.name})`
                        : this.selectedTourOffer.info.name
                }

                if (this.clientType === 'pc') {
                    rq.clientId = this.selectedClient.personId || this.selectedClient.id
                } else if (this.clientType === 'cc') {
                    rq.clientId = this.selectedCompany.id
                } else if (this.clientType === 'ta') {
                    rq.clientId = this.selectedClient.companyId
                    rq.agentId = this.selectedClient.personId
                    rq.firstName = this.newClient.firstName
                    rq.lastName = this.newClient.lastName
                    rq.prefix = this.newClient.prefix
                } else if (this.isCorporate) {
                    rq.clientId = authStore.company.id
                } else if (authStore.person) {
                    rq.clientId = authStore.person.personId
                }

                const {
                    orders: [{orderId}],
                } = await this.$api.orders.post(rq)

                if (this.selectedTourEntries.length) {
                    await this.addTourServices(orderId)
                }

                if (!authStore.person || this.isAgency || this.isCorporate || this.isB2C) {
                    this.newOrderRequestModal = false
                    this.$toast.success(this.$t('new_reservation_request_success'))
                } else {
                    await this.$router.push({name: 'order-details', params: {id: orderId}})
                }

                //eslint-disable-next-line no-empty
            } catch (e) {
                console.error(e)
                this.$toast.error(e.errors?.[0]?.message || e)
            } finally {
                this.loading = false
            }
        }

        resetSelected() {
            this.selectedClient = null
            this.selectedCompany = null
            this.isNewClientMode = false
            this.newClient = {
                firstName: null,
                lastName: null,
                contactPhone: null,
                email: null,
            }
            if (!authStore.person) {
                this.isNewClientMode = true
            }
            this.selectTourStep = 0
            toursRuntimeStore.SET_OFFERS([])
            this.tourSearchRequest = tourSearchRequest()
        }

        tripTitleChange() {}

        async addTourServices(orderId) {
            try {
                const {basketKey} = await this.$api.basket.post()
                const basketRequests = this.selectedTourEntries.reduce((basketRequests, entry, index) => {
                    const prebookRequest = {
                        ...this.prepareBookRequests[index],
                        orderId,
                        offerKey: entry.selectedOfferKey,
                    }
                    switch (entry.type) {
                        case 'ACCOMMODATION':
                            basketRequests.push(
                                this.$api.prepareAccommodationBook.put(
                                    basketKey,
                                    !entry.selectedOfferKeys
                                        ? [prebookRequest]
                                        : entry.selectedOfferKeys.map(offerKey => ({orderId, offerKey}))
                                )
                            )
                            break
                        case 'ACTIVITY':
                        case 'EXCURSION':
                        case 'EVENT':
                            basketRequests.push(this.$api.prepareActivityBook.put(basketKey, prebookRequest))
                            break
                        case 'FLIGHT':
                        case 'OWNCHARTER':
                            basketRequests.push(this.$api.prepareFlightBook.put(basketKey, prebookRequest))
                            break
                        case 'CAR':
                            if (carsRentStore.pickUpLocation?.locationCode)
                                prebookRequest.pickUpPoint = carsRentStore.pickUpLocation.locationCode
                            if (carsRentStore.dropOffLocation?.locationCode)
                                prebookRequest.dropOffPoint = carsRentStore.dropOffLocation.locationCode
                            basketRequests.push(this.$api.prepareCarBook.put(basketKey, prebookRequest))
                            break
                        case 'TRANSFER':
                            basketRequests.push(this.$api.prepareBookTransfer.put(basketKey, prebookRequest))
                            break
                        case 'OWNEXTRASERVICE':
                            basketRequests.push(this.$api.prepareOwnExtraServiceBook.put(basketKey, prebookRequest))
                            break
                        case 'CRUISE':
                            basketRequests.push(this.$api.prepareBookCruise.put(basketKey, prebookRequest))
                            break
                    }
                    return basketRequests
                }, [])
                if (basketRequests.length) {
                    await Promise.all(basketRequests)
                }
                const {
                    param: {paymentMethod},
                } = await this.$api.paymentOptions.get({basketKey})
                const {id: paymentMethodId} = paymentMethod.find(paymentMethod => !paymentMethod.book)
                await this.$api.book.post({
                    basketKey,
                    paymentMethodId,
                })
            } catch (e) {
                EventBus.$emit(SHOW_NOTIFICATIONS, {
                    notifications: [this.$t('warning_message.services_not_added_to_new_order_request')],
                })
            }
        }

        cancel() {
            this.newOrderRequestModal = false
        }

        changeExtraPrice({price, index}) {
            this.$set(this.extraPrices, index, price)
        }

        get isCorporateAvailable() {
            return this.$config.account.showCorporateClients
        }

        get packageToursClientId() {
            if (this.clientType === 'pc') {
                return null
            }

            return this.selectedCompany?.id
        }

        get packageToursAgentId() {
            if (this.clientType === 'pc') {
                return null
            }

            return this.selectedClient?.personId
        }

        get clientTypeValues() {
            if (this.isCorporateAvailable) {
                if (this.isTO1 || this.isTO2) {
                    return ['pc', 'cc', 'ta']
                } else if (this.isAgency) {
                    return ['pc', 'cc']
                } else if (!authStore.person) {
                    return ['pc']
                }
            } else {
                if (this.isTO1 || this.isTO2) {
                    return ['pc', 'ta']
                } else if (this.isAgency) {
                    return ['pc']
                } else if (!authStore.person) {
                    return ['pc']
                }
            }
            return []
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isB2C() {
            return authStore.isB2C
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isAgency() {
            return authStore.isAgency
        }

        get disableTripTitle() {
            return false
        }

        get isToursActive() {
            return this.activeProducts.includes('tours')
        }

        get tourTotalPrice() {
            const tourTotalPrice = toursRuntimeStore.totalPrice(this.selectedTourOffer)
            if (this.extraPrices.length) {
                return sumPrice([tourTotalPrice, ...this.extraPrices])
            }
            return tourTotalPrice
        }

        get selectedTourOffer() {
            return toursRuntimeStore.offers[0]
        }

        get selectedTourEntries() {
            return this.selectedTourOffer?.entries.filter(({selectedOfferKey}) => selectedOfferKey) || []
        }

        get isSelectTourStepWithFlight() {
            return this.selectTourStep === 2 && this.selectedTourEntries.findIndex(({type}) => type === 'FLIGHT') !== -1
        }

        get isSelectedTourEntriesSoldOutHotelExists() {
            const selectedHotel = this.selectedTourEntries?.find(entry => entry.type === 'ACCOMMODATION')

            let selectedRoom

            selectedHotel?.offers.forEach(offer => {
                const room = offer.rooms.find(room => {
                    return !!room.groupedOffers.find(g => g.offerKey === selectedHotel?.selectedOfferKey)
                })

                if (room) {
                    selectedRoom = room
                    return undefined
                }
            })

            return !!selectedRoom?.soldOut
        }
    }
</script>
