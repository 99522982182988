<template>
    <v-col :cols="size" class="d-flex flex-column" :class="direction === 'departure' ? 'align-end' : 'align-start'">
        <div
            v-if="point.date"
            class="headline d-flex flex-wrap flex-column"
            :class="direction === 'departure' ? 'align-end' : 'align-start'"
        >
            {{ point.date | hoursFromDate }}
            <div class="caption" :class="direction === 'departure' ? 'text-right' : 'text-left'">
                {{ point.date | dateFormat }}
            </div>
        </div>
        <span v-else>{{ $t('undefined') }}</span>
        <div>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <div class="body-1" v-on="on">
                        {{ point.airportCode }}
                        <span
                            v-if="departureLabel && $route.name === 'flightBooking'"
                            class="text-caption grey--text"
                            v-text="departureLabel"
                        />
                        <span
                            v-if="arrivalLabel && $route.name === 'flightBooking'"
                            class="text-caption grey--text"
                            v-text="arrivalLabel"
                        />
                    </div>
                </template>
                <span>
                    <v-icon left class="white--text">mdi-airplane</v-icon>
                    {{ name }}
                </span>
            </v-tooltip>
        </div>
    </v-col>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {getOfferAirportName} from '@/utils/helpers'

    @Component
    export default class FlightItineraryPoint extends Vue {
        @Prop() direction
        @Prop() point
        @Prop({default: 3}) size
        @Prop() departureLabel
        @Prop() arrivalLabel

        getAirportName(data) {
            if (data.airportCode) {
                return getOfferAirportName(data)
            }
            return ''
        }

        get name() {
            return this.getAirportName(this.point)
        }
    }
</script>
