<template>
    <div class="d-flex align-center">
        <v-autocomplete
            ref="input"
            v-model="location"
            :items="items"
            :placeholder="$t('search_on_map')"
            background-color="white"
            dense
            hide-no-data
            hide-selected
            hide-details
            no-filter
            clearable
            return-object
            single-line
            outlined
            filled
            class="me-3 location"
            @change="change"
            @click:clear="clear"
            @update:search-input="onSearch"
        >
            <template v-slot:append>
                <v-icon v-if="!loading">mdi-magnify</v-icon>
                <v-progress-circular v-else indeterminate size="24" color="grey" />
            </template>
            <template v-slot:item="{item}">
                <span v-text="item.formatted_address" />
            </template>
            <template v-slot:selection="{item}">
                {{ item.formatted_address }}
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import HotelMapLocationFilter from '@/components/hotels/snippets/HotelMapLocationFilter'
    import HotelDirectionMapInfoWindow from '@/components/hotels/HotelDirectionMapInfoWindow'

    @Component
    export default class HotelMapDirectionFilter extends HotelMapLocationFilter {
        @Prop() markers
        polyline = null
        drivingDirectionData = null
        walkingDirectionData = null

        async makeDirection(point) {
            if (!point) {
                this.marker?.setMap(null)
                this.polyline.setMap(null)
                this.drivingDirectionData = null
                this.walkingDirectionData = null
                return
            }

            const request = {
                origin: this.markers[0].position.toJSON(),
                destination: point,
                travelMode: this.google.maps.DirectionsTravelMode.DRIVING,
                drivingOptions: {departureTime: new Date()},
            }
            const directionsService = new this.google.maps.DirectionsService()

            await directionsService.route(request, (response, status) => {
                if (status === this.google.maps.DirectionsStatus.OK) {
                    this.drivingDirectionData = response.routes[0]
                    this.polyline = new this.google.maps.Polyline({
                        path: response.routes[0].overview_path,
                        geodesic: true,
                        strokeColor: this.$vuetify.theme.themes.light.primary,
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                    })

                    this.polyline.setMap(this.map)
                }
            })

            await directionsService.route(
                {...request, travelMode: this.google.maps.DirectionsTravelMode.WALKING},
                (response, status) => {
                    if (status === this.google.maps.DirectionsStatus.OK) {
                        this.walkingDirectionData = response.routes[0]
                    }
                }
            )

            this.marker = new this.google.maps.Marker({
                position: point,
                icon: this.createMarkerIcon(),
                map: this.map,
            })

            this.marker.addListener('click', () => {
                if (!this.marker.infoWindow) {
                    this.isMapPositionSets = true
                    this.showDirectionWindow(this.marker)
                }
            })

            this.showDirectionWindow(this.marker)
        }

        showDirectionWindow(marker) {
            const markerWithInfoWindow = this.markers.find(marker => marker.infoWindow)
            if (markerWithInfoWindow) {
                markerWithInfoWindow.infoWindow.close()
                markerWithInfoWindow.infoWindow = null
            }

            const InfoWindowComponent = Vue.extend(HotelDirectionMapInfoWindow)
            const instance = new InfoWindowComponent({
                propsData: {
                    drivingDirectionData: this.drivingDirectionData,
                    walkingDirectionData: this.walkingDirectionData,
                    location: this.location,
                },
                parent: this,
            })
            instance.$mount()

            marker.infoWindow = new this.google.maps.InfoWindow({
                content: instance.$el,
            })
            this.google.maps.event.addListener(marker.infoWindow, 'closeclick', () => {
                marker.infoWindow.close()
                marker.infoWindow = null
            })
            marker.infoWindow.open(this.map, marker)
        }

        change() {
            if (this.location) {
                const center = this.location.geometry.location.toJSON()

                this.makeDirection(center)
            }
            this.$refs.input.blur()
        }

        clear() {
            if (this.marker) this.marker.setMap(null)
            this.marker = null
            if (this.polyline) this.polyline.setMap(null)
            this.polyline = null
        }
    }
</script>
